import ContactService from "@/services/contacts/ContactService.js";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import ContactUtils from "@/utils/contact-utils";
import {
    CONTACT_ACTION,
    ZmContact
} from "@/utils/constants"
import CONFIG from '@/config/config';
import _ from "lodash";

export default {
    state: {
        contacts: [],
        contactGroup: [],
        contactAlphabet: [],
        listSelectedContact: [],
        contactMode: CONTACT_ACTION.SCREEN_CONTACT,
        contactAction: null,
        contactCompose: {},
        activeContact: null,
        showPrefix: false,
        showMiddle: false,
        showMaiden: false,
        showSuffix: false,
        showNickName: false,
        showDepart: false,
        maxSizeUpload: 28 * 1048576, // B
        isCalled: 0,
        activeNodeContact: null,
        distributionLists: [],
        distributionListMembers: {},
        activeDistribution: null,
        currentExtraActiveContact: null,
        listSelectedDistribution: [],
        dataSidebarContact: null,
        dataSidebarExtendContact: null
    },
    getters: {
        contacts: state => state.contacts,
        contactGroup: state => state.contactGroup,
        contactAlphabet: state => state.contactAlphabet,
        contactMode: state => state.contactMode,
        contactAction: state => state.contactAction,
        activeContact: state => state.activeContact,
        showPrefix: state => state.showPrefix,
        showMiddle: state => state.showMiddle,
        showMaiden: state => state.showMaiden,
        showSuffix: state => state.showSuffix,
        showNickName: state => state.showNickName,
        showDepart: state => state.showDepart,
        maxSizeUpload: state => state.maxSizeUpload,
        isCalled: state => state.isCalled,
        listSelectedContact: state => state.listSelectedContact,
        activeNodeContact: state => state.activeNodeContact,
        distributionLists: state => state.distributionLists,
        distributionListMembers: state => state.distributionListMembers,
        activeDistribution: state => state.activeDistribution,
        listSelectedDistribution: state => state.listSelectedDistribution,
        currentExtraActiveContact: state => state.currentExtraActiveContact,
        dataSidebarContact: state => state.dataSidebarContact,
        dataSidebarExtendContact: state => state.dataSidebarExtendContact,
    },
    mutations: {
        SET_CONTACTS(state, data) {
            state.contacts = data
        },
        SET_CONTACT_GROUP(state, data) {
            state.contactGroup = data
        },
        ADD_CONTACT_GROUP(state, data) {
            state.contactGroup.push(data)
            state.contactGroup = _.orderBy(state.contactGroup, ["fileAsStr"], ["asc"])
        },
        EDIT_CONTACT_GROUP(state, data) {
            const _index = state.contacts.find(contact => contact.id == data.id)
            if (_index !== -1) {
                state.contacts = [
                    ...state.contacts.splice(0, _index),
                    data,
                    ...state.contacts.splice(_index + 1)
                ]
            }
        },
        SET_CONTACTALPHABET(state, data) {
            state.contactAlphabet = data
        },
        SET_CONTACTMODE(state, data) {
            state.contactMode = data
        },
        SET_CONTACTACTION(state, data) {
            state.contactAction = data
        },
        SET_ACTIVECONTACT(state, data) {
            state.activeContact = data
        },
        SET_ACTIVENODECONTACT(state, data) {
            state.activeNodeContact = data
        },
        ADD_CONTACT(state, data) {
            state.contacts.push(data)
            state.contacts = _.orderBy(state.contacts, "fileAsStr", "asc")
        },
        ADD_CONTACTALPHABET(state, data) {
            state.contactAlphabet.push(data)
            state.contactAlphabet = _.orderBy(state.contactAlphabet, "fileAsStr", "asc")
        },
        EDIT_CONTACT(state, data) {
            const _index = state.contacts.findIndex(ele => ele.id == data.id)
            if (_index > -1) {
                state.contacts = [
                    ...state.contacts.slice(0, _index),
                    data,
                    ...state.contacts.slice(_index + 1)
                ]
            }
        },
        EDIT_CONTACTALPHABET(state, data) {
            const _index = state.contactAlphabet.findIndex(ele => ele.id == data.id)
            if (_index > -1) {
                state.contactAlphabet = [
                    ...state.contactAlphabet.slice(0, _index),
                    data,
                    ...state.contactAlphabet.slice(_index + 1)
                ]
            }
        },
        SET_LIST_SELECTED_CONTACT(state, data) {
            state.listSelectedContact = data;
        },
        EDIT_LIST_SELECTED_CONTACT(state, data) {
            const _index = state.listSelectedContact.findIndex(ele => ele.id === data.id)
            if (_index !== -1) {
                state.listSelectedContact.splice(_index, 1);
            } else {
                state.listSelectedContact.push(data)
            }
        },
        DELETE_CONTACT(state, data) {
            const _ids = Number.isInteger(data) ? [data.toString()] : data.split(",")
            state.contacts = state.contacts.filter(contact => _ids.indexOf(contact.id.toString()) === -1).map((ele, idx) => ({ ...ele, index: idx }))
        },
        DELETE_CONTACTALPHABET(state, data) {
            const _ids = Number.isInteger(data) ? [data.toString()] : data.split(",")
            state.contactAlphabet = state.contactAlphabet.filter(contact => _ids.indexOf(contact.id.toString()) === -1)
        },
        DELETE_CONTACT_GROUP(state, data) {
            const _ids = Number.isInteger(data) ? [data.toString()] : data.split(",")
            state.contactGroup = state.contactGroup.filter(contact => _ids.indexOf(contact.id.toString()) === -1)
        },
        EDIT_SHOWPREFIX(state, data) {
            state.showPrefix = data
        },
        EDIT_SHOWMIDDLE(state, data) {
            state.showMiddle = data
        },
        EDIT_SHOWMAIDEN(state, data) {
            state.showMaiden = data
        },
        EDIT_SHOWSUFFIX(state, data) {
            state.showSuffix = data
        },
        EDIT_SHOWNICKNAME(state, data) {
            state.showNickName = data
        },
        EDIT_SHOWDEPART(state, data) {
            state.showDepart = data
        },
        SET_CONTACT_COMPOSE(state, data) {
            state.contactCompose = data
        },
        SET_CALL(state, data) {
            state.isCalled = data
        },
        SET_DISTRIBUTIONLISTS(state, data) {
            state.distributionLists = data
        },
        EDIT_DISTRIBUTIONLISTS(state, data) {
            const _index = state.distributionLists.findIndex(ele => ele.id === data.id);
            if (_index !== -1) {
                state.distributionLists = [
                    ...state.distributionLists.slice(0, _index),
                    data,
                    ...state.distributionLists.slice(_index + 1)
                ]
            }
        },
        SET_DISTRIBUTIONLISTMEMBERS(state, data) {
            state.distributionListMembers = data
        },
        SET_ACTIVEDISTRIBUTION(state, data) {
            state.activeDistribution = data
        },
        SET_LIST_SELECTED_DISTRIBUTION(state, data) {
            state.listSelectedDistribution = data
        },
        SET_ACTIVE_CONTACT_EXTRA(state, data) {
            state.currentExtraActiveContact = data;
        },
        SET_DATA_EXTEND_CONTACT(state, data) {
            state.dataSidebarExtendContact = data;
        },
        SET_DATA_CONTACT(state, data) {
            state.dataSidebarContact = data;
        },
    },
    actions: {
        async initDataContactLogin({ commit }) {
            try {
                await ContactService.getContactGroup().then(async (response) => {
                    let reader = new FileReader();
                    if (typeof response.data.text() !== undefined) {
                        await response.data.text().then(async text => {
                            const returnList = [];
                            const contacts = text.split(ZmContact.CONTACT_SPLIT_CHAR);
                            for (let i = 0, len = contacts.length; i < len; i++) {
                                let contact = {}, attrs = {};
                                let fields = contacts[i].split(ZmContact.FIELD_SPLIT_CHAR);
                                let groupMembers = [];
                                // let foundDeref = false;
                                for (let j = 0, len1 = fields.length; j < len1; j += 2) {
                                    if (ZmContact.IS_CONTACT_FIELD[fields[j]]) {
                                        contact[fields[j]] = fields[j + 1];
                                    } else {
                                        const value = fields[j + 1];
                                        switch (fields[j]) {
                                            case ZmContact.F_memberC:
                                                groupMembers.push({ type: ZmContact.GROUP_CONTACT_REF, value: value });
                                                // foundDeref = true; //load shared contacts
                                                break;
                                            case ZmContact.F_memberG:
                                                groupMembers.push({ type: ZmContact.GROUP_GAL_REF, value: value });
                                                // foundDeref = true;
                                                break;
                                            case ZmContact.F_memberI:
                                                groupMembers.push({ type: ZmContact.GROUP_INLINE_REF, value: value });
                                                // foundDeref = true;
                                                break;
                                            default:
                                                attrs[fields[j]] = value;
                                        }
                                    }
                                    if (attrs[ZmContact.F_type] === "group") { //set only for group.
                                        attrs[ZmContact.F_groups] = groupMembers;
                                    }
                                    contact._attrs = attrs;
                                    returnList.push(contact);
                                }
                            }
                            const _setContact = Array.from(new Set(returnList))
                            const ids = _setContact.filter(contact => contact._attrs && contact._attrs.groups)
                            if (ids.length) {
                                await ContactService.getContactsRequest(ids).then(async resp => {
                                    let _listContactGroup = await CommonUtils.methods.getResponseBody(resp)["GetContactsResponse"]["cn"]
                                    _listContactGroup = Array.isArray(_listContactGroup) ? _listContactGroup : [_listContactGroup]
                                    const _newList = _listContactGroup.map(contact => ({ ...contact, m: Array.isArray(contact.m) ? contact.m : [contact.m] }))
                                    commit("SET_CONTACT_GROUP", _newList)
                                })
                            }
                        });
                    }
                })
            } catch (error) {
                console.log(error);
            }
        },
        async getAllContacts({ commit }, formData) {
            try {
                const resp = await ZimbraMailService.searchRequest(formData)
                const listDataContact = CommonUtils.methods.getResponseBody(resp)["SearchResponse"]["cn"]
                const isCalled = CommonUtils.methods.getResponseBody(resp)["SearchResponse"]["more"]
                if (listDataContact) {
                    let _temp = []
                    if (Array.isArray(listDataContact)) {
                        _temp = listDataContact
                    } else {
                        _temp.push(listDataContact)
                    }
                    const _list = ContactUtils.methods.objectToArray(_temp)
                    const _contacts = _list.map(ele => {
                        if (ele.a) {
                            const _image = ele.a.find(attr => attr.n === "image")
                            const _email = []
                            ele.a.forEach(attr => {
                                if (attr.n.includes("email")) {
                                    _email.push(attr.content)
                                }
                            })
                            ele["url"] = _image ? `${CONFIG.ENDPOINT["homeService"]}/~/?auth=co&id=${ele.id}&part=1&max_width=48&max_height=48&t=${new Date().getTime()}` : require('../../assets/images/Avatar.png')
                            ele["email"] = _email ? _email : []
                        }
                        return ele
                    });
                    commit("SET_ACTIVECONTACT", _contacts[0])
                }
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async addContactRequest({ commit }, formData) {
            try {
                const resp = await ContactService.createContactRequest(formData);
                const respJson = CommonUtils.methods.getResponseBody(resp)
                if (respJson) {
                    commit("ADD_CONTACT", respJson["CreateContactResponse"]["cn"])
                }
                return resp
            } catch (error) {
                console.log(error);
            }
        },
        async modifyContactRequest({ commit }, formData) {
            try {
                const resp = await ContactService.modifyContactRequest(formData);
                const { cn } = CommonUtils.methods.getResponseBody(resp)["ModifyContactResponse"]
                if (cn) {
                    commit("EDIT_CONTACT", cn)
                    commit("SET_ACTIVECONTACT", cn)
                }
                return resp
            } catch (error) {
                console.log(error);
            }
        },
        async deleteContactRequest({ commit }, formData) {
            try {
                let isSuccess = false
                const resp = await ContactService.contactActionRequest(formData)
                const respJson = CommonUtils.methods.getResponseBody(resp)
                if (respJson["ContactActionResponse"]) {
                    isSuccess = true
                    const action = respJson["ContactActionResponse"]["action"]
                    commit("DELETE_CONTACT", action.id)
                }
                return isSuccess
            } catch (error) {
                console.log(error);
            }
        },
    }
}
