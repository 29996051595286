<template>
    <div >
        <span v-if="!showOnlyTime" class="mr-2">
            <!-- {{date}} {{$t("zimbra.zhMsg.calViewMonthShort")}} {{month}}, {{year}} {{$t("zimbra.zhMsg.FILT_COND_HEADER_from")}}   -->
            {{date}} từ {{hours}}
        </span>

        <span v-if="showOnlyTime" class="ml-2">
             {{hours}}
        </span>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
export default {
    name:"ConvertDateToText",
    props: {
        time:{
            type: Number,
            default:  null
        },
        showOnlyTime:{
            type: Boolean,
            default: false
        }

    },
    created() {
        this.convertTime()
    },
    methods:{
        convertTime(){
            this.date = moment(this.time).lang(this.localeCurrent.content).format('D MMMM, YYYY');
            this.hours = moment(this.time).lang(this.localeCurrent.content).format('HH:mm A');
        }
    },
    computed:{
        ...mapGetters(["localeCurrent"]),
    },
    data(){
        return{
            date: 1,
            hours: 0,
        }
    }
}
</script>