import moment from "moment";
import i18n from "@/plugins/i18n";

const AjxDateUtil = {};

AjxDateUtil.YEAR = 1;
AjxDateUtil.MONTH = 2;
AjxDateUtil.WEEK = 3;
AjxDateUtil.DAY = 4;
AjxDateUtil.TWO_WEEKS = 5;

AjxDateUtil.SUNDAY = 0;
AjxDateUtil.MONDAY = 1;
AjxDateUtil.TUESDAY = 2;
AjxDateUtil.WEDNESDAY = 3;
AjxDateUtil.THURSDAY = 4;
AjxDateUtil.FRIDAY = 5;
AjxDateUtil.SATURDAY = 6;

AjxDateUtil.MSEC_PER_MINUTE = 60000;
AjxDateUtil.MSEC_PER_FIFTEEN_MINUTES = 900000;
AjxDateUtil.MSEC_PER_HALF_HOUR = 1800000;
AjxDateUtil.MSEC_PER_HOUR = 3600000;
AjxDateUtil.MSEC_PER_DAY = 24 * AjxDateUtil.MSEC_PER_HOUR;

AjxDateUtil.MINUTES_PER_DAY = 60 * 24;
AjxDateUtil.SECONDS_PER_DAY = 60 * 60 * 24;

AjxDateUtil.DAYS_PER_WEEK = 7;


AjxDateUtil.getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}
const AjxMsg = {};

AjxMsg["calc.now"] = "now";
AjxMsg["calc.date"] = "date";

AjxMsg["calc.duration.year"] = "year|years";
AjxMsg["calc.duration.month"] = "mons|month|months";
AjxMsg["calc.duration.day"] = "day|days";
AjxMsg["calc.duration.hour"] = "hour|hours";
AjxMsg["calc.duration.minute"] = "min|mins|minute|minutes";
AjxMsg["calc.duration.week"] = "week";
AjxMsg["calc.duration.second"] = "sec|secs|second|seconds";
AjxMsg["calc.duration.millisecond"] = "milli|millis|millisecond|milliseconds";



AjxDateUtil.S_DAYNAME = [
    i18n.t("zimbraNokey.calc_dayname_sunday"),
    i18n.t("zimbraNokey.calc_dayname_monday"),
    i18n.t("zimbraNokey.calc_dayname_tuesday"),
    i18n.t("zimbraNokey.calc_dayname_wednesday"),
    i18n.t("zimbraNokey.calc_dayname_thursday"),
    i18n.t("zimbraNokey.calc_dayname_friday"),
    i18n.t("zimbraNokey.calc_dayname_saturday"),
].join("|");

AjxDateUtil.S_MONTHNAME = [
    i18n.t("zimbraNokey.calc_monthname_january"),
    i18n.t("zimbraNokey.calc_monthname_february"),
    i18n.t("zimbraNokey.calc_monthname_march"),
    i18n.t("zimbraNokey.calc_monthname_april"),
    i18n.t("zimbraNokey.calc_monthname_may"),
    i18n.t("zimbraNokey.calc_monthname_june"),
    i18n.t("zimbraNokey.calc_monthname_july"),
    i18n.t("zimbraNokey.calc_monthname_august"),
    i18n.t("zimbraNokey.calc_monthname_september"),
    i18n.t("zimbraNokey.calc_monthname_october"),
    i18n.t("zimbraNokey.calc_monthname_november"),
    i18n.t("zimbraNokey.calc_monthname_december"),
].join("|");

AjxDateUtil.S_WEEKORD = [
    i18n.t("zimbraNokey.calc_ordinal_first"),
    i18n.t("zimbraNokey.calc_ordinal_second"),
    i18n.t("zimbraNokey.calc_ordinal_third"),
    i18n.t("zimbraNokey.calc_ordinal_fourth"),
    i18n.t("zimbraNokey.calc_ordinal_fifth"),
    i18n.t("zimbra.zhMsg.recurLast"),
].join("|");

AjxDateUtil.WEEKORD_RE = [
    new RegExp("(first|" + i18n.t("zimbraNokey.calc_ordinal_first") + ")", "i"),
    new RegExp("(second|" + i18n.t("zimbraNokey.calc_ordinal_second") + ")", "i"),
    new RegExp("(third|" + i18n.t("zimbraNokey.calc_ordinal_third") + ")", "i"),
    new RegExp("(fourth|" + i18n.t("zimbraNokey.calc_ordinal_fourth") + ")", "i"),
    new RegExp("(last|" + i18n.t("zimbra.zhMsg.recurLast") + ")", "i")
];

AjxDateUtil.__calculate_init =
    function () {
        AjxDateUtil.WEEKDAYS = {};
        var weekdays = [
            "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"
        ];
        for (let i = 0; i < weekdays.length; i++) {
            let weekday = i18n.t("zimbraNokey.calc_dayname_" + weekdays[i]).split("|");
            for (let j = 0; j < weekday.length; j++) {
                AjxDateUtil.WEEKDAYS[weekday[j].toLowerCase()] = i;
            }
        }

        AjxDateUtil.MONTHNAME2MONTHNUM = {};
        var months = [
            "january", "february", "march", "april", "may", "june",
            "july", "august", "september", "october", "november", "december"
        ];
        for (let i = 0; i < months.length; i++) {
            let month = i18n.t("zimbraNokey.calc_monthname_" + months[i]).split("|");
            for (let j = 0; j < month.length; j++) {
                AjxDateUtil.MONTHNAME2MONTHNUM[month[j].toLowerCase()] = i;
            }
        }

        AjxDateUtil.RE_YEAR = new RegExp("^(" + AjxMsg["calc.duration.year"] + ")$", "i");
        AjxDateUtil.RE_MONTH = new RegExp("^(" + AjxMsg["calc.duration.month"] + ")$", "i");
        AjxDateUtil.RE_WEEK = new RegExp("^(" + AjxMsg["calc.duration.week"] + ")$", "i");
        AjxDateUtil.RE_DAY = new RegExp("^(" + AjxMsg["calc.duration.day"] + ")$", "i");
        AjxDateUtil.RE_HOUR = new RegExp("^(" + AjxMsg["calc.duration.hour"] + ")$", "i");
        AjxDateUtil.RE_MINUTE = new RegExp("^(" + AjxMsg["calc.duration.minute"] + ")$", "i");
        AjxDateUtil.RE_SECOND = new RegExp("^(" + AjxMsg["calc.duration.second"] + ")$", "i");
        AjxDateUtil.RE_MILLISECOND = new RegExp("^(" + AjxMsg["calc.duration.millisecond"] + ")$", "i");

        AjxDateUtil.RE_DATE = new RegExp("^(" + AjxMsg["calc.date"] + ")$", "i");

        AjxDateUtil.RE_DAYNAME = new RegExp("^(" + AjxDateUtil.S_DAYNAME + ")$", "i");
        AjxDateUtil.RE_MONTHNAME = new RegExp("^(" + AjxDateUtil.S_MONTHNAME + ")$", "i");
        AjxDateUtil.RE_WEEKORD = new RegExp("^(" + AjxDateUtil.S_WEEKORD + ")$", "i");

        AjxDateUtil.RE_COMMENT = /^#/;
        AjxDateUtil.RE_NOW = new RegExp("^(" + AjxMsg["calc.now"] + ")$", "i");
        AjxDateUtil.RE_ADD_NUMBER = new RegExp("^([+\\-])(\\d+)$", "i");
        AjxDateUtil.RE_SET = new RegExp("^(" + AjxDateUtil.S_DURATION + "|" + AjxMsg["calc.date"] + ")=(.*)$", "i");
    };

AjxDateUtil.__calculate_normalizeFullWidthDigit =
    function (digit) {
        var charCode = "0".charCodeAt(0) + digit.charCodeAt(0) - "\uff10".charCodeAt(0);
        return String.fromCharCode(charCode);
    };

AjxDateUtil.__calculate_parseInt =
    function (s) {
        s = s.replace(/([\uFF10-\uFF19])/g, AjxDateUtil.__calculate_normalizeFullWidthDigit);
        return parseInt(s, 10);
    };


AjxDateUtil.__calculate_add =
    function (date, type, amount) {
        if (type.match(AjxDateUtil.RE_YEAR)) {
            date.setFullYear(date.getFullYear() + amount);
            return;
        }
        if (type.match(AjxDateUtil.RE_MONTH)) {
            var month = date.getMonth();
            date.setMonth(month + amount);
            // avoid roll
            if (Math.abs(month + amount) % 12 != date.getMonth()) {
                date.setDate(0);
            }
            return;
        }
        if (type.match(AjxDateUtil.RE_WEEK)) {
            date.setDate(date.getDate() + amount * 7);
            return;
        }
        if (type.match(AjxDateUtil.RE_DAY)) {
            date.setDate(date.getDate() + amount);
            return;
        }
        if (type.match(AjxDateUtil.RE_HOUR)) {
            date.setHours(date.getHours() + amount);
            return;
        }
        if (type.match(AjxDateUtil.RE_MINUTE)) {
            date.setMinutes(date.getMinutes() + amount);
            return;
        }
        if (type.match(AjxDateUtil.RE_SECOND)) {
            date.setSeconds(date.getSeconds() + amount);
            return;
        }
        if (type.match(AjxDateUtil.RE_MILLISECOND)) {
            date.setMilliseconds(date.getMilliseconds() + amount);
            return;
        }
        if (type.match(AjxDateUtil.RE_MONTHNAME)) {
            var monthnum = AjxDateUtil.MONTHNAME2MONTHNUM[type.toLowerCase()];
            if (monthnum < date.getMonth()) {
                amount += amount > 0 ? 0 : 1;
            }
            else if (monthnum > date.getMonth()) {
                amount += amount > 0 ? -1 : 0;
            }
            date.setFullYear(date.getFullYear() + amount, monthnum, 1);
            return;
        }
        if (type.match(AjxDateUtil.RE_DAYNAME)) {
            var daynum = AjxDateUtil.WEEKDAYS[type.toLowerCase()];
            if (daynum < date.getDay()) {
                amount += amount > 0 ? 0 : 1;
            }
            else if (daynum > date.getDay()) {
                amount += amount > 0 ? -1 : 0;
            }
            date.setDate(date.getDate() + (daynum - date.getDay()) + 7 * amount);
            return;
        }
        throw "unknown type: " + type;
    };


AjxDateUtil.__calculate_fullYear =
    function (value) {
        if (value.length == 2) {
            var d = new Date;
            d.setYear(parseInt(value, 10));
            var fullYear = d.getFullYear();
            if (fullYear <= AjxMsg.dateParsing2DigitStartYear) {
                value = String(fullYear + 100);
            }
            else {
                value = String(fullYear).substr(0, 2) + value;
            }
        }
        return parseInt(value, 10);
    };

AjxDateUtil.__calculate_month =
    function (value) {
        var monthnum = AjxDateUtil.MONTHNAME2MONTHNUM[value.toLowerCase()];
        return monthnum != null ? monthnum : parseInt(value, 10) - 1;
    };

AjxDateUtil.__calculate_week = function (value) {
    for (var i = 0; i < AjxDateUtil.WEEKORD_RE.length; i++) {
        if (value.match(AjxDateUtil.WEEKORD_RE[i])) {
            if (i == AjxDateUtil.WEEKORD_RE.length - 1) {
                return -1;
            }
            return i;
        }
    }
    return 0;
};

AjxDateUtil.__calculate_day =
    function (value) {
        var daynum = AjxDateUtil.WEEKDAYS[value.toLowerCase()];
        return daynum != null ? daynum : parseInt(value, 10);
    };


AjxDateUtil.__calculate_set =
    function (date, type, value) {
        var args = value.split(/,/);
        //Add support for Japanese Heisei year format represented by H{year-number}
        //The year is H23 in H23/12/31, means 2011/12/31; we get that by adding year 1988 to 23
        //For example: H23 = 23 + 1988 = 2011(English year)
        if (args[0].indexOf("H") == 0) {
            args[0] = parseInt(args[0].replace("H", "")) + 1988;
        }
        if (type.match(AjxDateUtil.RE_YEAR)) {
            args[0] = AjxDateUtil.__calculate_fullYear(args[0]); // year
            if (args[1] != null) args[1] = AjxDateUtil.__calculate_month(args[1]); // month
            if (args[2] != null) args[2] = parseInt(args[2], 10); // date
            date.setFullYear.apply(date, args);
            return;
        }
        if (type.match(AjxDateUtil.RE_MONTH)) {
            args[0] = AjxDateUtil.__calculate_month(args[0]); // month
            if (args[1] != null) args[1] = parseInt(args[1], 10); // date
            date.setMonth.apply(date, args);
            return;
        }
        if (type.match(AjxDateUtil.RE_WEEK)) {
            var ord = AjxDateUtil.__calculate_week(args[0]); // week
            var day = args[1] ? AjxDateUtil.__calculate_day(args[1]) : date.getDay(); // day

            var target;
            if (ord != -1) {
                var firstday = new Date(date.getFullYear(), date.getMonth(), 1, 12, 0, 0, 0);
                var firstdow = firstday.getDay();
                var delta = firstdow - day;

                target = new Date(firstday.getTime());
                target.setDate(1 - delta);
                if (delta > 0) {
                    target.setDate(target.getDate() + 7);
                }
                target.setDate(target.getDate() + 7 * ord);
            }
            else {
                var lastday = new Date(date.getFullYear(), date.getMonth() + 1, 0, 12, 0, 0, 0);

                target = new Date(lastday.getTime());
                target.setDate(target.getDate() - (target.getDay() - day));
                if (target.getMonth() != lastday.getMonth()) {
                    target.setDate(target.getDate() - 7);
                }
            }

            if (target && (date.getMonth() == target.getMonth())) {
                date.setTime(target.getTime());
            }
            return;
        }
        if (type.match(AjxDateUtil.RE_DATE)) {
            args[0] = parseInt(args[0], 10); // date
            date.setDate.apply(date, args);
            return;
        }
        if (type.match(AjxDateUtil.RE_HOUR)) {
            args[0] = parseInt(args[0], 10); // hour
            if (args[1] != null) args[1] = parseInt(args[1], 10); // minutes
            if (args[2] != null) args[2] = parseInt(args[2], 10); // seconds
            if (args[3] != null) args[3] = parseInt(args[3], 10); // milliseconds
            date.setHours.apply(date, args);
            return;
        }
        if (type.match(AjxDateUtil.RE_MINUTE)) {
            args[0] = parseInt(args[0], 10); // minutes
            if (args[1] != null) args[1] = parseInt(args[1], 10); // seconds
            if (args[2] != null) args[2] = parseInt(args[2], 10); // milliseconds
            date.setMinutes.apply(date, args);
            return;
        }
        if (type.match(AjxDateUtil.RE_SECOND)) {
            args[0] = parseInt(args[0], 10); // seconds
            if (args[1] != null) args[1] = parseInt(args[1], 10); // milliseconds
            date.setSeconds.apply(date, args);
            return;
        }
        if (type.match(AjxDateUtil.RE_MILLISECOND)) {
            date.setMilliseconds.apply(date, args); // milliseconds
            return;
        }
        throw "unknown type: " + type;
    };

AjxDateUtil.calculate = (rule, date) => {
    // initialize
    if (!AjxDateUtil.__calculate_initialized) {
        AjxDateUtil.__calculate_initialized = true;
        AjxDateUtil.__calculate_init();
    }

    var now = date || new Date;
    rule = rule.replace(/^\s*|\s*$/, "").replace(/\s*=\s*/g, "=").replace(/\s*,\s*/g, ",");
    var a = rule.split(/\s+/g);
    var s, m, plusminus, number, type, amount;
    for (var i = 0; i < a.length; i++) {
        s = a[i];
        // comment
        if (s.match(AjxDateUtil.RE_COMMENT)) {
            break;
        }
        // context date
        if (s.match(AjxDateUtil.RE_NOW)) {
            date = new Date(now.getTime());
            continue;
        }
        // add
        m = s.match(AjxDateUtil.RE_ADD_NUMBER);
        if (m) {
            plusminus = m[1];
            number = AjxDateUtil.__calculate_parseInt(m[2]);
            type = a[++i];
            amount = plusminus == '+' ? number : number * -1;
            AjxDateUtil.__calculate_add(date, type, amount);
            continue;
        }
        // set
        m = s.match(AjxDateUtil.RE_SET);
        if (m) {
            AjxDateUtil.__calculate_set(date, m[1], m[2]);
            continue;
        }
        // try to parse as a date
        date = moment(s, "yyyyy-MM-DD").toDate();
        if (!date && (date = moment(s, "yyyy-MM-DD'T'hh:mm:ss'Z'").toDate())) {
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        }
        if (!date) date = moment(s, "yyyy-MM-DD'T'HH:mm:ss").toDate();
        if (!date) throw "invalid date pattern: \"" + s + "\"";
    }
    return date;
};

export default AjxDateUtil;