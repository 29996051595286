<template>
    <div class="form-controls">
        <label v-show="label" :for="id" class="label-control">{{
            this.label
        }}</label>
        <el-input
            :type="type"
            :id="id"
            class="input-control"
            size="small"
            :rows="rows"
            :disabled="disabled"
            :suffix-icon="icon"
            v-model="keyword"
            :number="number"
            :readonly="readonly"
            :show-password="password"
            @change="handleChange"
            @input="handleInput"
            @mouseout="handleChange"
            @blur="handleBlur"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :auto-complete="autocomplete"
            :maxlength="maxlength !== null ? maxlength : undefined"
        />
    </div>
</template>

<script>
export default {
    name: "InputText",
    props: {
        label: {
            type: String,
            default: "",
        },
        id: {
            type: [String, Number],
        },
        type: {
            type: String,
            default: "text",
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: "",
        },
        rows: {
            default: 7,
        },
        value: {
            type: [String, Number, Date],
        },
        placeholder: {
            type: String,
            default: "",
        },
        number: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        password: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            type: [Boolean, String],
            default: "off",
        },
        maxlength:{
            type: Number,
            default: null
        }
    },
    created() {
        this.keyword = this.value;
    },
    data() {
        return {
            keyword: "",
        };
    },
    watch: {
        value(val) {
            this.keyword = val;
        },
    },
    methods: {
        trim(val) {
            return val
                .toString()
                .replace(/(^\s*)|(\s*$)/gi, "")
                .replace(/[ ]{2,}/gi, " ");
        },
        handleChange(value) {
            value = value.trim();
            this.keyword = this.trim(value);
            if (this.number) {
                const regex = /^[0-9]*$/;
                const check = regex.test(value);
                if (!check) {
                    value = value.substring(0, value.length - 1);
                }
            }
            this.$emit("input", value.trim());
        },
        handleInput(value) {
            this.$emit("handleInput", value);
        },
        handleBlur(value) {
            this.$emit("blur", value);
        }
    },
};
</script>
