import BaseService from "../shared/services/BaseService";
import CONFIG from '@/config/config';
class CalendarService extends BaseService {
    path = "modifyPrefsRequest";
    pathRevokeRights = "revokeRightsRequest";
    pathGrantRights = "grantRightsRequest";
    url = CONFIG.ENDPOINT['compileService'];

    constructor() {
        super("zimbraService");
    }

    handleJsonToString(obj) {
        return JSON.stringify(obj);
    }

    listPrefs = [
        "zimbraPrefCalendarInitialView",
        "zimbraPrefCalendarFirstDayOfWeek",
        "zimbraPrefCalendarApptVisibility",
        "zimbraPrefCalendarForwardInvitesTo",
        "zimbraPrefCalendarApptReminderWarningTime",
        "zimbraPrefCalendarDefaultApptDuration",
        "zimbraPrefAppleIcalDelegationEnabled",
        "zimbraPrefCalendarUseQuickAdd",
        "zimbraPrefUseTimeZoneListInCalendar",
        "zimbraPrefDeleteInviteOnReply",
        "zimbraPrefCalendarAlwaysShowMiniCal",
        "zimbraPrefCalendarAutoAddInvites",
        "zimbraPrefCalendarShowDeclinedMeetings",
        "zimbraPrefShowCalendarWeek",
        "zimbraPrefCalendarReminderSoundsEnabled",
        "zimbraPrefCalendarReminderFlashTitle",
        "zimbraPrefCalendarToasterEnabled",
        "zimbraPrefCalendarShowPastDueReminders",
        "zimbraPrefCalendarWorkingHours",
        "zimbraPrefCalendarSendInviteDeniedAutoReply"
    ];

    /**
     * get Email remind when login
     * @param authToken
     * @returns {Promise<*>}
     */
    async getPrefsRequest() {
        let xmlStr = "";
        this.listPrefs.forEach(e => {
            xmlStr += `<urn1:pref name='${e}'></urn1:pref>`;
        });

        
        return this.postRequest(this.url, {
            listRequest: [
                {
                    path: "getPrefsRequest",
                    content: this.handleJsonToString({xml: xmlStr})
                }
            ]
        });
    }

    /**
     * getRightsRequest
     * @param authToken
     * @returns {Promise<*>}
     */
    async getRightsRequest() {
        return this.postRequest(this.url, {
            listRequest: [
                {
                    path: "getRightsRequest",
                    content: this.handleJsonToString({ace: ""})
                }
            ]
        });
    }

    /**
     * send Calendar to Zimbra service
     * @param formData
     * @param authToken
     * @returns {Promise<string>}
     */
    async sendCalendar(formData) {
        let xmlStr = "";
        formData.map(item => {
            // Kiem tra neu la dang boolean thi can uppercase true/false
            if(typeof item.content == "boolean"){
                const upperCaseBoolVal = item.content.toString().toUpperCase();
                xmlStr += `<urn1:pref name='${item.name}'>${upperCaseBoolVal}</urn1:pref>`;
            }else{
                xmlStr += `<urn1:pref name='${item.name}'>${item.content}</urn1:pref>`;
            }
        })
        return this.postRequest(this.url, {
            listRequest: [
                {
                    path: "modifyPrefsRequest",
                    content: this.handleJsonToString({xml: xmlStr.trim()})
                }
            ]
        });
    }

    /**
     * send Calendar to Zimbra service
     * @param data
     * @param authToken
     * @returns {Promise<string>}
     */
    async grantRightsRequest(data) {
        const request = {
            listRequest: [
                {
                    path: "grantRightsRequest",
                    content: this.handleJsonToString({
                        xml: data.map(item => {
                            let listItem = ""
                            for(let i in item){
                                listItem += ` ${i}='${item[i]}'`
                            }
                            return `<urn1:ace ${listItem}/>`
                        }).join("")
                    })
                }
            ]
        };
        return await this.postRequest(this.url, request);
    }
    /**
     * send Calendar to Zimbra service
     * @param data
     * @param authToken
     * @returns {Promise<string>}
     */
     async revokeRightsRequest(data) {
        const request = {
            listRequest: [
                {
                    path: "revokeRightsRequest",
                    content: this.handleJsonToString({
                        xml: data.map(item => {
                            let listItem = ""
                            for(let i in item){
                                listItem += ` ${i}='${item[i]}'`
                            }
                            return `<urn1:ace ${listItem}/>`
                        }).join("")
                    })
                }
            ]
        };
        return await this.postRequest(this.url, request);
    }
}

export default new CalendarService();
