<template>
    <el-dialog
        class="dialog-body-wrap zimbra-dialog add-share-folder-dialog"
        custom-class="dialog-add-folder"
        :title="$t('zimbra.zmMsg.sharedFoldersAddTitle')"
        :visible.sync ="dialogAddShareFolder"
        width="500px"
        :append-to-body="true"
    >
        <el-form class="form-wrap">
            <div class="wrap-tree" v-if="dialogAddShareFolder">
                <TreeFolderShare ref="treeFolderShare" :defaultType="defaultType"></TreeFolderShare>
            </div>
            <el-row :gutter="20" style="padding-top: 10px;padding-bottom: 10px;">
                <el-col :span="5">
                    <div style="padding-top: 5px; width: 100px;">{{$t('zimbra.zmMsg.sharedFoldersFindLabel')}}</div>
                </el-col>
                <el-col :span="12">
                    <el-tooltip :open-delay="1000" :content="$t('zimbra.zmMsg.sharedCalUserNameHint')" placement="bottom" effect="light">
                        <BaseAddressSelector
                            v-model="listEmailSearch"
                            :multiple="true"
                            class="select-input-wrap"
                            style="max-width: 250px"
                            size="medium"
                        />
                    </el-tooltip>
                </el-col>
                <el-col :span="1">
                    <el-button @click="searchByEmail" :disabled="!listEmailSearch.length">
                        {{ $t("zimbra.zhMsg.search") }}
                    </el-button>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="5">
                    <div style="padding-top:5px; width: 100px;">{{$t('zimbraNokey.zimbra_sidebar_dialogAddShare_include')}}</div>
                </el-col>
                <el-col :span="16">
                    <el-select v-model="option" :placeholder="$t('zimbra.zmMsg.sharedCalUserNameHint')" @change="handleChange" style="max-width: 250px">
                        <el-option
                        v-for="item in listOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogAddShareFolder = false">{{
                $t("zimbra.zhMsg.actionTaskCancelTT")
            }}</el-button>
            <el-button type="primary" @click="createMountpoint">{{
                $t("zimbra.zhMsg.add")
            }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import BaseAddressSelector from "@/components/modules/base/base-address-selector/BaseAddressSelector";
import TreeFolderShare from "./TreeFolderShare.vue";
import CommonUtils from "@/utils/common-utils";
import ContactUtils from "@/utils/contact-utils";
import {FOLDER_VIEW} from "@/utils/constants";
import CompileService from "@/services/CompileService.js";

export default {
    mixins: [CommonUtils, ContactUtils],
    mounted() {
        this.$root.$on("showAddShareFolder", () => {
            this.show();
        });
    },
    components: {
        TreeFolderShare,
        BaseAddressSelector,
    },
    props: {
        defaultType: {
            type: String,
            default: function () {
                return FOLDER_VIEW.MESSAGE;
            }
        }
    },
    data() {
        return {
            dialogAddShareFolder: false,
            listEmailSearch: [],
            listOption: [
                {label: this.$t("zimbra.zmMsg.allApplications"), value: 0},
                {label: this.$t("zimbra.zmMsg.mailSharesOnly"), value: FOLDER_VIEW.MESSAGE},
                {label: this.$t("zimbra.zmMsg.addrbookSharesOnly"), value: FOLDER_VIEW.CONTACT},
                {label: this.$t("zimbra.zmMsg.calendarSharesOnly"), value: FOLDER_VIEW.APPOINTMENT},
                // {label: this.$t("zimbra.zmMsg.taskSharesOnly"), value: FOLDER_DEFAULT.TASK},
                {label: this.$t("zimbra.zmMsg.briefcaseSharesOnly"), value: FOLDER_VIEW.DOCUMENT},
            ],
            option: this.defaultType
        };
    },
    methods: {
        show() {
            this.dialogAddShareFolder = true;
            this.listEmailSearch = [];
        },
        handleChange() {
            this.$refs.treeFolderShare.changeData(this.option);
        },
        searchByEmail() {
            this.$refs.treeFolderShare.searchByEmail(this.listEmailSearch);
        },
        async createMountpoint() {
            this.dialogAddShareFolder = false;
            let stringXml = [];
            const listDataCheck = this.$refs.treeFolderShare.listDataCheck;
            if (listDataCheck.length > 0) {
                listDataCheck.forEach(x => {
                    const index = x.name.lastIndexOf('/');
                    const nameFolder = index == -1 ? x.name : x.name.substring(index + 1);
                    const link = {
                        name: `${x.ownerName}’ ${nameFolder}`,
                        rid: `${x.folderId}`,
                        view: `${x.view}` ,
                        zid: `${x.ownerId}`,
                        l: "1"
                    };
                    stringXml.push({
                        path: "createMountpointRequest",
                        content: JSON.stringify(link),
                    });
                });
            }
            const formData = {
                listRequest: stringXml,
            };
            const request = await CompileService.batchRequest(formData);
            const dataRequest = request.data.data;
            let listLink = [];
            dataRequest.forEach((item) => {
                const data = JSON.parse(item);
                if (data.CreateMountpointResponse && data.CreateMountpointResponse.link) {
                    listLink.push(data.CreateMountpointResponse.link);
                }
            });
            if (listLink.length > 0) {
                listLink.forEach(x => {
                    x.isLink = true;
                    x.u = x.u || 0;
                    x.n = x.n || 0;
                    if (x.view == "message") {
                        this.$root.$emit("addItemToDataInBox", x);
                    } else if (x.view == "contact") {
                        this.dataSidebarContact[0].folder.push(x);
                        this.dataSidebarContact[0].folder = this.reorderFolder(this.dataSidebarContact[0].folder);
                    } else if (x.view == "document") {
                        this.$root.$emit("onInnitBriefcaseTree")
                    }
                });

            }
        }
    },
};
</script>
