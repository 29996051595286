import BaseService from "../shared/services/BaseService";
import CONFIG from '@/config/config';
import axios from "axios";
class ShareService extends BaseService {
    url = CONFIG.ENDPOINT['compileService'];
    exUrl = CONFIG.ENDPOINT['importExportService']
    constructor() {
        super("zimbraService");
    }
    encodedStr(unsafe) {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }
    autoCompleteRequest(form) {
        const url = `${this.baseURL}/autoCompleteRequest`;
        return this.postRequest(url, form);
    }
    getShareInfoRequest(form) {
        const url = `${this.baseURL}/getShareInfoRequest`;
        return this.postRequest(url, form);
    }
    createMountpointRequest(form) {
        const url = `${this.baseURL}/createMountpointRequest`;
        return this.postRequest(url, form);
    }
    sendMsgRequest(form) {
        const url = `${this.baseURL}/sendMsgRequest`;
        return this.postRequest(url, form);
    }
    getFolderRequest(form) {
        const url = `${this.baseURL}/getFolderRequest`;
        return this.postRequest(url, form);
    }
    folderActionRequest(form) {
        const url = `${this.baseURL}/folderActionRequest`;
        return this.postRequest(url, form);
    }
    sendShareNotificationRequest(form) {
        const url = `${this.baseURL}/sendShareNotificationRequest`;
        return this.postRequest(url, form);
    }
    exportFile(formData) {
        const url = `${this.exUrl}/${formData.username}/${formData.file}`;
        // document.cookie = `ZM_TEST=true;ZM_AUTH_TOKEN=${this.getAuthToken()}`;
        const config = {
            timeout: this.$configZimbra.TIMEOUT_REQUEST,
            headers: {
                "X-Gravitee-Api-Key": CONFIG.X_GRAVITEE_API_KEY_SERVICE_IMPORT_EXPORT,
                "X-Cookie": `ZM_AUTH_TOKEN=${this.getAuthToken()}`,
                "Access-Control-Allow-Credentials": true,
                "Cache-Control": "no-cache",
            },
            responseType: 'blob',
            validateStatus: function (status) {
                return status >= 200 && status < 500; // default
            },
            withCredentials: true,
            // exposedHeaders: ["set-cookie"],
        }
        const instanceService = axios.create(config);
        return instanceService.get(url, null, config);
    }
    getAuthToken() {
        const store = localStorage.getItem('zimbra_token');
        if (store != null) {
            const zimbraToken = JSON.parse(store);
            return zimbraToken.auth_token || '';
        }
        return '';
    }
}
export default new ShareService();
