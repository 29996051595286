const briefcaseModule = {
    state: {
        nodeBriefcaseCurrent: null,
        nodeBriefcaseExtraCurrent: null,
        briefcaseTreeFolders: [],
        listBriefcaseSearch: [],
        listSelectedBriefcase: [],
        currentExtraActiveBriefcase: null,
        currentActiveBriefcase: null,
        briefcaseFolderWraper: [],
        lstBriefcaseQuerySearch: [],
    },
    getters: {
        nodeBriefcaseCurrent: state => state.nodeBriefcaseCurrent,
        nodeBriefcaseExtraCurrent: state => state.nodeBriefcaseExtraCurrent,
        briefcaseTreeFolders: state => state.briefcaseTreeFolders,
        listBriefcaseSearch: state => state.listBriefcaseSearch,
        listSelectedBriefcase: state => state.listSelectedBriefcase,
        currentExtraActiveBriefcase: state => state.currentExtraActiveBriefcase,
        currentActiveBriefcase: state => state.currentActiveBriefcase,
        briefcaseFolderWraper: state => state.briefcaseFolderWraper,
        lstBriefcaseQuerySearch: state => state.lstBriefcaseQuerySearch
    },
    mutations: {
        SET_LIST_BRIEFCASE(state, data) {
            state.listBriefcaseSearch = data
        },
        SET_NODE_BRIEFCASE_CURRENT(state, data) {
            state.nodeBriefcaseCurrent = data
        },
        SET_NODE_BRIEFCASE_EXTRA_CURRENT(state, data) {
            state.nodeBriefcaseExtraCurrent = data
        },
        SET_BRIEFCASES_FOLDER(state, data) {
            state.briefcaseTreeFolders = data
        },
        SET_LIST_SELECTED_BRIEFCASE(state, data) {
            state.listSelectedBriefcase = data
        },
        SET_ACTIVE_BRIEFCASE_EXTRA(state, data) {
            state.currentExtraActiveBriefcase = data
        },
        SET_CURRENT_ACTIVE_BRIEFCASE(state, data) {
            state.currentActiveBriefcase = data
        },
        SET_WRAPER_BRIEFCASE(state, data) {
            state.briefcaseFolderWraper = data
        },
        SET_LST_BRIEFCASE_QUERY_SEARCH(state, data) {
            state.lstBriefcaseQuerySearch = data
        },
    }
}
export default briefcaseModule