<template>
    <el-dialog
        :title="$t('zimbra.zmMsg.sendUpdateTitle')"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        :destroy-on-close="true"
        class="zimbra-dialog zm-calendar"
        width="450px"
    >
        <div class="event-info">
            <div>{{ $t('zimbra.zmMsg.attendeeListChanged') }}</div>
            <table class="ml-3">
                <tr v-if="addedEmails">
                    <td><b class="pr-2">{{ $t('zimbra.zmMsg.added') }}</b>{{ addedEmails }}</td>
                </tr>
                <tr v-if="deletedEmails">
                    <td><b class="pr-2">{{ $t('zimbra.zmMsg.removed') }}</b>{{ deletedEmails }}</td>
                </tr>
            </table>
            <div>
                <el-radio-group
                    class="radio-control pt-3"
                    v-model="confirmListChanged"
                >
                    <el-radio
                        class="radio-item w-100 pb-2"
                        :label="SEND_UPDATES.NEW"
                    >
                        {{$t("zimbra.zmMsg.sendUpdatesNew")}}
                    </el-radio>
                    <el-radio
                        class="radio-item w-100 pb-2"
                        :label="SEND_UPDATES.ALL"
                    >
                        {{$t("zimbra.zmMsg.sendUpdatesAll")}}
                    </el-radio>
                </el-radio-group>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button
                class="btn-cancel"
                @click="_cancel"
            >
                {{ $t("zimbra.zhMsg.actionTaskCancelTT") }}
            </el-button>
            <el-button
                type="primary"
                class="btn-calendar"
                @click="_confirm"
            >
                {{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
/**
 * Dialog confirm Gửi cập nhật tới người tham dự
 */
import {
    SEND_UPDATES
} from "@/utils/constants.js";
export default {
    name: "DialogConfirmChangeAttend",
    data() {
        return {
            dialogVisible: false,
            title: "",
            SEND_UPDATES: SEND_UPDATES,
            addedEmails: "",
            deletedEmails: "",
            resolvePromise: undefined,
            rejectPromise: undefined,
            confirmListChanged: SEND_UPDATES.NEW
        }
    },
    methods: {
        show(listAdd, listDelete) {
            this.dialogVisible = true;
            if (listAdd) {
                this.addedEmails = listAdd.map(e => e.a).join(", ");
            }
            if (listDelete) {
                this.deletedEmails = listDelete.map(e => e.a).join(", ");
            }
             return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },
        _confirm() {
            this.dialogVisible = false;
            this.resolvePromise({ confirm: true, confirmListChanged: this.confirmListChanged });
        },
        _cancel() {
            this.dialogVisible = false;
            this.resolvePromise({ confirm: false, confirmListChanged: this.confirmListChanged })
            // Or you can throw an error
            // this.rejectPromise(new Error('User cancelled the dialogue'))
        },
    }
}
</script>