import BaseService from "@/shared/services/BaseService";
import { READ_EMAIL_LAYOUT, PATH_REQUEST_SETTING } from '@/utils/constants';
import CONFIG from '@/config/config';
import _ from "lodash";
import $ from 'jquery';

const fontSizes = "8pt 9pt 10pt 11pt 12pt 13pt 14pt 16pt 18pt 24pt 36pt 48pt";

const a = [];
a["Etc/GMT+12"] = "GMT -12:00 Dateline";
a["Pacific/Midway"] = "GMT -11:00 Samoa";
a["America/Adak"] = "GMT -10:00 Adak";
a["Pacific/Honolulu"] = "GMT -10:00 Hawaii";
a["Pacific/Marquesas"] = "GMT -09:30 Marquesas";
a["America/Anchorage"] = "GMT -09:00 Alaska";
a["America/Los_Angeles"] = "GMT -08:00 US/Canada Pacific";
a["America/Tijuana"] = "GMT -08:00 Baja California";
a["America/Chihuahua"] = "GMT -07:00 Chihuahua, La Paz, Mazatlan";
a["America/Denver"] = "GMT -07:00 US/Canada Mountain";
a["America/Fort_Nelson"] = "GMT -07:00 Fort Nelson";
a["America/Phoenix"] = "GMT -07:00 Arizona";
a["America/Chicago"] = "GMT -06:00 US/Canada Central";
a["America/Guatemala"] = "GMT -06:00 Trung M\u1ef9";
a["America/Mexico_City"] = "GMT -06:00 Guadalajara, Mexico City, Monterrey";
a["America/Regina"] = "GMT -06:00 Saskatchewan";
a["Pacific/Easter"] = "GMT -06:00 Easter";
a["America/Bogota"] = "GMT -05:00 Colombia";
a["America/Cancun"] = "GMT -05:00 Cancun, Chetumal";
a["America/Havana"] = "GMT -05:00 Havana";
a["America/Indiana/Indianapolis"] = "GMT -05:00 Indiana (East)";
a["America/New_York"] = "GMT -05:00 US/Canada Eastern";
a["America/Port-au-Prince"] = "GMT -05:00 Port-au-Prince";
a["America/Asuncion"] = "GMT -04:00 Asuncion";
a["America/Caracas"] = "GMT -04:00 Caracas";
a["America/Cuiaba"] = "GMT -04:00 Cuiaba";
a["America/Grand_Turk"] = "GMT -04:00 Turks and Caicos Islands";
a["America/Guyana"] = "GMT -04:00 Georgetown, La Paz, Manaus, San Juan";
a["America/Halifax"] = "GMT -04:00 Gi\u1edd \u0110\u1ea1i T\u00e2y D\u01b0\u01a1ng (Canada)";
a["America/Santiago"] = "GMT -04:00 Nam M\u1ef9 Th\u00e1i B\u00ecnh D\u01b0\u01a1ng";
a["America/St_Johns"] = "GMT -03:30 Newfoundland";
a["America/Araguaina"] = "GMT -03:00 Araguaina";
a["America/Argentina/Buenos_Aires"] = "GMT -03:00 \u00c1c-hen-ti-na";
a["America/Bahia"] = "GMT -03:00 Salvador";
a["America/Cayenne"] = "GMT -03:00 Cayenne, Fortaleza";
a["America/Godthab"] = "GMT -03:00 Greenland";
a["America/Miquelon"] = "GMT -03:00 Miquelon";
a["America/Montevideo"] = "GMT -03:00 Montevideo";
a["America/Punta_Arenas"] = "GMT -03:00 Punta_Arenas";
a["America/Sao_Paulo"] = "GMT -03:00 Brasilia";
a["Atlantic/South_Georgia"] = "GMT -02:00 Trung-\u0110\u1ea1i T\u00e2y D\u01b0\u01a1ng";
a["Atlantic/Azores"] = "GMT -01:00 Azores";
a["Atlantic/Cape_Verde"] = "GMT -01:00 Cape Verde Is.";
a["Africa/Casablanca"] = "GMT +00:00 Casablanca";
a["Africa/Monrovia"] = "GMT +00:00 Monrovia";
a["Europe/London"] = "GMT +00:00 Anh, Ai-len, B\u1ed3 \u0110\u00e0o Nha";
a.UTC = "Gi\u1edd ph\u1ed1i h\u1ee3p qu\u1ed1c t\u1ebf GMT/UTC";
a["Africa/Algiers"] = "GMT +01:00 Trung T\u00e2y Phi";
a["Africa/Windhoek"] = "GMT +01:00 Namibia";
a["Europe/Belgrade"] = "GMT +01:00 Belgrade, Bratislava, Budapest, Ljubljana, Prague";
a["Europe/Berlin"] = "GMT +01:00 Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna";
a["Europe/Brussels"] = "GMT +01:00 Brussels, Copenhagen, Madrid, Paris";
a["Europe/Warsaw"] = "GMT +01:00 Sarajevo, Skopje, Warsaw, Zagreb";
a["Africa/Cairo"] = "GMT +02:00 Ai C\u1eadp";
a["Africa/Harare"] = "GMT +02:00 Harare, Pretoria";
a["Africa/Tripoli"] = "GMT +02:00 Tripoli";
a["Asia/Amman"] = "GMT +02:00 Jordan";
a["Asia/Beirut"] = "GMT +02:00 Beirut";
a["Asia/Damascus"] = "GMT +02:00 Damascus";
a["Asia/Gaza"] = "GMT +02:00 Gaza";
a["Asia/Jerusalem"] = "GMT +02:00 Jerusalem";
a["Europe/Athens"] = "GMT +02:00 Athens, Beirut, Bucharest, Istanbul";
a["Europe/Bucharest"] = "GMT +02:00 Bucharest";
a["Europe/Chisinau"] = "GMT +02:00 Chisinau";
a["Europe/Helsinki"] = "GMT +02:00 Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius";
a["Europe/Kaliningrad"] = "GMT +02:00 Kaliningrad";
a["Africa/Nairobi"] = "GMT +03:00 Nairobi";
a["Asia/Baghdad"] = "GMT +03:00 Iraq";
a["Asia/Kuwait"] = "GMT +03:00 C\u00f4-o\u00e9t, Riyadh";
a["Europe/Istanbul"] = "GMT +03:00 Istanbul";
a["Europe/Minsk"] = "GMT +03:00 Minsk";
a["Europe/Moscow"] = "GMT +03:00 Moscow, St.Petersburg, Volgograd";
a["Asia/Tehran"] = "GMT +03:30 Tehran";
a["Asia/Baku"] = "GMT +04:00 Baku";
a["Asia/Muscat"] = "GMT +04:00 Abu Dhabi, Muscat";
a["Asia/Tbilisi"] = "GMT +04:00 Tbilisi";
a["Asia/Yerevan"] = "GMT +04:00 Yerevan";
a["Europe/Astrakhan"] = "GMT +04:00 Astrakhan";
a["Europe/Samara"] = "GMT +04:00 Izhevsk, Samara (RTZ 3)";
a["Europe/Saratov"] = "GMT +04:00 Saratov";
a["Indian/Mauritius"] = "GMT +04:00 Port Louis";
a["Asia/Kabul"] = "GMT +04:30 Kabul";
a["Asia/Karachi"] = "GMT +05:00 Islamabad, Karachi";
a["Asia/Tashkent"] = "GMT +05:00 Tashkent";
a["Asia/Yekaterinburg"] = "GMT +05:00 Yekaterinburg";
a["Asia/Colombo"] = "GMT +05:30 Sri Jayawardenepura";
a["Asia/Kolkata"] = "GMT +05:30 Chennai, Kolkata, Mumbai, New Delhi";
a["Asia/Kathmandu"] = "GMT +05:45 Kathmandu";
a["Asia/Katmandu"] = "GMT +05:45 Kathmandu";
a["Asia/Almaty"] = "GMT +06:00 Astana";
a["Asia/Dhaka"] = "GMT +06:00 Dhaka";
a["Asia/Omsk"] = "GMT +06:00 Omsk";
a["Asia/Yangon"] = "GMT +06:30 Yangon";
a["Asia/Bangkok"] = "GMT +07:00 Bangkok, H\u00e0 N\u1ed9i, Jakarta";
a["Asia/Barnaul"] = "GMT +07:00 Barnaul";
a["Asia/Hovd"] = "GMT +07:00 Hovd";
a["Asia/Krasnoyarsk"] = "GMT +07:00 Krasnoyarsk";
a["Asia/Novosibirsk"] = "GMT +07:00 Novosibirsk";
a["Asia/Tomsk"] = "GMT +07:00 Tomsk";
a["Asia/Hong_Kong"] = "GMT +08:00 B\u1eafc Kinh, Tr\u00f9ng Kh\u00e1nh, H\u1ed3ng K\u00f4ng, T\u00e2n C\u01b0\u01a1ng";
a["Asia/Irkutsk"] = "GMT +08:00 Irkutsk";
a["Asia/Kuala_Lumpur"] = "GMT +08:00 Kuala Lumpur, Singapore";
a["Asia/Singapore"] = "GMT +08:00 Singapore";
a["Asia/Taipei"] = "GMT +08:00 \u0110\u00e0i B\u1eafc";
a["Asia/Ulaanbaatar"] = "GMT +08:00 Ulaanbaatar";
a["Australia/Perth"] = "GMT +08:00 Perth";
a["Asia/Pyongyang"] = "GMT +08:30 Pyongyang";
a["Australia/Eucla"] = "GMT +08:45 Eucla";
a["Asia/Chita"] = "GMT +09:00 Chita";
a["Asia/Seoul"] = "GMT +09:00 Tri\u1ec1u Ti\u00ean";
a["Asia/Tokyo"] = "GMT +09:00 Nh\u1eadt B\u1ea3n";
a["Asia/Yakutsk"] = "GMT +09:00 Yakutsk";
a["Australia/Adelaide"] = "GMT +09:30 Adelaide";
a["Australia/Darwin"] = "GMT +09:30 Darwin";
a["Asia/Vladivostok"] = "GMT +10:00 Vladivostok";
a["Australia/Brisbane"] = "GMT +10:00 Brisbane";
a["Australia/Hobart"] = "GMT +10:00 Hobart";
a["Australia/Sydney"] = "GMT +10:00 Canberra, Melbourne, Sydney";
a["Pacific/Guam"] = "GMT +10:00 Guam, Port Moresby";
a["Australia/Lord_Howe"] = "GMT +10:30 Lord_Howe";
a["Asia/Magadan"] = "GMT +11:00 Magadan";
a["Asia/Sakhalin"] = "GMT +11:00 Sakhalin";
a["Asia/Srednekolymsk"] = "GMT +11:00 Chokurdakh (RTZ 10)";
a["Pacific/Bougainville"] = "GMT +11:00 Bougainville Standard Time";
a["Pacific/Guadalcanal"] = "GMT +11:00 \u0110\u1ea3o Solomon / New Caledonia";
a["Pacific/Norfolk"] = "GMT +11:00 Norfolk";
a["Asia/Kamchatka"] = "GMT +12:00 Anadyr, Petropavlovsk-Kamchatsky (RTZ 11)";
a["Pacific/Auckland"] = "GMT +12:00 New Zealand";
a["Pacific/Fiji"] = "GMT +12:00 Fiji";
a["Pacific/Chatham"] = "GMT +12:45 Chatham";
a["Pacific/Apia"] = "GMT +13:00 Samoa";
a["Pacific/Tongatapu"] = "GMT +13:00 Nuku\u2019alofa";
a["Pacific/Kiritimati"] = "GMT +14:00 Kiritimati Island";

class GeneralService extends BaseService {
    url = CONFIG.ENDPOINT['compileService'];
    urlService = CONFIG.ENDPOINT['zimbraService'];

    constructor() {
        super("zimbraService");
    }


    handleJsonToString(obj) {
        return JSON.stringify(obj);
    }

    getAll() {
        const listRequest = {"xml": ""};
        return this.postRequest(this.urlService + "/getPrefsRequest", listRequest );
        //const listRequest = PATH_REQUEST_SETTING.map(el => ({ path: el, content: el === "getPrefsRequest" ? this.handleJsonToString({ xml: "" }) : `` }));
        // return this.postRequest(this.url, { listRequest });
    }

    saveSetting(data) {
        // let xmlStr = "";
        // data.forEach(item => {
        //     xmlStr += `<urn1:pref name='${item.name}'>${typeof item.content === "boolean"
        //         ? item.content.toString().toUpperCase()
        //         : item.content
        //         }</urn1:pref>`;
        // });
        // return this.postRequest(this.url, {
        //     listRequest: [
        //         {
        //             path: "modifyPrefsRequest",
        //             content: this.handleJsonToString({
        //                 xml: xmlStr.trim()
        //             })
        //         }
        //     ]
        // });

        let xmlStr = "";
        data.forEach(item => {
            xmlStr += `<urn1:pref name='${item.name}'>${typeof item.content === "boolean"
                ? item.content.toString().toUpperCase()
                : item.content
                }</urn1:pref>`;
        });
        let request = {"xml": xmlStr}
        return this.postRequest(this.urlService + "/modifyPrefsRequest", request);

    }

    setSkinRequest(data) {
        const request = { "xml": `<urn1:pref name='${data.name}'>${data.content}</urn1:pref>`};
        return this.postRequest(this.urlService + "/modifyPrefsRequest", request);
    }

    setFontRequest(data) {
        // const request = {
        //     listRequest: [
        //         {
        //             path: "modifyPrefsRequest",
        //             content: this.handleJsonToString({
        //                 xml: `<urn1:pref name='${data.name}'>${data.content}</urn1:pref>`
        //             })
        //         }
        //     ]
        // };
        // return this.postRequest(this.url, request);
        const request = { "xml": `<urn1:pref name='${data.name}'>${data.content}</urn1:pref>`};
        return this.postRequest(this.urlService + "/modifyPrefsRequest", request);
    }

    setFontSizeRequest(data) {
        // const request = {
        //     listRequest: [
        //         {
        //             path: "modifyPrefsRequest",
        //             content: this.handleJsonToString({
        //                 xml: `<urn1:pref name='${data.name}'>${data.content}</urn1:pref>`
        //             })
        //         }
        //     ]
        // };
        // return this.postRequest(this.url, request);
        const request = { "xml": `<urn1:pref name='${data.name}'>${data.content}</urn1:pref>`};
        return this.postRequest(this.urlService + "/modifyPrefsRequest", request);
    }

    setTimeZoneRequest(data) {
        // const request = {
        //     listRequest: [
        //         {
        //             path: "modifyPrefsRequest",
        //             content: this.handleJsonToString({
        //                 xml: `<urn1:pref name='${data.name}'>${data.content}</urn1:pref>`
        //             })
        //         }
        //     ]
        // };
        // return this.postRequest(this.url, request);
        const request = { "xml": `<urn1:pref name='${data.name}'>${data.content}</urn1:pref>`};
        return this.postRequest(this.urlService + "/modifyPrefsRequest", request);
    }

    setLocaleRequest(data) {
        // const request = {
        //     listRequest: [
        //         {
        //             path: "modifyPrefsRequest",
        //             content: this.handleJsonToString({
        //                 xml: `<urn1:pref name='${data.name}'>${data.content}</urn1:pref>`
        //             })
        //         }
        //     ]
        // };
        // return this.postRequest(this.url, request);
        const request = { "xml": `<urn1:pref name='${data.name}'>${data.content}</urn1:pref>`};
        return this.postRequest(this.urlService + "/modifyPrefsRequest", request);
    }

    getPrefsRequest(data) {
        return this.postRequest(this.url, data);
    }

    // Tim kiem time zone
    findTimeZoneByKey(key) {
        let res = "";
        for (let prop in a) {
            // do stuff
            if (prop === key) {
                res = a[key]
            }
        }
        return res;
    }

    convertDataZimbra() {
        const lstObj = [];
        for (let prop in a) {
            lstObj.push({
                id: prop,
                name: a[prop],
                hours: this.getHours(a[prop]),
                minute: this.getMinutes(a[prop]),
            });
        }
        return _.orderBy(lstObj, ["hours", "minute"], ["asc", "asc"]);
    }

    getHours(value) {
        if (!value) {
            return Number.MAX_SAFE_INTEGER;
        }
        const regex = /[+-]\d{1,2}/i;
        const match = value.match(regex);
        if (!match || match.length === 0) {
            return 0;
        }
        return parseInt(match[0], 10);
    }

    getMinutes(value) {
        if (!value) {
            return Number.MAX_SAFE_INTEGER;
        }
        const regex = /:\d{1,2}/i;
        const match = value.match(regex)
        if (!match || match.length === 0) {
            return 1;
        }
        return parseInt(match[0].substring(1, match[0].length), 10);
    }

    convertFontPrintSize() {
        const fontSizesList = fontSizes.split(" ");
        const lstObj = [];
        fontSizesList.forEach((value, i) => {
            // do stuff
            lstObj.push({
                id: i,
                name: value
            });
        });
        return lstObj;
    }

    setI18nNameByName(value) {
        const a = {};
        a["theme-bare"] = "zimbra.zhMsg.SKIN_bare";
        a["theme-beach"] = "zimbra.zhMsg.SKIN_beach";
        a["theme-carbon"] = "zimbra.zhMsg.SKIN_carbon";
        a["theme-bones"] = "zimbra.zhMsg.SKIN_bones";
        // a["theme-froggy"] = "\u00c9ch nh\u00e1i";
        // a["theme-harvest"] = "M\u00f9a v\u1ee5";
        // a["theme-lake"] = "H\u1ed3";
        // a["theme-harmony"] = "H\u00f2a \u00e2m";
        // a["theme-hotrod"] = "Xe c\u1ea3i ti\u1ebfn";
        // a["theme-lavender"] = "Hoa o\u1ea3i h\u01b0\u01a1ng";
        // a["theme-lemongrass"] = "C\u1ecf chanh";
        // a["theme-oasis"] = "\u1ed0c \u0111\u1ea3o";
        // a["theme-pebble"] = "\u0110\u00e1 s\u1ecfi";
        // a["theme-sand"] = "C\u00e1t";
        // a["theme-serenity"] = "S\u1ef1 thanh b\u00ecnh";
        // a["theme-smoke"] = "Kh\u00f3i";
        // a["theme-sky"] = "B\u1ea7u tr\u1eddi";
        // a["theme-steel"] = "Th\u00e9p";
        // a["theme-tree"] = "C\u00e2y";
        // a["theme-twilight"] = "Ch\u1ea1ng v\u1ea1ng";
        // a["theme-vanilla"] = "Va-ni";
        // a["theme-venice"] = "Venice";
        // a["theme-waves"] = "S\u00f3ng";
        return this.getKeyByValue(a, `theme-${value}`);
    }

    getKeyByValue(obj, value) {
        const name = Object.keys(obj).find(key => key === value);
        return obj[name];
    }

    afterSetSkin(oldValue, value, panel) {
        this.removeClassEndWith($('body'), '-skin');
        if(panel == READ_EMAIL_LAYOUT.RIGHT) {
            document.body.className += ` ${value}-skin`;
        }
        else if (panel == READ_EMAIL_LAYOUT.HIDE) {

            document.body.className += ` ${value}-vmail-skin`;
        }
    }

    removeClassEndWith(node, end) {
        node.removeClass(function (index, className) {
            return (className.match(new RegExp("\\S*" + end + "\\S*", 'g')) || []).join(' ')
        });
    }

    removeClassStartingWith(node, begin) {
        node.removeClass(function (index, className) {
            return (className.match(new RegExp("\\b" + begin + "\\S+", "g")) || []).join(' ');
        });
    }

    afterSetFontFamily(oldValue, value) {
        this.removeClassStartingWith($('body'), 'user_font_');
        document.body.className += ` user_font_${value}`;
    }
}

export default new GeneralService();
