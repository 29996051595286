<template>
    <!-- dialog tạo thư mục danh bạ -->
    <el-dialog
        class="dialog-body-wrap zimbra-dialog d-center"
        :title="!isBriefCase ? $t('zimbra.zmMsg.createNewAddrBook') : $t('zimbra.zmMsg.createNewBriefcaseItem')"
        :visible.sync="dialogAddFolderContact"
        width="30%"
        custom-class="dialog-add-folder mw__450"
        height="550px"
        :append-to-body="true"
        :close-on-click-modal="false"
        @open="setCurrentKeyRoot"
    >
        <el-form class="form-wrap">
            <el-form-item :label="$t('zimbra.zhMsg.nameLabel')" label-width="90px">
                <el-input
                    class="el-text-control"
                    v-model="formAdd.name"
                    autocomplete="off"
                    auto-complete="off"
                    ref="refInputName"
                    id="input-root-name-contact-folder"
                    size="medium"
                    @keypress.enter.native="addNewFolder"
                >
                </el-input>
            </el-form-item>
            <el-form-item
                :label="$t('zimbra.zmMsg.colorLabel')"
                label-width="90px"
            >
                <zm-color-picker
                    v-model="formAdd.rgb"
                    :visible="false"
                    :showFooter="true"
                >
                </zm-color-picker>
            </el-form-item>
            <div style="padding-bottom: 10px; padding-top: 10px">
                <span class="zimbra-label">{{
                    $t("zimbra.zmMsg.newFolderParent")
                }}</span>
            </div>
            <div class="form__body wrap-tree">
                <div class="ps-align">
                    <tree-briefcase-extra
                        ref="treeFolderExtra"
                        v-if="isBriefCase"
                        :listIdFolderHidden="[FOLDER_DEFAULT.TRASH]"
                        :isMoveBriefcase="false"
                        :showInputSearch="false"
                        :isAddFolder="true"
                    />
                    <div style="height: 300px" v-else>
                        <TreeBasicFolder
                            ref="treeFolderExtra"
                            :showInputSearch="showInputSearch"
                            :isRootClick="isRootClick"
                            :isMoveMail="isMoveMail"
                        ></TreeBasicFolder>
                    </div>
                </div>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">{{
                $t("zimbra.zhMsg.actionTaskCancelTT")
            }}</el-button>
            <el-button type="primary" @click="addNewFolder">{{
                $t("zimbra.zmMsg.mobileStatusNeedProvision")
            }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import CommonUtils from "@/utils/common-utils"
import ContactUtils from "@/utils/contact-utils"
import color from "@/utils/color/Color"
import { mapGetters } from "vuex"
import TreeBasicFolder from "@/components/modules/contacts/components/layouts/TreeBasicFolder.vue"
import { FOLDER_DEFAULT, STATUS_REQUEST, ERROR_CODE, FOLDER_VIEW, BRIEFCASE_ACTION } from "@/utils/constants"
import ZimbraMailService from "@/services/ZimbraMailService.js";
import _ from "lodash";
// import $ from 'jquery'
import ZmColorPicker from "@/components/modules/base/base-color-picker-advance/zm-color-picker"
import hotkeys from "hotkeys-js"
import TreeBriefcaseExtra from "@/components/modules/briefcase/components/TreeBriefcaseExtra.vue"

export default {
    mixins: [CommonUtils, ContactUtils],
    components: {
        "zm-color-picker": ZmColorPicker,
        TreeBasicFolder,
        TreeBriefcaseExtra
    },
    props: {
        isBriefCaseSelector: { //bỏ drop tree briefcase
            type: Boolean,
            default: true,
        },
        removeDataTrash: { //bỏ thùng rác tree briefcase
            type: Boolean,
            default: true,
        },
        showInputSearch: {
            type: Boolean,
            default: false,
        },
        isRootClick: {
            type: Boolean,
            default: true,
        },
        isMoveMail: {
            type: Boolean,
            default: false,
        },
        isAddFolder: {
            type: Boolean,
            default: true,
        },
    },
    mounted() {
        this.$root.$on("handleAddFolderContact", (typeAdd, move) => {
            this.show(typeAdd, move);
            this.$nextTick(() => {
                this.$refs.refInputName && this.$refs.refInputName.focus()
            })
        });

        /*
        * Phim tat enter
        */
        hotkeys("enter", this.handleKeyAddFolder)
    },
    computed: {
        ...mapGetters(["dataSidebarContact", "activeFolderExtra", "briefcaseTreeFolders", "activeNodeBriefcase"]),
    },
    data() {
        return {
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            formAdd: {
                name: "",
                color: null,
                parent: {},
                rgb: "#3F4254",
            },
            dialogAddFolderContact: false,
            listColor: color,
            objectColor: { value: "-1", label: "Không" },
            isBriefCase: false,
            moveToFolder: false,
            dataSidebar: []
        };
    },
    methods: {
        /*
        * Phim tat enter
        */
       handleKeyAddFolder(e) {
           if(this.dialogAddFolderContact ) {
               e.preventDefault()
               this.addNewFolder()
           }
       },
        async show(typeAdd, move) {
            await this.getFolderContactApi();
            this.dataSidebar = []
            this.isBriefCase = typeAdd;
            this.dialogAddFolderContact = true;
            this.formAdd = {
                name: "",
                parent: {},
                rgb: "#3F4254",
            };
            this.dataSidebar = this.dataSidebarContact?.length ? this.dataSidebarContact : this.briefcaseTreeFolders
            // Focus Tên thư mục
            // $(document).ready(function () {
            //     setTimeout(function () {
            //         $("#input-root-name-contact-folder").focus();
            //     }, 500);
            // });
            if (move) {
                this.moveToFolder = move
            } else {
                this.moveToFolder = false
            }
            // this.$root.$emit("setCurrentKey", null);
            // setTimeout(() => {
            //     this.$refs.treeFolderExtra.isMoveMail = false;
            // }, 500);
        },
        checkExistsName(name, folderParent) {
            if (folderParent.id == FOLDER_DEFAULT.ROOT) {
                if (
                    this.dataSidebar
                        .map((x) => x.name.toString().toLowerCase())
                        .indexOf(name.toString().toLowerCase()) > -1
                ) {
                    this.$alert(
                        this.$t("zimbra.zmMsg.errorAlreadyExists", {
                            1: this.$t("zimbra.zhMsg.ALT_FOLDER"),
                            0: name,
                        }),
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return true;
                }
            }
            if (Array.isArray(folderParent.folder)) {
                if (folderParent.folder.map((x) => x.name.toString().toLowerCase()).indexOf(name.toString().toLowerCase()) > -1) {
                    this.$alert(
                        this.$t("zimbra.zmMsg.errorAlreadyExists", {
                            1: this.$t("zimbra.zhMsg.ALT_FOLDER"),
                            0: name,
                        }),
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return true;
                }
            }
        },
        addNewFolder() {
            this.formAdd.parent = this.$store.getters.activeFolderExtra
                ? this.$store.getters.activeFolderExtra
                : { id: FOLDER_DEFAULT.ROOT };
            if (this.checkExistsName(this.formAdd.name, this.formAdd.parent)) {
                return;
            }
            this.formAdd.name = this.formAdd.name.trim();
            // validateName
            if (
                this.formAdd.name === "" ||
                this.formAdd.name.length > 128 ||
                this.formAdd.name.indexOf("/") > -1
            ) {
                const nameError = _.escape(this.formAdd.name);
                const Msgerror =
                    this.formAdd.name === ""
                        ? this.$t("zimbra.zmMsg.nameEmpty")
                        : this.formAdd.name.length > 128
                            ? this.$t("zimbra.zhMsg.nameTooLong", { 0: 128 })
                            : this.$t(
                                "zimbra.zmMsg.errorInvalidName",
                                { 0: nameError }
                            );
                this.$alert(Msgerror, this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"), {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    type: "error",
                });
                return;
            }
            this.formAdd.name = _.escape(this.formAdd.name);
            const formData = {
                l: `${this.formAdd.parent.id}`,
                name: `${this.formAdd.name}`,
                view: `${this.formAdd.parent.view
                    ? this.formAdd.parent.view
                    : `contact`
                    }`,
            };
            if (this.formAdd.rgb) {
                formData.rgb = this.formAdd.rgb;
            }
            ZimbraMailService.createFolderRequest(formData)
                .then((res) => {
                    if (res.status == STATUS_REQUEST.SUCCESS) {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t(
                                "zimbraNokey.zimbra_sidebar_messageSuccessAddFolder"
                            ),
                            type: "success",
                        });
                        const itemAddResponse =
                            this.getResponseBody(res)["CreateFolderResponse"];
                        const itemAdd = itemAddResponse.folder;
                        // Nếu là cặp tài liệu thì load lại tree
                        if (itemAdd.view == FOLDER_VIEW.DOCUMENT) {
                            this.$root.$emit("onInnitBriefcaseTree");
                        } else {
                            this.addItemToData(itemAdd, this.dataSidebar);
                        }
                        setTimeout(() => {
                            this.$root.$emit("extendFolderContact", itemAdd.l);
                        }, 100);
                        if (this.moveToFolder) {
                            this.$store.commit("SET_NODE_BRIEFCASE_EXTRA_CURRENT", itemAdd);
                            setTimeout(() => {
                                this.$root.$emit("handleNavbarAction", BRIEFCASE_ACTION.MOVE);
                            }, 200);
                        }
                        // this.$root.$emit("addItemToDataExtend", itemAdd);
                        // this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", null);
                        this.$root.$emit("extendFolderExtra", itemAdd.id);
                        this.$root.$emit("setCurrentActiveKey", itemAdd.id);
                    }
                })
                .catch((err) => {
                    const { detail } = this.getResponseBody(err.response)[
                        "soap:Fault"
                    ];
                    if (
                        detail.Error.Code ===
                        ERROR_CODE.SERVICE_RESOURCE_UNREACHABLE ||
                        detail.Error.Code === ERROR_CODE.SERVICE_PARSE_ERROR
                    ) {
                        this.$confirm(
                            this.$t("zimbraNokey.zimbra_sidebar_resourceUnreachable", {
                                url: formData.url,
                            }),
                            this.$t("zimbraNokey.zimbra_common_criticalMsg"),
                            {
                                showConfirmButton: false,
                                cancelButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                                type: "error",
                            }
                        )
                            .then(() => { })
                            .catch(() => { });
                    }
                });
            this.dialogAddFolderContact = false;
        },
        closeDialog() {
            this.$root.$emit('handleFocusInput')
            this.dialogAddFolderContact = false;
        },
        setCurrentKeyRoot() {
            setTimeout(() => {
                this.$refs.treeFolderExtra.onCurrentKeyRoot()
            }, 200);
        }
    },
};
</script>

<style>
</style>
