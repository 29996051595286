<template>
    <div class="form-controls">
        <label v-show="label" :for="id" class="label-control">{{
            label
            }}</label>
        <el-checkbox-group
            :id="id"
            class="checkbox-control"
            v-model="keyword"
            @change="handleChange"
        >
            <el-checkbox
                class="checkbox-item"
                v-for="option in options"
                :key="option[valueField]"
                :disabled="disabledCheckBox"
                :label="option[valueField]"
            >{{option[displayField]}}
            </el-checkbox
            >
        </el-checkbox-group>
    </div>
</template>

<script>
    import _ from "lodash";
    export default {
        name: "base-checkbox-group",
        props: {
            id: {
                type: [String, Number],
                default: "",
            },
            label: {
                type: String,
                default: "",
            },
            value: {
                type: [Array, String, Number, Boolean],
            },
            options: {
                type: [Array, String, Number, Object],
                required: true,
            },
            valueField: {
                type: String,
                default: "id",
            },
            displayField: {
                type: String,
                default: "name",
            },
            disabledCheckBox:{
                type: Boolean,
                default: false,
            },
            sortValue: {
                type: Boolean,
                default: false,
            }
        },
        created() {
            this.keyword = this.value;
        },
        data() {
            return {
                keyword: [],
            };
        },
        watch: {
            value(val) {
                this.keyword = val;
            },
        },
        methods: {
            handleChange(val) {
                this.$emit("change", this.orderBy(val));
                this.$emit("onfocus");
            },
            orderBy(val) {
                if (!this.options || !val || !this.sortValue) {
                    return val;
                }
                let _temp = val.map(e => {
                    return {
                        value: e,
                        index: this.options.findIndex(x => x[this.valueField] == e)
                    }
                });
                _temp = _.orderBy(_temp, ["index"], ["asc"]);
                return _temp.map(e => e.value);
            }
        },
    };
</script>
