<template>
    <el-dialog
        class="dialog-body-wrap d-center "
        custom-class="modal-choose__tag setting-filter"
        :title="`${formChoose.title}`"
        :visible.sync="dialogChooseTag"
        :close-on-click-modal="false"
        :append-to-body="true"
        :destroy-on-close="true"
    >
        <el-form class="form-wrap">
            <div class="form__heading">
                {{ $t("zimbra.zmMsg.chooserDescription") }}
            </div>
            <base-input
                :value="filterText"
                placeholder=""
                style="padding-bottom: 10px; width: 100%"
                size="medium"
                @handleInput="handleFilter"
            ></base-input>
            <div class="form__body">
                <perfect-scrollbar>
                    <el-tree
                        ref="tagTree"
                        node-key="id"
                        class="tree-wrap"
                        style="margin-top: 10px"
                        id="treeExtend"
                        :data="dataTags"
                        :props="defaultProps"
                        @node-click="nodeClick"
                        :filter-node-method="filterNode"
                        :default-expanded-keys="[FOLDER_DEFAULT.TAG]"
                        highlight-current
                        :expand-on-click-node="false"
                    >
                        <span class="zm-folder-info" slot-scope="{node, data }">
                            <el-popover
                                popper-class="zm-tooltip"
                                placement="bottom-start"
                                :open-delay="1000"
                                trigger="hover"
                            >
                            <div>
                                <b>{{ node.label }}</b> <br />
                            </div>
                             <div slot="reference" class="custom-tree-node">
                                <span v-if="data.id != -2">
                                    <i
                                        class="ic-tag"
                                        :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(
                                            data
                                        )}`"
                                    ></i>
                                </span>
                                <span v-if="data.id == -2">
                                    <i
                                        class="ic-tag"
                                        style="
                                            padding-right: 28px;
                                            margin-left: -7px;
                                        "
                                    ></i>
                                </span>
                                <span>{{ data.name }}</span>
                             </div>
                            </el-popover>
                        </span>
                    </el-tree>
                </perfect-scrollbar>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <base-button-default
                class="btn-email primary outline cover-size"
                @click="$root.$emit('handleTagActionAdd')"
                >{{ $t("zimbra.zhMsg.apptPtstNEW") }}</base-button-default
            >
            <div class="btn-group">
                <base-button-default
                    class="btn-email default cover-size"
                    @click="handleCancel"
                    >{{ $t("zimbra.zhMsg.actionTaskCancelTT") }}</base-button-default
                >
                <base-button-default
                    class="btn-email primary cover-size"
                    :disabled="isChoose"
                    @click="handleAcpectTag"
                    >{{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}</base-button-default
                >
            </div>
        </span>
    </el-dialog>
</template>

<script>
import BaseInput from "@/components/modules/base/base-input";
import CommonUtils from "@/utils/common-utils";
import { FOLDER_DEFAULT, EMAIL_ACTION } from "@/utils/constants";
import BaseButtonDefault from "@/components/modules/base/base-button/btn-default";
import { mapGetters } from "vuex";
export default {
    name: "DialogChooseTag",
    mixins: [CommonUtils],
    mounted() {
        this.$root.$on("handleShowChooseTag", (isShowChooseTag) => {
            this.show(isShowChooseTag);
        });
        this.dataTags = [
            {
                id: FOLDER_DEFAULT.TAG,
                tags: this.tags,
                name: this.$t("zimbra.zhMsg.actionTag"),
            },
        ];
    },
    components: {
        BaseInput,
        BaseButtonDefault,
    },
    data() {
        return {
            formChoose: { parent: {}, title: "", isShowChooseTag: false },
            defaultProps: {
                children: "tags",
                label: "name",
            },
            dataTags: [],
            tagChoose: {},
            dialogChooseTag: false,
            isChoose:true,
            treeNodeMove: {},
            filterText: "",
            listIdChild: [],
            EMAIL_ACTION: EMAIL_ACTION,
            FOLDER_DEFAULT: FOLDER_DEFAULT,
        };
    },
    computed: {
        ...mapGetters(["tags"]),
    },
    watch: {
        tags(val){
            this.dataTags = [
            {
                id: FOLDER_DEFAULT.TAG,
                tags: val,
                name: this.$t("zimbra.zhMsg.actionTag"),
            },
        ];
        }
    },
    methods: {
        show(isShowChooseTag) {
            this.formChoose.isShowChooseTag = isShowChooseTag;
            this.formChoose.title = isShowChooseTag
                ? this.$t("zimbraNokey.zimbra_setting_account_dialogChooseTag_title1")
                : this.$t("zimbraNokey.zimbra_setting_account_dialogChooseTag_title1");
            this.dialogChooseTag = true;
            this.formChoose.parent = {};
        },
        handleCancel() {
            this.dialogChooseTag = false;
        },
        nodeClick(data) {
            this.$refs.tagTree && this.$refs.tagTree.setCurrentKey(null);
            this.$refs.tagTree &&
                this.$refs.tagTree.setCurrentKey(data.id || 0);
            if (data.id !== -2) {
                this.filterText = data.name;
                this.tagChoose = data;
            }
            this.isChoose = false;
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
        },
        handleFilter(val) {
            this.filterText = val;
            this.$refs.tagTree.filter(val);
        },
        handleAcpectTag() {
            this.$emit("handleAcpectTag", this.tagChoose);
            this.dialogChooseTag = false;
        },
    },
};
</script>

<style>
</style>
