<template>
  <el-menu class="box-control-inbox">
      <el-menu-item
            index="1"
            class="menu-item"
            @click="handleOffMail"
        >
            <span>{{ $t('zimbraNokey.zimbra_setting_zimlet_offyahoo') }}</span>
        </el-menu-item>
        <el-menu-item
            index="2"
            class="menu-item"
            @click="onMoreYahoo"
        >
            <span>{{ $t('zimbra.zhMsg.ALT_APP_OPTIONS') }}</span>
        </el-menu-item>
  </el-menu>
</template>

<script>
export default {
    methods: {
        onMoreYahoo() {
            this.$root.$emit("handleShowZimletYahoo", true);
        },
        handleOffMail(){}
    }
}
</script>

<style scoped>
.box-control-inbox {
    max-width: 50rem;
    width: auto
}
</style>