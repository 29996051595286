<template>
    <FullCalendar :options="calendarOptions" :class="isShowWeekNumber? 'have-week-number' : ''" ref="miniCalendar" />
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import allLocales from "@fullcalendar/core/locales-all";
import moment from "moment";
import { mapGetters } from "vuex";
import CommonUtils from "@/utils/common-utils.js";
import CalendarUltils from "@/utils/calendar-utils.js";
import { CALENDAR_VIEW_MODE } from "@/utils/constants";
export default {
    name: "CalendarMini",
    components: {
        FullCalendar
    },
    props: {
        freeBusyCal: { // Lịch rảnh/bận
            type: Array,
            default: () => {
                return [];
            }
        },
        startDate: {
            type: [Number, String],
            default: function() {
                return 0;
            }
        },
    },
    mixins: [CommonUtils, CalendarUltils],
    data() {
        return {
             viewMode: 0,
             selectedDate: moment(this.startDate).startOf('day').toDate(),
             oldYearMonth: moment(this.startDate).format('YYYYMM')
        };
    },
    created() {
    },
    mounted() {
        this.focusDateSelect({date: this.selectedDate});
        this.$root.$on("onSetInnitView", viewMode => {
                this.viewMode = viewMode;
                // chọn lại ngày trên calendar
                this.focusDateSelect({date: this.selectedDate})
            });
        this.$root.$on("onChangeView", viewMode =>
            {
                this.viewMode = viewMode
                // chọn lại ngày trên calendar
                this.focusDateSelect({date: this.selectedDate})
            }
        );
        this.$root.$on("onSelectDay", (date) =>
            {
                this.selectedDate = moment(date).toDate();
                this.$refs.miniCalendar?.getApi().gotoDate(this.selectedDate);
                // chọn lại ngày trên calendar
                this.focusDateSelect({date: this.selectedDate})
            }
        );
    },
    watch: {
        startDate(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.selectedDate = moment(this.startDate).startOf('day').toDate();
                // chọn lại ngày trên calendar
                this.focusDateSelect({date: this.selectedDate});
            }
        },
    },
    destroyed() {
        this.$root.$off("onSetInnitView");
        this.$root.$off("onChangeView");
        this.$root.$off("onSelectDay");
    },
    computed: {
        ...mapGetters(["timeZoneCurrent", "localeCurrent"]),
        calendarOptions() {
            let _lang = this.localeCurrent?.content?.toString() || "vi";
            const indexOf = _lang.indexOf(",");
            _lang = _lang.substring(indexOf + 1).replaceAll("_", "-");
            return {
                plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
                initialView: "dayGridMonthCus",
                locales: allLocales,
                locale: _lang,
                timeZone: this.timeZoneCurrent.content,
                headerToolbar: {
                    start: "title",
                    center: "",
                    end: "prevButton,nextButton",
                },
                customButtons: {
                    nextButton: {
                        text: '>',
                        click: this.handleNextWeek,
                        icon: 'fa-chevron-right',
                    },
                    prevButton: {
                        text: '<',
                        click: this.handleBackWeek,
                        icon: 'fa-chevron-left',
                    },
                },
                views: {
                    dayGridMonthCus: {
                        type: 'dayGridMonth',
                    }
                },
                editable: true,
                dateClick: this.onDateClick,
                unselectAuto: false,
                events: this.freeBusyCal,
                weekNumbers: this.isShowWeekNumber,
                dayCellDidMount: (info) => {
                    // Loại bỏ class event
                    const eventDiv = info.el.querySelector('.fc-daygrid-day-events');
                    eventDiv.className = "";
                },
            };
        },

        /**
         * Hàm kiểm tra có show week number hay không
         */
        isShowWeekNumber() {
            return this.$store.getters.preference.zimbraPrefShowCalendarWeek;
        }
    },
    methods: {
        handleNextWeek() {
            this.$refs.miniCalendar.getApi().next();
        },
        handleBackWeek() {
            this.$refs.miniCalendar.getApi().prev();
        },

        /**
         * Hàm chọn click
         */
        onDateClick(info) {
            const date = new Date(info.date.toDateString());
            this.selectedDate = date;
            this.focusDateSelect(info);
            this.$emit("onSelectDate", date);
        },

        /**
         * Hàm focus vào trường date trên mini calendar
         */
        focusDateSelect(info) {
            const date = new Date(info.date.toDateString());
            const yearMonth = moment(date).format('YYYYMM');
            if (yearMonth > this.oldYearMonth) {
                this.oldYearMonth = yearMonth;
                this.$refs.miniCalendar.getApi().next();
            } else if (yearMonth < this.oldYearMonth) {
                this.oldYearMonth = yearMonth;
                this.$refs.miniCalendar.getApi().prev();
            }
            // Nếu là week thì chọn range week
            if(this.viewMode === CALENDAR_VIEW_MODE.WEEK || this.viewMode === CALENDAR_VIEW_MODE.WEEK_WORK) {
                const firstday = moment(date).startOf('week').toDate();
                const lastday = moment(date).endOf('week').add(1, 'days').toDate();
                this.$refs.miniCalendar.getApi().select(this.getDateForCalendar(firstday), this.getDateForCalendar(lastday));
            } else {
                // nếu không thì chọn ngày như bình thường
                this.$refs.miniCalendar.getApi().select(this.getDateForCalendar(date));
            }
        },
    },
};
</script>
