<template>
    <el-tooltip v-if="replyInv == CAL_OPERATOR_REPLY_INV.ACCEPT" :open-delay="1000"
        class="item" effect="dark" :content="$t('zimbra.zmMsg.attendeeStatusLabel') + ' ' + $t('zimbra.zhMsg.replyAccept')" placement="top-start">
        <img class="svg-resource-type" src="@/assets/zimbra/images/schedules/reply_inv_accept.svg"/>
    </el-tooltip>
    <el-tooltip v-else-if="replyInv == CAL_OPERATOR_REPLY_INV.TENTATIVE" :open-delay="1000"
        class="item" effect="dark" :content="$t('zimbra.zmMsg.attendeeStatusLabel') + ' ' + $t('zimbra.zhMsg.apptPtstTE')" placement="top-start">
        <img class="svg-resource-type" src="@/assets/zimbra/images/schedules/reply_inv_tentative.svg"/>
    </el-tooltip>
    <el-tooltip v-else-if="replyInv == CAL_OPERATOR_REPLY_INV.DECLINE" :open-delay="1000"
        class="item" effect="dark" :content="$t('zimbra.zmMsg.attendeeStatusLabel') + ' ' + $t('zimbra.zhMsg.replyDecline')" placement="top-start">
        <img class="svg-resource-type" src="@/assets/zimbra/images/schedules/reply_inv_decline.svg"/>
    </el-tooltip>
    <el-tooltip v-else-if="replyInv == CAL_OPERATOR_REPLY_INV.NEW_TIME" :open-delay="1000"
        class="item" effect="dark" :content="$t('zimbra.zmMsg.attendeeStatusLabel') + ' ' + $t('zimbra.zmMsg.proposeNewTime')" placement="top-start">
        <img class="svg-resource-type" src="@/assets/zimbra/images/schedules/reply_inv_new_time.svg"/>
    </el-tooltip>
    <el-tooltip v-else-if="replyInv == CAL_OPERATOR_REPLY_INV.NEEDS_ACTION" :open-delay="1000"
        class="item" effect="dark" :content="$t('zimbra.zmMsg.attendeeStatusLabel') + ' ' + $t('zimbra.zmMsg.needsAction')" placement="top-start">
        <img class="svg-resource-type" src="@/assets/zimbra/images/schedules/reply_inv_need_action.svg"/>
    </el-tooltip>
</template>
<script>
import {
    CAL_OPERATOR_REPLY_INV
} from "@/utils/constants.js";
/**
 {
    displayName: tên hiển thị
    resouceType: 1: Người tham dự bắt buộc, 2: Người tham dự tùy chọn, 3: Địa điểm, 4: Thiết bị
    email: //email
    status: trạng thái 1: Rảnh, 2: Không làm việc, 3: Bận, 4: Chưa dứt khoát, 5: Không xác định, 6: Ra khỏi văn phòng,
    isEditable: cờ có thể sửa true: có thể sửa, false: không thể sửa
 }
 */
export default {
    name: 'ReplyInvDisplay',
    props: {
        replyInv: {
            type: String
        }
    },
    data() {
        return {
            CAL_OPERATOR_REPLY_INV: CAL_OPERATOR_REPLY_INV
        };
    },
    async created() {
    },
    mounted() {
    },
    destroyed() {
    },
    computed: {
    },
    methods: {
    },
};
</script>
<style scoped>

.btn-svg-resource-type {
    height: 28px !important;
    padding: 0px 3px !important;
    border: none !important;
}
.btn-svg-resource-type[disabled] {
    background: transparent !important;
}
.btn-svg-resource-type img {
    max-width: unset;
    vertical-align: middle;
    margin-top: -3px;
}
.svg-resource-type {
    width: 20px;
    height: 20px;
}
</style>
