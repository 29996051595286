import BaseService from "../shared/services/BaseService";
import { FILTER_ACTION, FILTER_TESTS } from "@/utils/constants";
import CONFIG from '@/config/config';
class FileService extends BaseService {
    url = CONFIG.ENDPOINT['compileService'];

    constructor() {
        super("zimbraService");
    }
    encodedStr(unsafe) {
        if (!unsafe) return unsafe;
        return unsafe.toString()
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }
    getFilterRules(data = "", withProgress = true, withNotify = true) {
        const url = `${this.baseURL}/getFilterRulesRequest`;
        return this.postRequest(url, {
            xmlns: data
        }, withProgress, withNotify);
    }
    getOutgouingFilterRules() {
        const url = `${this.baseURL}/getOutgoingFilterRulesRequest`;
        return this.postRequest(url);
    }
    renderActionFilter(obj) {
        let _string = "";
        for (const key in obj) {
            let list = [];
            if (obj[key].length) {
                obj[key].forEach(item => list.push(item));
            } else {
                list.push(obj[key]);
            }
            if (key === FILTER_ACTION.ACTION_KEEP) {
                list.forEach(() => (_string += `<urn1:actionKeep  />`));
            } else if (key === FILTER_ACTION.ACTION_DISCARD) {
                list.forEach(() => (_string += `<urn1:actionDiscard  />`));
            } else if (key === FILTER_ACTION.ACTION_FILE_INTO) {
                list.forEach(
                    item =>
                        (_string += `<urn1:actionFileInto  folderPath="${item.folderPath}" />`)
                );
            } else if (key === FILTER_ACTION.ACTION_TAG) {
                list.forEach(
                    item =>
                        (_string += `<urn1:actionTag  tagName="${item.tagName}"/>`)
                );
            } else if (key === FILTER_ACTION.ACTION_FLAG) {
                list.forEach(
                    item =>
                        (_string += `<urn1:actionFlag  flagName="${item.flagName}"/>`)
                );
            } else if (key === FILTER_ACTION.ACTION_REDIRECT) {
                list.forEach(
                    item => (_string += `<urn1:actionRedirect  a="${item.a}"/>`)
                );
            } else if (key === FILTER_ACTION.ACTION_STOP) {
                list.forEach(() => (_string += `<urn1:actionStop  />`));
            }
        }
        return _string;
    }
    renderFilterTest(obj) {
        let filterTest = "";
        const ignoreProps = ["condition"];
        for (let key in obj) {
            let list = [];
            if (ignoreProps.indexOf(key) > -1) {
                continue;
            }
            if (obj[key].length) {
                obj[key].forEach(item => list.push(item));
            } else {
                list.push(obj[key]);
            }
            if (key === FILTER_TESTS.ADDRESS_BOOK_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:addressBookTest type="contacts" header="${this.encodedStr(
                        item.header
                    )}" ${item.negative ? `negative="${item.negative}"` : ""
                        } />`)
                );
            } else if (key === FILTER_TESTS.ADDRESS_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:addressTest header="${this.encodedStr(item.header)
                        }" stringComparison="${item.stringComparison
                        }" value="${this.encodedStr(item.value)}" part="${item.part
                        }"
                        ${item.negative ? `negative="${item.negative}"` : ""
                        } />`)
                );
            } else if (key === FILTER_TESTS.HEADER_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:headerTest header="${this.encodedStr(item.header)}" stringComparison="${item.stringComparison
                        }" value="${this.encodedStr(item.value)}" ${item.negative ? `negative="${item.negative}"` : ""
                        } />`)
                );
            } else if (key === FILTER_TESTS.CONVERSATION_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:conversationTest where="${item.where
                        }" ${item.negative ? `negative="${item.negative}"` : ""
                        } />`)
                );
            } else if (key === FILTER_TESTS.BULK_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:bulkTest ${item.negative ? `negative="${item.negative}"` : ""
                        } />`)
                );
            } else if (key === FILTER_TESTS.LIST_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:listTest ${item.negative ? `negative="${item.negative}"` : ""
                        } />`)
                );
            } else if (key === FILTER_TESTS.FLAGGED_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:flaggedTest flagName="${item.flagName
                        }" ${item.negative ? `negative="${item.negative}"` : ""
                        } />`)
                );
            } else if (key === FILTER_TESTS.IMPOTAMCE_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:impotamceTest imp="${item.imp}" ${item.negative ? `negative="${item.negative}"` : ""
                        } />`)
                );
            } else if (key === FILTER_TESTS.SIZE_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:sizeTest numberComparison="${item.numberComparison
                        }" s="${item.s}" ${item.negative ? `negative="${item.negative}"` : ""
                        } />`)
                );
            } else if (key === FILTER_TESTS.DATE_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:dateTest dateComparison="${item.dateComparison
                        }" d="${item.d}" ${item.negative ? `negative="${item.negative}"` : ""
                        } />`)
                );
            } else if (key === FILTER_TESTS.BODY_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:bodyTest value="${this.encodedStr(
                        item.value
                    )}" ${item.negative ? `negative="${item.negative}"` : ""
                        } />`)
                );
            } else if (key === FILTER_TESTS.ATTACHMENT_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:attachmentTest ${item.negative ? `negative="${item.negative}"` : ""
                        } />`)
                );
            } else if (key === FILTER_TESTS.MIME_HEADER_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:mimeHeaderTest header="${item.header
                        }" stringComparison="${item.stringComparison}" value="${this.encodedStr(item.value)
                        }" ${item.negative ? `negative="${item.negative}"` : ""
                        } />`)
                );
            } else if (key === FILTER_TESTS.CONTACT_RANK_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:contactRankTest header="${item.header
                        }" ${item.negative ? `negative="${item.negative}"` : ""
                        } />`)
                );
            } else if (key === FILTER_TESTS.ME_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:meTest header="${item.header}" ${item.negative ? `negative="${item.negative}"` : ""
                        } />`)
                );
            } else if (key === FILTER_TESTS.INVITE_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:inviteTest ${item.negative ? `negative="${item.negative}"` : ""
                        }><urn1:method>${item.method
                        }</urn1:method></urn1:inviteTest>`)
                );
            } else if (key === FILTER_TESTS.FACEBOOK_TEST) {
                list.forEach(() => (filterTest += `<urn1:facebookTest />`));
            } else if (key === FILTER_TESTS.LINKEDIN_TEST) {
                list.forEach(() => (filterTest += `<urn1:linkedinTest />`));
            } else if (key === FILTER_TESTS.TWITTER_TEST) {
                list.forEach(() => (filterTest += `<urn1:twitterTest />`));
            } else if (key === FILTER_TESTS.HEADER_EXITS_TEST) {
                list.forEach(
                    item =>
                    (filterTest += `<urn1:headerExitsTest header="${item.header
                        }" value="${this.encodedStr(item.value)}" ${item.negative ? `negative="${item.negative}"` : ""
                        } />`)
                );
            }
        }
        return filterTest;
    }
    beforeCallAPI(data) {
        const obj = {
            filterRules: `<urn1:filterRules>${data
                .map(
                    item =>
                        `<urn1:filterRule name="${this.encodedStr(
                            item.name
                        )}" active="${item.active
                        }"><urn1:filterActions>${this.renderActionFilter(
                            item.filterActions
                        )}</urn1:filterActions><urn1:filterTests condition="${item.filterTests.condition
                        }">${this.renderFilterTest(
                            item.filterTests
                        )}</urn1:filterTests></urn1:filterRule>`
                )
                .join("")
                .trim()}</urn1:filterRules>`
        };
        return obj;
    }
    createFolderRequest(form) {
        const url = `${this.baseURL}/createFolderRequest`;
        return this.postRequest(url, form);
    }
    modifyFilterRulesRequest(data) {
        const url = `${this.baseURL}/modifyFilterRulesRequest`;
        const request = this.beforeCallAPI(data);
        return this.postRequest(url, request);
    }
    modifyOutgoingFilterRulesRequest(data) {
        const url = `${this.baseURL}/modifyOutgoingFilterRulesRequest`;
        const request = this.beforeCallAPI(data);
        return this.postRequest(url, request);
    }
    searchRequest(form) {
        const url = `${this.baseURL}/searchRequestFilter`;
        return this.postRequest(url, form);
    }
    applyIncomingFilterRulesRequest(data) {
        const url = `${this.baseURL}/applyFilterRulesRequest`;
        const names = [];
        data.filterRulesSelect.forEach(item =>
            names.push({ name: `${this.encodedStr(item.data.name)}` })
        );
        const ids = data.listIds.map(item => item.id).join(",");
        return this.postRequest(url, {
            names: names,
            ids: ids
        });
    }
    applyOutgoingFilterRulesRequest(data) {
        const url = `${this.baseURL}/applyOutgoingFilterRulesRequest`;
        const names = [];
        data.filterRulesSelect.forEach(item =>
            names.push({ name: item.data.name })
        );
        const ids = data.listIds.map(item => item.id).join(",");
        return this.postRequest(url, {
            names: names,
            ids: ids
        });
    }
}
export default new FileService();
