<template>
    <el-dialog
        class="dialog-body-wrap zimbra-dialog d-center"
        ref="refDialogAddMoveFolder"
        custom-class="dialog-add-folder mw__450"
        :title="$t('zimbra.zhMsg.createNewFolder')"
        :visible.sync="dialogMoveAddFolder"
        width="30%"
        height="550px"
        :append-to-body="true"
        :close-on-click-modal="false"
    >
        <el-form class="form-wrap">
            <el-form-item
                :label="$t('zimbra.zhMsg.nameLabel')"
                label-width="90px"
            >
                <el-input
                    class="el-text-control"
                    v-model="formAdd.name"
                    autocomplete="off"
                    size="medium"
                    ref="refInputName"
                ></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('zimbra.zmMsg.colorLabel')"
                label-width="90px"
            >
                <zm-color-picker
                    v-model="formAdd.rgb"
                    :visible="false"
                    :showFooter="true"
                >
                </zm-color-picker>
            </el-form-item>
            <el-form-item
                :label="$t('zimbraNokey.zimbra_sidebar_dialogMoveFolder2_powerSupply')"
                label-width="90px"
            >
                <el-checkbox @change="handleChangeRegister" v-model="formAdd.registerRSS">{{
                    $t(
                        "zimbra.zmMsg.subscribeToFeed"
                    )
                }}</el-checkbox>
            </el-form-item>
            <el-form-item
                v-if="formAdd.registerRSS"
                :label="$t('zimbra.zhMsg.url')"
                label-width="90px"
            >
                <el-input
                    v-model="formAdd.url"
                    autocomplete="off"
                    size="medium"
                    ref="refInputUrl"
                ></el-input>
            </el-form-item>
            <div style="padding-bottom: 10px; padding-top: 10px;">
                <span class="zimbra-label">{{$t('zimbra.zmMsg.newFolderParent')}}</span>
            </div>
            <div class="wrap-tree">
                <TreeFolderExtra ref="treeFolderExtra"
                    :isMoveMail ="false"
                    :showInputSearch ="false"
                    :listIdFolderHidden = [FOLDER_DEFAULT.JUNK,FOLDER_DEFAULT.ROOT]
                 ></TreeFolderExtra>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogMoveAddFolder = false">{{
                $t("zimbra.zhMsg.actionTaskCancelTT")
            }}</el-button>
            <el-button type="primary" @click="AddFolder">{{
                $t("zimbra.zmMsg.mobileStatusNeedProvision")
            }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import TreeFolderExtra from "./TreeFolderExtra";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import EmailUtils from "@/utils/email-utils";
import color from "./../listColor";
import { EMAIL_ACTION, FOLDER_DEFAULT, STATUS_REQUEST, READ_EMAIL_LAYOUT_ROUTER } from "@/utils/constants";
import _ from "lodash";
import { mapGetters } from "vuex";
import { ERROR_CODE } from "@/utils/constants";
import ZmColorPicker from "@/components/modules/base/base-color-picker-advance/zm-color-picker";
export default {
    mixins: [CommonUtils, EmailUtils],
    mounted() {
        console.log("mounted");
        this.$root.$on("handleSidebarActionMoveAdd", this.handleSidebarActionMoveAdd);
    },
    beforeDestroy() {
        this.$root.$off("handleSidebarActionMoveAdd", this.handleSidebarActionMoveAdd);
    },
    computed: {
        ...mapGetters(["dataSidebarInBox", "allTreeFolder"]),
    },
    components: {
        TreeFolderExtra,
        "zm-color-picker": ZmColorPicker
    },
    data() {
        return {
            formAdd: {
                name: "",
                color: null,
                source: "",
                parent: {},
                url: null,
                rgb: "#3F4254",
            },
            dialogMoveAddFolder: false,
            listColor: color,
            isAddFolderToMoveMail: false,
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            STATUS_REQUEST: STATUS_REQUEST,
        };
    },
    methods: {
        handleSidebarActionMoveAdd(isAddFolderToMoveMail) {
            this.show(isAddFolderToMoveMail);
        },

        show(isAddFolderToMoveMail) {
            this.isAddFolderToMoveMail = isAddFolderToMoveMail;
            this.dialogMoveAddFolder = true;
            this.formAdd = {
                name: "",
                source: "",
                parent: {},
                url: "",
                rgb: "#3F4254",
                registerRSS: false,
            };
            this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", null);
            setTimeout(() => {
                this.$refs.treeFolderExtra.setCurrentKey(FOLDER_DEFAULT.ROOT)
                this.$refs.refInputName.focus()
            }, 500);
            if (name == READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE) {
                this.isMessage = true
            } else {
                this.isMessage = false
            }
        },
        checkExistsName(name, folderParent) {
            if (folderParent.id == FOLDER_DEFAULT.ROOT) {
                const _folderExists = this.allTreeFolder.find(folder => folder.name.toString().toLowerCase() === name.toString().toLowerCase())
                if (_folderExists) {
                    this.$alert(
                        this.$t("zimbra.zmMsg.errorAlreadyExists", { 0: name, 1: this.$t("zimbra.zhMsg.ALT_FOLDER") }),
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return true;
                }
            }
            if (Array.isArray(folderParent.folder)) {
                if (folderParent.folder.map((x) => x.name).indexOf(name) > -1) {
                    this.$alert(
                        this.$t("zimbra.zmMsg.errorAlreadyExists", { 0: name, 1: this.$t("zimbra.zhMsg.ALT_FOLDER") }),
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return true;
                }
            }
        },
        isUrl(s) {
            var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
            return regexp.test(s);
        },
        AddFolder() {
            this.formAdd.parent = this.$store.getters.activeFolderExtra ? this.$store.getters.activeFolderExtra : { id: FOLDER_DEFAULT.ROOT };
            if (this.checkExistsName(this.formAdd.name, this.formAdd.parent)) {
                return
            }
            this.formAdd.name = this.formAdd.name.toString().trim();
            // validateName
            if (this.formAdd.name == "" || this.formAdd.name.length > 128 || this.formAdd.name.indexOf("/") > -1) {
                const nameError = _.escape(this.formAdd.name);
                const Msgerror = this.formAdd.name == "" ? this.$t("zimbra.zmMsg.nameEmpty") :
                    (this.formAdd.name.length > 128 ? this.$t("zimbraNokey.zimbra_sidebar_messageCriticalMaxLengthName") : this.$t("zimbraNokey.zimbra_sidebar_messageCriticalMaxFormatName", { nameError: nameError }));
                this.$alert(
                    Msgerror,
                    this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                    {
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "error",
                    }
                );
                return;
            }
            this.formAdd.name = _.escape(this.formAdd.name);
            // validateUrl
            if (this.formAdd.registerRSS) {
                this.formAdd.url = this.formAdd.url.trim();
                if (this.formAdd.url == "" || !this.isUrl(this.formAdd.url)) {
                    const Msgerror = this.$t("zimbra.zmMsg.errorUrlMissing");
                    this.$alert(
                        Msgerror,
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return;
                }
            }
            const formData = {
                l: `${this.formAdd.parent.id}`,
                name: `${this.formAdd.name}`,
            };
            if (this.formAdd.registerRSS) {
                formData.url = this.formAdd.url;
            }
            formData.rgb = this.formAdd.rgb;
            ZimbraMailService.createFolderRequest(formData).then((res) => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    const itemAddResponse =
                        this.getResponseBody(res)["CreateFolderResponse"];
                    const itemAdd = itemAddResponse.folder;
                    if (this.formAdd.parent.id == FOLDER_DEFAULT.ROOT) {
                        this.addItemToData(itemAdd, this.dataSidebarInBox);
                    } else {
                        this.addItemToData(itemAdd, this.dataSidebarInBox);
                        this.addItemToData(itemAdd, this.dataSidebarExtend);
                    }
                    this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", itemAdd);
                    this.$root.$emit("setCurrentKey", itemAdd.id);
                    this.$root.$emit("setFocusNodeActive");
                    this.$root.$emit("extendFolderExtra", itemAdd.id);
                    if (this.isAddFolderToMoveMail) {
                        this.$root.$emit('handleNavbarAction', EMAIL_ACTION.MOVE);
                    } else {
                        if (this.isMessage) {
                            this.$message({
                                type: "success",
                                customClass: 'zm-notify',
                                duration: 3500,
                                dangerouslyUseHTMLString: true,
                                message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessAddFolder")
                            });
                        } else {
                            this.$notify({
                                title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                                message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessAddFolder"),
                                type: "success",
                            });
                        }
                    }
                }
            })
                .catch((err) => {
                    const { detail } = this.getResponseBody(err.response)["soap:Fault"];
                    if (detail.Error.Code === ERROR_CODE.SERVICE_RESOURCE_UNREACHABLE || detail.Error.Code === ERROR_CODE.SERVICE_PARSE_ERROR) {
                        this.$confirm(
                            this.$t("zimbraNokey.zimbra_sidebar_resourceUnreachable", { url: formData.url }),
                            this.$t("zimbraNokey.zimbra_common_criticalMsg"),
                            {
                                showCancelButton: false,
                                confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                                type: "error",
                            }
                        )
                            .then(() => { })
                            .catch(() => { });
                    }
                });
            this.dialogMoveAddFolder = false;
        },
        /*
            focus vào input url khi chọn nguồn cấp
        */
        handleChangeRegister(checked) {
            if (checked) {
                setTimeout(() => {
                    this.$refs.refInputUrl.focus();
                }, 150);
            }
        }
    },
    // destroyed() {
    //     this.$root.$off("handleSidebarActionMoveAdd", (isAddFolderToMoveMail) => {
    //         this.show(isAddFolderToMoveMail);
    //     });
    // }
};
</script>
