<template>
    <div class="row" style="flex-wrap: unset">
        <div class="display-name-label">
            <span class="icon">
                <i :class="`fas ${classIcon}`"></i>
            </span>
            <span class="email pl-2">
                {{ displayLabel }}
            </span>
        </div>
        <div class="text-right zm-width-50">
            <span v-if="value.ranking > 0" class="zm-action-on-hover" @click.stop="forgetAddress">
                {{ $t("zimbra.zmMsg.forget") }}
            </span>
        </div>
        <div class="text-right zm-width-50 ml-20">
            <el-popover
                placement="right"
                trigger="click"
                :open-delay="1000"
                popper-class="p-0"
                @show="openListMembers"
            >
                <perfect-scrollbar style="max-height: 400px">
                    <el-menu ref="rightMenu" class="menu-list-members">
                        <el-menu-item
                            v-if="multiple"
                            class="menu-item"
                            @click="selectAllMembers()"
                        >
                            <span>{{
                                $t("zimbraNokey.zimbra_sidebar_dialogShare_selectAll", {
                                    total: listMembers.length,
                                })
                            }}</span>
                        </el-menu-item>
                        <el-menu-item
                            v-for="item in listMembers"
                            :key="item"
                            @click="selectMembers(item)"
                            class="menu-item"
                        >
                            <i class="fas fa-user-tag"></i>
                            <span class="pl-2">{{ item }}</span>
                        </el-menu-item>
                    </el-menu>
                </perfect-scrollbar>
                <span slot="reference" v-if="value.isGroup && value.showExpand" class="zm-action-on-hover" @click.stop="">
                    {{ $t("zimbra.zmMsg.expand") }}
                </span>
            </el-popover>
        </div>
    </div>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import ZimbraMailService from "@/services/ZimbraMailService.js";
export default {
    name: "ItemAddressSelector",
    mixins: [CommonUtils],
    props: {
        value: {
            type: Object,
            default() {
                return {};
            },
        },
        multiple: {
            type: Boolean,
            default: false
        },
        fieldLabel: {
            type: String,
            default: "email"
        }
    },
    data() {
        return {
            listMembers: [],
        };
    },
    computed: {
        classIcon() {
            if (this.value.isGroup) {
                return "fa-users";
            }
            return "fa-user-alt";
        },
        displayLabel() {
            return this.value[this.fieldLabel];
        }
    },
    methods: {
        forgetAddress() {
            const formData = {
                op: "delete",
                email: this.getAddressEmailFromText(this.displayLabel),
            };
            ZimbraMailService.rankingActionRequest(formData).then(() => {
                this.$notify({
                    title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: this.$t("zimbra.zmMsg.forgetSummary", [
                        this.getAddressEmailFromText(this.displayLabel),
                    ]),
                    type: "success",
                });
                this.$emit("closed");
            });
        },
        openListMembers() {
            this.showListMembers = true;
            const content = this.getAddressEmailFromText(this.displayLabel);
            const form = { dl: { content: `${content}` }, limit: 100, offset: 0 };
            ZimbraMailService.getDistributionListMembersRequest(form)
                .then((res) => {
                    const data =
                        this.getResponseBody(res)[
                            "GetDistributionListMembersResponse"
                        ];
                    if (data && data.dlm) {
                        this.listMembers = Array.isArray(data.dlm)
                            ? data.dlm
                            : [data.dlm];
                    }
                })
                .catch();
        },
        selectMembers(item) {
            this.$emit("selectMembers", item);
        },
        selectAllMembers() {
            this.$emit("selectAllMembers", this.listMembers);
        },
    },
};
</script>
