<template>
  <el-menu class="box-control-inbox">
      <!-- Thư mục cặp tài liệu mới -->
        <el-menu-item
            index="1"
            class="menu-item"
            :disabled="isFromTrashFolder ||
                !hasPermissionSpecifyFolder(
                    this.folderData,
                    RIGHTS_FOLDER.CREATE_SUBFOLDER
                )"
            @click="onOpenAddBriefcase"
        >
            <i class="el-icon-folder-add" style="color: orange"></i>
            <span>{{ $t("zimbra.zhMsg.folderNew") }}</span>
        </el-menu-item>

        <!-- Xóa sạch thùng rác -->
        <el-menu-item
            index="5"
            class="menu-item"
            :hidden="!isFromTrashFolder"
            :disabled="!folderData.folder.length && folderData.n == 0"
            @click="clickEmptyTrash"
        >
            <i class="el-icon-delete" style="color: red"></i>
            <span>{{ $t("zimbra.zhMsg.emptyTrash") }}</span>
        </el-menu-item>

        <!-- Chia sẻ thư mục -->
        <el-menu-item
            index="2"
            class="menu-item"
            :disabled="isFromTrashFolder ||
                !hasPermissionSpecifyFolder(
                    this.folderData,
                    RIGHTS_FOLDER.ADMINISTER
                )"
            @click="onShareFolder"
        >
            <i class="el-icon-share" style="color: cornflowerblue"></i>
            <span>{{ $t("zimbra.zmMsg.shareFolder") }}</span>
        </el-menu-item>

        <!-- Xóa -->
        <el-menu-item
            index="3"
            class="menu-item"
            :disabled="isFromTrashFolder || isBriefCaseFolder"
            @click="onDelete"
        >
            <i class="el-icon-circle-close" style="color: red"></i>
            <span>{{ $t("zimbra.zhMsg.actionDelete") }}</span>
        </el-menu-item>

        <!-- Sửa thuộc tính -->
        <el-menu-item
            index="4"
            class="menu-item"
            :disabled="isFromTrashFolder"
            @click="onOpenEditBriefcase"
        >
            <i class="el-icon-edit" style="color: blue"></i>
            <span>{{ $t("zimbra.zmMsg.editProperties") }}</span>
        </el-menu-item>

  </el-menu>
</template>

<script>
import { RIGHTS_FOLDER, FOLDER_DEFAULT, FOLDER_ACTION, STATUS_REQUEST, FOLDER_VIEW } from "@/utils/constants.js";
import CommonUtils from "@/utils/common-utils.js";
import CalendarUtils from "@/utils/calendar-utils.js";
import BriefcaseUtils from "@/utils/briefcase-utils";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import { mapGetters } from 'vuex';
export default {
    mixins: [CommonUtils, CalendarUtils, BriefcaseUtils],
    data() {
        return {
            RIGHTS_FOLDER: RIGHTS_FOLDER,
        }
    },
    props: {
        folderData: {
            type: Object,
            default: () => {
                return {}
            }
        },

        /**
         * Biến xác định menu được click từ thùng rác hay không
         */
        isFromTrashFolder: {
            type: Boolean,
            default: () => {
                return false
            }
        },

    },
    computed: {
        ...mapGetters(["briefcaseTreeFolders", "allTreeFolder"]),
        isBriefCaseFolder() {
            return this.folderData.id == FOLDER_DEFAULT.BRIEFCASE
        }
    },
    methods: {
        /**
         * Hàm tạo thư mục cặp tài liệu mới
         */
        onOpenAddBriefcase() {
            this.$store.commit("SET_ACTIVE_NODE_SIDEBAR_RIGHTCLICK", this.folderData);
            this.$root.$emit('handleSidebarActionAdd', FOLDER_VIEW.BRIEFCASE)
        },

        /**
         * Hàm confirm xóa sạch thùng rác
         */
        clickEmptyTrash() {
            this.$confirm(
                this.$t("zimbra.zmMsg.confirmEmptyTrashFolder"),
                this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    cancelButtonText: this.$t("zimbra.zhMsg.actionTaskCancelTT"),
                    type: "warning",
                }
            ).then(() => this.emptyTrash())
                .catch(() => { })
        },

        /**
         * Hàm xóa sạch thùng rác
         */

        emptyTrash() {
            const xml = {
                folder: `<urn1:action id="${this.folderData.id}" op="empty" recursive="1"></urn1:action>`,
            };
            ZimbraMailService.folderActionRequest(xml).then((res) => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                        message: this.$t(
                            "zimbraNokey.zimbra_sidebar_messageSuccessEmptyTrash"
                        ),
                        type: "success",
                    });

                    // Cập nhật lại dữ liệu cây
                    this.$root.$emit("onInnitBriefcaseTree")
                }
            });
        },
        /**
         * Hàm chia sẻ thư mục
         */
        onShareFolder() {
            this.$store.commit("SET_ACTIVE_NODE_SIDEBAR_RIGHTCLICK", this.folderData);
            this.$root.$emit("handleSidebarActionShare", { isBriefcase: true }, false)
        },


        /**
         * Hàm kiếm tra xem folder có trong thùng rác không
         */
        isContainsInTrash() {
            // Lọc lấy folder thùng rác
            const trashNode = this.briefcaseTreeFolders?.find(x => x.id == FOLDER_DEFAULT.TRASH);
            let listIdTrash = [];
            this.searchTreeIds(trashNode, listIdTrash);
            // Lấy ra danh sách id trong trash node
            return listIdTrash.includes(this.folderData.id);
        },

        /**
         * Hàm xóa thư mục
         */
        onDelete() {
            if (this.isContainsInTrash()) {
                this.deleteBriefCase(this.folderData.id, this.folderData.name).then(
                    isReload => {
                        if (isReload) this.$root.$emit("onInnitBriefcaseTree")
                        this.$root.$emit("extendFolderContact", this.folderData.l);
                    }
                )
            } else {
                this.moveBriefCaseToTrash(this.folderData.id, this.folderData.name).then(() => this.$root.$emit("onInnitBriefcaseTree"))
                this.$root.$emit("extendFolderContact", FOLDER_DEFAULT.TRASH);
            }
        },
        /**
         * Hàm sửa thuộc tính
         */
        onOpenEditBriefcase() {
            this.$store.commit("SET_ACTIVE_NODE_SIDEBAR_RIGHTCLICK", this.folderData);
            this.$root.$emit("handleActionEditProperties")
        },

        /**
         * hàm chuyển thư mục cặp tài liệu vào thùng rác
         */
        async moveBriefCaseToTrash(idSource, nameSource) {
            await ZimbraMailService.folderActionRequest({
                folder: `<urn1:action id="${idSource.toString()}" op="${FOLDER_ACTION.TRASH}"></urn1:action>`
            }
            );
            const uuid = this.generateUniqueId();
            // Thông báo thành công
            this.notify = this.$notify({
                dangerouslyUseHTMLString: true,
                message: `<div> ${this.$t("zimbra.zmMsg.actionMoveOrganizer", [this.$t("zimbra.zhMsg.ALT_APP_BRIEFCASE"), nameSource, this.$t("zimbra.zhMsg.FOLDER_LABEL_3")])} ${this.folderData.isLink || this.folderData.perm ? "</div>`" : `<span style="margin-left: 5px;padding-left: 7px;padding-right: 7px;padding-top: 1px;padding-button: 1px;border: 1px solid white;"> <a id="undo_${uuid}" style="" href="javascript:;"}> ${this.$t(
                    "zimbra.zmMsg.undo"
                )}</a></span></div>`} `,
                position: "bottom-left",
                customClass: "notify-wrap",
                duration: 4000,
            });
            var x = document.getElementById(`undo_${uuid}`);
            x?.addEventListener("click", this.undo)
        },
        async undo() {
            this.notify.close()
            this.notify = {};
            await ZimbraMailService.folderActionRequest({
                folder: `<urn1:action id="${this.folderData.id.toString()}" l="${this.folderData.l}" op="${FOLDER_ACTION.MOVE}"></urn1:action>`
            }
            ).then(res => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    this.$root.$emit("onInnitBriefcaseTree")
                    // Thông báo thành công
                    const _nameSource = this.folderData.name
                    let _folderSource = this.$t("zimbra.zhMsg.ALT_APP_BRIEFCASE")
                    if (this.folderData.l) {
                        _folderSource = this.getFolderParentFolder(this.allTreeFolder, this.folderData.l)?.name
                    }
                    this.$notify({
                        dangerouslyUseHTMLString: true,
                        message: `<div> ${this.$t("zimbra.zmMsg.actionMoveOrganizer", [this.$t("zimbra.zhMsg.ALT_APP_BRIEFCASE"), _nameSource, _folderSource])}</div>`,
                        position: "bottom-left",
                        customClass: "notify-wrap",
                        duration: 4000,
                    });
                }
            }).catch((e) => console.log(e))
        },
        getFolderParentFolder(listFolder, l) {
            for (let index in listFolder) {
                if (listFolder[index].id == l) {
                    return listFolder[index];
                } else if (listFolder[index].folder) {
                    const result = this.getFolderParentFolder(listFolder[index].folder, l);
                    if (result != null) {
                        return result;
                    }
                }
            }
            return null;
        }
    }
}
</script>

<style>

</style>