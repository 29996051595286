<template>
    <el-dialog
        :title="$t('zimbra.zmMsg.uploadConflict')"
        :visible.sync="show"
        width="30%"
        custom-class="zm-dialog__update-conflict-file mw__450"
        :before-close="hiddenDialog"
    >
        <p v-html="$t('zimbra.zmMsg.uploadConflictDesc')"></p>
        <div class="conflict-form" v-if="lstData.length">
            <div class="item">
                <div class="left">
                    <span
                        v-for="(item, index) in options"
                        :key="index" :class="`link ${index > 0 ? 'name': ''}`"
                        @click="handleChangeAllOption(item)"
                    >
                        {{ item.label }}
                    </span>
                </div>
            </div>
            <div v-for="(item, index) in lstData" :key="index">
                <div class="item">
                    <div class="left">
                        <base-radio class="custom-radio"
                            :value="valOption[index]"
                            :options="options"
                            displayField="label"
                            valueField="id"
                            @change="handleChangeItem($event, index)"
                        />
                        <div class="name">{{ item.nameDoc }}</div>
                    </div>
                    <div class="right">
                        <a class="link" href="javascript:;" @click="handleDownloadFile(item)">{{ $t("zimbra.zmMsg.viewOld") }}</a>
                    </div>
                </div>
            </div>
        </div>
        <el-divider></el-divider>
        <div slot="footer" class="dialog-footer">
            <base-button class="btn-email default" @click="hiddenDialog">{{ $t('zimbra.zhMsg.actionTaskCancelTT') }}</base-button>
            <base-button class="btn-email primary" @click="handleUpdateConflictFile">{{ $t('zimbra.zmMsg.mobileStatusNeedProvision') }}</base-button>
        </div>
    </el-dialog>
</template>

<script>
import BriefcaseService from "@/services/BriefcaseService.js"
import BaseRadio from "@/components/modules/base/base-radio"
import BaseButton from "@/components/modules/base/base-button/btn-default"
import CommonUtils from "@/utils/common-utils"
import BriefcaseUtils from "@/utils/briefcase-utils"
import { mapGetters } from "vuex"
export default {
    mixins: [CommonUtils, BriefcaseUtils],
    props: {
        show: {
            type: Boolean,
            default: false
        },
        lstData: {
            type: Array,
            default: () => []
        }
    },
    components: {
        BaseRadio,
        BaseButton
    },
    computed: {
        ...mapGetters([
            "listBriefcaseSearch",
            "nodeBriefcaseCurrent"
        ])
    },
    watch: {
        show: {
            immediate: true,
            handler() {
                this.lstData.forEach(() => {
                    this.valOption.push(this.options[0].id)
                })
            }
        }
    },
    data() {
        return {
            options: [
                {
                    id: 1,
                    label: this.$t("zimbra.zhMsg.apptPtstNEW")
                },
                {
                    id: 2,
                    label: this.$t("zimbra.zmMsg.old")
                }
            ],
            valOption: []
        }
    },
    methods: {
        handleChangeItem(val, index) {
            this.valOption[index] = val
        },
        handleChangeAllOption(data) {
            this.valOption = this.valOption.map(() => data.id)
        },
        async handleUpdateConflictFile() {
            let _listUpdateFile = []
            this.valOption.forEach((item, index) => {
                if (item === this.options[0].id) {
                    _listUpdateFile.push(this.lstData[index])
                }
            })
            if (_listUpdateFile.length) {
                _listUpdateFile = _listUpdateFile.map(ele => {
                    const _briefcase = this.listBriefcaseSearch.find(item => item.name === ele.nameDoc)
                    if (_briefcase) {
                        ele.doc.id = _briefcase.id.toString()
                        ele.doc.ver = _briefcase.ver
                    }
                    return ele
                })
                const listRequest = []
                const listSuccess = []
                if (_listUpdateFile.length) {
                    _listUpdateFile.forEach(ele => listRequest.push(BriefcaseService.saveDocumentRequest(ele)))
                    await Promise.all(listRequest).then(listResp => {
                        listResp.forEach(resp => {
                            const _documentResponse = this.getResponseBody(resp).SaveDocumentResponse.doc
                            if (_documentResponse) listSuccess.push(_documentResponse)
                        })
                    }).catch((err) => {
                        console.log(err)
                        this.hiddenDialog()
                    })
                }
                if (listSuccess.length === listRequest.length) {
                    this.$root.$emit("handleSelectNode", this.nodeBriefcaseCurrent, true);
                    this.hiddenDialog()
                }
            } else {
                this.hiddenDialog()
            }
        },
        handleDownloadFile(data) {
            const { username } = this.getLocalStorageObject("zimbra_token")
            const restUrl = `${this.$configZimbra.ENDPOINT.importExportService}/${username}/${this.nodeBriefcaseCurrent.absFolderPath.replace("/", "")}/${encodeURIComponent(data.nameDoc)}`
            if (restUrl) {
                const _briefcase = this.listBriefcaseSearch.find(item => item.name === data.nameDoc)
                const ct = _briefcase.ct.split(";")[0]
                if (this.isDownloadable(ct) && !this._alwaysOpenInNewWindow(ct)) {
                    const iframe = document.createElement("iframe");
                    iframe.style.display = 'none';
                    iframe.src = restUrl;
                    iframe.setAttribute(
                        "download",
                        `${name}`
                    );
                    document.body.appendChild(iframe);
                    iframe.click();
                } else {
                    window.open(restUrl, "localhost");
                }
            }

        },
        hiddenDialog() {
            this.valOption = []
            this.$emit("hidden")
        }
    },
}
</script>