<template>
    <div id="menuLeftBriefcase">
        <div v-if="isLoading" class="pl-4 pr-4 pt-4">
            <el-skeleton :rows="12" :loading="isLoading" animated />
        </div>
        <template>
            <!-- Cặp tài liệu -->
            <div id="tree-upload-files">
                <TreeBriefcase
                    :highlight-default="true"
                    :show-info-folder="showInfoFolder"
                />
            </div>
            <!-- Tag -->
            <TagTree
                :show-info-folder="showInfoFolder"
            />

            <!-- TREE ZIMLET -->
            <ZimletTree
                :highlightDefault="highlightDefault"
                :showInfoFolder="showInfoFolder"
            />
            <!-- menu khi click vào tag bên phải -->
            <MenuRightTagClick />
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import TreeBriefcase from "../../components/TreeBriefCase.vue";
import TagTree from "@/components/modules/calendar/components/menu-left-calendar/tag-tree.vue"
import MenuRightTagClick from "@/components/modules/layouts/components/RightMenuTag.vue"
import ZimletTree from "@/components/modules/calendar/components/menu-left-calendar/zimlet-tree.vue"

export default {
    components: {
        TreeBriefcase,
        TagTree,
        MenuRightTagClick,
        ZimletTree
    },
    props: {
        highlightDefault: {
            type: Boolean,
            default: false,
        },
        showInfoFolder: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters([
            "briefcaseTreeFolders",
            "tags",
            "dataTableCurrent"
        ]),
        isLoading() {
            const _briefcase = this.briefcaseTreeFolders?.length || 0;
            const _tags = this.tags?.length || 0;
            const _zimlet = this.dataTableCurrent.length || 0
            return (_briefcase + _tags + _zimlet) === 0;
        }
    }
}
</script>

<style>

</style>