import AjxUtil from "./AjxUtil";
import AjxEnv from "./AjxEnv";
import Dwt from "./Dwt";
import DwtCssStyle from "./DwtCssStyle";

// fix tạm
const AjxMsg = {
    origMsg: '',
    forwardedMessage: '',
    origAppt: '',
    forwardedMessage1: '',
    from: '',
    to: '',
    subject: '',
    date: '',
    sent: '',
    cc: '',
}

const AjxStringUtil = function () { };
AjxStringUtil.SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
AjxStringUtil.TRIM_RE = /^\s+|\s+$/g;
AjxStringUtil.COMPRESS_RE = /\s+/g;
AjxStringUtil.ELLIPSIS = " ... ";
AjxStringUtil.ELLIPSIS_NO_SPACE = "...";
AjxStringUtil.LIST_SEP = ", ";

AjxStringUtil._NO_LIST = 0;
AjxStringUtil._ORDERED_LIST = 1;
AjxStringUtil._UNORDERED_LIST = 2;
AjxStringUtil._INDENT = "    ";
AjxStringUtil._NON_WHITESPACE = /\S+/;
AjxStringUtil._LF = /\n/;

// types of content related to finding original content; not all are used
AjxStringUtil.ORIG_UNKNOWN = "UNKNOWN";
AjxStringUtil.ORIG_QUOTED = "QUOTED";
AjxStringUtil.ORIG_SEP_STRONG = "SEP_STRONG";
AjxStringUtil.ORIG_SEP_WEAK = "SEP_WEAK";
AjxStringUtil.ORIG_WROTE_STRONG = "WROTE_STRONG";
AjxStringUtil.ORIG_WROTE_WEAK = "WROTE_WEAK";
AjxStringUtil.ORIG_HEADER = "HEADER";
AjxStringUtil.ORIG_LINE = "LINE";
AjxStringUtil.ORIG_SIG_SEP = "SIG_SEP";

// ID for an HR to mark it as ours
AjxStringUtil.HTML_SEP_ID = "zwchr";

// Regexes for finding stuff in msg content
AjxStringUtil.MSG_SEP_RE = new RegExp("^\\s*--+\\s*(" + AjxMsg.origMsg + "|" + AjxMsg.forwardedMessage + ")\\s*--+", "i");
AjxStringUtil.SIG_RE = /^(- ?-+)|(__+)\r?$/;
AjxStringUtil.SPLIT_RE = /\r\n|\r|\n/;
AjxStringUtil.HDR_RE = /^\s*\w+:/;
AjxStringUtil.COLON_RE = /\S+:$/;

AjxStringUtil.CRLF = "\r\n";
AjxStringUtil.CRLF2 = "\r\n\r\n";
AjxStringUtil.CRLF_HTML = "<br>";
AjxStringUtil.CRLF2_HTML = "<div><br></div><div><br></div>";

// regexes for finding a delimiter such as "On DATE, NAME (EMAIL) wrote:"
AjxStringUtil.ORIG_EMAIL_RE = /[^@\s]+@[A-Za-z0-9-]{2,}(\.[A-Za-z0-9-]{2,})+/;    // see AjxUtil.EMAIL_FULL_RE
AjxStringUtil.ORIG_DATE_RE = /\d+\s*(\/|-|, )20\d\d/;                                    // matches "03/07/2014" or "March 3, 2014" by looking for year 20xx
AjxStringUtil.ORIG_INTRO_RE = new RegExp("^(-{2,}|" + AjxMsg.on + "\\s+)", "i");

const ZmItem = {};
ZmItem.NOTES_SEPARATOR = "*~*~*~*~*~*~*~*~*~*";

// nodes to ignore; they won't have anything we're interested in
AjxStringUtil.IGNORE_NODE_LIST = ["#comment", "br", "script", "select", "style"];
AjxStringUtil.IGNORE_NODE = AjxUtil.arrayAsHash(AjxStringUtil.IGNORE_NODE_LIST);

AjxStringUtil.EMPTY_BODY = "<html><body></body></html>";

AjxStringUtil.SPACE_ENCODE_MAP = { ' ': '&nbsp;', '>': '&gt;', '<': '&lt;', '&': '&amp;', '\n': '<br>' };
AjxStringUtil.ENCODE_MAP = { '>': '&gt;', '<': '&lt;', '&': '&amp;' };


AjxStringUtil.WRAP_LENGTH = 80;
AjxStringUtil.HDR_WRAP_LENGTH = 120;
AjxStringUtil.MAX_HTMLNODE_COUNT = 250;

// ID for a BLOCKQUOTE to mark it as ours
AjxStringUtil.HTML_QUOTE_COLOR = "#1010FF";
AjxStringUtil.HTML_QUOTE_STYLE = "color:#000;font-weight:normal;font-style:normal;text-decoration:none;font-family:Helvetica,Arial,sans-serif;font-size:12pt;";
AjxStringUtil.HTML_QUOTE_PREFIX_PRE = '<blockquote style="border-left:2px solid ' +
    AjxStringUtil.HTML_QUOTE_COLOR +
    ';margin-left:5px;padding-left:5px;' +
    AjxStringUtil.HTML_QUOTE_STYLE +
    '">';
AjxStringUtil.HTML_QUOTE_PREFIX_POST = '</blockquote>';
AjxStringUtil.HTML_QUOTE_NONPREFIX_PRE = '<div style="' +
    AjxStringUtil.HTML_QUOTE_STYLE +
    '">';
AjxStringUtil.HTML_QUOTE_NONPREFIX_POST = '</div><br/>';


// regex for removing empty doc tags from an HTML string
AjxStringUtil.DOC_TAG_REGEX = /<\/?(html|head|body)>/gi;
AjxStringUtil.URL_PARSE_RE = new RegExp("^(?:([^:/?#.]+):)?(?://)?(([^:/?#]*)(?::(\\d*))?)?((/(?:[^?#](?![^?#/]*\\.[^?#/.]+(?:[\\?#]|$)))*/?)?([^?#/]*))?(?:\\?([^#]*))?(?:#(.*))?");
AjxStringUtil.MSG_REGEXES = [
    {
        // the two most popular quote characters, > and |
        type: AjxStringUtil.ORIG_QUOTED,
        regex: /^\s*(>|\|)/
    },
    {
        // marker for Original or Forwarded message, used by ZCS and others
        type: AjxStringUtil.ORIG_SEP_STRONG,
        regex: new RegExp("^\\s*--+\\s*(" + AjxMsg.origMsg + "|" + AjxMsg.forwardedMessage + "|" + AjxMsg.origAppt + ")\\s*--+\\s*$", "i")
    },
    {
        // marker for Original or Forwarded message, used by ZCS and others
        type: AjxStringUtil.ORIG_SEP_STRONG,
        regex: new RegExp("^" + AjxMsg.forwardedMessage1 + "$", "i")
    },
    {
        // one of the commonly quoted email headers
        type: AjxStringUtil.ORIG_HEADER,
        regex: new RegExp("^\\s*(" + [AjxMsg.from, AjxMsg.to, AjxMsg.subject, AjxMsg.date, AjxMsg.sent, AjxMsg.cc].join("|") + ")")
    },
    {
        // some clients use a series of underscores as a text-mode separator (text version of <hr>)
        type: AjxStringUtil.ORIG_LINE,
        regex: /^\s*_{5,}\s*$/
    }/*,
	{
		// in case a client doesn't use the exact words above
		type:	AjxStringUtil.ORIG_SEP_WEAK,
		regex:	/^\s*--+\s*[\w\s]+\s*--+$/
	},
	{
		// internet style signature separator
		type:	AjxStringUtil.ORIG_SIG_SEP,
		regex:	/^- ?-\s*$/
	}*/
];
AjxStringUtil.parseURL = function (sourceUri) {
    const names = ["source","protocol","authority","domain","port","path","directoryPath","fileName","query","anchor"];
	const parts = AjxStringUtil.URL_PARSE_RE.exec(sourceUri);
	const uri = {};

	for (var i = 0; i < names.length; i++) {
		uri[names[i]] = (parts[i] ? parts[i] : "");
	}

	if (uri.directoryPath.length > 0) {
		uri.directoryPath = uri.directoryPath.replace(/\/?$/, "/");
	}

	return uri;
};
const fixCrossDomainReference = (url, restUrlAuthority, convertToRelativeURL) => {
    const urlParts = AjxStringUtil.parseURL(url);
	if (urlParts.authority == window.location.host) {
		return url;
	}

	if ((restUrlAuthority && url.indexOf(restUrlAuthority) >=0) || !restUrlAuthority) {
        if (convertToRelativeURL) {
            url = urlParts.path;
        }
        else {
            const oldRef = urlParts.protocol + "://" + urlParts.authority;
            const newRef = window.location.protocol + "//" + window.location.host;
            url = url.replace(oldRef, newRef);
        }
	}
	return url;
}
const getOriginalContent = (text, isHtml) => {  

    if (!text) { return ""; }

    if (isHtml) {
        return _getOriginalHtmlContent(text);
    }

    var results = [];
    var lines = text.split(AjxStringUtil.SPLIT_RE);

    var curType, curBlock = [], count = {}, isMerged, unknownBlock, isBugzilla = false;
    for (let i = 0; i < lines.length; i++) {
        var line = lines[i];
        var testLine = trim(line);

        // blank lines are just added to the current block
        if (!AjxStringUtil._NON_WHITESPACE.test(testLine)) {
            curBlock.push(line);
            continue;
        }

        // Bugzilla summary looks like QUOTED; it should be treated as UNKNOWN
        if ((testLine.indexOf("| DO NOT REPLY") === 0) && (lines[i + 2].indexOf("bugzilla") !== -1)) {
            isBugzilla = true;
        }

        var type = _getLineType(testLine);
        if (type === AjxStringUtil.ORIG_QUOTED) {
            type = isBugzilla ? AjxStringUtil.ORIG_UNKNOWN : type;
        }
        else {
            isBugzilla = false;
        }

        // WROTE can stretch over two lines; if so, join them into one line
        var nextLine = lines[i + 1];
        isMerged = false;
        if (nextLine && (type === AjxStringUtil.ORIG_UNKNOWN) && AjxStringUtil.ORIG_INTRO_RE.test(testLine) && nextLine.match(/\w+:$/)) {
            testLine = [testLine, nextLine].join(" ");
            type = _getLineType(testLine);
            isMerged = true;
        }

        // LINE sometimes used as delimiter; if HEADER follows, lump it in with them
        if (type === AjxStringUtil.ORIG_LINE) {
            var j = i + 1;
            nextLine = lines[j];
            while (!AjxStringUtil._NON_WHITESPACE.test(nextLine) && j < lines.length) {
                nextLine = lines[++j];
            }
            var nextType = nextLine && _getLineType(nextLine);
            if (nextType === AjxStringUtil.ORIG_HEADER) {
                type = AjxStringUtil.ORIG_HEADER;
            }
            else {
                type = AjxStringUtil.ORIG_UNKNOWN;
            }
        }

        // see if we're switching to a new type; if so, package up what we have so far
        if (curType) {
            if (curType !== type) {
                results.push({ type: curType, block: curBlock });
                unknownBlock = (curType === AjxStringUtil.ORIG_UNKNOWN) ? curBlock : unknownBlock;
                count[curType] = count[curType] ? count[curType] + 1 : 1;
                curBlock = [];
                curType = type;
            }
        }
        else {
            curType = type;
        }

        if (isMerged && (type === AjxStringUtil.ORIG_WROTE_WEAK || type === AjxStringUtil.ORIG_WROTE_STRONG)) {
            curBlock.push(line);
            curBlock.push(nextLine);
            i++;
            isMerged = false;
        }
        else {
            curBlock.push(line);
        }
    }

    // Handle remaining content
    if (curBlock.length) {
        results.push({ type: curType, block: curBlock });
        unknownBlock = (curType === AjxStringUtil.ORIG_UNKNOWN) ? curBlock : unknownBlock;
        count[curType] = count[curType] ? count[curType] + 1 : 1;
    }

    // Now it's time to analyze all these blocks that we've classified

    // Check for UNKNOWN followed by HEADER
    var first = results[0], second = results[1];
    var originalText = null;
    if (first && first.type === AjxStringUtil.ORIG_UNKNOWN && second && (second.type === AjxStringUtil.ORIG_HEADER || second.type === AjxStringUtil.ORIG_WROTE_STRONG)) {
        originalText = _getTextFromBlock(first.block);
        if (originalText) {
            var third = results[2];
            if (third && third.type === AjxStringUtil.ORIG_UNKNOWN) {
                var originalThirdText = _getTextFromBlock(third.block);
                if (originalThirdText && originalThirdText.indexOf(ZmItem.NOTES_SEPARATOR) !== -1) {
                    return originalText + originalThirdText;
                }
            }
            return originalText;
        }
    }

    // check for special case of WROTE preceded by UNKNOWN, followed by mix of UNKNOWN and QUOTED (inline reply)
    originalText = _checkInlineWrote(count, results, false);
    if (originalText) {
        return originalText;
    }

    // If we found quoted content and there's exactly one UNKNOWN block, return it.
    if (count[AjxStringUtil.ORIG_UNKNOWN] === 1 && count[AjxStringUtil.ORIG_QUOTED] > 0) {
        originalText = _getTextFromBlock(unknownBlock);
        if (originalText) {
            return originalText;
        }
    }

    // If we have a STRONG separator (eg "--- Original Message ---"), consider it authoritative and return the text that precedes it
    if (count[AjxStringUtil.ORIG_SEP_STRONG] > 0) {
        var block = [];
        for (let i = 0; i < results.length; i++) {
            var result = results[i];
            if (result.type === AjxStringUtil.ORIG_SEP_STRONG) {
                break;
            }
            block = block.concat(result.block);
        }
        originalText = _getTextFromBlock(block);
        if (originalText) {
            return originalText;
        }
    }

    return text;
};

const _checkInlineWrote = function (count, results) {

    if (count[AjxStringUtil.ORIG_WROTE_STRONG] > 0) {
        var unknownBlock, foundSep = false, afterSep = {};
        for (var i = 0; i < results.length; i++) {
            var result = results[i], type = result.type;
            if (type === AjxStringUtil.ORIG_WROTE_STRONG) {
                foundSep = true;
            }
            else if (type === AjxStringUtil.ORIG_UNKNOWN && !foundSep) {
                if (unknownBlock) {
                    return null;
                }
                else {
                    unknownBlock = result.block;
                }
            }
            else if (foundSep) {
                afterSep[type] = true;
            }
        }

        var mixed = (afterSep[AjxStringUtil.ORIG_UNKNOWN] && afterSep[AjxStringUtil.ORIG_QUOTED]);
        var endsWithUnknown = (count[AjxStringUtil.ORIG_UNKNOWN] === 2 && results[results.length - 1].type === AjxStringUtil.ORIG_UNKNOWN);
        if (unknownBlock && (!mixed || endsWithUnknown)) {
            var originalText = _getTextFromBlock(unknownBlock);
            if (originalText) {
                return originalText;
            }
        }
    }
};

const _getTextFromBlock = function (block) {
    if (!(block && block.length)) { return null; }
    var originalText = block.join("\n") + "\n";
    originalText = originalText.replace(/\s+$/, "\n");
    return (AjxStringUtil._NON_WHITESPACE.test(originalText)) ? originalText : null;
};

/**
 * For HTML, we strip off the html, head, and body tags and stick the rest in a temporary DOM node so that
 * we can go element by element. If we find one that is recognized as a separator, we remove all subsequent elements.
 *
 * @param {string}	text		message body content
 *
 * @return	{string}	original content if quoted content was found, otherwise NULL
 * @private
 */
const _getOriginalHtmlContent = (text) => {
    // strip <script> tags (which should not be there)
    var htmlNode = _writeToTestIframeDoc(text);
    while (AjxStringUtil.SCRIPT_REGEX.test(text)) {
        text = text.replace(AjxStringUtil.SCRIPT_REGEX, "");
    }

    var done = false, nodeList = [];
    _flatten(htmlNode, nodeList);

    var ln = nodeList.length, i, results = [], count = {}, el, prevEl, nodeName, type, prevType, sepNode;
    for (i = 0; i < ln; i++) {
        el = nodeList[i];
        if (el.nodeType === AjxUtil.ELEMENT_NODE) {
            el.normalize();
        }
        nodeName = el.nodeName.toLowerCase();
        type = _checkNode(nodeList[i]);

        // Check for a multi-element "wrote:" attribution (usually a combo of #text and A nodes), for example:
        //
        //     On Feb 28, 2014, at 3:42 PM, Joe Smith &lt;<a href="mailto:jsmith@zimbra.com" target="_blank">jsmith@zimbra.com</a>&gt; wrote:

        // If the current node is a #text with a date or "On ...", find #text nodes within the next ten nodes, concatenate them, and check the result.
        if (type === AjxStringUtil.ORIG_UNKNOWN && el.nodeName === '#text' &&
            (AjxStringUtil.ORIG_DATE_RE.test(el.nodeValue) || AjxStringUtil.ORIG_INTRO_RE.test(el.nodeValue))) {

            var str = el.nodeValue;
            for (var j = 1; j < 10; j++) {
                var el1 = nodeList[i + j];
                if (el1 && el1.nodeName === '#text') {
                    str += el1.nodeValue;
                    if (/:$/.test(str)) {
                        type = _getLineType(trim(str));
                        if (type === AjxStringUtil.ORIG_WROTE_STRONG) {
                            i = i + j;
                            break;
                        }
                    }
                }
            }
        }

        if (type !== null) {
            results.push({ type: type, node: el, nodeName: nodeName });
            count[type] = count[type] ? count[type] + 1 : 1;
            // definite separator
            if (type === AjxStringUtil.ORIG_SEP_STRONG || type === AjxStringUtil.ORIG_WROTE_STRONG) {
                sepNode = el;
                done = true;
                break;
            }
            // some sort of line followed by a header
            if (type === AjxStringUtil.ORIG_HEADER && prevType === AjxStringUtil.ORIG_LINE) {
                sepNode = prevEl;
                done = true;
                break;
            }
            prevEl = el;
            prevType = type;
        }
    }

    if (sepNode) {
        _prune(sepNode, true);
    }

    // convert back to text, restoring html, head, and body nodes; if there is nothing left, return original text
    var result = done && htmlNode.textContent ? "<html>" + htmlNode.innerHTML + "</html>" : text;

    _removeTestIframeDoc();
    return result;
};

const _removeTestIframeDoc = function () {
    var iframe = document.getElementById(AjxStringUtil.__curIframeId);
    if (iframe) {
        iframe.parentNode.removeChild(iframe);
    }
    AjxStringUtil.__curIframeId = null;
};

const _prune = function (node, clipNode) {

    var p = node && node.parentNode;
    // clip all subsequent nodes
    while (p && p.lastChild && p.lastChild !== node) {
        p.removeChild(p.lastChild);
    }
    // clip the node if asked
    if (clipNode && p && p.lastChild === node) {
        p.removeChild(p.lastChild);
    }
    var nodeName = p && p.nodeName.toLowerCase();
    if (p && nodeName !== 'body' && nodeName !== 'html') {
        _prune(p, false);
    }
};
const trim = function (str, compress, space) {

    if (!str) { return ""; }

    var trim_re = AjxStringUtil.TRIM_RE;

    var compress_re = AjxStringUtil.COMPRESS_RE;
    if (space) {
        trim_re = new RegExp("^" + space + "+|" + space + "+$", "g");
        compress_re = new RegExp(space + "+", "g");
    } else {
        space = " ";
    }
    str = str.replace(trim_re, '');
    if (compress) {
        str = str.replace(compress_re, space);
    }

    return str;
};

const _checkNode = function (el) {

    if (!el) { return null; }

    var nodeName = el.nodeName.toLowerCase();
    var type = null;

    // Text node: test against our regexes
    if (nodeName === "#text") {
        var content = trim(el.nodeValue);
        if (AjxStringUtil._NON_WHITESPACE.test(content)) {
            type = _getLineType(content);
        }
    }
    // HR: look for a couple different forms that are used to delimit quoted content
    else if (nodeName === "hr") {
        // see if the HR is ours, or one commonly used by other mail clients such as Outlook
        if (el.id === AjxStringUtil.HTML_SEP_ID || (el.size === "2" && el.width === "100%" && el.align === "center")) {
            type = AjxStringUtil.ORIG_SEP_STRONG;
        }
        else {
            type = AjxStringUtil.ORIG_LINE;
        }
    }
    // PRE: treat as one big line of text (should maybe go line by line)
    else if (nodeName === "pre") {
        type = _checkNodeContent(el);
    }
    // DIV: check for Outlook class used as delimiter, or a top border used as a separator, and finally just
    // check the text content
    else if (nodeName === "div") {
        if (el.className === "OutlookMessageHeader" || el.className === "gmail_quote") {
            type = AjxStringUtil.ORIG_SEP_STRONG;
        }
        else if (el.style.borderTop) {
            var styleObj = DwtCssStyle.getComputedStyleObject(el);
            if (styleObj && styleObj.borderTopWidth && parseInt(styleObj.borderTopWidth) === 1 && styleObj.borderTopColor) {
                type = AjxStringUtil.ORIG_SEP_STRONG;
            }
        }
        type = type || _checkNodeContent(el);
    }
    // SPAN: check text content
    else if (nodeName === "span") {
        type = type || _checkNodeContent(el);
    }
    // IMG: treat as original content
    else if (nodeName === "img") {
        type = AjxStringUtil.ORIG_UNKNOWN;
    }
    // BLOCKQUOTE: treat as quoted section
    else if (nodeName === "blockquote") {
        type = AjxStringUtil.ORIG_QUOTED;
    }

    return type;
};

const _checkNodeContent = function (node) {
    var content = node.textContent || '';
    if (!AjxStringUtil._NON_WHITESPACE.test(content) || content.length > 200) {
        return null;
    }
    // We're really only interested in SEP_STRONG and WROTE_STRONG
    var type = _getLineType(content);
    return (type === AjxStringUtil.ORIG_SEP_STRONG || type === AjxStringUtil.ORIG_WROTE_STRONG) ? type : null;
};

const _getLineType = function (testLine) {

    var type = AjxStringUtil.ORIG_UNKNOWN;

    // see if the line matches any known delimiters or quote patterns
    for (var j = 0; j < AjxStringUtil.MSG_REGEXES.length; j++) {
        var msgTest = AjxStringUtil.MSG_REGEXES[j];
        const regex = msgTest.regex;
        if (regex.test(testLine.toLowerCase())) {
            // line that starts and ends with | is considered ASCII art (eg a table) rather than quoted
            if (msgTest.type == AjxStringUtil.ORIG_QUOTED && /^\s*\|.*\|\s*$/.test(testLine)) {
                continue;
            }
            type = msgTest.type;
            break;	// first match wins
        }
    }

    if (type === AjxStringUtil.ORIG_UNKNOWN) {
        // "so-and-so wrote:" takes a lot of different forms; look for various common parts and
        // assign points to determine confidence
        var m = testLine.match(/(\w+):$/);
        var verb = m && m[1] && m[1].toLowerCase();
        if (verb) {
            var points = 0;
            // look for "wrote:" (and discount "changed:", which is used by Bugzilla)
            points = points + (verb === AjxMsg.wrote) ? 5 : (verb === AjxMsg.changed) ? 0 : 2;
            if (AjxStringUtil.ORIG_EMAIL_RE.test(testLine)) {
                points += 4;
            }
            if (AjxStringUtil.ORIG_DATE_RE.test(testLine)) {
                points += 3;
            }
            // var regEx = new RegExp("^(--|" + AjxMsg.on + ")", "i");
            if (AjxStringUtil.ORIG_INTRO_RE.test(testLine)) {
                points += 1;
            }
            if (points >= 7) {
                type = AjxStringUtil.ORIG_WROTE_STRONG;
            }
            else if (points >= 5) {
                type = AjxStringUtil.ORIG_WROTE_WEAK;
            }
        }
    }

    return type;
};


const _flatten = function (node, list) {

    var nodeName = node && node.nodeName.toLowerCase();
    if (AjxStringUtil.IGNORE_NODE[nodeName]) {
        return;
    }

    list.push(node);

    var children = node.childNodes || [];
    for (var i = 0; i < children.length; i++) {
        _flatten(children[i], list);
    }
};

/**
 * Checks the given HTML to see if it is "safe", and cleans it up if it is. It must have only
 * the tags in the given list, otherwise false is returned. Attributes in the given list will
 * be removed. It is not necessary to include "#text", "html", "head", and "body" in the list
 * of allowed tags.
 *
 * @param {string}	html			HTML text
 * @param {array}	okTags			whitelist of allowed tags
 * @param {array}	untrustedAttrs	list of attributes to not allow in non-iframe.
 */
// const checkForCleanHtml = (html, okTags, untrustedAttrs) => {

//     var htmlNode = _writeToTestIframeDoc(html);
//     var ctxt = {
//         allowedTags: AjxUtil.arrayAsHash(okTags),
//         untrustedAttrs: untrustedAttrs || []
//     };
//     _traverseCleanHtml(htmlNode, ctxt);

//     var result = "<html>" + htmlNode.innerHTML + "</html>";

//     var width = Math.max(htmlNode.scrollWidth, htmlNode.lastChild.scrollWidth);

//     _removeTestIframeDoc();
//     return { html: result, width: width, useIframe: ctxt.fail };
// };


const _writeToTestIframeDoc = (html) => {
    var iframe;

    if (!AjxStringUtil.__curIframeId) {
        iframe = document.createElement("IFRAME");
        AjxStringUtil.__curIframeId = iframe.id = Dwt.getNextId();

        // position offscreen rather than set display:none so we can get metrics if needed; no perf difference seen
        iframe.setAttribute('aria-hidden', true);
        document.body.appendChild(iframe);
        Dwt.setPosition(iframe, Dwt.ABSOLUTE_STYLE);
        Dwt.setLocation(iframe, Dwt.LOC_NOWHERE, Dwt.LOC_NOWHERE);
    } else {
        iframe = document.getElementById(AjxStringUtil.__curIframeId);
    }

    var idoc = Dwt.getIframeDoc(iframe);

    html = html && html.replace(AjxStringUtil.IMG_SRC_CID_REGEX, '<img $1 pnsrc="cid:');
    idoc.open();
    idoc.write(html);
    idoc.close();

    return idoc.childNodes[0];
};

const htmlEncode = (str, includeSpaces) => {

    if (!str) { return ""; }
    if (typeof (str) != "string") {
        str = str.toString ? str.toString() : "";
    }

    if (!AjxEnv.isSafari || AjxEnv.isSafariNightly) {
    if (includeSpaces) {
        return str.replace(/[<>&]/g, function (htmlChar) { return AjxStringUtil.ENCODE_MAP[htmlChar]; }).replace(/ {2}/g, ' &nbsp;');
    } else {
        return str.replace(/[<>&]/g, function (htmlChar) { return AjxStringUtil.ENCODE_MAP[htmlChar]; });
    }
    } else {
        if (includeSpaces) {
            return str.replace(/[&]/g, '&amp;').replace(/ {2}/g, ' &nbsp;').replace(/[<]/g, '&lt;').replace(/[>]/g, '&gt;');
        } else {
            return str.replace(/[&]/g, '&amp;').replace(/[<]/g, '&lt;').replace(/[>]/g, '&gt;');
        }
    }
};
const convertToHtml = (str, quotePrefix, openTag, closeTag) => {
    openTag = openTag || "<blockquote>";
    closeTag = closeTag || "</blockquote>";

    if (!str) { return ""; }

    str = htmlEncode(str);
    if (quotePrefix) {
        // Convert a section of lines prefixed with > or |
        // to a section encapsuled in <blockquote> tags
        var prefix_re = /^(>|&gt;|\|\s+)/;
        var lines = str.split(/\r?\n/);
        var level = 0;
        for (var i = 0; i < lines.length; i++) {
            var line = lines[i];
            if (line.length > 0) {
                var lineLevel = 0;
                // Remove prefixes while counting how many there are on the line
                while (line.match(prefix_re)) {
                    line = line.replace(prefix_re, "");
                    lineLevel++;
                }
                // If the lineLevel has changed since the last line, add blockquote start or end tags, and adjust level accordingly
                while (lineLevel > level) {
                    line = openTag + line;
                    level++;
                }
                while (lineLevel < level) {
                    lines[i - 1] = lines[i - 1] + closeTag;
                    level--;
                }
            }
            lines[i] = line;
        }
        while (level > 0) {
            lines.push(closeTag);
            level--;
        }

        str = lines.join("\n");
    }

    str = str
        .replace(/ {2}/mg, ' &nbsp;')
        .replace(/^ /mg, '&nbsp;')
        .replace(/\t/mg, "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;")
        .replace(/\r?\n/mg, "<br>");
    return str;
}

const _traverse = (el, text, idx, listType, listLevel, bulletNum, ctxt, convertor, onlyOneNewLinePerP) => {

    var nodeName = el.nodeName.toLowerCase();

    var result = null;
    if (convertor && convertor[nodeName]) {
        result = convertor[nodeName](el, ctxt);
    }

    if (result != null) {
        text[idx++] = result;
    } else if (nodeName == "#text") {
        if (el.nodeValue.search(AjxStringUtil._NON_WHITESPACE) != -1) {
            if (ctxt.lastNode == "ol" || ctxt.lastNode == "ul") {
                text[idx++] = "\n";
            }
            if (ctxt.isPreformatted) {
                text[idx++] = trim(el.nodeValue) + " ";
            } else {
                text[idx++] = trim(el.nodeValue.replace(AjxStringUtil._LF, " "), true) + " ";
            }
        }
    } else if (nodeName == "p") {
        text[idx++] = onlyOneNewLinePerP ? "\n" : "\n\n";
    } else if (nodeName === "a") {
        if (el.href) {
            //format as [ href | text ] (if no text, format as [ href ]
            text[idx++] = "[ ";
            text[idx++] = el.href;
            if (el.textContent) {
                text[idx++] = " | ";
                text[idx++] = el.textContent;
            }
            text[idx++] = " ] ";
            return idx; // returning since we take care of all the child nodes via the "textContent" above. No need to parse further.
        }
    } else if (listType == AjxStringUtil._NO_LIST && (nodeName == "br" || nodeName == "hr")) {
        text[idx++] = "\n";
    } else if (nodeName == "ol" || nodeName == "ul") {
        text[idx++] = "\n";
        if (el.parentNode.nodeName.toLowerCase() != "li" && ctxt.lastNode != "br" && ctxt.lastNode != "hr") {
            text[idx++] = "\n";
        }
        listType = (nodeName == "ol") ? AjxStringUtil._ORDERED_LIST : AjxStringUtil._UNORDERED_LIST;
        listLevel++;
        bulletNum = 0;
    } else if (nodeName == "li") {
        for (var i = 0; i < listLevel; i++) {
            text[idx++] = AjxStringUtil._INDENT;
        }
        if (listType == AjxStringUtil._ORDERED_LIST) {
            text[idx++] = bulletNum + ". ";
        } else {
            text[idx++] = "\u002A "; // TODO AjxMsg.bullet
        }
    } else if (nodeName == "tr" && el.parentNode.firstChild != el) {
        text[idx++] = "\n";
    } else if (nodeName == "td" && el.parentNode.firstChild != el) {
        text[idx++] = "\t";
    } else if (nodeName == "div" || nodeName == "address") {
        if (idx && text[idx - 1] !== "\n") {
            text[idx++] = "\n";
        }
    } else if (nodeName == "blockquote") {
        text[idx++] = "\n\n";
    } else if (nodeName == "pre") {
        if (idx && text[idx - 1] !== "\n") {
            text[idx++] = "\n";
        }
        ctxt.isPreformatted = true;
    } else if (nodeName == "#comment" ||
        nodeName == "script" ||
        nodeName == "select" ||
        nodeName == "style") {
        return idx;
    }

    var childNodes = el.childNodes;
    var len = childNodes.length;
    for (let i = 0; i < len; i++) {
        var tmp = childNodes[i];
        if (tmp.nodeType == 1 && tmp.tagName.toLowerCase() == "li") {
            bulletNum++;
        }
        idx = _traverse(tmp, text, idx, listType, listLevel, bulletNum, ctxt, convertor, onlyOneNewLinePerP);
    }

    if (convertor && convertor["/" + nodeName]) {
        text[idx++] = convertor["/" + nodeName](el);
    }

    if (nodeName == "h1" || nodeName == "h2" || nodeName == "h3" || nodeName == "h4"
        || nodeName == "h5" || nodeName == "h6" || nodeName == "div" || nodeName == "address") {
        if (idx && text[idx - 1] !== "\n") {
            text[idx++] = "\n";
        }
        ctxt.list = false;
    } else if (nodeName == "pre") {
        if (idx && text[idx - 1] !== "\n") {
            text[idx++] = "\n";
        }
        ctxt.isPreformatted = false;
    } else if (nodeName == "li") {
        if (!ctxt.list) {
            text[idx++] = "\n";
        }
        ctxt.list = false;
    } else if (nodeName == "ol" || nodeName == "ul") {
        ctxt.list = true;
    } else if (nodeName != "#text") {
        ctxt.list = false;
    }

    ctxt.lastNode = nodeName;
    return idx;
};

const convertHtml2Text = (domRoot, convertor, onlyOneNewLinePerP) => {

    if (!domRoot) { return ""; }

    if (convertor && AjxUtil.isFunction(convertor._before)) {
        domRoot = convertor._before(domRoot);
    }

    if (typeof domRoot == "string") {
        let domNode = document.createElement("SPAN");
        domNode.innerHTML = domRoot;
        domRoot = domNode;
    }
    const text = [];
    const idx = 0;
    const ctxt = {};
    _traverse(domRoot, text, idx, AjxStringUtil._NO_LIST, 0, 0, ctxt, convertor, onlyOneNewLinePerP);

    let result = text.join("");

    if (convertor && AjxUtil.isFunction(convertor._after)) {
        result = convertor._after(result);
    }

    return result;
};


AjxStringUtil.SPACE_WORD_RE = new RegExp("\\s*\\S+", "g");
/**
 * Splits the line into words, keeping leading whitespace with each word.
 *
 * @param {string}	line	the text to split
 *
 * @return {array} the array of words
 */
const splitKeepLeadingWhitespace = (line) => {
    var words = [], result;
    // eslint-disable-next-line no-cond-assign
    while (result = AjxStringUtil.SPACE_WORD_RE.exec(line)) {
        words.push(result[0]);
    }
    return words;
};

const wordWrap = (params) => {

    if (!(params && params.text)) { return ""; }

    var text = params.text;
    var before = params.before || "";
    var after = params.after || "";
    var isFlowed = params.isFlowed;

    // For HTML, just surround the content with the before and after, which is
    // typically a block-level element that puts a border on the left
    if (params.htmlMode) {
        before = params.before || (params.prefix ? AjxStringUtil.HTML_QUOTE_PREFIX_PRE : AjxStringUtil.HTML_QUOTE_NONPREFIX_PRE);
        after = params.after || (params.prefix ? AjxStringUtil.HTML_QUOTE_PREFIX_POST : AjxStringUtil.HTML_QUOTE_NONPREFIX_POST);
        return [before, text, after].join("");
    }

    var max = params.len || (params.isHeaders ? AjxStringUtil.HDR_WRAP_LENGTH : AjxStringUtil.WRAP_LENGTH);
    var prefixChar = params.prefix || "";
    var eol = "\n";

    var lines = text.split(AjxStringUtil.SPLIT_RE);
    var words = [];

    // Divides lines into words. Each word is part of a hash that also has
    // the word's prefix, whether it's a paragraph break, and whether it
    // needs to be preserved at the start or end of a line.
    for (var l = 0, llen = lines.length; l < llen; l++) {
        let line = lines[l];
        // get this line's prefix
        let m = line.match(/^([\s>\\|]+)/);
        let prefix = m ? m[1] : "";
        if (prefix) {
            line = line.substr(prefix.length);
        }
        if (AjxStringUtil._NON_WHITESPACE.test(line)) {
            let wds = splitKeepLeadingWhitespace(line);
            if (wds && wds[0] && wds[0].length) {
                var mustStart = AjxStringUtil.MSG_SEP_RE.test(line) || AjxStringUtil.COLON_RE.test(line) ||
                    AjxStringUtil.HDR_RE.test(line) || params.isHeaders || AjxStringUtil.SIG_RE.test(line);
                var mustEnd = params.preserveReturns;
                if (isFlowed) {
                    let m = line.match(/( +)$/);
                    if (m) {
                        wds[wds.length - 1] += m[1];	// preserve trailing space at end of line
                        mustEnd = false;
                    }
                    else {
                        mustEnd = true;
                    }
                }
                for (var w = 0, wlen = wds.length; w < wlen; w++) {
                    words.push({
                        w: wds[w],
                        prefix: prefix,
                        mustStart: (w === 0) && mustStart,
                        mustEnd: (w === wlen - 1) && mustEnd
                    });
                }
            }
        } else {
            // paragraph marker
            words.push({
                para: true,
                prefix: prefix
            });
        }
    }

    // Take the array of words and put them back together. We break for a new line
    // when we hit the max line length, change prefixes, or hit a word that must start a new line.
    let result = "", curLen = 0, wds = [], curPrefix = null;
    for (var i = 0, len = words.length; i < len; i++) {
        var word = words[i];
        let w = word.w, prefix = word.prefix;
        let addPrefix = !prefixChar ? "" : curPrefix ? prefixChar : prefixChar + " ";
        var pl = (curPrefix === null) ? 0 : curPrefix.length;
        pl = 0;
        var newPrefix = addPrefix + (curPrefix || "");
        if (word.para) {
            // paragraph break - output what we have, then add a blank line
            if (wds.length) {
                result += newPrefix + wds.join("").replace(/^ +/, "") + eol;
            }
            if (i < words.length - 1) {
                curPrefix = prefix;
                addPrefix = !prefixChar ? "" : curPrefix ? prefixChar : prefixChar + " ";
                newPrefix = addPrefix + (curPrefix || "");
                result += newPrefix + eol;
            }
            wds = [];
            curLen = 0;
            curPrefix = null;
        } else if ((pl + curLen + w.length <= max) && (prefix === curPrefix || curPrefix === null) && !word.mustStart) {
            // still room left on the current line, add the word
            wds.push(w);
            curLen += w.length;
            curPrefix = prefix;
            if (word.mustEnd && words[i + 1]) {
                words[i + 1].mustStart = true;
            }
        } else {
            // no more room - output what we have and start a new line
            if (wds.length) {
                result += newPrefix + wds.join("").replace(/^ +/, "") + eol;
            }
            wds = [w];
            curLen = w.length;
            curPrefix = prefix;
            if (word.mustEnd && words[i + 1]) {
                words[i + 1].mustStart = true;
            }
        }
    }

    // handle last line
    if (wds.length) {
        let addPrefix = !prefixChar ? "" : wds[0].prefix ? prefixChar : prefixChar + " ";
        let newPrefix = addPrefix + (curPrefix || "");
        result += newPrefix + wds.join("").replace(/^ /, "") + eol;
    }

    return [before, result, after].join("");
};

/**
 * Returns the string repeated the given number of times.
 *
 * @param {string}	str		a string
 * @param {number}	num		number of times to repeat the string
 * @return	{string}	the string
 */
const repeat = (str, num) => {
    var text = "";
    for (var i = 0; i < num; i++) {
        text += str;
    }
    return text;
};


/**
 * Removes non-content HTML from the beginning and end. The idea is to remove anything that would
 * appear to the user as blank space. This function is an approximation since that's hard to do,
 * especially when dealing with HTML as a string.
 *
 * @param {String}  html    HTML to fix
 * @return {String} trimmed HTML
 * @adapts AjxStringUtil.trimHtml
 */
const trimHtml = (html) => {

    if (!html) {
        return '';
    }
    var trimmedHtml = html;

    // remove doc-level tags if they don't have attributes
    trimmedHtml = trimmedHtml.replace(AjxStringUtil.DOC_TAG_REGEX, '');

    // some editors like to put every <br> in a <div>
    trimmedHtml = trimmedHtml.replace(/<div><br ?\/?><\/div>/gi, '<br>');

    // remove leading/trailing <br>
    var len = 0;
    while (trimmedHtml.length !== len && (/^<br ?\/?>/i.test(trimmedHtml) || /<br ?\/?>$/i.test(trimmedHtml))) {
        len = trimmedHtml.length;	// loop prevention
        trimmedHtml = trimmedHtml.replace(/^<br ?\/?>/i, "").replace(/<br ?\/?>$/i, "");
    }

    // remove trailing <br> trapped in front of closing tags
    var m = trimmedHtml && trimmedHtml.match(/((<br ?\/?>)+)((<\/\w+>)+)$/i);
    if (m && m.length) {
        var regex = new RegExp(m[1] + m[3] + '$', 'i');
        trimmedHtml = trimmedHtml.replace(regex, m[3]);
    }

    // remove empty internal <div> containers
    trimmedHtml = trimmedHtml.replace(/(<div><\/div>)+/gi, '');

    return trim(trimmedHtml);
};

/**
 * Removes HTML tags from the given string.
 *
 * @param {string}	str			text from which to strip tags
 * @param {boolean}	removeContent	if <code>true</code>, also remove content within tags
 * @return	{string}	the resulting HTML string
 */
const stripTags = (str, removeContent) => {
    if (typeof str !== 'string') {
        return "";
    }
    if (removeContent) {
        str = str.replace(/(<(\w+)[^>]*>).*(<\/\2[^>]*>)/, "$1$3");
    }
    return str.replace(/<\/?[^>]+>/gi, '');
};
export default {
    ...AjxStringUtil,
    fixCrossDomainReference,
    getOriginalContent,
    convertToHtml,
    htmlEncode,
    convertHtml2Text,
    _writeToTestIframeDoc,
    wordWrap,
    repeat,
    trimHtml,
    trim,
    stripTags
}