<template>
    <el-button class="btn-svg-resource-type" :disabled="!isEditable">
        <img class="svg-resource-type" v-if="!isEditable" src="@/assets/zimbra/images/schedules/resouce-type-self.svg"/>
        <img class="svg-resource-type" v-else-if="resouceType == CALENDAR_RESOURE_TYPE.REQUIRED_ATTENDEE" src="@/assets/zimbra/images/schedules/required_attendee.svg"/>
        <img class="svg-resource-type" v-else-if="resouceType == CALENDAR_RESOURE_TYPE.OPTIONAL_ATTENDEE" src="@/assets/zimbra/images/schedules/optional_attendee.svg"/>
        <img class="svg-resource-type" v-else-if="resouceType == CALENDAR_RESOURE_TYPE.LOCATION_LABEL" src="@/assets/zimbra/images/schedules/location_label.svg"/>
        <img class="svg-resource-type" v-else-if="resouceType == CALENDAR_RESOURE_TYPE.EQUIPMENT_LABEL" src="@/assets/zimbra/images/schedules/equipment_label.svg"/>
        <i class="btn-svg-caret-right el-select__caret el-input__icon el-icon-arrow-down is-reverse" v-if="!hideCaret"></i>
    </el-button>
</template>
<script>
import {
    CALENDAR_RESOURE_TYPE
} from "@/utils/constants.js";
/**
 {
    displayName: tên hiển thị
    resouceType: 1: Người tham dự bắt buộc, 2: Người tham dự tùy chọn, 3: Địa điểm, 4: Thiết bị
    email: //email
    status: trạng thái 1: Rảnh, 2: Không làm việc, 3: Bận, 4: Chưa dứt khoát, 5: Không xác định, 6: Ra khỏi văn phòng,
    isEditable: cờ có thể sửa true: có thể sửa, false: không thể sửa
 }
 */
export default {
    name: 'ResourceTypeDisplay',
    props: {
        resouceType: {
            type: Number
        },
        isEditable: {
            type: Boolean
        },
        hideCaret: {
            type: Boolean
        }
    },
    data() {
        return {
            CALENDAR_RESOURE_TYPE: CALENDAR_RESOURE_TYPE
        };
    },
    async created() {
    },
    mounted() {
    },
    destroyed() {
    },
    computed: {
    },
    methods: {
    },
};
</script>
<style scoped>

.btn-svg-resource-type {
    height: 28px !important;
    padding: 0px 3px !important;
    border: none !important;
}
.btn-svg-resource-type[disabled] {
    background: transparent !important;
}
.btn-svg-resource-type img {
    max-width: unset;
    vertical-align: middle;
    margin-top: -3px;
}
.svg-resource-type {
    width: 20px;
    height: 20px;
}
</style>
