<template>
    <div class="card-filter-row">
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueMainSelect"
                :options="mainSelect"
                @change="handleChangeMainSelect"
            />
        </div>
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="header"
                :value="valueHeader"
                :options="header"
                @change="hanldeChangeHeader"
            />
        </div>
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueAddressInCompartison"
                :options="addressInCompartison"
                @change="handleChangeAddressInCompartison"
            />
        </div>
        <div class="card-filter-item select-wrap__210" v-show="isMeTest">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueType"
                :options="type"
                @change="handleChangeType"
            />
        </div>
        <div class="card-filter-item"></div>
        <div class="card-filter-item" v-show="!isMeTest"></div>
    </div>
</template>

<script>
import { FILTER_TEST_CONFIG, FILTER_TESTS } from "@/utils/constants";
import CoreTest from "@/components/modules/base/base-filter-form/core-test";
import BaseSelect from "@/components/modules/base/base-select";
export default {
    extends: CoreTest,
    components: {
        BaseSelect,
    },
    created() {
        this.beforeBuildDataForm();
        this.conditionsAddressInTest = this.conditionsTest;
    },
    watch: {
        conditionsTest(newData) {
            this.beforeBuildDataForm();
            this.conditionsAddressInTest = newData;
        },
    },
    data() {
        return {
            FILTER_TESTS: FILTER_TESTS,
            FILTER_TEST_CONFIG: FILTER_TEST_CONFIG,
            header: [
                {
                    header: FILTER_TEST_CONFIG.HEADER.FROM,
                    name: this.$t("zimbra.zhMsg.FILT_COND_HEADER_from"),
                },
                {
                    header: FILTER_TEST_CONFIG.HEADER.TO,
                    name: this.$t("zimbra.zhMsg.FILT_COND_HEADER_to"),
                },
                {
                    header: FILTER_TEST_CONFIG.HEADER.CC,
                    name: this.$t("zimbra.zhMsg.cc"),
                },
                {
                    header: FILTER_TEST_CONFIG.HEADER.TO_CC,
                    name: this.$t("zimbra.zhMsg.FILT_COND_HEADER_to,cc"),
                },
            ],
            addressInCompartison: [
                {
                    id: FILTER_TEST_CONFIG.STRING_COMPARTION.IN,
                    name: "trong",
                },
                {
                    id: FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_IN,
                    name: "không trong",
                },
                {
                    id: FILTER_TEST_CONFIG.STRING_COMPARTION.IS_ME,
                    name: "là tôi",
                    type: FILTER_TESTS.ME_TEST,
                },
                {
                    id: FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_ME,
                    name: "không là tôi",
                    type: FILTER_TESTS.ME_TEST,
                },
            ],
            type: [
                {
                    id: FILTER_TEST_CONFIG.TYPE.CONTACT,
                    name: "Danh bạ",
                    type: FILTER_TESTS.ADDRESS_BOOK_TEST,
                },
                {
                    id: FILTER_TEST_CONFIG.TYPE.EMAIL,
                    name: "Email thường xuyên của tôi",
                    type: FILTER_TESTS.CONTACT_RANK_TEST,
                },
            ],
            valueMainSelect: "",
            valueHeader: "",
            valueAddressInCompartison: "",
            valueType: "",
            conditionsAddressInTest: {},
            isMeTest: null,
        };
    },
    methods: {
        beforeBuildDataForm() {
            // SET-VALUE-MAIN-SELECT
            this.valueMainSelect = this.mainSelect[11].id;
            //SET-VALUE-HEADER
            this.valueHeader = this.conditionsTest.header.toLowerCase();
            //SET-VALUE-ADDRESS-IN-COMPARISON
            if (this.conditionsTest.types === FILTER_TESTS.ME_TEST) {
                this.valueAddressInCompartison = this.conditionsTest.negative
                    ? FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_ME
                    : FILTER_TEST_CONFIG.STRING_COMPARTION.IS_ME;
            } else {
                this.valueAddressInCompartison = this.conditionsTest.negative
                    ? FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_IN
                    : FILTER_TEST_CONFIG.STRING_COMPARTION.IN;
            }
            this.isMeTest =
                this.conditionsTest.types === FILTER_TESTS.ME_TEST
                    ? false
                    : true;
            //SET-VALUE-TYPE
            this.valueType =
                this.conditionsTest.types === FILTER_TESTS.ADDRESS_BOOK_TEST
                    ? FILTER_TEST_CONFIG.TYPE.CONTACT
                    : FILTER_TEST_CONFIG.TYPE.EMAIL;
        },
        hanldeChangeHeader(header) {
            this.conditionsAddressInTest.header = header;
            this.$emit("onChangeConditionsTest", this.conditionsAddressInTest);
        },
        handleChangeAddressInCompartison(addressInCompartison) {
            if (
                addressInCompartison ===
                    FILTER_TEST_CONFIG.STRING_COMPARTION.IS_ME ||
                addressInCompartison ===
                    FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_ME
            ) {
                this.conditionsAddressInTest.types = FILTER_TESTS.ME_TEST;
                this.isMeTest = false;
            } else {
                this.isMeTest = true;
                this.conditionsAddressInTest.types =
                    this.valueType == FILTER_TEST_CONFIG.TYPE.EMAIL
                        ? FILTER_TESTS.CONTACT_RANK_TEST
                        : FILTER_TESTS.ADDRESS_BOOK_TEST;
            }
            for (let i in this.conditionsAddressInTest) {
                if (
                    i !== "negative" &&
                    this.validateNegative(addressInCompartison)
                ) {
                    this.conditionsAddressInTest = {
                        ...this.conditionsAddressInTest,
                        negative: FILTER_TEST_CONFIG.NEGATIVE,
                    };
                } else {
                    if (!this.validateNegative(addressInCompartison)) {
                        delete this.conditionsAddressInTest.negative;
                    }
                }
            }
            this.$emit("onChangeConditionsTest", this.conditionsAddressInTest);
        },
        handleChangeType(type) {
            this.conditionsAddressInTest.types =
                type == FILTER_TEST_CONFIG.TYPE.EMAIL
                    ? FILTER_TESTS.CONTACT_RANK_TEST
                    : FILTER_TESTS.ADDRESS_BOOK_TEST;
            this.$emit("onChangeConditionsTest", this.conditionsAddressInTest);
        },
    },
};
</script>
