var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"dialog-body-wrap zimbra-dialog d-center zimbra-limited-width-dialog",attrs:{"custom-class":"dialog-add-folder","title":_vm.checkTitleAddFolder,"visible":_vm.dialogAddFolder,"close-on-click-modal":false,"width":"30%","append-to-body":true},on:{"update:visible":function($event){_vm.dialogAddFolder=$event}}},[_c('el-form',{staticClass:"form-wrap",attrs:{"model":_vm.formAdd},nativeOn:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.AddFolder.apply(null, arguments)}}},[_c('el-form-item',{attrs:{"label":_vm.$t('zimbra.zhMsg.folderName'),"label-width":"110px"}},[_c('el-input',{ref:"refInputName",staticClass:"el-text-control",attrs:{"autocomplete":"off","size":"medium","id":"input-name-contact-folder"},model:{value:(_vm.formAdd.name),callback:function ($$v) {_vm.$set(_vm.formAdd, "name", $$v)},expression:"formAdd.name"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('zimbra.zhMsg.color'),"label-width":"110px"}},[[_c('div',[_c('zm-color-picker',{attrs:{"visible":false,"showFooter":true,"default-colors":[
                            '#3F4254',
                            '#0000FF',
                            '#00FFFF',
                            '#008000',
                            '#FF00FF',
                            '#FF0000',
                            '#FFFF00',
                            '#FF66FF',
                            '#808080',
                            '#FF9900',
                        ]},model:{value:(_vm.formAdd.rgb),callback:function ($$v) {_vm.$set(_vm.formAdd, "rgb", $$v)},expression:"formAdd.rgb"}})],1)]],2),(!_vm.checkTypeFolder(_vm.formAdd.parent))?_c('el-form-item',{attrs:{"label":_vm.$t('zimbraNokey.zimbra_sidebar_dialogAddFolder_powerSupply'),"label-width":"110px"}},[_c('el-checkbox',{model:{value:(_vm.formAdd.registerRSS),callback:function ($$v) {_vm.$set(_vm.formAdd, "registerRSS", $$v)},expression:"formAdd.registerRSS"}},[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.subscribeToFeed")))])],1):_vm._e(),(_vm.formAdd.registerRSS)?_c('el-form-item',{attrs:{"label":_vm.$t('zimbra.zhMsg.url'),"label-width":"110px"}},[_c('el-input',{staticClass:"el-text-control",attrs:{"autocomplete":"off","size":"medium"},model:{value:(_vm.formAdd.url),callback:function ($$v) {_vm.$set(_vm.formAdd, "url", $$v)},expression:"formAdd.url"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":_vm.$t('zimbra.zhMsg.parentFolder'),"label-width":"110px"}},[_c('span',[_vm._v(_vm._s(_vm.formAdd.parent.name))])])],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"btn-email default",on:{"click":function($event){_vm.dialogAddFolder = false}}},[_vm._v(_vm._s(_vm.$t("zimbra.zhMsg.actionTaskCancelTT")))]),_c('el-button',{staticClass:"btn-email primary",attrs:{"native-type":"submit","type":"primary"},on:{"click":function($event){return _vm.AddFolder()}}},[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.mobileStatusNeedProvision")))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }