<template>
    <div class="form-controls">
        <label v-show="label" :for="id" class="label-control">{{
            this.label
            }}</label>
        <el-checkbox
            :id="id"
            class="checkbox-control"
            v-model="keyword"
            :disabled="disabled"
            @change="handleChange"
        >
            {{this.option}}
        </el-checkbox>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: [String, Number],
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Boolean, String, Number],
        },
        option: {
            type: [String, Number],
            required: true,
        },
    },
    created() {
        this.keyword = this.value;
    },
    data() {
        return {
            keyword: true,
        };
    },
    watch: {
        value(val) {
            this.keyword = val;
        },
    },
    methods: {
        handleChange(val) {
            this.$emit("change", val);
        },
    },
};
</script>
