<template>
    <!-- Danh sách tag để chọn -->
    <ul class="sub-menu" ref="subMenuTag">
        <perfect-scrollbar style="max-height: 200px; height: auto">
            <template v-for="(item, idx) in listTag">
                <el-tooltip
                    :key="`tag_${idx}`"
                    class="item"
                    effect="dark"
                    :content="item.name.toString()"
                    placement="top-start"
                    :open-delay="1000"
                >
                    <li
                        class="zm-tag-item"
                        :key="`tag_${idx}`"
                        @click="handleSelectTag(item.name)"
                    >
                        <a href="javascript:;">
                            <i
                                class="ic ic-tag"
                                :style="`filter: ${convertHexToFilterCss(
                                    item
                                )}`"
                            ></i>
                            <span>{{ item.name }}</span>
                        </a>
                    </li>
                </el-tooltip>
            </template>
        </perfect-scrollbar>
    </ul>
</template>
<script>
import { LIST_TYPE_SEARCH } from "@/utils/constants"
import CommonUtils from "@/utils/common-utils"
import { mapGetters } from "vuex"
export default {
    mixins: [CommonUtils],
    props: {
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    computed: {
        // Danh sách tất cả tag
        ...mapGetters(["listTag"]),
    },
    created() {
    },
    methods: {
        handleSelectTag(item) {
            const _content = `tag:"${item}"`
            this.$emit("onSelectTag", _content, LIST_TYPE_SEARCH.TAG)
        }
    },
};
</script>
<style scoped>
.sub-menu {
    margin-left: 5px;
}
</style>
