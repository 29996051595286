<template>
    <div :class="`zimbra-tree${this.isMoveBriefcase ? ' setting-tree-wrap' : ''}`">
        <el-tree
            ref="treeBriefcase"
            :class="`tree-wrap tree-drop-file ${
                    this.isClickRoot ? 'disabled-root-node' : ''
                }`"
            node-key="id"
            id="treeExtendBriefcase"
            :check-strictly="true"
            :data="treeCombineData"
            :props="defaultProps"
            @node-contextmenu="rightClickExtend"
            highlight-current
            :default-expand-all="!isShowRightMenu"
            :allow-drop="allowDrop"
            :allow-drag="allowDrag"
            :expand-on-click-node="false"
            :default-expanded-keys="defaultExpandTreeExtend"
            :filterNodeMethod="filterNode"
            :auto-expand-parent="false"
            @node-click="nodeClick"
        >
            <div :class="`zm-unselect zm-folder-info zm-dropable ${isFolderDefault(data.id)? '': 'zm-dragable'}`" slot-scope="{ node, data }"
                v-bind:id="data.id"
                v-bind:type="data.view"
                v-bind:name="data.name"
            >
                <el-popover
                    popper-class="zm-tooltip"
                    placement="bottom-start"
                    trigger="hover"
                    :key="`tag_${data.id}`"
                    :disabled="!showInfoFolder || data.id == FOLDER_DEFAULT.ROOT || isMoveBriefcase"
                    :open-delay="1000"
                >
                    <div>
                        <span><b>{{ node.label}}</b></span>
                        <div class="d-flex">
                            <span class="folder-info-left">{{ $t("zimbra.zmMsg.fileLabel") }}</span>
                            <span class="folder-info-right">{{
                                data.n || 0
                            }}</span>
                        </div>
                        <div v-if="data.s" class="d-flex">
                            <span class="folder-info-left">
                                {{$t("zimbra.zmMsg.fSizeLabel")}}</span>
                            <span class="folder-info-right">
                                {{formatSize(data.s)}}
                            </span>
                    </div>
                    </div>
                    <div slot="reference" class="custom-tree-node">
                        <span v-if="!data.isLink">
                            <i v-if="data.id !== FOLDER_DEFAULT.TRASH && data.id !== FOLDER_DEFAULT.ROOT" class="briefcase-icon ic-with-color"
                                :class="data.id === ROOT_BRIEFCASE_FOLDER_ID ? 'active' : ''"
                                :style="`filter: ${convertHexToFilterCss( data )}; --icColor: ${convertHexToFilterCss(data)}; padding-right: 18px; margin-right: 5px`"
                            ></i>
                            <i v-else  :class="data.id == FOLDER_DEFAULT.TRASH ? 'ic-delete-red' : '' "
                                :style="data.id == FOLDER_DEFAULT.TRASH ? 'padding-right: 18px; margin-right: 5px' : ''"
                            ></i>
                        </span>
                        <span v-else>
                            <i :class="['ic-folder-copy ic-with-color', isActive(data) ? 'active' : '',]"
                                :style="`padding-right: 30px; margin-left: -6px;filter: ${convertHexToFilterCss(data)}; --icColor: ${convertHexToFilterCss(data)}`"
                            ></i>
                        </span>
                        <span
                            v-if="data.isLink && data.perm == undefined"
                            style="text-decoration: line-through"
                            >{{ node.label }}
                        </span>
                        <span v-else>
                            <span>
                                {{ node.label }}
                            </span>
                        </span>
                        <!-- icon hover -->
                        <button
                            v-if="!isBriefCaseSelector"
                            class="icon-caret float-right"
                            :id="`caret_${data.id}`"
                            @click.stop="(e) => rightClickExtend(e, data, node)"
                        >
                            <i class="el-icon-caret-bottom"></i>
                        </button>
                    </div>
                </el-popover>
            </div>
        </el-tree>
    </div>
</template>

<script>
import CommonUtils from "@/utils/common-utils";
import EmailUtils from "@/utils/email-utils";
import BriefcaseUtils from "@/utils/briefcase-utils"
import {
    FOLDER_DEFAULT,
    ROOT_BRIEFCASE_FOLDER_ID,
    FOLDER_VIEW,
    BRIEFCASE_RIGHT_MENU_TYPE,
    SIDEBAR_NODE,
    BRIEFCASE_ACTION
}
    from "@/utils/constants"
import { mapGetters } from "vuex"
import _ from "lodash"
export default {
    props: {
        showCheckbox: {
            data: Boolean,
            default: function () {
                return false;
            }
        },
        filterValue: {
            data: String,
            default: function () {
                return true;
            }
        },
        isShowRightMenu: {
            data: Boolean,
            default: function () {
                return true;
            }
        },
        highlightDefault: {
            type: Boolean,
            default: false,
        },
        showInfoFolder: {
            type: Boolean,
            default: false
        },
        isBriefCaseSelector: { // Sử dụng trong dialog thêm mới thư mục
            type: Boolean,
            default: false,
        },
        removeDataTrash: { //bỏ thùng rác tree briefcase
            type: Boolean,
            default: false,
        },
        isAddFolder: {
            type: Boolean,
            default: false,
        },
        isMoveBriefcase: {
            type: Boolean,
            default: false,
        },
        isMoveFast: {
            type: Boolean,
            default: false,
        },
        isForward: {
            type: Boolean,
            default: false,
        },
        isRootClick: {
            type: Boolean,
            default: true,
        },
        isClickRoot: {
            type: Boolean,
            default: false,
        },
        listIdFolderHidden: {
            type: Array,
            default: () => []
        },
        isRootSearchFolder: {
            type: Boolean,
            default: true,
        },
        hidenFolderShare: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [CommonUtils, EmailUtils, BriefcaseUtils],
    data() {
        return {
            ROOT_BRIEFCASE_FOLDER_ID: ROOT_BRIEFCASE_FOLDER_ID,
            SIDEBAR_NODE: SIDEBAR_NODE,
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            defaultExpandTreeExtend: [ROOT_BRIEFCASE_FOLDER_ID],
            treeCombineData: [],
            treeBriefcaseData: [],
            listMenuDataCollapse: [],
            showFullMenuDataCollapse: false,
            showIconFullMenuDataCollapse: false,
            defaultProps: {
                children: "folder",
                label: "name",
            },
            node: {},
            formMove: {
                parent: {}
            },
            idCurrent: null,
            treeNodeMove: {},
            dataBriefcaseCurrent: {}
        }
    },
    async created() {
        this.innit();
    },
    mounted() {
        this.$root.$on("setActiveNodeTreeBriefcase", (oldNode, currentNode) => this.setActiveNodeTreeBriefcase(oldNode, currentNode))
        this.$root.$on("closeAllRightMenu", this.closeAllRightMenu)
        this.$root.$on("onInnitBriefcaseTree", this.innit);
        this.$root.$on("onCheckAllTree", () => this.onCheckAllTree());
        this.$root.$on("onUnCheckAllTree", () => this.onUnCheckAllTree());
        this.$root.$on("extendFolderContact", (data) => {
            this.defaultExpandTreeExtend.push(data)
        });
        this.$root.$on("setCurrentActiveKey", (id) => {
            this.setCurrentKey(id)
        })
        this.$root.$on("setActiveNodeAndToggle", (node) => {
            this.setActiveNodeAndToggle(node)
        })

        // mở rộng tất cả
        this.$root.$on("extendAllFolder", () => {
            this.extendAll(this.node)
        });

        const $ = window.jQuery;
        $('body').on('keydown', '.el-tree', this.horizontalControlKey)
        const ele = document.getElementById('treeExtendBriefcase')
        setTimeout(() => {
            ele.ondragover = function (e) {
                e.preventDefault();
                e.stopPropagation();
            };
            ele.ondragenter = function (e) {
                e.preventDefault();
                e.stopPropagation();
            };
            ele.ondrop = function (e) {
                e.preventDefault();
                const lstFile = e.dataTransfer.files
                e.stopPropagation();
            }
        }, 1000)
    },
    destroyed() {
        this.$root.$off("onInnitBriefcaseTree")
        this.$root.$off("onCheckAllTree")
        this.$root.$off("onUnCheckAllTree", this.innit)
        this.$root.$off("extendFolderContact")
        this.$root.$off("setCurrentActiveKey")
    },
    computed: {
        ...mapGetters([
            "menuCollapse",
            "listBriefcaseSearch",
            "nodeBriefcaseCurrent",
            "briefcaseTreeFolders",
            "listSelectedBriefcase",
        ])
    },
    watch: {
        briefcaseTreeFolders: {
            deep: true,
            handler(val) {
                this.setTreeCombineData(val)
            }
        },
        filterValue(val) {
            this.$refs.treeBriefcase.filter(val);
        },
        menuCollapse(data) {
            if (data) {
                this.actionGetListMenuCollapse(this.showFullMenuDataCollapse);
            }
        },
    },
    methods: {
        async innit() {
            // Lấy cấu hình cặp tài liệu - thùng rác
            const briefcaseFolderRes = await this.getBriefcaseFolder();
            this.renameAbsFolderPath(briefcaseFolderRes)
            this.$store.commit("SET_BRIEFCASES_FOLDER", briefcaseFolderRes);
            // Lọc lấy cấu hình cặp tài liệu
            const _briefcase = _.cloneDeep(briefcaseFolderRes)
            if (this.hidenFolderShare) {
                this.treeBriefcaseData = _briefcase.filter(x => !x.isLink)
            } else {
                this.treeBriefcaseData = _briefcase
            }
            // this.setTreeChecked();
            // reload lại tree data
            this.setTreeCombineData(this.treeBriefcaseData)
            // const $ = window.jQuery;
            // $('#menuLeftBriefcase .tree-drop-file').on('dragenter', '.zm-dropable' , (e) => {
            //      console.log('dragenter1',e.currentTarget);
            //      console.log('dragenter2',e);
            //     e.preventDefault();
            //     e.stopPropagation();
            // })
        },

        setActiveNodeAndToggle(node) {
            if (node) {
                if (node.l !== FOLDER_DEFAULT.ROOT) {
                    const _parentFolder = this.findFolderById(this.briefcaseTreeFolders, node.l)
                    const _parentNode = this.$refs.treeBriefcase &&
                        this.$refs.treeBriefcase.getNode(_parentFolder)
                    this.$nextTick(() => {
                        _parentNode.expanded = true
                    })
                }
                this.$nextTick(() => {
                    const _childNode = this.$refs.treeBriefcase &&
                        this.$refs.treeBriefcase.getNode(_childNode)
                    if (_childNode) {
                        setTimeout(() => {
                            this.$store.commit("SET_NODE_BRIEFCASE_CURRENT", _childNode)
                            this.setCurrentKey(null)
                            this.setCurrentKey(_childNode.id)
                            _childNode.expanded = true
                        }, 200)
                    }
                })
            }
        },

        setActiveNodeTreeBriefcase(oldNode, currentNode) {
            if (oldNode !== undefined || currentNode) {
                setTimeout(() => {
                    const _oldNode = this.$refs.treeBriefcase.getNode(oldNode);
                    if (_oldNode) {
                        _oldNode.expanded = true
                    }
                    this.$nextTick(() => {
                        const _nodeCurrent = this.$refs.treeBriefcase.getNode(currentNode)
                        if (_nodeCurrent) {
                            _nodeCurrent.expanded = true
                        }
                        this.$refs.treeBriefcase &&
                            this.$refs.treeBriefcase.setCurrentNode(currentNode)
                    })
                }, 500)
            }
        },
        rightClickExtend(e, data, node) {
            node.isExtend = true;
            this.rightClick(e, data, node)
        },
        rightClick(e, data, node) {
            if (this.isShowRightMenu) {
                this.closeAllRightMenu()
                this.node = node
                let type = BRIEFCASE_RIGHT_MENU_TYPE.TREE_BRIEFCASE;

                // Trường hợp click vào cặp tài liệu root thì hiển thị menu loại khác
                if (data.id == ROOT_BRIEFCASE_FOLDER_ID) {
                    type = BRIEFCASE_RIGHT_MENU_TYPE.TREE_BRIEFCASE_ROOT;
                }

                // Trường hợp click vào thùng rác thì hiển thị loại khác
                if (data.id == FOLDER_DEFAULT.TRASH) {
                    type = BRIEFCASE_RIGHT_MENU_TYPE.TREE_BRIEFCASE_TRASH;
                }

                //Trường hợp click vào cặp tài liệu
                if (data.id == FOLDER_DEFAULT.BRIEFCASE) {
                    type = BRIEFCASE_RIGHT_MENU_TYPE.TREE_BRIEFCASE;
                }
                this.$root.$emit("onRightClickBriefCase", type, e, {}, data);
            }
        },
        allowDrop(draggingNode, dropNode, type) {
            if (draggingNode.level === dropNode.level) {
                if (draggingNode.parent.id === dropNode.parent.id) {
                    return type === "prev" || type === "next";
                }
            } else {
                return false;
            }
        },
        allowDrag() {
            return true;
        },

        /**
         * hàm lọc ra những id lịch trong thùng rác
        */
        getIdCalInTrash() {
            const trashNode = this.treeBriefcaseData?.find(x => x.id == FOLDER_DEFAULT.TRASH)
            let listIdTrash = [];
            this.searchTreeIds(trashNode, listIdTrash);
            return listIdTrash;
        },


        /**
         * hamf filterr tree node
         */
        filterNode(value, data) {
            if (!value) return true;
            return data.name.toString().toLowerCase().indexOf(value.toString().trim().toLowerCase()) !== -1;
        },

        /**
         * hàm kiểm tra folder có phải folder mặc định không
         */
        isFolderDefault(folderId) {
            return Object.values(FOLDER_DEFAULT).includes(folderId);
        },

        /**
         * hàm kết hợp dữ liệu lịch lại thành gốc
         */
        setTreeCombineData(_treeBriefcaseData) {
            if (this.hidenFolderShare) {
                _treeBriefcaseData = _treeBriefcaseData.filter(folder => !folder.isLink)
            }
            const rootTree = {
                id: ROOT_BRIEFCASE_FOLDER_ID,
                name: this.$t('zimbra.zmMsg.briefcaseFolders'),
                view: FOLDER_VIEW.BRIEFCASE,
                folder: _treeBriefcaseData.filter(folder => !this.listIdFolderHidden.includes(folder.id))
            }

            if (this.highlightDefault) {
                const folderBriefcase = rootTree.folder.find(folder => folder.id === FOLDER_DEFAULT.BRIEFCASE)
                this.setCurrentKey(folderBriefcase.id)
                setTimeout(() => {
                    if (this.$refs.treeBriefcase) {
                        setTimeout(() => {
                            if (this.$refs.treeBriefcase) {
                                const _expandNode = this.$refs.treeBriefcase.getNode(folderBriefcase);
                                if (_expandNode) {
                                    this.$nextTick(() => {
                                        _expandNode.expanded = true
                                    })
                                }
                            }
                        }, 500)
                    }
                }, 500)
                this.$store.commit("SET_NODE_BRIEFCASE_CURRENT", folderBriefcase)
            }
            this.calFolderFlatten = [rootTree]
            // Nếu là dialog đính kèm cặp tài liệu thì bỏ node cha "Thư mục cặp tài liệu"
            if (this.isBriefCaseSelector) {
                this.treeCombineData = [rootTree][0].folder;
            } else {
                this.treeCombineData = [rootTree];
            }
            this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", this.treeCombineData[0])
            this.$store.commit("SET_WRAPER_BRIEFCASE", this.treeCombineData)
        },

        /**
         * hàm lọc thư mục được chia sẻ
         */
        setTreeCombineDataFilter() {
            this.treeCombineData[0].folder = this.treeCombineData[0].folder.filter(x => !x.owner)
        },

        /**
         * hàm làm phẳng cây
         */
        flattenCalData(parent) {
            let _list = [];
            parent.forEach(ele => {
                _list.push(ele)
                if (ele.folder) {
                    _list = _list.concat(...this.flattenCalData(ele.folder))
                }
            })
            return _list
        },

        /**
         * hàm Xu li su kien keydown
         */
        horizontalControlKey(evt) {
            if (!this.nodeActive) return
            if (evt.code == "ArrowDown") {
                if (this.index >= this.calFolderFlatten.length - 1) this.index = 0
                else this.index = this.index + 1
                this.nodeActive = this.calFolderFlatten[this.index]
                this.$emit("nodeClick", this.calFolderFlatten[this.index])
                this.setActiveNode(this.nodeActive)
            } else if (evt.code == "ArrowUp") {
                if (this.index === 0) return
                this.index = this.index - 1
                this.nodeActive = this.calFolderFlatten[this.index]
                this.$emit("nodeClick", this.calFolderFlatten[this.index])
                this.setActiveNode(this.nodeActive)
            }
        },

        nodeClick(nodeInfo) {
            this.$store.commit("SET_NODE_BRIEFCASE_EXTRA_CURRENT", nodeInfo);
            this.$emit("nodeClick", nodeInfo)
            if (nodeInfo.id != ROOT_BRIEFCASE_FOLDER_ID && !this.isAddFolder && !this.isMoveFast && !this.isForward) {
                this.handleNodeClick(nodeInfo, false);
            } else {
                if (this.isMoveFast) {
                    this.$store.commit("SET_NODE_BRIEFCASE_EXTRA_CURRENT", nodeInfo);
                    setTimeout(() => {
                        this.$root.$emit("handleNavbarAction", BRIEFCASE_ACTION.MOVE);
                        if (nodeInfo.id !== FOLDER_DEFAULT.ROOT) this.$root.$emit("closePopoverNavbar");
                    }, 200);
                } else if (this.isAddFolder) {
                    this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", nodeInfo)
                }
            }
        },

        handleNodeClick(data) {
            this.$refs.treeBriefcase && this.$refs.treeBriefcase.setCurrentKey(null);
            this.$refs.treeBriefcase &&
                this.$refs.treeBriefcase.setCurrentKey(data.id || ROOT_BRIEFCASE_FOLDER_ID);
            this.$store.commit("SET_NODE_BRIEFCASE_CURRENT", data);
            if (!this.checkFolderRegain(data) && this.isRootSearchFolder) {
                this.$store.commit("SET_LIST_SELECTED_BRIEFCASE", [])
                this.$root.$emit("handleSelectNode", data, true);
            }
        },

        checkFolderRegain(data) {
            if (data.isLink && data.perm == undefined) {
                this.$confirm(
                    this.$t("zimbraNokey.zimbra_sidebar_warningRegain", {
                        name: data.name,
                    }),
                    this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                    {
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        cancelButtonText: this.$t("zimbra.zhMsg.actionTaskCancelTT"),
                        type: "warning",
                    }
                )
                    .then(() => {
                        this.$root.$emit("deleteFolder", data);
                    })
                    .catch(() => { });
                return true;
            } else {
                return false;
            }
        },

        setCurrentKey(id) {
            setTimeout(() => {
                this.$refs.treeBriefcase && this.$refs.treeBriefcase.setCurrentKey(id);
            }, 150);
        },

        // set node hien tai tren tree
        setActiveNode(node) {
            this.$refs.treeBriefcase && this.$refs.treeBriefcase.setCurrentKey(node)
            this.$forceUpdate()
        },

        /*
        * Set key root khi open dialog
        */
        onCurrentKeyRoot() {
            this.setCurrentKey(FOLDER_DEFAULT.ROOT)
            const _dataCurrent = this.treeCombineData[0]
            this.$store.commit("SET_NODE_BRIEFCASE_EXTRA_CURRENT", _dataCurrent);
        },

        /*
         * Hàm extend all
         */
        extendAll(node) {
            node.expanded = true;
            if (node.childNodes) {
                node.childNodes.forEach((x) => {
                    this.extendAll(x);
                });
            }
        },

        /*
         * Hàm active folder
         */
        isActive(node) {
            return node.id === this.nodeBriefcaseCurrent?.id;
        },
        actionGetListMenuCollapse() {
            this.listMenuDataCollapse = [];
            let listResult = [];
            let listExtra = []
            if (this.treeCombineData && this.treeCombineData.length > 0) {
                this.treeCombineData[0].folder.forEach(item => {
                    item.cuTuype = 1;
                    if (item.absFolderPath && (item.id === FOLDER_DEFAULT.BRIEFCASE || item.id === FOLDER_DEFAULT.TRASH)) {
                        listResult.push(item);
                    } else if (item.absFolderPath) {
                        listExtra.push(item);
                    }
                });
            }
            this.listMenuDataCollapse = listResult.concat(listExtra);
        },
        toggleShowFullMenuDataCollapse() {
            this.showFullMenuDataCollapse = !this.showFullMenuDataCollapse;
            this.actionGetListMenuCollapse(this.showFullMenuDataCollapse);
        },
        closeAllRightMenu() {
            this.$root.$emit("closeRightMenuHeader")
            this.$root.$emit("closePopoverNavbar")
            this.$root.$emit("closeRightMenuSidebar")
            this.$root.$emit("closeRightMenuTag")
            this.$root.$emit("closeRightMenuBriefcase")
        },
        setCurrentActiveNode(node) {
            this.$refs.treeBriefcase && this.$refs.treeBriefcase.setCurrentNode(node);
        },

        /*
        * Hàm di chuyển briefcase
        */
        //    moveBriefcase() {
        //        if(this.$store.getters.activeFolderExtra) {
        //            const uuid = this.generateUniqueId();
        //            this.formMove.parent = this.listSelectedBriefcase;

        //        }
        //    }
    }
}
</script>