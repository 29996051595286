<template>
    <div class="popover-wrap" style="min-width: 202px;">
        <ul>
            <li>
              <TreeFolderExtra ref="treeFolderExtra" :isMoveMail="false" :isMoveFast="true" :showInputSearch="false"></TreeFolderExtra>
            <li>
            <hr />
            <li index="1" class="menu-item" style="margin-left: 3px; height: 40px;">
                <a href="javascript:;" @click=" $emit('onclose'); $root.$emit('handleSidebarActionMoveAdd', true)">
                    <i class="ic-folder-add" style="padding-right: 30px;"></i>
                    <span style="font-family: 'Roboto'; font-weight: 500;">{{ $t("zimbra.zhMsg.folderNew") }}</span>
                </a>
            </li>
            <!-- <li class="menu-item sub" style="margin-left: 15px; height: 40px;">
              <el-popover
                placement="right"
                width="200"
                trigger="hover"
                popper-class="zimbra-popper"
              >
                <TreeFolderExtra ref="treeFolderExtra" :isMoveMail="false"></TreeFolderExtra>
                <a href="javascript:;" slot="reference">
                    <i class="ic-folder-copy" style="padding-right: 30px;"></i>
                    <span style="font-family: 'Roboto'; font-weight: 500;">copy to folder</span>
                </a>
              </el-popover>
            </li> -->
            <!-- <li index="1" class="menu-item" style="margin-left: 3px; height: 40px;">
                <a href="javascript:;" @click="$emit('onclose'); $root.$emit('handleNavbarAction', EMAIL_ACTION.DELETE)">
                    <i class="ic-delete-red" style="padding-right: 30px;"></i>
                    <span style="font-family: 'Roboto'; font-weight: 500;">{{ $t("zimbraNokey.zimbra_mailbox_moveToTrash") }}</span>
                </a>
            </li> -->
        </ul>
    </div>
</template>

<script>
import TreeFolderExtra from "./TreeFolderExtra";
// import ZimbraMailService from "@/services/ZimbraMailService.js";
import { EMAIL_ACTION  } from "@/utils/constants";
import CommonUtils from "@/utils/common-utils";
export default {
    mixins: [CommonUtils],
    components: {
        TreeFolderExtra,
    },
    created(){
    },
    data() {
        return {
          EMAIL_ACTION:EMAIL_ACTION,
          visablePopover: false,
        };
    },
    methods: {
    },
};
</script>