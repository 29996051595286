<template>
    <div>
        <div
            v-show="showMenuTag"
            id="menuTag"
            class="right-menu"
            active-text-color="#303133"
        >
            <el-menu class="box-control-inbox">
                <el-menu-item
                    index="1"
                    class="menu-item"
                    @click="$root.$emit('handleTagActionAdd')"
                >
                    <i class="el-icon-folder-add" style="color: orange"></i>
                    <span>{{
                        $t("zimbra.zhMsg.tagNew")
                    }}</span>
                </el-menu-item>
                <el-menu-item
                    index="2"
                    class="menu-item"
                    @click="readTag"
                    :disabled="disabledReadTag"
                    :hidden="isRootTag"
                >
                    <i class="el-icon-check" style="color: teal"></i>
                    <span>{{
                        $t("zimbra.zhMsg.actionMarkAllRead")
                    }}</span>
                </el-menu-item>
                <el-menu-item
                    index="6"
                    class="menu-item"
                    @click="clickDeleteTag"
                    :hidden="isRootTag"
                >
                    <i class="el-icon-circle-close" style="color: red"></i>
                    <span>{{
                        $t("zimbra.zhMsg.actionDelete")
                    }}</span>
                </el-menu-item>
                <el-menu-item
                    index="7"
                    class="menu-item"
                    @click="$root.$emit('handleTagActionReName', treeNodeData)"
                    :hidden="isRootTag"
                >
                    <i class="el-icon-edit-outline" style="color: blue"></i>
                    <span>{{
                        $t("zimbra.zhMsg.renameTag")
                    }}</span>
                </el-menu-item>
                <el-popover
                    placement="left"
                    trigger="hover"
                >
                    <zm-color-picker
                        v-model="rgb"
                        :visible="false"
                        :showFooter="true"
                        :default-colors="['#3F4254', '#0000FF', '#00FFFF', '#008000', '#FF00FF', '#FF0000', '#FFFF00', '#FF66FF', '#808080', '#FF9900']"
                        @onchange="clickChangeColor"
                    >
                    </zm-color-picker>
                    <el-menu-item
                        slot="reference"
                        index="4"
                        class="menu-item sub clearfix"
                        :hidden="isRootTag"
                        id="itemMenuColor"
                    >
                        <i
                            class="el-icon-orange"
                            style="color: cornflowerblue"
                        ></i>
                        <span>
                            {{ $t("zimbra.zhMsg.tagColor") }}
                        </span>
                    </el-menu-item>
                </el-popover>

                <el-menu-item
                    index="9"
                    class="menu-item"
                    :hidden="isRootTag"
                    @click="openInTab(treeNodeData)"
                >
                    <i class="el-icon-close"></i>
                    <span>{{
                        $t("zimbra.zmMsg.openInTab")
                    }}</span>
                </el-menu-item>
            </el-menu>
        </div>
        <!-- menu color -->
        <!-- <el-popover
            v-model="showMenuColor"
            id="menuTagColor"
            class="right-menu popover-wrap"
        >
            <el-menu
                ref="rightMenu"
                class="box-control-inbox menu-color-wrap"
                v-if="!showPickerColor"
            >
                <el-menu-item
                    v-for="item in listColor"
                    :key="item.value"
                    v-model="rgb"
                    class="menu-item"
                    @click="clickChangeColor(item.value)"
                >
                    <i
                        class="el-icon-folder"
                        :style="`color:${item.color} ;padding-right: 10px; `"
                    ></i>
                    <span>{{ item.label }}</span>
                </el-menu-item>
                <el-menu-item class="menu-item" @click="clickShowPickerColor()">
                    <span>{{ $t("zimbra.zmMsg.colorsShowMore") }}</span>
                </el-menu-item>
            </el-menu>
        </el-popover> -->
    </div>
</template>
<script>
import ZmColorPicker from "@/components/modules/base/base-color-picker-advance/zm-color-picker";
import CommonUtils from "@/utils/common-utils";
import listColor from "./../listColor";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import { FOLDER_DEFAULT, SIDEBAR_ACTION, STATUS_REQUEST, SIDEBAR_NODE } from "@/utils/constants";
import { mapGetters } from "vuex";
import { FOLDER_VIEW, CALENDAR_VIEW_MODE, READ_EMAIL_LAYOUT_ROUTER } from '../../../../utils/constants';
import _ from "lodash"
export default {
    mixins: [CommonUtils],
    mounted() {
        this.$root.$on("showRightMenuTag", (e, data, note) => {
            this.show(e, data, note);
        });
        this.$root.$on("closeRightMenuTag", () => {
            this.showMenuTag = false;
        });
    },
    computed: {
        ...mapGetters(["dataSidebarExtend", "listTag", "calViewMode"]),
    },
    data() {
        return {
            showMenuTag: false,
            note: "",
            isTrash: false,
            listColor: listColor,
            isRootTag: false,
            showPickerColor: false,
            rgb: "#3F4254",
            point: {},
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            SIDEBAR_ACTION: SIDEBAR_ACTION,
            treeNodeData: {},
            disabledReadTag: false,
        };
    },
    components: {
        "zm-color-picker": ZmColorPicker
    },
    methods: {
        show(e, data, note) {
            this.rgb = data.rgb || "#3F4254";
            this.disabledReadTag = !data.u;
            this.treeNodeData = data;
            this.showPickerColor = false;
            this.isRootTag = data.id == FOLDER_DEFAULT.TAG;
            this.point = e;
            this.note = note;
            let menuTag = document.querySelector("#menuTag");
            menuTag.style.left = e.clientX + "px";
            menuTag.style.top = e.clientY + "px";
            const screenHeight =
                document.getElementById("appZimbra").offsetHeight;
            if (screenHeight - e.clientY < 260) {
                menuTag.style.top = screenHeight - 260 + "px";
            } else {
                menuTag.style.top = e.clientY + "px";
            }
            this.showMenuTag = true;
            this.$store.commit("SET_TAGS", data);
            document.addEventListener("click", this.closeRightMenu);

            const { name } = this.$route;
            if (name == READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE) {
                this.isMessage = true
            } else {
                this.isMessage = false
            }
        },
        closeRightMenu() {
            this.showMenuTag = false;
            document.removeEventListener("click", this.closeRightMenu);
        },
        clickChangeColor() {
            const form = {
                tag: `<urn1:action id="${this.treeNodeData.id}" op="color" rgb="${this.rgb}"></urn1:action>`,
            };
            ZimbraMailService.tagActionRequest(form).then((res) => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    if (this.isMessage) {
                        this.$message({
                            type: "success",
                            customClass: 'zm-notify',
                            duration: 3500,
                            dangerouslyUseHTMLString: true,
                            message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessChangeColorTag")
                        });
                    } else {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessChangeColorTag"),
                            type: "success",
                        });
                    }

                    this.treeNodeData.rgb = this.rgb;
                    this.$store.commit("SET_TAGS", this.treeNodeData);
                }
            });
            this.showPickerColor = false;
            this.showMenuTag = false;
        },
        readTag() {
            const form = {
                tag: `<urn1:action id="${this.treeNodeData.id}" l="${this.treeNodeData.id}" op="read"></urn1:action>`,
            };
            const _this = this;
            ZimbraMailService.tagActionRequest(form).then(() => {
                _this.reloadTag();
            });
            this.$root.$emit("handleUpdateReadAll");
        },
        async reloadTag() {
            const searchRequest = await ZimbraMailService.getTagRequest();
            const data = this.getResponseBody(searchRequest)["GetTagResponse"];
            if (data && data.tag) {
                data.tag = Array.isArray(data.tag) ? data.tag : [data.tag];
                let dataTag = this.dataSidebarExtend[0].folder.find(x => x.id == FOLDER_DEFAULT.TAG);
                dataTag.folder = data.tag.map(x => ({ ...x, l: FOLDER_DEFAULT.TAG, type: SIDEBAR_NODE.TAG }));
                this.$root.$emit("deleteItemToDataExtend", dataTag);
                this.$root.$emit("addItemToDataExtend", dataTag);
            }
        },
        clickDeleteTag() {
            this.$confirm(
                this.$t("zimbraNokey.zimbra_sidebar_confirmDeleteTag", {
                    name: this.treeNodeData.name,
                }),
                this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    cancelButtonText: this.$t("zimbra.zhMsg.actionTaskCancelTT"),
                    type: "warning",
                }
            )
                .then(() => {
                    this.deleteTag();
                })
                .catch(() => { });
        },
        deleteTag() {
            const form = {
                tag: `<urn1:action id="${this.treeNodeData.id}" op="delete"></urn1:action>`,
            };
            ZimbraMailService.tagActionRequest(form).then((res) => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    if (this.isMessage) {
                        this.$message({
                            type: "success",
                            customClass: 'zm-notify',
                            duration: 3500,
                            dangerouslyUseHTMLString: true,
                            message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessDeleteTag"),
                        });
                    } else {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessDeleteTag"),
                            type: "success",
                        });
                    }

                    this.treeNodeData.l = FOLDER_DEFAULT.TAG;
                    const listTag = _.cloneDeep(this.listTag).filter(x => x.id != this.treeNodeData.id);
                    this.$store.commit("SET_LIST_TAG", listTag);
                    this.$root.$emit(
                        "deleteItemToDataExtend",
                        this.treeNodeData
                    );
                }
            });
        },
        openInTab(node) {
            const query = `tag:"${node.name}"`;
            if (node.types == FOLDER_VIEW.APPOINTMENT) {
                this.$store.commit("SET_LST_CAL_QUERY_SEARCH", [query]);

                // thực hiện mở thanh thông tin tìm kiếm
                this.$store.commit("SET_IS_VISIBLE_CAL_SEARCH_FORM", true)

                // thực mở màn danh sách + tìm kiếm
                if (this.calViewMode == CALENDAR_VIEW_MODE.LIST) {
                    this.$root.$emit("onSearchAppointmentRequest")
                } else {
                    this.$root.$emit("onChangeViewFromMenu", CALENDAR_VIEW_MODE.LIST)
                }
            } else if (node.types == FOLDER_VIEW.DOCUMENT) {
                this.$store.commit("SET_LST_BRIEFCASE_QUERY_SEARCH", [query])
                this.$store.commit("SET_SHOW_SEARCH_BOX", true);
                this.$root.$emit("handleSearchByQuery", _.escape(query));
            } else {
                this.$store.commit("SET_LIST_QUERY", [query]);
                this.$store.commit("SET_LIST_QUERY_TEMP", [query]);
                this.$store.commit("SET_SHOW_SEARCH_BOX", true);
                this.$root.$emit("handleSearchBox", query);
            }
        },
    },
};
</script>
