import inboxService from "@/services/InboxService";
const inboxModule = {
    state: {
        blackList: [],
        whiteList: [],
        mailPollingInterval: {
            name: "",
            content: ""
        },
        messageViewHtmlPreferred: {
            name: "",
            content: ""
        },
        displayTimeInMailList: {
            name: "",
            content: ""
        },
        openMailInNewWindow: {
            name: "",
            content: ""
        },
        showFragments: {
            name: "",
            content: ""
        },
        displayExternalImages: {
            name: "",
            content: ""
        },
        markMsgRead: {
            name: "",
            content: ""
        },
        mailSelectAfterDelete: {
            name: "",
            content: ""
        },
        colorMessagesEnabledv: {
            name: "",
            content: ""
        },
        mailInitialSearch: {
            name: "",
            content: ""
        },
        mailForwardingAddress: {
            name: "zimbraPrefMailForwardingAddress",
            content: ""
        },
        mailLocalDeliveryDisabled: {
            name: "zimbraPrefMailLocalDeliveryDisabled",
            content: false
        },
        newMailNotificationAddress: {
            name: "zimbraPrefNewMailNotificationAddress",
            content: ""
        },
        newMailNotificationEnabled: {
            name: "zimbraPrefNewMailNotificationEnabled",
            content: false
        },
        mailToasterEnabled: {
            name: "",
            content: ""
        },
        showAllNewMailNotifications: {
            name: "",
            content: ""
        },
        mailSendReadReceipts: {
            name: "",
            content: ""
        },
        dedupeMessagesSentToSelf: {
            name: "",
            content: ""
        },
        messageIdDedupingEnabled: {
            name: "",
            content: ""
        },
        composeFormat: {
            name: "",
            content: ""
        },
        htmlEditorDefaultFontFamily: {
            name: "",
            content: "arial,helvetica,sans-serif"
        },
        htmlEditorDefaultFontSize: {
            name: "",
            content: ""
        },
        htmlEditorDefaultFontColor: {
            name: "zimbraPrefHtmlEditorDefaultFontColor",
            content: ""
        },
        autoSaveDraftInterval: {
            name: "zimbraPrefAutoSaveDraftInterval",
            content: ""
        },
        forwardReplyInOriginalFormat: {
            name: "",
            content: ""
        },
        composeInNewWindow: {
            name: "",
            content: ""
        },
        saveToSent: {
            name: "",
            content: ""
        },
        mailRequestReadReceipts: {
            name: "",
            content: ""
        },
        mandatorySpellCheckEnabled: {
            name: "",
            content: ""
        },
        useSendMsgShortcut: {
            name: "",
            content: ""
        },
        tabInEditorEnabled: {
            name: "",
            content: ""
        },
        replyIncludeOriginalText: {
            name: "zimbraPrefReplyIncludeOriginalText",
            content: ""
        },
        forwardIncludeOriginalText: {
            name: "zimbraPrefForwardIncludeOriginalText",
            content: ""
        },
        forwardReplyPrefixChar: {
            name: "",
            content: ""
        },
        pop3Enabled: {
            name: "",
            content: ""
        },
        imapEnabled: {
            name: "",
            content: ""
        },
        pop3DownloadSince: {
            name: "zimbraPrefPop3DownloadSince",
            content: ""
        },
        pop3IncludeSpam: {
            name: "",
            content: ""
        },
        pop3DeleteOption: {
            name: "",
            content: ""
        },
        delegatedSendSaveTarget: {
            name: "",
            content: ""
        },
        prefMailSignatureStyle: {
            name: "zimbraPrefMailSignatureStyle",
            content: ""
        }
    },
    getters: {
        blackList: state => state.blackList,
        whiteList: state => state.whiteList,
        mailPollingInterval: state => state.mailPollingInterval,
        messageViewHtmlPreferred: state => state.messageViewHtmlPreferred,
        displayTimeInMailList: state => state.displayTimeInMailList,
        openMailInNewWindow: state => state.openMailInNewWindow,
        showFragments: state => state.showFragments,
        displayExternalImages: state => state.displayExternalImages,
        markMsgRead: state => state.markMsgRead,
        mailSelectAfterDelete: state => state.mailSelectAfterDelete,
        colorMessagesEnabledv: state => state.colorMessagesEnabledv,
        mailInitialSearch: state => state.mailInitialSearch,
        mailForwardingAddress: state => state.mailForwardingAddress,
        mailLocalDeliveryDisabled: state => state.mailLocalDeliveryDisabled,
        newMailNotificationAddress: state => state.newMailNotificationAddress,
        newMailNotificationEnabled: state => state.newMailNotificationEnabled,
        mailToasterEnabled: state => state.mailToasterEnabled,
        showAllNewMailNotifications: state => state.showAllNewMailNotifications,
        mailSendReadReceipts: state => state.mailSendReadReceipts,
        dedupeMessagesSentToSelf: state => state.dedupeMessagesSentToSelf,
        messageIdDedupingEnabled: state => state.messageIdDedupingEnabled,
        composeFormat: state => state.composeFormat,
        htmlEditorDefaultFontFamily: state => state.htmlEditorDefaultFontFamily,
        htmlEditorDefaultFontSize: state => state.htmlEditorDefaultFontSize,
        htmlEditorDefaultFontColor: state => state.htmlEditorDefaultFontColor,
        autoSaveDraftInterval: state => state.autoSaveDraftInterval,
        forwardReplyInOriginalFormat: state =>
            state.forwardReplyInOriginalFormat,
        composeInNewWindow: state => state.composeInNewWindow,
        saveToSent: state => state.saveToSent,
        mailRequestReadReceipts: state => state.mailRequestReadReceipts,
        mandatorySpellCheckEnabled: state => state.mandatorySpellCheckEnabled,
        useSendMsgShortcut: state => state.useSendMsgShortcut,
        tabInEditorEnabled: state => state.tabInEditorEnabled,
        replyIncludeOriginalText: state => state.replyIncludeOriginalText,
        forwardIncludeOriginalText: state => state.forwardIncludeOriginalText,
        forwardReplyPrefixChar: state => state.forwardReplyPrefixChar,
        imapEnabled: state => state.imapEnabled,
        pop3Enabled: state => state.pop3Enabled,
        pop3DownloadSince: state => state.pop3DownloadSince,
        pop3IncludeSpam: state => state.pop3IncludeSpam,
        pop3DeleteOption: state => state.pop3DeleteOption,
        delegatedSendSaveTarget: state => state.delegatedSendSaveTarget,
        prefMailSignatureStyle: state => state.prefMailSignatureStyle
    },
    mutations: {
        SET_BLACKLIST(state, data) {
            const _list = Array.isArray(data) ? data : [data];
            state.blackList = _list.map((addr, index) => {
                return {
                    id: index,
                    email: addr
                }
            });
        },
        SET_WHITELIST(state, data) {
            const _list = Array.isArray(data) ? data : [data];
            state.whiteList = _list.map((addr, index) => {
                return {
                    id: index,
                    email: addr
                }
            });
        },
        SET_ZIMBRAPREFMAILPOLLINGINTERVAL(state, data) {
            state.mailPollingInterval = data;
        },
        SET_ZIMBRAPREFMESSAGEVIEWHTMLPREFERRED(state, data) {
            state.messageViewHtmlPreferred = data;
        },
        SET_ZIMBRAPREFDISPLAYTIMEINMAILLIST(state, data) {
            state.displayTimeInMailList = data;
        },
        SET_ZIMBRAPREFSHOWFRAGMENTS(state, data) {
            state.showFragments = data;
        },
        SET_ZIMBRAPREFOPENMAILINNEWWINDOW(state, data) {
            state.openMailInNewWindow = data;
        },
        SET_ZIMBRAPREFDISPLAYEXTERNALIMAGES(state, data) {
            state.displayExternalImages = data;
        },
        SET_ZIMBRAPREFMARKMSGREAD(state, data) {
            state.markMsgRead = data;
        },
        SET_ZIMBRAPREFMAILSELECTAFTERDELETE(state, data) {
            state.mailSelectAfterDelete = data;
        },
        SET_ZIMBRAPREFCOLORMESSAGESENABLED(state, data) {
            state.colorMessagesEnabledv = data;
        },
        SET_ZIMBRAPREFMAILINITIALSEARCH(state, data) {
            state.mailInitialSearch = data;
        },
        SET_ZIMBRAPREFMAILFORWARDINGADDRESS(state, data) {
            state.mailForwardingAddress = data;
        },
        SET_ZIMBRAPREFMAILLOCALDELIVERYDISABLED(state, data) {
            state.mailLocalDeliveryDisabled = data;
        },
        SET_ZIMBRAPREFNEWMAILNOTIFICATIONADDRESS(state, data) {
            state.newMailNotificationAddress = data;
        },
        SET_ZIMBRAPREFNEWMAILNOTIFICATIONENABLED(state, data) {
            state.newMailNotificationEnabled = data;
        },
        SET_ZIMBRAPREFMAILTOASTERENABLED(state, data) {
            state.mailToasterEnabled = data;
        },
        SET_ZIMBRAPREFSHOWALLNEWMAILNOTIFICATIONS(state, data) {
            state.showAllNewMailNotifications = data;
        },
        SET_ZIMBRAPREFMAILSENDREADRECEIPTS(state, data) {
            state.mailSendReadReceipts = data;
        },
        SET_ZIMBRAPREFDEDUPEMESSAGESSENTTOSELF(state, data) {
            state.dedupeMessagesSentToSelf = data;
        },
        SET_ZIMBRAPREFMESSAGEIDDEDUPINGENABLED(state, data) {
            state.messageIdDedupingEnabled = data;
        },
        SET_ZIMBRAPREFCOMPOSEFORMAT(state, data) {
            state.composeFormat = data;
        },
        SET_ZIMBRAPREFHTMLEDITORDEFAULTFONTFAMILY(state, data) {
            state.htmlEditorDefaultFontFamily = data;
        },
        SET_ZIMBRAPREFHTMLEDITORDEFAULTFONTSIZE(state, data) {
            state.htmlEditorDefaultFontSize = data;
        },
        SET_ZIMBRAPREFHTMLEDITORDEFAULTFONTCOLOR(state, data) {
            state.htmlEditorDefaultFontColor = data;
        },
        SET_ZIMBRAPREFAUTOSAVEDRAFTINTERVAL(state, data) {
            state.autoSaveDraftInterval = data;
        },
        SET_ZIMBRAPREFFORWARDREPLYINORIGINALFORMAT(state, data) {
            state.forwardReplyInOriginalFormat = data;
        },
        SET_ZIMBRAPREFCOMPOSEINNEWWINDOW(state, data) {
            state.composeInNewWindow = data;
        },
        SET_ZIMBRAPREFSAVETOSENT(state, data) {
            state.saveToSent = data;
        },
        SET_ZIMBRAPREFMAILREQUESTREADRECEIPTS(state, data) {
            state.mailRequestReadReceipts = data;
        },
        SET_ZIMBRAPREFMANDATORYSPELLCHECKENABLED(state, data) {
            state.mandatorySpellCheckEnabled = data;
        },
        SET_ZIMBRAPREFUSESENDMSGSHORTCUT(state, data) {
            state.useSendMsgShortcut = data;
        },
        SET_ZIMBRAPREFTABINEDITORENABLED(state, data) {
            state.tabInEditorEnabled = data;
        },
        SET_ZIMBRAPREFREPLYINCLUDEORIGINALTEXT(state, data) {
            state.replyIncludeOriginalText = data;
        },
        SET_ZIMBRAPREFFORWARDINCLUDEORIGINALTEXT(state, data) {
            state.forwardIncludeOriginalText = data;
        },
        SET_ZIMBRAPREFFORWARDREPLYPREFIXCHAR(state, data) {
            state.forwardReplyPrefixChar = data;
        },
        SET_ZIMBRAPREFPOP3ENABLED(state, data) {
            state.pop3Enabled = data;
        },
        SET_ZIMBRAPREFIMAPENABLED(state, data) {
            state.imapEnabled = data;
        },
        SET_ZIMBRAPREFPOP3DOWNLOADSINCE(state, data) {
            state.pop3DownloadSince = data;
        },
        SET_ZIMBRAPREFPOP3INCLUDESPAM(state, data) {
            state.pop3IncludeSpam = data;
        },
        SET_ZIMBRAPREFPOP3DELETEOPTION(state, data) {
            state.pop3DeleteOption = data;
        },
        SET_ZIMBRAPREFDELEGATEDSENDSAVETARGET(state, data) {
            state.delegatedSendSaveTarget = data;
        },
        SET_ZIMBRAPREFMAILSIGNATURESTYLE(state, data) {
            state.prefMailSignatureStyle = data
        },
        // edit constant
        EDIT_ZIMBRAPREFMAILPOLLINGINTERVAL(state, data) {
            state.mailPollingInterval.content = data.content;
        },
        EDIT_ZIMBRAPREFMESSAGEVIEWHTMLPREFERRED(state, data) {
            state.messageViewHtmlPreferred.content = data.content;
        },
        EDIT_ZIMBRAPREFDISPLAYTIMEINMAILLIST(state, data) {
            state.displayTimeInMailList.content = data.content;
        },
        EDIT_ZIMBRAPREFSHOWFRAGMENTS(state, data) {
            state.showFragments.content = data.content;
        },
        EDIT_ZIMBRAPREFOPENMAILINNEWWINDOW(state, data) {
            state.openMailInNewWindow.content = data.content;
        },
        EDIT_ZIMBRAPREFDISPLAYEXTERNALIMAGES(state, data) {
            state.displayExternalImages.content = data.content;
        },
        EDIT_ZIMBRAPREFMARKMSGREAD(state, data) {
            state.markMsgRead.content = data.content;
        },
        EDIT_ZIMBRAPREFMAILSELECTAFTERDELETE(state, data) {
            state.mailSelectAfterDelete.content = data.content;
        },
        EDIT_ZIMBRAPREFCOLORMESSAGESENABLED(state, data) {
            state.colorMessagesEnabledv.content = data.content;
        },
        EDIT_ZIMBRAPREFMAILINITIALSEARCH(state, data) {
            state.mailInitialSearch.content = data.content;
        },
        EDIT_ZIMBRAPREFMAILFORWARDINGADDRESS(state, data) {
            state.mailForwardingAddress.content = data.content;
        },
        EDIT_ZIMBRAPREFMAILLOCALDELIVERYDISABLED(state, data) {
            state.mailLocalDeliveryDisabled.content = data.content;
        },
        EDIT_ZIMBRAPREFNEWMAILNOTIFICATIONADDRESS(state, data) {
            state.newMailNotificationAddress.content = data.content;
        },
        EDIT_ZIMBRAPREFNEWMAILNOTIFICATIONENABLED(state, data) {
            state.newMailNotificationEnabled.content = data.content;
        },
        EDIT_ZIMBRAPREFMAILTOASTERENABLED(state, data) {
            state.mailToasterEnabled.content = data.content;
        },
        EDIT_ZIMBRAPREFSHOWALLNEWMAILNOTIFICATIONS(state, data) {
            state.showAllNewMailNotifications.content = data.content;
        },
        EDIT_ZIMBRAPREFMAILSENDREADRECEIPTS(state, data) {
            state.mailSendReadReceipts.content = data.content;
        },
        EDIT_ZIMBRAPREFDEDUPEMESSAGESSENTTOSELF(state, data) {
            state.dedupeMessagesSentToSelf.content = data.content;
        },
        EDIT_ZIMBRAPREFMESSAGEIDDEDUPINGENABLED(state, data) {
            state.messageIdDedupingEnabled.content = data.content;
        },
        EDIT_ZIMBRAPREFCOMPOSEFORMAT(state, data) {
            state.composeFormat.content = data.content;
        },
        EDIT_ZIMBRAPREFHTMLEDITORDEFAULTFONTFAMILY(state, data) {
            state.htmlEditorDefaultFontFamily.content = data.content;
        },
        EDIT_ZIMBRAPREFHTMLEDITORDEFAULTFONTSIZE(state, data) {
            state.htmlEditorDefaultFontSize.content = data.content;
        },
        EDIT_ZIMBRAPREFHTMLEDITORDEFAULTFONTCOLOR(state, data) {
            state.htmlEditorDefaultFontColor.content = data.content;
        },
        EDIT_ZIMBRAPREFAUTOSAVEDRAFTINTERVAL(state, data) {
            state.autoSaveDraftInterval.content = data.content;
        },
        EDIT_ZIMBRAPREFFORWARDREPLYINORIGINALFORMAT(state, data) {
            state.forwardReplyInOriginalFormat.content = data.content;
        },
        EDIT_ZIMBRAPREFCOMPOSEINNEWWINDOW(state, data) {
            state.composeInNewWindow.content = data.content;
        },
        EDIT_ZIMBRAPREFSAVETOSENT(state, data) {
            state.saveToSent.content = data.content;
        },
        EDIT_ZIMBRAPREFMAILREQUESTREADRECEIPTS(state, data) {
            state.mailRequestReadReceipts.content = data.content;
        },
        EDIT_ZIMBRAPREFMANDATORYSPELLCHECKENABLED(state, data) {
            state.mandatorySpellCheckEnabled.content = data.content;
        },
        EDIT_ZIMBRAPREFUSESENDMSGSHORTCUT(state, data) {
            state.useSendMsgShortcut.content = data.content;
        },
        EDIT_ZIMBRAPREFTABINEDITORENABLED(state, data) {
            state.tabInEditorEnabled.content = data.content;
        },
        EDIT_ZIMBRAPREFREPLYINCLUDEORIGINALTEXT(state, data) {
            state.replyIncludeOriginalText.content = data.content;
        },
        EDIT_ZIMBRAPREFFORWARDINCLUDEORIGINALTEXT(state, data) {
            state.forwardIncludeOriginalText.content = data.content;
        },
        EDIT_ZIMBRAPREFFORWARDREPLYPREFIXCHAR(state, data) {
            state.forwardReplyPrefixChar.content = data.content;
        },
        EDIT_ZIMBRAPREFPOP3ENABLED(state, data) {
            state.pop3Enabled.content = data.content;
        },
        EDIT_ZIMBRAPREFIMAPENABLED(state, data) {
            state.imapEnabled.content = data.content;
        },
        EDIT_ZIMBRAPREFPOP3DOWNLOADSINCE(state, data) {
            state.pop3DownloadSince.content = data.content;
        },
        EDIT_ZIMBRAPREFPOP3INCLUDESPAM(state, data) {
            state.pop3IncludeSpam.content = data.content;
        },
        EDIT_ZIMBRAPREFPOP3DELETEOPTION(state, data) {
            state.pop3DeleteOption.content = data.content;
        },
        EDIT_ZIMBRAPREFDELEGATEDSENDSAVETARGET(state, data) {
            state.delegatedSendSaveTarget.content = data.content;
        },
        EDIT_ZIMBRAPREFMAILSIGNATURESTYLE(state, data) {
            state.prefMailSignatureStyle.content = data
        },
        ADD_WHITELIST(state, data) {
            state.whiteList.push(data);
        },
        DELETE_WHITELIST(state, data) {
            state.whiteList = state.whiteList.filter(item => item.email !== data);
        },
        ADD_BLACKLIST(state, data) {
            state.blackList.push(data);
        },
        DELETE_BLACKLIST(state, data) {
            state.blackList = state.blackList.filter(item => item.email !== data);
        }
    },
    actions: {
        async getPrefInbox({ commit }, data) {
            try {
                const resp = await inboxService.getPrefsRequest(data);
                const _list =
                    resp["data"]["soap:Envelope"]["soap:Body"][
                    "GetPrefsResponse"
                    ]["pref"];
                _list.map(item => {
                    if (item.name === "zimbraPrefMailPollingInterval") {
                        commit("SET_ZIMBRAPREFMAILPOLLINGINTERVAL", item);
                    } else if (
                        item.name === "zimbraPrefMessageViewHtmlPreferred"
                    ) {
                        commit("SET_ZIMBRAPREFMESSAGEVIEWHTMLPREFERRED", item);
                    } else if (item.name === "zimbraPrefShowFragments") {
                        commit("SET_ZIMBRAPREFSHOWFRAGMENTS", item);
                    } else if (item.name === "zimbraPrefOpenMailInNewWindow") {
                        commit("SET_ZIMBRAPREFOPENMAILINNEWWINDOW", item);
                    } else if (
                        item.name === "zimbraPrefDisplayTimeInMailList"
                    ) {
                        commit("SET_ZIMBRAPREFDISPLAYTIMEINMAILLIST", item);
                    } else if (
                        item.name === "zimbraPrefDisplayExternalImages"
                    ) {
                        commit("SET_ZIMBRAPREFDISPLAYEXTERNALIMAGES", item);
                    } else if (item.name === "zimbraPrefMarkMsgRead") {
                        commit("SET_ZIMBRAPREFMARKMSGREAD", item);
                    } else if (
                        item.name === "zimbraPrefMailSelectAfterDelete"
                    ) {
                        commit("SET_ZIMBRAPREFMAILSELECTAFTERDELETE", item);
                    } else if (item.name === "zimbraPrefColorMessagesEnabled") {
                        commit("SET_ZIMBRAPREFCOLORMESSAGESENABLED", item);
                    } else if (item.name === "zimbraPrefMailInitialSearch") {
                        commit("SET_ZIMBRAPREFMAILINITIALSEARCH", item);
                    } else if (
                        item.name === "zimbraPrefMailForwardingAddress"
                    ) {
                        commit("SET_ZIMBRAPREFMAILFORWARDINGADDRESS", item);
                    } else if (
                        item.name === "zimbraPrefMailLocalDeliveryDisabled"
                    ) {
                        commit("SET_ZIMBRAPREFMAILLOCALDELIVERYDISABLED", item);
                    } else if (
                        item.name === "zimbraPrefNewMailNotificationAddress"
                    ) {
                        commit(
                            "SET_ZIMBRAPREFNEWMAILNOTIFICATIONADDRESS",
                            item
                        );
                    } else if (
                        item.name === "zimbraPrefNewMailNotificationEnabled"
                    ) {
                        commit(
                            "SET_ZIMBRAPREFNEWMAILNOTIFICATIONENABLED",
                            item
                        );
                    } else if (item.name === "zimbraPrefMailToasterEnabled") {
                        commit("SET_ZIMBRAPREFMAILTOASTERENABLED", item);
                    } else if (
                        item.name === "zimbraPrefShowAllNewMailNotifications"
                    ) {
                        commit(
                            "SET_ZIMBRAPREFSHOWALLNEWMAILNOTIFICATIONS",
                            item
                        );
                    } else if (item.name === "zimbraPrefMailSendReadReceipts") {
                        commit("SET_ZIMBRAPREFMAILSENDREADRECEIPTS", item);
                    } else if (
                        item.name === "zimbraPrefDedupeMessagesSentToSelf"
                    ) {
                        commit("SET_ZIMBRAPREFDEDUPEMESSAGESSENTTOSELF", item);
                    } else if (
                        item.name === "zimbraPrefMessageIdDedupingEnabled"
                    ) {
                        commit("SET_ZIMBRAPREFMESSAGEIDDEDUPINGENABLED", item);
                    } else if (item.name === "zimbraPrefComposeFormat") {
                        commit("SET_ZIMBRAPREFCOMPOSEFORMAT", item);
                    } else if (
                        item.name === "zimbraPrefHtmlEditorDefaultFontFamily"
                    ) {
                        commit(
                            "SET_ZIMBRAPREFHTMLEDITORDEFAULTFONTFAMILY",
                            item
                        );
                    } else if (
                        item.name === "zimbraPrefHtmlEditorDefaultFontSize"
                    ) {
                        commit("SET_ZIMBRAPREFHTMLEDITORDEFAULTFONTSIZE", item);
                    } else if (
                        item.name === "zimbraPrefHtmlEditorDefaultFontColor"
                    ) {
                        commit(
                            "SET_ZIMBRAPREFHTMLEDITORDEFAULTFONTCOLOR",
                            item
                        );
                    } else if (
                        item.name === "zimbraPrefAutoSaveDraftInterval"
                    ) {
                        commit("SET_ZIMBRAPREFAUTOSAVEDRAFTINTERVAL", item);
                    } else if (
                        item.name === "zimbraPrefForwardReplyInOriginalFormat"
                    ) {
                        commit(
                            "SET_ZIMBRAPREFFORWARDREPLYINORIGINALFORMAT",
                            item
                        );
                    } else if (item.name === "zimbraPrefComposeInNewWindow") {
                        commit("SET_ZIMBRAPREFCOMPOSEINNEWWINDOW", item);
                    } else if (item.name === "zimbraPrefSaveToSent") {
                        commit("SET_ZIMBRAPREFSAVETOSENT", item);
                    } else if (
                        item.name === "zimbraPrefMailRequestReadReceipts"
                    ) {
                        commit("SET_ZIMBRAPREFMAILREQUESTREADRECEIPTS", item);
                    } else if (
                        item.name === "zimbraPrefMandatorySpellCheckEnabled"
                    ) {
                        commit(
                            "SET_ZIMBRAPREFMANDATORYSPELLCHECKENABLED",
                            item
                        );
                    } else if (item.name === "zimbraPrefUseSendMsgShortcut") {
                        commit("SET_ZIMBRAPREFUSESENDMSGSHORTCUT", item);
                    } else if (item.name === "zimbraPrefTabInEditorEnabled") {
                        commit("SET_ZIMBRAPREFTABINEDITORENABLED", item);
                    } else if (
                        item.name === "zimbraPrefReplyIncludeOriginalText"
                    ) {
                        commit("SET_ZIMBRAPREFREPLYINCLUDEORIGINALTEXT", item);
                    } else if (
                        item.name === "zimbraPrefForwardIncludeOriginalText"
                    ) {
                        commit(
                            "SET_ZIMBRAPREFFORWARDINCLUDEORIGINALTEXT",
                            item
                        );
                    } else if (
                        item.name === "zimbraPrefForwardReplyPrefixChar"
                    ) {
                        commit("SET_ZIMBRAPREFFORWARDREPLYPREFIXCHAR", item);
                    } else if (item.name === "zimbraPrefPop3Enabled") {
                        commit("SET_ZIMBRAPREFPOP3ENABLED", item);
                    } else if (item.name === "zimbraPrefImapEnabled") {
                        commit("SET_ZIMBRAPREFIMAPENABLED", item);
                    } else if (item.name === "zimbraPrefPop3DownloadSince") {
                        commit("SET_ZIMBRAPREFPOP3DOWNLOADSINCE", item);
                    } else if (item.name === "zimbraPrefPop3IncludeSpam") {
                        commit("SET_ZIMBRAPREFPOP3INCLUDESPAM", item);
                    } else if (item.name === "zimbraPrefPop3DeleteOption") {
                        commit("SET_ZIMBRAPREFPOP3DELETEOPTION", item);
                    } else if (
                        item.name === "zimbraPrefDelegatedSendSaveTarget"
                    ) {
                        commit("SET_ZIMBRAPREFDELEGATEDSENDSAVETARGET", item);
                    } else if (item.name === "zimbraPrefMailSignatureStyle") {
                        commit("SET_ZIMBRAPREFMAILSIGNATURESTYLE", item);
                    }
                });
                return resp;
            } catch (error) {
                console.log(error);
            }
        },
        async getBlackWhiteList({ commit }) {
            try {
                const resp = await inboxService.getBlackWhiteList();
                const data =
                    resp["data"]["soap:Envelope"]["soap:Body"][
                    "GetWhiteBlackListResponse"
                    ];
                const blackList =
                    data.blackList.addr === undefined
                        ? []
                        : data.blackList.addr;
                const whiteList =
                    data.whiteList.addr === undefined
                        ? []
                        : data.whiteList.addr;
                if (resp.data) {
                    commit("SET_BLACKLIST", blackList);
                    commit("SET_WHITELIST", whiteList);
                }
                return resp;
            } catch (error) {
                console.log(error);
            }
        },
        async modifyBlackList({ commit }, data) {
            try {
                const resp = await inboxService.modifyBlackList(data);
                if (resp.data.code === "SUCCESS") {
                    data.map(item => {
                        if (item.operator === "+") {
                            commit("ADD_BLACKLIST", {
                                id: item.id,
                                email: item.email
                            });
                        } else {
                            commit("DELETE_BLACKLIST", item.email);
                        }
                    });
                }
                return resp;
            } catch (error) {
                console.log(error);
            }
        },
        async modifyWhiteList({ commit }, data) {
            try {
                const resp = await inboxService.modifyWhiteList(data);
                if (resp.data.code === "SUCCESS") {
                    data.map(item => {
                        if (item.operator === "+") {
                            commit("ADD_WHITELIST", {
                                id: item.id,
                                email: item.email
                            });
                        } else {
                            commit("DELETE_WHITELIST", item.email);
                        }
                    });
                }
                return resp;
            } catch (error) {
                console.log(error);
            }
        },
        async saveInboxSetting({ commit }, data) {
            try {
                const resp = await inboxService.saveSetting(data);
                if (resp.data.code === "SUCCESS") {
                    data.prefList.map(item => {
                        commit(`EDIT_${item.name.trim().toUpperCase()}`, item);
                    });
                    data.blackList.map(item => {
                        if (item.operator === "+") {
                            commit("ADD_BLACKLIST", {
                                id: item.id,
                                email: item.email
                            });
                        } else {
                            commit("DELETE_BLACKLIST", item.email);
                        }
                    });
                    data.whiteList.map(item => {
                        if (item.operator === "+") {
                            commit("ADD_WHITELIST", {
                                id: item.id,
                                email: item.email
                            });
                        } else {
                            commit("DELETE_WHITELIST", item.email);
                        }
                    });
                }
                return resp;
            } catch (error) {
                console.log(error);
            }
        }
    }
};

export default inboxModule;
