<template>
  <div class="upload-title upload__wrap">
        <span class="text-label">{{ this.$t('zimbra.zmMsg.fileLabel') }}</span>
        <div class="form-controls">
            <input
                type="file"
                ref="upload_file"
                class="el-input__inner"
                style="z-index: 100; opacity: 0"
                readonly="readonly"
                :multiple="true"
                @change="handleUploadFiles"
            />
            <el-input
                placeholder="Không có tệp nào được chọn"
                class="view__name"
                :readonly="true"
                :value="renderNameUpload"
            />
    </div>
        <span class="mr-2 size-wrap" :style="renderNameUpload ? 'visibility: visible' : 'visibility: hidden'">{{ renderSizeUpload }}</span>
        <el-button type="small" class="btn-email default" @click="handleShowChooseFile">
            {{$t("zimbraNokey.zimbra_setting_import_choose_file")}}
        </el-button>
        <div class="button-group-circle">
            <button class="btn-circle btn-add" @click="handleAddFileItem">
                <i class="fa fa-plus"></i>
            </button>
            <button
            class="btn-circle btn-delete"
            v-if="listFile.length !== 1"
            @click="handleDeleteFileItem">
                <i class="fa fa-minus"></i>
            </button>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
import CommonUtils from "@/utils/common-utils";
import _ from "lodash"
export default {
    mixins: [CommonUtils],
    props: {
        listFile: {
            type: Array,
            required: true,
        },
        file: {
            type: Object,
        },
        index: {
            type: Number
        }
    },
    created() {

    },
    data() {
        return {
            fileSelect: null,
            uploadFailed: false,
        }
    },
    computed: {
        ...mapGetters(["maxSizeUpload"]),
        renderNameUpload() {
            if (this.file.content.length > 1) {
                return `${this.file.content.length} tệp`
            } else if (this.file.content.length === 1) {
                return this.file.content[0].name
            } else {
                return this.file.content.name
            }
        },
        renderSizeUpload() {
            let _sizeUpload = 0
            const _listFiles = typeof (this.file.content) === 'object' ? this.file.content : []
            _listFiles.forEach(file => _sizeUpload += file.size)
            return this.formatSize(_sizeUpload)
        }
    },
    methods: {
        async handleUploadFiles(event) {
            let invalid = false
            if (event) {
                const files = [...event.target.files];
                if (files.length) {
                    let _sizeUpload = 0
                    files.forEach(file => _sizeUpload += file.size)
                    this.fileSelect = files
                    this.file.content = this.fileSelect
                    if (_sizeUpload > this.maxSizeUpload) {
                        this.uploadFailed = true
                        const size = this.formatSize(this.maxSizeUpload);
                        await this.$confirm(
                            this.$t("zimbraNokey.zimbra_compose_validateMaxSizeAttach", {
                                size,
                            }),
                            this.$t("zimbraNokey.zimbra_compose_criticalMsg"),
                            {
                                showConfirmButton: false,
                                cancelButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                                type: "error",
                            }
                        )
                            .then(() => {
                            })
                            .catch(() => {
                                invalid = true;
                            });
                        this.$emit("renderData", [
                            {
                                id: 0,
                                content: [{ name: "" }]
                            }
                        ]);
                    }
                } else {
                    event.target.files = null
                    this.file.content = [{ content: {} }]
                    this.fileSelect = null
                }
                if (invalid) {
                    return;
                }
            }
        },
        handleShowChooseFile() {
            this.$refs.upload_file.click();
        },
        handleAddFileItem() {
            const item = {
                id: this.index + 1,
                content: ""
            }
            let listFile = _.cloneDeep(this.listFile)
            listFile.push(item)
            this.$emit("renderData", listFile)
        },
        //DWP-187
        handleDeleteFileItem() {
            let listFile = _.cloneDeep(this.listFile)
            // listFile.splice(this.file.id, 1)
            const index = listFile.findIndex(file => file.id === this.file.id);
            if (index !== -1) {
                listFile.splice(index, 1);
                this.$emit("renderData", listFile);
            }
        }
    }
}
</script>

<style>

</style>
