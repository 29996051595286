<template>
    <el-dialog
        class="dialog-body-wrap zimbra-dialog"
        :title="titleDialog"
        :visible.sync="showDialog"
        width="30%"
        height="550px"
        :append-to-body="true"
        :close-on-click-modal="false"
        :destroy-on-close="true"
    >
        <el-form class="form-wrap">
            <div
                style="padding-bottom: 10px"
                >{{ $t("zimbra.zmMsg.chooserDescription") }}
            </div>
            <TreeFolderSelector
             :folderType="type"
             @selectFolder="selectFolder"
            ></TreeFolderSelector>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button
                v-if="showButtonNew"
                class="btn-outline"
                @click="handleNew"
                style="float: left"
                >{{ $t("zimbra.zhMsg.apptPtstNEW") }}</el-button
            >
            <el-button
                class="btn-email default"
                @click="showDialog = false"
                >{{ $t("zimbra.zhMsg.actionTaskCancelTT") }}</el-button
            >
            <el-button
                class="btn-email primary"
                type="primary"
                @click="handleSelect()"
                >{{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import TreeFolderSelector from "./TreeFolderSelector";
import CommonUtils from "@/utils/common-utils";
import EmailUtils from "@/utils/email-utils";
import { mapGetters } from "vuex";
import { FOLDER_TYPE } from "@/utils/constants";

export default {
    mixins: [CommonUtils, EmailUtils],
    mounted() {
        this.$root.$on("showDialogSelectTor", (objectInput) => {
            this.show(objectInput);
        });
    },
    computed: {
        ...mapGetters([
            "activeFolderExtra",
            "listSelectedMail",
            "listEmailSearch"
        ]),
    },
    components: {
        TreeFolderSelector,
    },
    data() {
        return {
            showDialog: false,
            type: "",
            FOLDER_TYPE: FOLDER_TYPE,
            titleDialog: "",
            folderSelect: {},
            showButtonNew: false,
            isChooseTag: false
        };
    },
    methods: {
        selectFolder(folder, isDubbleClick) {
            if (isDubbleClick) {
                this.handleSelect(folder);
            } else {
                this.folderSelect = folder;
            }
        },
        show(objectInput) {
            this.type = objectInput.type;
            this.isChooseTag = objectInput.isChoose;
            this.showButtonNew = true;
            switch (this.type) {
                case FOLDER_TYPE.TAG:
                    this.titleDialog = this.$t("zimbra.zmMsg.pickATag");
                    break;
                case FOLDER_TYPE.SEARCH:
                    this.titleDialog = this.$t("zimbra.zmMsg.selectSearch");
                    this.showButtonNew = false;
                    break;
                case FOLDER_TYPE.FOLDER:
                    this.titleDialog = this.$t("zimbra.zhMsg.EFILT_ACTION_FILEINTO");
                    break;
            }
            this.showDialog = true;
        },
        handleSelect(data) {
            const folderSelect = data || this.folderSelect;
            if (this.type == FOLDER_TYPE.TAG && this.isChooseTag) {
                this.$root.$emit("handleSelectNode", folderSelect);
                this.$root.$emit("setCurrentKeyMainTree", this.folderSelect.id);
            }
            if (this.type == FOLDER_TYPE.TAG && !this.isChooseTag) {
                this.handledAssginOrRemoveTag(folderSelect, true);
            }
            if (this.type == FOLDER_TYPE.SEARCH || this.type == FOLDER_TYPE.FOLDER) {
                if (!folderSelect) {
                    this.$confirm(
                        this.$t("zimbra.zmMsg.noTargetFolder"),
                        this.$t("zimbraNokey.zimbra_common_criticalMsg"),
                        {
                            showCancelButton: false,
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    ).then(() => {})
                    return;
                }
                this.$store.commit("SET_ACTIVE_NODE_SIDEBAR", folderSelect);
                this.$root.$emit("handleSelectNode", folderSelect);
            }
            this.showDialog = false;
        },
        handleNew() {
            if (this.type == FOLDER_TYPE.TAG) {
                this.$root.$emit("handleTagActionAdd");
            }
            if (this.type == FOLDER_TYPE.FOLDER_TYPE) {
                this.$root.$emit("handleSidebarActionMoveAdd", false);
            }
        }
    },
};
</script>
