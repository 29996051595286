var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showTextboxSearch)?_c('base-input',{staticStyle:{"padding-bottom":"10px"},attrs:{"value":_vm.filterText,"placeholder":"","size":"medium"},on:{"handleInput":_vm.handleFilter}}):_vm._e(),_c('perfect-scrollbar',{staticStyle:{"max-height":"585px"}},[_c('el-tree',{ref:"tree",staticClass:"tree-wrap",attrs:{"data":_vm.dataInbox,"node-key":"id","props":_vm.defaultProps,"filter-node-method":_vm.filterNode,"highlight-current":"","draggable":"","default-expanded-keys":[_vm.FOLDER_DEFAULT.ROOT],"show-checkbox":_vm.showCheckbox,"expand-on-click-node":false,"empty-text":""},on:{"node-click":_vm.nodeClick},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('div',{staticClass:"zm-folder-info"},[_c('el-popover',{attrs:{"placement":"bottom-start","open-delay":1000,"trigger":"hover","disabled":!_vm.showInfoFolder}},[_c('div',[_c('b',[_vm._v(_vm._s(node.label))]),_vm._v(" "),_c('br'),(data.id != _vm.FOLDER_DEFAULT.STARS)?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"folder-info-left"},[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.mailLabel")))]),_c('span',{staticClass:"folder-info-right"},[_vm._v(_vm._s(data.n || 0))])]):_vm._e(),(data.u)?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"folder-info-left"},[_vm._v(_vm._s(_vm.$t("zimbraNokey.zimbra_sidebar_infoUnread")))]),_c('b',{staticClass:"folder-info-right"},[_vm._v(_vm._s(data.u))])]):_vm._e(),(data.s)?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"folder-info-left"},[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.fSizeLabel")))]),_c('span',{staticClass:"folder-info-right"},[_vm._v(_vm._s(_vm.formatSize(data.s)))])]):_vm._e()]),_c('span',{staticClass:"custom-tree-node",attrs:{"slot":"reference"},slot:"reference"},[(
                                data.id != _vm.FOLDER_DEFAULT.DRAFTS &&
                                data.id != _vm.FOLDER_DEFAULT.INBOX &&
                                data.id != _vm.FOLDER_DEFAULT.SENT &&
                                data.id != _vm.FOLDER_DEFAULT.STARS &&
                                data.isLink == undefined
                            )?_c('span',[_c('i',{staticClass:"ic-folder",style:(`padding-right: 30px; margin-left: -8px;filter: ${_vm.convertHexToFilterCss(
                                    data
                                )}`)})]):_vm._e(),(data.isLink)?_c('span',[_c('i',{staticClass:"ic-folder-copy",staticStyle:{"padding-right":"30px","margin-left":"-8px"}})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.STARS)?_c('span',[_c('i',{staticClass:"ic-star",staticStyle:{"padding-right":"30px","margin-left":"-8px"}})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.SENT)?_c('span',[_c('i',{staticClass:"ic-sent",staticStyle:{"padding-right":"30px","margin-left":"-8px"}})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.DRAFTS)?_c('span',[_c('i',{staticClass:"ic-draft",staticStyle:{"padding-right":"30px","margin-left":"-8px","zoom":"1.05"}})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.INBOX)?_c('span',[_c('i',{class:`ic-inbox ${
                                    node.expanded == true ? 'active' : ''
                                }`,staticStyle:{"padding-right":"30px","margin-left":"-8px","zoom":"1.05"}})]):_vm._e(),(
                                data.isLink == undefined ||
                                (data.isLink && data.perm)
                            )?_c('span',[_vm._v(_vm._s(node.label))]):_vm._e(),(data.isLink && data.perm == undefined)?_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(node.label))]):_vm._e()])])],1)}}])}),_c('el-tree',{ref:"treeExtend",staticClass:"tree-wrap tree-extend",staticStyle:{"margin-top":"10px"},attrs:{"node-key":"id","empty-text":_vm.emptyText,"data":_vm.dataExtend,"props":_vm.defaultProps,"filter-node-method":_vm.filterNode,"highlight-current":"","draggable":"","default-expanded-keys":[_vm.FOLDER_DEFAULT.EXTEND],"expand-on-click-node":false},on:{"node-click":_vm.nodeClick},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('div',{staticClass:"zm-folder-info"},[_c('el-popover',{attrs:{"placement":"bottom-start","open-delay":1000,"trigger":"hover","disabled":!_vm.showInfoFolder}},[_c('div',[_c('b',[_vm._v(_vm._s(node.label))]),_vm._v(" "),_c('br'),(data.id != _vm.FOLDER_DEFAULT.STARS)?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"folder-info-left"},[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.mailLabel")))]),_c('span',{staticClass:"folder-info-right"},[_vm._v(_vm._s(data.n || 0))])]):_vm._e(),(data.u)?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"folder-info-left"},[_vm._v(_vm._s(_vm.$t("zimbraNokey.zimbra_sidebar_infoUnread")))]),_c('b',{staticClass:"folder-info-right"},[_vm._v(_vm._s(data.u))])]):_vm._e(),(data.s)?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"folder-info-left"},[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.fSizeLabel")))]),_c('span',{staticClass:"folder-info-right"},[_vm._v(_vm._s(_vm.formatSize(data.s)))])]):_vm._e()]),_c('span',{staticClass:"custom-tree-node",attrs:{"slot":"reference"},slot:"reference"},[(
                                data.id != _vm.FOLDER_DEFAULT.TRASH &&
                                data.id != _vm.FOLDER_DEFAULT.JUNK &&
                                data.id != _vm.FOLDER_DEFAULT.EXTEND &&
                                data.id != _vm.FOLDER_DEFAULT.TAG &&
                                data.isLink == undefined
                            )?_c('span',[_c('i',{staticClass:"ic-folder",style:(`padding-right: 30px; margin-left: -8px;filter: ${_vm.convertHexToFilterCss(
                                    data
                                )}`)})]):_vm._e(),(data.isLink)?_c('span',[_c('i',{staticClass:"ic-folder-copy",staticStyle:{"padding-right":"30px","margin-left":"-8px","zoom":"1.05"}})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.JUNK)?_c('span',[_c('i',{staticClass:"ic-trash",staticStyle:{"padding-right":"28px","margin-left":"-9px"}})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.TRASH)?_c('span',[_c('i',{staticClass:"ic-delete-red",staticStyle:{"padding-right":"28px","margin-left":"-9px"}})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.TAG)?_c('span',[_c('i',{staticClass:"ic-tag",staticStyle:{"padding-right":"28px","margin-left":"-9px"}})]):_vm._e(),(
                                data.id != _vm.FOLDER_DEFAULT.EXTEND &&
                                (data.isLink == undefined ||
                                    (data.isLink && data.perm))
                            )?_c('span',[_vm._v(_vm._s(node.label))]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.EXTEND)?_c('span',{staticStyle:{"margin-left":"-4px"}},[_vm._v(_vm._s(node.expanded ? _vm.$t("zimbra.zmMsg.collapse") : _vm.$t("zimbraNokey.zimbra_sidebar_extend")))]):_vm._e(),(data.isLink && data.perm == undefined)?_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(node.label))]):_vm._e()])])],1)}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }