import BaseService from "../shared/services/BaseService";
import CONFIG from "@/config/config";
import axios from "axios";

class AuthSSOService extends BaseService {
    constructor() {
        super("ssoService", "X_GRAVITEE_API_KEY_SSO");
    }

    validateTicket(ticket) {
        // const url = `${this.baseURL}/p3/serviceValidate?service=${encodeURIComponent(CONFIG.CLIENT_ADDRESS+'/auth-sso')}&ticket=${ticket}`;
        // return this.getRequest(url, {});
        // const url = 'http://localhost:5000/test';
        // const config = {
        //     baseURL: url,
        //     timeout: CONFIG.TIMEOUT_REQUEST,
        //     headers: {
        //         "X-Gravitee-Api-Key": CONFIG.X_GRAVITEE_API_KEY_SSO,
        //         "X-Cookie": document.cookie,
        //         // "Access-Control-Allow-Origin": "*",
        //         // "Access-Control-Allow-Credentials": true,
        //         // "X-Requested-With": "XMLHttpRequest",
        //         // "Cache-Control": "no-cache",
        //         // "content-type": "multipart/form-data"
        //     },
        //     validateStatus: function (status) {
        //         return status >= 200 && status < 500; // default
        //     },
        //     withCredentials: true,
        //     exposedHeaders: ["set-cookie"],
        // }
        // const instanceService = axios.create(config);
        // const body = {
        //     password: password,
        //     ticket: ticket
        // }
        // // return instanceService.post(url, {}, config);
        // return 
            // const origin = process.env.NODE_ENV === "development" ? "mail.epass-vdtc.com.vn" : window.location.origin;
            // const formData = {
            //     password: this.data.password,
            // }
            // const configDomain = this.$configZimbra.VIRTUAL_HOST.find(el => origin.indexOf(el.origin) !== -1);
            // let suffixesDomain = "";
            // if (configDomain) {
            //     formData['virtualHost'] = configDomain.origin;
            //     suffixesDomain = configDomain.domain;
            // }
            // formData['username'] = this.data.username.indexOf("@") > -1 ? this.data.username : this.data.username.concat(suffixesDomain);

            // formData['username'] = 'quangnv_is@bitel.com.pe';
            // formData['password'] = '1qazXSW@'
            // AccountService.login(formData)
            //     .then(async (response) => {
            //         const { authToken, lifetime, session } = this.getResponseBody(response)["AuthResponse"];
            //         const _username = formData.username;
            //         const tokenStorage = {
            //             lifetime: lifetime,
            //             username: _username,
            //             session: session
            //         };
            //         if (process.env.NODE_ENV === "development") {
            //             tokenStorage['auth_token'] = authToken;
            //             this.setCookie("ZM_AUTH_TOKEN" , authToken);
            //         }
            //         this.addLocalStorageObject("zimbra_token", tokenStorage);
            //         // this.setCookie("_session" , session?.id);
            //         // this.setCookie("X-Gravitee-Api-Key", this.$configZimbra.X_GRAVITEE_API_KEY_SERVICE_IMPORT_EXPORT);
            //         this.getAccountInfo(_username);
            //         const { origin } = window.location;
            //         window.location.href = `${origin}/mail`;
            //     })
            //     .catch((err) => {
            //         const errorCode = this.getResponseBody(err.response)[
            //             "soap:Fault"
            //         ].detail.Error.Code;
            //         if (errorCode == ERROR_CODE.ACCOUNT_AUTH_FAILED) {
            //             this.authFailed = true;
            //         }
            //     });
        
    }
}
export default new AuthSSOService();
