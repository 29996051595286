<script>
import { FILTER_TESTS, FILTER_TEST_CONFIG } from "@/utils/constants";
import CommonUtils from "@/utils/common-utils";
export default {
    props: {
        conditionsTest: {
            type: [Object],
        },
        index: {
            type: [Number],
        },
    },
    mixins: [CommonUtils],
    mounted(){

    },
    data() {
        return {
            FILTER_TESTS: FILTER_TESTS,
            FILTER_TEST_CONFIG: FILTER_TEST_CONFIG,
            ignoreProps: ["condition"],
            mainSelect: [
                {
                    id: 0,
                    name: this.$t("zimbra.zhMsg.FILT_COND_HEADER_from"),
                    header: FILTER_TEST_CONFIG.HEADER.FROM,
                    types: FILTER_TESTS.ADDRESS_TEST,
                },
                {
                    id: 1,
                    name: this.$t("zimbra.zhMsg.FILT_COND_HEADER_to"),
                    header: FILTER_TEST_CONFIG.HEADER.TO,
                    types: FILTER_TESTS.ADDRESS_TEST,
                },
                {
                    id: 2,
                    name: this.$t("zimbra.zhMsg.cc"),
                    header: FILTER_TEST_CONFIG.HEADER.CC,
                    types: FILTER_TESTS.ADDRESS_TEST,
                },
                {
                    id: 3,
                    name: this.$t("zimbra.zhMsg.FILT_COND_HEADER_to,cc"),
                    header: FILTER_TEST_CONFIG.HEADER.TO_CC,
                    types: FILTER_TESTS.ADDRESS_TEST,
                },
                {
                    id: 4,
                    name: this.$t("zimbra.zhMsg.FILT_COND_HEADER_subject"),
                    header: FILTER_TEST_CONFIG.HEADER.SUBJECT,
                    index: 1,
                    types: FILTER_TESTS.HEADER_TEST,
                },
                {
                    id: 5,
                    name: this.$t("zimbra.zhMsg.ALT_APP_MAIL"),
                    types: FILTER_TESTS.CONVERSATION_TEST,
                },
                {
                    id: 6,
                    name: this.$t("zimbra.zhMsg.MO_size"),
                    types: FILTER_TESTS.SIZE_TEST,
                },
                {
                    id: 7,
                    name: this.$t("zimbra.zhMsg.calViewDayShort"),
                    types: FILTER_TESTS.DATE_TEST,
                },
                {
                    id: 8,
                    name: this.$t("zimbra.zmMsg.body"),
                    types: FILTER_TESTS.BODY_TEST,
                },
                {
                    id: 9,
                    name: this.$t(
                        "zimbra.zhMsg.ALT_ATTACHMENT"
                    ),
                    types: FILTER_TESTS.ATTACHMENT_TEST,
                },
                {
                    id: 10,
                    name: this.$t(
                        "zimbra.zmMsg.readReceiptFilter"
                    ),
                    types: FILTER_TESTS.MIME_HEADER_TEST,
                },
                {
                    id: 11,
                    name: this.$t("zimbra.zmMsg.addressIn"),
                    types: FILTER_TESTS.ADDRESS_BOOK_TEST,
                },
                {
                    id: 12,
                    name: this.$t("zimbra.zhMsg.ALT_APP_CALENDAR"),
                    types: FILTER_TESTS.INVITE_TEST,
                },
                {
                    id: 13,
                    name: this.$t("zimbra.zmMsg.socialLabel"),
                    types: FILTER_TESTS.FACEBOOK_TEST,
                },
                {
                    id: 14,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_headerNamed"
                    ),
                    index: 2,
                    types: FILTER_TESTS.HEADER_TEST,
                },
            ],
        };
    },
    methods: {
        handleChangeMainSelect(id) {
            const selectValue = this.mainSelect[id];
            const valueStringComparison = this.valueStringComparison
                    ? this.replacePrefix(this.valueStringComparison)
                    : FILTER_TEST_CONFIG.STRING_COMPARTION.IS;
            switch (selectValue.types){
                case FILTER_TESTS.ADDRESS_TEST:
                    this.conditionAddressTest = {
                        value: this.valueAddressTest ? this.valueAddressTest : "",
                        header: selectValue.header,
                        stringComparison: valueStringComparison,
                        index: this.conditionsTest.index,
                        part: this.valuePart
                            ? this.valuePart
                            : FILTER_TEST_CONFIG.PART.ALL,
                        types: selectValue.types,
                    };
                    if (this.validateNegative(valueStringComparison)) {
                        this.conditionAddressTest = {
                            ...this.conditionAddressTest,
                            negative: FILTER_TEST_CONFIG.NEGATIVE,
                        };
                    }
                    this.$emit("onChangeConditionsTest", this.conditionAddressTest);
                    break;
                case FILTER_TESTS.HEADER_TEST:
                    switch(selectValue.index){
                        case 1:
                            this.conditionHeaderTest = {
                                index: this.conditionsTest.index,
                                header: FILTER_TEST_CONFIG.HEADER.SUBJECT,
                                stringComparison: FILTER_TEST_CONFIG.STRING_COMPARTION.IS,
                                value: "",
                                types: selectValue.types,
                            };
                            this.$emit("onChangeConditionsTest", this.conditionHeaderTest);
                            break;
                        case 2:
                            this.conditionsHeaderNameTest = {
                                index: this.conditionsTest.index,
                                header: FILTER_TEST_CONFIG.HEADER.NAME,
                                stringComparison: FILTER_TEST_CONFIG.STRING_COMPARTION.IS,
                                value: "",
                                types: selectValue.types,
                            };
                            this.$emit("onChangeConditionsTest",this.conditionsHeaderNameTest);
                            break;
                    }
                    break;
                case FILTER_TESTS.CONVERSATION_TEST:
                    this.conditionsMessageTest = {
                        index: this.conditionsTest.index,
                        where: FILTER_TEST_CONFIG.WHERE.STARTED,
                        types: selectValue.types,
                    };
                    this.$emit("onChangeConditionsTest", this.conditionsMessageTest);
                    break;
                case FILTER_TESTS.SIZE_TEST:
                    this.conditionsSizeTest = {
                        index: this.conditionsTest.index,
                        numberComparison: FILTER_TEST_CONFIG.NUMBER_COMPARISON.UNDER,
                        s: FILTER_TEST_CONFIG.NUMBER_COMPARISON.B,
                        types: selectValue.types,
                    };
                    this.$emit("onChangeConditionsTest", this.conditionsSizeTest);
                    break;
                case FILTER_TESTS.DATE_TEST:
                    this.conditionsDateTest = {
                        index: this.conditionsTest.index,
                        dateComparison: FILTER_TEST_CONFIG.DATE_COMPARTISON.BEFORE,
                        d: "",
                        types: selectValue.types,
                    };
                    this.$emit("onChangeConditionsTest", this.conditionsDateTest);
                    break;
                case FILTER_TESTS.BODY_TEST:
                    this.conditionsBodyTest = {
                        index: this.conditionsTest.index,
                        value: "",
                        types: selectValue.types,
                    };
                    this.$emit("onChangeConditionsTest", this.conditionsBodyTest);
                    break;
                case FILTER_TESTS.ATTACHMENT_TEST:
                    this.conditionsAttachMentTest = {
                        index: this.conditionsTest.index,
                        types: selectValue.types,
                    };
                    this.$emit("onChangeConditionsTest",this.conditionsAttachMentTest);
                    break;
                case FILTER_TESTS.MIME_HEADER_TEST:
                    this.conditionsMimeHeaderTest = {
                        index: this.conditionsTest.index,
                        stringComparison: FILTER_TEST_CONFIG.STRING_COMPARTION.CONTAINS,
                        value: FILTER_TEST_CONFIG.VALUE,
                        types: selectValue.types,
                    };
                    this.$emit("onChangeConditionsTest",this.conditionsMimeHeaderTest);
                    break;
                case FILTER_TESTS.ADDRESS_BOOK_TEST:
                    this.conditionsAddressInTest = {
                        index: this.conditionsTest.index,
                        header: FILTER_TEST_CONFIG.HEADER.FROM.toUpperCase(),
                        types: selectValue.types,
                    };
                    this.$emit("onChangeConditionsTest",this.conditionsAddressInTest);
                    break;
                case FILTER_TESTS.FACEBOOK_TEST:
                    this.conditionsSocialTest = {
                        index: this.conditionsTest.index,
                        types: selectValue.types,
                    };
                    this.$emit("onChangeConditionsTest", this.conditionsSocialTest);
                    break;
                case FILTER_TESTS.INVITE_TEST:
                    this.conditionsInviteTest = {
                        index: this.conditionsTest.index,
                        method: FILTER_TEST_CONFIG.METHOD.ANY_REPLY,
                        types: selectValue.types,
                    };
                    this.$emit("onChangeConditionsTest", this.conditionsInviteTest);
                    break;
                default:
                break;
            }
        },
        validateNegative(data) {
            if (data.slice(0, 3) === "not") return true;
            else return false;
        },
        replacePrefix(data, subStr = "not", newSub = "") {
            return data.replace(subStr, newSub);
        },
    },
};
</script>
