import development from "@/config/develoment.json";
import production from "@/config/production.json";
import test from "@/config/test.json";

let CONFIG = {}

switch (process.env.NODE_ENV) {
    case "production":
        CONFIG = Object.freeze(production);
        break;
    case "development":
        CONFIG = Object.freeze(development);
        break;
    case "development_test":
        CONFIG = Object.freeze(test);
        break;
    default:
        CONFIG = Object.freeze(development);
        break;
}

export default CONFIG;
