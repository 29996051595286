<template>
    <!-- Tag -->
    <el-tree
            ref="tagTree"
            class="tree-wrap"
            style="margin-top: 10px"
            node-key="id"
            id="treeExtend"
            :data="treeTagData"
            :props="defaultProps"
            @node-click="onClickTagTree"
            @node-contextmenu="rightClickTag"
            draggable
            highlight-current
            :allow-drop="allowDrop"
            :allow-drag="allowDrag"
            :expand-on-click-node="false"
            :default-expanded-keys="defaultExpandTreeExtend"
        >
            <div class="zm-folder-info" slot-scope="{ node, data }">
                <div class="custom-tree-node">
                        <span>
                            <i :class="['ic-tag', 'ic-with-color']"
                                style="padding-right: 28px; margin-left: -9px"
                                :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(data)};
                                        --icColor: ${convertHexToFilterCss(data)};`"
                            ></i>
                        </span>
                        <span :class="data.u > 0 ? 'font-weight-bold' : '' ">
                            {{ node.label }}
                        </span>
                        <span v-if="data.u > 0" class="font-weight-bold">
                            ({{data.u}})
                        </span>
                        <!-- icon hover -->
                        <button
                            class="icon-caret float-right"
                            :id="`caret_${data.id}`"
                            @click.stop="(e) => rightClickTag(e, data, node)"
                        >
                            <i class="el-icon-caret-bottom"></i>
                        </button>
                </div>
            </div>
    </el-tree>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import EmailUltils from "@/utils/email-utils";
import { FOLDER_DEFAULT } from "@/utils/constants";
import { mapGetters } from "vuex";
import { FOLDER_VIEW } from '../../../../../utils/constants';
import _ from "lodash"
export default {
    mixins: [CommonUtils, EmailUltils],
    props: {
    },
    components: {
    },
    data() {
        return {
            defaultProps: {
                children: "folder",
                label: "name",
            },
            defaultExpandTreeExtend: [-2],
        };
    },
    mounted() {

        /**
         * hàm lắng nghe sự kiện xóa tag
         */
        this.$root.$on("deleteItemToDataExtend", (itemAdd) => {
            this.deleteItemToData(itemAdd, this.dataSidebarExtend);
            //Set lại dữ liệu sau khi xóa
            if (this.dataSidebarExtend[0] && this.dataSidebarExtend[0].l && this.dataSidebarExtend[0].l == -1) {
                const _listTag = this.dataSidebarExtend[0].folder.find((x) => x.id == FOLDER_DEFAULT.TAG).folder;
                this.$store.commit("SET_LIST_TAG", _listTag);
            }
        });

        /**
         * hàm lắng nghe sự kiện thêm tag
         */
        this.$root.$on("addItemToDataExtend", (itemAdd) => {
            this.addItemToData(itemAdd, this.dataSidebarExtend);
        });

        /**
         * hàm lắng nghe sự kiện cập nhật tag
         */
        this.$root.$on("updateItemToDataExtend", (itemUpdate) => {
            this.updateItemToData(itemUpdate, this.dataSidebarExtend);
        });
    },
    computed: {
        ...mapGetters([
            "dataSidebarExtend",
        ]),

        /**
         * Lấy dữ liệu thẻ
         */
        treeTagData() {
            if (this.dataSidebarExtend !== null && this.dataSidebarExtend.length > 0) {
                const dataTag = [this.dataSidebarExtend[0].folder.find((x) => x.id == FOLDER_DEFAULT.TAG)]
                dataTag[0].folder = _.orderBy(dataTag[0].folder, [(n) => { return n.name.toString().toLowerCase() }], ["asc"])
                return dataTag
            }
            return []
        },
    },
    created() {
        this.getTagApi(); // Lấy thông tin folder chung để get tag
    },
    methods: {
        rightClickTag(e, data, node) {
            if (this.$route.name == "Briefcase") {
                data.types = FOLDER_VIEW.DOCUMENT
            } else {
                data.types = FOLDER_VIEW.APPOINTMENT
            }
            this.$root.$emit("showRightMenuTag", e, data, node);
            this.$store.commit("SET_ACTIVE_NODE_SIDEBAR_RIGHTCLICK", data);
        },

        /**
         * Hàm thực hiện tìm kiếm khi click tag
         */
        onClickTagTree(event) {
            if (!event.id) {
                return;
            }
            this.$root.$emit("onClickTagTree", event.name)
        },
        allowDrop(draggingNode, dropNode, type) {
            if (draggingNode.level === dropNode.level) {
                if (draggingNode.parent.id === dropNode.parent.id) {
                    return type === "prev" || type === "next";
                }
            } else {
                return false;
            }
        },
        allowDrag() {
            return true;
        },
    },
};
</script>
