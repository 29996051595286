<template>
    <div>
        <div v-click-outside="closePopup">
                <small-popup-calendar
                    v-if="visibleSmall==true"
                    @onCloseSmall="visibleSmall=false"
                    @openMax="onpenCalendarScreen"
                    @openQuickAddCalendar="quickAddCalendar"
                    @openDialogDetailSchedule = "openDialogDetailSchedule"
                />
                <div
                    slot="reference"
                    :class="[
                        this.activeMenu === 'setting' ? 'activeMenu' : '',
                        'element',
                    ]"
                >
                    <el-tooltip class="item" effect="dark"
                                :content='this.$t("zimbra.zhMsg.ALT_APP_CALENDAR")'
                                placement="left-end"
                    >
                        <i :class="`${$route.path == '/mail/calendar' || visibleSmall ? 'calendar-icon-active' : 'calendar-icon'} zimbra-cursor-pointer`" @click="visibleSmall=!visibleSmall"></i>
                    </el-tooltip>
                </div>
                <QuickEditCalendar ref="quickEditCalendar"
                    v-if="showQuickCalender"
                    @fCloseQuickEditCalendar="closeQuickEditCalendar"
                    @actionMoreDetail="actionMoreDetail"
                    @onSendSuccess="onQuickSendSuccess"
            />
        </div>
        <calendar-add-dialog
            v-if="dialogQuickAddCalender"
            ref="refCalendarAddDialog"
            @actionMoreDetail="actionMoreDetail"
        />
        <template v-if="showCalendarForm">
            <calendar-form ref="refCalendarForm"
                @onSendSuccess="onSendSuccess"
            />
        </template>
    </div>
</template>

<script>
import QuickEditCalendar from "@/components/modules/calendar/calendar-setting/quick-edit-calendar";
import { mapGetters, mapActions } from "vuex";
import GeneralService from "@/services/GeneralService";
import SmallPopupCalendar from "./small-popup-calendar.vue";
import CalendarAddDialog from "../calendar-add-dialog/calendar-add-dialog";
import CalendarFrom from "../calendar-form/calendar-form.vue";
import hotkeys from "hotkeys-js";
// import i18n from "@/plugins/i18n";

export default {
    props: {
        activeMenu: {
            type: [String],
        },
    },
    components: {
        SmallPopupCalendar,
        "calendar-add-dialog": CalendarAddDialog,
        "calendar-form": CalendarFrom,
        QuickEditCalendar,
    },
    created() {
    },
    mounted() {
        setTimeout(() => {
            this.dialogQuickAddCalender = true;
        }, 2000);
        this.$root.$on("openQuickEditCalandar", this.openQuickEditCalandar);
        /**
         * Thực hiện phím tắt
        */
        hotkeys("q", this.handleAddNewCalendar)

        /**
         * Mở dialog thêm cuộc hẹn nhanh
         */
        this.$root.$on("openAddCalandarDialog", this.openAddCalandarDialog);
    },
    data() {
        return {
            visibleSmall: false,
            showQuickCalender: false,
            tabindex: 1,
            fonts: [
                {
                    id: "system",
                    name: "Chuẩn",
                },
                {
                    id: "modern",
                    name: "Hiện đại",
                },
                {
                    id: "classic",
                    name: "Cổ điển",
                },
                {
                    id: "wide",
                    name: "Rộng",
                },
            ],
            fontShow: [
                {
                    id: "small",
                    name: this.$t(
                        "zimbra.zmMsg.fontSizeSmall"
                    ),
                },
                {
                    id: "normal",
                    name: this.$t(
                        "zimbra.zhMsg.normal"
                    ),
                },
                {
                    id: "large",
                    name: this.$t(
                        "zimbra.zmMsg.fontSizeLarge"
                    ),
                },
                {
                    id: "extraLarge",
                    name: this.$t(
                        "zimbra.zmMsg.fontSizeExtraLarge"
                    ),
                },
            ],
            timeZone: GeneralService.convertDataZimbra(),
            skinOld: {
                name: "",
                content: "",
            },
            fontOld: {
                name: "",
                content: "",
            },
            fontSizeOld: {
                name: "",
                content: "",
            },
            timeZoneOld: {
                name: "",
                content: "",
            },
            localeOld: {
                name: "",
                content: "",
            },
            visibleSetting: false,
            search: {
                page: 1,
                limit: 8,
            },
            limitSkin: 4,
            showAllSkin: false,
            dialogQuickAddCalender: false,
            showCalendarForm: false
        };
    },
    computed: {
        ...mapGetters([
            "skins",
            "locales",
            "skinCurrent",
            "fontCurrent",
            "fontSizeCurrent",
            "timeZoneCurrent",
            "localeCurrent",
        ]),
        splitContent() {
            return this.localeOld.content.split(",");
        },
    },
    watch: {
        showAllSkin(val) {
            if (val) {
                this.limitSkin = this.skins.length;
            } else {
                this.limitSkin = 4;
            }
        },
    },
    methods: {
        ...mapActions([
            "getPrefInbox",
            "setSkin",
            "setFont",
            "setFontSize",
            "setTimeZone",
            "setLocale",
        ]),

        handleAddNewCalendar() {
            this.$refs.refCalendarAddDialog.open();
        },
        closePopup() {
           this.visibleSmall = false;
           this.showQuickCalender = false;
        },
        onpenCalendarScreen() {
            window.location.href = "/mail/calendar";
        },
        quickAddCalendar() {
            this.visibleSmall = false;
            this.$refs.refCalendarAddDialog.open();
        },
        actionMoreDetail(modelData, ridZ) {
            this.showCalendarForm = true;
            // Mở hình thêm thường
            this.$nextTick(() => {
                this.$refs.refCalendarForm && this.$refs.refCalendarForm.show(modelData.id, ridZ, modelData, null, this.$store.getters.activeAppointment);
            });
        },
        openDialogDetailSchedule(){
            this.visibleSmall = false;
        },
        openQuickCalandar() {
            this.quickAddCalendar = true;
        },
        openQuickEditCalandar(event) {
            const { recur, inst } = event;
            const ridZ = recur ? inst.ridZ : null;
            this.showQuickCalender = true;
            setTimeout(() => {
                this.$refs.quickEditCalendar.open(event.invId, ridZ, this.$store.getters.activeAppointment);
            }, 200);
        },
        closeQuickEditCalendar(){
            this.showQuickCalender = false;
        },

        /**
         * Thêm mởi form chi tiết thành côcng
         */
        onSendSuccess(){
            this.$root.$emit('onSearchAppointmentRequest')
        },

        /**
         * Thêm mới form nhanh thành công
         */
        onQuickSendSuccess(){
            this.$root.$emit('onSearchAppointmentRequest')
            this.$root.$emit('onSearchQuickAppointmentRequest')
        },

        /**
         * Mở dialog thêm cuộc hẹn nhanh
         */
        openAddCalandarDialog(moreDetails) {
            this.$refs.refCalendarAddDialog.open(moreDetails);
        }
    },
    destroyed() {
        this.$root.$off("openQuickEditCalandar", this.openQuickEditCalandar);
    },
};
</script>
<style lang="scss">
@import "@/assets/scss/quick-setting.scss";
</style>
