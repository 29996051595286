<template>
    <el-aside class="v-email sidebar-wrap sidebar-left">
        <div class="progress-mail">
            <el-menu :collapse="!isCollapse" :default-openeds="['1', '3']">
                <h2 v-show="!menuCollapse" class="title text-uppercase">{{$t("zimbraNokey.zimbra_zimbraNokey_personalMailbox")}}</h2>
            </el-menu>
            <!-- Dung lượng mail start -->
            <!-- không hiện với tài khoản không giới hạn -->
            <div class="zm-mail-quota" v-if="mailQuota.zimbraMailQuota && !menuCollapse">
                <el-progress
                    :percentage="percentageMailQuota"
                    :show-text="false"
                    :status="statusMailQuota"
                ></el-progress>
                <span class="text-quota-text">{{ mailQuotaText }}</span>
            </div>
            <div class="zm-mail-quota" v-else-if="mailQuota.zimbraMailQuota">
                <el-progress
                    type="circle"
                    :percentage="percentageMailQuota"
                    :width="45"
                />
            </div>
            <!-- Dung lượng mail end -->
        </div>
        <perfect-scrollbar class="tree-folder">
            <TreeFolder
                :highlight-default="true"
                :show-info-folder="showInfoFolder"
            ></TreeFolder>
        </perfect-scrollbar>
        <MenuChatLeft/>
        <RightMenuSidebar></RightMenuSidebar>
        <RightMenuTag></RightMenuTag>
        <!-- menu di chuyển mail -->
        <DialogEditFolderSearch></DialogEditFolderSearch>
        <DialogAddFolder ref="dialogAddFolder"></DialogAddFolder>
        <DialogEditName></DialogEditName>
        <DialogMoveFolder></DialogMoveFolder>
        <DialogShareFolder></DialogShareFolder>
        <DialogEditFolder></DialogEditFolder>
        <DialogZimletYahoo></DialogZimletYahoo>
        <DialogZimletArchire></DialogZimletArchire>
        <DialogRedirect></DialogRedirect>
        <RightMenuMailFolder></RightMenuMailFolder>
        <DialogAddShareFolder></DialogAddShareFolder>
        <DialogFolderSelector></DialogFolderSelector>
        <!-- bộ lọc -->
        <DialogFilterActivityStream></DialogFilterActivityStream>
        <base-dialog-filter>
        </base-dialog-filter>
        <DialogCreateFolder />
        <DialogWebexPreferences/>
    </el-aside>
</template>

<script>
import DialogFolderSelector from "@/components/modules/layouts/components/DialogFolderSelector";
import DialogAddShareFolder from "@/components/modules/layouts/components/DialogAddShareFolder";
import DialogRedirect from "@/components/modules/layouts/components/DialogRedirect";
import RightMenuSidebar from "@/components/modules/layouts/components/RightMenuSidebar";
import DialogEditFolderSearch from "@/components/modules/layouts/components/DialogEditFolderSearch";
import RightMenuTag from "@/components/modules/layouts/components/RightMenuTag";
import DialogEditFolder from "@/components/modules/layouts/components/DialogEditFolder";
import DialogShareFolder from "@/components/modules/layouts/components/DialogShareFolder";
import DialogMoveFolder from "@/components/modules/layouts/components/DialogMoveFolder";
import DialogZimletYahoo from "@/components/modules/layouts/components/DialogZimletYahoo";
import DialogZimletArchire from "@/components/modules/layouts/components/DialogZimletArchire";
import DialogEditName from "@/components/modules/layouts/components/DialogEditName";
import DialogWebexPreferences from "@/components/modules/calendar/components/dialogs/dialog-webex-preferences.vue"
import MenuChatLeft from "@/components/modules/layouts/components/side-bar-left/MenuChatLeft.vue";
import DialogAddFolder from "@/components/modules/layouts/components/DialogAddFolder.vue";
import DialogFilterActivityStream from "@/components/modules/layouts/components/setting/filter/DialogFilterActivityStream.vue";
import BaseDialogFilter from "@/components/modules/base/base-dialog-filter";
import DialogCreateFolder from "@/components/modules/layouts/components/DialogCreateFolder.vue";
import RightMenuMailFolder from "@/components/modules/layouts/components/RightMenuMailFolder.vue";
import AjxUtil from "@/utils/zimbra-lib/AjxUtil";
import TreeFolder from "@/components/modules/layouts/components/side-bar-left/TreeFolder.vue";
import CommonUtils from "@/utils/common-utils";
import EmailUtils from "@/utils/email-utils";
import { mapGetters, mapActions } from "vuex";
export default {
    mixins: [CommonUtils, EmailUtils],
    components: {
        RightMenuSidebar,
        DialogAddFolder,
        DialogEditName,
        TreeFolder,
        MenuChatLeft,
        RightMenuTag,
        DialogMoveFolder,
        DialogShareFolder,
        DialogEditFolder,
        DialogZimletYahoo,
        DialogZimletArchire,
        DialogEditFolderSearch,
        DialogRedirect,
        RightMenuMailFolder,
        DialogAddShareFolder,
        DialogFilterActivityStream,
        DialogFolderSelector,
        BaseDialogFilter,
        DialogCreateFolder,
        DialogWebexPreferences
    },
    data() {
        return {
            isCollapse: true,
            dataTag: [],
            dataInBox: [],
            dataExtend: [],
            defaultProps: {
                children: "folder",
                label: "name",
            },
            listFolderIgnore: [16, 10, 14, 7, 13, 15],
            folderArchive: {},
            showInfoFolder: true,
        };
    },
    computed: {
        ...mapGetters([
            "mailQuota",
            "menuCollapse",
            "isLoadDataLogin"
        ]),
        percentageMailQuota() {
            const { zimbraMailQuota, used } = this.mailQuota;
            return Math.round(zimbraMailQuota ? used / zimbraMailQuota * 100 : 0);
        },
        statusMailQuota() {
            const { zimbraMailQuota, used } = this.mailQuota;
            let progressClassName = "success";
            if (!zimbraMailQuota && !used) {
                return progressClassName;
            }
            const percent = parseInt(used / zimbraMailQuota * 100);
            if (percent < 85 && percent > 65) {
                progressClassName = "warning";
            } else if (percent >= 85) {
                progressClassName = "exception";
            }
            return progressClassName;
        },
        mailQuotaText() {
            const { zimbraMailQuota, used } = this.mailQuota;
            if (!zimbraMailQuota && !used) {
                return "";
            }
            const sizeQuota = AjxUtil.formatSize(zimbraMailQuota);
            const sizeUsed = AjxUtil.formatSize(used);
            if (zimbraMailQuota == 0) {
                return this.$t("zimbra.zmMsg.usingDescUnlimited", [`${sizeUsed.size} ${sizeUsed.units}`]);
            }
            return this.$t("zimbra.zmMsg.usingDescLimited",
                [
                    `${sizeUsed.size} ${sizeUsed.units}`,
                    `(${Math.round(used / zimbraMailQuota * 100)}%)`,
                    `${sizeQuota.size} ${sizeQuota.units}`
                ]);
        }
    },
    watch: {
        isLoadDataLogin: {
            immediate: true,
            handler() {
                this.getFolderArchiveApi();
                this.getTagApi();
            }
        }
    },
    methods: {
        ...mapActions(["actionToggleMenuCollapse"]),
    },
    mounted() {
        this.$root.$on("showInfoFolder", (val) => {
            this.showInfoFolder = val;
        });
        this.$root.$on("updateItemToDataInBox", (itemUpdate) => {
            this.updateItemToData(itemUpdate, this.dataSidebarInBox);
        });
        this.$root.$on("updateItemToDataExtend", (itemUpdate) => {
            this.updateItemToData(itemUpdate, this.dataSidebarExtend);
        });
        this.$root.$on("addItemToDataInBox", (itemAdd) => {
            this.addItemToData(itemAdd, this.dataSidebarInBox);
        });
        this.$root.$on("addItemToDataExtend", (itemAdd) => {
            this.addItemToData(itemAdd, this.dataSidebarExtend);
        });
        this.$root.$on("deleteItemToDataInBox", (itemAdd) => {
            const data = this.deleteItemToData(itemAdd, this.dataSidebarInBox);
            if (data) {
                this.$store.commit("SET_DATA_INBOX", data);
            }
        });
        this.$root.$on("deleteItemToDataExtend", (itemAdd) => {
            this.deleteItemToData(itemAdd, this.dataSidebarExtend);
        });

    },
}
</script>