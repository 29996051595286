import OutOfOfficeService from "@/services/OutOfOfficeService";
import _ from "lodash";
const OutOfOfficeModule = {
    state: {
        data: [],
        displayAppointmentCurent:"O",
        calItemId:"",
        replyEnableCurrent: {
            name: "zimbraPrefOutOfOfficeReplyEnabled",
            content: false
        },
        replyCurrent: {
            name: "zimbraPrefOutOfOfficeReply",
            content: ""
        },
        externalReplyEnabledCurrent: {
            name: "zimbraPrefOutOfOfficeExternalReplyEnabled",
            content: false
        },
        suppressExternalReplyCurrent: {
            name: "zimbraPrefOutOfOfficeSuppressExternalReply",
            content: false
        },
        externalReplyCurrent: {
            name: "zimbraPrefOutOfOfficeExternalReply",
            content: ""
        },
        prefExternalSendersTypeCurrent: {
            name: "zimbraPrefExternalSendersType",
            content: ""
        },
        fromDateCurrent: {
            name: "zimbraPrefOutOfOfficeFromDate",
            content: ""
        },
        untilDateCurrent: {
            name: "zimbraPrefOutOfOfficeUntilDate",
            content: ""
        },
        freeBusyStatusCurrent:{
            name:"zimbraPrefOutOfOfficeFreeBusyStatus",
            content:""
        },
        statusAlertOnLoginCurrent:{
            name: "zimbraPrefOutOfOfficeStatusAlertOnLogin",
            content: ""
        },
    },
    getters: {
        replyEnableCurrent: state => state.replyEnableCurrent,
        replyCurrent: state => state.replyCurrent,
        externalReplyEnabledCurrent: state => state.externalReplyEnabledCurrent,
        suppressExternalReplyCurrent: state =>
            state.suppressExternalReplyCurrent,
        externalReplyCurrent: state => state.externalReplyCurrent,
        prefExternalSendersTypeCurrent: state =>
            state.prefExternalSendersTypeCurrent,
        fromDateCurrent: state => state.fromDateCurrent,
        untilDateCurrent: state => state.untilDateCurrent,
        statusAlertOnLoginCurrent: state => state.statusAlertOnLoginCurrent,
        displayAppointmentCurent: state => state.displayAppointmentCurent,
        freeBusyStatusCurrent: state => state.freeBusyStatusCurrent,
        calItemId: state => state.calItemId,
    },
    actions: {
        async getAll({commit},data) {
            try {
                const resp = await OutOfOfficeService.getAll(data);
                const prefRes =
                    resp["data"]["soap:Envelope"]["soap:Body"][
                        "GetPrefsResponse"
                    ]["pref"];
                let listData = [];
                if (prefRes) {
                    if (prefRes.name) {
                        listData.push(prefRes);
                    }
                    if (prefRes.length > 0) {
                        prefRes.forEach(e => {
                            listData.push(e);
                        });
                    }
                }
                if (listData === undefined || listData.length === 0) {
                    return "";
                } else {
                    listData.map(item => {
                        commit(`SET${item.name.substring(10 , item.name.length).split("")
                        .map(item => {
                            return item === item.toUpperCase() ? "_" + item : item
                          }).join("").toUpperCase()}`, item);
                    });
                    return resp;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async saveOutOfOffice({ commit},list){
            return await OutOfOfficeService.saveSetting({
                listRequest: [
                    {
                        path: "modifyPrefsRequest",
                        content: JSON.stringify({
                            xml: list.map(item => {
                                    return `<urn1:pref name='${item.name}'>${typeof item.content === "boolean" ? item.content.toString().toUpperCase() : _.escape(item.content) }</urn1:pref>`}).join("").trim()
                        })
                    }
                ]
            })
                .then(resp => {
                    list.map(item => {
                        commit(`SET${item.name.substring(10 , item.name.length).split("")
                        .map(item => {
                            return item === item.toUpperCase() ? "_" + item : item
                          }).join("").toUpperCase()}`, item);
                    });
                    list = [];
                    return resp
                })
                .catch(err => console.log(err));
        },
        setCalItemId({ commit}, data){
            commit("SET_CAL_ITEM_ID",data)
        }
    },
    mutations: {
        SET_OUT_OF_OFFICE_REPLY_ENABLED(state, data) {
            state.replyEnableCurrent = data;
        },
        SET_OUT_OF_OFFICE_REPLY(state, data) {
            state.replyCurrent = data;
        },
        SET_OUT_OF_OFFICE_EXTERNAL_REPLY_ENABLED(state, data) {
            state.externalReplyEnabledCurrent = data;
        },
        SET_OUT_OF_OFFICE_SUPPRESS_EXTERNAL_REPLY(state, data) {
            state.suppressExternalReplyCurrent = data;
        },
        SET_OUT_OF_OFFICE_EXTERNAL_REPLY(state, data) {
            state.externalReplyCurrent = data;
        },
        SET_EXTERNAL_SENDERS_TYPE(state, data) {
            state.prefExternalSendersTypeCurrent = data;
        },
        SET_OUT_OF_OFFICE_FROM_DATE(state, data) {
            state.fromDateCurrent = data;
        },
        SET_OUT_OF_OFFICE_UNTIL_DATE(state, data) {
            state.untilDateCurrent = data;
        },
        SET_OUT_OF_OFFICE_STATUS_ALERT_ON_LOGIN(state,data){
            state.statusAlertOnLoginCurrent = data;
        },
        SET_OUT_OF_OFFICE_FREE_BUSY_STATUS(state, data){
            state.freeBusyStatusCurrent = data;
        },
        SET_DISPLAY_APPOINTMENT(state, data){
            state.displayAppointmentCurent = data;
        },
        SET_CAL_ITEM_ID(state, data){
            state.calItemId = data;
        }
    }
};
export default OutOfOfficeModule;
