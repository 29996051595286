<template>
    <el-popover
        placement="left-start"
        width="296"
        trigger="click"
        popper-class="quick-setting__popover"
        v-model="visibleSetting"
        @show="handleShowQuickSetting"
        @hide="handleHideQuickSetting"
    >
        <perfect-scrollbar
            ref="scroll"
            :suppressScrollY="true"
            :suppressScrollX="true"
        >
            <el-row class="quick-setting">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <div class="heading-wrapper">
                        <h2 class="title">
                            {{ $t("zimbra.zmMsg.setup") }}
                        </h2>
                        <span
                            class="icon el-icon-close y-center"
                            @click="visibleSetting = false"
                        >
                        </span>
                    </div>
                    <div class="body-wrapper">
                        <div class="themes-container">
                            <div class="themes-title">
                                <h2 class="title mb-0">
                                    {{ $t("zimbra.zmMsg.readingPane") }}
                                </h2>
                            </div>
                            <div class="themes-content"  style="margin-bottom: 8px">
                                <el-radio-group
                                    class="radio-control"
                                    v-model="readMailLayout"
                                    @change="handleChangeReadLayout"
                                >
                                    <el-radio
                                        class="radio-item"
                                        v-for="option in mailLayoutOptions"
                                        :key="option['value']"
                                        :label="option['value']"
                                    >
                                        <span>
                                            {{ $t(option['label']) }}
                                        </span>
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="themes-container">
                            <div class="themes-title">
                                <h2 class="title mb-0">
                                    {{ $t("zimbra.zhMsg.FILT_COND_HEADER_subject") }}
                                </h2>
                            </div>
                            <div class="themes-content">
                                <el-row :gutter="8">
                                    <el-col
                                        style="margin-bottom: 8px"
                                        :span="6"
                                        v-for="(skin, index) in skins.slice(0,limitSkin)"
                                        :key="skin.label"
                                        :tabindex="tabindex + index"
                                    >
                                        <el-tooltip
                                            class="item"
                                            effect="light"
                                            :content="$t(skin.label)"
                                            transition="el-fade-in-linear"
                                            :open-delay="200"
                                            placement="top-start"
                                        >
                                            <div
                                                :class="[skinCurrent.content === skin.id ? 'active': '','theme-item',]"
                                                :style="{backgroundImage:'url(' +require(`@/assets/images/${skin.photo}`) +')',}"
                                                @click="handleChangeTheme(skin)"
                                                @keydown.enter="handleChangeTheme(skin)"
                                            ></div>
                                        </el-tooltip>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                        <div class="font-setting">
                            <h2 class="title">
                                {{ $t("zimbraNokey.zimbra_setting_quick_setting_font") }}
                            </h2>
                            <div class="font-selected">
                                <base-select
                                    id="font"
                                    :placeholder="$t('zimbraNokey.zimbra_setting_quick_setting_select_font')"
                                    :label="$t('zimbraNokey.zimbra_setting_quick_setting_font')"
                                    valueField="id"
                                    displayField="name"
                                    :renderSideI18N="false"
                                    :value="fontOld.content"
                                    :options="fonts"
                                    @change="handleChangeFont"
                                />
                            </div>
                        </div>
                        <div class="orther-setting">
                            <h2 class="title">
                                {{ $t("zimbraNokey.zimbra_setting_quick_setting_timeZoneAndLanguage") }}
                            </h2>
                            <div class="orthers-selected">
                                <base-select
                                    id="time"
                                    placeholder="Select time"
                                    :label="$t('zimbra.zhMsg.timeZonePref')"
                                    valueField="id"
                                    displayField="name"
                                    :value="timeZoneOld.content"
                                    :options="timeZone"
                                    @change="handleChangeTime"
                                />
                                <base-cascader
                                    id="locale"
                                    placeholder="Select time"
                                    :label="$t('zimbra.zhMsg.optionsLanguage')"
                                    valueField="id"
                                    displayField="name"
                                    :value="splitContent"
                                    :options="locales"
                                    @change="handleChangeLang"

                                />
                            </div>
                        </div>
                    </div>
                    <div class="footer-setting">
                        <base-button-default
                            class="full-width br-8 btn-email default small"
                            size="small"
                            @click="handleRedirect"
                        >
                            {{ $t("zimbraNokey.zimbra_setting_quick_setting_open_setting") }}
                        </base-button-default>
                    </div>
                </el-col>
            </el-row>
        </perfect-scrollbar>
        <div
            slot="reference"
            :class="[activeMenu === 'setting' ? 'activeMenu' : '', 'element']"
        >
            <el-tooltip class="item" effect="dark"
                :content='this.$t("zimbra.zmMsg.setup")'
                placement="left-end"
            >
                <i class="config-icon zimbra-cursor-pointer"></i>
            </el-tooltip>
        </div>
    </el-popover>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseButtonDefault from "@/components/modules/base/base-button/btn-default";
import BaseSelect from "@/components/modules/base/base-select";
import GeneralService from "@/services/GeneralService";
import i18n from "@/plugins/i18n";
import BaseCascader from "@/components/modules/base/base-cascader";
import { READ_EMAIL_LAYOUT, READ_EMAIL_LAYOUT_ROUTER } from '../../../../utils/constants';
import ZimbraMailService from '../../../../services/ZimbraMailService';
import _ from 'lodash';
import { locale } from "core-js";

export default {
    props: {
        activeMenu: {
            type: [String],
        },
    },
    components: {
        BaseButtonDefault,
        BaseSelect,
        BaseCascader,
    },
    async created() {
        await this.getData();
    },
    data() {
        return {
            READ_EMAIL_LAYOUT: READ_EMAIL_LAYOUT,
            readMailLayout: READ_EMAIL_LAYOUT.RIGHT,
            mailLayoutOptions: [
                {
                    label: 'zimbra.zmMsg.readingPaneOnRight',
                    value: READ_EMAIL_LAYOUT.RIGHT
                },
                {
                    label: 'zimbra.zmMsg.readingPaneOff',
                    value: READ_EMAIL_LAYOUT.HIDE
                }
            ],
            tabindex: 1,
            fonts: [
                {
                    id: "system",
                    name: "zimbra.zhMsg.optionsPromptsLengthMedium",
                },
                {
                    id: "modern",
                    name: "zimbra.zmMsg.fontModern",
                },
                {
                    id: "classic",
                    name: "zimbra.zmMsg.fontClassic",
                },
                {
                    id: "wide",
                    name: "zimbra.zmMsg.fontWide",
                },
            ],
            fontShow: [
                {
                    id: "small",
                    name: "zimbra.zmMsg.fontSizeSmall",
                },
                {
                    id: "normal",
                    name: "zimbra.zhMsg.normal",
                },
                {
                    id: "large",
                    name: "zimbra.zmMsg.fontSizeLarge",
                },
                {
                    id: "extraLarge",
                    name: "zimbra.zmMsg.fontSizeExtraLarge",
                },
            ],
            timeZone: GeneralService.convertDataZimbra(),
            skinOld: {
                name: "",
                content: "bare",
                photo: "skin_1.jpg",
            },
            fontOld: {
                name: "",
                content: "system",
            },
            timeZoneOld: {
                name: "",
                content: "",
            },
            localeOld: {
                name: "Tiếng Việt",
                content: "vi",
            },
            visibleSetting: false,
            search: {
                page: 1,
                limit: 8,
            },
            limitSkin: 4,
            showAllSkin: false,
        };
    },
    computed: {
        ...mapGetters([
            "skins",
            "locales",
            "skinCurrent",
            "fontCurrent",
            "timeZoneCurrent",
            "localeCurrent",
            "preference"
        ]),
        splitContent() {
            const content = _.cloneDeep(this.localeOld.content);
            const splitContent = content?.split(",");
            const lastContent = splitContent[splitContent.length - 1];
            let cascaderContent = "";
            lastContent.split("_")?.forEach((el, index) => {
                if (index % 2 === 0) {
                    cascaderContent += el;
                } else {
                    cascaderContent += `,${cascaderContent}_${el}`;
                }
            });
            return cascaderContent || "vi";
        },
        isShowThemeContent() {
            const { name } = this.$route;
            return this.readMailLayout == READ_EMAIL_LAYOUT.RIGHT && name == READ_EMAIL_LAYOUT_ROUTER.NAME_RIGHT
        }
    },
    watch: {
        showAllSkin(val) {
            if (val) {
                this.limitSkin = this.skins.length;
            } else {
                this.limitSkin = 4;
            }
        },
        "preference.zimbraPrefReadingPaneLocation": {
            immediate: true,
            handler(val) {
                this.readMailLayout = val || READ_EMAIL_LAYOUT.HIDE;
            }
        },
    },
    methods: {
        ...mapActions([
            "getAllGeneral",
            "getPrefInbox",
            "setSkin",
            "setFont",
            "setTimeZone",
            "setLocale",
        ]),
        handleShowQuickSetting() {
            this.renderData();
        },
        handleHideQuickSetting() {
            this.$emit("hide", "");
        },
        handleJsonToString(obj) {
            return JSON.stringify(obj);
        },
        async getData() {
            await this.getAllGeneral().then(() => {
                this.renderData();
            }).catch((err) => console.log(err));
        },
        handleViewAllSkin() {
            this.showAllSkin = !this.showAllSkin;
        },
        handleVisibleSetting(val) {
            this.visibleSetting = val;
            this.$emit("click", false);
        },
        handleChangeTheme(skin) {
            const oldValue = this.skinOld.content;
            this.skinOld.content = skin.id;
            if (this.skinOld.content !== this.skinCurrent.content) {
                this.setSkin(this.skinOld)
                    .then((resp) => {
                        if (resp.status == 200) {
                            this.$notify({
                                title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                                message: this.$t("zimbraNokey.zimbra_notify_success_message"),
                                type: "success",
                            });

                            // Set theme
                            GeneralService.afterSetSkin(
                                oldValue,
                                this.skinCurrent.content,
                                this.preference.zimbraPrefReadingPaneLocation
                            );
                            // this.$router.go();
                        }
                    })
                    .catch((err) => {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestFailure"),
                            message: this.$t("zimbraNokey.zimbra_notify_error_message"),
                            type: "error",
                        });
                        console.log(err);
                    });
            }
        },
        handleChangeFont(val) {
            const oldValue = this.fontOld.content;
            this.fontOld.content = val;
            this.setFont(this.fontOld)
                .then((resp) => {
                    if (resp.status === 200) {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t("zimbraNokey.zimbra_notify_success_message"),
                            type: "success",
                        });
                        // Set font family
                        GeneralService.afterSetFontFamily(
                            oldValue,
                            this.fontCurrent.content
                        );
                        // this.$router.go();
                    }
                })
                .catch((err) => {
                    this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestFailure"),
                        message: this.$t("zimbraNokey.zimbra_notify_error_message"),
                        type: "error",
                    });
                    console.log(err);
                });
        },
        changeLocale(locale) {
            i18n.locale = locale;
        },
        handleChangeTime(val) {
            this.timeZoneOld.content = val;
            this.setTimeZone(this.timeZoneOld)
                .then((resp) => {
                    if (resp.status === 200) {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t("zimbraNokey.zimbra_notify_success_message"),
                            type: "success",
                        });
                        // this.$router.go();
                    }
                })
                .catch((err) => {
                    this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestFailure"),
                        message: this.$t("zimbraNokey.zimbra_notify_error_message"),
                        type: "error",
                    });
                    console.log(err);
                });
        },
        handleChangeLang(val) {
            this.localeOld.content = val;
            this.setLocale(this.localeOld)
                .then((resp) => {
                    if (resp.status === 200) {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t("zimbraNokey.zimbra_notify_success_message"),
                            type: "success",
                        });
                        this.$confirm(
                            this.$t("zimbraNokey.zimbra_common_reload_setting"),
                            this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                            {
                                confirmButtonText: this.$t("zimbra.zhMsg.yes"),
                                cancelButtonText: this.$t("zimbra.zhMsg.no"),
                                type: "warning",
                            }
                        ).then(() => {
                            window.location.reload(true);
                        })
                    }
                })
                .catch((err) => {
                    this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestFailure"),
                        message: this.$t("zimbraNokey.zimbra_notify_error_message"),
                        type: "error",
                    });
                    console.log(err);
                });
        },
        handleRedirect() {
            const path = "/mail/setting";
            this.handleVisibleSetting();
            if (this.$route.path !== path) {
                window.location.href = path;
            }
        },
        renderData() {
            this.skinOld = Object.assign({}, this.skinCurrent);
            this.fontOld = Object.assign({}, this.fontCurrent);
            this.fontOld.content = this.fontOld.content ? this.fontOld.content : "system"
            this.timeZoneOld = Object.assign({}, this.timeZoneCurrent);
            this.localeOld = Object.assign({}, this.localeCurrent);
        },
        renderLayout() {
            const route = this.$route.fullPath
            if(route == "/mail/v-email") {
                this.readMailLayout = READ_EMAIL_LAYOUT.HIDE
            }
            // else if(route == "/mail/email") {
            //     }
            else {
                this.readMailLayout = READ_EMAIL_LAYOUT.RIGHT

            }
            const formData = {
                xml: `<urn1:pref name="zimbraPrefReadingPaneLocation">${this.readMailLayout}</urn1:pref>`,
            };
            ZimbraMailService.modifyPrefsRequest(formData).then(() => {
                this.$store.commit(
                    "SET_PREFERENCE",
                    _.cloneDeep({
                        ...this.preference,
                        zimbraPrefReadMailLayout: this.readMailLayout,
                    })
                );
            });
        },
        /**
         * Thay đổi layout
         */
        handleChangeReadLayout(value) {
            const formData = {
                xml: `<urn1:pref name="zimbraPrefReadingPaneLocation">${value}</urn1:pref>`,
            };
            ZimbraMailService.modifyPrefsRequest(formData).then(() => {
                const newPreference = { ...this.preference, zimbraPrefReadMailLayout: value };
                this.$store.commit("SET_PREFERENCE", _.cloneDeep(newPreference));
                window.location.href = `${origin}${value == READ_EMAIL_LAYOUT.RIGHT ? READ_EMAIL_LAYOUT_ROUTER.RIGHT : READ_EMAIL_LAYOUT_ROUTER.HIDE}`;
            });
        }
    },
};
</script>
<style lang="scss">
@import "@/assets/scss/quick-setting.scss";
</style>
