<template>
    <el-dialog
        class="dialog-body-wrap zimbra-dialog"
        custom-class="mw__450"
        :title="$t('zimbra.zmWebExZimlet.startJoinExistingMeeting')"
        :visible.sync="isOpenDialog"
        width="30%"
        height="auto"
        :append-to-body="true"
        :close-on-click-modal="false"
    >
        <el-form v-if="!isWaiting" class="form-wrap">
            <div class="row pt-2">
                <div class="col-4 zm-label">
                    <p class="webEx-label">
                        {{ $t("zimbra.zmWebExZimlet.webExAccntToUse") }}
                    </p>
                </div>
                <div class="col-7">
                    <el-select
                        class="w-100"
                        v-model="formInsert.account"
                        :default-first-option="true"
                        @change="onChangeMeetingSetting"
                    >
                        <el-option
                            v-for="(
                                item, idx
                            ) in lstAcountWebex"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"
                        >
                            <span>
                                {{ item.label }}
                            </span>
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-4 zm-label">
                    <p class="webEx-label">
                        {{ $t("zimbra.zmWebExZimlet.showMeetings") }}
                    </p>
                </div>
                <div class="col-5">
                    <el-select
                        class="w-100"
                        v-model="formInsert.meetingSetting"
                        :default-first-option="true"
                        @change="onChangeMeetingSetting"
                    >
                        <el-option
                            v-for="(
                                item, idx
                            ) in lstShowMeeting"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"
                        >
                            <span>
                                {{ item.label }}
                            </span>
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="row pt-2 zm-label webEx-note">
                <p> {{ note }} </p>
            </div>
            <hr style="margin: 2px" />
        </el-form>
        <el-form v-if="isWaiting" class="form-wrap">
            <div class="row pt-2">
                <p class="webEx-waiting">
                    {{ $t("zimbra.zmWebExZimlet.pleaseWait") }}
                </p>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="isOpenDialog = false">{{
                $t("zimbra.zhMsg.actionTaskCancelTT")
            }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    mounted() {
        this.$root.$on("onOpenJoinMeetingWebex", () => {
            if(this.lstAcountWebex.length > 0) {
                this.isWaiting = true;
                this.isOpenDialog = true;
                this.$notify({
                    message: this.$t("zimbra.zmWebExZimlet.pleaseWait"),
                    type: "success",
                });
                setTimeout(() => this.isWaiting = false, 500);
            } else {
                this.$alert(
                    this.$t("zimbra.zmWebExZimlet.noWebExAccount"),
                    this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                    {
                        confirmButtonText:this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "warning",
                        dangerouslyUseHTMLString: true,
                    }
                )
            }
            
        });
    },
    destroyed() {
        this.$root.$off("onOpenJoinMeetingWebex");
    },
    computed: {
        lstAcountWebex() {
          const lstAccountWebExZimlet = this.$store.getters.lstAccountWebExZimlet;
          let i = 0;
          const lstSelect = lstAccountWebExZimlet.map((item, index) => {
              const objectSelect = {...item}
              if(this.isValidForm(objectSelect)){
                  i++;
              }
              objectSelect.value = index;
              const altName = item.webexZimlet_username != ""? item.webexZimlet_username: this.$t("zimbra.zmWebExZimlet.notConfigured")
              objectSelect.label = this.$t("zimbra.zmWebExZimlet.accntNumberAndName", [index + 1, altName]);
              return objectSelect;
          })
          if(i > 0) {
              return lstSelect;
          } else {
              return [];
          }
        },
        lstShowMeeting() {
            const lstSetting = [];
            lstSetting.push({value: 0, label: this.$t("zimbra.zmWebExZimlet.fromNowOn")})
            lstSetting.push({value: 1, label: this.$t("zimbra.zhMsg.today")})
            lstSetting.push({value: 2, label: this.$t("zimbra.zmWebExZimlet.tomorrow")})
            lstSetting.push({value: 3, label: this.$t("zimbra.zmWebExZimlet.next7days")})
          return lstSetting;
        }
    },
    components: {
    },
    data() {
        return {
            formInsert: {
                account: 0,
                meetingSetting: 0,
            },
            isWaiting : false,
            isOpenDialog: false,
            note: this.$t("zimbra.zmWebExZimlet.noMeetingsFound"),
           };
    },
    methods: {
        /**
         * Validate trước khi load dữ liệu
         */
        validateBeforeChange() {
            let isValid = false;
            this.lstAcountWebex.map((item) => {
              const objectSelect = {...item}
              if(this.validFormAccount(objectSelect) && this.formInsert.account === objectSelect.value){
                  isValid = true;
              }
            })
            // Hiển thị thông báo lỗi
            if(!isValid) {
                this.$alert(
                    this.$t("zimbra.zmWebExZimlet.accountNumberNotSetup", [this.formInsert.account + 1]),
                    this.$t("zimbraNokey.zimbra_compose_criticalMsg"),
                    {
                        confirmButtonText:this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "error",
                        dangerouslyUseHTMLString: true,
                    }
                ).then(() => {
                    setTimeout(() => {
                        this.$refs.refInputName && this.$refs.refInputName.focus();
                    }, 500);
                })
                .catch(() => {});
            }
            return isValid;
        },
        onChangeMeetingSetting() {
            if(!this.validateBeforeChange()) {
                return;
            }
            this.note = this.$t("zimbra.zmWebExZimlet.findingMeetings");
            if(this.formInsert.meetingSetting === 0) {
                //Call API xử lý

                //Nếu lỗi khi call api hoặc không có dữ liệu
                setTimeout(() => this.note = this.$t("zimbra.zmWebExZimlet.noMeetingsFound"), 500);
            } else if(this.formInsert.meetingSetting === 1) {
                //Call API xử lý

                //Nếu lỗi khi call api hoặc không có dữ liệu
                setTimeout(() => this.note = this.$t("zimbra.zmWebExZimlet.noMeetingsFound"), 500);
            } else if(this.formInsert.meetingSetting === 2) {
                //Call API xử lý

                //Nếu lỗi khi call api hoặc không có dữ liệu
                setTimeout(() => this.note = this.$t("zimbra.zmWebExZimlet.noMeetingsFound"), 500);
            } else if(this.formInsert.meetingSetting === 3) {
                //Call API xử lý

                //Nếu lỗi khi call api hoặc không có dữ liệu
                setTimeout(() => this.note = this.$t("zimbra.zmWebExZimlet.noMeetingsFound"), 500);
            }
        },
         isValidForm(form){
            if (
                form["webexZimlet_accountname"] || form["webexZimlet_accountname"].toString().trim().length > 0 ||
                form["webexZimlet_username"] || form["webexZimlet_username"].toString().trim().length > 0 ||
                form["webexZimlet_pwd"] || form["webexZimlet_pwd"].toString().trim().length > 0 ||
                form["webexZimlet_meetingPwd"] || form["webexZimlet_meetingPwd"].toString().trim().length > 0 ||
                form["WebExZimlet_altHosts"] || form["WebExZimlet_altHosts"].toString().trim().length > 0 ||
                form["webexZimlet_tollFreePH"] || form["webexZimlet_tollFreePH"].toString().trim().length > 0 ||
                form["webexZimlet_tollPH"] || form["webexZimlet_tollPH"].toString().trim().length > 0 ||
                form["webexZimlet_phonePasscode"] || form["webexZimlet_phonePasscode"].toString().trim().length > 0 ||
                form["webexZimlet_otherPH"] || form["webexZimlet_otherPH"].toString().trim().length > 0){
                    return true;
            }
            return false;
        },
        validFormAccount(form){
            if ( form["webexZimlet_accountname"].toString().length > 0 && form["webexZimlet_username"].toString().length > 0 ){
                    return true;
            }
            return false;
        },
    },
};
</script>
<style scoped>
.webEx-label {
    line-height: 37px;
    float: right !important;
}
.webEx-waiting {
    margin-left: 20px;
    font-weight: bold;
}
.webEx-note {
    text-align: center;
}
.webEx-note p{
    margin-left: 50px;
    line-height: 50px;
}

</style>