<template>
    <div>
        <el-dialog width="900px" :title="$t('zimbra.zmMsg.selectAddresses')" :visible.sync="dialogFormVisible"
            :append-to-body="true" :close-on-click-modal="false" custom-class="dialog-address-selector"
            class="zm-diablog-address-selector zimbra-dialog zm-unselect" @close="onclose">
            <el-form :model="form">
                <el-row class="pb-2">
                    <el-col :span="12">
                        <el-row>
                            <el-col :span="16">
                                <el-input ref="refKeyword" class="el-text-control input-address-selector" v-model="keyword"
                                    :placeholder="$t(
                                        'zimbra.zmMsg.contactPickerHint'
                                    )
                                        " @change="handleSearch"></el-input>
                            </el-col>
                            <el-col :span="2" class="pl-2">
                                <el-button class="btn-outline btn-address-selector" size="medium" @click="handleSearch">{{
                                    $t(
                                        "zimbra.zhMsg.search"
                                    )
                                }}</el-button>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="12">
                        <el-row class="sourceName-address-selector">
                            <el-col :span="12" class="text-right pr-2 pt-3 span-address-selector">{{
                                $t("zimbra.zmMsg.showNames")
                            }}</el-col>
                            <el-col :span="12">
                                <el-select class="select-address-selector" v-model="addressDatasource"
                                    @change="changeAddressDatasource">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row class="d-flex">
                    <el-col :span="11">
                        <table class="table mb-0" style="border: 1px solid #dee2e6">
                            <thead>
                                <tr>
                                    <th scope="col" class="address_no_field">
                                        {{
                                            $t("zimbraNokey.zimbra_compose_addressSelector_no")
                                        }}
                                    </th>
                                    <th scope="col" class="address_fullName_field">
                                        {{
                                            $t(
                                                "zimbra.zhMsg.AB_FIELD_firstName"
                                            )
                                        }}
                                    </th>
                                    <th scope="col" class="address_email_field">
                                        {{
                                            $t(
                                                "zimbra.zhMsg.AB_FIELD_email"
                                            )
                                        }}
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <div class="border-col">
                            <perfect-scrollbar class="table-address">
                                <table class="table table-scroll table-address-selector">
                                    <tbody>
                                        <template v-for="(row, idx) in listDataCurrentPage">
                                            <tr :key="idx" :class="['zimbra-cursor-pointer',
                                                'zm-row-email',
                                                isSelected(row, 'source') ? 'selected' : '']" @click.exact.prevent="
        handleSelectRow(row, 'source')
        " @click.ctrl="
        ctrlAddItemToList(row, 'source')
        " @click.shift="
        shiftAddItemToList(row, 'source')
        ">
                                                <td scope="row" class="address_no_field text-center">{{ (currentPage - 1) *
                                                    pageSize + idx + 1 }}</td>
                                                <td class="address_fullName_field">
                                                    <base-address-popover :value="row" :canDoActionCreate="false">
                                                        <template v-slot:templateView>
                                                            <div class="setting-cell">
                                                                {{ row.p || $t("zimbra.zhMsg.noName") }}
                                                            </div>
                                                        </template>
                                                    </base-address-popover>
                                                </td>
                                                <td class="address_email_field">
                                                    <base-address-popover :value="row" :canDoActionCreate="false">
                                                        <template v-slot:templateView>
                                                            {{ row.a }}
                                                        </template>
                                                    </base-address-popover>
                                                </td>
                                            </tr>
                                        </template>
                                        <el-empty v-if="listDataCurrentPage.length == 0"
                                            :description="`${$t('zimbra.zhMsg.noResultsFound')}`"></el-empty>
                                    </tbody>
                                </table>
                            </perfect-scrollbar>
                        </div>
                        <el-pagination background layout="prev, next" :page-size="pageSize" :total="totalAddress"
                            @current-change="currentChange" @prev-click="prevClick" @next-click="nextClick">
                        </el-pagination>
                        <!-- </table> -->
                    </el-col>
                    <el-col :span="2" class="ml-2 mr-2 zm-btn-action">
                        <div>
                            <div class="pb-2">
                                <el-button
                                    :class="['btn-outline btn-address-selector', selectedRowsSource.length == 0 ? 'noclick' : '']"
                                    size="mini" style="width: 100%" @click="handleAction('to')"
                                    :disabled="selectedRowsSource.length == 0">{{ $t("zimbra.zhMsg.FILT_COND_HEADER_to")
                                    }}</el-button>
                            </div>
                            <div class="pb-2" v-if="showCc">
                                <el-button
                                    :class="['btn-outline btn-address-selector', selectedRowsSource.length == 0 ? 'noclick' : '']"
                                    size="mini" style="width: 100%" @click="handleAction('cc')"
                                    :disabled="selectedRowsSource.length == 0">{{ $t("zimbra.zhMsg.cc") }}</el-button>
                            </div>
                            <div class="pb-2" v-if="showBcc">
                                <el-button
                                    :class="['btn-outline btn-address-selector', selectedRowsSource.length == 0 ? 'noclick' : '']"
                                    size="mini" style="width: 100%" @click="handleAction('bcc')"
                                    :disabled="selectedRowsSource.length == 0">{{ $t("zimbra.zhMsg.bcc") }}</el-button>
                            </div>
                            <div class="pb-2">
                                <el-button
                                    :class="['btn-outline btn-address-selector', selectedRowsTarget.length == 0 ? 'noclick' : '']"
                                    size="mini" style="width: 100%" @click="handleAction('delete')"
                                    :disabled="selectedRowsTarget.length == 0">{{
                                        $t("zimbra.zhMsg.actionDelete")
                                    }}</el-button>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="11">
                        <table class="table mb-0" style="border: 1px solid #dee2e6">
                            <thead>
                                <tr>
                                    <th scope="col" class="address_no_field">
                                        {{
                                            $t("zimbraNokey.zimbra_compose_addressSelector_no")
                                        }}
                                    </th>
                                    <th scope="col" class="address_fullName_field">
                                        {{
                                            $t(
                                                "zimbra.zhMsg.AB_FIELD_firstName"
                                            )
                                        }}
                                    </th>
                                    <th scope="col" class="address_email_field">
                                        {{
                                            $t(
                                                "zimbra.zhMsg.AB_FIELD_email"
                                            )
                                        }}
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <div class="border-col">
                            <perfect-scrollbar class="table-address">
                                <table class="table">
                                    <tbody>
                                        <template v-for="(row, idx) in listEmailAddressSelected">
                                            <tr :key="idx" :class="['zimbra-cursor-pointer',
                                                'zm-row-email',
                                                isSelected(row, 'target') ? 'selected' : '']" @click.exact.prevent="handleSelectRow(row, 'target')
        " @click.ctrl="
        ctrlAddItemToList(row, 'target')
        " @click.shift="
        shiftAddItemToList(row, 'target')
        ">
                                                <td class="address_no_field text-center" scope="row">{{ idx + 1 }}</td>
                                                <td class="address_fullName_field">
                                                    <base-address-popover :value="row" :canDoActionCreate="false">
                                                        <template v-slot:templateView>
                                                            {{ typeSend(row) }} {{ row.p || $t("zimbra.zhMsg.noName") }}
                                                        </template>
                                                    </base-address-popover>
                                                </td>
                                                <td class="address_email_field">
                                                    <base-address-popover :value="row" :canDoActionCreate="false">
                                                        <template v-slot:templateView>
                                                            {{ row.a }}
                                                        </template>
                                                    </base-address-popover>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </perfect-scrollbar>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button class="el-button btn-email default" @click="onclose">{{ $t("zimbra.zhMsg.actionTaskCancelTT")
                }}</el-button>
                <el-button class="el-button btn-email primary" type="primary" @click="handleSelected">{{
                    $t("zimbra.zmMsg.mobileStatusNeedProvision") }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import BaseAddressPopover from "@/components/modules/base/base-address-popover/BaseAddressPopover";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import _ from "lodash";
import { mapGetters } from "vuex";
export default {
    name: "DialogAddressSelector",
    mixins: [CommonUtils],
    props: {
        dialogFormVisibleProps: {
            type: Boolean,
            default: false,
        },
        showCc: {
            type: Boolean,
            default: true,
        },
        showBcc: {
            type: Boolean,
            default: true,
        },
        allowDuplicate: {
            type: Boolean,
            default: true
        },
    },
    components: {
        BaseAddressPopover,
    },
    data() {
        return {
            dialogFormVisible: this.dialogFormVisibleProps,
            form: {},
            formLabelWidth: "120px",
            options: [
                {
                    value: "Option1",
                    label: this.$t("zimbra.zhMsg.addressBook"),
                },
                {
                    value: "Option2",
                    label: this.$t("zimbra.zmMsg.searchPersonalSharedContacts"),
                },
                {
                    value: "Option3",
                    label: this.$t("zimbra.zhMsg.GAL"),
                },
            ],
            addressDatasource: "Option2",
            listEmailAddress: [],
            listEmailAddressOrigin: [],
            listEmailAddressSelected: [],
            currentPage: 1,
            totalPages: 0,
            currentPageSelected: 1,
            totalPagesSelected: 0,
            selectedRowsSource: [],
            selectedRowsTarget: [],
            keyword: "",
            totalAddress: 0,
            pageSize: 50,
            listDataCurrentPage: [],
            extraType: '',
        };
    },
    computed: {
        ...mapGetters([
            "listFileContacts", "listFolderContactsShare", "zimbraPrefGalSearchEnabled"
        ]),
        isShowTypeSend() {
            return this.showCc && this.showBcc;
        }
    },
    watch: {
    },
    created() {
        this.searchGalRequest();
    },
    methods: {
        async show(addresses, type) {
            this.dialogFormVisible = true;
            this.listEmailAddressSelected = addresses || [];
            this.keyword = "";
            this.addressDatasource = this.options[2].value;
            this.extraType = type;
            switch (this.addressDatasource) {
                case "Option1":
                    await this.searchRequest();
                    break;
                case "Option2":
                    await this.searchRequest();
                    break;
                case "Option3":
                    await this.searchGalRequest();
                    break;
                default:
                    break;
            }
            setTimeout(() => {
                this.$refs.refKeyword.focus();
                this.handleSelectRow(this.listDataCurrentPage[0], 'source');
            }, 200);
        },
        handleSelected() {
            this.$emit("onselected", this.listEmailAddressSelected, this.extraType);
            this.dialogFormVisible = false;
        },
        async searchGalRequest(name) {
            const formData = {
                name: _.escape(name) || "",
                type: "account",
                needIsMember: "directOnly",
                sortBy: "nameAsc",
                limit: "50",
                offset: "0",
            };
            const searchRequest = await ZimbraMailService.searchGalRequest(
                formData
            );
            let { cn, more } =
                this.getResponseBody(searchRequest)["SearchGalResponse"];
            cn = Array.isArray(cn) ? cn : (cn ? [cn] : []);
            if (name == "" && more) {
                this.$confirm(
                    this.$t("zimbra.zmMsg.errorSearchNotExpanded"),
                    this.$t("zimbraNokey.zimbra_common_titleInfo"),
                    {
                        showCancelButton: false,
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "info",
                    }
                )
                    .then(() => {
                    })
                    .catch(() => {
                    });
            }
            this.listEmailAddressOrigin = cn.map((e, idx) => {
                const fullName = e?.a?.find((x) => x.n == "fullName")?.content;
                const email = e?.a?.find((x) => x.n == "email")?.content;
                return {
                    ...e,
                    a: email, // email
                    p: fullName, // fullname
                    pUpper: fullName?.toString().toUpperCase(),
                    index: idx
                };
            });
            this.listEmailAddressOrigin = _.orderBy(this.listEmailAddressOrigin, ["pUpper"], ["asc"]);
            this.updateListMail();
            this.handleSelectRow(this.listDataCurrentPage[0], 'source');
        },
        handleAction(type) {
            if (type == "delete") {
                this.selectedRowsTarget.forEach((e) => {
                    const findIndex = this.listEmailAddressSelected.findIndex(
                        (x) => x.email == e.email && x.type == e.type
                    );
                    if (findIndex >= 0) {
                        this.listEmailAddressSelected.splice(findIndex, 1);
                    }
                });
                this.selectedRowsTarget = [];
                this.updateListMail();
            } else {
                for (let index = 0; index < this.selectedRowsSource.length; index++) {
                    if (this.selectedRowsSource[index].type == "group") {
                        this.selectedRowsSource = this.selectedRowsSource.concat(this.selectedRowsSource[index].emailMembers);
                    } else {
                        const exists = this.listEmailAddressSelected.find(
                            (x) => x.a == this.selectedRowsSource[index].a && x.type == type
                        );
                        if (!exists) {
                            this.listEmailAddressSelected.push({
                                ...this.selectedRowsSource[index],
                                type: type,
                            });
                        }
                    }

                }
                this.selectedRowsSource = [];
                this.listEmailAddressSelected = this.listEmailAddressSelected.map((e, idx) => ({ ...e, index: idx }));
                this.updateListMail();
            }
        },
        async changeAddressDatasource() {
            this.selectedRowsSource = [];
            if (this.addressDatasource == "Option3") {
                this.searchGalRequest(this.keyword);
                return;
            }
            await this.searchRequest();
        },
        async handleSearch() {
            await this.searchRequest();
        },
        async searchRequest(isContinueSearch = false, offset = 0) {
            this.keyword = this.keyword ? this.keyword.trim() : "";
            if (this.addressDatasource === "Option3") {
                this.searchGalRequest(this.keyword);
                return;
            }
            const formData = {

                query: `${this.keyword ? "(" + _.escape(this.keyword) + ")" : ""
                    }(is:local)`,
                offset: offset,
                limit: 50,
                sortBy: "nameAsc",
                types: "contact",
            };
            if (this.addressDatasource == "Option2" && this.listFolderContactsShare.length > 0) {
                const queryId = `(${this.listFolderContactsShare.map(x => `inid:"${x.id}" OR `).join("")}is:local)`;
                formData.query = `${this.keyword ? "(" + _.escape(this.keyword) + ")" : ""} ${queryId}`
            }
            const searchRequest = await ZimbraMailService.searchRequest(
                formData
            );
            const { cn, more } =
                this.getResponseBody(searchRequest)["SearchResponse"];
            const response = Array.isArray(cn) ? cn : (cn ? [cn] : []);
            if (response) {
                const listEmailSearch = response.map((e) => {
                    // const fullName = e.a.find((x) => x.n == "fullName") || "";
                    const attr = e.a ? (Array.isArray(e.a) ? e.a : [e.a]) : [];
                    const nickname = attr.find((x) => x.n == "nickname");
                    const email = attr.find((x) => x.n == "email") || {};
                    const addressContacts = this.listFileContacts.find(x => x.id == e.id);
                    if (addressContacts && addressContacts.type == "group") {
                        e.type = "group";
                        e.emailMembers = [];
                        addressContacts.m.forEach(m => {
                            if (m.cn && m.cn.a) {
                                m.cn.a = Array.isArray(m.cn.a) ? m.cn.a : [m.cn.a];
                                const mail = m.cn.a.find(a => a.n == "email");
                                // const fullName = m.cn.a.find(a => a.n == "fullName")?.content || m.cn.fileAsStr || "";
                                const fullName = m.cn.a.find(a => a.n == "fullName")?.content || m.cn.a.find(a => a.n == "firstName")?.content || "";
                                if (mail) {
                                    m.valueLabel = `"${fullName}" <${mail.content}>`;
                                    e.emailMembers.push({ a: mail.content, p: fullName })
                                }
                            }
                        })
                        email.content = addressContacts.m.filter(x => !_.isNil(x.valueLabel)).map(x => x.valueLabel).join("; ");
                    }
                    return {
                        ...e,
                        // p: nickname ? e.fileAsStr || nickname.content : e.fileAsStr, // fullname
                        p:  e.a.find(a => a.n === "fullName")?.content || e.a.find(a => a.n === "firstName")?.content || "",

                        a: email ? email.content : "", // email
                        pUpper: e.fileAsStr.toString().toUpperCase(),
                    };
                }).filter(x => !_.isNil(x.a));
                if (isContinueSearch) {
                    this.listEmailAddressOrigin = this.listEmailAddressOrigin.concat(listEmailSearch);

                } else {
                    this.listEmailAddressOrigin = listEmailSearch;
                }
                if (more) {
                    this.searchRequest(true, offset + 50);
                    return;
                }
            } else {
                this.listEmailAddressOrigin = [];
            }
            this.listEmailAddressOrigin = _.orderBy(this.listEmailAddressOrigin, ["pUpper"], ["asc"]);
            this.updateListMail();
            this.handleSelectRow(this.listDataCurrentPage[0], 'source');
        },
        onclose() {
            this.dialogFormVisible = false;
            // this.listEmailAddressSelected = [];
        },
        handleSelectRow(row, type) {
            if (type != undefined && row != undefined) {

                if (type === "source") {
                    const _idx = this.selectedRowsSource.findIndex(e => e.a === row.a);
                    if (_idx > -1) {
                        this.selectedRowsSource.splice(_idx, 1);
                    } else {
                        this.selectedRowsSource = [row];
                    }
                    this.selectedRowsTarget = [];
                } else {
                    const _idx = this.selectedRowsTarget.findIndex(e => e.a === row.a);
                    if (_idx > -1) {
                        this.selectedRowsTarget.splice(_idx, 1);
                    } else {
                        this.selectedRowsTarget = [row];
                    }
                    this.selectedRowsSource = [];
                }
            }
        },
        ctrlAddItemToList(row, type) {
            if (type === "source") {
                const idx = this.selectedRowsSource.findIndex(
                    (e) => e.a === row.a
                );
                if (idx > -1) {
                    this.selectedRowsSource.splice(idx, 1);
                } else {
                    this.selectedRowsSource.push(row);
                }
            } else {
                const idx = this.selectedRowsTarget.findIndex(
                    (e) => e.a === row.a
                );
                if (idx > -1) {
                    this.selectedRowsTarget.splice(idx, 1);
                } else {
                    this.selectedRowsTarget.push(row);
                }
            }
        },
        shiftAddItemToList(row, type) {
            if (type === "source") {
                let indexs = row.index;
                if (this.selectedRowsSource.length === 0) {
                    return;
                }
                this.selectedRowsSource = _.orderBy(
                    this.selectedRowsSource,
                    ["index"],
                    ["asc"]
                );
                let firstIndex = this.selectedRowsSource[0].index;
                if (firstIndex > row.index) {
                    firstIndex = [indexs, (indexs = firstIndex)][0];
                }
                this.selectedRowsSource = this.listEmailAddress.filter(
                    (e, idx) => idx >= firstIndex && idx <= indexs
                );
            } else {
                let indexs = row.index;
                if (this.selectedRowsTarget.length === 0) {
                    return;
                }
                this.selectedRowsTarget = _.orderBy(
                    this.selectedRowsTarget,
                    ["index"],
                    ["asc"]
                );
                let firstIndex = this.selectedRowsTarget[0].index;
                if (firstIndex > row.index) {
                    firstIndex = [indexs, (indexs = firstIndex)][0];
                }
                this.selectedRowsTarget = this.listEmailAddressSelected.filter(
                    (e, idx) => idx >= firstIndex && idx <= indexs
                );
            }
        },
        isSelected(row, type) {
            if (type === "source") {
                const _idx = this.selectedRowsSource.findIndex(e => e.a === row.a);
                return _idx > -1
            } else {
                const _idx = this.selectedRowsTarget.findIndex(e => e.a === row.a);
                return _idx > -1
            }
        },
        currentChange() {
            // do somethings
        },
        prevClick(page) {
            this.currentPage = page;
            this.listDataCurrentPage = this.listEmailAddress.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
            this.selectedRowsSource = [];
        },
        nextClick(page) {
            this.currentPage = page;
            this.listDataCurrentPage = this.listEmailAddress.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
            this.selectedRowsSource = [];
        },
        typeSend(row) {
            let label = "";
            if (!this.isShowTypeSend) {
                return label;
            }
            switch (row.type) {
                case "to":
                    label = this.$t("zimbra.zhMsg.toLabel");
                    break;
                case "cc":
                    label = this.$t("zimbra.zhMsg.ccLabel");
                    break;
                case "bcc":
                    label = this.$t("zimbra.zhMsg.bccLabel");
                    break;
            }
            return label;
        },
        updateListMail() {
            if (!this.allowDuplicate) {
                const selectedIds = this.listEmailAddressSelected?.map(e => e.a) || [];
                this.listEmailAddress = this.listEmailAddressOrigin.filter(e => !selectedIds.includes(e.a)).map((e, idx) => ({ ...e, index: idx }));
            } else {
                this.listEmailAddress = this.listEmailAddressOrigin.map((e, idx) => ({ ...e, index: idx }));
            }
            this.totalAddress = this.listEmailAddress.length;
            this.listDataCurrentPage = this.listEmailAddress.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
        }
    },

};
</script>
<style>
.border-col {
    border: 1px solid #dcdfe6;
    border-top: none;
}

.zm-diablog-address-selector .el-select {
    display: unset !important;
}

.table-address {
    height: 400px !important;
}

.table-address-header {
    margin-bottom: 0px !important;
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
    .zm-diablog-address-selector .el-dialog {
        width: 75%;
    }

    .table-address {
        height: 350px !important;
    }
}
</style>
