import FilterService from "@/services/FilterService";
import CommonUtils from "@/utils/common-utils";
const filterModules = {
    state: {
        filterRulesIncoming: [],
        filterRulesOutgoing: [],
        dataIncomingActiveFilters: [],
        dataIncomingAvailableFilters: [],
        dataOutgoingActiveFilters: [],
        dataOutgoingAvailableFilters: [],
    },
    getters: {
        filterRulesIncoming: state => state.filterRulesIncoming,
        filterRulesOutgoing: state => state.filterRulesOutgoing,
        dataIncomingActiveFilters: state => state.dataIncomingActiveFilters,
        dataIncomingAvailableFilters: state => state.dataIncomingAvailableFilters,
        dataOutgoingActiveFilters: state => state.dataOutgoingActiveFilters,
        dataOutgoingAvailableFilters: state => state.dataOutgoingAvailableFilters,
    },
    actions: {
        setFilterRulesIncoming({ commit }, data) {
            commit("SET_FILTER_RULES_INCOMING", data);
        },
        addFilterRulesIncoming({ commit }, data) {
            commit("ADD_FILTER_RULES_INCOMING", data);
        },
        editFilterRulesIncoming({ commit }, data) {
            commit("EDIT_FILTER_RULES_INCOMING", data);
        },
        deleteFilterRuleIncoming({ commit }, data) {
            commit("DELETE_FILTER_RULE_INCOMING", data)
        },
        setFilterRulesOutgoing({ commit }, data) {
            commit("SET_FILTER_RULES_OUTGOING", data);
        },
        addFilterRulesOutgoing({ commit }, data) {
            commit("ADD_FILTER_RULES_OUTGOING", data);
        },
        deleteFIlterRuleOutgoing({ commit }, data) {
            commit("DELETE_FILTER_RULE_OUTGOING", data)
        },
        async getFilterRulesIncoming({ commit }, withProgress = true, withNotify = true) {
            await FilterService.getFilterRules("urn:zimbraMail", withProgress, withNotify).then(
                async (resp) => {
                    const response =
                        CommonUtils.methods.getResponseBody(resp)["GetFilterRulesResponse"]["filterRules"];
                    const _list = [];
                    if (response.filterRule) {
                        if (response.filterRule.length > 0) {
                            response.filterRule.forEach((item) =>
                                _list.push(item)
                            );
                        } else {
                            _list.push(response.filterRule);
                        }
                    }
                    let listActive = [];
                    let listAvailable = [];
                    let indexActive = 0;
                    let indexAvailable = 0;
                    commit("SET_FILTER_RULES_INCOMING",_list)
                    _list.forEach((item) => {
                        if(item.active === 1){
                            listActive.push({
                                key: indexActive++,
                                data: item
                            })
                        }else{
                            listAvailable.push({
                                key: indexAvailable++,
                                data: item
                            })
                        }
                    })
                    commit("SET_DATA_INCOMING_ACTIVE_FILTER",listActive)
                    commit("SET_DATA_INCOMING_AVAILABLE_FILTER",listAvailable)
                    return resp
                }
            );
        },
        async getFilterRulesOutgoing({ commit}) {
            await FilterService.getOutgouingFilterRules()
                .then((resp) => {
                    const response =
                    CommonUtils.methods.getResponseBody(resp)["GetOutgoingFilterRulesResponse"]["filterRules"];
                    const _list = [];
                    if (response.filterRule) {
                        if (response.filterRule.length > 0) {
                            response.filterRule.forEach((item) =>
                                _list.push(item)
                            );
                        } else {
                            _list.push(response.filterRule);
                        }
                    }
                    let listActive = [];
                    let listAvailable = [];
                    let indexActive = 0;
                    let indexAvailable = 0;
                    commit("SET_FILTER_RULES_OUTGOING",_list)
                    _list.forEach((item) => {
                        if(item.active === 1){
                            listActive.push({
                                key: indexActive++,
                                data: item
                            })
                        }else{
                            listAvailable.push({
                                key: indexAvailable++,
                                data: item
                            })
                        }
                    })
                    commit("SET_DATA_OUTGOING_ACTIVE_FILTER",listActive)
                    commit("SET_DATA_OUTGOING_AVAILABLE_FILTER",listAvailable)
                    return resp
                })
                .catch((err) => console.log(err));
        },
    },
    mutations: {
        SET_FILTER_RULES_INCOMING(state, data) {
            state.filterRulesIncoming = data;
        },
        ADD_FILTER_RULES_INCOMING(state, data) {
            state.filterRulesIncoming.unshift(data);
        },
        EDIT_FILTER_RULES_INCOMING(state, data) {
            let index = state.filterRulesIncoming.findIndex(
                item => item.key === data.key
            );
            if (index > -1) {
                state.filterRulesIncoming = [
                    ...state.filterRulesIncoming.slice(0, index),
                    data,
                    ...state.filterRulesIncoming.slice(index + 1)
                ];
            }
        },
        DELETE_FILTER_RULE_INCOMING(state, data) {
            state.filterRulesIncoming = state.filterRulesIncoming.filter(ele => ele.name !== data.data.name)
        },
        SET_FILTER_RULES_OUTGOING(state, data) {
            state.filterRulesOutgoing = data;
        },
        ADD_FILTER_RULES_OUTGOING(state, data) {
            state.filterRulesOutgoing.unshift(data);
        },
        DELETE_FILTER_RULE_OUTGOING(state, data) {
            state.filterRulesOutgoing = state.filterRulesOutgoing.filter(ele => ele.name !== data.data.name)
        },
        SET_DATA_INCOMING_ACTIVE_FILTER(state, data){
            state.dataIncomingActiveFilters = data
        },
        SET_DATA_INCOMING_AVAILABLE_FILTER(state, data){
            state.dataIncomingAvailableFilters = data
        },
        SET_DATA_OUTGOING_ACTIVE_FILTER(state, data){
            state.dataOutgoingActiveFilters = data
        },
        SET_DATA_OUTGOING_AVAILABLE_FILTER(state, data){
            state.dataOutgoingAvailableFilters = data
        },
    }
};
export default filterModules;
