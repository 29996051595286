<template>
    <div>
    </div>
</template>

<script>
import CommonUtils from "@/utils/common-utils";
import AuthSSOService from "@/services/AuthSSOService.js";
import AccountService from "@/services/account/AccountService";
// import _ from "lodash";
import { mapActions } from "vuex";
export default {
    name: "AuthSSO",
    mixins: [CommonUtils],
    data() {
        return {

        };
    },
    created() {
    },
    mounted() {
        const query = this.$route.query;
        const ticket = query.ticket;
        const origin = process.env.NODE_ENV === "development" ? "mail.epass-vdtc.com.vn" : window.location.origin;

        const formData = {
            // password: this.data.password,
            ticket: encodeURIComponent(ticket)
        }
        const configDomain = this.$configZimbra.VIRTUAL_HOST.find(el => origin.indexOf(el.origin) !== -1);
        let suffixesDomain = "";
        if (configDomain) {
            formData['virtualHost'] = configDomain.origin;
            //formData['ticket'] = ticket;
            suffixesDomain = configDomain.domain;
        }

        AccountService.loginSSO(formData)
            .then(async (response) => {
                const { authToken, lifetime, username } = this.getResponseBodyAuthSSO(response)["AuthResponse"];

                this.setCookie("ZM_AUTH_TOKEN", authToken);
                this.setCookie("login_sc", 1);
                // this.getAccountInfo(username);

                const tokenStorage = {
                    lifetime: lifetime,
                    username: username,
                };
                tokenStorage['auth_token'] = authToken;
                this.addLocalStorageObject("zimbra_token", tokenStorage);

                const { origin } = window.location;
                window.location.href = `${origin}/mail`;
            })
            .catch((err) => {
                const errorCode = this.getResponseBody(err.response)[
                    "soap:Fault"
                ].detail.Error.Code;
                if (errorCode == ERROR_CODE.ACCOUNT_AUTH_FAILED) {
                    this.authFailed = true;
                }
            });
    },
    methods: {
        ...mapActions(["getAccountInfo"]),
    },
};
</script>

<style></style>
