const settingModule = {
    state: {
        // signatures: [],
    },
    getters: {
        // signatures: state => state.signatures
    },
    mutations: {
        SET_SIGNATURES(state, data) {
            state.signatures = data
        },
        ADD_SIGNATURES(state, data) {
            state.signatures.push(data)
        },
        EDIT_SIGNATURES(state, data) {
            const _index = state.signatures.findIndex(signature => signature.id === data.id)
            if (_index !== -1) {
                state.signatures = [
                    ...state.signatures.slice(0, _index),
                    data,
                    ...state.signatures.slice(_index + 1)
                ]
            }
        },
        DELETE_SIGNATURES(state, data) {
            state.signatures = state.signatures.filter(signature => signature.id !== data.id)
        }
    }
}
export default settingModule;