<template>
    <div class="form-controls">
        <label v-show="label" :for="id" class="label-control">{{this.label}}</label>
        <el-select
            :id="id"
            class="el-select-control"
            :popper-class="`zm-select-custom ${popperClass}`"
            :size="size"
            v-model="keyword"
            :disabled ="disabled"
            :placeholder="placeholder"
            :clearable ="clearable"
            @change="handleChange"
        >
            <!-- <template slot="prefix"><img class="prefix" :src="value.photo" /></template> -->
            <el-option
                v-for="option in options"
                :key="option[valueField]"
                :label="renderSideI18N ? option[displayField] : $t(option[displayField])"
                :value="option[valueField]"
                :selected="option[valueField] === value"
            >
                <!-- <img :src="option[prefix]" /> -->
            </el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        label: {
            type: String,
            default: "",
        },
        id: {
            type: [String, Number],
            default: "",
        },
        placeholder: {
            type: String,
            default: "Please select",
        },
        value: {
            type: [String, Number, Object],
        },
        options: {
            type: Array,
            required: true,
        },
        valueField: {
            type: String,
            default: "id",
        },
        displayField: {
            type: String,
            default: "name",
        },
        size: {
            type: String,
            default: "small",
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        popperClass: {
            type: String,
            default: ""
        },
        renderSideI18N: {
            type: Boolean,
            default: true
        }
        // prefix: {
        //   type: [String, Number],
        // }
    },
    created() {
        this.keyword = this.value;
    },
    data() {
        return {
            keyword: "",
        };
    },
    watch: {
        value(val) {
            this.keyword = val;
        },
    },
    methods: {
        handleChange(val) {
            this.$emit("change", val);
        },
    },
};
</script>
