<template>
    <div class="form-controls">
        <label v-show="label" :for="id" class="label-control">{{
            this.label
            }}</label>
        <el-cascader
            :popper-class="custommize"
            :id="id"
            :size="size"
            v-model="keyword"
            :props="{ expandTrigger: 'hover' }"
            :options="dataOp"
            :show-all-levels="false"
            :placeholder="placeholder"
            :clearable ="clearable"
            @change="handleChange">
            </el-cascader>
    </div>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            custommize: String,
            label: {
                type: String,
                default: "",
            },
            id: {
                type: [String, Number],
                default: "",
            },
            placeholder: {
                type: String,
                default: "Vui lòng chọn",
            },
            value: {
                type: [String, Number, Object, Array],
            },
            options: {
                type: Array,
                required: true,
            },
            valueField: {
                type: String,
                default: "id",
            },
            displayField: {
                type: String,
                default: "name",
            },
            size: {
                type: String,
                default: "small",
            },
            clearable: {
                type: Boolean,
                default: false,
            },
        },
        created() {
            this.keyword = Array.isArray(this.value) ? this.value : this.value.split(",");
            this.dataOp = this.buildData(this.options)
        },
        data() {
            return {
                keyword: "",
                dataOp: []
            };
        },
        watch: {
            value(val) {
                this.keyword = Array.isArray(val) ? val : val.split(",");
            },
            options(data) {
                this.dataOp = this.buildData(data)
            }
        },
        methods: {
            buildData(data) {
                return data.map(item => {
                    return {
                        value: item.value,
                        label: this.$t(item.label) !== item.name ? `${this.$t(item.label)} - ${item.name}` : `${this.$t(item.label)}`,
                        children: item?.children ? this.buildData(item.children) : null
                    }
                })
            },
            handleChange(val) {
                this.$emit("change", val);
            },
        },
    };
</script>
