<template>
    <el-dialog
        class="dialog-body-wrap zimbra-dialog"
        :title="$t('zimbra.zmWebExZimlet.manageAccounts')"
        custom-class="mt-1 mw__600"
        :visible.sync="isOpenDialog"
        width="35%"
        height="auto"
        :append-to-body="true"
        :close-on-click-modal="false"
    >
        <el-tabs v-model="activeTab" type="card" closable @tab-remove="removeAccount">
            <template v-for="(item, index) in lstFormAdd">
                <el-tab-pane
                    :name="`${index}`"
                    :key="index"
                >
                    <span slot="label">{{item.accountname}}</span>
                    <el-form class="form-wrap">
                        <div class="row pt-2">
                            <div class="col-5 zm-label">
                                <p class="float-left webEx-font-bold">
                                    {{ $t("zimbra.zmWebExZimlet.accountSettings") }}
                                </p>
                            </div>
                        </div>
                        <hr style="margin: 0px" />
                        <div class="row pt-2">
                            <div class="col-4 zm-label">
                                <p class="webEx-label">
                                    {{ $t("zimbra.zmMsg.accountNameLabel") }}
                                </p>
                            </div>
                            <div class="col-6">
                                <el-input
                                    class="el-text-control float-left"
                                    v-model="item.accountname"
                                    autocomplete="off"
                                    size="medium"
                                ></el-input>
                            </div>
                            <div class="col-2 zm-label">
                                <a
                                    href="javascript:void(0)"
                                    @click="removeAccount(index)"
                                    class="btn-delete"
                                    >Xóa</a
                                >
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-4 zm-label">
                                <p class="webEx-label">
                                    {{ $t("zimbra.zmMsg.usernameLabel") }}
                                </p>
                            </div>
                            <div class="col-6">
                                <el-input
                                    class="el-text-control float-left"
                                    v-model="item.username"
                                    autocomplete="off"
                                    type="text"
                                    size="medium"
                                ></el-input>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-4 zm-label webEx-label">
                                <p class="webEx-label">
                                    {{ $t("zimbra.zmMsg.passwordLabel") }}
                                </p>
                            </div>
                            <div class="col-6">
                                <el-input
                                    class="el-text-control float-left"
                                    v-model="item.pwd"
                                    autocomplete="off"
                                    type="password"
                                    size="medium"
                                ></el-input>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-4 zm-label webEx-label">
                                <p class="webEx-label">{{ $t("zimbra.zmWebExZimlet.companyId") }}</p>
                            </div>
                            <div class="col-6">
                                <el-input
                                    class="el-text-control float-left"
                                    v-model="item.companyId"
                                    autocomplete="off"
                                    size="medium"
                                ></el-input>
                            </div>
                            <div class="col-2 zm-label">
                                <a href="javascript:void(0)" @click="showHelpDialog(companyNote)">{{ $t("zimbra.zmWebExZimlet.help") }}</a>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-4 zm-label webEx-label">
                                <p class="webEx-label">
                                    {{ $t("zimbra.zmWebExZimlet.meetingPwd") }}
                                </p>
                            </div>
                            <div class="col-6">
                                <el-input
                                    class="el-text-control float-left"
                                    v-model="item.meetingPwd"
                                    autocomplete="off"
                                    size="medium"
                                ></el-input>
                            </div>
                            <div class="col-2 zm-label">
                                <p href="javascript:void(0)">{{ $t("zimbra.zmWebExZimlet.optional") }}</p>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-4 zm-label webEx-label">
                                <p class="webEx-label">
                                    {{ $t("zimbra.zmWebExZimlet.altHosts") }}
                                </p>
                            </div>
                            <div class="col-6">
                                <el-input
                                    class="el-text-control float-left"
                                    v-model="item.altHosts"
                                    autocomplete="off"
                                    size="medium"
                                ></el-input>
                            </div>
                            <div class="col-2 zm-label">
                                <a href="javascript:void(0)"  @click="showHelpDialog(hostNote)">{{ $t("zimbra.zmWebExZimlet.help") }}</a>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-4 zm-label">
                                <p class="webEx-label">{{ $t("zimbra.zmMsg.linkTo") }}</p>
                            </div>
                            <div class="col-6">
                                <el-select
                                    class="float-left"
                                    v-model="item.AssociatedCalendar"
                                    size="mini"
                                    :default-first-option="true"
                                >
                                    <el-option
                                        v-for="(item, idx) in dataCalendarFolder"
                                        :key="idx"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                        <span>
                                            {{ item.label }}
                                        </span>
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-4 zm-label webEx-label"></div>
                            <div class="col-8 zm-label">
                                <el-button class="float-left webEx-btn" @click="validateWebexAccount(index)">
                                    <i class="icon-custom-wrap ic-WebEX"></i>
                                    <span>{{ $t("zimbra.zmWebExZimlet.validateWebExAccntNumber", [index + 1]) }}</span>
                                </el-button>
                                &nbsp;
                                &nbsp;
                                <a
                                    href="javascript:void(0)"
                                    @click="item.isOpenPhoneSetting = true"
                                    >{{ $t("zimbra.zmWebExZimlet.addTeleConfSettings") }}</a
                                >
                            </div>
                        </div>
                        <br />
                    </el-form>
                    <el-form class="form-wrap" v-if="item.isOpenPhoneSetting">
                        <div class="row pt-2 setting-phone">
                            <div class="col-6 zm-label">
                                <p class="float-left webEx-font-bold">
                                    {{ $t("zimbra.zmWebExZimlet.teleConfSettings") }}
                                </p>
                            </div>
                            <div class="col-6 zm-label">
                                <a
                                    href="javascript:void(0)"
                                    @click="resetFormPhoneSetting(item)"
                                    class="float-right webEx-font-bold"
                                    >{{ $t("zimbra.zhMsg.actionDelete") }}</a
                                >
                            </div>
                        </div>
                        <hr style="margin: 0px" />
                        <div class="row pt-2">
                            <div class="col-4 zm-label">
                                <p class="webEx-label">
                                    {{ $t("zimbra.zmWebExZimlet.tollFreeNumber") }}
                                </p>
                            </div>
                            <div class="col-6">
                                <el-input
                                    class="el-text-control float-left"
                                    v-model="item.tollFreePH"
                                    autocomplete="off"
                                    size="medium"
                                ></el-input>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-4 zm-label">
                                <p class="webEx-label">{{ $t("zimbra.zmMsg.deviceEmailNotificationsPhoneNumberLabel") }}</p>
                            </div>
                            <div class="col-6">
                                <el-input
                                    class="el-text-control float-left"
                                    v-model="item.tollPH"
                                    autocomplete="off"
                                    size="medium"
                                ></el-input>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-4 zm-label webEx-label">
                                <p class="webEx-label">{{ $t("zimbra.zmWebExZimlet.otherPhoneNumbers") }}</p>
                            </div>
                            <div class="col-6">
                                <el-input
                                    class="el-text-control float-left"
                                    v-model="item.otherPH"
                                    autocomplete="off"
                                    type="textarea"
                                    :rows="4"
                                    size="medium"
                                ></el-input>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-4 zm-label webEx-label">
                                <p class="webEx-label">
                                    {{ $t("zimbra.zmMsg.passwordLabel") }}
                                </p>
                            </div>
                            <div class="col-6">
                                <el-input
                                    class="el-text-control float-left"
                                    v-model="item.phonePasscode"
                                    autocomplete="off"
                                    size="medium"
                                ></el-input>
                            </div>
                            <div class="col-2 zm-label">
                                <p href="javascript:void(0)">{{ $t("zimbra.zmWebExZimlet.digitsOnly") }}</p>
                            </div>
                        </div>
                        <br />
                    </el-form>
                </el-tab-pane>
            </template>
        </el-tabs>
        <hr style="margin: 2px" />
        <span slot="footer" class="dialog-footer">
            <el-button
                @click="addNewAccount"
                class="btn-outline float-left webEx-btn"
            >
                {{ $t("zimbra.zmWebExZimlet.addAccount") }}
            </el-button>
            <el-button type="primary" @click="onSaveAccount">{{
                $t("zimbra.zmMsg.mobileStatusNeedProvision")
            }}</el-button>
            <el-button @click="isOpenDialog = false">{{
                $t("zimbra.zhMsg.actionTaskCancelTT")
            }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import CalendarUtils from "@/utils/calendar-utils";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import _ from "lodash";
export default {
    mixins: [CalendarUtils],
    mounted() {
        this.$root.$on("onOpenAccountManangeWebex", () => {
            this.isOpenDialog = true;
            const data = this.lstDataProcess();
            if (this.lstFormAdd.length < 1) {
                this.lstFormAdd = data.slice();
            }
            if (this.lstFormAdd.length < 1) {
                this.lstFormAdd.push({ ...this.formAdd });
            }
            this.callCalen();
        });
    },
    destroyed() {
        this.$root.$off("onOpenAccountManangeWebex");
    },
    computed: {
        
    },
    components: {
    },
    data() {
        return {
            // for add
            lstFormAdd: [],
            dataCalendarFolder: [],
            //form setting phonnumber
            formAdd: {
                accountname: "",
                username: "",
                pwd: "",
                meetingPwd: "",
                altHosts: "",
                companyId: "zimbra",
                tollFreePH: "",
                tollPH: "",
                phonePasscode: "",
                otherPH: "",
                isOpenPhoneSetting: false,
                AssociatedCalendar: "ALL",
            },
            title: "",
            isOpenDialog: false,
            activeTab: 0,
            companyNote: this.$t("zimbra.zmWebExZimlet.companyIdExample"),
            hostNote: this.$t("zimbra.zmWebExZimlet.setupAlternateHost")
        };
    },
    methods: {
        /**
         * Hàm thêm một tab mới
         */
        addNewAccount() {
            if (this.lstFormAdd.length == 5) {
                //Xuất thông báo không vượt quá 5
                this.$notify({
                    title: this.$t("zimbra.zhMsg.optionsVoiceNotifications"),
                    message: "Chỉ có năm tài khoản có sẵn.",
                    type: "success",
                });
            } else {
                let flag = false;
                let i = 0;
                for (var key in this.lstFormAdd) {
                    const item = this.lstFormAdd[key];
                    i++;
                    if (this.isValidForm(item)) {
                        // Khong lam gi ca
                    } else {
                        flag = true;
                        this.activeTab = (i - 1).toString();
                        return;
                    }
                }
                if (!flag) {
                    this.lstFormAdd.push({ ...this.formAdd });
                    this.activeTab = (this.lstFormAdd.length - 1).toString();
                }
            }
        },
        /**
         * Hàm xóa account
         */
        removeAccount(index) {
            if(this.lstFormAdd.length > 1){
                this.lstFormAdd.splice(index, 1);
                if(index == this.lstFormAdd.length){
                    this.activeTab = (this.lstFormAdd.length - 1).toString();
                } else {
                    this.activeTab = index;
                }
            } else {
                 this.resetForm(this.lstFormAdd[0]);
            }
        },

        /**
         * resetForm
         */
        resetForm(form) {
            form["accountname"] = "";
            form["username"] = "";
            form["pwd"] = "";
            form["meetingPwd"] = "";
            form["altHosts"] = "";
            form["companyId"] = "";
            form["AssociatedCalendar"] = "ALL";
            this.resetFormPhoneSetting(form);
        },
        resetFormPhoneSetting(form) {
            form["tollFreePH"] = "";
            form["tollPH"] = "";
            form["phonePasscode"] = "";
            form["otherPH"] = "";
            form["isOpenPhoneSetting"] = false;
        },

        /**
         * Tạo dữ liệu trong select lịch liên kết tới
         */
        async callCalen(){
            this.dataCalendarFolder = [];
            this.dataCalendarFolder.push({value: "ALL", label: "Tất cả lịch biểu"})
            const formData = {
                folder: `<view>appointment</view><depth>-1</depth><tr>1</tr><visible>1</visible><needGranteeName>1</needGranteeName>`,
            };
            const res = await ZimbraMailService.getFolderRequest(formData);
            const data = this.getResponseBody(res)["GetFolderResponse"]["folder"].folder;
            for (var key in data) {
                const item = data[key];
                if(item["name"].trim() != "Trash"){
                    this.dataCalendarFolder.push({value: item.id, label: item.name})
                }
            }
        },

        /**
         * Hàm lưu account
         */
        async onSaveAccount() {
            const zimblet = "webexZimlet_"
            let arr = [];
            let i= 0;
            while(i < 5) {
                i++;
                if(i <= this.lstFormAdd.length ){
                    const item = this.lstFormAdd[i-1];
                    arr.push({n : zimblet + "accountname" +i, content: item["accountname"] ? _.escape(item["accountname"]) : "N/A"});
                    arr.push({n : zimblet + "username" +i, content: item["username"] ? _.escape(item["username"]) : "N/A"});
                    arr.push({n : zimblet + "pwd" + i, content: item["pwd"] ? _.escape(item["pwd"]) : "N/A"});
                    arr.push({n : zimblet + "meetingPwd" + i, content: item["meetingPwd"] ? _.escape(item["meetingPwd"]) : "N/A"});
                    arr.push({n : "WebExZimlet_altHosts" + i, content: item["altHosts"] ? _.escape(item["altHosts"]) : "N/A"});
                    arr.push({n : zimblet + "companyId" + i, content: item["companyId"] ? _.escape(item["companyId"]) : "N/A"});
                    arr.push({n : zimblet + "AssociatedCalendar" + i, content: item["AssociatedCalendar"] ? item["AssociatedCalendar"].toString() : "ALL"});
                    arr.push({n : zimblet + "tollFreePH" + i, content: item["tollFreePH"] ? _.escape(item["tollFreePH"]): "N/A"});
                    arr.push({n : zimblet + "tollPH" + i, content: item["tollPH"] ? _.escape(item["tollPH"]) : "N/A"});
                    arr.push({n : zimblet + "phonePasscode" + i, content: item["phonePasscode"] ? _.escape(item["phonePasscode"]) : "N/A"});
                    arr.push({n : zimblet + "otherPH" + i, content: item["otherPH"] ? _.escape(item["otherPH"]) : "N/A"});
                } else {
                    arr.push({n : zimblet + "accountname" +i, content: "N/A"});
                    arr.push({n : zimblet + "username" +i, content: "N/A"});
                    arr.push({n : zimblet + "pwd" + i, content: "N/A"});
                    arr.push({n : zimblet + "meetingPwd" + i, content: "N/A"});
                    arr.push({n : "WebExZimlet_altHosts" + i, content: "N/A"});
                    arr.push({n : zimblet + "companyId" + i, content: "N/A"});
                    arr.push({n : zimblet + "AssociatedCalendar" + i, content: "ALL"});
                    arr.push({n : zimblet + "tollFreePH" + i, content: "N/A"});
                    arr.push({n : zimblet + "tollPH" + i, content: "N/A"});
                    arr.push({n : zimblet + "phonePasscode" + i, content: "N/A"});
                    arr.push({n : zimblet + "otherPH" + i, content:"N/A"});
                }
            }
            
            const formSetFolderAccount = {
                section: "zwc:webexZimletAccountPreferences",
                a: arr.slice(),
            };
            await ZimbraMailService.setMailboxMetadataRequest(formSetFolderAccount).then(() => {
                this.$notify({
                    title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: this.$t("zimbra.zmWebExZimlet.PrefSaved"),
                    type: "success",
                });
                this.initAcountWebexZimlet();
            });
            this.isOpenDialog = false;
        },

        isValidForm(form){
            if (
                form["accountname"] || form["accountname"].toString().trim().length > 0 ||
                form["username"] || form["username"].toString().trim().length > 0 ||
                form["pwd"] || form["pwd"].toString().trim().length > 0 ||
                form["meetingPwd"] || form["meetingPwd"].toString().trim().length > 0 ||
                form["altHosts"] || form["altHosts"].toString().trim().length > 0 ||
                form["tollFreePH"] || form["tollFreePH"].toString().trim().length > 0 ||
                form["tollPH"] || form["tollPH"].toString().trim().length > 0 ||
                form["phonePasscode"] || form["phonePasscode"].toString().trim().length > 0 ||
                form["otherPH"] || form["otherPH"].toString().trim().length > 0){
                    return true;
            }
            return false;
        },
        isValidFormSettingPhone(form){
            if (
                form["tollFreePH"] || form["tollFreePH"].toString().trim().length > 0 ||
                form["tollPH"] || form["tollPH"].toString().trim().length > 0 ||
                form["phonePasscode"] || form["phonePasscode"].toString().trim().length > 0 ||
                form["otherPH"] || form["otherPH"].toString().trim().length > 0){
                return true;
            }
            return false;
        },
        showHelpDialog(strMess) {
            this.$alert(
                    strMess,
                    this.$t("zimbraNokey.zimbra_common_titleInfo"),
                    {
                        confirmButtonText:this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "info",
                        dangerouslyUseHTMLString: true,
                    }
            )
        },
        lstDataProcess(){
            const lstAccountWebExZimlet = this.$store.getters.lstAccountWebExZimlet;
            const lstData = [];
            lstAccountWebExZimlet.map((item) => {
                const form = {};
                form["accountname"] = item.webexZimlet_accountname;
                form["username"] = item.webexZimlet_username;
                form["pwd"] = item.webexZimlet_pwd;
                form["meetingPwd"] = item.webexZimlet_meetingPwd;
                form["altHosts"] = item.WebExZimlet_altHosts;
                form["companyId"] = item.webexZimlet_companyId;
                form["AssociatedCalendar"] = item.webexZimlet_AssociatedCalendar;
                form["tollFreePH"] = item.webexZimlet_tollFreePH ? item.webexZimlet_tollFreePH : "";
                form["tollPH"] = item.webexZimlet_tollPH ? item.webexZimlet_tollPH : "";
                form["phonePasscode"] = item.webexZimlet_phonePasscode ? item.webexZimlet_phonePasscode : "";
                form["otherPH"] = item.webexZimlet_otherPH ? item.webexZimlet_otherPH : "";
                form["isOpenPhoneSetting"] = this.isValidFormSettingPhone(form);
                if(this.isValidForm(form) && form["username"].toString().length > 0 && form["pwd"].toString().length > 0 && form["companyId"].toString().length > 0){
                    lstData.push({...form});
                }
          })
          
          return lstData;
        },
        validateWebexAccount(index) {
            const form = this.lstFormAdd[index];
            if(!this.isValidForm(form) || !form["username"].toString().length > 0 || !form["pwd"].toString().length > 0 || !form["companyId"].toString().length > 0) {
                this.$notify({
                    message: this.$t("zimbra.zmWebExZimlet.userPwdCIdRequired"),
                    type: "warning",
                });
            }
             
        }
    },
};
</script>
<style scoped>
.btn-validateWebExAcc {
    padding-bottom: 5px;
}
.webEx-label {
    line-height: 37px;
    float: right !important;
}
.btn-delete {
    line-height: 37px;
    color: red;
    font-weight: bold;
}
a {
    line-height: 37px;
    color: rgb(43, 80, 247);
}
.webEx-font-bold {
    font-weight: bold;
}
.webEx-btn {
    padding: 8px 10px !important;
}
.setting-phone {
    margin-bottom: -11px;
}
</style>
