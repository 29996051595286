<template>
    <el-dialog
        class="dialog-body-wrap zimbra-dialog"
        custom-class="modal-add__tag"
        :title="$t('zimbra.zhMsg.createNewTag')"
        :visible.sync="dialogAddTag"
        width="422px"
        :append-to-body="true"
        :close-on-click-modal="false"
    >
        <el-form class="form-wrap">
            <el-row :gutter="10">
                <el-col :span="4" class="zm-label">
                    <span>{{ $t("zimbraNokey.zimbra_sidebar_dialogAddTag_tagName") }}</span>
                </el-col>
                <el-col :span="12">
                    <el-input
                        v-model="formAddEditTag.name"
                        autocomplete="off"
                        size="medium"
                        id="input-name-tag-folder"
                        class="el-text-control"
                    ></el-input>
                </el-col>
                <el-col :span="8">
                    <zm-color-picker
                        v-model="formAddEditTag.rgb"
                        :visible="false"
                        :showFooter="true"
                        :default-colors="['#3F4254', '#0000FF', '#00FFFF', '#008000', '#FF00FF', '#FF0000', '#FFFF00', '#FF66FF', '#808080', '#FF9900']"
                    >
                    </zm-color-picker>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button  class="btn-email default cover-size" @click="dialogAddTag = false">{{
                $t("zimbra.zhMsg.actionTaskCancelTT")
            }}</el-button>
            <el-button  class="btn-email primary cover-size" @click="addTag">{{
                $t("zimbra.zmMsg.mobileStatusNeedProvision")
            }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import ZmColorPicker from "@/components/modules/base/base-color-picker-advance/zm-color-picker";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import EmailUtils from "@/utils/email-utils";
import { SIDEBAR_NODE, STATUS_REQUEST, FOLDER_VIEW, CONTACT_ACTION, BRIEFCASE_ACTION, READ_EMAIL_LAYOUT_ROUTER } from "@/utils/constants";
import color from "./../listColor";
import { mapGetters } from "vuex";
import _ from "lodash";
import $ from 'jquery'
import hotkeys from "hotkeys-js";
import CalendarUtils from "@/utils/calendar-utils";
export default {
    mixins: [CommonUtils, EmailUtils],
    mounted() {
        this.$root.$on("handleTagActionAdd", () => {
            this.show();
        });
        this.$root.$on("handleAddAssginTag", (mailAssginTag) => {
            this.show();
            this.mailAssginTag = mailAssginTag;
            this.isAssginTag = true;
        });
        hotkeys("n+t", this.handleNewTag);
    },
    computed: {
        ...mapGetters(["tags", "listEmailSearch", "listSelectedMail", "listTag"])
    },
    data() {
        return {
            formAddEditTag: { name: "", rgb: "#3F4254" },
            dialogAddTag: false,
            SIDEBAR_NODE: SIDEBAR_NODE,
            listColor: color,
            objectColor: { value: "-1", label: "Không" },
            isAssginTag: false,
            mailAssginTag: {},
            CONTACT_ACTION: CONTACT_ACTION,
            STATUS_REQUEST: STATUS_REQUEST
        };
    },
    components: {
        "zm-color-picker": ZmColorPicker
    },
    methods: {
        handleNewTag(e) {
            if (e.preventDefault) {
                this.show();
            } else {
                e.returnValue = false;
            }
        },
        handleCommand(item) {
            this.formAddEditTag.rgb = item.value;
            if (item.value == "-1") {
                this.$refs.colorPicker.showPicker = true;
                this.$refs.colorPicker.popperElm.childNodes[3].childNodes[1].childNodes[2].outerHTML = `<div><el-button id='colorDefault' style='color: #606266'>Sử dụng màu mặc định</el-button> <br> <div id = 'colorLess' style='color: #606266;padding-top: 10px;'>Ít màu hơn...</div></div>`;
                var btDefault = document.getElementById("colorDefault");
                btDefault.addEventListener(
                    "click",
                    this.handleClickColorDefault
                );
                var btLess = document.getElementById("colorLess");
                btLess.addEventListener("click", this.handleClickColorLess);
            } else {
                this.objectColor = item;
            }
        },
        handleClickColorDefault() {
            this.formAddEditTag.rgb = null;
            this.objectColor = { value: "-1", label: "Không" };
        },
        handleClickColorLess() {
            this.$refs.colorBasic.show();
        },
        show() {
            this.isAssginTag = false;
            this.formAddEditTag = {
                name: "",
                rgb: "#3F4254"
            };
            this.dialogAddTag = true;
            this.objectColor = { value: "-1", label: "Không" };
            // Focus Tên tag
            $(document).ready(function () {
                setTimeout(function () {
                    $("#input-name-tag-folder").focus();
                }, 500);
            });
            const { name } = this.$route;
            if (name == READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE) {
                this.isMessage = true
            } else {
                this.isMessage = false
            }
        },
        checkExistsName(name) {
            if (this.listTag) {
                if (this.listTag.map(x => x.name.toString().toLowerCase()).indexOf(name.toString().toLowerCase()) > -1) {
                    this.$alert(
                        this.$t("zimbra.zmMsg.tagNameExists", {
                            name: name
                        }),
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error"
                        }
                    );
                    return true;
                }
            }
        },
        addTag() {
            this.formAddEditTag.name = this.formAddEditTag.name.trim();
            if (
                this.formAddEditTag.name == "" ||
                this.formAddEditTag.name.length > 128 ||
                this.formAddEditTag.name.indexOf("/") > -1
            ) {
                const nameError = _.escape(this.formAddEditTag.name);
                const Msgerror =
                    this.formAddEditTag.name == ""
                        ? this.$t("zimbra.zmMsg.tagNameEmpty")
                        : this.formAddEditTag.name.length > 128
                            ? this.$t("zimbra.zhMsg.nameTooLong", { 0: 128 })
                            : this.$t(
                                "zimbra.zmMsg.errorInvalidName",
                                { 0: nameError }
                            );
                this.$alert(Msgerror, this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"), {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    customClass: "notification-error-tag",
                    type: "error"
                });
                return;
            }
            if (this.checkExistsName(this.formAddEditTag.name)) {
                return;
            }
            this.formAddEditTag.name = _.escape(this.formAddEditTag.name);
            const formData = {
                name: `${this.formAddEditTag.name}`
            };
            if (this.formAddEditTag.rgb != null) {
                formData.rgb = this.formAddEditTag.rgb;
            }
            ZimbraMailService.createTagRequest(formData).then(res => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    if (this.isMessage) {
                        this.$message({
                            type: "success",
                            customClass: 'zm-notify',
                            duration: 3500,
                            dangerouslyUseHTMLString: true,
                            message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessAddTag")
                        });
                    } else {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessAddTag"),
                            type: "success"
                        });
                    }

                    const itemAddResponse = this.getResponseBody(res)[
                        "CreateTagResponse"
                    ];
                    const itemAdd = itemAddResponse.tag;
                    itemAdd.l = -2;
                    itemAdd.type = SIDEBAR_NODE.TAG;
                    this.$root.$emit("addItemToDataExtend", itemAdd);
                    if (this.isAssginTag) {
                        if (this.mailAssginTag && this.mailAssginTag.types && this.mailAssginTag.types === FOLDER_VIEW.APPOINTMENT) {
                            CalendarUtils.methods.handledAssginOrRemoveTagCalendar(this.mailAssginTag.ids, itemAdd.name, true);
                            this.$root.$emit("onSearchAppointmentRequest");
                        } else if (this.mailAssginTag && this.mailAssginTag.types && this.mailAssginTag.types === CONTACT_ACTION.ADD_TAG_TO_CONTACT) {
                            this.$root.$emit("handleNavbarActionContact", CONTACT_ACTION.ADD_TAG_TO_CONTACT, { item: itemAdd, isAssginTag: true })
                        } else if (this.mailAssginTag && this.mailAssginTag.types && this.mailAssginTag.types === FOLDER_VIEW.DOCUMENT) {
                            this.$root.$emit("handleNavbarAction", BRIEFCASE_ACTION.ASSIGN_TAG, { item: itemAdd, isAssginTag: true })
                        } else {
                            this.handledAssginOrRemoveTag(itemAdd, true);
                        }
                    }
                }
            });
            this.dialogAddTag = false;
        }
    }
};
</script>
