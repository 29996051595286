<template>
    <el-dialog
        class="dialog-body-wrap d-center "
        custom-class="dialog-created__folder setting-filter dialog-add-folder"
        :title="$t('zimbra.zhMsg.createNewFolder')"
        :visible.sync="dialogCreateFolder"
        @close="closeDialog"
        @open="openDialog"
        width="30%"
        height="550px"
        :append-to-body="true"
        :close-on-click-modal="false"
        :destroy-on-close="true"
    >
        <el-form class="form-wrap">
            <el-form-item
                :label="$t('zimbra.zhMsg.nameLabel')"
                label-width="90px"
            >
                <el-input
                    class="el-text-control"
                    v-model="formAdd.name"
                    autocomplete="off"
                    size="medium"
                ></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('zimbra.zmMsg.colorLabel')"
                label-width="90px"
            >
                <zm-color-picker
                    v-model="formAdd.rgb"
                    :visible="false"
                    :showFooter="true"
                >
                </zm-color-picker>
            </el-form-item>
            <el-form-item
                :label="$t('zimbraNokey.zimbra_sidebar_dialogMoveFolder2_powerSupply')"
                label-width="90px"
            >
                <el-checkbox v-model="formAdd.registerRSS">{{
                    $t(
                        "zimbra.zmMsg.subscribeToFeed"
                    )
                }}</el-checkbox>
            </el-form-item>
            <el-form-item
                v-if="formAdd.registerRSS"
                :label="$t('zimbra.zhMsg.url')"
                label-width="90px"
            >
                <el-input
                    v-model="formAdd.url"
                    autocomplete="off"
                    size="medium"
                ></el-input>
            </el-form-item>
            <div style="padding-bottom: 10px; padding-top: 10px">
                <span class="zimbra-label">{{
                    $t("zimbra.zmMsg.newFolderParent")
                }}</span>
            </div>
            <div class="wrap-tree">
                <TreeChooseFolder
                    :showCheckbox="false"
                    :showTextboxSearch="false"
                    :showInfoFolder="true"
                    ref="treeFolderExtra"
                    :listIdFolderHidden = [FOLDER_DEFAULT.TAG,FOLDER_DEFAULT.JUNK,FOLDER_DEFAULT.STARS,FOLDER_DEFAULT.DRAFTS,FOLDER_DEFAULT.ROOT]
                ></TreeChooseFolder>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
                <base-button-default
                    class="btn-email default cover-size"
                    @click="closeDialog">
                    {{ $t("zimbra.zhMsg.actionTaskCancelTT") }}
                </base-button-default>
                <base-button-default
                    class="btn-email primary cover-size"
                    @click="AddFolder"
                    >
                    {{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}
                </base-button-default>
        </span>
    </el-dialog>
</template>
<script>
import TreeChooseFolder from "./TreeChooseFolder";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import color from "./../listColor";
import { EMAIL_ACTION, FOLDER_DEFAULT, STATUS_REQUEST } from "@/utils/constants";
import _ from "lodash";
import { mapGetters } from "vuex";
import { ERROR_CODE } from "@/utils/constants";
import ZmColorPicker from "@/components/modules/base/base-color-picker-advance/zm-color-picker";
import BaseButtonDefault from "@/components/modules/base/base-button/btn-default";
export default {
    mixins: [CommonUtils],
    mounted() {
        this.$root.$on("handleShowCreateFolder", (isAddFolderToMoveMail) => {
            this.show(isAddFolderToMoveMail);
        });
    },
    computed: {
        ...mapGetters(["dataSidebarInBox"]),
    },
    // updated(){
    //     const eleParent = document.getElementsByClassName("dialog-created__folder ")[0]
    //     const eleChildren = eleParent.getElementsByClassName("el-tree-node")[0]
    //     eleChildren.classList.add("is-current");
    // },
    components: {
        TreeChooseFolder,
        "zm-color-picker": ZmColorPicker,
        BaseButtonDefault
    },
    data() {
        return {
            formAdd: {
                name: "",
                color: null,
                source: "",
                parent: {},
                url: null,
                rgb: "#3F4254",
            },
            dialogCreateFolder: false,
            listColor: color,
            isAddFolderToMoveMail: false,
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            objectColor: { value: "-1", label: "Không" },
        };
    },
    methods: {
        show(isAddFolderToMoveMail) {
            this.isAddFolderToMoveMail = isAddFolderToMoveMail;
            this.dialogCreateFolder = true;
            this.formAdd = {
                name: "",
                source: "",
                parent: {},
                url: "",
                rgb: "#3F4254",
                registerRSS: false,
            };
            this.objectColor = {
                value: "-1",
                label: "Không",
                color: "#000000",
            };
            // this.$root.$emit("setCurrentKey", null);
            setTimeout(() => {
                this.$refs.treeFolderExtra.setCurrentKey(FOLDER_DEFAULT.ROOT)
            }, 300);
        },
        openDialog(){
            this.$root.$emit('hanldeOpenCreateFolder')
        },
        closeDialog(){
            this.dialogCreateFolder = false
            this.$root.$emit('hanldeCloseCreateFolder')
        },
        checkExistsName(name, folderParent) {
            if (folderParent.id == FOLDER_DEFAULT.ROOT) {
                if (
                    this.dataSidebarInBox
                        .map((x) => x.name.toString().toLowerCase())
                        .indexOf(name.toString().toLowerCase()) > -1
                ) {
                    this.$alert(
                        this.$t("zimbra.zmMsg.errorAlreadyExists", {0: name,1: this.$t("zimbra.zhMsg.ALT_FOLDER")}),
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return true;
                }
            }
            if (Array.isArray(folderParent.folder)) {
                if (folderParent.folder.map((x) => x.name).indexOf(name) > -1) {
                    this.$alert(
                        this.$t("zimbra.zmMsg.errorAlreadyExists", {0: name,1: this.$t("zimbra.zhMsg.ALT_FOLDER")}),
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return true;
                }
            }
        },
        isUrl(s) {
            var regexp =
                /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
            return regexp.test(s);
        },
        AddFolder() {
            this.formAdd.parent = this.$store.getters.activeFolderExtra
                ? this.$store.getters.activeFolderExtra
                : { id: FOLDER_DEFAULT.ROOT };
            if (this.checkExistsName(this.formAdd.name, this.formAdd.parent)) {
                return;
            }
            this.formAdd.name = this.formAdd.name.trim();
            // validateName
            if (
                this.formAdd.name == "" ||
                this.formAdd.name.length > 128 ||
                this.formAdd.name.indexOf("/") > -1
            ) {
                const nameError = _.escape(this.formAdd.name);
                const Msgerror =
                    this.formAdd.name == ""
                        ? this.$t("zimbra.zmMsg.nameEmpty")
                        : this.formAdd.name.length > 128
                        ? this.$t("zimbra.zhMsg.nameTooLong", { 0: 128 })
                        : this.$t(
                              "zimbra.zmMsg.errorInvalidName",
                              { 0: nameError }
                          );
                this.$alert(Msgerror, this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"), {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    type: "error",
                });
                return;
            }
            this.formAdd.name = _.escape(this.formAdd.name);
            // validateUrl
            if (this.formAdd.registerRSS) {
                this.formAdd.url = this.formAdd.url.trim();
                if (this.formAdd.url == "" || !this.isUrl(this.formAdd.url)) {
                    const Msgerror = this.$t(
                        "zimbra.zmMsg.errorUrlMissing"
                    );
                    this.$alert(
                        Msgerror,
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return;
                }
            }
            const formData = {
                l: `${this.formAdd.parent.id}`,
                name: `${this.formAdd.name}`,
            };
            if (this.formAdd.url) {
                formData.url = this.formAdd.url;
            }
            if (this.formAdd.rgb) {
                formData.rgb = this.formAdd.rgb;
            }
            ZimbraMailService.createFolderRequest(formData)
                .then((res) => {
                    if (res.status == STATUS_REQUEST.SUCCESS) {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t(
                                "zimbraNokey.zimbra_sidebar_messageSuccessAddFolder"
                            ),
                            type: "success",
                        });
                        const itemAddResponse =
                            this.getResponseBody(res)["CreateFolderResponse"];
                        const itemAdd = itemAddResponse.folder;
                        if (this.formAdd.parent.id == FOLDER_DEFAULT.ROOT) {
                            this.$root.$emit("addItemToDataInBox", itemAdd);
                        } else {
                            this.$root.$emit("addItemToDataInBox", itemAdd);
                            this.$root.$emit("addItemToDataExtend", itemAdd);
                        }
                        this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", null);
                        this.$root.$emit("setCurrentKey", itemAdd.id);
                        if (this.isAddFolderToMoveMail) {
                            this.$root.$emit(
                                "handleNavbarAction",
                                EMAIL_ACTION.MOVE
                            );
                        }
                    }
                })
                .catch((err) => {
                    const { detail } = this.getResponseBody(err.response)[
                        "soap:Fault"
                    ];
                    if (
                        detail.Error.Code ===
                            ERROR_CODE.SERVICE_RESOURCE_UNREACHABLE ||
                        detail.Error.Code === ERROR_CODE.SERVICE_PARSE_ERROR
                    ) {
                        this.$confirm(
                            this.$t("zimbraNokey.zimbra_sidebar_resourceUnreachable", {
                                url: formData.url,
                            }),
                            this.$t("zimbraNokey.zimbra_common_criticalMsg"),
                            {
                                showConfirmButton: false,
                                cancelButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                                type: "error",
                            }
                        )
                            .then(() => {})
                            .catch(() => {});
                    }
                });
            this.dialogCreateFolder = false;
        },
    },
};
</script>
