const ZmFolderRetentionView = function () {
};

// Data to populate and process the custom units and values
ZmFolderRetentionView._CustomUnitsToDays = { year: 366, month: 31, week: 7, day: 1 };
ZmFolderRetentionView._CustomUnits = [
    { id: "day", label: 'Ngày', days: ZmFolderRetentionView._CustomUnitsToDays.day },
    { id: "week", label: 'Tuần', days: ZmFolderRetentionView._CustomUnitsToDays.week },
    { id: "month", label: 'Tháng', days: ZmFolderRetentionView._CustomUnitsToDays.month },
    { id: "year", label: 'Năm', days: ZmFolderRetentionView._CustomUnitsToDays.year }];

const getValue = (days) => {
    // Convert lifetime to the best fit for unit and amount.  Start with year,
    // proceed to smaller units.  If the amount in days is evenly divisible by the
    // days for a unit, use that unit
    let option = null;
    let value = null;
    for (var i = ZmFolderRetentionView._CustomUnits.length - 1; i >= 0; i--) {
        if ((days >= ZmFolderRetentionView._CustomUnits[i].days) &&
            ((days % ZmFolderRetentionView._CustomUnits[i].days) == 0)) {
                option = ZmFolderRetentionView._CustomUnits[i].days;
                value = days / ZmFolderRetentionView._CustomUnits[i].days;
            break;
        }
    }
    return {
        option,
        value
    }
}



export default {
    ...ZmFolderRetentionView,
    getValue
}