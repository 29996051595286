import { render, staticRenderFns } from "./briefcase-search-size.vue?vue&type=template&id=629f5723&scoped=true"
import script from "./briefcase-search-size.vue?vue&type=script&lang=js"
export * from "./briefcase-search-size.vue?vue&type=script&lang=js"
import style0 from "./briefcase-search-size.vue?vue&type=style&index=0&id=629f5723&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "629f5723",
  null
  
)

export default component.exports