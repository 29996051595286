<template>
    <div>
        <el-dialog
            :title="$t('zimbra.zmMsg.quickAddAppt')"
            :visible="visable"
            :append-to-body="true"
            :destroy-on-close="true"
            class="zimbra-dialog zm-calendar"
            id="appointment-detail"
            :modal="true"
            :modal-append-to-body="true"
            :before-close="handleCancel"
        >
            <!-- <div v-if="visable"> -->
            <div class="wrap zm-calendar-form">
                <div class="wrap-pri">
                    <div class="main-content-wrapper main-calendar">
                        <div class="header-pri">
                            <div class="head-pri">
                                <i class="icon calendar-icon-active"> </i>
                                <span>{{ $t("zimbra.zhMsg.ALT_APP_CALENDAR") }}</span>
                            </div>
                            <button @click="handleCancel()" class="btn-close">
                                <i class="fal fa-times"></i>
                            </button>
                        </div>
                        <div class="header-pri tool-bar">
                            <!-- Thao tác phản hồi lời mời cuộc hẹn -->
                                <div class="row event-info">
                                    <el-popover
                                        v-model="visibleOptionals"
                                        placement="bottom-end"
                                        trigger="click"
                                    >
                                        <ul class="zm-box-control-option">
                                            <li class="text" v-if="isNotReply">
                                                <a
                                                    href="javascript:;"
                                                    @click="onReply(CAL_PARTICIPATION_STATUS.NEEDS_ACTION)"
                                                >
                                                   <i class="pr-2 fas fa-exclamation-triangle" ></i>
                                                   <span>{{ $t('zimbra.zmMsg.needsAction') }}</span>
                                                </a>
                                            </li>
                                            <li class="text">
                                                <a
                                                    href="javascript:;"
                                                    @click="onReply(CAL_PARTICIPATION_STATUS.ACCEPT)"
                                                >
                                                   <i class="pr-2 fas fa-check" style="color: green"></i>
                                                   <span>{{ $t('zimbra.zhMsg.apptPtstAC') }}</span>
                                                </a>
                                            </li>
                                            <li class="text">
                                                <a
                                                    href="javascript:;"
                                                    @click="onReply(CAL_PARTICIPATION_STATUS.TENTATIVE)"
                                                >
                                                    <i class="pr-2 fas fa-question" style="color: grey"></i>
                                                    <span>{{ $t('zimbra.zhMsg.apptPtstTE') }}</span>
                                                </a>
                                            </li>
                                            <li class="text">
                                                <a
                                                    href="javascript:;"
                                                    @click="onReply(CAL_PARTICIPATION_STATUS.DECLINED)"
                                                >
                                                    <i class="pr-2 fas fa-times" style="color: red"></i>
                                                    <span>{{ $t('zimbra.zhMsg.apptPtstDE') }}</span>
                                                </a>
                                            </li>
                                        </ul>
                                        <button
                                            :class="['el-button btn-cancel el-button--default', (isViewOnly || isApptFromExternalCal)? 'noclick': '']"
                                            slot="reference"

                                        >
                                            <span>
                                                <i :class="`pr-2 fas ${getInfoStatus().icon}`" :style="`color: ${getInfoStatus().color}`"></i>
                                                <span>{{ getInfoStatus().label}}</span>
                                                <i class="el-icon-caret-bottom ml-2"></i>
                                            </span>
                                        </button>
                                    </el-popover>
                                    <div class="ml-4 zm-label">
                                        <span>{{ getInfoStatus().content }} </span>
                                     </div>
                                </div>
                        </div>
                        <!-- Nội dung cuộc hẹn -->
                        <div class="main-calendar-pri pl-0 pr-0 scrollbar-y">
                            <div class="w-100 h-100 pt-0">
                                <div class="event-info row w-100 ml-0">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="head-pri">
                                            <span>{{ modelData.name }}</span>
                                        </div>
                                    </div>
                                    <div class="zm-mailbox-inv mt-2 border-bottom">
                                         <table>
                                            <tbody>
                                                <!-- Địa điểm -->
                                                <tr v-if="modelData.loc">
                                                    <td class="inv-label">
                                                        {{ $t("zimbra.zhMsg.locationLabel") }}
                                                    </td>
                                                    <td class="inv-value">
                                                        {{ modelData.loc }}
                                                    </td>
                                                </tr>
                                                <!-- Thời gian -->
                                                <tr>
                                                    <td class="inv-label">
                                                        {{ $t("zimbra.zhMsg.calViewDayShort") }}:
                                                    </td>
                                                    <td v-if="activeAppointment.inst" class="inv-value d-flex">
                                                        <ConvertDateToText
                                                            :time="activeAppointment.inst.s"
                                                            key="1"
                                                        />
                                                        {{ $t("zimbra.zmMsg.calendarWorkHoursDelimiter") }}
                                                        <ConvertDateToText
                                                            :time="
                                                                activeAppointment.inst.s +
                                                                activeAppointment.dur
                                                            "
                                                            :showOnlyTime="true"
                                                            key="2"
                                                        />
                                                        &ensp;{{ timeZoneCurrentLabel }}
                                                    </td>
                                                </tr>
                                                <!-- Người tổ chức -->
                                                <tr v-if="modelData.from && modelData.to && modelData.to.length > 0">
                                                    <td class="inv-label">
                                                        {{ $t("zimbra.zhMsg.organizerLabel") }}
                                                    </td>
                                                    <td class="inv-value">
                                                        <el-tag
                                                            type="info"
                                                            size="small"
                                                            class="mb-1 ml-1"
                                                        >
                                                            <base-address-popover :value="modelData.from">
                                                                <!-- <template
                                                                    v-slot:templateView="{
                                                                        data,
                                                                    }"
                                                                >
                                                                    {{ data.text}}
                                                                </template> -->
                                                            </base-address-popover>
                                                        </el-tag>
                                                    </td>
                                                </tr>
                                                <!-- Khách mời -->
                                                <tr v-if="modelData.to && modelData.to.length > 0">
                                                    <td class="inv-label">
                                                        {{ $t("zimbra.zhMsg.attendee") }}:
                                                    </td>
                                                    <td class="inv-value">
                                                            <el-tag
                                                                v-for="item in modelData.to"
                                                                :key="item.a"
                                                                type="info"
                                                                size="small"
                                                                class="mb-1 ml-1"
                                                            >
                                                                <base-address-popover :value="item">
                                                                    <!-- <template
                                                                        v-slot:templateView="{
                                                                            data,
                                                                        }"
                                                                    >
                                                                        {{ data.text}}
                                                                    </template> -->
                                                                </base-address-popover>
                                                            </el-tag>
                                                    </td>
                                                </tr>
                                                <!-- Lặp lại -->
                                                <tr v-if="recurBlurb">
                                                    <td class="inv-label">
                                                        {{ $t("zimbra.zhMsg.repeatLabel") }}
                                                    </td>
                                                    <td class="inv-value">
                                                        {{ recurBlurb }}
                                                    </td>
                                                </tr>
                                                <!-- Tập đính kèm -->
                                                <tr v-if="modelData.listAttachment && modelData.listAttachment.length > 0">
                                                    <td class="inv-label">
                                                        {{ $t("zimbra.zmMsg.attachmentsLabel") }}
                                                    </td>
                                                    <td class="inv-value content-mail">
                                                        <div class="list-file-mail mb-0">
                                                            <template v-for="item in modelData.listAttachment">
                                                                <a :key="item.part"
                                                                    class="item d-flex"
                                                                    href="javascript:;"
                                                                    @click="actionClickFileAttach(item, modelData.apptId)"
                                                                >
                                                                    <span>
                                                                        <i
                                                                            :class="`ic ic-${item.fileType}`"
                                                                        ></i>
                                                                        {{ item.name }}
                                                                    </span>
                                                                    <div v-if="!isRFC822(item)" class="pl-3">
                                                                        <span class="zm-action-item" @click.stop="downloadFile(item.part)">
                                                                            {{ $t("zimbra.zhMsg.actionDownload") }}
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                            </template>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- Nội dung tin gửi -->
                                <div class="col-sm-12 col-md-12 h-100">
                                    <iframe
                                        ref="refIframe"
                                        :srcdoc="isHTML? contentMsg: `<div style='white-space: pre-line;'> ${contentMsg} </div>`"
                                        allowfullscreen
                                        class="zimbra-iframe-print"
                                        frameborder="0"
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </el-dialog>
    </div>
</template>
<script>
import { CAL_PARTICIPATION_STATUS, CAL_OPERATOR_REPLY_INV, MESSAGE_CONTENT_TYPE, RIGHTS_FOLDER } from "@/utils/constants.js"
import BaseAddressPopover from "@/components/modules/base/base-address-popover/BaseAddressPopover";
import CalendarUtils from "@/utils/calendar-utils.js"
import CommonUtils from "@/utils/common-utils";
import ConvertDateToText from "../components/warning-calendar/convert-date-to-text.vue";
import ZmMimeTable from "@/utils/zimbra-lib/ZmMimeTable";
export default {
    components: {
        BaseAddressPopover,
        ConvertDateToText,
    },
    mixins: [CalendarUtils, CommonUtils],
    data() {
        return {
            visable: false,
            visibleOptionals: false,
            oldStatus: "",
            action: "",
            modelData: {}
        }
    },
    computed: {
        CAL_PARTICIPATION_STATUS() {
            return CAL_PARTICIPATION_STATUS;
        },
        activeAppointment() {
            return this.$store.getters.activeAppointment;
        },
        timeZoneCurrentLabel() {
            const key = `zimbra.ztMsg.${this.$store.getters.timeZoneCurrent.content}`
            return this.$t(key);
        },
        ZmMimeTable() {
            return ZmMimeTable;
        },
        isHTML() {
            return this.modelData.contentType == ZmMimeTable.TEXT_HTML;
        },
        contentMsg() {
            return this.truncateBodyContent(this.isHTML ? this.modelData.descHtml || "" : this.modelData.desc || "", this.isHTML);
        },
        isNotReply(){
            return this.activeAppointment.ptst == CAL_PARTICIPATION_STATUS.NEEDS_ACTION;
        },

        /**
         * Lặp lại
         */
        recurBlurb() {
            // tùy chọn lăp lại
            return this.getBlur(this.modelData);
        },

        /**
         * hàm kiểm tra cuộc hẹn có phải chỉ có quyền xem không
         */
        isViewOnly() {
            const rootNode = {id: 1, folder: this.$store.getters.calTreeFolders}
            // Nếu không phải folder chia sẻ thì dừng
            if(!this.isCalendarFolderShare(this.activeAppointment)) {
                return false;
            }
            const calendarId =  this.activeAppointment.l.split(":")[1];
            const calendarFolder = this.searchNodeShareById(rootNode, calendarId);
            if(calendarFolder && calendarFolder.perm && calendarFolder.perm == RIGHTS_FOLDER.READ) {
                return true;
            }
            return false;
        },
        /**
         * hàm kiểm tra cuộc hẹn được thêm qua lịch ngoài
         */
        isApptFromExternalCal () {
            return this.checkApptFromExternalCal(this.modelData.l)
        },

    },
    created() {
    },
    methods: {
        show(_modelData) {
            this.visable = true;
            this.modelData = {..._modelData};
            this.modelData.ptst = this.activeAppointment.ptst;
            this.oldStatus = this.activeAppointment.ptst;
            if(_modelData.listAttachment && _modelData.listAttachment.length > 0) {
                this.modelData.listAttachment = _modelData.listAttachment.map(e => ({
                    ...e,
                    fileType: this.classFileType(e.filename),
                    isImageFile: this.isImageFile(e.filename)
                }));
            }
            _modelData.or.text = this.getTextAddressSelector(_modelData.or);
            this.modelData.from = _modelData.or;
            this.modelData.to?.length < 1 && this.isApptFromExternalCal ? this.modelData.to = this.modelData.at.slice() : ''
        },
        handleCancel() {
             if (this.oldStatus != this.modelData.ptst) {
                this.$confirm(
                    this.$t("zimbra.zmMsg.askToSave"),
                    this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                    {
                        confirmButtonText: this.$t("zimbra.zhMsg.yes"),
                        cancelButtonText: this.$t("zimbra.zhMsg.no"),
                        distinguishCancelAndClose: true,
                        type: "warning",
                    }
                )
                    .then(async () => {
                        this.sendCalendarInviteReplay({...this.modelData, invId: this.modelData.id, comp: this.activeAppointment.comp}, this.action, true).then(() => {
                            this.visable = false;
                            // Thực hiện tìm kiếm lại
                            this.$root.$emit("onSearchAppointmentRequest")
                        });
                    })
                    .catch((action) => {
                        if (action === 'cancel')
                        {
                            this.visable = false;
                        }
                    });
            } else {
                this.visable = false;
            }
        },

        /**
         * Hàm lấy icon trạng thái cuộc hẹn hiện tại
         */
        getInfoStatus() {
            let _icon = "";
            let _key = "";
            let _color = "";
            let _contentKey = "";
            switch(this.modelData.ptst) {
                case CAL_PARTICIPATION_STATUS.NEEDS_ACTION:
                    _icon = "fa-exclamation-triangle"
                    _key = "zimbra.zmMsg.needsAction"
                    _contentKey = "zimbra.zmMsg.ptstMsgNeedsAction"
                    break;
                case CAL_PARTICIPATION_STATUS.TENTATIVE:
                    _icon = "fa-question"
                    _key = "zimbra.zhMsg.apptPtstTE"
                    _contentKey = "zimbra.zmMsg.ptstMsgTentative"
                    _color = "grey"
                    break;
                case CAL_PARTICIPATION_STATUS.ACCEPT:
                    _icon = "fa-check"
                    _key = "zimbra.zhMsg.apptPtstAC"
                     _contentKey = "zimbra.zmMsg.ptstMsgAccepted"
                    _color = "green"
                    break;
                case CAL_PARTICIPATION_STATUS.DECLINED:
                    _icon = "fa-times"
                    _key = "zimbra.zhMsg.apptPtstDE"
                     _contentKey = "zimbra.zmMsg.ptstMsgDeclined"
                    _color = "red"
                    break;
                default:
                    _icon = "fa-check"
                    _key = "zimbra.zhMsg.apptPtstAC"
                     _contentKey = "zimbra.zmMsg.ptstMsgAccepted"
                    _color = "green"
                    break;
            }
            return {
                icon: _icon,
                label: this.$t(_key),
                content: this.$t(_contentKey),
                color: _color
            };
        },

        /**
         * Hàm xử lý khi thay đổi trạng thái cuộc hẹn
         */
        onReply(_status) {
            this.modelData.ptst = _status
            this.visibleOptionals = false
            switch(this.modelData.ptst) {
                case CAL_PARTICIPATION_STATUS.NEEDS_ACTION:
                    this.action = CAL_OPERATOR_REPLY_INV.NEED_ACTION;
                    break;
                case CAL_PARTICIPATION_STATUS.TENTATIVE:
                    this.action = CAL_OPERATOR_REPLY_INV.TENTATIVE;
                    break;
                case CAL_PARTICIPATION_STATUS.ACCEPT:
                    this.action = CAL_OPERATOR_REPLY_INV.ACCEPT;
                    break;
                case CAL_PARTICIPATION_STATUS.DECLINED:
                    this.action = CAL_OPERATOR_REPLY_INV.DECLINE;
                    break;
                default:
                    this.action = CAL_OPERATOR_REPLY_INV.ACCEPT;
                    break;
            }
        },

        /**
         * Tải về 1 file
         */
        downloadFile(part) {
            const url = `${this.$configZimbra.ENDPOINT.homeService}/~/?auth=co&disp=a&id=${this.modelData.id}&part=${part}`;
            window.open(url, '_blank');
        },

        /**
         * Kiểm tra loại file
         */
        isRFC822(file) {
            return file.ct === MESSAGE_CONTENT_TYPE.RFC822;
        },

        /**
         * Kiểm tra xem có phải file ảnh không
         */
        isImageFile(fileName) {
            const regex_filename = /.(jpg|jpeg|png|gif)$/i;
            return regex_filename.test(fileName);
        },
    },
};
</script>
<style scoped>
.float-right {
    float: right;
}
.border-bottom {
    border-bottom: 1px solid #ebedf3;;
}
.zm-action-item {
    color: #5d5fef;
}
.zm-action-item:hover {
    text-decoration: underline;
}
.zm-box-control-option li.text a {
    padding-left: 0px;
}
.fa-exclamation-triangle  {
    margin: 0px;
}
</style>
<style>
#appointment-detail .inv-value span a {
    color: black!important;
}
</style>
