<template>
    <div>
        <el-dialog
            class="zm-diablog-contact-group d-center"
            :title="$t('zimbra.zmMsg.AB_CONTACT_GROUP')"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :append-to-body="true"
            @close="onclose"
        >
            <el-form :model="form">
                <el-row class="pb-2" style="display: flex; justify-content: space-between;">
                    <el-col :span="12">
                        <el-row>
                            <el-col :span="16" class="y-center">
                                <base-input
                                    :placeholder="$t('zimbra.zhMsg.AB_GROUP_NAME')"
                                    :value="nameGroup"
                                    @handleInput="handleChangeNameGroup"
                                />
                                <base-button v-if="!isEdit"
                                    style="margin-left: 10px"
                                    class="btn-email primary"
                                    @click="handleSaveGroupContact"
                                >
                                    {{ $t("zimbra.zhMsg.actionSave") }}
                                </base-button>
                                <base-button v-else
                                    style="margin-left: 10px"
                                    class="btn-email primary"
                                    @click="handleModifyContact"
                                >
                                    {{ $t("zimbra.zhMsg.actionSave") }}
                                </base-button>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="12" style="display: flex; justify-content: flex-end; align-items: center">
                        <label style="margin-right: 6px;" class="label">{{ $t("zimbra.zhMsg.locationLabel") }}</label>
                        <el-button class="btn-email default" :disabled="(nameGroup != '' && (activeNodeContact.isLink || activeNodeContact.perm))" @click="handleShowChooseFolder">{{ renderFolderName }}</el-button>
                    </el-col>
                </el-row>
                <el-row class="zm-contact-group-body">
                    <el-col :span="11" >
                        <table class="table mb-0" style="border: 1px solid #dee2e6">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        {{  $t("zimbra.zmMsg.membersLabel")  }}
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <perfect-scrollbar class="table-address left">
                            <table class="table target">
                                <tbody>
                                    <div v-if="listEmailAddressSelected.length == 0" class="zm-title-notFound">
                                        <span>{{this.$t("zimbra.zhMsg.noResultsFound")}}</span>
                                    </div>
                                    <template v-else v-for="(row, idx) in listEmailAddressSelected">
                                        <div :key="idx"
                                            :class="['zimbra-cursor-pointer',
                                                'zm-row-email',
                                                isSelected(row, 'target') ? 'selected' : '']"
                                            @click.exact.prevent="
                                                handleSelectRow(row, 'target')
                                            "
                                            @click.ctrl="
                                                ctrlAddItemToList(row, 'target')
                                            "
                                            @click.shift="
                                                shiftAddItemToList(row, 'target')
                                            "
                                        >
                                            <div class="info">
                                                <div class="contact-image">
                                                    <img v-if="renderAvtUrl(row) != null" :src="renderAvtUrl(row)" />
                                                    <div v-else class="avt no-avt" />
                                                </div>
                                                <div class="info-block">
                                                    <h2 class="fullname">{{ row.p }}</h2>
                                                    <span class="email">{{ row.job }}</span>
                                                    <p class="email">{{ row.email }}</p>
                                                    <p class="email">{{ row.tel }}</p>
                                                    <i v-if="!row.id" class="fas fa-plus" @click="handleShowViewQuickContact(row, idx)"></i>
                                                </div>
                                            </div>
                                            <i class="fas fa-times ic-delete" @click="handleAction('delete', row)"></i>
                                        </div>
                                    </template>
                                </tbody>
                            </table>
                        </perfect-scrollbar>
                        <!-- </table> -->
                    </el-col>
                    <el-col :span="2" class="y-center pl-2 pr-2 w-120">
                        <div>
                            <div class="pb-2">
                            <base-button
                                    class="btn-email primary outline"

                                    @click="handleAction('addSelect')"
                                >
                                    <ArrowLeft />
                                    {{ $t("zimbra.zhMsg.add") }}
                                </base-button>
                            </div>
                            <div class="pb-2">
                                <base-button
                                    class="btn-email primary outline"
                                    style="width: 100%"
                                    @click="handleAction('addAll')"
                                >
                                    <ArrowLeft />
                                    {{ $t("zimbra.zmMsg.addAll") }}
                                </base-button>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="11">
                        <div class="form-select search-in-group-contract">
                            <form onsubmit="return false">
                                <div class="form-group">
                                    <label class="label">{{ $t("zimbra.zmMsg.findLabel") }}</label>
                                    <!-- Xu ly enter thi call ham search -->
                                    <base-input
                                        :value="keyword"
                                        @handleInput="handleChangeKeyword"
                                    />
                                    <el-button size="small" style="margin-left: 10px" class="btn-email default" type="default" native-type="submit" @click="handleSearch">
                                        {{ $t("zimbra.zhMsg.search") }}
                                    </el-button>
                                </div>
                                <div class="form-group">
                                    <label class="label">{{ $t("zimbra.zmMsg.searchIn") }}</label>
                                    <base-select
                                        displayField="label"
                                        valueField="value"
                                        popperClass="group-contact"
                                        :value="addressDatasource"
                                        :options="options"
                                        @change="handleChangeDatasource"
                                    />
                                </div>
                            </form>
                        </div>
                        <table class="table mb-0" style="border: 1px solid #dee2e6">
                            <thead>
                                <tr>
                                    <th scope="col" style="width: 50px;">
                                        <UserFrame />
                                    </th>
                                    <th scope="col" style="min-width: 80px;">
                                        {{  $t("zimbra.zhMsg.AB_FIELD_firstName")  }}
                                    </th>
                                    <th scope="col">
                                        {{  $t("zimbra.zhMsg.AB_FIELD_email")  }}
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <perfect-scrollbar class="table-address right">
                            <table class="table table-scroll">
                                <tbody>
                                    <template class="label-no-data" v-if="listDataCurrentPage.length === 0">
                                        <tr>
                                            <td><span class="center-block-td-table">{{this.$t("zimbra.zhMsg.noResultsFound")}}</span></td>
                                        </tr>
                                    </template>
                                    <template
                                        v-else-if="listDataCurrentPage.length > 0"
                                        v-for="(row, idx) in listDataCurrentPage"
                                    >
                                        <tr :key="idx"
                                            :class="['zimbra-cursor-pointer',
                                                'zm-row-email',
                                                isSelected(row, 'source') ? 'selected' : '']"
                                            @click.exact.prevent="
                                                handleSelectRow(row, 'source')
                                            "
                                            @click.ctrl="
                                                ctrlAddItemToList(row, 'source')
                                            "
                                            @click.shift="
                                                shiftAddItemToList(row, 'source')
                                            "
                                        >
                                            <th scope="row" v-if="addressDatasource === 'Option3' && !row.exp" style="width: 50px;">
                                                <UserFrame />
                                            </th>
                                            <th scope="row" v-else-if="addressDatasource !== 'Option3'" style="width: 50px;">
                                                <div v-if="row.dataGroup">
                                                    <UserGroup />
                                                </div>
                                                <div v-else>
                                                    <UserFrame />
                                                </div>
                                            </th>
                                            <th scope="row" v-else style="width: 50px;"></th>
                                            <td style="min-width: 80px;">{{ row.p }}</td>
                                            <td>{{ renderEmail(row) }}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </perfect-scrollbar>
                        <!-- <el-pagination
                            style="display: flex; justify-content: flex-end; padding: 4px 0"
                            background
                            layout="prev, next"
                            :page-size="pageSize"
                            :total="totalAddress"
                            @current-change="currentChange"
                            @prev-click="prevClick"
                            @next-click="nextClick"
                        >
                        </el-pagination> -->
                        <div class="pagination" style="display: flex; justify-content: flex-end; padding: 4px 0">
                            <base-button
                                :disabled="currentPage <= 1"
                                @click="prevClick"
                                class="btn-email default"
                            >
                                <i class="ic ic-back-square"></i>
                            </base-button>
                            <base-button
                                :disabled="disabledNextClick"
                                @click="nextClick"
                                class="btn-email default"
                            >
                                <i class="ic ic-forward-square"></i>
                            </base-button>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="y-center">
                    <el-col :span="11"><div style="visibility: hidden">No content</div></el-col>
                    <el-col :span="2" class="pl-2 pr-2 w-120">
                        <div class="pb-2">
                            <base-button
                                class="btn-email primary outline"
                                @click="handleAction('addOther')"
                                :disabled="addressInput.length == 0"
                            >
                                <ArrowLeft />
                                {{ $t("zimbra.zhMsg.add") }}
                            </base-button>
                        </div>
                    </el-col>
                    <el-col :span="11" class="addrbelow pl-2 pr-2">
                        <p>{{ $t("zimbra.zhMsg.enterAddrBelow") }}</p>
                        <base-input
                            type="textarea"
                            style="width: 100%"
                            :rows="2"
                            :value="addressInput"
                            @handleInput="handleChangeAddressInput"
                        />
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
        <el-dialog
            class="zm-diablog-quick-contact"
            :title="$t('zimbra.zmMsg.quickAddContact')"
            :visible.sync="showModalAddContact"
            :append-to-body="true"
            :before-close="handleCloseAddContact"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            @open="handleBeforeOpen"
        >
            <div class="contact-group">
                <div class="form-group">
                    <label class="label">{{ $t('zimbra.zhMsg.nameLabel') }}</label>
                    <base-input
                        :value="formContact.firstName"
                        @handleInput="handleInputFirstName"
                    />
                </div>
                <div class="form-group">
                    <label class="label">
                        {{ $t('zimbra.zmMsg.lastNameLabel') }}
                    </label>
                    <base-input
                        :value="formContact.lastName"
                        @handleInput="handleInputLastName"
                    />
                </div>
                <div class="form-group">
                    <label class="label">
                        {{ $t('zimbra.zmMsg.emailLabel') }}
                    </label>
                    <base-input
                        :value="formContact.email"
                        @handleInput="handleInputEmail"
                    />
                </div>
                <div class="form-group contacts-folder">
                    <label class="label">{{ $t('zimbra.zhMsg.addressBooksLabel') }}</label>
                    <base-select
                        popper-class="contacts-folder"
                        displayField="name"
                        valueField="id"
                        :value="formContact.folders"
                        :options="contactsFolder"
                        @change="handleChangeFolders"
                    />
                </div>
            </div>
            <span slot="footer">
                <base-button
                    class="btn-email primary"
                    @click="handleAddContact"
                >
                    {{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}
                </base-button>
                <base-button
                    class="btn-email default"
                    @click="handleCloseAddContact"
                >
                    {{ $t("zimbra.zhMsg.actionTaskCancelTT") }}
                </base-button>
            </span>
        </el-dialog>
        <!-- choose folder -->
        <DialogBasicFolder
            :show="showChooseFolder"
            @close="handleClose"
            @click="handleAccept"
        />
    </div>
</template>

<script>
import { FOLDER_DEFAULT, CONTACT_ACTION } from "@/utils/constants"
import ContactUtils from "@/utils/contact-utils";
import CONFIG from "@/config/config";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import ContactService from "@/services/contacts/ContactService.js";
import BaseInput from "@/components/modules/base/base-input"
import BaseButton from "@/components/modules/base/base-button/btn-default"
import BaseSelect from "@/components/modules/base/base-select";
import CommonUtils from "@/utils/common-utils";
import UserFrame from "@/components/modules/contacts/components/icons/UserFrame"
import UserGroup from "@/components/modules/contacts/components/icons/UserGroup"
import ArrowLeft from "@/components/modules/contacts/components/icons/ArrowLeft"
import DialogBasicFolder from "@/components/modules/contacts/components/layouts/DialogBasicFolder"
import { mapGetters, mapActions } from "vuex"
import _ from "lodash";
import $ from 'jquery';
export default {
    mixins: [CommonUtils, ContactUtils],
    components: {
        BaseInput,
        BaseButton,
        BaseSelect,
        UserFrame,
        ArrowLeft,
        UserGroup,
        DialogBasicFolder
    },
    // async created() {
    //     await this.searchGalRequest();
    // },
    mounted() {
        this.$root.$on("handleShowContactGroup",async (data) => {
            await this.show(data)
            if(this.listDataCurrentPage.length > 0 )
                this.handleSelectRow(this.listDataCurrentPage[0], 'source')
            })
        this.$nextTick(() => {
            document.addEventListener("keydown", this.handleKeySaveGroup);
        })
    },
    destroyed() {
        document.removeEventListener("keydown", this.handleKeySaveGroup)

    },
    data() {
        return {
            CONTACT_ACTION: CONTACT_ACTION,
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            dialogVisible: false,
            showModalAddContact: false,
            nameGroup: "",
            keyword: "",
            form: {},
            formContact: {
                index: null,
                firstName: "",
                lastName: "",
                email: "",
                folders: 7
            },
            isEdit: false,
            listEmailAddress: [],
            listEmailAddressSelected: [],
            listDataCurrentPage: [],
            selectedRowsSource: [],
            selectedRowsTarget: [],
            currentPage: 1,
            totalPages: 0,
            currentPageSelected: 1,
            totalPagesSelected: 0,
            totalAddress: 0,
            pageSize: 50,
            isMore: false,
            idGroup: "",
            extraType: '',
            options: [
                {
                    value: "Option1",
                    label: this.$t("zimbra.zhMsg.addressBook"),
                },
                {
                    value: "Option2",
                    label: this.$t("zimbra.zmMsg.searchPersonalSharedContacts"),
                },
                {
                    value: "Option3",
                    label: this.$t("zimbra.zhMsg.GAL"),
                },
            ],
            contactsFolder: [],
            addressDatasource: "Option3",
            addressInput: "",
            listRemoveContact: [],
            listContactCurrent: [],
            listContactAffter: [],
            dataCurrentGroup: [],
            showChooseFolder: false,
            folderCurrent: null,
            idFolderCurrent: null,
            externalContact: null,
            roles: [
                "rwidx",
                "rwidxp",
                "rwidxa",
                "rwidxap"
            ]
        }
    },
    computed: {
        ...mapGetters([
            "contacts",
            "contactGroup",
            "localeCurrent",
            "timeZoneCurrent",
            "activeNodeContact",
            "dataSidebarContact",
            "listSelectedContact",
            "zimbraPrefGalSearchEnabled"
        ]),
        renderFolderName() {
            return this.folderCurrent && this.folderCurrent.name ? this.folderCurrent.name : this.$t("zimbra.zhMsg.addressBook")
        },
        disabledNextClick() {
            if (this.addressDatasource === "Option3") {
                return this.totalAddress === this.listDataCurrentPage.length * this.currentPage
            }
            return !this.isMore
        }
    },
    watch: {
        dataSidebarContact: {
            deep: true,
            handler(newData) {
                this.renderListFolderContact(newData)
            }
        },
        // listEmailAddress: {
        //     deep: true,
        //     handler(newData) {
        //         this.listDataCurrentPage = newData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
        //         console.log(this.listDataCurrentPage);
        //     }
        // },
        // currentPage: {
        //     deep: true,
        //     handler(page) {
        //         this.listDataCurrentPage = this.listEmailAddress.slice((page - 1) * this.pageSize, page * this.pageSize);
        //     }
        // }
    },
    methods: {
        ...mapActions([
            "addContactRequest",
        ]),
        handleKeySaveGroup(e) {
            if (e.which == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && this.dialogVisible) {
                e.preventDefault();
                if (!this.isEdit) {
                    this.handleSaveGroupContact();
                } else {
                    this.handleModifyContact();
                }
                return false;
            }
        },
        getFlatten(parent) {
            const _list = []
            parent.forEach(element => {
                if (Array.isArray(element.folder)) {
                    this.getFlatten(element.folder)
                } else {
                    _list.push(element.folder)
                }
            })
            return _list
        },
        objectToArr(data) {
            for (const key in data) {
                data[key].folder = data[key].folder || [];
                if (!data[key].folder || !Array.isArray(data[key].folder)) {
                    data[key].folder = data[key].folder ? [data[key].folder] : []
                }
                if (data[key].folder.length > 0) {
                    data[key].folder = data[key].folder.filter(x => x);
                    this.objectToArr(data[key].folder);
                }
            }
        },
        lamPhangMang(parent) {
            let _list = [];
            parent.forEach(ele => {
                _list.push(ele)
                if (ele.folder) {
                    _list = _list.concat(...this.lamPhangMang(ele.folder))
                }
            })
            return _list
        },
        handleBeforeOpen() {
            this.renderListFolderContact(this.dataSidebarContact)
        },
        renderListFolderContact(parent) {
            let _listFolder = _.cloneDeep(parent[0].folder)
            _listFolder = _.orderBy(_listFolder, "id", "asc");
            const _temp = this.lamPhangMang(_listFolder)
            this.contactsFolder = _temp.filter(x => x.id != FOLDER_DEFAULT.TRASH && (x.view === "contact" && this.roles.indexOf(x.perm)))
        },
        async show(data) {
            await this.getFolderContactApi()
            this.addressDatasource = this.zimbraPrefGalSearchEnabled?.content ? this.options[2].value : this.options[0].value
            this.dataCurrentGroup = [];
            this.listEmailAddressSelected = [];
            this.nameGroup = "";
            this.addressInput = "";
            this.keyword = ""
            this.isEdit = false;
            this.listContactCurrent = [];
            this.listRemoveContact = [];
            this.listContactAffter = [];
            this.folderCurrent = this.activeNodeContact && this.activeNodeContact.id !== FOLDER_DEFAULT.MAILING_LIST ? this.activeNodeContact : this.dataSidebarContact.find(folder => folder.id === FOLDER_DEFAULT.CONTACTS)
            if (data.m) {
                // this.folderCurrent = this.dataSidebarContact[0].folder.find(x => x.id === data.l)
                this.idFolderCurrent = this.folderCurrent.id
                this.dataCurrentGroup = _.cloneDeep(data);
                Array.isArray(this.dataCurrentGroup.m) ? this.dataCurrentGroup.m : [this.dataCurrentGroup.m]
                this.idGroup = data.id;
                this.isEdit = true;
                const _temp = [];
                const _dataContact = Array.isArray(data.m) ? data.m : [data.m];
                _dataContact.map(e => {
                    if (e.type == "I") {
                        _temp.push(e)
                    } else {
                        [e].forEach(y => {
                            _temp.push({
                                cn: y?.cn,
                                type: y?.type
                            })
                        })
                    }
                });
                let _data = [];
                _temp.forEach(item => {
                    if (item.cn) {
                        const listA = Array.isArray(item.cn.a) ? item.cn.a : [item.cn.a]
                        const a = listA.find(attr => attr.n == "email")
                        const fir = listA.find(attr => attr.n == "firstName")?.content || ""
                        const last = listA.find(attr => attr.n == "lastName")?.content || ""
                        const _phone = listA.find(attr => attr.n == "mobilePhone")?.content || ""
                        const _job = listA.find(attr => attr.n == "jobTitle")?.content || ""
                        const _p = fir + " " + last
                        _data.push({
                            ...item.cn,
                            email: a ? a.content : "",
                            p: _p,
                            id: item.cn.id,
                            tel: _phone,
                            job: _job,
                            type: item.type
                        })
                    } else {
                        _data.push({
                            email: item.value,
                            value: item.value,
                            type: item.type,
                            isUpdate: true,
                        })
                    }
                })
                _data = _.orderBy(_data, ["a", "p"], ["asc", "asc"])
                this.listContactCurrent = _.cloneDeep(_data);
                this.listEmailAddressSelected = _data;
                this.nameGroup = data.fileAsStr
            }
            this.dialogVisible = true
            switch (this.addressDatasource) {
                case "Option1":
                    await this.searchRequest();
                    break;
                case "Option2":
                    await this.searchRequest();
                    break;
                case "Option3":
                    await this.searchGalRequest();
                    break;
                default:
                    break;
            }
            this.renderListFolderContact(this.dataSidebarContact)
            $(document).ready(function () {
                setTimeout(function () {
                    const divContractGroup = $(".zm-diablog-contact-group");
                    const firstInput = $(":input:not(input[type=button],input[type=submit],button):visible:first", divContractGroup);
                    firstInput.focus();
                }, 500);
            });
        },
        async searchGalRequest(name) {
            const formData = {
                limit: 50,
                locale: this.localeCurrent.content,
                name: name || "",
                needExp: 1,
                needIsMember: "directOnly",
                needIsOwner: "1",
                offset: (this.currentPage - 1) * 50 || 0,
                type: "account",
                sortBy: "nameAsc",
            };
            const searchRequest = await ZimbraMailService.searchGalRequest(
                formData
            );
            const searchGalResponse =
                this.getResponseBody(searchRequest)["SearchGalResponse"];
            const _list = Array.isArray(searchGalResponse.cn) ? searchGalResponse.cn : [searchGalResponse.cn]
            this.isMore = searchGalResponse["more"] || false
            this.listEmailAddress = _list.map((e, idx) => {
                const fullName = e.a.find((x) => x.n == "fullName");
                const email = e.a.find((x) => x.n == "email");
                const type = e.a.find((x) => x.n == "type")
                return {
                    ...e,
                    type: type?.content == "group" || type == undefined ? "G" : "C" || "C",
                    email: email.content, // email
                    p: fullName.content || this.$t("zimbra.zhMsg.noName"), // fullname
                    index: idx,
                };
            });
            this.listEmailAddress = this.listEmailAddress.sort((a, b) => a.p.toUpperCase().localeCompare(b.p.toUpperCase()))
            this.totalAddress = this.listEmailAddress.length;
            this.listDataCurrentPage = this.listEmailAddress.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
        },
        async searchRequest() {
            this.listDataCurrentPage = [];
            this.keyword = this.keyword.trim()
            if (this.addressDatasource == "Option3") {
                await this.searchGalRequest(this.keyword);
                return;
            }
            const formData = {
                field: "contact",
                limit: 50,
                locale: {
                    content: this.localeCurrent.content
                },
                needExp: 1,
                offset: (this.currentPage - 1) * 50 || 0,
                query: `${this.keyword ? "(" + this.keyword + ")" : ""
                    }(is:local)`,
                sortBy: "nameAsc",
                types: "contact",
                tz: {
                    id: this.timeZoneCurrent.content
                },
            }
            if (this.addressDatasource == "Option2") {
                const _listFolderShare = this.contactsFolder.filter(folder => folder.isLink !== undefined)
                formData["query"] = `(${_listFolderShare.map(folder => `inid:"${folder.id}" OR `).join("")}is:local)`
            }
            const searchRequest = await ZimbraMailService.searchRequest(
                formData
            );
            const searchResponse =
                this.getResponseBody(searchRequest)["SearchResponse"];
            const response = Array.isArray(searchResponse.cn)
                ? searchResponse.cn
                : searchResponse.cn
                    ? [searchResponse.cn]
                    : [];
            if (response) {
                this.isMore = searchResponse["more"]
                const _dataContact = this.exchangContacts(response, this.contactGroup);
                let _listContact = [];
                _dataContact.forEach((e, index) => {
                    const _a = Array.isArray(e.a) ? e.a : [e.a]
                    const type = _a.find((x) => x.n == "type")
                    const fullName = e.fileAsStr;
                    let email = [];
                    const dataGroup = [];
                    if (e.m) {
                        const _temp = Array.isArray(e.m) ? e.m : [e.m];
                        _temp.forEach(x => {
                            if (x.cn) {
                                [x.cn].forEach(y => {
                                    const _listA = Array.isArray(y.a) ? y.a : [y.a];
                                    email = _listA.find(z => z.n == "email");
                                    const firstName = _listA.find(z => z.n == "firstName")?.content || "";
                                    const lastName = _listA.find(z => z.n == "lastName")?.content || "";
                                    // const fullName = _listA.find(z => z.n == "fullName")?.content;
                                    const fullN = lastName + " " + firstName
                                    // const img = _listA.find(z => z.n == "image")
                                    const _group = {
                                        ...y,
                                        type: x.type,
                                        p: fullN || this.$t("zimbra.zhMsg.noName"),
                                        email: email ? email?.content : "",
                                        // ...img,
                                        index: index
                                    };
                                    [_group].map(ele => {
                                        dataGroup.push(ele)
                                    })
                                })
                            }
                        });
                        _listContact.push({
                            p: e.fileAsStr || this.$t("zimbra.zhMsg.noName"),
                            id: e.id,
                            dataGroup
                        })
                    } else {
                        email = _a.find((x) => x.n == "email");
                        _listContact.push({
                            ...e,
                            id: e.id,
                            type: type?.content == "group" ? "G" : "C" || "C",
                            p: fullName ? fullName : this.$t("zimbra.zhMsg.noName"), // fullname
                            email: email ? email.content : "",
                            index: index
                        });
                    }
                });
                // this.listEmailAddress = this.listEmailAddress.concat(_listContact)
                this.totalAddress = _listContact.length;
                this.listEmailAddress = _listContact
                this.listDataCurrentPage = _listContact
                this.selectedRowsSource = [];
            } else {
                this.totalAddress = 0;
                this.listEmailAddress = [];
            }
        },
        async handleChangeDatasource(option) {
            this.addressDatasource = option
            this.listEmailAddress = []
            this.currentPage = 1
            if (option == "Option3") {
                await this.searchGalRequest();
                if(this.listDataCurrentPage.length > 0 )
                    this.handleSelectRow(this.listDataCurrentPage[0], 'source')
                return;
            }
            await this.searchRequest();
            if(this.listDataCurrentPage.length > 0 )
                this.handleSelectRow(this.listDataCurrentPage[0], 'source')
        },
        onclose() {
            this.dialogFormVisible = false;
            this.selectedRowsSource = [];
        },
        handleChangeNameGroup(val) {
            this.nameGroup = val
        },
        handleSelected() {
            this.$emit("onselected");
            this.dialogFormVisible = false;
        },
        handleSelectRow(row, type) {
            if (type === "source") {
                const _idx = this.selectedRowsSource.findIndex(e => e.email == row.email && e.id == row.id);
                if (_idx > -1) {
                    this.selectedRowsSource.splice(_idx, 1);
                } else {
                    this.selectedRowsSource = [row];
                }
            } else {
                const _idx = this.selectedRowsTarget.findIndex(e => e.email == row.email && e.id == row.id);
                if (_idx > -1) {
                    this.selectedRowsTarget.splice(_idx, 1);
                } else {
                    this.selectedRowsTarget = [row];
                }
            }
        },
        ctrlAddItemToList(row, type) {
            if (type === "source") {
                const idx = this.selectedRowsSource.findIndex(
                    (e) => e.email == row.email && e.id == row.id
                );
                if (idx > -1) {
                    this.selectedRowsSource.splice(idx, 1);
                } else {
                    this.selectedRowsSource.push(row);
                }
            } else {
                const idx = this.selectedRowsTarget.findIndex(
                    (e) => e.email == row.email && e.id == row.id
                );
                if (idx > -1) {
                    this.selectedRowsTarget.splice(idx, 1);
                } else {
                    this.selectedRowsTarget.push(row);
                }
            }
        },
        shiftAddItemToList(row, type) {
            if (type === "source") {
                let indexs = row.index;
                if (this.selectedRowsSource.length === 0) {
                    return;
                }
                this.selectedRowsSource = _.orderBy(
                    this.selectedRowsSource,
                    ["index"],
                    ["asc"]
                );
                let firstIndex = this.selectedRowsSource[0].index;
                if (firstIndex > row.index) {
                    firstIndex = [indexs, (indexs = firstIndex)][0];
                }
                this.selectedRowsSource = this.listEmailAddress.filter(
                    (e, idx) => idx >= firstIndex && idx <= indexs
                );
            } else {
                let indexs = row.index;
                if (this.selectedRowsTarget.length === 0) {
                    return;
                }
                this.selectedRowsTarget = _.orderBy(
                    this.selectedRowsTarget,
                    ["index"],
                    ["asc"]
                );
                let firstIndex = this.selectedRowsTarget[0].index;
                if (firstIndex > row.index) {
                    firstIndex = [indexs, (indexs = firstIndex)][0];
                }
                this.selectedRowsTarget = this.listEmailAddressSelected.filter(
                    (e, idx) => idx >= firstIndex && idx <= indexs
                );
            }
        },
        isSelected(row, type) {
            if (type === "source") {
                const _idx = this.selectedRowsSource.findIndex(e => e.email == row.email && row.id == e.id);
                return _idx > -1
            } else {
                const _idx = this.selectedRowsTarget.findIndex(e => e.email == row.email && row.id == e.id);
                return _idx > -1
            }
        },
        handleChangeKeyword(keyword) {
            this.keyword = keyword
        },
        async handleSearch() {
            this.currentPage = 1;
            await this.searchRequest();
            return false;
        },
        handleAction(action, target) {
            if (action === "delete") {
                if (target) {
                    const _data = this.listContactCurrent.find(attr => attr.email == target.email && attr.id == target.id);
                    if (_data !== undefined) {
                        this.listRemoveContact.push(_data)
                    }
                    const _index = this.listEmailAddressSelected.findIndex(attr => attr.email == target.email && attr.id == target.id)
                    if (_index > -1) {
                        const _dataRemote = this.listEmailAddressSelected.splice(_index, 1);
                        this.listContactAffter = this.listContactAffter.filter(item => item !== _dataRemote[0])
                        this.selectedRowsTarget = []
                        return
                    }
                }
            } else {
                let _list = []
                switch (action) {
                    case "addSelect":
                        this.selectedRowsSource.forEach(x => {
                            if (x.dataGroup) {
                                x.dataGroup.map(y => {
                                    _list.push(y)
                                })
                            } else {
                                _list.push(x)
                            }
                        })
                        break
                    case "addAll":
                        this.listDataCurrentPage.forEach(x => {
                            if (x.dataGroup) {
                                x.dataGroup.map(y => {
                                    _list.push(y)
                                })
                            } else {
                                _list.push(x)
                            }
                        })
                        break;
                    case "addOther":
                        this.addressInput.split(',').forEach((ele, index) => {
                            const _a = ele.toString().trim()
                            if (_a) {
                                _list.push({
                                    email: _a,
                                    type: "I",
                                    index: index
                                })
                            }
                        })
                        break;
                    default:
                        break;
                }
                _list.forEach(attr => {

                    const exists = this.listEmailAddressSelected.find(
                        (x) => x.email == attr.email && x.id == attr.id
                    );
                    if (!exists) {
                        if (action === "addOther") {
                            this.listEmailAddressSelected.unshift(attr);
                            this.listContactAffter.unshift(attr);
                            this.addressInput = ""
                        }
                        else {
                            if (attr.dataGroup) {
                                const _attr = [];
                                attr.dataGroup.forEach(e => {
                                    _attr.push({
                                        ...e
                                    })
                                })
                                _attr.forEach(item => {
                                    this.listEmailAddressSelected.push(item);
                                    this.listContactAffter.push(item);
                                })
                            } else {
                                this.listEmailAddressSelected.push(attr);
                                this.listContactAffter.push(attr);
                            }
                        }
                    }
                })
                this.listEmailAddressSelected = _.orderBy(this.listEmailAddressSelected, "index", "asc")
            }
        },
        handleChangeAddressInput(keyword) {
            this.addressInput = keyword;
        },
        async prevClick() {
            if (this.currentPage > 1) this.currentPage = this.currentPage - 1;
            if (this.addressDatasource !== "Option3") await this.searchRequest()
            else this.paginationFromCache()
            this.selectedRowsSource = [];
            this.resetScrollbar();
        },
        async nextClick() {
            if (this.isMore) this.currentPage = this.currentPage + 1;
            if (this.addressDatasource !== "Option3") await this.searchRequest()
            else this.paginationFromCache()
            this.selectedRowsSource = [];
            this.resetScrollbar();
        },
        paginationFromCache() {
            this.listDataCurrentPage = this.listEmailAddress.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
        },
        // Reset scrollbar về mặc định
        resetScrollbar() {
            $(document).ready(function () {
                $('.table-address').scrollTop(0);
            });
        },
        handleShowViewQuickContact(row, idx) {
            this.externalContact = row;
            this.showModalAddContact = true;
            this.formContact.email = row.email
            this.formContact.index = idx
            this.formContact.folders = this.contactsFolder[0].id
        },
        handleCloseAddContact() {
            this.showModalAddContact = false
            this.resetForm()
        },
        handleInputFirstName(val) {
            this.formContact.firstName = val
        },
        handleInputLastName(val) {
            this.formContact.lastName = val
        },
        handleInputEmail(val) {
            this.formContact.email = val
        },
        handleChangeFolders(val) {
            this.formContact.folders = val
        },
        validateBeforeCallAPI() {
            if (!this.nameGroup)
                return false
            else if (!this.listEmailAddressSelected.length)
                return false
            return true
        },
        async handleAddContact() {
            const formData = {
                a: [
                    {
                        n: "firstName",
                        content: this.formContact.firstName
                    },
                    {
                        n: "lastName",
                        content: this.formContact.lastName
                    },
                    {
                        n: "email",
                        content: this.formContact.email
                    },
                    {
                        n: "fullName",
                        content: this.formContact.lastName.concat(", ", this.formContact.firstName)
                    }
                ],
                l: this.formContact.folders,
                m: []
            }
            await this.addContactRequest(formData).then(resp => {
                const response = this.getResponseBody(resp)
                if (response["CreateContactResponse"]) {
                    const _attr = Array.isArray(response["CreateContactResponse"]["cn"]) ?
                        response["CreateContactResponse"]["cn"] : [response["CreateContactResponse"]["cn"]]
                    const _list = _attr.map(ele => {
                        const _a = Array.isArray(ele.a) ? ele.a : [ele.a]
                        const fullName = ele.fileAsStr;
                        const email = _a.find((x) => x.n == "email");
                        return {
                            ...ele,
                            id: ele.id,
                            type: "C",
                            p: fullName ? fullName : "", // fullname
                            email: email ? email.content : "", // email
                        };
                    })
                    this.listContactAffter = this.listContactAffter.filter(e => e !== this.externalContact);
                    this.listEmailAddressSelected[this.formContact.index] = _list[0]
                    this.listRemoveContact.filter(e => e !== this.externalContact)
                    this.listContactAffter.filter(e => e !== this.externalContact);
                    if (this.externalContact.isUpdate) {
                        this.listRemoveContact.push(this.externalContact)
                    }
                    this.listEmailAddressSelected = _.orderBy(this.listEmailAddressSelected, "email", "asc");
                    this.listContactAffter.push(_list[0]);
                    this.handleCloseAddContact()
                }
            }).catch(err => console.log(err))
        },
        async handleSaveGroupContact() {
            if (!this.validateBeforeCallAPI()) {
                this.$confirm(
                    this.$t("zimbra.zmMsg.errorSavingWithMessage", { 0: this.$t("zimbra.zmMsg.errorMissingGroup") }),
                    this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                    {
                        customClass: "zm-popover-message",
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        showCancelButton: false,
                        type: "error",
                        dangerouslyUseHTMLString: true,
                    }
                );
                return
            }
            const formData = {
                a: [
                    {
                        n: "fileAs",
                        content: `8:${this.nameGroup}`
                    },
                    {
                        n: "nickname",
                        content: this.nameGroup
                    },
                    {
                        n: "type",
                        content: "group"
                    },
                    {
                        n: "fullName",
                        content: this.nameGroup
                    }
                ],
                l: this.folderCurrent.id,
                m: this.listEmailAddressSelected.map(ele => {
                    if (ele.type === "I") {
                        return {
                            type: ele.type,
                            value: ele.email
                        }
                    }
                    return {
                        type: ele.type,
                        value: ele.id
                    }
                })
            };
            await ContactService.createContactRequest(formData).then(resp => {
                const response = this.getResponseBody(resp)
                const _data = response["CreateContactResponse"]["cn"]
                if (_data) {
                    const mOfResponse = Array.isArray(_data.m) ? _data.m : [_data.m]
                    for (let index = 0; index < mOfResponse.length; index++) {
                        for (let idx = 0; idx < this.listEmailAddressSelected.length; idx++) {
                            if (mOfResponse[index].value == this.listEmailAddressSelected[idx].id) {
                                mOfResponse[index].cn = this.listEmailAddressSelected[idx]
                            }
                        }
                    }
                    _data["m"] = mOfResponse
                    // this.$store.commit("ADD_CONTACT", _data)
                    const listContactAlphabet = _.cloneDeep(this.contacts);
                    listContactAlphabet.push(_data);
                    const contactAlphabet = this.convertAlphabet(listContactAlphabet)
                    this.$store.commit("SET_CONTACTALPHABET", contactAlphabet)
                    // this.$store.commit("ADD_CONTACTALPHABET", _data)
                    this.$store.commit("ADD_CONTACT_GROUP", _data)
                    if (this.activeNodeContact.id === this.folderCurrent.id) {
                        setTimeout(() => {
                            this.$store.commit("SET_CONTACTACTION", CONTACT_ACTION.VIEW_CONTACT_GROUP_LIST)
                            this.$store.commit("SET_LIST_SELECTED_CONTACT", [_data]);
                        }, 150)
                    }
                    this.dialogVisible = false
                    this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                        message: this.$t(
                            "zimbra.zmMsg.groupCreated"
                        ),
                        type: "success",
                    });
                }
            }).catch(err => console.log(err))
        },
        encodedStr(unsafe) {
            return unsafe
                .replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#039;");
        },
        resetForm() {
            this.formContact = {
                firstName: "",
                lastName: "",
                email: "",
                folders: "",
            }
        },
        async handleModifyContact() {
            if (!this.validateBeforeCallAPI()) {
                this.$confirm(
                    this.$t("zimbra.zmMsg.errorSavingWithMessage", { 0: this.$t("zimbra.zmMsg.errorMissingGroup") }),
                    this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                    {
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        showCancelButton: false,
                        type: "error",
                        dangerouslyUseHTMLString: true,
                    }
                );
                return
            }
            const cn = {};
            cn.id = this.idGroup;
            cn.a = [
                {
                    n: "fileAs",
                    content: `8:${this.nameGroup}`
                },
                {
                    n: "nickname",
                    content: `${this.nameGroup}`
                },
                {
                    n: "fullname",
                    content: `${this.nameGroup}`
                }
            ];
            cn.m = [];
            if (this.listRemoveContact.length) {
                this.listRemoveContact.forEach(e => {
                    if (e.type == "I") {
                        cn.m.push({
                            type: `${e.type}`,
                            value: `${e.email}`,
                            op: "-"
                        })
                    }
                    if (e.type !== "I") {
                        cn.m.push({
                            type: `${e.type}`,
                            value: `${e.id}`,
                            op: "-"
                        })
                    }
                })
            }
            if (this.listContactAffter.length) {
                this.listContactAffter.forEach(e => {
                    if (e.type == "I") {
                        cn.m.push({
                            type: `${e.type}`,
                            value: `${e.email}`,
                            op: "+"
                        })
                    }
                    if (e.type !== "I") {
                        cn.m.push({
                            type: `${e.type}`,
                            value: `${e.id}`,
                            op: "+"
                        })
                    }
                });
            }
            await ContactService.modifyContactRequest(cn).then(res => {
                const resp = this.getResponseBody((res));
                const response = resp["ModifyContactResponse"]["cn"]
                if (response) {
                    let contactGroup = this.contacts.find(contact => contact.id == response.id)
                    contactGroup["m"] = contactGroup["m"].concat(
                        this.listContactAffter.map(contact =>
                            ({ cn: contact, value: contact.id })));
                    let mOfResponse = Array.isArray(response["m"]) ? response["m"] : [response["m"]];
                    for (let x = 0; x < mOfResponse.length; x++) {
                        for (let y = 0; y < contactGroup["m"].length; y++) {
                            const temp = contactGroup["m"][y]
                            if (mOfResponse[x].value == temp.value) {
                                mOfResponse[x] = {
                                    ...mOfResponse[x],
                                    cn: temp.cn
                                }
                            }
                        }
                    }
                    contactGroup = response;
                    mOfResponse = mOfResponse.filter(e => e.cn || e.type == "I")
                    contactGroup["m"] = mOfResponse;
                    let listContact = _.clone(this.contacts)
                    const index = listContact.findIndex(contact => contact.id == contactGroup.id)
                    if (index !== -1) listContact[index] = contactGroup
                    listContact = listContact.map((contact, index) => ({ ...contact, index: index }))
                    const contactAlphabet = this.convertAlphabet(listContact)
                    this.$store.commit("SET_CONTACTS", listContact)
                    this.$store.commit("SET_CONTACTALPHABET", contactAlphabet)
                    this.$store.commit("SET_LIST_SELECTED_CONTACT", [contactGroup]);
                    this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                        message: this.$t(
                            "zimbra.zmMsg.groupSaved"
                        ),
                        type: "success",
                    });
                    if (this.folderCurrent.id !== this.idFolderCurrent) {
                        this.handleMoveGroupContact()
                    }
                    this.dialogVisible = false;
                }
            }).catch()
        },
        async handleMoveGroupContact() {
            const formData = {
                id: `${this.idGroup}`,
                l: `${this.folderCurrent.id}`,
                op: "move"
            };
            await ContactService.contactActionRequest(formData).then(res => {
                if (res.status == 200) {
                    this.$notify({
                        dangerouslyUseHTMLString: true,
                        message: `<div> ${this.$t("zimbra.zmMsg.actionMove", [1, this.$t("zimbraNokey.zimbra_contacts_contact"), this.folderCurrent.name])}</div>`,
                        position: "bottom-left",
                        customClass: "notify-wrap",
                        duration: 4000,
                    });
                    const length = this.contacts.length
                    const idx = this.contacts.findIndex(e => e.id == this.idGroup)
                    if (idx == length - 1) {
                        setTimeout(() => {
                            this.$store.commit("SET_LIST_SELECTED_CONTACT", [])
                            this.$store.commit("SET_ACTIVECONTACT", this.contacts[length - 1])
                        }, 150)
                    } else {
                        setTimeout(() => {
                            this.$store.commit("SET_LIST_SELECTED_CONTACT", [])
                            this.$store.commit("SET_ACTIVECONTACT", this.contacts[idx])
                        }, 150)

                    }
                    this.$store.commit("DELETE_CONTACT", this.idGroup)
                }
            })
        },
        exchangContacts(contact, contactGroup) {
            for (let index = 0; index < contactGroup.length; index++) {
                for (let idx = 0; idx < contact.length; idx++) {
                    if (contactGroup[index].id == contact[idx].id) {
                        contact[idx] = contactGroup[index]
                    }
                }
            }
            return contact;
        },
        renderEmail(row) {
            let email = "";
            if (row.dataGroup) {
                row.dataGroup.forEach(e => {
                    email += ` "${e.p}" ${e.email ? `<${e.email}>` : ""}; `
                })
            } else {
                email = row.email || ""
            }
            return email;
        },
        renderAvtUrl(data) {
            if (data && data.a) {
                const img = Array.isArray(data.a) ? data.a : [data.a]
                const url = img.find(ele => ele.n === "image")
                return url ? `${CONFIG.ENDPOINT["homeService"]}/~/?auth=co&id=${data.id}&part=1&max_width=48&max_height=48&t=${new Date().getTime()}` : null;
            }
            return null
        },
        handleClose(status) {
            this.showChooseFolder = status
        },
        handleAccept(folder) {
            if (this.folderCurrent.id != folder.id) {
                this.folderCurrent = folder
            }
            this.$emit("handleUpdateLocations", this.folderCurrent)
        },
        handleShowChooseFolder() {
            this.showChooseFolder = true
            if (this.folderCurrent) {
                this.$root.$emit("setCurrentKeyTreeContact", this.folderCurrent.id)
            }
        },
    }
}
</script>

<style>

</style>
