<template>
    <el-dialog
        :title="$t('zimbra.zmMsg.activityStream')"
        class="form-activity"
        :before-close="handleBeforeCloseActivity"
        :visible.sync="dialogFormVisible"
    >
        <div class="title-actitity">
            <label class="label-control">
                {{$t("zimbra.zmMsg.enableActivityStream")}}
            </label>
            <el-switch
                v-model="isActiveStream"
                @change="handleChangeStatus"
            >
            </el-switch>
        </div>
        <div>
            <span>
                {{$t("zimbra.zmMsg.priorityFilterDescription")}}
            </span>
        </div>
        <div v-if="isActiveStream">
            <div class="form-controls checkbox-activity-stream">
                <div class="checkbox-form">
                    <base-checkbox
                        :option="data.avtivityStream[0].name"
                        @change="handleChangeMassMaketing"
                        :value="isListTestCurrent"
                    />
                    <base-checkbox
                        :option="data.avtivityStream[1].name"
                        @change="handleChangeDlSubscribed"
                        :value="isBulkTestCurrent"
                    />
                    <div class="checkbox-select-form">
                        <base-checkbox
                            :option="data.avtivityStream[2].name"
                            @change="handleChangeNotToMe"
                            :value="isMeTestCurrent.negative"
                        />
                        <base-select
                            displayField="name"
                            valueField="header"
                            class="select-wrap__130"
                            :value="isMeTestCurrent.header"
                            :options="data.zWidgetTitle"
                            @change="handleChangeSelectField"
                        />
                    </div>
                    <base-checkbox
                        :option="data.avtivityStream[3].name"
                        @change="handleChangeNotInAddr"
                        :value="isAddressBookCurrent"
                    />
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <div>
                <base-button-default
                    class="btn-email primary outline cover-size"
                    @click="handleShowDialogActivity"
                >
                    {{$t("zimbra.zmMsg.advancedControls")}}
                </base-button-default>
            </div>
            <div>
                <base-button-default
                    class="btn-email default cover-size"
                    @click="handleCancelActivity"
                    >
                    {{$t("zimbra.zhMsg.actionTaskCancelTT")}}
                </base-button-default>
                <base-button-default
                    class="btn-email primary cover-size"
                    @click="handleAddActivitySteam"
                    >
                    {{this.$t("zimbra.zhMsg.actionSave")}}
                </base-button-default>
            </div>
        </span>
        <div>
        </div>
    </el-dialog>
</template>

<script>
import FilterService from "@/services/FilterService";
import BaseCheckbox from "@/components/modules/base/base-checkbox";
import BaseSelect from "@/components/modules/base/base-select";
import BaseButtonDefault from "@/components/modules/base/base-button/btn-default";
import { mapGetters, mapActions } from "vuex";
import CommonUtils from "@/utils/common-utils";
import {
    FILTER_ACTION_TYPE,
    FILTER_ACTION,
    FILTER_TESTS,
    FILTER_TEST_CONFIG,
} from "@/utils/constants";
export default {
    data() {
        return {
            data: {
                avtivityStream: [
                    {
                        id: 1,
                        name: this.$t("zimbra.zmMsg.moveMessagesFromDL"),
                        bulkTest: [{}],
                    },
                    {
                        id: 2,
                        name: this.$t("zimbra.zmMsg.massMarketingMessages"),
                        listTest: [{}],
                    },
                    {
                        id: 3,
                        name: this.$t("zimbra.zmMsg.moveNotToMe"),
                        meTest: [{negative: "1"},],
                    },
                    {
                        id: 4,
                        name: this.$t("zimbra.zmMsg.moveNotInAddrBook"),
                        addressBookTest: [
                            {
                                header: "from",
                                negative: "1",
                                type: "contacts",
                            },
                        ],
                    },
                ],
                zWidgetTitle: [
                    {
                        id: 1,
                        name: this.$t("zimbra.zhMsg.FILT_COND_HEADER_to"),
                        header: "to",
                    },
                    {
                        id: 2,
                        name: this.$t("zimbra.zhMsg.FILT_COND_HEADER_to,cc"),
                        header: "to,cc",
                    },
                ],
            },
            FILTER_ACTION_TYPE: FILTER_ACTION_TYPE,
            FILTER_ACTION: FILTER_ACTION,
            FILTER_TESTS: FILTER_TESTS,
            FILTER_TEST_CONFIG: FILTER_TEST_CONFIG,
            dialogFormVisible: false,
            isActiveStream: false,
            isListTestCurrent: false,
            isListTestOld: false,
            isBulkTestCurrent: false,
            isMeTestCurrent: { header: "", negative: false },
            isAddressBookCurrent: false,
            action: null,
            valueDefaultActiveStream: this.$t("zimbra.zmMsg.activityStream"),
            dataActivityStream: undefined,
            dataActivityStreamOld: undefined,
            dialogFormEditFilterInComing: false,
            titleDialog: "",
            dataFilterActivitySteam: [
                {
                    condition: "anyof",
                },
            ],
        }
    },
    mixins: [CommonUtils],
    components: {
        BaseCheckbox,
        BaseSelect,
        BaseButtonDefault,
    },
    computed: {
        ...mapGetters([
            "filterRulesIncoming",
            "dataSidebarInBox",
        ]),
    },
    created() {
        this.isMeTestCurrent.header = this.data.zWidgetTitle[0].header;
        this.dataFilterActivitySteam[0]["listTest"] = [{}];
        this.dataFilterActivitySteam[0]["bulkTest"] = [{}];
        this.buildFormActivityStream()
    },
    mounted() {
        this.$root.$on("handleShowActivityStream", () => {
            this.dialogFormVisible = true
        })
        this.$root.$on("handleBuildFormActivity", () => {
            this.buildFormActivity();
        })
        this.$root.$on("handleBuildFormActivityStream", () => {
            this.buildFormActivityStream()
        })
    },
    methods: {
        ...mapActions([
            "setFilterRulesIncoming",
            "getFilterRulesIncoming"
        ]),
        handleChangeStatus(val) {
            this.isActiveStream = val;
        },
        handleChangeSelectField(val) {
            this.isMeTestCurrent.header = val;
            this.buildForm();
        },
        buildFormActivityStream() {
            const formActivity = this.filterRulesIncoming.find((item) => item.name === this.valueDefaultActiveStream);
            this.dataActivityStreamOld = Object.assign({}, formActivity)
            if (formActivity) {
                const filterRule = formActivity;
                const { active } = filterRule;
                const { name } = filterRule;
                const { filterTests } = filterRule;
                const { filterActions } = filterRule;
                // filterTests
                const { listTest } = filterTests;
                const { bulkTest } = filterTests;
                const { addressBookTest } = filterTests;
                const { meTest } = filterTests;
                const _activityStream = {};
                if (bulkTest) {
                    this.isBulkTestCurrent = true;
                }else{
                    this.isBulkTestCurrent = false;
                }
                if (listTest) {
                    this.isListTestCurrent = true;
                }else{
                    this.isListTestCurrent = false;
                }
                if (addressBookTest && addressBookTest["negative"] === FILTER_TEST_CONFIG.NEGATIVE) {
                    this.isAddressBookCurrent = true;
                }else{
                    this.isAddressBookCurrent = false;
                }
                if (meTest && (meTest["negative"] === FILTER_TEST_CONFIG.NEGATIVE || meTest["negative"] === true)
                    && (meTest["header"] === FILTER_TEST_CONFIG.HEADER.TO ||
                    meTest["header"] === FILTER_TEST_CONFIG.HEADER.TO_CC)
                ) {
                    this.isMeTestCurrent.negative = true;
                    this.isMeTestCurrent.header = meTest["header"];
                }else{
                    this.isMeTestCurrent.negative = false
                }
                this.isActiveStream = !!(active || active === 1);
                _activityStream["active"] = active;
                _activityStream["name"] = name;
                _activityStream["filterTests"] = filterTests;
                _activityStream["filterActions"] = filterActions;
                this.action = FILTER_ACTION_TYPE.EDIT_FILTER;
                this.dataActivityStream = _activityStream
            } else {
                this.action = FILTER_ACTION_TYPE.ADD_FILTER;
                this.isActiveStream = false;
                this.isBulkTestCurrent = true;
                this.isListTestCurrent = true;
                this.isMeTestCurrent.negative = false;
                this.isMeTestCurrent.header = FILTER_TEST_CONFIG.HEADER.TO;
                this.isAddressBookCurrent = false;
                this.dataActivityStream = undefined;
            }
            this.isListTestOld = this.isListTestCurrent;
        },
        handleShowDialogActivity() {
            // this.titleDialog = this.$t("zimbra.zhMsg.editFilter")
            this.dialogFormEditFilterInComing = true;
            if (!this.dataActivityStream) {
                if (!this.isActiveStream) {
                    const data = this.buildFormActivity();
                    this.dataActivityStream = data
                }
            } else {
                this.actionType = FILTER_ACTION_TYPE.INCOMING_FILTER;
            }
            const dataInfoFilter = {
                action: this.action,
                actionType: FILTER_ACTION_TYPE.INCOMING_FILTER,
                dialogFormEnabled: this.dialogFormEditFilterInComing
            }
            this.$root.$emit("hanldeShowInfoFilter", dataInfoFilter)
            this.$root.$emit("hanldeCreateFilterRules",this.dataActivityStream);
        },
        handleBeforeCloseActivity(done) {
            done();
            this.action = null;
            this.buildFormActivityStream();
        },
        handleCancelActivity() {
            this.dialogFormVisible = false;
            this.action = null;
            this.buildFormActivityStream();
        },
        handleChangeDlSubscribed(val) {
            this.isBulkTestCurrent = val;
        },
        handleChangeMassMaketing(val) {
            this.isListTestCurrent = val;
        },
        handleChangeNotToMe(val) {
            this.isMeTestCurrent.negative = val;
        },
        handleChangeNotInAddr(val) {
            this.isAddressBookCurrent = val;
        },
        buildFormActivity() {
            const filterRule = {};
            filterRule["active"] = true;
            filterRule["name"] = this.valueDefaultActiveStream;
            filterRule["filterTests"] = {
                condition: FILTER_TEST_CONFIG.ANYOF,
                addressBookTest: [
                    {
                        index: 0,
                        header: FILTER_TEST_CONFIG.HEADER.FROM,
                        negative: FILTER_TEST_CONFIG.NEGATIVE,
                        type: FILTER_TEST_CONFIG.TYPE.CONTACT,
                    },
                ],
                bulkTest: [{ index: 1 }],
                listTest: [{ index: 2 }],
                meTest: [
                    {
                        index: 3,
                        header: FILTER_TEST_CONFIG.HEADER.TO,
                        negative: FILTER_TEST_CONFIG.NEGATIVE,
                    },
                ],
            };
            filterRule["filterActions"] = {
                actionFileInto: [
                    {
                        folderPath: this.valueDefaultActiveStream,
                    },
                ],
            };
            return filterRule;
        },
        handleAddCondition(rule, condition, isNegative, headerValue, isType) {
            const _rule = rule[condition];
            let _list = [];
            const negative = isNegative;
            const header = headerValue;
            const type = isType;
            let _condition = {};
            if (_rule) {
                if (Array.isArray(_rule)) _list = _rule;
                else _list.push(_rule);
            }
            if (negative) {
                _condition = {
                    ..._condition,
                    negative,
                };
            }
            if (header) {
                _condition = {
                    ..._condition,
                    header,
                };
            }
            if (type) {
                _condition = {
                    ..._condition,
                    type: type,
                };
            }
            _list.push(_condition);
            rule[condition] = _list;
            return rule;
        },
        handleRemoveCondition(rule, condition, isNegative, headerValue) {
            const _rule = rule[condition];
            let _list = [];
            if (_rule) {
                if (!_rule.length) _list.push(_rule);
                else _list = _rule;
                for (let i = 0; i < _list.length; i++) {
                    const negativeCheck = isNegative ? _list[i].negative == FILTER_TEST_CONFIG.NEGATIVE : !_list[i].negative;
                    const headerCheck = headerValue ? _list[i].header === headerValue : true;
                    if (negativeCheck && headerCheck) {
                        _list.splice(i, 1);
                        rule[condition] = _list;
                    }
                }
                for (let i in rule) {
                    if (rule[i].length <= 0) {
                        delete rule[i];
                    }
                }
            }
            return rule;
        },
        setStreamSelection(test) {
            let conditionTest = test;
            let condition = null;
            const negative = FILTER_TEST_CONFIG.NEGATIVE;
            let header = null;
            if (conditionTest) {
                if (this.isListTestCurrent) {
                    condition = FILTER_TESTS.LIST_TEST;
                    conditionTest = this.handleRemoveCondition(
                        conditionTest,
                        condition
                    );
                }
                if (this.isBulkTestCurrent) {
                    condition = FILTER_TESTS.BULK_TEST;
                    conditionTest = this.handleRemoveCondition(conditionTest,condition);
                }
                if (this.isMeTestCurrent.negative) {
                    condition = FILTER_TESTS.ME_TEST;
                    header = this.isMeTestCurrent.header;
                    conditionTest = this.handleRemoveCondition(conditionTest,condition, negative,header);
                }
                if (this.isAddressBookCurrent) {
                    condition = FILTER_TESTS.ADDRESS_BOOK_TEST;
                    header = FILTER_TEST_CONFIG.HEADER.FROM;
                    const type = FILTER_TEST_CONFIG.TYPE.CONTACT;
                    conditionTest = this.handleRemoveCondition(conditionTest,condition,negative,header,type);
                }
            }
            return conditionTest;
        },
        buildForm() {
            let _list = this.filterRulesIncoming
            const indexActivity = _list.findIndex(item => item.name === this.valueDefaultActiveStream);
            const filterRules = {};
            let filterTests = {};
            const filterActions = {};
            let condition = "";
            let header = "";
            const negative = FILTER_TEST_CONFIG.NEGATIVE;
            let type = "";
            switch (this.action) {
                case FILTER_ACTION_TYPE.ADD_FILTER:
                    filterActions[FILTER_ACTION.ACTION_FILE_INTO] = [
                        {
                            folderPath: this.valueDefaultActiveStream,
                        },
                    ];
                    if (this.isListTestCurrent)
                        filterTests[FILTER_TESTS.LIST_TEST] = [{}];
                    if (this.isBulkTestCurrent)
                        filterTests[FILTER_TESTS.BULK_TEST] = [{}];
                    if (this.isMeTestCurrent.negative)
                        filterTests[FILTER_TESTS.ME_TEST] = [
                            {
                                negative: FILTER_TEST_CONFIG.NEGATIVE,
                                header:this.isMeTestCurrent.header === FILTER_TEST_CONFIG.HEADER.TO ? FILTER_TEST_CONFIG.HEADER.TO : FILTER_TEST_CONFIG.HEADER.TO_CC,
                            },
                        ];
                    if (this.isAddressBookCurrent)
                        filterTests[FILTER_TESTS.ADDRESS_BOOK_TEST] = [
                            {
                                negative: FILTER_TEST_CONFIG.NEGATIVE,
                                header: FILTER_TEST_CONFIG.HEADER.FROM,
                                type: FILTER_TEST_CONFIG.TYPE.CONTACT,
                            },
                        ];
                    filterTests["condition"] = FILTER_TEST_CONFIG.ANYOF;

                    //
                    filterRules["name"] = this.valueDefaultActiveStream;
                    filterRules["active"] = this.isActiveStream;
                    filterRules["filterActions"] = filterActions;
                    filterRules["filterTests"] = filterTests;
                    _list.push(filterRules)
                    this.setFilterRulesIncoming(_list)
                    // this.addFilterRulesIncoming({
                    //     key: this.filterRulesIncoming.length + 1,
                    //     data: filterRules,
                    // });
                    break;
                case FILTER_ACTION_TYPE.EDIT_FILTER:
                    filterTests = this.setStreamSelection(
                        this.dataActivityStream.filterTests
                    );
                    if (this.isListTestCurrent) {
                        condition = FILTER_TESTS.LIST_TEST;
                        filterTests = this.handleAddCondition(
                            filterTests,
                            condition
                        );
                    } else {
                        condition = FILTER_TESTS.LIST_TEST;
                        filterTests = this.handleRemoveCondition(
                            filterTests,
                            condition
                        );
                    }
                    if (this.isBulkTestCurrent) {
                        condition = FILTER_TESTS.BULK_TEST;
                        filterTests = this.handleAddCondition(
                            filterTests,
                            condition
                        );
                    } else {
                        condition = FILTER_TESTS.BULK_TEST;
                        filterTests = this.handleRemoveCondition(
                            filterTests,
                            condition
                        );
                    }
                    if (this.isMeTestCurrent.negative) {
                        condition = FILTER_TESTS.ME_TEST;
                        header = this.isMeTestCurrent.header;
                        filterTests = this.handleAddCondition(
                            filterTests,
                            condition,
                            negative,
                            header
                        );
                    } else {
                        condition = FILTER_TESTS.ME_TEST;
                        header = this.isMeTestCurrent.header;
                        filterTests = this.handleRemoveCondition(
                            filterTests,
                            condition,
                            negative,
                            header
                        );
                    }
                    if (this.isAddressBookCurrent) {
                        condition = FILTER_TESTS.ADDRESS_BOOK_TEST;
                        header = FILTER_TEST_CONFIG.HEADER.FROM;
                        type = FILTER_TEST_CONFIG.TYPE.CONTACT;
                        filterTests = this.handleAddCondition(
                            filterTests,
                            condition,
                            negative,
                            header,
                            type
                        );
                    } else {
                        condition = FILTER_TESTS.ADDRESS_BOOK_TEST;
                        header = FILTER_TEST_CONFIG.HEADER.FROM;
                        filterTests = this.handleRemoveCondition(
                            filterTests,
                            condition,
                            negative,
                            header
                        );
                    }
                    filterRules["name"] = this.dataActivityStream.name;
                    filterRules["active"] = this.isActiveStream;
                    filterRules["filterTests"] = filterTests;
                    filterRules["filterActions"] =
                        this.dataActivityStream.filterActions;
                    if (indexActivity > -1) {
                        _list = [..._list.slice(0, indexActivity), filterRules, ..._list.slice(indexActivity + 1)];
                    }
                    this.setFilterRulesIncoming(_list)
                    break;
                default:
                    break;
            }
            const listRequest = this.filterRulesIncoming
            return listRequest;
        },
        async handleSaveActititySteam(listRequest) {
            await FilterService.modifyFilterRulesRequest(listRequest).then((resp) => {
                    const response = this.getResponseBody(resp);
                    if (response["ModifyFilterRulesResponse"]) {
                        this.getFilterRulesIncoming()
                        this.dialogFormVisible = false;
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t("zimbra.zmMsg.filtersSaved"),
                            type: "success",
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            const index = this.dataSidebarInBox[0].folder.findIndex(
                (item) => item.name === this.valueDefaultActiveStream
            );
            if (index === -1) {
                const folderRequest = {
                    fie: 1,
                    l: 1,
                    name: this.valueDefaultActiveStream,
                    message: "message",
                };
                await FilterService.createFolderRequest(folderRequest)
                    .then((resp) => {
                        const response = this.getResponseBody(resp);
                        if (response["CreateFolderResponse"]) {
                            const { folder } = response["CreateFolderResponse"];
                            this.$root.$emit("addItemToDataInBox", folder);
                            setTimeout(() => {
                                this.$root.$emit("extendFolder");
                            }, 500);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        handleAddActivitySteam() {
            switch (this.action) {
                case FILTER_ACTION_TYPE.ADD_FILTER:
                    if (
                        !this.isActiveStream ||
                        (!this.isListTestCurrent &&
                            !this.isBulkTestCurrent &&
                            !this.isMeTestCurrent.negative &&
                            !this.isAddressBookCurrent)
                    ) {
                        this.showDialogError = true;
                    } else {
                        const listRequest = this.buildForm();
                        this.handleSaveActititySteam(listRequest);
                    }
                    break;
                case FILTER_ACTION_TYPE.EDIT_FILTER:
                    if (!this.isListTestCurrent &&!this.isBulkTestCurrent &&
                        !this.isMeTestCurrent.negative &&
                        !this.isAddressBookCurrent &&
                        this.dataActivityStream && this.isActiveStream
                    ) {
                        this.showDialogError = true;
                    } else if (!this.isListTestCurrent &&
                        !this.isBulkTestCurrent &&
                        !this.isMeTestCurrent.negative &&
                        !this.isAddressBookCurrent &&
                        !this.isActiveStream
                    ) {
                        let listRequest = this.filterRulesIncoming
                        listRequest.map(item => {
                            if (item.name === this.dataActivityStreamOld.name) {
                                item.active = 0;
                            }
                        })
                        this.handleSaveActititySteam(listRequest);
                    } else {
                        const listRequest = this.buildForm();
                        this.handleSaveActititySteam(listRequest);
                    }
                    break;
                default:
                    break;
            }
        },
        handleCloseFilterDialog() {
            this.dialogFormEditFilterInComing = false;
        },
    }
}
</script>

<style>

</style>