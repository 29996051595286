<template>
    <el-dialog
        :title="$t('zimbra.zmMsg.suggestionPreferences')"
        :visible.sync="visable"
        :append-to-body="true"
        :destroy-on-close="true"
        class="zimbra-dialog zm-suggestion-references"
        width="700px"
    >
        <div class="event-info">
            <div class="font-weight-bold">
                {{ $t("zimbra.zmMsg.timePreferences") }}
            </div>
            <hr class="zm-hr m-0" />
            <div class="row pt-3">
                <div class="col-2 zm-label text-nowrap">
                    {{ $t("zimbra.zmMsg.suggestionTimes") }}:
                </div>
                <div class="col-10">
                    <div class="form-group">
                        <label class="checkbox-container">
                            {{ $t("zimbra.zmMsg.schedulerPrefMyWorkingHours") }}
                            <input
                                v-model="modelData.my_working_hrs_pref"
                                type="checkbox"
                                class="allday-check"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="form-group">
                        <label class="checkbox-container">
                            {{
                                $t("zimbra.zmMsg.schedulerPrefOtherWorkingHours")
                            }}
                            <input
                                v-model="modelData.others_working_hrs_pref"
                                type="checkbox"
                                class="allday-check"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2 zm-label text-nowrap">{{ $t("zimbra.zhMsg.recurrence") }}:</div>
                <div class="col-10">
                    <el-select
                        class="recurrence-select"
                        v-model="modelData.recurrenceSelect"
                    >
                        <el-option
                            v-for="item in datasourceRecurrenceSelect"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                            <span>
                                {{ item.label }}
                            </span>
                        </el-option>
                    </el-select>
                    {{ $t("zimbra.zmMsg.calculateAvailability") }}
                </div>
            </div>
            <div class="font-weight-bold">
                {{ $t("zimbra.zmMsg.locationPreferences") }}
            </div>
            <hr class="zm-hr m-0" />
            <div class="row pt-3">
                <div class="col-3 zm-label text-nowrap">
                    {{ $t("zimbra.zhMsg.AB_FIELD_firstName") }}:
                </div>
                <div class="col-3">
                    <input v-model.trim="modelData.name" class="form-control" />
                </div>
                <div class="col-3 zm-label text-nowrap">
                    {{ $t("zimbra.zhMsg.AB_FIELD_homeURL") }}:
                </div>
                <div class="col-3">
                    <input v-model.trim="modelData.site" class="form-control" />
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-3 zm-label">
                    {{ $t("zimbra.zmMsg.minimumCapacity") }}:
                </div>
                <div class="col-3">
                    <input
                        v-model.trim="modelData.capacity"
                        class="form-control"
                    />
                </div>
                <div class="col-3 zm-label text-nowrap">
                    {{ $t("zimbraNokey.zimbra_zmMsg_building") }}:
                </div>
                <div class="col-3">
                    <input
                        v-model.trim="modelData.building"
                        class="form-control"
                    />
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-3 zm-label text-nowrap">
                    {{ $t("zimbra.zmMsg.description") }}:
                </div>
                <div class="col-3">
                    <input v-model.trim="modelData.desc" class="form-control" />
                </div>
                <div class="col-3 zm-label text-nowrap">
                    {{ $t("zimbra.zmMsg.floor") }}:
                </div>
                <div class="col-3">
                    <input
                        v-model.trim="modelData.floor"
                        class="form-control"
                    />
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <div class="text-right">
                <el-button class="btn-cancel" @click="visable = false">
                    {{ $t("zimbra.zhMsg.actionTaskCancelTT") }}
                </el-button>
                <el-button
                    type="primary"
                    class="btn-calendar"
                    @click="actionSavePreference"
                >
                    {{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}
                </el-button>
            </div>
        </span>
    </el-dialog>
</template>
<script>
/**
 * Dialog Tùy chọn gợi ý
 */
import CommonUtils from "@/utils/common-utils";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import { mapActions } from "vuex";
import _ from "lodash";
export default {
    name: "DialogOptionsSuggest",
    mixins: [CommonUtils],
    data() {
        return {
            visable: false,
            modelData: {
                my_working_hrs_pref: true,
                recurrenceSelect: 4,
            },
            datasourceRecurrenceSelect: [
                { value: 1, label: 1 },
                { value: 2, label: 2 },
                { value: 3, label: 3 },
                { value: 4, label: 4 },
                { value: 5, label: 5 },
                { value: 6, label: 6 },
                { value: 7, label: 7 },
                { value: 8, label: 8 },
                { value: 9, label: 9 },
                { value: 10, label: 10 },
            ],
        };
    },
    methods: {
        ...mapActions(["setLocationSearchPref"]),
        show() {
            this.visable = true;
            ZimbraMailService.getMailboxMetadataRequest({ section: "zwc:MD_LOCATION_SEARCH_PREF" }).then(res => {
                
                const { meta } = this.getResponseBody(res).GetMailboxMetadataResponse;
                if (meta && meta.a) {
                    meta.a = Array.isArray(meta.a) ? meta.a : [meta.a];
                    const data = {};
                    meta.a.forEach(item => {
                        data[item.n] = item.content;
                    });
                    this.modelData = data;
                }
            });
        },
        actionSavePreference() {
            const _pref = [];
            Object.keys(this.modelData).forEach(key => {
                const value = this.modelData[key]?.toString();
                if (value) {
                     _pref.push({
                        n: key,
                        content: _.escape(value)
                    });
                }
            });
             const formData = {
                section: "zwc:MD_LOCATION_SEARCH_PREF",
                a: _pref
            };
            ZimbraMailService.setMailboxMetadataRequest(formData).then(() => {
                this.setLocationSearchPref();
                this.$emit("onLocationSearchPref");
                this.visable = false;
            });
        }
    },
};
</script>
