<template>
    <el-menu class="box-control-inbox">
        <!-- Cặp tài liệu mới -->
        <el-menu-item
            index="1"
            class="menu-item"
            @click="onOpenAddBriefcase"
        >
            <i class="el-icon-folder-add" style="color: orange"></i>
            <span>{{ $t("zimbra.zhMsg.briefcaseNew") }}</span>
        </el-menu-item>

        <!-- Mở rộng tất cả -->
        <el-menu-item index="2" class="menu-item" @click="extendAll()">
            <i class="el-icon-rank"></i>
            <span>{{ $t("zimbra.zmMsg.expandAll") }}</span>
        </el-menu-item>

        <!-- Tìm các lượt chia sẻ -->
        <el-menu-item index="3" class="menu-item" @click="$root.$emit('showAddShareFolder', FOLDER_DEFAULT.BRIEFCASE)">
            <i class="ic-people icon-custom-wrap"></i>
            <span>{{ $t("zimbra.zmMsg.findShares") }}</span>
        </el-menu-item>
    </el-menu>
</template>

<script>
import { FOLDER_DEFAULT } from "@/utils/constants"

export default {
    data() {
        return {
            FOLDER_DEFAULT: FOLDER_DEFAULT
        }
    },
    methods: {
        /**
         * Hàm Thêm thư mục cặp tài liệu mới
         */
        onOpenAddBriefcase() {
            this.$root.$emit('handleAddFolderContact', true)
        },

        /**
         * Hàm mở rộng tất cả
         */
        extendAll() {
            this.$root.$emit("extendAllFolder");
        },
    }
}
</script>

<style>

</style>