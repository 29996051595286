<template>
    <section class="drag-wrapper">
        <div>
            <main class="main-content__wrapper zm-unselect">
                <slot />
            </main>
        </div>
        <SideBarRight />
    </section>
</template>

<script>
import SideBarRight from "@/components/modules/layouts/SideBarRight";
import CommonUtils from "@/utils/common-utils";
export default {
    components: {
        SideBarRight,
    },
    mixins: [CommonUtils],
    mounted() {
        // this.login(); // Fix bug login trên safari
    },
    methods: {
        login() {
            const { origin } = window.location;
            var authToken = this.getLocalStorageObject("zimbra_token");
            if (process.env.NODE_ENV === "development") {
                if (!authToken) {
                    window.location.href = `${origin}/${this.$configZimbra.LOGIN_PAGE}`;
                }
            } else if (!this.doesHttpOnlyCookieExist("ZM_AUTH_TOKEN")) {
                window.location.href = `${origin}/${this.$configZimbra.LOGIN_PAGE}`;
            }
        },
    },
};
</script>

<style lang="scss">
.app-wrapper {
    // display: flex;
    & .main-content__wrapper {
        width: calc(100% - 250px - 64px);
        @media screen and (max-width: 1280px) {
            width: calc(100% - 200px - 54px);
        }
    }
}
</style>
