<template>
    <el-dialog
        class="dialog-body-wrap zimbra-dialog d-center"
        custom-class="dialog-add-folder mw__450"
        :title="$t('zimbra.zhMsg.createNewFolder')"
        :visible.sync="dialogMoveAddFolder"
        width="30%"
        height="550px"
        :append-to-body="true"
        :close-on-click-modal="false"
    >
        <el-form class="form-wrap">
            <el-form-item
                :label="$t('zimbra.zhMsg.nameLabel')"
                label-width="90px"
            >
                <el-input
                    class="el-text-control"
                    v-model="formAdd.name"
                    autocomplete="off"
                    size="medium"
                    ref="refInputName"
                ></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('zimbra.zmMsg.colorLabel')"
                label-width="90px"
            >
                <zm-color-picker
                    v-model="formAdd.rgb"
                    :visible="false"
                    :showFooter="true"
                >
                </zm-color-picker>
            </el-form-item>
            <div style="padding-bottom: 10px; padding-top: 10px;">
                <span class="zimbra-label">{{$t('zimbra.zmMsg.newFolderParent')}}</span>
            </div>
            <div class="wrap-tree">
                <TreeBriefcaseExtra ref="treeFolderBriefcase"
                    :showInputSearch="false"
                    :isMoveBriefcase="false"
                    :listIdFolderHidden=[FOLDER_DEFAULT.ROOT]
                 ></TreeBriefcaseExtra>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogMoveAddFolder = false">{{
                $t("zimbra.zhMsg.actionTaskCancelTT")
            }}</el-button>
            <el-button type="primary" @click="handleAddFolder">{{
                $t("zimbra.zmMsg.mobileStatusNeedProvision")
            }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import TreeBriefcaseExtra from "./TreeBriefcaseExtra";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import EmailUtils from "@/utils/email-utils";
import BriefcaseUtils from "@/utils/briefcase-utils";
import color from "@/components/modules/layouts/listColor.js";
import { FOLDER_DEFAULT, STATUS_REQUEST, ROOT_BRIEFCASE_FOLDER_ID } from "@/utils/constants";
import _ from "lodash";
import { mapGetters } from "vuex";
import ZmColorPicker from "@/components/modules/base/base-color-picker-advance/zm-color-picker";
export default {
    mixins: [CommonUtils, EmailUtils, BriefcaseUtils],
    mounted() {
        this.$root.$on("handleActionAddBriefcase", (isAddFolderToBriefcase) => {
            this.show(isAddFolderToBriefcase);
        });
    },
    computed: {
        ...mapGetters(["briefcaseTreeFolders"]),
    },
    components: {
        TreeBriefcaseExtra,
        "zm-color-picker": ZmColorPicker
    },
    data() {
        return {
            formAdd: {
                name: "",
                color: null,
                parent: {},
                rgb: "#3F4254",
            },
            dialogMoveAddFolder: false,
            listColor: color,
            isAddFolderToBriefcase: false,
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            STATUS_REQUEST: STATUS_REQUEST,
        };
    },
    methods: {
        show(isAddFolderToBriefcase) {
            this.isAddFolderToBriefcase = isAddFolderToBriefcase;
            this.dialogMoveAddFolder = true;
            this.formAdd = {
                name: "",
                parent: {},
                rgb: "#3F4254",
                registerRSS: false,
            };
            this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", null);
            setTimeout(() => {
                this.$refs.treeFolderBriefcase.setCurrentKey(ROOT_BRIEFCASE_FOLDER_ID)
                this.$refs.refInputName.focus()
            }, 500);
        },
        checkExistsName(name, folderParent) {
            if (folderParent.id == ROOT_BRIEFCASE_FOLDER_ID) {
                if (this.briefcaseTreeFolders[0].folder.map((x) => x.name.toString().toLowerCase()).indexOf(name.toString().toLowerCase()) > -1) {
                    this.$alert(
                        this.$t("zimbra.zmMsg.errorAlreadyExists", { 0: name, 1: this.$t("zimbra.zhMsg.ALT_FOLDER") }),
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return true;
                }
            }
            if (Array.isArray(folderParent.folder)) {
                if (folderParent.folder.map((x) => x.name).indexOf(name) > -1) {
                    this.$alert(
                        this.$t("zimbra.zmMsg.errorAlreadyExists", { 0: name, 1: this.$t("zimbra.zhMsg.ALT_FOLDER") }),
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return true;
                }
            }
        },
        async handleAddFolder() {
            this.formAdd.parent = this.$store.getters.nodeBriefcaseExtraCurrent ? this.$store.getters.nodeBriefcaseExtraCurrent : { id: ROOT_BRIEFCASE_FOLDER_ID };
            if (this.checkExistsName(this.formAdd.name, this.formAdd.parent)) {
                return
            }
            this.formAdd.name = this.formAdd.name.toString().trim();
            // validateName
            if (this.formAdd.name == "" || this.formAdd.name.length > 128 || this.formAdd.name.indexOf("/") > -1) {
                const nameError = _.escape(this.formAdd.name);
                const Msgerror = this.formAdd.name == "" ? this.$t("zimbra.zmMsg.nameEmpty") :
                    (this.formAdd.name.length > 128 ? this.$t("zimbraNokey.zimbra_sidebar_messageCriticalMaxLengthName") : this.$t("zimbraNokey.zimbra_sidebar_messageCriticalMaxFormatName", { nameError: nameError }));
                this.$alert(
                    Msgerror,
                    this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                    {
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "error",
                    }
                );
                return;
            }
            this.formAdd.name = _.escape(this.formAdd.name);
            const formData = {
                l: `${this.formAdd.parent.id}`,
                name: `${this.formAdd.name}`,
                view: "document"
            };
            formData.rgb = this.formAdd.rgb;
            await ZimbraMailService.createFolderRequest(formData).then(async (res) => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    const itemAddResponse =
                        this.getResponseBody(res)["CreateFolderResponse"];
                    const itemAdd = itemAddResponse.folder;
                    await this.getBriefcaseFolder().then(briefcaseFolderRes => {
                        this.$store.commit('SET_BRIEFCASES_FOLDER', briefcaseFolderRes);
                        this.$store.dispatch("getTreeFolder")
                    });
                    this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", itemAdd);
                    this.$root.$emit("setCurrentKey", itemAdd.id);
                    this.$root.$emit("setFocusNodeActive");
                    this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                        message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessAddFolder"),
                        type: "success",
                    });
                }
            })
                .catch((err) => {
                    const { detail } = this.getResponseBody(err.response)["soap:Fault"];
                    
                });
            this.dialogMoveAddFolder = false;
        },
    },
};
</script>
