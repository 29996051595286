<template>
    <el-popover
        placement="left-start"
        trigger="click"
        popper-class="quick-contact__popover"
        v-model="visibleContact"
        @show="handleShow"
    >
        <el-row class="quick-contact">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="heading-wrapper">
                    <div class="heading-title">
                        <h2 class="title">
                            {{ $t("zimbra.zhMsg.addressBook") }}
                        </h2>
                        <el-tooltip class="item" effect="dark"
                                    :content='this.$t("zimbra.zmMsg.expandAll")'
                                    placement="left-end"
                        >
                            <span class="ic-opent-contact" @click="handleRedirect"> </span>
                        </el-tooltip>
                    </div>
                    <span
                        class="icon el-icon-close y-center"
                        @click="visibleContact = false"
                    >
                    </span>
                </div>
                <template v-if="isLoading">
                    <div class="p-3">
                        <el-skeleton :rows="12" :loading="isLoading" animated />
                    </div>
                </template>
                <template v-else>
                    <div v-if="isShowListContact">
                        <ListContact
                            :contacts="listContacts"
                            :currentPage="currentPage"
                            :totalContact="totalContact"
                            @prevClick="prevClick"
                            @nextClick="nextClick"
                            @search="handleSearchContact"
                        />
                    </div>
                    <div v-else >
                        <ItemContact :itemContact="itemContact" />
                    </div>
                    <!-- <template v-if="!isShowListContact && contactAction === CONTACT_ACTION.VIEW_CONTACT_GROUP_LIST">
                        <div class="header-profile">
                            <div class="icon" @click="hanldePrevContact">
                            <span class="ic-prev"></span>
                        </div>
                        </div>
                        <screen-group-contact :contactForm="itemContact" />
                    </template> -->
                </template>
            </el-col>
        </el-row>
        <div
            slot="reference"
            :class="[
                this.activeContact === 'contact' ? 'activeContact' : '',
                'element',
            ]"
        >
            <el-tooltip class="item" effect="dark"
                :content='this.$t("zimbra.zhMsg.addressBook")'
                placement="left-end"
            >
                <i :class="$route.path == '/mail/contacts' || visibleContact ? 'contact-icon-active' : 'contact-icon'"></i>
            </el-tooltip>
        </div>
    </el-popover>
</template>

<script>
import ListContact from "./list-contact.vue";
import ItemContact from "./item-contact.vue";
import { mapActions, mapGetters } from "vuex"
import ZimbraMailService from "@/services/ZimbraMailService.js";
import ContactUtils from "@/utils/contact-utils";
import CommonUtils from "@/utils/common-utils";
import CONFIG from '@/config/config';
// import ScreenGroupContact from "@/components/modules/contacts/contact-box/right-contact/screen-group-contact.vue"
import { CONTACT_ACTION } from "@/utils/constants"
import { cloneDeep } from "lodash"
export default {
    mixins: [CommonUtils, ContactUtils],
    components: {
        ListContact,
        ItemContact,
        // ScreenGroupContact
    },
    mounted() {
        this.$root.$on("hanldeShowContact", (data) => {
            this.isShowListContact = false
            this.itemContact = data;
            // if (data.m && data.m.length) {
            //     this.itemContact = data.m
            //     // this.$store.commit("SET_CONTACTACTION", CONTACT_ACTION.VIEW_CONTACT_GROUP_LIST)
            // } else {
            // this.itemContact = data;
            // this.$store.commit("SET_CONTACTACTION", CONTACT_ACTION.EDIT_CONTACT)
            // }
        });
        this.$root.$on("handleHideItemContact", (data) => {
            this.isShowListContact = !data
        });
        this.$root.$on("handleOpenListContact", (data) => {
            this.isShowItemContact = data;
            this.isShowListContact = !data;
        });
        this.$root.$on("handleVisibleQuickContact", () => {
            this.handleVisibleContact()
        })
    },
    data() {
        return {
            CONTACT_ACTION: CONTACT_ACTION,
            allContacts: [],
            tabindex: 1,
            visibleContact: false,
            isShowListContact: true,
            isShowItemContact: false,
            itemContact: {},
            listContacts: [],
            currentPage: 1,
            totalContact: 0,
            isLoading: true,
            pageSize: 10,
        };
    },
    computed: {
        ...mapGetters([
            "contactGroup",
            "contactAction"
        ])
    },
    methods: {
        ...mapActions([
            "getAllContacts"
        ]),
        async handleShow() {
            this.isShowListContact = true
            await this.getData()
        },
        convertAndBuildList(parent) {
            return parent.map(ele => {
                const _attr = Array.isArray(ele.a) ? ele.a : [ele.a]
                const _image = _attr.find(attr => attr.n === "image")
                const _email = []
                _attr.forEach(attr => {
                    if (attr.n.includes("email")) {
                        _email.push(attr.content)
                    }
                })
                ele["fname"] = this.getViewName(_attr)
                ele["url"] = _image ? `${CONFIG.ENDPOINT["homeService"]}/~/?auth=co&id=${ele.id}&part=1&max_width=48&max_height=48&t=${new Date().getTime()}` : require('@/assets/images/Avatar.png')
                ele["email"] = _email ? _email : []
                for (let i = 0; i < this.contactGroup.length; i++) {
                    const _data2 = this.contactGroup[i]
                    if (ele.id === _data2.id) {
                        const _mTemp = Array.isArray(_data2.m) ? _data2.m : [_data2.m]
                        ele["m"] = _mTemp.map(m => {
                            if (m.cn) {
                                const _attr = Array.isArray(m.cn.a) ? m.cn.a : [m.cn.a]
                                const _image = _attr.find(attr => attr.n === "image")
                                const _email = []
                                _attr.forEach(attr => {
                                    if (attr.n.includes("email")) {
                                        _email.push(attr.content)
                                    }
                                })
                                m.cn["fname"] = this.getViewName(_attr)
                                m.cn["url"] = _image ? `${CONFIG.ENDPOINT["homeService"]}/~/?auth=co&id=${m.id}&part=1&max_width=48&max_height=48&t=${new Date().getTime()}` : require('@/assets/images/Avatar.png')
                                m.cn["email"] = _email ? _email : []
                                m.cn.a = _attr
                            }
                            return m
                        })
                    }
                }
                return {
                    ...ele,
                    _attr
                }
            })
        },
        getViewName(data) {
            const rule = ["firstName", "middleName", "lastName"]
            let fname = []
            data.forEach(attr => {
                const _index = rule.findIndex(item => item === attr.n)
                if (_index !== -1) {
                    fname[_index] = attr.content
                }
            })
            return fname.join(" ").trim() || ""
        },
        async getData() {
            this.isLoading = true
            const formDataContacts = {
                offset: 0,
                limit: 999,
                sortBy: "nameAsc",
                query: `in:contacts`,
                types: "contact",
                needExp: 1
            }
            const formDataEmailContact = {
                offset: 0,
                limit: 999,
                sortBy: "nameAsc",
                query: `in:"Emailed Contacts"`,
                types: "contact",
                needExp: 1
            }
            await Promise.all([
                ZimbraMailService.searchRequest(formDataContacts),
                ZimbraMailService.searchRequest(formDataEmailContact)
            ]).then(async response => {
                let listContact = [];
                response.forEach(resp => {
                    let _contact = this.getResponseBody(resp)["SearchResponse"]["cn"]
                    if (_contact) {
                        const contacts = Array.isArray(_contact) ? _contact : [_contact]
                        listContact = listContact.concat(contacts)
                        listContact = listContact.filter(contact => !contact.m)
                        // _contact = Array.isArray(_contact) ? _contact : [_contact]
                        // _contacts = this.convertAndBuildList(_contact)
                        // listContact.push(..._contacts)
                    }
                })
                if (listContact.length) {
                    // const _groups = listContact.filter(contact => contact.m)
                    // listContact = await this.handleReplaceContactGroup(_groups, listContact)
                    listContact = listContact.map((contact, index) => this.preProcessingItem(contact, index))
                }
                this.allContacts = cloneDeep(listContact)
                this.listContacts = this.allContacts.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
                this.totalContact = this.allContacts.length;
                this.isLoading = false
            }).catch((err) => {
                console.log(err);
                this.isLoading = false
            })

        },
        objectToArray(data) {
            return data.map(ele => {
                let _temp = []
                if (ele.a.length) {
                    _temp = ele.a
                } else {
                    _temp.push(ele.a)
                }
                return {
                    ...ele,
                    a: _temp
                }
            })
        },
        handleSearchContact(keyword) {
            if (!keyword) {
                this.listContacts = this.allContacts.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
                this.totalContact = this.allContacts.length;
            } else {
                this.currentPage = 1
                this.totalContact = 0
                const _listSearch = []
                this.allContacts.forEach(contact => {
                    const fileAsStr = contact.fileAsStr
                    const email = contact.emailTitle && contact.emailTitle.find(ele => ele)?.content
                    const phone = contact.phoneTitle && contact.phoneTitle.find(ele => ele)?.content
                    if ((fileAsStr && fileAsStr.toString().toLowerCase().trim().includes(keyword.toLowerCase().trim())) ||
                        (email && email.toString().toLowerCase().trim().includes(keyword.toLowerCase().trim())) ||
                        (phone && phone.toString().toLowerCase().trim().includes(keyword.toLowerCase().trim()))
                    ) {
                        _listSearch.push(contact)
                    }
                });
                this.listContacts = _listSearch.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
                this.totalContact = _listSearch.length;
            }
        },
        prevClick(page) {
            this.currentPage = page;
            this.listContacts = this.allContacts.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
        },
        nextClick(page) {
            this.currentPage = page;
            this.listContacts = this.allContacts.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
        },
        handleRedirect() {
            const path = "/mail/contacts"
            this.handleVisibleContact();
            if (this.$route.path !== path) {
                window.location.href = "/mail/contacts";
            }
        },
        handleVisibleContact(val) {
            this.visibleContact = val;
            this.$emit("click", false);
        },
    },
};
</script>

<style  lang="scss">
@import "@/assets/scss/contact/quick-contact.scss";
</style>
