<template>
    <div class="main-pri">
        <div class="head-main">
            <div id="toolbox" class='left vertical-control'>
                <i @click="handleToggleMenuCollapse" class="d-block strikethrough-icon"></i>
                <a class="btn-compose button-hover focusable"
                    @click="$root.$emit('handleNavbarAction', EMAIL_ACTION.COMPOSE)">
                    <img src="@/assets/zimbra/images/ic-edit.svg" />
                    {{ $t("zimbra.zhMsg.compose") }}
                </a>
                <div class="box-menu-head zm-cursor-no-drop" v-if="showButtonAction">
                    <ul>
                        <li v-if="isNewWindow" class="item-menu-head zm-icon-fi" @click="oncloseNewWindow">
                            <a href="javascript:;" class="focusable">
                                <i class="fi fi-rr-cross"></i>
                                <span>{{ $t("zimbra.zhMsg.close") }}</span>
                            </a>
                        </li>
                        <li v-if="isDraft"
                            :class="['item-menu-head', actionDisable[EMAIL_ACTION.DRAFT] ? 'noclick' : '', isRssFolder ? 'disabled' : '']">
                            <a href="javascript:;" class="focusable"
                                @click="$root.$emit('handleNavbarAction', EMAIL_ACTION.EDIT_AS_NEW)">
                                <i class="ic ic-edit-email"></i>
                                <span>{{ $t("zimbra.zhMsg.edit") }}</span>
                            </a>
                        </li>
                        <li :class="`item-menu-head ${isRssFolder ? 'disabled' : ''}`">
                            <el-tooltip :open-delay="1000" :content="`${this.$t('zimbra.zmMsg.replyTooltip')} [r]`"
                                placement="bottom" effect="light">
                                <a :class="[actionDisable[EMAIL_ACTION.REPLY] ? 'noclick' : 'focusable']"
                                    href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.REPLY)">
                                    <i class="ic ic-reply"></i>
                                    <span>{{ $t("zimbra.zhMsg.actionReplyByEmail") }}</span>
                                </a>
                            </el-tooltip>
                        </li>
                        <li :class="`item-menu-head ${isRssFolder ? 'disabled' : ''}`">
                            <el-tooltip :open-delay="1000" :content="`${this.$t('zimbra.zmMsg.replyAllTooltip')} [a]`"
                                placement="bottom" effect="light">
                                <a :class="[actionDisable[EMAIL_ACTION.REPLY_ALL] ? 'noclick' : 'focusable']"
                                    href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.REPLY_ALL)">
                                    <i class="ic ic-replyall"></i>
                                    <span>{{ $t("zimbra.zhMsg.replyAll") }}</span>
                                </a>
                            </el-tooltip>
                        </li>
                        <li class="item-menu-head">
                            <el-tooltip :open-delay="1000" :content="`${this.$t('zimbra.zmMsg.forwardTooltip')} [f]`"
                                placement="bottom" effect="light">
                                <a :class="[actionDisable[EMAIL_ACTION.FORWARD] ? 'noclick' : 'focusable']"
                                    href="javascript:;" @click=" handleNavbarAction(EMAIL_ACTION.FORWARD)">
                                    <i class="ic ic-forward"></i>
                                    <span>{{ $t("zimbra.zhMsg.actionForwardByEmail") }}</span>
                                </a>
                            </el-tooltip>
                        </li>
                        <li :class="['item-menu-head', isTablet === true ? 'd-none' : '']"
                            v-if="listSelectedMail[0] && !checkFlagsContainKey(listSelectedMail[0].f, EMAIL_FLAG.UNREAD)">
                            <a :class="[actionDisable[EMAIL_ACTION.MARK_AS_UNREAD] ? 'noclick' : 'focusable', isTablet === true ? 'd-none' : '', 'action-unread']"
                                href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.MARK_AS_UNREAD)">
                                <i class="ic ic-mail"></i>
                                <span>{{ $t("zimbra.zhMsg.mark_read_unread") }}</span>
                            </a>
                        </li>
                        <li :class="['item-menu-head', isTablet === true ? 'd-none' : '']"
                            v-if="listSelectedMail[0] && checkFlagsContainKey(listSelectedMail[0].f, EMAIL_FLAG.UNREAD)">
                            <a :class="[
                                actionDisable[EMAIL_ACTION.MARK_AS_READ] ? 'noclick' : 'focusable',
                                isTablet === true ? 'd-none' : ''
                            ]" href="javascript:;" @click="
                                    handleNavbarAction(
                                        EMAIL_ACTION.MARK_AS_READ
                                    )
                                    ">
                                <i class="ic ic-mail"></i>
                                <span>{{
                                    $t("zimbra.zhMsg.mark_read_unread")
                                    }}</span>
                            </a>
                        </li>
                        <li v-if="!isNewWindow" class="item-menu-head">
                            <el-tooltip :open-delay="1000"
                                :content="`${this.$t('zimbraNokey.zimbra_zimbraNokey_archiveTooltip')}`"
                                placement="bottom" effect="light">
                                <a :class="[actionDisable[EMAIL_ACTION.ARCHIVE] ? 'noclick' : 'focusable', isTablet === true ? 'd-none' : '', 'action-unread']"
                                    href="javascript:;" @click=" handleNavbarAction(EMAIL_ACTION.ARCHIVE)">
                                    <i class="ic ic-archive-ac"></i>
                                    <span>{{ $t("zimbraNokey.zimbra_mailbox_archive") }}</span>
                                </a>
                            </el-tooltip>
                        </li>
                    </ul>
                    <ul :class="[isTablet === true ? 'd-none' : '']">
                        <el-popover v-model="visablePopoverMove" placement="bottom-start" width="auto" trigger="click"
                            popper-class="zimbra-popper" :disabled="actionDisable[EMAIL_ACTION.MOVE]">
                            <PopoverActionMove></PopoverActionMove>
                            <li class="item-menu-head" ref="nodeMove" slot="reference">
                                <el-tooltip :open-delay="1000" :content="`${this.$t('zimbra.zhMsg.actionMoveTT')}`"
                                    placement="bottom" effect="light">
                                    <a href="javascript:;"
                                        :class="[actionDisable[EMAIL_ACTION.MOVE] ? 'noclick' : 'focusable down-enter']">
                                        <i class="ic ic-move"></i>
                                        <span>{{ $t("zimbra.zhMsg.actionGo") }}<i class="far fa-angle-down"></i></span>
                                    </a>
                                </el-tooltip>
                            </li>
                        </el-popover>
                        <li class="item-menu-head">
                            <el-tooltip :open-delay="1000" :content="`${this.$t('zimbra.zmMsg.printMultiTooltip')}`"
                                placement="bottom" effect="light">
                                <a :class="[actionDisable[EMAIL_ACTION.PRINT] ? 'noclick' : 'focusable', isTablet === true ? 'd-none' : '']"
                                    href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.PRINT)">
                                    <i class="ic ic-print"></i>
                                    <span>{{ $t("zimbra.zhMsg.actionPrint") }}</span>
                                </a>
                            </el-tooltip>
                        </li>
                    </ul>
                    <ul class="zm-cursor-no-drop">
                        <li v-if="!isFolder(FOLDER_DEFAULT.JUNK)" class="item-menu-head">
                            <el-tooltip :open-delay="1000" :content="`${this.$t('zimbra.zhMsg.actionSpamTT')} [ms]`"
                                placement="bottom" effect="light">
                                <a :class="[actionDisable[EMAIL_ACTION.SPAM] ? 'noclick' : 'focusable', isTablet === true ? 'd-none' : '']"
                                    href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.SPAM)">
                                    <i class="ic ic-spam1"></i>
                                    <span>{{ $t("zimbra.zhMsg.actionSpam") }}</span>
                                </a>
                            </el-tooltip>
                        </li>
                        <li v-if="isFolder(FOLDER_DEFAULT.JUNK)" class="item-menu-head">
                            <el-tooltip :open-delay="1000" :content="`${this.$t('zimbra.zhMsg.actionNotSpamTT')} [ms]`"
                                placement="bottom" effect="light">
                                <a :class="[actionDisable[EMAIL_ACTION.SPAM] ? 'noclick' : 'focusable', isTablet === true ? 'd-none' : '']"
                                    href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.UNSPAM)">
                                    <i class="ic ic-spam1"></i>
                                    <span>{{ $t("zimbra.zhMsg.actionNotSpam") }}</span>
                                </a>
                            </el-tooltip>
                        </li>
                        <li class="item-menu-head" v-show="!folderArchive.hideDeleteButton">
                            <el-tooltip :open-delay="1000" :content="`${this.$t('zimbra.zhMsg.actionTrashTT')} [Del]`"
                                placement="bottom" effect="light">
                                <a :class="[actionDisable[EMAIL_ACTION.DELETE] ? 'noclick' : 'focusable']"
                                    href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.DELETE)">
                                    <i class="ic ic-trash1"></i>
                                    <span>{{ $t("zimbra.zhMsg.actionDelete") }}</span>
                                </a>
                            </el-tooltip>
                        </li>
                    </ul>
                </div>
                <div :class="[actionDisable[EMAIL_ACTION.MORE_ACTION] ? 'noclick' : '', 'control',]"
                    v-if="showMoreAction">
                    <el-popover v-model="visablePopover" placement="right" width="200" trigger="click"
                        popper-class="zimbra-popper" :show="focusElement('inboxAssignTag')">
                        <div class="zimbra-navbar">
                            <ul class="box-control-inbox horizontal-control">
                                <li class="sub">
                                    <a href="javascript:;" class="focusable" id="inboxAssignTag">
                                        <i class="ic ic-tag"></i>
                                        <span>{{ $t("zimbraNokey.zimbra_mailbox_assignTag") }}</span>
                                    </a>
                                    <ul>
                                        <perfect-scrollbar style=" max-height: 200px; height: auto; ">
                                            <template v-for="(item, idx) in listTagChoice">
                                                <el-tooltip :key="`tag_${idx}`" class="item" effect="dark"
                                                    :content="item.name.toString()" placement="top-start"
                                                    :open-delay="500">
                                                    <li class="zm-tag-item"
                                                        @click="actiondAssginOrRemoveTag(item, true)">
                                                        <a href="javascript:;" class="focusable">
                                                            <i class="ic ic-tag"
                                                                :style="`filter: ${convertHexToFilterCss(item)}`"></i>
                                                            <span>{{ item.name }}</span>
                                                        </a>
                                                    </li>
                                                </el-tooltip>
                                            </template>
                                        </perfect-scrollbar>
                                        <hr style="margin: 0px" />
                                        <li v-if="!isNewWindow" class="zm-tag-item" :key="`new_tag`"
                                            @click="$root.$emit('handleAddAssginTag')">
                                            <a href="javascript:;" class="focusable">
                                                <i class="ic-add-circle"></i>
                                                <span>{{ $t("zimbra.zhMsg.tagNew") }}</span>
                                            </a>
                                        </li>
                                        <li v-if="listTagAssgin.length == 1" class="zm-tag-item" :key="`remove_tag`"
                                            @click="handledAssginOrRemoveTag(listTagAssgin[0], false)">
                                            <a href="javascript:;" class="focusable">
                                                <i class="ic-close-circle"></i>
                                                <span>{{ $t("zimbra.zhMsg.actionRemoveTag") }}</span>
                                            </a>
                                        </li>
                                        <li></li>
                                        <li v-if="listTagAssgin.length != 1"
                                            :class="`zm-tag-item ${!listTagAssgin.length > 0 ? 'noclick' : 'sub'}`"
                                            :key="`remove_tag`">
                                            <a href="javascript:;"
                                                :class="`${!listTagAssgin.length > 0 ? '' : 'focusable'}`">
                                                <i class="ic-close-circle"></i>
                                                <span>{{ $t("zimbra.zhMsg.actionRemoveTag") }}</span>
                                            </a>
                                            <ul v-if="listTagAssgin">
                                                <perfect-scrollbar style=" max-height: 200px; height: auto; ">
                                                    <template v-for="( item, idx ) in listTagAssgin">
                                                        <el-tooltip :key="`tag_${idx}`" class="item" effect="dark"
                                                            :content="item.name.toString()" placement="top-start"
                                                            :open-delay="1000">
                                                            <li class="zm-tag-item" :key="`tag_${idx}`"
                                                                @click="actiondAssginOrRemoveTag(item, false)">
                                                                <a href="javascript:;" class="focusable">
                                                                    <i class="ic ic-tag"
                                                                        :style="`filter: ${convertHexToFilterCss(item)}`"></i>
                                                                    <span>{{ item.name }}</span>
                                                                </a>
                                                            </li>
                                                        </el-tooltip>
                                                    </template>
                                                </perfect-scrollbar>
                                                <hr style="margin: 0px" />
                                                <li class="zm-tag-item" :key="`remove_tag`"
                                                    @click="handledRemoveAllTag()">
                                                    <a href="javascript:;" class="focusable">
                                                        <i class="ic ic-tag"></i>
                                                        <span>{{ $t("zimbra.zmMsg.allTags") }}</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li :class="[actionDisable[EMAIL_ACTION.REDIRECT] ? 'noclick' : '']"
                                    @click=" $root.$emit('showDialogRedirect'); visablePopover = false;">
                                    <a href="javascript:;" class="focusable">
                                        <i class="ic ic-navigation"></i>
                                        <span>{{ $t("zimbra.zmMsg.mailRedirect") }}</span>
                                    </a>
                                </li>
                                <li @click="showOriginal">
                                    <a href="javascript:;" class="color-3F4254 focusable">
                                        <i class="ic ic-email-active"></i>
                                        <span>{{ $t("zimbra.zhMsg.showOrig") }}</span>
                                    </a>
                                </li>
                                <template
                                    v-if="isStar(mapppingStarConv, currentActiveEmail ? currentActiveEmail.id : '', 'cid')">
                                    <li @click=" handleNavbarAction(EMAIL_ACTION.UNSTAR); visablePopover = false;">
                                        <a href="javascript:;" class="color-3F4254 focusable">
                                            <i class="ic ic-star"></i>
                                            <span> {{ $t("zimbraNokey.zimbra_zimbraNokey_unstar") }} </span>
                                        </a>
                                    </li>
                                </template>
                                <template v-else>
                                    <li @click="handleNavbarAction(EMAIL_ACTION.STAR); visablePopover = false;">
                                        <a href="javascript:;" class="color-3F4254 focusable">
                                            <i class="ic ic-star"></i>
                                            <span>{{ $t("zimbraNokey.zimbra_mailbox_assignStar") }}</span>
                                        </a>
                                    </li>
                                </template>
                                <li
                                    v-if="listSelectedMail[0] && !checkFlagsContainKey(listSelectedMail[0].f, EMAIL_FLAG.UNREAD)">
                                    <a :class="[actionDisable[EMAIL_ACTION.MARK_AS_UNREAD] ? 'noclick' : 'focusable', isTablet !== true ? 'd-none' : '', 'action-unread']"
                                        href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.MARK_AS_UNREAD)">
                                        <i class="ic ic-mark-as-unread"></i>
                                        <span>{{ $t("zimbra.zhMsg.actionMarkUnread") }}</span>
                                    </a>
                                </li>
                                <li
                                    v-if="listSelectedMail[0] && checkFlagsContainKey(listSelectedMail[0].f, EMAIL_FLAG.UNREAD)">
                                    <a :class="[actionDisable[EMAIL_ACTION.MARK_AS_READ] ? 'noclick' : 'focusable', isTablet !== true ? 'd-none' : '']"
                                        href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.MARK_AS_READ)">
                                        <i class="ic ic-mail"></i>
                                        <span>{{ $t("zimbra.zhMsg.actionMarkRead") }}</span>
                                    </a>
                                </li>
                                <li v-if="!isNewWindow">
                                    <a :class="[actionDisable[EMAIL_ACTION.ARCHIVE] ? 'noclick' : 'focusable', isTablet !== true ? 'd-none' : '', 'action-unread']"
                                        href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.ARCHIVE)">
                                        <i class="ic ic-archive-ac"></i>
                                        <span>{{ $t("zimbraNokey.zimbra_mailbox_archive") }}</span>
                                    </a>
                                </li>
                                <li ref="nodeMove" slot="reference">
                                    <a href="javascript:;"
                                        @click="$root.$emit('handleSidebarActionMoveArchiveMail', FOLDER_EXTRA_ACTION.MOVE_MAIL)"
                                        :class="[actionDisable[EMAIL_ACTION.MOVE] ? 'noclick' : 'focusable', isTablet !== true ? 'd-none' : '']">
                                        <i class="ic ic-move"></i>
                                        <span>{{ $t("zimbra.zhMsg.actionGo") }}</span>
                                    </a>
                                </li>
                                <li>
                                    <a :class="[actionDisable[EMAIL_ACTION.PRINT] ? 'noclick' : 'focusable', isTablet !== true ? 'd-none' : '']"
                                        href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.PRINT)">
                                        <i class="ic ic-print"></i>
                                        <span>{{ $t("zimbra.zhMsg.actionPrint") }}</span>
                                    </a>
                                </li>
                                <li v-if="!isFolder(FOLDER_DEFAULT.JUNK)">
                                    <a :class="[actionDisable[EMAIL_ACTION.SPAM] ? 'noclick' : 'focusable', isTablet !== true ? 'd-none' : '']"
                                        href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.SPAM)">
                                        <i class="ic ic-spam1"></i>
                                        <span>{{ $t("zimbra.zhMsg.actionSpam") }}</span>
                                    </a>
                                </li>
                                <li v-if="isFolder(FOLDER_DEFAULT.JUNK)">
                                    <a :class="[actionDisable[EMAIL_ACTION.SPAM] ? 'noclick' : 'focusable', isTablet !== true ? 'd-none' : '']"
                                        href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.UNSPAM)">
                                        <i class="ic ic-spam1"></i>
                                        <span>{{ $t("zimbra.zhMsg.actionNotSpam") }}</span>
                                    </a>
                                </li>
                                <li v-show="!folderArchive.hideDeleteButton">
                                    <a :class="[actionDisable[EMAIL_ACTION.DELETE] ? 'noclick' : 'focusable', isTablet !== true ? 'd-none' : '']"
                                        href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.DELETE)">
                                        <i class="ic ic-trash1"></i>
                                        <span>{{ $t("zimbra.zhMsg.actionDelete") }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <button slot="reference" class="btn-other focusable down-enter">
                            <img src="@/assets/zimbra/images/ic-other.svg" alt="" />
                        </button>
                    </el-popover>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    EMAIL_ACTION,
    EMAIL_FLAG,
    FOLDER_EXTRA_ACTION,
    FOLDER_DEFAULT,
    RIGHTS_FOLDER,
    LIST_EMAIL_ACTION,
    SCREEN_MODE
} from "@/utils/constants";
import $ from 'jquery'
import { mapActions, mapGetters } from "vuex";
import EmailUtils from "@/utils/email-utils";
import CommonUtils from "@/utils/common-utils";
import PopoverActionMove from "@/components/modules/layouts/components/PopoverActionMove.vue";
import _ from "lodash";
import hotkeys from "hotkeys-js";

export default {
    mixins: [CommonUtils, EmailUtils],
    props: {
        isNewWindow: {
            type: Boolean,
            default: false,
        },
        dataMailNewWindow: {
            type: Object,
            default: () => {
            },
        },
    },
    data() {
        return {
            EMAIL_FLAG: EMAIL_FLAG,
            EMAIL_ACTION: EMAIL_ACTION,
            FOLDER_EXTRA_ACTION: FOLDER_EXTRA_ACTION,
            RIGHTS_FOLDER: RIGHTS_FOLDER,
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            SCREEN_MODE: SCREEN_MODE,
            visablePopover: false,
            visablePopoverMove: false,
            nodeMove: "",
            visibleDialogSearchBox: false,
            refTimeoutSearch: null,
            hasTheWords: "",
            listTagAssgin: [],
            actionDisable: {},
        };
    },
    components: {
        PopoverActionMove,
    },
    computed: {
        ...mapGetters([
            "currentActiveEmail",
            "currentExtraActiveEmail",
            "listTag",
            "modelSearch",
            "showSearchStringCurrent",
            "mailInitialSearch",
            "activeNodeSidebar",
            "folderArchive",
            "listEmailSearch",
            "listSelectedMail",
            "mapppingStarConv",
            "isTablet",
            "menuCollapse"
        ]),
        isArchive() {
            const { m } = this.currentActiveEmail?.data ?? {};
            if (!m || (Array.isArray(m) && m.length === 0)) {
                return false;
            }
            const l = Array.isArray(m) ? m[0].l : m.l;
            return (
                this.folderArchive.id == this.activeNodeSidebar.id ||
                l == this.folderArchive.id
            );
        },
        listTagChoice() {
            return this.listTag.filter((x) => !this.listTagAssgin.includes(x));
        },
        isDraft() {
            const isOnlyOneMailDraft = this.listSelectedMail.length == 1 && this.listSelectedMail[0]?.l == FOLDER_DEFAULT.DRAFTS;
            return isOnlyOneMailDraft || (this.checkFlagsContainKey(
                this.currentActiveEmail?.f,
                EMAIL_FLAG.DRAFT
            ) && this.isFolder(FOLDER_DEFAULT.DRAFTS));
        },
        showActionCompose() {
            return (
                this.isNewWindow ||
                (this.$route.name != "Setting" &&
                    this.currentActiveEmail &&
                    this.currentActiveEmail.type === SCREEN_MODE.COMPOSE)
            );
        },
        showComposeButton() {
            return this.$route.name != "Setting" && !this.isNewWindow;
        },
        showSearchBox() {
            return !this.isNewWindow;
        },
        showButtonAction() {
            return (
                this.isNewWindow ||
                (this.$route.name != "Setting" &&
                    this.currentActiveEmail && this.currentActiveEmail.type != SCREEN_MODE.COMPOSE && this.currentActiveEmail.type != SCREEN_MODE.DRAFT)
            );
        },
        showMoreAction() {
            return (
                this.isNewWindow ||
                (this.$route.name != "Setting" &&
                    this.currentActiveEmail && this.currentActiveEmail.type != SCREEN_MODE.COMPOSE && this.currentActiveEmail.type != SCREEN_MODE.DRAFT)
            );
        },
        isRssFolder() {
            return !!this.activeNodeSidebar?.url;
        },
    },
    watch: {
        currentActiveEmail: {
            deep: true,
            handler: function (val) {
                if (val) {
                    this.listTagAssgin = this.getListTag(val);
                    this.updateDisabledButton();
                }
            },
        },
        listEmailSearch: {
            deep: true,
            handler: function (val) {
                if (this.currentActiveEmail) {
                    const _email = val.find(
                        (e) => e.id === this.currentActiveEmail.id
                    );
                    this.listTagAssgin = this.getListTag(_email);
                }
            },
        },
        listSelectedMail: {
            deep: true,
            handler() {
                // cập nhật trạng thái disabled button
                if (this.listSelectedMail.length === 1) {
                    this.updateDisabledButton();
                } else {
                    LIST_EMAIL_ACTION.forEach((e) => {
                        this.actionDisable[e] = true;
                    });
                    const enableField = this.isDraft
                        ? [
                            EMAIL_ACTION.ARCHIVE,
                            EMAIL_ACTION.DELETE,
                            EMAIL_ACTION.PRINT,
                            EMAIL_ACTION.MORE_ACTION,
                            EMAIL_ACTION.ASSIGN_TAG,
                            EMAIL_ACTION.SHOW_ORIGINAL,
                            EMAIL_ACTION.STAR,
                            EMAIL_ACTION.UNSTAR,
                        ]
                        : [
                            EMAIL_ACTION.FORWARD,
                            EMAIL_ACTION.ARCHIVE,
                            EMAIL_ACTION.MARK_AS_UNREAD,
                            EMAIL_ACTION.MARK_AS_READ,
                            EMAIL_ACTION.MOVE,
                            EMAIL_ACTION.SPAM,
                            EMAIL_ACTION.UNSPAM,
                            EMAIL_ACTION.MORE_ACTION,
                            EMAIL_ACTION.DELETE,
                            EMAIL_ACTION.PRINT,
                        ];
                    enableField.forEach((e) => {
                        this.actionDisable[e] = false;
                    });
                    this.actionDisable[EMAIL_ACTION.ARCHIVE] =
                        this.actionDisable[EMAIL_ACTION.ARCHIVE] || this.isArchive;
                    this.actionDisable = _.cloneDeep(this.actionDisable);
                }
            },
        },
        "showSearchStringCurrent.content": {
            immediate: true,
            handler(val) {
                if (val) {
                    this.hasTheWords = this.mailInitialSearch?.content;
                }
            }
        },
    },
    mounted() {
        this.$root.$on("pushTagToMailOpen", (mailUpdate) => {
            this.listTagAssgin.push(mailUpdate.tag);
        });
        this.$root.$on("deleteTagToMailOpen", (mailUpdate) => {
            this.listTagAssgin = this.listTagAssgin.filter(x => x.id != mailUpdate.tag.id);
        });
        this.$root.$on("closePopoverNavbar", () => {
            this.handleFocusOut();
        });
        this.$root.$on("handleSearchEmailFromContact", (obj) => {
            const formData = {
                header: obj.data.header,
                fromOrToContact: obj.data.email,
                sortBy: obj.data.sortBy,
                tz: obj.data.tz,
                locale: obj.data.locale,
                type: obj.type,
                recip: "0"
            };
            this.handleSearch(formData);
        });
        this.$root.$on("handleSearchFromSender", () => {
            const data = { from: this.currentExtraActiveEmail.e[0].a };
            this.handleSearch(data);
        });
        this.$root.$on("handleSearchToSender", () => {
            const data = { tocc: this.currentExtraActiveEmail.e[0].a };
            this.handleSearch(data);
        });
        this.$root.$on("clickMoveMailToFolder", (data) => {
            this.handledMoveMail(data);
        });
        this.$root.$on("handleShowSearchString", (data) => {
            if (data) this.hasTheWords = this.mailInitialSearch.content;
            else this.hasTheWords = "";
        });
        this.$root.$on("setHasTheWords", (content) => {
            this.hasTheWords = content;
        });

        // Xóa phần tô sáng kết quả tìm kiếm
        this.$root.$on("handleClearSearchHighlights", this.handleFocusSearchInput);

        // hotkeys("/", this.handleFocus);
        hotkeys("n+s", this.handleShowSearchBox);
        hotkeys("t", this.handlePressNextHotKey)
        hotkeys("u", this.handledRemoveTag);
        /*collapse search mobile*/
        setTimeout(() => {
            var win = $(window);
            if ($(win).width() < 1199) {
                if ($('.ic-search-mobile').length) {
                    $('.ic-search-mobile').on('click', function () {
                        $(this).children('i').toggleClass('fa-search fa-times');
                        $('.main-pri .head-search .form-group, .main-pri .head-search').toggleClass('show');
                    });
                }
            }
        }, 200)
    },
    async created() {
        // await this.initKeywordSearch();
        if (this.isNewWindow) {
            this.listTagAssgin = this.getListTag(this.dataMailNewWindow);
        }
    },
    methods: {
        handledRemoveTag() {
            if (this.listSelectedMail && this.listSelectedMail.length > 0 && this.listTagAssgin.length > 0) {
                this.handledRemoveAllTag();
            }
        },
        handleShowSearchBox() {
            this.$store.commit("SET_SHOW_SEARCH_BOX", true);
            this.$store.commit("SET_LIST_EMAIL", []);
        },
        handlePressNextHotKey() {

        },
        changeKeyWordSearch() {
            setTimeout(() => {
                let transformText = "";
                const regex = /^[a-zA-Z]+$/;
                const arrKeyWord = this.hasTheWords.split(' ');
                arrKeyWord.forEach(x => {
                    if (!regex.test(x) && !this.isContainsBlock(x) && x) {
                        transformText = transformText + `"${x}"` + " ";
                    } else {
                        transformText = transformText + x + " ";
                    }
                }
                )
                this.hasTheWords = transformText.replaceAll("  ", " ");
            }, 200)
        },
        isContainsBlock(keyStr) {
            if (!keyStr || keyStr.length < 2) {
                return false;
            }
            if (keyStr[0] == '"' && keyStr[keyStr.length - 1] == '"') {
                return true;
            }
            return false;
        },

        ...mapActions(["getAllGeneral", "actionToggleMenuCollapse"]),
        handleToggleMenuCollapse() {
            this.$store.commit("TOGGLE_MENU_COLLAPSE")
            this.addLocalStorage("menuCollapse", this.menuCollapse)
        },
        updateDisabledButton() {
            LIST_EMAIL_ACTION.forEach((e) => {
                this.actionDisable[e] = false;
            });
            let disableField = [];
            if (this.isDraft) {
                disableField = [
                    EMAIL_ACTION.REPLY,
                    EMAIL_ACTION.REPLY_ALL,
                    EMAIL_ACTION.FORWARD,
                    EMAIL_ACTION.MARK_AS_UNREAD,
                    EMAIL_ACTION.MARK_AS_READ,
                    EMAIL_ACTION.MOVE,
                    EMAIL_ACTION.SPAM,
                    EMAIL_ACTION.UNSPAM,
                ];
            }
            disableField.forEach((e) => {
                this.actionDisable[e] = true;
            });
            this.actionDisable[EMAIL_ACTION.MARK_AS_UNREAD] =
                this.actionDisable[EMAIL_ACTION.MARK_AS_UNREAD] ||
                !this.hasPermissionFolder(RIGHTS_FOLDER.WORKFLOW_ACTION);
            this.actionDisable[EMAIL_ACTION.MARK_AS_READ] =
                this.actionDisable[EMAIL_ACTION.MARK_AS_READ] ||
                !this.hasPermissionFolder(RIGHTS_FOLDER.WORKFLOW_ACTION);
            this.actionDisable[EMAIL_ACTION.MOVE] =
                this.actionDisable[EMAIL_ACTION.MOVE] ||
                !this.hasPermissionFolder(RIGHTS_FOLDER.WORKFLOW_ACTION);
            this.actionDisable[EMAIL_ACTION.SPAM] =
                this.actionDisable[EMAIL_ACTION.SPAM] ||
                !this.hasPermissionFolder(RIGHTS_FOLDER.WORKFLOW_ACTION);
            this.actionDisable[EMAIL_ACTION.DELETE] =
                this.actionDisable[EMAIL_ACTION.DELETE] ||
                !this.hasPermissionFolder(RIGHTS_FOLDER.WORKFLOW_ACTION);
            this.actionDisable[EMAIL_ACTION.MORE_ACTION] =
                this.actionDisable[EMAIL_ACTION.MORE_ACTION] ||
                !this.hasPermissionFolder(RIGHTS_FOLDER.WORKFLOW_ACTION);
            this.actionDisable[EMAIL_ACTION.ARCHIVE] =
                this.actionDisable[EMAIL_ACTION.ARCHIVE] || this.isArchive;
        },
        actiondAssginOrRemoveTag(tag, isAssgin) {
            this.handledAssginOrRemoveTag(tag, isAssgin);
            if (this.isNewWindow) {
                if (isAssgin) {
                    this.listTagAssgin.push(tag);
                } else {
                    this.listTagAssgin = this.listTagAssgin.filter(
                        (x) => x.id != tag.id
                    );
                }
            }
        },
        handleSearch(data) {
            this.visibleDialogSearchBox = false;
            const listQuery = [];
            const paramAgrs = {}
            if (data.tocc) {
                listQuery.push(`tocc:${data.tocc}`);
            }
            if (data.from) {
                listQuery.push(`from:${data.from}`);
            }
            if (data.fromOrToContact && data.fromOrToContact.length) {
                const _type = data.type
                data.fromOrToContact.forEach((email, index) => {
                    if (index > 0) listQuery.push(`OR ${_type}:${email}`)
                    else listQuery.push(`${_type}:${email}`)
                })
                if (data.header) paramAgrs["header"] = data.header
                if (data.sortBy) paramAgrs["sortBy"] = data.sortBy
                if (data.tz) paramAgrs["tz"] = data.tz
                if (data.locale) paramAgrs["locale"] = data.locale
                if (data.recip) paramAgrs["recip"] = data.recip
            }
            if (data.to) {
                listQuery.push(`to:${data.to}`);
            }
            if (data.subject) {
                listQuery.push(`subject:${data.subject}`);
            }
            if (data.hasTheWords) { /// tách các từ khi tìm kiếm
                data.hasTheWords?.trim().split(" ").forEach((str) => {
                    if (str.trim().length > 0) {
                        listQuery.push(`${str.trim()}`)
                    }
                });
            }
            if (data.doesNotHave) {
                data.doesNotHave?.trim().split(" ").forEach((str) => {
                    if (str.trim().length > 0) {
                        listQuery.push(`(not ${str.trim()})`)
                    }
                });
            }
            if (data.hasAttachment) {
                listQuery.push(`has:attachment`);
            }
            if (data.size && data.sizeAttach && data.sizeUnit) {
                listQuery.push(
                    `${data.size}:${data.sizeAttach}${data.sizeUnit}`
                );
            }
            if (data.time && data.date) {
                listQuery.push(`${data.time}:${data.date}`);
            }
            if (data.searchIn) {
                if (data.searchIn.absFolderPath === "/Flagged") {
                    listQuery.push(`is:flagged`);
                } else {
                    listQuery.push(`in:"${data.searchIn.absFolderPath}"`);
                }
            }
            const query = listQuery.map((e) => _.escape(e)).join(" ");
            this.$store.commit("SET_LIST_QUERY", listQuery);
            this.$store.commit("SET_LIST_QUERY_TEMP", listQuery);
            this.$store.commit("SET_SHOW_SEARCH_BOX", true);
            this.$store.commit("SET_MODEL_SEARCH", data);
            this.$root.$emit("handleSearchBox", query, paramAgrs);
        },
        handldeSearchKeyword() {
            const _listQuery = [];
            const _listStrQuery = this.hasTheWords?.trim().split(" ");
            _listStrQuery.forEach((str) => {
                if (str.trim().length > 0) {
                    // _listQuery.push(`"${str.trim()}"`);
                    _listQuery.push(`${str.trim()}`) // sửa bỏ dấu nháy kép thừa
                }
            });
            this.$root.$emit("handleSearchKeyWord", _listQuery.join(" "));
            this.$store.commit("SET_LIST_QUERY_TEMP", _listQuery);
            this.hasTheWords = "";
        },
        async initKeywordSearch() {
            await this.getAllGeneral().then(() => {
                if (this.showSearchStringCurrent.content)
                    this.hasTheWords = this.mailInitialSearch.content;
            });
        },
        handleFocus(e) {
            e.preventDefault();
            this.$refs.searchbar.focus();
        },
        isFolder(id) {
            return this.activeNodeSidebar?.id === id;
        },
        oncloseNewWindow() {
            window.close();
        },
        handleNavbarAction(actionType) {
            if (this.isNewWindow) {
                this.$emit("handleNavbarAction", actionType);
            } else {
                this.$root.$emit("handleNavbarAction", actionType);
            }
        },
        showOriginal() {
            if (this.isNewWindow) {
                this.showMailOriginalNewWindow(this.dataMailNewWindow);
            } else {
                if (this.currentActiveEmail) {
                    this.showMailOriginalNewWindow(this.currentActiveEmail.data);
                }
            }
        },
        handleFocusOut() {
            this.visablePopoverMove = false;
            this.visablePopover = false;
        },
        handleFocusSearchInput() {
            this.$refs.searchbar.focus();
        },
        /**
         * Cập nhật giao diện hiện thời
         */
        checkMailPrefUpdate() {
            this.$root.$emit("handleUpdateView");
        }
    },
    destroyed() {
        this.$root.$off("handleClearSearchHighlights", this.handleFocusSearchInput);
    }
};
</script>
<style lang="scss" scoped>
.navbar-navigation {
    & .left {
        width: 270px;

        & .btn-compose {
            margin-left: 12px;
            background-color: var(--color-primary);
            border-color: var(--color-primary);
        }
    }

    & .right {
        & .item {
            display: flex;
            align-items: center;
            margin-right: 35px;
            cursor: pointer;

            & i {
                width: 20px;
                height: 18px;
            }

            & span {
                margin: 5px 0 0 5px;
                font-weight: 400;
                font-style: 16px;
            }
        }
    }
}
</style>
