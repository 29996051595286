<template>
    <el-aside :class="['sidebar-wrap', 'left']">
            <div>
            <el-menu :collapse="!isCollapse" :default-openeds="['1', '3']">
                <el-menu-item index="0" class="menu-heading">
                    <router-link
                        v-show="!menuCollapse"
                        to="/mail/email"
                        class="navbar-brand"
                    >
                        <img src="@/assets/images/login/logo_vmail.svg" class="icon-brand" />
                    </router-link>
                    <!-- <i @click="isCollapse = !isCollapse" class="el-icon-s-fold"></i> -->
                </el-menu-item>
                <h2 v-show="!menuCollapse" class="title text-uppercase">{{$t("zimbraNokey.zimbra_zimbraNokey_personalMailbox")}}</h2>
                <div :class="['btn-expand-content', menuCollapse ? 'button-menu-expand' : 'button-menu-collapse']">
                    <a href="javascript:;" @click="handleToggleMenuCollapse" class="focusable">
                        <i :class="['ic', menuCollapse ? 'ic-menu-expand' : 'ic-menu-collapse']"></i>
                    </a>
                </div>
            </el-menu>
        <!-- Dung lượng mail start -->
        <!-- không hiện với tài khoản không giới hạn -->
            <div class="zm-mail-quota" v-if="mailQuota.zimbraMailQuota && !menuCollapse">
                <el-progress
                    :percentage="percentageMailQuota"
                    :show-text="false"
                    :status="statusMailQuota"
                ></el-progress>
                <span class="text-quota-text">{{ mailQuotaText }}</span>
            </div>
            <div class="zm-mail-quota" v-else-if="mailQuota.zimbraMailQuota">
                <el-progress
                 type="circle"
                 :percentage="percentageMailQuota"
                 :width="45">
                 </el-progress>
            </div>
            <!-- Dung lượng mail end -->
            </div>
            <perfect-scrollbar class="tree-folder">
            <TreeFolder
                :highlight-default="true"
                :show-info-folder="showInfoFolder"
            ></TreeFolder>
            </perfect-scrollbar>
            <MenuChatLeft/>
            <RightMenuSidebar></RightMenuSidebar>
            <RightMenuTag></RightMenuTag>
            <!-- menu di chuyển mail -->
            <DialogEditFolderSearch></DialogEditFolderSearch>
            <DialogAddFolder ref="dialogAddFolder"></DialogAddFolder>
            <DialogEditName></DialogEditName>
            <DialogMoveFolder></DialogMoveFolder>
            <!-- <DialogAddMoveFolder></DialogAddMoveFolder> -->
            <DialogShareFolder></DialogShareFolder>
            <DialogEditFolder></DialogEditFolder>
            <DialogZimletYahoo></DialogZimletYahoo>
            <DialogZimletArchire></DialogZimletArchire>
            <DialogRedirect></DialogRedirect>
            <RightMenuMailFolder></RightMenuMailFolder>
            <DialogAddShareFolder></DialogAddShareFolder>
            <DialogFolderSelector></DialogFolderSelector>
            <!-- bộ lọc -->
            <DialogFilterActivityStream></DialogFilterActivityStream>
            <base-dialog-filter>
            </base-dialog-filter>
            <DialogCreateFolder />
            <DialogWebexPreferences/>
            <!-- ==== -->

    </el-aside>
</template>

<script>
import DialogWebexPreferences from "@/components/modules/calendar/components/dialogs/dialog-webex-preferences.vue"
import DialogFolderSelector from "./components/DialogFolderSelector";
import DialogAddShareFolder from "./components/DialogAddShareFolder.vue";
import DialogRedirect from "./components/DialogRedirect";
import RightMenuSidebar from "./components/RightMenuSidebar";
import DialogEditFolderSearch from "./components/DialogEditFolderSearch";
import RightMenuTag from "./components/RightMenuTag";
import DialogEditFolder from "./components/DialogEditFolder";
import DialogShareFolder from "./components/DialogShareFolder";
import DialogMoveFolder from "./components/DialogMoveFolder";
import DialogAddFolder from "./components/DialogAddFolder";
import DialogAddMoveFolder from "./components/DialogAddMoveFolder";
import DialogZimletYahoo from "./components/DialogZimletYahoo";
import DialogZimletArchire from "./components/DialogZimletArchire";
import DialogFilterActivityStream from "@/components/modules/layouts/components/setting/filter/DialogFilterActivityStream.vue";
import BaseDialogFilter from "@/components/modules/base/base-dialog-filter";
import DialogCreateFolder from "@/components/modules/layouts/components/DialogCreateFolder.vue";
import TreeFolder from "./components/side-bar-left/TreeFolder";
import MenuChatLeft from "./components/side-bar-left/MenuChatLeft";
import DialogEditName from "./components/DialogEditName";
import CommonUtils from "@/utils/common-utils";
import EmailUtils from "@/utils/email-utils";
import AjxUtil from "@/utils/zimbra-lib/AjxUtil";
import RightMenuMailFolder from "./components/RightMenuMailFolder";
import { mapGetters, mapActions } from "vuex";


export default {
    mixins: [CommonUtils, EmailUtils],
    components: {
        RightMenuSidebar,
        DialogAddFolder,
        DialogEditName,
        TreeFolder,
        MenuChatLeft,
        RightMenuTag,
        DialogMoveFolder,
        DialogAddMoveFolder,
        DialogShareFolder,
        DialogEditFolder,
        DialogZimletYahoo,
        DialogZimletArchire,
        DialogEditFolderSearch,
        DialogRedirect,
        RightMenuMailFolder,
        DialogAddShareFolder,
        DialogFilterActivityStream,
        DialogFolderSelector,
        BaseDialogFilter,
        DialogCreateFolder,
        DialogWebexPreferences
    },
    computed: {
        ...mapGetters([
            "mailQuota",
            "menuCollapse",
            "isLoadDataLogin"
        ]),
        percentageMailQuota() {
            const { zimbraMailQuota, used } = this.mailQuota;
            return Math.round(zimbraMailQuota ? used / zimbraMailQuota * 100 : 0);
        },
        statusMailQuota() {
            const { zimbraMailQuota, used } = this.mailQuota;
            let progressClassName = "success";
            if (!zimbraMailQuota && !used) {
                return progressClassName;
            }
            const percent = parseInt(used / zimbraMailQuota * 100);
            if (percent < 85 && percent > 65) {
                progressClassName = "warning";
            } else if (percent >= 85) {
                progressClassName = "exception";
            }
            return progressClassName;
        },
        mailQuotaText() {
            const { zimbraMailQuota, used } = this.mailQuota;
            if (!zimbraMailQuota && !used) {
                return "";
            }
            const sizeQuota = AjxUtil.formatSize(zimbraMailQuota);
            const sizeUsed = AjxUtil.formatSize(used);
            if (zimbraMailQuota == 0) {
                return this.$t("zimbra.zmMsg.usingDescUnlimited", [`${sizeUsed.size} ${sizeUsed.units}`]);
            }
            return this.$t("zimbra.zmMsg.usingDescLimited",
                [
                    `${sizeUsed.size} ${sizeUsed.units}`,
                    `(${Math.round(used / zimbraMailQuota * 100)}%)`,
                    `${sizeQuota.size} ${sizeQuota.units}`
                ]);
        }
    },
    data() {
        return {
            isCollapse: true,
            dataTag: [],
            dataInBox: [],
            dataExtend: [],
            defaultProps: {
                children: "folder",
                label: "name",
            },
            listFolderIgnore: [16, 10, 14, 7, 13, 15],
            folderArchive: {},
            showInfoFolder: true,
        };
    },
    mounted() {
        this.$root.$on("showInfoFolder", (val) => {
            this.showInfoFolder = val;
        });
        this.$root.$on("updateItemToDataInBox", (itemUpdate) => {
            this.updateItemToData(itemUpdate, this.dataSidebarInBox);
        });
        this.$root.$on("updateItemToDataExtend", (itemUpdate) => {
            this.updateItemToData(itemUpdate, this.dataSidebarExtend);
        });
        this.$root.$on("addItemToDataInBox", (itemAdd) => {
            this.addItemToData(itemAdd, this.dataSidebarInBox);
        });
        this.$root.$on("addItemToDataExtend", (itemAdd) => {
            this.addItemToData(itemAdd, this.dataSidebarExtend);
        });
        this.$root.$on("deleteItemToDataInBox", (itemAdd) => {
            const data = this.deleteItemToData(itemAdd, this.dataSidebarInBox);
            if (data) {
                this.$store.commit("SET_DATA_INBOX", data);
            }
        });
        this.$root.$on("deleteItemToDataExtend", (itemAdd) => {
            this.deleteItemToData(itemAdd, this.dataSidebarExtend);
        });

    },
    watch: {
        isLoadDataLogin: {
            immediate: true,
            handler() {
                this.getFolderArchiveApi();
                this.getTagApi();
            }
        }
    },
    async created() {
        // this.getFolderApi();
    },
    methods: {
        ...mapActions(["actionToggleMenuCollapse"]),
        handleToggleMenuCollapse() {
            this.$store.commit("TOGGLE_MENU_COLLAPSE")
            this.addLocalStorage("menuCollapse", this.menuCollapse)
        }
    },
};
</script>

<style lang="scss">
</style>
