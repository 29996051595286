<template>
    <el-dialog
        :title="$t('zimbra.zmMsg.appointmentReminder')"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        :show-close="false"
        class="zimbra-dialog zm-calendar"
        @close="handleClose"
        width="800px"
    >
        <!-- 1 cảnh báo -->
        <div
            class="event-info dialog-warning"
            v-if="this.listAppoinmentWarning.length === 1"
        >
            <div
                v-for="warningitem in this.listAppoinmentWarning"
                :key="warningitem.id"
            >
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <a
                            href="javascript:;"
                            class="text-primary"
                            @click="actionMoreDetail(warningitem)"
                        >
                            {{ warningitem.name }}</a
                        >
                    </div>
                    <div class="col-sm-12 col-md-6 text-right">
                        <span
                            class="color-warning"
                            v-if="checkTime(warningitem) > 0"
                        >
                            Quá hạn {{ getTimeOut(warningitem) }}
                        </span>
                        <span
                            class="color-warning"
                            v-if="checkTime(warningitem) < 0"
                        >
                            Trong
                            {{ getTimeOut(warningitem) }}
                        </span>
                        <span
                            class="color-warning"
                            v-if="checkTime(warningitem) === 0"
                        >
                            Bây giờ</span
                        >
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-8 d-flex">
                        <ConvertDateToText
                            :time="warningitem.alarmData.alarmInstStart"
                            key="1"
                        />
                        tới
                        <ConvertDateToText
                            :time="
                                warningitem.alarmData.alarmInstStart +
                                warningitem.dur
                            "
                            :showOnlyTime="true"
                            key="2"
                        />
                    </div>
                </div>
                <div v-if="warningitem.l && warningitem.l !== typeDefault">
                    <span>{{ $t("zimbra.zhMsg.calendarLabel") }} </span
                    >{{ getNameCalendarFolderById(warningitem.l) }}
                </div>
                <div v-if="warningitem.loc">
                    <span>{{ $t("zimbra.zhMsg.locationLabel") }} </span
                    >{{ warningitem.loc }}
                </div>
                <hr class="mb-3" />
                <div class="row">
                    <div class="col-4 zm-label">
                        {{ $t("zimbra.zmMsg.snoozeFor") }}
                    </div>
                    <div class="col-5 pr-10">
                        <el-select class="w-100" v-model="warningitem.alarm">
                            <el-option
                                v-for="(item, idx) in snoozeTime(warningitem)"
                                :key="idx"
                                :label="item.label"
                                :value="item.value"
                            >
                                <span>
                                    {{ item.label }}
                                </span>
                            </el-option>
                        </el-select>
                    </div>
                    <div class="col-3 d-flex p-0">
                        <el-button
                            class="btn-cancel"
                            @click="snoozeCalendar(warningitem)"
                        >
                            {{ $t("zimbra.zmMsg.snooze") }}
                        </el-button>
                        <el-button
                            class="btn-cancel"
                            @click="dismissCalendar(warningitem)"
                        >
                            {{ $t("zimbra.zmMsg.dismiss") }}
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- nhiều cảnh báo -->
        <div
            class="event-info dialog-warning"
            v-if="this.listAppoinmentWarning.length > 1"
        >
            <div
                v-for="warItem in this.listAppoinmentWarning"
                :key="warItem.id"
            >
                <div class="row">
                    <div class="col-sm-12 col-md-5">
                        <a
                            href="javascript:;"
                            class="text-primary"
                            @click="actionMoreDetail(warItem)"
                        >
                            {{ warItem.name }}</a
                        >

                        <div class="d-flex">
                            <ConvertDateToText
                                :time="warItem.alarmData.alarmInstStart"
                                key="1"
                            />
                            tới
                            <ConvertDateToText
                                :time="
                                    warItem.alarmData.alarmInstStart +
                                    warItem.dur
                                "
                                :showOnlyTime="true"
                                key="2"
                            />
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 p-0">
                        <el-select class="w-100" v-model="warItem.alarm">
                            <el-option
                                v-for="(item, idx) in snoozeTime(warItem)"
                                :key="idx"
                                :label="item.label"
                                :value="item.value"
                            >
                                <span>
                                    {{ item.label }}
                                </span>
                            </el-option>
                        </el-select>
                    </div>
                    <div class="col-sm-12 col-md-3 pl-4">
                        <el-button
                            class="btn-cancel ml-2"
                            @click="snoozeCalendar(warItem)"
                        >
                            {{ $t("zimbra.zmMsg.snooze") }}
                        </el-button>
                        <el-button
                            class="btn-cancel ml-2"
                            @click="dismissCalendar(warItem)"
                        >
                            {{ $t("zimbra.zmMsg.dismiss") }}
                        </el-button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-9">
                        <div
                            v-if="warItem.l && warItem.l !== typeDefault"
                            class=""
                        >
                            <span>{{ $t("zimbra.zhMsg.calendarLabel") }} </span
                            >{{ getNameCalendarFolderById(warItem.l) }}
                        </div>
                        <div v-if="warItem.loc" class="">
                            <span>{{ $t("zimbra.zhMsg.locationLabel") }} </span
                            >{{ warItem.loc }}
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 p-0 pr-4 text-right">
                        <span
                            class="color-warning"
                            v-if="checkTime(warItem) > 0"
                        >
                            Quá hạn {{ getTimeOut(warItem) }}
                        </span>
                        <span
                            class="color-warning"
                            v-if="checkTime(warItem) < 0"
                        >
                            Trong {{ getTimeOut(warItem) }}
                        </span>
                        <span
                            class="color-warning"
                            v-if="checkTime(warItem) === 0"
                        >
                            Bây giờ</span
                        >
                    </div>
                </div>
                <hr class="mt-3" />
            </div>
            <div class="row">
                <div class="col-3 zm-label">
                    {{ $t("zimbra.zmMsg.snoozeAll") }}
                </div>
                <div class="col-5">
                    <el-select class="w-100" v-model="alarmAll">
                        <el-option
                            v-for="(item, idx) in SNOOZE_TIME"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"
                        >
                            <span>
                                {{ item.label }}
                            </span>
                        </el-option>
                    </el-select>
                </div>
                <div class="col-4 d-flex">
                    <el-button class="btn-cancel" @click="snoozeCalendarAll()">
                        {{ $t("zimbra.zmMsg.snoozeAllLabel") }}
                    </el-button>
                    <el-button class="btn-cancel" @click="dismissCalendarAll()">
                        {{ $t("zimbra.zmMsg.dismissAll") }}
                    </el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import CalendarUtils from "@/utils/calendar-utils";
import CommonUtils from "@/utils/common-utils";
import { mapGetters } from "vuex";
import {
    CALENDAR_FREQ_TYPE,
    CALENDAR_FREE_BUSY_TYPE,
    FOLDER_DEFAULT,
    SNOOZE_TIME,
    REMINDER_SNOOZE_TIME
} from "@/utils/constants.js";
import ZmMimeTable from "@/utils/zimbra-lib/ZmMimeTable";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import ConvertDateToText from "./convert-date-to-text.vue";
import moment from "moment";
import soundWarningAppt from '@/assets/sound-warning-appt.mp3'
export default {
    name: "DialogWarningCalendar",
    mixins: [CommonUtils, CalendarUtils],
    props: {},
    components: {
        ConvertDateToText,
    },
    created() {},
    computed: {
        ...mapGetters(["listAppoinmentWarning",]),
    },
    methods: {
        snoozeTime(data){
            let SNOOZE_TIME_TMP = [];
            SNOOZE_TIME_TMP.push(...this.SNOOZE_TIME) ;
            const dur = Math.floor( (this.dateNow - data.alarmData.alarmInstStart) / (1000 * 60));
            if(dur < 0){ // báo trước
               const tem = this.REMINDER_SNOOZE_TIME.filter( x => x.value > dur);
               SNOOZE_TIME_TMP.push(...tem);
            }
            return SNOOZE_TIME_TMP.sort((a,b) => a.value - b.value);
        },
        open() {
            this.dialogVisible = true;
            const soundsEnabled = this.$store.getters.preference.zimbraPrefCalendarReminderSoundsEnabled;
            if (soundsEnabled) {
                const audio = new Audio(soundWarningAppt)
                audio.play()
            }
            //Xử lý nhấp nháy tiêu đề khi có thông báo
            const reminderFlashTitle = this.$store.getters.preference.zimbraPrefCalendarReminderFlashTitle;
            if (reminderFlashTitle && !document.hasFocus()) {
                this.flashingTitle.originalTitle = document.title;
                const _this = this;
                _this.flashingTitle.interval = setInterval(function () {
                    document.title = (_this.flashingTitle.originalTitle == document.title)
                                        ? _this.$t("zimbra.zmMsg.reminder")
                                        : _this.flashingTitle.originalTitle;
                    if (document.hasFocus()) {
                        clearInterval(_this.flashingTitle.interval)
                        document.title = _this.flashingTitle.originalTitle;
                    }
                }, 1500);
            }
        },
        actionMoreDetail(data) {
            ZimbraMailService.getMsgRequest({ id: data.invId }).then(
                (msgRequest) => {
                    const { m } =
                        CommonUtils.methods.getResponseBody(msgRequest)[
                            "GetMsgResponse"
                        ];
                    this.modelData = this.initialDataForEdit(m);
                    this.dialogVisible = false;
                    this.$emit("fActionMoreDetail", this.modelData);

                    
                }
            ).then(this.snoozeCalendar(data)) // báo lại khi click xem chi tiết);
        },
        handleClose() {
            this.dialogVisible = false;
        },
        checkTime(warItem) {
            return Math.floor( (this.dateNow - warItem.alarmData.alarmInstStart) / (1000 * 60) );
        },
        getTimeOut(warItem) {
            const datebefore = new moment(warItem.alarmData.alarmInstStart);
            const datebenow = new moment(this.dateNow);
            const diff = Math.abs(datebenow.diff(datebefore));
            var duration = moment.duration(diff);
            const lstTime = [];

            if (duration._data.days > 0) {
                lstTime.push(duration._data.days + " ngày");
            }
            if (duration._data.hours > 0) {
                lstTime.push(duration._data.hours + " giờ");
            }
            if (duration._data.minutes > 0 && this.checkTime(warItem) > 0) {
                lstTime.push(duration._data.minutes + " phút");
            }
            if (this.checkTime(warItem) < 0) {
                lstTime.push(duration._data.minutes + 1 + " phút");
            }
            return lstTime.join(", ");
        },

        snoozeCalendar(data) {
            const dur = Math.floor( (this.dateNow - data.alarmData.alarmInstStart) / (1000 * 60));
            let until ;
            if(dur >= 0){
                until = new Date().getTime() + data.alarm * 60 * 1000
            }else{
                until = data.alarmData.alarmInstStart + data.alarm * 60 * 1000;
            }
            const fomData = {
                appt: [
                    {
                        id: String(data.id),
                        until: String( until ),
                    },
                ],
            };
            ZimbraMailService.snoozeCalendarItemAlarmRequest(fomData)
                .then((res) => {
                    let snoozeCalendar =
                        this.getResponseBody(res)[
                            "SnoozeCalendarItemAlarmResponse"
                        ]["appt"];
                    const index = this.listAppoinmentWarning.findIndex(
                        (x) => x.id === snoozeCalendar.calItemId
                    );
                    this.listAppoinmentWarning.splice(index, 1);

                    if (this.listAppoinmentWarning.length === 0) {
                        this.handleClose();
                    }
                    this.$root.$emit("searchListEventToWarning");
                })
                .catch((err) => {
                    console.log("có lỗi xảy ra", err);
                });
        },
        dismissCalendar(data) {
            const fomData = {
                appt: [
                    {
                        id: String(data.id),
                        dismissedAt: String(new Date().getTime()),
                    },
                ],
            };
            ZimbraMailService.dismissCalendarItemAlarmRequest(fomData)
                .then((res) => {
                    let dismissCalendar =
                        this.getResponseBody(res)[
                            "DismissCalendarItemAlarmResponse"
                        ]["appt"];
                    const index = this.listAppoinmentWarning.findIndex(
                        (x) => x.id === dismissCalendar.calItemId
                    );
                    this.listAppoinmentWarning.splice(index, 1);
                    if (this.listAppoinmentWarning.length === 0) {
                        this.handleClose();
                    }
                    this.$root.$emit("searchListEventToWarning");
                })
                .catch((err) => {
                    console.log("có lỗi xảy ra", err);
                });
        },
        snoozeCalendarAll() {
            const apptData = [];
            this.listAppoinmentWarning.forEach((e) => {
                apptData.push({
                    id: String(e.id),
                    until: String(
                        new Date().getTime() + this.alarmAll * 60 * 1000
                    ),
                });
            });
            ZimbraMailService.snoozeCalendarItemAlarmRequest({ appt: apptData })
                .then((res) => {
                    let snoozeCalendar =
                        this.getResponseBody(res)[
                            "SnoozeCalendarItemAlarmResponse"
                        ]["appt"];
                    if (snoozeCalendar) {
                        this.handleClose();
                    }
                })
                .catch((err) => {
                    console.log("có lỗi xảy ra", err);
                });
        },
        dismissCalendarAll() {
            const apptData = [];
            this.listAppoinmentWarning.forEach((e) => {
                apptData.push({
                    id: String(e.id),
                    dismissedAt: String(new Date().getTime()),
                });
            });
            ZimbraMailService.dismissCalendarItemAlarmRequest({
                appt: apptData,
            })
                .then((res) => {
                    let dismissCalendar =
                        this.getResponseBody(res)[
                            "DismissCalendarItemAlarmResponse"
                        ]["appt"];
                    if (dismissCalendar) {
                        this.handleClose();
                    }
                })
                .catch((err) => {
                    console.log("có lỗi xảy ra", err);
                });
        },
    },
    mounted() {
        setInterval(() => {
            this.dateNow = new Date().getTime();
        }, 3000);
    },
    data() {
        return {
            modelData: {
                fb: CALENDAR_FREE_BUSY_TYPE.BUSY,
                l: FOLDER_DEFAULT.CALENDAR,
                allDay: false,
                freq: CALENDAR_FREQ_TYPE.NONE,
                alarm: 5,
                contentType: ZmMimeTable.TEXT_HTML,
                listAttachment: [],
                customRepeat: null, // tùy chỉnh lặp
            },
            dialogVisible: false,
            SNOOZE_TIME: SNOOZE_TIME,
            warningitem: {},
            dateNow: new Date().getTime(),
            alarmAll: 1,
            typeDefault: FOLDER_DEFAULT.CALENDAR,
            REMINDER_SNOOZE_TIME: REMINDER_SNOOZE_TIME,
            flashingTitle:{
                originalTitle: document.title,
                interval: null
            }
        };
    },
    watch: {
        listAppoinmentWarning(data) {
            if (data.length > 0) {
                this.open();
            }
        },
    },
    destroyed() {},
};
</script>