<template>
    <el-card shadow="never">
        <div>
            <table class="card-filter">
                <tr
                    class="card-filter-main"
                    v-for="(item, index) in listBuildConditions"
                    :key="index"
                >
                    <!-- ADDRESS-TEST -->
                    <address-test
                        v-if="item.types === FILTER_TESTS.ADDRESS_TEST"
                        :conditionsTest="item"
                        ref="test"
                        @onChangeConditionsTest="onChangeConditionsTest"
                    >
                    </address-test>
                    <!-- HEADER-TEST -->
                    <header-test
                        v-else-if="
                            item.types === FILTER_TESTS.HEADER_TEST &&
                            item.header === 'subject'
                        "
                        :conditionsTest="item"
                        @onChangeConditionsTest="onChangeConditionsTest"
                    >
                    </header-test>
                    <!-- MESSAGE-TEST -->
                    <message-test
                        v-else-if="
                            item.types === FILTER_TESTS.CONVERSATION_TEST ||
                            item.types === FILTER_TESTS.BULK_TEST ||
                            item.types === FILTER_TESTS.LIST_TEST ||
                            item.types === FILTER_TESTS.FLAGGED_TEST ||
                            item.types === FILTER_TESTS.IMPOTAMCE_TEST
                        "
                        :conditionsTest="item"
                        @onChangeConditionsTest="onChangeConditionsTest"
                    >
                    </message-test>
                    <!-- SIZE-TEST -->
                    <size-test
                        v-else-if="item.types === FILTER_TESTS.SIZE_TEST"
                        :conditionsTest="item"
                        @onChangeConditionsTest="onChangeConditionsTest"
                    >
                    </size-test>
                    <!-- DATE-TEST -->
                    <date-test
                        v-else-if="item.types === FILTER_TESTS.DATE_TEST"
                        :conditionsTest="item"
                        @onChangeConditionsTest="onChangeConditionsTest"
                    >
                    </date-test>
                    <!-- BODY-TEST -->
                    <body-test
                        v-else-if="item.types === FILTER_TESTS.BODY_TEST"
                        :conditionsTest="item"
                        @onChangeConditionsTest="onChangeConditionsTest"
                    >
                    </body-test>
                    <!-- ATTACHMENT-TEST -->
                    <attachment-test
                        v-else-if="item.types === FILTER_TESTS.ATTACHMENT_TEST"
                        :conditionsTest="item"
                        @onChangeConditionsTest="onChangeConditionsTest"
                    >
                    </attachment-test>
                    <!-- MIME-HEADER-TEST -->
                    <mime-header-test
                        v-else-if="item.types === FILTER_TESTS.MIME_HEADER_TEST"
                        :conditionsTest="item"
                        @onChangeConditionsTest="onChangeConditionsTest"
                    >
                    </mime-header-test>
                    <!-- ADDRESS-BOOK-TEST -->
                    <address-in-test
                        v-else-if="
                            item.types === FILTER_TESTS.ADDRESS_BOOK_TEST ||
                            item.types === FILTER_TESTS.CONTACT_RANK_TEST ||
                            item.types === FILTER_TESTS.ME_TEST
                        "
                        :conditionsTest="item"
                        @onChangeConditionsTest="onChangeConditionsTest"
                    >
                    </address-in-test>
                    <!-- SOCIAL-TEST -->
                    <social-test
                        v-else-if="
                            item.types === FILTER_TESTS.FACEBOOK_TEST ||
                            item.types === FILTER_TESTS.LINKEDIN_TEST ||
                            item.types === FILTER_TESTS.TWITTER_TEST
                        "
                        :conditionsTest="item"
                        @onChangeConditionsTest="onChangeConditionsTest"
                    >
                    </social-test>
                    <!-- INVITE-TEST -->
                    <invite-test
                        v-else-if="item.types === FILTER_TESTS.INVITE_TEST"
                        :conditionsTest="item"
                        @onChangeConditionsTest="onChangeConditionsTest"
                    >
                    </invite-test>
                    <!-- HEADER-NAME-TEST -->
                    <header-name-test
                        v-else-if="
                            (item.types === FILTER_TESTS.HEADER_TEST &&
                                item.header != 'subject') ||
                            item.types === FILTER_TESTS.HEADER_EXITS_TEST
                        "
                        :conditionsTest="item"
                        @onChangeConditionsTest="onChangeConditionsTest"
                    >
                    </header-name-test>
                    <!-- <div class="card-filter-item" style="width:5%;"></div> -->
                    <div class="card-filter-item">
                        <div class="icon-close-check">
                            <el-button
                                icon="el-icon-close"
                                :disabled="handleCheckingButtonRemove"
                                @click="handleRemoveFilterItem(item.index)"
                            ></el-button>
                        </div>
                    </div>
                </tr>
            </table>
        </div>
        <div class="add-filter" @click="handleAddFormFilter">
            <i class="el-icon-plus"></i>
            <label for="">{{
                $t("zimbra.zmMsg.addFilter")
            }}</label>
        </div>
    </el-card>
</template>
<script>
import AddressTest from "@/components/modules/base/base-filter-form/address-test";
import AddressInTest from "@/components/modules/base/base-filter-form/address-in-test";
import SocialTest from "@/components/modules/base/base-filter-form/social-test";
import InviteTest from "@/components/modules/base/base-filter-form/invite-test";
import HeaderTest from "@/components/modules/base/base-filter-form/header-test";
import HeaderNameTest from "@/components/modules/base/base-filter-form/header-name-test";
import SizeTest from "@/components/modules/base/base-filter-form/size-test";
import DateTest from "@/components/modules/base/base-filter-form/date-test";
import BodyTest from "@/components/modules/base/base-filter-form/body-test";
import AttachmentTest from "@/components/modules/base/base-filter-form/attachment-test";
import MimeHeaderTest from "@/components/modules/base/base-filter-form/mime-header-test";
import MessageTest from "@/components/modules/base/base-filter-form/message-test";
import _ from "lodash";
import { FILTER_TESTS } from "@/utils/constants";
function compare(a, b) {
    if (a.index < b.index) {
        return -1;
    }
    if (a.index > b.index) {
        return 1;
    }
    return 0;
}
export default {
    props: {
        filterTests: {
            type: [Object],
        },
    },
    data() {
        return {
            FILTER_TESTS: FILTER_TESTS,
            mapProperties: {
                addressTest: "addressTest",
            },
            listBuildConditions: [],
            ignoreProps: ["condition"],
            conditionsTest: {},
        };
    },
    mounted() {
        this.listBuildConditions = this.buildConditions(this.filterTests);
    },
    updated() {
    },
    computed: {
        handleCheckingButtonRemove() {
            if (this.listBuildConditions.length > 1) return false;
            return true;
        },
    },
    watch: {
        filterTests(newValue) {
            this.listBuildConditions = this.buildConditions(newValue);
        },
    },
    methods: {
        handleAddFormFilter() {
            this.$emit("handleAddFormFilter");
        },
        buildConditions(filterTests) {
            let list = [];
            for (let k in filterTests) {
                if (this.ignoreProps.indexOf(k) > -1) {
                    continue;
                }
                const item = filterTests[k];
                if (Array.isArray(item)) {
                    for (let i in item) {
                        item[i].types = k;
                        list.push(item[i]);
                    }
                } else {
                    item.types = k;
                    list.push(item);
                }
            }
            list.sort(compare);
            return list;
        },
        onChangeConditionsTest(data) {
            const newListBuildConditions = this.listBuildConditions.map(
                (item) => {
                    if (item.index === data.index) {
                        item = data;
                    }
                    return item;
                }
            );
            this.listBuildConditions = _.cloneDeep(newListBuildConditions);
            this.$root.$emit("handleUpdateFilterTests",this.listBuildConditions);
        },
        handleRemoveFilterItem(index) {
            const newListBuildConditions = this.listBuildConditions.filter(
                (item) => item.index !== index
            );
            this.$root.$emit("handleUpdateFilterTests", newListBuildConditions);
        },
    },
    components: {
        AddressTest,
        HeaderTest,
        SizeTest,
        DateTest,
        BodyTest,
        AttachmentTest,
        MimeHeaderTest,
        AddressInTest,
        SocialTest,
        InviteTest,
        HeaderNameTest,
        MessageTest,
    },
};
</script>
