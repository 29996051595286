var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zm-suggestion-info white-space-normal"},[_c('div',{staticClass:"zm-suggestion-header d-flex justify-content-between"},[_c('div',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',[_c('a',{attrs:{"href":"javascript:;"},on:{"click":_vm.openSettingPrefence}},[_c('i',{staticClass:"ic ic-setting p-3"})]),_c('a',{staticClass:"btn-close ml-2",attrs:{"href":"javascript:;"},on:{"click":_vm.closeSuggest}},[_c('i',{staticClass:"ic ic-close-gray p-3"})])])]),_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"p-3"},[_c('el-skeleton',{attrs:{"rows":6,"loading":_vm.loading,"animated":""}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[(_vm.suggestType === 'time')?[_c('div',{staticClass:"pt-2"},[_c('CalendarMini',{attrs:{"free-busy-cal":_vm.freeBusyCal,"start-date":_vm.startDate},on:{"onSelectDate":_vm.onSelectDate}})],1),(_vm.listSuggestion && _vm.listSuggestion.length > 0)?[_c('ul',[_c('perfect-scrollbar',{staticClass:"zimbra-suggest-results"},_vm._l((_vm.listSuggestion),function(item,idx){return _c('div',{key:'sugget_' + idx,class:item.class},[_c('li',{staticClass:"active ls-header-label",domProps:{"innerHTML":_vm._s(_vm.$t('zimbra.zmMsg.availableCount', [item.count, _vm.emailList.length]))}}),_vm._l((item.lstResult),function(useTime,index){return [(item.class !== 'green-light')?[_c('el-popover',{key:'suggetTime_' + index,attrs:{"trigger":"hover","open-delay":1000}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getContentTooltip(useTime.time))}}),_c('li',{class:[
                                                    'p-2',
                                                    useTime.time === _vm.timeSelected ? 'selected': '',
                                                ],attrs:{"slot":"reference"},on:{"click":function($event){return _vm.onChangeTimeSelected(useTime.time)}},slot:"reference"},[_c('i',{class:['id pr-5', useTime.icon]}),_c('span',[_vm._v(_vm._s(useTime.time))])])])]:[_c('li',{key:'suggetTime_' + index,class:[
                                                'p-2',
                                                useTime.time === _vm.timeSelected ? 'selected': '',
                                            ],on:{"click":function($event){return _vm.onChangeTimeSelected(useTime.time)}}},[_c('i',{class:['id pr-5', useTime.icon]}),_c('span',[_vm._v(_vm._s(useTime.time))])])]]})],2)}),0)],1)]:[_c('perfect-scrollbar',{staticClass:"zimbra-suggest-results"},[_c('div',{staticClass:"NoSuggestions white-space-normal"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('zimbra.zmMsg.noSuggestionsFound', [_vm.startDateLabel, _vm.durationLabel]))}}),_c('div',{staticClass:"FakeAnchor",on:{"click":function($event){return _vm.makSuggetAnyTime()}}},[_vm._v(" "+_vm._s(_vm.$t("zimbra.zmMsg.showTimesAnyway"))+" ")])])])]]:[(
                        _vm.listResourceLocation &&
                        _vm.listResourceLocation.length > 0
                    )?void 0:[_c('div',{staticClass:"p-3"},[_vm._v(" "+_vm._s(_vm.$t("zimbra.zmMsg.noLocations"))+" ")])]]],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }