import generalService from "@/services/GeneralService";
import CommonUtils from "@/utils/common-utils";
import { LOCALES, THEMES } from "@/utils/constants";
const generalModule = {
    state: {
        optionSkins: ["bare", "beach", "bones", "carbon"],
        data: [],
        skins: [],
        locales: [],
        csvs: [],
        listPref: [],
        clientTypeCurrent: {
            name: "zimbraPrefClientType",
            content: ""
        },
        skinCurrent: {
            name: "zimbraPrefSkin",
            content: "bare",
            photo: ""
        },
        fontCurrent: {
            name: "zimbraPrefFont",
            content: ""
        },
        fontSizeCurrent: {
            name: "zimbraPrefFontSize",
            content: ""
        },
        printFontSizeCurrent: {
            name: "zimbraPrefDefaultPrintFontSize",
            content: ""
        },
        timeZoneCurrent: {
            name: "zimbraPrefTimeZoneId",
            content: "Asia/Bangkok"
        },
        localeCurrent: {
            name: "zimbraPrefLocale",
            content: "vi"
        },
        composeDirectionCurrent: {
            name: "zimbraPrefComposeDirection",
            content: "LTR"
        },
        showComposeDirectionCurrent: {
            name: "zimbraPrefShowComposeDirection",
            content: ""
        },
        includeSharedItemsInSearchCurrent: {
            name: "zimbraPrefIncludeSharedItemsInSearch",
            content: ""
        },
        includeSpamInSearchCurrent: {
            name: "zimbraPrefIncludeSpamInSearch",
            content: ""
        },
        includeTrashInSearchCurrent: {
            name: "zimbraPrefIncludeTrashInSearch",
            content: ""
        },
        showSearchStringCurrent: {
            name: "zimbraPrefShowSearchString",
            content: false
        },
        showSelectionCheckboxCurrent: {
            name: "zimbraPrefShowSelectionCheckbox",
            content: false
        },
        shortEmailAddressCurrent: {
            name: "zimbraPrefShortEmailAddress",
            content: false
        },
        zimbraPrefBriefcaseReadingPaneLocation: {
            name: "zimbraPrefBriefcaseReadingPaneLocation",
            content: "right"
        },
        zimbraPrefGalSearchEnabled: {
            name: "zimbraPrefGalSearchEnabled",
            content: false
        },
        menuCollapse: CommonUtils.methods.getLocalStorageObject("menuCollapse") || false,
        isTablet: false
    },
    getters: {
        skins: state => state.skins.filter(skin => state.optionSkins.indexOf(skin.id) > -1),
        locales: state => state.locales,
        csvs: state => state.csvs,
        clientTypeCurrent: state => state.clientTypeCurrent,
        skinCurrent: state => state.skinCurrent,
        fontCurrent: state => state.fontCurrent,
        fontSizeCurrent: state => state.fontSizeCurrent,
        printFontSizeCurrent: state => state.printFontSizeCurrent,
        timeZoneCurrent: state => state.timeZoneCurrent,
        localeCurrent: state => state.localeCurrent,
        composeDirectionCurrent: state => state.composeDirectionCurrent,
        showComposeDirectionCurrent: state => state.showComposeDirectionCurrent,
        includeSharedItemsInSearchCurrent: state =>
            state.includeSharedItemsInSearchCurrent,
        includeSpamInSearchCurrent: state => state.includeSpamInSearchCurrent,
        includeTrashInSearchCurrent: state => state.includeTrashInSearchCurrent,
        showSearchStringCurrent: state => state.showSearchStringCurrent,
        showSelectionCheckboxCurrent: state => state.showSelectionCheckboxCurrent,
        shortEmailAddressCurrent: state => state.shortEmailAddressCurrent,
        zimbraPrefBriefcaseReadingPaneLocation: state => state.zimbraPrefBriefcaseReadingPaneLocation,
        zimbraPrefGalSearchEnabled: state => state.zimbraPrefGalSearchEnabled,
        menuCollapse: state => state.menuCollapse,
        isTablet: state => state.isTablet,
    },
    actions: {
        async getAllGeneral({ commit }) {
            try {
                const resp = await generalService.getAll();
                const restData = resp.data;
                // const _list = restData?.filter(ele => ele)?.map(item => JSON.parse(item));

                const mapResult = restData["soap:Envelope"]["soap:Body"];
                // const mapResult = a?.reduce((cur, el) => {
                //     const key = Object.keys(el);
                //     cur[key[0]] = el[key[0]];
                //     return cur;
                // }, {});
                // console.log(mapResult)
                // ds cấu hình theme
                if (mapResult['GetAvailableSkinsResponse']) {
                    commit("SET_ALL_SKINS", mapResult['GetAvailableSkinsResponse'].skin);
                } else {
                    commit("SET_ALL_SKINS", THEMES);
                }
                // ds cấu hình ngôn ngữ
                if (mapResult['GetAvailableLocalesResponse']) {
                    commit("SET_ALL_LOCALES", mapResult['GetAvailableLocalesResponse'].locale);
                } else {
                    commit("SET_ALL_LOCALES", LOCALES);
                }
                // ds cấu hình csv format
                if (mapResult['GetAvailableCsvFormatsResponse']) {
                    commit("SET_ALL_CSVS", mapResult['GetAvailableCsvFormatsResponse'].csv);
                }
                // ds cấu hình chung
                if (mapResult['GetPrefsResponse']) {
                    const { pref: prefs } = mapResult['GetPrefsResponse'];
                    prefs.forEach(item => {
                        if (item.name === "zimbraPrefClientType") {
                            commit("SET_ZIMBRAPREFCLIENTTYPE", item);
                        } else if (item.name === "zimbraPrefSkin") {
                            commit("SET_ZIMBRAPREFSKIN", item);
                        } else if (item.name === "zimbraPrefFont") {
                            commit("SET_ZIMBRAPREFFONT", item);
                        } else if (item.name === "zimbraPrefFontSize") {
                            commit("SET_ZIMBRAPREFFONTSIZE", item);
                        } else if (item.name === "zimbraPrefDefaultPrintFontSize") {
                            commit("SET_ZIMBRAPREFDEFAULTPRINTFONTSIZE", item);
                        } else if (item.name === "zimbraPrefTimeZoneId") {
                            commit("SET_ZIMBRAPREFTIMEZONEID", item);
                        } else if (item.name === "zimbraPrefLocale") {
                            commit("SET_ZIMBRAPREFLOCALE", item);
                        } else if (item.name === "zimbraPrefComposeDirection") {
                            commit("SET_ZIMBRAPREFCOMPOSEDIRECTION", item);
                        } else if (item.name === "zimbraPrefShowComposeDirection") {
                            commit("SET_ZIMBRAPREFSHOWCOMPOSEDIRECTION", item);
                        } else if (item.name === "zimbraPrefIncludeSharedItemsInSearch") {
                            commit("SET_ZIMBRAPREFINCLUDESHAREDITEMSINSEARCH", item);
                        } else if (item.name === "zimbraPrefIncludeSpamInSearch") {
                            commit("SET_ZIMBRAPREFINCLUDESPAMINSEARCH", item);
                        } else if (item.name === "zimbraPrefIncludeTrashInSearch") {
                            commit("SET_ZIMBRAPREFINCLUDETRASHINSEARCH", item);
                        } else if (item.name === "zimbraPrefShowSearchString") {
                            commit("SET_ZIMBRAPREFSHOWSEARCHSTRING", item);
                        } else if (item.name === "zimbraPrefShowSelectionCheckbox") {
                            commit("SET_ZIMBRAPREFSHOWSELECTIONCHECKBOX", item);
                        } else if (item.name === "zimbraPrefShortEmailAddress") {
                            commit("SET_ZIMBRAPREFSHORTEMAILADDRESS", item);
                        } else if (item.name === "zimbraPrefBriefcaseReadingPaneLocation") {
                            commit("SET_ZIMBRAPREFBRIEFCASEREADINGPANELOCATION", item)
                        } else if (item.name === "zimbraPrefGalSearchEnabled") {
                            commit("SET_ZIMBRAPREFGALSEARCHENABLED", item)
                        }
                    });
                }
                return resp;
            } catch (error) {
                commit("SET_ALL_LOCALES", LOCALES);
                commit("SET_ALL_SKINS", THEMES);
                console.log(">>>>> error get all general: ", error);
            }
        },
        async saveGeneralSetting({ commit }, data) {
            try {
                const resp = await generalService.saveSetting(data);
                if (resp.status === 200) {
                    data.map(item => {
                        commit(`EDIT_${item.name.trim().toUpperCase()}`, item);
                    });
                }
                return resp;
            } catch (error) {
                console.log(error);
            }
        },
        async setSkin({ commit }, data) {
            try {
                const resp = await generalService.setSkinRequest(data);
                if (resp.status  === 200) {
                    commit("EDIT_ZIMBRAPREFSKIN", data);
                    return resp;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async setFont({ commit }, data) {
            try {
                const resp = await generalService.setFontRequest(data);
                if (resp.status === 200) {
                    commit("EDIT_ZIMBRAPREFFONT", data);
                    return resp;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async setFontSize({ commit }, data) {
            try {
                const resp = await generalService.setFontSizeRequest(data);
                if (resp.status === 200) {
                    commit("EDIT_ZIMBRAPREFFONTSIZE", data);
                    return resp;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async setTimeZone({ commit }, data) {
            try {
                const resp = await generalService.setTimeZoneRequest(data);
                if (resp.status === 200) {
                    commit("EDIT_ZIMBRAPREFTIMEZONEID", data);
                    return resp;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async setLocale({ commit }, data) {
            try {
                let content = data && data['content'] ? data['content'] : "vi";
                data['content'] = content[content.length - 1];
                if (data && data['content'] && data['content'] instanceof String) {
                    const splitPrefLocale = content.split(",");
                    const lastPrefLocale = splitPrefLocale[splitPrefLocale.length - 1];
                    data['content'] = lastPrefLocale;
                }
                const resp = await generalService.setLocaleRequest(data);
                if (resp.status == 200) {
                    commit("EDIT_ZIMBRAPREFLOCALE", data);
                    return resp;
                }
            } catch (error) {
                console.log(error);
            }
        },
        actionToggleMenuCollapse({ commit }) {
            commit("TOGGLE_MENU_COLLAPSE");
        },
    },
    mutations: {
        SET_MENU_COLLAPSE(state, data) {
            state.menuCollapse = data;
        },
        SET_INFO_TABLET(state, data) {
            state.isTablet = data;
        },
        SET_ALL_DATA(state, data) {
            state.data = data;
        },
        SET_ALL_SKINS(state, data) {
            const _listSkins = [];
            data.forEach((skin, index) =>
                _listSkins.push({
                    photo: `skin_${index + 1}.jpg`,
                    id: skin.name,
                    label: generalService.setI18nNameByName(skin.name)
                })
            );
            state.skins = _listSkins;
        },
        SET_ALL_LOCALES(state, data) {
            // const options = [];
            // data.forEach(e => {
            //     options.push({
            //         value: e.id,
            //         label: `zimbra.zmMsg.localeName_${e.id}`,
            //         name: e.name
            //     });
            // });
            // for (let i = 0; i < options.length; i++) {
            //     for (let j = 0; j < options.length; j++) {
            //         if (i !== j && options[i].value.includes(options[j].value)) {
            //             options[j].children = [
            //                 ...options[j].children || [],
            //                 options[i]
            //             ];
            //             options.splice(i, 1);
            //             i--;
            //             break;
            //         }
            //     }
            // }
            const options = [
                {
                    label: "zimbra.zmMsg.localeName_vi",
                    name: "Tiếng Việt",
                    value: "vi"
                },
                {
                    label: "zimbra.zmMsg.localeName_en_US",
                    name: "English (United States)",
                    value: "en"
                },
                {
                    label: "zimbra.zmMsg.localeName_es",
                    name: "español",
                    value: "es"
                },
                {
                    label: "zimbra.zmMsg.localeName_pt",
                    name: "português",
                    value: "pt"
                }
            ]

            state.locales = options;
        },
        SET_ALL_CSVS(state, data) {
            state.csvs = data;
        },
        SET_ZIMBRAPREFCLIENTTYPE(state, data) {
            state.clientTypeCurrent = data;
        },
        SET_ZIMBRAPREFSKIN(state, data) {
            state.skinCurrent = data;
        },
        SET_ZIMBRAPREFFONT(state, data) {
            state.fontCurrent = data;
        },
        SET_ZIMBRAPREFFONTSIZE(state, data) {
            state.fontSizeCurrent = data;
        },
        SET_ZIMBRAPREFDEFAULTPRINTFONTSIZE(state, data) {
            state.printFontSizeCurrent = data;
        },
        SET_ZIMBRAPREFTIMEZONEID(state, data) {
            state.timeZoneCurrent = data;
        },
        SET_ZIMBRAPREFLOCALE(state, data) {
            state.localeCurrent = data;
        },
        SET_ZIMBRAPREFCOMPOSEDIRECTION(state, data) {
            state.composeDirectionCurrent = data;
        },
        SET_ZIMBRAPREFSHOWCOMPOSEDIRECTION(state, data) {
            state.showComposeDirectionCurrent = data;
        },
        SET_ZIMBRAPREFINCLUDESHAREDITEMSINSEARCH(state, data) {
            state.includeSharedItemsInSearchCurrent = data;
        },
        SET_ZIMBRAPREFINCLUDESPAMINSEARCH(state, data) {
            state.includeSpamInSearchCurrent = data;
        },
        SET_ZIMBRAPREFINCLUDETRASHINSEARCH(state, data) {
            state.includeTrashInSearchCurrent = data;
        },
        SET_ZIMBRAPREFSHOWSEARCHSTRING(state, data) {
            state.showSearchStringCurrent = data;
        },
        SET_ZIMBRAPREFSHOWSELECTIONCHECKBOX(state, data) {
            state.showSelectionCheckboxCurrent = data
        },
        SET_ZIMBRAPREFSHORTEMAILADDRESS(state, data) {
            state.shortEmailAddressCurrent = data
        },
        SET_ZIMBRAPREFGALSEARCHENABLED(state, data) {
            state.zimbraPrefGalSearchEnabled = data
        },
        SET_ZIMBRAPREFBRIEFCASEREADINGPANELOCATION(state, data) {
            state.zimbraPrefBriefcaseReadingPaneLocation = data
        },
        // edit
        EDIT_ZIMBRAPREFCLIENTTYPE(state, data) {
            state.clientTypeCurrent.content = data.content;
        },
        EDIT_ZIMBRAPREFSKIN(state, data) {
            state.skinCurrent.content = data.content;
        },
        EDIT_ZIMBRAPREFFONT(state, data) {
            state.fontCurrent.content = data.content;
        },
        EDIT_ZIMBRAPREFFONTSIZE(state, data) {
            state.fontSizeCurrent.content = data.content;
        },
        EDIT_ZIMBRAPREFDEFAULTPRINTFONTSIZE(state, data) {
            state.printFontSizeCurrent.content = data.content;
        },
        EDIT_ZIMBRAPREFTIMEZONEID(state, data) {
            state.timeZoneCurrent.content = data.content;
        },
        EDIT_ZIMBRAPREFLOCALE(state, data) {
            state.localeCurrent.content = data.content;
        },
        EDIT_ZIMBRAPREFCOMPOSEDIRECTION(state, data) {
            state.composeDirectionCurrent.content = data.content;
        },
        EDIT_ZIMBRAPREFSHOWCOMPOSEDIRECTION(state, data) {
            state.showComposeDirectionCurrent.content = data.content;
        },
        EDIT_ZIMBRAPREFINCLUDESHAREDITEMSINSEARCH(state, data) {
            state.includeSharedItemsInSearchCurrent.content = data.content;
        },
        EDIT_ZIMBRAPREFINCLUDESPAMINSEARCH(state, data) {
            state.includeSpamInSearchCurrent.content = data.content;
        },
        EDIT_ZIMBRAPREFINCLUDETRASHINSEARCH(state, data) {
            state.includeTrashInSearchCurrent.content = data.content;
        },
        EDIT_ZIMBRAPREFSHOWSEARCHSTRING(state, data) {
            state.showSearchStringCurrent.content = data.content;
        },
        EDIT_ZIMBRAPREFSHOWSELECTIONCHECKBOX(state, data) {
            state.showSelectionCheckboxCurrent.content = data.content
        },
        EDIT_ZIMBRAPREFSHORTEMAILADDRESS(state, data) {
            state.shortEmailAddressCurrent.content = data.content
        },
        EDIT_SKIN_CURRENT(state, data) {
            state.skinCurrent = data;
        },
        EDIT_FONT_CURRENT(state, data) {
            state.fontCurrent = data;
        },
        EDIT_FONTSIZE_CURRENT(state, data) {
            state.fontSizeCurrent = data;
        },
        EDIT_TIMEZONE_CURRENT(state, data) {
            state.timeZoneCurrent = data;
        },
        EDIT_LOCALE_CURRENT(state, data) {
            state.localeCurrent = data;
        },
        EDIT_ZIMBRAPREFBRIEFCASEREADINGPANELOCATION(state, data) {
            state.zimbraPrefBriefcaseReadingPaneLocation = data
        },
        EDIT_ZIMBRAPREFGALSEARCHENABLED(state, data) {
            state.zimbraPrefGalSearchEnabled = data
        },
        TOGGLE_MENU_COLLAPSE(state) {
            state.menuCollapse = !state.menuCollapse;
        },
    }
};
export default generalModule;
