<template>
    <div class="card-filter-row">
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueMainSelect"
                :options="mainSelect"
                @change="handleChangeMainSelect"
            />
        </div>
        <div class="card-filter-item"></div>
        <div class="card-filter-item ">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueSelect_1"
                :options="select_1"
                @change="handleChangeSelect_1"
            />
        </div>
        <div class="card-filter-item select-wrap__270">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueSelect_2"
                :options="select_2"
                @change="handleChangeSelect_2"
            />
        </div>
        <div class="card-filter-item" v-if="isTick">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueSelect_3"
                :options="select_3"
                @change="handleChangeSelect_3"
            />
        </div>
        <div class="card-filter-item"></div>
    </div>
</template>

<script>
import { FILTER_TEST_CONFIG, FILTER_TESTS } from "@/utils/constants";
import BaseSelect from "@/components/modules/base/base-select";
import CoreTest from "@/components/modules/base/base-filter-form/core-test";
export default {
    extends: CoreTest,
    components: {
        BaseSelect,
    },
    created() {
        this.beforeBuildDataForm();
        this.conditionsMessageTest = this.conditionsTest;
    },
    watch: {
        conditionsTest(newData) {
            this.beforeBuildDataForm();
            this.conditionsMessageTest = newData;
        },
    },
    data() {
        return {
            FILTER_TEST_CONFIG: FILTER_TEST_CONFIG,
            FILTER_TESTS: FILTER_TESTS,
            select_1: [
                {
                    id: FILTER_TEST_CONFIG.STRING_COMPARTION.IS,
                    name: "là",
                },
                {
                    id: FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_IS,
                    name: "không là",
                },
            ],
            select_2: [
                {
                    id: 0,
                    name: "trong cuộc đàm thoại tôi đã bắt đầu",
                    value: FILTER_TEST_CONFIG.WHERE.STARTED,
                },
                {
                    id: 1,
                    name: "trong cuộc đàm thoại tôi đã tham gia",
                    value: FILTER_TEST_CONFIG.WHERE.PARTICIPATED,
                },
                {
                    id: 2,
                    name: "tiếp thị đại chúng(số lượng lớn)",
                },
                {
                    id: 3,
                    name: "từ danh sách gửi thư",
                },
                {
                    id: 4,
                    name: "đánh dấu là",
                },
                {
                    id: 5,
                    name: "đã gắn cờ",
                    value: FILTER_TEST_CONFIG.FLAG_NAME.FLAGGED,
                },
            ],
            select_3: [
                {
                    id: 0,
                    name: "đã đọc",
                    value: FILTER_TEST_CONFIG.FLAG_NAME.READ,
                },
                {
                    id: 1,
                    name: "mức ưu tiên",
                    value: FILTER_TEST_CONFIG.FLAG_NAME.PRIORITY,
                },
                {
                    id: 2,
                    name: "mức quan trọng cao",
                    value: FILTER_TEST_CONFIG.IMP.HIGHT,
                },
                {
                    id: 3,
                    name: "mức quan trọng bình thường",
                    value: FILTER_TEST_CONFIG.IMP.NORMAL,
                },
                {
                    id: 4,
                    name: "mức quan trọng thấp",
                    value: FILTER_TEST_CONFIG.IMP.LOW,
                },
            ],
            valueMainSelect: "",
            valueSelect_1: "",
            valueSelect_2: "",
            valueSelect_3: 0,
            conditionsMessageTest: {},
            isTick: false,
        };
    },
    methods: {
        beforeBuildDataForm() {
            this.valueMainSelect = this.mainSelect[5].id;

            this.valueSelect_1 = this.conditionsTest.negative
                ? FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_IS
                : FILTER_TEST_CONFIG.STRING_COMPARTION.IS;
            switch(this.conditionsTest.types){
                case FILTER_TESTS.CONVERSATION_TEST:
                    this.valueSelect_2 =
                    this.conditionsTest.where ==
                    FILTER_TEST_CONFIG.WHERE.STARTED
                        ? this.select_2[0].id
                        : this.select_2[1].id;
                    this.isTick = false;
                    break;
                case FILTER_TESTS.BULK_TEST:
                    this.valueSelect_2 = this.select_2[2].id;
                    this.isTick = false;
                    break;
                case FILTER_TESTS.LIST_TEST:
                    this.valueSelect_2 = this.select_2[3].id;
                    this.isTick = false;
                    break;
                case FILTER_TESTS.FLAGGED_TEST:
                    if(this.conditionsTest.flagName === FILTER_TEST_CONFIG.FLAG_NAME.FLAGGED){
                        this.valueSelect_2 = this.select_2[5].id;
                        this.isTick = false;
                    }else{
                        this.valueSelect_2 = this.select_2[4].id;
                        this.valueSelect_3 =
                            this.conditionsTest.flagName ===
                            FILTER_TEST_CONFIG.FLAG_NAME.READ
                                ? this.select_3[0].id
                                : this.select_3[1].id;
                        this.isTick = true;
                    }
                    break;
                case FILTER_TESTS.IMPOTAMCE_TEST:
                    this.isTick = true;
                    this.valueSelect_2 = this.select_2[4].id;
                    if (this.conditionsTest.imp === FILTER_TEST_CONFIG.IMP.HIGHT) {
                        this.valueSelect_3 = this.select_3[2].id;
                    } else if (
                        this.conditionsTest.imp === FILTER_TEST_CONFIG.IMP.NORMAL
                    ) {
                        this.valueSelect_3 = this.select_3[3].id;
                    } else {
                        this.valueSelect_3 = this.select_3[4].id;
                    }
                    break;
                default:
                    break;
            }
        },
        handleChangeSelect_1(val) {
            for (let key in this.conditionsTest) {
                if (key !== "negative" && this.validateNegative(val)) {
                    this.conditionsMessageTest = {
                        ...this.conditionsMessageTest,
                        negative: FILTER_TEST_CONFIG.NEGATIVE,
                    };
                } else {
                    if (!this.validateNegative(val)) {
                        delete this.conditionsMessageTest.negative;
                    }
                }
            }
            this.$emit("onChangeConditionsTest", this.conditionsMessageTest);
        },
        handleChangeSelect_2(status) {
            const dataIndexStatus = this.select_2[status];
            this.isTick = false;
            if (status === 0 || status === 1) {
                this.conditionsMessageTest.types =
                    FILTER_TESTS.CONVERSATION_TEST;
                if (this.conditionsMessageTest.flagName)
                    delete this.conditionsMessageTest.flagName;
                if (this.conditionsMessageTest.imp)
                    delete this.conditionsMessageTest.imp;
                if (this.conditionsMessageTest.where) {
                    this.conditionsMessageTest.where = dataIndexStatus.value;
                } else {
                    this.conditionsMessageTest = {
                        ...this.conditionsMessageTest,
                        where: dataIndexStatus.value,
                    };
                }
            }
            if (status === 2 || status === 3) {
                if (this.conditionsMessageTest.flagName)
                    delete this.conditionsMessageTest.flagName;
                if (this.conditionsMessageTest.imp)
                    delete this.conditionsMessageTest.imp;
                if (this.conditionsMessageTest.where)
                    delete this.conditionsMessageTest.where;
                this.conditionsMessageTest.types =
                    status === 2
                        ? FILTER_TESTS.BULK_TEST
                        : FILTER_TESTS.LIST_TEST;
            } else if (status === 4) {
                this.isTick = true;
                this.handleChangeSelect_3(this.valueSelect_3);
            } else if (status === 5) {
                if (this.conditionsMessageTest.imp)
                    delete this.conditionsMessageTest.imp;
                if (this.conditionsMessageTest.where)
                    delete this.conditionsMessageTest.where;
                this.conditionsMessageTest.types = FILTER_TESTS.FLAGGED_TEST;
                this.conditionsMessageTest = {
                    ...this.conditionsMessageTest,
                    flagName: dataIndexStatus.value,
                };
            }
            this.$emit("onChangeConditionsTest", this.conditionsMessageTest);
        },
        handleChangeSelect_3(tick) {
            const dataIndexTick = this.select_3[tick];
            if (tick === 0 || tick === 1) {
                if (this.conditionsMessageTest.imp)
                    delete this.conditionsMessageTest.imp;
                if (this.conditionsMessageTest.where)
                    delete this.conditionsMessageTest.where;
                this.conditionsMessageTest.types = FILTER_TESTS.FLAGGED_TEST;
                if (this.conditionsMessageTest.flagName) {
                    this.conditionsMessageTest.flagName = dataIndexTick.value;
                } else {
                    this.conditionsMessageTest = {
                        ...this.conditionsMessageTest,
                        flagName: dataIndexTick.value,
                    };
                }
            } else {
                if (this.conditionsMessageTest.flagName)
                    delete this.conditionsMessageTest.flagName;
                if (this.conditionsMessageTest.where)
                    delete this.conditionsMessageTest.where;
                this.conditionsMessageTest.types = FILTER_TESTS.IMPOTAMCE_TEST;
                if (this.conditionsMessageTest.imp) {
                    this.conditionsMessageTest.imp = dataIndexTick.value;
                } else {
                    this.conditionsMessageTest = {
                        ...this.conditionsMessageTest,
                        imp: dataIndexTick.value,
                    };
                }
            }
            this.$emit("onChangeConditionsTest", this.conditionsMessageTest);
        },
    },
};
</script>
