<template>
    <el-dialog
        class="dialog-body-wrap zimbra-dialog"
        :visible.sync="showView"
        custom-class="mw__450"
        width="30%"
        height="550px"
        :title="$t('zimbra.zmMsg.chooseAddrBook')"
        :append-to-body="true"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="handleClose"
        @open="onOpenDialog"
    >
        <el-form class="form-wrap">
            <div style="padding-bottom: 10px">
                {{ $t("zimbra.zmMsg.chooserDescription") }}
            </div>
            <perfect-scrollbar style="height: 300px">
                <TreeBasicFolder
                    ref="treeContact"
                    @onSelectFolder="onSelectFolder"
                ></TreeBasicFolder>
            </perfect-scrollbar>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <base-button
                class="btn-email primary outline"
                @click="$root.$emit('handleAddFolderContact', false)"
                style="float: left"
            >{{ $t("zimbra.zhMsg.apptPtstNEW") }}
            </base-button>
            <base-button
                class="btn-email default"
                @click="showView = false"
            >{{ $t("zimbra.zhMsg.actionTaskCancelTT") }}
            </base-button>
            <base-button
                @click="handleAccept"
                class="btn-email primary"
                type="primary"
            >{{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}
            </base-button>
        </span>
    </el-dialog>
</template>
<script>
import BaseButton from "@/components/modules/base/base-button/btn-default";
import TreeBasicFolder from "./TreeBasicFolder.vue"
import { mapGetters } from "vuex"
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },
    components: {
        BaseButton,
        TreeBasicFolder
    },
    created() {
        this.showView = this.show
    },
    data() {
        return {
            showView: false,
            folderSelect: null
        }
    },
    computed: {
        ...mapGetters([
            "folderArchive",
        ]),
    },
    watch: {
        show(status) {
            this.showView = status
        }
    },
    methods: {
        onSelectFolder(folder) {
            this.folderSelect = folder
        },
        handleClose() {
            this.$emit("close", false)
        },
        handleAccept() {
            if (this.folderSelect) {
                this.handleClose()
                this.$emit("click", this.folderSelect)
            } else {
                this.$alert(this.$t("zimbra.zmMsg.noTargetFolder"), this.$t("zimbraNokey.zimbra_common_criticalMsg"), {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    type: "error",
                });
            }
        },
        onOpenDialog() {
            setTimeout(() => {
                this.$refs.treeContact.setFocus();
            }, 200);
        }
    }
}
</script>