<template>
    <section class="wrap-pri drag-wrapper">
        <div class="main-content-wrapper main-contact zm-unselect">
            <NavContact />
            <main class="wrap-contact">
                <slot />
            </main>
        </div>
        <SideBarRight />
    </section>
</template>

<script>
import NavContact from "@/components/modules/layouts/NavContact";
import SideBarRight from "@/components/modules/layouts/SideBarRight";
import CommonUtils from "@/utils/common-utils";
export default {
    name: "ContactLayout",
    components: {
        NavContact,
        SideBarRight,
    },
    mixins: [CommonUtils]
}
</script>

<style>

</style>
