<template>
    <div class="detail-schedule-handmate">
        <div class="d-flex justify-content-between" >
            <div class="title">
                <span>{{ $t( "zimbra.zhMsg.schedule" ) }}</span>
            </div>
            <div class="float-right f-wrap">
                <template v-for="item in DATASOURCE_FREE_BUSY_FULL">
                    <span :key="item.value" class="position-relative">
                        <span class="pl-5 zm-free-busy" :style="{'--bgColorTag': item.color}">
                            {{ item.label }}
                        </span>
                    </span>
                </template>
            </div>
        </div>
        <div class="zm-detail-schedule mt-3 mb-3" style="padding-top: 30px" id="handmateTable">
            <table class="handmate-table">
                <colgroup><col style="width:200px" colspan="3"><col style="width:800px"></colgroup>
                <tr class="handmate-table-row header-row">
                    <!-- Tất cả người tham dự -->
                    <td align="center"><span class="zm-label lh-auto">{{ $t( "zimbra.zmMsg.allAttendees" ) }}</span></td>
                    <td>
                        <DetailScheduleGridTableTotal
                            :col-start-time="colStartTime"
                            :col-end-time="colEndTime"
                            :start-time="startTime"
                            :end-time="endTime"
                            :is-first-row="true"
                            :working-hours="workingHours"
                            :computed-items-map="computedItemsMap"
                        />
                    </td>
                </tr>
                <tr class="handmate-table-row" v-for="(item, index) in resouceList" :key="index">
                    <td align="center">
                        <table class="table-full-width table-no-border table-padding-small">
                            <tr>
                                <td style="width: 60px">
                                    <el-dropdown @command="arg => onChangeResouceType(arg, index)" trigger="click">
                                        <ResourceTypeDisplay :resouce-type="item.resouceType" :is-editable="item.isEditable" :hide-caret="!item.isEditable"/>
                                        <el-dropdown-menu slot="dropdown">
                                            <template v-for="datasource in DATASOURCE_CALENDAR_RESOURE_TYPE">
                                                <el-dropdown-item :key="datasource.value" :command="{datasource: datasource, item: item}">
                                                    <ResourceTypeDisplay :resouce-type="datasource.value" :is-editable="true" :hide-caret="true" />
                                                    <span>{{ datasource.label }}</span>
                                                </el-dropdown-item>
                                            </template>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </td>
                                <td>
                                    <template v-if="item.isEditable">
                                        <template v-if="item.resouceType == CALENDAR_RESOURE_TYPE.REQUIRED_ATTENDEE || item.resouceType == CALENDAR_RESOURE_TYPE.OPTIONAL_ATTENDEE">
                                            <BaseAddressSelectorAdv
                                                :value="item.a"
                                                :multiple="false"
                                                :autofocus="false"
                                                :multipleLimit="1"
                                                :clearable="true"
                                                placeholder=""
                                                class="select-input-wrap"
                                                size="medium"
                                                @change="(evt) => onchangeResource(evt, index)"
                                                @onblur="(evt) => onblurResource(evt, index)"
                                            />
                                        </template>
                                        <template v-else>
                                            <BaseResourceSelector
                                                :multiple="false"
                                                :autofocus="false"
                                                :multipleLimit="1"
                                                :clearable="true"
                                                :is-raw-value="true"
                                                placeholder=""
                                                class="select-input-wrap"
                                                size="medium"
                                                @change="(evt) => onchangeResource(evt, index)"
                                            />
                                        </template>
                                    </template>
                                    <span v-else>{{item.displayName}}</span>
                                </td>
                                <td style="width: 26px">
                                    <ReplyInvDisplay v-if="item.isEditable" :reply-inv="item.replyInv"/>
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td>
                        <DetailScheduleGridTable
                            :col-start-time="colStartTime"
                            :col-end-time="colEndTime"
                            :start-time="startTime"
                            :end-time="endTime"
                            :resource="item"
                            :excludeUid="excludeUid"
                            :time-origin="timeOrigin"
                            @onItemChange="(data) => onResouceChange(data, index)"
                            @onComputedItemsChange="(computedItems) => onComputedItemsChange(computedItems, item.a)"
                            :working-hours="workingHours ? workingHours[item.email] : null"
                            :all-working-hours="workingHours"
                        />
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
import CalendarUtils from "@/utils/calendar-utils";
import CommonUltils from "@/utils/common-utils";
import moment from "moment";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import DetailScheduleGridTable from "@/components/modules/calendar/components/detail-schedule/detail-schedule-grid-table.vue";
import ResourceTypeDisplay from "@/components/modules/calendar/components/detail-schedule/resource-type-display.vue";
import ReplyInvDisplay from "@/components/modules/calendar/components/detail-schedule/reply-inv-display.vue";
import DetailScheduleGridTableTotal from "@/components/modules/calendar/components/detail-schedule/detail-schedule-grid-table-total.vue";
import BaseAddressSelectorAdv from "@/components/modules/base/base-address-selector/BaseAddressSelectorAdv";
import BaseResourceSelector from "@/components/modules/base/base-resource-selector/BaseResourceSelector";

import _ from "lodash";
import {
    DATASOURCE_FREE_BUSY_FULL,
    DATASOURCE_CALENDAR_RESOURE_TYPE,
    CALENDAR_RESOURE_TYPE,
} from "@/utils/constants.js";
/**
 {
    displayName: tên hiển thị
    resouceType: 1: Người tham dự bắt buộc, 2: Người tham dự tùy chọn, 3: Địa điểm, 4: Thiết bị
    email: //email
    status: trạng thái 1: Rảnh, 2: Không làm việc, 3: Bận, 4: Chưa dứt khoát, 5: Không xác định, 6: Ra khỏi văn phòng,
    isEditable: cờ có thể sửa true: có thể sửa, false: không thể sửa
 }
 */
export default {
    name: 'DetailSchedule',
    mixins: [CalendarUtils, CommonUltils],
    components: {
        DetailScheduleGridTable,
        ResourceTypeDisplay,
        ReplyInvDisplay,
        DetailScheduleGridTableTotal,
        BaseAddressSelectorAdv,
        BaseResourceSelector,
    },
    props: {
        resouceList: {
            type: Array,
            default: function () {
                return [];
            }
        },
        timeOrigin: {
            type: Array,
            default: function () {
                return [];
            }
        },
        startTime: {
            type: Object
        },
        endTime: {
            type: Object
        },
        excludeUid: {
            type: String
        }
    },
    data() {
        return {
            DATASOURCE_FREE_BUSY_FULL: DATASOURCE_FREE_BUSY_FULL,
            DATASOURCE_CALENDAR_RESOURE_TYPE: DATASOURCE_CALENDAR_RESOURE_TYPE,
            CALENDAR_RESOURE_TYPE: CALENDAR_RESOURE_TYPE,
            computedItemsMap: {},
            timerShowInfoSchedule: null,
            listSchedulePopopver: []
        };
    },
    async created() {
    },
    mounted() {
        this.showChange();
    },
    destroyed() {
    },
    asyncComputed: {
        async workingHours() {
            const data = {};
            const ids = [];
            for (let i in this.resouceList) {
                this.resouceList[i] && ids.push(this.resouceList[i].a || this.resouceList[i].id);
            }
            if (ids.length <= 0) {
                return data;
            }

            const s = this.colStartTime.valueOf().toString();
            const e = this.colEndTime.valueOf().toString();
            const formData = { name: ids.join(','), s: s, e: e };
            const res = await ZimbraMailService.getWorkingHoursRequest(formData);
            let { usr } = this.getResponseBody(res).GetWorkingHoursResponse;
            usr = Array.isArray(usr) ? usr : [usr];
            usr.forEach((item) => data[item.id] = item);
            return data;
        }
    },
    computed: {
        colStartTime() {
            if (!this.startTime) {
                return CalendarUtils.methods.truncateMinuteTime(moment().startOf('day'));
            }
            return CalendarUtils.methods.truncateMinuteTime(moment(this.startTime).startOf('day'));
        },
        colEndTime() {
            if (!this.startTime) {
                return CalendarUtils.methods.truncateMinuteTime(moment().endOf('day'));
            }
            return CalendarUtils.methods.truncateMinuteTime(moment(this.startTime).endOf('day'));
        },
    },
    methods: {
        showChange() {
        },
        onChangeResouceType(arg, index) {
            const datasource = arg.datasource;
            const item = arg.item;
            if ([CALENDAR_RESOURE_TYPE.REQUIRED_ATTENDEE, CALENDAR_RESOURE_TYPE.OPTIONAL_ATTENDEE].includes(item.resouceType)
                && [CALENDAR_RESOURE_TYPE.LOCATION_LABEL, CALENDAR_RESOURE_TYPE.EQUIPMENT_LABEL].includes(datasource.value)
                && item.email
                && !item.isFirstChange
                && !item.isEdit
            ) {
                this.$emit("addNewRowRource", { resouceType: datasource.value, index: index });
            } else {
                item.resouceType = datasource.value;
                item.a = "";
                item.email = "";
                item.replyInv = null;
                this.$emit("onChangeResouceType", { event: item, index: index });
            }
        },
        onResouceChange(item, index) {
            this.resouceList[index] = item;
            this.$emit("updateResourceList");
        },
        onComputedItemsChange(data, email) {
            this.computedItemsMap[email] = data;
            this.computedItemsMap = _.cloneDeep(this.computedItemsMap)
        },
        onchangeResource(evt, index) {
            this.$emit("onchangeResource", { event: evt, index: index });
        },
        onblurResource(evt, index) {
            this.$emit("onblurResource", { event: evt, index: index });
        },
        /**
         * update dòng thời gian tổng
         */
        updateTotalSchedule() {
            const itemsMap = {};
            this.resouceList.forEach(e => {
                itemsMap[e.a] = e.a ? e.items : [];
            });
            this.computedItemsMap = itemsMap;
        },
    },
    watch: {
        resouceList() {
            this.showChange();
        },
        startTime() {
            this.showChange();
        },
        endTime() {
            this.showChange();
        }
    },
};
</script>
