// import ZimbraService from "@/services/ZimbraService";
const zimletModules = {
    state: {
        dataTableCurrent:[],
    },
    getters: {
        dataTableCurrent: state => state.dataTableCurrent,
    },
    actions: {
        setTableDataCurrent({ commit }, data) {
            commit('SET_TABLE_DATA_CURRENT', data)
        },

    },
    mutations: {
        SET_TABLE_DATA_CURRENT(state, data){
            state.dataTableCurrent = data;
        },
    }
};
export default zimletModules;
