import Vue from "vue";
import AppRoot from "./AppRoot.vue";
import router from "@/router";
import CONFIG from "@/config/config";
import i18n from "@/plugins/i18n";
import { store } from "@/stores/store.js";
import VueNotification from "@kugatsu/vuenotification";
import ElementUI from "element-ui";
import VueMousetrapPlugin from "vue-mousetrap/vue2";
import VueHotkeysPlugin from "hotkeys-js"
import PerfectScrollbar from "vue2-perfect-scrollbar";
import { ColorPicker, ColorPanel } from "zm-one-colorpicker";
// window.$ = window.jQuery = require("jquery");
window.$ = window.jQuery = require('jquery');
import AsyncComputed from "vue-async-computed";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import "element-ui/lib/theme-chalk/index.css";
// splitpanes style
import "@/assets/css/zm-splitpanes.css";

// nprogress
import "nprogress/nprogress.css";
import "@/assets/scss/setting.scss";
import "@/assets/css/bootstrap.min.css";
import "@/assets/fonts/fontawesome-pro-5.8.2-web/css/all.min.css";
import "@/assets/fonts/elegantIcon/elegantIcon.css";
// import "@/assets/css/main.css";
import "@/assets/zimbra/css/main.css";
import "@/assets/zimbra/css/list-app.css"
import "@/assets/zimbra/css/uicons-regular-rounded.css";
import "@/assets/css/app-zimbra.css";
// tinymce lib
import "tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/image";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/anchor";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/emoticons/js/emojis.js";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/table";
import "tinymce/plugins/paste";
import "tinymce/plugins/help";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/code";
import "tinymce/plugins/tabfocus";
import "tinymce/plugins/hr";
import "tinymce/plugins/directionality";
// lib i18N
import "tinymce-i18n/langs5/eu.js";
import "tinymce-i18n/langs5/id.js";
import "tinymce-i18n/langs5/ar.js";
// import "tinymce-i18n/langs5/en_GB.js";
// import "tinymce-i18n/langs5/en_AU.js";
import "tinymce-i18n/langs5/pl.js";
import "tinymce-i18n/langs5/pt_BR.js";
import "tinymce-i18n/langs5/pt_PT.js";
import "tinymce-i18n/langs5/nl.js";
import "tinymce-i18n/langs5/ko_KR";
import "tinymce-i18n/langs5/ru.js";
import "tinymce-i18n/langs5/ja.js";
import "tinymce-i18n/langs5/fr_FR.js";
import "tinymce-i18n/langs5/ro.js";
import "tinymce-i18n/langs5/th_TH.js";
import "tinymce-i18n/langs5/tr.js";
import "tinymce-i18n/langs5/sv_SE";
import "tinymce-i18n/langs5/zh_CN.js";
import "tinymce-i18n/langs5/zh_TW.js";
import "tinymce-i18n/langs5/es.js";
import "tinymce-i18n/langs5/uk.js";
import "tinymce-i18n/langs5/vi.js";
import "tinymce-i18n/langs5/sl.js";
import "tinymce-i18n/langs5/it.js";
import "tinymce-i18n/langs5/da.js";
import "tinymce-i18n/langs5/de.js";


import vClickOutside from 'v-click-outside'
import VueClipboard from 'vue-clipboard2'
import VueFriendlyIframe from 'vue-friendly-iframe';

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(vClickOutside)
Vue.use(ColorPanel);
Vue.use(ColorPicker);
Vue.use(AsyncComputed);
Vue.use(VueNotification, {
    timer: 20
});
Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
});
Vue.use(VueMousetrapPlugin);
Vue.use(VueHotkeysPlugin)
Vue.use(PerfectScrollbar);
Vue.config.productionTip = false;
Vue.prototype.$configZimbra = CONFIG;
Vue.prototype.$hotkeys = VueHotkeysPlugin
Vue.use(VueFriendlyIframe);
new Vue({
    el: "#app",
    i18n,
    router,
    store,
    render: h => h(AppRoot)
}).$mount("#app");
