<template>
  <div>
      <ul class="box-control-inbox" style="top: 180px; left: 500px; min-width: 28.6rem">
          <!-- Gắn thẻ  -->
        <li
            class="sub item-assign-tag"
            :class="[isFolder ? 'noclick' : '']"
        >
            <a class="cx-menu-item" href="javascript:;">
                <i class="ic ic-tag"></i>
                <span>{{$t("zimbra.zmMsg.tagFile")}}</span>
            </a>
            <ul v-if="listTag" class="cx-menu-sub">
                <perfect-scrollbar style="max-height: 200px;height: auto;">
                    <template v-for="(item, idx) in listTagChoice">
                        <el-tooltip :key="`tag_${idx}`" class="item" effect="dark" :content="item.name.toString()" placement="top-start" :open-delay="1000">
                            <li class="zm-tag-item" :key="`tag_${idx}`" @click="handleAssignOrRemoveTags(item, true)" >
                                <a href="javascript:;">
                                    <i class="ic ic-tag" :style="`filter: ${convertHexToFilterCss(item)}`"></i>
                                    <span>{{ item.name }}</span>
                                </a>
                            </li>
                        </el-tooltip>
                    </template>
                </perfect-scrollbar>
                <hr style="margin:0px">
                <li class="zm-tag-item" :key="`new_tag`" @click="$root.$emit('handleAddAssginTag',currentExtraActiveEmail)">
                    <a class="cx-menu-item" href="javascript:;">
                        <i class="ic-add-circle"></i>
                        <span>{{$t("zimbra.zhMsg.tagNew")}}</span>
                    </a>
                </li>
                <li v-if="listTagAssgin.length == 1" class="zm-tag-item" :key="`remove_tag`" @click="handleAssignOrRemoveTags(listTagAssgin[0],false)">
                    <a class="cx-menu-item" href="javascript:;">
                        <i class="ic-close-circle"></i>
                        <span>{{$t("zimbra.zhMsg.actionRemoveTag")}}</span>
                    </a>
                <li>
                <li v-if="listTagAssgin.length != 1" :class="`zm-tag-item ${!listTagAssgin.length > 0 ? 'noclick':'sub'}`" :key="`remove_tag`">
                    <a class="cx-menu-item" slot="reference" href="javascript:;">
                        <i class="ic-close-circle"></i>
                        <span>{{$t("zimbra.zhMsg.actionRemoveTag")}}</span>
                    </a>
                    <ul class="cx-menu-sub">
                        <perfect-scrollbar style="max-height: 200px;height: auto;">
                            <template v-for="(item, idx) in listTagAssgin">
                                <el-tooltip :key="`tag_${idx}`" class="item" effect="dark" :content="item.name.toString()" placement="top-start" :open-delay="1000">
                                    <li class="zm-tag-item" :key="`tag_${idx}`" @click="handleAssignOrRemoveTags(item, false)">
                                        <a href="javascript:;" class="cx-menu-item">
                                            <i class="ic ic-tag" :style="`filter: ${convertHexToFilterCss(item)}`"></i>
                                            <span>{{ item.name }}</span>
                                        </a>
                                    </li>
                                </el-tooltip>
                            </template>
                        </perfect-scrollbar>
                        <hr style="margin:0px">
                        <li class="zm-tag-item" :key="`new_tag`" @click="handledRemoveAllTags">
                            <a href="javascript:;" class="cx-menu-item">
                                <i class="ic ic-tag"></i>
                                <span>{{$t("zimbra.zmMsg.allTags")}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <!-- Gửi liên kết-->
        <li :class="[isFolder ? 'noclick' : '']">
            <a href="javascript:;" @click="handleSendLink">
                <i class="ic ic-navigation"></i>
                <span>{{ $t("zimbra.zmMsg.sendLink") }}</span>
            </a>
        </li>

            <!-- Gửi dưới dạng đính kèm -->
        <li :class="[isFolder ? 'noclick' : '']">
            <a href="javascript:;" @click="handleSendAttachmentsFile">
                <i class="ic-link"></i>
                <span>{{ $t("zimbra.zhMsg.sendAsAttachments") }}</span>
            </a>
        </li>
        <li class="dc-line"></li>

        <!-- Mở trong một cửa sổ riêng -->
        <li :class="[isChooseMany || isFolder ?'noclick':'']">
            <a href="javascript:;" @click="handleOpenNewWindow">
                <i class="ic ic-expand"></i>
                <span>{{ $t("zimbra.zmMsg.detach") }}</span>
            </a>
        </li>
        <li class="dc-line"></li>

        <!-- Lấy tập tin ra -->
        <li v-if="!isLock" :class="[(isCheck != 'unlock') || isFolder ?'noclick':'']">
            <a href="javascript:;" @click="handleExportFile">
                <i class="ic-file-receive"></i>
                <span>{{ $t("zimbra.zmMsg.checkOutFile") }}</span>
            </a>
        </li>

        <!-- Đăng kí tập tin -->
        <li v-if="isLock && !isFolder" :class="[isChooseMany ?'noclick':'']">
            <a href="javascript:;" @click="handleRegisterFile">
                <i class="ic-file-regiter"></i>
                <span>{{ $t("zimbra.zmMsg.checkInFile") }}</span>
            </a>
        </li>

        <!-- Hủy lấy ra -->
        <li v-if="(isLock && (isCheck == 'lock')) && !isFolder">
            <a href="javascript:;" @click="handleGetOutFile">
                <i class="ic-note-remove"></i>
                <span>{{ $t("zimbra.zmMsg.checkOutFileDiscard") }}</span>
            </a>
        </li>
        <li class="dc-line"></li>

        <!-- Đổi tên -->
        <li :class="[isChooseMany || isFolder ? 'noclick' : '']">
            <a href="javascript:;" @click="handleRenameBriefcase">
                <i class="ic-edit2"></i>
                <span>{{ $t("zimbra.zhMsg.rename") }}</span>
            </a>
        </li>
        <li></li>
      </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import CommonUtils from "@/utils/common-utils";
import BriefcaseUtils from "@/utils/briefcase-utils";
import EmailUtils from "@/utils/email-utils"
import { BRIEFCASE_ACTION } from "@/utils/constants";
import _ from "lodash"
export default {
    mixins: [CommonUtils, EmailUtils, BriefcaseUtils],
    computed: {
        ...mapGetters([
            "localeCurrent",
            "listSelectedBriefcase",
            "nodeBriefcaseCurrent"
        ]),

        isChooseMany() {
            if (this.listSelectedBriefcase && this.listSelectedBriefcase.length > 1) return true;
            else return false
        },
        isLock() {
            if (this.listSelectedBriefcase && this.listSelectedBriefcase[0]?.loid) return true;
            else return false
        },
        isCheck() {
            let count = 0;
            if (this.listSelectedBriefcase) {
                this.listSelectedBriefcase.forEach(item => {
                    if (item.loid)
                        count += 1
                })
            }
            if (count == 0)
                return "unlock"
            else if (count == this.listSelectedBriefcase.length)
                return "lock"
            else
                return "contain"
        },
        isFolder() {
            let count = 0;
            if (this.listSelectedBriefcase) {
                this.listSelectedBriefcase.forEach(item => {
                    if (item.isFolder)
                        count += 1
                })
            }
            return (count == 0) ? false : true
        },
        listTagChoice() {
            let setTagAssginAll = new Set();
            let firstLoop = true;
            for (let index in this.listSelectedBriefcase) {
                const listTagAssgin = this.getListTag(this.listSelectedBriefcase[index]);
                if (_.isNil(listTagAssgin) || listTagAssgin.length == 0) {
                    // console.log("this.listTag", this.listTag)
                    return this.listTag;
                }
                if (firstLoop) {
                    // tạo list tag là list tag tại mail đầu tiên
                    listTagAssgin.forEach(x => setTagAssginAll.add(x));
                } else {
                    // bỏ tag tại n không có trong n+1
                    listTagAssgin.forEach(x => {
                        if (!setTagAssginAll.has(x)) {
                            setTagAssginAll.delete(x);
                        }
                    });
                    // bỏ tag tại n+1 không có trong n
                    setTagAssginAll.forEach(x => {
                        if (listTagAssgin.indexOf(x) == -1) {
                            setTagAssginAll.delete(x);
                        }
                    })
                }
                firstLoop = false;
            }
            const listTagAssginAll = [...setTagAssginAll].map(x => x.id);
            return this.listTag.filter(x => !listTagAssginAll.includes(x.id));
        },
        listTagAssgin() {
            let setTagAssgin = new Set();
            this.listSelectedBriefcase.forEach(x => {
                this.getListTag(x).forEach(element => {
                    setTagAssgin.add(element)
                })
            });
            return [...setTagAssgin]
        }
    },
    methods: {
        /*
        * Hàm gửi liên kết
        */
        handleSendLink() {
            if (this.listSelectedBriefcase.length) this.$root.$emit('handleNavbarAction', BRIEFCASE_ACTION.SEND_LINK)
        },

        /*
         * Hàm gửi dưới dạng đính kèm
         */
        handleSendAttachmentsFile() {
            if (this.listSelectedBriefcase.length) this.$root.$emit('handleNavbarAction', BRIEFCASE_ACTION.SEND_AS_ATTACHMENTS)
        },

        /*
         * Hàm trong một cửa sổ riêng
         */
        handleOpenNewWindow() {
            this.$root.$emit("handleNavbarAction", BRIEFCASE_ACTION.OPEN_NEW_WINDOW)
        },

        /*
         * Hàm lấy tập tin ra
         */
        handleExportFile() {
            this.registerOrCheckoutFile(true)
            this.$root.$emit("closePopoverNavbar");
        },

        /*
         * Hàm đổi tên cặp tài liệu
         */
        handleRenameBriefcase() {
            this.$root.$emit('handleNavbarAction', BRIEFCASE_ACTION.RENAME)
            this.$root.$emit("closePopoverNavbar");
        },

        /*
         * Hàm đăng kí tệp tin
         */
        handleRegisterFile() {
            this.$root.$emit('handlShowDialogRegisterFile')
            this.$root.$emit("closePopoverNavbar");
        },

        /*
         * Hàm hủy lấy ra
         */
        handleGetOutFile() {
            this.registerOrCheckoutFile(false)
            this.$root.$emit("closePopoverNavbar");
        },

    }
}
</script>

<style>

</style>