<template>
    <table class="grid-table grid-table-all-attendees">
        <tr class="normal-row">
            <td v-for="(item, index) in computedItems" :key="index" width="2%" :class="(item.status != CALENDAR_FREE_BUSY_TYPE.FREE ? item.status : '') + (item.isWorkingHour ? ' is-working-hour' : '')" class="zm-scheduler">
                <div class="grid-div" :class="{
                    'half-hour': item.halfHour, 'is-start': item.isStart, 'is-end': item.isEnd,
                    'is-first-row': isFirstRow
                    }"
                    :attr-start-hour="item.startTime.locale('en').format('h')" 
                    :attr-end-hour="item.endTime.locale('en').format('h')" 
                    :attr-maa="item.startTime.locale('en').format('A')"
                >
                </div>
            </td>
        </tr>
    </table>
</template>
<script>
import CalendarUtils from "@/utils/calendar-utils";
import CommonUltils from "@/utils/common-utils";
import {
    DATASOURCE_FREE_BUSY_FULL, CALENDAR_FREE_BUSY_TYPE
} from "@/utils/constants.js";
/**
 {
    displayName: tên hiển thị
    resouceType: 1: Người tham dự bắt buộc, 2: Người tham dự tùy chọn, 3: Địa điểm, 4: Thiết bị
    email: //email
    status: trạng thái 1: Rảnh, 2: Không làm việc, 3: Bận, 4: Chưa dứt khoát, 5: Không xác định, 6: Ra khỏi văn phòng,
    isEditable: cờ có thể sửa true: có thể sửa, false: không thể sửa
 }
 */
export default {
    name: 'DetailScheduleGridTableTotal',
    mixins: [CalendarUtils, CommonUltils],
    props: {
        colStartTime: {
            type: Object
        },
        colEndTime: {
            type: Object
        },
        startTime: {
            type: Object
        },
        endTime: {
            type: Object
        },
        resource: {
            type: Object
        },
        isFirstRow: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
        workingHours: {
            type: Object,
            default: function() {
                return {};
            }
        },
        computedItemsMap: {
            type: Object,
            default: function() {
                return {};
            }
        }
    },
    data() {
        return {
            DATASOURCE_FREE_BUSY_FULL: DATASOURCE_FREE_BUSY_FULL,
            CALENDAR_FREE_BUSY_TYPE: CALENDAR_FREE_BUSY_TYPE,
            PRIORITY_ORDER_STATUS: {},
            NEXT_STEP_IN_MINUTES: 30,
        };
    },
    computed: {
        computedItems() {
            const returnValues = [];
            if (!this.computedItemsMap) {
                return returnValues;
            }
            const allEmail = [];
            for (let email in this.computedItemsMap) {
                allEmail.push(email);
            }
            if (allEmail.length <= 0) {
                return returnValues;
            }
            const items = this.computedItemsMap[allEmail[0]];
            for (let index in items) {
                const item = items[index];
                let returnValue = {
                    startTime: item.startTime.clone(),
                    endTime: item.endTime.clone(),
                    halfHour: item.halfHour,
                    isStart: item.isStart,
                    isEnd: item.isEnd,
                }
                returnValue = this.createItemStatusAndWorkingHour(returnValue, allEmail, index, this.computedItemsMap);
                returnValues.push(returnValue)
            }
            return returnValues;
        }
    },
    mounted() {
        this.PRIORITY_ORDER_STATUS[CALENDAR_FREE_BUSY_TYPE.FREE] = 2;// Rảnh
        this.PRIORITY_ORDER_STATUS[CALENDAR_FREE_BUSY_TYPE.BUSY_TENTATIVE] = 5;// Chưa dứt khoát
        this.PRIORITY_ORDER_STATUS[CALENDAR_FREE_BUSY_TYPE.OUT_OF_OFFICE] = 3;// Ra khỏi văn phòng,
        this.PRIORITY_ORDER_STATUS[CALENDAR_FREE_BUSY_TYPE.NON_WORKING] = 4;// Không làm việc
        this.PRIORITY_ORDER_STATUS[CALENDAR_FREE_BUSY_TYPE.UNKNOWN] = 1;// Không xác định
        this.PRIORITY_ORDER_STATUS[CALENDAR_FREE_BUSY_TYPE.BUSY] = 6;// Bận
    },
    destroyed() {
    },
    methods: {
        createItemStatusAndWorkingHour(returnValue, allEmail, index, computedItemsMap) {
            let status = null;
            let isWorkingHour = null;
            for (let i in allEmail) {
                const email = allEmail[i];
                if (!computedItemsMap[email]) {
                    continue;
                }
                const item = computedItemsMap[email][index];
                if (item) {
                    isWorkingHour = isWorkingHour || item.isWorkingHour;
                    status = this.priorityStatus(status, item.status);
                }
            }
            returnValue.status = status;
            returnValue.isWorkingHour = isWorkingHour;
            return returnValue;
        },
        priorityStatus(origin, destination) {
            const originValue = this.PRIORITY_ORDER_STATUS[origin] || 0;
            const destinationValue = this.PRIORITY_ORDER_STATUS[destination] || 0;

            return destinationValue >= originValue ? destination: origin;
        }
    },
};
</script>