<template>
    <el-dialog
        class="dialog-body-wrap zimbra-dialog"
        custom-class="mw__450"
        :title="`${formMove.title}`"
        :visible.sync="isOpenDialog"
        width="30%"
        :append-to-body="true"
        :close-on-click-modal="false"
        :destroy-on-close="true"
    >
        <el-form class="form-wrap">
            <!-- Vùng nội dung chung -->
            <div style="padding-bottom: 10px">
                {{ $t("zimbra.zmMsg.chooserDescription") }}
            </div>
            <!-- Vùng cây lịch -->
            <perfect-scrollbar style="max-height: 400px; height: auto;" class="br-8">
                <TreeBriefcaseExtra
                    :filterValue="filterText"
                    @nodeClick="onSelect"
                    :isShowRightMenu="false"
                    :isMoveBriefcase="true"
                />
            </perfect-scrollbar>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button
                class="btn-outline"
                @click="handleOpenNewBriefcase"
                style="float: left"
                >{{ $t("zimbra.zhMsg.apptPtstNEW") }}</el-button
            >
            <el-button
                class="btn-email default"
                @click="isOpenDialog = false"
                >{{ $t("zimbra.zhMsg.actionTaskCancelTT") }}</el-button
            >
            <el-button
                class="btn-email primary"
                type="primary"
                @click="handleMoveBriefcase"
                >{{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}</el-button
            >
        </span>
    </el-dialog>
</template>
<script>
import TreeBriefcaseExtra from "./TreeBriefcaseExtra.vue";
import CommonUtils from "@/utils/common-utils";
import { CALENDAR_TYPE_MOVE, FOLDER_VIEW, FOLDER_DEFAULT, BRIEFCASE_ACTION } from "@/utils/constants.js";
import BriefcaseUtils from "@/utils/briefcase-utils";
import { mapGetters } from "vuex";
export default {
    mixins: [CommonUtils, BriefcaseUtils],
    mounted() {
        this.$root.$on("handleActionMoveBriefcase", () => {
            this.show();
        }
        );
        this.$root.$on("onDropFinish", this.onMoveByDragDrop)
    },
    destroyed() {
        this.$root.$off("onOpenDialogMoveCalendar");
        this.$root.$off("onDropFinish", this.onMoveByDragDrop)
    },
    components: {
        TreeBriefcaseExtra,
    },
    data() {
        return {
            BRIEFCASE_ACTION: BRIEFCASE_ACTION,
            // Form config hiển thị chuyển
            formMove: {
                title: "",
                firstContent: "",
                dataItem: "",
                moveType: "",
            },

            // Biến tìm kiếm các hạng mục folder chuyển
            filterText: "",
            searchText: "",

            // Biến cấu hình mở popup
            isOpenDialog: false,

            // Giá trị folder chọn để chuyển
            folderSelected: {},
        };
    },
    computed: {
        ...mapGetters([
            "briefcaseTreeFolders",
            "nodeBriefcaseCurrent",
            "nodeBriefcaseExtraCurrent",
        ]),
    },
    methods: {
        show() {
            this.filterText = "";
            this.searchText = "";
            this.isOpenDialog = true;

            this.formMove.title = this.$t("zimbraNokey.zimbra_sidebar_dialogMoveFolder_title1");
            this.formMove.parent = this.nodeBriefcaseCurrent;
            setTimeout(() => {
                this.$root.$emit("setCurrentKey", this.nodeBriefcaseCurrent?.id);
            }, 300)
        },
        closeDialog() {
            this.isOpenDialog = false;
        },
        onChangeSearch(text) {
            this.filterText = text
        },

        /**
         * Cập nhật giá trị khi chọn cây
         */
        onSelect(nodeInfo) {
            this.folderSelected = nodeInfo;
            this.searchText = nodeInfo.name;
        },

        /**
         * hàm check tên lịch bị trùng
         */
        checkExistsName(name, folderParent) {
            if (Array.isArray(folderParent.folder)) {
                if (
                    folderParent.folder
                        .map((x) => x.name.toString().toLowerCase())
                        .indexOf(name.toString().toLowerCase()) > -1
                ) {
                    this.$alert(
                        this.$t("zimbra.zmMsg.folderAlreadyExistsInDestination", {
                            name: name,
                        }),
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return true;
                }
            }
        },

        /**
         * hàm thực hiện chuyển Briefcase
         */
        async handleMoveBriefcase() {
            if (this.$store.getters.nodeBriefcaseExtraCurrent) {
                this.treeNodeMove = this.$store.getters.nodeBriefcaseExtraCurrent;
                if (this.checkExistsName(this.formMove.parent.name, this.treeNodeMove)) {
                    return;
                }
                this.$root.$emit("handleNavbarAction", BRIEFCASE_ACTION.MOVE)
                this.isOpenDialog = false
            }
        },

        /**
         * Hàm chuyển lịch bằng kéo thả
         */
        onMoveByDragDrop(srcElement, desElement) {
            const srcType = srcElement.getAttribute("type")
            const desType = desElement.getAttribute("type")
            // Điều kiện để thực hiện move lịch
            if (FOLDER_VIEW.APPOINTMENT == srcType && (FOLDER_VIEW.APPOINTMENT == desType || FOLDER_DEFAULT.TRASH == desElement.getAttribute("id"))) {
                this.formMove.moveType = CALENDAR_TYPE_MOVE.CALENDAR;
                // Gán dữ liệu thư mục nguồn
                this.formMove.dataItem = {
                    id: srcElement.getAttribute("id"),
                    name: srcElement.getAttribute("name")
                }

                // Gán dữ liệu thư mục đích
                this.folderSelected = {
                    id: desElement.getAttribute("id"),
                    name: desElement.getAttribute("name")
                }

                this.onMove();
            }
        },
        handleOpenNewBriefcase() {
            this.$root.$emit('handleActionAddBriefcase', true);
        }
    },
};
</script>
<style scoped>
.perfect-scrollbar-boder{
    border: 1px solid #0c0c0c;
    border-radius: 8px;
}
</style>
