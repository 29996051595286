<template>
    <div class="card-filter-row">
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueMainSelect"
                :options="mainSelect"
                @change="handleChangeMainSelect"
            />
        </div>
        <div class="card-filter-item"></div>
        <div class="card-filter-item select-wrap__210">
            <base-select
                displayField="name"
                valueField="inviteCompartison"
                :value="valueInviteCompartison"
                :options="inviteCompartison"
                @change="handleChangeInviteTest"
            />
        </div>
        <div class="card-filter-item"></div>
        <div class="card-filter-item"></div>
    </div>
</template>

<script>
import { FILTER_TEST_CONFIG } from "@/utils/constants";
import CoreTest from "@/components/modules/base/base-filter-form/core-test";
import BaseSelect from "@/components/modules/base/base-select";
export default {
    extends: CoreTest,
    components: {
        BaseSelect,
    },
    created() {
        this.beforeBuildDataForm();
        this.conditionsInviteTest = this.conditionsTest;
    },
    watch: {
        conditionsTest(newData) {
            this.beforeBuildDataForm();
            this.conditionsInviteTest = newData;
        },
    },
    data() {
        return {
            FILTER_TEST_CONFIG: FILTER_TEST_CONFIG,
            inviteCompartison: [
                {
                    inviteCompartison: FILTER_TEST_CONFIG.METHOD.ANY_REQUEST,
                    name: "thư mời là bắt buộc",
                },
                {
                    inviteCompartison:
                        FILTER_TEST_CONFIG.METHOD.NOT_ANY_REQUEST,
                    name: " thư mời là không bắt buộc",
                },
                {
                    inviteCompartison: FILTER_TEST_CONFIG.METHOD.ANY_REPLY,
                    name: "thư mời được trả lời",
                },
                {
                    inviteCompartison: FILTER_TEST_CONFIG.METHOD.NOT_ANY_REPLY,
                    name: "thư mời không được trả lời",
                },
            ],
            valueMainSelect: "",
            valueInviteCompartison: FILTER_TEST_CONFIG.METHOD.ANY_REPLY,
            conditionsInviteTest: {},
        };
    },
    methods: {
        beforeBuildDataForm() {
            this.valueMainSelect = this.mainSelect[12].id;
            if (
                this.conditionsTest.method ===
                FILTER_TEST_CONFIG.METHOD.ANY_REQUEST
            ) {
                this.valueInviteCompartison =
                    FILTER_TEST_CONFIG.METHOD.ANY_REQUEST;
                if (this.conditionsTest.negative) {
                    this.valueInviteCompartison =
                        FILTER_TEST_CONFIG.METHOD.NOT_ANY_REQUEST;
                }
            }
            if (
                this.conditionsTest.method ===
                FILTER_TEST_CONFIG.METHOD.ANY_REPLY
            ) {
                this.valueInviteCompartison =
                    FILTER_TEST_CONFIG.METHOD.ANY_REPLY;
                if (this.conditionsTest.negative) {
                    this.valueInviteCompartison =
                        FILTER_TEST_CONFIG.METHOD.NOT_ANY_REPLY;
                }
            }
        },
        handleChangeInviteTest(inviteCompartison) {
            for (let i in this.conditionsInviteTest) {
                if (
                    i !== "negative" &&
                    this.validateNegative(inviteCompartison)
                ) {
                    this.conditionsInviteTest = {
                        ...this.conditionsInviteTest,
                        negative: FILTER_TEST_CONFIG.NEGATIVE,
                    };
                } else {
                    if (!this.validateNegative(inviteCompartison)) {
                        delete this.conditionsInviteTest.negative;
                    }
                }
            }
            this.valueInviteCompartison = inviteCompartison;
            this.conditionsInviteTest.method =
                this.replacePrefix(inviteCompartison);
            this.$emit("onChangeConditionsTest", this.conditionsInviteTest);
        },
    },
};
</script>
