<template>
    <!-- dialog đổi tên thư mục-->
    <el-dialog class="dialog-body-wrap zimbra-dialog dialog_edit_name_wrapper zimbra-limited-width-dialog"
        :title="titleDialog" :visible.sync="dialogEditName" width="25%" :append-to-body="true"
        :close-on-click-modal="false">
        <el-form class="form-wrap">
            <el-form-item :label="$t('zimbra.zhMsg.newName')" label-width="100%">
                <el-input class="el-text-control" v-model="newName" autocomplete="off" size="medium" :maxlength="maxLengName"
                show-word-limit ref="refInputName"></el-input>
            </el-form-item>
            <!-- <span>{{ $t("zimbra.zhMsg.newName") }}</span>
            <el-input
                v-model="newName"
                autocomplete="off"
                size="medium"
                class="el-text-control"
            ></el-input> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogEditName = false">{{
                $t("zimbra.zhMsg.actionTaskCancelTT")
            }}</el-button>
            <el-button type="primary" @click="reNameFolder">{{
                $t("zimbra.zmMsg.mobileStatusNeedProvision")
            }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import { mapGetters } from "vuex";
import { STATUS_REQUEST, READ_EMAIL_LAYOUT_ROUTER } from "@/utils/constants";
import _ from "lodash";
export default {
    mixins: [CommonUtils],
    computed: {
        ...mapGetters([
            "activeNodeSidebarRightClick",
            "dataSidebarInBox",
            "dataSidebarContact",
            "dataSidebarExtend",
        ]),
    },
    mounted() {
        this.$root.$on("handleSidebarActionEditName", () => {
            this.show();
        });
    },
    data() {
        return {
            formEdit: {},
            newName: "",
            dialogEditName: false,
            titleDialog: "",
            maxLengName: 10
        };
    },
    methods: {
        show() {
            const { name, isSearch } = this.activeNodeSidebarRightClick;
            this.titleDialog = isSearch
                ? this.$t('zimbraNokey.zimbra_sidebar_dialogReName_titleSearch', { name: name })
                : this.$t('zimbraNokey.zimbra_sidebar_dialogReName_title', { name: name });
            this.formEdit = this.activeNodeSidebarRightClick;
            this.newName = name;
            this.dialogEditName = true;
            setTimeout(() => {
                this.$refs.refInputName.focus();
            }, 200);
            const routeName = this.$route?.name;
            if (routeName == READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE) {
                this.isMessage = true
            } else {
                this.isMessage = false
            }
        },
        checkExistsName(name, folderParent) {
            if (Array.isArray(folderParent?.folder)) {
                if (folderParent.folder.map(x => x.name.toString().toLowerCase()).indexOf(name.toString().toLowerCase()) > -1) {
                    let msgerror = this.$t("zimbra.zmMsg.errorAlreadyExists", { 0: name, 1: this.$t("zimbra.zhMsg.ALT_FOLDER") });
                    if (this.formEdit.isSearch) {
                        msgerror = this.$t("zimbraNokey.zimbra_searchBox_duplidateName", { name });
                    }
                    this.$alert(
                        msgerror,
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return true;
                }
            } else if (Array.isArray(folderParent?.search)) {
                if (folderParent.search.map(x => x.name.toString().toLowerCase()).indexOf(name.toString().toLowerCase()) > -1) {
                    let msgerror = this.$t("zimbraNokey.zimbra_sidebar_messageCriticalExistsName", { name: name });
                    if (this.formEdit.isSearch) {
                        msgerror = this.$t("zimbraNokey.zimbra_searchBox_duplidateName", { name });
                    }
                    this.$alert(
                        msgerror,
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return true;
                }
            }
        },
        findFolderById(id, listFolder) {
            for (let index in listFolder) {
                if (listFolder[index].id == id) {
                    return listFolder[index];
                } else if (listFolder[index].folder) {
                    const result = this.findFolderById(id, listFolder[index].folder);
                    if (result != null) {
                        return result;
                    }
                }
            }
            return null;
        },
        reNameFolder() {
            this.newName = this.newName.toString().trim()
            let folderParent = {}
            this.formEdit.view == "contact" ? folderParent = this.findFolderById(this.formEdit.l, this.dataSidebarContact?.[0].folder) : folderParent = this.findFolderById(this.formEdit.l, this.dataSidebarInBox?.[0].folder) ||
                this.findFolderById(this.formEdit.l, this.dataSidebarExtend[0].folder);
            if (this.newName.toLowerCase() != this.formEdit.name.toString().toLowerCase()) { /// check trùng với tên viết hoa của chính nó
                if (this.checkExistsName(this.newName, folderParent)) {
                    return;
                }
            }

            let regexString = /[!@#$%^&*(),.?":{}|<>]/
            if (this.newName == "" || this.newName.length > this.maxLengName || this.newName.indexOf("/") > -1 || regexString.test(this.newName)) {
                const nameError = this.newName;
                const Msgerror = this.newName == "" ? this.$t("zimbra.zmMsg.nameEmpty") :
                    (this.newName.length > this.maxLengName 
                    ? this.$t("zimbraNokey.zimbra_sidebar_messageCriticalMaxLengthName_with_number", {maxCharacter: this.maxLengName}) 
                    : this.$t("zimbraNokey.zimbra_sidebar_messageCriticalMaxFormatName", { nameError: nameError }));

            
                this.$alert(
                    Msgerror,
                    this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                    {
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "error",
                    }
                );
                return;
            }
            const newName = _.escape(this.newName);
            const form = {
                folder: `<urn1:action id="${this.formEdit.id}" op="rename" name="${newName}"></urn1:action>`,
            };
            ZimbraMailService.folderActionRequest(form).then((res) => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    if (this.isMessage) {
                        this.$message({
                            type: "success",
                            customClass: 'zm-notify',
                            duration: 3500,
                            dangerouslyUseHTMLString: true,
                            message: this.$t("zimbraNokey.zimbra_sidebar_dialogReName_messageSuccessRename")
                        });
                    } else {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            duration: 2000,
                            message: this.$t("zimbraNokey.zimbra_sidebar_dialogReName_messageSuccessRename"),
                            type: "success",
                        });
                    }
                }
                // Hiển thị hộp thoại mới chứa tên cũ của thư mục
                setTimeout(() => {
                    const oldFolderName = this.formEdit.name;
                    this.$alert(
                        oldFolderName,
                        this.$t("zimbraNokey.zimbra_sidebar_dialogReName_messageSuccessRename"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "info",
                        }
                    );
                }, 500);
                const indexName = this.formEdit.absFolderPath.lastIndexOf(this.formEdit.name)
                this.formEdit.absFolderPath = this.formEdit.absFolderPath.substring(0, indexName) + this.newName;
                this.buildAbsFolderPath(this.formEdit);
                this.formEdit.name = this.newName;
                this.$store.commit("SET_ACTIVE_NODE_SIDEBAR_RIGHTCLICK", this.formEdit);
            });
            this.dialogEditName = false;
        },
        buildAbsFolderPath(parent) {
            if (parent.folder) {
                parent.folder = Array.isArray(parent.folder) ? parent.folder : [parent.folder];
            }
            for (let item in parent.folder) {
                parent.folder[item].absFolderPath =
                    parent.absFolderPath + "/" + parent.folder[item].name;
                if (parent.folder[item].folder) {
                    this.buildAbsFolderPath(parent.folder[item]);
                }
            }
        },
    },
};
</script>
