<template>
  <el-dialog
    title="Tùy chọn Biểu tượng cảm xúc Yahoo!"
    custom-class="dialog-yahoo-detail"
    :visible.sync="dialogYahoo"
    :append-to-body="true"
    :close-on-click-modal="false"
    :show-close="false"
    top="40vh"
    width="35rem"
  >
    <base-checkbox option="Bật biểu tượng cảm xúc trong Thư" :value="isShowZimletYahoo" @change="handleCheckActiveYahoo"/>

    <div class="footer-dialog">
         <base-button-default class="btn-email primary" @click="handleSaveYahoo">
             {{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}
        </base-button-default>
    </div>
  </el-dialog>
</template>

<script>
import BaseCheckbox from "@/components/modules/base/base-checkbox";
import BaseButtonDefault from "@/components/modules/base/base-button/btn-default";
import ZimbraService from "@/services/ZimbraService";
import { READ_EMAIL_LAYOUT_ROUTER } from "@/utils/constants";
export default {
    data(){
        return{
            isShowZimletYahoo : false,
            dialogYahoo:false,
        }
    },
    mounted() {
        this.$root.$on("handleShowZimletYahoo", (isShowZimletYahoo) => {
            this.show(isShowZimletYahoo);
        });
    },
    components: {
        BaseCheckbox,
        BaseButtonDefault
    },
    methods:{
        show(isShowZimletYahoo) {
            this.dialogYahoo = true;
            this.isShowZimletYahoo = isShowZimletYahoo;

            const { name } = this.$route;
            if (name == READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE) {
                this.isMessage = true
            } else {
                this.isMessage = false
            }
        },
        handleCheckActiveYahoo(val){
            this.isShowZimletYahoo = val;
        },
        handleSaveYahoo(){
            //debugger; // eslint-disable-line no-debugger
            ZimbraService.saveZimletYahoo(this.isShowZimletYahoo)
                .then(() => {
                    if (this.isMessage) {
                        this.$message({
                            type: "success",
                            customClass: 'zm-notify',
                            duration: 3500,
                            dangerouslyUseHTMLString: true,
                            message: this.$t("zimbraNokey.zimbra_notify_success_message"),
                        });
                    } else {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t("zimbraNokey.zimbra_notify_success_message"),
                            type: "success",
                        });
                    }
                })
                .catch(err => console.log(err));
            this.dialogYahoo = false;
        }
    }
}
</script>

<style lang="scss">
.footer-dialog{
   display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
}
</style>
