<template>
    <div class="bottom-content">
        <div class="list-event scrollbar-y scrollbar">
            <div v-if="isNoEvents" class="no-event">Không có cuộc hẹn nào.</div>
            <div v-else class="item" v-for="event in lstEvent" :key="event.name" @click="showQuickDetail(event)">
                <div class="time">
                    <span class="from">{{convertStringToDate(event.start, "HH:mm")}}</span> -
                    <span class="to"> {{convertStringToDate(event.end, "HH:mm")}}</span>
                </div>
                <div class="title">{{event.name}}</div>
                <div class="member">{{event.loc}}</div>
            </div>
        </div>
    </div>


</template>

<script>
import CalendarUltils from "@/utils/calendar-utils.js";
export default {
    components: {
    },
    mixins: [CalendarUltils],
    data() {
        return {
            lstEvent: [],
            showQuickCalender: false
        };
    },
    created() {

    },
    mounted() {
        this.$root.$on("onDataSettingSearchEvent", _lstEvent => this.lstEvent = _lstEvent);
    },
    destroyed() {
        this.$root.$off("onDataSettingSearchEvent");
    },
    computed: {
        isNoEvents() {
            return !this.lstEvent || this.lstEvent.length == 0;
        }
    },
    methods: {
        showQuickDetail(event) {
            this.$store.commit("SET_ACTIVE_APPOINTMENT", event)
            // xử lý đóng popup khi double click
            // this.$emit("openDialogDetailSchedule");
            // this.$root.$emit("openDialogDetailSchedule", event);
            this.$root.$emit("openQuickEditCalandar", event);
            // this.showQuickCalender = true
        },
    },
};
</script>
<style lang="scss" scoped>
.toolbar-menu .toolbar-item span {
    display: contents !important;
}
</style>
