<template>
    <ul ref="subMenuTag" class="sub-menu">
        <template v-for="(item, idx) in listTypeDaySearch">
            <li
                class="zm-tag-item"
                :key="`tag_${idx}`"
                @click="handleChoose(idx)"
            >
                <el-popover
                    v-model="showPopover[idx]"
                    placement="left"
                    trigger="click"
                    class="zm-popover-search"
                    popper-class="zm-popover-search"
                    :key="`tag_${idx}`"
                >
                    <a slot="reference" href="javascript:;">
                        <span> {{ $t(item.label) }}</span>
                        <button
                            class="icon-caret float-right"
                            style="position: absolute; right: 0px; padding-right:15px"
                        >
                            <i class="el-icon-caret-right"></i>
                        </button>
                    </a>
                    <el-date-picker
                        v-model="lstTime[idx]"
                        type="date"
                        :clearable="false"
                        format="dd/MM/yyyy"
                        @change="handleSelectDay(idx, item)"
                    >
                    </el-date-picker>
                </el-popover>
            </li>
        </template>
    </ul>
</template>
<script>
import { LIST_TYPE_DAY_SEARCH, TYPE_DAY_SEARCH } from "@/utils/constants"
import moment from "moment"
export default {
    data() {
        return {
            lstTime: [null, null, null],
            showPopover: [false, false, false]
        }
    },
    mounted() {
        const _now = moment(new Date().getTime()).format("DD/MM/yyyy")
        this.lstTime = [_now, _now, _now]
        this.$root.$on("closePoverDaySearch", this.closePoverDaySearch)
    },
    computed: {
        // Danh sách tất cả loại fil
        listTypeDaySearch() {
            return LIST_TYPE_DAY_SEARCH;
        }
    },
    methods: {
        closePoverDaySearch() {
            this.showPopover = [false, false, false]
        },
        handleChoose(idx) {
            this.showPopover = this.showPopover.map((item, index) => {
                if (index === idx) return true
                return false
            })
        },
        handleSelectDay(idxDate, itemDay) {
            let _contentQuery = ""
            const _date = moment(this.lstTime[idxDate]).format("D/M/YY")
            if (TYPE_DAY_SEARCH.BEFORE == itemDay.value) {
                _contentQuery = "before:" + _date
            } else if (TYPE_DAY_SEARCH.AFTER == itemDay.value) {
                _contentQuery = "after:" + _date
            } else if (TYPE_DAY_SEARCH.ON == itemDay.value) {
                _contentQuery = "date:" + _date
            }
            this.closePoverDaySearch()
            this.$emit("onSelectDay", _contentQuery, itemDay.value)
        }
    },
    destroyed() {
        this.$root.$off("closePoverDaySearch")
    }
};
</script>
<style scoped>
.sub-menu {
    margin-left: 5px;
}
</style>
