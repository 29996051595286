<template>
    <form
        class="zimbra-search-box search-box"
        id="searchBox"
        style="display: block"
        v-on:submit.prevent
    >
        <div class="zimbra form-search-group">
            <span class="text">{{ $t("zimbra.zhMsg.FILT_COND_HEADER_from") }}</span>
            <el-select
                class="select-input-wrap"
                size="large"
                v-model="model.from"
                filterable
                remote
                :clearable="true"
                :placeholder="$t('zimbraNokey.zimbra_searchBox_addressPlaceholder')"
                :remote-method="remoteMethodEmail"
                :no-data-text="searchNotFound"
            >
                <el-option
                    v-for="(item, idx) in listEmail"
                    :key="`${item.email}_${idx}`"
                    :label="item.label"
                    :value="item.label"
                >
                    {{ item.email }}
                </el-option>
            </el-select>
        </div>
        <div class="zimbra form-search-group">
            <span class="text">{{ $t("zimbra.zhMsg.FILT_COND_HEADER_to") }}</span>
            <el-select
                class="select-input-wrap"
                size="large"
                v-model="model.to"
                multiple
                filterable
                remote
                :clearable="true"
                :placeholder="$t('zimbraNokey.zimbra_searchBox_addressPlaceholder')"
                :remote-method="remoteMethodEmail"
                :no-data-text="searchNotFound"
            >
                <el-option
                    v-for="(item, idx) in listEmail"
                    :key="`${item.email}_${idx}`"
                    :label="item.label"
                    :value="item.label"
                >
                    {{ item.email }}
                </el-option>
            </el-select>
        </div>
        <div class="form-search-group">
            <span class="text">{{ $t("zimbra.zhMsg.FILT_COND_HEADER_subject") }}</span>
            <input
                type="text"
                class="form-control"
                v-model="model.subject"
                @change="model.subject = model.subject.trim()"
            />
        </div>
        <div class="form-search-group">
            <span class="text">{{ $t("zimbraNokey.zimbra_searchBox_hasTheWords") }}</span>
            <input
                type="text"
                class="form-control"
                v-model="model.hasTheWords"
                v-on:keyup.space="model.hasTheWords = changeKeyWordSearch(model.hasTheWords)"
                @change="model.hasTheWords = model.hasTheWords.trim()"
            />
        </div>
        <div class="form-search-group">
            <span class="text">{{ $t("zimbraNokey.zimbra_searchBox_doesNotHave") }}</span>
            <input
                type="text"
                class="form-control"
                v-model="model.doesNotHave"
                v-on:keyup.space="model.doesNotHave = changeKeyWordSearch(model.doesNotHave)"
                @change="model.doesNotHave = model.doesNotHave.trim()"
            />
        </div>
        <div class="form-search-group">
            <span class="text">&nbsp;</span>
            <label class="i-check">
                <input
                    type="checkbox"
                    name="method"
                    v-model="model.hasAttachment"
                /><i></i>
                <span>{{ $t("zimbra.zhMsg.EFILT_COND_ATTACHMENT_EXISTS") }}</span>
            </label>
        </div>
        <div class="zimbra form-search-group">
            <span class="text">{{ $t("zimbraNokey.zimbra_searchBox_searchIn") }}</span>
            <div class="w-100">
                <el-popover
                    v-model="visableFolder"
                    placement="bottom-start"
                    trigger="click"
                >
                    <TreeFolderExtra
                        :listIdFolderHidden = [FOLDER_DEFAULT.DRAFTS,FOLDER_DEFAULT.ROOT]
                        @onSelectFolder="onSelectFolder"
                    ></TreeFolderExtra>
                    <!-- <input
                        slot="reference"
                        type="button"
                        class="form-control text-left"
                        v-model="model.searchInName"
                    /> -->
                    <el-input
                        slot="reference"
                        v-model="model.searchInName"
                        clearable
                        :readonly="true">
                    </el-input>
                </el-popover>
            </div>
        </div>
        <div class="zimbra form-search-group">
            <span class="text">{{ $t("zimbraNokey.zimbra_searchBox_size") }}</span>
            <div class="right">
                <base-select
                    id="attribute-select-size"
                    v-model="model.size"
                    displayField="label"
                    valueField="value"
                    :placeholder="$t('zimbra.zhMsg.select')"
                    :clearable="true"
                    :options="optionsSize"
                />
                <div v-if="model.size" class="show-right">
                    <input
                        type="text"
                        min="0"
                        max="1000000"
                        class="form-control size-attach"
                        
                        v-model="model.sizeAttach"
                        @input="handleInput"
                    />
                    <div class="select-size-unit">
                        <base-select
                            id="attribute-select-size-unit"
                            v-model="model.sizeUnit"
                            displayField="label"
                            valueField="value"
                            placeholder=""
                            value="MB"
                            :clearable="true"
                            :options="optionsSizeUnit"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="model.sizeAttach > 1000000" style="color: red; margin-bottom: 1em; text-align: right;">{{ "Giá trị nhập vào không vượt quá 1000000" }}</div>
        <div class="zimbra form-search-group">
            <span class="text">{{ $t("zimbraNokey.zimbra_searchBox_time") }}</span>
            <div class="right">
                <base-select
                    id="attribute-select-time"
                    v-model="model.time"
                    displayField="label"
                    valueField="value"
                    :placeholder="$t('zimbra.zhMsg.select')"
                    :clearable="true"
                    :options="optionsTime"
                />
                <div v-if="model.time" class="show-right">
                    <el-date-picker
                        ref="datePicker"
                        v-model="model.date"
                        type="date"
                        format="dd/MM/yyyy"
                        :clearable="false"
                        value-format="d/M/yy"
                        @focus="changeOptionsTime"
                    >
                    </el-date-picker>
                    <!-- <span class="dc"></span>
                    <el-date-picker
                        v-model="model.toDate"
                        type="date"
                        :clearable="false"
                        format="dd/MM/yyyy"
                        value-format="d/M/yy"
                    >
                    </el-date-picker> -->
                </div>
            </div>
        </div>
        <div class="btn-search-box">
            <button type="button" class="reset" @click="handleReset">
                {{ $t("zimbra.zmMsg.revert") }}
            </button>
            <button type="submit" class="search" @click="handleSearch">
                {{ $t("zimbra.zhMsg.search") }}
            </button>
        </div>
    </form>
</template>
<script>
import { FOLDER_DEFAULT } from "@/utils/constants";
import TreeFolderExtra from "./TreeFolderExtra";
import BaseSelect from "@/components/modules/base/base-select";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import moment from "moment";
export default {
    name: "DialogSearchBox",
    mixins: [CommonUtils],
    props: {
        value: {
            type: Object
        }
    },
    data() {
        return {
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            model: this.value
                ? {
                    ...this.value,
                    sizeUnit: this.value.sizeUnit ?? "MB",
                    searchInName: this.value.searchInName ?? this.$t("zimbra.zmMsg.allFolders"),
                    date: this.value.date ?? moment(new Date()).format("D/M/YY")
                }
                : {
                    sizeUnit: "MB",
                    searchInName: this.$t("zimbra.zmMsg.allFolders"),
                    date: moment(new Date()).format("D/M/YY")
                },
            visableFolder: false,
            optionsSize: [
                {
                    value: "larger",
                    label: this.$t("zimbraNokey.zimbra_searchBox_greaterThan"),
                },
                {
                    value: "smaller",
                    label: this.$t("zimbraNokey.zimbra_searchBox_littleThan"),
                },
            ],
            optionsTime: [
                {
                    value: "before",
                    label: this.$t("zimbra.zhMsg.MO_PREV"),
                },
                {
                    value: "after",
                    label: this.$t("zimbra.zmMsg.after"),
                },
                {
                    value: "date",
                    label: this.$t("zimbraNokey.zimbra_searchBox_within"),
                },
            ],
            optionsSizeUnit: [
                {
                    value: "KB",
                    label: this.$t("zimbraNokey.zimbra_searchBox_kb"),
                },
                {
                    value: "MB",
                    label: this.$t("zimbraNokey.zimbra_searchBox_mb"),
                },
            ],
            searchNotFound: this.$t("zimbraNokey.zimbra_common_searchNotFound"),
            listEmail: [],
            today: moment(new Date()).format("D/M/YY")
        };
    },
    components: {
        TreeFolderExtra,
        BaseSelect,
    },
    methods: {
        changeOptionsTime() {
            setTimeout(() => {
                this.$refs.datePicker.popperElm.childNodes[0].childNodes[1].childNodes[1].onclick =
                function() {
                    let textContent = this.childNodes[this.childNodes.length - 4].childNodes[0].data;
                    if (textContent.includes('-')) {
                        let listString = textContent.split("-");
                        listString[0]= listString[0].replace(' ', ' - ')
                        listString.reverse();
                        this.childNodes[this.childNodes.length - 4].childNodes[0].data = listString.join(" ");
                    }
                }
            },200)
        },
        handleInput(event) {
            let inputValue = event.target.value;

            // Loại bỏ tất cả các ký tự không phải số và chữ "e"
            inputValue = inputValue.replace(/[^\d.]/gi, '');

            // Kiểm tra nếu dấu chấm được nhập vào đầu tiên, thì thay thế nó bằng chuỗi rỗng
            if (inputValue.indexOf('.') === 0) {
                inputValue = '';
            }

            // Kiểm tra xem dấu chấm có xuất hiện nhiều hơn một lần không
            if (inputValue.split('.').length > 2) {
                // Nếu có, chỉ giữ lại phần đầu tiên của số thập phân
                inputValue = inputValue.replace(/(\..*)\./, '$1');
            }

            this.model.sizeAttach = inputValue;
        },
        changeKeyWordSearch(keyWord) {
            // let transformText = "";
            // const regex = /^[a-zA-Z]+$/;
            //     const arrKeyWord = keyWord.split(' ');
            //     arrKeyWord.forEach(x => {
            //             if (!regex.test(x) && !this.isContainsBlock(x) && x) {
            //             transformText = transformText + `"${x}"`  + " ";
            //             } else {
            //                 transformText = transformText + x + " ";
            //             }
            //         }
            //     )
            // keyWord = transformText.replaceAll("  ", " ");
            return keyWord;
        },
        isContainsBlock(keyStr) {
            if(!keyStr || keyStr.length < 2) {
                return false;
            }
            if(keyStr[0] == '"' && keyStr[keyStr.length - 1] == '"') {
                return true;
            }
            return false;
        },
        handleReset() {
            this.model = {
                sizeUnit: "MB",
                searchInName: this.$t("zimbra.zmMsg.allFolders"),
                date: this.today,
                to: []
            };
            // this.$emit("input", this.model);
            // this.$emit("onclose");
        },
        handleSearch() {
            // this.$emit("input", this.model);
            this.$root.$emit('modelSearch', this.model);
            if (parseInt(this.model.sizeAttach, 10) > 1000000) {
                // this.$notify({
                //     title: this.$t("zimbra.zmMsg.dataSourceTestFailure"),
                //     message: this.$t("zimbraNokey.zimbra_searchBox_maxSize"),
                //     type: "success"
                // });
             
                return;
            }
            if (this.model.subject  ) {
                if (this.model.subject.length > 256 ) {
                this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestFailure"),
                        message: "Trường dữ liệu 'Chủ để' không được vượt quá 60 ký tự.",
                        type: "warning"
                    });
                  
                return;
                }
            }
            if (this.model.hasTheWords   ) {
                if ( this.model.hasTheWords.length > 256 ) {
                this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestFailure"),
                        message: "Trường dữ liệu 'Có các từ' không được vượt quá 60 ký tự.",
                        type: "warning"
                    });
                    this.queryKeyWord=this.model.hasTheWords;
                return;
                }
            }
            if ( this.model.doesNotHave ) {
                if ( this.model.doesNotHave.length > 256) {
                this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestFailure"),
                        message: "Trường dữ liệu '' không được vượt quá 60 ký tự.",
                        type: "warning"
                    });
                  
                return;
                }
            }
       
            this.$emit("onsearch", this.model);
        },
        onSelectFolder(data) {
            this.visableFolder = false;
            this.model.searchIn = data;
            this.model.searchInName = data.absFolderPathI18n || data.name;
        },
        remoteMethodEmail(query) {
            const regex =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            
            if (query !== "") {
                const form = { name: `${query}` };
                ZimbraMailService.autoCompleteRequest(form)
                    .then((res) => {
                        const data =
                            this.getResponseBody(res)?.AutoCompleteResponse
                                ?.match;
                        this.listEmail = Array.isArray(data) ? data : (data ? [data] : []);
                        if(regex.test(query)){
                            const firstName = query ? query.split("@")[0]: '';
                            this.listEmail.push({email: `${firstName}<${query}>`,first:firstName})
                        }
                       
                        if (this.listEmail) {
                            this.listEmail.forEach((x) => {
                                if (x) {
                                    x.label = x ? x.email.substring(
                                                x.email.indexOf("<") + 1,
                                                x.email.length - 1
                                            ) : '';
                                }
                            });
                        }
                    })
                    .catch();
            } else {
                this.listEmail = [];
            }
        },

    },
};
</script>