<template>
    <table class="w-100" :class="{ 'mb-3': type == 'datetime', 'zm-date-editor-allday': type == 'date' }">
        <tr>
            <td style="width: 45%">
                <div class="zm-date-editor" @click="onclickStartTime">
                    <div class="content" :class="{ noclick: disabled }">
                        <div v-if="type == 'datetime'" class="hour">
                            {{ startHour }}
                        </div>
                        <div class="date">
                            {{ startDate }}
                        </div>
                    </div>
                    <div class="date-input">
                        <el-date-picker
                            ref="refStartTime"
                            v-model="startTime"
                            :type="type"
                            :value-format="valueFormat"
                            :popper-class="popperClass"
                            :clearable="clearable"
                            :picker-options="{
                                format: 'HH:mm',
                            }"
                            @change="onchangStartTime"
                        >
                        </el-date-picker>
                    </div>
                </div>
            </td>
            <td class="text-center">
                <span v-if="rangeSeparator">
                    {{ rangeSeparator }}
                </span>
                <div v-else class="zm-range-separator"></div>
            </td>
            <td style="width: 45%">
                <div class="zm-date-editor" @click="onclickEndTime">
                    <div class="content" :class="{ noclick: disabled }">
                        <div v-if="type == 'datetime'" class="hour">
                            {{ endHour }}
                        </div>
                        <div class="date">
                            {{ endDate }}
                        </div>
                    </div>
                    <div class="date-input">
                        <el-date-picker
                            ref="refEndTime"
                            v-model="endTime"
                            :type="type"
                            :value-format="valueFormat"
                            :popper-class="popperClass"
                            :clearable="clearable"
                            :picker-options="{
                                format: 'HH:mm',
                            }"
                            @change="onchangeEndTime"
                        >
                        </el-date-picker>
                    </div>
                </div>
            </td>
        </tr>
    </table>
</template>
<script>
/**
 * Component chọn khoảng ngày giờ
 */
import moment from "moment";
import { mapGetters } from "vuex";
export default {
    name: "BaseDateTimeRange",
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: "date"
        },
        rangeSeparator: {
            type: String,
            default: null
        },
        popperClass: {
            type: String,
            default: ""
        },
        valueFormat: {
            type: String,
            default: "yyyyMMddTHHmmss"
        },
        clearable: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        duration: {
            type: Number
        }
    },
    data() {
        return {
            startTime: null,
            endTime: null
        };
    },
    computed: {
        ...mapGetters(["localeCurrent"]),
        startHour() {
            return this.startTime ? moment(this.startTime).format("HH:mm") : "";
        },
        startDate() {
            return this.startTime ? moment(this.startTime).locale(this.localeCurrent.content).format("ddd, DD/MM/YYYY") : "";
        },
        endHour() {
            return this.endTime ? moment(this.endTime).format("HH:mm") : "";
        },
        endDate() {
            return this.endTime ? moment(this.endTime).locale(this.localeCurrent.content).format("ddd, DD/MM/YYYY") : "";
        }
    },
    watch: {
        value() {
            if (this.value && this.value.length == 2) {
                this.startTime = this.value[0];
                this.endTime = this.value[1];
            }
        }
    },
    mounted() {
        if (this.value && this.value.length == 2) {
            this.startTime = this.value[0];
            this.endTime = this.value[1];
        }
    },
    methods: {
        onclickStartTime() {
            if (this.disabled) {
                return;
            }
            this.$refs.refStartTime.focus();
        },
        onclickEndTime() {
            if (this.disabled) {
                return;
            }
            this.$refs.refEndTime.focus();
        },
        /**
         * Thay đổi thời gian bắt đầu
         */
        onchangStartTime() {
            if (this.duration && (moment(this.endTime).isBefore(moment(this.startTime)) || moment(this.endTime).isSame(moment(this.startTime)))) {
                this.endTime = moment(this.startTime).add(this.duration, "seconds").format("yyyyMMDDTHHmmss");
            }
            this.$emit("change", [this.startTime, this.endTime]);
        },
        /**
         * Thay đổi thời gian kết thúc
         */
        onchangeEndTime() {
            if (this.duration && (moment(this.endTime).isBefore(moment(this.startTime)) || moment(this.endTime).isSame(moment(this.startTime)))) {
                this.startTime = moment(this.endTime).add(0 - this.duration, "seconds").format("yyyyMMDDTHHmmss");
            }
            this.$emit("change", [this.startTime, this.endTime]);
        },
    },
};
</script>
