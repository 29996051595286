<template>
    <el-popover ref="popoverAddress" placement="top-start" width="300" trigger="click" @show="onShowPopover"
        v-model="showPopover" :open-delay="10000">
        <div class="zm-address-info-popover">
            <el-row>
                <el-col :span="6">
                    <BaseAvatar :listEmailAddress="listEmailAvatar" :maxWidth="80" :maxHeight="80"
                        imageClass="zm-img" />
                    <!-- <img class="zm-img" src="@/assets/zimbra/images/avt-login.png" alt="" /> -->
                </el-col>
                <el-col :span="18" class="pl-4">
                    <div>
                        <a v-if="addressInfo.fullName" class="full-name" href="javascript:;" @click="editContact">
                            <strong>{{ addressInfo.fullName }}</strong> <br />
                        </a>
                        <span>{{ addressInfo.email }}</span>
                        <br />
                        <span v-if="value.mobilePhone">{{ value.mobilePhone }}</span>
                    </div>
                </el-col>
            </el-row>
            <el-row v-if="showActionBUtton" class="pt-4">
                <el-col :span="12" class="text-center">
                    <el-tooltip class="item" effect="dark" :content="$t('zimbra.zmMsg.newEmail')" placement="top-start">
                        <a href="javascript:;" class="item" @click="createNewEmail">
                            <i class="fi fi-rr-envelope"></i>
                        </a>
                    </el-tooltip>
                </el-col>
                <el-col :span="12" class="text-center">
                    <el-tooltip class="item" effect="dark" :content="$t('zimbra.zhMsg.ALT_CAL_NEW_APPT')"
                        placement="top-start">
                        <a href="javascript:;" class="item" @click="createNewAppointment">
                            <i class="fi fi-rr-calendar"></i>
                        </a>
                    </el-tooltip>
                </el-col>
            </el-row>
        </div>
        <a href="javascript:;" slot="reference" @dblclick="createNewEmailSlot" v-if="!hiddenDisplayName"
            @dragstart="onDragStart" @dragend="onDragEnd" draggable="true">
            <slot name="templateView" v-bind:data="value">
                {{ displayName }}
            </slot>
        </a>
    </el-popover>
</template>
<script>
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import EmailUtils from "@/utils/email-utils";
import BaseAvatar from "@/components/modules/base/base-avatar/base-avatar.vue"
import { mapGetters } from "vuex";
import { READ_EMAIL_LAYOUT_ROUTER } from "@/utils/constants.js"
import _ from "lodash"
import { mapActions, mapState } from "vuex/dist/vuex.common.js";

export default {
    name: "BaseAddressPopover",
    components: {
        BaseAvatar
    },
    props: {
        value: {
            type: Object,
            default: () => { },
        },
        canDoActionCreate: {
            type: Boolean,
            default: true,
        },
        hiddenDisplayName: {
            type: Boolean,
            default: false,
        },
        emailTags: {
            type: Array,
            default: () => []
        },
        currentHoverIndex: {
            type: DragEvent
        },
        type: {}
    },
    mixins: [CommonUtils, EmailUtils],
    data() {
        return {
            addressInfo: {},
            showActionBUtton: false,
            showPopover: false,
        }
    },
    mounted() {
        const { meta } = this.$route;
        this.showActionBUtton = !meta || !meta.isNewWindow;
    },
    computed: {
        ...mapGetters([
            "preference", "emailDragEvent"
        ]),

        listEmailAvatar() {
            return [this.value];
        },
        displayName() {
            if (this.preference.zimbraPrefShortEmailAddress) {
                return this.getDisplayName(this.value);
            }
            const name = this.getDisplayName(this.value);
            if (name) {
                return `"${name}" <${this.value.a}>`;
            }
            return this.value.a;
        }
    },
    methods: {
        ...mapActions(['setEmailDragEvent']),
        onShowPopover(e) {
            if (e) {
                this.$refs.popoverAddress.referenceElm = e.target
            }
            this.showPopover = true;
            const formData = {
                limit: 3,
                name: _.escape(this.value.a || this.value.text),
                offset: 0,
                type: "account",
                needIsMember: "none",
            };
            ZimbraMailService.searchGalRequest(formData, false).then(res => {
                let { cn } = this.getResponseBody(res)["SearchGalResponse"];
                if (Array.isArray(cn) && cn.length > 0) {
                    this.addressInfo = this.convertArrayToObject(cn[0].a, "n", "content");
                } else if (cn && cn.a) {
                    this.addressInfo = this.convertArrayToObject(cn.a, "n", "content");
                } else {
                    this.addressInfo = {
                        email: this.value.a,
                    }
                }
                this.addressInfo.fullName = this.getDisplayName(this.value);
            })
        },
        createNewEmailSlot() {
            if (!this.canDoActionCreate) {
                return;
            }
            this.createNewEmail();
        },
        /**
         * Gửi thư
         */
        createNewEmail() {
            const address = [{
                ...this.value,
                text: this.getDisplayNameAddress(this.value)
            }];
            if (this.$route.name != READ_EMAIL_LAYOUT_ROUTER.NAME_RIGHT && this.$route.name != READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE) {
                this.$root.$emit('createNewEmailRoot', address);
            } else {
                this.$root.$emit('createNewEmail', address);
            }
        },
        /**
         * Tạo cuộc hẹn
         */
        createNewAppointment() {
            const address = {
                ...this.value,
                email: this.value.a,
                text: this.getDisplayNameAddress(this.value)
            }
            this.$root.$emit('createNewAppointmentRoot', address);
        },
        /**
         * Sửa số liên hệ
         */
        editContact() {
            const _currentActiveMail = {
                from: [{
                    ...this.value,
                    email: this.value.a,
                    text: this.getDisplayNameAddress(this.value)
                }]
            };
            const path = "/mail/contacts";
            this.$router.push(path)
            // window.location.href = "/mail/contacts";
            this.addLocalStorageObject("editContactDataForm", { _currentActiveMail: _currentActiveMail, isEdit: !this.isAddNewContact(this.value.a) })
        },

        onDragStart(event) {
            event.dataTransfer.setData('emailDragging', this.value.a);
            event.dataTransfer.setData('type', this.type);
        },

        onDragEnd(event) {
            if (event.x === this.emailDragEvent?.x && event.y === this.emailDragEvent?.y) {
                const updatedEmailTags = this.emailTags.filter(email => email.a !== this.value.a);
                this.$emit('update-email-tags', updatedEmailTags);
            }
            this.setEmailDragEvent(null)
        },



    }
};
</script>