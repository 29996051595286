<template>
    <el-dialog
        :title="$t('zimbra.zmMsg.quickAddAppt')"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        :destroy-on-close="true"
        class="zimbra-dialog zm-calendar"
        @close="handleClose"
        width="700px"
    >
        <div class="event-info">
            <div class="row">
                <div class="col">
                    <input
                        ref="refInputName"
                        v-model.trim="modelData.name"
                        class="form-control"
                        :placeholder="$t('zimbra.zhMsg.FILT_COND_HEADER_subject')"
                    />
                </div>
                <div class="col">
                    <input
                        v-model.trim="modelData.loc"
                        class="form-control"
                        :placeholder="$t('zimbra.zhMsg.location')"
                    />
                </div>
            </div>
            <div class="row pt-4">
                <div class="col-2 zm-label">
                    {{ $t("zimbra.zhMsg.optionsDisplay") }}
                </div>
                <div class="col-4">
                    <el-select class="w-100" v-model="modelData.fb">
                        <el-option
                            v-for="(item, idx) in DATASOURCE_FREE_BUSY"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"
                        >
                            <span
                                class="zm-free-busy ml-2"
                                :style="{
                                    '--bgColorTag': item.color,
                                }"
                            >
                                {{ item.label }}
                            </span>
                        </el-option>
                        <template v-if="modelData.fb" #prefix>
                            <span
                                class="prefix zm-free-busy"
                                :style="{
                                    '--bgColorTag': getColorFreeBusy(
                                        modelData.fb
                                    ),
                                }"
                            ></span>
                        </template>
                    </el-select>
                </div>
                <div class="col-2 zm-label">
                    {{ $t("zimbra.zhMsg.ALT_APP_CALENDAR") }}
                </div>
                <div class="col-4">
                    <el-select
                        class="w-100"
                        popper-class="zm-event-info"
                        v-model="modelData.l"
                    >
                        <el-option
                            v-for="(item, idx) in datasourceCalendarFolder"
                            :key="idx"
                            :label="item.name"
                            :value="item.id"
                        >
                            <span>
                                <i
                                    :class="['pr-5', getIcon()]"
                                    :style="`filter: ${convertHexToFilterCss(
                                        item
                                    )}`"
                                ></i>
                            </span>
                            <span class="zm-free-busy">
                                {{ item.name }}
                            </span>
                        </el-option>
                        <template v-if="modelData.l" #prefix>
                            <span
                                class="prefix zm-calendar-select"
                                :style="{
                                    '--bgColorTag': getColorFreeBusy(
                                        modelData.fb
                                    ),
                                }"
                            >
                                <i
                                    :class="['pr-5', getIcon()]"
                                    :style="`filter: ${convertHexToFilterCss(
                                        getFolderSelect(modelData.l)
                                    )}`"
                                ></i>
                            </span>
                        </template>
                    </el-select>
                </div>
            </div>
            <div class="row pt-4">
                <div class="col-2"></div>
                <div class="col-4">
                    <div class="form-group">
                        <label class="checkbox-container">
                            {{ $t("zimbra.zhMsg.private") }}
                            <input
                                v-model="modelData.class"
                                type="checkbox"
                                class="allday-check"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
            <hr class="m-0" />
            <div class="row pt-4">
                <div class="col">
                    <BaseDateTimeRange
                        v-show="modelData.allDay"
                        v-model="modelData.time"
                        type="date"
                        popper-class="zm-calendar"
                        value-format="yyyyMMddTHHmmss"
                        :clearable="false"
                        :duration="defaultApptDuration"
                        @change="changeDate"
                    />
                    <BaseDateTimeRange
                        v-show="!modelData.allDay"
                        v-model="modelData.time"
                        type="datetime"
                        popper-class="zm-calendar"
                        value-format="yyyyMMddTHHmmss"
                        :clearable="false"
                        :duration="defaultApptDuration"
                        @change="changeDate"
                    />
                    <template v-if="errorTime">
                        <div class="pt-4 not-break-word">
                            <i class="fi-rr-info pr-2"></i>
                            <span v-if="modelData.allDay">
                                {{ $t("zimbra.zmMsg.meetingDateInPastWarning") }}
                            </span>
                            <span v-else>
                                {{ $t("zimbra.zmMsg.meetingInPastWarning") }}
                            </span>
                        </div>
                    </template>
                </div>
                <div class="col zm-label">
                    <div class="form-group">
                        <label class="checkbox-container">
                            {{ $t("zimbra.zhMsg.allDay") }}
                            <input
                                v-model="modelData.allDay"
                                type="checkbox"
                                class="allday-check"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row pt-4">
                <div class="col-2 zm-label">
                    {{ $t("zimbra.zhMsg.repeat") }}
                </div>
                <div class="col-4">
                    <el-select class="w-100" v-model="modelData.freq">
                        <el-option
                            v-for="(item, idx) in DATASOURCE_FREQ_TYPE"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"
                        >
                            <span>
                                {{ item.label }}
                            </span>
                        </el-option>
                    </el-select>
                    <i v-if="modelData.freq != CALENDAR_FREQ_TYPE.NONE">{{
                        $t("zimbra.zmMsg.recurEndNone")
                    }}</i>
                </div>
                <div class="col-2 zm-label">
                    {{ $t("zimbra.zmMsg.reminder") }}
                </div>
                <div class="col-4">
                    <el-select class="w-100" v-model="modelData.alarm">
                        <el-option
                            v-for="(item, idx) in REMINDER_TIME"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"
                        >
                            <span>
                                {{ item.label }}
                            </span>
                        </el-option>
                    </el-select>
                    <div v-if="hasCalendarReminderEmail" class="form-group">
                        <label class="checkbox-container justify-content-between">
                            {{ preference.zimbraPrefCalendarReminderEmail }}
                            <input
                                v-model="modelData.calendarReminderEmail"
                                type="checkbox"
                                class="allday-check"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <div class="d-flex justify-content-between">
                <div>
                    <a class="zm-calendar-more-details" href="javascript:;" @click="actionMoreDetail">
                        {{ $t("zimbra.zmMsg.moreDetails") }}
                    </a>
                </div>
                <div>
                    <el-button
                        class="btn-cancel"
                        @click="dialogVisible = false"
                    >
                        {{ $t("zimbra.zhMsg.actionTaskCancelTT") }}
                    </el-button>
                    <el-button
                        type="primary"
                        class="btn-calendar"
                        @click="actionAdd"
                    >
                        {{ $t("zimbra.zhMsg.add") }}
                    </el-button>
                </div>
            </div>
        </span>
    </el-dialog>
</template>
<script>
import CalendarUtils from "@/utils/calendar-utils";
import CommonUtils from "@/utils/common-utils";
import {
    DATASOURCE_FREE_BUSY,
    CALENDAR_FREE_BUSY_TYPE,
    FOLDER_DEFAULT,
    DATASOURCE_FREQ_TYPE,
    CALENDAR_FREQ_TYPE,
    REMINDER_TIME,
    CAL_ALARM_TYPE,
} from "@/utils/constants.js";
import { mapGetters } from "vuex";
import ZmMimeTable from "@/utils/zimbra-lib/ZmMimeTable";
import ZmCalendarApp from "@/utils/zimbra-lib/ZmCalendarApp";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import moment from "moment";
import _ from "lodash";
import BaseDateTimeRange from "@/components/modules/base/base-date-time-range/BaseDateTimeRange.vue";

export default {
    name: "CalendarAddDialog",
    mixins: [CommonUtils, CalendarUtils],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        BaseDateTimeRange
    },
    computed: {
        ...mapGetters([
            "treeCalendarFolder",
            "preference",
            "emailAddress",
            "attributes",
        ]),
        classAppoitment() {
            return (
                this.preference.zimbraPrefCalendarApptVisibility === "private"
            );
        },
        hasCalendarReminderEmail() {
            return !!this.preference?.zimbraPrefCalendarReminderEmail;
        },
    },
    watch: {},
    created() {},
    mounted() {
    },
    data() {
        return {
            modelData: {
                fb: CALENDAR_FREE_BUSY_TYPE.BUSY,
                l: FOLDER_DEFAULT.CALENDAR,
                allDay: false,
                freq: CALENDAR_FREQ_TYPE.NONE,
                alarm: this.preference?.zimbraPrefCalendarApptReminderWarningTime,
                class: this.classAppoitment,
            },
            dialogVisible: false,
            DATASOURCE_FREE_BUSY: DATASOURCE_FREE_BUSY,
            DATASOURCE_FREQ_TYPE: DATASOURCE_FREQ_TYPE,
            REMINDER_TIME: REMINDER_TIME,
            CALENDAR_FREQ_TYPE: CALENDAR_FREQ_TYPE,
            dateFormat: "hh:MM dd/MM/yyyy",
            errorTime: false,
            // Dữ liệu cấu hình từ bên ngoài truyền vào
            id: null,
            startTime: null,
            duration: null,
            allDay: null,
            //
            defaultApptDuration: null
        };
    },
    methods: {
        initialData() {
            this.modelData = {
                fb: CALENDAR_FREE_BUSY_TYPE.BUSY,
                l: FOLDER_DEFAULT.CALENDAR,
                allDay: this.allDay,
                freq: CALENDAR_FREQ_TYPE.NONE,
                alarm: this.preference
                    .zimbraPrefCalendarApptReminderWarningTime,
                class: this.classAppoitment,
                time: [this.getDefautTime(true, this.startTime, this.duration), this.getDefautTime(false, this.startTime, this.duration)],
            };
        },
        open(formData) {
            // config dữ liệu từ bên ngoài truyền vào
            this.id = formData?.id;
            this.startTime = formData?.startTime;
            this.allDay = formData?.allDay;
            this.duration = formData?.duration;
            //
            this.defaultApptDuration = this.preference.zimbraPrefCalendarDefaultApptDuration === "60m" ? 60 * 60 : this.preference.zimbraPrefCalendarDefaultApptDuration;

            this.dialogVisible = true;
            this.initialData();
            setTimeout(() => {
                this.$refs.refInputName && this.$refs.refInputName.focus();
                this.changeDate();
            }, 500);
        },
        handleClose() {
            this.dialogVisible = false;
        },
        changeDate() {
            const _startTime = this.modelData.time?.length > 0 ? this.modelData.time[0] : null;
            if (_startTime) {
                const _current = moment(new Date()).format(this.modelData.allDay ? "yyyyMMDD" : "yyyyMMDDTHHmmss");
                this.errorTime = _current > _startTime;
                return;
            }
            this.errorTime = false;
        },
        validateBeforeSave() {
            let res = true;
            if (!this.modelData.name || this.modelData.name.length === 0) {
                res = false;
                this.$confirm(
                    this.$t("zimbra.zhMsg.errorMissingSubject"),
                    this.$t("zimbraNokey.zimbra_compose_criticalMsg"),
                    {
                        showCancelButton: false,
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "error",
                    }
                )
                    .then(() => {
                        setTimeout(() => {
                            this.$refs.refInputName && this.$refs.refInputName.focus();
                        }, 500);
                    })
                    .catch(() => {});
            }
            return res;
        },
        actionAdd() {
            const _validate = this.validateBeforeSave();
            if (!_validate) {
                return;
            }
            const formData = {};
            formData.l = this.modelData.l?.toString();
            formData.su = _.escape(this.modelData.name);
            formData.mp = [{
                ct: ZmMimeTable.MULTI_ALT,
                mp: [
                    {
                        ct: ZmMimeTable.TEXT_PLAIN,
                        content: "",
                    },
                ],
            }];
            // Invite information
            formData.inv = {
                comp: this.getInviteComponent(),
            };

            formData.e = [
                {
                    a: this.emailAddress,
                    t: "f",
                    p: _.escape(this.attributes.displayName),
                },
            ];
            ZimbraMailService.createAppointmentCalendarRequest({
                m: formData,
            }).then((res) => {
                const { apptId } =
                    this.getResponseBody(res)["CreateAppointmentResponse"];
                if (apptId) {
                    this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                        message: this.$t("zimbra.zmMsg.apptCreated"),
                        type: "success",
                    });
                    this.dialogVisible = false;
                    // emit để tìm kiếm lại danh sách lịch
                    this.$emit("onAddSuccess");
                    this.$root.$emit("searchListEventToWarning");
                }
            });
        },
        getInviteComponent() {
            const inviteComponent = {};
            inviteComponent.status = ZmCalendarApp.STATUS_CONF;
            inviteComponent.fb = this.modelData.fb;
            inviteComponent.class = this.modelData.class ? "PRI" : "PUB";
            inviteComponent.transp = 0;
            inviteComponent.draft = 0; // 0|1 Set if invite has changes that haven't been sent to attendees; for organizer only
            inviteComponent.allDay = this.modelData.allDay ? 1 : 0;
            inviteComponent.name = _.escape(this.modelData.name);
            inviteComponent.loc = _.escape(this.modelData.loc);
            inviteComponent.s = {
                d: this.modelData.time[0],
                tz: this.preference.zimbraPrefTimeZoneId,
            };
            inviteComponent.e = {
                d: this.modelData.time[1],
                tz: this.preference.zimbraPrefTimeZoneId,
            };
            inviteComponent.or = {
                a: this.emailAddress,
                d: _.escape(this.preference.zimbraPrefFromDisplay),
            };
            // Lặp lại
            if (this.modelData.freq !== CALENDAR_FREQ_TYPE.NONE) {
                inviteComponent.recur = {
                    add: {
                        rule: {
                            interval: {
                                ival: 1,
                            },
                            freq: this.modelData.freq,
                        },
                    },
                };
            }
            // Trình nhắc
            if (this.modelData.alarm !== -1) {
                inviteComponent.alarm = [
                    {
                        action: CAL_ALARM_TYPE.DISPLAY,
                        trigger: {
                            rel: {
                                m: this.modelData.alarm,
                                related: "START",
                                neg: "1",
                            },
                        },
                    },
                ];
                if (this.modelData.calendarReminderEmail) {
                    inviteComponent.alarm.push({
                        action: CAL_ALARM_TYPE.EMAIL,
                        trigger: {
                            rel: {
                                m: this.modelData.alarm,
                                related: "START",
                                neg: "1",
                            },
                        },
                        at: {
                            a: this.preference.zimbraPrefCalendarReminderEmail,
                        },
                    });
                }
            } 
            return [inviteComponent];
        },
        actionMoreDetail() {
            // chi tiết hơn
            this.dialogVisible = false;
            this.$emit("actionMoreDetail", this.modelData);
        }
    },
};
</script>
<style>
</style>
