<template>
    <div class="quick-setting__popover">
            <div class="side-popup calendar-popup show">
                        <div class="popup-content">
                            <div class="header">
                                <div class="header-left">
                                    <div class="title">{{ $t("zimbra.zhMsg.ALT_APP_CALENDAR") }}</div>
                                    <button @click="onOpenMaxSetting()" class="expand">
                                        <el-tooltip class="item" effect="dark"
                                                    :content='this.$t("zimbra.zmMsg.expandAll")'
                                                    placement="left-end"
                                        >
                                            <img src="~@/assets/images/ic-expand-arrow.svg" alt="">
                                        </el-tooltip>
                                    </button>
                                </div>
                                <div class="header-right">
                                    <button @click="onClose()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <i class="fal fa-times"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="calendar-popup-content">
                                <div class="main-content">
                                     <SmallCalendar
                                        @quickAddCalendar="quickAddCalendar"
                                     />
                                </div>
                                <SmallListEvent  @openDialogDetailSchedule = "openDialogDetailSchedule"/>
                            </div>
                        </div>


            </div>
    </div>
</template>

<script>
import SmallCalendar from './small-calendar.vue';
import SmallListEvent from './small-list-event.vue';
export default {
    components: {
        SmallListEvent,
        SmallCalendar
    },
    data() {
            return {
            typeView:'dayGridMonth',
            hiddenDays: [],
            quickAddDialogVisiable: false,
        }
    },
    created() {
    },
    computed: {
    },
    methods: {
        onClose() {
            this.$emit("onCloseSmall");
        },
        onOpenMaxSetting() {
            this.$emit("openMax");
        },
        quickAddCalendar() {
            this.$emit("openQuickAddCalendar");
        },
        openDialogDetailSchedule() {
            this.$emit("openDialogDetailSchedule");
        }
    }
};
</script>
<style lang="scss" scoped>
.toolbar-menu .toolbar-item span {
    display: contents !important;
}
</style>
