<template>
    <el-dialog
        :title="title"
        :visible.sync="visable"
        :append-to-body="true"
        :destroy-on-close="true"
        class="zimbra-dialog zm-resource-selector"
    >
        <perfect-scrollbar class="event-info default-scroll">
            <fieldset class="border p-2">
                <legend class="w-auto">
                    <strong>{{ title }}</strong>
                </legend>
                <div>
                    <div class="row pt-3">
                        <div class="col-2 zm-label">
                            {{ $t("zimbra.zhMsg.AB_FIELD_firstName") }}:
                        </div>
                        <div class="col-3">
                            <input
                                v-model.trim="modelData.name"
                                class="form-control"
                            />
                        </div>
                        <div class="col-2 zm-label">
                            {{ $t("zimbra.zhMsg.AB_FIELD_homeURL") }}:
                        </div>
                        <div class="col-3">
                            <input
                                v-model.trim="modelData.zimbraCalResSite"
                                class="form-control"
                            />
                        </div>
                        <div class="position-right">
                            <el-button type="primary" class="btn-calendar" @click="handleSearch">
                                {{ $t("zimbra.zhMsg.search") }}
                            </el-button>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <template v-if="resourceType === ZIMBRA_CAL_RES_TYPE.LOCATION">
                            <div class="col-2 zm-label">
                                {{ $t("zimbra.zmMsg.minimumCapacity") }}:
                            </div>
                            <div class="col-3">
                                <input
                                    v-model.trim="modelData.zimbraCalResCapacity"
                                    class="form-control"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div class="col-2 zm-label">
                                {{ $t("zimbra.zmMsg.description") }}:
                            </div>
                            <div class="col-3">
                                <input
                                    v-model.trim="modelData.description"
                                    class="form-control"
                                />
                            </div>
                        </template>
                        <div class="col-2 zm-label">
                            {{ $t("zimbraNokey.zimbra_zmMsg_building") }}:
                        </div>
                        <div class="col-3">
                            <input
                                v-model.trim="modelData.zimbraCalResBuilding"
                                class="form-control"
                            />
                        </div>
                    </div>
                    <div class="row pt-3">
                        <template v-if="resourceType === ZIMBRA_CAL_RES_TYPE.LOCATION">
                            <div class="col-2 zm-label">
                                {{ $t("zimbra.zmMsg.description") }}:
                            </div>
                            <div class="col-3">
                                <input
                                    v-model.trim="modelData.description"
                                    class="form-control"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div class="col-2 zm-label">
                                {{ $t("zimbra.zhMsg.ALT_CONTACT_CONTACT") }}:
                            </div>
                            <div class="col-3">
                                <input
                                    v-model.trim="modelData.zimbraCalResContactName"
                                    class="form-control"
                                />
                            </div>
                        </template>
                        <div class="col-2 zm-label">
                            {{ $t("zimbra.zmMsg.floor") }}:
                        </div>
                        <div class="col-3">
                            <input
                                v-model.trim="modelData.zimbraCalResFloor"
                                class="form-control"
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div class="d-flex justify-content-center">
                        <div class="position-buttom">
                            <el-button type="primary" class="btn-calendar" @click="handleSearch">
                                {{ $t("zimbra.zhMsg.search") }}
                            </el-button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div v-if="resourceType === ZIMBRA_CAL_RES_TYPE.LOCATION" class="form-group pt-2">
                            <label class="checkbox-container mb-0">
                                {{
                                    $t("zimbra.zmMsg.allowMultipleLocations")
                                }}
                                <input
                                    v-model="allowMultipleLocations"
                                    type="checkbox"
                                    class="allday-check"
                                />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <el-pagination
                            background
                            layout="prev, next"
                            :page-size="pageSize"
                            :total="totalResource"
                            @current-change="currentChange"
                            @prev-click="prevClick"
                            @next-click="nextClick"
                        >
                        </el-pagination>
                    </div>
                    <table class="table mb-0" style="border: 1px solid #dee2e6">
                        <thead>
                            <tr>
                                <th scope="col" style="width: 200px;">
                                    {{
                                        $t("zimbra.zhMsg.AB_FIELD_firstName")
                                    }}
                                </th>
                                <th scope="col" style="width: 150px;">
                                    {{
                                        $t("zimbra.zhMsg.location")
                                    }}
                                </th>
                                <th scope="col" style="width: 150px;">
                                    {{
                                        $t("zimbra.zhMsg.ALT_CONTACT_CONTACT")
                                    }}
                                </th>
                                <th v-if="resourceType === ZIMBRA_CAL_RES_TYPE.LOCATION" scope="col" style="width: 150px;">
                                    {{
                                        $t("zimbra.zmMsg.capacity")
                                    }}
                                </th>
                                <th scope="col" style="width: 150px;">
                                    {{
                                        $t("zimbra.zhMsg.MO_status")
                                    }}
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <perfect-scrollbar class="zm-table-resource">
                        <table class="table table-scroll">
                            <tbody>
                                <template v-for="(row, idx) in listResource">
                                    <tr :key="idx"
                                        :class="['zimbra-cursor-pointer',
                                            'zm-row-email',
                                            isSelected(row, 'source') ? 'selected' : '']"
                                        @click.exact.prevent="
                                            handleSelectRow(row, 'source')
                                        "
                                        @click.ctrl="
                                            ctrlAddItemToList(row, 'source')
                                        "
                                        @click.shift="
                                            shiftAddItemToList(row, 'source')
                                        "
                                    >
                                        <th scope="col" style="width: 200px;">
                                    
                                        </th>
                                        <th scope="col" style="width: 150px;">
                                        
                                        </th>
                                        <th scope="col" style="width: 150px;">
                                            
                                        </th>
                                        <th v-if="resourceType === ZIMBRA_CAL_RES_TYPE.LOCATION" scope="col" style="width: 150px;">
                                        
                                        </th>
                                        <th scope="col" style="width: 150px;">
                                            
                                        </th>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </perfect-scrollbar>
                </div>
            </fieldset>
            <div class="text-center pt-2 pb-2">
                <template v-if="allowMultipleLocations">
                    <el-button class="btn-cancel" :disabled="!listResource || listResource.length == 0">
                        {{ $t("zimbra.zmMsg.addAll") }}
                    </el-button>
                    <el-button class="btn-cancel" :disabled="!selectedRowsSource || selectedRowsSource.length == 0">
                        {{ $t("zimbra.zhMsg.add") }}
                    </el-button>
                    <el-button class="btn-cancel" :disabled="!selectedRowsTarget || selectedRowsTarget.length == 0">
                        {{ $t("zimbra.zhMsg.actionDelete") }}
                    </el-button>
                    <el-button class="btn-cancel" :disabled="!listSelectedResource || listSelectedResource.length == 0">
                        {{ $t("zimbra.zhMsg.removeAll") }}
                    </el-button>
                </template>
                <template v-else>
                    <el-button class="btn-cancel" :disabled="!selectedRowsSource || selectedRowsSource.length == 0">
                        {{ $t("zimbra.zhMsg.select") }}
                    </el-button>
                    <el-button class="btn-cancel" :disabled="!listSelectedResource || listSelectedResource.length == 0">
                        {{ $t("zimbra.zhMsg.actionDelete") }}
                    </el-button>
                </template>
            </div>
            <fieldset class="border p-2">
                <legend class="w-auto">
                    <strong>{{ titleSelect }}</strong>
                </legend>
                <table class="table mb-0" style="border: 1px solid #dee2e6">
                        <thead>
                            <tr>
                                <th scope="col" style="width: 200px;">
                                    {{
                                        $t("zimbra.zhMsg.AB_FIELD_firstName")
                                    }}
                                </th>
                                <th scope="col" style="width: 150px;">
                                    {{
                                        $t("zimbra.zhMsg.location")
                                    }}
                                </th>
                                <th scope="col" style="width: 150px;">
                                    {{
                                        $t("zimbra.zhMsg.ALT_CONTACT_CONTACT")
                                    }}
                                </th>
                                <th v-if="resourceType === ZIMBRA_CAL_RES_TYPE.LOCATION" scope="col" style="width: 150px;">
                                    {{
                                        $t("zimbra.zmMsg.capacity")
                                    }}
                                </th>
                                <th scope="col" style="width: 150px;">
                                    {{
                                        $t("zimbra.zhMsg.MO_status")
                                    }}
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <perfect-scrollbar class="zm-table-resource">
                        <table class="table table-scroll">
                            <tbody>
                                <template v-for="(row, idx) in listSelectedResource">
                                    <tr :key="idx"
                                        :class="['zimbra-cursor-pointer',
                                            'zm-row-email',
                                            isSelected(row, 'target') ? 'selected' : '']"
                                        @click.exact.prevent="
                                            handleSelectRow(row, 'target')
                                        "
                                        @click.ctrl="
                                            ctrlAddItemToList(row, 'target')
                                        "
                                        @click.shift="
                                            shiftAddItemToList(row, 'target')
                                        "
                                    >
                                        <th scope="col" style="width: 200px;">
                                    
                                        </th>
                                        <th scope="col" style="width: 150px;">
                                        
                                        </th>
                                        <th scope="col" style="width: 150px;">
                                            
                                        </th>
                                        <th v-if="resourceType === ZIMBRA_CAL_RES_TYPE.LOCATION" scope="col" style="width: 150px;">
                                        
                                        </th>
                                        <th scope="col" style="width: 150px;">
                                            
                                        </th>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </perfect-scrollbar>
            </fieldset>
        </perfect-scrollbar>
        <span slot="footer" class="dialog-footer">
            <div class="text-right">
                <el-button class="btn-cancel" @click="visable = false">
                    {{ $t("zimbra.zhMsg.actionTaskCancelTT") }}
                </el-button>
                <el-button type="primary" class="btn-calendar">
                    {{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}
                </el-button>
            </div>
        </span>
    </el-dialog>
</template>
<script>
/**
 * Dựng trước giao diện
 * Chưa hoàn thiện hết chức năng do chưa tạo được tài nguyên Địa điểm/Thiết bị
 */
import CommonUtils from "@/utils/common-utils";
import { ZIMBRA_CAL_RES_TYPE } from "@/utils/constants.js";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import _ from "lodash";
export default {
    name: "DialogResourceSelector",
    mixins: [CommonUtils],
    props: {},
    data() {
        return {
            visable: false,
            resourceType: "",
            title: "",
            titleSelect: "",
            modelData: {},
            ZIMBRA_CAL_RES_TYPE: ZIMBRA_CAL_RES_TYPE,
            selectedRowsSource: [], // Đã chọn trên kết quả tìm kiếm
            selectedRowsTarget: [],
            listResource: [], // Kết quả tìm kiếm
            listSelectedResource: [], // Địa điểm/thiết bị cho cuộc hẹn
            currentPage: 0, // trang hiện tại
            pageSize: 50, // Kích thước trang
            totalResource: 0,
            allowMultipleLocations: false, // cho phép nhiều địa điểm
        };
    },
    methods: {
        show(resourceType) {
            this.modelData = {};
            this.visable = true;
            this.resourceType = resourceType;
            this.title =
                resourceType === ZIMBRA_CAL_RES_TYPE.LOCATION
                    ? this.$t("zimbra.zmMsg.findLocations")
                    : this.$t("zimbra.zmMsg.findEquipment");
            this.titleSelect =
                resourceType === ZIMBRA_CAL_RES_TYPE.LOCATION
                    ? this.$t("zimbra.zmMsg.apptLocations")
                    : this.$t("zimbra.zmMsg.apptEquipment");
            this.allowMultipleLocations = resourceType === ZIMBRA_CAL_RES_TYPE.EQUIPMENT;
        },
        isSelected(row, type) {
            if (type === "source") {
                const _idx = this.selectedRowsSource.findIndex(e => e.a === row.a);
                return _idx > -1
            } else {
                const _idx = this.selectedRowsTarget.findIndex(e => e.a === row.a);
                return _idx > -1
            }
        },
        handleSelectRow(row, type) {
            if (type === "source") {
                const _idx = this.selectedRowsSource.findIndex(e => e.a === row.a);
                if (_idx > -1) {
                    this.selectedRowsSource.splice(_idx, 1);
                } else {
                    this.selectedRowsSource = [row];
                }
            } else {
                const _idx = this.selectedRowsTarget.findIndex(e => e.a === row.a);
                if (_idx > -1) {
                    this.selectedRowsTarget.splice(_idx, 1);
                } else {
                    this.selectedRowsTarget = [row];
                }
            }
        },
        ctrlAddItemToList(row, type) {
            if (type === "source") {
                const idx = this.selectedRowsSource.findIndex(
                    (e) => e.a === row.a
                );
                if (idx > -1) {
                    this.selectedRowsSource.splice(idx, 1);
                } else {
                    this.selectedRowsSource.push(row);
                }
            } else {
                const idx = this.selectedRowsTarget.findIndex(
                    (e) => e.a === row.a
                );
                if (idx > -1) {
                    this.selectedRowsTarget.splice(idx, 1);
                } else {
                    this.selectedRowsTarget.push(row);
                }
            }
        },
        shiftAddItemToList(row, type) {
            if (type === "source") {
                let indexs = row.index;
                if (this.selectedRowsSource.length === 0) {
                    return;
                }
                this.selectedRowsSource = _.orderBy(
                    this.selectedRowsSource,
                    ["index"],
                    ["asc"]
                );
                let firstIndex = this.selectedRowsSource[0].index;
                if (firstIndex > row.index) {
                    firstIndex = [indexs, (indexs = firstIndex)][0];
                }
                this.selectedRowsSource = this.listResource.filter(
                    (e, idx) => idx >= firstIndex && idx <= indexs
                );
            } else {
                let indexs = row.index;
                if (this.selectedRowsTarget.length === 0) {
                    return;
                }
                this.selectedRowsTarget = _.orderBy(
                    this.selectedRowsTarget,
                    ["index"],
                    ["asc"]
                );
                let firstIndex = this.selectedRowsTarget[0].index;
                if (firstIndex > row.index) {
                    firstIndex = [indexs, (indexs = firstIndex)][0];
                }
                this.selectedRowsTarget = this.listSelectedResource.filter(
                    (e, idx) => idx >= firstIndex && idx <= indexs
                );
            }
        },
        currentChange() {
            // do somethings
        },
        prevClick(page) {
            // trang trước
            this.currentPage = page;
        },
        nextClick(page) {
            // trang sau
            this.currentPage = page;
        },
        handleSearch() {
            // tìm kiếm
            const formData =
                this.resourceType === ZIMBRA_CAL_RES_TYPE.LOCATION
                    ? this.getFormDataSearchLocation()
                    : this.getFormDataSearchEquipment()
            ZimbraMailService.searchCalendarResourcesRequest(formData).then(res => {
                const { calresource } = this.getResponseBody(res).SearchCalendarResourcesResponse;
                this.listResource = calresource;
            });
        },
        getFormDataSearchLocation() {
            // form tìm kiếm địa điểm
             const formData = {
                attrs: "fullName,email,zimbraCalResLocationDisplayName,zimbraCalResCapacity,zimbraCalResContactEmail,description,zimbraCalResType",
                offset: 0,
                limit: this.pageSize,
                name: this.modelData.name,
            };
            const _conds = [];
            const listFiledHas = ["zimbraCalResSite", "description", "zimbraCalResBuilding"];
            listFiledHas.forEach(key => {
                if (this.modelData[key] && this.modelData[key].length > 0) {
                    _conds.push({ attr: key, op: "has", value: this.modelData[key]});
                }
            });
            _conds.push({ attr: "zimbraCalResType", op: "eq", value: this.resourceType});
            if (this.modelData.zimbraCalResFloor && this.modelData.zimbraCalResFloor.length > 0) {
                _conds.push({ attr: "zimbraCalResFloor", op: "eq", value: this.modelData.zimbraCalResFloor});
            }
            if (this.modelData.zimbraCalResCapacity && this.modelData.zimbraCalResCapacity.length > 0) {
                _conds.push({ attr: "zimbraCalResCapacity", op: "ge", value: this.modelData.zimbraCalResCapacity});
            }
            formData.conds = _conds;
        },
        getFormDataSearchEquipment() {
            // form tìm kiếm thiết bị
             const formData = {
                attrs: "fullName,email,zimbraCalResLocationDisplayName,zimbraCalResContactEmail,description,zimbraCalResType",
                offset: 0,
                limit: this.pageSize,
                name: this.modelData.name,
            };
            const _conds = [];
            const listFiledHas = ["zimbraCalResSite", "description", "zimbraCalResBuilding", "zimbraCalResContactName"];
            listFiledHas.forEach(key => {
                if (this.modelData[key] && this.modelData[key].length > 0) {
                    _conds.push({ attr: key, op: "has", value: this.modelData[key]});
                }
            });
            _conds.push({ attr: "zimbraCalResType", op: "eq", value: this.resourceType});
            if (this.modelData.zimbraCalResFloor && this.modelData.zimbraCalResFloor.length > 0) {
                _conds.push({ attr: "zimbraCalResFloor", op: "eq", value: this.modelData.zimbraCalResFloor});
            }
            formData.conds = _conds;
        }

    },
};
</script>