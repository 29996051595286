import DwtCssStyle from "./DwtCssStyle";
// import AjxEnv from "./AjxEnv";

const Dwt = function () {
};

Dwt.DEFAULT = -123456789;
Dwt.LOC_NOWHERE = -10000;

/**
 * @private
 */
Dwt.__nextId = {};

const getNextId =
    function (prefix) {
        prefix = prefix || "DWT";
        if (!Dwt.__nextId[prefix]) {
            Dwt.__nextId[prefix] = 1;
        }
        return prefix + Dwt.__nextId[prefix]++;
    };

const getIframeDoc =
    function (iframeObj) {
        if (iframeObj) {
            return iframeObj.contentDocument;
        }
        return null;
    };

const getPosition =
    function (htmlElement) {
        if (!(htmlElement = getElement(htmlElement))) { return; }
        return htmlElement.style.position;
    };

const setPosition =
    function (htmlElement, posStyle) {
        if (!(htmlElement = getElement(htmlElement))) { return; }
        htmlElement.style.position = posStyle;
    };

const getElement =
    function (el) {
        return (typeof (el) == "string") ? document.getElementById(el) : el;
    };


const setLocation =
    function (htmlElement, x, y) {
        if (!(htmlElement = getElement(htmlElement))) { return; }
        var position = DwtCssStyle.getProperty(htmlElement, 'position');
        if (position != Dwt.ABSOLUTE_STYLE && position != Dwt.RELATIVE_STYLE && position != Dwt.FIXED_STYLE) {
            // DBG.println(AjxDebug.DBG1, "Cannot position static widget " + htmlElement.className);
            // throw new DwtException("Static widgets may not be positioned", DwtException.INVALID_OP, "Dwt.setLocation");
        }
        if (x == __checkPxVal(x)) {
            htmlElement.style.left = x;
        }
        if (y == __checkPxVal(y)) {
            htmlElement.style.top = y;
        }
    };

const __checkPxVal =
    function(val, check) {
        if (val == Dwt.DEFAULT) { return false; }
        if (isNaN(parseInt(val))) { return false; }
    
        if (check && val < 0 && val != Dwt.LOC_NOWHERE) {
            // DBG.println(AjxDebug.DBG1, "negative pixel value: " + val);
            val = 0;
        }
        if (typeof(val) == "number") {
            val = val + "px";
        }
        // if (!AjxEnv.supportsCSS3RemUnits && Dwt.__REM_RE.test(val)) {
        //     val = DwtCssStyle.asPixelCount(val) + "px";
        // }
        return val;
    };

const byId =
    function(id, ancestor) {
        if (!ancestor) {
            return (typeof id == "string" ? document.getElementById(id) : id);
        }

        // Find node with id that descends from ancestor (also works on DOM trees
        // that are not attached to the document object)
        if (ancestor == id || ancestor.id == id) {
            return ancestor;
        }

        for (var i = 0; i < ancestor.childNodes.length; i++) {
            if (ancestor.childNodes[i].nodeType == 1) {
                var cnode = byId(id, ancestor.childNodes[i]);
                if (cnode) { return cnode; }
            }
        }
        return null;
    };


export default {
    getNextId,
    getIframeDoc,
    getPosition,
    setPosition,
    getElement,
    setLocation,
    byId
}
