<template>
    <el-dialog
        :title="$t('zimbra.zmWebExZimlet.selectAccntToUse')"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        :destroy-on-close="true"
        class="zimbra-dialog zm-suggestion-references"
    >
        <el-form class="form-wrap">
            <!-- Chọn tài khoản -->
             <div class="row pt-2">
                <div class="col-3 zm-label">
                    {{ $t('zimbra.zmWebExZimlet.webExAccntToUse') }}
                </div>
                <div class="col-7">
                    <el-select
                        class="w-100"
                        v-model="formInsert.account"
                        :default-first-option="true"
                        @change="validateAccount"
                    >
                        <el-option
                            v-for="(
                                item, idx
                            ) in lstAcountWebex"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"
                        >
                            <span>
                                {{ item.label }}
                            </span>
                        </el-option>
                    </el-select>
                </div>
             </div>

             <!-- Tùy chọn tài khoản -->
             <div class="row pt-5">
                <div class="col-2">
                    <b> {{ $t('zimbra.zmWebExZimlet.getAccounts') }} </b>
                </div>
             </div>
             <hr class="mt-2 mb-2"/>
             <!-- Máy chủ thay thế -->
             <div class="row">
                <div class="col-3 zm-label">
                    {{ $t('zimbra.zmWebExZimlet.altHosts') }}
                </div>
                <div class="col-6">
                    <el-input
                    class="el-text-control"
                    v-model="formInsert.altHost"
                    autocomplete="off"
                    size="medium"
                ></el-input>
                </div>
                <!-- trợ giúp -->
                <div class="col-2 zm-extra-label zm-recur-blurb">
                   <a href="javascript:;" @click="onOpenConfirmHelp">{{ $t("zimbra.zmWebExZimlet.help") }}</a>
                </div>
             </div>
             <!-- Mật khẩu cuộc họp webex -->
             <div class="row pt-3">
                <div class="col-3 zm-label">
                    {{ $t('zimbra.zmWebExZimlet.meetingPwd') }}
                </div>
                <div class="col-6">
                    <el-input
                    class="el-text-control"
                    v-model="formInsert.meetingPwd"
                    autocomplete="off"
                    size="medium"
                ></el-input>
                </div>
                <!-- tùy chọn -->
                 <div class="col-2 zm-extra-label">
                   <a style="cursor: default">{{ $t("zimbra.zmWebExZimlet.optional") }}</a>
                </div>
             </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button
                class="btn-cancel"
                @click="onCloseDialog"
            >
                {{ $t("zimbra.zhMsg.actionTaskCancelTT") }}
            </el-button>
            <el-button
                type="primary"
                class="btn-calendar"
                @click="onSubmit"
            >
                {{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
import ContentService from "@/services/ContentService.js";
export default {
    name: "DialogConfirmOpenInstance",
    data() {
        return {
            formInsert: {
                account: 0
            },
            dialogVisible: false,
            accountDatas: []
        }
    },
    computed: {
        lstAcountWebex() {
          const lstAccountWebExZimlet = this.$store.getters.lstAccountWebExZimlet;
          const lstSelect = lstAccountWebExZimlet.map((item, index) => {
              const objectSelect = {...item}
              objectSelect.value = index;
              const altName = item.webexZimlet_username != ""? item.webexZimlet_username: this.$t("zimbra.zmWebExZimlet.notConfigured")
              objectSelect.label = this.$t("zimbra.zmWebExZimlet.accntNumberAndName", [index + 1, altName]);
              return objectSelect;
          })
          return lstSelect;
        }
    },
    methods: {
        open() {
            this.dialogVisible = true;
            // Convert thông tin về dạng danh sách cho phép chọn
        },

        /**
         * Hàm mở hộp thoại thông tin hỗ trợ
         */
        onOpenConfirmHelp() {
            this.$confirm(this.$t("zimbra.zmWebExZimlet.setupAlternateHost"), this.$t("zimbraNokey.zimbra_common_titleInfo"), {
                confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                showCancelButton: false,
                type: "info",
                dangerouslyUseHTMLString: true,
            }).then(() => {});
        },
        /**
         * Action xử lý thêm
         */
        onSubmit() {
            if(!this.validateAccount()) {
                return;
            }
            const index = this.formInsert.account;
            const account = this.$store.getters.lstAccountWebExZimlet[index];
            const formParram = {
                webExID: account.webexZimlet_username,
                password: account.webexZimlet_pwd,
                siteName: account.webexZimlet_companyId
            }
            const res = ContentService.proxy(formParram);
            this.dialogVisible = false;
        },

        validateAccount() {
            let isValid = false;
            this.lstAcountWebex.map((item) => {
              const objectSelect = {...item}
              if(this.isValidAccount(objectSelect) && this.formInsert.account === objectSelect.value){
                  isValid = true;
              }
            })
            if(!isValid) {
                this.$confirm(
                    this.$t("zimbra.zmWebExZimlet.accountNumberNotSetup", [this.formInsert.account + 1]),
                    this.$t("zimbraNokey.zimbra_compose_criticalMsg"),
                    {
                        showCancelButton: false,
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "error",
                        dangerouslyUseHTMLString: true,
                    }
                ).then(() => {});
            }
            return isValid;
        },
        isValidAccount(form){
            if ( form["webexZimlet_accountname"].toString().length > 0 && form["webexZimlet_username"].toString().length > 0 ){
                    return true;
            }
            return false;
        },
        onCloseDialog() {
            this.dialogVisible = false
            this.formInsert.account = 0;
            this.formInsert.altHost = "";
            this.formInsert.meetingPwd = "";
        }
    }
}
</script>
<style lang="css" scoped>
.zm-label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.zm-extra-label {
    display: flex;
    align-items: center;
}
</style>
