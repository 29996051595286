import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ElementUI from 'element-ui';
import localeVI from 'element-ui/lib/locale/lang/vi';
import localeTR from 'element-ui/lib/locale/lang/tr-TR';
import localeKO from 'element-ui/lib/locale/lang/ko';
import localeRU from 'element-ui/lib/locale/lang/ru-RU';
import localePL from 'element-ui/lib/locale/lang/pl';
import localeJA from 'element-ui/lib/locale/lang/ja';
import localeZH_CN from 'element-ui/lib/locale/lang/zh-CN';
import localeZH_TW from 'element-ui/lib/locale/lang/zh-TW';
import localeEN from 'element-ui/lib/locale/lang/en';
import vi from '@/i18n/vi.json';
const defaultLocale = localStorage.getItem('defaultLocale') || 'vi';
const loadedLanguages = [];
let locale;
switch(defaultLocale) {
    case "vi":
        locale = localeVI;
        break;
    case "tr":
        locale = localeTR;
        break;
    case "ko":
        locale = localeKO;
        break;
    case "ja":
        locale = localeJA;
        break;
    case "pl":
        locale = localePL;
        break;
    case "ru":
        locale = localeRU;
        break;
    case "zh_CN":
    case "zh_HK":
        locale = localeZH_CN;
        break;
    case "zh_TW":
        locale = localeZH_TW;
        break;
    case "en":
    case "en_AU":
    case "en_US":
    case "en_GB":
        locale = localeEN;
        break;
    default:
        locale = localeEN;
        break;
}
Vue.use(VueI18n);
Vue.use(ElementUI, { locale });

/* function loadLocaleMessages() {
    const locales = require.context('@/i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
} */

const i18n = new VueI18n({
    locale: 'vi', // set locale
    fallbackLocale: 'vi', // set fallback locale
    messages: { 'vi': vi }, // set locale messages
});

function setI18nLanguage (lang) {
    i18n.locale = lang;
    document.querySelector('html').setAttribute('lang', lang);
    return lang;
}

export function loadLanguageAsync(lang) {
    if (i18n.locale !== lang) {
        if (!loadedLanguages.includes(lang)) {
            return import(/* webpackChunkName: "lang-[request]" */ `@/i18n/${lang}`).then(msgs => {
                i18n.setLocaleMessage(lang, msgs.default);
                loadedLanguages.push(lang);
                return setI18nLanguage(lang);
            })
        }
    }
    return Promise.resolve(setI18nLanguage(lang));
}
export default i18n;
