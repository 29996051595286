<template>
        <!-- v-if="formShare.optionShare == 'usr'" -->
    <el-select
        ref="refSelectEmail"
        :id="`address_selector_${generateUniqueId()}`"
        :size="size"
        :disabled="disabled"
        :multiple-limit="multipleLimit"
        v-model="dataSelected"
        :multiple="multiple"
        :clearable="clearable"
        filterable
        remote
        :loading="loading"
        :placeholder="placeholder"
        :remote-method="remoteMethodEmail"
        :loading-text="loaddingText"
        @change="onchange"
        @clear="onclear"
        @blur="onblur"
        popper-class="zm-base-address-selector"
        value-key="email"
    >
        <el-option
            v-for="(item, i) in listEmail"
            :key="i"
            :label="item.email"
            :value="item"
        >
            <ItemAddressSelector
                :value="item"
                :multiple="multiple"
                @selectMembers="selectMembers"
                @selectAllMembers="selectAllMembers"
                @closed="
                    listEmail = [];
                    $refs.refSelectEmail.blur();
                "
            />
        </el-option>
    </el-select>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import ItemAddressSelector from "./ItemAddressSelector.vue";
export default {
    name: "BaseAddressSelector",
    mixins: [CommonUtils],
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        value: {
            type: [Array, Object, String],
            default() {
                return []
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: "medium"
        },
        multipleLimit: {
            type: Number,
            default: 0
        },
        placeholder: {
            type: String,
            default() {
                return this.$t('zimbraNokey.zimbra_sidebar_dialogShare_emailPlaceholder')
            }
        },
        autofocus: {
            type: Boolean,
            default: true
        },
        focusAfterClear: {
            type: Boolean,
            default: false
        },
    },
    components: {
        ItemAddressSelector
    },
    data() {
        return {
            dataSelected: this.value,
            listEmail: [],
            loaddingText: this.$t("zimbra.zmMsg.autocompleteWaiting"),
            loading: false,
        };
    },
    watch: {
        value(val) {
            this.dataSelected = val;
        }
    },
    mounted() {
        setTimeout(() => {
            this.autofocus && this.$refs.refSelectEmail.focus();
        }, 100);
    },
    methods: {
        onchange() {
            this.listEmail = [];
            if (this.dataSelected || (!this.dataSelected && !this.focusAfterClear)) {
                this.$emit("change", this.dataSelected);
            }
            const inputFocus = this.$refs.refSelectEmail.$el.querySelector('.el-select__input.is-medium');
            if (inputFocus) {
                inputFocus.focus();
            }
        },
        selectMembers(item) {
            if (this.multiple) {
                this.dataSelected = this.dataSelected ? this.dataSelected : [];
                this.dataSelected.push(item);
                this.$emit("change", this.dataSelected);
            } else {
                this.dataSelected = item;
                this.$emit("change", this.dataSelected);
            }
        },
        selectAllMembers(listMembers) {
            this.dataSelected = this.dataSelected ? this.dataSelected : [];
            this.dataSelected = [ ...listMembers.filter(x => !this.dataSelected.includes(x)), ...this.dataSelected];
            this.$emit("change", this.dataSelected);
        },
        getResponseBodyEmail(response) {
            return response["data"]["soap:Envelope"]["soap:Body"][
                "AutoCompleteResponse"
            ]["match"];
        },
        remoteMethodEmail(query) {
            if (query !== "") {
                const form = { name: `${query}` };
                this.loading = true;
                ZimbraMailService.autoCompleteRequest(form)
                    .then((res) => {
                        const data = this.getResponseBodyEmail(res);
                        this.listEmail = data;
                        if (this.listEmail) {
                            this.listEmail = Array.isArray(this.listEmail) ? this.listEmail : [this.listEmail];
                            this.listEmail.forEach((x) => {
                                x.label = this.getAddressEmailFromText(x.email);
                                x.p = this.getNamePartAddressFromText(x.email);
                            });
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.listEmail = [];
                        this.loading = false;
                    });
            } else {
                this.listEmail = [];
            }
        },
        onclear() {
            if (this.focusAfterClear) {
                this.$refs.refSelectEmail.focus();
            }
        },
        onblur() {
            this.$emit("onblur", this.dataSelected);
        }
    }
};
</script>