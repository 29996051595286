import { render, staticRenderFns } from "./menu-right-archive.vue?vue&type=template&id=313d8448&scoped=true"
import script from "./menu-right-archive.vue?vue&type=script&lang=js"
export * from "./menu-right-archive.vue?vue&type=script&lang=js"
import style0 from "./menu-right-archive.vue?vue&type=style&index=0&id=313d8448&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "313d8448",
  null
  
)

export default component.exports