<template>
    <el-dialog
        :title="$t('zimbra.zhMsg.customRepeat')"
        :visible.sync="visable"
        :append-to-body="true"
        :destroy-on-close="true"
        class="zimbra-dialog zm-recurrence-config"
    >
        <div>
            <div class="event-info">
                <fieldset class="border p-2">
                    <legend class="w-auto">
                        <strong>{{ $t("zimbra.zhMsg.repeat") }}</strong>
                    </legend>
                    <div>
                        <div class="row pl-4">
                            <el-select v-model="customRepeat.freq" @change="changeFreq">
                                <el-option
                                    v-for="item in DATASOURCE_FREQ_TYPE"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                    <span>
                                        {{ item.label }}
                                    </span>
                                </el-option>
                            </el-select>
                        </div>
                        <template v-if="customRepeat.freq === CALENDAR_FREQ_TYPE.DAILY">
                            <!-- Lặp ngày -->
                            <div class="row pl-4">
                                <el-radio-group
                                    class="radio-control pt-3"
                                    v-model="customRepeat.daily"
                                >
                                    <el-radio
                                        class="radio-item w-100 pb-2"
                                        :label="CAL_RECUR_DAILY_TYPE.EVERY_DAY"
                                    >
                                        {{
                                            $t("zimbra.zmMsg.recurDailyEveryDay")
                                        }}
                                    </el-radio>
                                    <el-radio
                                        class="radio-item w-100 pb-2"
                                        :label="CAL_RECUR_DAILY_TYPE.EVERY_WEEKDAY"
                                    >
                                        {{
                                            $t("zimbra.zmMsg.recurDailyEveryWeekday")
                                        }}
                                    </el-radio>
                                    <el-radio
                                        class="radio-item w-100 pb-2 locale"
                                        :label="CAL_RECUR_DAILY_TYPE.EVERY_NUM_DAYS"
                                    >
                                        <BaseLocaleInput
                                            localeKey="zimbra.zmMsg.recurDailyEveryNumDays"
                                            v-model="customRepeat.recurDailyEveryNumDays"
                                            key="recurDailyEveryNumDays"
                                            @onfocus="customRepeat.daily = CAL_RECUR_DAILY_TYPE.EVERY_NUM_DAYS"
                                        />
                                        
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </template>
                        <template v-else-if="customRepeat.freq === CALENDAR_FREQ_TYPE.WEEKLY">
                            <!-- Lặp tuần -->
                            <div class="row pl-4">
                                <el-radio-group
                                    class="radio-control pt-3"
                                    v-model="customRepeat.weekly"
                                >
                                    <el-radio
                                        class="radio-item w-100 pb-2 locale"
                                        :label="CAL_RECUR_WEEKLY_TYPE.EVERY_WEEKDAY"
                                    >
                                        <BaseLocaleInput
                                            localeKey="zimbra.zmMsg.recurWeeklyEveryWeekday"
                                            v-model="customRepeat.recurWeeklyEveryWeekday"
                                            key="recurDailyEveryNumDays"
                                            @onfocus="customRepeat.weekly = CAL_RECUR_WEEKLY_TYPE.EVERY_WEEKDAY"
                                        />
                                    </el-radio>
                                    <el-radio
                                        class="radio-item w-100 pb-2 locale"
                                        :label="CAL_RECUR_WEEKLY_TYPE.EVERY_NUM_WEEKS_DATE"
                                    >
                                        <BaseLocaleInput
                                            localeKey="zimbra.zmMsg.recurWeeklyEveryNumWeeksDate"
                                            v-model="customRepeat.recurWeeklyEveryNumWeeksDate"
                                            key="recurWeeklyEveryNumWeeksDate"
                                            @onfocus="customRepeat.weekly = CAL_RECUR_WEEKLY_TYPE.EVERY_NUM_WEEKS_DATE"
                                        />
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </template>
                        <template v-else-if="customRepeat.freq === CALENDAR_FREQ_TYPE.MONTHLY">
                            <!-- Lặp tháng -->
                            <div class="row pl-4">
                                <el-radio-group
                                    class="radio-control pt-3"
                                    v-model="customRepeat.monthly"
                                >
                                    <el-radio
                                        class="radio-item w-100 pb-2 locale"
                                        :label="CAL_RECUR_MONTHLY_TYPE.EVERY_NUM_MONTHS_DATE"
                                    >
                                        <BaseLocaleInput
                                            localeKey="zimbra.zmMsg.recurMonthlyEveryNumMonthsDate"
                                            v-model="customRepeat.recurMonthlyEveryNumMonthsDate"
                                            key="recurMonthlyEveryNumMonthsDate"
                                            @onfocus="customRepeat.monthly = CAL_RECUR_MONTHLY_TYPE.EVERY_NUM_MONTHS_DATE"
                                        />
                                    </el-radio>
                                    <el-radio
                                        class="radio-item w-100 pb-2 locale radio-label-overflow-month"
                                        :label="CAL_RECUR_MONTHLY_TYPE.EVERY_NUM_MONTHS_NUMDAY"
                                    >
                                        <BaseLocaleInput
                                            localeKey="zimbra.zmMsg.recurMonthlyEveryNumMonthsWeekDays"
                                            v-model="customRepeat.recurMonthlyEveryNumMonthsWeekDays"
                                            key="recurMonthlyEveryNumMonthsWeekDays"
                                            @onfocus="customRepeat.monthly = CAL_RECUR_MONTHLY_TYPE.EVERY_NUM_MONTHS_NUMDAY"
                                        />
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </template>
                        <template v-else-if="customRepeat.freq === CALENDAR_FREQ_TYPE.YEARLY">
                            <!-- Lặp năm -->
                            <div class="row pl-4">
                                <el-radio-group
                                    class="radio-control pt-3"
                                    v-model="customRepeat.yearly"
                                >
                                    <el-radio
                                        class="radio-item w-100 pb-2 locale radio-label-overflow-year"
                                        :label="CAL_RECUR_YEARLY_TYPE.EVERY_DATE"
                                    >
                                        <BaseLocaleInput
                                            localeKey="zimbra.zmMsg.recurYearlyEveryDate"
                                            v-model="customRepeat.recurYearlyEveryDate"
                                            key="recurYearlyEveryDate"
                                            @onfocus="customRepeat.yearly = CAL_RECUR_YEARLY_TYPE.EVERY_DATE"
                                        />
                                    </el-radio>
                                    <el-radio
                                        class="radio-item w-100 pb-2 locale radio-label-overflow-year"
                                        :label="CAL_RECUR_YEARLY_TYPE.EVERY_MONTH_WEEK_DAYS"
                                    >
                                        <BaseLocaleInput
                                            localeKey="zimbra.zmMsg.recurYearlyEveryMonthWeekDays"
                                            v-model="customRepeat.recurYearlyEveryMonthWeekDays"
                                            key="recurYearlyEveryMonthWeekDays"
                                            @onfocus="customRepeat.yearly = CAL_RECUR_YEARLY_TYPE.EVERY_MONTH_WEEK_DAYS"
                                        />
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </template>
                    </div>
                </fieldset>
                <fieldset v-if="customRepeat.freq !== CALENDAR_FREQ_TYPE.NONE" class="border p-2 mt-3">
                    <!-- Kết thúc lặp -->
                    <legend class="w-auto">
                        <strong>{{ $t("zimbra.zhMsg.end") }}</strong>
                    </legend>
                    <div>
                        <el-radio-group
                            class="radio-control pt-3"
                            v-model="customRepeat.recurEndType"
                        >
                            <el-radio
                                class="radio-item w-100 pb-2"
                                :label="RECUR_END_INTERVAL_TYPE.NONE"
                            >
                                {{$t("zimbra.zmMsg.recurEndNone")}}
                            </el-radio>
                            <el-radio 
                                class="radio-item w-100 pb-2 locale"
                                :label="RECUR_END_INTERVAL_TYPE.NUMBER"
                            >
                                <BaseLocaleInput
                                    localeKey="zimbra.zmMsg.recurEndNumber"
                                    v-model="customRepeat.recurEndNumber"
                                    key="recurEndNumber"
                                    @onfocus="customRepeat.recurEndType = RECUR_END_INTERVAL_TYPE.NUMBER"
                                />
                            </el-radio>
                            <el-radio 
                                :class="['radio-item w-100 pb-2 locale', isRecurEndDateInvalid ? 'zm-recur-end-date-invalid' : '']"
                                :label="RECUR_END_INTERVAL_TYPE.DATE"
                            >
                                <BaseLocaleInput
                                    localeKey="zimbra.zmMsg.recurEndByDate"
                                    v-model="customRepeat.recurEndByDate"
                                    key="recurEndByDate"
                                    @onfocus="customRepeat.recurEndType = RECUR_END_INTERVAL_TYPE.DATE"
                                />
                            </el-radio>
                        </el-radio-group>
                    </div>
                </fieldset>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <div class="text-right">
                <el-button class="btn-cancel" @click="oncancel">
                    {{ $t("zimbra.zhMsg.actionTaskCancelTT") }}
                </el-button>
                <el-button
                    type="primary"
                    class="btn-calendar"
                    @click="handleCustomRepeat"
                >
                    {{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}
                </el-button>
            </div>
        </span>
    </el-dialog>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import {
    DATASOURCE_FREQ_TYPE,
    CALENDAR_FREQ_TYPE,
    DATASOURCE_TIME_DAYS,
    DATASOURCE_MONTHLY_DAY,
    DATASOURCE_MONTHS,
    RECUR_END_INTERVAL_TYPE,
    CAL_RECUR_DAILY_TYPE,
    CAL_RECUR_WEEKLY_TYPE,
    CAL_RECUR_MONTHLY_TYPE,
    CAL_RECUR_YEARLY_TYPE,
} from "@/utils/constants.js";
// import BaseCheckboxGroup from "@/components/modules/base/base-checkbox-group";
import moment from "moment";
import BaseLocaleInput from "@/components/modules/base/base-locale-input/base-locale-input";
import _ from "lodash";
import CalendarUtils from "@/utils/calendar-utils";
export default {
    name: "DialogRecurrenceConfig", // lặp lại tùy chỉnh
    mixins: [CommonUtils, CalendarUtils],
    components: {
        // BaseCheckboxGroup,
        BaseLocaleInput
    },
    data() {
        return {
            visable: false,
            freq: null,
            type: null,
            customRepeat: {
            },
            DATASOURCE_FREQ_TYPE: DATASOURCE_FREQ_TYPE,
            CALENDAR_FREQ_TYPE: CALENDAR_FREQ_TYPE,
            DATASOURCE_TIME_DAYS: DATASOURCE_TIME_DAYS,
            DATASOURCE_MONTHLY_DAY: DATASOURCE_MONTHLY_DAY,
            DATASOURCE_MONTHS: DATASOURCE_MONTHS,
            RECUR_END_INTERVAL_TYPE: RECUR_END_INTERVAL_TYPE,
            CAL_RECUR_DAILY_TYPE: CAL_RECUR_DAILY_TYPE,
            CAL_RECUR_WEEKLY_TYPE: CAL_RECUR_WEEKLY_TYPE,
            CAL_RECUR_MONTHLY_TYPE: CAL_RECUR_MONTHLY_TYPE,
            CAL_RECUR_YEARLY_TYPE: CAL_RECUR_YEARLY_TYPE,
            today: moment(new Date()).format('yyyyMMDD'),
            isRecurEndDateInvalid: false,
        }
    },
    computed: {
        
    },
    methods: {
        show(freq, customRepeat) {
            this.freq = freq || CALENDAR_FREQ_TYPE.DAILY;
            this.visable = true;
            this.isRecurEndDateInvalid = false;
            if (customRepeat) {
                this.customRepeat = {
                    ...customRepeat,
                    freq: customRepeat.freq || this.freq
                };
            } else {
                this.setDefault();
            }
        },
        setDefaultRecur() {
            moment.locale("en");
            const _today = moment(new Date());
            const _dd = _today.format("dd").toUpperCase();

            // Hàng ngày
            this.customRepeat.daily = CAL_RECUR_DAILY_TYPE.EVERY_DAY;
            this.customRepeat.recurDailyEveryNumDays = [2];

            // Hàng tuần
            this.customRepeat.weekly = CAL_RECUR_WEEKLY_TYPE.EVERY_WEEKDAY;
            this.customRepeat.recurWeeklyEveryWeekday = [_dd];
            this.customRepeat.recurWeeklyEveryNumWeeksDate = [2, [_dd]];

            // Hàng tháng
            this.customRepeat.monthly = CAL_RECUR_MONTHLY_TYPE.EVERY_NUM_MONTHS_DATE
            this.customRepeat.recurMonthlyEveryNumMonthsDate = [_today.date(), 1];
            this.customRepeat.recurMonthlyEveryNumMonthsWeekDays = [1, "SU", 1];

            // Hàng năm
            this.customRepeat.yearly = CAL_RECUR_YEARLY_TYPE.EVERY_DATE;
            this.customRepeat.recurYearlyEveryDate = [_today.month() + 1, _today.date()];
            this.customRepeat.recurYearlyEveryMonthWeekDays = [1, "SU", _today.month() + 1];
        },
        setDefault() {
            // Khởi tạo giá trị mặc định
            this.customRepeat.freq = this.customRepeat.freq || this.freq;
            this.customRepeat.freq = this.customRepeat.freq === CALENDAR_FREQ_TYPE.CUSTOM ? CALENDAR_FREQ_TYPE.DAILY : this.customRepeat.freq;

            this.setDefaultRecur();

            // Kết thúc
            this.customRepeat.recurEndByDate = [this.today];
            this.customRepeat.recurEndNumber = [1];
            this.customRepeat.recurEndType = RECUR_END_INTERVAL_TYPE.NONE;

            this.customRepeat = _.cloneDeep(this.customRepeat);
        },
        validateBeforeSave() {
            if (this.customRepeat.recurEndType === RECUR_END_INTERVAL_TYPE.DATE) {
                const endDate = moment(this.customRepeat.recurEndByDate[0]);
                const today = this.startOfDay();
                if (endDate.isBefore(today)) {
                    this.isRecurEndDateInvalid = true;
                    return false;
                }
            }
            return true;
        },
        handleCustomRepeat() {
            if (!this.validateBeforeSave()) {
                return;
            }
            this.$emit("handleCustomRepeat", this.customRepeat);
            this.visable = false;
        },
        onchangeRecurEndNumber() {
            // change Kết thúc sau n lần xuất hiện
            this.customRepeat.recurEndType = RECUR_END_INTERVAL_TYPE.NUMBER;
        },
        onchangeRecurEndByDate() {
            // change Kết thúc vào
            this.customRepeat.recurEndType = RECUR_END_INTERVAL_TYPE.DATE;
        },
        changeFreq() {
            this.setDefaultRecur();
            this.customRepeat = _.cloneDeep(this.customRepeat);
        },
        /**
         * click button Hủy
         */
        oncancel() {
            this.visable = false;
            this.$emit("oncancel");
        }
    }
}
</script>