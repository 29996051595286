<template>
    <el-tooltip class="item" effect="dark" :content='this.$t("zimbra.zhMsg.userGuide")' placement="left-end">
        <a class="element" :href='this.$t("zimbra.zhMsg.userGuideLink")' target="_blank">
            <i :class="`hw-18 ic-user-guide`">
            </i>
        </a>

    </el-tooltip>
</template>
<script>
export default {
}
</script>

<style></style>