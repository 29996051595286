import Vue from "vue";
import Vuex from "vuex";
import AccountService from "@/services/account/AccountService";
import accountModule from "./modules/account";
import generalModule from "./modules/general";
import inboxModule from "./modules/inbox";
import filterModules from "./modules/filter";
import OutOfOfficeModule from "./modules/outOfOffice";
import EmailModule from "./modules/email";
import calendarModule from "./modules/calendar";
import zimletModules from "./modules/zimlet";
import settingModule from "./modules/setting";
import ContactModule from "./modules/contact";
import ChatModule from "./modules/chat";
import briefcaseModule from "./modules/briefcase";
Vue.use(Vuex);
// const zimbra_token = JSON.parse(localStorage.getItem('zimbra_token'));
// const initAccount = zimbra_token ? zimbra_token : {};

export const store = new Vuex.Store({
    state: {
        safelyStoredNumber: 105,
        accountInfo: {},
        selectedComponent: "navGeneral",
        tabActive: null,
        emailDragEvent: null
    },
    getters: {
        // lay gia tri cua state trong store
        safelyStoredNumber: state => state.safelyStoredNumber,
        getAccountInfo: state => state.accountInfo,
        accountInfo: state => state.accountInfo,
        selectedComponent: state => state.selectedComponent,
        tabActive: state => state.tabActive,
        emailDragEvent: state => state.emailDragEvent
    },
    mutations: {
        // thay doi state,
        SET_ACCOUNT_INFO: (state, data) => {
            state.accountInfo = data;
        },
        SET_SELECTEDCOMPONENT: (state, data) => {
            state.selectedComponent = data;
        },
        SET_TABACTIVE(state, data) {
            state.tabActive = data;
        },
        SET_EMAIL_DRAG_EVENT(state, data) {
            state.emailDragEvent = data
        }
    },
    actions: {
        // thay doi state xu ly bat dong bo
        async getAccountInfo({ commit }, username) {
            // lấy thông tin user
            const response = await AccountService.getAccountInfoRequest({
                username: username
            });
            const info =
                response["data"]["soap:Envelope"]["soap:Body"][
                "GetAccountInfoResponse"
                ];
            // console.log('getAccountInfoRequest', info);
            commit("SET_ACCOUNT_INFO", info);
            return response;
        },
        setSelectedcomponent({ commit }, data) {
            commit("SET_SELECTEDCOMPONENT", data);
        },
        setTabActive({ commit }, data) {
            commit("SET_TABACTIVE", data);
        },
        setEmailDragEvent({commit}, data) {
            commit('SET_EMAIL_DRAG_EVENT', data)
        }
    },
    modules: {
        generalModule,
        inboxModule,
        accountModule,
        OutOfOfficeModule,
        EmailModule,
        filterModules,
        zimletModules,
        calendarModule,
        ContactModule,
        settingModule,
        ChatModule,
        briefcaseModule
    }
});
