<template>
    <el-dialog
        class="dialog-body-wrap zimbra-dialog d-center zimbra-limited-width-dialog"
        custom-class="dialog-add-folder"
        :title="checkTitleAddFolder"
        :visible.sync="dialogAddFolder"
        :close-on-click-modal="false"
        width="30%"
        :append-to-body="true"
    >
        <el-form :model="formAdd" class="form-wrap" @keypress.enter.native="AddFolder">
            <el-form-item
                :label="$t('zimbra.zhMsg.folderName')"
                label-width="110px"
            >
                <el-input
                    class="el-text-control"
                    v-model="formAdd.name"
                    autocomplete="off"
                    size="medium"
                    id="input-name-contact-folder"
                    ref="refInputName"
                ></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('zimbra.zhMsg.color')"
                label-width="110px"
            >
                <template>
                    <div>
                        <zm-color-picker
                            v-model="formAdd.rgb"
                            :visible="false"
                            :showFooter="true"
                            :default-colors="[
                                '#3F4254',
                                '#0000FF',
                                '#00FFFF',
                                '#008000',
                                '#FF00FF',
                                '#FF0000',
                                '#FFFF00',
                                '#FF66FF',
                                '#808080',
                                '#FF9900',
                            ]"
                        >
                        </zm-color-picker>
                    </div>
                </template>
            </el-form-item>
            <el-form-item
                v-if="!checkTypeFolder(formAdd.parent)"
                :label="$t('zimbraNokey.zimbra_sidebar_dialogAddFolder_powerSupply')"
                label-width="110px"
            >
                <el-checkbox v-model="formAdd.registerRSS">{{
                    $t("zimbra.zmMsg.subscribeToFeed")
                }}</el-checkbox>
            </el-form-item>
            <el-form-item
                v-if="formAdd.registerRSS"
                :label="$t('zimbra.zhMsg.url')"
                label-width="110px"
            >
                <el-input
                    class="el-text-control"
                    v-model="formAdd.url"
                    autocomplete="off"
                    size="medium"
                ></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('zimbra.zhMsg.parentFolder')"
                label-width="110px"
            >
                <span>{{ formAdd.parent.name }}</span>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button
                class="btn-email default"
                @click="dialogAddFolder = false"
                >{{ $t("zimbra.zhMsg.actionTaskCancelTT") }}</el-button
            >
            <el-button
                native-type="submit"
                class="btn-email primary"
                type="primary"
                @click="AddFolder()"
                >{{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}</el-button
            >
        </span>
    </el-dialog>
</template>
<script>
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import ContactUtils from "@/utils/contact-utils";
import { STATUS_REQUEST, FOLDER_VIEW, READ_EMAIL_LAYOUT_ROUTER } from "@/utils/constants";
import color from "./../listColor";
import _ from "lodash";
import hotkeys from "hotkeys-js"
// import $ from 'jquery'
import ZmColorPicker from "@/components/modules/base/base-color-picker-advance/zm-color-picker";
export default {
    components: {
        "zm-color-picker": ZmColorPicker,
    },
    mixins: [CommonUtils, ContactUtils],
    mounted() {
        this.$root.$on("handleSidebarActionAdd", (type) => {
            this.show(type);
        });

        /*
       * Phim tat enter
       */
        hotkeys("enter", this.handleKeyAddFolder)
    },
    props: {
        createNewAddrBook: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
    },
    data() {
        return {
            formAdd: {
                name: "",
                registerRSS: "",
                parent: "",
                url: null,
                rgb: "#3F4254",
            },
            listColor: color,
            dialogAddFolder: false,
            objectColor: { value: "-1", label: "Không" },
            suckerCanvas: null,
            suckerArea: [],
            colors: null,
            color: "#59c7f9",
            isSucking: false,
            showColorPicker: false,
            STATUS_REQUEST: STATUS_REQUEST,
            checkTitleAddFolder: null,
            typeFolder: null
        };
    },
    methods: {
        /*
        * Phim tat enter
        */
        handleKeyAddFolder(e) {
            if (this.dialogAddFolder) {
                e.preventDefault()
                this.AddFolder()
            }
        },
        changeColor() {
            this.showColorPicker = false;
        },
        show(type) {
            this.formAdd = {
                name: "",
                registerRSS: "",
                parent: {},
                url: "",
                rgb: "#3F4254",
            };
            this.objectColor = { value: "-1", label: "Không" };
            this.formAdd.parent =
                this.$store.getters.activeNodeSidebarRightClick;
            this.typeFolder = type
            this.checkTitleAddFolder = this.checkTitle(this.typeFolder);
            this.dialogAddFolder = true;
            // Focus Tên thư mục
            setTimeout(() => {
                this.$refs.refInputName.focus();
            }, 500);
            const { name } = this.$route;
            if (name == READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE) {
                this.isMessage = true
            } else {
                this.isMessage = false
            }
            // $(document).ready(function() { lỗi trên firefox 53
            //     setTimeout(function() {
            //         $("#input-name-contact-folder").focus();
            //     }, 500);
            // });
        },
        checkExistsName(name, folderParent) {

            if (Array.isArray(folderParent.folder)) {          
                if (
                    folderParent.folder
                        .map((x) => x.name.toString().toLowerCase())
                        .indexOf(name.toString().toLowerCase()) > -1
                ) {
                   
                    let message = "";
                    if (this.typeFolder === FOLDER_VIEW.CONTACT) {
                        message = this.$t("zimbra.zmMsg.errorAlreadyExists", {
                            0: name,
                            1: this.$t("zimbra.zhMsg.addressBook")
                        });
                    } else if (this.typeFolder === FOLDER_VIEW.BRIEFCASE) {
                        message = this.$t("zimbra.zmMsg.errorAlreadyExists", { 0: name, 1: this.$t("zimbra.zhMsg.ALT_APP_BRIEFCASE") });
                    } else {
                        message = this.$t("zimbra.zmMsg.errorAlreadyExists", { 0: name, 1: this.$t("zimbra.zhMsg.ALT_FOLDER") });
                    }
                    
                    this.$alert(
                        message,
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return true;
                }
            }
        },
        isUrl(s) {
            var regexp =
                /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
            return regexp.test(s);
        },
        AddFolder() {
           
            if (this.checkExistsName(this.formAdd.name, this.formAdd.parent)) {
                return;
            }
            this.formAdd.name = this.formAdd.name.trim();
            // validateName
            if (
                this.formAdd.name == "" ||
                this.formAdd.name.length > 128 ||
                this.formAdd.name.indexOf("/") > -1 ||
                /[!@#$%^&*(),.?":{}|<>]/.test(this.formAdd.name)
            ) {
                const nameError = this.formAdd.name;
                const Msgerror =
                    this.formAdd.name == ""
                        ? this.$t("zimbra.zmMsg.nameEmpty")
                        : this.formAdd.name.length > 128
                            ? this.$t("zimbra.zhMsg.nameTooLong", { 0: 128 })
                            : this.$t(
                                "zimbra.zmMsg.errorInvalidName",
                                { 0: nameError }
                            );
                this.$alert(Msgerror, this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"), {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    type: "error",
                });
                return;
            }
            // validateUrl
            this.formAdd.url = this.formAdd.url.trim();
            if (this.formAdd.registerRSS) {
                if (this.formAdd.url == "" || !this.isUrl(this.formAdd.url)) {
                    const Msgerror = this.$t(
                        "zimbra.zmMsg.errorUrlMissing"
                    );
                    this.$alert(
                        Msgerror,
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return;
                }
            }
            const nameFolder = _.escape(this.formAdd.name);
            const formData = {
                l: `${this.formAdd.parent.id}`,
                name: `${nameFolder}`,
                view: `${this.formAdd.parent.view}`,
                // rgb:"#FF00FF"
            };
            if (this.formAdd.rgb != null) {
                formData.rgb = this.formAdd.rgb;
            }
            if (this.formAdd.registerRSS) {
                formData.url = _.escape(this.formAdd.url.trim());
            }
            ZimbraMailService.createFolderRequest(formData).then((res) => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    if (this.isMessage) {
                        this.$message({
                            type: "success",
                            customClass: 'zm-notify',
                            duration: 3500,
                            dangerouslyUseHTMLString: true,
                            message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessAddFolder")
                        });
                    } else {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessAddFolder"),
                            type: "success",
                        });
                    }
                    const itemAddResponse =
                        this.getResponseBody(res)["CreateFolderResponse"];
                    const itemAdd = itemAddResponse.folder;
                    if (this.formAdd.parent.isLink) {
                        itemAdd.isLink = true;
                        itemAdd.absFolderPath = this.formAdd.parent.absFolderPath + `/${itemAdd.name}`;
                    }
                    if (itemAdd.view == FOLDER_VIEW.CONTACT) {
                        this.addItemToData(itemAdd, this.dataSidebarContact[0].folder);
                        setTimeout(() => {
                            this.$root.$emit("extendFolderContact");
                        }, 500);
                    } else if (itemAdd.view == FOLDER_VIEW.DOCUMENT) {
                        this.$root.$emit("onInnitBriefcaseTree");
                        setTimeout(() => {
                            this.$root.$emit("extendFolderContact", itemAdd.l);
                        }, 500);
                    } else {
                        this.$root.$emit("addItemToDataInBox", itemAdd);
                        this.$root.$emit("addItemToDataExtend", itemAdd);
                        setTimeout(() => {
                            this.$root.$emit("extendFolder");
                        }, 500);
                    }
                }
            })
                .catch(() => {
                    if (this.formAdd.registerRSS) {
                        this.$confirm(
                            this.$t("zimbraNokey.zimbra_sidebar_resourceUnreachable", {
                                url: formData.url,
                            }),
                            this.$t("zimbraNokey.zimbra_common_criticalMsg"),
                            {
                                showConfirmButton: false,
                                cancelButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                                type: "error",
                            }
                        )
                            .then(() => { })
                            .catch(() => { });
                    } else {
                        const message = this.$t("zimbra.zmMsg.errorAlreadyExists", {
                            0: this.formAdd.name,
                            1: this.$t("zimbra.zhMsg.addressBook")
                        });
                        this.$alert(
                            message,
                            this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                            {
                                confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                                type: "error",
                            }
                        );
                    }
                    return;
                    // const { detail } = this.getResponseBody(err.response)[
                    //     "soap:Fault"
                    // ];
                    // if (detail.Error.Code) {
                    //     if (detail.Error.Code ==="mail.ALREADY_EXISTS") {
                    //         const message = this.$t("zimbra.zmMsg.errorAlreadyExists", {
                    //             0: this.formAdd.name,
                    //             1: this.$t("zimbra.zhMsg.addressBook")
                    //         });
                    //         this.$alert(
                    //             message,
                    //             this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                    //             {
                    //                 confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    //                 type: "error",
                    //             }
                    //         );
                    //         return;
                    //     }
                    //     if (
                    //         detail.Error.Code ===
                    //             ERROR_CODE.SERVICE_RESOURCE_UNREACHABLE ||
                    //         detail.Error.Code === ERROR_CODE.SERVICE_PARSE_ERROR
                    //     ) {
                    //         this.$confirm(
                    //             this.$t("zimbraNokey.zimbra_sidebar_resourceUnreachable", {
                    //                 url: formData.url,
                    //             }),
                    //             this.$t("zimbraNokey.zimbra_common_criticalMsg"),
                    //             {
                    //                 showConfirmButton: false,
                    //                 cancelButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    //                 type: "error",
                    //             }
                    //         )
                    //             .then(() => {})
                    //             .catch(() => {});
                    //     }
                    // }
                });
            this.dialogAddFolder = false;
        },

        /*
        * Hàm kiểm tra folder có dạng view message
        */
        checkTypeFolder(data) {
            if (data.view && data.view !== FOLDER_VIEW.MESSAGE) {
                return true;
            } else {
                if (data.folder && data.folder.length) {
                    for (let index = 0; index < data.folder.length; index++) {
                        if (this.checkTypeFolder(data.folder[index])) {
                            return true;
                        }
                    }
                }
            }
            return false
        },

        checkTitle(view) {
            if (view == FOLDER_VIEW.CONTACT) {
                return this.$t('zimbra.zmMsg.createNewAddrBook');
            } else if (view == FOLDER_VIEW.BRIEFCASE) {
                return this.$t('zimbra.zmMsg.createNewBriefcaseItem');
            } else {
                return this.$t('zimbraNokey.zimbra_sidebar_dialogAddFolder_title');
            }
        }
    },
};
</script>
