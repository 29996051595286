<template>
    <el-dialog class="dialog-body-wrap zimbra-dialog zimbra-limited-width-dialog"
        :title="$t('zimbra.zmMsg.folderProperties')" :visible.sync="dialogEditFolderSearch" width="30%"
        :append-to-body="true" :close-on-click-modal="false">
        <el-form :model="formEdit" class="form-wrap">
            <el-form-item :label="$t('zimbra.zhMsg.AB_FIELD_firstName')" label-width="110px">
                <el-input class="el-text-control" v-model="formEdit.name" autocomplete="off" size="medium"></el-input>
            </el-form-item>
            <el-form-item :label="$t('zimbra.zhMsg.optionsAccountType')" label-width="110px">
                <span>{{
            $t(
                "zimbra.zmMsg.savedSearch"
            )
        }}</span>
            </el-form-item>
            <el-form-item :label="$t('zimbra.zhMsg.search')" label-width="110px">
                <el-input class="el-text-control" v-model="formEdit.query" autocomplete="off" size="medium"></el-input>
            </el-form-item>
            <el-form-item :label="$t('zimbra.zhMsg.color')" label-width="110px">
                <zm-color-picker v-model="formEdit.rgb" :visible="false" :showFooter="true">
                </zm-color-picker>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button class="btn-email default" @click="dialogEditFolderSearch = false">{{
            $t("zimbra.zhMsg.actionTaskCancelTT") }}</el-button>
            <el-button class="btn-email primary" type="primary" @click="editFolder()">{{
            $t("zimbra.zmMsg.mobileStatusNeedProvision") }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import ZmColorPicker from "@/components/modules/base/base-color-picker-advance/zm-color-picker";
import CompileService from "@/services/CompileService.js";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import EmailUltils from "@/utils/email-utils";
import CalendarUtils from "@/utils/calendar-utils";
import color from "./../listColor";
import _ from "lodash";
import { mapGetters } from "vuex";
import { STATUS_REQUEST } from "@/utils/constants";
export default {
    mixins: [CommonUtils, EmailUltils],
    mounted() {
        this.$root.$on("handleActionEditFolderSearch", () => {
            this.show();
        });
    },
    components: {
        "zm-color-picker": ZmColorPicker,
    },
    data() {
        return {
            formEdit: {
                name: "",
                rgb: null,
            },
            listColor: color,
            dialogEditFolderSearch: false,
        };
    },
    computed: {
        ...mapGetters(["activeNodeSidebarRightClick"]),
    },
    methods: {
        show() {
            this.formEdit = _.cloneDeep(this.activeNodeSidebarRightClick);
            if (this.formEdit.color) {
                this.formEdit.rgb = this.listColor.find(
                    (x) => x.colorNumeric == this.formEdit.color
                ).value;
            }
            this.dialogEditFolderSearch = true;
        },
        checkExistsName(name, folderParent) {
            if (Array.isArray(folderParent?.folder)) {
                if (folderParent.folder.map(x => x.name.toString().toLowerCase()).indexOf(name.toString().toLowerCase()) > -1) {
                    let msgerror = this.$t("zimbraNokey.zimbra_searchBox_duplidateName", { name });
                    this.$alert(
                        msgerror,
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return true;
                }
            }
        },
        async editFolder() {
            this.formEdit.name = this.formEdit.name.trim();
            const folderSearches = await this.getFolderSearchApi();
            const nodeCalContain = CalendarUtils.methods.searchNodeById({ id: 1, folder: folderSearches[0].search }, this.formEdit.l)
            if (
                this.formEdit.name == "" ||
                this.formEdit.name.length > 128 ||
                this.formEdit.name.indexOf("/") > -1 ||
                this.formEdit.name.indexOf(`"`) > -1 ||
                /[!@#$%^&*(),.?":{}|<>]/.test(this.formEdit.name)
            ) {
                const nameError = _.escape(this.formEdit.name);
                const Msgerror =
                    this.formEdit.name == ""
                        ? this.$t("zimbra.zmMsg.nameEmpty")
                        : this.formEdit.name.length > 128
                            ? this.$t("zimbra.zhMsg.nameTooLong", { 0: 128 })
                            : this.$t(
                                "zimbra.zmMsg.errorInvalidName",
                                { 0: this.formEdit.name }
                            );
                this.$alert(Msgerror, this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"), {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    type: "error",
                });
                return;
            }
            if (this.formEdit.name.toLowerCase() != this.activeNodeSidebarRightClick.name.toString().toLowerCase()) { /// check trùng với tên viết hoa của chính nó
                if (this.checkExistsName(this.formEdit.name, nodeCalContain)) {
                    return;
                }
            }
            // this.formEdit.name = _.escape(this.formEdit.name);
            let stringXml = [];
            if (this.formEdit.name != this.activeNodeSidebarRightClick.name) {
                const xml = `<urn1:action id="${this.formEdit.id}" op="rename" name="${this.formEdit.name}"></urn1:action>`;
                stringXml.push({
                    path: "folderActionRequest",
                    content: { folder: xml },
                });
            }
            if (this.formEdit.rgb != this.activeNodeSidebarRightClick.rgb) {
                this.formEdit.rgb =
                    this.formEdit.rgb == null ? "" : this.formEdit.rgb;
                const xml = `<urn1:action id="${this.formEdit.id}" op="color" rgb="${this.formEdit.rgb}"></urn1:action>`;
                stringXml.push({
                    path: "folderActionRequest",
                    content: {folder: xml },
                });
            }
            const formData = {
                id: `${this.formEdit.id}`,
                query: _.escape(this.formEdit.query),
                types: this.formEdit.types,
            };

            ZimbraMailService.modifySearchFolderRequest(formData).then((request) => {
                if (request.status == STATUS_REQUEST.SUCCESS) {
                    const folder = this.activeNodeSidebarRightClick;
                    folder.name = this.formEdit.name;
                    folder.query = this.formEdit.query;
                    folder.rgb = this.formEdit.rgb;
                    const formData1 = {
                        listRequest: stringXml,
                    };
                    if (stringXml != "") {

                        // CompileService.batchRequest(formData1).then((res) => {
                        // if (res.data.code == "SUCCESS") {
                        //     this.$notify({
                        //         title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                        //         message: this.$t(
                        //             "zimbraNokey.zimbra_sidebar_dialogEditFolder_updateFolderSuccess"
                        //         ),
                        //         type: "success",
                        //     });
                        //     this.$store.commit("SET_ACTIVE_NODE_SIDEBAR", folder);
                        // }
                        let test = true;
                        stringXml.forEach((content) => {
                            ZimbraMailService.folderActionRequest(content.content).then((res) => {
                                if (res.status !== 200) {
                                    test = false
                                }
                            })
                        });
                        if (test) {
                            this.$notify({
                                title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                                message: this.$t(
                                    "zimbraNokey.zimbra_sidebar_dialogEditFolder_updateFolderSuccess"
                                ),
                                type: "success",
                            });
                            this.$store.commit("SET_ACTIVE_NODE_SIDEBAR", folder);
                        }

                    }
                }
            })
            this.dialogEditFolderSearch = false;
        },
    },
};
</script>
