<template>
    <div class="card-filter-row">
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueMainSelect"
                :options="mainSelect"
                @change="handleChangeMainSelect"
            />
        </div>
        <div class="card-filter-item"></div>
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="attachmentCompartison"
                :value="valueAttachmentCompartison"
                :options="attachmentCompartison"
                @change="handleChangeAttachmentTest"
            />
        </div>
        <div class="card-filter-item"></div>
        <div class="card-filter-item"></div>
    </div>
</template>

<script>
import { FILTER_TEST_CONFIG } from "@/utils/constants";
import CoreTest from "@/components/modules/base/base-filter-form/core-test";
import BaseSelect from "@/components/modules/base/base-select";
export default {
    extends: CoreTest,
    components: {
        BaseSelect,
    },
    created() {
        this.beforeBuildDataForm();
        this.conditionsAttachMentTest = this.conditionsTest;
    },
    watch: {
        conditionsTest(newData) {
            this.beforeBuildDataForm();
            this.conditionsAttachMentTest = newData;
        },
    },
     mounted(){

    },
    data() {
        return {
            FILTER_TEST_CONFIG: FILTER_TEST_CONFIG,
            attachmentCompartison: [
                {
                    attachmentCompartison:
                        FILTER_TEST_CONFIG.ATTACHMENT_COMPARTISON.EXIST,
                    name: this.$t(
                        "zimbra.zmMsg.exists"
                    ),
                },
                {
                    attachmentCompartison:
                        FILTER_TEST_CONFIG.ATTACHMENT_COMPARTISON.NOT_EXIST,
                    name: this.$t(
                        "zimbra.zmMsg.notExist"
                    ),
                },
            ],
            valueMainSelect: "",
            valueAttachmentCompartison: "",
            conditionsAttachMentTest: "",
        };
    },
    methods: {
        beforeBuildDataForm() {
            this.valueMainSelect = this.mainSelect[9].id;
            // SET-VALUE-ATTACHMENT-COMPARISON
            this.valueAttachmentCompartison = this.conditionsTest.negative
                ? this.attachmentCompartison[1].attachmentCompartison
                : this.attachmentCompartison[0].attachmentCompartison;
        },
        handleChangeAttachmentTest(attachmentCompartison) {
            for (let i in this.conditionsAttachMentTest) {
                if (
                    i !== "negative" &&
                    this.validateNegative(attachmentCompartison)
                ) {
                    this.conditionsAttachMentTest = {
                        ...this.conditionsAttachMentTest,
                        negative: FILTER_TEST_CONFIG.NEGATIVE,
                    };
                } else {
                    if (!this.validateNegative(attachmentCompartison)) {
                        delete this.conditionsAttachMentTest.negative;
                    }
                }
            }
            this.$emit("onChangeConditionsTest", this.conditionsAttachMentTest);
        },
    },
};
</script>
