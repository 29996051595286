<template>
    <div class="body-profile" :data="itemContact">
        <div class="header-profile">
            <div class="icon" @click="hanldePrevContact">
                <span class="ic-prev"></span>
            </div>
            <span class="title">{{ dataContact.fullName }}</span>
        </div>
        <div class="contact-content">
            <div class="contact-detail">
                <img :src="dataContact.avtUrl ? dataContact.avtUrl : require('@/assets/images/Avatar.png')" />
                <div class="contacts-info">
                    <div class="position-info">
                        <div class="work" v-if="dataContact.jobTitle || dataContact.departmentTitle">
                            <span class="ic-brifecase"></span>
                            <h2 class="title">
                                {{ dataContact.jobTitle }}
                            </h2>
                            <span v-if="dataContact.jobTitle && dataContact.departmentTitle">&#160;-&#160;</span>
                            <span>{{ dataContact.departmentTitle }}</span>
                        </div>
                        <div class="work" v-if="dataContact.companyTitle">
                            <span class="ic-buildings"></span>
                            <h2 class="title">{{ dataContact.companyTitle }}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-tag" v-if="dataContact.tags">
                <h2 class="title">{{ $t("zimbra.zhMsg.actionTag") }}</h2>
                <el-tag
                    class="contact-tag"
                    type="info"
                    closable
                    v-for="tag in dataContact.tags"
                    :key="tag.id"
                    :style="{
                        '--bgColorTag': getColor(tag),
                    }"
                >
                    {{ tag.name }}
                </el-tag>
        </div>
        <perfect-scrollbar>
            <div v-if="dataContact.emailTitle" class="content-address">
                <span class="title">{{ $t("zimbra.zhMsg.AB_FIELD_email") }}</span>
                <div class="email-list">
                    <span class="content-phone" v-for="(email, index) in dataContact.emailTitle" :key="index">{{ email.content }}</span>
                </div>
            </div>
            <div class="content-address" v-if="dataContact.phoneTitle">
                <span class="title">{{ $t("zimbra.zhMsg.AB_FIELD_homePhone") }}</span>
                <div class="phone-email" v-for="(phone, index) in dataContact.phoneTitle" :key="index">
                    <span class="content-phone">{{ phone.content }}</span>
                    <span class="title attrs">{{ renderTypePhone(phone) }}</span>
                </div>
            </div>
            <div class="content-address" v-if="dataContact.imTitle">
                <span class="title">{{ $t("zimbra.zhMsg.AB_FIELD_imAddress1") }}</span>
                <div class="im" v-for="(im, index) in dataContact.imTitle" :key="index">
                    <span class="content">{{ im.content }}</span>
                    <span class="title attrs">IM Address</span>
                </div>
            </div>
            <div class="content-address" v-if="checkAddress()">
                <span class="title">{{ $t("zimbra.zhMsg.EFILT_NEW_COND_ADDRESS") }}</span>
                <div class="address" v-for="(data, index) in dataContact.addressWorkTitle" :key="index">
                    <div class="info-address">
                        <p>{{ data.workStreet }}</p>
                        <p>{{ data.workCity }} {{ data.workPostalCode }} {{ data.workState }}</p>
                        <p>{{ data.workCountry }}</p>
                    </div>
                    <div class="type-address">
                        <span class="title attrs">{{ $t("zimbra.zhMsg.AB_FIELD_otherOffice") }}</span>
                    </div>
                </div>
                <div class="address" v-for="(data, index) in dataContact.addressHomeTitle" :key="index">
                    <div class="info-address">
                        <p>{{ data.homeStreet }}</p>
                        <p>{{ data.homeCity }} {{ data.homePostalCode }} {{ data.homeState }}</p>
                        <p>{{ data.homeCountry }}</p>
                    </div>
                    <div class="type-address">
                        <span class="title attrs">{{ $t("zimbra.zhMsg.home") }}</span>
                    </div>
                </div>
                <div class="address" v-for="(data, index) in dataContact.addressOtherTitle" :key="index">
                    <div class="info-address">
                        <p>{{ data.otherStreet }}</p>
                        <p>{{ data.otherCity }} {{ data.otherPostalCode }} {{ data.otherState }}</p>
                        <p>{{ data.otherCountry }}</p>
                    </div>
                    <div class="type-address">
                        <span class="title attrs">{{ $t("zimbra.zhMsg.AB_FIELD_IM_other") }}</span>
                    </div>
                </div>
            </div>
            <div class="content-address" v-if="dataContact.urlTitle">
                <span class="title">{{ $t("zimbra.zhMsg.url") }}</span>
                <div class="im" v-for="(url, index) in dataContact.urlTitle" :key="index">
                    <span class="content">{{ url.content }}</span>
                    <span class="title attrs">{{ url.typeI18N }}</span>
                </div>
            </div>
            <div class="content-address" v-if="dataContact.otherTitle">
                <span class="title">{{ $t("zimbra.zhMsg.AB_FIELD_IM_other") }}</span>
                <div class="im" v-for="(other, index) in dataContact.otherTitle" :key="index">
                    <span class="content">{{ other.content }}</span>
                    <span class="title attrs">{{ other.typeI18N }}</span>
                </div>
            </div>
            <div class="content-address" v-if="dataContact.notesTitle">
                <span class="title">{{ $t("zimbra.zhMsg.notes") }}</span>
                <div class="im">
                    <span class="content">{{ dataContact.notesTitle.content }}</span>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</template>

<script>
import ContactUtils from "@/utils/contact-utils";
import EmailUtils from "@/utils/email-utils";
// import _ from "lodash";
export default {
    mixins: [ContactUtils, EmailUtils],
    props: {
        itemContact: {
            type: [Object, Array],
            required: true,
        }
    },
    created() {
        this.dataContact = this.itemContact
       
    },

    data() {
        return {
            dataContact: {}
        };
    },
    watch: {
        itemContact(newData) {
            this.dataContact = newData
        }
    },
    methods: {
        checkAddress() {
            return (this.dataContact?.addressWorkTitle || this.dataContact?.addressHomeTitle || this.dataContact?.addressOtherTitle) ? true : false;
        }
    }
};
</script>
