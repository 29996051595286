import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import { FOLDER_DEFAULT } from "@/utils/constants"

const calendarModule = {
    state: {
        formCalenderOld:{
            // Giao dien mac dinh
            calendarInitialView: "day",
            // Bat dau tuan vao
            calendarFirstDayOfWeek: 0,
            // Che do hien thi cuoc hen mac dinh
            calendarApptVisibility: "public",
            // Luon hien thi lich thu gon
            calendarAlwaysShowMiniCal: false,
            // Hien thi lich voi so tuan
            showCalendarWeek: false,
            // Tu dong them cac cuoc hen
            calendarAutoAddInvites: false,
            // Hien thi cuoc hen bi tu choi
            calendarShowDeclinedMeetings: false,
            // Tra loi thu moi
            deleteInviteOnReply: false,
            // Chuyen tiep thu moi
            calendarForwardInvitesTo: "",
            // Hien thi thong bao nhac
            calendarApptReminderWarningTime: "",
            // Thoi gian cuoc hen mac dinh
            calendarDefaultApptDuration: "",
            //tuan va gio lam viec
            calendarWorkingHours: "",
            // Hop thoai them nhanh
            calendarUseQuickAdd: "",
            // Cac mui gio
            useTimeZoneListInCalendar: false,
        },
        // View mode hiện tại của mành hình: Ngày, tuần làm việc, tuần, năm
        calViewMode: 0,
        locationSearchPref: {}, // cấu hình tùy chọn gợi ý
        calTreeFolders: [], // cấu hình tùy chọn gợi ý
        isOpenCalTrash: false, // Cấu hình đóng, mở danh sách cuộc hẹn đã xóa
        listAppointmentSearch: [], // Danh sách cuộc hẹn khi tìm kiếm nâng cao
        // vùng thông tin cho tìm kiếm
        isVisibelCalSearchForm: false,
        calFormSearch: {},
        lstCalQuerySearch: [], // Danh sách các điều kiện tìm kiếm dạng str
        lstEventSearch: [],
        listAppoinmentWarning: [], // Danh sách lịch hẹn đang cảnh báo
        listAppoinment: [], // Danh sách lịch hẹn
        //
        listCalendarId: [], // Danh sách danh mục lịch
        lstAccountWebExZimlet: [], // Danh sách tài khoản webex zimlet
        activeAppointment: {}, // cuộc hẹn đang thao tác hiện tại
        formPreferencesWebExZimlet: {}, // Danh sách tài khoản webex zimlet,
        infoSchedules: null, // Thông tin lịch trình
        searchCalErrorMessage: [], // lỗi tìm kiếm
        isShowInfoCalFolder: true
    },
    getters: {
        formCalenderOld : state => state.formCalenderOld,
        calViewMode : state => state.calViewMode,
        locationSearchPref : state => state.locationSearchPref,
        calTreeFolders : state => state.calTreeFolders,
        isOpenCalTrash : state => state.isOpenCalTrash,
        listAppointmentSearch : state => state.listAppointmentSearch,
        isVisibelCalSearchForm : state => state.isVisibelCalSearchForm,
        calFormSearch : state => state.calFormSearch,
        lstCalQuerySearch : state => state.lstCalQuerySearch,
        lstEventSearch : state => state.lstEventSearch,
        listAppoinmentWarning : state => state.listAppoinmentWarning,
        listAppoinment : state => state.listAppoinment,
        listCalendarId : state => state.listCalendarId,
        lstAccountWebExZimlet : state => state.lstAccountWebExZimlet,
        activeAppointment : state => state.activeAppointment,
        formPreferencesWebExZimlet : state => state.formPreferencesWebExZimlet,
        infoSchedules : state => state.infoSchedules,
        searchCalErrorMessage: state => state.searchCalErrorMessage,
        isShowInfoCalFolder : state => state.isShowInfoCalFolder,
    },
    actions: {
        setCalViewMode({ commit }, _viewMode) {
            commit('SET_CAL_VIEW_MODE', _viewMode);
        },
        setCalTreeFolders({ commit }, data) {
            commit('SET_CAL_TREE_FOLDER', data);
        },
        setLocationSearchPref({ commit }) {
            ZimbraMailService.getMailboxMetadataRequest({ section: "zwc:MD_LOCATION_SEARCH_PREF" }).then(res => {
                const { meta } = CommonUtils.methods.getResponseBody(res).GetMailboxMetadataResponse;
                if (meta && meta.a) {
                    meta.a = Array.isArray(meta.a) ? meta.a : [meta.a];
                    const data = {};
                    meta.a.forEach(item => {
                        data[item.n] = item.content;
                    });
                    this.modelData = data;
                    commit("SET_LOCATION_SEARCH_PREF", data);
                }
            });
        },
    },
    mutations: {
        SET_ZIMBRAPREF_CALENDARINITIALVIEW(state, data){
            state.formCalenderOld.calendarInitialView = data
        },
        SET_ZIMBRAPREF_CALENDARFIRSTDAYOFWEEK(state, data){
            state.formCalenderOld.calendarFirstDayOfWeek = data
        },
        SET_ZIMBRAPREF_CALENDARAPPTVISIBILITY(state, data){
            state.formCalenderOld.calendarApptVisibility = data
        },
        SET_ZIMBRAPREF_CALENDARALWAYSSHOWMINICAL(state, data){
            state.formCalenderOld.calendarAlwaysShowMiniCal = data
        },
        SET_ZIMBRAPREF_SHOWCALENDARWEEK(state, data){
            state.formCalenderOld.showCalendarWeek = data
        },
        SET_ZIMBRAPREF_CALENDARAUTOADDINVITES(state, data){
            state.formCalenderOld.calendarAutoAddInvites = data
        },
        SET_ZIMBRAPREF_CALENDARSHOWDECLINEDMEETINGS(state, data){
            state.formCalenderOld.calendarShowDeclinedMeetings = data
        },

        SET_ZIMBRAPREF_DELETEINVITEONREPLY(state, data){
            state.formCalenderOld.deleteInviteOnReply = data
        },
        SET_ZIMBRAPREF_CALENDARFORWARDINVITESTO(state, data){
            state.formCalenderOld.calendarForwardInvitesTo = data
        },

        SET_ZIMBRAPREF_CALENDARAPPTREMINDERWARNINGTIME(state, data){
            state.formCalenderOld.calendarApptReminderWarningTime = data
        },
        SET_ZIMBRAPREF_CALENDARDEFAULTAPPTDURATION(state, data){
            state.formCalenderOld.calendarDefaultApptDuration = data
        },
        SET_ZIMBRAPREF_CALENDARWORKINGHOURS(state, data){
            state.formCalenderOld.calendarWorkingHours = data
        },
        SET_ZIMBRAPREF_CALENDARUSEQUICKADD(state, data){
            state.formCalenderOld.calendarUseQuickAdd = data
        },
        SET_ZIMBRAPREF_USETIMEZONELISTINCALENDAR(state, data){
            state.formCalenderOld.useTimeZoneListInCalendar = data
        },
        SET_CAL_VIEW_MODE(state, data){
            state.calViewMode = data
        },
        SET_LOCATION_SEARCH_PREF(state, data){
            state.locationSearchPref = data
        },
        // Set dữ liệu cây của lịch
        SET_CAL_TREE_FOLDER(state, data){
            const calFolderDefaut = data.filter(x => x.id == FOLDER_DEFAULT.CALENDAR);
            const calFolder = data.filter(x => x.id !== FOLDER_DEFAULT.TRASH && x.perm == undefined && x.id !== FOLDER_DEFAULT.CALENDAR);
            const calTrashFolder = data.filter(x => x.id == FOLDER_DEFAULT.TRASH);
            const calShareFolder = data.filter(x => x.perm !== undefined);
            const calData = calFolderDefaut.concat(calFolder.concat(calTrashFolder.concat(calShareFolder)));

            state.calTreeFolders = calData
        },
        // Set dữ liệu cây của lịch
        SET_IS_OPEN_CAL_TRASH(state, data){
            state.isOpenCalTrash = data
        },
        // Hàm hiện thị các tiêu chí đã chọn để tìm kiếm tìm kiếm
        SET_IS_VISIBLE_CAL_SEARCH_FORM(state, data){
            state.isVisibelCalSearchForm = data
            if(!data){
                state.listAppointmentSearch = [];
            }
        },
        // Hàm thiết định thông tin tìm kiếm
        SET_CAL_FORM_SEARCH(state, data){
            state.calFormSearch = data
        },
        // Hàm thiết định thông tin tìm kiếm
        SET_LST_EVENT_SEARCH(state, data){
            state.lstEventSearch = data
        },
        // Hàm thiết định thông tin tìm kiếm
        SET_LST_CAL_QUERY_SEARCH(state, data){
            state.lstCalQuerySearch = data
        },
        // Hàm thiết định thông tin tìm kiếm danh sách cảnh báo lịch
        SET_LIST_APPOINMENT(state, data){
            state.listAppoinment = data
        },
        // Hàm thiết định thông tin danh sách cảnh báo
        SET_LIST_APPOINMENT_WARNING(state, data){
            const currentData = state.listAppoinmentWarning;
            if (data && data.length > 0) {
                data.forEach(obj => {
                    const temp = currentData.findIndex(x => x.id === obj.id);
                    if (temp < 0) {
                        currentData.push(obj);
                    }
                })
            }
            state.listAppoinmentWarning = currentData;
        },
        // Hàm thiết định danh sách danh mục lịch
        SET_LIST_CALENDAR_ID(state, data){
            state.listCalendarId = data;
        },
        // Hàm thiết định danh sách danh mục lịch
        SET_LST_ACCOUNT_ZIMLET_WEBEX_ZIMLET(state, data){
            state.lstAccountWebExZimlet = data;
        },
        // Hàm thiết cuộc hẹn đang thao tác hiện tại
        SET_ACTIVE_APPOINTMENT(state, data){
            state.activeAppointment = data;
        },
        // Hàm thiết định dữ liệu preferences
        SET_PREFERENCES_ZIMLET_WEBEX_ZIMLET(state, data){
            state.formPreferencesWebExZimlet = data;
        },
        // Thông tin lịch trình
        SET_INFO_SCHEDULES(state, data){
            state.infoSchedules = data;
        },
        SET_SEARCH_CAL_ERROR_MESSAGE(state, data) {
            state.searchCalErrorMessage = data;
        },

        SET_IS_SHOW_INFO_CAL_FOLDER(state, data){
            state.isShowInfoCalFolder = data
        },
    }
}
export default calendarModule;
