<template>
    <table class="grid-table grid-table-all-attendees">
        <tr class="normal-row">
            <td v-for="(item, index) in computedItems" :key="index" width="2%" :class="item.status + (item.isWorkingHour ? ' is-working-hour' : '')" class="zm-scheduler">
                <el-popover
                    placement="bottom"
                    width="500"
                    trigger="hover"
                    :open-delay="1000"
                    :popper-class="schedules && schedules.length > 0  ? 'zm-popover-info-schedules' : 'width-fit-content'"
                    :disabled="!item.status"
                    @show="onShowPopoverInfoSchedule(item.startTime)"
                >
                    <PopoverInfoSchedule
                        v-if="schedules && schedules.length > 0"
                        :schedules="schedules"
                    >
                    </PopoverInfoSchedule>
                    <template v-else>
                        <b>{{ item.statusText }}</b>
                    </template>
                    <div
                        slot="reference"
                        class="grid-div"
                        :class="{
                            'half-hour': item.halfHour,
                            'is-start': item.isStart,
                            'is-end': item.isEnd,
                            'is-first-row': isFirstRow
                        }"
                        :attr-start-hour="item.startTime.format('h')" 
                        :attr-end-hour="item.endTime.format('h')" 
                        :attr-maa="item.startTime.format('A')"
                    >
                    </div>
                </el-popover>
            </td>
        </tr>
    </table>
</template>
<script>
import CalendarUtils from "@/utils/calendar-utils";
import CommonUltils from "@/utils/common-utils";
import moment from "moment";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import PopoverInfoSchedule from "./popover-info-schedule.vue";
// import _ from "lodash";
import { mapGetters } from "vuex";
import {
    DATASOURCE_FREE_BUSY_FULL,
    CALENDAR_FREE_BUSY_TYPE,
    CAL_PARTICIPATION_STATUS
} from "@/utils/constants.js";
/**
 {
    displayName: tên hiển thị
    resouceType: 1: Người tham dự bắt buộc, 2: Người tham dự tùy chọn, 3: Địa điểm, 4: Thiết bị
    email: //email
    status: trạng thái 1: Rảnh, 2: Không làm việc, 3: Bận, 4: Chưa dứt khoát, 5: Không xác định, 6: Ra khỏi văn phòng,
    isEditable: cờ có thể sửa true: có thể sửa, false: không thể sửa
 }
 */
export default {
    name: 'DetailScheduleGridTable',
    components: {
        PopoverInfoSchedule
    },
    mixins: [CalendarUtils, CommonUltils],
    props: {
        allWorkingHours: {
            type: Object,
            default: function() {
                return {};
            }
        },
        colStartTime: {
            type: Object
        },
        colEndTime: {
            type: Object
        },
        startTime: {
            type: Object
        },
        endTime: {
            type: Object
        },
        resource: {
            type: Object
        },
        isFirstRow: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
        // workingHours: {
        //     type: Object,
        //     default: function() {
        //         return {};
        //     }
        // },
        excludeUid: {
            type: String
        },
        timeOrigin: {
            type: Array,
            default: function () {
                return [];
            }
        },
    },
    data() {
        return {
            DATASOURCE_FREE_BUSY_FULL: DATASOURCE_FREE_BUSY_FULL,
            NEXT_STEP_IN_MINUTES: 30,
            computedItems: [],
            isShowPopoverInfoSchedule: false,
            schedules: [],
            workingHours: {}
        };
    },
    computed: {
        ...mapGetters([
            "infoSchedules",
            "emailAddress",
        ]),
    },
    async created() {
    },
    mounted() {
        this.$watch(vm => [vm.colStartTime, vm.startTime, vm.workingHours], () => {
                this.handleComputedItems();
            },
            {
                immediate: true, // run immediately
                deep: true // detects changes inside objects. not needed here, but maybe in other cases
            }
        );
    },
    destroyed() {
    },
    watch: {
        allWorkingHours(val) {
            this.workingHours = val[this.resource.a];
        },
        resource(newValue, oldValue) {
            if ((!oldValue.a && newValue.a) || (!oldValue.a && !newValue.a) || (newValue.a && oldValue.a && oldValue.a != newValue.a)) {
                this.handleComputedItems();
            } else if (!newValue.a) {
                this.computedItems = this.step1CreateItems(this.workingHours);
                this.resource.items = this.computedItems;
            }
        }
    },
    asyncComputed: {
        // async computedItems() {
        //     if (!this.colStartTime || !this.startTime) {
        //         return [];
        //     }
        //     let items = this.step1CreateItems(this.workingHours);
        //     if (this.resource && this.resource.email) {
        //         items = await this.step2FetchFreeBusyDataItems(items);
        //     }
        //     // gửi thông báo ra ngoài update đã tạo xong dữ liệu
        //     this.$emit('onComputedItemsChange', items);
        //     return items;
        // }
    },
    methods: {
        async handleComputedItems() {
            if (!this.colStartTime || !this.startTime) {
                this.computedItems = [];
            }
            let items = this.step1CreateItems(this.workingHours);
            if (this.resource && this.resource.a) {
                items = await this.step2FetchFreeBusyDataItems(items);
            }
            // gửi thông báo ra ngoài update đã tạo xong dữ liệu
            this.$emit('onComputedItemsChange', items);
            this.resource.items = items;
            this.computedItems = items;
        },
        async step2FetchFreeBusyDataItems(items) {
            const uid = this.resource.a;
            const s = this.colStartTime.valueOf().toString();
            const e = this.colEndTime.valueOf().toString();
            const formData = { uid: uid, s: s, e: e };
            if (this.excludeUid) {
                formData.excludeUid = this.excludeUid;
            }
            const res = await ZimbraMailService.getFreeBusyRequest(formData);
            let { usr } = this.getResponseBody(res).GetFreeBusyResponse;
            this.resource.fetched = this.resource.fetched || {};
            this.resource.fetched.freeBusy = usr;
            this.loading = false;
            // start xử lý tính free busy cho từng dòng
            const rangeItems = [];
            let mustSelected = false;
            for (let i in items) {
                let check = this.calculateStatus(items[i], usr.f);
                if (check) {
                    items[i].status = CALENDAR_FREE_BUSY_TYPE.FREE;
                    if (this.isWorkingHour(items[i], this.workingHours)) {
                        items[i].statusText = `${this.$t("zimbra.zmMsg.attendeeStatusLabel")} ${this.$t("zimbra.zhMsg.free")}`;
                    } else {
                        items[i].statusText = `${this.$t("zimbra.zmMsg.attendeeStatusLabel")} ${this.$t("zimbra.zmMsg.nonWorking")}`;
                    }
                }

                check = this.calculateStatus(items[i], usr.u);
                if (check) {
                    items[i].status = CALENDAR_FREE_BUSY_TYPE.OUT_OF_OFFICE;
                    items[i].statusText = `${this.$t("zimbra.zmMsg.attendeeStatusLabel")} ${this.$t("zimbra.zhMsg.outOfOffice")}`;
                }

                // check = this.calculateStatus(items[i], usr.n);
                // if (check) {
                //     items[i].status = CALENDAR_FREE_BUSY_TYPE.NON_WORKING;
                //     items[i].statusText = `${this.$t("zimbra.zmMsg.attendeeStatusLabel")} ${this.$t("zimbra.zmMsg.nonWorking")}`;
                // }

                check = this.calculateStatus(items[i], usr.t);
                if (check) {
                    items[i].status = CALENDAR_FREE_BUSY_TYPE.BUSY_TENTATIVE;
                    items[i].statusText = `${this.$t("zimbra.zmMsg.attendeeStatusLabel")} ${this.$t("zimbra.zhMsg.apptPtstTE")}`;
                }
                if (this.timeOrigin && this.timeOrigin.length > 0 && this.resource.isEdit && this.resource.ptst == CAL_PARTICIPATION_STATUS.ACCEPT) {
                    usr.b = Array.isArray(usr.b) ? usr.b : (usr.b ? [usr.b] : []);
                    usr.b.push({
                        s: this.timeOrigin[0].toDate().getTime(),
                        e: this.timeOrigin[1].toDate().getTime(),
                    });
                }
                check = this.calculateStatus(items[i], usr.b);
                if (check) {
                    items[i].status = CALENDAR_FREE_BUSY_TYPE.BUSY;
                    items[i].statusText = `${this.$t("zimbra.zmMsg.attendeeStatusLabel")} ${this.$t("zimbra.zhMsg.busy")}`;
                }

                check = this.calculateStatus(items[i], usr.n);
                if (check) {
                    items[i].status = CALENDAR_FREE_BUSY_TYPE.UNKNOWN;
                    items[i].statusText = `${this.$t("zimbra.zmMsg.attendeeStatusLabel")} ${this.$t("zimbra.zmMsg.mailSizeUnknown")}`;
                }
                // thêm logic tính xem resouce có bận trong khoảng thời gian đã chọn không?
                if (mustSelected) {
                    rangeItems.push(items[i]);
                    if (items[i].isEnd) {
                        mustSelected = false;
                    }
                }
                mustSelected = mustSelected || items[i].isStart;
            }
            let isInvalidTime = false;
            // const INVALID_TIME = [CALENDAR_FREE_BUSY_TYPE.BUSY, CALENDAR_FREE_BUSY_TYPE.BUSY_TENTATIVE, CALENDAR_FREE_BUSY_TYPE.OUT_OF_OFFICE];
            for (let i in rangeItems) {
                isInvalidTime = isInvalidTime || this.isBusySchedule(rangeItems[i].status);
                if (isInvalidTime) {
                    break;
                }
            }
            this.resource.isInvalidTime = isInvalidTime;
            // thay đổi resouce tại component cha
            this.$emit('onItemChange', this.resource);
            return items;

        },
        step1CreateItems(workingHours) {
            const items = [];
            let iTime = this.colStartTime.clone();
            while (!iTime.isAfter(this.colEndTime)) {

                const startTime = iTime.clone();
                const endTime = iTime.add(this.NEXT_STEP_IN_MINUTES, 'minutes').clone();
                const isStart = CalendarUtils.methods.checkIsBetweenTime(endTime.startOf('minute')
                            , this.startTime.clone().add(0 - this.NEXT_STEP_IN_MINUTES, 'minutes').startOf('minute')
                            , this.startTime.clone().endOf('minute')
                            );
                const isEnd = CalendarUtils.methods.checkIsBetweenTime(this.endTime.startOf('minute')
                            , startTime.clone().startOf('minute')
                            , endTime.clone().endOf('minute')
                            , undefined, "(]");
                const item = {
                    startTime: startTime,
                    endTime: endTime,
                    halfHour: startTime.minutes() == 0,
                    isStart: isStart,
                    isEnd: isEnd,
                    isWorkingHour: null
                };
                item.isWorkingHour = this.isWorkingHour(item, workingHours);

                items.push(item)
            }
            return items;
        },
        isWorkingHour(item, workingHours) {
            if (!workingHours) {
                return false;
            }
            let check = false;
            if (workingHours.f) {
                check = this.calculateStatus(item, workingHours.f);
                if (check) {
                    return true;
                }
            }
            if (workingHours.n) {
                check = this.calculateStatus(item, workingHours.n);
                if (check) {
                    return true;
                }
            }
            return false;
        },
        calculateStatus(item, checkTime) {
            if (checkTime) {
                if (Array.isArray(checkTime)) {
                    for (let i in checkTime) {
                        const ft = checkTime[i];
                        if (this.checkIsConflictTime(item.startTime, item.endTime, moment(ft.s), moment(ft.e))) {
                            return true;
                        }
                    }
                } else {
                    if (this.checkIsConflictTime(item.startTime, item.endTime, moment(checkTime.s), moment(checkTime.e))) {
                        return true;
                    }
                }
            }

            return false;
        },
        mouseover(evt, startTime, endTime) {
            this.$emit("mouseover", { event: evt, resource: this.resource, startTime: startTime, endTime: endTime });
        },
        mouseleave(evt, startTime, endTime) {
            this.$emit("mouseleave", { event: evt, resource: this.resource, startTime: startTime, endTime: endTime });
        },
        /**
         * Tính thời gian bắt đầu, thời gian kết thúc
         */
        computedAppts(appts) {
            if (!appts) {
                return [];
            }
            return appts.map(e => {
                e.inst = Array.isArray(e.inst) ? e.inst[0] : e.inst;
                const start = moment(e.inst.s);
                const end = start.clone().add(e.dur, 'milliseconds');
                return {
                    ...e,
                    startTime: start,
                    endTime: end,
                    ptstText: this.getParticipationStatusText(e.ptst),
                    fbText: this.getFreeBusyText(e.fb),
                    recurClass: e.recur ? "ic-reload-blue" : (e.recur === false ? "ic-reload-red" : "")
                }
            });
        },
        /**
         * call api lấy thông tin lịch trình
         */
        async onShowPopoverInfoSchedule(startTime) {
            const resource = this.resource;
            // const startTime = this.startTime;
            // const endTime = this.endTime;
            
            if (!resource.a) {
                return;
            }
            const _start = this.startOfDay(this.startTime);
            const calExpandInstStart = _start.toDate().getTime().toString();
            const calExpandInstEnd = _start.add(1, 'day').toDate().getTime().toString();
            const appointInDate = this.infoSchedules?.find(e => e.a == resource.a && e.calExpandInstStart == calExpandInstStart);
            if (!appointInDate) {
                const _infoSchedules = this.infoSchedules || [];
                let query = "";
                if (resource.a != this.emailAddress) {
                    const formGetShareInfo = {
                        owner: {
                            by: "name",
                            content: resource.a
                        }
                    };
                    const shareInfo = await ZimbraMailService.getShareInfoRequest(formGetShareInfo);
                    let { share } = this.getResponseBody(shareInfo).GetShareInfoResponse;
                    share = share ? (Array.isArray(share) ? share : [share]) : [];
                    query = share.map(e => `inid:"${e.ownerId}:${e.folderId}"`);
                } else {
                    query = this.datasourceCalendarFolder.map(e => `inid:"${e.id}"`)
                }
                // call api search appoint
                const formData = {
                    sortBy: "none",
                    limit: "500",
                    calExpandInstStart: calExpandInstStart,
                    calExpandInstEnd: calExpandInstEnd,
                    types: "appointment",
                    offset: 0,
                    query: query.join(" OR ")
                };
                const res = await ZimbraMailService.searchRequest(formData);
                let { appt } = this.getResponseBody(res).SearchResponse;
                appt = appt ? (Array.isArray(appt) ? appt : [appt]) : [];
                appt = this.computedAppts(appt);
                _infoSchedules.push({
                    a: resource.a,
                    calExpandInstStart: calExpandInstStart,
                    appt: appt,
                });
                this.$store.commit("SET_INFO_SCHEDULES", _infoSchedules);
                this.schedules = appt.filter(e => this.isBetween(e.startTime, e.endTime, startTime) && this.isBusySchedule(e.fb));
            } else {
                this.schedules = appointInDate.appt.filter(e => this.isBetween(e.startTime, e.endTime, startTime) && this.isBusySchedule(e.fb));
            }
            
        },
        isBetween(startDate, endDate, compareDate) {
            return compareDate.isBetween(startDate.clone().startOf('minute'), endDate.clone().startOf('minute'), undefined, "[]");
        },
        isBusySchedule(fb) {
            return [CALENDAR_FREE_BUSY_TYPE.BUSY_TENTATIVE, CALENDAR_FREE_BUSY_TYPE.BUSY, CALENDAR_FREE_BUSY_TYPE.OUT_OF_OFFICE].includes(fb);
        }
    },
};
</script>