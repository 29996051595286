<template>
    <section :class="['app-wrapper drag-wrapper', menuCollapse ? 'menu-collapse' : null]">
        <Sidebar />
        <main class="main-content__wrapper zm-unselect">
            <NavbarMailBox />
            <slot />
        </main>
        <SideBarRight />
    </section>
</template>

<script>
import Sidebar from "@/components/modules/layouts/Sidebar";
import SideBarRight from "@/components/modules/layouts/SideBarRight";
import NavbarMailBox from "@/components/modules/layouts/NavbarMailBox";
import CommonUtils from "@/utils/common-utils";
import { mapGetters } from "vuex";
export default {
    components: {
        SideBarRight,
        Sidebar,
        NavbarMailBox,
    },
    mixins: [CommonUtils],
    computed: {
        ...mapGetters([
            "menuCollapse"
        ]),
        // ...mapGetters(["tags"]),
    },
    mounted() {
        // this.login(); // Fix bug login trên safari
    },
    methods: {
        login() {
            const { origin } = window.location;
            const authToken = this.getLocalStorageObject("zimbra_token");
            // if (process.env.NODE_ENV === "development") {
            //     if (!authToken) {
            //         window.location.href = `${origin}/${this.$configZimbra.LOGIN_PAGE}`;
            //     }
            // } else if (!this.doesHttpOnlyCookieExist("ZM_AUTH_TOKEN")) {
            //     window.location.href = `${origin}/${this.$configZimbra.LOGIN_PAGE}`;
            // }

            
        },
    },
};
</script>

<style lang="scss">
.app-wrapper {

    // display: flex;
    & .main-content__wrapper {
        width: calc(100% - 250px - 64px);

        @media screen and (max-width: 1280px) {
            width: calc(100% - 200px - 54px);
        }
    }
}
</style>
