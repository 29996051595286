<template>
    <el-dialog
        class="dialog-body-wrap zimbra-dialog"
        custom-class="zm-add-folder-calendar"
        :title="title"
        :visible.sync="isOpenDialog"
        width="500px"
        height="550px"
        :append-to-body="true"
        :close-on-click-modal="false"
        id="addCalendarFolder"
    >
        <el-form class="form-wrap">
             <div class="row pt-2">
                <div class="col-2 zm-label">
                    {{ $t('zimbra.zhMsg.nameLabel') }}
                </div>
                <div class="col-9">
                    <el-input
                        class="el-text-control"
                        v-model="formAdd.name"
                        autocomplete="off"
                        size="medium"
                         @change="handleChange"
                        ref="refInputName"
                        @keydown.native.13="handleKeyAddCalendar"
                    ></el-input>
                </div>
             </div>
             <div class="row pt-2">
                <div class="col-2 zm-label">
                    {{ $t('zimbra.zmMsg.colorLabel') }}
                </div>
                <div class="col-9">
                    <zm-color-picker
                        v-model="formAdd.rgb"
                        :visible="false"
                        :showFooter="true"
                    >
                    </zm-color-picker>
                </div>
             </div>
             <div class="row pt-2">
                <div class="col-2 zm-label">
                    <el-checkbox v-model="formAdd.isExcludeFromFreeBusy" @keydown.native.13="handleKeyAddCalendar"></el-checkbox>
                </div>
                <div class="col-9 mt-2">
                    <span>{{$t("zimbra.zhMsg.excludeFromFreeBusyTimes")}}</span>
                </div>
             </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button v-if="Object.keys(formExternal).length > 0" type="primary" @click="onBackCalendar" class="btn-back">{{
                $t("zimbra.zhMsg.back")
            }}</el-button>
            <el-button @click="isOpenDialog = false">{{
                $t("zimbra.zhMsg.actionTaskCancelTT")
            }}</el-button>
            <el-button type="primary" @click="onAddCalendar">{{
                $t("zimbra.zmMsg.mobileStatusNeedProvision")
            }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import CalendarUtils from "@/utils/calendar-utils";
import ZmColorPicker from "@/components/modules/base/base-color-picker-advance/zm-color-picker";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import _ from "lodash";
import { ZmSetting, STATUS_REQUEST, ROOT_CALENDAR_FOLDER_ID, FOLDER_VIEW, ERROR_CODE } from "@/utils/constants";
import hotkeys from "hotkeys-js"
export default {
    mixins: [CommonUtils, CalendarUtils],
    mounted() {
        this.$root.$on("onOpenAddCalendar", (parentFolderId, type, formExternalCal) => {
            if (formExternalCal != undefined) {
                this.externalType = type
                this.title = this.$t('zimbra.zmMsg.addExternalCalendar')
                this.formExternal = { ...formExternalCal };
            }
            this.formAdd.name = "";
            this.formAdd.rgb = "#3F4254";
            this.formAdd.isExcludeFromFreeBusy = "";
            this.formAdd.parentFolderId = parentFolderId ?? 1
            this.isOpenDialog = true
            // focus vào input Tên khi mở dialog tạo lịch mới
            setTimeout(() => {
                this.$refs.refInputName.focus();
            }, 500);
        });

        // Nhấn Enter để lưu
        hotkeys("enter","addApoinment", this.handleKeyAddCalendar)
        hotkeys.setScope("addApoinment");
    },
    destroyed() {
        this.$root.$off("onOpenAddCalendar");
        hotkeys.deleteScope("addApoinment");
    },
    computed: {
    },
    components: {
        "zm-color-picker": ZmColorPicker
    },
    data() {
        return {
            // for add
            formAdd: {
                name: "",
                rgb: "#3F4254",
                isExcludeFromFreeBusy: "",
                parentFolderId: "",
            },
            title: this.$t('zimbra.zhMsg.createNewCalendar'),
            formExternal: {},
            isOpenDialog: false,
            externalType: "",
        };
    },
    methods: {
        /**
         * hàm check tên lịch bị trùng
         */
        checkExistsName() {
            let isValid = true;
            const lstCalendar = this.$store.getters.calTreeFolders;
            const nodeCalContain = this.searchNodeById({ id: 1, folder: lstCalendar }, this.formAdd.parentFolderId)
            const data = nodeCalContain.folder;
            if (data.length > 0) {
                for (let key in data) {
                    const item = data[key];
                    if (this.formAdd.name.toString() === item.name.toString() ||
                        this.formAdd.name.toLowerCase() === this.$t("zimbra.zhMsg.ALT_APP_CALENDAR").toLowerCase() ||
                        this.formAdd.name.toLowerCase() === this.$t("zimbra.zhMsg.FOLDER_LABEL_3").toLowerCase()
                    ) {
                        isValid = false;
                        break;
                    } else if (this.formAdd.name.toLowerCase() === "trash" || this.formAdd.name.toLowerCase() === "calendar") {
                        isValid = false;
                        break;
                    }
                }
            }
            return isValid;
        },

        /**
         * Validate trước khi save
         */
        validateBeforeSave() {
            let isValid = true;
            let contentError = "";
            // validate chưa nhập tên
            if (!this.formAdd.name || this.formAdd.name.trim().length === 0) {
                isValid = false;
                contentError = this.$t("zimbra.zmMsg.nameEmpty")
            } else if (this.formAdd.name.length > 128 || this.formAdd.name.indexOf("/") > -1) {
                const nameError = _.escape(this.formAdd.name);
                isValid = false;
                contentError = this.formAdd.name.length > 128
                    ? this.$t("zimbra.zhMsg.nameTooLong", { 0: 128 })
                    : this.$t(
                        "zimbra.zmMsg.errorInvalidName",
                        { 0: nameError }
                    );
            } else if (!this.checkExistsName()) {
                isValid = false
                if (this.formAdd.parentFolderId === ROOT_CALENDAR_FOLDER_ID) {
                    if (this.formAdd.name.toLowerCase() === this.$t("zimbra.zhMsg.ALT_APP_CALENDAR").toLowerCase()
                        || this.formAdd.name.toLowerCase() === this.$t("zimbra.zhMsg.FOLDER_LABEL_3").toLowerCase()
                    ) {
                        contentError = this.$t("zimbra.zmMsg.folderNameReserved")
                    } else if (this.formAdd.name.toLowerCase() === "trash") {
                        contentError = this.$t("zimbra.zmMsg.errorAlreadyExists", { 0: this.formAdd.name, 1: "folder" });
                    } else if (this.formAdd.name.toLowerCase() === "calendar") {
                        contentError = this.$t("zimbra.zmMsg.errorAlreadyExists", { 0: this.formAdd.name, 1: "calendar" });
                    } else {
                        contentError = this.$t("zimbra.zmMsg.errorAlreadyExists", { 0: this.formAdd.name, 1: this.$t("zimbra.zhMsg.ALT_APP_CALENDAR") });
                    }
                } else {
                    contentError = this.$t("zimbra.zmMsg.errorAlreadyExists", { 0: this.formAdd.name, 1: this.$t("zimbra.zhMsg.ALT_APP_CALENDAR") });
                }
            }
            // Hiển thị thông báo lỗi
            if (!isValid) {
                this.$confirm(
                    contentError,
                    this.$t("zimbraNokey.zimbra_compose_criticalMsg"),
                    {
                        showCancelButton: false,
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "error",
                    }
                )
                    .then(() => {
                        setTimeout(() => {
                            this.$refs.refInputName && this.$refs.refInputName.focus();
                        }, 500);
                    })
                    .catch(() => { });
            }
            return isValid;
        },

        handleBuildDataSourceCalendarXml(formData) {
            let _body = ""
            for (const key in formData) {
                _body += ` ${key}='${formData[key]}'`
            }
            const _a ='p:/calendar/dav/_USERNAME_/user'
            const xml = `<urn1:caldav ${_body}> <urn1:a n="zimbraDataSourceAttribute">${_a}</urn1:a></urn1:caldav>`
            return xml
        },

        /**
         * hàm thêm mới calendar
         */
        async onAddCalendar() {
            if (!this.validateBeforeSave()) {
                return;
            }
            this.formAdd.name = _.escape(this.formAdd.name)
            if (Object.keys(this.formExternal).length > 0) {
                //call api thêm lịch ngoài
                const formData = {}
                const formAddCal = {}
                // add form Them
                formAddCal['name'] = this.formAdd.name;
                formAddCal['f'] = (this.formAdd.isExcludeFromFreeBusy ? "b" : "") + "#";
                formAddCal['l'] = this.formAdd.parentFolderId ? this.formAdd.parentFolderId.toString() : ROOT_CALENDAR_FOLDER_ID;
                formAddCal['rgb'] = this.formAdd.rgb ? this.formAdd.rgb.toString() : "";
                formAddCal['view'] = FOLDER_VIEW.APPOINTMENT;
                if (!this.formExternal.isCalDAV) {
                    formAddCal['url'] = this.formExternal.url
                }

                formData['name'] = this.formAdd.name
                formData['isEnabled'] = 1
                formData['host'] = this.formExternal.host
                formData['port'] = '443'
                formData['username'] = this.formExternal.username
                formData['password'] = this.formExternal.password
                formData['connectionType'] = ZmSetting.SSL
                formData['pollingInterval'] = "1m"
                // formData["caldav"] = caldav
                await ZimbraMailService.createFolderRequest(formAddCal).then((res) => {
                    if (res.status == STATUS_REQUEST.SUCCESS) {
                        const itemAddResponse =
                        this.getResponseBody(res)["CreateFolderResponse"];
                        formData['l'] = itemAddResponse.folder.id;
                        // Cập nhật lại dữ liệu cây
                        this.getCalFolder().then(calFolderRes  => {
                            this.$store.commit('SET_CAL_TREE_FOLDER', calFolderRes);
                            this.$store.dispatch("getTreeFolder")
                        });
                    }
                }).catch((err) => {
                    this.isOpenDialog = false;
                    const { detail } = this.getResponseBody(err.response)["soap:Fault"];
                    if (detail.Error.Code) {
                        if (detail.Error.Code == ERROR_CODE.SERVICE_RESOURCE_UNREACHABLE) {
                            this.$confirm(
                                this.$t("zimbra.zmMsg.feedUnreachable", [this.formExternal.url]),
                                this.$t("zimbraNokey.zimbra_common_criticalMsg"),
                                {
                                    showConfirmButton: false,
                                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                                    type: "error",
                                }
                            )
                                .then(() => { })
                                .catch(() => { });
                        }
                    }
                });
                if (this.formExternal.isCalDAV) {
                    await ZimbraMailService.createCalendarDataSourceRequest(this.handleBuildDataSourceCalendarXml(formData)).then(async resp => {
                        const respJson = this.getResponseBody(resp)
                        const dataSources = respJson["CreateDataSourceResponse"]
                        if (dataSources) {
                            this.$notify({
                                title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                                message: this.$t("zimbra.zmMsg.addExternalCalendarSuccess"),
                                type: "success",
                            });
                        }
                    }).catch(() => {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestFailure"),
                            message: this.$t("zimbra.zmMsg.addExternalCalendarError"),
                            type: "error",
                        });
                    })
                }
            } else {
                await this.createCalendarFolder(this.formAdd);

                // Cập nhật lại dữ liệu cây
                this.getCalFolder().then(calFolderRes  => {
                    this.$store.commit('SET_CAL_TREE_FOLDER', calFolderRes);
                    this.$store.dispatch("getTreeFolder")
                });
            }

            // Tắt popup
            this.isOpenDialog = false;
        },
        async onBackCalendar() {
            // Tắt popup
            this.isOpenDialog = false;
            this.$root.$emit('onOpenAddExternalCalendarItem', this.formAdd.parentFolderId, this.externalType, this.formExternal)
        },
        handleChange() {
            this.formAdd.name = this.formAdd.name.toString().trim();
        },

        /**
         * Phím tắt enter để thêm mới lịch
         */
        handleKeyAddCalendar(e){
            if(this.isOpenDialog) {
                e.preventDefault();
                this.onAddCalendar();
            }
        },
    },
};
</script>
<style scoped>
.btn-back {
    float: left !important;
    padding: 10px 12px;
}
</style>

