<template>
    <div>
        <el-dialog
            class="dialog-body-wrap zimbra-dialog xxx"
            :title="$t('zimbra.zmMsg.folderProperties')"
            :visible.sync="dialogEditProperties"
            width="500px"
            :append-to-body="true"
            :close-on-click-modal="false"
        >
            <el-form :model="formEdit" class="form-wrap">
                <el-row :gutter="20">
                    <el-col
                        :span="5"
                        style="
                            padding-right: 0px;
                            padding-left: 10px;
                            padding-top: 10px;
                        "
                    ><span>{{
                            $t("zimbra.zhMsg.nameLabel")
                        }}</span>
                    </el-col>
                    <el-col :span="15"
                    >
                        <el-input
                            class="el-text-control"
                            :disabled="disableEditName"
                            v-model="formEdit.name"
                            autocomplete="off"
                            id="input-edit-name-folder"
                            size="medium"
                        ></el-input
                        >
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col
                        :span="5"
                        style="
                            padding-right: 0px;
                            padding-left: 10px;
                            padding-top: 10px;
                        "
                    ><span>{{
                            $t("zimbra.zmMsg.sharedCalTypeLabel")
                        }}</span>
                    </el-col>
                    <el-col :span="15" style="padding-top: 10px">
                        <span>
                            {{ renderTypeFolder(treeNodeData.view) }}
                        </span>
                    </el-col>
                </el-row>
                 <el-row :gutter="20" v-if="treeNodeData.owner">
                            <el-col
                                :span="5"
                                style="
                                    padding-right: 0px;
                                    padding-left: 10px;
                                    padding-top: 10px;
                                "
                            >
                                <span>
                                    {{
                                        $t(
                                            "zimbra.zmMsg.ownerLabel"
                                        )
                                    }}
                                </span>
                            </el-col>
                            <el-col :span="19" style="padding-top: 10px"
                                ><span>{{ treeNodeData.owner }}</span></el-col
                            >
                </el-row>
                <el-row :gutter="20" v-if="treeNodeData.perm">
                            <el-col
                                :span="5"
                                style="
                                    padding-right: 0px;
                                    padding-left: 10px;
                                    padding-top: 10px;
                                "
                            >
                                <span>
                                    {{
                                        $t(
                                            "zimbra.zmMsg.permissions"
                                        )
                                    }}
                                </span>
                            </el-col>
                            <el-col :span="19" style="padding-top: 10px"
                                ><span>{{ getRoleFolder(treeNodeData.perm) }}</span></el-col
                            >
                </el-row>
                <el-row :gutter="20">
                    <el-col
                        :span="5"
                        style="
                            padding-right: 0px;
                            padding-left: 10px;
                            padding-top: 17px;
                        "
                    >
                        <span>{{
                            $t("zimbra.zmMsg.colorLabel")
                        }}</span>
                    </el-col>
                    <el-col :span="15" style="padding-top: 11px">
                        <template>
                            <div>
                                <zm-color-picker
                                    v-model="formEdit.rgb"
                                    class="zm-color-picker"
                                    :visible="false"
                                    :showFooter="true"
                                    :default-colors="[
                                        '#3F4254',
                                        '#0000FF',
                                        '#00FFFF',
                                        '#008000',
                                        '#FF00FF',
                                        '#FF0000',
                                        '#FFFF00',
                                        '#FF66FF',
                                        '#808080',
                                        '#FF9900',
                                    ]"
                                >
                                </zm-color-picker>
                            </div>
                        </template>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col
                        :span="5"
                        style="
                            padding-right: 0px;
                            padding-left: 10px;
                            padding-top: 10px;
                        "
                    >
                        <span> {{renderLabelTypeFolder(treeNodeData.view)}} </span>
                    </el-col>
                    <el-col :span="15" style="padding-top: 10px">
                        <span>{{ formEdit.mailNumber }}</span>
                    </el-col>
                </el-row>
                <el-row :gutter="20" v-if="treeNodeData.s > 0">
                    <el-col
                        :span="5"
                        style="
                            padding-right: 0px;
                            padding-left: 10px;
                            padding-top: 10px;
                        "
                    >
                        <span>
                            {{ $t("zimbra.zmMsg.fSizeLabel") }}
                        </span>
                    </el-col>
                    <el-col :span="15" style="padding-top: 10px">
                        <span>{{ formatSize(treeNodeData.s, true) }}</span>
                    </el-col>
                </el-row>
            </el-form>
            <!-- dialog thu hồi chia sẻ -->
            <el-dialog
                class="dialog-body-wrap zimbra-dialog"
                :title="$t('zimbraNokey.zimbra_sidebar_dialogReGain_title')"
                :visible.sync="dialogReGain"
                width="470px"
                :append-to-body="true"
            >
                <el-form class="form-wrap">
                    <b>{{
                        $t("zimbraNokey.zimbra_sidebar_dialogReGain_confirmReGain", {
                        name: rowShare.name,
                        })
                        }}</b>
                    <div style="margin-left: 20px; margin-top: 10px" v-if="formShare.optionShare !=='pub'">
                        <el-select
                            size="medium"
                            v-model="formShare.optionMessage"
                            :placeholder="
                                $t(
                                    'zimbraNokey.zimbra_zmMsg_select'
                                )
                            "
                            @change="changeOptionMessage"
                        >
                            <el-option
                                value="1"
                                v-show="showRole"
                                :label="
                                    $t(
                                        'zimbra.zmMsg.sendNoMailAboutShare'
                                    )
                                "
                            >
                            </el-option>
                            <el-option
                                value="2"
                                :label="
                                    $t(
                                        'zimbra.zmMsg.sendStandardMailAboutShare'
                                    )
                                "
                            >
                            </el-option>
                            <el-option
                                value="3"
                                :label="
                                    $t(
                                        'zimbra.zmMsg.sendStandardMailAboutSharePlusNote'
                                    )
                                "
                            >
                            </el-option>
                        </el-select>
                        <div
                            v-show="showNote"
                            style="
                                width: 26em;
                                display: block;
                                padding-top: 10px;
                            "
                        >
                            <b>{{ $t("zimbra.zmMsg.noteLabel") }}</b>
                            {{ $t("zimbraNokey.zimbra_sidebar_dialogReGain_noteContent") }}
                        </div>
                        <div v-show="showAddNote">
                            <el-input
                                type="textarea"
                                :rows="3"
                                v-model="formShare.note"
                                style="width: 26em; display: inline-block"
                                class="show-add-note-textarea"
                            ></el-input>
                        </div>
                    </div>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogReGain = false">{{
                        $t("zimbra.zhMsg.actionTaskCancelTT")
                    }}</el-button>
                    <el-button type="primary" @click="regain">{{
                        $t("zimbra.zmMsg.mobileStatusNeedProvision")
                    }}</el-button>
                </span>
            </el-dialog>
            <!-- danh sách email được chia sẻ -->
            <div
                v-show="showEmailShare"
                style="
                    border: 1px solid rgb(242, 242, 242);
                    padding: 5px;
                    margin: 5px;
                    margin-top: 20px;
                "
            >
                <div
                    style="
                        width: 156px;
                        height: 35px;
                        background: white;
                        margin-top: -17px;
                        font-weight: 600;
                    "
                >
                    <span>{{
                        $t("zimbra.zmMsg.folderSharing")
                    }}</span>
                </div>
                <el-table
                    :data="listShare"
                    :show-header="false"
                    :fit="true"
                    class="table-wrap zm-table__edit-properties"
                    row-class-name="table-row__wrap"
                >
                    <el-table-column
                        prop="name"
                        label=""
                        width="auto"
                        height="auto"
                    >
                    </el-table-column>
                    <el-table-column prop="role" label="" width="auto">
                    </el-table-column>
                    <el-table-column prop="actione" label="" width="170">
                        <template slot-scope="scope">
                            <div v-if="scope.row.gt == 'pub'" style="margin-left: 25px; padding: 10px">
                                <el-button
                                    type="text"
                                    size="medium"
                                    @click="clickRegain(scope.row)"
                                >{{ $t("zimbra.zmMsg.revoke") }}
                                </el-button>
                            </div>
                            <div v-else>
                                <el-button
                                    type="text"
                                    size="medium"
                                    @click="editShare(scope.row)"
                                >
                                    {{ $t("zimbra.zhMsg.edit") }}
                                </el-button>
                                <el-button
                                    type="text"
                                    size="medium"
                                    @click="clickRegain(scope.row)"
                                >{{ $t("zimbra.zmMsg.revoke") }}
                                </el-button>
                                <el-button
                                    type="text"
                                    size="medium"
                                    @click="reSend(scope.row)"
                                >
                                    {{
                                    $t(
                                    "zimbra.zhMsg.resend"
                                    )
                                    }}
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button
                    :hidden="
                        !hasPermissionSpecifyFolder(
                            treeNodeData,
                            RIGHTS_FOLDER.ADMINISTER
                        )
                    "
                    class="btn-outline float-left"
                    v-if="showButtonShare"
                    @click="$root.$emit('handleSidebarActionShare', {}, false)"
                >
                    {{ $t("zimbraNokey.zimbra_sidebar_dialogEditFolder_buttonAddShare") }}
                </el-button>
                <el-button
                    class="btn-email default"
                    @click="dialogEditProperties = false"
                >{{ $t("zimbra.zhMsg.actionTaskCancelTT") }}</el-button
                >
                <el-button
                    class="btn-email primary"
                    type="primary"
                    @click="editFolder"
                >{{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}</el-button
                >
            </span>
        </el-dialog>

        <!-- dialog error -->
        <el-dialog
            class="zimbra-dialog xxx"
            title="Zimbra"
            :visible.sync="errorVisible"
            :show-close="false"
            :close-on-click-modal="false"
            width="550px"
            top="35vh"
        >
            <div class="form-wrap" style="display: flex; align-items: center">
                <span class="icon-error"
                    ><i class="el-icon-circle-close" style="color: red; font-size:38px"></i
                ></span>
                <span class="text-description" style="margin-left: 10px">
                        {{ msgerror }}
                </span>
            </div>
            <div  v-if="showDetail == true" style="margin-left: 50px">
                <hr />
                <span>{{ detailError }}</span>
            </div>
           <span slot="footer" class="dialog-footer">
                <el-button v-if="!showDetail" class="btn-email default float-left" @click="showDetail = true">
                    {{ $t("zimbra.zhMsg.MO_showDetails") }}
                </el-button>
                <el-button v-else class="btn-email default float-left" @click="showDetail = false">
                    {{ $t("zimbra.zhMsg.MO_hideDetails") }}
                </el-button>
                <el-button
                    class="btn-email primary"
                    type="primary"
                    @click="errorVisible = false"
                >{{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import color from "./../../listColor";
import ZmColorPicker from "@/components/modules/base/base-color-picker-advance/zm-color-picker";
import { FOLDER_DEFAULT, RIGHTS_FOLDER, FOLDER_VIEW, READ_EMAIL_LAYOUT_ROUTER } from "@/utils/constants";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import AccountService from "@/services/account/AccountService";
import CompileService from "@/services/CompileService.js";
import _ from "lodash";
import $ from 'jquery';
import { mapGetters } from "vuex";
// import ZimbraMailService from "@/services/ZimbraMailService.js";
export default {
    mixins: [CommonUtils],
    mounted() {
        this.$root.$on("handleActionEditProperties", () => {
            this.show();
        });
        this.$root.$on("reloadDataFolder", (idFolder) => {
            this.getDetailFolder(idFolder);
        });
    },
    components: {
        "zm-color-picker": ZmColorPicker,
    },
    created() {
        this.getInfo();
    },
    computed: {
        ...mapGetters([
            "activeNodeSidebarRightClick",
            "activeNodeSidebar",
            "fromAddresses",
            "preference",
            "dataSidebarContact",
            "attributes"
        ]),
    },
    watch: {
        preference() {
            this.myAddresses = this.fromAddresses.find(x => x.uuid == this.preference.defaultFromAddress);
            this.myAddresses.d = this.attributes ? this.attributes.displayName : "";
        },
    },
    data() {
        return {
            dialogEditProperties: false,
            formEdit: {
                name: "",
                rgb: "#3F4254",
                mailNumber: "",
            },
            emailAddress: "",
            grantorId: "",
            emailOwnerName: "",
            showEmailShare: false,
            disableEditName: false,
            disableEditColor: false,
            treeNodeData: {},
            rowShare: {},
            dialogReGain: false,
            showButtonShare: true,
            idFolder: "",
            showAddNote: false,
            showNote: true,
            listShare: [],
            formShare: {
                note: "",
                optionMessage: "2",
            },
            listColor: color,
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            RIGHTS_FOLDER: RIGHTS_FOLDER,
            myAddresses: {},
            showRole: true,
            mapOperationRole: new Map([
                ["", this.$t("zimbra.zhMsg.no")],
                ["r", this.$t("zimbra.zhMsg.MO_view")],
                ["rwidx", this.$t("zimbraNokey.zimbra_setting_share_formShare_roleManager")],
                ["rwidxa", this.$t("zimbraNokey.zimbra_setting_share_formShare_roleAdmin")],
                ["rwidxc", this.$t("zimbraNokey.zimbra_setting_share_formShare_roleManager")],
                ["rwidxac", this.$t("zimbraNokey.zimbra_setting_share_formShare_roleAdmin")],
            ]),
            mapRole: new Map([
                ["", this.$t("zimbra.zhMsg.no")],
                ["r", this.$t("zimbra.zmMsg.shareRoleViewer")],
                ["rwidx", this.$t("zimbra.zmMsg.shareRoleManager")],
                ["rwidxc", this.$t("zimbra.zmMsg.shareRoleManager")],
                ["rwidxa", this.$t("zimbra.zmMsg.shareActionAdmin")],
                ["rwidxac", this.$t("zimbra.zmMsg.shareActionAdmin")],
            ]),
            currentName: "",
            errorVisible: false,
            msgerror: null,
            detailError: null,
            showDetail: false
        };
    },
    methods: {
        async getInfo() {
            const reponse = await ZimbraMailService.getInfoRequest({
                sections: "idents,sigs,mbox",
            });
            const infoResponse =
                this.getResponseBody(reponse)["GetInfoResponse"];
            const { name, id } = infoResponse;
            this.emailAddress = name;
            this.grantorId = id;
            const reponse1 = await AccountService.getAccountInfoRequest({
                username: name,
            });
            const infoResponse1 =
                this.getResponseBody(reponse1)["GetAccountInfoResponse"];
            this.emailOwnerName = infoResponse1.attr[2].content;
        },
        show() {
            this.showDetail = false;
            this.showAddNote = false;
            this.formShare = {
                note: "",
                optionMessage: "2",
            };
            this.dialogEditProperties = true;
            this.disableEditName = false;
            this.treeNodeData = this.activeNodeSidebarRightClick;
            this.showEmailShare = false;
            this.idFolder = this.treeNodeData.id;
            this.getDetailFolder(this.idFolder);
            for (let item in FOLDER_DEFAULT) {
                if (this.treeNodeData.id == FOLDER_DEFAULT[item]) {
                    this.disableEditName = true;
                    this.$nextTick(() => {
                        $(".color-input input").focus();
                    });
                    return;
                }
            }
            // Focus Tên
            $(document).ready(function () {
                setTimeout(function () {
                    $("#input-edit-name-folder").focus();
                }, 500);
            });
            const { name } = this.$route;
            if (name == READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE) {
                this.isMessage = true
            } else {
                this.isMessage = false
            }
        },
        async getDetailFolder(idFolder) {
            const form = {
                folder: `<folder><l>${idFolder}</l></folder>`,
            };
            const request = await ZimbraMailService.getFolderRequest(form);
            const folderResponse =
                this.getResponseBody(request)["GetFolderResponse"];
            let folder = folderResponse.folder || folderResponse.link;
            this.formEdit.name = this.treeNodeData
                ? this.treeNodeData.name
                : folder.name;
            this.currentName = this.treeNodeData
                ? this.treeNodeData.name
                : folder.name;
            this.formEdit.mailNumber = this.treeNodeData?.n;
            this.formEdit.rgb = "#3F4254";
            if (this.treeNodeData.rgb) {
                this.formEdit.rgb = this.treeNodeData.rgb;
            }
            if (this.treeNodeData.color) {
                this.formEdit.rgb = this.listColor.find(
                    (x) => x.colorNumeric == this.treeNodeData.color
                ).value;
            }
            // reload mail share
            if (
                !_.isNil(this.activeNodeSidebarRightClick) &&
                this.activeNodeSidebarRightClick.id == folder.id
            ) {
                const _activeNodeSidebarRightClick = _.cloneDeep(this.activeNodeSidebarRightClick)
                _activeNodeSidebarRightClick.acl = folder.acl;
                _activeNodeSidebarRightClick.n = folder.n;
                this.$store.commit(
                    "SET_ACTIVE_NODE_SIDEBAR_RIGHTCLICK",
                    _activeNodeSidebarRightClick
                );
            }
            this.listShare = [];
            if (folder.acl) {
                let listEmailShare = [];
                if (folder.acl.grant.length) {
                    listEmailShare = folder.acl.grant;
                } else {
                    listEmailShare.push(folder.acl.grant);
                }
                listEmailShare.forEach((item) => {
                    this.listShare.push({
                        name: this.getOwnerEmail(item.d ? item.d : item.zid),
                        role: this.mapRole.get(item.perm),
                        zid: item.zid,
                        perm: item.perm,
                        gt: item.gt,
                    });
                });
                this.listShare = this.listShare.filter(e => e.name !== this.myAddresses.a)
                if (this.listShare.length) this.showEmailShare = true;
            }
        },
        editShare(row) {
            this.isEditShare = true;
            const formShare = {};
            formShare.disableSharePub = true
            formShare.optionShare = row.gt;
            formShare.optionRole = row.perm;
            formShare.email = [];
            formShare.email.push(row.name);
            formShare.group = row.gt == "grp" ? true : false
            this.$root.$emit("handleSidebarActionShare", formShare, true);
        },
        clickRegain(row) {
            this.formShare = {
                note: "",
                optionMessage: "2",
            };
            if (row.gt == "guest") {
                this.showRole = false
            } else {
                this.showRole = true
            }
            this.formShare.optionShare = row.gt;
            this.rowShare = row;
            this.dialogReGain = true;
            this.changeOptionMessage();
        },
        async reSend(row) {
            const MapOperationRole = new Map([
                ["", this.$t("zimbra.zhMsg.no")],
                [this.$t("zimbra.zmMsg.shareRoleViewer"), this.$t("zimbra.zhMsg.MO_view")],
                [this.$t("zimbra.zmMsg.shareRoleManager"), this.$t("zimbraNokey.zimbra_setting_share_formShare_roleManager")],
                [this.$t("zimbra.zmMsg.shareActionAdmin"), this.$t("zimbraNokey.zimbra_setting_share_formShare_roleAdmin")],
            ]);
            row.role = this.mapRole.get(row.perm);
            const objectInput = {
                title: `${this.myAddresses.d} đã chia sẻ "${this.activeNodeSidebarRightClick.name}" với ${row.name}`,
                nameFolder: this.activeNodeSidebarRightClick.name,
                emailOwnerName: this.myAddresses.d,
                emailTo: row.name || row.d,
                emailFrom: this.myAddresses.a,
                isRegain: false,
                showNote: false,
                idFolder: this.activeNodeSidebarRightClick.id,
                granteeId: row.zid,
                grantorId: this.grantorId,
                perm: row.perm,
                note: this.formShare.note,
                role: row.role,
                operationRole: MapOperationRole.get(row.role),
            };
            if (this.formShare.optionMessage == "3") {
                objectInput.showNote = true;
            }
            const pEmailTo = objectInput.emailTo.substring(
                0,
                objectInput.emailTo.indexOf("@")
            );
            const pEmailFrom = objectInput.emailFrom.substring(
                0,
                objectInput.emailFrom.indexOf("@")
            );
            const mail = {
                su: _.escape(
                    `Chia sẻ được tạo: ${objectInput.nameFolder} được chia sẻ bởi ${objectInput.emailOwnerName}`
                ),
            };
            const e = [
                { t: "t", a: `${objectInput.emailTo}`, p: `${pEmailTo}` },
                { t: "f", a: `${objectInput.emailFrom}`, p: `${pEmailFrom}` },
            ];
            mail["e"] = e;
            mail["mp"] = this.buildMpContent(objectInput);
            const sendMsgRequest = await ZimbraMailService.sendMsgRequest({
                m: mail,
            });
            const sendMsgResponse =
                this.getResponseBody(sendMsgRequest)["SendMsgResponse"];
            if (sendMsgResponse?.m?.id) {
                if (this.isMessage) {
                    this.$message({
                        type: "success",
                        customClass: 'zm-notify',
                        duration: 3500,
                        dangerouslyUseHTMLString: true,
                        message: this.$t("zimbraNokey.zimbra_compose_sendSuccess")
                    });
                } else {
                    this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                        message: this.$t("zimbraNokey.zimbra_compose_sendSuccess"),
                        type: "success",
                    });
                }
                this.$emit("sendMsgSuccess");
            } else {
                // gửi thất bại
            }
        },
        async sentMsgRegain(row) {
            const emailFrom = this.myAddresses.a;
            const emailTo = row.name;
            const pEmailTo = emailTo.substring(0, emailTo.indexOf("@"));
            const pEmailFrom = emailFrom.substring(0, emailFrom.indexOf("@"));
            const objectInput = {
                title: `Lượt chia sẻ sau đã bị hủy bỏ: ${this.treeNodeData.name}`,
                nameFolder: this.treeNodeData.name,
                emailOwnerName: this.myAddresses.d,
                emailTo: row.name,
                emailFrom: this.myAddresses.a,
                isRegain: true,
                showNote: false,
                idFolder: this.treeNodeData.id,
                granteeId: row.zid,
                grantorId: this.grantorId,
                perm: row.perm,
                note: this.formShare.note,
                view: this.treeNodeData.view
            };
            if (this.formShare.optionMessage == "3") {
                objectInput.showNote = true;
            }
            const mail = {
                su: _.escape(
                    `Chia sẻ bị thu hồi: ${objectInput.nameFolder} được chia sẻ bởi ${objectInput.emailOwnerName || objectInput.emailFrom}`
                ),
            };
            const e = [
                { t: "t", a: `${emailTo}`, p: `${pEmailTo}` },
                { t: "f", a: `${emailFrom}`, p: `${pEmailFrom}` },
            ];
            mail["e"] = e;
            mail["mp"] = this.buildMpContent(objectInput);
            const sendMsgRequest = await ZimbraMailService.sendMsgRequest({
                m: mail,
            });
            const sendMsgResponse =
                this.getResponseBody(sendMsgRequest)["SendMsgResponse"];
            if (sendMsgResponse?.m?.id) {
                if (this.isMessage) {
                    this.$message({
                        type: "success",
                        customClass: 'zm-notify',
                        duration: 3500,
                        dangerouslyUseHTMLString: true,
                        message: this.$t("zimbraNokey.zimbra_compose_sendSuccess")
                    });
                } else {
                    this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                        message: this.$t("zimbraNokey.zimbra_compose_sendSuccess"),
                        type: "success",
                    });
                }
                this.$emit("sendMsgSuccess");
            } else {
                // gửi thất bại
            }
        },
        buildMpContent(objectInput) {
            const titleGrantee = objectInput.isRegain
                ? "Người bị thu hồi"
                : "Người được cấp";
            let htmlNote = "";
            let plainNote = "";
            if (objectInput.showNote) {
                plainNote = `\n *~*~*~*~*~*~*~*~*~* \n ${objectInput.note}`;
                htmlNote = `<hr>
                <table border="0" cellpadding="3" cellspacing="0" style="color:#333;font:1em sans-serif;">
                    <tr valign="top"><th align="right">Ghi chú:</th><td>${objectInput.note}</td></tr>
                </table>`;
            }
            let htmlRole = "";
            let plainRole = "";
            if (objectInput.role != undefined) {
                htmlRole = `<tr><th align="right">Vai trò:</th><td>${objectInput.role}</td></tr>
                <tr><th align="right">Thao tác được cho phép</th><td>${objectInput.operationRole}</td>`;
                plainRole = `\n Vai trò: ${objectInput.role} \n Thao tác được cho phép ${objectInput.operationRole}`;
            }
            let stringPlainContent = `${objectInput.title}
                \n\n----------------------------------------------
            \n\nMục chia sẻ:  ${objectInput.nameFolder} (${this.renderTypeFolder(objectInput.view)})
            \nChủ sở hữu:  ${objectInput.emailOwnerName}
            \n${titleGrantee}: ${objectInput.emailTo} ${plainRole} ${plainNote}`;
            let htmlContent = `<div style="font-family:sans-serif;">
                <h1 style="font-size:1.3em;">${objectInput.title}</h1>
                <hr>
                <table border="0" cellpadding="3" cellspacing="0" style="color:#333;font-size:1em;">
                    <tr><th align="right">Mục chia sẻ:</th><td>${objectInput.nameFolder} (${this.renderTypeFolder(objectInput.view)})</td></tr>
                    <tr><th align="right">Chủ sở hữu:</th><td>${objectInput.emailOwnerName}</td></tr>
                    <tr><th align="right">${titleGrantee}:</th><td>${objectInput.emailTo}</td></tr>
                    ${htmlRole}
                </table>
            </div>
            ${htmlNote}`;
            const action = objectInput.isRegain ? "delete" : "new";
            let xmlContent = `<share xmlns="urn:zimbraShare" version="0.2" action=${action} >
                <grantee id="${objectInput.granteeId}" email="${objectInput.emailTo}" name="${objectInput.emailTo}" />
                <grantor id="${objectInput.grantorId}" email="${objectInput.emailFrom}" name="${objectInput.emailOwnerName}" />
                <link id="${objectInput.idFolder}" name="${objectInput.nameFolder}" view="message" perm="${objectInput.perm}" />
                <notes>${objectInput.note}</notes>
            </share>`;
            const mpValue = [
                { ct: "text/plain", content: _.escape(stringPlainContent.replace(/\n/g, '<br>')) },
                { ct: "text/html", content: _.escape(htmlContent) },
                { ct: "xml/x-zimbra-share", content: _.escape(xmlContent) },
            ];
            const mp = [];
            mp[0] = { ct: "multipart/alternative", mp: mpValue };
            return mp;
        },
        regain() {
            const row = this.rowShare;
            const xml = {
                folder: `<urn1:action id="${this.treeNodeData.id}" zid="${row.zid ? row.zid : "99999999-9999-9999-9999-999999999999"}" op="!grant"></urn1:action>`,
            };
            ZimbraMailService.folderActionRequest(xml).then((res) => {
                if (res.statusText == "OK") {
                    if (this.isMessage) {
                        this.$message({
                            type: "success",
                            customClass: 'zm-notify',
                            duration: 3500,
                            dangerouslyUseHTMLString: true,
                            message: this.$t("zimbraNokey.zimbra_sidebar_dialogEditFolder_regainSuccess")
                        });
                    } else {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t("zimbraNokey.zimbra_sidebar_dialogEditFolder_regainSuccess"),
                            type: "success",
                        });
                    }
                }
                this.listShare = this.listShare.filter((x) => x.zid != row.zid);
                if (this.listShare.length < 1) {
                    this.showEmailShare = false;
                }
            });
            if (this.formShare.optionMessage != "1" && row.zid != undefined) {
                this.sentMsgRegain(row);
            }
            setTimeout(() => {
                this.getDetailFolder(this.treeNodeData.id);
            }, 500);
            this.dialogReGain = false;
        },
        editFolder() {
            alert('tét')
            this.formEdit.name = this.formEdit.name.toString().trim();
            if (
                this.formEdit.name == "" ||
                this.formEdit.name.length > 128 ||
                this.formEdit.name.indexOf("/") > -1 ||
                this.formEdit.name.indexOf(":") > -1 ||
                this.formEdit.name.indexOf(`"`) > -1||
                /[!@#$%^&*(),.?":{}|<>]/.test(this.formEdit.name)
            ) {
                const nameError = this.formEdit.name;
                const Msgerror =
                    this.formEdit.name == ""
                        ? this.$t("zimbra.zmMsg.nameEmpty")
                        : this.formEdit.name.length > 128
                            ? this.$t("zimbra.zhMsg.nameTooLong", { 0: 128 })
                            : this.$t(
                                "zimbra.zmMsg.errorInvalidName",
                                { 0: nameError }
                            );
                this.$alert(Msgerror, this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"), {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    type: "warning",
                });
                return;
            }
            // const folderParent = this.findFolderById(this.treeNodeData.l, this.dataSidebarContact[0]?.folder);
            // console.log("folderparent", folderParent);
            // if (this.checkExistsName(this.formEdit.name, folderParent)) {
            //     return;
            // }
            this.formEdit.name = _.escape(this.formEdit.name);
            let stringXml = [];
            if (this.formEdit.name != this.treeNodeData.name) {
                const xml = `<urn1:action id="${this.treeNodeData.id}" op="rename" name="${this.formEdit.name}"></urn1:action>`;
                stringXml.push({
                    path: "folderActionRequest",
                    content: JSON.stringify({ folder: xml }),
                });
            }
            if (this.formEdit.rgb != this.treeNodeData.rgb) {
                this.formEdit.rgb =
                    this.formEdit.rgb == null ? "" : this.formEdit.rgb;
                const xml = `<urn1:action id="${this.treeNodeData.id}" op="color" rgb="${this.formEdit.rgb}"></urn1:action>`;
                stringXml.push({
                    path: "folderActionRequest",
                    content: JSON.stringify({ folder: xml }),
                });
            }
            const formData = {
                listRequest: stringXml,
            };
            if (stringXml != "") {
                CompileService.batchRequest(formData).then((res) => {
                    const _res = res["data"]["data"]
                    let response = [];
                    if (_res.length > 1) {
                        _res.forEach(e => {
                            response = JSON.parse(e)
                        })
                    } else {
                        response = JSON.parse(res["data"]["data"])
                    }
                    if (response["FolderActionResponse"]) {
                        const folder = this.treeNodeData;
                        folder.name = this.formEdit.name;
                        folder.rgb = this.formEdit.rgb;
                        if (this.isMessage) {
                            this.$message({
                                type: "success",
                                customClass: 'zm-notify',
                                duration: 3500,
                                dangerouslyUseHTMLString: true,
                                message: this.$t("zimbraNokey.zimbra_sidebar_dialogEditFolder_updateFolderSuccess"),
                            });
                        } else {
                            this.$notify({
                                title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                                message: this.$t("zimbraNokey.zimbra_sidebar_dialogEditFolder_updateFolderSuccess"),
                                type: "success",
                            });
                        }

                        this.$root.$emit("handleReloadTreeFolder")
                        this.$store.commit("SET_ACTIVE_NODE_SIDEBAR", folder);
                    } else {
                        this.detailError = response["soap:Fault"]["faultstring"]
                        console.log("detailError", this.detailError);
                        this.errorVisible = true;
                        let name = this.formEdit.name
                        this.msgerror = this.$t("zimbra.zmMsg.errorAlreadyExists", { 1: this.$t("zimbra.zhMsg.addressBook"), 0: name });
                        if (this.formEdit.isSearch) {
                            this.msgerror = this.$t("zimbraNokey.zimbra_searchBox_duplidateName", { name });
                        }
                        return;
                    }
                });
            }
            this.dialogEditProperties = false;
        },
        changeOptionMessage() {
            if (this.formShare.optionMessage == "1") {
                this.showAddNote = false;
                this.showNote = false;
            } else if (this.formShare.optionMessage == "3") {
                this.showAddNote = true;
                this.showNote = true;
            } else {
                this.showAddNote = false;
                this.showNote = true;
            }
        },
        getOwnerEmail(str) {
            if (str == "pub" || str == undefined || str == null) {
                return this.$t("zimbra.zhMsg.public");
            } else {
                return str;
            }
        },
        checkExistsName(name, folderParent) {
            if (this.currentName !== name) {
                if (Array.isArray(folderParent?.folder)) {
                    if (folderParent.folder.map(x => x.name.toString().toLowerCase()).indexOf(name.toString().toLowerCase()) > -1) {
                        let msgerror = this.$t("zimbra.zmMsg.errorAlreadyExists", { 0: name, 1: this.$t("zimbra.zhMsg.ALT_FOLDER") });
                        if (this.formEdit.isSearch) {
                            msgerror = this.$t("zimbraNokey.zimbra_searchBox_duplidateName", { name });
                        }
                        this.$alert(
                            msgerror,
                            this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                            {
                                confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                                type: "error",
                            }
                        );
                        return true;
                    }
                }
            }
        },
        findFolderById(id, listFolder) {
            for (let index in listFolder) {
                if (listFolder[index].id == id) {
                    return listFolder[index];
                }
                if (listFolder[index].folder) {
                    if (Array.isArray(listFolder[index].folder)) {
                        this.findFolderById(id, listFolder[index].folder);
                    } else {
                        this.findFolderById([id, listFolder[index].folder]);
                    }
                }
            }
        },
        renderTypeFolder(view) {
            let typeFolder = "";
            switch (view) {
                case FOLDER_VIEW.MESSAGE:
                    typeFolder = this.$t("zimbra.zmMsg.mailFolder")
                    break;
                case FOLDER_VIEW.APPOINTMENT:
                    typeFolder = this.$t("zimbra.zhMsg.ALT_APP_CALENDAR")
                    break;
                case FOLDER_VIEW.CONTACT:
                    typeFolder = this.$t("zimbra.zmMsg.contactsFolder")
                    break;
                case FOLDER_VIEW.DOCUMENT:
                    typeFolder = this.$t("zimbra.zhMsg.ALT_APP_BRIEFCASE")
                    break;
                default:
                    typeFolder = this.$t("zimbra.zmMsg.mailFolder")
                    break;
            }
            return typeFolder
        },

        renderLabelTypeFolder(view) {
            let labelType = "";
            switch (view) {
                case FOLDER_VIEW.MESSAGE:
                    labelType = this.$t("zimbra.zhMsg.ALT_APP_MAIL")
                    break;
                case FOLDER_VIEW.CONTACT:
                    labelType = this.$t("zimbra.zhMsg.addressBook")
                    break;
                case FOLDER_VIEW.DOCUMENT:
                    labelType = this.$t("zimbra.zhMsg.files")
                    break;
                default:
                    labelType = this.$t("zimbra.zhMsg.ALT_APP_MAIL")
                    break;
            }
            return labelType + ":"
        },

        getRoleFolder(role) {
            return this.mapOperationRole.get(role)
        }
    },
};
</script>
<style>
</style>
