import moment from "moment";
import CONFIG from "@/config/config";
// import Color from "./color/Color";
// import Solver from "./color/Solver";
import { ZmSetting } from "@/utils/constants";
import ColorFilter from "./color/ColorFilter";
import color from "@/components/modules/layouts/listColor";
import _ from "lodash";
import AjxUtil from "./zimbra-lib/AjxUtil";
import AjxStringUtil from "./zimbra-lib/AjxStringUtil";
import { EMAIL_FLAG } from "./constants";
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import ZmComposeView from "@/utils/zimbra-lib/ZmComposeView";
import {
    MESSAGE_CONTENT_TYPE,
    NOTES_SEPARATOR
} from "@/utils/constants";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import Dwt from "@/utils/zimbra-lib/Dwt.js";
import ZmMimeTable from "./zimbra-lib/ZmMimeTable";

export default {
    name: "CommonUtils",
    data() {
        return {
            checkFocusConfirm: true
        };
    },
    created() { },
    mounted() { },
    computed: {
        ...mapGetters([
            "activeNodeSidebar",
            "displayTimeInMailList",
            "localeCurrent",
            "listFileContacts",
            "externalAccount"
        ]),
        titleMonWedFri() {
            return [
                this.$t("zimbra.ztMsg.weekdayMonday"),
                this.$t("zimbra.ztMsg.weekdayWednesday"),
                this.$t("zimbra.ztMsg.weekdayFriday")
            ].join(", ");
        },
        titleTueThu() {
            return [
                this.$t("zimbra.ztMsg.weekdayTuesday"),
                this.$t("zimbra.ztMsg.weekdayThursday"),
            ].join(", ");
        },
        titleSatSun() {
            return [
                this.$t("zimbra.ztMsg.weekdaySaturday"),
                this.$t("zimbra.ztMsg.weekdaySunday"),
            ].join(", ");
        },
        localeCurrentValue() {
            const listLocale = this.localeCurrent?.content.split(",");
            const localeCurrentValue = listLocale[listLocale.length - 1];
            return localeCurrentValue;
        },
        /**
         * convert danh sách liên hệ
         */
        contactsPlain() {
            const _contacts = [];
            if (this.listFileContacts) {
                this.listFileContacts.forEach(item => {
                    if (item.a) {
                        item.a = Array.isArray(item.a) ? item.a : [item.a];
                        item.a.forEach(e => {
                            if (e.n === "image") {
                                item[e.n] = e.filename;
                                item.part = e.part;
                            } else {
                                item[e.n] = e.content;
                            }
                        })
                        _contacts.push(item);
                    } else {
                        _contacts.push(item);
                    }
                });
            }
            return _contacts;
        }
    },
    watch: {},
    components: {},
    methods: {
        isFolderExternalAccount(data) {
            if (this.externalAccount.length > 0) {
                return this.externalAccount.findIndex(x => x.l == data.id) > -1;
            }
            return false;
        },
        getResponseBody(response) {
            try {
                return response["data"]["soap:Envelope"]["soap:Body"];
            } catch (error) {
                console.log(">>>>> getResponseBody: ", error);
                return {};
            }
        },
        getResponseBodyAuthSSO(response) {
            try {
                console.log(response['data'])
                return response['data'];
            } catch (error) {
                console.log(">>>>> getResponseBody: ", error);
                return {};
            }
        },
        getResponseHeader(response) {
            return response["data"]["soap:Envelope"]["soap:Header"];
        },
        addLocalStorage(key, value) {
            localStorage.setItem(key, value);
        },
        addLocalStorageObject(key, value) {
            localStorage.setItem(key, JSON.stringify(value || {}));
        },
        setCookie(key, value, path="/") {
            // Secure - Cookie will be sent in HTTPS transmission only.
            // HttpOnly- Don't allow scripts to access cookie. You can set both of the Secure and HttpOnly.
            // ; Secure; HttpOnly
            document.cookie = `${key}=${value}; path=${path}`;
        },
        doesHttpOnlyCookieExist(cookieName) {
            const d = new Date();
            d.setTime(d.getTime() + (1000));
            const expires = "expires=" + d.toUTCString();
            document.cookie = cookieName + "=new_value;path=/;" + expires;
            return document.cookie.indexOf(cookieName + '=') == -1;
        },
        getCookie(name) {
            const cookieString = document.cookie;
            const cookies = cookieString.split('; ');

            for(let cookie of cookies) {
                const [cookieName, cookieValue] = cookie.split('=');
                if(cookieName === decodeURIComponent(name)) {
                    return decodeURIComponent(cookieValue);
                }
            }

            return null;
        },
        deleteCookie( ) {
            document.cookie = `ZM_AUTH_TOKEN=`;
        },
        deleteAllCookies() {
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i];
                const eqPos = cookie.indexOf("=");
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";

                if(name === 'login_sc') {
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/mail";
                }
            }
        },
        getLocalStorage(key) {
            return localStorage.getItem(key);
        },
        getLocalStorageObject(key) {
            const store = localStorage.getItem(key);
            if (store != null) {
                return JSON.parse(store);
            }
            return null;
        },
        removeLocalStorage(key) {
            localStorage.removeItem(key);
        },
        formatDateTime(timestamp, format = "DD/MM/YYYY", checkIsToDay = true) {
            if (!timestamp) {
                return "";
            }
            const date = new Date(parseInt(timestamp));
            const res = moment(date).format(format);
            const toDay = moment(new Date()).format(format);
            const yesterday = moment(new Date())
                .subtract(1, "days")
                .format(format);
            const hour = moment(date).format("hh:mm A");
            if (checkIsToDay) {
                if (res === toDay) {
                    // return i18n.t("zimbra.zhMsg.today");
                    return hour;
                } else if (res === yesterday) {
                    return i18n.t("zimbra.zmMsg.yesterday");
                }
            }
            return res;
            // return res != toDay ? res : i18n.t('zimbra.zhMsg.today');
        },

        formatUrl(params) {
            params = params || {};
            var url = [];
            var i = 0;
            if (!params.relative) {
                var proto = params.protocol || location.protocol;
                if (proto.indexOf(":") == -1) {
                    proto = proto + ":";
                }
                url[i++] = proto;
                url[i++] = "//";
                url[i++] = params.host || location.hostname;
                var port = Number(params.port || location.port);
                if (port &&
                    ((proto == ZmSetting.PROTO_HTTP && port != ZmSetting.HTTP_DEFAULT_PORT) ||
                        (proto == ZmSetting.PROTO_HTTPS && port != ZmSetting.HTTPS_DEFAULT_PORT))) {
                    url[i++] = ":";
                    url[i++] = port;
                }
            }
            url[i++] = params.path || location.pathname;
            var qs = "";
            if (params.qsArgs) {
                qs = this.queryStringSet(params.qsArgs, params.qsReset);
            } else {
                qs = params.qsReset ? "" : location.search;
            }
            url[i++] = qs;

            return url.join("");
        },

        queryStringSet(args, qsReset) {
            let qs = qsReset ? "" : location.search;
            if (qs.indexOf("?") == 0) {
                qs = qs.substr(1);
            }
            let qsArgs = qs.split("&");
            let newArgs = {};
            for (let i = 0; i < qsArgs.length; i++) {
                let f = qsArgs[i].split("=");
                newArgs[f[0]] = f[1];
            }
            for (const name in args) {
                newArgs[name] = args[name];
            }
            let pairs = [];
            let i = 0;
            for (const name in newArgs) {
                if (name) {
                    pairs[i++] = [name, newArgs[name]].join("=");
                }
            }

            return "?" + pairs.join("&");
        },

        formatDateTimeForLang(timestamp, locale, format = "DD/MM/YYYY") {
            if (!timestamp) return "";
            const date = new Date(parseInt(timestamp));
            const resAnotherToday = moment(date)
                .locale(locale)
                .format(format);
            const resToday = moment(date)
                .locale(locale)
                .format("h:mm A");
            return moment(date).format("DD/MM/YYYY") === moment(new Date()).format("DD/MM/YYYY") ? resToday : resAnotherToday;
        },
        formatDateTimeForCalendar(timestamp, locale = "vi", format = "DD/MM/YYYY") {
            if (!timestamp) return '';
            return moment(timestamp).locale(locale).format(format)
        },

        convertToISO8601(time) {
            if (!time.toIOString) {
                (function () {
                    function pad(number) {
                        var r = String(number);
                        if (r.length === 1) {
                            r = "0" + r;
                        }
                        return r;
                    }
                    time.toIOString = function () {
                        return (
                            this.getUTCFullYear() +
                            pad(this.getUTCMonth() + 1) +
                            pad(this.getUTCDate()) +
                            pad(this.getUTCHours()) +
                            pad(this.getUTCMinutes()) +
                            pad(this.getUTCSeconds()) +
                            "Z"
                        );
                    };
                })();
            }
            return time.toIOString();
        },
        convertISO8601ToDate(timeUTC) {
            let result = timeUTC.substring(0, 4) + "-" + timeUTC.substring(4, 6) + "-" + timeUTC.substring(6, 8) + "T" +
                timeUTC.substring(8, 10) + ":" + timeUTC.substring(10, 12) + ":" + timeUTC.substring(12)
            return new Date(result);
        },
        checkFlagsContainKey(flags, key) {
            return !flags ? false : flags.includes(key);
        },
        checkIsUnread(mail) {
            return mail?.isUnread;
        },
        createUrlDownloadFile(id, part) {
            return `${CONFIG.ENDPOINT.homeService}/~/?auth=co&loc=vi_VN&id=${id}&part=${part}`;
        },
        getBodyPart(dataPart) {
            const tempGeneral = {};
            this.getBodyAllPart(dataPart, tempGeneral);
            for (const [key, value] of Object.entries(tempGeneral)) {
                const splitKey = key.split("|");
                const ct = splitKey[1];
                if (ZmMimeTable.isRenderableImage(ct)) {
                    return value;
                } else if (value?.filename && value?.cd === "inline") {
                    return value;
                } else if ((ct === ZmMimeTable.TEXT_HTML || ct === ZmMimeTable.TEXT_PLAIN) && !_.isNil(value.content)) {
                    return value;
                }
            }
            return null;
        },
        makeSureString(v) {
            return new String(v);
        },
        getBodyAllPart(dataPart, dataGenaral) {
            if (!dataPart) {
                return;
            }
            if (Array.isArray(dataPart)) {
                for (let index = 0; index < dataPart.length; index++) {
                    this.getBodyAllPart(dataPart[index], dataGenaral);
                }
            } else if (dataPart && dataPart.mp) {
                this.getBodyAllPart(dataPart.mp, dataGenaral);
            } else {
                dataGenaral[`${dataPart.part}|${dataPart.ct}`] = dataPart;
            }
        },
        hexToRgb(hex) {
            // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
            const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, (m, r, g, b) => {
                return r + r + g + g + b + b;
            });
            const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
                hex
            );
            return result
                ? [
                    parseInt(result[1], 16),
                    parseInt(result[2], 16),
                    parseInt(result[3], 16)
                ]
                : null;
        },
        convertHexToFilterCss(item) {
            let hex = "";
            if (!item) {
                return hex;
            }
            if (item.color) {
                hex = color.find(e => e.colorNumeric === item.color)?.value;
            } else if (item.rgb) {
                hex = item.rgb;
            }
            if (!hex || hex === "") {
                return "";
            }
            return ColorFilter.computeFilterCss(hex);
        },
        getColorHex(item) {
            let hex = "#FF9900"; // giá trị default
            if (item.color) {
                hex = color.find(e => e.colorNumeric === item.color)?.value;
            } else if (item.rgb) {
                hex = item.rgb;
            }
            return hex;
        },
        formatSize(value, isRounding = false) {
            const { units, size } = AjxUtil.formatSize(value, isRounding);
            return `${size} ${units}`;
        },
        computeListStar(listEmail, field) {
            if (field === "cid") {
                return listEmail
                    ? listEmail.map(e => ({
                        cid: e.id,
                        star: this.checkFlagsContainKey(
                            e.f,
                            EMAIL_FLAG.FLAGGED
                        )
                    }))
                    : [];
            } else {
                return listEmail
                    ? listEmail.map(e => ({
                        cid: e.cid,
                        id: e.id,
                        star: this.checkFlagsContainKey(
                            e.f,
                            EMAIL_FLAG.FLAGGED
                        )
                    }))
                    : [];
            }
        },
        isStar(mappingStar, id, field) {
            const email = mappingStar?.find(e =>
                field === "cid" ? e.cid === id : e.id === id
            );
            return email ? email.star : false;
        },
        convertArrayToObject(arr, fieldName, fieldContent) {
            if (!arr) {
                return {};
            }
            const data = {};
            arr = Array.isArray(arr) ? arr : [arr];
            arr.forEach(field => {
                data[field[fieldName || 'name']] = field[fieldContent || 'content'];
            });
            return data;
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        validatePhone(phone) {
            const regex = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im
            return regex.test(phone);
        },
        validateUrl(url) {
            let check = false
            const regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!]))?/;
            !regex.test(url) ? check = false : check = true;
            return check
        },
        validateFileExtentionDeny(fileName) {
            const regex = /\.(exe|msi|bat|cmd|com|bin|vb|vbs|vbe)$/i;
            return regex.test(fileName);
        },
        generateUniqueId() {
            return Math.random()
                .toString(36)
                .substring(2);
        },
        reorderTree(data, childrenField, sortBy, sortOrder) {
            if (Array.isArray(data)) {
                data.forEach(item => {
                    if (item[childrenField]) {
                        item[childrenField] = this.reorderTree(
                            item[childrenField]
                        );
                    }
                });
                data = _.orderBy(data, sortBy, sortOrder);
            }
            if (data[childrenField]) {
                data[childrenField] = this.reorderTree(data[childrenField]);
            }
            return data;
        },
        hasPermissionFolder(key) {
            // Check quyền cho folder đang active
            if (!this.activeNodeSidebar) {
                return true;
            }
            const { perm } = this.activeNodeSidebar;
            return !perm ? true : perm.includes(key);
        },
        hasPermissionSpecifyFolder(folder, key) {
            // Check quyền cho folder bất kỳ
            if (!folder) {
                return true;
            }
            const { perm } = folder;
            return !perm ? true : perm.includes(key);
        },
        renderTime(d) {
            let formatTime = "MMMM DD YYYY, h:mm A";
            if (this.displayTimeInMailList.content === true) {
                formatTime = "MMMM DD h:mm A";
            } else {
                formatTime = "DD MMMM";
            }
            return this.formatDateTimeForLang(
                d,
                this.localeCurrent.content,
                formatTime
            );
        },
        renderTimeTooltip(d) {
            let formatTime = "dddd, MMMM, YYYY h:mm:ss A";
            if (!d) return "";
            const date = new Date(parseInt(d));
            return moment(date)
                .locale(this.localeCurrent.content)
                .format(formatTime) + '<br> (' + moment(date).locale(this.localeCurrent.content).fromNow() + ')';
        },
        renderTimeInDetailMail(d) {
            let formatTime = "DD MMMM YYYY, h:mm A";

            if (!d) return "";
            const date = new Date(parseInt(d));
            return moment(date)
                .locale(this.localeCurrent.content)
                .format(formatTime);
        },
        getAbsFolderPathDisplay(path) {
            return path ? path.replace(/^\//i, '') : '';
        },
        getAddressEmailFromText(text) {
            const pattern = /(<)(.*?)(>)/ig;
            const match = text.match(pattern);
            return match?.length > 0 ? match[match.length - 1].substring(1, match[match.length - 1].length - 1) : '';
            // return text;
        },
        getNamePartAddressFromText(text) {
            const pattern = /(")(.*?)(")/ig;
            const match = text.match(pattern);
            return match?.length > 0 ? match[0].substring(1, match[0].length - 1) : '';
            // return text;
        },
        /**
         * Hiển thị tên thay cho địa chỉ email khi khả dụng
         * @returns
         */
        getDisplayNameAddress(address) {
            if (this.preference.zimbraPrefShortEmailAddress) {
                return this.getDisplayName(address);
            }
            const name = this.getDisplayName(address);
            return `"${name}" <${address.a}>`;
        },
        /**
         * Check chuoi co phai la dang unicode khong
         * @param str
         * @returns {boolean}
         */
        isDoubleByte(str) {
            for (let i = 0, n = str.length; i < n; i++) {
                if (str.charCodeAt(i) > 255) {
                    return true;
                }
            }
            return false;
        },
        createNotification(title, text, icon) {
            new Notification(title, {
                body: text,
                icon
            });
        },
        askForApproval(title, text) {
            if (Notification.permission === "granted") {
                this.createNotification(title, text, 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGJo8iMwVHlHWUK2ALHvyu8Ht7bIUGNsOmefRd2lipSP9Gw6uI88pXJvphu5pz9RbaNiY&usqp=CAU');
            }
            else {
                Notification.requestPermission(permission => {
                    if (permission === 'granted') {
                        this.createNotification(title, text, 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGJo8iMwVHlHWUK2ALHvyu8Ht7bIUGNsOmefRd2lipSP9Gw6uI88pXJvphu5pz9RbaNiY&usqp=CAU');
                    }
                });
            }
        },
        classFileType(fileName) {
            if (!fileName) {
                return;
            }
            const arrName = fileName.toString().split(".");
            const extention = arrName[arrName.length - 1];
            let classFile = "";
            switch (extention) {
                case "xlsx":
                case "xls":
                    classFile = "excel";
                    break;
                case "docx":
                case "doc":
                    classFile = "word";
                    break;
                case "pdf":
                    classFile = "pdf";
                    break;
                // case "vcf":
                //     classFile = "pdf";
                //     break;
                default:
                    classFile = "file";
                    break;
            }
            return classFile;
        },
        classFileTypeVmail(fileName) {
            if (!fileName) {
                return;
            }
            const arrName = fileName.toString().split(".");
            const extention = arrName[arrName.length - 1];
            let classFile = "";
            switch (extention) {
                case "xlsx":
                case "xls":
                    classFile = "excel";
                    break;
                case "docx":
                case "doc":
                    classFile = "word";
                    break;
                case "pdf":
                    classFile = "pdf";
                    break;
                case "vcf":
                    classFile = "pdf";
                    break;
                case "html":
                    classFile = "html";
                    break;
                case "jpg":
                    classFile = "jpg";
                    break;
                case "txt":
                    classFile = "txt";
                    break;
                case "zip":
                    classFile = "zip";
                    break;
                case "eml":
                    classFile = "eml";
                    break;
                default:
                    classFile = "file";
                    break;

            }
            return classFile;
        },
        isNullOrEmpty(str) {
            return !str || str.length === 0;
        },
        _addShadowMain(useMinWidth = false) {
            // this method adds shadow span to input
            // we'll use this span to calculate text width
            const { input } = this._getElementsMain();
            const span = document.createElement('span');
            span.classList.add('resizeable-main-shadow');
            input.parentNode.insertBefore(span, input);
            // copy font, padding and border styles from input
            const css = input.computedStyleMap();
            span.style.font = css.get('font');
            span.style.padding = css.get('padding');
            span.style.border = css.get('border');
            if (useMinWidth) {
                span.style.minWidth = `${input.getBoundingClientRect().width * 1 + 50}px`;
            }
        },
        getTextAddressSelector(e) {
            return `"${e.d || e.a}" <${e.a}>`;
        },
        getFileNameWithSize(file) {
            const sizeFile = AjxUtil.formatSize(file.s);
            return `${file.filename} (${sizeFile.size} ${sizeFile.units})`;
        },
        actionClickFileAttach(file, id) {
            if (file.ct === MESSAGE_CONTENT_TYPE.RFC822) {
                const formData = {
                    html: 1,
                    id: `${id}`,
                    max: 250000,
                    needExp: 1,
                    part: file.part,
                    read: 1,
                }
                ZimbraMailService.getMsgRequest(formData).then((res) => {
                    const msg = this.getResponseBody(res)["GetMsgResponse"];
                    this.lanchNewWindow([msg.m]);
                });
            } else if (file.href) {
                window.open(file.href);
            } else {
                const _href = this.createUrlDownloadFile(id, file.part)
                window.open(_href);
            }
        },
        capitalizeFirstLetter(string) {
            // Viết hoa chữ cái đầu
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        /**
         * capitalize the first letter of each word
         * @param {*} str
         * @returns
         */
        titleCase(str) {
            var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                // You do not need to check if i is larger than splitStr length, as your for does that for you
                // Assign it back to the array
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            // Directly return the joined string
            return splitStr.join(' ');
        },
        getDisplayName(address) {
            if (!address.a) {
                return address.p || address.d || address.email;
            }
            // lấy tên hiển thị của email
            // const item = this.contactsPlain.find(e => e.email === address.a);
            let item = null;
            if (address.id) {
                item = this.contactsPlain.find(e => e.id === address.id);
            } else {
                item = this.contactsPlain.find(e => e.email === address.a);
            }
            if (item) {
                if (item.mobilePhone) {
                    address.mobilePhone = `${item.mobilePhone} (${this.$t("zimbra.zmMsg.cell")})`;
                }
                if (item.workPhone) {
                    address.workPhone = `${item.workPhone} (${this.$t("zimbra.zhMsg.AB_FIELD_otherOffice")})`
                }
                if (item.homePhone) {
                    address.homePhone = `${item.homePhone} (${this.$t("zimbra.zhMsg.home")})`
                }
                if (item.fullName) {
                    return item.fullName;
                }
                if (item.lastName && item.firstName) {
                    return `${item.firstName} ${item.lastName}`;
                }
                return item.firstName;
            }
            return address.p || address.d || address.a;
        },
        getDomainName(hostName) {
            if (hostName) {
                return hostName.substring(hostName.lastIndexOf("@") + 1);
            }
            return "";
        },
        replacesNewlinesWithBr(str) {
            if (!str) return "";
            return str.replace(/^ /mg, " ").
                replace(/\t/mg, "<span style='white-space:pre'>\t</span>").
                replace(/\n/g, "<br>");
        },
        setFavicon(numberUnread, title) {
            // khởi tạo icon
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            if (numberUnread) {
                link.href = `${process.env.BASE_URL}favicon_${numberUnread < 10 ? numberUnread : 'plus'}.svg`;
            } else {
                link.href = `${process.env.BASE_URL}favicon.svg`;
            }
            document.title = title || `VMail`;
        },

        /**
         * Kiểm tra ký tự có phải là ký tự thường khi nhập vào ô tìm kiếm
        */
        isNormalCharacter(content) {
            const regex = /^[a-zA-Z]+$/;
            return regex.test(content);
        },
        logTime(key, ...args) {
            console.log(key, new Date(), args);
        },
        mobileAndTabletCheck() {
            const ua = navigator.userAgent;
            const isSafari = this.detectBrowser();
            if (/(tablet|iPad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                return "tablet";
            } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
                return "mobile";
            } else {
                if (isSafari === "Safari") {
                    return "tablet";
                }
                return "desktop";
            }
        },
        detectBrowser() {
            if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
                return 'Opera';
            } else if (navigator.userAgent.indexOf("Chrome") != -1) {
                return 'Chrome';
            } else if (navigator.userAgent.indexOf("Safari") != -1) {
                return 'Safari';
            } else if (navigator.userAgent.indexOf("Firefox") != -1) {
                return 'Firefox';
            } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
                return 'IE';//crap
            } else {
                return 'Unknown';
            }
        },
        getFirefoxVersion() {
            var ua = navigator.userAgent;
            if (ua.includes('Firefox/')) {
                return parseInt(ua.split('Firefox/')[1])
            } else {
                return 999;
            }
        },
        stringTruncate(str, limit = 17) {
            let newTitle = [];
            if (str.length >= limit) {
                str.split(" ").reduce((acc, cur) => {
                    if (acc + cur.length <= limit) {
                        newTitle.push(cur);
                    }
                    return acc + cur.length;
                }, 0);
                if (newTitle.length > 0) {
                    return `${newTitle.join(" ")} ...`;
                }
                return str.substring(0, 20) + ' ...';
            }
            return str
        },
        stringTruncateFileName(filename) {
            let nameShort = filename;
            if (nameShort && nameShort.length > 40) {
                nameShort = `${nameShort.substring(0, 10)}...${nameShort.substring(nameShort.length - 20, nameShort.length)}`;
            }
            return nameShort;
        },
        removeMarkers(text) {
            return text.replace(ZmComposeView.BC_MARKER_REGEXP, "");
        },
        focusElement(id, delay) {
            const element = document.getElementById(id);
            if (!element) {
                return;
            }
            setTimeout(() => element.focus(), delay || 200)
        },

        /**
         * Hàm cắt chuỗi tin nhắn đầu tiên
         * @param {*} content
         * @param {*} isHtml
         */
        truncateBodyContent(content, isHtml) {
            if (!content) return content;
            const sepIdx = content.indexOf(NOTES_SEPARATOR);
            if (sepIdx == -1) {
                return content;
            }
            if (isHtml) {
                //if it is a html content then just remove the content and preserve the html tags
                //surrounding the content.
                content = content.replace("<div>" + NOTES_SEPARATOR + "</div>", NOTES_SEPARATOR); // Striping div if NOTES_SEPARATOR is part of div.
                content = content.replace(NOTES_SEPARATOR, "<div id='separatorId'>" + NOTES_SEPARATOR + "</div>");
                const divEle = document.createElement("div");
                divEle.innerHTML = content;
                const node = Dwt.byId("separatorId", divEle);
                if (node) {
                    const parent = node.parentNode
                    // Removing all previousSiblings of node that contains NOTES_SEPARATOR
                    while (node.previousSibling) {
                        parent.removeChild(node.previousSibling);
                    }
                    parent.removeChild(node);
                }
                return divEle.innerHTML;
            }
            return content.substring(sepIdx + NOTES_SEPARATOR.length);
        },
        /**
         * Đăng xuất
         */
        _logout() {
            const { origin } = window.location;
            this.deleteAllCookies();
            // End the current session, removing it from all caches.
            ZimbraMailService.endSessionRequest({ logoff: 1 }).then(() => {
                window.location.href = `${origin}/${this.$configZimbra.LOGIN_PAGE}`;
                window.location.href = `https://sso2.viettel.vn:8001/sso/logout?service=${window.location.protocol}//${window.location.host}/login`;
            })

            
        },

        /**
         * Hàm lấy thông tin timezone của máy local
         */
        getTimeZoneLocal() {
            return Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        /**
         *
         * @param {*} html
         * @param {*} idx
         * @param {*} obj
         * @param {*} spanId
         * @param {*} context
         * @param {*} options
         * @returns
         */
        generateSpan(className, spanId, content, dataAttr, isLink) {
            let html = [], idx = 0;
            html[idx++] = "<span class='";
            html[idx++] = className;
            html[idx++] = "' role='link' ";
            // html[idx++] = `id='${spanId}'`;
            if (dataAttr) {
                for (const [key, value] of Object.entries(dataAttr)) {
                    html[idx++] = ` data-${key}='${value}'`;
                }
            }
            html[idx++] = ">";
            if (isLink) {
                html[idx++] = `<a target='_blank' class='${className}' role='link' href='${content}'`;
                if (dataAttr) {
                    for (const [key, value] of Object.entries(dataAttr)) {
                        html[idx++] = ` data-${key}='${value}'`;
                    }
                }
                html[idx++] = `>${AjxStringUtil.htmlEncode(content, true)}</a>`;
            } else {
                html[idx++] = AjxStringUtil.htmlEncode(content, true);
            }
            html[idx++] = "</span>";
            return html.join("");
        },
        /**
         * copy text to clipboard
         * @param {*} text
         */
        copyToClipboard(text) {
            // Create a "hidden" input
            const aux = document.createElement("input");
            // Assign it the value of the specified element
            aux.setAttribute("value", text);
            // Append it to the body
            document.body.appendChild(aux);
            // Highlight its content
            aux.select();
            // Copy the highlighted text
            document.execCommand("copy");
            // Remove it from the body
            document.body.removeChild(aux);
        },
        /**
         * Check Thêm mới/Sửa số liên hệ
         * @param {*} email
         * @returns
         */
        isAddNewContact(email) {
            const _contact = this.listFileContacts.find(e => e.email === email);
            return !_contact;
        },
        /***
         * Tìm folder theo id
         * @param {*} listFolder
         * @param {*} id
         * @returns
         */
        findFolderById(id, listFolder) {
            for (let index in listFolder) {
                if (listFolder[index].id == id) {
                    return listFolder[index];
                } else if (listFolder[index].folder) {
                    const result = this.findFolderById(id, listFolder[index].folder);
                    if (result != null) {
                        return result;
                    }
                }
            }
            return null;
        },
    }
};
