const AjxUtil = function () {
};

AjxUtil.ELEMENT_NODE = 1;
AjxUtil.TEXT_NODE = 3;
AjxUtil.DOCUMENT_NODE = 9;
AjxUtil.isArrayLike = function (aThing) { return typeof aThing !== 'string' && typeof aThing.length === 'number'; };

const isArray1 =
    function (arg) {
        return !!(arg && (arg.length != null) && arg.splice && arg.slice);
    };
// AjxUtil.isArray1 = isArray1;

const toArray =
    function (arg) {
        if (!arg) {
            return [];
        }
        else if (isArray1(arg)) {
            return arg;
        }
        else if (AjxUtil.isArrayLike(arg)) {
            return Array.prototype.slice.call(arg);
        }
        else if (arg.isAjxVector) {
            return arg.getArray();
        }
        else {
            return [arg];
        }
    };
// AjxUtil.toArray = toArray;

const arrayAsHash = function (array, valueOrFunc) {
    array = toArray(array);
    var hash = {};
    var func = typeof valueOrFunc == "function" && valueOrFunc;
    var value = valueOrFunc || true;
    for (var i = 0; i < array.length; i++) {
        var key = array[i];
        hash[key] = func ? func(key, hash, i, array) : value;
    }
    return hash;
};
// AjxUtil.arrayAsHash = arrayAsHash;


//Format size
AjxUtil.SIZE_GIGABYTES = "GB";
AjxUtil.SIZE_MEGABYTES = "MB";
AjxUtil.SIZE_KILOBYTES = "KB";
AjxUtil.SIZE_BYTES = "B";

/**
 * Formats a size (in bytes) to the largest whole unit. For example,
 * AjxUtil.formatSize(302132199) returns "288 MB".
 *
 * @param size      The size (in bytes) to be formatted.
 * @param round     True to round to nearest integer. Default is true.
 * @param fractions Number of fractional digits to display, if not rounding.
 *                  Trailing zeros after the decimal point are trimmed.
 */
const formatSize = function (size, isRounding) {
    var units = AjxUtil.SIZE_BYTES;
    if (size >= 1073741824) {
        units = AjxUtil.SIZE_GIGABYTES;
        size = size / 1073741824
    }
    else if (size >= 1048576) {
        units = AjxUtil.SIZE_MEGABYTES;
        size = size / 1048576
    }
    else if (size > 1023) {
        units = AjxUtil.SIZE_KILOBYTES;
        size = size / 1024
    }
    size = Math.round(size * 10) / 10;
    if (isRounding) {
        size = Math.round(size);
    }
    return { units, size };
};

const isFunction = (aThing) => { return (typeof (aThing) === 'function'); };

/**
 * Call a function with the the items in the given object, which special logic
 * for handling of arrays.
 *
 * @param obj                   Array or other object
 * @param func [function]       Called with index or key and value.
 */
const foreach = (obj, func) => {

    if (!func || !obj) {
        return;
    }

    if (AjxUtil.isArrayLike(obj)) {
        var array = obj;

        for (var i = 0; i < array.length; i++) {
            func(array[i], i);
        }
    }
    else {
        // don't rely on the value in the object itself
        var hasown = Object.prototype.hasOwnProperty.bind(obj);

        for (var k in obj) {
            if (hasown(k)) {
                func(obj[k], k)
            }
        }
    }
};

const indexOf = (array, object, strict) => {
    if (array) {
        for (var i = 0; i < array.length; i++) {
            var item = array[i];
            if ((strict && item === object) || (!strict && item == object)) {
                return i;
            }
        }
    }
    return -1;
};


const arrayContains = (array, object, strict) => {
    return indexOf(array, object, strict) != -1;
};

export default {
    ...AjxUtil,
    arrayAsHash,
    formatSize,
    isFunction,
    foreach,
    indexOf,
    arrayContains
}