<template>
    <!-- <div v-if="showSearchBox" :class="['head-search']"> -->
    <div class="head-search calendar-search">
        <div class="form-group hidden-scroll">
            <vue-tags-input
                class="form-control scroll-box"
                ref="refTag"
                v-model="tag"
                :tags="tags"
                :allow-edit-tags="true"
                :placeholder="renderPlaceholder"
                @tags-changed="tagsChanged"
                @before-saving-tag="beforeSavingTag"
                @before-adding-tag="beforeAddingTag"
                @keyup.enter.native="onEnter"
            />
            <button class="btn-search" @click="handldeSearchKeyword()">
                <img
                    src="@/assets/zimbra/images/ic-search1.svg"
                    alt=""
                />
            </button>
            <el-popover
                v-model="visibleDialogSearchBox"
                placement="bottom"
                trigger="click"
            >
                <dialog-briefcase-search
                    v-if="visibleDialogSearchBox"
                    v-model="modelSearch"
                    @onPushQueryContent="handleAddQueryContent"
                    @onReset="onReset"
                    @onsearch="handleSearch"
                />
                <a
                    slot="reference"
                    href="javascript:;"
                    class="btn-search-advanced extra-option"
                >
                    <img
                        src="@/assets/zimbra/images/ic-setting1.svg"
                        alt=""
                    />
                </a>
            </el-popover>
        </div>
    </div>
    <!-- </div> -->
</template>
<script>
import VueTagsInput from "@johmun/vue-tags-input";
import { mapGetters } from "vuex";
import DialogBriefcaseSearch from './dialog-briefcase-search.vue';
import { TYPE_DAY_SEARCH, LIST_TYPE_SEARCH } from "@/utils/constants";
import _ from "lodash"
import hotkeys from "hotkeys-js"
export default {
    components: {
        VueTagsInput,
        DialogBriefcaseSearch
    },
    data() {
        return {
            visibleDialogSearchBox: false,
            modelSearch: {},
            tags: [],
            tag: ""
        }
    },
    mounted() {
        hotkeys("/", this.handleFocus)
    },
    computed: {
        ...mapGetters(["calViewMode"]),
        renderPlaceholder() {
            if (this.tags.length) {
                return ""
            }
            return this.$t('zimbra.zhMsg.search')
        }
    },
    methods: {
        /**
         * Hàm emit khi chọn xong cấu hình tìm kiếm
         */
        handleSearch() {
            this.handldeSearchKeyword()
        },

        /**
         * Hàm tìm kiếm lịch
         */
        handldeSearchKeyword() {
            this.visibleDialogSearchBox = false;
            const query = this.tags.map((e) => (e.text));
            this.$store.commit("SET_LST_BRIEFCASE_QUERY_SEARCH", query)
            // thực hiện mở thanh thông tin tìm kiếm
            // this.$store.commit("SET_SHOW_SEARCH_BOX", true);
            // this.$store.commit("SET_LIST_QUERY_TEMP", query);
            this.$root.$emit("handleSearchByQuery", _.escape(query.join(" ")))
            // Xóa giá trị keyword
            this.tags = []
        },

        /**
         * reset điều kiện tìm kiếm
         */
        onReset() {
            this.tags = []
        },

        /**
         * thêm điều kiện tìm kiếm
         */
        handleAddQueryContent(_content, _type) {
            if (_type === TYPE_DAY_SEARCH.ON) {
                this.tags = this.tags.filter(x => x.type !== TYPE_DAY_SEARCH.BEFORE
                    && x.type !== TYPE_DAY_SEARCH.AFTER)
            } else if (_type !== LIST_TYPE_SEARCH.TAG) {
                this.tags = this.tags.filter(x => x.type !== _type)
            }
            const arr = _content.trim().split(" ").reverse()
            const _tagsExtra = []
            arr.forEach(element => {
                if (element && element.trim() != "") {
                    _tagsExtra.unshift({ text: element.trim(), type: _type })
                }
            });
            if (_content.indexOf(`"`) > -1) {
                this.tags = this.tags.concat({ text: _content, type: _type })
                return;
            }
            this.tags = this.tags.concat(_tagsExtra)
        },

        /**
         * update lai tags
         */
        tagsChanged(newTags) {
            this.tags = newTags;
            const query = this.tags.map((e) => `${_.escape(e.text)}`).join(" ")
            this.$root.$emit("handleSearchByQuery", query);
            this.$store.commit(
                "SET_LST_BRIEFCASE_QUERY_SEARCH",
                this.tags.map((e) => e.text)
            );
            this.updateModelSearch();
        },

        beforeSavingTag(obj) {
            obj.text = obj.text?.trim()
            obj.saveTag();
        },
        beforeAddingTag(obj) {
            const arr = obj.tag.text.trim().split(" ").reverse();
            obj.tag.text = arr[0].trim();
            if (arr.length > 1) {
                setTimeout(() => {
                    const _tags = this.tags;
                    for (let index = arr.length - 1; index > 0; index--) {
                        const item = arr[index].trim();
                        if (item.length > 0) {
                            _tags.push({
                                text: item,
                            });
                        }
                    }
                    this.tags = _.cloneDeep(_tags);
                    obj.addTag();
                }, 500);
            } else {
                obj.addTag();
            }
        },

        updateModelSearch() {
            if (this.tags && this.tags.length > 0) {
                const data = {};
                let hasTheWords = "";
                this.tags.forEach((tag) => {
                    const regex_size = /(larger|smaller):([0-9]+)(KB|MB)/i;
                    const regex_time =
                        /(before|after|date):(([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{1,2}))/i;
                    const regex_in = /in:"*"/i;
                    if (regex_in.test(tag.text)) {
                        data.searchInName = tag.text.substring(
                            4,
                            tag.text.length - 1
                        );
                        data.searchIn = {
                            absFolderPath: data.searchInName,
                        };
                    } else if (regex_size.test(tag.text)) {
                        const size = /(larger|smaller)/gi;
                        const sizeUnit = /(KB|MB)/gi;
                        const sizeAttach = /([0-9]+)/gi;
                        data.size = tag.text.match(size)[0].toLowerCase();
                        data.sizeUnit = tag.text
                            .match(sizeUnit)[0]
                            .toUpperCase();
                        data.sizeAttach = tag.text.match(sizeAttach)[0];
                    } else if (regex_time.test(tag.text)) {
                        const time = /(before|after|date)/gi;
                        const date =
                            /(([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{1,2}))/gi;
                        data.time = tag.text.match(time)[0].toLowerCase();
                        data.date = tag.text.match(date)[0]; // moment(, 'd/M/yy');
                    } else {
                        hasTheWords += " " + tag.text;
                    }
                });
                data.hasTheWords = hasTheWords;
                this.$store.commit("SET_MODEL_SEARCH", data);
            } else {
                this.$store.commit("SET_MODEL_SEARCH", {});
            }
        },

        /**
         * sự kiện khi click enter
         */
        onEnter() {
            if (this.tag) {
                const arr = this.tag.trim().split(" ").reverse();
                const _tagsExtra = []
                arr.forEach(element => {
                    if (element && element.trim() != "") {
                        _tagsExtra.unshift({ text: element.trim() })
                    }
                });
                this.tags = this.tags.concat(_tagsExtra)
            }
            this.tag = "";
            this.handldeSearchKeyword()
        },

        /*
        * Foucs search
        */
        handleFocus(e) {
            e.preventDefault();
            this.$nextTick(() => {
                this.$refs.refTag.$refs.newTagInput.focus();
            })
        }
    }
}
</script>
<style lang="scss">
.main-pri {
    & .calendar-search {
        & .form-group {
            & .form-control {
                height: unset !important;
                padding: 0.75rem 3.25rem!important;
                font-size: 1.5rem!important;
                overflow-y: auto;
                min-width: unset !important;
                & input {
                    padding-left: 0 !important;
                    &:hover {
                        box-shadow: unset !important;
                    }
                }
            }
        }
    }
}
.form-group.hidden-scroll {
    height: 39px;
    // width: 69rem;
    border: 1px solid #EBEDF3;
    border-radius: 8px;
}
.extra-option {
    position: absolute;
    right: 2rem;
    top: 30%;
    transform: translateY(-50%);
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.4rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
}
</style>
