<template>
    <div>
        <el-dialog
            class="dialog-body-wrap zimbra-dialog"
            custom-class="upload-file"
            :title="$t('zimbra.zmMsg.uploadFileToBriefcase')"
            :visible.sync="dialogUploadFile"
            :close-on-click-modal="false"
            :append-to-body="true"
            :destroy-on-close="true"
            @close="handleCloseDialog"
            width="30%"
        >
            <p class="text-label">{{ this.$t('zimbra.zmMsg.uploadChoose') }}</p>
            <div class="zm-wrap-scroll default-scroll">
                <div v-for="(file,index) in listFile" :key="index">
                    <el-form-upload
                        :file="file"
                        :index="index"
                        :listFile="listFile"
                        @renderData="renderData"
                    />
                </div>
            </div>
            <label for="">{{this.$t("zimbra.zmMsg.notesLabel")}}</label>
            <el-input
                type="textarea"
                v-model="notesUpload">
            </el-input>
            <div class="text-label" v-html="this.$t('zimbra.zmMsg.attachmentLimitMsgSingleFile', { 0: '28MB' })"></div>
            <span slot="footer" class="dialog-footer">
                <el-button class="btn-email primary" @click="hanldeSave">
                    {{ this.$t("zimbra.zmMsg.mobileStatusNeedProvision") }}
                </el-button>
                <el-button class="btn-email default"  @click="handleCloseDialog">
                    {{ this.$t("zimbra.zhMsg.actionTaskCancelTT") }}
                </el-button>
            </span>
        </el-dialog>
        <DialogUpdateConflictFile
            :show="showUpdateConflictFile"
            :lstData="lstUpdateFile"
            @hidden="handleHiddenUpdateConflict"
        />
    </div>
</template>

<script>
import DialogUpdateConflictFile from "./DialogUpdateConflictFile.vue"
import ElFormUpload from "@/components/modules/briefcase/components/el-form-upload.vue"
import { mapGetters } from "vuex"
import ContactService from "@/services/contacts/ContactService"
import BriefcaseService from "@/services/BriefcaseService.js"
import CommonUtils from "@/utils/common-utils"
import { STATUS_REQUEST } from "@/utils/constants"
// import _ from "lodash"
export default {
    mixins: [CommonUtils],
    props: {
        dialogUploadFile: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            listFile: [
                {
                    id: 0,
                    content: [{ name: "" }]
                }
            ],
            lstUpdateFile: [],
            showUpdateConflictFile: false,
            notesUpload: ""
        }
    },
    created() {
    },
    computed: {
        ...mapGetters([
            "nodeBriefcaseCurrent"
        ]),
    },
    components: {
        ElFormUpload,
        DialogUpdateConflictFile
    },
    methods: {
        handleCloseDialog() {
            this.$emit("close", false)
            this.listFile = [{ id: 0, content: [{ name: "" }] }];
            this.notesUpload = ""
        },
        async handlePromiseAll() {
            const listRequest = []
            const FOLDER_SELECT_DEFAULT = 16;
            this.listFile.forEach(item => {
                const _listFiles = item.content ? item.content : []
                _listFiles.forEach(content => {
                    const formData = new FormData()
                    formData.append("file", content)
                    listRequest.push(ContactService.uploadImage(formData))
                })
            })
            return await Promise.all(listRequest).then((resp) => {
                return resp.map(item => {
                    const response = item.data
                    let _start = response.indexOf("{")
                    let _last = response.indexOf("}")
                    let respStr = response.substring(_start + 1, _last)
                    _start = respStr.indexOf("(")
                    _last = respStr.indexOf(")")
                    respStr = respStr.substring(_start + 1, _last)
                    const listResp = respStr.split(",")
                    const id = listResp[2].substring(1, listResp[2].length - 1) || "";
                    const obj = {
                        doc: {
                            l: this.nodeBriefcaseCurrent?.id ? this.nodeBriefcaseCurrent.id.toString() : FOLDER_SELECT_DEFAULT.toString(),
                            desc: this.notesUpload,
                        },
                        id: id
                    }
                    return obj
                })
            })
        },
        async hanldeSave() {
            const form = await this.handlePromiseAll()
            const listRequest = []
            const listSuccess = []
            if (form.length) {
                form.forEach(ele => listRequest.push(BriefcaseService.saveDocumentRequest(ele)))
                await Promise.all(listRequest).then(listResp => {
                    listResp.forEach(resp => {
                        // const _documentResponse = CommonUtils.getResponseBody(resp).SaveDocumentResponse.doc
                        const _documentResponse = resp["data"]["soap:Envelope"]["soap:Body"].SaveDocumentResponse.doc
                        if (_documentResponse) listSuccess.push(_documentResponse)
                    })
                }).catch((err) => {
                    const { Code } = this.getResponseBody(err.response)["soap:Fault"]["detail"]["Error"]
                    if (Code === STATUS_REQUEST.ALREADY_EXISTS) {
                        let _index = 0
                        this.listFile.forEach(item => {
                            const _listFiles = item.content ? item.content : []
                            _listFiles.forEach(item => {
                                const _item = form[_index]
                                const formData = {
                                    doc: {
                                        desc: _item.doc.desc
                                    },
                                    id: _item.id,
                                    nameDoc: item.name
                                }
                                this.lstUpdateFile.push(formData)
                                _index++
                            })
                        })
                        setTimeout(() => {
                            this.showUpdateConflictFile = true
                        }, 200)
                    }
                })
                if (listSuccess.length === listRequest.length) {
                    this.$root.$emit("handleSelectNode", this.nodeBriefcaseCurrent, true);
                    this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                        message: this.$t("zimbra.zmMsg.twoStepAuthSuccessFinish"),
                        type: "success",
                    });
                }
            }
            this.handleCloseDialog()
        },
        renderData(arr) {
            this.listFile = arr;
        },
        handleHiddenUpdateConflict() {
            this.showUpdateConflictFile = false
            this.lstUpdateFile = []
        }
    }
}
</script>

<style>

</style>