<template>
    <div>
        <li>
            <div class="body-wrapper">
                <div class="search-container">
                    <base-input
                        class="search-input"
                        :placeholder="this.$t('zimbra.zhMsg.search')"
                        icon="el-icon-search"
                        id="input-search-contact"
                        :value="searchInput"
                        ref="inputSearch2"
                        @handleInput="handleChangeSearchInput"
                    >
                    </base-input>
                </div>
                <perfect-scrollbar>
                    <div class="contacts-content" style="padding: 0 16px;">
                        <el-empty v-if="!contacts.length" :description="`${$t('zimbra.zhMsg.noResultsFound')}`"></el-empty>
                        <div v-for="(item, index) in contacts" :key="index">
                            <div class="list-item">
                                <div
                                    class="list-item-detail"
                                    @click="hanldeShowDetailContact(item)"
                                >
                                    <img :src="item.avtUrl ? item.avtUrl : require('@/assets/images/Avatar.png')" :id="item.id" :alt="`avt-${item.id}`">
                                    <div class="contacts-item">
                                        <h2 class="title-item">
                                            {{ item.fileAsStr ? item.fileAsStr : $t("zimbra.zhMsg.noName") }}
                                        </h2>
                                        <span class="content-item">
                                             {{ defaultEmail(item) }}
                                        </span>
                                    </div>
                                </div>
                                <base-button-default
                                    class="btn-email primary"
                                    @click="handleCompose(item)"
                                >
                                    {{$t("zimbra.zhMsg.compose")}}
                                </base-button-default>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="footer-contact">
                <div class="icon">
                    <el-pagination
                        background
                        layout="prev, next"
                        :page-size="pageSize"
                        :current-page="currentPage"
                        :total="totalContact"
                        @current-change="changePage"
                        @prev-click="prevClick"
                        @next-click="nextClick"
                    >
                    </el-pagination>
                </div>
            </div>
        </li>
    </div>
</template>

<script>
import BaseInput from "@/components/modules/base/base-input";
import BaseButtonDefault from "@/components/modules/base/base-button/btn-default";
import { EMAIL_ACTION, READ_EMAIL_LAYOUT } from "@/utils/constants";
import CommonUtils from "@/utils/common-utils";
import { mapGetters } from "vuex";
// import _ from "lodash"
export default {
    mixins: [CommonUtils],
    components: {
        BaseInput,
        BaseButtonDefault,
    },
    props: {
        contacts: {
            type: Array,
            required: true
        },
        pageSize: {
            type: Number
        },
        currentPage: {
            type: Number
        },
        totalContact: {
            type: Number
        },
    },
    created() {
    },
    computed: {
        ...mapGetters(["preference"])
    },
    data() {
        return {
            EMAIL_ACTION: EMAIL_ACTION,
            searchInput: "",
            dataContact: [],
        };
    },
    methods: {
        handleChangeSearchInput(keyword) {
            this.searchInput = keyword
            this.$emit("search", keyword)

        },
        changePage() {
        },
        defaultEmail(item) {
            if (item) {
                const listEmail = item.emailTitle ? item.emailTitle : []
                const email = listEmail.find(ele => ele.n === "email")
                return email ? email.content : ""
            }
            return ""
        },
        prevClick(page) {
            this.$emit("prevClick", page)
        },
        nextClick(page) {
            this.$emit("nextClick", page)
        },
        objectToArray(data) {
            return data.map(ele => {
                let _temp = []
                if (ele.a.length) {
                    _temp = ele.a
                } else {
                    _temp.push(ele.a)
                }
                return {
                    ...ele,
                    a: _temp
                }
            })
        },
        hanldeShowDetailContact(data) {
            this.$store.commit("SET_ACTIVECONTACT", data)
            this.$root.$emit("hanldeShowContact", data);
        },
        _imageEncode(arrayBuffer) {
            let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer), function (p, c) { return p + String.fromCharCode(c) }, ''))
            let mimetype = "image/jpeg"
            return "data:" + mimetype + ";base64," + b64encoded
        },
        handleCompose(data) {
            this.$store.commit("SET_CONTACT_COMPOSE", data)
            let path = ""
            if(this.preference.zimbraPrefReadingPaneLocation == READ_EMAIL_LAYOUT.RIGHT)
                path = "/mail/email";
            else {
                path = "/mail/v-email";
            }
            this.$root.$emit("handleVisibleQuickContact")
            if (this.$route.path !== path) {
                this.addLocalStorageObject("composeContact", { data: data, isDomain: false })
                window.location.href = path
            } else {
                this.$root.$emit("createNewEmail", data)
            }
        },
    },
};
</script>

<style>
</style>
