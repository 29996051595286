<template>
    <ul ref="subMenuTag" class="sub-menu">
        <template v-for="(item, idx) in listTypeSizeSearch">
            <li
                class="zm-tag-item"
                :key="`tag_${idx}`"
                @click="handleChoose(idx)"
            >
                <el-popover
                    v-model="showPopover[idx]"
                    placement="left"
                    trigger="click"
                    :key="`tag_${idx}`"
                    class="zm-popover-search"
                    popper-class="zm-popover-search"
                    @show="handleShowPopver(idx)"
                >
                    <div style="display: flex" >
                        <el-input
                            :ref="`refInputSize`"
                            type="number"
                            class="el-text-control"
                            v-model="sizeVal[idx]"
                            :value="sizeVal[idx]"
                            @input="handleInputSize($event, idx, item)"
                            @keydown.enter.native="handleSelectSize(idx, item)"
                        />
                        <el-dropdown trigger="hover" class="zm-dropdown__search-size" @command="handleCommand($event, idx, item)">
                            <span class="el-dropdown__label">
                                {{ formatSize[idx] }}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    v-for="(item, index) in lstFormatSize"
                                    :command="item.value"
                                    :key="index"
                                >
                                    {{ item.label }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                            </el-dropdown>
                        <!-- <base-select
                            v-model="formatSize[idx]"
                            :value="formatSize[idx]"
                            placeholder=""
                            displayField="label"
                            valueField="value"
                            :clearable="true"
                            :options="lstFormatSize"
                            @change="handleSelectSize(idx, item)"
                        /> -->
                    </div>
                    <a slot="reference" href="javascript:;">
                        <span> {{ $t(item.label) }}</span>
                        <button
                            class="icon-caret float-right"
                            style="position: absolute; right: 0px; padding-right:15px"
                        >
                            <i class="el-icon-caret-right"></i>
                        </button>
                    </a>
                </el-popover>
            </li>
        </template>
    </ul>
</template>
<script>
import {
    LIST_FORMAT_SIZE_SEARCH,
    LIST_TYPE_SIZE_SEARCH,
    TYPE_SIZE_SEARCH
} from "@/utils/constants";
import _ from "lodash"
// import BaseInput from "@/components/modules/base/base-input"
// import BaseSelect from "@/components/modules/base/base-select"
export default {
    components: {
        // BaseInput,
        // BaseSelect
    },
    data() {
        return {
            sizeVal: [0, 0],
            LIST_FORMAT_SIZE_SEARCH: LIST_FORMAT_SIZE_SEARCH,
            formatSize: [LIST_FORMAT_SIZE_SEARCH[0].value, LIST_FORMAT_SIZE_SEARCH[0].value],
            showPopover: [false, false]
        }
    },
    mounted() {
        this.$root.$on("closePoverSizeSearch", this.closePoverSizeSearch)
    },
    computed: {
        // Danh sách tất cả loại fil
        listTypeSizeSearch() {
            return LIST_TYPE_SIZE_SEARCH;
        },
        // Danh sách format size
        lstFormatSize() {
            return LIST_FORMAT_SIZE_SEARCH
        }
    },
    methods: {
        closePoverSizeSearch() {
            this.showPopover = [false, false]
        },
        handleShowPopver(idx) {
            this.$nextTick(() => {
                this.$refs.refInputSize[idx].focus()
            })
        },
        handleChoose(idx) {
            this.showPopover = this.showPopover.map((item, index) => {
                if (index === idx) return true
                return false
            })
        },
        handleCommand(command, idx, itemSize) {
            switch (command) {
                case this.lstFormatSize[0].value: {
                    this.formatSize[idx] = _.cloneDeep(this.lstFormatSize[0].value)
                    this.handleSelectSize(idx, itemSize)
                    break
                }
                case this.lstFormatSize[1].value: {
                    this.formatSize[idx] = _.cloneDeep(this.lstFormatSize[1].value)
                    this.handleSelectSize(idx, itemSize)
                    break
                }
                default:
                    break
            }
            this.showPopover = [false, false]
        },
        handleInputSize(val, idx) {
            this.sizeVal[idx] = val
        },
        handleSelectSize(idx, itemSize) {
            let _contentQuery = ""
            if (this.sizeVal[idx] !== "" && this.formatSize[idx]) {
                if (TYPE_SIZE_SEARCH.SMALLER == itemSize.value) {
                    _contentQuery = `smaller:${this.sizeVal[idx]}${this.formatSize[idx]}`
                } else if (TYPE_SIZE_SEARCH.LARGER == itemSize.value) {
                    _contentQuery = `larger:${this.sizeVal[idx]}${this.formatSize[idx]}`
                }
                this.closePoverSizeSearch()
                this.$emit("onSelectSize", _contentQuery, itemSize.value)
            }
        }
    },
    destroyed() {
        this.$root.$off("closePoverSizeSearch")
    }
};
</script>
<style scoped>
.sub-menu {
    margin-left: 5px;
}
</style>
