var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-popover',{staticClass:"right-menu popover-wrap",attrs:{"id":"menu"},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('el-menu',{ref:"rightMenu",staticClass:"box-control-inbox"},[_c('el-menu-item',{staticClass:"menu-item",attrs:{"hidden":_vm.isFolderSearch || _vm.isRootSearchFolder,"index":"1","disabled":_vm.disabledRightMenu[0] ||
                !_vm.hasPermissionSpecifyFolder(
                    _vm.treeNodeData,
                    _vm.RIGHTS_FOLDER.CREATE_SUBFOLDER
                )},on:{"click":function($event){return _vm.$root.$emit('handleSidebarActionAdd')}}},[_c('i',{staticClass:"el-icon-folder-add",staticStyle:{"color":"orange"}}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbraNokey.zimbra_sidebar_rightMenu_menuItem1")))])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"hidden":((!_vm.isFolderRSS || _vm.isRootSearchFolder) &&
                    !_vm.isParentFolderRSS(_vm.treeNodeData)) ||
                _vm.isFolderDefaut(_vm.treeNodeData),"index":"1","disabled":!_vm.isFolderRSS},on:{"click":function($event){return _vm.checkFeed(_vm.treeNodeData)}}},[_c('i',{staticClass:"el-icon-refresh-right",staticStyle:{"color":"blue"}}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zhMsg.checkFeed")))])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"hidden":((!_vm.isFolderRSS || _vm.isRootSearchFolder) &&
                    !_vm.isParentFolderRSS(_vm.treeNodeData)) ||
                _vm.isFolderDefaut(_vm.treeNodeData),"index":"1","disabled":!_vm.isParentFolderRSS(_vm.treeNodeData)},on:{"click":function($event){return _vm.reloadAllFeed()}}},[_c('i',{staticClass:"el-icon-refresh-right",staticStyle:{"color":"blue"}}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.checkAllFeed")))])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"hidden":!_vm.treeNodeData.isFolderPop,"index":"1"},on:{"click":function($event){return _vm.loadExternalMail()}}},[_c('i',{staticClass:"el-icon-refresh-right",staticStyle:{"color":"blue"}}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.checkExternalMail")))])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"2","hidden":_vm.isFolderSearch || _vm.isRootSearchFolder,"disabled":_vm.disabledRightMenu[1] ||
                !_vm.hasPermissionSpecifyFolder(
                    _vm.treeNodeData,
                    _vm.RIGHTS_FOLDER.WRITE
                )},on:{"click":_vm.readAll}},[_c('i',{staticClass:"el-icon-check",staticStyle:{"color":"teal"}}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zhMsg.actionMarkAllRead")))])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"3","hidden":_vm.isTrash || _vm.isFolderSearch || _vm.isRootSearchFolder,"disabled":_vm.disabledRightMenu[2] || _vm.isFolderDefautShare(_vm.treeNodeData)},on:{"click":_vm.clickEmptyFolder}},[(!_vm.isJunk)?_c('div',[_c('i',{staticClass:"el-icon-delete",staticStyle:{"color":"red"}}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zhMsg.folderEmptyFolder")))])]):_vm._e(),(_vm.isJunk)?_c('div',[_c('i',{staticClass:"el-icon-delete",staticStyle:{"color":"red"}}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.emptyJunk")))])]):_vm._e()]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"4","hidden":!_vm.isTrash || _vm.isRootSearchFolder,"disabled":_vm.disabledRightMenu[3]},on:{"click":_vm.clickEmptyTrash}},[_c('i',{staticClass:"el-icon-delete",staticStyle:{"color":"red"}}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbraNokey.zimbra_sidebar_rightMenu_menuItem4")))])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"hidden":_vm.isFolderSearch || _vm.isRootSearchFolder,"index":"5","disabled":_vm.disabledRightMenu[4] ||
                !_vm.hasPermissionSpecifyFolder(
                    _vm.treeNodeData,
                    _vm.RIGHTS_FOLDER.ADMINISTER
                )},on:{"click":function($event){return _vm.$root.$emit('handleSidebarActionShare', {}, false)}}},[_c('i',{staticClass:"el-icon-share",staticStyle:{"color":"cornflowerblue"}}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.shareFolder")))])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"6","disabled":_vm.isFolderExternalAccount(_vm.treeNodeData) ||
                _vm.disabledRightMenu[5] ||
                (!_vm.hasPermissionSpecifyFolder(
                    _vm.treeNodeData,
                    _vm.RIGHTS_FOLDER.WRITE
                ) &&
                    _vm.treeNodeData.l != _vm.FOLDER_DEFAULT.ROOT),"hidden":_vm.isRootSearchFolder},on:{"click":function($event){_vm.activeNodeSidebarRightClick.isSearch
                    ? _vm.$root.$emit(
                          'handleSidebarActionMoveArchiveMail',
                          _vm.FOLDER_EXTRA_ACTION.MOVE_FOLDER_SEARCH
                      )
                    : _vm.$root.$emit(
                          'handleSidebarActionMoveArchiveMail',
                          _vm.FOLDER_EXTRA_ACTION.MOVE_FOLDER
                      )}}},[_c('i',{staticClass:"el-icon-truck",staticStyle:{"color":"black"}}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zhMsg.actionGo")))])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"7","disabled":_vm.disabledRightMenu[6] ||
                (!_vm.hasPermissionSpecifyFolder(
                    _vm.treeNodeData,
                    _vm.RIGHTS_FOLDER.WRITE
                ) &&
                    this.treeNodeData.l != _vm.FOLDER_DEFAULT.ROOT),"hidden":_vm.isRootSearchFolder},on:{"click":_vm.clickDeleteFolder}},[_c('i',{staticClass:"el-icon-circle-close",staticStyle:{"color":"red"}}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zhMsg.actionDelete"))+" ")])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"8","disabled":_vm.isFolderExternalAccount(_vm.treeNodeData) ||
                _vm.disabledRightMenu[7] ||
                (!_vm.hasPermissionSpecifyFolder(
                    _vm.treeNodeData,
                    _vm.RIGHTS_FOLDER.WRITE
                ) &&
                    _vm.treeNodeData.l != _vm.FOLDER_DEFAULT.ROOT),"hidden":_vm.isRootSearchFolder},on:{"click":function($event){return _vm.$root.$emit('handleSidebarActionEditName')}}},[_c('i',{staticClass:"el-icon-edit-outline",staticStyle:{"color":"blue"}}),_c('span',[_vm._v(_vm._s(_vm.treeNodeData.isSearch ? _vm.$t("zimbra.zmMsg.renameSearch") : _vm.$t("zimbra.zhMsg.renameFolder")))])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"9","disabled":_vm.disabledRightMenu[8] ||
                (!_vm.hasPermissionSpecifyFolder(
                    _vm.treeNodeData,
                    _vm.RIGHTS_FOLDER.WRITE
                ) &&
                    _vm.treeNodeData.l != _vm.FOLDER_DEFAULT.ROOT),"hidden":_vm.isRootSearchFolder},on:{"click":function($event){_vm.isFolderSearch
                    ? _vm.$root.$emit('handleActionEditFolderSearch')
                    : _vm.$root.$emit('handleSidebarActionEdit')}}},[_c('i',{staticClass:"el-icon-edit"}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.editProperties")))])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"10","hidden":!_vm.showActionSync},on:{"click":function($event){return _vm.syncFolder()}}},[_c('i',{staticClass:"el-icon-refresh-right",staticStyle:{"color":"blue"}}),(!_vm.isSync)?_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.syncOfflineFolderOn")))]):_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.syncOfflineFolderOff")))])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"10","disabled":_vm.disabledRightMenu[9],"hidden":_vm.isRootSearchFolder},on:{"click":function($event){return _vm.openInTab(_vm.note)}}},[_c('i',{staticClass:"el-icon-folder"}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.openInTab")))])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"11","disabled":_vm.disabledRightMenu[10]},on:{"click":function($event){return _vm.extendAll()}}},[_c('i',{staticClass:"el-icon-rank"}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.expandAll")))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }