<template>
    <el-menu class="box-control-inbox">
        <!-- Cuộc hẹn mới -->
        <el-menu-item
            index="1"
            class="menu-item"
            @click="onOpenNewMeetingWebex"
        >
            <i class="icon-custom-wrap ic-WebEX"></i>
            <span>{{ $t('zimbra.zmWebExZimlet.quickMeeting') }}</span>
        </el-menu-item>
        <el-menu-item
            index="2"
            class="menu-item"
            @click="onOpenJoinMeetingWebex"
        >
            <i class="icon-custom-wrap ic-WebEX"></i>
            <span>{{ $t('zimbra.zmWebExZimlet.startJoinExistingMeeting') }}</span>
        </el-menu-item>

        <hr style="margin:0px">

        <el-menu-item
            index="3"
            class="menu-item"
            @click="onOpenAccountManangeWebex"
        >
            <i class="icon-custom-wrap ic-people"></i>
            <span>{{ $t('zimbra.zmWebExZimlet.manageAccounts') }}</span>
        </el-menu-item>

        <el-menu-item
            index="4"
            class="menu-item"
            @click="onOpenWebexPreferences"
        >
            <i class="el-icon-setting icon-setting-color"></i>
            <span>{{ $t('zimbra.zhMsg.ALT_APP_OPTIONS') }}</span>
        </el-menu-item>
    </el-menu>
</template>
<script>
export default {
    props: {
    },
    data() {
        return {
        };
    },
    components: {
    },
    computed: {
    },
    methods: {
        /**
         * Hàm mở dialog quản lý tài khoản
         */
        onOpenAccountManangeWebex() {
            this.$root.$emit("onOpenAccountManangeWebex")
        },

        /**
         * Hàm mở dialog thêm cuộc họp mới
         */
        onOpenNewMeetingWebex() {
            this.$root.$emit("onOpenNewMeetingWebex")
        },

        /**
         * Hàm mở dialog tham dự cuộc họp
         */
        onOpenJoinMeetingWebex() {
            this.$root.$emit("onOpenJoinMeetingWebex")
        },

        /**
         * Hàm mở dialog tùy chọn
         */
        onOpenWebexPreferences() {
            this.$root.$emit("onOpenWebexPreferences")
        }
    },
};
</script>
<style scoped>
.box-control-inbox {
    max-width: 50rem;
    width: auto
}
</style>
