<template>
    <div>
        <div v-show="isMoveFolder">
            <el-input
                class="el-text-control"
                v-model="searchText"
                style="padding-bottom: 10px"
                size="medium"
                ref="inputSearch"
                @blur="blurInput"
                @input="onChangeSearch"
            ></el-input>
        </div>
        <perfect-scrollbar style="height: 300px">
            <el-tree
                class="tree-wrap"
                ref="tree"
                :data="folderSearches"
                node-key="id"
                :props="defaultProps"
                :default-expanded-keys="[FOLDER_DEFAULT.ROOT]"
                :filter-node-method="filterNode"
                @node-click="nodeClick"
                highlight-current
            >
                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>
                        <i
                            class="ic-folder"
                            :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(
                                data
                            )}`"
                        ></i>
                    </span>
                    <span>{{ node.label }}</span>
                </span>
            </el-tree>
        </perfect-scrollbar>
    </div>
    <!-- </perfect-scrollbar> -->
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import { mapGetters } from "vuex";import {
    FOLDER_DEFAULT,
    FOLDER_VIEW
} from "@/utils/constants";
// import _ from "lodash";
export default {
    props: {
        isMoveFolder: {
            type: Boolean,
            default: false,
        },
        typeFolderSearch: {
            type: String,
            default: function() {
                return ""
            },
        },
    },
    mixins: [CommonUtils],
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        },
    },
    model: {
        prop: "value",
        event: "change",
    },
    data() {
        return {
            selectedNode: null,
            filterText: "",
            searchText: "",
            FOLDER_DEFAULT: FOLDER_DEFAULT
        };
    },
    mounted() {
        this.$refs.inputSearch && this.$refs.inputSearch.focus();
    },
    computed: {
         ...mapGetters(["dataSidebarExtend"]),
         ...mapGetters({
            allFolderSearches: 'folderSearches',
        }),
        defaultProps() {
            const defaultConfig = { children: "folder", label: "name" }
            // nếu folder đổi cấu hình tree
            if(this.typeFolderSearch == FOLDER_VIEW.APPOINTMENT || this.typeFolderSearch == FOLDER_VIEW.CONTACT) {
                defaultConfig.children = "search"
            }
            return defaultConfig
        },
        folderSearches() {
            // nếu folder chuyển là loại calendar thì lấy dữ liệu folder đích phải có loại là calendar
            if(this.typeFolderSearch == FOLDER_VIEW.APPOINTMENT) {
                const calChildrenSearch = this.allFolderSearches[0]?.search? this.allFolderSearches[0].search.filter(x => x.types == FOLDER_VIEW.APPOINTMENT) : []
                return [
                        {
                            ...this.allFolderSearches[0],
                            search: calChildrenSearch
                        }
                    ];
            } else if (this.typeFolderSearch == FOLDER_VIEW.CONTACT) {
                const contactChildrenSearch = this.allFolderSearches[0].search ? this.allFolderSearches[0].search.filter(x => x.types == FOLDER_VIEW.CONTACT) : []
                return [
                    {
                        ...this.allFolderSearches[0],
                        search: contactChildrenSearch
                    }
                ]
            }
            return [this.dataSidebarExtend[0].folder.find(x=>x.isRootSearchFolder)];
        }
    },
    created() {
        setTimeout(() => {
            this.$refs.tree.setCurrentKey(FOLDER_DEFAULT.ROOT);
        }, 500);
        // console.log('folderSearches', this.folderSearches)
    },
    components: {
    },
    methods: {
        blurInput() {
            this.filterText = this.filterText.trim()
        },
        nodeClick(data) {
            this.selectedNode = data;
            this.$emit("change", data.id);
            this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", data);
            this.searchText = data.name;
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.toString().indexOf(value.toString().trim()) !== -1;
        },
        setFocus() {
            this.$refs.inputSearch && this.$refs.inputSearch.focus();
        },
        onChangeSearch(text) {
            this.filterText = text
        },
    },
};
</script>
