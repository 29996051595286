<template>
    <div class="main-pri">
        <div class="head-main nav-briefcase">
            <div id="toolbox" class='left vertical-control'>
                <el-dropdown
                class="zm-drop_menu"
                split-button
                trigger="click"
                @click="handleAddNewDocument"
                @command="handleCommand"
            >
                {{this.$t("zimbra.zmMsg.newDocument")}}
                <el-dropdown-menu slot="dropdown" class="zm-dropdown">
                    <div class="ps">
                        <div class="content">
                            <el-dropdown-item
                                :command="CONTACT_ACTION.COMPOSE"
                            >
                                <MessageAdd class="ic" />
                                {{ $t("zimbra.zhMsg.ALT_APP_MAIL") }}
                            </el-dropdown-item>
                            <el-dropdown-item
                                :command="CONTACT_ACTION.ADD_CONTACT"
                            >
                                <ContactAdd class="ic" />
                                {{ $t("zimbra.zhMsg.ALT_CONTACT_CONTACT") }}
                            </el-dropdown-item>
                            <el-dropdown-item
                                :command="CONTACT_ACTION.ADD_GROUP_CONTACT"
                            >
                                <UserGroup class="ic" />
                                {{ $t("zimbra.zmMsg.AB_CONTACT_GROUP") }}
                            </el-dropdown-item>
                            <el-dropdown-item
                                :command="CONTACT_ACTION.ADD_APPOINTMENT"
                            >
                                <CalendarAdd class="ic" />
                                {{ $t("zimbra.zhMsg.ALT_MSG_STATUS_APPT") }}
                            </el-dropdown-item>
                            <!-- <el-dropdown-item
                                :command="CONTACT_ACTION.ADD_TASK"
                            >
                                <BriefcaseAdd class="ic" />
                                {{ $t("zimbra.zhMsg.ALT_APP_TASK") }}
                            </el-dropdown-item> -->
                            <el-dropdown-item
                                :command="CONTACT_ACTION.ADD_DOCUMENT"
                            >
                                <DocumentAdd class="ic" />
                                {{ $t("zimbra.zhMsg.ALT_APP_NOTEBOOK") }}
                            </el-dropdown-item>
                            <el-divider></el-divider>
                            <el-dropdown-item
                                :command="CONTACT_ACTION.ADD_FOLDER"
                            >
                                <FolderAdd class="ic" />
                                {{ $t("zimbra.zhMsg.ALT_FOLDER") }}
                            </el-dropdown-item>
                            <el-dropdown-item
                                :command="CONTACT_ACTION.ADD_TAG"
                            >
                                <!-- <TagAdd class="ic" /> -->
                                <i class="ic ic-tag"></i>
                                {{ $t("zimbra.zhMsg.actionTag") }}
                            </el-dropdown-item>
                            <el-dropdown-item
                                :command="CONTACT_ACTION.ADD_CONTACT_FOLDER"
                            >
                                <UserGroup class="ic" />
                                {{ $t("zimbra.zmMsg.contactsFolder") }}
                            </el-dropdown-item>
                            <el-dropdown-item
                                :command="CONTACT_ACTION.ADD_CALENDAR_FODLER"
                            >
                                <CalendarAdd class="ic" />
                                {{ $t("zimbra.zhMsg.ALT_APP_CALENDAR") }}
                            </el-dropdown-item>
                            <!-- <el-dropdown-item
                                :command="CONTACT_ACTION.ADD_TASK_FOLDER"
                            >
                                <BriefcaseAdd class="ic" />
                                {{ $t("zimbra.zmMsg.tasksFolder") }}
                            </el-dropdown-item> -->
                            <el-dropdown-item
                                :command="CONTACT_ACTION.ADD_BRIEFCASE_FOLDER"
                            >
                                <FolderAdd class="ic" />
                                {{ $t("zimbra.zhMsg.ALT_APP_BRIEFCASE") }}
                            </el-dropdown-item>
                        </div>
                    </div>
                </el-dropdown-menu>
                </el-dropdown>
                <el-button size="small" class="btn-briefcase default" @click="hanldeUploadNewFile">
                    {{this.$t("zimbra.zhMsg.uploadNewFile")}}
                </el-button>
                 <div class="box-menu-head header-briefcase" >
                    <el-button size="small" class="btn-briefcase outline"
                    :disabled="isDisabled || !isDowloadBriefcase" @click="$root.$emit('handleNavbarAction', BRIEFCASE_ACTION.DOWNLOAD)">
                        <i class="fal fa-download"></i>
                        <span class="title">
                            {{ $t("zimbra.zhMsg.actionDownload") }}
                        </span>
                    </el-button>
                    <el-button size="small" class="btn-briefcase outline" @click="handleEditBriefcase"
                    :disabled="isDisabled || !isDisableEdit">
                        <i class="fal fa-edit"></i>
                        <span class="title">
                            {{ $t("zimbra.zhMsg.edit") }}
                        </span>
                    </el-button>
                    <el-button size="small" class="btn-briefcase outline" @click="handleDeleteBriefcase" :disabled="isDisabled || isDisableDelete">
                        <i class="fal fa-trash-alt"></i>
                        <span class="title">
                            {{ $t("zimbra.zhMsg.actionDelete") }}
                        </span>
                    </el-button>
                    <el-dropdown trigger="click" :disabled="isDisabled">
                        <el-button size="small" class="btn-briefcase outline" :class="isDisabled ? 'is-disabled' : ''">
                            <span class="title">
                                {{ $t("zimbra.zhMsg.actionGo")}}
                            </span>
                            <i class="fal fa-chevron-down"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown" v-model="visablePopoverMove">
                            <el-dropdown-item>
                                <PopoverMoveBriefcase v-if="visablePopoverMove"></PopoverMoveBriefcase>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown trigger="click" :disabled="isDisabled">
                        <el-tooltip class="item" effect="dark"
                            :content='this.$t("zimbra.zhMsg.MO_actions")'
                            placement="top-start"
                        >
                            <button class="more">
                                <i class="far fa-ellipsis-v"></i>
                            </button>
                        </el-tooltip>
                        <el-dropdown-menu slot="dropdown" v-model="visablePopoverAction">
                            <!-- <el-dropdown-item> -->
                            <PopoverActionBriefcase
                                ref="refPopoverAction"
                            />
                            <!-- </el-dropdown-item> -->
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="right">
                <NavbarBriefcaseSearch />
                <a href="javascript:;" class="ic-search-mobile focusable"><i class="fal fa-search"></i></a>
                <button class="btn-close" @click="handleRedirect">
                    <i class="fal fa-times"></i>
                </button>
            </div>
        </div>
        <DialogUploadFile :dialogUploadFile="dialogUploadFile" @close="handleCloseDialog"/>
         <!-- Dialog add folder lịch -->
            <DialogAddCalendarFolder />
    </div>
</template>
<script>
import EmailUtils from "@/utils/email-utils";
import CommonUtils from "@/utils/common-utils";
import BriefcaseUtils from "@/utils/briefcase-utils"
import { CONTACT_ACTION, FOLDER_DEFAULT, BRIEFCASE_ACTION, Zm_Display, KEY_WORD } from "@/utils/constants"
import PopoverMoveBriefcase from "@/components/modules/layouts/components/briefcase/PopoverMoveBriefcase";
import MessageAdd from "@/components/modules/contacts/components/icons/MessageAdd";
import ContactAdd from "@/components/modules/contacts/components/icons/ContactAdd";
import UserGroup from "@/components/modules/contacts/components/icons/UserGroup";
// import BriefcaseAdd from "@/components/modules/contacts/components/icons/BriefcaseAdd";
import CalendarAdd from "@/components/modules/contacts/components/icons/CalendarAdd";
import FolderAdd from "@/components/modules/contacts/components/icons/FolderAdd";
import DocumentAdd from "@/components/modules/contacts/components/icons/DocumentAdd";
// import TagAdd from "@/components/modules/contacts/components/icons/TagAdd";
import DialogUploadFile from "@/components/modules/layouts/components/briefcase/DialogUploadFile.vue"
// import { v4 as uuidv4 } from "uuid";
import _ from "lodash"
import { mapGetters } from "vuex";
import PopoverActionBriefcase from '../components/PopoverActionBriefcase.vue';
import NavbarBriefcaseSearch from '@/components/modules/briefcase/components/search/navbar-briefcase-search.vue';
import DialogAddCalendarFolder from "@/components/modules/calendar/components/dialogs/dialog-add-calendar-folder.vue";
import $ from 'jquery'
// import BriefcaseService from "@/services/BriefcaseService.js"
export default {
    mixins: [CommonUtils, EmailUtils, BriefcaseUtils],
    data() {
        return {
            Zm_Display: Zm_Display,
            showComposeButton: true,
            visablePopoverMove: false,
            CONTACT_ACTION: CONTACT_ACTION,
            BRIEFCASE_ACTION: BRIEFCASE_ACTION,
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            dialogUploadFile: false,
            isDisableEdit: false,
            isDisableDelete: true,
            isDowloadBriefcase: false,
            visablePopoverAction: false,
            timer: null,
            checkHotkeyBefore: false,
        };
    },
    watch: {
        listSelectedBriefcase(val) {
            this.isDisableEdit = false
            this.isDowloadBriefcase = false
            this.isDisableDelete = false
            if (val.length === 1) {
                if (!val[0].isFolder && val[0]?.ct.includes("application/x-zimbra-doc")) {
                    const parent = this.listBriefcaseSearch.find(item => item.id === val[0].id)
                    if (parent.ver === val[0].ver)
                        this.isDisableEdit = true
                }

            }
            const folder = val.find(x => x.isFolder)
            const file = val.find(x => x.ct)
            if (val.length == 0 || (folder && file)) {
                this.isDisableDelete = true
            }
            if (val.length > 0 && val.find(x => !x.isFolder)) {
                this.isDowloadBriefcase = true
            }
        },
    },
    components: {
        PopoverMoveBriefcase,
        MessageAdd,
        ContactAdd,
        UserGroup,
        // BriefcaseAdd,
        FolderAdd,
        DocumentAdd,
        CalendarAdd,
        // TagAdd,
        PopoverActionBriefcase,
        DialogUploadFile,
        NavbarBriefcaseSearch,
        DialogAddCalendarFolder
    },
    computed: {
        ...mapGetters([
            "activeFolderExtra",
            "listSelectedBriefcase",
            "listBriefcaseSearch",
            "currentActiveBriefcase",
            "briefcaseTreeFolders",
            "nodeBriefcaseCurrent",
            "activeNodeContact",
            "preference"
        ]),
        isDisabled() {
            return this.listSelectedBriefcase && this.listSelectedBriefcase.length === 0
        }
    },
    mounted() {
        this.$root.$on("closePopoverNavbar", () => {
            this.handleFocusOut()
        });
        this.timer = setTimeout(() => {
            this.handleAddShortcutAfterInitView()
            this.$nextTick(() => {
                var win = $(window);
                if ($(win).width() < 1199) {
                    if ($('.ic-search-mobile').length) {
                        $('.ic-search-mobile').on('click', function () {
                            $(this).children('i').toggleClass('fa-search fa-times');
                            $('.main-pri .head-main .right').toggleClass('show-search');
                        });
                    }
                }
            })
            clearTimeout(this.timer);
        }, 500)
    },
    destroyed() {
        this.$root.$off("closePopoverNavbar")
    },
    created() {
        // console.log('activeNodeContact', this.activeNodeContact)
    },
    methods: {
        /***
         * Dropdown trên tài liệu mới
         */
        handleAddShortcutAfterInitView() {
            this.$hotkeys("del", this.handleDelete)
            this.$hotkeys("u", this.handledRemoveTag)
            this.$hotkeys("t", this.handleOpendPopverAssignTag)
            this.$hotkeys("n+d", this.handleOpendNewDocment)
            this.blockEventKeys("n")
            this.$mousetrap.bind("m+p+b", (e) => {
                this.handleToggleDisplayView(e, Zm_Display.BOTTOM)
            })
            this.$mousetrap.bind("m+p+r", (e) => {
                this.handleToggleDisplayView(e, Zm_Display.RIGHT)
            })
            this.$mousetrap.bind("m+p+o", (e) => {
                this.handleToggleDisplayView(e, Zm_Display.OFF)
            })
        },
        blockEventKeys(key) {
            for (let index in KEY_WORD) {
                this.$hotkeys(`${key}+${KEY_WORD[index]}`, this.handlePressNextHotKey);
            }
        },
        handlePressNextHotKey() {
            this.checkHotkeyBefore = true;
            setTimeout(() => {
                this.checkHotkeyBefore = false;
            }, 1000)
        },
        handleCommand(command) {
            switch (command) {
                case CONTACT_ACTION.COMPOSE:
                    this.path = "/mail/email";
                    if (this.$route.path !== this.path) {
                        window.location.href = this.path
                        this.addLocalStorageObject("composeContact", { newCompose: true })
                    }
                    break
                case CONTACT_ACTION.ADD_CONTACT:
                    // this.$store.commit("SET_CONTACTMODE", CONTACT_ACTION.SCREEN_ADD_CONTACT)
                    this.path = "/mail/contacts";
                    if (this.$route.path !== this.path) {
                        window.location.href = this.path
                        this.addLocalStorageObject("addNewContact", { newAddContact: true })
                        // this.handleAddNewContact()
                    }
                    break
                case CONTACT_ACTION.ADD_GROUP_CONTACT:
                    this.$root.$emit("handleShowContactGroup", true)
                    break
                case CONTACT_ACTION.ADD_APPOINTMENT:
                    this.$root.$emit("createNewAppointmentRoot", "");
                    break
                case CONTACT_ACTION.ADD_TASK:
                    break
                case CONTACT_ACTION.ADD_DOCUMENT:
                    this.handleAddNewDocument()
                    break
                case CONTACT_ACTION.ADD_FOLDER:
                    this.$root.$emit("handleSidebarActionMoveAdd", false)
                    break
                case CONTACT_ACTION.ADD_TAG:
                    this.$root.$emit("handleTagActionAdd")
                    break
                case CONTACT_ACTION.ADD_CONTACT_FOLDER:
                    this.$root.$emit("handleAddFolderContact", false)
                    break
                case CONTACT_ACTION.ADD_CALENDAR_FODLER:
                    this.$root.$emit('onOpenAddCalendar', FOLDER_DEFAULT.ROOT)
                    break
                case CONTACT_ACTION.ADD_TASK_FOLDER:
                    break
                case CONTACT_ACTION.ADD_BRIEFCASE_FOLDER:
                    this.$root.$emit('handleAddFolderContact', true)
                    break
                default:
                    break
            }
        },
        handleDelete() {
            if (this.listSelectedBriefcase && this.listSelectedBriefcase.length) {
                this.$root.$emit("handleNavbarAction", BRIEFCASE_ACTION.DELETE)
            }
        },
        handledRemoveTag() {
            const _listSelectedNoFolder = this.listSelectedBriefcase.filter(item => !item.isFolder)
            const _listTagAssgin = this.$refs.refPopoverAction &&
                this.$refs.refPopoverAction.listTagAssgin
            if (_listSelectedNoFolder && _listSelectedNoFolder.length && _listTagAssgin && _listTagAssgin.length) {
                this.handledRemoveAllTags()
            }
        },
        handleOpendPopverAssignTag() {
            const _listSelectedNoFolder = this.listSelectedBriefcase.filter(item => !item.isFolder)
            if (_listSelectedNoFolder && _listSelectedNoFolder.length > 0) {
                this.$root.$emit("handleShowPopoverAssignTag")
            }
        },
        handleOpendNewDocment(e) {
            if (e.preventDefault) {
                if (!this.checkHotkeyBefore) {
                    this.handleAddNewDocument()
                }
            } else {
                e.returnValue = false;
            }
        },
        handleToggleDisplayView(e, view) {
            if (e.preventDefault) {
                e.preventDefault()
                this.handleChangeDisplayView(view)
            }
        },
        hasHistory() {
            return window.history.length > 2;
        },
        handleRedirect() {
            window.location.href = "/mail";
        },
        handleAddNewDocument() {
            const uuid = this.generateUniqueId();
            const activeFolderExtra = {
                data: this.nodeBriefcaseCurrent
            };
            this.addLocalStorageObject("preference", this.preference)
            this.addLocalStorageObject("data_briefcase", _.cloneDeep(activeFolderExtra));
            const { origin } = window.location;
            const height = screen.height * 0.6;
            const width = screen.width * 0.5;
            const left = screen.width * 0.25;
            const top = screen.height * 0.15;
            const style = `width=${width},height=${height},left=${left},top=${top}`;
            window.open(`${origin}/launchNewWindow2?createdBriefcase=1&id=${uuid}`, '', style.toString());
        },
        handleFocusOut() {
            this.visablePopoverMove = false
            this.visablePopoverAction = false
            // this.visablePopover = false;
        },
        async handleEditBriefcase() {
            this.$root.$emit("handleNavbarAction", BRIEFCASE_ACTION.EDIT)
        },
        /*
        * Hàm xóa cặp tài liệu
        */
        handleDeleteBriefcase() {
            this.$root.$emit("handleNavbarAction", BRIEFCASE_ACTION.DELETE)
        },
        hanldeUploadNewFile() {
            this.dialogUploadFile = true
        },
        /***
         * Đóng dialog tải tập tin mới lên Cặp tài liệu
         */
        handleCloseDialog(val) {
            this.dialogUploadFile = val;
        },
        validateButtonNavbar() { }
    }
};
</script>
<style lang="scss">
    .head-main {
        & .left {
            &.visible {
                visibility: hidden !important;
            }
        }
    }
    .main-pri {
        height: unset !important;
    }
</style>
