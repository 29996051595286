<template>
    <!-- Tag -->
    <div class="setting-tree-wrap">
        <div>
            <el-input
                class="el-text-control"
                v-model="filterText"
                style="padding-bottom: 10px"
                size="medium"
                ref="refInputSearch"
                @blur="blurInput"
            ></el-input>
        </div>
        <perfect-scrollbar style="height: 300px" id="treeExtra">
            <el-tree
                ref="treeTag"
                class="tree-wrap"
                style="margin-top: 10px"
                node-key="id"
                id="treeExtend"
                :data="dataSelector"
                :props="defaultProps"
                @node-click="onClickTagTree"
                :filter-node-method="filterNode"
                draggable
                highlight-current
                :expand-on-click-node="false"
                :default-expanded-keys="defaultExpandTreeExtend"
            >
                <div class="zm-folder-info" slot-scope="{ node, data }">
                    <div class="custom-tree-node">
                            <span>
                                <i :class="['ic-tag', 'ic-with-color']"
                                    style="padding-right: 28px; margin-left: -9px"
                                    :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(data)};
                                            --icColor: ${convertHexToFilterCss(data)};`"
                                ></i>
                            </span>
                            <span :class="data.u > 0 ? 'font-weight-bold' : '' ">
                                {{ node.label }}
                            </span>
                            <span v-if="data.u > 0" class="font-weight-bold">
                                ({{data.u}})
                            </span>
                    </div>
                </div>
            </el-tree>
        </perfect-scrollbar>
    </div>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import EmailUltils from "@/utils/email-utils";
import { FOLDER_DEFAULT } from "@/utils/constants";
import { mapGetters } from "vuex";
import _ from "lodash"
export default {
    mixins: [CommonUtils, EmailUltils],
    props: {
        isRootEmitEvt: {
            type: Boolean,
            default: true
        },
        allowfilter: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            defaultProps: {
                children: "folder",
                label: "name",
            },
            dataSelector: [],
            filterText:"",
            defaultExpandTreeExtend: [FOLDER_DEFAULT.TAG, FOLDER_DEFAULT.ROOT],
            timer: null,
        };
    },
    created() {
        const dataExtend = _.cloneDeep(this.dataSidebarExtend);
        this.dataSelector = dataExtend[0].folder.filter(
            (x) => x.id == FOLDER_DEFAULT.TAG
        );
    },
    computed: {
        ...mapGetters([
            "dataSidebarExtend"
        ]),
    },
    watch: {
        filterText(val) {
            if (this.allowfilter) {
                this.$refs.treeTag.filter(val);
            }
        },
        dataSidebarExtend: {
            deep: true,
            handler(val) {
                const dataExtend = _.cloneDeep(val);
                this.dataSelector = dataExtend[0].folder.filter(
                    (x) => x.id == FOLDER_DEFAULT.TAG)
            }
        }
    },
    methods: {
        blurInput() {
            this.filterText = this.filterText.trim()
        },
        filterNode(value, data) {
            if (this.allowfilter) {
                if (!value) return true;
                return data.name.toString().indexOf(value.toString().trim()) !== -1;
            }
            return true;
        },
        /**
         * Hàm thực hiện tìm kiếm khi click tag
         */
        onClickTagTree(event) {
      
            if(!event.id){
                return;
            }
            if (this.isRootEmitEvt) {
                this.$root.$emit("onClickTagTree", event.name)
            } else {
                if (!this.timer) {
                    this.timer = setTimeout(() => {
                        this.$emit("nodeClick", event, false);
                        clearTimeout(this.timer);
                        this.timer = null;
                    },200)
                } else {
                    clearTimeout(this.timer);
                    this.timer = null;
                    this.$emit("nodeClick", event, true);
                }
            }
        },
        allowDrop(draggingNode, dropNode, type) {
            if (draggingNode.level === dropNode.level) {
                if (draggingNode.parent.id === dropNode.parent.id) {
                    return type === "prev" || type === "next";
                }
            } else {
                return false;
            }
        },
        allowDrag() {
            return true;
        },
    },
};
</script>
