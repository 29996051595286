<template>
    <div class="header-pri">
        <div class="head-pri">
            <span class="icon">
                <img src="@/assets/zimbra/images/book-saved-active.svg" alt="logo-contact">
            </span>
            <span>{{ this.$t("zimbra.zhMsg.addressBook") }}</span>
        </div>
        <button class="btn-close" @click="handleRedirect">
            <i class="el-icon-close"></i>
        </button>
    </div>
</template>

<script>
export default {
    methods: {
        hasHistory() {
            return window.history.length > 2;
        },
        handleRedirect() {
            window.location.href = "/mail";
        }
    }
};
</script>
<style lang="scss">
@import "@/assets/scss/contact/main.scss";
</style>
