import NProgress from 'nprogress';
import { ERROR_CODE } from "@/utils/constants";
import {
    Notification
} from 'element-ui';
import i18n from "@/plugins/i18n";
import CONFIG from "@/config/config";

let countReq = 0; // Số lượng request đang thực thi

function handleProcessing(isEndRequest) {
    const appZimbra = document.getElementById('appZimbra');
    if (!appZimbra) {
        return;
    }
    if (isEndRequest) {
        countReq--;
        if (countReq <= 0) {
            setTimeout(() => {
                NProgress.done();
                appZimbra.classList.remove("processing");
            }, 500);
        }
    } else {
        countReq++;
        setTimeout(() => {
            NProgress.start();
            if (!appZimbra.classList.contains('processing')) {
                appZimbra.classList.add("processing");
            }
        }, 300);
    }
}

function interceptorsRequest(instance, withProgress) {
    instance.interceptors.request.use(
        config => {
            if (withProgress) {
                handleProcessing(false);
            }
            return config
        },
        err => {
            // console.log('interceptorsRequest err', err);
            if (withProgress) {
                handleProcessing(true);
            }
            return Promise.reject(err)
        }
    )
}

function interceptorsResponse(instance, withProgress, withNotify) {
    instance.interceptors.response.use(
        response => {
            if (withProgress) {
                handleProcessing(true);
            }
            if (response['data'] && response['data']['soap:Envelope']) {
                const header = response['data']['soap:Envelope']['soap:Header'];
                const store = localStorage.getItem('zimbra_token');
                if (store != null) {
                    let zimbraToken = JSON.parse(store) || {};
                    const { id } = zimbraToken.session || {};
                    if (header?.context?.session && id !== header.context.session.id) {
                        zimbraToken = {
                            ...zimbraToken,
                            session: header?.context?.session
                        };
                        localStorage.setItem('zimbra_token', JSON.stringify(zimbraToken || {}));
                    }
                }
            }
            return response
        },
        error => {
            
            if (withProgress) {
                handleProcessing(true);
            }
            // if (error.response && error.response.status == 502 && withNotify) {
            //     Notification({
            //         title: i18n.t("zimbra.zmMsg.dataSourceTestFailure"),
            //         message: i18n.t(`zimbra.zhMsg.MO_networkError`),
            //         type: 'error',
            //     });
            //     return;
            // }

            if (error.response) {
                const errorCode = getResponseBody(error.response)["soap:Fault"]?.detail?.Error?.Code;
                if (errorCode == ERROR_CODE.SERVICE_AUTH_EXPIRED || errorCode == ERROR_CODE.SERVICE_AUTH_REQUIRED) {
                    window.location = `/${CONFIG.LOGIN_PAGE}`;
                }

                // if (withNotify) {
                //     const msg = i18n.te(`zimbra.error.${errorCode}`) ? i18n.t(`zimbra.error.${errorCode}`) : i18n.t(`zimbra.error.common`);
                //     Notification({
                //         title: i18n.t("zimbra.zmMsg.dataSourceTestFailure"),
                //         message: msg,
                //         type: 'error',
                //     });
                // }
            }

            return Promise.reject(error)
        }
    )
}

function getResponseBody(response) {
    return response['data']['soap:Envelope']['soap:Body'];
}

export default {
    interceptorsRequest,
    interceptorsResponse
}
