<template>
    <el-form
        class="zimbra-search-box zm-briefcase-search search-box"
        id="searchBox"
        style="display: block"
        @keypress.enter.native="handleSearch"
    >
    <!-- Bộ lọc nâng cao -->
    <div class="zimbra form-search-group">
        <label class="text header">
            {{ $t("zimbra.zmMsg.advancedFilters") }}
        </label>
    </div>
    <!-- Ngày -->
    <el-popover
        placement="right"
        trigger="manual"
        v-model="isVisibleDay"
    >
        <div class="dropdown-tag-search">
            <briefcase-search-day
                @onSelectDay="onPushQueryContent"
            />
        </div>
        <div @click="isVisibleDay = !isVisibleDay"  id="daySearch"  slot="reference" class="btn-condition button-hover">
            <a class="text" id="daySearch">{{ $t("zimbra.zhMsg.EFILT_NEW_COND_DATE") }}</a>
        </div>
    </el-popover>
    <!-- Kích cỡ -->
    <el-popover
        placement="right"
        trigger="manual"
        v-model="isVisibleSize"
    >
        <div class="dropdown-tag-search">
            <briefcase-search-size
                @onSelectSize="onPushQueryContent"
            />
        </div>
        <div @click="isVisibleSize = !isVisibleSize"  id="sizeSearch"  slot="reference" class="btn-condition button-hover">
            <a class="text" id="sizeSearch">{{ $t("zimbra.zhMsg.EFILT_NEW_COND_SIZE") }}</a>
        </div>
    </el-popover>
    <!-- Thẻ -->
    <el-popover
        placement="right"
        trigger="manual"
        v-model="isVisibleTag"
        v-if="listTag && listTag.length > 0"
    >
        <div class="dropdown-tag-search">
            <briefcase-tag-search
                @onSelectTag="onPushQueryContent"
            />
        </div>
        <div @click="isVisibleTag = !isVisibleTag"  id="tagSearch" slot="reference" class="btn-condition button-hover">
                <span class="text" id="tagSearch">{{ $t("zimbra.zmMsg.filterTag") }}</span>
        </div>
    </el-popover>

    <!-- Cặp tài liệu -->
    <el-popover
        placement="right"
        trigger="manual"
        v-model="isVisibleTree"
    >
        <div class="dropdown-tag-search">
            <perfect-scrollbar style="max-height: 300px; height: auto; min-width: 350px;">
                <tree-briefcase-extra
                    @onSelectFolder="onSelectTree"
                    :showInputSearch="false"
                    :isRootSearchFolder="false"
                    :isShowRightMenu="false"
                />
            </perfect-scrollbar>
        </div>
        <div @click="isVisibleTree = !isVisibleTree"  id="treeSearch" slot="reference" class="btn-condition button-hover">
            <span class="text" id="treeSearch">{{ $t("zimbra.zmMsg.filterBriefcase") }}</span>
        </div>
    </el-popover>
    <div class="btn-search-box">
        <button type="button" class="reset" @click="handleReset">
            {{ $t("zimbra.zmMsg.revert") }}
        </button>
        <el-button type="submit" class="search" ref="refBtnSearch" native-type="submit" @click="handleSearch">
            {{ $t("zimbra.zhMsg.search") }}
        </el-button>
    </div>
    </el-form>
</template>
<script>
import { FOLDER_DEFAULT, BRIEFCASE_RIGHT_MENU_TYPE, LIST_TYPE_SEARCH } from "@/utils/constants";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import moment from "moment";
import { mapGetters } from "vuex";
import BriefcaseSearchSize from './sub-form/briefcase-search-size.vue';
import BriefcaseSearchDay from './sub-form/briefcase-search-day.vue';
import BriefcaseTagSearch from './sub-form/briefcase-tag-search.vue';
// import TreeBriefcase from '@/components/modules/briefcase/components/TreeBriefCase.vue'
import TreeBriefcaseExtra from '../TreeBriefcaseExtra.vue';
export default {
    name: "CalSearchDialog",
    components: {
        BriefcaseSearchSize,
        BriefcaseSearchDay,
        BriefcaseTagSearch,
        TreeBriefcaseExtra
    },
    mixins: [CommonUtils],
    props: {
        value: {
            type: Object
        }
    },
    data() {
        return {
            isVisibleDay: false,
            isVisibleSize: false,
            isVisibleTag: false,
            isVisibleTree: false,
            hasAttachment: false,
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            model: this.value
                ? {
                    ...this.value,
                    sizeUnit: this.value.sizeUnit ?? "MB",
                    searchInName: this.value.searchInName ?? this.$t("zimbra.zmMsg.allFolders"),
                    date: this.value.date ?? moment(new Date()).format("D/M/YY")
                }
                : {
                    sizeUnit: "MB",
                    searchInName: this.$t("zimbra.zmMsg.allFolders"),
                    date: moment(new Date()).format("D/M/YY")
                },
            visableFolder: false,
            optionsSize: [
                {
                    value: "larger",
                    label: this.$t("zimbraNokey.zimbra_searchBox_greaterThan"),
                },
                {
                    value: "smaller",
                    label: this.$t("zimbraNokey.zimbra_searchBox_littleThan"),
                },
            ],
            optionsTime: [
                {
                    value: "before",
                    label: this.$t("zimbra.zhMsg.MO_PREV"),
                },
                {
                    value: "after",
                    label: this.$t("zimbra.zmMsg.after"),
                },
                {
                    value: "date",
                    label: this.$t("zimbraNokey.zimbra_searchBox_within"),
                },
            ],
            optionsSizeUnit: [
                {
                    value: "KB",
                    label: this.$t("zimbraNokey.zimbra_searchBox_kb"),
                },
                {
                    value: "MB",
                    label: this.$t("zimbraNokey.zimbra_searchBox_mb"),
                },
            ],
            searchNotFound: this.$t("zimbraNokey.zimbra_common_searchNotFound"),
            listEmail: [],
            today: moment(new Date()).format("D/M/YY")
        };
    },
    computed: {
        ...mapGetters(["listTag"])
    },
    mounted() {
        this.$el.addEventListener('click', this.onClickOutSite);
        this.$hotkeys('enter', 'shortcut_navbar_search', this.handleSearch)
        this.$hotkeys.setScope('shortcut_navbar_search')
    },
    destroyed() {
        this.$el.removeEventListener('click', this.onClickOutSite);
        this.$hotkeys.deleteScope('shortcut_navbar_search')
    },
    methods: {
        handleReset() {
            this.$emit("onReset");
        },
        handleSearch() {
            this.$emit("onsearch", this.model);
          

        },
        onSelectFolder(data) {
            this.visableFolder = false;
            this.model.searchIn = data;
            this.model.searchInName = data.absFolderPathI18n;
        },
        remoteMethodEmail(query) {
            if (query !== "") {
                const form = { name: `${query}` };
                ZimbraMailService.autoCompleteRequest(form)
                    .then((res) => {
                        const data =
                            this.getResponseBody(res)?.AutoCompleteResponse
                                ?.match;
                        this.listEmail = Array.isArray(data) ? data : (data ? [data] : []);
                        if (this.listEmail) {
                            this.listEmail.forEach((x) => {
                                if (x) {
                                    x.label = x ? x.email.substring(
                                        x.email.indexOf("<") + 1,
                                        x.email.length - 1
                                    ) : '';
                                }
                            });
                        }
                    })
                    .catch();
            } else {
                this.listEmail = [];
            }
        },

        /**
         * Hàm chọn tree
         */
        onSelectTree(treeNode) {
            // Nếu là tree gốc thì không làm gì
            if (treeNode.id == BRIEFCASE_RIGHT_MENU_TYPE.TREE_BRIEFCASE_ROOT) {
                return;
            }
            // Nếu là calendar thường thì bỏ ký tự đầu tiên đi vì là dầu /
            let pathSearch = treeNode.absFolderPath.substring(1)
            if (!this.isNormalCharacter(pathSearch)) {
                pathSearch = `"${pathSearch}"`
            }
            const _content = `in:${pathSearch}`
            this.onPushQueryContent(_content, LIST_TYPE_SEARCH.FOLDER);
        },

        /**
         * Hàm đẩy thêm điều kiện tìm kiếm
         */
        onPushQueryContent(queryContent, type) {
            this.isVisibleDay = false;
            this.isVisibleAttachmentFile = false;
            this.isVisibleTag = false;
            this.isVisibleTree = false;
            this.$emit("onPushQueryContent", queryContent, type);
        },

        /**
         * Hàm xác định click outsite để đóng popover
         */
        onClickOutSite(element) {
            const id = element.srcElement.id;
            if (id != "daySearch") {
                this.isVisibleDay = false
                this.$root.$emit("closePoverSizeSearch")
            }
            if (id != "sizeSearch") {
                this.isVisibleSize = false
                this.$root.$emit("closePoverDaySearch")
            }
            if (id != "tagSearch") {
                this.isVisibleTag = false
                this.$root.$emit("closePoverDaySearch")
                this.$root.$emit("closePoverSizeSearch")
            }
            if (id != "treeSearch") {
                this.isVisibleTree = false
                this.$root.$emit("closePoverDaySearch")
                this.$root.$emit("closePoverSizeSearch")
            }
        }
    },
};
</script>
<style scoped>
.search-box {
    right: -94px;
    width: 30rem;
}
.header {
    font-weight: 500;
    color: #3F4254;
}
.btn-condition {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #2E3669;
    font-weight: 500;
    flex: 0 0 10.8rem;
    height: 3.4rem;
    border: 1px solid #4A528A;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 10px;
}
.button-hover:hover {
    cursor: pointer;
}
</style>
