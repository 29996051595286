<template>
    <!-- <perfect-scrollbar> -->
    <div class="setting-tree-wrap">
        <div>
            <el-input
                class="el-text-control"
                v-model="filterText"
                style="padding-bottom: 10px"
                size="medium"
                ref="refInputSearch"
                @blur="blurInput"
            ></el-input>
        </div>
        <perfect-scrollbar style="height: 300px" id="treeExtra">
            <el-tree
                v-if="folderType == FOLDER_TYPE.FOLDER" 
                ref="treeInbox"
                class="tree-wrap disabled-root-node"
                node-key="id"
                style="margin-top: 10px"
                :data="dataInboxSelector"
                :props="defaultProps"
                @node-click="nodeClick"
                :expand-on-click-node="false"
                draggable
                highlight-current
                :filter-node-method="filterNode"
                empty-text=""
                :default-expanded-keys="listIdExpanded"
            >
                <div class="zm-folder-info" slot-scope="{ node, data }">
                    <span slot="reference" class="custom-tree-node">
                        <span
                            v-if="
                                data.id != FOLDER_DEFAULT.DRAFTS &&
                                data.id != FOLDER_DEFAULT.INBOX &&
                                data.id != FOLDER_DEFAULT.SENT &&
                                data.id != FOLDER_DEFAULT.STARS &&
                                data.id != FOLDER_DEFAULT.ROOT &&
                                data.isLink == undefined
                            "
                        >
                            <i
                                class="ic-folder"
                                :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(
                                    data
                                )}`"
                            ></i>
                        </span>
                        <span v-if="data.isLink">
                            <i
                                class="ic-folder-copy"
                                style="
                                    padding-right: 30px;
                                    margin-left: -8px;
                                "
                            ></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.STARS">
                            <i
                                class="ic-star"
                                style="
                                    padding-right: 30px;
                                    margin-left: -8px;
                                "
                            ></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.SENT">
                            <i
                                class="ic-sent"
                                style="
                                    padding-right: 30px;
                                    margin-left: -8px;
                                "
                            ></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.DRAFTS">
                            <i
                                class="ic-draft"
                                style="
                                    padding-right: 30px;
                                    margin-left: -8px;
                                    zoom: 1.05;
                                "
                            ></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.INBOX">
                            <i
                                :class="`ic-inbox ${
                                    node.expanded == true ? 'active' : ''
                                }`"
                                style="
                                    padding-right: 30px;
                                    margin-left: -8px;
                                    zoom: 1.05;
                                "
                            ></i>
                        </span>
                        <span
                            v-if="
                                data.isLink == undefined ||
                                (data.isLink && data.perm)
                            "
                        >
                            <span v-if="node.label.length > 30">
                                {{ node.label.substring(0, 30) + "..." }}
                            </span>
                            <span v-else>
                                {{ node.label }}
                            </span>
                        </span>
                        <span
                            v-if="data.isLink && data.perm == undefined"
                            style="text-decoration: line-through"
                            >{{ node.label }}
                        </span>
                    </span>
                </div>
            </el-tree>
            <el-tree
                ref="tree"
                class="tree-wrap disabled-root-node"
                node-key="id"
                style="margin-top: 10px"
                :data="dataSelector"
                :props="defaultProps"
                @node-click="nodeClick"
                :expand-on-click-node="false"
                draggable
                highlight-current
                :filter-node-method="filterNode"
                empty-text=""
                :default-expanded-keys="listIdExpanded"
            >
                <div class="zm-folder-info" slot-scope="{ node, data }">
                    <span class="custom-tree-node">
                        <span
                            v-if="
                                data.id != FOLDER_DEFAULT.TRASH &&
                                data.id != FOLDER_DEFAULT.JUNK &&
                                data.id != FOLDER_DEFAULT.EXTEND &&
                                data.id != FOLDER_DEFAULT.TAG &&
                                data.isLink == undefined
                            "
                        >
                            <i
                                class="ic-folder"
                                :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(
                                    data
                                )}`"
                            ></i>
                        </span>
                        <span v-if="data.isLink">
                            <i
                                class="ic-folder-copy"
                                :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(
                                    data
                                )}`"
                            ></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.JUNK">
                            <i
                                class="ic-spam"
                                style="
                                    padding-right: 28px;
                                    margin-left: -9px;
                                "
                            ></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.TRASH">
                            <i
                                class="ic-trash"
                                style="
                                    padding-right: 28px;
                                    margin-left: -9px;
                                "
                            ></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.TAG">
                            <i
                                class="ic-tag"
                                style="
                                    padding-right: 28px;
                                    margin-left: -9px;
                                "
                            ></i>
                        </span>
                        <span
                            v-if="
                                data.id != FOLDER_DEFAULT.EXTEND &&
                                (data.isLink == undefined ||
                                    (data.isLink && data.perm))
                            "
                            >{{ node.label }}</span
                        >
                        <span
                            v-if="data.id == FOLDER_DEFAULT.EXTEND"
                            >{{
                                node.expanded
                                    ? $t("zimbra.zmMsg.collapse")
                                    : $t("zimbraNokey.zimbra_sidebar_extend")
                            }}</span
                        >
                        <span
                            v-if="data.isLink && data.perm == undefined"
                            style="text-decoration: line-through"
                            >{{ node.label }}</span
                        >
                    </span>
                </div>
            </el-tree>
        </perfect-scrollbar>
    </div>
    <!-- </perfect-scrollbar> -->
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import { FOLDER_DEFAULT, FOLDER_TYPE } from "@/utils/constants";
import { mapGetters } from "vuex";
import _ from "lodash";
export default {
    mixins: [CommonUtils],
    props: {
        folderType: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            defaultProps: {
                children: "folder",
                label: "name",
            },
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            FOLDER_TYPE: FOLDER_TYPE,
            dataSelector: [],
            dataInboxSelector: [],
            filterText:"",
            timer: null,
            listIdExpanded: [FOLDER_DEFAULT.TAG, FOLDER_DEFAULT.ROOT],
            dataInbox: []
        };
    },
    watch: {
        filterText(val) {
            if (this.allowfilter) {
                this.$refs.tree.filter(val);
                this.$refs.treeInbox.filter(val);
            }
        },
        dataSidebarExtend: {
            deep: true,
            handler(val) {
                const dataExtend = _.cloneDeep(val);
                if (this.folderType == FOLDER_TYPE.TAG) {
                    this.dataSelector = dataExtend[0].folder.filter(
                        (x) => x.id == FOLDER_DEFAULT.TAG
                    );
                } else if (this.folderType == FOLDER_TYPE.SEARCH) {
                    this.dataSelector = dataExtend[0].folder.filter(
                        (x) => x.id == FOLDER_DEFAULT.ROOT
                    );
                }
            }
        },
        folderType() {
            const dataExtend = _.cloneDeep(this.dataSidebarExtend);
            this.dataInboxSelector = _.cloneDeep(this.dataSidebarInBox);
            if (this.folderType == FOLDER_TYPE.TAG) {
                this.dataSelector = dataExtend[0].folder.filter(
                    (x) => x.id == FOLDER_DEFAULT.TAG
                );
            } else if (this.folderType == FOLDER_TYPE.SEARCH) {
                this.dataSelector = dataExtend[0].folder.filter(
                    (x) => x.id == FOLDER_DEFAULT.ROOT
                );
            } else {
                dataExtend[0].folder = dataExtend[0].folder.filter(
                    (x) => x.id != FOLDER_DEFAULT.ROOT && x.id != FOLDER_DEFAULT.TAG
                );
                this.dataSelector = dataExtend;
            }
        },
        dataSelector: {
            deep: true,
            handler() {
            }
        },
    },
    computed: {
        ...mapGetters([
            "dataSidebarExtend",
            "dataSidebarInBox"
        ]),
    },
    created() {
        const dataExtend = _.cloneDeep(this.dataSidebarExtend);
        this.dataInboxSelector = _.cloneDeep(this.dataSidebarInBox);
        if (this.folderType == FOLDER_TYPE.TAG) {
            this.dataSelector = dataExtend[0].folder.filter(
                (x) => x.id == FOLDER_DEFAULT.TAG
            );
        } else if (this.folderType == FOLDER_TYPE.SEARCH) {
            this.dataSelector = dataExtend[0].folder.filter(
                (x) => x.id == FOLDER_DEFAULT.ROOT
            );
        } else {
            dataExtend[0].folder = dataExtend[0].folder.filter(
                (x) => x.id != FOLDER_DEFAULT.ROOT && x.id != FOLDER_DEFAULT.TAG
            );
            this.dataSelector = dataExtend;
        }
    },
    methods: {
        blurInput() {
            this.filterText = this.filterText.trim()
        },
        filterNode(value, data) {
            if (this.allowfilter) {
                if (!value) return true;
                return data.name.toString().indexOf(value.toString().trim()) !== -1;
            }
            return true;
        },
        nodeClick(data) {           
            if (!this.timer) {
                this.timer = setTimeout(() => {
                    this.$emit("selectFolder", data, false);
                    clearTimeout(this.timer);
                    this.timer = null;
                },200)
            } else {
                clearTimeout(this.timer);
                this.timer = null;
                this.$emit("selectFolder", data, true);
            }

        },
    },
};
</script>
