const ZmCalendarApp = {};

ZmCalendarApp.CUTYPE_INDIVIDUAL = "IND";
ZmCalendarApp.CUTYPE_GROUP = "GRO";
ZmCalendarApp.CUTYPE_RESOURCE = "RES";
ZmCalendarApp.CUTYPE_ROOM = "ROO";
ZmCalendarApp.CUTYPE_UNKNOWN = "UNK";

/**
 * Type:String
* Status - TENT|CONF|CANC|NEED|COMP|INPR|WAITING|DEFERRED
* i.e. TENTative, CONFirmed, CANCelled, COMPleted, INPRogress, WAITING, DEFERRED
* where waiting and Deferred are custom values not found in the iCalendar spec.
 */
ZmCalendarApp.STATUS_CANC = "CANC"; // cancelled
ZmCalendarApp.STATUS_COMP = "COMP"; // completed
ZmCalendarApp.STATUS_CONF = "CONF"; // confirmed
ZmCalendarApp.STATUS_DEFR = "DEFERRED"; // deferred
ZmCalendarApp.STATUS_INPR = "INPR"; // inprogress
ZmCalendarApp.STATUS_NEED = "NEED"; // need
ZmCalendarApp.STATUS_TENT = "TENT"; // tentative
ZmCalendarApp.STATUS_WAIT = "WAITING"; // waiting

ZmCalendarApp.METHOD_CANCEL = "CANCEL";
ZmCalendarApp.METHOD_PUBLISH = "PUBLISH";
ZmCalendarApp.METHOD_REPLY = "REPLY";
ZmCalendarApp.METHOD_REQUEST = "REQUEST";
ZmCalendarApp.METHOD_COUNTER = "COUNTER";

ZmCalendarApp.DEFAULT_WORKING_HOURS = "1:N:0800:1700,2:Y:0800:1700,3:Y:0800:1700,4:Y:0800:1700,5:Y:0800:1700,6:Y:0800:1700,7:N:0800:1700";
ZmCalendarApp.DEFAULT_APPT_DURATION = "60"; //60minutes

export default ZmCalendarApp;