import {
    ZmContact,
    CONTACT_ACTION,
    CONTACT_UTILS,
    SIDEBAR_NODE,
    FOLDER_DEFAULT,
    FOLDER_ORDER,
    FOLDER_RENAME,
    CONTACT_ALPHABET_TEST
} from "@/utils/constants"
import { v4 as uuidv4 } from "uuid"
import CONFIG from "@/config/config";
import { mapGetters, mapActions } from "vuex"
import _ from "lodash"
import i18n from "@/plugins/i18n";
import ContactService from "../services/contacts/ContactService";
import CommonUtils from "@/utils/common-utils";
import ZimbraMailService from "../services/ZimbraMailService"
import color from "@/components/modules/layouts/listColor";
export default {
    mixins: [CommonUtils],
    data() {
        return {
            dataTag: [],
            dataContact: [],
            dataExtend: [],
            dataFolderArchive: {},
            defaultProps: {
                children: "folder",
                label: "name",
            },
            ZmContact: ZmContact,
            CONTACT_ACTION: CONTACT_ACTION,
            CONTACT_UTILS: CONTACT_UTILS,
            CONTACT_ALPHABET_TEST: CONTACT_ALPHABET_TEST
        }
    },
    computed: {
        ...mapGetters([
            "tags",
            "listTag",
            "contacts",
            "preference",
            "contactGroup",
            "activeContact",
            "localeCurrent",
            "folderSearches",
            "contactAlphabet",
            "timeZoneCurrent",
            "listFileContacts",
            "activeNodeContact",
            "distributionLists",
            "dataSidebarExtend",
            "dataSidebarContact",
            "listSelectedContact",
            "dataSidebarExtendContact"
        ])
    },
    methods: {
        ...mapActions([
            "modifyContactRequest"
        ]),
        handleJsonToString(obj) {
            return JSON.stringify(obj);
        },
        objectToArray(parent) {
            const _list = parent.map(ele => {
                if (!Array.isArray(ele.a)) {
                    return {
                        ...ele,
                        a: [ele.a]
                    }
                }
                return ele
            })
            return _list
        },
        toArray(parent) {
            let _list = []
            if (Array.isArray(parent)) {
                _list = parent
            } else {
                _list.push(parent)
            }
            return _list
        },
        handleBuildDataContact(data) {
            if (data) {
                data.a = this.toArray(data.a);

                const regexWork = /(work)/;
                const filteredListWork = data.a.filter(str => regexWork.test(str.n));
                const regexHome = /(home)/;
                const filteredListHome = data.a.filter(str => regexHome.test(str.n));
                const regexOther = /(other)/;
                const filteredListOther = data.a.filter(str => regexOther.test(str.n));
                return {
                    id: data.id,
                    avtUrl: this.renderAvtUrl(data),
                    info: this.renderInfo(data),
                    fullName: this.renderFullName(data),
                    viewName: this.renderViewName(data),
                    name: this.renderName(data),
                    jobTitle: this.renderJobTitle(data),
                    departmentTitle: this.renderDepartmentTitle(data),
                    companyTitle: this.renderCompanyTitle(data),
                    tags: this.renderTags(data),
                    attrs: this.renderAttrsTitle(data.a),

                    emailTitle: this.renderEmailTitle(data),
                    phoneTitle: this.renderPhoneTitle(data),
                    imTitle: this.renderImTitle(data),
                    addressHomeTitle: this.renderAddressTitle(filteredListHome),
                    addressWorkTitle: this.renderAddressTitle(filteredListWork),
                    addressOtherTitle: this.renderAddressTitle(filteredListOther),
                    urlTitle: this.renderUrlTitle(data),
                    otherTitle: null,
                    // otherTitle: this.renderOrtherTitle(data),
                    notesTitle: this.renderNotesTitle(data)
                };
            }
            return {}
        },
        getQueryExtraContact() {
            let queryExtra = "";
            const listFolderSharedInbox = this.getListFolderShared(this.dataSidebarContact[0].folder);
            const listFolderSharedExtend = this.getListFolderShared(this.dataSidebarExtend[0].folder);
            const listFolderShared = _.concat(listFolderSharedInbox, listFolderSharedExtend);
            if (this.preference.zimbraPrefIncludeSharedItemsInSearch && listFolderShared.length > 0) {
                const listFolderId = listFolderShared.map(e => `inid:"${e.id}"`);
                listFolderId.push("is:local");
                queryExtra = listFolderId.join(" OR ");
            }
            return queryExtra;
        },
        hanldePrevContact() {
            this.$root.$emit("handleHideItemContact", false);
        },
        renderAvtUrl(data) {
            if (data && data.a) {
                const url = data.a.find(ele => ele.n === "image")
                return url ? `${CONFIG.ENDPOINT["homeService"]}/~/?auth=co&id=${data.id}&part=1&max_width=48&max_height=48&t=${new Date().getTime()}` : null;
            }
            return null
        },
        renderInfo(data) {
            if (data && data.a) {
                const _attrInfo = ["namePrefix", "firstName", "middleName", "maidenName", "lastName", "nameSuffix", "nickname", "jobTitle", "department", "company", "fileAs"]
                const _list = data.a.filter(attr => _attrInfo.indexOf(attr.n) > -1)
                return _list
            }
            return null
        },
        renderFullName(data) {
            if (data && data.fileAsStr) {
                return data.fileAsStr
            }
            return this.$t("zimbra.zhMsg.noName")
        },
        renderName(data) {
            if (data.a.length) {
                const rule = ["firstName", "middleName", "lastName"]
                let fname = []
                data.a.forEach(attr => {
                    const _index = rule.findIndex(item => item === attr.n)
                    if (_index !== -1) {
                        fname[_index] = attr.content
                    }
                });
                return fname.join(" ").trim()
            }
            return null
        },
        renderViewName(data) {
            if (data && data.a) {
                const _attrInfo = ["namePrefix", "firstName", "middleName", "maidenName", "lastName", "nameSuffix", "nickname"]
                let _list = [];
                data.a.forEach(ele => {
                    const _index = _attrInfo.findIndex(attr => attr === ele.n)
                    if (_index !== -1) {
                        switch (_index) {
                            case 3:
                                _list[_index] = `(${ele.content})`;
                                break;
                            case 5:
                                _list[_index] = `, ${ele.content}`;
                                break;
                            case 6:
                                _list[_index] = `"${ele.content}"`;
                                break;
                            default:
                                _list[_index] = ele.content;
                                break;
                        }
                    }
                });
                return _list.length ? _list.join(' ') : this.$t("zimbra.zhMsg.noName");
            }
            return this.$t("zimbra.zhMsg.noName")
        },
        renderFolderParent(data) {
            if (data && data.l) {
                const _temp = data.l.toString().indexOf(":")
                let _ids = null
                if (_temp > -1) {
                    const id = data.l.slice(_temp + 1);
                    _ids = parseInt(id)
                } else {
                    _ids = data.l
                }
                const parent = this.dataSidebarContact && this.findFolderById(this.dataSidebarContact[0].folder, _ids)
                return parent ? parent : null
            }
            return null
        },
        findFolderById(listFolder, id) {
            for (let index in listFolder) {
                if (listFolder[index].id == id || listFolder[index].rid == id) {
                    return listFolder[index];
                } else if (listFolder[index].folder) {
                    const result = this.findFolderById(listFolder[index].folder, id);
                    if (result != null) {
                        return result;
                    }
                }
            }
            return null;
        },
        renderJobTitle(data) {
            if (data && data.a) {
                const _job = data.a.find(ele => ele.n === "jobTitle")
                return _job ? _job.content : ""
            }
            return null
        },
        renderDepartmentTitle(data) {
            if (data && data.a) {
                const _job = data.a.find(ele => ele.n === "department")
                return _job ? _job.content : ""
            }
            return null
        },
        renderCompanyTitle(data) {
            if (data && data.a) {
                const _company = data.a.find(ele => ele.n === "company")
                return _company ? _company.content : ""
            }
            return null
        },
        renderTags(data) {
            if (data && !_.isNil(data.t)) {
                if (Number.isInteger(data.t)) {
                    // TH co 1 tag
                    const tagAssigned = Array.isArray(this.tags) ? this.tags.find(e => data.t == e.id) : this.tags;
                    return [tagAssigned];
                }
                const tagIds = data.t.split(",").map(e => parseInt(e, 10));
                const tags = Array.isArray(this.tags) ? this.tags : [this.tags || {}];
                const tagAssigned = tags.filter(e => tagIds.includes(e.id));
                return tagAssigned.length ? tagAssigned : [];
            }
            return [];
        },
        renderEmailTitle(data) {
            if (data && data.a) {
                const regexEmail = /(email)/;
                let _list = data.a.filter(attr => regexEmail.test(attr.n))
                _list = _.orderBy(_list, [(e) => { return e.n }], ["asc"])
                return _list.length ? _list.map(ele => ({ id: uuidv4(), ...ele })) : null
            }
            return null
        },
        renderAttrsTitle(attrs) {
            const _attrInfo = ["namePrefix", "firstName", "middleName", "maidenName", "lastName", "nameSuffix", "nickname", "jobTitle", "department", "company", "fileAs"];
            const returnData = {};
            if (!attrs || attrs.length < 1) {
                return returnData;
            }

            for (let i = 0; i < attrs.length; i++) {
                const item = attrs[i];
                const n = item.n;
                if (_attrInfo.indexOf(n) !== -1) {
                    continue;
                }
                //const content = item.content;
                const nanPropName = this.getNanPropName(n);
                const indexPropName = this.getIndexPropName(nanPropName, n);
                let currentList = returnData[nanPropName] || [];
                currentList[indexPropName] = item;
                returnData[nanPropName] = currentList;
            }
            return returnData;
        },
        getNanPropName(propName) {
            return propName.replace(/\d+$/, '');
        },
        getIndexPropName(nanPropName, propName) {
            const indexStr = propName.replace(nanPropName, '');
            if (indexStr && indexStr.length > 0) {
                return parseInt(indexStr) - 1;
            }
            return 0;
        },
        renderPhoneTitle(data) {
            if (data && data.a) {
                const _list = []
                for (const x in data.a) {
                    for (const y in CONTACT_UTILS.PHONE) {
                        let _typeI18N = ""
                        let _type = ""
                        let _temp = ""
                        if (data.a[x].n.includes(CONTACT_UTILS.PHONE[y])) {
                            _temp = CONTACT_UTILS.PHONE[y]
                            switch (_temp) {
                                case CONTACT_UTILS.PHONE.MOBILE:
                                    _type = CONTACT_UTILS.PHONE.MOBILE
                                    _typeI18N = this.$t("zimbra.zhMsg.AB_FIELD_mobilePhone")
                                    break;
                                case CONTACT_UTILS.PHONE.WORK:
                                    _type = CONTACT_UTILS.PHONE.WORK
                                    _typeI18N = this.$t("zimbra.zhMsg.AB_FIELD_otherOffice")
                                    break;
                                case CONTACT_UTILS.PHONE.WORK_FAX:
                                    _type = CONTACT_UTILS.PHONE.WORK_FAX
                                    _typeI18N = this.$t("zimbra.zhMsg.AB_FIELD_workFax")
                                    break;
                                case CONTACT_UTILS.PHONE.COMPANY:
                                    _type = CONTACT_UTILS.PHONE.COMPANY
                                    _typeI18N = this.$t("zimbra.zhMsg.AB_FIELD_company")
                                    break;
                                case CONTACT_UTILS.PHONE.HOME:
                                    _type = CONTACT_UTILS.PHONE.HOME
                                    _typeI18N = this.$t("zimbra.zhMsg.MO_AB_home")
                                    break;
                                case CONTACT_UTILS.PHONE.HOME_FAX:
                                    _type = CONTACT_UTILS.PHONE.HOME_FAX
                                    _typeI18N = this.$t("zimbra.zhMsg.AB_FIELD_homeFax")
                                    break;
                                case CONTACT_UTILS.PHONE.PAGER:
                                    _type = CONTACT_UTILS.PHONE.PAGER
                                    _typeI18N = this.$t("zimbra.zhMsg.AB_FIELD_pager")
                                    break;
                                case CONTACT_UTILS.PHONE.CALLBACK:
                                    _type = CONTACT_UTILS.PHONE.CALLBACK
                                    _typeI18N = this.$t("zimbra.zhMsg.AB_FIELD_callbackPhone")
                                    break;
                                case CONTACT_UTILS.PHONE.ASSISTANT:
                                    _type = CONTACT_UTILS.PHONE.ASSISTANT
                                    _typeI18N = this.$t("zimbra.zhMsg.MO_AB_assistant")
                                    break;
                                case CONTACT_UTILS.PHONE.CAR:
                                    _type = CONTACT_UTILS.PHONE.CAR
                                    _typeI18N = this.$t("zimbra.zhMsg.AB_FIELD_carPhone")
                                    break;
                                case CONTACT_UTILS.PHONE.OTHER:
                                    _type = CONTACT_UTILS.PHONE.OTHER
                                    _typeI18N = this.$t("zimbra.zhMsg.AB_FIELD_IM_other")
                                    break;
                                case CONTACT_UTILS.PHONE.OTHER_FAX:
                                    _type = CONTACT_UTILS.PHONE.OTHER_FAX
                                    _typeI18N = this.$t("zimbra.zhMsg.AB_FIELD_otherFax")
                                    break;
                                default:
                                    break;
                            }
                            _list.push({
                                id: uuidv4(),
                                ...data.a[x],
                                type: _type,
                                typeI18N: _typeI18N
                            })
                        }
                    }
                }
                return _list.length ? _.orderBy(_list, "n", "asc") : null
            }
            return null
        },
        renderTypePhone(data) {
            let _type = ""
            for (const key in CONTACT_UTILS.PHONE) {
                if (data.n.includes(CONTACT_UTILS.PHONE[key])) {
                    data.n = CONTACT_UTILS.PHONE[key]
                }
            }
            switch (data.n) {
                case CONTACT_UTILS.PHONE.MOBILE:
                    _type = this.$t("zimbra.zhMsg.AB_FIELD_mobilePhone")
                    break;
                case CONTACT_UTILS.PHONE.WORK:
                    _type = this.$t("zimbra.zhMsg.AB_FIELD_otherOffice")
                    break;
                case CONTACT_UTILS.PHONE.WORK_FAX:
                    _type = this.$t("zimbra.zhMsg.AB_FIELD_workFax")
                    break;
                case CONTACT_UTILS.PHONE.COMPANY:
                    _type = this.$t("zimbra.zhMsg.AB_FIELD_company")
                    break;
                case CONTACT_UTILS.PHONE.HOME:
                    _type = this.$t("zimbra.zhMsg.MO_AB_home")
                    break;
                case CONTACT_UTILS.PHONE.HOME_FAX:
                    _type = this.$t("zimbra.zhMsg.AB_FIELD_homeFax")
                    break;
                case CONTACT_UTILS.PHONE.PAGER:
                    _type = this.$t("zimbra.zhMsg.AB_FIELD_pager")
                    break;
                case CONTACT_UTILS.PHONE.CALLBACK:
                    _type = this.$t("zimbra.zhMsg.AB_FIELD_callbackPhone")
                    break;
                case CONTACT_UTILS.PHONE.ASSISTANT:
                    _type = this.$t("zimbra.zhMsg.MO_AB_assistant")
                    break;
                case CONTACT_UTILS.PHONE.CAR:
                    _type = this.$t("zimbra.zhMsg.AB_FIELD_carPhone")
                    break;
                case CONTACT_UTILS.PHONE.OTHER:
                    _type = this.$t("zimbra.zhMsg.AB_FIELD_IM_other")
                    break;
                case CONTACT_UTILS.PHONE.OTHER_FAX:
                    _type = this.$t("zimbra.zhMsg.AB_FIELD_otherFax")
                    break;
                default:
                    break;
            }
            return _type
        },
        renderImTitle(data) {
            if (data && data.a) {
                const _rule = ["xmpp", "yahoo", "aol", "msn", "im",]
                const _list = []
                data.a.forEach(ele => {
                    if (ele.n.includes("imAddress")) {
                        const _indexOf = ele.content.indexOf(":")
                        const _type = _rule.indexOf(ele.content.substring(0, _indexOf))
                        _list.push({
                            ...ele,
                            id: uuidv4(),
                            type: _type !== -1 ? _rule[_type] : _rule[0],
                        })
                    }
                })
                return _list.length ? _list : null
            }
            return null
        },
        renderAddressTitle(data) {
            const cloneData = _.cloneDeep(data);
            let pattern = /\d+$/ig;
            const objectInfor = {};
            cloneData.forEach(e => {
                const match = e.n.match(pattern);
                const strRemoveNumber = e.n.replace(/[0-9]/g, '');
                if (match?.length > 0) {
                    objectInfor[match[0]] = objectInfor[match[0]] ? objectInfor[match[0]] : {};
                    objectInfor[match[0]][`${strRemoveNumber.replace(/\\d+/g, '')}`] = e.content;
                } else {
                    objectInfor['0'] = objectInfor['0'] ? objectInfor['0'] : {};
                    objectInfor['0'][`${strRemoveNumber.replace(/\\d+/g, '')}`] = e.content;
                }
            });
            return Object.keys(objectInfor).length > 0 ? objectInfor : null;
        },
        renderUrlTitle(data) {
            if (data && data.a) {
                const _list = []
                data = data.a
                for (const x in data) {
                    let _type = ""
                    let _typeI18N = ""
                    let _temp = ""
                    for (const y in CONTACT_UTILS.URL_TYPE) {
                        if (data[x].n.includes(CONTACT_UTILS.URL_TYPE[y])) {
                            _temp = CONTACT_UTILS.URL_TYPE[y]
                            switch (_temp) {
                                case CONTACT_UTILS.URL_TYPE[0]:
                                    _type = CONTACT_UTILS.URL_TYPE[0]
                                    _typeI18N = this.$t("zimbra.zhMsg.home")
                                    break
                                case CONTACT_UTILS.URL_TYPE[1]:
                                    _type = CONTACT_UTILS.URL_TYPE[1]
                                    _typeI18N = this.$t("zimbra.zhMsg.AB_FIELD_otherOffice")
                                    break
                                case CONTACT_UTILS.URL_TYPE[2]:
                                    _type = CONTACT_UTILS.URL_TYPE[2]
                                    _typeI18N = this.$t("zimbra.zhMsg.AB_FIELD_IM_other")
                                    break
                                default:
                                    break
                            }
                            _list.push({
                                id: uuidv4(),
                                ...data[x],
                                type: _type,
                                typeI18N: _typeI18N
                            })
                            break;
                        }
                    }
                }
                return _list.length ? _.orderBy(_list, "type", "asc") : null
            }
            return null
        },
        renderOtherTitle(data) {
            const _list = []
            if (data && data.a) {
                let typeI18N = ""
                let type = ""
                const _rulesOther = ["birthday", "anniversary", "custom"]
                const _ruleInfo = ["namePrefix", "firstName", "middleName", "maidenName", "lastName", "nameSuffix", "nickname", "jobTitle", "company", "department", "fullName", "fileAs", "notes", "image"]
                const _ruleEmail = ["email"]
                const _rulePhone = [
                    "mobilePhone",
                    "workPhone",
                    "workFax",
                    "companyPhone",
                    "homePhone",
                    "homeFax",
                    "pager",
                    "callbackPhone",
                    "assistantPhone",
                    "carPhone",
                    "otherPhone",
                    "otherFax"
                ]
                const _ruleIM = ["imAddress"]
                const _ruleAddress = [
                    "homeStreet",
                    "homeCity",
                    "homeState",
                    "homePostalCode",
                    "homeCountry",
                    "workStreet",
                    "workCity",
                    "workState",
                    "workPostalCode",
                    "workCountry",
                    "otherStreet",
                    "otherCity",
                    "otherState",
                    "otherPostalCode",
                    "otherCountry",
                ]
                const _ruleURL = [
                    "homeURL",
                    "workURL",
                    "otherURL",
                ]
                for (const x in data.a) {
                    const popsName = data.a[x].n
                    const nanPropName = this.getNanPropName(data.a[x].n)
                    const index = _rulesOther.findIndex(rule => rule === nanPropName)
                    if (index !== -1) {
                        let rs = _rulesOther[index]
                        switch (rs) {
                            case CONTACT_UTILS.OTHER.BIRTHDAY:
                                type = CONTACT_UTILS.OTHER.BIRTHDAY
                                typeI18N = this.$t("zimbra.zhMsg.AB_FIELD_birthDay")
                                break
                            case CONTACT_UTILS.OTHER.ANNIVERSARY:
                                type = CONTACT_UTILS.OTHER.ANNIVERSARY
                                typeI18N = this.$t("zimbra.zhMsg.AB_FIELD_otherAnniversary")
                                break
                            case CONTACT_UTILS.OTHER.CUSTOM:
                                type = CONTACT_UTILS.OTHER.CUSTOM
                                typeI18N = this.$t("zimbra.zhMsg.AB_FIELD_IM_other")
                                break
                            default:
                                break
                        }
                        _list.push({
                            id: uuidv4(),
                            ...data.a[x],
                            type: type,
                            typeI18N: typeI18N,
                        })
                    } else {
                        const _isInfo = _ruleInfo.findIndex(attr => nanPropName === attr)
                        const _isEmail = _ruleEmail.findIndex(attr => nanPropName === attr)
                        const _isPhone = _rulePhone.findIndex(attr => nanPropName === attr)
                        const _isRuleIM = _ruleIM.findIndex(attr => nanPropName === attr)
                        const _isAddress = _ruleAddress.findIndex(attr => nanPropName === attr)
                        const _isURL = _ruleURL.findIndex(attr => nanPropName === attr)
                        if (_isInfo !== -1 || _isEmail !== -1 || _isPhone !== -1 || _isRuleIM !== -1 || _isAddress !== -1 || _isURL !== -1) {
                            continue
                        }
                        const _index = _list.findIndex(attr => attr.n === popsName)
                        if (_index === -1) {
                            type = typeI18N = nanPropName
                            _list.push({
                                id: uuidv4(),
                                ...data.a[x],
                                type: type,
                                typeI18N: typeI18N,
                            })
                        }
                    }
                }
                return _list.length ? _.orderBy(_list, "type", "desc") : null
            }
            return null
        },
        // validator(item) {
        //     const DATE_ATTRS = { "birthday": true, "anniversary": true };
        //     if (item.type in DATE_ATTRS || item.type.replace(/^other/,"").toLowerCase() in DATE_ATTRS) {
        //         var dateStr = item.value.trim();
        //         if (dateStr.length) {

        //         }
        //     }
        //     return item.value
        // },
        // parseDate(dateStr) {
        //     var noYear = dateStr.match(/^--/);
        //     var pattern = noYear ? "--MM-dd" : "yyyy-MM-dd";
        //     var aDate = AjxDateFormat.parse(pattern, dateStr);

        //     if (_.isNil(aDate) || aDate == null) {
        //         aDate = AjxDateUtil.simpleParseDateStr(dateStr);
        //     }
        //     else if (noYear) {
        //         aDate.setFullYear(0);
        //     }
        //     return aDate;
        // },
        renderNotesTitle(data) {
            if (data && data.a) {
                return data.a.find(ele => ele.n === "notes")
            }
            return null
        },
        isLastElement(parent, id) {
            const _lenth = parent.length
            const _index = parent.findIndex(e => e.id == id);
            if (_index === _lenth - 1) return true;
            return false
        },
        async handledAssginOrRemoveTag(item, isAssginTag) {
            this.visablePopover = false;
            const idContactConv = this.listSelectedContact.filter(e => !e.cid).map(x => x.id).join(',');
            const op = isAssginTag ? "tag" : "!tag";

            const msg = isAssginTag
                ? i18n.t("zimbraNokey.zimbra_mailbox_assignTagContact", [this.listSelectedContact.length, item.name])
                : i18n.t("zimbraNokey.zimbra_mailbox_removeTagContact", [item.name, this.listSelectedContact.length]);
            let convAction = null;
            if (idContactConv?.length > 0) {
                const formData = {
                    id: `${idContactConv}`,
                    op: `${op}`,
                    tn: _.escape(item.name),
                };
                convAction = await ContactService.assginTagActionRequest(
                    formData
                );
            }
            const responseConv = convAction && this.getResponseBody(convAction)["ContactActionResponse"];
            if (responseConv?.action) {
                this.$notify({
                    title: i18n.t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: `${msg}`,
                    type: "success",
                });
                this.reloadTag(item.id);
                this.listSelectedContact.forEach(x => {
                    this.updateTag(item, x, isAssginTag);
                })
                this.$root.$emit("updateTagList", {
                    listSelectedContact: this.listSelectedContact,
                    tag: item,
                    isAssginTag: isAssginTag
                });
            }
        },
        async bathRequest() {
            let formData = {
                attrs: "zimbraDistributionListUnsubscriptionPolicy,zimbraDistributionListSubscriptionPolicy,zimbraHideInGal",
                ownerOf: 1
            }
            await ContactService.getAccountDistributionListsRequest(formData).then(async resp => {
                const { dl } = await this.getResponseBody(resp)["GetAccountDistributionListsResponse"]
                if (dl) {
                    let _listDL = Array.isArray(dl) ? dl : [dl];
                    _listDL = _listDL.map((ele, index) => ({ ...ele, index: index }));
                    this.$store.commit("SET_DISTRIBUTIONLISTS", _listDL)
                    this.$store.commit("SET_CONTACTACTION", CONTACT_ACTION.VIEW_MAILING_LIST)
                    this.$store.commit("SET_ACTIVEDISTRIBUTION", _listDL[0])
                    this.$store.commit("SET_LIST_SELECTED_CONTACT", [_listDL[0]])
                    await this.getDistributionAndListMembersRequest(_listDL[0])
                }
            })
        },
        async getDistributionAndListMembersRequest(distribution) {
            const formData = {
                needOwners: 1,
                needRights: "sendToDistList",
                by: "name",
                value: distribution.name
            }
            await ContactService.getDistributionListRequest(formData).then(async resp => {
                const dl = await this.getResponseBody(resp)["GetDistributionListResponse"]
                if (dl) {
                    const formData = {
                        limit: 100,
                        offset: 0,
                        dl: {
                            content: distribution.name
                        }
                    }
                    await ContactService.getDistributionListMembersRequest(formData).then(async response => {
                        const _list = this.getResponseBody(response)["GetDistributionListMembersResponse"]
                        const _index = this.distributionLists.findIndex(ele => ele.id === distribution.id)
                        this.$store.commit("SET_DISTRIBUTIONLISTMEMBERS", _list)
                        if (_index > -1) {
                            const _temp = {
                                ...this.distributionLists[_index],
                                dlm: Array.isArray(_list.dlm) ? _list.dlm : [_list.dlm],
                                total: _list.total,
                                more: _list.more
                            }
                            // console.log(_temp);
                            this.$store.commit("EDIT_DISTRIBUTIONLISTS", _temp)
                        }
                    })
                }
            })
        },
        async searchContact(node) {
            let query = ""
            if (node) {
                if (node.id === FOLDER_DEFAULT.CONTACTS) {
                    query = `in:contacts`
                } else if (node.id === 12) {
                    this.bathRequest()
                    return
                } else if (node.id === FOLDER_DEFAULT.TRASH) {
                    query = `in:trash`
                } else {
                    query = `in:"${_.escape(node.absFolderPath.replace("/", ""))}"`;
                }
                const formData = {
                    fetch: 1,
                    limit: 50,
                    locale: {
                        content: this.localeCurrent.content
                    },
                    needExp: 1,
                    offset: this.contacts.length,
                    query: query,
                    sortBy: "nameAsc",
                    types: "contact",
                    tz: {
                        content: this.timeZoneCurrent.content
                    }
                }
                await this.searchRequest(formData)
            }
        },
        async searchRequest(formData) {
            await ZimbraMailService.searchRequest(formData).then(resp => {
                const listDataContact = this.getResponseBody(resp)["SearchResponse"]["cn"]
                const isCalled = this.getResponseBody(resp)["SearchResponse"]["more"]
                if (listDataContact) {
                    let _temp = []
                    if (Array.isArray(listDataContact)) {
                        _temp = listDataContact
                    } else {
                        _temp.push(listDataContact)
                    }
                    for (let x = 0; x < _temp.length; x++) {
                        // let _data1 = _temp[x]
                        for (let y = 0; y < this.contactGroup.length; y++) {
                            const _data2 = this.contactGroup[y]
                            if (_temp[x].id === _data2.id) {
                                const _attrInfo = ["namePrefix", "firstName", "middleName", "maidenName", "lastName", "nameSuffix", "nickname", "jobTitle", "department", "company"]
                                _temp[x].a.forEach(attr => {
                                    if (_attrInfo.indexOf(attr.n) > -1) {
                                        _data2[attr.n] = attr.content
                                    }
                                })
                                _temp[x] = _data2
                            }
                        }
                    }
                    // console.log(_temp);
                    const _list = this.objectToArray(_temp)
                    const _contacts = _list.map((ele, index) => {
                        if (ele.a) {
                            const _image = ele.a.find(attr => attr.n === "image")
                            const _email = []
                            ele.a.forEach(attr => {
                                if (attr.n.includes("email")) {
                                    _email.push(attr.content)
                                }
                            })
                            ele["url"] = _image ? `${CONFIG.ENDPOINT["homeService"]}/~/?auth=co&id=${ele.id}&part=1&max_width=48&max_height=48&t=${new Date().getTime()}` : require('@/assets/images/Avatar.png')
                            ele["email"] = _email ? _email : []
                        }
                        return { ...ele, index: index }
                    })
                    // const _newList = this.contacts.concat(_contacts)
                    const _alphabet = this.convertAlphabet(_contacts)
                    let _active = null
                    for (let i = 0; i < _alphabet.length; i++) {
                        if (_alphabet[i].attr.length) {
                            _active = _alphabet[i].attr[0]
                            break
                        }
                    }
                    if (_active.m) {
                        this.$store.commit("SET_CONTACTACTION", CONTACT_ACTION.VIEW_CONTACT_GROUP_LIST)
                    } else {
                        this.$store.commit("SET_CONTACTACTION", CONTACT_ACTION.VIEW_CONTACT)
                    }
                    this.$store.commit("SET_ACTIVECONTACT", _active)
                    this.$store.commit("SET_LIST_SELECTED_CONTACT", [_active])
                    this.$store.commit("SET_CONTACTS", _contacts)
                    this.$store.commit("SET_CONTACTALPHABET", _alphabet)
                    this.$store.commit("SET_CALL", isCalled)
                }
            })
        },
        // isHasTag(email) {
        //     return email.t !== undefined && email.t !== null && email.t !== ""
        // },
        getColor(item) {
            let hex = null; // giá trị default
            if (item && item.color) {
                hex = color.find(e => e.colorNumeric === item.color)?.value;
            } else if (item && item.rgb) {
                hex = item.rgb;
            }
            return hex;
        },
        isHasTag(email) {
            if (email.t) {
                return true
            }
            return false
        },
        isMultiTag(email) {
            const listTag = !_.isNil(email.t) ? email.t.toString().split(',') : null;
            return !_.isNil(listTag) && listTag.length > 1;
        },
        getColorTag(email) {
            if (!_.isNil(email.t)) {
                const tagAssigned = Array.isArray(this.listTag) ? this.listTag.find(e => email.t.toString() == e.id.toString()) : this.listTag;
                return this.getColor(tagAssigned);
            }
            return "#fff";
        },
        getListTag(email) {
            if (email && !_.isNil(email.t)) {
                try {
                    if (Number.isInteger(email.t)) {
                        const tagAssigned = Array.isArray(this.listTag) ? this.listTag.find(e => email.t == e.id) : this.listTag;
                        return [tagAssigned].map(e => ({ ...e, colorHex: this.getColor(e) }));
                    }
                    const tagIds = email.t.split(",").map(e => parseInt(e, 10));
                    const tags = Array.isArray(this.listTag) ? this.listTag : [this.listTag || {}];
                    let tagAssigned = [];
                    if (email.tn) {
                        const listTagName = this.replaceAll(email.tn, "\\", "\u200B").split(",");
                        for (let i = 0; i < listTagName.length; i++) {
                            let tag = tags.find(t => t.id == tagIds[tagIds.length - i - 1]);
                            if (tag === undefined) {
                                tag = {
                                    id: tagIds[i],
                                    name: this.$t("zimbra.zmMsg.tagNotLocal", [listTagName[i]]),
                                    colorHex: "#f8acbc"
                                }
                                tagAssigned.push(tag)
                            }
                        }
                    }
                    tagAssigned = tagAssigned.concat(tags.filter(e => tagIds.includes(e.id))
                        .map(e => ({ ...e, colorHex: this.getColor(e) })))
                    return tagAssigned;
                } catch (error) {
                    return [];
                }
            }
            return [];
        },
        htmlEncodeSpace(str) {
            return str.replace(/[&]/g, '&amp;').replace(/ /g, '&nbsp;').replace(/[<]/g, '&lt;').replace(/[>]/g, '&gt;')
        },
        replaceEscaped(str, match, replacement) {
            return str.replace(new RegExp(this.escapeRegExp(match), 'g'), () => replacement);
        },
        escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        },
        async handleRemoveAllTag() {
            const _contacts = this.listSelectedContact.map(x => {
                return {
                    id: x.id,
                    tn: x.tn.split(",") || []
                }
            });
            if (!_contacts.length) {
                return
            }
            const listRequest = []
            _contacts.forEach(x => {
                x.tn.forEach(y => {
                    listRequest.push({
                        path: "contactActionRequest",
                        content: this.handleJsonToString({
                            id: `${x.id}` || "",
                            op: "!tag",
                            tn: y
                        })
                    })
                })
            })
            await ContactService.bathRequest(listRequest).then(resp => {
                const _listResp = resp.data.data;
                const _actions = _listResp.map(obj => JSON.parse(obj))
                let _listSuccess = []
                _actions.forEach(obj => {
                    const _contactAction = obj.ContactActionResponse.action || {}
                    const _contactSuccess = this.listSelectedContact.find(contact => contact.id == _contactAction.id)
                    if (_contactSuccess) _listSuccess.push(_contactSuccess)
                })
                _listSuccess = Array.from(new Set(_listSuccess))
                if (_listSuccess.length) {
                    this.$notify({
                        title: i18n.t("zimbra.zmMsg.dataSourceTestSuccess"),
                        message: i18n.t("zimbraNokey.zimbra_mailbox_removeAllTagContact", { 1: _listSuccess.length }),
                        type: "success",
                    });
                    _listSuccess.forEach(x => {
                        this.updateTag(false, x, false);
                    })
                    this.reloadTag(false);
                    this.$root.$emit("updateTagList", {
                        listSelectedContact: this.listSelectedContact,
                        tag: null,
                        isAssginTag: false
                    });
                }
            })
        },
        async handledRemoveAllTag() {
            const idContact = this.listSelectedContact.map(x => x.id).join(',');
            const formData = {
                id: `${idContact}`,
                op: "update",
                t: "",
            };
            const convAction = await ContactService.assginTagActionRequest(
                formData
            );
            const response =
                this.getResponseBody(convAction)["ContactActionResponse"];
            if (response && response.action) {
                this.$notify({
                    title: i18n.t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: i18n.t("zimbraNokey.zimbra_mailbox_removeAllTagContact", { 1: this.listSelectedContact.length }),
                    type: "success",
                });
                // this.reloadTag(false);
                this.listSelectedContact.forEach(x => {
                    this.updateTag(false, x, false);
                })
            }
        },
        updateTag(item, beforeMail, isAssginTag) {
            if (isAssginTag) {
                const mailUpdate = { ...beforeMail, tag: item }
                this.$root.$emit("pushTagToContact", mailUpdate);
            }
            let mail = _.cloneDeep(beforeMail);
            mail.t = mail.t ? mail.t.toString() : "";
            mail.tn = mail.tn ? mail.tn.toString() : ""
            let litsTag = mail.t != "" ? mail.t.split(',') : [];
            let listTagName = mail.tn != "" ? mail.tn.split(',') : [];
            if (item) {
                if (isAssginTag) {
                    litsTag.push(item.id);
                    listTagName.push(item.name)
                } else {
                    litsTag = litsTag.filter(x => x != item.id.toString());
                    listTagName = listTagName.filter(x => x != item.name.toString());
                    // this.$root.$emit("removeTag", item)
                }
                mail.t = litsTag.length != 0 ? litsTag.join(',') : undefined;
                mail.tn = listTagName.length != 0 ? listTagName.join(',') : undefined;
            } else {
                // this.$root.$emit("removeAllTag")
                mail.t = undefined;
                mail.tn = undefined;
            }
            const idxItem = this.contacts.findIndex(e => e.id === mail.id);
            if (idxItem > -1) {
                const _emails = this.contacts;
                _emails.splice(idxItem, 1, mail)
                this.$store.commit("SET_CONTACTALPHABET", _emails);
                // this.$store.commit("SET_CONTACTS", _emails);
            }
            // update list tag tại mail select
            const idxItemSelect = this.listSelectedContact.findIndex(e => e.id === mail.id);
            if (idxItemSelect > -1) {
                const _emails = this.listSelectedContact;
                _emails.splice(idxItemSelect, 1, mail)
                this.$store.commit("SET_LIST_SELECTED_CONTACT", _emails);
            }
        },
        async reloadTag(idTag) {
            const searchRequest = await ZimbraMailService.getTagRequest();
            const data = this.getResponseBody(searchRequest)["GetTagResponse"]["tag"];
            const tags = Array.isArray(data) ? data : [data]
            if (data && data.tag) {
                let dataChange = [];
                if (idTag) {
                    dataChange.push(tags.find(x => x.id == idTag));
                } else {
                    dataChange = data.tag;
                }
                dataChange.forEach(item => {
                    let tagChange = this.listTag.find(x => x.id == item.id);
                    tagChange.u = item.u || 0;
                    tagChange.n = item.n || 0;
                    this.deleteItemToData(tagChange, this.contacts);
                    this.addItemToData(tagChange, this.contacts);
                })
            }
        },
        deleteItemToData(itemDelete, data) {
            for (let item in data) {
                if (data[item].id == itemDelete.l) {
                    const arrFolder = data[item].folder.filter(
                        (x) => x.id != itemDelete.id
                    );
                    data[item].folder = arrFolder;
                    return false;
                } else {
                    if (data[item].folder) {
                        this.deleteItemToData(itemDelete, data[item].folder);
                    }
                }
            }
        },
        addItemToData(itemAdd, data) {
            ///// tạo absfolderPathI18n khi tạo thư mục lưu trữ mới
            if (!_.isNil(itemAdd.absFolderPath)) {
                itemAdd.absFolderPathI18n = this.pathI18n(itemAdd.absFolderPath);
            }
            for (let item in data) {
                if (data[item].id == itemAdd.l) {
                    if (!data[item].folder) {
                        this.$set(data[item], "folder", []);
                    }
                    data[item].folder.push(itemAdd);
                    if (itemAdd.type === SIDEBAR_NODE.TAG) {
                        this.$store.commit("SET_TAGS", data[item].folder);
                        this.$store.commit("SET_LIST_TAG", data[item].folder);
                    }
                    data[item].folder = this.reorderFolder(data[item].folder);
                    return;
                } else {
                    if (data[item].folder) {
                        this.addItemToData(itemAdd, data[item].folder);
                    }
                }
            }
        },
        async getTagApi() {
            const searchRequest = await ZimbraMailService.getTagRequest();
            const data = this.getResponseBody(searchRequest)["GetTagResponse"];
            if (data && data.tag) {
                if (data.tag.length) {
                    data.tag.forEach((x) => {
                        x.l = -2;
                        x.u = x.u || 0;
                        x.rgb = x.rgb || "#3F4254";
                        x.type = SIDEBAR_NODE.TAG;
                        this.dataTag.push(x);
                    });
                } else {
                    data.tag.l = -2;
                    data.tag.u = data.tag.u || 0;
                    data.tag.type = SIDEBAR_NODE.TAG;
                    this.dataTag.push(data.tag);
                }
            }
            this.$store.commit("SET_TAGS", this.dataTag);
            this.$store.commit("SET_LIST_TAG", this.dataTag);

            await this.getFolderContactApi();
        },
        async getContactFolder() {
            const formData = {
                folder: `<view>contact</view><depth>-1</depth><tr>1</tr><visible>1</visible><needGranteeName>1</needGranteeName>`,
            };
            const searchRequest = await ZimbraMailService.getFolderRequest(formData);
            const searchActionResponse = this.getResponseBody(searchRequest)["GetFolderResponse"]?.folder;
            const _folder = this.convertContactFolder(searchActionResponse);
            return _folder[0].folder;
        },
        convertContactFolder(folder) {
            if (!folder) {
                return [];
            }
            folder = Array.isArray(folder) ? folder : [folder];
            for (let index = 0; index < folder.length; index++) {
                const item = folder[index];
                let link = [];
                if (item.link) {
                    link = Array.isArray(item.link) ? item.link : [item.link];
                }
                if (item.folder) {
                    item.folder = Array.isArray(item.folder) ? item.folder : [item.folder];
                } else {
                    item.folder = [];
                }
                // convert tên folder
                item.name = FOLDER_RENAME.includes(item.name)
                    ? i18n.t(`zimbra.systemFolder.folder${item.name}`)
                    : item.name;
                item.folder = item.folder.concat(link);
                item.folder = this.convertContactFolder(item.folder);
            }
            return folder;
        },
        async getFolderContactApi() {
            const formData = {
                folder: `<view>1</view><depth>-1</depth><tr>1</tr><visible>1</visible><needGranteeName>1</needGranteeName>`,
            };
            const searchRequest = await ZimbraMailService.getFolderRequest(
                formData
            );
            const searchActionResponse =
                this.getResponseBody(searchRequest)["GetFolderResponse"];
            let data = searchActionResponse.folder.folder;
            this.$store.commit(
                "SET_FOLDER_SEARCH",
                this.reorderTree(
                    [
                        {
                            id: 1,
                            name: i18n.t("zimbra.zhMsg.optionsSearches"),
                            search: this.wrapFolderSearch(
                                searchActionResponse.folder.search
                            ),
                            isRootSearchFolder: true,
                        },
                    ],
                    "search",
                    ["name"],
                    ["asc"]
                )
            );
            if (searchActionResponse.folder.link) {
                let dataLink = searchActionResponse.folder.link;
                this.tickLinkFolder(dataLink);
                data = data.concat(dataLink);
            }
            this.objectToArrContact(data);
            this.divideDataContact(data);
        },
        wrapFolderSearch(search) {
            if (!search) {
                return [];
            }
            if (Array.isArray(search)) {
                search.forEach((item) => {
                    item.search = this.wrapFolderSearch(item.search);
                });
            } else if (search) {
                search = this.wrapFolderSearch([search]);
            }
            return search;
        },
        tickLinkFolder(data, parent) {
            if (data.length == undefined) {
                const arrLink = [];
                arrLink.push(data);
                data = arrLink;
            }
            for (let item in data) {
                if (data[item].l != FOLDER_DEFAULT.ROOT && !_.isNil(parent)) {
                    data[item].absFolderPath =
                        parent.absFolderPath + "/" + data[item].name;
                }
                data[item].isLink = true;
                if (data[item].folder) {
                    if (data[item].folder.length) {
                        this.tickLinkFolder(data[item].folder, data[item]);
                    } else {
                        const arrFolder = [];
                        arrFolder.push(data[item].folder);
                        data[item].folder = arrFolder;
                        this.tickLinkFolder(data[item].folder, data[item]);
                    }
                }
            }
        },
        objectToArrContact(data) {
            for (let item in data) {
                if (!_.isNil(data[item].perm)) {
                    data[item].isLink = true;
                }
                data[item].rgb = data[item].rgb || "#3F4254";
                data[item].u = data[item].u || 0;
                data[item].n = data[item].n || 0;
                data[item].folder = data[item].folder || [];
                if (!data[item].folder || !Array.isArray(data[item].folder)) {
                    data[item].folder = data[item].folder ? [data[item].folder] : []
                }
                if (data[item].search && data[item].isRootSearchFolder) {
                    const dataSearch = Array.isArray(data[item].search)
                        ? data[item].search
                        : [data[item].search];
                    data[item].folder = data[item].folder.concat(dataSearch.map(x => ({ ...x, isSearch: true })));
                    if (!this.isFolderDefaut(data[item])) {
                        data[item].isSearch = true;
                    }
                }
                if (data[item].link) {
                    const _link = Array.isArray(data[item].link)
                        ? data[item].link
                        : [data[item].link]
                    const dataLink = _link.map(e => ({ ...e, isLink: true }));
                    data[item].folder = data[item].folder.concat(dataLink);
                }
                if (data[item].folder.length > 0) {
                    data[item].folder = data[item].folder.filter(x => !_.isNil(x.absFolderPath));
                    data[item].folder = data[item].folder.filter(x => x.view == "contact" || _.isNil(x.view));
                    data[item].folder = data[item].folder.filter(x => !x.isSearch || (x.isSearch && x.types == "contact"));
                    this.objectToArrContact(data[item].folder);
                }
            }
        },
        isFolderDefaut(data) {
            for (let item in FOLDER_DEFAULT) {
                if (FOLDER_DEFAULT[item] == data.id) {
                    return true;
                }
            }
            return false;
        },
        divideDataContact(data) {
            // this.dataContact = data.filter(x=> x.view == "contact" || x.folder.map(x=>x.view).indexOf("contact") > -1);
            this.dataContact = [];
            const distributionList = {
                absFolderPath: "/DistributionList",
                name: "DistributionList",
                id: 12,
                folder: [],
            };
            const tag = {
                name: "Tag",
                id: FOLDER_DEFAULT.TAG,
                folder: this.dataTag,
            };
            this.dataExtend = [
                {
                    name: i18n.t("zimbraNokey.zimbra_sidebar_extend"),
                    id: FOLDER_DEFAULT.EXTEND,
                    folder: [],
                    order: 1,
                },
            ];
            for (let item in data) {
                if ((data[item].id == 7 || data[item].id == 13) || data[item].id == 3) {
                    this.dataContact.push(data[item]);
                } else {
                    if (this.haveChildContact(data[item])) {
                        this.dataContact.push(data[item]);
                    }
                }
            }
            this.dataExtend[0].folder.push(tag);
            let folderSearches = _.cloneDeep(this.folderSearches);
            this.objectToArrContact(folderSearches);
            this.dataExtend[0].folder.push(folderSearches[0]);
            this.dataExtend[0].folder = this.reorderFolder(this.dataExtend[0].folder)
            this.$store.commit(
                "SET_DATA_EXTEND_CONTACT",
                this.reorderFolder(this.dataExtend))
            const folderContact = this.dataContact.find(folder => folder.id === FOLDER_DEFAULT.CONTACTS)
            this.$store.commit("SET_ACTIVENODECONTACT", folderContact);
            this.renameAbsFolderPath(this.dataContact);
            this.dataContact.push(distributionList)
            this.dataContact = this.reorderFolder(this.dataContact);
            const dataContact = [
                {
                    name: i18n.t("zimbra.zmMsg.contactLists"),
                    id: FOLDER_DEFAULT.ROOT,
                    folder: this.dataContact,
                    order: 1,
                }
            ];
            this.$store.commit("SET_DATA_CONTACT", dataContact)
        },
        haveChildContact(data) {
            if (data.view && data.view == "contact") {
                return true;
            } else {
                if (data.folder && data.folder.length) {
                    for (let index = 0; index < data.folder.length; index++) {
                        if (this.haveChildContact(data.folder[index])) {
                            return true;
                        }
                    }
                }
            }
            return false
        },
        reorderFolder(folders) {
            if (Array.isArray(folders)) {
                folders.forEach((item) => {
                    const folderOrder = FOLDER_ORDER.filter(e => e.idFolder !== FOLDER_DEFAULT.TRASH)
                    const sort = folderOrder.find(
                        (e) => e.idFolder === item.id
                    );
                    if (sort) {
                        item.order = sort.order;
                    } else {
                        // item.order = Number.MAX_SAFE_INTEGER;
                        item.order = item.isLink
                            ? Number.MAX_SAFE_INTEGER
                            : item.id == FOLDER_DEFAULT.TRASH ? Number.MAX_SAFE_INTEGER - 1 : Number.MAX_SAFE_INTEGER - 2;
                    }
                    item.name = FOLDER_RENAME.includes(item.name)
                        ? i18n.t(`zimbra.systemFolder.folder${item.name}`)
                        : item.name;
                    if (item.folder) {
                        folders.folder = this.reorderFolder(item.folder);
                    }
                });
                folders = _.orderBy(folders, ["order", (n) => { return n.name.toString().toLowerCase() || "" }], ["asc", "asc"]);
            } else if (folders) {
                folders.name = FOLDER_RENAME.includes(folders.name)
                    ? i18n.t(`zimbra.systemFolder.folder${folders.name}`)
                    : folders.name;
            }
            if (folders.folder) {
                folders.folder = this.reorderFolder(folders.folder);
            }
            return folders;
        },
        renameAbsFolderPath(folders) {
            if (Array.isArray(folders)) {
                folders.forEach((folder) => {
                    folder.absFolderPathI18n = folder.absFolderPath ? this.pathI18n(folder.absFolderPath) : "";
                    this.renameAbsFolderPath(folder.folder);
                });
            } else if (folders) {
                folders.absFolderPathI18n = folders.absFolderPath ? this.pathI18n(folders.absFolderPath) : "";
            }
        },
        pathI18n(path) {
            const regex = /^(\/[A-z]*)/gi;
            const match = path.match(regex);
            if (match?.length > 0) {
                const folderRoot = match[0].substring(1, match[0].length);
                const nameI18n = FOLDER_RENAME.includes(folderRoot)
                    ? i18n.t(`zimbra.systemFolder.folder${folderRoot}`)
                    : folderRoot;
                return path.replace(/^(\/[A-z]*)/gi, `${nameI18n}`);
            }
            return path;
        },
        handleShowViewEditContact() {
            this.$store.commit("SET_CONTACTACTION", CONTACT_ACTION.EDIT_CONTACT)
            this.$root.$emit("handleActionEditContact", this.listSelectedContact[0])
        },
        convertAlphabet(parent) {
            // Init gia tri cho mapAlphaBet
            const mapAlpha = new Map();
            CONTACT_ALPHABET_TEST.forEach(x => {
                mapAlpha.set(x, [])
            }
            );
            mapAlpha.set("123", []);
            mapAlpha.set("#", []);

            // Duyệt danh sách list có trước
            parent.forEach(x => {
                const alphabet = x.fileAsStr.toString().charAt(0).toUpperCase();
                const keyword = alphabet.normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replace(/đ/g, 'd').replace(/Đ/g, 'D');
                if (/^-?\d+$/.test(keyword) === true) {
                    mapAlpha.get("123").push(x)
                } else if (keyword.match(/[a-zA-Z]/)) {
                    if (mapAlpha.has(keyword)) {
                        mapAlpha.get(keyword).push(x)
                    }
                } else {
                    mapAlpha.get("#").push(x)
                }
            });
            let mapToArray = []
            mapAlpha.forEach((value, key) => {
                if (value.length > 0) {
                    mapToArray.push({
                        alphabet: key,
                        attr: value
                    })
                }
            });
            mapToArray = _.orderBy(mapToArray, "alphabet", "asc");
            let listContactGroup = []
            mapToArray.forEach(ele => {
                const idGroup = uuidv4();
                listContactGroup.push({
                    id: idGroup,
                    keyword: ele.alphabet,
                    isGroup: true,
                    isContacts: ele.attr.length > 0,
                    contacts: ele.attr
                })
                listContactGroup = listContactGroup.concat(ele.attr.map(attr => ({ ...attr, idGroup: idGroup, expand: true, isElement: true })))
            })
            return listContactGroup
        },

        // Tạo nhóm liên hệ cho danh bạ
        // buildFormAddGroupContact(item) {

        //     return cn;
        // },
        async handleAddGroupContact(item) {
            // Lọc những id trùng nhau
            const _item = Array.isArray(item) ? item : [item]
            const id = [];
            _item.forEach(ele => {
                if (!ele.m) {
                    id.push(ele.id)
                } else {
                    if (Array.isArray(ele.m)) {
                        ele.m.forEach(ele => {
                            id.push(ele.value)
                        })
                    } else {
                        id.push(ele.m.value)
                    }
                }
            });

            // build listM
            let listM = []
            this.listSelectedContact.forEach(e => {
                if (!e.m) {
                    listM.push({
                        cn: e,
                        value: e.id,
                        type: "C"
                    })
                } else {
                    e.m.forEach(x => {
                        if (!id.includes(x.value)) {
                            listM.push(x)
                        }
                    })
                }
            });

            // build list Cn
            const cn = {};
            cn.id = `${item.id}`
            cn.a = [],
                cn.m = listM.map(e => {
                    item.m.push(e)
                    return {
                        type: `${e.type}`,
                        value: `${e.value}`,
                        op: "+"
                    }
                });
            await ContactService.modifyContactRequest(cn).then(resp => {
                const response = this.getResponseBody(resp);
                const data = response["ModifyContactResponse"]["cn"]
                if (data) {
                    let mOfResponse = Array.isArray(data["m"]) ? data["m"] : [data["m"]];
                    for (let index = 0; index < mOfResponse.length; index++) {
                        for (let idx = 0; idx < item["m"].length; idx++) {
                            const temp = item["m"][idx];
                            if (mOfResponse[index].value == temp.value) {
                                mOfResponse[index] = {
                                    ...mOfResponse[index],
                                    cn: temp.cn
                                }
                            }
                        }
                    }
                    mOfResponse = mOfResponse.filter(e => e.cn || e.type == "I")
                    item["m"] = mOfResponse
                    let listContact = _.clone(this.contacts)
                    const index = listContact.findIndex(contact => contact.id == item.id)
                    if (index !== -1) listContact[index] = item
                    listContact = listContact.map((contact, index) => ({ ...contact, index: index }))
                    const contactAlphabet = this.convertAlphabet(listContact)
                    this.$store.commit("SET_CONTACTALPHABET", contactAlphabet)
                    this.$store.commit("SET_LIST_SELECTED_CONTACT", [item]);
                    this.$store.commit("SET_CONTACTS", listContact)
                    // this.$store.commit("EDIT_CONTACT", item);
                    if (item.l === this.activeNodeContact.id) {
                        setTimeout(() => {
                            this.$store.commit("SET_CONTACTACTION", CONTACT_ACTION.VIEW_CONTACT_GROUP_LIST)
                            this.$store.commit("SET_LIST_SELECTED_CONTACT", [item]);
                        }, 150)
                    } else {
                        this.$store.commit("SET_LIST_SELECTED_CONTACT", [this.listSelectedContact[0]]);
                    }
                    this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                        message: this.$t(
                            "zimbra.zmMsg.contactSaved"
                        ),
                        type: "success",
                    });
                }
            }).catch(e => {
                console.log(e);
                this.$alert(this.$t("zimbra.error.service.INVALID_REQUEST"), "zimbra", {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    type: "error",
                });
                // this.$root.$emit("hanldeErrorAddGroup", true)
            })
        },
        async handleReplaceContactGroup(groups, contacts) {
            if (groups && groups.length) {
                await ContactService.getContactsRequest(groups).then(async resp => {
                    let _listContactGroup = await CommonUtils.methods.getResponseBody(resp)["GetContactsResponse"]["cn"]
                    _listContactGroup = Array.isArray(_listContactGroup) ? _listContactGroup : [_listContactGroup]
                    if (_listContactGroup && _listContactGroup) {
                        _listContactGroup.forEach(e => {
                            e.m = Array.isArray(e.m) ? e.m : [e.m]
                            for (let x = 0; x < e.m.length; x++) {
                                let _lstContactGr = e.m[x];
                                if (_lstContactGr.type == "C" && !_lstContactGr.cn) {
                                    for (let y = 0; y < this.listFileContacts.length; y++) {
                                        let _lstFileCt = this.listFileContacts[y]
                                        if (_lstContactGr.value == _lstFileCt.id) {
                                            _lstContactGr.cn = { ..._lstFileCt }
                                        }
                                    }
                                }
                            }
                        })
                    }
                    if (_listContactGroup.length && contacts.length) {
                        for (let x = 0; x < _listContactGroup.length; x++) {
                            let _dataTemp = _listContactGroup[x]
                            for (let y = 0; y < contacts.length; y++) {
                                if (_dataTemp.id === contacts[y].id) {
                                    const _attrInfo = ["namePrefix", "firstName", "middleName", "maidenName", "lastName", "nameSuffix", "nickname", "jobTitle", "department", "company"]
                                    contacts[y].a.forEach(attr => {
                                        if (_attrInfo.indexOf(attr.n) > -1) {
                                            _dataTemp[attr.n] = attr.content
                                        }
                                    })
                                    contacts[y] = _dataTemp
                                }
                            }
                        }
                    }
                }).catch(err => {
                    console.log(err);
                })
            }
            contacts = contacts.map(ele => {
                if (ele.m) {
                    let _mTemp = Array.isArray(ele.m) ? ele.m : [ele.m];
                    _mTemp = _mTemp.filter(attr => attr.type === ZmContact.GROUP_INLINE_REF || attr.cn)
                    ele["m"] = _mTemp
                }
                return ele
            })
            return contacts
        },
        prepareDataBeforeRender(listContact) {
            if (!listContact) {
                return [];
            }
            let currentIndex = this.listContact.length
            return listContact.map((contact, idx) => {
                return this.preProcessingItem(contact, currentIndex + idx);
            });
        },
        preProcessingItem(contact, idx) {
            contact.index = idx
            contact.a = this.toArray(contact.a);
            // start regex address
            const listRegexAddress = ["Street", "City", "State", "PostalCode", "Country"];
            const listAddressAfterRegex = contact.a.filter(e => listRegexAddress.some(x => (e.n).includes(x)));
            const regexWork = /(work)/;
            const filteredListWork = listAddressAfterRegex.filter(str => regexWork.test(str.n));
            const regexHome = /(home)/;
            const filteredListHome = listAddressAfterRegex.filter(str => regexHome.test(str.n));
            const regexOther = /(other)/;
            const filteredListOther = listAddressAfterRegex.filter(str => regexOther.test(str.n));
            // end regex address
            // start regex other
            const regexBirthday = /(birthday)/;
            const filteredListBirthday = contact.a.filter(str => regexBirthday.test(str.n));
            const regexAnniversary = /(anniversary)/;
            const filteredListAnniversary = contact.a.filter(str => regexAnniversary.test(str.n));
            const regexCustom = /(custom)/;
            const filteredListCustom = contact.a.filter(str => regexCustom.test(str.n));
            // end regex other
            contact.isHasTag = this.isHasTag(contact)
            contact.isMultiTag = this.isMultiTag(contact)
            contact.listTag = this.getListTag(contact)
            contact.colorTag = this.getColorTag(contact)
            contact.info = this.renderInfo(contact)
            contact.avtUrl = this.renderAvtUrl(contact)
            contact.fullName = this.renderFullName(contact)
            contact.viewName = this.renderViewName(contact)
            contact.parent = this.renderFolderParent(contact)
            contact.name = this.renderName(contact)
            contact.jobTitle = this.renderJobTitle(contact)
            contact.departmentTitle = this.renderDepartmentTitle(contact)
            contact.companyTitle = this.renderCompanyTitle(contact)
            contact.tags = this.renderTags(contact)
            contact.emailTitle = this.renderEmailTitle(contact)
            contact.phoneTitle = this.renderPhoneTitle(contact)
            contact.imTitle = this.renderImTitle(contact)
            contact.addressHomeTitle = this.renderAddressTitle(filteredListHome)
            contact.addressWorkTitle = this.renderAddressTitle(filteredListWork)
            contact.addressOtherTitle = this.renderAddressTitle(filteredListOther)
            contact.urlTitle = this.renderUrlTitle(contact)
            contact.otherTitle = this.renderOtherTitle(contact)
            contact.notesTitle = this.renderNotesTitle(contact)
            contact.attrs = this.renderAttrsTitle(contact.a)
            contact.otherBirthday = this.renderAddressTitle(filteredListBirthday)
            contact.otherAnniversary = this.renderAddressTitle(filteredListAnniversary)
            contact.otherCustom = this.renderAddressTitle(filteredListCustom)
            return contact
        },
        /*
        * Hàm lấy folder theo id
        */
        findFolderByIdDragDrop(id, listFolder) {
            if (id == FOLDER_DEFAULT.ROOT) {
                return this.dataSidebarContact[0];
            }
            for (let index in listFolder) {
                if (listFolder[index].id == id) {
                    return listFolder[index];
                } else if (listFolder[index].folder) {
                    const result = this.findFolderByIdDragDrop(id, listFolder[index].folder)
                    if (result != null) {
                        return result;
                    }
                }
            }
            return null;
        },

    }
}