<template>
    <el-dialog
        class="dialog-body-wrap d-center "
        custom-class="modal-choose__folder setting-filter"
        :title="`${formChoose.title}`"
        :visible.sync="dialogChooseFolder"
        :close-on-click-modal="false"
        :append-to-body="true"
        :destroy-on-close="true"
    >
        <el-form class="form-wrap">
            <span
                v-show="formChoose.isChooseFolder"
                style="margin: 0; margin-bottom: 2px"
                >{{
                    $t("zimbra.zmMsg.targetFolder")
                }}</span
            >
            <div class="form__heading">
                {{ $t("zimbra.zmMsg.chooserDescription") }}
            </div>
            <div class="form__body">
                <TreeChooseFolder
                    :showCheckbox="showCheckbox"
                    :showInfoFolder="true"
                    ref="treeFolderExtra"
                    :listIdFolderHidden = [FOLDER_DEFAULT.TAG,FOLDER_DEFAULT.STARS,FOLDER_DEFAULT.DRAFTS,FOLDER_DEFAULT.ROOT]
                    @onSelectFolder="onSelectFolder"
                ></TreeChooseFolder>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <base-button-default
                class="btn-email primary cover-size"
                @click="$root.$emit('handleShowCreateFolder', false)"
                >{{ $t("zimbra.zhMsg.apptPtstNEW") }}</base-button-default
            >
            <div class="btn-group">
                <base-button-default
                    class="btn-email default cover-size"
                    @click="handleCancel"
                    >{{ $t("zimbra.zhMsg.actionTaskCancelTT") }}</base-button-default
                >
                <base-button-default
                    class="btn-email primary cover-size"
                    v-if="!formChoose.isChooseFolder"
                    @click="handleApcepterAction"
                    >{{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}</base-button-default
                >
            </div>
        </span>
    </el-dialog>
</template>
<script>
import TreeChooseFolder from "@/components/modules/layouts/components/TreeChooseFolder";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import { EMAIL_ACTION, STATUS_REQUEST,FOLDER_DEFAULT } from "@/utils/constants";
import BaseButtonDefault from "@/components/modules/base/base-button/btn-default";
export default {
    props: {
        showCheckbox: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [CommonUtils],
    mounted() {
        this.$root.$on("handleShowChooseFolder", (isShowChooseFolder) => {
            this.show(isShowChooseFolder);

        });
    },
    components: {
        TreeChooseFolder,
        BaseButtonDefault,
    },
    destroyed() {
        this.$root.$off("handleShowChooseFolder")
    },
    data() {
        return {
            formChoose: { parent: {}, title: "", isShowChooseFolder: false },
            dialogChooseFolder: false,
            filterText: "",
            treeNodeMove: {},
            listIdChild: [],
            EMAIL_ACTION: EMAIL_ACTION,
            FOLDER_DEFAULT:FOLDER_DEFAULT,
        };
    },
    methods: {
        show(isShowChooseFolder) {
            this.formChoose.isShowChooseFolder = isShowChooseFolder;
            this.formChoose.title = isShowChooseFolder
                ? this.$t("zimbra.zmMsg.chooseFolder")
                : this.$t("zimbraNokey.zimbra_setting_account_dialogChooseFolder_title2");
            this.dialogChooseFolder = true;
            this.formChoose.parent = {};
            if (this.$refs.treeFolderExtra) {
                this.$refs.treeFolderExtra.filterText = "";
            }
        },
        onSelectFolder(val) {
            this.formChoose.parent = val;
        },
        handleCancel() {
            this.dialogChooseFolder = false;
        },
        moveFolder() {
            if (this.$store.getters.activeFolderExtra) {
                this.treeNodeMove = this.$store.getters.activeFolderExtra;
                const form = {
                    folder: `<urn1:action id="${this.formChoose.parent.id}" op="move" l="${this.treeNodeMove.id}"></urn1:action>`,
                };
                this.listIdChild = [];
                this.getListIdChild(this.formChoose.parent);
                if (this.listIdChild.indexOf(this.treeNodeMove.id) > -1) {
                    this.$alert(
                        this.$t("zimbraNokey.zimbra_sidebar_messageCriticalMoveFolder"),
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                } else {
                    ZimbraMailService.folderActionRequest(form).then((res) => {
                        if (res.status == STATUS_REQUEST.SUCCESS) {
                            this.dialogchooseFolder = false;
                            this.$root.$emit(
                                "deleteItemToDataInBox",
                                this.formChoose.parent
                            );
                            this.$root.$emit(
                                "deleteItemToDataExtend",
                                this.formChoose.parent
                            );
                            this.formChoose.parent.l = this.treeNodeMove.id;
                            this.$root.$emit(
                                "addItemToDataInBox",
                                this.formChoose.parent
                            );
                            this.$root.$emit(
                                "addItemToDataExtend",
                                this.formChoose.parent
                            );
                            this.$message({
                                message: "!Ok",
                                type: "success",
                            });
                        }
                    });
                }
            }
        },
        getListIdChild(data) {
            this.listIdChild.push(data.id);
            if (data.folder) {
                for (var item in data.folder) {
                    this.getListIdChild(data.folder[item]);
                }
            }
        },
        handleApcepterAction() {
            if (this.formChoose.parent.id) {
                this.$emit("handleApcepterAction", this.formChoose.parent);
                this.dialogChooseFolder = false;
            } else {
                this.$confirm(
                    this.$t("zimbra.zmMsg.noTargetFolder"),
                    this.$t("zimbraNokey.zimbra_common_criticalMsg"),
                    {
                        showConfirmButton: false,
                        cancelButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "error",
                    }
                )
                    .then(() => {})
                    .catch(() => {});
            }
        },
    },
};
</script>