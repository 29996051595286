<template>
    <el-popover placement="left-center" trigger="click" @hide="handleHidePopover" @show="handleShowPopover"
        :visible-arrow="true" :popper-class="`list-category ${isShow ? 'show' : ''}`">
        <div>
            <div class="popover-arrow"></div>
            <div class="category-group">
                <div @click="handleCollapsePlatform" :class="`category-header${isCollapsePlatform ? ' open' : ''}`">
                    <a href="javascript:;" class="" data-toggle="collapse" data-target="#category-1"
                        aria-expanded="true" aria-controls="category-1">
                        <i class="far fa-chevron-up"></i>
                        Platform
                    </a>
                </div>
                <div id="category-1" :class="`collapse-body collapse${isCollapsePlatform ? ' show' : ''}`">
                    <div class="category-body">
                        <ul class="category-list">
                            <li class="category-item" v-for="item, idx in datapPlatform" :key="idx">
                                <a :href="item.link" target="_blank">
                                    <span class="icon"><img :src="item.img" alt=""></span>
                                    <span class="ct">
                                        <span class="name">{{ item.name }}</span>
                                        <span class="sub-name">{{ item.subName }}</span>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="category-group">
                <div @click="handleCollapseWork" :class="`category-header${isCollapseWork ? ' open' : ''}`">
                    <a href="javascript:;" class="" data-toggle="collapse" data-target="#category-2"
                        aria-expanded="false" aria-controls="category-2">
                        <i class="far fa-chevron-up"></i>
                        Work
                    </a>
                </div>
                <div id="category-2" :class="`collapse-body collapse${isCollapseWork ? ' show' : ''}`">
                    <div class="category-body">
                        <ul class="category-list">
                            <li class="category-item" v-for="item, idx in dataWork" :key="idx">
                                <a :href="item.link" target="_blank">
                                    <span class="icon"><img :src="item.img" alt=""></span>
                                    <span class="ct">
                                        <span class="name">{{ item.name }}</span>
                                        <span class="sub-name">{{ item.subName }}</span>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="category-group">
                <div @click="handleCollapseCollaboration"
                    :class="`category-header${isCollapseCollaboration ? ' open' : ''}`">
                    <a href="javascript:;" class="" data-toggle="collapse" data-target="#category-3"
                        aria-expanded="false" aria-controls="category-3">
                        <i class="far fa-chevron-up"></i>
                        Collaboration
                    </a>
                </div>
                <div id="category-3" :class="`collapse-body collapse${isCollapseCollaboration ? ' show' : ''}`">
                    <div class="category-body">
                        <ul class="category-list">
                            <li class="category-item" v-for="item, idx in dataCollaboration" :key="idx">
                                <a :href="item.link" target="_blank">
                                    <span class="icon"><img :src="item.img" alt=""></span>
                                    <span class="ct">
                                        <span class="name">{{ item.name }}</span>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="category-group">
                <div @click="handleCollapseKnowledge" :class="`category-header${isCollapseKnowledge ? ' open' : ''}`">
                    <a href="javascript:;" class="" data-toggle="collapse" data-target="#category-4"
                        aria-expanded="false" aria-controls="category-4">
                        <i class="far fa-chevron-up"></i>
                        Knowledge
                    </a>
                </div>
                <div id="category-4" :class="`collapse-body collapse${isCollapseKnowledge ? ' show' : ''}`">
                    <div class="category-body">
                        <ul class="category-list">
                            <li class="category-item" v-for="item, idx in dataKnowledge" :key="idx">
                                <a :href="item.link" target="_blank">
                                    <span class="icon"><img :src="item.img" alt=""></span>
                                    <span class="ct">
                                        <span class="name">{{ item.name }}</span>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
        </div>
        <div slot="reference" :class="[
            this.activeMenu === 'setting' ? 'activeMenu' : '',
            'element',
        ]">
            <i class="app-icon"></i>
        </div>
    </el-popover>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        activeMenu: {
            type: [String],
        },
    },
    data() {
        return {
            isCollapsePlatform: true,
            isShow: false,
            isCollapseWork: true,
            isCollapseCollaboration: true,
            isCollapseKnowledge: true,
            datapPlatform: [
                // {
                //     img: require('@/assets/zimbra/images/icon-category/ic-home-cate.svg'),
                //     name: "Workplace",
                //     subName: "Trang chủ",
                //     link: "https://connection.viettel.vn/"
                // },
                {
                    img: require('@/assets/zimbra/images/icon-category/ic-vops.svg'),
                    name: "Chat",
                    subName: "",
                    link: "https://vops.viettel.vn/"
                },
                // {
                //     img: require('@/assets/zimbra/images/icon-category/ic-account-cate.svg'),
                //     name: "Account",
                //     subName: "Tài khoản",
                //     link: "https://sso2.viettel.vn/"
                // }
                {

                    img: require('@/assets/zimbra/images/icon-category/ic-voffice-cate.svg'),
                    name: "Voffice",
                    subName: "",
                    link: "http://voffice.viettel.vn/"
                },

                {
                    img: require('@/assets/zimbra/images/icon-category/ic-vtf-cate.svg'),
                    name: "Viettel Family",
                    subName: "",
                    link: "https://viettelfamily.com/"
                },
                
                {
                    img: require('@/assets/zimbra/images/icon-category/ic-shield-cate.svg'),
                    name: "Datasecurity",
                    subName: "",
                    link: "https://datasecurity.viettel.vn/"
                }
            ],
            /*dataWork: [
                {

                    img: require('@/assets/zimbra/images/icon-category/ic-voffice-cate.svg'),
                    name: "Voffice",
                    subName: "",
                    link: "javascript:;"
                },
                {
                    img: require('@/assets/zimbra/images/icon-category/ic-work-cate.svg'),
                    name: "Công việc",
                    subName: "",
                    link: "javascript:;"
                },
                {
                    img: require('@/assets/zimbra/images/icon-category/ic-workflow-cate.svg'),
                    name: "Workflow",
                    subName: "Quy trình",
                    link: "javascript:;"
                }
            ],
            dataCollaboration: [
                {

                    img: require('@/assets/zimbra/images/icon-category/ic-vmail-cate.svg'),
                    name: "vMail 4.0",
                    subName: "",
                    link: `${window.location.origin}`
                },
                {
                    img: require('@/assets/zimbra/images/icon-category/ic-talk-cate.svg'),
                    name: "Tin nhắn",
                    subName: "",
                    link: "https://chat.viettel.vn/"
                },
                {
                    img: require('@/assets/zimbra/images/icon-category/ic-vtf-cate.svg'),
                    name: "Viettel Family",
                    subName: "",
                    link: "https://viettelfamily.com/"
                },
                {
                    img: require('@/assets/zimbra/images/icon-category/ic-shield-cate.svg'),
                    name: "Datasecurity",
                    subName: "",
                    link: "https://datasecurity.viettel.vn/"
                }
            ],
            dataKnowledge: [
                {

                    img: require('@/assets/zimbra/images/icon-category/ic-ask-cate.svg'),
                    name: "Hỏi đáp",
                    subName: "",
                    link: "https://forviettel.viettelfamily.com"
                },
                {
                    img: require('@/assets/zimbra/images/icon-category/ic-knowledge-cate.svg'),
                    name: "Kiến thức",
                    subName: "",
                    link: "http://kms.viettel.vn"
                },
                {
                    img: require('@/assets/zimbra/images/icon-category/ic-doc-cate.svg'),
                    name: "Tài liệu",
                    subName: "",
                    link: "http://kms.viettel.vn"
                },
                {
                    img: require('@/assets/zimbra/images/icon-category/ic-shield-cate.svg'),
                    name: "SKYT",
                    subName: "",
                    link: "http://skyt.viettel.vn"
                }
            ]*/
        }
    },
    watch: {
        show(status) {
        },
    },
    methods: {
        handleShowPopover() {
            this.isShow = true
            this.$emit("show", this.isShow);
        },
        handleHidePopover() {
            this.isShow = false
            this.$emit("show", this.isShow);
        },
        handleCollapsePlatform() {
            this.isCollapsePlatform = !this.isCollapsePlatform
        },
        handleCollapseWork() {
            this.isCollapseWork = !this.isCollapseWork
        },
        handleCollapseCollaboration() {
            this.isCollapseCollaboration = !this.isCollapseCollaboration
        },
        handleCollapseKnowledge() {
            this.isCollapseKnowledge = !this.isCollapseKnowledge
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/list-app.scss";
</style>