import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import EmailUtils from "@/utils/email-utils";
import {
    FOLDER_VIEW,
    FROM_ADDRESS_TYPE
} from "@/utils/constants";
import { ZmSetting, BASE64_TO_NORMAL_RATIO } from "../../utils/constants";
import _ from "lodash";
import i18n from "@/plugins/i18n";
export default {
    state: {
        currentExtraActiveEmail: null,
        currentActiveEmail: null,
        dataSidebarInBox: null,
        dataSidebarExtend: null,
        activeFolderExtra: null,
        activeNodeSidebar: null,
        tags: null, // list tag
        activeNodeSidebarRightClick: null,
        mapppingStarConv: [],
        fromAddresses: [],
        preference: {}, // cau hinh tuy chon
        identities: null,
        showSearchBox: false,
        showCheckBox: false,
        listQuery: [], // điều kiện tìm kiếm
        listQuerySub: [], // điều kiện tìm kiểm dùng để thay đổi ngôn ngữ tương ứng vs ứng dụng
        listQueryTemp: [], // điều kiện tìm kiếm
        listEmailSearch: [], // danh sách email trong inbox
        searchErrorMessage: [], // lỗi tìm kiếm
        modelSearch: {},
        folderSearches: [], // Lượt tìm kiếm
        implicitMailboxMetadata: {},
        folderArchive: {}, // folder lưu trữ
        maxSizeAttach: 37 * 1048576, // B
        listTag: [], // list tag
        listFileContacts: [],
        attributes: null, // attributes
        listSelectedMail: [], // list email được chọn
        signatures: [], // chữ ký
        emailAddress: "", // địa chỉ email
        defaultIdentityId: null,
        allTreeFolder: [], // Toàn bộ cây thư mục
        mailQuota: {}, // Dung lượng thư,
        isLoadDataLogin: false,
        listFolderContactsShare: [], // thư mục danh bạ chia sẻ,
        mapTabSetting: [],
        searchModelDialog: {} // giá trị tìm kiếm trên header
    },
    getters: {
        // lay gia tri cua state trong store
        currentActiveEmail: state => state.currentActiveEmail,
        currentExtraActiveEmail: state => state.currentExtraActiveEmail,
        activeFolderExtra: state => state.activeFolderExtra,
        dataSidebarInBox: state => state.dataSidebarInBox,
        dataSidebarExtend: state => state.dataSidebarExtend,
        activeNodeSidebar: state => state.activeNodeSidebar,
        activeNodeSidebarRightClick: state => state.activeNodeSidebarRightClick,
        tags: state => state.tags,
        mapppingStarConv: state => state.mapppingStarConv,
        fromAddresses: state => state.fromAddresses,
        preference: state => state.preference,
        identities: state => state.identities,
        showSearchBox: state => state.showSearchBox,
        listQuery: state => state.listQuery,
        listQuerySub: state => state.listQuerySub,
        listEmailSearch: state => state.listEmailSearch,
        searchErrorMessage: state => state.searchErrorMessage,
        modelSearch: state => state.modelSearch,
        folderSearches: state => state.folderSearches,
        implicitMailboxMetadata: state => state.implicitMailboxMetadata,
        folderArchive: state => state.folderArchive,
        maxSizeAttach: state => state.maxSizeAttach,
        showCheckBox: state => state.showCheckBox,
        listTag: state => {
            const _tags = state.listTag.map(e => ({
                ...e,
                nameUpper: e.name.toString().toUpperCase()
            }))
            return _.orderBy(_tags, ["nameUpper"], ["asc"])
        },
        listQueryTemp: state => state.listQueryTemp,
        listFileContacts: state => state.listFileContacts,
        attributes: state => state.attributes,
        listSelectedMail: state => state.listSelectedMail,
        signatures: state => state.signatures,
        emailAddress: state => state.emailAddress,
        defaultIdentityId: state => state.defaultIdentityId,
        allTreeFolder: state => state.allTreeFolder,
        mailQuota: state => state.mailQuota,
        treeCalendarFolder: (state) => {
            const _allTreeFolder = state.allTreeFolder || [];
            const _appoitment = _allTreeFolder.filter(e => e.view === FOLDER_VIEW.APPOINTMENT);
            return _appoitment;
        },
        isLoadDataLogin: state => state.isLoadDataLogin,
        listFolderContactsShare: state => state.listFolderContactsShare,
        mapTabSetting: state => state.mapTabSetting,
        
    },
    mutations: {
        SET_MAP_TAB_SETTING(state, data) {
            state.mapTabSetting = data;
        },
        // thay doi state,
        SET_FOLDER_CONTACTS_SHARE(state, data) {
            state.listFolderContactsShare = data;
        },
        SET_FILE_CONTACTS(state, data) {
            state.listFileContacts = data;
        },
        SET_ACTIVE_EMAIL(state, data) {
            state.currentActiveEmail = data;
        },
        SET_ACTIVE_EMAIL_EXTRA(state, data) {
            state.currentExtraActiveEmail = data;
        },
        SET_DATA_INBOX(state, data) {
            state.dataSidebarInBox = data;
        },
        SET_DATA_EXTEND(state, data) {
            state.dataSidebarExtend = data;
        },
        SET_ACTIVE_FOLDER_EXTRA(state, data) {
            state.activeFolderExtra = data;
        },
        SET_ACTIVE_NODE_SIDEBAR_RIGHTCLICK(state, data) {
            state.activeNodeSidebarRightClick = data;
        },
        SET_ACTIVE_NODE_SIDEBAR(state, data) {
            state.activeNodeSidebar = data;
        },
        SET_TAGS(state, data) {
            state.tags = data;
        },
        SET_LIST_TAG(state, data) {
            state.listTag = data;
        },
        SET_MAPPPING_STAR_CONV(state, data) {
            state.mapppingStarConv = data;
        },
        PUSH_MAPPPING_STAR_CONV(state, data) {
            if (Array.isArray(data)) {
                state.mapppingStarConv = [...state.mapppingStarConv, ...data];
            } else {
                state.mapppingStarConv = [...state.mapppingStarConv, data];
            }

        },
        SET_FROM_ADDRESS(state, data) {
            state.fromAddresses = data;
        },
        SET_PREFERENCE(state, data) {
            state.preference = data;
        },
        SET_IDENTITIES(state, data) {
            state.identities = data;
        },
        SET_MAIL_QUOTA(state, data) {
            state.mailQuota = data;
        },
        UPDATE_FLAG_STAR(state, data) {
            const { cid, id, star, field } = data;
            const mapppingStarConv = state.mapppingStarConv || [];
            const idx = mapppingStarConv.findIndex(e => field === 'cid' ? e.cid === cid : e.id === id);
            if (idx > -1) {
                mapppingStarConv[idx].star = star;
            } else {
                mapppingStarConv[mapppingStarConv.length] = data;
            }
            if (cid && field === 'cid') {
                mapppingStarConv.forEach(e => {
                    if (e.cid === cid) {
                        e.star = star;
                    }
                })
            } else if (field === 'id') {
                if (star) {
                    mapppingStarConv.forEach(e => {
                        if (e.cid === cid && _.isNil(e.id)) {
                            e.star = star;
                        }
                    });
                } else {
                    let isStar = false;
                    mapppingStarConv.forEach(e => {
                        if (e.cid === cid && !_.isNil(e.id)) {
                            isStar = isStar || e.star;
                        }
                    });
                    const idxRoot = mapppingStarConv.findIndex(e => e.cid === cid && _.isNil(e.id));
                    if (idxRoot > -1) {
                        mapppingStarConv[idxRoot].star = isStar;
                    }
                }
            }
            state.mapppingStarConv = _.cloneDeep(mapppingStarConv);
        },
        SET_SHOW_SEARCH_BOX(state, data) {
            state.showSearchBox = data;
            if (!data) {
                state.listQuery = [];
                // state.modelSearch = null;
            }
        },
        SET_LIST_QUERYSUB(state, data){
            state.listQuerySub = data;
        },
        SET_LIST_QUERY(state, data) {
            state.listQuery = data;
        },
        SET_LIST_QUERY_TEMP(state, data) {
            state.listQueryTemp = data;
        },
        SET_LIST_EMAIL(state, data) {
            state.listEmailSearch = data;
        },
        SET_SEARCH_ERROR_MESSAGE(state, data) {
            state.searchErrorMessage = data;
        },
        SET_MODEL_SEARCH(state, data) {
            state.modelSearch = data;
        },
        SET_FOLDER_SEARCH(state, data) {
            state.folderSearches = data;
        },
        SET_IMPLICIT_MAILBOX_METADATA(state, data) {
            state.implicitMailboxMetadata = data;
        },
        SET_FOLDER_ARCHIVE(state, data) {
            state.folderArchive = data;
        },
        SET_ATTRIBUTES(state, data) {
            state.attributes = data;
        },
        SET_LIST_SELECTED_MAIL(state, data) {
            state.listSelectedMail = data;
        },
        SET_SIGNATURES(state, data) {
            state.signatures = data;
        },
        SET_EMAIL_ADDRESS(state, data) {
            state.emailAddress = data;
        },
        SET_DEFAULT_IDENTITY(state, data) {
            state.defaultIdentityId = data;
        },
        SET_ALL_TREE_FOLDER(state, data) {
            state.allTreeFolder = data;
        },
        SET_MAX_SIZE_ATTACH(state, data) {
            state.maxSizeAttach = data;
        },
        LOAD_DONE_DATA_LOGIN(state, data) {
            state.isLoadDataLogin = data;
        },
        SET_SHOW_CHECK_BOX(state, data) {
            state.showCheckBox = data;
        }
    },
    actions: {
        // thay doi state xu ly bat dong bo
        async initialDataUserLogin({ commit, dispatch }) {
            const formData = {
                rights: [
                    { right: ZmSetting.SEND_AS },
                    { right: ZmSetting.SEND_ON_BEHALF_OF },
                ]
            };
            const discoverRights = await ZimbraMailService.discoverRightsRequest(formData);
            const infos = await ZimbraMailService.getInfoRequest({ sections: "mbox,prefs,sigs,idents,attrs,dsrcs" });
       
            const mailboxMetadata = await ZimbraMailService.getMailboxMetadataRequest({ section: "zwc:implicit" });
            let { targets } = CommonUtils.methods.getResponseBody(discoverRights)['DiscoverRightsResponse'];
            const { prefs, identities, id, attrs, signatures, name, used, dataSources, attSizeLimit } = CommonUtils.methods.getResponseBody(infos)['GetInfoResponse'];
            const { meta } = CommonUtils.methods.getResponseBody(mailboxMetadata)['GetMailboxMetadataResponse'];

            const preference = CommonUtils.methods.convertArrayToObject(prefs.pref);
            const attribute = CommonUtils.methods.convertArrayToObject(attrs.attr);
            preference['zimbraPrefMailTrustedSenderList'] = prefs.pref.filter(x => x.name == "zimbraPrefMailTrustedSenderList") || [];
            let dataIdentities = [];
            if (Array.isArray(identities.identity)) {
                dataIdentities = _.concat(dataIdentities, identities.identity.map(e => ({
                    name: e.name,
                    id: e.id,
                    // identity: CommonUtils.methods.convertArrayToObject(e.a),
                    identity: {
                        ...CommonUtils.methods.convertArrayToObject(e.a), // Preserve other properties
                        zimbraPrefWhenInFolderIds: e.a.filter(item => item.name === 'zimbraPrefWhenInFolderIds').map(item => item.content),
                      },
                })))
            } else if (identities.identity) {
                dataIdentities.push({
                    name: identities.identity.name,
                    id: identities.identity.id,
                    identity: CommonUtils.methods.convertArrayToObject(identities.identity.a),
                });
            }

            const _defaultIdentity = dataIdentities.find(e => e.id === id);
            if (_defaultIdentity.identity.zimbraPrefIdentityName) {
                if (_defaultIdentity.identity.zimbraPrefIdentityName === "DEFAULT") {
                    _defaultIdentity.identity.zimbraPrefIdentityName = i18n.t("zimbra.zmMsg.accountDefault");
                }
            } else {
                _defaultIdentity.identity.zimbraPrefIdentityName = i18n.t("zimbra.zmMsg.accountDefault");
            }
            const zimbraPrefIdentityName = _defaultIdentity.identity.zimbraPrefIdentityName;
            const zimbraPrefFromDisplay = _defaultIdentity.identity.zimbraPrefFromDisplay;
            const zimbraPrefFromAddress = _defaultIdentity.identity.zimbraPrefFromAddress;
            const defaultFromAddress = Math.random().toString(36).substring(2);

            let addressEmails = [
                {
                    d: zimbraPrefFromDisplay,
                    a: zimbraPrefFromAddress,
                    label: zimbraPrefFromDisplay 
                    ? `${zimbraPrefIdentityName} (${zimbraPrefFromDisplay} <${zimbraPrefFromAddress}>)` 
                    : `${zimbraPrefIdentityName} <${zimbraPrefFromAddress}>`,
                    uuid: defaultFromAddress,
                    identityId: _defaultIdentity?.id,
                    identity: _defaultIdentity?.identity,
                    type: FROM_ADDRESS_TYPE.MAIN_ACCOUNT
                }
            ];

            const _otherAccount = dataIdentities.filter(e => e.id !== id);
            if (_otherAccount) {
                addressEmails = _.concat(addressEmails, _otherAccount.map(e => ({
                    d: e.identity.zimbraPrefFromDisplay,
                    a: e.identity.zimbraPrefFromAddress,
                    identityId: e.id,
                    identity: e.identity,
                    label: e.identity.zimbraPrefFromDisplay ? 
                    `${e.identity.zimbraPrefIdentityName} (${e.identity.zimbraPrefFromDisplay} <${e.identity.zimbraPrefFromAddress}>)` :
                    `${e.identity.zimbraPrefIdentityName} <${e.identity.zimbraPrefFromAddress}>`,
                    uuid: Math.random().toString(36).substring(2),
                    type: FROM_ADDRESS_TYPE.IDENTITY
                })));
            }
            if (targets) {
                targets = Array.isArray(targets) ? targets : [targets];
            } else {
                targets = [];
            }
            if (targets && targets.length > 0) {
                targets.forEach(target => {
                    if (Array.isArray(target.target)) {
                        addressEmails = _.concat(addressEmails, target.target.map(e => ({
                            d: e.d,
                            a: e.email.addr,
                            label: target.right === ZmSetting.SEND_ON_BEHALF_OF ? i18n.t('zimbra.zmMsg.sendOboAddressAndDispName', [name, e.email.addr, e.d]) : `${e.d} <${e.email.addr}>`,
                            uuid: Math.random().toString(36).substring(2),
                            type: FROM_ADDRESS_TYPE.DISCOVER_RIGHTS
                        })));
                    } else if (target.target) {
                        addressEmails.push({
                            d: target.target.d,
                            a: target.target.email.addr,
                            label: target.right === ZmSetting.SEND_ON_BEHALF_OF ? i18n.t('zimbra.zmMsg.sendOboAddressAndDispName', [name, target.target.d, target.target.email.addr]) : `${target.target.d} <${target.target.email.addr}>`,
                            uuid: Math.random().toString(36).substring(2),
                            type: FROM_ADDRESS_TYPE.DISCOVER_RIGHTS
                        });
                    }
                });
            }
            if (dataSources && dataSources.pop3) {
                const _pop = Array.isArray(dataSources.pop3) ? dataSources.pop3 : [dataSources.pop3];
                addressEmails = _.concat(addressEmails, _pop.map(e => ({
                    ...e,
                    identityId: e.id,
                    identity: {
                        zimbraPrefDefaultSignatureId: e.defaultSignature,
                        zimbraPrefForwardReplySignatureId: e.forwardReplySignature
                    },
                    d: e.fromDisplay,
                    a: e.emailAddress,
                    label: e.fromDisplay ? `${e.name} (${e.fromDisplay} <${e.emailAddress}>)` : `${e.name} <${e.emailAddress}>`,
                    uuid: Math.random().toString(36).substring(2),
                    type: FROM_ADDRESS_TYPE.POP
                })));
            }
            if (dataSources && dataSources.imap) {
                const _map = Array.isArray(dataSources.imap) ? dataSources.imap : [dataSources.imap];
                addressEmails = _.concat(addressEmails, _map.map(e => ({
                    ...e,
                    identityId: e.id,
                    identity: {
                        zimbraPrefDefaultSignatureId: e.defaultSignature,
                        zimbraPrefForwardReplySignatureId: e.forwardReplySignature
                    },
                    d: e.fromDisplay,
                    a: e.emailAddress,
                    label: e.fromDisplay ? `${e.name} (${e.fromDisplay} <${e.emailAddress}>)` : `${e.name} <${e.emailAddress}>`,
                    uuid: Math.random().toString(36).substring(2),
                    type: FROM_ADDRESS_TYPE.MAP
                })));
            }
            preference['defaultFromAddress'] = defaultFromAddress;
            commit("SET_FROM_ADDRESS", addressEmails);
            commit("SET_PREFERENCE", preference);
            commit("SET_ATTRIBUTES", attribute);
            commit("SET_IDENTITIES", dataIdentities);
            commit("SET_DEFAULT_IDENTITY", id);
            commit("SET_MAIL_QUOTA", { zimbraMailQuota: attribute.zimbraMailQuota, used: used });
            commit("SET_SIGNATURES", Array.isArray(signatures?.signature)
                ? signatures?.signature
                : signatures?.signature
                    ? [signatures?.signature]
                    : []);
            commit("SET_IMPLICIT_MAILBOX_METADATA", CommonUtils.methods.convertArrayToObject(meta.a, "n", "content"));
            dispatch("initialDataContact");
            dispatch("getTreeFolder");
            commit("SET_EMAIL_ADDRESS", name);
            commit("SET_MAX_SIZE_ATTACH", attSizeLimit / BASE64_TO_NORMAL_RATIO);
            commit("LOAD_DONE_DATA_LOGIN", true);
        },
        async initialDataContact({ commit }) {
            const formDataEmailedContacts = {
                query: "in:\"Emailed Contacts\"",
                offset: 0,
                limit: 100,
                sortBy: "nameAsc",
                types: "contact",
            };
            const formDataContacts = {
                query: "in:\"contacts\"",
                offset: 0,
                limit: 100,
                sortBy: "nameAsc",
                types: "contact",
            };
            let fileContacts = [];
            const searchRequestEmailedContacts = await ZimbraMailService.searchRequest(formDataEmailedContacts);
            const searchRequestContacts = await ZimbraMailService.searchRequest(formDataContacts);
            const searchResponseEmailedContacts =
                CommonUtils.methods.getResponseBody(searchRequestEmailedContacts)["SearchResponse"];
            const searchResponseContacts =
                CommonUtils.methods.getResponseBody(searchRequestContacts)["SearchResponse"];
            const responseEmailedContacts = Array.isArray(searchResponseEmailedContacts.cn) ? searchResponseEmailedContacts.cn : (searchResponseEmailedContacts.cn ? [searchResponseEmailedContacts.cn] : []);
            const responseContacts = Array.isArray(searchResponseContacts.cn) ? searchResponseContacts.cn : (searchResponseContacts.cn ? [searchResponseContacts.cn] : []);
            if (responseEmailedContacts) {
                fileContacts = responseEmailedContacts;
            }
            if (responseContacts) {
                fileContacts = [...fileContacts, ...responseContacts];
            }
            const _groups = fileContacts.filter(contact => contact.m);
            await EmailUtils.methods.handleReplaceContactGroup(_groups, fileContacts);
            commit("SET_FILE_CONTACTS", fileContacts);
        },
        // get all folder
        getTreeFolder({ commit }) {
            const formData = {
                folder: `<view>1</view><depth>-1</depth><tr>1</tr><visible>1</visible><needGranteeName>1</needGranteeName>`,
            };
            ZimbraMailService.getFolderRequest(
                formData
            ).then(searchRequest => {
                const searchActionResponse = CommonUtils.methods.getResponseBody(searchRequest)["GetFolderResponse"];
                let data = searchActionResponse.folder.folder;
                if (searchActionResponse.folder.link) {
                    let dataLink = searchActionResponse.folder.link;
                    EmailUtils.methods.tickLinkFolder(dataLink);
                    data = data.concat(dataLink);
                }
                EmailUtils.methods.objectToArr(data, true);
                data = EmailUtils.methods.reorderFolder(data);
                EmailUtils.methods.renameAbsFolderPath(data);
                commit("SET_ALL_TREE_FOLDER", data);
            });
        }
    },
}

