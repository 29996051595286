<template>
    <div class="card-filter-row">
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueMainSelect"
                :options="mainSelect"
                @change="handleChangeMainSelect"
            />
        </div>
        <div class="card-filter-item"></div>
        <div class="card-filter-item">
            <base-input
                type="text"
                :value="valueHeader"
                @input="handleChangeValueHeader"
            />
        </div>
        <div class="card-filter-item select-wrap__300">
            <base-select
                displayField="name"
                valueField="stringNameComparison"
                :value="valueHeaderNameComparison"
                :options="stringNameComparison"
                @change="handleChangeHeaderNameComparison"
            />
        </div>
        <div class="card-filter-item" v-if="isHeaderTest">
            <base-input
                type="text"
                :value="valueTitle"
                @input="handleChangeValueTitle"
            />
        </div>
    </div>
</template>

<script>
import { FILTER_TEST_CONFIG, FILTER_TESTS } from "@/utils/constants";
import BaseSelect from "@/components/modules/base/base-select";
import BaseInput from "@/components/modules/base/base-input";
import CoreTest from "@/components/modules/base/base-filter-form/core-test";
export default {
    extends: CoreTest,
    components: {
        BaseSelect,
        BaseInput,
    },
    created() {
        this.beforeBuildDataForm();
        this.conditionsHeaderNameTest = this.conditionsTest;
    },
    watch: {
        conditionsTest(newData) {
            this.beforeBuildDataForm();
            this.conditionsHeaderNameTest = newData;
        },
    },
    data() {
        return {
            FILTER_TEST_CONFIG: FILTER_TEST_CONFIG,
            FILTER_TESTS: FILTER_TESTS,
            stringNameComparison: [
                {
                    stringNameComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.IS,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_IS"
                    ),
                },
                {
                    stringNameComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_IS,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_NOT_IS"
                    ),
                },
                {
                    stringNameComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.CONTAINS,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_CONTAINS"
                    ),
                },
                {
                    stringNameComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_CONTAINS,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_NOT_CONTAINS"
                    ),
                },
                {
                    stringNameComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.MATHCHES,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_MATCHES"
                    ),
                },
                {
                    stringNameComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_MATHCHES,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_NOT_MATCHES"
                    ),
                },
                {
                    stringNameComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.EXIST,
                    name: "tồn tại",
                },
                {
                    stringNameComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_EXIST,
                    name: "không tồn tại",
                },
            ],
            valueHeader: "",
            valueMainSelect: "",
            valueHeaderNameComparison: "",
            valueTitle: "",
            isHeaderTest: true,
            conditionsHeaderNameTest: {},
        };
    },
    methods: {
        beforeBuildDataForm() {
            this.valueMainSelect = this.mainSelect[14].id;
            this.valueHeader = this.conditionsTest.header;
            // SET-VALUE-STRING-COMPARISON
            if (
                this.conditionsTest.stringComparison ===
                FILTER_TEST_CONFIG.STRING_COMPARTION.IS
            ) {
                this.valueHeaderNameComparison =
                    FILTER_TEST_CONFIG.STRING_COMPARTION.IS;
                this.isHeaderTest = true;
                if (this.conditionsTest.negative) {
                    this.valueHeaderNameComparison =
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_IS;
                }
            }
            if (
                this.conditionsTest.stringComparison ===
                FILTER_TEST_CONFIG.STRING_COMPARTION.CONTAINS
            ) {
                this.valueHeaderNameComparison =
                    FILTER_TEST_CONFIG.STRING_COMPARTION.CONTAINS;
                this.isHeaderTest = true;
                if (this.conditionsTest.negative) {
                    this.valueHeaderNameComparison =
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_CONTAINS;
                }
            } else if (
                this.conditionsTest.stringComparison ===
                FILTER_TEST_CONFIG.STRING_COMPARTION.MATHCHES
            ) {
                this.valueHeaderNameComparison =
                    FILTER_TEST_CONFIG.STRING_COMPARTION.MATHCHES;
                this.isHeaderTest = true;
                if (this.conditionsTest.negative) {
                    this.valueHeaderNameComparison =
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_MATHCHES;
                }
            } else if (
                this.conditionsTest.types === FILTER_TESTS.HEADER_EXITS_TEST
            ) {
                this.valueHeaderNameComparison =
                    FILTER_TEST_CONFIG.STRING_COMPARTION.EXIST;
                this.isHeaderTest = false;
                if (this.conditionsTest.negative) {
                    this.valueHeaderNameComparison =
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_EXIST;
                }
            }

            this.valueTitle = this.conditionsTest.value;
        },
        handleChangeValueHeader(valueHeader) {
            this.valueHeader = valueHeader;
            this.conditionsHeaderNameTest.header = valueHeader;

            this.$emit("onChangeConditionsTest", this.conditionsHeaderNameTest);
        },
        handleChangeHeaderNameComparison(headerNameComparison) {
            if (
                headerNameComparison ===
                    FILTER_TEST_CONFIG.STRING_COMPARTION.EXIST ||
                headerNameComparison ===
                    FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_EXIST
            ) {
                this.conditionsHeaderNameTest.types =
                    FILTER_TESTS.HEADER_EXITS_TEST;
                delete this.conditionsHeaderNameTest.value;
                delete this.conditionsHeaderNameTest.stringComparison;
                this.isHeaderTest = false;
            } else {
                this.conditionsHeaderNameTest.types = FILTER_TESTS.HEADER_TEST;
                this.isHeaderTest = true;
                this.conditionsHeaderNameTest.stringComparison =
                    this.replacePrefix(headerNameComparison);
                if (!this.conditionsTest.value) {
                    this.conditionsHeaderNameTest = {
                        ...this.conditionsHeaderNameTest,
                        value: this.valueTitle,
                    };
                }
                if (!this.conditionsTest.stringComparison) {
                    this.conditionsHeaderNameTest = {
                        ...this.conditionsHeaderNameTest,
                        stringComparison:
                            this.replacePrefix(headerNameComparison),
                    };
                }
            }
            for (let i in this.conditionsHeaderNameTest) {
                if (
                    i !== "negative" &&
                    this.validateNegative(headerNameComparison)
                ) {
                    this.conditionsHeaderNameTest = {
                        ...this.conditionsHeaderNameTest,
                        negative: FILTER_TEST_CONFIG.NEGATIVE,
                    };
                } else {
                    if (!this.validateNegative(headerNameComparison)) {
                        delete this.conditionsHeaderNameTest.negative;
                    }
                }
            }
            this.$emit("onChangeConditionsTest", this.conditionsHeaderNameTest);
        },
        handleChangeValueTitle(valueTitle) {
            this.valueTitle = valueTitle;
            this.conditionsHeaderNameTest.value = valueTitle;
            this.$emit("onChangeConditionsTest", this.conditionsHeaderNameTest);
        },
    },
};
</script>