import BaseService from "@/shared/services/BaseService";
import CommonUtils from "@/utils/common-utils";
import CONFIG from '@/config/config';
class InboxService extends BaseService {
    url = CONFIG.ENDPOINT['compileService'];

    constructor() {
        super("zimbraService");
    }

    handleJsonToString(obj) {
        return JSON.stringify(obj);
    }

    getAll(data) {
        return this.postRequest(this.url, data);
    }

    getBlackWhiteList() {
        const url = `${this.baseURL}/getWhiteBlackListRequest`;
        return this.postRequest(url);
    }

    getPrefsRequest(data = { xml: "" }) {
        const url = `${this.baseURL}/getPrefsRequest`;
        return this.postRequest(url, data);
    }

    saveSetting(data) {
        const listRequest = [];
        if (data.prefList.length > 0) {
            let xmlStr = "";
            data.prefList.forEach(pref => {
                if (pref.name === "zimbraPrefPop3DownloadSince" && pref.content !== "") {
                    const tz = CommonUtils.methods.convertToISO8601(new Date());
                    xmlStr += `<urn1:pref name='${pref.name}'>${tz}</urn1:pref>`;
                } else {
                    xmlStr += `<urn1:pref name='${pref.name}'>${typeof pref.content === "boolean" ? pref.content.toString().toUpperCase() : pref.content}</urn1:pref>`;
                }
            });
            listRequest.push({
                path: "modifyPrefsRequest",
                content: this.handleJsonToString({ xml: xmlStr })
            });
        }
        if (data.blackList.length > 0 || data.whiteList.length > 0) {
            const backListString = data.blackList.length > 0 ? `<urn1:blackList>${data.blackList.map(item => `<urn1:addr op='${item.operator}'>${item.email}</urn1:addr>`).join("")}</urn1:blackList>` : "";
            const whiteListString = data.whiteList.length > 0 ? `<urn1:whiteList>${data.whiteList.map(item => `<urn1:addr op='${item.operator}'>${item.email}</urn1:addr>`).join("")}</urn1:whiteList>` : "";
            listRequest.push({
                path: "modifyWhiteBlackListRequest",
                content: this.handleJsonToString({
                    xml: backListString.concat(whiteListString)
                })
            });
        }

        return this.postRequest(this.url, { listRequest: listRequest });
    }

    modifyBlackList(data) {
        return this.postRequest(this.url, {
            listRequest: [
                {
                    path: "modifyWhiteBlackListRequest",
                    content: this.handleJsonToString({
                        xml: `<urn1:blackList>${data.map(
                            item =>
                                `<urn1:addr op='${item.operator}'>${item.email}</urn1:addr>`
                        )}</urn1:blackList>`
                    })
                }
            ]
        });
    }

    modifyWhiteList(data) {
        return this.postRequest(this.url, {
            listRequest: [
                {
                    path: "modifyWhiteBlackListRequest",
                    content: this.handleJsonToString({
                        xml: `<urn1:whiteList>${data.map(
                            item =>
                                `<urn1:addr op='${item.operator}'>${item.email}</urn1:addr>`
                        )}</urn1:whiteList>`
                    })
                }
            ]
        });
    }
}

export default new InboxService();
