<template>
    <div class="card-filter-row">
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueMainSelect"
                :options="mainSelect"
                @change="handleChangeMainSelect"
            />
        </div>
        <div class="card-filter-item"></div>
        <div class="card-filter-item select-wrap__210">
            <base-select
                displayField="name"
                valueField="socialCompartison"
                :value="valueSocialCompartison"
                :options="socialCompartison"
                @change="handleChangeSocialTest"
            />
        </div>
        <div class="card-filter-item"></div>
        <div class="card-filter-item"></div>
    </div>
</template>

<script>
import { FILTER_TEST_CONFIG, FILTER_TESTS } from "@/utils/constants";
import CoreTest from "@/components/modules/base/base-filter-form/core-test";
import BaseSelect from "@/components/modules/base/base-select";
export default {
    extends: CoreTest,
    components: {
        BaseSelect,
    },
    created() {
        this.beforeBuildDataForm();
        this.conditionsSocialTest = this.conditionsTest;
    },
    watch: {
        conditionsTest(newData) {
            this.beforeBuildDataForm();
            this.conditionsSocialTest = newData;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this._addShadow()
        })
    },
    data() {
        return {
            FILTER_TEST_CONFIG: FILTER_TEST_CONFIG,
            FILTER_TESTS: FILTER_TESTS,
            socialCompartison: [
                {
                    socialCompartison: FILTER_TEST_CONFIG.SOCIAL.FACEBOOK,
                    name: "Thông báo của FaceBook",
                },
                {
                    socialCompartison: FILTER_TEST_CONFIG.SOCIAL.LINKEDIN,
                    name: "Thông báo của LinedIn",
                },
                {
                    socialCompartison: FILTER_TEST_CONFIG.SOCIAL.TWITTER,
                    name: "Thông báo của Twitter",
                },
            ],
            valueMainSelect: "",
            valueSocialCompartison: "",
            conditionsSocialTest: {},
        };
    },
    methods: {
        beforeBuildDataForm() {
            this.valueMainSelect = this.mainSelect[13].id;

            if (this.conditionsTest.types === FILTER_TESTS.FACEBOOK_TEST)
                this.valueSocialCompartison =
                    FILTER_TEST_CONFIG.SOCIAL.FACEBOOK;
            else if (this.conditionsTest.types === FILTER_TESTS.LINKEDIN_TEST)
                this.valueSocialCompartison =
                    FILTER_TEST_CONFIG.SOCIAL.LINKEDIN;
            else if (this.conditionsTest.types === FILTER_TESTS.TWITTER_TEST)
                this.valueSocialCompartison = FILTER_TEST_CONFIG.SOCIAL.TWITTER;
        },
        handleChangeSocialTest(social) {
            if (social === FILTER_TEST_CONFIG.SOCIAL.FACEBOOK)
                this.conditionsSocialTest.types = FILTER_TESTS.FACEBOOK_TEST;
            else if (social === FILTER_TEST_CONFIG.SOCIAL.TWITTER)
                this.conditionsSocialTest.types = FILTER_TESTS.TWITTER_TEST;
            else if (social === FILTER_TEST_CONFIG.SOCIAL.LINKEDIN)
                this.conditionsSocialTest.types = FILTER_TESTS.LINKEDIN_TEST;
            this.$emit("onChangeConditionsTest", this.conditionsSocialTest);
        },
    },
};
</script>
