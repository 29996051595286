<template>
    <el-button :size="size" type="default" :disabled="disabled" @click="handleClick"
    >
        <slot
        />
    </el-button>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                default: "",
            },
            size: {
                type: String,
                default: "small",
            },
            disabled: {
                type: Boolean,
                default: false,
            }
        },
        methods: {
            handleClick() {
                this.$emit("click");
            },
        },
    };
</script>

<style>
</style>
