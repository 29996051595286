<template>
    <el-dialog
        class="rename-tag-div dialog-body-wrap zimbra-dialog zimbra-limited-width-dialog"
        :title="
          $t('zimbraNokey.zimbra_sidebar_dialogReNameTag_title') + ` ${treeNodeData.name}`
        "
        :visible.sync="dialogReNameTag"
        width="25%"
        :append-to-body="true"
        :close-on-click-modal="false"
    >
        <el-form class="form-wrap">
          <span>{{ $t("zimbraNokey.zimbra_sidebar_dialogReNameTag_tagName") }}</span>
          <el-input
            v-model="newName"
            autocomplete="off"
            size="medium"
            class="el-text-control"
            @change="changeNewName"
          ></el-input>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogReNameTag = false">{{
            $t("zimbra.zhMsg.actionTaskCancelTT")
          }}</el-button>
          <el-button type="primary" @click="reNameTag">{{
            $t("zimbra.zmMsg.mobileStatusNeedProvision")
          }}</el-button>
        </span>
      </el-dialog>
</template>
<script>
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import { mapGetters } from "vuex";
import { STATUS_REQUEST, READ_EMAIL_LAYOUT_ROUTER } from "@/utils/constants";
import _ from "lodash";
import $ from 'jquery';
export default {
    mixins: [CommonUtils],
    computed: {
        ...mapGetters(["tags", "listTag"]),
    },
    mounted() {
        this.$root.$on("handleTagActionReName", (data) => {
            this.show(data);
        });
    },
    data() {
        return {
            treeNodeData: {},
            newName: "",
            dialogReNameTag: false,
        };
    },
    methods: {
        show(data) {
            this.treeNodeData = data;
            this.newName = this.treeNodeData.name;
            this.dialogReNameTag = true;
            // Cách first focus input
            $(document).ready(function () {
                setTimeout(function () {
                    const divMainContract = $(".rename-tag-div");
                    const firstInput = $(":input:not(input[type=button],input[type=submit],button):visible:first", divMainContract);
                    firstInput.focus();
                }, 500);
            });
            const { name } = this.$route;
            if (name == READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE) {
                this.isMessage = true
            } else {
                this.isMessage = false
            }
        },
        reNameTag() {
            this.newName = this.newName.toString().trim();
            if (
                this.newName === "" ||
                this.newName.length > 128 ||
                this.newName.indexOf("/") > -1
            ) {
                const nameError = _.escape(this.newName);
                const Msgerror =
                    this.newName === ""
                        ? this.$t("zimbra.zmMsg.nameEmpty")
                        : this.newName.length > 128
                            ? this.$t("zimbra.zhMsg.nameTooLong", { 0: 128 })
                            : this.$t(
                                "zimbra.zmMsg.errorInvalidName",
                                { 0: nameError }
                            );
                this.$alert(Msgerror, this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"), {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    customClass: "notification-error-tag",
                    type: "error"
                });
                return;
            }
            if (this.newName.toLowerCase() != this.treeNodeData.name.toString().toLowerCase()) {
                if (this.checkExistsName(this.newName)) {
                    return;
                }
            }
            const newName = _.escape(this.newName)
            const form = {
                tag: `<urn1:action id="${this.treeNodeData.id}" op="rename" name="${newName}"></urn1:action>`,
            };
            ZimbraMailService.tagActionRequest(form).then((res) => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    if (this.isMessage) {
                        this.$message({
                            type: "success",
                            customClass: 'zm-notify',
                            duration: 3500,
                            dangerouslyUseHTMLString: true,
                            message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessReNameTag"),
                        });
                    } else {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessReNameTag"),
                            type: "success",
                        });
                    }
                }
                this.tags.name = this.newName;
                this.$store.commit("SET_TAGS", this.tags);
            });
            this.dialogReNameTag = false;
        },
        changeNewName() {
            this.newName = this.newName ? this.newName.trim() : '';
        },
        checkExistsName(name) {
            if (this.listTag) {
                if (this.listTag.map(x => x.name.toString().toLowerCase()).indexOf(name.toString().toLowerCase()) > -1) {
                    this.$alert(
                        this.$t("zimbra.zmMsg.tagNameExists", {
                            name: name
                        }),
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error"
                        }
                    );
                    return true;
                }
            }
        },
    },
};
</script>
