import BaseService from "../shared/services/BaseService";
import axios from "axios";
import CONFIG from '@/config/config';

class UploadService extends BaseService {
    constructor() {
        super('uploadService');
    }

    uploadFile(formData) {
        const url = `${this.baseURL}?fmt=extended,raw`;
        // document.cookie = `ZM_AUTH_TOKEN=${this.getAuthToken()}`;
        const config = {
            baseURL: url,
            timeout: CONFIG.TIMEOUT_REQUEST,
            headers: {
                "X-Gravitee-Api-Key": CONFIG.X_GRAVITEE_API_KEY_SERVICE_UPLOAD,
                "X-Cookie": `ZM_AUTH_TOKEN=${this.getAuthToken()}`,
                "Access-Control-Allow-Credentials": true,
                "X-Requested-With": "XMLHttpRequest",
                "Cache-Control": "no-cache",
                "content-type": "multipart/form-data"
            },
            validateStatus: function (status) {
                return status >= 200 && status < 500; // default
            },
            // withCredentials: true,
            // exposedHeaders: ["set-cookie"],
        }
        const instanceService = axios.create(config);
        return instanceService.post(url, formData, config);
    }


    getAuthToken() {
        const store = localStorage.getItem('zimbra_token');
        if (store != null) {
            const zimbraToken = JSON.parse(store);
            return zimbraToken.auth_token || '';
        }
        return '';
    }
}

export default new UploadService();
