var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"setting-tree-wrap"},[_c('div',[_c('el-input',{ref:"refInputSearch",staticClass:"el-text-control",staticStyle:{"padding-bottom":"10px"},attrs:{"size":"medium"},on:{"blur":_vm.blurInput},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}})],1),_c('perfect-scrollbar',{staticStyle:{"height":"300px"},attrs:{"id":"treeExtra"}},[(_vm.folderType == _vm.FOLDER_TYPE.FOLDER)?_c('el-tree',{ref:"treeInbox",staticClass:"tree-wrap disabled-root-node",staticStyle:{"margin-top":"10px"},attrs:{"node-key":"id","data":_vm.dataInboxSelector,"props":_vm.defaultProps,"expand-on-click-node":false,"draggable":"","highlight-current":"","filter-node-method":_vm.filterNode,"empty-text":"","default-expanded-keys":_vm.listIdExpanded},on:{"node-click":_vm.nodeClick},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('div',{staticClass:"zm-folder-info"},[_c('span',{staticClass:"custom-tree-node",attrs:{"slot":"reference"},slot:"reference"},[(
                            data.id != _vm.FOLDER_DEFAULT.DRAFTS &&
                            data.id != _vm.FOLDER_DEFAULT.INBOX &&
                            data.id != _vm.FOLDER_DEFAULT.SENT &&
                            data.id != _vm.FOLDER_DEFAULT.STARS &&
                            data.id != _vm.FOLDER_DEFAULT.ROOT &&
                            data.isLink == undefined
                        )?_c('span',[_c('i',{staticClass:"ic-folder",style:(`padding-right: 30px; margin-left: -8px;filter: ${_vm.convertHexToFilterCss(
                                data
                            )}`)})]):_vm._e(),(data.isLink)?_c('span',[_c('i',{staticClass:"ic-folder-copy",staticStyle:{"padding-right":"30px","margin-left":"-8px"}})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.STARS)?_c('span',[_c('i',{staticClass:"ic-star",staticStyle:{"padding-right":"30px","margin-left":"-8px"}})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.SENT)?_c('span',[_c('i',{staticClass:"ic-sent",staticStyle:{"padding-right":"30px","margin-left":"-8px"}})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.DRAFTS)?_c('span',[_c('i',{staticClass:"ic-draft",staticStyle:{"padding-right":"30px","margin-left":"-8px","zoom":"1.05"}})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.INBOX)?_c('span',[_c('i',{class:`ic-inbox ${
                                node.expanded == true ? 'active' : ''
                            }`,staticStyle:{"padding-right":"30px","margin-left":"-8px","zoom":"1.05"}})]):_vm._e(),(
                            data.isLink == undefined ||
                            (data.isLink && data.perm)
                        )?_c('span',[(node.label.length > 30)?_c('span',[_vm._v(" "+_vm._s(node.label.substring(0, 30) + "...")+" ")]):_c('span',[_vm._v(" "+_vm._s(node.label)+" ")])]):_vm._e(),(data.isLink && data.perm == undefined)?_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(node.label)+" ")]):_vm._e()])])}}],null,false,1516410154)}):_vm._e(),_c('el-tree',{ref:"tree",staticClass:"tree-wrap disabled-root-node",staticStyle:{"margin-top":"10px"},attrs:{"node-key":"id","data":_vm.dataSelector,"props":_vm.defaultProps,"expand-on-click-node":false,"draggable":"","highlight-current":"","filter-node-method":_vm.filterNode,"empty-text":"","default-expanded-keys":_vm.listIdExpanded},on:{"node-click":_vm.nodeClick},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('div',{staticClass:"zm-folder-info"},[_c('span',{staticClass:"custom-tree-node"},[(
                            data.id != _vm.FOLDER_DEFAULT.TRASH &&
                            data.id != _vm.FOLDER_DEFAULT.JUNK &&
                            data.id != _vm.FOLDER_DEFAULT.EXTEND &&
                            data.id != _vm.FOLDER_DEFAULT.TAG &&
                            data.isLink == undefined
                        )?_c('span',[_c('i',{staticClass:"ic-folder",style:(`padding-right: 30px; margin-left: -8px;filter: ${_vm.convertHexToFilterCss(
                                data
                            )}`)})]):_vm._e(),(data.isLink)?_c('span',[_c('i',{staticClass:"ic-folder-copy",style:(`padding-right: 30px; margin-left: -8px;filter: ${_vm.convertHexToFilterCss(
                                data
                            )}`)})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.JUNK)?_c('span',[_c('i',{staticClass:"ic-spam",staticStyle:{"padding-right":"28px","margin-left":"-9px"}})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.TRASH)?_c('span',[_c('i',{staticClass:"ic-trash",staticStyle:{"padding-right":"28px","margin-left":"-9px"}})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.TAG)?_c('span',[_c('i',{staticClass:"ic-tag",staticStyle:{"padding-right":"28px","margin-left":"-9px"}})]):_vm._e(),(
                            data.id != _vm.FOLDER_DEFAULT.EXTEND &&
                            (data.isLink == undefined ||
                                (data.isLink && data.perm))
                        )?_c('span',[_vm._v(_vm._s(node.label))]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.EXTEND)?_c('span',[_vm._v(_vm._s(node.expanded ? _vm.$t("zimbra.zmMsg.collapse") : _vm.$t("zimbraNokey.zimbra_sidebar_extend")))]):_vm._e(),(data.isLink && data.perm == undefined)?_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(node.label))]):_vm._e()])])}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }