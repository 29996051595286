<template>
    <div class="zm-suggestion-info white-space-normal">
        <div class="zm-suggestion-header d-flex justify-content-between">
            <div>
                {{ title }}
            </div>
            <div>
                <a href="javascript:;" @click="openSettingPrefence">
                    <i class="ic ic-setting p-3"></i>
                </a>
                <a
                    href="javascript:;"
                    @click="closeSuggest"
                    class="btn-close ml-2"
                >
                    <i class="ic ic-close-gray p-3"></i>
                </a>
            </div>
        </div>
        <div>
            <div v-show="loading" class="p-3">
                <el-skeleton :rows="6" :loading="loading" animated />
            </div>
            <div v-show="!loading">
                <template v-if="suggestType === 'time'">
                    <!-- Gợi ý thời gian -->
                    <div class="pt-2">
                        <CalendarMini
                            :free-busy-cal="freeBusyCal"
                            :start-date="startDate"
                            @onSelectDate="onSelectDate"
                        />
                    </div>
                    <template v-if="listSuggestion && listSuggestion.length > 0">
                        <ul>
                            <perfect-scrollbar class="zimbra-suggest-results">
                                <div :class="item.class" v-for="(item, idx) in listSuggestion" :key="'sugget_' + idx">
                                    <li class="active ls-header-label"
                                        v-html="$t('zimbra.zmMsg.availableCount', [item.count, emailList.length])"></li>
                                    <template v-for="(useTime, index) in item.lstResult">
                                        <template v-if="item.class !== 'green-light'">
                                            <el-popover
                                                :key="'suggetTime_' + index"
                                                trigger="hover"
                                                :open-delay="1000"
                                            >
                                                <div v-html="getContentTooltip(useTime.time)"></div>
                                                <li slot="reference"
                                                    @click="onChangeTimeSelected(useTime.time)"
                                                    :class="[
                                                        'p-2',
                                                        useTime.time === timeSelected ? 'selected': '',
                                                    ]"
                                                >
                                                    <i :class="['id pr-5', useTime.icon]"></i>
                                                    <span>{{ useTime.time }}</span>
                                                </li>
                                            </el-popover>
                                        </template>
                                        <template v-else>
                                            <li
                                                :key="'suggetTime_' + index"
                                                @click="onChangeTimeSelected(useTime.time)"
                                                :class="[
                                                    'p-2',
                                                    useTime.time === timeSelected ? 'selected': '',
                                                ]"
                                            >
                                                <i :class="['id pr-5', useTime.icon]"></i>
                                                <span>{{ useTime.time }}</span>
                                            </li>
                                        </template>
                                    </template>
                                </div>
                            </perfect-scrollbar>
                        </ul>

                    </template>
                    <template v-else>
                        <perfect-scrollbar class="zimbra-suggest-results">
                            <div class="NoSuggestions white-space-normal">
                                <div v-html="$t('zimbra.zmMsg.noSuggestionsFound', [startDateLabel, durationLabel])">
                                </div>
                                <div class="FakeAnchor" @click="makSuggetAnyTime()">
                                    {{ $t("zimbra.zmMsg.showTimesAnyway") }}
                                </div>
                            </div>
                        </perfect-scrollbar>
                    </template>
                </template>
                <template v-else>
                    <!-- Gợi ý địa điểm -->
                    <template
                        v-if="
                            listResourceLocation &&
                            listResourceLocation.length > 0
                        "
                    >
                        <!-- NOTE: Không có dữ liệu để xử lý !!!! -->
                    </template>
                    <template v-else>
                        <div class="p-3">
                            {{ $t("zimbra.zmMsg.noLocations") }}
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import { mapGetters } from "vuex";
import {
    ZIMBRA_CAL_RES_TYPE,
    CALENDAR_FREE_BUSY_TYPE
} from "@/utils/constants.js";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CalendarMini from "../components/calendar-mini.vue";
import moment from "moment";
import _ from "lodash";

export default {
    name: "SuggestionInfo",
    mixins: [CommonUtils],
    props: {
        suggestType: {
            type: String,
        },
        emailList: {
            type: Array,
            default: function() {
                return [];
            }
        },
        resouceList: {
            type: Array,
            default: function() {
                return [];
            }
        },
        startDate: {
            type: [Number, String],
            default: function() {
                return 0;
            }
        },
        endDate: {
            type: [Number, String],
            default: function() {
                return 0;
            }
        },
    },
    components: {
        CalendarMini,
    },
    data() {
        return {
            loading: false,
            listResourceLocation: [], // danh sách địa điểm gợi ý
            colorFree: "#80c095",
            colorFreeGray: "#c2e0c9",
            colorBusy: "#eb8383",
            colorBusyGray: "#eec2c2",
            TOTAL_MINUTES_IN_DAY: 1425,
            today: moment(new Date).startOf('day'),
            freeBusyCal: [],
            usrFreeBusyCal: [],
            listSuggestion: [], // giá trị gợi ý
            suggetTimeMap: {},
            suggestAnyTimeMap: {},
            usrBusyMap: {},
            timeSelected: null
        };
    },
    computed: {
        ...mapGetters([
            "locationSearchPref",
            "emailAddress",
            "localeCurrent",
            "preference",
        ]),
        title() {
            return this.suggestType === "time"
                ? this.$t("zimbra.zmMsg.suggestedTimes")
                : this.$t("zimbra.zmMsg.suggestedLocations");
        },
        startDateLabel() {
            if (this.localeCurrent && this.localeCurrent.content === 'vi') {
                return moment(this.startDate).locale('vi').format('DD MMMM');
            } else {
                return moment(this.startDate).format('MMM DD');
            }
        },
        duration() {
            // thời gian kéo dài cuộc hẹn
            if (this.startDate && this.endDate) {
                const start = moment(this.startDate);
                const end = moment(this.endDate);
                const duration = moment.duration(end.diff(start));
                const asMinutes = duration.asMinutes();
                return asMinutes;
            }
            return 0;
        },
        durationLabel() {
            let durationDisplay = '';
            if (this.duration < 60) {
                durationDisplay = this.duration + ' ' + this.$t("zimbra.zmMsg.minute");
            } else if (this.duration < 60 * 24) {
                durationDisplay = Math.floor(this.duration / 60) + ' ' + this.$t("zimbra.ztMsg.hour");
                if (this.duration % 60 !== 0) {
                    durationDisplay += (' ' + this.duration % 60 + ' ' + this.$t("zimbra.zmMsg.minute"));
                }
            } else if (this.duration < 60 * 24 * 3) {
                durationDisplay = Math.floor(this.duration / (60 * 24)) + ' ' + this.$t("zimbra.zmMsg.days");
                if (this.duration % (60 * 24) !== 0 && (this.duration % (60 * 24)) / 60 > 0) {
                   durationDisplay += (' ' + Math.floor(this.duration % (60 * 24) / 60) + ' ' + this.$t("zimbra.ztMsg.hour"));
                }
            } else if (this.duration < 60 * 24 * 30) {
                durationDisplay = Math.floor(this.duration / (60 * 24)) + ' ' + this.$t("zimbra.zmMsg.days");
            } else if (this.duration < 60 * 24 * 30 * 3) {
                durationDisplay = Math.floor(this.duration / (60 * 24 * 30)) + ' ' + this.$t("zimbra.zhMsg.calViewMonthShort");
                if (this.duration % (60 * 24 * 30) !== 0 && (this.duration % (60 * 24 * 30)) / (60 * 24) > 0) {
                    durationDisplay += (' ' + Math.floor(this.duration % (60 * 24 * 30) / (60 * 24)) + ' ' + this.$t("zimbra.zmMsg.days"));
                }
            } else if (this.duration < 60 * 24 * 30 * 12) {
                durationDisplay = Math.floor(this.duration / (60 * 24 * 30)) + ' ' + this.$t("zimbra.zhMsg.calViewMonthShort");
            } else {
                durationDisplay = Math.floor(this.duration / (60 * 24 * 30 * 12)) + ' ' + this.$t("zimbra.zmMsg.years");
            }
            return durationDisplay;
        },
        defaultApptDuration() {
            return this.preference.zimbraPrefCalendarDefaultApptDuration === "60m" ? 60 : this.preference.zimbraPrefCalendarDefaultApptDuration / 60;
        }

    },
    mounted() {
        if (this.suggestType) {
            if (this.suggestType === "time") {
                this.handleSearchFreeBusy();
            } else {
                this.loading = true;
                this.handleSearchLocation();
            }
        }
    },
    watch: {
        suggestType(newValue) {
            if (newValue) {
                if (newValue === "time") {
                    this.loading = true;
                    this.handleSearchFreeBusy();
                } else {
                    this.loading = true;
                    this.handleSearchLocation();
                }
            }
        },
    },
    methods: {
        closeSuggest() {
            // đóng gợi ý
            this.$emit("closeSuggest");
        },
        openSettingPrefence() {
            // mở cấu hình tùy chọn gợi ý
            this.$emit("openSettingPrefence");
        },
        handleSearchFreeBusy(dateSelected) {
            const _startDate = dateSelected ? dateSelected : this.startDate;
            this.loading = true;
            // search thời gian rảnh/bận - getFreeBusyRequest
            const fromDate = moment(_startDate).subtract(1, 'months').startOf('month');
            const toDate = moment(_startDate).add(1, 'months').endOf('month');
            const currentDate = moment(new Date).startOf('day');
            const yearMonthInMonthSelected = moment(_startDate).format('YYYYMM');
            const formData = {
                uid: this.emailList.join(","),
                s: fromDate.valueOf().toString(),
                e: toDate.valueOf().toString(),
            };
            ZimbraMailService.getFreeBusyRequest(formData)
                .then((res) => {
                    let { usr } = this.getResponseBody(res).GetFreeBusyResponse;
                    usr = Array.isArray(usr) ? usr : [usr];
                    this.usrFreeBusyCal = usr;
                    const dayBusy = [];
                    const dayBusyGray = [];
                    this.usrFreeBusyCal.forEach(item => {
                        if (item.b) {
                            item.b = Array.isArray(item.b) ? item.b : [item.b];
                            item.b.forEach(b => {
                                let start = moment(b.s);
                                let end = moment(b.e);
                                let duration = moment.duration(end.diff(start)).asMinutes();
                                if (duration >= this.TOTAL_MINUTES_IN_DAY) {
                                    let startStr = null;
                                    if (start.format('HH:mm:ss') === '00:00:00') {
                                        startStr = start.format('YYYY-MM-DD');
                                    } else {
                                        start = start.add(1, 'days').startOf('day');
                                        duration = moment.duration(end.diff(start)).asMinutes();
                                        if (duration >= this.TOTAL_MINUTES_IN_DAY) {
                                            startStr = start.format('YYYY-MM-DD');
                                        }
                                    }
                                    if (startStr != null) {
                                        if (end.format('HH:mm:ss') !== '23:45:00') {
                                            end = end.subtract(1, 'days');
                                        }
                                        while (!start.isAfter(end)) {
                                            if (!start.isBefore(currentDate)) {
                                                if (yearMonthInMonthSelected === start.format('YYYYMM')) {
                                                    dayBusy.push(start.format('YYYY-MM-DD'));
                                                } else {
                                                    dayBusyGray.push(start.format('YYYY-MM-DD'));
                                                }
                                            }
                                            start = start.add(1, 'day');
                                        }
                                    }
                                }
                            })
                        }
                    })
                    this.freeBusyCal = this.makeFreeBush(_startDate, dayBusy, dayBusyGray);
                    this.handleMakeSuggetTimeList(_startDate);
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        makeFreeBush(dateSelected, dayBusy, dayBusyGray) {
            let fromDate = moment(dateSelected).subtract(1, 'months').startOf('month');
            const toDate = moment(dateSelected).add(1, 'months').endOf('month');
            const currentDate = moment(new Date).startOf('day');
            const yearMonthInMonthSelected = moment(dateSelected).format('YYYYMM');
            let startColorFree = null;
            let startColorFreeGray = null;
            let startColorBusy = null;
            let startColorBusyGray = null;
            let endStr = null;
            const _freeBusyCal = [];
            let isFirstFreeBusy = true;
            while (!fromDate.isAfter(toDate)) {
                if (!fromDate.isBefore(currentDate)) {
                    const dateStr = fromDate.format('YYYY-MM-DD');
                    endStr = dateStr;
                    if (dayBusy.includes(dateStr)) {
                        if (startColorFree != null) {
                            const startStr = isFirstFreeBusy ? startColorFree.format('YYYY-MM-DD') : startColorFree.subtract(1, 'day').format('YYYY-MM-DD');
                            isFirstFreeBusy = false;
                            _freeBusyCal.push({
                                start: startStr,
                                end: dateStr,
                                overlap: false,
                                display: "background",
                                color: this.colorFree
                            });
                        } else if (startColorFreeGray != null) {
                            const startStr = isFirstFreeBusy ? startColorFreeGray.format('YYYY-MM-DD') : startColorFreeGray.subtract(1, 'day').format('YYYY-MM-DD');
                            isFirstFreeBusy = false;
                            _freeBusyCal.push({
                                start: startStr,
                                end: dateStr,
                                overlap: false,
                                display: "background",
                                color: this.colorFreeGray
                            });
                        } else if (startColorBusyGray != null) {
                            const startStr = isFirstFreeBusy ? startColorBusyGray.format('YYYY-MM-DD') : startColorBusyGray.subtract(1, 'day').format('YYYY-MM-DD');
                            isFirstFreeBusy = false;
                            _freeBusyCal.push({
                                start: startStr,
                                end: dateStr,
                                overlap: false,
                                display: "background",
                                color: this.colorBusyGray
                            });
                        }
                        if (startColorBusy === null) {
                            startColorBusy = _.cloneDeep(fromDate);
                        }
                        // cleanr start date
                        startColorFree = null;
                        startColorFreeGray = null;
                        startColorBusyGray = null;
                    } else if (dayBusyGray.includes(dateStr)) {
                        if (startColorFree != null) {
                            const startStr = isFirstFreeBusy ? startColorFree.format('YYYY-MM-DD') : startColorFree.subtract(1, 'day').format('YYYY-MM-DD');
                            isFirstFreeBusy = false;
                            _freeBusyCal.push({
                                start: startStr,
                                end: dateStr,
                                overlap: false,
                                display: "background",
                                color: this.colorFree
                            });
                        } else if (startColorFreeGray != null) {
                            const startStr = isFirstFreeBusy ? startColorFreeGray.format('YYYY-MM-DD') : startColorFreeGray.subtract(1, 'day').format('YYYY-MM-DD');
                            isFirstFreeBusy = false;
                            _freeBusyCal.push({
                                start: startStr,
                                end: dateStr,
                                overlap: false,
                                display: "background",
                                color: this.colorFreeGray
                            });
                        } else if (startColorBusy != null) {
                            const startStr = isFirstFreeBusy ? startColorBusy.format('YYYY-MM-DD') : startColorBusy.subtract(1, 'day').format('YYYY-MM-DD');
                            isFirstFreeBusy = false;
                            _freeBusyCal.push({
                                start: startStr,
                                end: dateStr,
                                overlap: false,
                                display: "background",
                                color: this.colorBusy
                            });
                        }
                        if (startColorBusyGray === null) {
                            startColorBusyGray = _.cloneDeep(fromDate);
                        }
                        // cleanr start date
                        startColorFree = null;
                        startColorFreeGray = null;
                        startColorBusy = null;
                    } else if (yearMonthInMonthSelected === fromDate.format('YYYYMM')) {
                        if (startColorFreeGray != null) {
                            const startStr = isFirstFreeBusy ? startColorFreeGray.format('YYYY-MM-DD') : startColorFreeGray.subtract(1, 'day').format('YYYY-MM-DD');
                            isFirstFreeBusy = false;
                            _freeBusyCal.push({
                                start: startStr,
                                end: dateStr,
                                overlap: false,
                                display: "background",
                                color: this.colorFreeGray
                            });
                        } else if (startColorBusy != null) {
                            const startStr = isFirstFreeBusy ? startColorBusy.format('YYYY-MM-DD') : startColorBusy.subtract(1, 'day').format('YYYY-MM-DD');
                            isFirstFreeBusy = false;
                            _freeBusyCal.push({
                                start: startStr,
                                end: dateStr,
                                overlap: false,
                                display: "background",
                                color: this.colorBusy
                            });
                        } else if (startColorBusyGray != null) {
                            const startStr = isFirstFreeBusy ? startColorBusyGray.format('YYYY-MM-DD') : startColorBusyGray.subtract(1, 'day').format('YYYY-MM-DD');
                            isFirstFreeBusy = false;
                            _freeBusyCal.push({
                                start: startStr,
                                end: dateStr,
                                overlap: false,
                                display: "background",
                                color: this.colorBusyGray
                            });
                        }
                        if (startColorFree === null) {
                            startColorFree = _.cloneDeep(fromDate);
                        }
                        // cleanr start date
                        startColorFreeGray = null;
                        startColorBusy = null;
                        startColorBusyGray = null;
                    } else {
                        if (startColorFree != null) {
                            const startStr = isFirstFreeBusy ? startColorFree.format('YYYY-MM-DD') : startColorFree.subtract(1, 'day').format('YYYY-MM-DD');
                            isFirstFreeBusy = false;
                            _freeBusyCal.push({
                                start: startStr,
                                end: dateStr,
                                overlap: false,
                                display: "background",
                                color: this.colorFree
                            });
                        } else if (startColorBusy != null) {
                            const startStr = isFirstFreeBusy ? startColorBusy.format('YYYY-MM-DD') : startColorBusy.subtract(1, 'day').format('YYYY-MM-DD');
                            isFirstFreeBusy = false;
                            _freeBusyCal.push({
                                start: startStr,
                                end: dateStr,
                                overlap: false,
                                display: "background",
                                color: this.colorBusy
                            });
                        } else if (startColorBusyGray != null) {
                            const startStr = isFirstFreeBusy ? startColorBusyGray.format('YYYY-MM-DD') : startColorBusyGray.subtract(1, 'day').format('YYYY-MM-DD');
                            isFirstFreeBusy = false;
                            _freeBusyCal.push({
                                start: startStr,
                                end: dateStr,
                                overlap: false,
                                display: "background",
                                color: this.colorBusyGray
                            });
                        }
                        if (startColorFreeGray === null) {
                            startColorFreeGray = _.cloneDeep(fromDate);
                        }
                        // cleanr start date
                        startColorFree = null;
                        startColorBusy = null;
                        startColorBusyGray = null;
                    }
                }

                fromDate = fromDate.add(1, 'day');
            }
            if (startColorFree != null) {
                const startStr = isFirstFreeBusy ? startColorFree.format('YYYY-MM-DD') : startColorFree.subtract(1, 'day').format('YYYY-MM-DD');
                _freeBusyCal.push({
                    start: startStr,
                    end: endStr,
                    overlap: false,
                    display: "background",
                    color: this.colorFree
                });
            } else if (startColorFreeGray != null) {
                const startStr = isFirstFreeBusy ? startColorFreeGray.format('YYYY-MM-DD') : startColorFreeGray.subtract(1, 'day').format('YYYY-MM-DD');
                _freeBusyCal.push({
                    start: startStr,
                    end: endStr,
                    overlap: false,
                    display: "background",
                    color: this.colorFreeGray
                });
            } else if (startColorBusy != null) {
                const startStr = isFirstFreeBusy ? startColorBusy.format('YYYY-MM-DD') : startColorBusy.subtract(1, 'day').format('YYYY-MM-DD');
                _freeBusyCal.push({
                    start: startStr,
                    end: endStr,
                    overlap: false,
                    display: "background",
                    color: this.colorBusy
                });
            } else if (startColorBusyGray != null) {
                const startStr = isFirstFreeBusy ? startColorBusyGray.format('YYYY-MM-DD') : startColorBusyGray.subtract(1, 'day').format('YYYY-MM-DD');
                _freeBusyCal.push({
                    start: startStr,
                    end: endStr,
                    overlap: false,
                    display: "background",
                    color: this.colorBusyGray
                });
            }

            return _freeBusyCal;
        },

        makeEmailListSuggetTime() {
            if (this.locationSearchPref.my_working_hrs_pref && this.locationSearchPref.others_working_hrs_pref) {
                return this.emailList;
            } else if (this.locationSearchPref.my_working_hrs_pref) {
                return [this.emailAddress];
            } else if (this.locationSearchPref.others_working_hrs_pref) {
                return this.emailList.filter(email => email !== this.emailAddress);
            }
            return [];
        },

        handleMakeSuggetTimeList(dateSelected) {
            this.listSuggestion = [];
            this.suggestAnyTimeMap = {};
            this.usrBusyMap = {};
            this.timeSelected = null;
            // search thời gian rảnh/bận - getFreeBusyRequest
            const currentDateStartOfDay = moment(new Date).startOf('day');
            const currentDate = moment(new Date);
            const fromDate = moment(dateSelected).startOf('day');
            const toDate = moment(dateSelected).endOf('day');
            const formData = {
                uid: this.emailList.join(","),
                s: fromDate.valueOf().toString(),
                e: toDate.valueOf().toString(),
            };
            ZimbraMailService.getFreeBusyRequest(formData)
                .then((res) => {
                    let { usr } = this.getResponseBody(res).GetFreeBusyResponse;
                    usr = Array.isArray(usr) ? usr : [usr];
                    const data = {};
                    const formData2 = {
                        name: this.emailList.join(","),
                        s: fromDate.valueOf().toString(),
                        e: toDate.valueOf().toString()
                    };
                    ZimbraMailService.getWorkingHoursRequest(formData2).then((res2) => {
                        let workingHours = this.getResponseBody(res2).GetWorkingHoursResponse.usr;
                        workingHours = Array.isArray(workingHours) ? workingHours : [workingHours];
                        workingHours.forEach((item) => data[item.id] = item);
                        const emailListCheckSuggetTime = this.makeEmailListSuggetTime();
                        const suggetTimeFullList = this.makeSuggetTimeFull(dateSelected);
                        this.suggetTimeMap = {};
                        let suggetTimeWorkingMap = {};
                        usr.forEach(item => {
                            const _suggetTimeList = [];
                            const _suggetTimeWorkingList = [];
                            let workingHour = data[item.id];
                            if (!emailListCheckSuggetTime.includes(item.id)) {
                                suggetTimeWorkingMap[item.id] = suggetTimeFullList;
                                this.suggetTimeMap[item.id] = suggetTimeFullList;
                            } else if (item.f && workingHour.f) {
                                item.f = Array.isArray(item.f) ? item.f : [item.f];
                                workingHour.f = Array.isArray(workingHour.f) ? workingHour.f : [workingHour.f];
                                item.f.forEach(f => {
                                    let _start = moment(f.s);
                                    const _end = moment(f.e);
                                    const minuteStart = _start.format('mm');
                                    if (minuteStart != '00' && minuteStart != '30') {
                                        if (minuteStart > '30') {
                                            _start.set({minute: 0, hour: _start.hour() + 1});
                                        } else {
                                            _start.set({minute: 30});
                                        }
                                    }
                                    while (!_start.isAfter(_end)) {
                                        const _duration = moment.duration(_end.diff(_start)).asMinutes();
                                        if (_duration >= this.duration) {
                                            const _time = _start.format('HH:mm');
                                            if ((fromDate.isBefore(currentDateStartOfDay) || _start.isAfter(currentDate))
                                                    && this.isWorkingHour(_start, workingHour.f)) {
                                                _suggetTimeWorkingList.push(_time);
                                            }
                                            _suggetTimeList.push(_time);
                                            _start = _start.add(this.defaultApptDuration, 'minutes');
                                        } else {
                                            _start = _start.add(1, 'day');
                                        }
                                    }
                                })
                                suggetTimeWorkingMap[item.id] = _suggetTimeWorkingList;
                                if (_suggetTimeWorkingList.length > 0) {
                                    this.suggetTimeMap[item.id] = _suggetTimeList;
                                }
                            }
                        })
                        this.listSuggestion = this.makeSuggestTime(suggetTimeWorkingMap);
                    });
                })
                .catch(() => {
                });
        },

        makeSuggetTimeFull(dateSelected) {
            const suggetTimeFullList = [];
            const fromDate = moment(dateSelected).startOf('day');
            let start = moment(dateSelected).startOf('day');
            const end = moment(dateSelected).endOf('day');
            const currentDateStartOfDay = moment(new Date).startOf('day');
            const currentDate = moment(new Date);
            while (!start.isAfter(end)) {
                if (fromDate.isBefore(currentDateStartOfDay) || start.isAfter(currentDate)) {
                    suggetTimeFullList.push(start.format('HH:mm'));
                }
                start = start.add(this.defaultApptDuration, 'minutes');
            }
            return suggetTimeFullList;
        },

        makeSuggestTime(suggetTimeWorkingMap) {
            const suggestTimeList = [];
            this.emailList.forEach(email => {
                if (suggetTimeWorkingMap[email]) {
                    suggetTimeWorkingMap[email].forEach(startTime => {
                        if (!suggestTimeList.includes(startTime)
                            && this.checkSuggestTimeValid(suggetTimeWorkingMap, email, startTime)) {
                            suggestTimeList.push(startTime);
                        }
                    })
                }
            })
            const _suggetList = [];
            if (suggestTimeList.length > 0) {
                suggestTimeList.forEach(time => {
                    _suggetList.push({
                        type: CALENDAR_FREE_BUSY_TYPE.FREE,
                        icon: 'ic-user-free',
                        time: time
                    })
                })
                return [{count: this.emailList.length, lstResult: _suggetList, class: 'green-light'}];
            } else {
                return [];
            }
        },
        checkSuggestTimeValid(suggetTimeWorkingMap, email, timeCheck) {
            let countValid = 0;
            this.emailList.forEach(item => {
                if (item != email && suggetTimeWorkingMap[item]
                    && suggetTimeWorkingMap[item].includes(timeCheck)) {
                    countValid++;
                }
            })
            return countValid === this.emailList.length - 1;
        },
        isWorkingHour(start, workingHourFree) {
            let isWorkingHour = false;
            const endTime = _.cloneDeep(start).add(this.duration, 'minutes');
            workingHourFree.forEach(f => {
                if (!start.isBefore(moment(f.s)) && !endTime.isAfter(moment(f.e))) {
                    isWorkingHour = true;
                    return;
                }
            })
            return isWorkingHour;
        },
        makSuggetAnyTime() {
            const currentDateStartOfDay = moment(new Date).startOf('day');
            const currentDate = moment(new Date);
            const start = moment(this.startDate).startOf('day');
            let fromDate = moment(this.startDate).startOf('day');
            const toDate = moment(this.startDate).endOf('day');
            this.suggestAnyTimeMap = {};
            this.usrBusyMap = {};
            while (!fromDate.isAfter(toDate)) {
                const time = fromDate.format('HH:mm');
                const _duration = moment.duration(toDate.diff(fromDate)).asMinutes();
                if ((start.isBefore(currentDateStartOfDay) || fromDate.isAfter(currentDate))
                        && _duration >= this.duration) {
                    let count = 0;
                    const usrBusyList = [];
                    this.emailList.forEach(email => {
                        if (this.suggetTimeMap[email]
                            && this.suggetTimeMap[email].includes(time)) {
                                count++;
                        } else {
                            usrBusyList.push(email);
                        }
                    })

                    let listAnyTime = this.suggestAnyTimeMap[count];
                    if (!listAnyTime) {
                        listAnyTime = [];
                    }
                    listAnyTime.push(time);
                    this.suggestAnyTimeMap[count] = listAnyTime;

                    if (usrBusyList.length > 0) {
                        this.usrBusyMap[time] = usrBusyList;
                    }
                }

                fromDate = fromDate.add(this.defaultApptDuration, 'minutes');
            }
            const lstSuggetTimeList = [];
            for (let i = this.emailList.length - 1; i >= 0; i--) {
                if (this.suggestAnyTimeMap[i]) {
                    const _suggetList = [];
                    this.suggestAnyTimeMap[i].forEach(time => {
                        _suggetList.push({
                            type: CALENDAR_FREE_BUSY_TYPE.FREE,
                            icon: i > this.emailList.length/2 ? 'ic-user-orange' : 'ic-user-busy',
                            time: time
                        })
                    })
                    lstSuggetTimeList.push({
                        count: i,
                        lstResult: _suggetList,
                        class: i > this.emailList.length/2 ? 'orange-light' : 'red-light'
                    })
                }
            }
            this.listSuggestion = lstSuggetTimeList;
        },
        getContentTooltip(time) {
            if (this.usrBusyMap[time]) {
                return this.$t("zimbra.zhMsg.busy") + "<br/>" + this.usrBusyMap[time].join("<br/>");
            }
            return '';
        },
        onChangeTimeSelected(time) {
            this.timeSelected = time;
            const times = time.split(':');
            const startDateChange = moment(_.cloneDeep(this.startDate)).set({minute: times[1], hour: times[0]});
            const endDateChange = _.cloneDeep(startDateChange).add(this.duration, 'minutes');
            this.$emit("onChangeTime", {
                time: [
                    startDateChange.format('yyyyMMDDTHHmmss'),
                    endDateChange.format('yyyyMMDDTHHmmss')
                ]
            });
        },
        onSelectDate(date) {
            const times = moment(this.startDate).format('HH:mm').split(':');
            const startDateChange = moment(date).set({minute: times[1], hour: times[0]});
            const endDateChange = _.cloneDeep(startDateChange).add(this.duration, 'minutes');
            this.$emit("onChangeStartDate", {
                time: [
                    startDateChange.format('yyyyMMDDTHHmmss'),
                    endDateChange.format('yyyyMMDDTHHmmss')
                ]
            });
        },

        handleSearchLocation() {
            // tìm địa điểm gợi ý
            const { name, site, capacity, building, desc, floor } =
                this.locationSearchPref;
            const formData = {
                attrs: "fullName,email,zimbraCalResLocationDisplayName,zimbraCalResCapacity,zimbraCalResContactEmail,description,zimbraCalResType",
                offset: 0,
                limit: 50,
                name: name,
            };
            const _conds = [];
            _conds.push({
                attr: "zimbraCalResType",
                op: "eq",
                value: ZIMBRA_CAL_RES_TYPE.LOCATION,
            });
            if (!this.isNullOrEmpty(site)) {
                _conds.push({
                    attr: "zimbraCalResSite",
                    op: "has",
                    value: site,
                });
            }
            if (!this.isNullOrEmpty(capacity)) {
                _conds.push({
                    attr: "zimbraCalResCapacity",
                    op: "ge",
                    value: capacity,
                });
            }
            if (!this.isNullOrEmpty(building)) {
                _conds.push({
                    attr: "zimbraCalResBuilding",
                    op: "has",
                    value: building,
                });
            }
            if (!this.isNullOrEmpty(desc)) {
                _conds.push({ attr: "description", op: "has", value: desc });
            }
            if (!this.isNullOrEmpty(floor)) {
                _conds.push({
                    attr: "zimbraCalResFloor",
                    op: "eq",
                    value: floor,
                });
            }
            formData.conds = _conds;
            ZimbraMailService.searchCalendarResourcesRequest(formData)
                .then((res) => {
                    const { calresource } =
                        this.getResponseBody(
                            res
                        ).SearchCalendarResourcesResponse;
                    this.listResourceLocation = calresource;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
<style scoped>
.zimbra-suggest-results {
    max-height: calc(100vh - 425px) !important;
}
</style>
