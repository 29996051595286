<template>
    <el-tooltip class="item" effect="dark" :content='this.$t("zimbra.zhMsg.ALT_APP_BRIEFCASE")' placement="left-end">
        <div class="element">
            <i :class="`hw-18 ${$route.path == '/mail/briefcase' ? 'ic-stickynote-act' : 'ic-stickynote'}`"> </i>
        </div>
    </el-tooltip>
</template>
<script>
export default {
}
</script>

<style>

</style>