<template>
    <div class="quick-setting__popover zm-quick-cal-edit">
        <div class="side-popup calendar-popup show event-info width-38">
            <div class="popup-content">
                <div class="header">
                    <div class="header-left">
                        <div class="title">{{ modelData.name }}</div>
                    </div>
                    <div class="header-right">
                        <button
                            @click="onClose()"
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <i class="fal fa-times"></i>
                        </button>
                    </div>
                </div>
               <div
                    v-if="
                        fromAddressesCalendar &&
                        fromAddressesCalendar.length > 1
                    "
                    class="row"
                >
                    <!-- Từ -->
                    <div
                        class="
                            col-sm-12 col-md-4
                            zm-label
                        "
                    >
                        {{ $t("zimbra.zhMsg.FILT_COND_HEADER_from") }}
                    </div>
                    <div class="col-sm-12 col-md-8">
                        <el-select
                            v-model="modelData.from"
                            size="medium"
                            class="w-100"
                            :default-first-option="true"
                            value-key="uuid"
                        >
                            <el-option
                                v-for="(
                                    item, idx
                                ) in fromAddressesCalendar"
                                :key="idx"
                                :label="item.label"
                                :value="item.uuid"
                                allow-create="true"
                            >
                                <span>
                                    {{ item.label }}
                                </span>
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="row edit-margin-bottom">
                    <div class="col-sm-12 col-md-4 zm-label">
                        {{ $t("zimbra.zhMsg.FILT_COND_HEADER_subject") }}
                    </div>
                    <div class="col-sm-12 col-md-8">
                        <input
                            ref="refInputName"
                            v-model.trim="modelData.name"
                            class="form-control"
                            :placeholder="$t('zimbra.zhMsg.FILT_COND_HEADER_subject')"
                        />
                    </div>
                </div>
                <div class="row edit-margin-top-img">
                    <div class="col-sm-12 col-md-4 zm-label">
                        {{ $t("zimbra.zhMsg.attendee") }}
                    </div>
                    <div class="col-sm-12 col-md-8 d-flex">
                        <div
                            class="avatar pr-1"
                            v-for="(item, idx) in listEmailAvatar"
                            :key="idx"
                        >
                            <BaseAvatar :listEmailAddress="[item]" />
                        </div>
                    </div>
                </div>
                <div class="row edit-margin-bottom">
                    <div class="col-sm-12 col-md-4 zm-label"></div>
                    <div class="col-sm-12 col-md-8">
                        <AddressSelector
                            ref="refToAddress"
                            v-model="modelData.to"
                            :showCc="false"
                            :showBcc="false"
                            :showDialog="false"
                        />
                    </div>
                </div>

                <div class="row edit-margin-bottom">
                    <div class="col-sm-12 col-md-4 zm-label">
                        {{ $t("zimbra.zhMsg.location") }}
                    </div>
                    <div class="col-sm-12 col-md-8">
                        <input
                            v-model.trim="modelData.loc"
                            class="form-control"
                            :placeholder="$t('zimbra.zhMsg.location')"
                        />
                    </div>
                </div>
                <div class="row time edit-margin-bottom">
                    <div class="col-sm-12 col-md-4 zm-label">
                        <label class="checkbox-container">
                            {{ $t("zimbra.zhMsg.allDay") }}
                            <input
                                v-model="modelData.allDay"
                                type="checkbox"
                                class="allday-check"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="row edit-margin-bottom">
                    <div class="col">
                        <BaseDateTimeRange
                            v-model="modelData.time"
                            :type="modelData.allDay ? 'date' : 'datetime'"
                            popper-class="zm-calendar"
                            value-format="yyyyMMddTHHmmss"
                            :clearable="false"
                            :duration="defaultApptDuration"
                        />
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer pt-2 w-100">
                <div class="d-flex justify-content-between mt-3 w-100">
                    <div>
                        <a
                            class="zm-calendar-more-details"
                            href="javascript:;"
                            @click="actionMoreDetail"
                        >
                            {{ $t("zimbra.zmMsg.moreDetails") }}
                        </a>
                    </div>
                    <div>
                        <el-button
                            v-if="showButtonSend"
                            class="btn-cancel"
                            @click="handleSaveOrSendAppt(0)"
                        >
                            {{ $t("zimbra.zhMsg.send") }}
                        </el-button>
                        <el-button
                            class="btn-calendar"
                            @click="handleSaveOrSendAppt(1)"
                        >
                            {{ $t("zimbra.zhMsg.actionSave") }}
                        </el-button>
                    </div>
                </div>
            </span>
        </div>
    </div>
</template>
<script>
import {
    CALENDAR_FREE_BUSY_TYPE,
    FOLDER_DEFAULT,
    CALENDAR_FREQ_TYPE,
    CAL_PARTICIPATION_STATUS,
    CAL_ROLE_AT,
    CAL_ALARM_TYPE,
    ADDRESS_TYPE,
    RECUR_END_INTERVAL_TYPE,
    CAL_RECUR_DAILY_TYPE,
    CAL_RECUR_WEEKLY_TYPE,
    CAL_RECUR_MONTHLY_TYPE,
    CAL_RECUR_YEARLY_TYPE,
    FROM_ADDRESS_TYPE,
    CALENDAR_FORM_FIELD,
} from "@/utils/constants.js";
import BaseDateTimeRange from "@/components/modules/base/base-date-time-range/BaseDateTimeRange.vue";
import CalendarUtils from "@/utils/calendar-utils";
import CommonUtils from "@/utils/common-utils";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import { mapGetters } from "vuex";
import ZmCalendarApp from "@/utils/zimbra-lib/ZmCalendarApp";
import ZmMimeTable from "@/utils/zimbra-lib/ZmMimeTable";
import _ from "lodash";
import BaseAvatar from "@/components/modules/base/base-avatar/base-avatar.vue";
import AddressSelector from "@/components/modules/email/mail-compose/components/AddressSelector";
export default {
    name: "QuickEditCalendar",
    mixins: [CommonUtils, CalendarUtils],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        BaseAvatar,
        AddressSelector,
        BaseDateTimeRange
    },
    computed: {
        ...mapGetters([
            "fromAddresses",
            "preference",
            "attributes",
            "emailAddress",
            "showComposeDirectionCurrent",
        ]),
        showButtonSend() {
            return (
                this.modelData?.to?.length > 0 ||
                this.modelData?.optional?.length > 0
            );
        },
        listEmailAvatar() {
            return this.modelData?.to;
        },
        fromAddressesCalendar() {
            const addressType = [FROM_ADDRESS_TYPE.MAIN_ACCOUNT, FROM_ADDRESS_TYPE.IDENTITY, FROM_ADDRESS_TYPE.POP];
            return this.fromAddresses.filter(e => addressType.includes(e.type));
        },
    },
    methods: {
        open(invId, ridZ, apptDataInstance) {
            const formData = {
                id: `${invId}`,
            };
            this.ridZ = ridZ;
            this.dialogVisible = true;
            ZimbraMailService.getMsgRequest(formData).then((msgRequest) => {
                const { m } =
                    CommonUtils.methods.getResponseBody(msgRequest)[
                        "GetMsgResponse"
                    ];
                this.modelData = this.initialDataForEdit(m, null, apptDataInstance);
                const form = this.fromAddresses.filter(e => e.a == this.modelData.or.a);
                this.modelData.from = form.length > 0 && form[0].uuid != null ? form[0].uuid : this.modelData.replyTo[0].text
                this.rawDataEdit = m;
                this.showOptional = this.modelData?.optional?.length > 0;
                this.visable = true;
                this.defaultApptDuration = this.preference.zimbraPrefCalendarDefaultApptDuration === "60m" ? 60 * 60 : this.preference.zimbraPrefCalendarDefaultApptDuration;
            });
        },
        onClose(){
            this.$emit("fCloseQuickEditCalendar");
        },
        handleClose() {
            this.dialogVisible = false;
        },
        actionMoreDetail() {
            this.dialogVisible = false;
            this.$emit("actionMoreDetail", this.modelData, this.ridZ);
        },
        async handleSaveOrSendAppt(draft) {
            this.draft = draft;
            if (!(await this.validateBeforeSave())) {
                return;
            }
            // build form data
            const formData = await this.buildFormData();
            if (this.modelData.id) {
                await this.modifyAppt(formData);
            }
            this.onClose();
        },
        async validateBeforeSave() {
            let res = true;
            if (
                !this.modelData.name ||
                this.modelData.name.trim().length === 0
            ) {
                const errorMissingSubject = this.$t(
                    "zimbra.zhMsg.errorMissingSubject"
                );
                const msg = this.$t("zimbra.zmMsg.errorSavingWithMessage", [
                    errorMissingSubject,
                ]);
                await this.$confirm(msg, this.$t("zimbraNokey.zimbra_common_criticalMsg"), {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    showCancelButton: false,
                    type: "error",
                    dangerouslyUseHTMLString: true,
                }).then(() => {
                    res = false;
                });
            }
            return res;
        },

        async modifyAppt(formData) {
            await ZimbraMailService.modifyAppointmentRequest({
                m: formData,
                id: this.modelData.id.toString(),
                comp: 0,
                // ms
                // rev
            }).then((res) => {
                const { apptId } =
                    this.getResponseBody(res)["ModifyAppointmentResponse"];
                const msg = this.draft
                    ? "zimbra.zmMsg.savedAppointment"
                    : "zimbra.zmMsg.apptSent";
                if (apptId) {
                    this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                        message: this.$t(msg),
                        type: "success",
                    });
                    this.$emit("onSendSuccess");
                    this.dialogVisible = false;
                }
            });
        },

        async buildFormData() {
            const formData = {};
            formData.l = this.modelData.l?.toString();
            formData.su = this.modelData.name;
            this.modelData.loc = _.escape(this.modelData.loc)
            if (this.modelData.contentType === ZmMimeTable.TEXT_HTML) {
                formData.mp = [
                    {
                        ct: ZmMimeTable.MULTI_ALT,
                        mp: [
                            {
                                ct: ZmMimeTable.TEXT_PLAIN,
                                content: _.escape(this.modelData.desc),
                            },
                            {
                                ct: ZmMimeTable.TEXT_HTML,
                                content: _.escape(this.modelData.descHtml),
                            },
                        ],
                    },
                ];
            } else {
                formData.mp = [
                    {
                        ct: ZmMimeTable.MULTI_ALT,
                        mp: [
                            {
                                ct: ZmMimeTable.TEXT_PLAIN,
                                content: this.getContentAppt(
                                    this.modelData.desc,
                                    false
                                ),
                            },
                        ],
                    },
                ];
            }

            // Invite information
            formData.inv = {
                comp: this.getInviteComponent(),
            };

            formData.e = [
                {
                    a: _.escape(this.emailAddress),
                    t: ADDRESS_TYPE.FROM,
                    p: this.attributes.displayName,
                },
            ];
            const { at } = formData.inv.comp[0];
            if (!this.draft && at && at.length > 0) {
                at.forEach((item) => {
                    formData.e.push({
                        a: item.a,
                        t: ADDRESS_TYPE.TO,
                        p: item.d,
                    });
                });
            }
            return formData;
        },

        getContentAppt(text, isHtml) {
            if (this.draft) {
                return _.escape(text);
            }
            const summary = [];
            const header = "zimbra.zhMsg.apptNew";

            if (isHtml) {
                summary.push(`<html><body id='htmlmode'>`);
                summary.push(`<h3>${this.$t(header)}</h3>`);
                summary.push(this.getSummary(this.modelData, isHtml));
                summary.push(text);
                summary.push(`</body></html>`);
            } else {
                summary.push(this.$t(header));
                summary.push("\n");
                summary.push(this.getSummary(this.modelData, isHtml));
                summary.push("\n");
                summary.push(text);
            }
            return _.escape(summary.join(""));
        },

        getInviteComponent() {
            const inviteComponent = {};
            inviteComponent.status = ZmCalendarApp.STATUS_CONF;
            inviteComponent.fb = this.modelData.fb;
            inviteComponent.class = this.modelData.class ? "PRI" : "PUB";
            inviteComponent.transp = 0;
            inviteComponent.draft = this.draft; // 0|1 Set if invite has changes that haven't been sent to attendees; for organizer only
            inviteComponent.allDay = this.modelData.allDay ? 1 : 0;
            inviteComponent.name = this.modelData.name;
            inviteComponent.loc = this.modelData.loc;
            inviteComponent.s = {
                d: this.modelData.time[0],
                tz: this.preference.zimbraPrefTimeZoneId,
            };
            inviteComponent.e = {
                d: this.modelData.time[1],
                tz: this.preference.zimbraPrefTimeZoneId,
            };
            if (this.exceptId) {
                inviteComponent.exceptId = {
                    d: this.exceptId,
                    tz: this.preference.zimbraPrefTimeZoneId,
                };
            }
            inviteComponent.or = {
                a: this.emailAddress,
                d: this.preference.zimbraPrefFromDisplay,
            };
            // Lặp lại
            if (!this.exceptId) {
                inviteComponent.recur = this.buildRecur();
            }
            // Trình nhắc
            if (this.modelData.alarm !== -1) {
                inviteComponent.alarm = [
                    {
                        action: CAL_ALARM_TYPE.DISPLAY,
                        trigger: {
                            rel: {
                                m: this.modelData.alarm,
                                related: "START",
                                neg: "1",
                            },
                        },
                    },
                ];

                if (this.modelData.calendarReminderEmail) {
                    inviteComponent.alarm.push({
                        action: CAL_ALARM_TYPE.EMAIL,
                        trigger: {
                            rel: {
                                m: this.modelData.alarm,
                                related: "START",
                                neg: "1",
                            },
                        },
                        at: {
                            a: this.preference.zimbraPrefCalendarReminderEmail,
                        },
                    });
                }
            }
            // người tham dự
            const attendees = [];
            if (this.modelData.to?.length > 0) {
                this.modelData.to.forEach((at) => {
                    attendees.push({
                        a: this.getAddressEmailFromText(at.text),
                        d: at.p || at.d,
                        ptst: CAL_PARTICIPATION_STATUS.NEEDS_ACTION,
                        role: CAL_ROLE_AT.REQUIRED,
                        rsvp: "1",
                    });
                });
            }
            if (this.modelData.optional?.length > 0) {
                this.modelData.optional.forEach((at) => {
                    attendees.push({
                        a: this.getAddressEmailFromText(at.text),
                        d: at.p,
                        ptst: CAL_PARTICIPATION_STATUS.NEEDS_ACTION,
                        role: CAL_ROLE_AT.OPTIONAL,
                        rsvp: "1",
                    });
                });
            }
            inviteComponent.at = attendees;
            return [inviteComponent];
        },
        buildRecur() {
            // build form cấp hình lặp
            const DAY_IN_WEEK = ["MO", "TU", "WE", "TH", "FR"];
            if (
                this.modelData.freq &&
                this.modelData.freq !== CALENDAR_FREQ_TYPE.NONE &&
                this.modelData.freq !== CALENDAR_FREQ_TYPE.CUSTOM
            ) {
                return {
                    add: {
                        rule: {
                            interval: {
                                ival: 1,
                            },
                            freq: this.modelData.freq,
                        },
                    },
                };
            } else if (this.modelData.freq === CALENDAR_FREQ_TYPE.CUSTOM) {
                const { customRepeat } = this.modelData;
                const {
                    daily,
                    weekly,
                    monthly,
                    yearly,
                    recurDailyEveryNumDays,
                    recurWeeklyEveryWeekday,
                    recurWeeklyEveryNumWeeksDate,
                    recurMonthlyEveryNumMonthsDate,
                    recurMonthlyEveryNumMonthsWeekDays,
                    recurYearlyEveryDate,
                    recurEndType,
                    recurEndNumber,
                    recurEndByDate,
                } = customRepeat;
                const rule = {};
                rule.freq = customRepeat.freq;

                // Kết thúc
                if (recurEndType === RECUR_END_INTERVAL_TYPE.NUMBER) {
                    const number = recurEndNumber[0];
                    rule.count = {
                        num: number,
                    };
                } else if (recurEndType === RECUR_END_INTERVAL_TYPE.NUMBER) {
                    rule.until = {
                        d: recurEndByDate[0],
                    };
                }

                switch (customRepeat.freq) {
                    case CALENDAR_FREQ_TYPE.DAILY:
                        if (daily === CAL_RECUR_DAILY_TYPE.EVERY_DAY) {
                            rule.interval = {
                                ival: 1,
                            };
                        } else if (
                            daily === CAL_RECUR_DAILY_TYPE.EVERY_WEEKDAY
                        ) {
                            rule.byday = {
                                wkday: DAY_IN_WEEK.map((e) => ({ day: e })),
                            };
                        } else {
                            rule.interval = {
                                ival: recurDailyEveryNumDays[0],
                            };
                        }
                        break;
                    case CALENDAR_FREQ_TYPE.WEEKLY:
                        if (weekly === CAL_RECUR_WEEKLY_TYPE.EVERY_WEEKDAY) {
                            rule.interval = {
                                ival: 1,
                            };
                            rule.byday = {
                                wkday: [{ day: recurWeeklyEveryWeekday[0] }],
                            };
                        } else {
                            rule.interval = {
                                ival: recurWeeklyEveryNumWeeksDate[0],
                            };
                            rule.byday = {
                                wkday: recurWeeklyEveryNumWeeksDate[1].map(
                                    (e) => ({ day: e })
                                ),
                            };
                        }
                        break;
                    case CALENDAR_FREQ_TYPE.MONTHLY:
                        if (
                            monthly ===
                            CAL_RECUR_MONTHLY_TYPE.EVERY_NUM_MONTHS_DATE
                        ) {
                            rule.bymonthday = {
                                modaylist: recurMonthlyEveryNumMonthsDate[0],
                            };
                            rule.interval = {
                                ival: recurMonthlyEveryNumMonthsDate[1],
                            };
                        } else {
                            const _date = recurMonthlyEveryNumMonthsWeekDays[1];
                            rule.bysetpos = {
                                poslist: recurMonthlyEveryNumMonthsWeekDays[0],
                            };
                            rule.byday = {
                                wkday: this.getWkday(_date),
                            };
                            rule.interval = {
                                ival: recurMonthlyEveryNumMonthsWeekDays[2],
                            };
                        }
                        break;
                    case CALENDAR_FREQ_TYPE.YEARLY:
                        if (yearly === CAL_RECUR_YEARLY_TYPE.EVERY_DATE) {
                            rule.bymonth = {
                                molist: recurYearlyEveryDate[0],
                            };
                            rule.bymonthday = {
                                modaylist: recurYearlyEveryDate[1],
                            };
                        } else {
                            const _date = recurMonthlyEveryNumMonthsWeekDays[1];
                            rule.bysetpos = {
                                poslist: recurMonthlyEveryNumMonthsWeekDays[0],
                            };
                            rule.byday = {
                                wkday: this.getWkday(_date),
                            };
                            rule.bymonth = {
                                molist: recurMonthlyEveryNumMonthsWeekDays[2],
                            };
                        }
                        break;
                }
                return {
                    add: {
                        rule: rule,
                    },
                };
            } else {
                return {};
            }
        },
        getWkday(_date) {
            const DAY_IN_WEEK = ["MO", "TU", "WE", "TH", "FR"];
            const DAY_WEEKEND = ["SU", "SA"];
            const DAY_ALL_WEEK = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
            let wkday = [];
            if (_date == -1) {
                // ngày
                wkday = DAY_ALL_WEEK.map((e) => ({ day: e }));
            } else if (_date == 1) {
                // ngày cuối tuần
                wkday = DAY_WEEKEND.map((e) => ({ day: e }));
            } else if (_date == 1) {
                // ngày trong tuần
                wkday = DAY_IN_WEEK.map((e) => ({ day: e }));
            } else {
                wkday = [{ day: _date }];
            }
            return wkday;
        },
    },
    created() {},
    mounted() {},
    data() {
        return {
            dialogVisible: false,
            modelData: {
                fb: CALENDAR_FREE_BUSY_TYPE.BUSY,
                l: FOLDER_DEFAULT.CALENDAR,
                allDay: false,
                freq: CALENDAR_FREQ_TYPE.NONE,
                alarm: 5,
                contentType: ZmMimeTable.TEXT_HTML,
                listAttachment: [],
                customRepeat: null, // tùy chỉnh lặp
                from:"",
            },
            draft: 1,
            CALENDAR_FORM_FIELD: CALENDAR_FORM_FIELD,
            defaultApptDuration: null,
            ridZ: null,
        };
    },
    destroyed() {
        // this.listEmailAvatar = [];
    },
};
</script>
<style scoped>
.edit-margin-top-img {
    margin-top: 4px;
}
.edit-margin-bottom {
    margin-top: 5px;
}
.row-input{
    margin-bottom: 2px;
}
</style>
