<template>
    <el-dialog
        :title="$t('zimbraNokey.zimbra_setting_zimlet_archivePreferences')"
        custom-class="dialog-archive-detail"
        :visible.sync="dialogZimletArchire"
        :append-to-body="true"
        :close-on-click-modal="false"
        :show-close="false"
        top="40vh"
        width="35rem"
        class="zimbra-dialog"
    >
        <div class="setting-archive">
            <div class="folder-archive form-controls">
                <label for="" class="label-control">{{
                    $t("zimbraNokey.zimbra_setting_zimlet_archiveFolder")
                }}</label>
                <button class="link-archive" @click="changeFolderArchive">
                    {{ formData.absFolderPathI18n }}
                </button>
            </div>
            <div class="form-controls">
                <base-radio
                    :label="$t('zimbraNokey.zimbra_setting_zimlet_deleteButton')"
                    displayField="name"
                    valueField="value"
                    :value="!formData.hideDeleteButton"
                    :options="status"
                    @change="handleChangeDeleteBtnEnable"
                />
            </div>
            <div class="form-controls">
                <base-radio
                    :label="$t('zimbraNokey.zimbra_setting_zimlet_sendAndArchive')"
                    displayField="name"
                    valueField="value"
                    :value="formData.showSendAndArchive"
                    :options="status"
                    @change="handleChangeSendAndArchireBtnEnable"
                />
            </div>
            <div class="footer-dialog">
                <base-button-default
                    class="btn-email primary"
                    @click="editFolderArchive"
                >
                    {{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}
                </base-button-default>
                <base-button-default
                    class="btn-email default"
                    @click="dialogZimletArchire = false"
                >
                    {{ $t("zimbra.zhMsg.actionTaskCancelTT") }}
                </base-button-default>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import BaseRadio from "@/components/modules/base/base-radio";
import BaseButtonDefault from "@/components/modules/base/base-button/btn-default";
import { mapGetters } from "vuex";
import { FOLDER_EXTRA_ACTION, READ_EMAIL_LAYOUT_ROUTER } from "@/utils/constants";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import _ from "lodash";
export default {
    data() {
        return {
            FOLDER_EXTRA_ACTION: FOLDER_EXTRA_ACTION,
            status: [
                {
                    value: true,
                    name: this.$t("zimbra.zhMsg.optionsDisplay"),
                },
                {
                    value: false,
                    name: this.$t("zimbra.zhMsg.hide"),
                },
            ],
            formData: {
                hideDeleteButton: false,
                showSendAndArchive: false,
                absFolderPathI18n: this.$t("zimbra.zmMsg.browse"),
            },
            isShowZimletArchire: false,
            dialogZimletArchire: false,
        };
    },
    computed: {
        ...mapGetters(["folderArchive"]),
    },
    components: {
        BaseRadio,
        BaseButtonDefault,
    },
    watch: {
        folderArchive(val) {
            if (!_.isNil(val)) {
                this.formData = _.cloneDeep(val);
            }
        },
    },
    mounted() {
        this.$root.$on("handleShowZimletArchire", (isShowZimletArchire) => {
            this.show(isShowZimletArchire);
        });

    },
    methods: {
        handleChangeDeleteBtnEnable(val) {
            this.formData.hideDeleteButton = !val;
        },
        handleChangeSendAndArchireBtnEnable(val) {
            this.formData.showSendAndArchive = val;
        },
        async editFolderArchive() {
            if (this.formData.id) {
                const formSetFolderArchive = {
                    section: "zwc:archiveZimlet",
                    a: [
                        {
                            n: "hideDeleteButton",
                            content: `${this.formData.hideDeleteButton}`,
                        },
                        {
                            n: "showSendAndArchive",
                            content: `${this.formData.showSendAndArchive}`,
                        },
                        {
                            n: "archivedFolder",
                            content: `${this.formData.id}`,
                        },
                    ],
                };
                await ZimbraMailService.setMailboxMetadataRequest(
                    formSetFolderArchive
                );
                if (this.isMessage) {
                    this.$message({
                        type: "success",
                        customClass: 'zm-notify',
                        duration: 3500,
                        dangerouslyUseHTMLString: true,
                        message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessChangeFolderArchive"),
                    });
                } else {
                    this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                        message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessChangeFolderArchive"),
                        type: "success",
                    });
                }
                this.$store.commit("SET_FOLDER_ARCHIVE", this.formData);
            } else {
                this.$alert(this.$t("zimbraNokey.zimbra_setting_zimlet_archiveWarning"), this.$t("zimbra.ztMsg.alertFeedReplyTitle"), {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    type: "warning",
                });
                return
            }
            this.dialogZimletArchire = false;
        },
        show(isShowZimletArchire) {
            if (this.folderArchive.id) {
                this.formData = Object.assign({}, this.folderArchive);
            } else {
                this.formData = {
                    hideDeleteButton: false,
                    showSendAndArchive: false,
                    absFolderPathI18n: this.$t("zimbra.zmMsg.browse"),
                };
            }
            this.isShowZimletArchire = isShowZimletArchire;
            this.dialogZimletArchire = true;

            const { name } = this.$route;
            if (name == READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE) {
                this.isMessage = true
            } else {
                this.isMessage = false
            }
        },
        changeFolderArchive() {
            this.$root.$emit(
                "handleSidebarActionMoveArchiveMail",
                FOLDER_EXTRA_ACTION.CHANGE_FOLDER_ARCHIVE
            );
        },
    },
};
</script>

<style lang="scss">
.setting-archive {
    display: flex;
    flex-direction: column;
    & .form-controls {
        margin-bottom: 0.2rem;
        & .label-control {
            font-weight: 500;
            min-width: 12rem;
        }
        & .radio-control {
            flex-direction: row;
        }
        &.folder-archive {
            padding-bottom: 1rem;
        }
    }
}
</style>
