<template>
  <el-menu class="box-control-inbox">
      <el-menu-item
            index="1"
            class="menu-item"
            @click="onOpenArchive"
        >
            <i class="icon-custom-wrap ic-setting-archive"></i>
            <span>{{ $t('zimbra.zhMsg.ALT_APP_OPTIONS') }}</span>
        </el-menu-item>
  </el-menu>
</template>

<script>
export default {
    methods: {
        onOpenArchive() {
            this.$root.$emit("handleShowZimletArchire", true);
        }
    }
}
</script>

<style scoped>
.box-control-inbox {
    max-width: 50rem;
    width: auto
}
</style>