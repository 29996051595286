
const ChatModule = {
    state: {
        listChat: []
    },
    getters: {
        listChat: state => state.listChat},
    mutations: {
        SET_LIST_CHAT(state, data) {
            state.listChat = data
        }
    },
    actions: {
        async getListChat({ commit }) {
            const _listChat = [
                {
                    otherSystemInfor:null,
                    groupId:3016,
                    setPushNotify:1,
                    groupKey:"b7cbc7d367ba0c04cd013b3206fe55b1",
                    DelAllMsgStatus:0,
                    lastTimeActive:1635145773,
                    createBy:30529299,
                    avatarServer:0,
                    numberMember:2,
                    keyCrypto:"f3MGH5zah60MHoUAmNsGlIcl9FVVzGzD",
                    members:[
                        {
                            orgName:"Ban Phát triển",
                            fullNameAscii:"Tran Xuan Truong1",
                            created:1614851859,
                            sex:2,
                            groupId:3016,
                            fullName:"Trần Xuân Trường1",
                            avatar:"http://10.58.71.190:8567/congnt/storage/avatar/514558.jpg",
                            userId:30529299,
                            orgId:245631,
                            employeeCode:"129848",
                            positionName:"Ngọc tạo",
                            createBy:30529299,
                            phoneNumber:"033322112239",
                            name:"129848",
                            _id:{
                            oid:"6040af13996b7b1cadad4b28"
                            },
                            email:"c_129848_id_367681_@d2t.vn",
                            username:"129848"
                        }
                    ],
                    messages:[
                        {
                            senderName:"Trần Xuân Trường1",
                            sender:30529299,
                            sendDate:1635145773,
                            groupId:3016,
                            _id:{
                                oid:"6176582d996b7b219d822bfa"
                            },
                            id:656354,
                            tempId:1635155405,
                            appCode:"SmartOffice",
                            type:0,
                            content:"Bạn: Hoàn thanh task tích hợp chát rồi anh nhé!",
                            platform:1,
                            status:2
                        }
                    ],
                    numberNewMsg:0,
                    projectName:"",
                    createKeyTime:1614851859,
                    projectId:0,
                    createName:"Trần Xuân Trường1",
                    createDate:1614851859
                },
                {
                    otherSystemInfor:null,
                    groupId:3015,
                    setPushNotify:1,
                    groupKey:"aa8777280b2fa7df4b20b81219fbe0a7",
                    DelAllMsgStatus:0,
                    lastTimeActive:1635042218,
                    createBy:30529299,
                    groupName:"Hans",
                    avatarServer:0,
                    numberMember:3,
                    keyCrypto:"zJfQPWWbkh1fTMjmwJ4nbFylb0zZ7FDX",
                    members:[
                        {
                            orgName:"Ban Phát triển",
                            fullNameAscii:"Doan Du",
                            created:1612239472,
                            sex:1,
                            groupId:3016,
                            fullName:"Đoàn Dự",
                            avatar:"http://10.58.71.190:8567/congnt/storage/avatar/514568.jpg",
                            userId:90010,
                            orgId:245631,
                            employeeCode:"0123456",
                            positionName:"Công Tử",
                            createBy:30529299,
                            phoneNumber:"0378883895",
                            name:"dud",
                            _id:{
                                oid:"6018d270996b7b66a24d95cc"
                            },
                            email:"dud@d2t.vn",
                            username:"dud"
                        },
                        {
                            orgName:"Ban Phát triển",
                            fullNameAscii:"Chu Ba Thong",
                            created:1612239472,
                            sex:1,
                            groupId:3015,
                            fullName:"Chu Bá Thông",
                            avatar:"http://10.58.71.190:8567/congnt/storage/avatar/514568.jpg",
                            userId:90003,
                            orgId:245631,
                            employeeCode:"1234655",
                            positionName:"Lão Ngoan Đồng",
                            createBy:30529299,
                            phoneNumber:"0378883895",
                            name:"thongcb",
                            _id:{
                                oid:"6018d270996b7b66a24d95cd"
                            },
                            email:"thongcb@d2t.vn",
                            username:"thongcb"
                        },
                        {
                            orgName:"Ban Phát triển",
                            fullNameAscii:"Tran Xuan Truong1",
                            created:1612239472,
                            sex:2,
                            groupId:3015,
                            fullName:"Trần Xuân Trường1",
                            avatar:"http://10.58.71.190:8567/congnt/storage/avatar/514558.jpg",
                            userId:30529299,
                            orgId:245631,
                            employeeCode:"129848",
                            positionName:"Ngọc tạo",
                            createBy:30529299,
                            phoneNumber:"033322112239",
                            name:"129848",
                            _id:{
                                oid:"6018d270996b7b66a24d95ce"
                            },
                            email:"c_129848_id_367681_@d2t.vn",
                            username:"129848"
                        }
                    ],
                    messages:[
                        {
                            isLink:1,
                            sendDate:1635042218,
                            groupId:3015,
                            appCode:"SmartOffice",
                            type:0,
                            content:"Bạn: Hnay demo nhé!",
                            platform:1,
                            senderName:"Trần Xuân Trường1",
                            sender:30529299,
                            _id:{
                            oid:"6174c3aa996b7b219d822bf9"
                            },
                            id:656353,
                            tempId:1635051842,
                            status:0
                        }
                    ],
                    numberNewMsg:0,
                    projectName:"",
                    createKeyTime:1612239472,
                    projectId:0,
                    groupNameAscii:"Hans",
                    createName:"Trần Xuân Trường1",
                    createDate:1612239472
                },
                {
                    otherSystemInfor:null,
                    groupId:3017,
                    setPushNotify:1,
                    groupKey:"715f24bd26f8e1b7648023b385df709a",
                    DelAllMsgStatus:0,
                    lastTimeActive:1631337500,
                    createBy:30529299,
                    avatarServer:0,
                    numberMember:2,
                    keyCrypto:"gAdEXvPnjUkkRww4BnN8SyzHPtM3o0qd",
                    members:[
                        {
                            orgName:"Ban Phát triển",
                            fullNameAscii:"Hieu Pham",
                            created:1614327229,
                            sex:2,
                            groupId:3017,
                            fullName:"Hiếu Phạm",
                            avatar:"http://10.58.71.190:8567/congnt/storage/avatar/514558.jpg",
                            userId:30529299,
                            orgId:245631,
                            employeeCode:"129848",
                            positionName:"Ngọc tạo",
                            createBy:30529299,
                            phoneNumber:"033322112239",
                            name:"129848",
                            _id:{
                                oid:"6038adbd996b7b1cadad4b22"
                            },
                            email:"c_129848_id_367681_@d2t.vn",
                            username:"129848"
                        }
                    ],
                    messages:[
                        {
                            senderName:"Trần Xuân Trường1",
                            sender:30529299,
                            sendDate:1631337500,
                            groupId:3017,
                            _id:{
                                oid:"613c3c1c996b7b1ff39d5e3a"
                            },
                            id:656329,
                            tempId:1631347071,
                            appCode:"SmartOffice",
                            type:0,
                            content:"Hiếu Phạm: Giao diện bị lỗi gì nữa không?",
                            platform:2,
                            status:2
                        }
                    ],
                    numberNewMsg:0,
                    projectName:"",
                    createKeyTime:1614327229,
                    projectId:0,
                    createName:"Trần Xuân Trường1",
                    createDate:1614327229
                },
                {
                    otherSystemInfor:null,
                    groupId:3017,
                    setPushNotify:1,
                    groupKey:"715f24bd26f8e1b7648023b385df709a",
                    DelAllMsgStatus:0,
                    lastTimeActive:1631337500,
                    createBy:30529299,
                    avatarServer:0,
                    numberMember:2,
                    keyCrypto:"gAdEXvPnjUkkRww4BnN8SyzHPtM3o0qd",
                    members:[
                        {
                            orgName:"Ban Phát triển",
                            fullNameAscii:"Hung Nguyen",
                            created:1614327229,
                            sex:2,
                            groupId:3017,
                            fullName:"Hùng Nguyễn",
                            avatar:"http://10.58.71.190:8567/congnt/storage/avatar/514558.jpg",
                            userId:30529299,
                            orgId:245631,
                            employeeCode:"129848",
                            positionName:"Ngọc tạo",
                            createBy:30529299,
                            phoneNumber:"033322112239",
                            name:"129848",
                            _id:{
                                oid:"6038adbd996b7b1cadad4b22"
                            },
                            email:"c_129848_id_367681_@d2t.vn",
                            username:"129848"
                        }
                    ],
                    messages:[
                        {
                            senderName:"Trần Xuân Trường1",
                            sender:30529299,
                            sendDate:1631337500,
                            groupId:3017,
                            _id:{
                                oid:"613c3c1c996b7b1ff39d5e3a"
                            },
                            id:656329,
                            tempId:1631347071,
                            appCode:"SmartOffice",
                            type:0,
                            content:"Bạn: Dumy data đẹp để demo",
                            platform:2,
                            status:2
                        }
                    ],
                    numberNewMsg:0,
                    projectName:"",
                    createKeyTime:1614327229,
                    projectId:0,
                    createName:"Trần Xuân Trường1",
                    createDate:1614327229
                },
                {
                    otherSystemInfor:null,
                    groupId:3017,
                    setPushNotify:1,
                    groupKey:"715f24bd26f8e1b7648023b385df709a",
                    DelAllMsgStatus:0,
                    lastTimeActive:1631337500,
                    createBy:30529299,
                    avatarServer:0,
                    numberMember:2,
                    keyCrypto:"gAdEXvPnjUkkRww4BnN8SyzHPtM3o0qd",
                    members:[
                        {
                            orgName:"Ban Phát triển",
                            fullNameAscii:"Le Van Viet",
                            created:1614327229,
                            sex:2,
                            groupId:3017,
                            fullName:"Lê Văn Việt",
                            avatar:"http://10.58.71.190:8567/congnt/storage/avatar/514558.jpg",
                            userId:30529299,
                            orgId:245631,
                            employeeCode:"129848",
                            positionName:"Ngọc tạo",
                            createBy:30529299,
                            phoneNumber:"033322112239",
                            name:"129848",
                            _id:{
                                oid:"6038adbd996b7b1cadad4b22"
                            },
                            email:"c_129848_id_367681_@d2t.vn",
                            username:"129848"
                        }
                    ],
                    messages:[
                        {
                            senderName:"Trần Xuân Trường1",
                            sender:30529299,
                            sendDate:1631337500,
                            groupId:3017,
                            _id:{
                                oid:"613c3c1c996b7b1ff39d5e3a"
                            },
                            id:656329,
                            tempId:1631347071,
                            appCode:"SmartOffice",
                            type:0,
                            content:"Bạn: Hoàn thành giao diện rồi",
                            platform:2,
                            status:2
                        }
                    ],
                    numberNewMsg:0,
                    projectName:"",
                    createKeyTime:1614327229,
                    projectId:0,
                    createName:"Trần Xuân Trường1",
                    createDate:1614327229
                },
                {
                    otherSystemInfor:null,
                    groupId:3017,
                    setPushNotify:1,
                    groupKey:"715f24bd26f8e1b7648023b385df709a",
                    DelAllMsgStatus:0,
                    lastTimeActive:1631337500,
                    createBy:30529299,
                    avatarServer:0,
                    numberMember:2,
                    keyCrypto:"gAdEXvPnjUkkRww4BnN8SyzHPtM3o0qd",
                    members:[
                        {
                            orgName:"Ban Phát triển",
                            fullNameAscii:"Hieu Nguyen",
                            created:1614327229,
                            sex:2,
                            groupId:3017,
                            fullName:"Hiếu Nguyễn",
                            avatar:"http://10.58.71.190:8567/congnt/storage/avatar/514558.jpg",
                            userId:30529299,
                            orgId:245631,
                            employeeCode:"129848",
                            positionName:"Ngọc tạo",
                            createBy:30529299,
                            phoneNumber:"033322112239",
                            name:"129848",
                            _id:{
                                oid:"6038adbd996b7b1cadad4b22"
                            },
                            email:"c_129848_id_367681_@d2t.vn",
                            username:"129848"
                        }
                    ],
                    messages:[
                        {
                            senderName:"Trần Xuân Trường1",
                            sender:30529299,
                            sendDate:1631337500,
                            groupId:3017,
                            _id:{
                                oid:"613c3c1c996b7b1ff39d5e3a"
                            },
                            id:656329,
                            tempId:1631347071,
                            appCode:"SmartOffice",
                            type:0,
                            content:"Hiếu Nguyễn: Hỗ trợ em phần tin tức",
                            platform:2,
                            status:2
                        }
                    ],
                    numberNewMsg:0,
                    projectName:"",
                    createKeyTime:1614327229,
                    projectId:0,
                    createName:"Trần Xuân Trường1",
                    createDate:1614327229
                },
                {
                    otherSystemInfor:null,
                    groupId:3017,
                    setPushNotify:1,
                    groupKey:"715f24bd26f8e1b7648023b385df709a",
                    DelAllMsgStatus:0,
                    lastTimeActive:1631337500,
                    createBy:30529299,
                    avatarServer:0,
                    numberMember:2,
                    keyCrypto:"gAdEXvPnjUkkRww4BnN8SyzHPtM3o0qd",
                    members:[
                        {
                            orgName:"Ban Phát triển",
                            fullNameAscii:"Huy Pham",
                            created:1614327229,
                            sex:2,
                            groupId:3017,
                            fullName:"Huy Pham",
                            avatar:"http://10.58.71.190:8567/congnt/storage/avatar/514558.jpg",
                            userId:30529299,
                            orgId:245631,
                            employeeCode:"129848",
                            positionName:"Ngọc tạo",
                            createBy:30529299,
                            phoneNumber:"033322112239",
                            name:"129848",
                            _id:{
                                oid:"6038adbd996b7b1cadad4b22"
                            },
                            email:"c_129848_id_367681_@d2t.vn",
                            username:"129848"
                        }
                    ],
                    messages:[
                        {
                            senderName:"Trần Xuân Trường1",
                            sender:30529299,
                            sendDate:1631337500,
                            groupId:3017,
                            _id:{
                                oid:"613c3c1c996b7b1ff39d5e3a"
                            },
                            id:656329,
                            tempId:1631347071,
                            appCode:"SmartOffice",
                            type:0,
                            content:"Bạn: alo, alo!",
                            platform:2,
                            status:2
                        }
                    ],
                    numberNewMsg:0,
                    projectName:"",
                    createKeyTime:1614327229,
                    projectId:0,
                    createName:"Trần Xuân Trường1",
                    createDate:1614327229
                },
                {
                    otherSystemInfor:null,
                    groupId:3017,
                    setPushNotify:1,
                    groupKey:"715f24bd26f8e1b7648023b385df709a",
                    DelAllMsgStatus:0,
                    lastTimeActive:1631337500,
                    createBy:30529299,
                    avatarServer:0,
                    numberMember:2,
                    keyCrypto:"gAdEXvPnjUkkRww4BnN8SyzHPtM3o0qd",
                    members:[
                        {
                            orgName:"Ban Phát triển",
                            fullNameAscii:"Hoa Pham",
                            created:1614327229,
                            sex:2,
                            groupId:3017,
                            fullName:"Hòa Phạm",
                            avatar:"http://10.58.71.190:8567/congnt/storage/avatar/514558.jpg",
                            userId:30529299,
                            orgId:245631,
                            employeeCode:"129848",
                            positionName:"Ngọc tạo",
                            createBy:30529299,
                            phoneNumber:"033322112239",
                            name:"129848",
                            _id:{
                                oid:"6038adbd996b7b1cadad4b22"
                            },
                            email:"c_129848_id_367681_@d2t.vn",
                            username:"129848"
                        }
                    ],
                    messages:[
                        {
                            senderName:"Trần Xuân Trường1",
                            sender:30529299,
                            sendDate:1631337500,
                            groupId:3017,
                            _id:{
                                oid:"613c3c1c996b7b1ff39d5e3a"
                            },
                            id:656329,
                            tempId:1631347071,
                            appCode:"SmartOffice",
                            type:0,
                            content:"Hòa Phạm: Phần tích hợp dashboard tableau tiến độ sao rồi e?",
                            platform:2,
                            status:2
                        }
                    ],
                    numberNewMsg:0,
                    projectName:"",
                    createKeyTime:1614327229,
                    projectId:0,
                    createName:"Trần Xuân Trường1",
                    createDate:1614327229
                },
            ]
            commit("SET_LIST_CHAT", _listChat);

        }
    }
};
export default ChatModule;
