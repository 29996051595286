var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"setting-tree-wrap"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInputSearch),expression:"showInputSearch"}]},[_c('el-input',{ref:"refInputSearch",staticClass:"el-text-control",staticStyle:{"padding-bottom":"10px"},attrs:{"size":"medium","id":"input-choose-contact"},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}})],1),_c('el-tree',{ref:"treeContact",class:`tree-wrap ${
            this.isMoveMail ? 'disabled-root-node' : ''
        }`,attrs:{"data":_vm.dataContact,"node-key":"id","props":_vm.defaultProps,"filter-node-method":_vm.filterNode,"expand-on-click-node":false,"draggable":"","highlight-current":"","default-expanded-keys":[_vm.FOLDER_DEFAULT.ROOT]},on:{"node-click":_vm.nodeClick},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('div',{staticClass:"zm-folder-info"},[_c('el-popover',{attrs:{"placement":"bottom-start","open-delay":1000,"trigger":"hover"}},[_c('div',[_c('b',[_vm._v(_vm._s(node.label))]),_vm._v(" "),_c('br'),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"folder-info-left"},[_vm._v(" "+_vm._s(_vm.$t("zimbra.zhMsg.addressBooksLabel"))+" ")]),_c('span',{staticClass:"folder-info-right"},[_vm._v(" "+_vm._s(data.n || 0)+" ")])]),(data.s)?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"folder-info-left"},[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.fSizeLabel")))]),_c('span',{staticClass:"folder-info-right"},[_vm._v(_vm._s(_vm.formatSize(data.s)))])]):_vm._e()]),_c('span',{staticClass:"custom-tree-node",attrs:{"slot":"reference"},slot:"reference"},[(
                            data.id != _vm.FOLDER_DEFAULT.CONTACTS &&
                            data.id != _vm.FOLDER_DEFAULT.EMAIL_CONTACT &&
                            data.id != _vm.FOLDER_DEFAULT.ROOT &&
                            data.id != _vm.FOLDER_DEFAULT.TRASH &&
                            data.id != _vm.FOLDER_DEFAULT.MAILING_LIST &&
                            data.isLink == undefined
                        )?_c('span',[_c('i',{class:[
                                'ic-folder',
                                _vm.isActive(data) ? 'active' : '',
                            ],style:(`padding-right: 30px; margin-left: -8px;filter: ${_vm.convertHexToFilterCss(
                                data
                            )}`)})]):_vm._e(),(data.isLink)?_c('span',[_c('i',{class:[
                                'ic-folder-copy',
                                _vm.isActive(data) ? 'active' : '',
                            ],style:(`padding-right: 30px; margin-left: -8px;filter: ${_vm.convertHexToFilterCss(
                                data
                            )}`)})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.CONTACTS)?_c('span',[_c('i',{class:[
                                'ic-contact ic-with-color',
                                _vm.isActive(data) ? 'active' : '',
                            ],style:(`
                                padding-right: 30px; margin-left: -8px;filter: ${_vm.convertHexToFilterCss(
                                data
                            )}; --icColor: ${_vm.convertHexToFilterCss(data)}`)})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.MAILING_LIST)?_c('span',[_c('i',{class:[
                                'ic-distribution',
                                _vm.isActive(data) ? 'active' : '',
                            ],staticStyle:{"padding-right":"30px","margin-left":"-8px"}})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.EMAIL_CONTACT)?_c('span',[_c('i',{class:[
                                'ic-email-contact',
                                _vm.isActive(data) ? 'active' : '',
                            ],style:(`
                                padding-right: 30px; margin-left: -8px;filter: ${_vm.convertHexToFilterCss(
                                data
                            )}; --icColor: ${_vm.convertHexToFilterCss(data)}`)})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.TRASH)?_c('span',[_c('i',{class:[
                                'ic-trash',
                                _vm.isActive(data) ? 'active' : '',
                            ],staticStyle:{"padding-right":"28px","margin-left":"-9px"}})]):_vm._e(),(
                            data.isLink == undefined ||
                            (data.isLink && data.perm)
                        )?_c('span',[_c('span',[_vm._v(" "+_vm._s(node.label)+" ")])]):_vm._e(),(data.isLink && data.perm == undefined)?_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(node.label)+" ")]):_vm._e()])])],1)}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }