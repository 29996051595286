<template>
    <el-aside :class="['sidebar-wrap', 'left' , 'aside-briefcase']">
        <div>
            <el-menu :default-openeds="['1', '3']">
                <el-menu-item index="0" class="menu-heading">
                    <div class="head-pri">
                        <i class="icon ic-stickynote-act"></i>
                        <span>{{this.$t("zimbra.zhMsg.ALT_APP_BRIEFCASE")}}</span>
                    </div>
                </el-menu-item>
            </el-menu>
        </div>
        <perfect-scrollbar class="zm-tree-folder">
            <BriefcaseMenuIndex
                :highlight-default="true"
                :show-info-folder="showInfoFolder"
            />
        </perfect-scrollbar>

        <MenuChatLeft/>

        <MenuRightClick/>

        <!-- Thêm mới thư mục cặp tài liệu vào tree -->
        <DialogAddNewContact />

        <!-- Dialog share thư mục cặp tài liệu-->
        <DialogShareFolder
            :isBriefcase="true"
        />

        <!-- Dialog thêm mới cặp tài liệu -->
        <DialogAddFolder/>

        <!-- Dialog Sửa các thuộc tính -->
        <DialogEditProperties/>
        <DialogRegisterFile />

        <!-- Dialog move Briefcase-->
        <DialogAddFolderBriefcase />
        <DialogMoveBriefcase />

        <DialogAddShareFolder
            :defaultType="FOLDER_VIEW.DOCUMENT"
        />
        <DialogTagSelector />

        <!--Vùng thao tác với zimblet-->
        <DialogZimletYahoo></DialogZimletYahoo>
        <DialogZimletArchire></DialogZimletArchire>
        <DialogAddWebexAccountManage />
        <DialogAddWebexNewMeeting />
        <DialogWebexJoinMeeting />
        <DialogWebexPreferences />
        <DialogMoveFolder></DialogMoveFolder>
    </el-aside>
</template>

<script>
import { mapActions } from "vuex"
import MenuRightClick from "../components/menu-right-click/menu-right-click-index.vue"
import DialogAddNewContact from "@/components/modules/layouts/components/contacts/DialogAddNewContact.vue";
import DialogShareFolder from "@/components/modules/layouts/components/DialogShareFolder";
import DialogAddFolder from "@/components/modules/layouts/components/DialogAddFolder";
import BriefcaseMenuIndex from "../components/menu-left-briefcase/briefcase-menu-index.vue"
import DialogEditProperties from "@/components/modules/layouts/components/contacts/DialogEditProperties.vue"
import DialogRegisterFile from "@/components/modules/briefcase/components/DialogRegisterFile.vue"
import DialogMoveBriefcase from "@/components/modules/briefcase/components/DialogMoveBriefcase.vue"
import DialogAddFolderBriefcase from "@/components/modules/briefcase/components/DialogAddFolderBriefcase.vue"
import DialogAddShareFolder from "@/components/modules/layouts/components/DialogAddShareFolder.vue"
import DialogTagSelector from "@/components/modules/briefcase/components/DialogTagSelector.vue"
import BriefcaseUtils from "@/utils/briefcase-utils"
import { FOLDER_VIEW } from "@/utils/constants"
import DialogAddWebexAccountManage from "@/components/modules/calendar/components/dialogs/dialog-add-webex-account-manage.vue"
import DialogAddWebexNewMeeting from "@/components/modules/calendar/components/dialogs/dialog-add-webex-new-meeting.vue"
import DialogWebexJoinMeeting from "@/components/modules/calendar/components/dialogs/dialog-webex-join-meeting.vue"
import DialogWebexPreferences from "@/components/modules/calendar/components/dialogs/dialog-webex-preferences.vue"
import DialogZimletYahoo from "@/components/modules/layouts/components/DialogZimletYahoo";
import DialogZimletArchire from "@/components/modules/layouts/components/DialogZimletArchire";
import DialogMoveFolder from "@/components/modules/layouts/components/DialogMoveFolder";
import MenuChatLeft from "@/components/modules/layouts/components/side-bar-left/MenuChatLeft.vue"
// import LauchNewWindow2 from "@/views/LauchNewWindow2.vue"
export default {
    mixins: [BriefcaseUtils],
    components: {
        MenuRightClick,
        DialogAddNewContact,
        DialogShareFolder,
        DialogAddFolder,
        BriefcaseMenuIndex,
        DialogEditProperties,
        DialogRegisterFile,
        DialogMoveBriefcase,
        DialogAddFolderBriefcase,
        DialogAddShareFolder,
        DialogTagSelector,
        DialogAddWebexAccountManage,
        DialogAddWebexNewMeeting,
        DialogWebexJoinMeeting,
        DialogWebexPreferences,
        DialogZimletYahoo,
        DialogZimletArchire,
        DialogMoveFolder,
        MenuChatLeft,
    },
    data() {
        return {
            FOLDER_VIEW: FOLDER_VIEW,
            isCollapse: true,
            showInfoFolder: true
        }
    },
    computed: {},
    methods: {
        ...mapActions(["actionToggleMenuCollapse"]),
        async handleReloadTreeFolder() {
            // await this.getBriefcaseFolder().then(briefcaseFolderRes => {
            //     this.$store.commit('SET_BRIEFCASES_FOLDER', briefcaseFolderRes);
            //     this.$store.dispatch("getTreeFolder")
            // });
        }
    },
    mounted() {
        this.$root.$on("showInfoFolder", (val) => {
            this.showInfoFolder = val
        })
        this.$root.$on("handleReloadTreeFolder", this.handleReloadTreeFolder)
    },
    destroyed() {
        this.$root.$off("handleReloadTreeFolder", this.handleReloadTreeFolder)
    }
}
</script>