import BaseService from "../shared/services/BaseService";


class ContentService extends BaseService {
    constructor() {
        super("service");
    }

    proxy(formParam) {
        const _target = "https://zimbra.webex.com/WBXService/XMLService"
        const url = `${this.baseURL}/proxy?target=${encodeURIComponent(_target)}`;
        return this.postRequest(url, formParam);
    }
}
export default new ContentService();
