<template>
    <!-- <perfect-scrollbar> -->
    <div class="setting-tree-wrap">
        <div v-show="showInputSearch">
            <el-input
                class="el-text-control"
                v-model="filterText"
                style="padding-bottom: 10px"
                size="medium"
                ref="refInputSearch"
                @blur="blurInput"
            ></el-input>
        </div>
        <!-- tree inbox -->
        <div style="height: 300px; overflow: auto">
            <!-- <perfect-scrollbar style="height: 300px" id="treeExtra"> -->
                <el-tree
                    :class="`tree-wrap ${ this.isMoveMail ? 'disabled-root-node': ''}`"
                    ref="tree"
                    :data="dataInBox"
                    node-key="id"
                    :props="defaultProps"
                    @node-click="nodeClick"
                    :filter-node-method="filterNode"
                    :expand-on-click-node="false"
                    draggable
                    highlight-current
                    :default-expanded-keys="listIdInBoxFolderExpanded"
                >
                    <div class="zm-folder-info" slot-scope="{ node, data }">
                        <el-popover popper-class="zm-tooltip" placement="bottom-start" :open-delay="1000" trigger="hover">
                            <div>
                                <b>{{ node.label }}</b> <br />
                                <div v-if="data.id != FOLDER_DEFAULT.STARS" class="d-flex">
                                    <span class="folder-info-left">{{ $t("zimbra.zmMsg.mailLabel")}}</span>
                                    <span class="folder-info-right">{{ data.n || 0 }}</span>
                                </div>
                                <div v-if="data.u" class="d-flex">
                                    <span class="folder-info-left">{{ $t("zimbraNokey.zimbra_sidebar_infoUnread") }}</span>
                                    <b class="folder-info-right">{{ data.u }}</b>
                                </div>
                                <div v-if="data.s" class="d-flex">
                                    <span class="folder-info-left">{{ $t("zimbra.zmMsg.fSizeLabel") }}</span>
                                    <span class="folder-info-right">{{ formatSize(data.s) }}</span>
                                </div>
                            </div>
                            <span slot="reference" class="custom-tree-node" id="`${data.id}`">
                                <span v-if="
                                        data.id != FOLDER_DEFAULT.DRAFTS &&
                                        data.id != FOLDER_DEFAULT.INBOX &&
                                        data.id != FOLDER_DEFAULT.SENT &&
                                        data.id != FOLDER_DEFAULT.STARS &&
                                        data.id != FOLDER_DEFAULT.ROOT &&
                                        data.isLink == undefined &&
                                        data.url == undefined &&
                                        !isFolderExternalAccount(data)"
                                >
                                    <i class="ic-folder" :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss( data )}`" ></i>
                                </span>
                                <span v-if="data.isLink">
                                    <i class="ic-folder-copy" style=" padding-right: 30px; margin-left: -8px; "></i>
                                </span>
                                <span v-if="data.id == FOLDER_DEFAULT.STARS">
                                    <i class="ic-star" style=" padding-right: 30px; margin-left: -8px; "></i>
                                </span>
                                <span v-if="data.id == FOLDER_DEFAULT.SENT">
                                    <i class="ic-sent" style=" padding-right: 30px; margin-left: -8px; "></i>
                                </span>
                                <span v-if="data.id == FOLDER_DEFAULT.DRAFTS">
                                    <i class="ic-draft" style=" padding-right: 30px; margin-left: -8px; zoom: 1.05; " ></i>
                                </span>
                                <span v-if="data.id == FOLDER_DEFAULT.INBOX">
                                    <i :class="`ic-inbox ${ node.expanded == true ? 'active' : '' }`"
                                        style=" padding-right: 30px; margin-left: -8px; zoom: 1.05; " ></i>
                                </span>
                                <span v-else-if="isFolderExternalAccount(data)">
                                    <i
                                        :class="['ic-directbox ic-with-color', isActive(data) ? 'active' : '']"
                                        :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(data)}; --icColor: ${convertHexToFilterCss(data)}`"
                                    ></i>
                                </span>
                                <span v-if="data.url != undefined">
                                    <i
                                        :class="['ic-folder-url ic-with-color', isActive(data) ? 'active' : '']"
                                        :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(data)}; --icColor: ${convertHexToFilterCss(data)}`"
                                    ></i>
                                </span>
                                <span v-if=" data.isLink == undefined || (data.isLink && data.perm) " >
                                    <span v-if="node.label.length > 30">
                                        {{ node.label.substring(0, 30) + "..." }}
                                    </span>
                                    <span v-else>
                                        {{ node.label }}
                                    </span>
                                </span>
                                <span v-if="data.isLink && data.perm == undefined" style="text-decoration: line-through"
                                    >{{ node.label }}
                                </span>
                            </span>
                        </el-popover>
                    </div>
                </el-tree>
                <!-- tree extend -->
                <el-tree
                    ref="treeExtend"
                    class="tree-wrap"
                    node-key="id"
                    style="margin-top: 10px"
                    :data="dataExtend"
                    :props="defaultProps"
                    @node-click="nodeClick"
                    :expand-on-click-node="false"
                    draggable
                    highlight-current
                    :filter-node-method="filterNode"
                    empty-text=""
                    :default-expanded-keys="listIdExtendFolderExpanded"
                >
                    <div class="zm-folder-info" slot-scope="{ node, data }">
                        <el-popover popper-class="zm-tooltip" placement="bottom-start" :open-delay="1000" trigger="hover" >
                            <div>
                                <b>{{ node.label }}</b> <br />
                                <div v-if="data.id != FOLDER_DEFAULT.STARS" class="d-flex" >
                                    <span class="folder-info-left">{{ $t("zimbra.zmMsg.mailLabel") }}</span>
                                    <span class="folder-info-right">{{ data.n || 0 }}</span>
                                </div>
                                <div v-if="data.u" class="d-flex">
                                    <span class="folder-info-left">{{ $t("zimbraNokey.zimbra_sidebar_infoUnread") }}</span>
                                    <b class="folder-info-right">{{ data.u }}</b>
                                </div>
                                <div v-if="data.s" class="d-flex">
                                    <span class="folder-info-left">{{ $t("zimbra.zmMsg.fSizeLabel") }}</span>
                                    <span class="folder-info-right">{{ formatSize(data.s) }}</span>
                                </div>
                            </div>
                            <span slot="reference" class="custom-tree-node">
                                <span v-if="
                                        data.id != FOLDER_DEFAULT.TRASH &&
                                        data.id != FOLDER_DEFAULT.JUNK &&
                                        data.id != FOLDER_DEFAULT.EXTEND &&
                                        data.id != FOLDER_DEFAULT.TAG &&
                                        data.isLink == undefined
                                    "
                                >
                                    <i class="ic-folder" :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss( data )}`" ></i>
                                </span>
                                <span v-if="data.isLink">
                                    <i class="ic-folder-copy" :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss( data )}`" ></i>
                                </span>
                                <span v-if="data.id == FOLDER_DEFAULT.JUNK">
                                    <i class="ic-spam" style=" padding-right: 28px; margin-left: -9px; " ></i>
                                </span>
                                <span v-if="data.id == FOLDER_DEFAULT.TRASH">
                                    <i class="ic-trash" style=" padding-right: 28px; margin-left: -9px; " ></i>
                                </span>
                                <span v-if="data.id == FOLDER_DEFAULT.TAG">
                                    <i class="ic-tag" style=" padding-right: 28px; margin-left: -9px; " ></i>
                                </span>
                                <span v-if=" data.id != FOLDER_DEFAULT.EXTEND && (data.isLink == undefined || (data.isLink && data.perm)) " >{{ node.label }}</span>
                                <!-- <span v-if="data.id == FOLDER_DEFAULT.EXTEND" >{{ node.expanded ? $t("zimbra.zmMsg.collapse") : $t("zimbraNokey.zimbra_sidebar_extend") }}</span > -->
                                    <span v-if="data.id == FOLDER_DEFAULT.EXTEND" >{{ $t("zimbraNokey.zimbra_sidebar_extend") }}</span >
                                    <span v-if="data.isLink && data.perm == undefined" style="text-decoration: line-through" >{{ node.label }}</span >
                            </span>
                        </el-popover>
                    </div>
                </el-tree>
            <!-- </perfect-scrollbar> -->
        </div>
    </div>
    <!-- </perfect-scrollbar> -->
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import { FOLDER_DEFAULT } from "@/utils/constants";
import { mapGetters } from "vuex";
import { EMAIL_ACTION } from "@/utils/constants";
import _ from "lodash";
import $ from 'jquery';
export default {
    mixins: [CommonUtils],
    props: {
        showInputSearch: {
            type: Boolean,
            default: true,
        },
        isMoveMail: {
            type: Boolean,
            default: true,
        },
        isMoveFast: {
            type: Boolean,
            default: false,
        },
        showInfoFolder: {
            type: Boolean,
            default: false,
        },
        isChangeFolderArchive: {
            type: Boolean,
            default: false,
        },
        listIdFolderHidden: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            defaultProps: {
                children: "folder",
                label: "name",
            },
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            filterText: "",
            dataExtend: [],
            dataInBox: [],
            allowfilter: true,
            node: {},
            listIdInBoxFolderExpanded: [FOLDER_DEFAULT.ROOT],
            listIdExtendFolderExpanded: [FOLDER_DEFAULT.EXTEND],
            dataBefore: {},
        };
    },
    mounted() {
        this.$root.$on("setFocusNodeActive", () => {
            this.setFocusNodeActive();
        });
        this.$root.$on("setCurrentKey", (id) => {
            this.setCurrentKey(id);
        });
        this.$root.$on("extendFolderExtra", (id) => {
            this.listIdInBoxFolderExpanded.push(id);
            this.listIdExtendFolderExpanded.push(id);
            // this.node.expanded = true;
        });
    },
    watch: {
        isMoveMail() {
            this.dataExtend = _.cloneDeep(this.dataSidebarExtend);
            this.dataExtend[0].folder = this.dataExtend[0].folder.filter(
                (x) => x.id != FOLDER_DEFAULT.TAG && !x.isRootSearchFolder
            );
        },
        listIdFolderHidden: {
            deep: true,
            handler(val) {
                if (this.dataInBox.length > 0 && this.dataInBox[0].folder){
                    this.dataInBox[0].folder = this.dataInBox[0].folder.filter(x => !val.includes(x.id));
                this.dataExtend[0].folder = this.dataExtend[0].folder.filter(x => !val.includes(x.id));
                }
                
            }
        },
        filterText(val) {
            if (this.allowfilter) {
                this.$refs.treeExtend.filter(val);
                this.$refs.tree.filter(val);
            }
        },
        dataSidebarInBox: {
            deep: true,
            handler(val) {
                this.dataInBox = _.cloneDeep(val);
                this.dataInBox[0].folder = this.dataInBox[0].folder.filter((x) => x.id != FOLDER_DEFAULT.DRAFTS);
            }
        },
        dataSidebarExtend: {
            deep: true,
            handler(val) {
                this.dataExtend = _.cloneDeep(val);
                this.dataExtend[0].folder = this.dataExtend[0].folder.filter((x) => x.id != FOLDER_DEFAULT.TAG && !x.isRootSearchFolder);
            }
        },
    },
    computed: {
        ...mapGetters([
            "activeNodeSidebar",
            "dataSidebarInBox",
            "dataSidebarExtend",
            "activeNodeSidebarRightClick",
            "activeFolderExtra"
        ]),
    },
    created() {
        this.initDataFolder();
    },
    methods: {
        initDataFolder() {
            this.$nextTick(() => {
                this.dataInBox = _.cloneDeep(this.dataSidebarInBox);
                
                this.dataExtend = _.cloneDeep(this.dataSidebarExtend);
                
                if (this.dataInBox && this.dataInBox.length) {
                    this.dataInBox[0].folder = this.dataInBox[0].folder.filter((x) => x.id != FOLDER_DEFAULT.DRAFTS);
                    this.dataInBox[0].folder = this.dataInBox[0].folder.filter(x => !this.listIdFolderHidden.includes(x.id));
                }
                if (this.dataExtend && this.dataExtend.length) {
                    this.dataExtend[0].folder = this.dataExtend[0].folder.filter((x) => x.id != FOLDER_DEFAULT.TAG && !x.isRootSearchFolder);
                    this.dataExtend[0].folder = this.dataExtend[0].folder.filter(x => !this.listIdFolderHidden.includes(x.id));
                }
            });
        },
        blurInput() {
            this.filterText = this.filterText.trim()
        },
        setCurrentKey(id) {
            setTimeout(() => {
                this.$refs.tree && this.$refs.tree.setCurrentKey(id);
            }, 200);
            setTimeout(() => {
                this.$refs.treeExtend && this.$refs.treeExtend.setCurrentKey(id);
            }, 200);
        },
        filterNode(value, data) {
            
            if (this.allowfilter) {
                if (!value) return true;
                return data.name?.toLowerCase().toString().indexOf(value?.toLowerCase().toString().trim()) !== -1;
            }
            return true;
        },
        nodeClick(data, node) {
           
            if (node.level != 1 || (!this.isMoveFast && !this.isMoveMail)) {
                this.dataBefore = data;
                this.setCurrentKey(null);
                this.setCurrentKey(data.id);
                this.node = node;
                this.allowfilter = false;
                this.filterText = data.name;
                if (this.$listeners.onSelectFolder) {
                    this.$emit("onSelectFolder", data);
                } else {
                    this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", data);
                    if (this.isMoveFast) {
                        setTimeout(() => {
                            this.$root.$emit(
                                "handleNavbarAction",
                                EMAIL_ACTION.MOVE
                            );
                            this.$root.$emit("closePopoverNavbar");
                        }, 200);
                    }
                }
                setTimeout(() => {
                    this.allowfilter = true;
                }, 200);
            } else {
                this.$refs.tree.setCurrentKey(this.dataBefore.id);
                this.$refs.treeExtend.setCurrentKey(this.dataBefore.id);
            }
        },
        setFocusNodeActive() {
            this.$nextTick(() => {
                setTimeout(() => {
                    let node = document.querySelector('#treeExtra .is-current');
                    if (node) {
                        setTimeout(() => {
                            // node.scrollIntoView();
                            let top = $(node).position().top;
                            $("#treeExtra").scrollTop(top - 120);
                        }, 500);
                    }
                }, 0);
            });
        },
        setFocusInPut() {
            this.$refs.refInputSearch.focus();
        },
        isActive(node) {
            return node.id === this.activeNodeSidebar?.id;
        },
    },
};
</script>
