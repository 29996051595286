<template>
    <div v-if="isLoading" class="pl-4 pr-4 pt-4">
        <el-skeleton :rows="12" :loading="isLoading" animated />
    </div>
    <div class="zimbra-tree" v-else>
        <!-- tree inbox -->
        <el-tree ref="vuetree" class="tree-wrap" id="tree" :data="dataSidebarInBox" node-key="id" :props="defaultProps"
                 @node-click="nodeClick" @node-contextmenu="rightClick" highlight-current :allowDrag="allowDrag"
                 :allow-drag="allowDrag" :expand-on-click-node="false" :default-expanded-keys="defaultExpand"
                 @node-expand="nodeExpandInbox" @node-collapse="nodeCollapeInbox" :auto-expand-parent="false"
                 v-if="!menuCollapse">
            <div :class="`zm-unselect zm-folder-info zm-dropable ${isFolderDefault(data) ? '' : 'zm-dragable'}`"
                 slot-scope="{ node, data }" :type="FOLDER_VIEW.MESSAGE" v-bind:id="data.id" v-bind:name="data.name">
                <el-popover popper-class="zm-tooltip" placement="bottom-start" :open-delay="1000" trigger="hover"
                            :disabled="!showInfoFolder || data.id === FOLDER_DEFAULT.ROOT">
                    <div>
                        <b>{{ node.label }}</b> <br />
                        <div v-if="data.id != FOLDER_DEFAULT.STARS" class="d-flex">
                            <span class="folder-info-left">{{
                                    $t("zimbra.zmMsg.mailLabel")
                                }}</span>
                            <span class="folder-info-right">{{
                                    data.n || 0
                                }}</span>
                        </div>
                        <div v-if="data.u" class="d-flex">
                            <span class="folder-info-left">{{
                                    $t("zimbraNokey.zimbra_sidebar_infoUnread")
                                }}</span>
                            <b class="folder-info-right">{{ data.u }}</b>
                        </div>
                        <div v-if="data.s" class="d-flex">
                            <span class="folder-info-left">{{
                                    $t("zimbra.zmMsg.fSizeLabel")
                                }}</span>
                            <span class="folder-info-right">{{
                                    formatSize(data.s)
                                }}</span>
                        </div>
                    </div>
                    <div slot="reference" class="custom-tree-node">
                        <span v-if="data.id != FOLDER_DEFAULT.DRAFTS &&
                            data.id != FOLDER_DEFAULT.INBOX &&
                            data.id != FOLDER_DEFAULT.SENT &&
                            data.id != FOLDER_DEFAULT.STARS &&
                            data.id != FOLDER_DEFAULT.ROOT &&
                            data.isLink == undefined &&
                            data.url == undefined &&
                            !isFolderExternalAccount(data)
                        ">
                            <i :class="['ic-folder ic-with-color', isActive(data) ? 'active' : '']"
                               :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(data)}; --icColor: ${convertHexToFilterCss(data)}`"></i>
                        </span>
                        <span v-if="data.url != undefined">
                            <i :class="['ic-folder-url ic-with-color', isActive(data) ? 'active' : '']"
                               :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(data)}; --icColor: ${convertHexToFilterCss(data)}`"></i>
                        </span>
                        <span v-if="data.isLink">
                            <i :class="['ic-folder-copy ic-with-color', isActive(data) ? 'active' : '']"
                               :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(data)}; --icColor: ${convertHexToFilterCss(data)}`"></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.STARS">
                            <i :class="['ic-star', isActive(data) ? 'active' : '']"
                               style="padding-right: 30px; margin-left: -9px"></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.SENT">
                            <i :class="['ic-sent', isActive(data) ? 'active' : '']"
                               style="padding-right: 30px; margin-left: -9px"></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.DRAFTS">
                            <i :class="['ic-draft', isActive(data) ? 'active' : '']"
                               style=" padding-right: 30px; margin-left: -8px; zoom: 1.05; "></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.INBOX">
                            <i :class="`ic-inbox ${isActive(data) ? 'active' : ''}`"
                               style=" padding-right: 30px; margin-left: -8px; zoom: 1.05; "></i>
                        </span>
                        <span v-else-if="isFolderExternalAccount(data)">
                            <i :class="['ic-directbox ic-with-color', isActive(data) ? 'active' : '']"
                               :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(data)}; --icColor: ${convertHexToFilterCss(data)}`"></i>
                        </span>
                        <!-- <span
                        v-if="data.isLink == undefined || (data.isLink && data.perm) "
                    >
                        {{ node.label }}{{ data.u ? ` (${data.u})` : "" }}
                    </span> -->
                        <span v-if="data.isLink == undefined ||
                            (data.isLink && data.perm)
                        ">
                            <span v-if="node.label.length > 30">
                                {{ node.label.substring(0, 30) + "..." }}
                            </span>
                            <span v-else>
                                {{ node.label }}
                            </span>
                            <span v-if="data.id === FOLDER_DEFAULT.DRAFTS || data.rid === FOLDER_DEFAULT.DRAFTS">{{
                                    data.n ? ` (${data.n})` : "" }}</span>
                            <span v-else>{{ data.u ? ` (${data.u})` : "" }}</span>
                        </span>
                        <span v-if="data.isLink && data.perm == undefined" style="text-decoration: line-through">
                            {{ node.label }}
                        </span>
                        <!-- icon hover -->
                        <button v-if="data.id != FOLDER_DEFAULT.STARS" class="icon-caret float-right"
                                style="position: absolute;right: 0px;padding-right:10px" :id="`caret_${data.id}`"
                                @click.stop="(e) => rightClick(e, data, node)" tabindex="-1">
                            <i class="el-icon-caret-right"></i>
                        </button>
                    </div>
                </el-popover>
            </div>
        </el-tree>
        <!-- tree extend -->
        <el-tree ref="vuetreeExtend" class="tree-wrap" node-key="id" id="treeExtend" :data="dataSidebarExtend"
                 :props="defaultProps" @node-click="nodeClickExtend" @node-contextmenu="rightClickExtend" draggable
                 highlight-current :allow-drag="allowDrag" :expand-on-click-node="false"
                 :default-expanded-keys="defaultExpandTreeExtend" @node-expand="nodeExpandExtend"
                 @node-collapse="nodeCollapeExtend" :auto-expand-parent="false" v-if="!menuCollapse">
            <div :class="`zm-unselect zm-folder-info zm-dropable ${isFolderDefault(data) ? '' : 'zm-dragable'}`"
                 slot-scope="{ node, data }" :type="FOLDER_VIEW.MESSAGE" v-bind:id="data.id" v-bind:name="data.name">
                <el-popover popper-class="zm-tooltip" placement="bottom-start" :open-delay="1000" trigger="hover"
                            :disabled="!(
                        showInfoFolder &&
                        data.id != FOLDER_DEFAULT.TAG &&
                        data.id != FOLDER_DEFAULT.EXTEND &&
                        !data.isRootSearchFolder
                    )
                        ">
                    <div>
                        <b>{{ node.label }}</b> <br />
                        <div class="d-flex" v-if="data.type != SIDEBAR_NODE.TAG && !data.isSearch">
                            <span class="folder-info-left">{{
                                    $t("zimbra.zmMsg.mailLabel")
                                }}</span>
                            <span class="folder-info-right"> {{
                                    data.n || 0
                                }}</span>
                        </div>
                        <div v-if="data.u" class="d-flex">
                            <span class="folder-info-left">{{
                                    $t("zimbraNokey.zimbra_sidebar_infoUnread")
                                }}</span>
                            <b class="folder-info-right">{{ data.u }}</b>
                        </div>
                        <div v-if="data.s" class="d-flex">
                            <span class="folder-info-left">{{
                                    $t("zimbra.zmMsg.fSizeLabel")
                                }}</span>
                            <span class="folder-info-right">{{
                                    formatSize(data.s)
                                }}</span>
                        </div>
                    </div>
                    <div slot="reference" class="custom-tree-node">
                        <span v-if="data.isLink">
                            <i :class="['ic-folder-copy', isActive(data) ? 'active' : '']" style="
                                    padding-right: 30px;
                                    margin-left: -7px;
                                    zoom: 1.05;
                                "></i>
                        </span>
                        <span v-if="data.isSearch &&
                            data.id != FOLDER_DEFAULT.TRASH &&
                            data.id != FOLDER_DEFAULT.ROOT
                        ">
                            <i :class="['ic-folder-add ic-with-color', isActive(data) ? 'active' : '']"
                               :style="`padding-right: 30px; margin-left: -7px;filter: ${convertHexToFilterCss(data)}; --icColor: ${convertHexToFilterCss(data)}`"></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.JUNK">
                            <i :class="['ic-spam', isActive(data) ? 'active' : '']"
                               style="padding-right: 28px; margin-left: -7px"></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.TRASH">
                            <i :class="['ic-trash', isActive(data) ? 'active' : '']"
                               style="padding-right: 28px; margin-left: -7px"></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.TAG ||
                            data.type == SIDEBAR_NODE.TAG
                        ">
                            <i :class="['ic-tag ic-with-color', isActive(data) ? 'active' : '']"
                               :style="`padding-right: 30px; margin-left: -7px; filter: ${convertHexToFilterCss(data)}; --icColor: ${convertHexToFilterCss(data)}`"></i>
                        </span>
                        <span v-else-if="data.isFolderMap">
                            <i :class="['ic-directbox ic-with-color', isActive(data) ? 'active' : '']"
                               :style="`padding-right: 30px; margin-left: -7px;filter: ${convertHexToFilterCss(data)}; --icColor: ${convertHexToFilterCss(data)}`"></i>
                        </span>
                        <span v-else-if="data.isInbox">
                            <i :class="`ic-inbox ${isActive(data) ? 'active' : ''}`"
                               style="padding-right: 30px;margin-left: -7px;zoom: 1.05;">
                            </i>
                        </span>
                        <span v-else-if="data.isSent">
                            <i :class="['ic-sent', isActive(data) ? 'active' : '']"
                               style="padding-right: 30px; margin-left: -7px"></i>
                        </span>
                        <span v-else-if="data.isJunk">
                            <i :class="['ic-spam', isActive(data) ? 'active' : '']"
                               style="padding-right: 28px; margin-left: -7px"></i>
                        </span>
                        <span v-else-if="data.isTrash">
                            <i :class="['ic-trash', isActive(data) ? 'active' : '']"
                               style="padding-right: 28px; margin-left: -7px"></i>
                        </span>
                        <span v-else-if="data.id != FOLDER_DEFAULT.TRASH &&
                            data.id != FOLDER_DEFAULT.JUNK &&
                            data.id != FOLDER_DEFAULT.EXTEND &&
                            data.id != FOLDER_DEFAULT.TAG &&
                            data.type != SIDEBAR_NODE.TAG &&
                            !data.isSearch &&
                            data.isLink == undefined &&
                            !data.isFolderMap
                        ">
                            <i :class="['ic-folder ic-with-color', isActive(data) ? 'active' : '']"
                               :style="`padding-right: 30px; margin-left: -7px;filter: ${convertHexToFilterCss(data)}; --icColor: ${convertHexToFilterCss(data)}`"></i>
                        </span>
                        <span v-if="data.id != FOLDER_DEFAULT.EXTEND &&
                            (data.isLink == undefined ||
                                (data.isLink && data.perm))
                        ">{{ node.label }}{{ data.u ? ` (${data.u})` : "" }}</span>
                        <span v-if="data.id == FOLDER_DEFAULT.EXTEND">{{
                                node.expanded
                                    ? $t("zimbra.zmMsg.collapse")
                                    : $t("zimbraNokey.zimbra_sidebar_extend")
                            }}
                        </span>
                        <span v-if="data.isLink && data.perm == undefined" style="text-decoration: line-through">
                            {{ node.label }}
                        </span>
                        <!-- icon hover -->
                        <button class="icon-caret float-right" :id="`caret_${data.id}`"
                                @click.stop="(e) => rightClickExtend(e, data, node)" tabindex="-1">
                            <i class="el-icon-caret-bottom"></i>
                        </button>
                    </div>
                </el-popover>
            </div>
        </el-tree>








        <!-- TREE ZIMLET -->
        <!-- <el-tree ref="vuetreeZimlet" class="tree-zimlet tree-wrap" node-key="id" id="treeZimlet" :data="data"
                 :props="defaultProps" @node-click="nodeClickZimlet" @node-contextmenu="rightClickZimlet"
                 :allow-drag="allowDrag" :expand-on-click-node="false" :default-expanded-keys="defaultExpandZimlet"
                 v-if="!menuCollapse">
            <span slot-scope="{ node, data }">
                <el-tooltip popper-class="zm-tooltip zm-custom-tooltip" placement="top-start" trigger="hover"
                            effect="dark" :open-delay="1000" :content="$t(setTooltipByTypeZimlet(data))">
                    <div class="wrap-zimlet">
                        <span>
                            <i v-if="data.id == -1" class="ic-3d-cube ic-viewable"></i>
                        </span>
                        <span>
                            <i v-if="data.data == SETTING_ZIMLET.YMEMOTIONS" class="ic-yahoo-2 ic-viewable"></i>
                        </span>
                        <span>
                            <i v-if="data.data == SETTING_ZIMLET.MAIL_ARCHIVE" class="ic-archire-2 ic-viewable"></i>
                        </span>
                        <span>
                            <i v-if="data.data == SETTING_ZIMLET.WEBEX" class="ic-webex-2 ic-viewable"></i>
                        </span>
                        <span>{{ $t(node.label) }}</span>
                    </div>
                </el-tooltip>
            </span>
        </el-tree> -->


























        <!-- <DialogChooseFolder @handleApcepterAction="handleApcepterAction" /> -->

        <!-- <div v-if="!menuCollapse" class="conversation-sidebar">
            <div class="top">
            </div>
            <perfect-scrollbar style="height: 230px;" class="list">
                <div
                    class="item-conversation on"
                    v-for="(item, index) in listChat"
                    :key="'chat_' + index"
                >
                    <a target="_blank" :href="getRedirectChat(item.groupId)" class="avt">
                        <span v-if="!(item.groupName || item.groupname || item.name || item.projectId || item.project)">
                            <img :src="getAvatar(item.messages[0].sender)" alt="image">
                        </span>
                        <span v-else>
                            <img src="@/assets/images/avatar_group.png" alt="">
                        </span>
                    </a>
                    <div class="ct">
                        <h3 class="name"><a target="_blank" :href="getRedirectChat(item.groupId)">{{getGroupChatName(item)}}</a></h3>
                        <span class="mail">{{getChatContent(item)}}</span>
                    </div>
                </div>
            </perfect-scrollbar>
        </div> -->
        <!--List menu collapse-->
        <div class="menu-collapse" v-if="menuCollapse">
            <ul class="toolbar-menu">
                <li v-for="(item, index) in listMenuDataCollapse" :key="index"
                    :class="['toolbar-item-left', isActive(item) ? 'active' : '']"
                    @click="handleNodeClick(item, item.cuType === 1)">
                    <el-tooltip class="item" effect="dark" :content="item.absFolderPathI18n || item.name" placement="right">

                        <span>
                            <i v-if="item.id == FOLDER_DEFAULT.INBOX"
                               :class="['ic-inbox', isActive(item) ? 'active' : '']">
                            </i>
                            <i v-else-if="item.id == FOLDER_DEFAULT.DRAFTS"
                               :class="['ic-draft', isActive(item) ? 'active' : '']">
                            </i>
                            <i v-else-if="item.id == FOLDER_DEFAULT.TRASH"
                               :class="['ic-trash', isActive(item) ? 'active' : '']">
                            </i>
                            <i v-else-if="item.id == FOLDER_DEFAULT.SENT"
                               :class="['ic-sent', isActive(item) ? 'active' : '']">
                            </i>
                            <i v-else-if="item.id == FOLDER_DEFAULT.STARS"
                               :class="['ic-star', isActive(item) ? 'active' : '']">
                            </i>
                            <i v-else-if="item.id == FOLDER_DEFAULT.JUNK"
                               :class="['ic-spam', isActive(item) ? 'active' : '']">
                            </i>
                            <i v-else-if="item.id == FOLDER_DEFAULT.TAG || item.type == SIDEBAR_NODE.TAG"
                               :class="['ic-tag', isActive(item) ? 'active' : '']">
                            </i>
                            <i v-else :class="['ic-folder', isActive(item) ? 'active' : '']">
                            </i>
                        </span>
                    </el-tooltip>
                </li>
                <!-- <li class="toolbar-item" @click="toggleShowFullMenuDataCollapse()" v-if="showIconFullMenuDataCollapse">
                    <i :class="[showFullMenuDataCollapse ? 'ic-arrow-up' : 'ic-arrow-down']"></i>
                </li> -->
            </ul>
        </div>
    </div>
</template>
<script>
import ZimbraService from "@/services/ZimbraService";
import CommonUtils from "@/utils/common-utils";
import { mapActions, mapGetters } from "vuex";
import { FOLDER_DEFAULT, FOLDER_VIEW, SETTING_ZIMLET, SIDEBAR_NODE } from "@/utils/constants";
// import _ from "lodash";
import hotkeys from "hotkeys-js";
// import CONFIG from "@/config/config";
export default {
    mixins: [CommonUtils],
    props: {
        highlightDefault: {
            type: Boolean,
            default: false
        },
        showInfoFolder: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    data() {
        const defaultExpandInbox = JSON.parse(localStorage.getItem("defaultExpandInbox")) || [FOLDER_DEFAULT.ROOT];
        const defaultExpandExtend = JSON.parse(localStorage.getItem("defaultExpandExtend")) || [FOLDER_DEFAULT.EXTEND];
        return {
            defaultProps: {
                children: "folder",
                label: "name",
                label2: "shortName"
            },
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            SETTING_ZIMLET: SETTING_ZIMLET,
            SIDEBAR_NODE: SIDEBAR_NODE,
            FOLDER_VIEW: FOLDER_VIEW,
            tableData: [
                {
                    data: "1",
                    content: "com_zimbra_ymemoticons",
                    value: "enabled",
                    name: "zimbraNokey.zimbra_setting_zimlet_yahoo_emoticons",
                    description: "zimbraNokey.zimbra_setting_zimlet_yahoo_emoticons_desc"
                },
                {
                    data: "2",
                    content: "com_zimbra_mailarchive",
                    value: "enabled",
                    name: "zimbraNokey.zimbra_setting_zimlet_archive",
                    description: "zimbraNokey.zimbra_setting_zimlet_archive_desc"
                },
                // {
                //     data: "3",
                //     content: "com_zextras_chat_open",
                //     value: "enabled",
                //     name: "zimbraNokey.zimbra_setting_zimlet_open_chat",
                //     description: "zimbra.setting.zimlet.open_chat_desc",
                // },
                {
                    data: "4",
                    content: "com_zimbra_srchhighlighter",
                    value: "enabled",
                    name: "zimbraNokey.zimbra_setting_zimlet_search_highlighter",
                    description: "zimbraNokey.zimbra_setting_zimlet_search_highlighter_desc"
                },
                {
                    data: "5",
                    content: "com_zimbra_webex",
                    value: "enabled",
                    name: "zimbra.zmWebExZimlet.label",
                    description: "zimbra.zmWebExZimlet.description"
                },
                // {
                //     data: "6",
                //     content: "com_zextras_drive_open",
                //     value: "enabled",
                //     name: "zimbraNokey.zimbra_setting_zimlet_zimbra_drive",
                //     description: "zimbra.setting.zimlet.zimbra_drive_desc",
                // },
                {
                    data: "7",
                    content: "com_zimbra_phone",
                    value: "enabled",
                    name: "zimbra.zhMsg.AB_FIELD_homePhone",
                    description: "zimbraNokey.zimbra_setting_zimlet_phone_desc"
                }
            ],
            data: [],
            listCheckZimlet: [
                SETTING_ZIMLET.YMEMOTIONS,
                SETTING_ZIMLET.MAIL_ARCHIVE,
                SETTING_ZIMLET.WEBEX
            ],
            node: {},
            defaultExpand: defaultExpandInbox,
            defaultExpandTreeExtend: defaultExpandExtend,
            defaultExpandZimlet: [-1],
            listMenuDataCollapse: [],
            showFullMenuDataCollapse: false,
            showIconFullMenuDataCollapse: false
        };
    },
    mounted() {
        this.$root.$on("closeAllRightMenu", () => {
            this.closeAllRightMenu();
        });
        this.$root.$on("handleChangeZimlet", (data) => {
            this.data[0].folder = data.filter(
                (item) =>
                    this.listCheckZimlet.indexOf(item.data * 1) !==
                    SETTING_ZIMLET.UNDEFINED && item.value === "enabled"
            );
        });
        this.$root.$on("extendAllFolder", () => {
            this.extendAll(this.node);
        });
        this.$root.$on("extendFolder", () => {
            this.node.expanded = true;
        });
        this.$root.$on("extendFolderById", (id) => {
            this.defaultExpandTreeExtend.push(id);
            this.defaultExpand.push(id);
        });
        this.$root.$on("setCurrentKeyMainTree", (id) => {
            setTimeout(() => {
                this.$refs.vuetree.setCurrentKey(id);
            }, 200);
            setTimeout(() => {
                this.$refs.vuetreeExtend.setCurrentKey(id);
            }, 200);
        });
        this.$root.$on("actionSetActiveNode", ({ nodeExtend, nodeId }) => {
            // Xự kiện set node active trên cây
            this.actionSetActiveNode(nodeExtend, nodeId);
        });
        hotkeys("i", this.handleGoToInbox);
        hotkeys("v+i", this.handleGoToInbox);
        hotkeys("v+d", this.handleGoToDrafts);
        hotkeys("v+j", this.handleGoToJunk);
        hotkeys("v+s", this.handleGoToSent);
        hotkeys("v+t", this.handleGoToTrash);
    },
    computed: {
        ...mapGetters([
            "dataSidebarInBox",
            "dataTableCurrent",
            "activeNodeSidebar",
            "dataSidebarExtend",
            "menuCollapse",
            "listChat",
            "preference",
            "showSearchStringCurrent"
        ]),
        isLoading() {
            const _inbox = this.dataSidebarInBox?.length || 0;
            const _extend = this.dataSidebarExtend?.length || 0;
            const _zimlet = this.data?.length || 0;
            return (_inbox + _extend + _zimlet) === 0;
        }
    },
    watch: {
        menuCollapse(data) {
            if (data) {
                this.actionGetListMenuCollapse(this.showFullMenuDataCollapse);
            }
        },
        dataSidebarInBox() {
            this.actionGetListMenuCollapse(this.showFullMenuDataCollapse);
        },
        dataSidebarInBox: {
            immediate: true,
            handler(data) {
                if (this._isCreated) {
                    if (this.highlightDefault) {
                        this.$nextTick(() => {
                            const initialSearch = this.preference.zimbraPrefMailInitialSearch;
                            let nodeActive = null;
                            if (initialSearch == "in:inbox" && this.$refs.vuetree) {
                                nodeActive = FOLDER_DEFAULT.INBOX;
                            }
                            this.$refs.vuetree && this.$refs.vuetree.setCurrentKey(null);
                            setTimeout(() => {
                                this.$refs.vuetree && this.$refs.vuetree.setCurrentKey(nodeActive);
                            }, 500);
                        });
                    }
                    this.actionGetListMenuCollapse(this.showFullMenuDataCollapse);
                }
            }
        }
    },
    async created() {
        this._isCreated = true;
        await this.getListChat();
        // await this.getDataZimlet();
    },
    methods: {
        nodeCollapeExtend(data) {
            let defaultExpandExtend = JSON.parse(localStorage.getItem("defaultExpandExtend")) || [FOLDER_DEFAULT.EXTEND];
            defaultExpandExtend = defaultExpandExtend.filter(x => x != data.id);
            localStorage.setItem("defaultExpandExtend", JSON.stringify(defaultExpandExtend));
        },
        nodeCollapeInbox(data) {
            let defaultExpandInbox = JSON.parse(localStorage.getItem("defaultExpandInbox")) || [FOLDER_DEFAULT.ROOT];
            defaultExpandInbox = defaultExpandInbox.filter(x => x != data.id);
            localStorage.setItem("defaultExpandInbox", JSON.stringify(defaultExpandInbox));
        },
        nodeExpandExtend(data) {
            let defaultExpandExtend = JSON.parse(localStorage.getItem("defaultExpandExtend")) || [FOLDER_DEFAULT.EXTEND];
            defaultExpandExtend.push(data.id);
            localStorage.setItem("defaultExpandExtend", JSON.stringify(defaultExpandExtend));
        },
        nodeExpandInbox(data) {
            let defaultExpandInbox = JSON.parse(localStorage.getItem("defaultExpandInbox")) || [FOLDER_DEFAULT.ROOT];
            defaultExpandInbox.push(data.id);
            localStorage.setItem("defaultExpandInbox", JSON.stringify(defaultExpandInbox));
        },
        ...mapActions(["setTableDataCurrent", "getListChat"]),
        extendAll(node) {
            node.expanded = true;
            if (node.isExtend) {
                let defaultExpandExtend = JSON.parse(localStorage.getItem("defaultExpandExtend")) || [FOLDER_DEFAULT.EXTEND];
                defaultExpandExtend.push(node.data.id);
                localStorage.setItem("defaultExpandExtend", JSON.stringify(defaultExpandExtend));
            } else {
                let defaultExpandInbox = JSON.parse(localStorage.getItem("defaultExpandInbox")) || [FOLDER_DEFAULT.ROOT];
                defaultExpandInbox.push(node.data.id);
                localStorage.setItem("defaultExpandInbox", JSON.stringify(defaultExpandInbox));
            }
            if (node.childNodes) {
                node.childNodes.forEach((x) => {
                    this.extendAll(x);
                });
            }
        },
        setTooltipByTypeZimlet(data) {
            let tooltip = "zimbraNokey.";
            if (data.data == SETTING_ZIMLET.YMEMOTIONS) {
                tooltip += "zimbra_setting_zimlet_yahoo_emoticons";
            } else if (data.data == SETTING_ZIMLET.MAIL_ARCHIVE) {
                tooltip += "zimbra_setting_zimlet_archive";
            } else if (data.data == SETTING_ZIMLET.WEBEX) {
                tooltip += "zimbra_setting_zimlet_webex";
            } else {
                return null;
            }
            return tooltip;
        },
        handleChooseTag() {
            this.$root.$emit("handleShowChooseTag", true);
        },
        handleChooseFolder() {
            this.$root.$emit("handleShowChooseFolder", true);
        },
        handleApcepterAction(val) {
            this.$root.$emit("handleSelectNode", val);
            this.$refs.vuetree.setCurrentKey(val.id);
        },
        handleGoToInbox() {
            // const path = "/mail/email";
            // if (this.$route.path == path) {
            //     this.setCurrentKeyDefault(
            //         "SET_ACTIVE_NODE_SIDEBAR",
            //         this.dataSidebarInBox[0]
            //     );
            //     this.$root.$emit("handleSelectNode", this.dataSidebarInBox[0]);
            //     this.$refs.vuetree.setCurrentKey(this.dataSidebarInBox[0].id);
            // }
        },
        handleGoToDrafts() {
            const path = "/mail/email";
            if (this.$route.path == path) {
                this.setCurrentKeyDefault(
                    "SET_ACTIVE_NODE_SIDEBAR",
                    this.dataSidebarInBox[2]
                );
                this.$root.$emit("handleSelectNode", this.dataSidebarInBox[2]);
                this.$refs.vuetree.setCurrentKey(this.dataSidebarInBox[2].id);
            }
        },
        handleGoToJunk() {
            const path = "/mail/email";
            if (this.$route.path == path) {
                this.setCurrentKeyDefault(
                    "SET_ACTIVE_NODE_SIDEBAR",
                    this.dataSidebarExtend[0].folder[0]
                );
                this.$root.$emit(
                    "handleSelectNode",
                    this.dataSidebarExtend[0].folder[0]
                );
                this.$refs.vuetreeExtend.setCurrentKey(
                    this.dataSidebarExtend[0].folder[0].id
                );
            }
        },
        handleGoToSent() {
            const path = "/mail/email";
            if (this.$route.path == path) {
                this.setCurrentKeyDefault(
                    "SET_ACTIVE_NODE_SIDEBAR",
                    this.dataSidebarInBox[3]
                );
                this.$root.$emit("handleSelectNode", this.dataSidebarInBox[3]);
                this.$refs.vuetree.setCurrentKey(this.dataSidebarInBox[3].id);
            }
        },
        handleGoToTrash() {
            const path = "/mail/email";
            if (this.$route.path == path) {
                this.setCurrentKeyDefault(
                    "SET_ACTIVE_NODE_SIDEBAR",
                    this.dataSidebarExtend[0].folder[1]
                );
                this.$root.$emit(
                    "handleSelectNode",
                    this.dataSidebarExtend[0].folder[1]
                );
                this.$refs.vuetreeExtend.setCurrentKey(
                    this.dataSidebarExtend[0].folder[1].id
                );
            }
        },
        setCurrentKeyDefault(type, data) {
            this.$refs.vuetreeExtend.setCurrentKey(null);
            this.$refs.vuetree && this.$refs.vuetree.setCurrentKey(null);
            this.$store.commit(type, data);
        },
        async getDataZimlet() {
            await ZimbraService.getInfoRequest("zimlets").then(async (resp) => {
                const response = this.getResponseBody(resp)["GetInfoResponse"]["zimlets"]["zimlet"];
                this.tableData = this.tableData.map((item) => {
                    if (item.content === "com_zimbra_ymemoticons") {
                        item.value = response.find((item) => item.zimlet.name === "com_zimbra_ymemoticons").zimletContext.presence;
                    } else if (item.content === "com_zimbra_mailarchive") {
                        item.value = response.find((item) => item.zimlet.name === "com_zimbra_mailarchive").zimletContext.presence;
                    } else if (item.content === "com_zimbra_srchhighlighter") {
                        item.value = response.find((item) => item.zimlet.name === "com_zimbra_srchhighlighter").zimletContext.presence;
                    } else if (item.content === "com_zimbra_webex") {
                        item.value = response.find((item) => item.zimlet.name === "com_zimbra_webex").zimletContext.presence;
                    } else if (item.content === "com_zimbra_phone") {
                        item.value = response.find((item) => item.zimlet.name === "com_zimbra_phone").zimletContext.presence;
                    }
                    // else if (item.content === "com_zextras_chat_open") {
                    //     item.value = response.find(
                    //         item => item.zimlet.name === "com_zextras_chat_open"
                    //     ).zimletContext.presence;
                    // } else if (item.content === "com_zextras_drive_open") {
                    //     item.value = response.find(
                    //         item => item.zimlet.name === "com_zextras_drive_open"
                    //     ).zimletContext.presence;
                    // }
                    return item;
                });
            });
            this.setTableDataCurrent(this.tableData.slice(0));
            this.data = [
                {
                    id: -1,
                    folder: [],
                    name: "zimbraNokey.zimbra_setting_zimlet_title"
                }
            ];
            this.data[0].folder = this.dataTableCurrent.filter((item) =>
                this.listCheckZimlet.indexOf(item.data * 1) !== SETTING_ZIMLET.UNDEFINED && item.value === "enabled"
            );
        },
        rightClickExtend(e, data, node) {

            node.isExtend = true;
            this.rightClick(e, data, node);
        },
        rightClick(e, data, node) {
            this.closeAllRightMenu();
            if (!this.checkFolderRegain(data)) {
                this.node = node;
                if (data.id == FOLDER_DEFAULT.TAG || data.l == FOLDER_DEFAULT.TAG) {
                    this.$root.$emit("showRightMenuTag", e, data, node);
                    this.$store.commit("SET_ACTIVE_NODE_SIDEBAR_RIGHTCLICK", data);
                } else if ((data.id == FOLDER_DEFAULT.ROOT && !data.isRootSearchFolder) || data.isFolderMap) {
                    this.$root.$emit("showRightMenuMailFolder", e, data);
                    this.$store.commit("SET_ACTIVE_NODE_SIDEBAR_RIGHTCLICK", data);
                } else {
                    this.$root.$emit("showRightMenu", e, data, node);
                    this.$store.commit("SET_TAGS", data);
                }
            }
        },
        closeAllRightMenu() {
            this.$root.$emit("closeRightMenuMail");
            this.$root.$emit("closeRightMenuSidebar");
            this.$root.$emit("closeRightMenuTag");
            this.$root.$emit("closeRightMenuMailFolder");
            this.$root.$emit("closePopoverNavbar");
        },
        nodeClickExtend(data) {
            if (!data.isRootSearchFolder) {
                this.handleNodeClick(data, true);
            }
        },
        nodeClick(data) {
            this.$store.commit(
                "SET_LIST_SELECTED_MAIL",
                []
            );
            this.$root.$emit("closeListTab"),
                this.closeAllRightMenu();
            if (data.id != FOLDER_DEFAULT.ROOT) {
                this.handleNodeClick(data, false);
            }
        },
        handleNodeClick(data, isExtendTree) {
            this.$root.$emit("fillModelToSearch", data);
            this.$refs.vuetree && this.$refs.vuetree.setCurrentKey(null);
            this.$refs.vuetreeExtend &&
            this.$refs.vuetreeExtend.setCurrentKey(null);
            if (isExtendTree) {
                this.$refs.vuetreeExtend &&
                this.$refs.vuetreeExtend.setCurrentKey(data.id || 0);
            } else {
                this.$refs.vuetree &&
                this.$refs.vuetree.setCurrentKey(data.id || 0);
            }
            // if (data.id == FOLDER_DEFAULT.TAG || data.l == FOLDER_DEFAULT.TAG) {
            //     this.$store.commit("SET_ACTIVE_NODE_SIDEBAR", data);
            // } else {
            //     this.$store.commit("SET_ACTIVE_NODE_SIDEBAR", data);
            // }
            this.$store.commit("SET_ACTIVE_NODE_SIDEBAR", data);
            if (!this.checkFolderRegain(data)) {

                this.$root.$emit("handleSelectNode", data);
            }
        },
        checkFolderRegain(data) {
            if (data.isLink && data.perm == undefined) {
                this.$confirm(
                    this.$t("zimbraNokey.zimbra_sidebar_warningRegain", {
                        name: data.name
                    }),
                    this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                    {
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        cancelButtonText: this.$t("zimbra.zhMsg.actionTaskCancelTT"),
                        type: "warning"
                    }
                )
                    .then(() => {
                        this.$root.$emit("deleteFolder", data);
                    })
                    .catch(() => {
                    });
                return true;
            } else {
                return false;
            }
        },
        actionSetActiveNode(nodeExtend, nodeId) {
            if (nodeExtend) {
                this.$refs.vuetree && this.$refs.vuetree.setCurrentKey(null);
                this.$refs.vuetreeExtend &&
                this.$refs.vuetreeExtend.setCurrentKey(nodeId);
                this.defaultExpandTreeExtend = [nodeId];
                this.defaultExpand = null;
            } else {
                this.$refs.vuetree && this.$refs.vuetree.setCurrentKey(nodeId);
                this.$refs.vuetreeExtend &&
                this.$refs.vuetreeExtend.setCurrentKey(null);
                this.defaultExpand = [nodeId];
                this.defaultExpandTreeExtend = null;
            }
        },
        nodeClickZimlet(data) {
            if (data.data == SETTING_ZIMLET.YMEMOTIONS) {
                this.$root.$emit("handleShowZimletYahoo", true);
            } else if (data.data == SETTING_ZIMLET.MAIL_ARCHIVE) {
                this.$root.$emit("handleShowZimletArchire", true);
            } else if (data.data == SETTING_ZIMLET.WEBEX) {
                this.$root.$emit("onOpenWebexPreferences");
            } else {
                console.log("Error");
            }
        },
        rightClickZimlet(e, data, node) {
            console.log(e);
        },
        // allowDrop(draggingNode, dropNode, type) {
        //     if (draggingNode.level === dropNode.level) {
        //         if (draggingNode.parent.id === dropNode.parent.id) {
        //             return type === "prev" || type === "next";
        //         }
        //     } else {
        //         return false;
        //     }
        // },
        allowDrag(node) {
            // node.data.id
            // console.log("node", node);
            return !this.isFolderDefault(node.data);
        },
        isFolderDefault(data) {
            for (var item in FOLDER_DEFAULT) {
                if (FOLDER_DEFAULT[item] == data.id) {
                    return true;
                }
            }
            return false;
        },
        isActive(node) {
            return node.id === this.activeNodeSidebar?.id;
        },
        actionGetListMenuCollapse(expandList) {
            this.listMenuDataCollapse = [];
            const listExtra = [];
            let listResult = [];
            if (this.dataSidebarInBox && this.dataSidebarInBox.length > 0) {
                this.dataSidebarInBox[0].folder.forEach(item => {
                    item.cuTuype = 1;
                    if (item.absFolderPath && (item.id === FOLDER_DEFAULT.INBOX || item.id === FOLDER_DEFAULT.STARS || item.id === FOLDER_DEFAULT.DRAFTS || item.id === FOLDER_DEFAULT.SENT)) {
                        listResult.push(item);
                    } else if (item.absFolderPath) {
                        listExtra.push(item);
                    }
                });
            }
            if (this.dataSidebarExtend && this.dataSidebarExtend.length > 0) {
                this.dataSidebarExtend[0].folder.forEach(item => {
                    item.cuTuype = 2;
                    if ((item.absFolderPath && (item.id === FOLDER_DEFAULT.JUNK || item.id === FOLDER_DEFAULT.TRASH)) || item.id == FOLDER_DEFAULT.TAG || item.type == SIDEBAR_NODE.TAG) {
                        listResult.push(item);
                    } else if (item.absFolderPath) {
                        listExtra.push(item);
                    }
                });
            }
            if (listExtra.length > 0) {
                this.showIconFullMenuDataCollapse = true;
            } else {
                this.showIconFullMenuDataCollapse = false;
            }
            if (expandList) {
                listResult = listResult.concat(listExtra);
            }
            this.listMenuDataCollapse = listResult;
        },
        toggleShowFullMenuDataCollapse() {
            this.showFullMenuDataCollapse = !this.showFullMenuDataCollapse;
            this.actionGetListMenuCollapse(this.showFullMenuDataCollapse);
        }
        // getAvatar(memberId) {
        //         return CONFIG.LINK_URL_AVARTAR_CHAT + memberId;
        //     },
        // getRedirectChat(chatGroupId) {
        //     return CONFIG.GROUP_CHAT + chatGroupId;
        // },
        // getGroupChatName(item) {
        //     if (item.groupName || item.name) {
        //         return item.groupName || item.name;
        //     }
        //     if (item.members && item.members.length > 0) {
        //         const user = item.members[0];
        //         return user.fullName ? user.fullName : user.fullname;
        //     }
        //     return '';
        // },
        // getChatContent(item) {
        //     // TODO decryptMessage
        //     if (item.messages[0] != null) {
        //         return this.stringTruncate(item.messages[0].content, 30);
        //     }
        //     return '';
        // }
    },
    destroyed() {
        this._isCreated = false;
    }
};
</script>
