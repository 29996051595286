<template>
    <div class="card-filter-row">
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueMainSelect"
                :options="mainSelect"
                @change="handleChangeMainSelect"
            />
        </div>
        <div class="card-filter-item"></div>
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="dateComparison"
                :value="valueDateComparison"
                :options="dateComparison"
                @change="handleChangeDateComparison"
            />
        </div>
        <div class="card-filter-item">
            <el-date-picker
                v-model="time"
                type="date"
                placeholder="Choose date"
                @change="handleChangeTime"
            >
            </el-date-picker>
        </div>
        <div class="card-filter-item"></div>
    </div>
</template>

<script>
import { FILTER_TEST_CONFIG } from "@/utils/constants";
import BaseSelect from "@/components/modules/base/base-select";
import CoreTest from "@/components/modules/base/base-filter-form/core-test";
import CommonUtils from "@/utils/common-utils";
export default {
    extends: CoreTest,
    components: {
        BaseSelect,
    },
    mixins: [CommonUtils],
    created() {
        this.beforeBuildDataForm();
        this.conditionsDateTest = this.conditionsTest;
    },
    watch: {
        conditionsTest(newData) {
            this.beforeBuildDataForm();
            this.conditionsDateTest = newData;
        },
    },
    data() {
        return {
            FILTER_TEST_CONFIG: FILTER_TEST_CONFIG,
            dateComparison: [
                {
                    dateComparison: FILTER_TEST_CONFIG.DATE_COMPARTISON.BEFORE,
                    name: this.$t(
                        "zimbra.zmMsg.beforeLc"
                    ),
                },
                {
                    dateComparison:
                        FILTER_TEST_CONFIG.DATE_COMPARTISON.NOT_BEFORE,
                    name: this.$t(
                        "zimbraNokey.zimbra_setting_filter_editFilter_dateCompartison_notBefore"
                    ),
                },
                {
                    dateComparison: FILTER_TEST_CONFIG.DATE_COMPARTISON.AFTER,
                    name: this.$t(
                        "zimbra.zmMsg.afterLc"
                    ),
                },
                {
                    dateComparison:
                        FILTER_TEST_CONFIG.DATE_COMPARTISON.NOT_AFTER,
                    name: this.$t(
                        "zimbraNokey.zimbra_setting_filter_editFilter_dateCompartison_notAfter"
                    ),
                },
            ],
            conditionsDateTest: {},
            valueMainSelect: "",
            valueDateComparison: "",
            time: "",
        };
    },
    methods: {
        beforeBuildDataForm() {
            // SET-VALUE-MAIN-SELECT
            this.valueMainSelect = this.mainSelect[7].id;
            //SET-VALUE-DATE-dateComparison
            if (
                this.conditionsTest.dateComparison ===
                FILTER_TEST_CONFIG.DATE_COMPARTISON.BEFORE
            ) {
                this.valueDateComparison =
                    FILTER_TEST_CONFIG.DATE_COMPARTISON.BEFORE;
                if (this.conditionsTest.nagative) {
                    this.valueDateComparison =
                        FILTER_TEST_CONFIG.DATE_COMPARTISON.NOT_BEFORE;
                }
            }
            if (
                this.conditionsTest.dateComparison ===
                FILTER_TEST_CONFIG.DATE_COMPARTISON.AFTER
            ) {
                this.valueDateComparison =
                    FILTER_TEST_CONFIG.DATE_COMPARTISON.AFTER;
                if (this.conditionsTest.nagative) {
                    this.valueDateComparison =
                        FILTER_TEST_CONFIG.DATE_COMPARTISON.NOT_AFTER;
                }
            }
            //SET-VALUE-TIME
            this.time =
                this.conditionsTest.d == ""
                    ? ""
                    : new Date(this.conditionsTest.d * 1000);
        },
        handleChangeDateComparison(dateComparison) {
            for (let i in this.conditionsDateTest) {
                if (i !== "negative" && this.validateNegative(dateComparison)) {
                    this.conditionsDateTest = {
                        ...this.conditionsDateTest,
                        negative: FILTER_TEST_CONFIG.NEGATIVE,
                    };
                } else {
                    if (!this.validateNegative(dateComparison)) {
                        delete this.conditionsDateTest.negative;
                    }
                }
            }
            this.conditionsDateTest.dateComparison =
                this.replacePrefix(dateComparison);
            this.valueDateComparison = dateComparison;
            this.$emit("onChangeConditionsTest", this.conditionsDateTest);
        },
        handleChangeTime(time) {
            this.time = time;
            this.conditionsDateTest.d = Math.floor(time.getTime() / 1000);
            this.$emit("onChangeConditionsTest", this.conditionsDateTest);
        },
    },
};
</script>
