<template>
    <div class="setting-tree-share-wrap">
        <div>
            <el-input
                class="el-text-control"
                v-model="filterText"
                style="padding-bottom: 10px"
                size="medium"
                ref="refInputSearch"
                :placeholder="$t('zimbraNokey.zimbra_sidebar_dialogAddShare_searchNamePlaceholder')"
                @blur="blurInput"
            ></el-input>
        </div>
        <perfect-scrollbar>
            <el-tree
                ref="treeFolderShare"
                class="tree-wrap tree-share-folder"
                id="tree"
                :data="dataView"
                :props="defaultProps"
                :filter-node-method="filterNode"
                show-checkbox
                node-key="id"
                draggable
                :expand-on-click-node="false"
                :default-expand-all="true"
                @check-change="checkChange"
                :check-strictly="true"
            >
                <div class="zm-folder-info " slot-scope="{ node, data }">
                    <el-popover
                        :width="400"
                        placement="bottom-start"
                        :open-delay="1000"
                        trigger="hover"
                        popper-class="zm-popper-folder-share"
                        :disabled="data.isOwner"
                    >
                        <span>
                            <i
                                :class="`${data.icon}`"
                                style="padding-right: 30px; margin-left: -9px"
                            ></i>
                        </span>
                        <span>
                            <b>{{ node.label }}</b> <br />
                        </span>
                        <el-row :gutter="20" style="padding-top: 10px;">
                            <el-col :span="8">
                                <b> {{$t("zimbra.zmMsg.ownerLabel")}}</b>
                            </el-col>
                            <el-col :span="16">
                                <span>{{data.parentName}}</span>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" style="padding-top: 10px;">
                            <el-col :span="8">
                                <b> {{$t("zimbra.zmMsg.granteeLabel")}} </b>
                            </el-col>
                            <el-col :span="16">
                                <span>{{data.granteeName}}</span>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" style="padding-top: 10px;">
                            <el-col :span="8">
                                <b> {{$t('zimbraNokey.zimbra_setting_share_role')}}:</b>
                            </el-col>
                            <el-col :span="16">
                                <span>{{mapRights.get(data.rights)}}</span>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" style="padding-top: 10px;">
                            <el-col :span="8">
                                <b> {{$t('zimbra.zmMsg.noteLabel')}} </b>
                            </el-col>
                            <el-col :span="16">
                                <span>{{$t("zimbra.zmMsg.sharedFoldersLinkHint",[`${data.parentName}’ ${data.name.split("/").pop(-1)}`])}}</span>
                            </el-col>
                        </el-row>
                        <div slot="reference" class="custom-tree-node">
                            <span>
                                <i
                                    :class="`${data.icon}`"
                                    style="padding-right: 30px; margin-left: -9px"
                                ></i>
                            </span>
                            <span>
                                {{ node.label }}
                            </span>
                        </div>
                    </el-popover>
                </div>
            </el-tree>
        </perfect-scrollbar>
    </div>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import EmailUtils from "@/utils/email-utils";
import ShareService from "@/services/ShareService.js";
import CompileService from "@/services/CompileService.js";
import {ICON_FOLDER_DEFAULT, FOLDER_DEFAULT, FOLDER_VIEW, FOLDER_RENAME} from "@/utils/constants";
import _ from "lodash";
import { mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
export default {
    mixins: [CommonUtils, EmailUtils],
    props: {
        defaultType: {
            type: String,
            default: function () {
                return FOLDER_VIEW.MESSAGE;
            }
        }
    },
    data() {
        return {
            FOLDER_VIEW: FOLDER_VIEW,
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            dataFolderShare: [],
            defaultProps: {
                children: "folder",
                label: "name",
            },
            dataView:[],
            filterText: "",
            ICON_FOLDER_DEFAULT: new Map(ICON_FOLDER_DEFAULT),
            option: this.defaultType ?? FOLDER_DEFAULT.INBOX,
            mapRights: new Map([
                ["", this.$t("zimbra.zhMsg.no")],
                ["r", this.$t("zimbra.zmMsg.shareRoleViewer")+" (" + this.$t("zimbra.zhMsg.MO_view")+")"],
                ["rwidx", this.$t("zimbra.zmMsg.shareRoleManager")+" (" + this.$t("zimbraNokey.zimbra_setting_share_formShare_roleManager")+")"],
                ["rwidxa",this.$t("zimbra.zmMsg.shareActionAdmin")+" (" + this.$t("zimbraNokey.zimbra_setting_share_formShare_roleAdmin")+")"],
                ["rwidxc", this.$t("zimbra.zmMsg.shareRoleManager")+" (" + this.$t("zimbraNokey.zimbra_setting_share_formShare_roleManager")+")"],
                ["rwidxac", this.$t("zimbra.zmMsg.shareActionAdmin")+" (" + this.$t("zimbraNokey.zimbra_setting_share_formShare_roleAdmin")+")"],
            ]),
            listDataCheck: [],
            myAddresses: {}
        };
    },
    computed: {
        ...mapGetters([
            "preference",
            "fromAddresses",
        ]),
    },
    watch: {
        filterText(val) {
            this.$refs.treeFolderShare.filter(val);
        },
    },
    mounted() {

    },
    created() {
        let formData = {content:""};
            formData.content = "<urn1:includeSelf>0</urn1:includeSelf>";
            this.myAddresses =  this.fromAddresses.find(x => x.uuid == this.preference.defaultFromAddress);
        this.getShareInfoApi(formData);
    },
    methods: {
        blurInput() {
            this.filterText = this.filterText.trim()
        },
        checkChange() {
            this.listDataCheck = this.$refs.treeFolderShare.getCheckedNodes();
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.toString().toUpperCase().indexOf(value.trim().toUpperCase()) !== -1;
        },
        changeData(option) {
            // const listFolderSpecial = [FOLDER_DEFAULT.TASK,FOLDER_DEFAULT.CONTACTS,FOLDER_DEFAULT.CALENDAR,FOLDER_DEFAULT.EMAIL_CONTACT]
            this.option = option;
            let data = [];
            if (option == 0) { // tất cả
                data = this.dataFolderShare;
            } else { // phần còn lại
                const dataFilter = this.dataFolderShare.filter(x=>x.view == option );
                if (option == FOLDER_VIEW.CONTACT) {
                    data = dataFilter
                                    .map(v => v['folderUuid'])
                                    .map((v, i, array) => array.indexOf(v) === i && i)
                                    .filter(v => dataFilter[v])
                                    .map(v => dataFilter[v]);
                } else {
                    data = dataFilter;
                }
            }
            this.dataView = this.createDataTree(data);
        },
        async getShareInfoApi(formData) {
            // content: `<urn1:owner by="name">${this.findEmail.email.toString()}</urn1:owner>`
            const searchRequest = await ShareService.getShareInfoRequest(formData);
            const data =this.getResponseBody(searchRequest)["GetShareInfoResponse"];
            if (data && data.share) {
                this.dataFolderShare = data.share;
                this.changeData(this.option);
            }
        },
        createDataTree(data) {
            let dataFolderShare = [];
            data.forEach(element => {
                let parentFolderShare = dataFolderShare.find(x => x.ownerId == element.ownerId);
                const folder = {...element, id:element.folderId, name: element.folderPath.substring(1), isOwner: false};
                folder.icon = this.ICON_FOLDER_DEFAULT.get(folder.id) || this.ICON_FOLDER_DEFAULT.get(folder.view) || "ic-folder-copy";
                folder.name = FOLDER_RENAME.includes(folder.name)
                        ? i18n.t(`zimbra.systemFolder.folder${folder.name}`)
                        : folder.name;
                if (_.isNil(parentFolderShare)) {
                    const newParentFolderShare = {
                        ...element,
                        name: element.ownerName,
                        nUpCase: element.ownerName.toLowerCase(),
                        id: this.generateUniqueId(),
                        folder: [],
                        isOwner: true,
                        icon: "ic-folder-copy"
                    };
                    newParentFolderShare.folder.push(folder);
                    folder.parentName = newParentFolderShare.name;
                    dataFolderShare.push(newParentFolderShare);
                } else {
                    if (!parentFolderShare.folder.map(e => e.id).includes(folder.id)) {
                        folder.parentName = parentFolderShare.name;
                        parentFolderShare.folder.push(folder);
                    }
                }
            });
            // dataFolderShare = this.reorderFolder(dataFolderShare);
            // console.log("DataFolderShare", dataFolderShare);
            dataFolderShare = _.orderBy(dataFolderShare, ["nUpCase"], ["asc"])
            return dataFolderShare;
        },
        async searchByEmail(listEmail) {
            listEmail = listEmail.filter(x => x != this.myAddresses.a);
            if (listEmail.length == 0) {
                this.dataFolderShare = [];
                this.changeData(this.option)
                return;
            }
            let stringXml = [];
            listEmail.forEach(x=>{
                const xml = `<urn1:owner by="name">${x}</urn1:owner><urn1:includeSelf>0</urn1:includeSelf>`;
                stringXml.push({
                    path: "getShareInfoRequest",
                    content: JSON.stringify({ content: xml }),
                });
            })
            const formData = {
                listRequest: stringXml,
            };
            const request = await CompileService.batchRequest(formData);
            const dataRequest = request.data.data;
            let listShare = [];
            dataRequest.forEach((item) => {
                const data = JSON.parse(item);
                listShare = Array.isArray(data.GetShareInfoResponse.share) ? [...listShare,...data.GetShareInfoResponse.share]:
                [...listShare,...[data.GetShareInfoResponse.share]];
            });
            this.dataFolderShare = listShare;
            this.changeData(this.option);
        },
    },
};
</script>
