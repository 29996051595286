<template>
    <div class="card-filter-row">
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueMainSelect"
                :options="mainSelect"
                @change="handleChangeMainSelect"
            />
        </div>
        <div class="card-filter-item"></div>
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="bodyComparison"
                :value="valueBodyComparison"
                :options="bodyComparison"
                @change="hanldeChangeBodyComparison"
            />
        </div>
        <div class="card-filter-item">
            <base-input
                type="text"
                :value="valueBodyTest"
                @input="hanldeChangeValue"
            />
        </div>
        <div class="card-filter-item"></div>
    </div>
</template>

<script>
import { FILTER_TEST_CONFIG } from "@/utils/constants";
import BaseSelect from "@/components/modules/base/base-select";
import BaseInput from "@/components/modules/base/base-input";
import CoreTest from "@/components/modules/base/base-filter-form/core-test";
export default {
    extends: CoreTest,
    components: {
        BaseSelect,
        BaseInput,
    },
    created() {
        this.beforeBuildDataForm();
        this.conditionsBodyTest = this.conditionsTest;
    },
    watch: {
        conditionsTest(newData) {
            this.beforeBuildDataForm();
            this.conditionsBodyTest = newData;
        },
    },
     mounted(){

    },
    data() {
        return {
            FILTER_TEST_CONFIG: FILTER_TEST_CONFIG,
            bodyComparison: [
                {
                    bodyComparison: FILTER_TEST_CONFIG.BODY_COMPARTISON.CONTAIN,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_CONTAINS"
                    ),
                },
                {
                    bodyComparison:
                        FILTER_TEST_CONFIG.BODY_COMPARTISON.NOT_CONTAIN,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_NOT_CONTAINS"
                    ),
                },
            ],
            valueMainSelect: "",
            valueBodyComparison: "",
            valueBodyTest: "",
            conditionsBodyTest: {},
        };
    },
    methods: {
        beforeBuildDataForm() {
            //SET-VALUE-MAIN-SELECT
            this.valueMainSelect = this.mainSelect[8].id;
            //SET-VALUE-BODY-COMPARISON
            this.valueBodyComparison = this.conditionsTest.negative
                ? this.bodyComparison[1].bodyComparison
                : this.bodyComparison[0].bodyComparison;
            //SET-VALUE-BODY-TEST
            this.valueBodyTest = this.conditionsTest.value;
        },
        hanldeChangeBodyComparison(bodyComparison) {
            for (let i in this.conditionsBodyTest) {
                if (i != "negative" && this.validateNegative(bodyComparison)) {
                    this.conditionsBodyTest = {
                        ...this.conditionsBodyTest,
                        negative: FILTER_TEST_CONFIG.NEGATIVE,
                    };
                } else {
                    if (!this.validateNegative(bodyComparison)) {
                        delete this.conditionsBodyTest.negative;
                    }
                }
            }
        },
        hanldeChangeValue(val) {
            this.conditionsBodyTest.value = val;
            this.$emit("onChangeConditionsTest", this.conditionsBodyTest);
        },
    },
};
</script>
