import BaseService from '@/shared/services/BaseService'

class CompileService extends BaseService {
    constructor() {
        super('compileService')
    }

    batchRequest(form) {
        const url = `${this.baseURL}`;
        return this.postRequest(url, form);
    }


    emailClientRequest(form, subUrl){
        const url = `https://vmail.viettel.vn/api/email-client/` + subUrl;
        return this.postRequest(url, form);
    }


    batchRequest1(form) {
        const url = `https://vmail.viettel.vn/api/email-client/folderActionRequest`;
        return this.postRequest(url, form);
    }

}

export default new CompileService()
