<template>
    <section :class="['app-wrapper zm-wrap__vemail drag-wrapper', menuCollapse ? 'menu-collapse' : null]">
        <top-bar />
        <top-nav class="navbar-navigation"/>
        <!-- <NavbarMailBox class="navbar-navigation"/> -->
        <div class="zm-vemail__layout">
            <side-bar-left />
            <main class="main-content__vemail zm-unselect">
                <slot />
            </main>
            <side-bar-right />
        </div>
    </section>
</template>

<script>
import TopBar from "@/components/modules/v-mail/layouts/TopBar.vue";
import TopNav from "@/components/modules/v-mail/layouts/TopNav.vue";
// import NavbarMailBox from "@/components/modules/layouts/NavbarMailBox";
import SideBarLeft from "@/components/modules/v-mail/layouts/SideBarLeft.vue";
// import SideBarLeft from "@/components/modules/layouts/Sidebar";
import SideBarRight from "@/components/modules/v-mail/layouts/SideBarRight.vue";
import CommonUtils from "@/utils/common-utils";
import { mapGetters } from "vuex";
export default {
    name: "EmailWithReadPanelLayout",
    components: {
        TopBar,
        TopNav,
        // NavbarMailBox,
        SideBarLeft,
        SideBarRight
    },
    mixins: [CommonUtils],
    computed: {
        ...mapGetters([
            "menuCollapse"
        ]),
    },
    methods: {
        login() {
            const { origin } = window.location;
            var authToken = this.getLocalStorageObject("zimbra_token");
            if (process.env.NODE_ENV === "development") {
                if (!authToken) {
                    window.location.href = `${origin}/${this.$configZimbra.LOGIN_PAGE}`;
                }
            } else if (!this.doesHttpOnlyCookieExist("ZM_AUTH_TOKEN")) {
                window.location.href = `${origin}/${this.$configZimbra.LOGIN_PAGE}`;
            }
        },
    },
};
</script>

<style lang="scss">
.app-wrapper {
    // display: flex;
    & .main-content__wrapper {
        width: calc(100% - 250px - 64px);
        @media screen and (max-width: 1280px) {
            width: calc(100% - 200px - 54px);
        }
    }
    & .navbar-navigation {
        background-color: #FAFAFA;
        border-bottom: 1px solid #EBEDF3;
        padding: 0px 12px;
        & .btn-compose {
            margin-left: 10px;
            background-color: #1976D2;
            border-color: #1976D2;
        }
        & .box-menu-head {
            margin-left: 8rem !important;
            & .item-menu-head {
                & a {
                    flex-direction: row;
                    & .ic {
                        width: 2.2rem;
                        height: 1.9rem;
                        &.ic-mark-as-unread, &.ic-archive-ac {
                            width: 2.4rem;
                            height: 2.4rem;
                        }
                    }
                    & span {
                        padding-left: 5px;
                        font-size: 1.3rem;
                    }
                }
            }
        }

    }
}
</style>
