import BaseService from "../shared/services/BaseService"
import CONFIG from '@/config/config'
class BriefcaseService extends BaseService {
    url = CONFIG.ENDPOINT['compileService']
    pathSaveDocument = "saveDocumentRequest"
    constructor() {
        super('zimbraService')
    }
    async listDocumentRevisionsRequest(form) {
        const url = `${this.baseURL}/ListDocumentRevisionsRequest`
        return await this.postRequest(url, form)
    }
    async saveDocumentRequest(form) {
        const url = `${this.baseURL}/saveDocumentRequest`
        return await this.postRequest(url, form, true, false)
    }
    async saveCompileDocumentRequest(form) {
        let listRequest = []
        if (form.length) {
            form.forEach(item => {
                listRequest.push({
                    path: this.pathSaveDocument,
                    content: JSON.stringify(item)
                })
            })
        }
        return this.postRequest(this.url, { listRequest })
    }
    async _handleFetchContent(url) {
        return this.getRequest(url)
    }
    async getItemRequest(data) {
        const url = `${this.baseURL}/getItemRequest`
        return await this.postRequest(url, data)
    }
    async purgeRevisionRequest(data) {
        const url = `${this.baseURL}/purgeRevisionRequest`
        return await this.postRequest(url, data)
    }
    // renderContent(dataContent){
    //     return
    // }
}
export default new BriefcaseService()