var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-menu',{staticClass:"box-control-inbox"},[_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"1","disabled":_vm.isFromTrashFolder ||
              !_vm.hasPermissionSpecifyFolder(
                  this.folderData,
                  _vm.RIGHTS_FOLDER.CREATE_SUBFOLDER
              )},on:{"click":_vm.onOpenAddBriefcase}},[_c('i',{staticClass:"el-icon-folder-add",staticStyle:{"color":"orange"}}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zhMsg.folderNew")))])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"5","hidden":!_vm.isFromTrashFolder,"disabled":!_vm.folderData.folder.length && _vm.folderData.n == 0},on:{"click":_vm.clickEmptyTrash}},[_c('i',{staticClass:"el-icon-delete",staticStyle:{"color":"red"}}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zhMsg.emptyTrash")))])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"2","disabled":_vm.isFromTrashFolder ||
              !_vm.hasPermissionSpecifyFolder(
                  this.folderData,
                  _vm.RIGHTS_FOLDER.ADMINISTER
              )},on:{"click":_vm.onShareFolder}},[_c('i',{staticClass:"el-icon-share",staticStyle:{"color":"cornflowerblue"}}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.shareFolder")))])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"3","disabled":_vm.isFromTrashFolder || _vm.isBriefCaseFolder},on:{"click":_vm.onDelete}},[_c('i',{staticClass:"el-icon-circle-close",staticStyle:{"color":"red"}}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zhMsg.actionDelete")))])]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"4","disabled":_vm.isFromTrashFolder},on:{"click":_vm.onOpenEditBriefcase}},[_c('i',{staticClass:"el-icon-edit",staticStyle:{"color":"blue"}}),_c('span',[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.editProperties")))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }