<template>
    <div class="card-filter-row">
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueHeader"
                :options="mainSelect"
                @change="handleChangeMainSelect"
            />
        </div>
        <div class="card-filter-item"></div>
        <div class="card-filter-item select-wrap__300">
            <base-select
                displayField="name"
                valueField="stringComparison"
                :value="valueStringComparison"
                :options="stringComparison"
                @change="handleChangeStringComparison"
            />
        </div>
        <div class="card-filter-item">
            <base-input
                type="text"
                :value="valueAddressTest"
                @input="handleChangeValue"
            />
        </div>
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="part"
                :value="valuePart"
                :options="part"
                @change="handleChangePart"
            />
        </div>
    </div>
</template>
<script>
import { FILTER_TEST_CONFIG } from "@/utils/constants";
import BaseSelect from "@/components/modules/base/base-select";
import BaseInput from "@/components/modules/base/base-input";
import CoreTest from "@/components/modules/base/base-filter-form/core-test";
import CommonUtils from "@/utils/common-utils";
export default {
    extends: CoreTest,
    mixins: [CommonUtils],
    components: {
        BaseSelect,
        BaseInput,
    },
    created() {
        this.beforeBuildDataForm();
        this.conditionAddressTest = this.conditionsTest;
    },
    mounted(){

    },
    watch: {
        conditionsTest(newData) {
            this.beforeBuildDataForm();
            this.conditionAddressTest = newData;
        },
    },
    data() {
        return {
            FILTER_TEST_CONFIG: FILTER_TEST_CONFIG,
            stringComparison: [
                {
                    stringComparison: FILTER_TEST_CONFIG.STRING_COMPARTION.IS,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_IS"
                    ),
                },
                {
                    stringComparison: "notis",
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_NOT_IS"
                    ),
                },
                {
                    stringComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.CONTAINS,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_CONTAINS"
                    ),
                },
                {
                    stringComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_CONTAINS,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_NOT_CONTAINS"
                    ),
                },
                {
                    stringComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.MATHCHES,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_MATCHES"
                    ),
                },
                {
                    stringComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_MATHCHES,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_NOT_MATCHES"
                    ),
                },
            ],
            part: [
                {
                    part: FILTER_TEST_CONFIG.PART.ALL,
                    name: this.$t("zimbra.zhMsg.EFILT_all"),
                },
                {
                    part: FILTER_TEST_CONFIG.PART.LOCALPART,
                    name: this.$t(
                        "zimbra.zmMsg.filterRuleOptionLocalpart"
                    ),
                },
                {
                    part: FILTER_TEST_CONFIG.PART.DOMAIN,
                    name: this.$t(
                        "zimbra.zmMsg.filterRuleOptionDomain"
                    ),
                },
            ],
            conditionAddressTest: {},
            valueHeader: "",
            valueStringComparison: FILTER_TEST_CONFIG.STRING_COMPARTION.IS,
            valueAddressTest: "",
            valuePart: FILTER_TEST_CONFIG.PART.ALL,
        };
    },
    methods: {
        beforeBuildDataForm() {
            if (this.conditionsTest.header === FILTER_TEST_CONFIG.HEADER.FROM)
                this.valueHeader = this.mainSelect[0].id;
            else if (
                this.conditionsTest.header === FILTER_TEST_CONFIG.HEADER.TO
            )
                this.valueHeader = this.mainSelect[1].id;
            else if (
                this.conditionsTest.header === FILTER_TEST_CONFIG.HEADER.CC
            )
                this.valueHeader = this.mainSelect[2].id;
            else if (
                this.conditionsTest.header === FILTER_TEST_CONFIG.HEADER.TO_CC
            )
                this.valueHeader = this.mainSelect[3].id;

            // SET-VALUE-STRING-COMPARISON
            if (
                this.conditionsTest.stringComparison ===
                FILTER_TEST_CONFIG.STRING_COMPARTION.IS
            ) {
                this.valueStringComparison =
                    FILTER_TEST_CONFIG.STRING_COMPARTION.IS;
                if (this.conditionsTest.negative) {
                    this.valueStringComparison =
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_IS;
                }
            } else if (
                this.conditionsTest.stringComparison ===
                FILTER_TEST_CONFIG.STRING_COMPARTION.CONTAINS
            ) {
                this.valueStringComparison =
                    FILTER_TEST_CONFIG.STRING_COMPARTION.CONTAINS;
                if (this.conditionsTest.negative) {
                    this.valueStringComparison =
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_CONTAINS;
                }
            } else if (
                this.conditionsTest.stringComparison ===
                FILTER_TEST_CONFIG.STRING_COMPARTION.MATHCHES
            ) {
                this.valueStringComparison =
                    FILTER_TEST_CONFIG.STRING_COMPARTION.MATHCHES;
                if (this.conditionsTest.negative) {
                    this.valueStringComparison =
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_MATHCHES;
                }
            }
            // SET-STRING-VALUE
            this.valueAddressTest = this.conditionsTest.value
                ? this.conditionsTest.value
                : "";
            //SET-VALUE-PART
            this.valuePart = this.conditionsTest.part;
        },
        handleChangeStringComparison(stringComparison) {
            for (let i in this.conditionAddressTest) {
                if (
                    i !== "negative" &&
                    this.validateNegative(stringComparison)
                ) {
                    this.conditionAddressTest = {
                        ...this.conditionAddressTest,
                        negative: FILTER_TEST_CONFIG.NEGATIVE,
                    };
                } else {
                    if (!this.validateNegative(stringComparison)) {
                        delete this.conditionAddressTest.negative;
                    }
                }
            }
            this.conditionAddressTest.stringComparison =
                this.replacePrefix(stringComparison);
            this.valueStringComparison = stringComparison;
            this.$emit("onChangeConditionsTest", this.conditionAddressTest);

        },
        handleChangeValue(val) {
            this.conditionAddressTest.value = val;
            this.valueAddressTest = val;
            this.$emit("onChangeConditionsTest", this.conditionAddressTest);
        },
        handleChangePart(part) {
            this.valuePart = part;
            this.conditionAddressTest.part = part;
            this.$emit("onChangeConditionsTest", this.conditionAddressTest);
        },
    },
};
</script>
