<template>
    <el-dialog
        class="dialog-body-wrap zimbra-dialog"
        :title="$t('zimbra.zmWebExZimlet.quickMeeting')"
        custom-class="mw__450"
        :visible.sync="isOpenDialog"
        width="35%"
        height="auto"
        :append-to-body="true"
        :close-on-click-modal="false"
    >
        <el-form class="form-wrap">
            <div class="row pt-2">
                <div class="col-3 zm-label">
                    <p class="webEx-label">
                        {{ $t("zimbra.zmWebExZimlet.webExAccntToUse") }}
                    </p>
                </div>
                <div class="col-6">
                    <el-select
                        class="w-100"
                        v-model="formInsert.account"
                        :default-first-option="true"
                    >
                        <el-option
                            v-for="(
                                item, idx
                            ) in lstAcountWebex"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"
                        >
                            <span>
                                {{ item.label }}
                            </span>
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-3 zm-label webEx-btn-label">
                    <el-button @click="onOpenDialogAddressSeletor"
                        class="webEx-btn"
                    >
                        {{ $t("zimbra.zhMsg.FILT_COND_HEADER_to") }}
                    </el-button>
                </div>
                <div class="col-8">
                    <BaseAddressSelector
                        v-model="formInsert.lstEmail"
                        :placeholder="''"
                        :multiple="true"
                        class="select-input-wrap"
                        style="width: 100%"
                        size="medium"
                    />
                </div>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="onSaveNewMeeting">{{
                $t("zimbra.zmMsg.mobileStatusNeedProvision")
            }}</el-button>
            <el-button @click="isOpenDialog = false">{{
                $t("zimbra.zhMsg.actionTaskCancelTT")
            }}</el-button>
        </span>
        <DialogAddressSelector
            ref="refAdressSelector"
            @onselected="handleSeletedAddress"
            :showCc="false"
            :showBcc="false"
        />
    </el-dialog>
</template>
<script>
import DialogAddressSelector from "../../../email/mail-compose/components/DialogAddressSelector";
import BaseAddressSelector from "@/components/modules/base/base-address-selector/BaseAddressSelector";
export default {
    mounted() {
        this.$root.$on("onOpenNewMeetingWebex", (lstEmail) => {
            if (this.lstAcountWebex.length > 0) {
                if (lstEmail && lstEmail.length > 0) {
                    this.formInsert.lstEmail = lstEmail
                } else {
                    this.formInsert.lstEmail = []
                }
                this.isOpenDialog = true;
            } else {
                this.$alert(
                    this.$t("zimbra.zmWebExZimlet.noWebExAccount"),
                    this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                    {
                        confirmButtonText:this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "warning",
                        dangerouslyUseHTMLString: true,
                    }
                )
            }
        });
    },
    destroyed() {
        this.$root.$off("onOpenNewMeetingWebex");
    },
    computed: {
        lstAcountWebex() {
            const lstAccountWebExZimlet = this.$store.getters.lstAccountWebExZimlet;
            let i = 0;
            const lstSelect = lstAccountWebExZimlet.map((item, index) => {
                const objectSelect = { ...item }
                if (this.isValidForm(objectSelect)) {
                    i++;
                }
                objectSelect.value = index;
                const altName = item.webexZimlet_username != "" ? item.webexZimlet_username : this.$t("zimbra.zmWebExZimlet.notConfigured")
                objectSelect.label = this.$t("zimbra.zmWebExZimlet.accntNumberAndName", [index + 1, altName]);
                return objectSelect;
            })
            if (i > 0) {
                return lstSelect;
            } else {
                return [];
            }

        }
    },
    components: {
        DialogAddressSelector,
        BaseAddressSelector
    },
    data() {
        return {
            //form Insert
            formInsert: {
                account: 0,
                lstEmail: [],
            },
            isOpenDialog: false,
        };
    },
    methods: {
        onOpenDialogAddressSeletor() {
            this.$refs.refAdressSelector.show();
        },
        /**
         * Hàm Lấy danh sách các email
         */
        handleSeletedAddress(addresses) {
            const lstSelect = addresses ? addresses.map((item) => {
                const objectSelect = { ...item }
                return objectSelect.a;
            }) : [];
            this.formInsert.lstEmail = this.formInsert.lstEmail.concat(lstSelect.slice());
        },

        /**
         * Validate trước khi lưu
         */
        validateBeforeSave() {
            let isValid = false;
            this.lstAcountWebex.map((item) => {
                const objectSelect = { ...item }
                if (this.validFormAccount(objectSelect) && this.formInsert.account === objectSelect.value) {
                    isValid = true;
                }
            })
            // Hiển thị thông báo lỗi
            if (!isValid) {
                this.$alert(
                    this.$t("zimbra.zmWebExZimlet.accountNumberNotSetup", [this.formInsert.account + 1]),
                    this.$t("zimbraNokey.zimbra_compose_criticalMsg"),
                    {
                        confirmButtonText:this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "error",
                        dangerouslyUseHTMLString: true,
                    }
                ).then(() => {
                    setTimeout(() => {
                        this.$refs.refInputName && this.$refs.refInputName.focus();
                    }, 500);
                })
                .catch(() => {});
            }
            return isValid;
        },
        /**
         * Hàm lưu
         */
        onSaveNewMeeting() {
            if (!this.validateBeforeSave()) {
                return;
            }
            //call API lưu dữ liệu
        },
        isValidForm(form) {
            if (
                form["webexZimlet_accountname"] || form["webexZimlet_accountname"].toString().trim().length > 0 ||
                form["webexZimlet_username"] || form["webexZimlet_username"].toString().trim().length > 0 ||
                form["webexZimlet_pwd"] || form["webexZimlet_pwd"].toString().trim().length > 0 ||
                form["webexZimlet_meetingPwd"] || form["webexZimlet_meetingPwd"].toString().trim().length > 0 ||
                form["WebExZimlet_altHosts"] || form["WebExZimlet_altHosts"].toString().trim().length > 0 ||
                form["webexZimlet_tollFreePH"] || form["webexZimlet_tollFreePH"].toString().trim().length > 0 ||
                form["webexZimlet_tollPH"] || form["webexZimlet_tollPH"].toString().trim().length > 0 ||
                form["webexZimlet_phonePasscode"] || form["webexZimlet_phonePasscode"].toString().trim().length > 0 ||
                form["webexZimlet_otherPH"] || form["webexZimlet_otherPH"].toString().trim().length > 0) {
                return true;
            }
            return false;
        },
        validFormAccount(form) {
            if (form["webexZimlet_accountname"].toString().length > 0 && form["webexZimlet_username"].toString().length > 0) {
                return true;
            }
            return false;
        },
    },
};
</script>
<style scoped>
.webEx-label {
    line-height: 37px;
    float: right !important;
}
.webEx-input {
    float: left !important;
}
.webEx-btn {
    float: right !important;
    padding: 8px 25px !important;
}
.webEx-btn-label {
    padding-right: 7px;
}
</style>