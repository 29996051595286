<template>
    <div class="setting-tree-wrap">
        <div v-show="showInputSearch">
            <el-input
                class="el-text-control"
                v-model="filterText"
                style="padding-bottom: 10px"
                size="medium"
                ref="refInputSearch"
                id="input-choose-contact"
            >
            </el-input>
        </div>
        <el-tree
            :class="`tree-wrap ${
                this.isMoveMail ? 'disabled-root-node' : ''
            }`"
            ref="treeContact"
            :data="dataContact"
            node-key="id"
            :props="defaultProps"
            @node-click="nodeClick"
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            draggable
            highlight-current
            :default-expanded-keys="[FOLDER_DEFAULT.ROOT]"
        >
            <div class="zm-folder-info" slot-scope="{ node, data }">
                <el-popover
                    placement="bottom-start"
                    :open-delay="1000"
                    trigger="hover"
                >
                    <div>
                        <b>{{ node.label }}</b> <br />
                        <div class="d-flex">
                            <span class="folder-info-left">
                                {{ $t("zimbra.zhMsg.addressBooksLabel") }}
                            </span>
                            <span class="folder-info-right">
                                {{ data.n || 0 }}
                            </span>
                        </div>
                        <div v-if="data.s" class="d-flex">
                            <span class="folder-info-left">{{
                                $t("zimbra.zmMsg.fSizeLabel")
                            }}</span>
                            <span class="folder-info-right">{{
                                formatSize(data.s)
                            }}</span>
                        </div>
                    </div>
                    <span slot="reference" class="custom-tree-node">
                        <span
                            v-if="
                                data.id != FOLDER_DEFAULT.CONTACTS &&
                                data.id != FOLDER_DEFAULT.EMAIL_CONTACT &&
                                data.id != FOLDER_DEFAULT.ROOT &&
                                data.id != FOLDER_DEFAULT.TRASH &&
                                data.id != FOLDER_DEFAULT.MAILING_LIST &&
                                data.isLink == undefined
                            "
                        >
                            <i
                                :class="[
                                    'ic-folder',
                                    isActive(data) ? 'active' : '',
                                ]"
                                :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(
                                    data
                                )}`"
                            ></i>
                        </span>
                        <span v-if="data.isLink">
                            <i
                                :class="[
                                    'ic-folder-copy',
                                    isActive(data) ? 'active' : '',
                                ]"
                                :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(
                                    data
                                )}`"
                            ></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.CONTACTS">
                            <i
                                :class="[
                                    'ic-contact ic-with-color',
                                    isActive(data) ? 'active' : '',
                                ]"
                                :style="`
                                    padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(
                                    data
                                )}; --icColor: ${convertHexToFilterCss(data)}`"
                            ></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.MAILING_LIST">
                            <i
                                :class="[
                                    'ic-distribution',
                                    isActive(data) ? 'active' : '',
                                ]"
                                style="
                                    padding-right: 30px;
                                    margin-left: -8px;
                                "
                            ></i>
                        </span>
                        <span
                            v-if="data.id == FOLDER_DEFAULT.EMAIL_CONTACT"
                        >
                            <i
                                :class="[
                                    'ic-email-contact',
                                    isActive(data) ? 'active' : '',
                                ]"
                                :style="`
                                    padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(
                                    data
                                )}; --icColor: ${convertHexToFilterCss(data)}`"
                            ></i>
                        </span>
                        <span v-if="data.id == FOLDER_DEFAULT.TRASH">
                            <i
                                :class="[
                                    'ic-trash',
                                    isActive(data) ? 'active' : '',
                                ]"
                                style="
                                    padding-right: 28px;
                                    margin-left: -9px;
                                "
                            ></i>
                        </span>
                        <span
                            v-if="
                                data.isLink == undefined ||
                                (data.isLink && data.perm)
                            "
                        >
                            <span>
                                {{ node.label }}
                            </span>
                        </span>
                        <span
                            v-if="data.isLink && data.perm == undefined"
                            style="text-decoration: line-through"
                            >{{ node.label }}
                        </span>
                    </span>
                </el-popover>
            </div>
        </el-tree>
    </div>
</template>

<script>
import CommonUtils from "@/utils/common-utils";
import { FOLDER_DEFAULT } from "@/utils/constants";
import { mapGetters } from "vuex";
import EmailUtils from "@/utils/email-utils";
import _ from "lodash";
import $ from 'jquery'
export default {
    mixins: [CommonUtils, EmailUtils],
    props: {
        isMoveMail: {
            type: Boolean,
            default: true,
        },
        isMoveFast: {
            type: Boolean,
            default: false,
        },
        showInputSearch: {
            type: Boolean,
            default: true,
        },
        isRootClick: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.dataContact = _.cloneDeep(this.dataSidebarContact);
        this.dataContact[0].folder = this.dataContact[0].folder.filter(
            (x) => x.id != FOLDER_DEFAULT.MAILING_LIST && x.id != FOLDER_DEFAULT.TRASH && (x.perm != "r"));
        this.setCurrentKey(null);
    },
    mounted() {
        this.$root.$on("setCurrentKeyTreeContact", (id) => {
            this.setCurrentKey(id);
        });
        this.$root.$on("handleFocusInput", () => {
            $(document).ready(function () {
                setTimeout(function () {
                    $("#input-choose-contact").focus();
                }, 500);
            });
        })
    },
    destroyed() {
        this.$root.$off("handleFocusInput")
    },
    data() {
        return {
            formMove: {
                parent: {}
            },
            defaultProps: {
                children: "folder",
                label: "name",
            },
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            filterText: "",
            dataContact: [],
            allowfilter: true,
            dataBefore: {},
            node: {},
            treeNodeMove: {},
            idCurrent: "",
            roles: ["rwidx",
                "rwidxp",
                "rwidxa",
                "rwidxap",],
            checkRoot: false
        };
    },
    computed: {
        ...mapGetters([
            "contacts",
            "dataSidebarContact",
            "activeFolderExtra",
            "listSelectedContact",
            "activeContact",
            "activeNodeContact",
        ]),
    },
    watch: {
        filterText(val) {
            if (this.allowfilter) {
                this.$refs.treeContact.filter(val);
            }
        },
        dataSidebarContact: {
            deep: true,
            handler(val) {
                this.dataContact = _.cloneDeep(val);
                this.dataContact[0].folder = this.dataContact[0].folder.filter(
                    (x) => x.id != FOLDER_DEFAULT.MAILING_LIST && x.id != FOLDER_DEFAULT.TRASH && (x.perm != "r")
                );
            },
        },
    },
    methods: {
        setCurrentKey(id) {
            setTimeout(() => {
                this.$refs.treeContact && this.$refs.treeContact.setCurrentKey(id);
            }, 150);
        },
        filterNode(value, data) {
            if (this.allowfilter) {
                if (!value) return true;
                return data.name.toString().toLowerCase().trim().indexOf(value.toString().toLowerCase().trim()) !== -1;
            }
            return true;
        },
        nodeClick(data, node) {
            if (node.level != 1 || this.isRootClick) {
                this.dataBefore = data;
                this.setCurrentKey(data.id);
                this.node = node;
                this.allowfilter = false;
                this.filterText = data.name;
                if (this.$listeners.onSelectFolder) {
                    this.$emit("onSelectFolder", data);
                } else {
                    this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", data);
                }
                setTimeout(() => {
                    this.allowfilter = true;
                }, 200);
            } else {
                this.$refs.treeContact.setCurrentKey(this.dataBefore.id);
            }
        },
        setFocus() {
            this.$refs.refInputSearch.focus();
        },
        isActive(node) {
            return node.id === this.activeNodeContact?.id;
        },

        /*
        * Set key root khi open dialog
        */
        onCurrentKeyRoot() {
            this.setCurrentKey(FOLDER_DEFAULT.ROOT)
            const _dataCurrent = this.dataSidebarContact[0]
            this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", _dataCurrent);
        }
    },
};
</script>