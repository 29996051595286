<template>
    <section :class="['app-wrapper drag-wrapper']">
        <Sidebar />
        <main class="main-content__wrapper zm-unselect">
            <NavbarBriefcaseBox />
            <slot />
        </main>
        <SideBarRight />
    </section>
</template>
<script>
import Sidebar from "@/components/modules/briefcase/layouts/SideBar.vue";
import SideBarRight from "@/components/modules/layouts/SideBarRight";
import NavbarBriefcaseBox from "@/components/modules/briefcase/layouts/NavbarBriefcaseBox";
import CommonUtils from "@/utils/common-utils";
import { mapGetters } from "vuex";
export default {
    components: {
        SideBarRight,
        Sidebar,
        NavbarBriefcaseBox,
    },
    mixins: [CommonUtils],
    computed: {
        ...mapGetters([
            "menuCollapse"
        ]),
    },
}
</script>