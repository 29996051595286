<template>
    <div>
        <div v-show="!menuCollapse" class="conversation-sidebar">
            <!-- <div class="top">
            </div> -->
            <div class="content">
                <el-tabs :class="'tab__'+tabActive" v-model="tabActive" @tab-click="handleChangeTabs">
                        <!-- START MESSAGE -->
                        <el-tab-pane v-if="false" :label="$t('zimbraNokey.zimbra_zimbraNokey_message')" name="message">
                            <div class="find-conversation">
                                <el-input :placeholder="$t('zimbra.zhMsg.search')" @input="hanldeChangeSearchMsg" v-model="inputSearchMessage"></el-input>
                                <!-- <el-button type="primary" icon="el-icon-search" @click="hanldeFindMsg"></el-button> -->
                                <button class="btn-search" @click="hanldeFindMsg">
                                <img src="@/assets/zimbra/images/ic-search1.svg" alt=""/>
                                </button>
                            </div>
                            <perfect-scrollbar class="listChat">
                                <div
                                    class="item-conversation on"
                                    v-for="(item, index) in listChatSearch"
                                    :key="'chat_' + index"
                                >
                                    <a target="_blank" :href="getRedirectChat(item.groupId)" class="avt">
                                        <span v-if="!(item.groupName || item.groupname || item.name || item.projectId || item.project)">
                                            <!-- <img :src="getAvatar(item.messages[0].sender)" alt="image"> -->
                                            <img :src="ramdomAvatar(item.groupId)" alt="image">
                                        </span>
                                        <span v-else>
                                            <img src="@/assets/images/avatar_group.png" alt="">
                                        </span>
                                    </a>
                                    <div class="ct">
                                        <h3 class="name"><a target="_blank" :href="getRedirectChat(item.groupId)">{{getGroupChatName(item)}}</a></h3>
                                        <span class="mail">{{getChatContent(item)}}</span>
                                    </div>
                                </div>
                            </perfect-scrollbar>
                        </el-tab-pane>
                        <!-- END MESSAGE-->
                        <!-- START CALENDAR -->
                        <el-tab-pane v-if="isShowCalendarMini" :label="$t('zimbra.zhMsg.ALT_APP_CALENDAR')" name="calendar">
                            <div class="date-timekeeping">
                                <!-- <perfect-scrollbar> -->
                                    <CalendarMiniScreen />
                                <!-- </perfect-scrollbar> -->
                            </div>
                        </el-tab-pane>
                        <!-- END CALENDAR -->
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CommonUtils from "@/utils/common-utils";
import CalendarUtils from "@/utils/calendar-utils.js";
import CalendarMiniScreen from "@/components/modules/calendar/calendar-side-bar"
import {
    FOLDER_DEFAULT,
    SIDEBAR_NODE,
} from "@/utils/constants";
import CONFIG from "@/config/config";
import CalendarService from "@/services/CalendarService";
export default {
    mixins: [CommonUtils, CalendarUtils],
    data() {
        return {
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            SIDEBAR_NODE: SIDEBAR_NODE,
            inputSearchMessage: "",
            tabActive: "calendar",
            listChatSearch: [],
            timeoutSearch: false
        }
    },
    components: {
        CalendarMiniScreen
    },
    async created() {
        await this.getListChat().then(() => {
            this.listChatSearch = [...this.listChat]
        });
        // Lấy thông tin cấu hình lịch
        let res = await CalendarService.getPrefsRequest();
        
        if (res?.data?.data && res?.data?.data instanceof Array && res?.data?.data.length > 0) {
            res = JSON.parse(res.data.data[0]).GetPrefsResponse.pref;
            const innitViewType = res.find((e) => e.name === "zimbraPrefCalendarInitialView")?.content;
            const _viewMode = this.getViewModeFromZimbra(innitViewType);
            this.setCalViewMode(_viewMode)
            // emit set view cho các component bên ngoài
            this.$root.$emit("onSetInnitView", _viewMode);
        }
    },
    computed: {
        ...mapGetters(["menuCollapse", "listChat"]),
        isShowCalendarMini() {
            return this.$store.getters.preference.zimbraPrefCalendarAlwaysShowMiniCal
        },
        // calViewMode() {
        //     return this.$store.getters.calViewMode;
        // }
    },
    watch: {
        isShowCalendarMini(val) {
            if (val) this.tabActive = "calendar"
            else this.tabActive = "message"
        }
    },
    methods: {
        ...mapActions(["getListChat", "setCalViewMode"]),
        ramdomAvatar(groupId) {
            const name = require("@/assets/zimbra/images/avatar-" + groupId + ".jpg")
            return name
        },
        getAvatar(memberId) {
            return CONFIG.LINK_URL_AVARTAR_CHAT + memberId;
        },
        getRedirectChat(chatGroupId) {
            return CONFIG.GROUP_CHAT + chatGroupId;
        },
        getGroupChatName(item) {
            if (item.groupName || item.name) {
                return item.groupName || item.name;
            }
            if (item.members && item.members.length > 0) {
                const user = item.members[0];
                return user.fullName ? user.fullName : user.fullname;
            }
            return '';
        },
        getChatContent(item) {
            // TODO decryptMessage
            if (item.messages[0] != null) {
                return this.stringTruncate(item.messages[0].content, 30);
            }
            return '';
        },
        handleChangeTabs() {
        },
        hanldeChangeSearchMsg() {
            if (this.timeoutSearch) {
                clearTimeout(this.timeoutSearch)
            }
            this.timeoutSearch = setTimeout(this.hanldeFindMsg, 500);
        },
        hanldeFindMsg() {
            if (this.inputSearchMessage) {
                const listSearch = []
                this.listChat.map(item => {
                    if (item.groupName) {
                        if (item.groupName.toLowerCase().includes(this.inputSearchMessage.toLowerCase()) ||
                            item.groupNameAscii.toLowerCase().includes(this.inputSearchMessage.toLowerCase())
                        ) {
                            listSearch.push(item)
                        }
                    } else {
                        if (item.members[0].fullNameAscii.toLowerCase().includes(this.inputSearchMessage.toLowerCase()) ||
                            item.members[0].fullName.toLowerCase().includes(this.inputSearchMessage.toLowerCase())
                        ) {
                            listSearch.push(item)
                        }
                    }
                })
                this.listChatSearch = [...listSearch]
            } else {
                this.listChatSearch = [...this.listChat]
            }
        }
    }
}
</script>

<style lang="scss">
.date-timekeeping {
    // height: 300px;
}
</style>