<template>
    <div class="card-filter-row">
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueMainSelect"
                :options="mainSelect"
                @change="handleChangeMainSelect"
            />
        </div>
        <div class="card-filter-item"></div>
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="numberComparison"
                :value="valueNumberComparison"
                :options="numberComparison"
                @change="handleChangeNumberComparison"
            />
        </div>
        <div class="card-filter-item">
            <base-input
                type="number"
                :value="valueSizeTest"
                @input="changeValueSize"
            />
        </div>
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="size"
                :value="valueSize"
                :options="size"
                @change="changeUnitSize"
            />
        </div>
    </div>
</template>

<script>
import { FILTER_TEST_CONFIG } from "@/utils/constants";
import BaseSelect from "@/components/modules/base/base-select";
import BaseInput from "@/components/modules/base/base-input";
import CoreTest from "@/components/modules/base/base-filter-form/core-test";
function hasString(myString) {
    return /[A-Z]/.test(myString);
}
function getNumber(String) {
    return hasString(String) ? String.replace(/[^0-9]/g, "") : String;
}
function getString(String) {
    return hasString(String) ? String.slice(-1) : "";
}
export default {
    extends: CoreTest,
    components: {
        BaseSelect,
        BaseInput,
    },
    created() {
        this.beforeBuildDataForm();
        this.conditionsSizeTest = this.conditionsTest;
    },
    watch: {
        conditionsTest(newData) {
            this.beforeBuildDataForm();
            this.conditionsSizeTest = newData;
        },
    },
    data() {
        return {
            FILTER_TEST_CONFIG: FILTER_TEST_CONFIG,
            numberComparison: [
                {
                    numberComparison:
                        FILTER_TEST_CONFIG.NUMBER_COMPARISON.UNDER,
                    name: this.$t(
                        "zimbra.zmMsg.under"
                    ),
                },
                {
                    numberComparison:
                        FILTER_TEST_CONFIG.NUMBER_COMPARISON.NOT_UNDER,
                    name: this.$t(
                        "zimbra.zmMsg.notUnder"
                    ),
                },
                {
                    numberComparison: FILTER_TEST_CONFIG.NUMBER_COMPARISON.OVER,
                    name: this.$t(
                        "zimbra.zmMsg.isOn"
                    ),
                },
                {
                    numberComparison:
                        FILTER_TEST_CONFIG.NUMBER_COMPARISON.NOT_OVER,
                    name: this.$t(
                        "zimbra.zmMsg.notOver"
                    ),
                },
            ],
            size: [
                {
                    size: FILTER_TEST_CONFIG.NUMBER_COMPARISON.S.B,
                    name: "B",
                },
                {
                    size: FILTER_TEST_CONFIG.NUMBER_COMPARISON.S.KB,
                    name: "KB",
                },
                {
                    size: FILTER_TEST_CONFIG.NUMBER_COMPARISON.S.MB,
                    name: "MB",
                },
                {
                    size: FILTER_TEST_CONFIG.NUMBER_COMPARISON.S.GB,
                    name: "GB",
                },
            ],
            conditionsSizeTest: {},
            valueMainSelect: "",
            valueNumberComparison: "",
            valueSizeTest: "",
            valueSize: "",
        };
    },
    methods: {
        beforeBuildDataForm() {
            // SET-VALUE-MAIN-SELECT
            this.valueMainSelect = this.mainSelect[6].id;
            // SET-VALUE-NUMBER-COMPARISON
            if (
                this.conditionsTest.numberComparison === FILTER_TEST_CONFIG.NUMBER_COMPARISON.UNDER
            ) {
                this.valueNumberComparison = FILTER_TEST_CONFIG.NUMBER_COMPARISON.UNDER;
                if (this.conditionsTest.nagative) {
                    this.valueNumberComparison = FILTER_TEST_CONFIG.NUMBER_COMPARISON.NOT_UNDER;
                }
            }
            if (
                this.conditionsTest.numberComparison === FILTER_TEST_CONFIG.NUMBER_COMPARISON.OVER
            ) {
                this.valueNumberComparison = FILTER_TEST_CONFIG.NUMBER_COMPARISON.OVER;
                if (this.conditionsTest.nagative) {
                    this.valueNumberComparison = FILTER_TEST_CONFIG.NUMBER_COMPARISON.NOT_OVER;
                }
            }
            // SET-VALUE-SIZE-TEST
            this.valueSizeTest = getNumber(this.conditionsTest.s);
            // SET-VALUE-SIZE
            this.valueSize = getString(this.conditionsTest.s);
        },
        handleChangeNumberComparison(numberComparison) {
            for (let i in this.conditionsSizeTest) {
                if (
                    i !== "negative" &&
                    this.validateNegative(numberComparison)
                ) {
                    this.conditionsSizeTest = {
                        ...this.conditionsSizeTest,
                        negative: FILTER_TEST_CONFIG.NEGATIVE,
                    };
                } else {
                    if (this.validateNegative(numberComparison)) {
                        delete this.conditionsSizeTest.negative;
                    }
                }
            }
            this.conditionsSizeTest.numberComparison =
                this.replacePrefix(numberComparison);
            this.valueHeadernumberComparison = numberComparison;
            this.$emit("onChangeConditionsTest", this.conditionsSizeTest);
        },
        changeValueSize(val) {
            this.valueSizeTest = val;
            let s = this.valueSizeTest + this.valueSize;
            this.conditionsSizeTest.s = s;
            this.$emit("onChangeConditionsTest", this.conditionsSizeTest);
        },
        changeUnitSize(val) {
            this.valueSize = val;
            let s = this.valueSizeTest + this.valueSize;
            this.conditionsSizeTest.s = s;
            this.$emit("onChangeConditionsTest", this.conditionsSizeTest);
        },
    },
};
</script>
