import BaseService from "../shared/services/BaseService";
import CompileService from "./CompileService";
class ZimbraMailService extends BaseService {
    constructor() {
        super('zimbraService');
    }

    searchRequest(form, withProgress = true, withNotify = true) {
        const url = `${this.baseURL}/searchRequest`;
        return this.postRequest(url, form, withProgress, withNotify);
    }

    searchConvRequest(form) {
        const url = `${this.baseURL}/searchConvRequest`;
        return this.postRequest(url, form);
    }
    getFolderRequest(form) {
        const url = `${this.baseURL}/getFolderRequest`;
        return this.postRequest(url, form);
    }

    createFolderRequest(form) {
        const url = `${this.baseURL}/createFolderRequest`;
        return this.postRequest(url, form, false, false);
    }

    folderActionRequest(form) {
        const url = `${this.baseURL}/folderActionRequest`;
        return this.postRequest(url, form, false, false);
    }

    getMsgRequest(form) {
        const url = `${this.baseURL}/getMsgRequest`;
        return this.postRequest(url, form);
    }

    autoCompleteRequest(form) {
        const url = `${this.baseURL}/autoCompleteRequest`;
        return this.postRequest(url, form, false);
    }

    sendShareNotificationRequest(form) {
        const url = `${this.baseURL}/sendShareNotificationRequest`;
        return this.postRequest(url, form);
    }

    searchGalRequest(form, withProgress = true, withNotify = true) {
        const url = `${this.baseURL}/searchGalRequest`;
        return this.postRequest(url, form, withProgress, withNotify);
    }

    batchRequest(form) {
        const url = `${this.baseURL}/batchRequest`;
        return this.postRequest(url, form);
    }

    createTagRequest(form) {
        const url = `${this.baseURL}/createTagRequest`;
        return this.postRequest(url, form);
    }

    getTagRequest() {
        const url = `${this.baseURL}/getTagRequest`;
        return this.postRequest(url);
    }

    tagActionRequest(form) {
        const url = `${this.baseURL}/tagActionRequest`;
        return this.postRequest(url, form);
    }

    convActionRequest(form) {
        const url = `${this.baseURL}/convActionRequest`;
        return this.postRequest(url, form);
    }

    msgActionRequest(form) {
        const url = `${this.baseURL}/msgActionRequest`;
        return this.postRequest(url, form, false, false);
    }

    sendMsgRequest(form, withProgress = true, withNotify = true) {
        const url = `${this.baseURL}/sendMsgRequest`;
        return this.postRequest(url, form, withProgress, withNotify);
    }

    getSignaturesRequest() {
        const url = `${this.baseURL}/getSignaturesRequest`;
        return this.postRequest(url, null);
    }

    getInfoRequest(form) {
        const url = `${this.baseURL}/getInfoRequest`;
        return this.postRequest(url, form);
    }

    getShareInfoRequest(form) {
        const url = `${this.baseURL}/getShareInfoRequest`
        return this.postRequest(url, form)
    }

    createMountpointRequest(form) {
        const url = `${this.baseURL}/createMountpointRequest `;
        return this.postRequest(url, form, false, false);
    }

    saveDraftRequest(form, withNotify = true) {
        const url = `${this.baseURL}/saveDraftRequest`;
        return this.postRequest(url, form, false, withNotify);
    }

    setMailboxMetadataRequest(form) {
        const url = `${this.baseURL}/setMailboxMetadataRequest`;
        return this.postRequest(url, form);
    }

    sendDeliveryReportRequest(form) {
        const url = `${this.baseURL}/sendDeliveryReportRequest`;
        return this.postRequest(url, form);
    }

    discoverRightsRequest(form) {
        const url = `${this.baseURL}/discoverRightsRequest`;
        return this.postRequest(url, form);
    }

    createSearchFolderRequest(form) {
        const url = `${this.baseURL}/createSearchFolderRequest`;
        return this.postRequest(url, form);
    }

    modifySearchFolderRequest(form) {
        const url = `${this.baseURL}/modifySearchFolderRequest`;
        return this.postRequest(url, form);
    }
    getMailboxMetadataRequest(form) {
        const url = `${this.baseURL}/getMailboxMetadataRequest`;
        return this.postRequest(url, form);
    }

    modifyPrefsRequest(form) {
        const url = `${this.baseURL}/modifyPrefsRequest`;
        return this.postRequest(url, form);
    }

    getDistributionListMembersRequest(form) {
        const url = `${this.baseURL}/getDistributionListMembersRequest`;
        return this.postRequest(url, form);
    }
    bounceMsgRequest(form) {
        const url = `${this.baseURL}/bounceMsgRequest`;
        return this.postRequest(url, form);
    }
    noOpRequest(form) {
        const url = `${this.baseURL}/noOpRequest`;
        return this.postRequest(url, form, false, false);
    }

    rankingActionRequest(form) {
        const url = `${this.baseURL}/rankingActionRequest`;
        return this.postRequest(url, form, false, false);
    }

    checkSpellingRequest(form) {
        const url = `${this.baseURL}/checkSpellingRequest`;
        return this.postRequest(url, form, false, false);
    }

    createAppointmentCalendarRequest(form) {
        const url = `${this.baseURL}/createAppointmentCalendarRequest`;
        return this.postRequest(url, form);
    }

    modifyAppointmentRequest(form) {
        const url = `${this.baseURL}/modifyAppointmentRequest`;
        return this.postRequest(url, form);
    }

    searchCalendarResourcesRequest(form) {
        const url = `${this.baseURL}/searchCalendarResourcesRequest`;
        return this.postRequest(url, form);
    }

    getFreeBusyRequest(form) {
        const url = `${this.baseURL}/getFreeBusyRequest`;
        return this.postRequest(url, form);
    }

    getWorkingHoursRequest(form) {
        const url = `${this.baseURL}/getWorkingHoursRequest`;
        return this.postRequest(url, form);
    }

    /**
     * API hủy cuộc hẹn (Cho vào thùng rác)
     * @param {*} form
     */
    cancelAppointmentRequest(form) {
        const url = `${this.baseURL}/cancelAppointmentRequest`;
        return this.postRequest(url, form);
    }

    checkRightsRequest(form) {
        const url = `${this.baseURL}/checkRightsRequest`;
        return this.postRequest(url, form);
    }

    /**
     * API tác động vào Item
     * @param {*} form
     */
    itemActionRequest(form) {
        const url = `${this.baseURL}/itemActionRequest`;
        return this.postRequest(url, form, true, false);
    }

    createAppointmentExceptionRequest(form) {
        const url = `${this.baseURL}/createAppointmentExceptionRequest`;
        return this.postRequest(url, form);
    }

    sendInviteReplyRequest(form) {
        const url = `${this.baseURL}/sendInviteReplyRequest`;
        return this.postRequest(url, form);
    }

    getAppointmentRequest(form) {
        const url = `${this.baseURL}/getAppointmentRequest`;
        return this.postRequest(url, form);
    }

    counterAppointmentRequest(form) {
        const url = `${this.baseURL}/counterAppointmentRequest`;
        return this.postRequest(url, form);
    }

    removeAttachmentsRequest(form) {
        const url = `${this.baseURL}/removeAttachmentsRequest`;
        return this.postRequest(url, form);
    }

    snoozeCalendarItemAlarmRequest(form) {
        const url = `${this.baseURL}/snoozeCalendarItemAlarmRequest`;
        return this.postRequest(url, form);
    }

    dismissCalendarItemAlarmRequest(form) {
        const url = `${this.baseURL}/dismissCalendarItemAlarmRequest`;
        return this.postRequest(url, form);
    }

    createCalendarDataSourceRequest(formXML) {
        const url = `${this.baseURL}/createDataSourceRequest`;
        return this.postRequest(url, { xml: formXML }, true, false)
    }

    /**
     * API chuyến tiếp cuộc hẹn
     * @param {*} form
     */
    forwardAppointmentRequest(form) {
        const url = `${this.baseURL}/forwardAppointmentRequest`;
        return this.postRequest(url, form);
    }
    importDataRequest(form) {
        const url = `${this.baseURL}/importDataRequest`;
        return this.postRequest(url, form);
    }
    getImportStatusRequest(form) {
        const url = `${this.baseURL}/getImportStatusRequest`;
        return this.postRequest(url, form);
    }
    autoCompleteGalRequest(form) {
        const url = `${this.baseURL}/autoCompleteGalRequest`;
        return this.postRequest(url, form);
    }
    /**
     * End the current session, removing it from all caches.
     * @param {*} form
     * @returns
     */
    endSessionRequest(form) {
        const url = `${this.baseURL}/endSessionRequest`;
        return this.postRequest(url, form, false, false);
    }

    /**
     * getPrefsRequest
     * @param {*} formData
     */
    getPrefsRequest(formData) {
        const url = `${this.baseURL}/getPrefsRequest`;
        return this.postRequest(url, formData, false, false);
    }
}
export default new ZimbraMailService();
