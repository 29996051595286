import {
    EMAIL_ACTION,
    EMAIL_FLAG,
    ADDRESS_TYPE,
    EMAIL_CONTENT_TYPE_FULLNAME,
    SIGNATURE_STYLE,
    REPLY_TYPE,
    EMAIL_CONTENT_TYPE,
    CAL_OPERATOR_REPLY_INV
} from "@/utils/constants";
import ContactService from "@/services/contacts/ContactService";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import AjxStringUtil from "@/utils/zimbra-lib/AjxStringUtil";
import { mapGetters } from "vuex";
import ZmComposeView from "@/utils/zimbra-lib/ZmComposeView";
import _ from "lodash";
import color from "@/components/modules/layouts/listColor";
import ZmMimeTable from "@/utils/zimbra-lib/ZmMimeTable";
import i18n from "@/plugins/i18n";
import {
    SIDEBAR_NODE,
    FOLDER_DEFAULT,
    FOLDER_ORDER,
    FOLDER_RENAME,
    FROM_ADDRESS_TYPE,
    ZmContact
} from "@/utils/constants";
import CONFIG from "@/config/config";
global.jQuery = require("jquery");
let $ = global.jQuery;
window.$ = $;

export default {
    name: "EmailUtils",
    data() {
        return {
            dataTag: [],
            dataInBox: [],
            dataExtend: [],
            defaultProps: {
                children: "folder",
                label: "name",
            },
            listFolderIgnore: [16, 10, 14, 7, 13, 15],
            dataFolderArchive: {},
            finishedDownloaData: false,
            saveDraftComopseNewMail: false
        };
    },
    computed: {
        ...mapGetters([
            "preference",
            "fromAddresses",
            "listTag",
            "dataSidebarInBox",
            "folderSearches",
            "dataSidebarExtend",
            "activeFolderExtra",
            "tags",
            "signatures",
            "identities",
            "defaultIdentityId",
        ]),
        externalAccountMap() {
            return this.fromAddresses.filter(x => x.type == FROM_ADDRESS_TYPE.MAP)
        },
        externalAccountPop() {
            return this.fromAddresses.filter(x => x.type == FROM_ADDRESS_TYPE.POP)
        }
    },
    methods: {
        async handleReplaceContactGroup(groups, contacts) {
            if (groups && groups.length) {
                await ContactService.getContactsRequest(groups).then(async resp => {
                    let _listContactGroup = await CommonUtils.methods.getResponseBody(resp)["GetContactsResponse"]["cn"]
                    _listContactGroup = Array.isArray(_listContactGroup) ? _listContactGroup : [_listContactGroup]
                    if (_listContactGroup.length && contacts.length) {
                        for (let x = 0; x < _listContactGroup.length; x++) {
                            let _dataTemp = _listContactGroup[x]
                            for (let y = 0; y < contacts.length; y++) {
                                if (_dataTemp.id === contacts[y].id) {
                                    const _attrInfo = ["namePrefix", "firstName", "middleName", "maidenName", "lastName", "nameSuffix", "nickname", "jobTitle", "department", "company"]
                                    contacts[y].a.forEach(attr => {
                                        if (_attrInfo.indexOf(attr.n) > -1) {
                                            _dataTemp[attr.n] = attr.content
                                        }
                                    })
                                    contacts[y] = _dataTemp
                                }
                            }
                        }
                    }
                }).catch(err => {
                    console.log(err);
                })
            }
            contacts = contacts.map(ele => {
                if (ele.m) {
                    let _mTemp = Array.isArray(ele.m) ? ele.m : [ele.m];
                    _mTemp = _mTemp.filter(attr => attr.type === ZmContact.GROUP_INLINE_REF || attr.cn)
                    ele["m"] = _mTemp
                }
                return ele
            })
            return contacts
        },
        async initDataForEditDraft(data, msg) {
            if (!data && !msg) {
                return {};
            }
            let msgResponse = {};
            if (!msg) {
                const formData = {
                    id: `${data.id}`
                };
                const msgRequest = await ZimbraMailService.getMsgRequest(formData);
                msgResponse = CommonUtils.methods.getResponseBody(msgRequest)[
                    "GetMsgResponse"
                ];
            } else {
                data = msg;
            }
            const { su, e, id, mp } = msgResponse.m || msg;
            const address = Array.isArray(e) ? e : e ? [e] : [];
            const fromAddress = this.getEmailAddrers(address, ADDRESS_TYPE.FROM, true);
            const cc = this.getEmailAddrers(address, ADDRESS_TYPE.CC);
            const bcc = this.getEmailAddrers(address, ADDRESS_TYPE.BCC);
            const part = CommonUtils.methods.getBodyPart(mp);
            const allPart = {};
            CommonUtils.methods.getBodyAllPart(mp, allPart);
            let listFileAttachment = [];
            if (Array.isArray(mp?.mp)) {
                listFileAttachment = mp?.mp.filter(e => e.cd == "attachment")
                    .map((e, idx) => ({
                        ...e,
                        mid: id,
                        part: idx + 2,
                        name: this.getFileNameWithSize(e),
                        forward: true,
                        aid: Math.random()
                            .toString(36)
                            .substring(2)
                    }));
            }
            const requestReadReceipt = address.filter(
                e => e.t == EMAIL_FLAG.NOTIFICATION_SENT
            );
            const dataEdit = {
                id: id,
                su: su,
                modelData: {
                    subject: su,
                    fromAddress: fromAddress,
                    to: this.getEmailAddrers(address, ADDRESS_TYPE.TO),
                    cc: cc,
                    bcc: bcc
                },
                // dialogFormVisible = data.dialogFormVisible || false,
                showCc: !(cc && cc.length > 0),
                showBcc: !(bcc && bcc.length > 0),
                attachInline: this.getAttachInline(allPart),
                priority: CommonUtils.methods.checkFlagsContainKey(
                    data.f,
                    EMAIL_FLAG.URGENT
                )
                    ? EMAIL_FLAG.URGENT
                    : CommonUtils.methods.checkFlagsContainKey(
                        data.f,
                        EMAIL_FLAG.LOW_PRIORITY
                    )
                        ? EMAIL_FLAG.LOW_PRIORITY
                        : EMAIL_FLAG.PRIORITY,
                signatureActive: data.signatureActive || null,
                requestReadReceipt:
                    requestReadReceipt && requestReadReceipt.length > 0,
                contentType:
                    part.ct == EMAIL_CONTENT_TYPE_FULLNAME.PLAIN
                        ? EMAIL_CONTENT_TYPE.PLAIN
                        : EMAIL_CONTENT_TYPE.HTML,
                // emailAddress: data.emailAddress,
                plainText:
                    part.ct == EMAIL_CONTENT_TYPE_FULLNAME.PLAIN ? part.content : "",
                listFileAttachment: listFileAttachment || [],
                editorModel:
                    part.ct == EMAIL_CONTENT_TYPE_FULLNAME.HTML ? this.handleFixImageSrc(part.content, allPart, id) : "",
                draftId: CommonUtils.methods.checkFlagsContainKey(
                    data.f,
                    EMAIL_FLAG.DRAFT
                )
                    ? id
                    : null
            };
            return dataEdit;
        },
        async initDataForReplayForward(data, type, mailSelect) {
            let { su, e, id } = data;
            const address = Array.isArray(e) ? e : e ? [e] : [];
            let to = [];
            let cc = [];
            let bcc = [];

            // to = type == EMAIL_ACTION.FORWARD ? [] : this.getEmailAddrers(address, CommonUtils.methods.checkFlagsContainKey(f, EMAIL_FLAG.SENT_BY_ME) ? ADDRESS_TYPE.TO : ADDRESS_TYPE.FROM);
            // if (CommonUtils.methods.checkFlagsContainKey(f, EMAIL_FLAG.SENT_BY_ME)) {
            //     to = type == EMAIL_ACTION.FORWARD ? [] : this.getEmailAddrers(address, ADDRESS_TYPE.TO);
            //     cc = type == EMAIL_ACTION.REPLY_ALL
            //         ? this.getEmailAddrers(address, ADDRESS_TYPE.CC)
            //         : [];
            //     bcc = type == EMAIL_ACTION.REPLY_ALL
            //         ? this.getEmailAddrers(address, ADDRESS_TYPE.BCC)
            //         : [];
            // } else {
            // Khi reply 1 email bất kỳ, người gửi cũ thành người nhận mới, trường hợp cc loại trừ chính mình
            // to = type == EMAIL_ACTION.FORWARD ? [] :
            //  (type == EMAIL_ACTION.EDIT_AS_NEW ? this.getEmailAddrers(address, ADDRESS_TYPE.TO, true) :
            //   this.getEmailAddrers(address, ADDRESS_TYPE.FROM));
            if (type == EMAIL_ACTION.FORWARD) {
                to = [];
            } else if (type == EMAIL_ACTION.EDIT_AS_NEW || data.l != FOLDER_DEFAULT.INBOX) {
                to = this.getEmailAddrers(address, ADDRESS_TYPE.TO, true);
            }
            else if (type == EMAIL_ACTION.REPLY_ALL) {
                let from_ = this.getEmailAddrers(address, ADDRESS_TYPE.FROM, true);
                let to_ = this.getEmailAddrers(address, ADDRESS_TYPE.TO, true);

                const fromAdd = this.fromAddresses.find(e => e.uuid === this.preference.defaultFromAddress);
                if (fromAdd) {
                    to_ = to_.filter(t => t.a !== fromAdd.a);
                    to = [...from_, ...to_]
                }
                else {
                    to = [...from_]
                }
            }
            else {
                const rellyTo = this.getEmailAddrers(address, ADDRESS_TYPE.REPLY, true);
                to = rellyTo.length > 0 ? rellyTo : this.getEmailAddrers(address, ADDRESS_TYPE.FROM, true);
            }
            cc = type == EMAIL_ACTION.REPLY_ALL
                ? [
                    // ...this.getEmailAddrers(address, ADDRESS_TYPE.TO),
                    ...this.getEmailAddrers(address, ADDRESS_TYPE.CC),
                ]
                : [];
            // }
            const allPart = {};
            CommonUtils.methods.getBodyAllPart(data.mp, allPart);
            let _body = "";
            let _header = "";
            const part = CommonUtils.methods.getBodyPart(data.mp);
            if (part) {
                if (part?.ct == ZmMimeTable.TEXT_PLAIN) {
                    _body = part.content;
                    _header = "\n\n" + this.getDividerWithoutHeaders(data) + "\n";
                } else {
                    const div = document.createElement("div");
                    $(div).html(part.content);
                    _body = $(div).html();
                    _header = this.getHeader(data);
                }
            }
            const signaturePre = this.getSignature(SIGNATURE_STYLE.OUTLOOK);
            const divider = this.getDivider();
            const header = `<div data-marker="${this.getDataMarker(ZmComposeView.BC_HEADERS)}"${_header}</div>`;
            const body = `<div style="white-space: pre-line;" data-marker="${this.getDataMarker(ZmComposeView.BC_QUOTED_TEXT)}">${_body}</div>`;
            const signaturePost = this.getSignature(SIGNATURE_STYLE.INTERNET);

            // TH forward thì bổ sung file đính kèm
            let listFileAttachment = [];
            if (Array.isArray(data.mp?.mp)) {
                listFileAttachment = data.mp.mp
                    .filter(e => e.cd == "attachment")
                    .map((e, idx) => ({
                        ...e,
                        part: idx + 2,
                        name: this.getFileNameWithSize(e),
                        forward: true,
                        aid: Math.random()
                            .toString(36)
                            .substring(2)
                    }));
            }
            const origid = type === EMAIL_ACTION.EDIT_AS_NEW ? null : `${id}`;

            const editorModel = type == EMAIL_ACTION.EDIT_AS_NEW ? body : [
                AjxStringUtil.CRLF2_HTML,
                signaturePre,
                divider,
                header,
                body,
                signaturePost
            ].join("");

            const divplainText = document.createElement("div");
            $(divplainText).html(editorModel);
            const plainText = $(divplainText).text();
            let contentType = EMAIL_CONTENT_TYPE.HTML;
            if (this.preference.zimbraPrefForwardReplyInOriginalFormat) {
                contentType = part?.ct == ZmMimeTable.TEXT_PLAIN ? EMAIL_CONTENT_TYPE.PLAIN : EMAIL_CONTENT_TYPE.HTML;
            }
            const dataEdit = {
                cid: mailSelect.id,
                id: id,
                modelData: {
                    subject: this.getNewSubject(su, type),
                    from: this.preference.defaultFromAddress,
                    to: to,
                    cc: cc,
                    bcc: bcc,
                    origid: origid,
                    rt: type == EMAIL_ACTION.REPLY ? REPLY_TYPE.REPLY : REPLY_TYPE.FORWARD
                },
                // dialogFormVisible = data.dialogFormVisible || false,
                showCc: !(cc && cc.length > 0),
                showBcc: !(bcc && bcc.length > 0),
                contentType: contentType,
                // emailAddress: data.emailAddress,
                // plainText: part.ct == EMAIL_CONTENT_TYPE_FULLNAME.PLAIN ? part.content : "",
                listFileAttachment: listFileAttachment || [],
                plainText: plainText,
                editorModel: this.handleFixImageSrc(editorModel, allPart, id), //part.content,
                actionType: type,
                allPart: allPart,
                signatureActive: this.getSignatureForwardReply(),
                isInitManual: type == EMAIL_ACTION.EDIT_AS_NEW
            };
            // TH forward conversation bổ sung file đính kèm
            if (type == EMAIL_ACTION.FORWARD_CONVERSATION) {
                const fileForward = await this.getFileForward(mailSelect);
                listFileAttachment = fileForward.listFileDraft
                    .filter(e => e.cd == "attachment")
                    .map((e, idx) => ({
                        ...e,
                        part: idx + 2,
                        name: this.getFileNameWithSize(e),
                        forward: true,
                        aid: Math.random()
                            .toString(36)
                            .substring(2),
                        mid: fileForward.draftId
                    }));
                const dataForwardConversation = {
                    id: id,
                    modelData: {
                        subject: this.getNewSubject(su, type),
                    },
                    listFileAttachment: listFileAttachment || [],
                    actionType: type,
                    draftId: fileForward.draftId
                };
                return dataForwardConversation;
            }
            return dataEdit;
        },
        async getFileForward(mail) {
            let m = Array.isArray(mail.m) ? mail.m : [mail.m];
            // const listMsgId = m.filter(x => x.f == undefined).map(x => ({ id: x.id.toString(), type: "mail" }));
            const listMsgId = m.map(x => ({ id: x.id.toString(), type: "mail" }));
            const formData = {
                m: {
                    attachPart: listMsgId,
                    mp: [
                        {
                            "ct": "text/plain",
                            "content": ""
                        }
                    ]
                }
            }
            const saveDraftRequest = await ZimbraMailService.saveDraftRequest(formData);
            const saveDraftResponse =
                this.getResponseBody(saveDraftRequest)["SaveDraftResponse"];
            if (saveDraftResponse?.m?.id) {
                return { listFileDraft: saveDraftResponse.m.mp.mp, draftId: saveDraftResponse.m.id }
            }
            return null;
        },
        getEmailAddrers(address, type, allowDuplicate, hasQuote = true) {
            if (!address || address.length == 0) {
                return [];
            }
            const fromAdd = this.fromAddresses.find(e => e.uuid === this.preference.defaultFromAddress);
            return fromAdd ? address
                .filter((x) => x.t == type && (x.a != fromAdd.a || allowDuplicate))
                .reduce((arr = [], item) => {
                    arr.push({
                        ...item,
                        text: (`${hasQuote ? '"' : ''}${this.getDisplayName(item)}${hasQuote ? '"' : ''} <${item.a}>`)
                    });
                    return arr;
                }, []) : [];
        },
        getHeader(mail) {
            if (!mail) {
                return "";
            }
            const { su, e, d } = mail;
            const address = Array.isArray(e) ? e : e ? [e] : [];

            // const header = `${i18n.t('zimbra.zhMsg.FILT_COND_HEADER_from')}`;
            // from
            const from = this.getAddressName(
                address,
                ADDRESS_TYPE.FROM,
                i18n.t("zimbra.zhMsg.FILT_COND_HEADER_from")
            );

            // to
            const to = this.getAddressName(
                address,
                ADDRESS_TYPE.TO,
                i18n.t("zimbra.zhMsg.FILT_COND_HEADER_to")
            );

            // cc
            const cc = this.getAddressName(
                address,
                ADDRESS_TYPE.CC,
                i18n.t("zimbra.zhMsg.cc")
            );

            // bcc
            const bcc = this.getAddressName(
                address,
                ADDRESS_TYPE.BCC,
                i18n.t("zimbra.zhMsg.bcc")
            );

            // sent
            const sent = `<b> ${i18n.t(
                "zimbra.zhMsg.FOLDER_LABEL_5"
            )}: </b>${CommonUtils.methods.formatDateTime(
                d,
                "HH:mm DD/MM/YYYY",
                false
            )}<br>`;

            // subject
            const subject = `<b> ${i18n.t(
                "zimbra.zhMsg.FILT_COND_HEADER_subject"
            )}: </b>${su}<br>`;

            return [from, to, cc, bcc, sent, subject].join("");
        },
        getDivider() {
            const preface = `<hr id="${AjxStringUtil.HTML_SEP_ID
                }" data-marker="${this.getDataMarker(ZmComposeView.BC_DIVIDER)}">`;
            return preface;
        },
        getDividerWithoutHeaders(mail, contentType) {
            const { e, d } = mail;
            const address = Array.isArray(e) ? e : e ? [e] : [];
            const form = this.getEmailAddrers(address, ADDRESS_TYPE.FROM, true, false);
            const replyPrefix = i18n.t('zimbra.zmMsg.replyPrefix', [this.formatDateTime(d, "MMMM DD YYYY", false), this.formatDateTime(d, "h:mm A"), form.map(e => e.text).join(", ")]);
            let preface = "";
            if (contentType == EMAIL_CONTENT_TYPE.PLAIN) {
                preface = replyPrefix;
            } else {
                preface = `<span id="${AjxStringUtil.HTML_SEP_ID}" data-marker="${this.getDataMarker(ZmComposeView.BC_DIVIDER)}">${_.escape(replyPrefix)}<span>`;
            }
            return preface;
        },
        getBody(mail) {
            const part = CommonUtils.methods.getBodyPart(mail.mp);
            if (part.ct == ZmMimeTable.TEXT_PLAIN) {
                return _.escape(part.content)
            }
            const div = document.createElement("div");
            $(div).html(part.content);
            return $(div).html();
        },
        getSignature(style, idSignatureActive) {
            const _identity = this.identities.find(e => e.id === this.defaultIdentityId);
            if (_identity) {
                if (!_identity.identity.zimbraPrefMailSignatureStyle ||
                    _identity.identity.zimbraPrefMailSignatureStyle !== style) {
                    return "";
                }
                const signatureId = idSignatureActive ?? _identity.identity.zimbraPrefForwardReplySignatureId;
                const signature = this.signatures?.find(
                    e => e.id == signatureId
                );
                if (!signature) {
                    return "";
                }
                if (signature.cid) {
                    this.saveDraftComopseNewMail = true;
                }
                // const markerNoSpace = ZmComposeView.BC_TEXT_MARKER[style == SIGNATURE_STYLE.INTERNET ? ZmComposeView.BC_SIG_POST : ZmComposeView.BC_SIG_PRE];
                const marker = this.getDataMarker(style == SIGNATURE_STYLE.INTERNET ? ZmComposeView.BC_SIG_POST : ZmComposeView.BC_SIG_PRE);
                if (style == SIGNATURE_STYLE.INTERNET) {
                    return `<div data-marker="${marker}">-- <br>
                                <div id="${signature?.id}">${(signature?.content?.content || "")}</div>
                            </div>`;
                } else {
                    return `<div id="${signature?.id}" data-marker="${marker}">${(signature?.content?.content.replaceAll('\n', '<br />') || "")}</div>`;
                }
            }
            return "";
        },
        getSignatureForwardReply() {
            const _identity = this.identities.find(e => e.id === this.defaultIdentityId);
            if (_identity) {
                const signatureId = _identity.identity.zimbraPrefForwardReplySignatureId;
                const signature = this.signatures?.find(
                    e => e.id == signatureId
                );
                return signature;
            }
            return null;
        },
        getAddressName(address, type, title) {
            const target = this.getEmailAddrers(address, type, true);
            if (target && target.length > 0) {
                const text = target.map(e => e.text).join(", ");
                return `<b> ${title}: </b>${_.escape(text)}<br>`;
            } else {
                return "";
            }
        },
        async checkRequestReadReceipt(mail, mailSendReadReceipts) {
            const { e, id, f } = mail;
            const address = Array.isArray(e) ? e : [e || {}];
            const notify = address?.filter(
                e => e.t === EMAIL_FLAG.NOTIFICATION_SENT
                    && !CommonUtils.methods.checkFlagsContainKey(f, EMAIL_FLAG.DRAFT)
                    && !CommonUtils.methods.checkFlagsContainKey(f, EMAIL_FLAG.SENT_BY_ME)
            );
            if (
                notify &&
                notify.length > 0 &&
                !CommonUtils.methods.checkFlagsContainKey(
                    f,
                    EMAIL_FLAG.NOTIFICATION_SENT
                )
            ) {
                if (mailSendReadReceipts.content === "prompt") {
                    await this.$confirm(
                        i18n.t("zimbra.zmMsg.readReceiptSend"),
                        i18n.t("zimbra.ztMsg.alertFeedReplyTitle"),
                        {
                            dangerouslyUseHTMLString: true,
                            cancelButtonText: i18n.t("zimbra.zhMsg.no"),
                            confirmButtonText: i18n.t("zimbra.zhMsg.yes"),
                            type: "warning"
                        }
                    )
                        .then(async () => {
                            // SendDeliveryReportRequest
                            const report = await ZimbraMailService.sendDeliveryReportRequest(
                                { mid: `${id}` }
                            );
                            if (report) {
                                this.$notify({
                                    title: i18n.t("zimbra.zmMsg.dataSourceTestSuccess"),
                                    message: i18n.t(
                                        "zimbraNokey.zimbra_mailbox_readReceiptSent"
                                    ),
                                    type: "success"
                                });
                            }
                        })
                        .catch(() => { });
                    await this.updateFlagMsg(mail, "n");
                } else if (mailSendReadReceipts.content === "always") {
                    const report = await ZimbraMailService.sendDeliveryReportRequest(
                        { mid: `${id}` }
                    );
                    if (report) {
                        this.$notify({
                            title: i18n.t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: i18n.t("zimbraNokey.zimbra_mailbox_readReceiptSent"),
                            type: "success"
                        });
                    }
                } else {
                    return;
                }
            }
        },
        async updateFlagMsg(data, f) {
            const formData = {
                f: `${f}`,
                id: `${data.id}`,
                op: "update",
            };
            await ZimbraMailService.msgActionRequest(
                formData
            );
            // const msgResponse =
            //     this.getResponseBody(msgRequest)["MsgActionResponse"];
        },
        getDataMarker(type) {
            return `__${type}__`;
        },
        isHasTag(email) {
            return !_.isNil(email.t);
        },
        getColorTag(email) {
            if (!_.isNil(email.t)) {
                const tagAssigned = Array.isArray(this.listTag) ? this.listTag.find(e => email.t.toString() == e.id.toString()) : this.listTag;
                return this.getColor(tagAssigned);
            }
            return '#fff';
        },
        getColor(item) {
            let hex = "#FF9900"; // giá trị default
            if (item && item.color) {
                hex = color.find(e => e.colorNumeric === item.color)?.value;
            } else if (item && item.rgb) {
                hex = item.rgb;
            }
            return hex;
        },
        getListTag(email) {
            if (email && !_.isNil(email.t)) {
                try {
                    if (Number.isInteger(email.t)) {
                        // TH co 1 tag
                        const tagAssigned = Array.isArray(this.listTag) ? this.listTag.find(e => email.t == e.id) : this.listTag;
                        // const tagAssigned = this.tags.find(e => email.t == e.id);
                        return [tagAssigned].map(e => ({ ...e, colorHex: this.getColor(e) }));
                    }
                    const tagIds = email.t.split(",").map(e => parseInt(e, 10));
                    const tags = Array.isArray(this.listTag) ? this.listTag : [this.listTag || {}];
                    let tagAssigned = [];
                    if (email.tn) {
                        const listTagName = this.replaceAll(email.tn, "\\", "\u200B").split(",");
                        for (let i = 0; i < listTagName.length; i++) {
                            let tag = tags.find(t => t.id == tagIds[tagIds.length - i - 1]);
                            if (_.isNil(tag)) {
                                tag = {
                                    id: tagIds[i],
                                    name: this.$t("zimbra.zmMsg.tagNotLocal", [listTagName[i].replace("\u200B", ",")]),
                                }
                            }
                            tag.colorHex = this.getColor(tag);
                            tagAssigned.push(tag)
                        }
                    }
                    tagAssigned = tags.filter(e => tagIds.includes(e.id))
                        .map(e => ({ ...e, colorHex: this.getColor(e) }));
                    return tagAssigned;
                } catch (error) {
                    return [];
                }
            }
            return [];
        },
        replaceAll(str, match, replacement) {
            return str.replace(new RegExp(this.escapeRegExp(match), 'g'), () => replacement);
        },
        escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        },
        isMultiTag(email) {
            const listTag = !_.isNil(email.t) ? email.t.toString().split(',') : null;
            return !_.isNil(listTag) && listTag.length > 1;
        },
        async reloadTag(idTag) {
            const searchRequest = await ZimbraMailService.getTagRequest();
            const data = this.getResponseBody(searchRequest)["GetTagResponse"];
            if (data && data.tag) {
                let dataChange = [];
                if (idTag) {
                    dataChange.push(data.tag.find(x => x.id == idTag));
                } else {
                    dataChange = data.tag;
                }
                dataChange.forEach(item => {
                    let tagChange = this.listTag.find(x => x.id == item.id);
                    tagChange.u = item.u || 0;
                    tagChange.n = item.n || 0;
                    this.deleteItemToData(tagChange, this.dataSidebarExtend);
                    this.addItemToData(tagChange, this.dataSidebarExtend);
                })
            }
        },
        async handledAssginOrRemoveTag(item, isAssginTag) {
            this.visablePopover = false;
            const idMailConv = this.listSelectedMail.filter(e => !e.cid).map(x => x.id).join(',');
            const idMailMsg = this.listSelectedMail.filter(e => e.cid).map(x => x.id).join(',');
            const op = isAssginTag ? "tag" : "!tag";

            const msg = isAssginTag
                ? i18n.t("zimbraNokey.zimbra_mailbox_assignTagSuccess", [this.listSelectedMail.length, item.name])
                : i18n.t("zimbraNokey.zimbra_mailbox_removeTagSuccess", [this.listSelectedMail.length, item.name]);
            let convAction = null;
            let msgAction = null;
            if (idMailConv?.length > 0) {
                const formData = {
                    id: `${idMailConv}`,
                    op: `${op}`,
                    tn: _.escape(item.name),
                };
                convAction = await ZimbraMailService.convActionRequest(
                    formData
                );
            }
            if (idMailMsg?.length > 0) {
                const formData = {
                    id: `${idMailMsg}`,
                    op: `${op}`,
                    tn: _.escape(item.name),
                };
                msgAction = await ZimbraMailService.msgActionRequest(
                    formData
                );
            }
            const responseConv = convAction && this.getResponseBody(convAction)["ConvActionResponse"];
            const responseMsg = msgAction && this.getResponseBody(msgAction)["MsgActionResponse"];
            if (responseConv?.action || responseMsg?.action) {
                this.$notify({
                    title: i18n.t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: `${msg}`,
                    type: "success",
                });
                this.reloadTag(item.id);
                // cập nhật tag của thư trên danh sách
                this.$root.$emit("updateTagList", {
                    listSelectedMail: this.listSelectedMail,
                    tag: item,
                    isAssginTag: isAssginTag
                });
                // this.listSelectedMail.forEach(x => {
                //     this.updateTag(item, x, isAssginTag);
                // });
            }
        },
        updateTag(item, beforeMail, isAssginTag) {
            const mailUpdate = { ...beforeMail, tag: item }
            if (isAssginTag) {
                this.$root.$emit("pushTagToMailOpen", mailUpdate);
            } else {
                this.$root.$emit("deleteTagToMailOpen", mailUpdate);
            }
            let mail = _.cloneDeep(beforeMail);
            mail.t = mail.t ? mail.t.toString() : "";
            let litsTag = mail.t != "" ? mail.t.split(',') : [];
            if (item) {
                if (isAssginTag) {
                    litsTag.push(item.id);
                } else {
                    litsTag = litsTag.filter(x => x != item.id.toString());
                }
                mail.t = litsTag.length != 0 ? litsTag.join(',') : undefined;
            } else {
                mail.t = undefined;
            }
            const idxItem = this.listEmailSearch.findIndex(e => e.id === mail.id);
            if (idxItem > -1) {
                const _emails = this.listEmailSearch;
                _emails.splice(idxItem, 1, mail)
                this.$store.commit("SET_LIST_EMAIL", _emails);
            }
            // update list tag tại mail select
            const idxItemSelect = this.listSelectedMail.findIndex(e => e.id === mail.id);
            if (idxItemSelect > -1) {
                const _emails = this.listSelectedMail;
                _emails.splice(idxItemSelect, 1, mail)
                this.$store.commit("SET_LIST_SELECTED_MAIL", _emails);
            }
        },
        async handledRemoveAllTag() {
            const idMailConv = this.listSelectedMail.filter(e => !e.cid).map(x => x.id);
            const idMailMsg = this.listSelectedMail.filter(e => e.cid).map(x => x.id);
            let convAction = null;
            let msgAction = null;
            if (idMailConv?.length > 0) {
                const formData = {
                    id: `${idMailConv.join(',')}`,
                    op: "update",
                    t: "empty"
                };
                convAction = await ZimbraMailService.convActionRequest(
                    formData
                );
            }
            if (idMailMsg?.length > 0) {
                const formData = {
                    id: `${idMailMsg.join(',')}`,
                    op: "update",
                    t: "empty",
                };
                msgAction = await ZimbraMailService.msgActionRequest(
                    formData
                );
            }
            if (msgAction) {
                this.$notify({
                    title: i18n.t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: i18n.t("zimbra.zmMsg.actionRemoveTags", [idMailMsg.length, this.$t("zimbra.zhMsg.ALT_APP_MAIL")]),
                    type: "success",
                });
            } else if (convAction) {
                this.$notify({
                    title: i18n.t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: i18n.t("zimbra.zmMsg.actionRemoveTags", [idMailConv.length, this.$t("zimbra.zhMsg.ALT_CONVERSATION")]),
                    type: "success",
                });
            }
            this.reloadTag(false);
            // this.listSelectedMail.forEach(x => {
            //     this.updateTag(false, x, false);
            // });
            // cập nhật tag của thư trên danh sách
            this.$root.$emit("updateTagList", {
                listSelectedMail: this.listSelectedMail,
                tag: null,
                isAssginTag: false
            });
        },
        lanchNewWindow(listmail) {
            if (!listmail || listmail.length === 0) {
                return;
            }
            const { origin } = window.location;
            const height = screen.height * 0.6;
            const width = screen.width * 0.8;
            const left = screen.width * 0.1;
            const top = screen.height * 0.1;
            const style = `width=${width},height=${height},left=${left},top=${top}`;
            listmail.forEach(x => {
                const m = x.isConv ? (Array.isArray(x.m) ? x.m[0] : x.m) : x;
                const id = m.id;
                const url = m.part ? `${origin}/launchNewWindow?id=${id}&part=${m.part}` : `${origin}/launchNewWindow?id=${id}`;
                window.open(url, '', style.toString());
            });
        },
        getNewSubject(subject, type) {
            let su = subject;
            const regex_fwd = /^(Fwd:)/i;
            const regex_re = /^(Re:)/i;
            if (type === EMAIL_ACTION.DECLINE_NEW_TIME) {
                su = `${i18n.t("zimbra.zhMsg.replyDecline")} - ${su}`;
            } else if (type != EMAIL_ACTION.EDIT_AS_NEW) {
                if (regex_fwd.test(su)) {
                    su = `${type == EMAIL_ACTION.FORWARD || type == EMAIL_ACTION.FORWARD_CONVERSATION ? 'Fwd:' : 'Re:'} ${su.substring(4, su.length)}`;
                } else if (regex_re.test(su)) {
                    su = `${type == EMAIL_ACTION.FORWARD || type == EMAIL_ACTION.FORWARD_CONVERSATION ? 'Fwd:' : 'Re:'} ${su.substring(3, su.length)}`;
                } else {
                    su = `${type == EMAIL_ACTION.FORWARD || type == EMAIL_ACTION.FORWARD_CONVERSATION ? 'Fwd:' : 'Re:'} ${su}`;
                }
            }
            return su;
        },
        showMailOriginalNewWindow(mail) {
            const height = screen.height * 0.6;
            const width = screen.width * 0.8;
            const left = screen.width * 0.1;
            const top = screen.height * 0.1;
            const style = `width=${width},height=${height},left=${left},top=${top}`;
            const id = !_.isNil(mail.m) ? Array.isArray(mail.m) ? mail.m[0].id : mail.m.id : mail.id;
            window.open(`${CONFIG.ENDPOINT.homeService}/~/?auth=co&view=text&id=${id}`, '', style.toString());
        },
        async getFolderArchiveApi() {
            const dataBody = {
                section: "zwc:archiveZimlet",
            };
            const searchRequest =
                await ZimbraMailService.getMailboxMetadataRequest(dataBody);
            const data =
                this.getResponseBody(searchRequest)[
                "GetMailboxMetadataResponse"
                ];
            if (data && data.meta && data.meta.a) {
                this.dataFolderArchive.id = data.meta.a[0].content;
                this.dataFolderArchive.hideDeleteButton = data.meta.a[2].content;
                this.dataFolderArchive.showSendAndArchive = data.meta.a[2].content;
            }
        },
        async getTagApi() {
            const searchRequest = await ZimbraMailService.getTagRequest();
            const data = this.getResponseBody(searchRequest)["GetTagResponse"];
            this.dataTag = []
            if (data && data.tag) {
                if (data.tag.length) {
                    data.tag.forEach((x) => {
                        x.l = -2;
                        x.u = x.u || 0;
                        x.rgb = x.rgb || "#3F4254";
                        x.type = SIDEBAR_NODE.TAG;
                        this.dataTag.push(x);
                    });
                } else {
                    data.tag.l = -2;
                    data.tag.u = data.tag.u || 0;
                    data.tag.type = SIDEBAR_NODE.TAG;
                    this.dataTag.push(data.tag);
                }
            }
            this.$store.commit("SET_TAGS", this.dataTag);
            this.$store.commit("SET_LIST_TAG", this.dataTag);

            await this.getFolderApi();
        },
        updateItemToData(itemUpdate, data) {
            for (let item in data) {
                if (data[item].id == itemUpdate.id) {
                    data[item] = itemUpdate;
                    return;
                } else {
                    if (data[item].folder) {
                        this.updateItemToData(itemUpdate, data[item].folder);
                    }
                }
            }
        },
        addItemToData(itemAdd, data) {
            ///// tạo absfolderPathI18n khi tạo thư mục lưu trữ mới
            if (!_.isNil(itemAdd.absFolderPath)) {
                itemAdd.absFolderPathI18n = this.pathI18n(itemAdd.absFolderPath);
            }
            for (let item in data) {
                if (data[item].id == itemAdd.l) {
                    if (!data[item].folder) {
                        this.$set(data[item], "folder", []);
                    }
                    data[item].folder.push(itemAdd);
                    if (itemAdd.type === SIDEBAR_NODE.TAG) {
                        this.$store.commit("SET_TAGS", data[item].folder);
                        this.$store.commit("SET_LIST_TAG", data[item].folder);
                    }
                    data[item].folder = this.reorderFolder(data[item].folder);
                    return;
                } else {
                    if (data[item].folder) {
                        this.addItemToData(itemAdd, data[item].folder);
                    }
                }
            }
        },
        deleteItemToData(itemDelete, data) {
            if (_.isNil(data)) {
                data = this.dataSidebarExtend || this.dataSidebarInBox;
            }
            // if (itemDelete.l == FOLDER_DEFAULT.ROOT && !itemDelete.isSearch) {
            //     const data = this.dataSidebarInBox.filter(
            //         (x) => x.id != itemDelete.id
            //     );
            //     return data;
            // }
            for (let item in data) {
                if (data[item].id == itemDelete.l) {
                    const arrFolder = data[item].folder.filter(
                        (x) => x.id != itemDelete.id
                    );
                    data[item].folder = arrFolder;
                    return false;
                } else {
                    if (data[item].folder) {
                        this.deleteItemToData(itemDelete, data[item].folder);
                    }
                }
            }
        },
        async getFolderApi() {
            const formData = {
                folder: `<view>1</view><depth>-1</depth><tr>1</tr><visible>1</visible><needGranteeName>1</needGranteeName>`,
            };
            const searchRequest = await ZimbraMailService.getFolderRequest(
                formData
            );
            const searchActionResponse =
                this.getResponseBody(searchRequest)["GetFolderResponse"];
            let data = searchActionResponse.folder.folder;

            this.$store.commit(
                "SET_FOLDER_SEARCH",
                this.reorderTree(
                    [
                        {
                            id: 1,
                            name: i18n.t("zimbra.zhMsg.optionsSearches"),
                            search: this.wrapFolderSearch(
                                searchActionResponse.folder.search
                            ),
                            isRootSearchFolder: true,
                        },
                    ],
                    "search",
                    ["name"],
                    ["asc"]
                )
            );
            if (searchActionResponse.folder.link) {
                let dataLink = searchActionResponse.folder.link;
                this.tickLinkFolder(dataLink);
                data = data.concat(dataLink);
                this.$store.commit("SET_FOLDER_CONTACTS_SHARE", this.getFolderContactShare(dataLink));
            }
            data = data.filter(x => !_.isNil(x.absFolderPath));
            data = data.filter(x => x.view == "message" || _.isNil(x.view));
            data = data.filter(x => (!x.isSearch || (x.isSearch && x.types == "conversation")));
            this.objectToArr(data);
            this.divideData(data);
        },
        tickLinkFolder(data, parent) {
            if (data.length == undefined) {
                const arrLink = [];
                arrLink.push(data);
                data = arrLink;
            }
            for (let item in data) {
                if (data[item].l != FOLDER_DEFAULT.ROOT && !_.isNil(parent)) {
                    data[item].absFolderPath =
                        parent.absFolderPath + "/" + data[item].name;
                }
                data[item].isLink = true;
                if (data[item].folder) {
                    if (data[item].folder.length) {
                        this.tickLinkFolder(data[item].folder, data[item]);
                    } else {
                        const arrFolder = [];
                        arrFolder.push(data[item].folder);
                        data[item].folder = arrFolder;
                        this.tickLinkFolder(data[item].folder, data[item]);
                    }
                }
            }
        },
        divideData(data) {

            this.dataInBox = [];
            const tag = {
                name: "Tag",
                id: FOLDER_DEFAULT.TAG,
                folder: this.dataTag,
                l: FOLDER_DEFAULT.EXTEND
            };
            const stars = {
                name: "Stars",
                id: FOLDER_DEFAULT.STARS,
                absFolderPath: "/Flagged",
            };
            this.dataExtend = [
                {
                    name: i18n.t("zimbraNokey.zimbra_sidebar_extend"),
                    id: FOLDER_DEFAULT.EXTEND,
                    folder: [],
                    order: 1,
                },
            ];
            const listFolderExternalMap = [];
            for (let item in data) {
                if (
                    this.listFolderIgnore.indexOf(data[item].id) == -1
                ) {
                    const emailDefaultMap = this.externalAccountMap.find(x => x.l == data[item].id)
                    if (emailDefaultMap) {
                        const folderTrashPrivate = data[item].folder.find(x => x.absFolderPath.endsWith("Trash"));
                        this.tickExternalFolder(data[item].folder, folderTrashPrivate, emailDefaultMap);
                        data[item].folder = data[item].folder.map(x =>
                        ({
                            ...x,
                            isInbox: x.absFolderPath.endsWith("Inbox"),
                            isJunk: x.absFolderPath.endsWith("Junk"),
                            isSent: x.absFolderPath.endsWith("Sent"),
                            isTrash: x.absFolderPath.endsWith("Trash"),
                            emailDefault: emailDefaultMap
                        }),
                        )
                        listFolderExternalMap.push({ ...data[item], isFolderMap: true });
                    } else {
                        if (this.externalAccountPop.findIndex(x => x.l == data[item].id) > -1) {
                            data[item].isFolderPop = true;
                        }
                        if (
                            data[item].id == FOLDER_DEFAULT.TRASH ||
                            data[item].id == FOLDER_DEFAULT.JUNK
                        ) {
                            this.dataExtend[0].folder.push(data[item]);
                        } else {
                            this.dataInBox.push(data[item]);
                        }
                    }
                }
            }
            this.dataInBox.push(stars);
            this.dataExtend[0].folder.push(tag);
            this.dataExtend[0].folder = this.dataExtend[0].folder.concat(listFolderExternalMap);
            let folderSearches = _.cloneDeep(this.folderSearches);
            this.objectToArr(folderSearches);
            this.dataExtend[0].folder.push(folderSearches[0]);
            const folderInBox = this.dataInBox.find(
                (x) => x.id == FOLDER_DEFAULT.INBOX
            );
            this.$store.commit("SET_ACTIVE_NODE_SIDEBAR", folderInBox);
            this.renameAbsFolderPath(this.dataInBox);
            this.dataInBox = this.reorderFolder(this.dataInBox);
            const dataInBox = [
                {
                    name: i18n.t("zimbra.zmMsg.mailFolder"),
                    id: FOLDER_DEFAULT.ROOT,
                    folder: this.dataInBox,
                    order: 1,
                },
            ];
            this.$store.commit(
                "SET_DATA_INBOX",
                dataInBox
            );
            this.dataExtend[0].folder = this.reorderFolder(this.dataExtend[0].folder);
            this.renameAbsFolderPath(this.dataExtend);
            this.$store.commit(
                "SET_DATA_EXTEND",
                this.reorderFolder(this.dataExtend)
            );
            this.finishedDownloaData = true;
        },
        tickExternalFolder(folders, folderTrashPrivate, emailDefaultMap) {
            folders.forEach(x => {
                x.idTrash = folderTrashPrivate.id;
                x.isSubFolderMap = true;
                x.emailDefault = emailDefaultMap
                if (x.folder) {
                    this.tickExternalFolder(x.folder, folderTrashPrivate);
                }
            })
        },
        reorderFolder(folders) {
            if (Array.isArray(folders)) {
                folders.forEach((item) => {
                    item.order = item.isInbox ? 1 : item.isSent ? 2 : item.isJunk ? 3 : item.isTrash ? 4 : "";
                    const sort = FOLDER_ORDER.find(
                        (e) => e.idFolder === item.id
                    );
                    if (sort) {
                        item.order = sort.order;
                    } else if (!item.order) {
                        // item.order = Number.MAX_SAFE_INTEGER;
                        item.order = item.isLink
                            ? Number.MAX_SAFE_INTEGER
                            : Number.MAX_SAFE_INTEGER - 1;
                    }
                    item.name = FOLDER_RENAME.includes(item.name)
                        ? i18n.t(`zimbra.systemFolder.folder${item.name}`)
                        : item.name;
                    if (item.folder) {
                        item.folder = this.reorderFolder(item.folder);
                    }
                });
                folders = _.orderBy(folders, ["order", "name"], ["asc", "asc"]);
            } else if (folders) {
                folders.name = FOLDER_RENAME.includes(folders.name)
                    ? i18n.t(`zimbra.systemFolder.folder${folders.name}`)
                    : folders.name;
                if (folders.folder) {
                    folders.folder = this.reorderFolder(folders.folder);
                }
            }

            return folders;
        },
        isFolderDefaut(data) {
            for (let item in FOLDER_DEFAULT) {
                if (FOLDER_DEFAULT[item] == data.id) {
                    return true;
                }
            }
            return false;
        },
        objectToArr(data, isGetAll) {
            for (let item in data) {
                if (!_.isNil(data[item].perm)) {
                    data[item].isLink = true;
                }
                data[item].rgb = data[item].rgb || "#3F4254";
                data[item].u = data[item].u || 0;
                data[item].n = data[item].n || 0;
                data[item].folder = data[item].folder || []; // bug 1378
                if (
                    this.dataFolderArchive &&
                    this.dataFolderArchive.id &&
                    data[item].id == this.dataFolderArchive.id
                ) {
                    const dataFolderArchive = data[item];
                    dataFolderArchive.hideDeleteButton =
                        this.dataFolderArchive.hideDeleteButton;
                    dataFolderArchive.showSendAndArchive =
                        this.dataFolderArchive.showSendAndArchive;
                    this.$store.commit("SET_FOLDER_ARCHIVE", dataFolderArchive);
                }
                if (!data[item].folder || !Array.isArray(data[item].folder)) {
                    data[item].folder = data[item].folder ? [data[item].folder] : []
                }
                if (data[item].search) {
                    const dataSearch = Array.isArray(data[item].search)
                        ? data[item].search
                        : [data[item].search];
                    data[item].folder = data[item].folder.concat(dataSearch.map(x => ({ ...x, isSearch: true })));
                    if (!this.isFolderDefaut(data[item])) {
                        data[item].isSearch = true;
                    }
                }
                if (data[item].link) {
                    const _link = Array.isArray(data[item].link)
                        ? data[item].link
                        : [data[item].link]
                    const dataLink = _link.map(e => ({ ...e, isLink: true }));
                    data[item].folder = data[item].folder.concat(dataLink);
                }
                if (data[item].folder.length > 0) {
                    if (!isGetAll) {
                        if (data[item].id == FOLDER_DEFAULT.TRASH) {
                            data[item].folder = data[item].folder.filter(x => x.view == "message" || _.isNil(x.view) || this.containsChildMessage(x));
                        } else {
                            data[item].folder = data[item].folder.filter(x => x.view == "message" || _.isNil(x.view));
                        }
                        data[item].folder = data[item].folder.filter(x => !_.isNil(x.absFolderPath));
                        data[item].folder = data[item].folder.filter(x => (!x.isSearch || (x.isSearch && x.types == "conversation")));
                    }
                    this.objectToArr(data[item].folder, isGetAll);
                }
            }
        },
        containsChildMessage(child) {
            if (child.folder) {
                child.folder = Array.isArray(child.folder) ? child.folder : [child.folder];
                child.folder.forEach(x => {
                    this.buildAbsFolderPath(child, x);
                })
                return child.folder.findIndex(x => x.view == "message") > -1;
            }
            return false;
        },
        buildAbsFolderPath(parent, sub) {
            sub.absFolderPath = parent.absFolderPath + "/" + sub.name;
            if (sub.folder) {
                sub.folder = Array.isArray(sub.folder) ? sub.folder : [sub.folder]
                sub.folder.forEach(x => this.buildAbsFolderPath(sub, x));
            }
        },
        getFolderContactShare(folders) {
            folders = Array.isArray(folders) ? folders : [folders];
            let res = [];
            folders.forEach(x => {
                if (x.view == "contact") {
                    res.push(x);
                }
                if (x.folder) {
                    res = _.concat(res, this.getListFolderShared(x.folder));
                }
            })
            return res;
        },
        wrapFolderSearch(search) {
            if (!search) {
                return [];
            }
            if (Array.isArray(search)) {
                search.forEach((item) => {
                    item.search = this.wrapFolderSearch(item.search);
                });
            } else if (search) {
                search = this.wrapFolderSearch([search]);
            }
            return search;
        },
        renameAbsFolderPath(folders) {
            if (Array.isArray(folders)) {
                folders.forEach((folder) => {
                    folder.absFolderPathI18n = folder.absFolderPath ? this.pathI18n(folder.absFolderPath) : "";
                    this.renameAbsFolderPath(folder.folder);
                });
            } else if (folders) {
                folders.absFolderPathI18n = folders.absFolderPath ? this.pathI18n(folders.absFolderPath) : "";
            }
        },
        pathI18n(path) {
            const regex = /^(\/[A-z]*)/gi;
            const match = path.match(regex);
            if (match?.length > 0) {
                const folderRoot = match[0].substring(1, match[0].length);
                const nameI18n = FOLDER_RENAME.includes(folderRoot)
                    ? i18n.t(`zimbra.systemFolder.folder${folderRoot}`)
                    : folderRoot;
                return path.replace(/^(\/[A-z]*)/gi, `${nameI18n}`);
            }
            return path;
        },
        // getListFolderShared(folders) {
        //     let res = [];
        //     if (Array.isArray(folders) && folders.length > 0) {
        //         for (let index = 0; index < folders.length; index++) {
        //             const folder = folders[index];
        //             if (folder.isLink) {
        //                 res.push({ ...folder, folder: [] });
        //             }
        //             const temp = this.getListFolderShared(folder.folder);
        //             temp?.length > 0 && (res = _.concat(res, temp));
        //         }
        //     } else if (folders) {
        //         if (folders.isLink) {
        //             res.push({ ...folders, folder: [] });
        //         }
        //         const temp = this.getListFolderShared(folders.folder);
        //         temp?.length > 0 && (res = _.concat(res, temp));
        //     }
        //     return res;
        // },
        getListFolderShared(folders) {
            folders = Array.isArray(folders) ? folders : [folders];
            // const listFolder =  Array.isArray(folders) ? folders : [folders];
            let res = [];
            folders.forEach(x => {
                if (x.folder) {
                    res = _.concat(res, this.getListFolderShared(x.folder));
                }
                if (x.isLink) {
                    res.push(x);
                }
            })
            return res;
        },
        getQueryExtra() {
            if (!this.dataSidebarInBox || this.dataSidebarInBox.length == 0) {
                return "";
            }
            let queryExtra = "";
            const listFolderSharedInbox = this.getListFolderShared(this.dataSidebarInBox[0].folder);
            const listFolderSharedExtend = this.getListFolderShared(this.dataSidebarExtend[0].folder);
            const listFolderShared = _.concat(listFolderSharedInbox, listFolderSharedExtend);
            if (this.preference.zimbraPrefIncludeSharedItemsInSearch && listFolderShared.length > 0) {
                const listFolderId = listFolderShared.map(e => `inid:"${e.id}"`);
                listFolderId.push("is:local");
                queryExtra = listFolderId.join(" OR ");
            }
            return queryExtra;
        },
        buildListEmailAddress(listEmail, type) {
            if (listEmail && listEmail.length > 0) {
                return listEmail.reduce((arr = [], item) => {
                    if (item.a) {
                        arr.push({
                            t: _.escape(type),
                            a: _.escape(item.a),
                            p: _.escape(item.p),
                        });
                    } else {
                        arr.push({
                            t: _.escape(type),
                            a: _.escape(item.text),
                        });
                    }
                    return arr;
                }, []);
            }
            return [];
        },
        isImageFile(fileName) {
            const regex_filename = /.(jpg|jpeg|png|gif)$/i;
            return regex_filename.test(fileName);
        },
        getNewSubjectReplyCal(su, type) {
            let pre = "";
            switch (type) {
                case CAL_OPERATOR_REPLY_INV.ACCEPT:
                    pre = i18n.t("zimbra.zhMsg.replyAccept");
                    break;
                case CAL_OPERATOR_REPLY_INV.TENTATIVE:
                    pre = i18n.t("zimbra.zhMsg.apptPtstTE");
                    break;
                case CAL_OPERATOR_REPLY_INV.DECLINE:
                    pre = i18n.t("zimbra.zhMsg.replyDecline");
                    break;
            }
            return `${pre}: ${su}`;
        },
        /**
         * Body nội dung trả lời
         * example: Có, tôi sẽ tham dự.
         */
        getInviteReplyBody(verb) {
            let replyBody = "";
            switch (verb) {
                case CAL_OPERATOR_REPLY_INV.ACCEPT:
                    replyBody = this.$t("zimbra.zhMsg.defaultInviteReplyAcceptMessage");
                    break;
                case CAL_OPERATOR_REPLY_INV.TENTATIVE:
                    replyBody = this.$t("zimbra.zhMsg.defaultInviteReplyTentativeMessage");
                    break;
                case CAL_OPERATOR_REPLY_INV.DECLINE:
                    replyBody = this.$t("zimbra.zhMsg.defaultInviteReplyDeclineMessage");
                    break;
            }
            return replyBody;
        },
        /**
         * Khởi tạo dữ liệu cho màn hình sửa câu trả lời cuộc hẹn
         * @param {*} dataEditReplyCal
         */
        initDataForEditReplyCal(dataEditReplyCal) {
            let { su, id, e, mp } = dataEditReplyCal.currentMessage;
            const to = e.filter(x => x.t === ADDRESS_TYPE.FROM)
                .map(x => ({
                    ...x,
                    text: `"${x.p}" <${x.a}>`
                }));

            let listFileAttachment = [];
            if (Array.isArray(mp?.mp)) {
                listFileAttachment = mp.mp
                    .filter(e => e.cd == "attachment")
                    .map((e, idx) => ({
                        ...e,
                        part: idx + 2,
                        name: this.getFileNameWithSize(e),
                        forward: true,
                        aid: Math.random()
                            .toString(36)
                            .substring(2)
                    }));
            }

            const replyBody = this.getInviteReplyBody(dataEditReplyCal.verb);
            const signaturePre = this.getSignature(SIGNATURE_STYLE.OUTLOOK);
            const divider = this.getDivider();
            const header = `<div data-marker="${this.getDataMarker(ZmComposeView.BC_HEADERS)}">${this.getHeader(dataEditReplyCal.currentMessage)}</div>`;
            const body = `<div style="white-space: pre-line;" data-marker="${this.getDataMarker(ZmComposeView.BC_QUOTED_TEXT)}">${this.getBody(dataEditReplyCal.currentMessage)}</div>`;
            const signaturePost = this.getSignature(SIGNATURE_STYLE.INTERNET);

            const dataEdit = {
                id: id,
                modelData: {
                    id: id.toString(),
                    origid: id.toString(),
                    subject: this.getNewSubjectReplyCal(su, dataEditReplyCal.verb),
                    to: to,
                    rt: "r",
                    verb: dataEditReplyCal.verb,
                },
                showBcc: true,
                showCc: true,
                contentType: EMAIL_CONTENT_TYPE.HTML,
                listFileAttachment: listFileAttachment || [],
                actionType: EMAIL_ACTION.EDIT_REPLY_CAL,
                editorModel: [
                    replyBody,
                    AjxStringUtil.CRLF2_HTML,
                    signaturePre,
                    divider,
                    header,
                    body,
                    signaturePost
                ].join(""), //part.content,
                signatureActive: this.getSignatureForwardReply(),
            };
            return dataEdit;
        },

        /**
         * Khởi tạo dữ liệu cho màn hình trả lời cuộc hẹn ở Cụm calendar
         * @param {*} dataReplyCal
         * @param {*} isHaveAction biến xem là trả lời có bao gồm action chấp nhận - từ chối - hủy hay không?
         */
        initDataForReplyFromCalendar(dataReplyCal, isHaveAction) {
            const id = dataReplyCal.invComp.invId;
            let { su, e } = dataReplyCal.currentMessage;
            const to = e.filter(x => x.t === ADDRESS_TYPE.FROM)
                .map(x => ({
                    ...x,
                    text: this.getDisplayNameAddress(x)
                }));
            const cc = e.filter(x => x.t === ADDRESS_TYPE.CC)
                .map(x => ({
                    ...x,
                    text: this.getDisplayNameAddress(x)
                }));
            let listFileAttachment = [];
            let replyBody = "";
            let header = "";
            if (isHaveAction) {
                replyBody = this.getInviteReplyBody(dataReplyCal.verb);
                header = `<div data-marker="${this.getDataMarker(ZmComposeView.BC_HEADERS)}">${this.getHeader(dataReplyCal.currentMessage)}</div>`;
            }
            const signaturePre = this.getSignature(SIGNATURE_STYLE.OUTLOOK);
            const divider = this.getDivider();
            const body = `<div style="white-space: pre-line;" data-marker="${this.getDataMarker(ZmComposeView.BC_QUOTED_TEXT)}">${dataReplyCal.invComp.descHtml || ""}</div>`;
            const signaturePost = this.getSignature(SIGNATURE_STYLE.INTERNET);

            const dataReply = {
                id: id,
                modelData: {
                    id: id.toString(),
                    origid: id.toString(),
                    subject: isHaveAction ? this.getNewSubjectReplyCal(su, dataReplyCal.verb) : this.getNewSubject(su, dataReplyCal.verb),
                    to: to,
                    cc: cc,
                    rt: "r",
                    verb: dataReplyCal.verb,
                },
                showBcc: true,
                showCc: false,
                contentType: EMAIL_CONTENT_TYPE.HTML,
                listFileAttachment: listFileAttachment || [],
                actionType: isHaveAction ? EMAIL_ACTION.EDIT_REPLY_CAL : dataReplyCal.verb,
                editorModel: [
                    replyBody,
                    AjxStringUtil.CRLF2_HTML,
                    signaturePre,
                    divider,
                    header,
                    body,
                    signaturePost
                ].join(""), //part.content,
                signatureActive: this.getSignatureForwardReply(),
                e: e,
                d: dataReplyCal.invComp.d,
                desc: dataReplyCal.invComp.desc,
                descHtml: dataReplyCal.invComp.descHtml,
            };
            return dataReply;
        },

        /**
         * Khởi tạo dữ liệu cho liên kết rảnh, bận
         * @param {*} data
         */
        initDataForSendLink(_contentMessage) {
            const body = _contentMessage;

            const signaturePre = this.getSignature(SIGNATURE_STYLE.OUTLOOK, this.preference.zimbraPrefDefaultSignatureId);
            const signaturePost = this.getSignature(SIGNATURE_STYLE.INTERNET, this.preference.zimbraPrefDefaultSignatureId);

            const dataSend = {
                modelData: {
                    subject: "",
                    to: [],
                    cc: [],
                    rt: "r",
                    verb: EMAIL_ACTION.DRAFT,
                },
                signatureActive: this.signatures?.find((e) => e.id == this.preference.zimbraPrefDefaultSignatureId),
                showBcc: true,
                showCc: false,
                contentType: EMAIL_CONTENT_TYPE.HTML,
                listFileAttachment: [],
                actionType: EMAIL_ACTION.DRAFT,
                isInitManual: true,
                editorModel: [
                    body,
                    signaturePre,
                    signaturePost
                ].join(""), //part.content,
            };
            return dataSend;
        },

        /**
         * Fix src của img khi xem
         */
        handleFixImageSrc(html, dataGenaral, idMsg, haveImg) {
            if (!html || !dataGenaral) {
                return html;
            }
            const div = document.createElement("div");
            $(div).html(html.trim());
            const images = $(div).find("img");
            for (let i = 0; i < images.length; i++) {
                const dfsrc = images[i].getAttribute("dfsrc");
                const dataMceSrc = images[i].getAttribute("data-mce-src");
                const src = images[i].getAttribute("src");
                const reg_src = /^cid:/ig;
                if (dfsrc) {
                    try {
                        images[i].src = ""; //unload it first
                        images[i].src = dfsrc;
                    } catch (ex) {
                        // do nothing
                    }
                } else if (dataMceSrc || (src && reg_src.test(src))) {
                    const arrSplit = dataMceSrc ? dataMceSrc.split(":") : src.split(":");
                    const cid = "<" + arrSplit[1] + ">";
                    for (const [, value] of Object.entries(dataGenaral)) {
                        if (value.ci && value.ci == cid) {
                            try {
                                images[i].src = ""; //unload it first
                                images[i].src = this.createUrlDownloadFile(
                                    idMsg,
                                    value.part
                                );
                            } catch (ex) {
                                // do nothing
                            }
                            break;
                        }
                    }
                }
                if (haveImg && dfsrc?.indexOf("http") > -1) {
                    if (!haveImg.showImg) {
                        try {
                            images[i].src = ""; //unload it first
                            images[i].src = "/img/zimbra/1x1-trans.png";
                            images[i].style.display = "none";
                        } catch (ex) {
                            // do nothing
                        }
                    }
                    haveImg.value = true;
                }
            }
            return $(div).html();
        },
        /**
         * Fix src của img khi gửi/lưu nháp
         */
        handleFixImageSrcBeforeSend(htmlContent) {
            const div = document.createElement("div");
            $(div).html(htmlContent.trim());
            const images = $(div).find("img");
            for (let i = 0; i < images.length; i++) {
                try {
                    images[i].src = images[i].getAttribute("data-mce-src");
                } catch (ex) {
                    // do nothing
                }
            }
            return $(div).html();
        },
        getAttachInline(allPart) {
            const res = [];
            for (const [, value] of Object.entries(allPart)) {
                if (value.cd === "inline") {
                    res.push(value);
                }
            }
            return res;
        },

        /**
         * kienpt thêm
         * Hàm cập nhật lại folder search
         */
        async getFolderSearchApi() {
            const formData = {
                folder: `<view>1</view><depth>-1</depth><tr>1</tr><visible>1</visible><needGranteeName>1</needGranteeName>`,
            };
            const searchRequest = await ZimbraMailService.getFolderRequest(
                formData
            );
            const searchActionResponse =
                this.getResponseBody(searchRequest)["GetFolderResponse"];
            const newFolders = this.reorderTree(
                [
                    {
                        id: 1,
                        name: i18n.t("zimbra.zhMsg.optionsSearches"),
                        search: this.wrapFolderSearch(
                            searchActionResponse.folder.search
                        ),
                        isRootSearchFolder: true,
                    },
                ],
                "search",
                ["name"],
                ["asc"]
            );
            this.$store.commit("SET_FOLDER_SEARCH", newFolders);
            return newFolders;
        },

        /**
         * Khởi tạo dữ liệu cho màn hình Soạn thư hủy
         * @param {*} dataReplyCal
         * @param {*} isInstance
         */
        initDataForCancelApptFromCalendar(dataReplyCal, isInstance) {
            const id = dataReplyCal.invComp.invId;
            let { su, e } = dataReplyCal.currentMessage;
            const to = e.filter(x => x.t === ADDRESS_TYPE.FROM)
                .map(x => ({
                    ...x,
                    text: this.getDisplayNameAddress(x)
                }));
            const cc = e.filter(x => x.t === ADDRESS_TYPE.CC)
                .map(x => ({
                    ...x,
                    text: this.getDisplayNameAddress(x)
                }));
            let listFileAttachment = [];
            let replyBody = this.$t("zimbra.zhMsg.apptCancelled");
            if (isInstance) {
                replyBody = this.$t("zimbra.zhMsg.apptInstanceCancelled");
            }

            const dataReply = {
                id: id,
                modelData: {
                    id: id.toString(),
                    origid: id.toString(),
                    subject: this.$t('zimbra.ztMsg.apptCancelSubject', [su]),
                    to: to,
                    cc: cc,
                    rt: "r",
                    verb: dataReplyCal.verb,
                },
                showBcc: true,
                showCc: false,
                contentType: EMAIL_CONTENT_TYPE.HTML,
                listFileAttachment: listFileAttachment || [],
                actionType: dataReplyCal.verb,
                editorModel: replyBody,
                signatureActive: this.getSignatureForwardReply(),
            };
            return dataReply;
        },
    },
};
