<template>
    <div class="form-controls zm-color-picker compose-color"   >
        <base-input class="color-input" :value="color" @input="changeInput" />
        <div class="one-colorpicker" ref="colorpicker" >
            <el-popover
            
                v-model="show"
                placement="bottom"
                width="200"
                trigger="click"
                popper-class="zm-color-picker-popover float-right-color-picker"
            >
                <color-panel
                :style="{ left: '48px',top: '0px',position: 'absolute',left: '100px' }"
                    v-model="colorTemp"
                    
                    :mode="mode"
                    :showFooter="showFooter"
                    :enabledToggleMode="false"
                    :default-colors="defaultColors"
                    @change="change"
                    @onCancel="onCancel"
                    @onConfirm="onConfirm"
                    :confirmButtonLabel="$t('zimbra.zhMsg.select')"
                    :cancelButtonLabel="$t('zimbra.zhMsg.actionTaskCancelTT')"
                ></color-panel>
                <div class="color-block" slot="reference">
                    <div
                        class="color-block-layer value"
                        :style="{ backgroundColor: color }"
                    ></div>
                    <div class="color-block-layer bg"></div>
                </div>
            </el-popover>
        </div>
    </div>
</template>
<script>
import BaseInput from "@/components/modules/base/base-input";
import { getTinyColor } from "@/utils/color/color-utils.js";
export default {
    data() {
        return {
            show: false,
            color: this.value,
            colorTemp: this.value,
        };
    },
    components: {
        BaseInput,
    },
    computed: {
        panelStyle() {
            let style = {};
            if (typeof this.position === "string") {
                style.left = "48px";
                if (this.position === "up") {
                    style.bottom = 0;
                } else {
                    style.top = 0;
                }
            } else {
                for (let key in this.position) {
                    style[key] = this.position[key];
                }
            }
            return style;
        },
    },
    props: {
        value: {
            type: String,
            default: "#3F4254",
        },
        mode: {
            type: String,
            default: "hex", // hex/rgb/all
        },
        position: {
            type: [String, Object], // up | down
            default: "down",
        },
        defaultColors: {
            type: [Array],
            default() {
                return ['#3F4254', '#0000FF', '#00FFFF', '#008000', '#FF00FF', '#FF0000', '#FFFF00', '#FF66FF', '#808080', '#FF9900'];
            }
        },
        showFooter: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        value(newValue) {
            this.color = newValue;
            this.colorTemp = newValue;
        },
    },
    methods: {
        change(color) {
            this.colorTemp = color;
            if (!this.showFooter) {
                this.$emit("change", color);
                this.$emit("input", color);
                this.color = color;
                this.$emit("onchange");
            }
        },
        changeInput(color) {
            this.color = "";
            const _tiny = getTinyColor(color);
            this.$emit("change", _tiny.hex);
            this.$emit("input", _tiny.hex);
            setTimeout(() => {
                this.color = _tiny.hex;
            }, 100);
            this.$emit("onchange");
        },
        toggle() {
            this.show = !this.show;
        },
        _includes(el, child) {
            if (el) {
                return el === child || el.contains(child);
            } else {
                return false;
            }
        },
        onConfirm() {
            this.color = this.colorTemp;
            this.$emit("change", this.color);
            this.$emit("input", this.color);
            this.show = false;
            this.$emit("onchange");
        },
        onCancel() {
            this.show = false;
        },
    },
    mounted() {
        if (this.value) {
            this.color = this.value;
            this.colorTemp = this.value;
        }
    },
};
</script>
<style lang="scss">
.one-colorpicker {
    position: relative;
    display: inline-block;
    vertical-align: top;
    .one-colorpanel {
        position: absolute;
        z-index: 2;
    }
    
}
.color-block {
    width: 32px;
    height: 32px;
    cursor: pointer;
    position: relative;
    .color-block-layer {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .bg {
        z-index: 0;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC);
    }
    .value {
        z-index: 1;
    }
}
</style>
