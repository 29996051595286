import BaseService from "@/shared/services/BaseService";
import CONFIG from '@/config/config';
class OutOfOfficeService extends BaseService {
    url = CONFIG.ENDPOINT['compileService'];

    constructor() {
        super("zimbraService");
    }

    getAll(data = { xml: "" }) {
        const url = `${this.baseURL}/getPrefsRequest`
        return this.postRequest(url, data)
    }

    saveSetting(data) {
        return this.postRequest(`${this.url}`, data);
    }
    // createAppointment(data) {
    //     const url = `${this.baseURL}/createAppointmentRequest`
    //     return this.postRequest(url, data)
    // }
    getAppointmentRequest(data){
        const url = `${this.baseURL}/getAppointmentRequest`
        return this.postRequest(url, data)
    }
}

export default new OutOfOfficeService();
