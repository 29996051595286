<template>
    <div>
        <base-input
            v-if="showTextboxSearch"
            :value="filterText"
            placeholder=""
            style="padding-bottom: 10px"
            size="medium"
            @handleInput="handleFilter"
        ></base-input>
        <!-- tree inbox -->
        <perfect-scrollbar style="max-height: 585px">
            <el-tree
                class="tree-wrap"
                ref="tree"
                :data="dataInbox"
                node-key="id"
                :props="defaultProps"
                @node-click="nodeClick"
                :filter-node-method="filterNode"
                highlight-current
                draggable
                :default-expanded-keys="[FOLDER_DEFAULT.ROOT]"
                :show-checkbox="showCheckbox"
                :expand-on-click-node="false"
                empty-text=""
            >
                <div class="zm-folder-info" slot-scope="{ node, data }">
                    <el-popover
                        placement="bottom-start"
                        :open-delay="1000"
                        trigger="hover"
                        :disabled="!showInfoFolder"
                    >
                        <div>
                            <b>{{ node.label }}</b> <br />
                            <div
                                v-if="data.id != FOLDER_DEFAULT.STARS"
                                class="d-flex"
                            >
                                <span class="folder-info-left">{{
                                    $t("zimbra.zmMsg.mailLabel")
                                }}</span>
                                <span class="folder-info-right">{{
                                    data.n || 0
                                }}</span>
                            </div>
                            <div v-if="data.u" class="d-flex">
                                <span class="folder-info-left">{{
                                    $t("zimbraNokey.zimbra_sidebar_infoUnread")
                                }}</span>
                                <b class="folder-info-right">{{ data.u }}</b>
                            </div>
                            <div v-if="data.s" class="d-flex">
                                <span class="folder-info-left">{{
                                    $t("zimbra.zmMsg.fSizeLabel")
                                }}</span>
                                <span class="folder-info-right">{{
                                    formatSize(data.s)
                                }}</span>
                            </div>
                        </div>
                        <span slot="reference" class="custom-tree-node">
                            <span
                                v-if="
                                    data.id != FOLDER_DEFAULT.DRAFTS &&
                                    data.id != FOLDER_DEFAULT.INBOX &&
                                    data.id != FOLDER_DEFAULT.SENT &&
                                    data.id != FOLDER_DEFAULT.STARS &&
                                    data.isLink == undefined
                                "
                            >
                                <i
                                    class="ic-folder"
                                    :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(
                                        data
                                    )}`"
                                ></i>
                            </span>
                            <span v-if="data.isLink">
                                <i
                                    class="ic-folder-copy"
                                    style="
                                        padding-right: 30px;
                                        margin-left: -8px;
                                    "
                                ></i>
                            </span>
                            <span v-if="data.id == FOLDER_DEFAULT.STARS">
                                <i
                                    class="ic-star"
                                    style="
                                        padding-right: 30px;
                                        margin-left: -8px;
                                    "
                                ></i>
                            </span>
                            <span v-if="data.id == FOLDER_DEFAULT.SENT">
                                <i
                                    class="ic-sent"
                                    style="
                                        padding-right: 30px;
                                        margin-left: -8px;
                                    "
                                ></i>
                            </span>
                            <span v-if="data.id == FOLDER_DEFAULT.DRAFTS">
                                <i
                                    class="ic-draft"
                                    style="
                                        padding-right: 30px;
                                        margin-left: -8px;
                                        zoom: 1.05;
                                    "
                                ></i>
                            </span>
                            <span v-if="data.id == FOLDER_DEFAULT.INBOX">
                                <i
                                    :class="`ic-inbox ${
                                        node.expanded == true ? 'active' : ''
                                    }`"
                                    style="
                                        padding-right: 30px;
                                        margin-left: -8px;
                                        zoom: 1.05;
                                    "
                                ></i>
                            </span>
                            <span
                                v-if="
                                    data.isLink == undefined ||
                                    (data.isLink && data.perm)
                                "
                                >{{ node.label }}</span
                            >
                            <span
                                v-if="data.isLink && data.perm == undefined"
                                style="text-decoration: line-through"
                                >{{ node.label }}</span
                            >
                        </span>
                    </el-popover>
                </div>
            </el-tree>
            <!-- tree extend -->
            <el-tree
                ref="treeExtend"
                class="tree-wrap tree-extend"
                node-key="id"
                :empty-text="emptyText"
                style="margin-top: 10px"
                :data="dataExtend"
                :props="defaultProps"
                @node-click="nodeClick"
                :filter-node-method="filterNode"
                highlight-current
                draggable
                :default-expanded-keys="[FOLDER_DEFAULT.EXTEND]"
                :expand-on-click-node="false"
            >
                <div class="zm-folder-info" slot-scope="{ node, data }">
                    <el-popover
                        placement="bottom-start"
                        :open-delay="1000"
                        trigger="hover"
                        :disabled="!showInfoFolder"
                    >
                        <div>
                            <b>{{ node.label }}</b> <br />
                            <div
                                v-if="data.id != FOLDER_DEFAULT.STARS"
                                class="d-flex"
                            >
                                <span class="folder-info-left">{{
                                    $t("zimbra.zmMsg.mailLabel")
                                }}</span>
                                <span class="folder-info-right">{{
                                    data.n || 0
                                }}</span>
                            </div>
                            <div v-if="data.u" class="d-flex">
                                <span class="folder-info-left">{{
                                    $t("zimbraNokey.zimbra_sidebar_infoUnread")
                                }}</span>
                                <b class="folder-info-right">{{ data.u }}</b>
                            </div>
                            <div v-if="data.s" class="d-flex">
                                <span class="folder-info-left">{{
                                    $t("zimbra.zmMsg.fSizeLabel")
                                }}</span>
                                <span class="folder-info-right">{{
                                    formatSize(data.s)
                                }}</span>
                            </div>
                        </div>
                        <span slot="reference" class="custom-tree-node">
                            <span
                                v-if="
                                    data.id != FOLDER_DEFAULT.TRASH &&
                                    data.id != FOLDER_DEFAULT.JUNK &&
                                    data.id != FOLDER_DEFAULT.EXTEND &&
                                    data.id != FOLDER_DEFAULT.TAG &&
                                    data.isLink == undefined
                                "
                            >
                                <i
                                    class="ic-folder"
                                    :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(
                                        data
                                    )}`"
                                ></i>
                            </span>
                            <span v-if="data.isLink">
                                <i
                                    class="ic-folder-copy"
                                    style="
                                        padding-right: 30px;
                                        margin-left: -8px;
                                        zoom: 1.05;
                                    "
                                ></i>
                            </span>
                            <span v-if="data.id == FOLDER_DEFAULT.JUNK">
                                <i
                                    class="ic-trash"
                                    style="
                                        padding-right: 28px;
                                        margin-left: -9px;
                                    "
                                ></i>
                            </span>
                            <span v-if="data.id == FOLDER_DEFAULT.TRASH">
                                <i
                                    class="ic-delete-red"
                                    style="
                                        padding-right: 28px;
                                        margin-left: -9px;
                                    "
                                ></i>
                            </span>
                            <span v-if="data.id == FOLDER_DEFAULT.TAG">
                                <i
                                    class="ic-tag"
                                    style="
                                        padding-right: 28px;
                                        margin-left: -9px;
                                    "
                                ></i>
                            </span>
                            <span
                                v-if="
                                    data.id != FOLDER_DEFAULT.EXTEND &&
                                    (data.isLink == undefined ||
                                        (data.isLink && data.perm))
                                "
                                >{{ node.label }}</span
                            >
                            <span
                                v-if="data.id == FOLDER_DEFAULT.EXTEND"
                                style="margin-left: -4px"
                                >{{
                                    node.expanded
                                        ? $t("zimbra.zmMsg.collapse")
                                        : $t("zimbraNokey.zimbra_sidebar_extend")
                                }}</span
                            >
                            <span
                                v-if="data.isLink && data.perm == undefined"
                                style="text-decoration: line-through"
                                >{{ node.label }}</span
                            >
                        </span>
                    </el-popover>
                </div>
            </el-tree>
            <!-- <div v-if="isNoData" class="tree__empty-block">
                <span class="tree__empty-text">Không có dữ liệu</span>
            </div> -->
        </perfect-scrollbar>
    </div>
    <!-- </perfect-scrollbar> -->
</template>
<script>
import BaseInput from "@/components/modules/base/base-input";
import CommonUtils from "@/utils/common-utils";
import { FOLDER_DEFAULT } from "@/utils/constants";
import { mapGetters } from "vuex";
import { EMAIL_ACTION } from "@/utils/constants";
import _ from "lodash";
export default {
    props: {
        showCheckbox: {
            type: Boolean,
            default: false,
        },
        showTextboxSearch: {
            type: Boolean,
            default: true,
        },
        showInfoFolder: {
            type: Boolean,
            default: false,
        },
        listIdFolderHidden: {
            type: Array,
            default: () => [],
        },
    },
    mixins: [CommonUtils],
    components: {
        BaseInput,
    },
    data() {
        return {
            defaultProps: {
                children: "folder",
                label: "name",
            },
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            filterText: "",
            dataExtend: [],
            dataInbox: [],
            data: {},
            allowfilter: true,
            isNoData:false,
            emptyText: ""
        };
    },
    computed: {
        ...mapGetters(["dataSidebarInBox", "dataSidebarExtend"]),
    },
    watch: {
        filterText(val) {
            if (this.allowfilter) {
                this.$refs.treeExtend.filter(val);
                this.$refs.tree.filter(val);
                this.emptyText = this.$refs.treeExtend.isEmpty && this.$refs.tree.isEmpty ? this.$t("zimbraNokey.emptyData") : "";
            }
        },
        dataSidebarExtend: {
            deep: true,
            handler(val) {
                this.dataExtend = _.cloneDeep(val);
                this.dataExtend[0].folder = this.dataExtend[0].folder.filter((x) => !this.listIdFolderHidden.includes(x.id));
            }
        },
        dataSidebarInBox: {
            deep: true,
            handler(val) {
            const _listAll = _.cloneDeep(val);
            const _list = _listAll[0].folder.filter((x) => !this.listIdFolderHidden.includes(x.id));
            this.dataInbox = [
                {
                    id: FOLDER_DEFAULT.ROOT,
                    folder: _list,
                    name: this.$t("zimbra.zmMsg.mailFolder"),
                },
            ];
        }
        }
    },
    mounted() {
        // this.$root.$on('hanldeOpenCreateFolder',() => {
        //     setTimeout(() => {
        //         this.$refs.tree.setCurrentKey(FOLDER_DEFAULT.ROOT);
        //     }, 500);
        // })
        // this.$root.$on('hanldeCloseCreateFolder',() => {
        //     this.$refs.tree.setCurrentKey(undefined)
        // })
    },
    created() {
        this.dataExtend = _.cloneDeep(this.dataSidebarExtend);
        this.dataExtend[0].folder = this.dataExtend[0].folder.filter(
            (x) => !this.listIdFolderHidden.includes(x.id)
        );
        const _list = this.dataSidebarInBox[0].folder.filter(
            (x) => !this.listIdFolderHidden.includes(x.id)
        );
        this.dataInbox = [
            {
                id: FOLDER_DEFAULT.ROOT,
                folder: _list,
                name: this.$t("zimbra.zmMsg.mailFolder"),
            },
        ];
    },
    methods: {
        filterNode(value, data) {
             if (this.allowfilter) {
                if (!value) return true;
                return data.name.toString().toLowerCase().indexOf(value.toString().toLowerCase().trim()) !== -1;
            }
            return true;
        },
        handleFilter(val) {
            this.filterText = val;
            if (this.allowfilter) {
                this.$refs.treeExtend.filter(val);
                this.$refs.tree.filter(val);
            }
        },
        async nodeClick(data) {
            if (this.$listeners.onSelectFolder) {
                this.filterText = data.name;
                this.allowfilter = false;
                this.$emit("onSelectFolder", data);
            } else {
                if (this.isMoveMail) {
                    this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", data);
                } else {
                    this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", data);
                    setTimeout(() => {
                        this.$root.$emit(
                            "handleNavbarAction",
                            EMAIL_ACTION.MOVE
                        );
                    }, 200);
                    // this.$root.$emit("clickMoveMailToFolder", data);
                }
            }
            setTimeout(() => {
                this.allowfilter = true;
            }, 200);
        },
        setCurrentKey(key) {
            this.$refs.tree.setCurrentKey(key);
        },
    },
};
</script>
