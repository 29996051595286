import BaseService from "@/shared/services/BaseService";
import axios from "axios";
import CONFIG from '@/config/config';
class AccountService extends BaseService {
    constructor() {
        super('zimbraService');
    }

    login(formLogin) {
       
        const url = `${this.baseURL}/authRequest`;

        // return this.postRequest(url, formLogin);
        // document.cookie = `ZM_AUTH_TOKEN=${this.getAuthToken()}`;
        const config = {
            baseURL: url,
            timeout: CONFIG.TIMEOUT_REQUEST,
            headers: {
                "X-Gravitee-Api-Key": CONFIG.X_GRAVITEE_API_KEY,
            },
            validateStatus: function (status) {
                return status >= 200 && status < 500; // default
            },
            withCredentials: true,
            exposedHeaders: ["set-cookie"],
        }
        const instanceService = axios.create(config);
        return instanceService.post(url, formLogin, config);
    }

    loginSSO(loginForm) {
        const url = CONFIG.ENDPOINT['authRequest']
        // return this.postRequest(url, formLogin);
        // document.cookie = `ZM_AUTH_TOKEN=${this.getAuthToken()}`;
        const config = {
            // baseURL: url,
            timeout: CONFIG.TIMEOUT_REQUEST,
            headers: {
                "X-Gravitee-Api-Key": CONFIG.X_GRAVITEE_API_AUTH_REQUEST,
            },
            validateStatus: function (status) {
                return status >= 200 && status < 500; // default
            },
            withCredentials: true,
            exposedHeaders: ["set-cookie"],
        }

        const instanceService = axios.create(config);
        return instanceService.post(url, loginForm, config);
    }

    getAccountInfoRequest(form) {
        const url = `${this.baseURL}/getAccountInfoRequest`;
        return this.postRequest(url, form);
    }

    changePassword(form) {
        const url = `${this.baseURL}/changepass`;
        return this.postRequest(url, form, true, false)
    }
}

export default new AccountService;
