import axios from "axios";
import CONFIG from '@/config/config';
import interceptors from './config/interceptors'
import { error } from "jquery";
// import { wrapper } from 'axios-cookiejar-support';
// import { CookieJar } from 'tough-cookie';

class BaseService {
    constructor(endpoint, xGraviteeApiKey) {
        const configUrl = CONFIG.ENDPOINT[endpoint];
        const origin = window.location.origin;
        if (configUrl.startsWith('http')) {
            this.baseURL = configUrl;
        } else {
            this.baseURL = origin + configUrl;
        }

        this.xGraviteeApiKey = CONFIG[xGraviteeApiKey || 'X_GRAVITEE_API_KEY'];
    }

    createInstanceConfig(url) {
        return {
            // withCredentials: true,
            // baseURL: url,
            timeout: CONFIG.TIMEOUT_REQUEST,
            headers: {
                "X-Gravitee-Api-Key": this.xGraviteeApiKey,
                "X-Cookie": `ZM_AUTH_TOKEN=${this.getAuthToken()}`,
            },
            validateStatus: function (status) {
                return status >= 200 && status < 500; // default
            },
            // credentials: "same-origin"
            // jar: true
        }
    }

    search(data) {
        // debugger; // eslint-disable-line no-debugger
        const url = `${this.baseURL}/search`;
        return this.getRequest(url, data);
    }

    findOne(id) {
        const url = `${this.baseURL}/detail/${id}`;
        return this.getRequest(url, {});
    }

    postRequest(url, data, withProgress = true, withNotify = true) {
        return this.httpRequest(url, data, 'post', withProgress, withNotify);
    }

    getRequest(url, data = {}, withProgress = true, withNotify = true) {
        return this.httpRequest(url, data, 'get', withProgress, withNotify);
    }

    putRequest(url, data = {}, withProgress = true, withNotify = true) {
        return this.httpRequest(url, data, 'put', withProgress, withNotify);
    }

    deleteRequest(url, data = {}, withProgress = true, withNotify = true) {
        return this.httpRequest(url, data, 'delete', withProgress, withNotify);
    }

    httpRequest(url, data, method, withProgress, withNotify) {
        data = data || {};
        // const jar = new CookieJar();
        // const instanceService = wrapper(axios.create({ ...this.createInstanceConfig(url), jar }));
        const instanceService = axios.create(this.createInstanceConfig(url));

        // instanceService.defaults.withCredentials = true;
        interceptors.interceptorsRequest(instanceService, withProgress);
        interceptors.interceptorsResponse(instanceService, withProgress, withNotify);

        data['authToken'] = this.getAuthToken();
        data['headerReq'] = this.getHeader();


        if (method == 'get') {
            return instanceService.get(url, data);
        } else if (method == 'post') {
            return instanceService.post(url, data);
        } else if (method == 'delete') {
            return instanceService.delete(url, data);
        } else if (method == 'put') {
            return instanceService.put(url, data);
        }
    }

    soapPostRequest(url, xmlBodyStr) {
        const instanceService = axios.create(this.createInstanceConfig(url));
        var config = {
            headers: { 'Content-Type': 'application/soap+xml; charset=UTF-8' }
        };
        interceptors.interceptorsRequest(instanceService);
        interceptors.interceptorsResponse(instanceService);
        return instanceService.post(url, xmlBodyStr, config);
    }

    soapGetRequest(url, xmlBodyStr) {
        const instanceService = axios.create(this.createInstanceConfig(url));
        var config = {
            headers: { 'Content-Type': 'application/soap+xml; charset=UTF-8' }
        };
        interceptors.interceptorsRequest(instanceService);
        interceptors.interceptorsResponse(instanceService);
        return instanceService.post(url, xmlBodyStr, config);
    }

    getAuthToken() {
        const store = localStorage.getItem('zimbra_token');
        if (store != null) {
            const zimbraToken = JSON.parse(store);
            return zimbraToken.auth_token || '';
        }
        return '';
    }

    getHeader() {
        let sessionId = '';
        const store = localStorage.getItem('zimbra_token');
        if (store != null) {
            const zimbraToken = JSON.parse(store) || {};
            const { id } = zimbraToken.session || {};
            sessionId = id || '1' + Math.random().toString().slice(2, 9);
        } else {
            sessionId = '1' + Math.random().toString().slice(2, 9);
        }
        return {
            session: {
                id: `${sessionId}`,
                _content: `${sessionId}`,
            }
        }
    }
}

export default BaseService;
