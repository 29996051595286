import BaseService from "../shared/services/BaseService";
class ZimbraService extends BaseService {
    URL_CONFIG = `${this.baseURL}`;
    url = this.URL_CONFIG + "/api/compile";
    path = "modifyZimletPrefsRequest";

    constructor() {
        super("zimbraService");
    }

    handleJsonToString(obj) {
        return JSON.stringify(obj);
    }

    createFolderRequest(form) {
        const url = `${this.baseURL}/createFolderRequest`;
        return this.postRequest(url, form, true, false);
    }

    async getInfoRequest(data = "") {
        const url = `${this.baseURL}/getInfoRequest`;
        return this.postRequest(url, {
            sections: data
        });
    }
    async saveTest(data = []) {
        const url = `${this.baseURL}/modifyZimletPrefsRequest`;
        const request = data
            .map(
                item =>
                    `<urn1:zimlet name='${item.name}' presence='${item.presence}'/>`
            )
            .join("");
        return this.postRequest(url, {
            zimlet: request
        });
    }

    async saveZimletYahoo(data) {
        const url = `${this.baseURL}/modifyPropertiesRequest`;
        const request = `<prop zimlet="com_zimbra_ymemoticons" name="yemoticons_enableInMail">${data}</prop>`;
        return this.postRequest(url, {
            zimlet: request
        });
    }
}
export default new ZimbraService();
