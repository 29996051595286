<template>
    <div class="card-filter-row">
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueMainSelect"
                :options="mainSelect"
                @change="handleChangeMainSelect"
            />
        </div>
        <div class="card-filter-item"></div>
        <div class="card-filter-item select-wrap__300">
            <base-select
                displayField="name"
                valueField="stringComparison"
                :value="valueHeaderStringComparison"
                :options="stringComparison"
                @change="handleChangeStringComparison"
            />
        </div>
        <div class="card-filter-item">
            <base-input
                type="text"
                :value="valueHeaderTest"
                @input="handleChangeValue"
            />
        </div>
        <div class="card-filter-item"></div>
    </div>
</template>

<script>
import { FILTER_TEST_CONFIG } from "@/utils/constants";
import BaseSelect from "@/components/modules/base/base-select";
import BaseInput from "@/components/modules/base/base-input";
import CoreTest from "@/components/modules/base/base-filter-form/core-test";
export default {
    extends: CoreTest,
    components: {
        BaseSelect,
        BaseInput,
    },
    created() {
        this.beforeBuildDataForm();
        this.conditionHeaderTest = this.conditionsTest;
    },
    mounted() {

    },
    watch: {
        conditionsTest(newData) {
            this.beforeBuildDataForm();
            this.conditionHeaderTest = newData;
        },
    },
    data() {
        return {
            FILTER_TEST_CONFIG: FILTER_TEST_CONFIG,
            stringComparison: [
                {
                    stringComparison: FILTER_TEST_CONFIG.STRING_COMPARTION.IS,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_IS"
                    ),
                },
                {
                    stringComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_IS,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_NOT_IS"
                    ),
                },
                {
                    stringComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.CONTAINS,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_CONTAINS"
                    ),
                },
                {
                    stringComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_CONTAINS,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_NOT_CONTAINS"
                    ),
                },
                {
                    stringComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.MATHCHES,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_MATCHES"
                    ),
                },
                {
                    stringComparison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_MATHCHES,
                    name: this.$t(
                        "zimbra.zhMsg.EFILT_COND_HEADER_NOT_MATCHES"
                    ),
                },
            ],
            valueMainSelect: "",
            valueHeaderStringComparison: "",
            valueHeaderTest: "",
            conditionHeaderTest: {},
        };
    },
    methods: {
        beforeBuildDataForm() {
            // SET-VALUE-HEADER
            this.valueMainSelect = this.mainSelect[4].id;
            // SET-VALUE-STRING-COMPARISON
            if (
                this.conditionsTest.stringComparison ===
                FILTER_TEST_CONFIG.STRING_COMPARTION.IS
            ) {
                this.valueHeaderStringComparison =
                    FILTER_TEST_CONFIG.STRING_COMPARTION.IS;
                if (this.conditionsTest.negative) {
                    this.valueHeaderStringComparison =
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_IS;
                }
            }
            if (
                this.conditionsTest.stringComparison ===
                FILTER_TEST_CONFIG.STRING_COMPARTION.CONTAINS
            ) {
                this.valueHeaderStringComparison =
                    FILTER_TEST_CONFIG.STRING_COMPARTION.CONTAINS;
                if (this.conditionsTest.negative) {
                    this.valueHeaderStringComparison =
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_CONTAINS;
                }
            }
            if (
                this.conditionsTest.stringComparison ===
                FILTER_TEST_CONFIG.STRING_COMPARTION.MATHCHES
            ) {
                this.valueHeaderStringComparison =
                    FILTER_TEST_CONFIG.STRING_COMPARTION.MATHCHES;
                if (this.conditionsTest.negative) {
                    this.valueHeaderStringComparison =
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_MATHCHES;
                }
            }
            // SET-STRING-VALUE
            this.valueHeaderTest = this.conditionsTest.value;
        },
        handleChangeStringComparison(stringComparison) {
            for (let i in this.conditionHeaderTest) {
                if (
                    i !== "negative" &&
                    this.validateNegative(stringComparison)
                ) {
                    this.conditionHeaderTest = {
                        ...this.conditionHeaderTest,
                        negative: FILTER_TEST_CONFIG.NEGATIVE,
                    };
                } else {
                    if (!this.validateNegative(stringComparison)) {
                        delete this.conditionHeaderTest.negative;
                    }
                }
            }
            this.conditionHeaderTest.stringComparison =
                this.replacePrefix(stringComparison);
            this.valueHeaderStringComparison = stringComparison;
            this.$emit("onChangeConditionsTest", this.conditionHeaderTest);
        },
        handleChangeValue(val) {
            this.conditionHeaderTest.value = val;
            this.valueHeaderTest = val;
            this.$emit("onChangeConditionsTest", this.conditionHeaderTest);
        },
    },
};
</script>
