<template>
    <el-select
        ref="refSelectResource"
        :id="`resource_selector_${generateUniqueId()}`"
        :size="size"
        :disabled="disabled"
        :multiple-limit="multipleLimit"
        v-model="dataSelected"
        :multiple="multiple"
        :clearable="clearable"
        filterable
        remote
        :loading="loading"
        :placeholder="placeholder"
        :remote-method="remoteMethodResource"
        :loading-text="loaddingText"
        @change="onchange"
        @clear="onclear"
        @blur="onblur"
        popper-class="zm-base-address-selector"
        value-key="label"
    >
        <el-option
            v-for="(item, idx) in listResource"
            :key="idx"
            :label="item.label"
            :value="item"
        >
            <ItemAddressSelector
                :value="item"
                :multiple="multiple"
                @selectMembers="selectMembers"
                @selectAllMembers="selectAllMembers"
                @closed="
                    listResource = [];
                    $refs.refSelectResource.blur();
                "
            />
        </el-option>
    </el-select>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import ZimbraMailService from "@/services/ZimbraMailService.js";
export default {
    name: "BaseResourceSelector",
    mixins: [CommonUtils],
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        value: {
            type: [Array, Object, String],
            default() {
                return []
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: "medium"
        },
        multipleLimit: {
            type: Number,
            default: 0
        },
        placeholder: {
            type: String,
            default() {
                return this.$t('zimbraNokey.zimbra_sidebar_dialogShare_emailPlaceholder')
            }
        },
        autofocus: {
            type: Boolean,
            default: true
        },
        focusAfterClear: {
            type: Boolean,
            default: false
        },
        isRawValue: {
            type: Boolean,
            default: false
        },
    },
    components: {
    },
    data() {
        return {
            dataSelected: null,
            listResource: [],
            loaddingText: this.$t("zimbra.zmMsg.autocompleteWaiting"),
            loading: false,
        };
    },
    watch: {
        value(val) {
            this.dataSelected = val;
        }
    },
    mounted() {
        setTimeout(() => {
            this.autofocus && this.$refs.refSelectResource.focus();
        }, 100);
    },
    methods: {
        onchange() {
            this.$emit("change", this.dataSelected);
            const inputFocus = this.$refs.refSelectResource.$el.querySelector('.el-select__input.is-medium');
            if (inputFocus) {
                inputFocus.focus();
            }
        },
        getResponseBodyResource(response) {
            return response["data"]["soap:Envelope"]["soap:Body"][
                "AutoCompleteGalResponse"
            ]["match"];
        },
        remoteMethodResource(query) {
            if (query !== "") {
                const form = {
                    name: `${query}`,
                    limit: 20,
                    type: "resource",
                    needExp: 1
                };
                this.loading = true;
                ZimbraMailService.autoCompleteGalRequest(form)
                    .then((res) => {
                        const data = this.getResponseBodyResource(res);
                        this.listResource = data;
                        if (this.listResource) {
                            this.listResource = Array.isArray(this.listResource) ? this.listResource : [this.listResource];
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.listResource = [];
                        this.loading = false;
                    });
            } else {
                this.listResource = [];
            }
        },
        onclear() {
            if (this.focusAfterClear) {
                this.$refs.refSelectResource.focus();
            }
        },
        onblur() {
            this.$emit("onblur", this.dataSelected);
        }
    }
};
</script>