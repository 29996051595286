import i18n from "@/plugins/i18n";

export const BASE64_TO_NORMAL_RATIO = 1.34;

export const STATUS_REQUEST = {
    SUCCESS: 200,
    ALREADY_EXISTS: "mail.ALREADY_EXISTS",
    ACCOUNT_DATA_SOURCE_EXISTS:"account.DATA_SOURCE_EXISTS"
};

export const KEY_WORD = ['backspace', 'tab', 'enter', 'shift', 'ctrl', 'alt', 'pause/break', 'caps lock', 'escape', 'page up', 'page down', 'end', 'home', 'left arrow', 'up arrow', 'right arrow', 'down arrow', 'insert', 'delete', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'left window key', 'right window key', 'select key', 'numpad 0', 'numpad 1', 'numpad 2', 'numpad 3', 'numpad 4', 'numpad 5', 'numpad 6', 'numpad 7', 'numpad 8', 'numpad 9', 'multiply', 'add', 'subtract', 'decimal point', 'divide', 'f1', 'f2', 'f3', 'f4', 'f5', 'f6', 'f7', 'f8', 'f9', 'f10', 'f11', 'f12', 'num lock', 'scroll lock', 'semi-colon', 'equal sign', 'comma', 'dash', 'period', 'forward slash', 'grave accent', 'open bracket', 'back slash', 'close braket', 'single quote'];

export const SELECT_AFTER_DELETE = {
    PREVIOUS: "previous",
    NEXT: "next",
    ADAPTIVE: "adaptive"
};
export const ACTION_STATE = {
    HIDDEN: "HIDDEN", // ẩn
    DISABLED: "DISABLED", // vô hiệu hóa
    VIEW: "VIEW" // hiện
};
export const FOLDER_DEFAULT = {
    ROOT: 1,
    STARS: -3,
    TAG: -2,
    EXTEND: -1,
    INBOX: 2,
    TRASH: 3,
    JUNK: 4,
    SENT: 5,
    DRAFTS: 6,
    CALENDAR: 10,
    CONTACTS: 7,
    TASK: 15,
    BRIEFCASE: 16,
    MAILING_LIST: 12,
    EMAIL_CONTACT: 13,
};

export const FOLDER_TYPE = {
    TAG: "TAG",
    SEARCH: "SEARCH",
    FOLDER: "FOLDER",
    CHOOSE_TAG: "CHOOSE_TAG"
};

export const FOLDER_EXTRA_ACTION = {
    MOVE_FOLDER: "MOVE_FOLDER",
    MOVE_MAIL: "MOVE_MAIL",
    ARCHIVE_MAIL: "ARCHIVE_MAIL",
    MOVE_FOLDER_SEARCH: "MOVE_FOLDER_SEARCH",
    CHANGE_FOLDER_ARCHIVE: "CHANGE_FOLDER_ARCHIVE"
};

export const FOLDER_RENAME = [
    "Inbox",
    "Trash",
    "Junk",
    "Sent",
    "Drafts",
    "Contacts",
    "EmailedContacts",
    "DistributionList",
    "Searches",
    "Calendar",
    "Briefcase",
    "Stars",
    "Flagged",
    "Tag",
    "Emailed Contacts",
];
export const TIME_IN_DAYS = {
    DAY: 1,
    WEEK: 7,
    MONTH: 31,
    YEAR: 366
};
export const SIDEBAR_ACTION = {
    ADDFOLER: "ADDFOLER",
    SHAREFOLDER: "SHAREFOLDER",
    MOVEFOLDER: "MOVEFOLDER",
    RENAME: "RENAME",
    EDITFOLDER: "EDITFOLDER"
};

export const REQ_STATUS = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    WARNING: "WARNING"
};

export const SCREEN_MODE = {
    EMPTY: "EMPTY",
    VIEW: "VIEW",
    COMPOSE: "COMPOSE",
    REPLY: "REPLY",
    REPLY_ALL: "REPLY_ALL",
    DRAFT: "DRAFT",
    CREATE: "CREATE",
    EDIT: "EDIT",
    PROPOSE_NEW_TIME: "PROPOSE_NEW_TIME"
};

export const EMAIL_ACTION = {
    COMPOSE: "COMPOSE",
    COMPOSE_TO_SENDER: "COMPOSE_TO_SENDER",
    COMPOSE_CONTACT: "COMPOSE_CONTACT",
    FORWARD_CONTACT: "FORWARD_CONTACT",
    FORWARD_CONVERSATION: "FORWARD_CONVERSATION",
    EDIT_AS_NEW: "EDIT_AS_NEW",
    CREATE_FILTER: "CREATE_FILTER",
    CREATE_FILTER_EMAIL: "CREATE_FILTER_EMAIL", // Tạo filter từ email
    VIEW: "VIEW",
    REPLY: "REPLY",
    REPLY_ALL: "REPLY_ALL",
    FORWARD: "FORWARD",
    MARK_AS_UNREAD: "MARK_AS_UNREAD",
    MARK_AS_READ: "MARK_AS_READ",
    DRAFT: "DRAFT",
    PRINT: "PRINT",
    ARCHIVE: "ARCHIVE",
    MOVE: "MOVE",
    SPAM: "SPAM",
    UNSPAM: "UNSPAM",
    DELETE: "DELETE",
    STAR: "STAR",
    UNSTAR: "UNSTAR",
    ACCEPT_SHARE_FOLDER: "ACCEPT_SHARE_FOLDER",
    SHOW_ORIGINAL: "SHOW_ORIGINAL",
    MORE_ACTION: "MORE_ACTION",
    OPEN_NEW_WINDOW: "OPEN_NEW_WINDOW",
    CREATE_APPOITMENT: "CREATE_APPOITMENT", // Tạo cuộc hẹn
    CREATE_TASK: "CREATE_TASK",
    ASSIGN_TAG: "ASSIGN_TAG",
    REDIRECT: "REDIRECT",
    EDIT_REPLY_CAL: "EDIT_REPLY_CAL", // sửa câu trả lời cuộc hẹn
    SEND_AND_ARCHIVE: "SEND_AND_ARCHIVE",
    DELETE_IMMEDIATELY: "DELETE_IMMEDIATELY", // xóa và không có confirm hoàn tác
    DECLINE_NEW_TIME: "DECLINE_NEW_TIME", // Từ chối thời gian mới được đề xuất
    DELETE_VALID_RETENTION: "DELETE_VALID_RETENTION", // xóa hợp lệ trong thời gian duy trì
    DELETE_ALL_RETENTION: "DELETE_ALL_RETENTION", // xóa tất cả trong thời gian duy trì
    RELOAD_VIEW: "RELOAD_VIEW", // Reload thư đang xem,
    EDIT_CONTACT: "EDIT_CONTACT",
    DELETE_COMPLETELY: "DELETE_COMPLETELY", // xóa hẳn
    MOVE_STAR: "MOVE_STAR", // Chuyển tới thư mục gắn sao
    CLEAR_HIGH_LIGHT: "CLEAR_HIGH_LIGHT", // xóa tô sáng tìm kiếm
    VIEW_CONVERSATION: "VIEW_CONVERSATION", // hiển thị cuộc đàm thoại
    DELETE_APPT: "DELETE_APPT",
};

export const LIST_EMAIL_ACTION = [
    EMAIL_ACTION.DRAFT,
    EMAIL_ACTION.REPLY,
    EMAIL_ACTION.REPLY_ALL,
    EMAIL_ACTION.FORWARD,
    EMAIL_ACTION.FORWARD_CONVERSATION,
    EMAIL_ACTION.MARK_AS_UNREAD,
    EMAIL_ACTION.MARK_AS_READ,
    EMAIL_ACTION.ARCHIVE,
    EMAIL_ACTION.MOVE,
    EMAIL_ACTION.PRINT,
    EMAIL_ACTION.SPAM,
    EMAIL_ACTION.UNSPAM,
    EMAIL_ACTION.DELETE,
    EMAIL_ACTION.MORE_ACTION,
    EMAIL_ACTION.SHOW_ORIGINAL,
    EMAIL_ACTION.CREATE_FILTER,
    EMAIL_ACTION.CREATE_APPOITMENT,
    EMAIL_ACTION.CREATE_TASK,
    EMAIL_ACTION.EDIT_AS_NEW,
    EMAIL_ACTION.OPEN_NEW_WINDOW,
    EMAIL_ACTION.ASSIGN_TAG,
    EMAIL_ACTION.REDIRECT,
]

export const EMAIL_FLAG = {
    URGENT: "!", // priority high
    PRIORITY: "+", // priority middle
    LOW_PRIORITY: "?", // priority low
    UNREAD: "u", // unread
    FLAGGED: "f", // flagged
    ATTACHMENT: "a", // attachment
    REPLIED: "e", // replied
    SENT_BY_ME: "s", // sent by me
    FORWARD: "w", // forward
    DRAFT: "d", // draft,
    NOTIFICATION_SENT: "n", // notification_sent
    // extend
    READ: "read" // read
};
export const RIGHTS_FOLDER = {
    READ: "r",
    WRITE: "w",
    INSERT: "i",
    DELETE: "d",
    ADMINISTER: "a",
    WORKFLOW_ACTION: "x",
    VIEW_PRIVATE: "p",
    VIEW_FREEBUSY: "f",
    CREATE_SUBFOLDER: "c"
};
export const EMAIL_CONTENT_TYPE = {
    HTML: "html",
    PLAIN: "text"
};

export const EMAIL_CONTENT_TYPE_FULLNAME = {
    HTML: "text/html",
    PLAIN: "text/plain"
};

export const SIGNATURE_STYLE = {
    INTERNET: "internet",
    OUTLOOK: "outlook"
};

export const WORK_HOUR_SETTING = {
    NORMAL: 1,
    CUSTOMIZE: 2
};

export const EMAIL_FOLDER = {
    DRAFTS: "Drafts"
};

export const ADDRESS_TYPE = {
    FROM: "f",
    TO: "t",
    CC: "c",
    BCC: "b",
    REPLY: "r",
    TOCC: "tocc",
    SEND_AS: "s",
};

export const SIDEBAR_NODE = {
    TAG: "tag",
    FOLDER: "folder"
};

export const REPLY_TYPE = {
    REPLY: "r",
    FORWARD: "w"
};
export const FOLDER_ORDER = [
    {
        idFolder: FOLDER_DEFAULT.INBOX,
        order: 1
    },
    {
        idFolder: FOLDER_DEFAULT.STARS,
        order: 2
    },
    {
        idFolder: FOLDER_DEFAULT.DRAFTS,
        order: 3
    },
    {
        idFolder: FOLDER_DEFAULT.SENT,
        order: 4
    },
    {
        idFolder: FOLDER_DEFAULT.JUNK,
        order: 5
    },
    {
        idFolder: FOLDER_DEFAULT.TRASH,
        order: 6
    },
    {
        idFolder: FOLDER_DEFAULT.EXTEND,
        order: 1
    },
    {
        idFolder: FOLDER_DEFAULT.CONTACTS,
        order: 1
    },
    {
        idFolder: FOLDER_DEFAULT.MAILING_LIST,
        order: 2
    },
    {
        idFolder: FOLDER_DEFAULT.EMAIL_CONTACT,
        order: 3
    },
    {
        idFolder: FOLDER_DEFAULT.BRIEFCASE,
        order: 1
    }
];
export const SETTING_ZIMLET = {
    YMEMOTIONS: 1,
    MAIL_ARCHIVE: 2,
    OPEN_CHAT: 3,
    SRCH_HIGH_LIGHTER: 4,
    WEBEX: 5,
    DRIVE_OPEN: 6,
    PHONE: 7,
    UNDEFINED: -1
};
export const PERMISSION_SETTING = {
    FBUSY_ALLOW_ALL: 1,
    FBUSY_ALLOW_DOMAIN: 2,
    FBUSY_ALLOW_LOCAL: 3,
    FBUSY_ALLOW_NONE: 4,
    FBUSY_ALLOW_SOME: 5,
    INV_ALLOW_ALL: 1,
    INV_ALLOW_LOCAL: 2,
    INV_ALLOW_NONE: 3,
    INV_ALLOW_SOME: 4
}
export const ZmSetting = {
    PROTO_HTTP: "http",
    PROTO_HTTPS: "https",
    SEND_AS: "sendAs",
    SEND_ON_BEHALF_OF: "sendOnBehalfOf",
    POP3: "pop3",
    IMAP: "imap",
    POP_PORT: 110,
    POP_SSL: 995,
    IMAP_PORT: 143,
    IMAP_SSL: 993,
    SSL: "ssl",
    CLEAR_TEXT: "cleartext"
};

export const ZmContact = {
    F_anniversary: "anniversary",
    F_assistantPhone: "assistantPhone",
    F_attachment: "attachment",
    F_birthday: "birthday",
    F_callbackPhone: "callbackPhone",
    F_carPhone: "carPhone",
    F_company: "company",
    F_companyPhone: "companyPhone",
    F_custom: "custom",
    F_description: "description",
    F_department: "department",
    F_dlist: "dlist",
    F_dlDisplayName: "dldisplayname",
    F_dlDesc: "dldesc",
    F_dlHideInGal: "dlhideingal",
    F_dlNotes: "dlnotes",
    F_dlSubscriptionPolicy: "dlsubspolicy",
    F_dlMailPolicy: "dlmailpolicy",
    F_dlMailPolicySpecificMailers: "dlmailpolicyspecificmailers",
    F_dlUnsubscriptionPolicy: "dlunsubspolicy",
    F_dlListOwners: "dllistowners",
    F_email: "email",
    F_email2: "email2",
    F_email3: "email3",
    F_email4: "email4",
    F_email5: "email5",
    F_email6: "email6",
    F_email7: "email7",
    F_email8: "email8",
    F_email9: "email9",
    F_email10: "email10",
    F_email11: "email11",
    F_email12: "email12",
    F_email13: "email13",
    F_email14: "email14",
    F_email15: "email15",
    F_email16: "email16",
    F_fileAs: "fileAs",
    F_firstName: "firstName",
    F_folderId: "folderId",
    F_groups: "groups",
    F_homeCity: "homeCity",
    F_homeCountry: "homeCountry",
    F_homeFax: "homeFax",
    F_homePhone: "homePhone",
    F_homePhone2: "homePhone2",
    F_homePostalCode: "homePostalCode",
    F_homeState: "homeState",
    F_homeStreet: "homeStreet",
    F_homeURL: "homeURL",
    F_image: "image",
    F_imAddress: "imAddress",
    F_imAddress1: "imAddress1",
    F_imAddress2: "imAddress2",
    F_imAddress3: "imAddress3",
    F_jobTitle: "jobTitle",
    F_lastName: "lastName",
    F_maidenName: "maidenName",
    F_memberC: "memberC",
    F_memberG: "memberG",
    F_memberI: "memberI",
    F_middleName: "middleName",
    F_mobilePhone: "mobilePhone",
    F_namePrefix: "namePrefix",
    F_nameSuffix: "nameSuffix",
    F_nickname: "nickname",
    F_notes: "notes",
    F_otherCity: "otherCity",
    F_otherCountry: "otherCountry",
    F_otherFax: "otherFax",
    F_otherPhone: "otherPhone",
    F_otherPostalCode: "otherPostalCode",
    F_otherState: "otherState",
    F_otherStreet: "otherStreet",
    F_otherURL: "otherURL",
    F_pager: "pager",
    F_phoneticFirstName: "phoneticFirstName",
    F_phoneticLastName: "phoneticLastName",
    F_phoneticCompany: "phoneticCompany",
    F_type: "type",
    F_workAltPhone: "workAltPhone",
    F_workCity: "workCity",
    F_workCountry: "workCountry",
    F_workEmail1: "workEmail1",
    F_workEmail2: "workEmail2",
    F_workEmail3: "workEmail3",
    F_workFax: "workFax",
    F_workMobile: "workMobile",
    F_workPhone: "workPhone",
    F_workPhone2: "workPhone2",
    F_workPostalCode: "workPostalCode",
    F_workState: "workState",
    F_workStreet: "workStreet",
    F_workURL: "workURL",
    F_imagepart: "imagepart",
    F_zimletImage: "zimletImage",
    X_fileAs: "fileAs",
    X_firstLast: "firstLast",
    X_fullName: "fullName",
    X_vcardXProps: "vcardXProps",
    X_outlookUserField: "outlookUserField",
    MC_cardOwner: "cardOwner",
    MC_workCardMessage: "workCardMessage",
    MC_homeCardMessage: "homeCardMessage",
    MC_homePhotoURL: "homePhotoURL",
    MC_workPhotoURL: "workPhotoURL",
    GROUP_CONTACT_REF: "C",
    GROUP_GAL_REF: "G",
    GROUP_INLINE_REF: "I",
    URL: "/Contacts",
    URL_ARGS: { fmt: 'cf', t: 2, all: 'all' },
    CONTACT_SPLIT_CHAR: "\u001E",
    FIELD_SPLIT_CHAR: "\u001D",
    IS_CONTACT_FIELD: { "id": true, "l": true, "d": true, "fileAsStr": true, "rev": true }
};

export const ERROR_CODE = {
    // account
    ACCOUNT_AUTH_FAILED: "account.AUTH_FAILED",

    // service
    SERVICE_AUTH_EXPIRED: "service.AUTH_EXPIRED",
    SERVICE_AUTH_REQUIRED: "service.AUTH_REQUIRED",
    SERVICE_RESOURCE_UNREACHABLE: "service.RESOURCE_UNREACHABLE",
    SERVICE_PARSE_ERROR: "service.PARSE_ERROR",
    INVALID_REQUEST: "service.INVALID_REQUEST",

    // mail
    QUERY_PARSE_ERROR: "mail.QUERY_PARSE_ERROR",
    NO_SUCH_FOLDER: "mail.NO_SUCH_FOLDER",
    MAIL_QUOTA_EXCEEDED: "mail.QUOTA_EXCEEDED"
};

export const SORT_BY = {
    FROM: "name",
    SUBJECT: "subj",
    SIZE: "size",
    DATE: "date",
    ATTACHMENT: "attach",
    FLAG: "flag",
    PRIORITY: "priority",
    READ_UNREAD: "read"
};

export const SORT_BY_DATASOURCE = [
    { label: i18n.t("zimbra.zhMsg.FILT_COND_HEADER_from"), value: SORT_BY.FROM },
    { label: i18n.t("zimbra.zhMsg.FILT_COND_HEADER_subject"), value: SORT_BY.SUBJECT },
    { label: i18n.t("zimbra.zhMsg.MO_size"), value: SORT_BY.SIZE },
    { label: i18n.t("zimbra.zhMsg.calViewDayShort"), value: SORT_BY.DATE },
    { label: i18n.t("zimbra.zhMsg.ALT_ATTACHMENT"), value: SORT_BY.ATTACHMENT },
    { label: i18n.t("zimbra.zhMsg.MO_flag"), value: SORT_BY.FLAG },
    { label: i18n.t("zimbra.zhMsg.ALT_PRIORITY"), value: SORT_BY.PRIORITY },
    { label: i18n.t("zimbra.zmMsg.readUnread"), value: SORT_BY.READ_UNREAD }
];

export const SORT_BY_BRIEFCASE = [
    { label: i18n.t("zimbra.zhMsg.AB_FIELD_firstName"), value: SORT_BY.SUBJECT },
    { label: i18n.t("zimbra.zhMsg.MO_size"), value: SORT_BY.SIZE },
    { label: i18n.t("zimbra.zhMsg.calViewDayShort"), value: SORT_BY.DATE },
]

export const EMAIL_TYPE = {
    CONVERSATION: "conversation",
    MESSAGE: "message"
};

export const CONV_ORDER = {
    DATE_DESC: "dateDesc",
    DATE_ASC: "dateAsc"
};

export const GROUP_BY = {
    NONE: "NONE",
    DATE: "DATE",
    SIZE: "SIZE",
    FROM: "FROM"
};

export const GROUP_BY_DATASOURCE = [
    { label: i18n.t("zimbra.zhMsg.no"), value: GROUP_BY.NONE },
    { label: i18n.t("zimbra.zhMsg.calViewDayShort"), value: GROUP_BY.DATE },
    { label: i18n.t("zimbra.zhMsg.FILT_COND_HEADER_from"), value: GROUP_BY.FROM },
    { label: i18n.t("zimbra.zhMsg.MO_size"), value: GROUP_BY.SIZE }
];

export const SORT_DIRECT = {
    ASC: "Asc",
    DESC: "Desc"
};

export const SETTING_MAIL = {
    INCLUDE_NONE: "includeNone",
    INCLUDE_BODY: "includeBody",
    INCLUDE_SMART: "includeSmart",
    INCLUDE_AS_ATTACHMENT: "includeAsAttachment",
    WITH_PREFIX: "WithPrefix",
    AND_PREFIX: "AndHeaders"
};
export const FILTER_ACTION_TYPE = {
    INCOMING_PREFIX: "_incoming",
    OUTGOING_PREFIX: "_outgocoming",
    ADD_FILTER: "addFilter",
    EDIT_FILTER: "editFilter",
    ACTIVITY_STREAM: 0,
    INCOMING_FILTER: 1,
    OUTGOING_FILTER: 2
};
export const FILTER_TESTS = {
    ADDRESS_BOOK_TEST: "addressBookTest",
    ADDRESS_TEST: "addressTest",
    HEADER_TEST: "headerTest",
    CONVERSATION_TEST: "conversationTest",
    BULK_TEST: "bulkTest",
    LIST_TEST: "listTest",
    FLAGGED_TEST: "flaggedTest",
    IMPOTAMCE_TEST: "impotamceTest",
    SIZE_TEST: "sizeTest",
    DATE_TEST: "dateTest",
    BODY_TEST: "bodyTest",
    ATTACHMENT_TEST: "attachmentTest",
    MIME_HEADER_TEST: "mimeHeaderTest",
    CONTACT_RANK_TEST: "contactRankingTest",
    ME_TEST: "meTest",
    INVITE_TEST: "inviteTest",
    FACEBOOK_TEST: "facebookTest",
    LINKEDIN_TEST: "linkedinTest",
    TWITTER_TEST: "twitterTest",
    HEADER_EXITS_TEST: "headerExitsTest"
};
export const FILTER_TEST_CONFIG = {
    VALUE: "message/disposition-notification",
    NEGATIVE: 1,
    TYPE: {
        CONTACT: "contacts",
        EMAIL: "email"
    },
    ANYOF: "anyof",
    ALLOF: "allof",
    HEADER: {
        NAME: "header",
        FROM: "from",
        TO: "to",
        CC: "cc",
        TO_CC: "to,cc",
        CC_TO: "cc,to",
        SUBJECT: "subject",
        CONTENT_TYPE: "Content-Type",
        STRING: "string"
    },
    PART: {
        ALL: "all",
        LOCALPART: "localpart",
        DOMAIN: "domain"
    },
    STRING_COMPARTION: {
        IS: "is",
        IN: "in",
        IS_ME: "isme",
        CONTAINS: "contains",
        MATHCHES: "matches",
        EXIST: "exist",
        NOT_IS: "notis",
        NOT_IN: "notin",
        NOT_ME: "notme",
        NOT_CONTAINS: "notcontains",
        NOT_MATHCHES: "notmatches",
        NOT_EXIST: "notexist"
    },
    WHERE: {
        STARTED: "started",
        PARTICIPATED: "participated"
    },
    FLAG_NAME: {
        READ: "read",
        PRIORITY: "priority",
        FLAGGED: "flagged"
    },
    IMP: {
        HIGHT: "hight",
        NORMAL: "normal",
        LOW: "low",
        FLAGGED: "flagged"
    },
    NUMBER_COMPARISON: {
        UNDER: "under",
        OVER: "over",
        NOT_UNDER: "notunder",
        NOT_OVER: "notover",
        S: {
            B: "",
            KB: "K",
            MB: "M",
            GB: "G"
        }
    },
    DATE_COMPARTISON: {
        BEFORE: "before",
        AFTER: "after",
        NOT_BEFORE: "notbefore",
        NOT_AFTER: "notafter"
    },
    METHOD: {
        ANY_REQUEST: "anyrequest",
        ANY_REPLY: "anyreply",
        NOT_ANY_REQUEST: "notanyrequest",
        NOT_ANY_REPLY: "notanyreply"
    },
    ATTACHMENT_COMPARTISON: {
        EXIST: "exist",
        NOT_EXIST: "notexist"
    },
    BODY_COMPARTISON: {
        CONTAIN: "contain",
        NOT_CONTAIN: "notcontain"
    },
    SOCIAL: {
        FACEBOOK: "facebook",
        LINKEDIN: "linkedin",
        TWITTER: "twitter"
    }
};
export const FILTER_ACTION = {
    ACTION_KEEP: "actionKeep",
    ACTION_DISCARD: "actionDiscard",
    ACTION_FILE_INTO: "actionFileInto",
    ACTION_TAG: "actionTag",
    ACTION_FLAG: "actionFlag",
    ACTION_REDIRECT: "actionRedirect",
    ACTION_STOP: "actionStop",
    ACTION_FLAG_READ: "read",
    ACTION_FLAG_FLAGGED: "flagged"
};

export const SEND_MAIL_OPTIONS = {
    SEND_NO_MAIL: "1",
    SEND_STANDARD_MAIL: "2",
    SEND_STANDARD_MAIL_WITH_NOTE: "3"
};

export const SEND_MAIL_DATASOURCE = [
    {
        label: i18n.t("zimbra.zmMsg.sendNoMailAboutShare"),
        value: SEND_MAIL_OPTIONS.SEND_NO_MAIL
    },
    {
        label: i18n.t("zimbra.zmMsg.sendStandardMailAboutShare"),
        value: SEND_MAIL_OPTIONS.SEND_STANDARD_MAIL
    },
    {
        label: i18n.t("zimbra.zmMsg.sendStandardMailAboutSharePlusNote"),
        value: SEND_MAIL_OPTIONS.SEND_STANDARD_MAIL_WITH_NOTE
    }
];

export const FILTER_SEARCH = {
    HEADER: [
        {
            n: "List-ID"
        },
        {
            n: "X-Zimbra-DL"
        },
        {
            n: "IN-REPLY-TO"
        }
    ],
    NEED_EXP: 1,
    OFFSET: 0,
    RESULT_MODE: "IDS",
    SORT_BY: {
        DATE_DESC: "dateDesc",
        DATE_ASC: "dateAsc"
    }
};
export const OUT_OF_OFFICE = {
    //ST: start , ED: end,DF: default , AT: auto, CT: custom, GR: group,ADDS: address
    ST_TIME_DF: "00:00:00",
    ED_TIME_DF: "23:59:59",
    AT_STANDARD: 0,
    CT_NOT_IN_GR: 1,
    CT_NOT_IN_GR_ADDS: 2,
    NOT_AT_OUT: 3
}

export const ACCEPT_TYPE = {
    TYPE_CSV: "csv",
    TYPE_VCF: "vcf",
    TYPE_ICS: "ics",
    TYPE_TGZ: "tgz",
    TYPE_ZIP: "zip"
}
export const COMPOSE_INCLUDE = {
    NONE: "none",
    BODY: "body",
    SMART: "smart",
    AS_ATTACH: "asAttach",
}
export const ZmAccount = {
    VIEW_ACCOUNT: "view-account",
    VIEW_SETTING_ACCOUNT_PRIMARY: "view-setting-account-primary",
    VIEW_SETTING_ACCOUNT_PERSONAL: "view-setting-account-personal",
    VIEW_SETTING_ACCOUNT_EXTERNAL: "view-setting-account-external"
}
export const IMPORT_EXPORT = {
    FOLDER_DEFAULT: {
        INBOX: "#1",
        CONTACTS: "#2",
        CALENDAR: "#3"
    },
    TYPE: {
        IMPORT: "import",
        EXPORT: "export",
        ACCOUNT: "ACCOUNT",
        CALENDAR: "CALENDAR",
        CONTACT: "CONTACT",
    },
    RESOLVE: {
        NONE: "",
        MODIFY: "modify",
        REPLACE: "replace",
        RESET: "reset",
    },
    MESSAGE: 1,
    CONTACT: 2,
    CALENDAR: 3,
    APPOINTMENT: 4,
    BRIEFCASE: 5
}
export const MESSAGE_CONTENT_TYPE = {
    RFC822: "message/rfc822",
    ALTERNATIVE: "multipart/alternative"
}
export const CONTACT_ACTION = {
    VIEW: "VIEW",
    SCREEN_CONTACT: "SCREEN_CONTACT",
    SCREEN_ADD_CONTACT: "SCREEN_ADD_CONTACT",
    COMPOSE: "COMPOSE",
    ADD_CONTACT: "ADD_CONTACT",
    EDIT_CONTACT: "EDIT_CONTACT",
    VIEW_CONTACT: "VIEW_CONTACT",
    ADD_GROUP_CONTACT: "ADD_GROUP_CONTACT",
    ADD_APPOINTMENT: "ADD_APPOINTMENT",
    ADD_TASK: "ADD_TASK",
    ADD_DOCUMENT: "ADD_DOCUMENT",
    ADD_FOLDER: "ADD_FOLDER",
    ADD_TAG: "ADD_TAG",
    ADD_CONTACT_FOLDER: "ADD_CONTACT_FOLDER",
    ADD_CALENDAR_FODLER: "ADD_CALENDAR_FODLER",
    ADD_TASK_FOLDER: "ADD_TASK_FOLDER",
    ADD_BRIEFCASE_FOLDER: "ADD_BRIEFCASE_FOLDER",
    ADD_TAG_TO_CONTACT: "ADD_TAG_TO_CONTACT",
    FIND_EMAIL: "FIND_EMAIL",
    FORWARD_CONTACT: "FORWARD_CONTACT",
    MOVE_CONTACT: "MOVE_CONTACT",
    VIEW_MAILING_LIST: "VIEW_MAILING_LIST",
    VIEW_CONTACT_GROUP_LIST: "VIEW_CONTACT_GROUP_LIST",
    SEND_EMAIL: "SEND_EMAIL",
}
export const LIST_CONTACT_ACTION = [
    CONTACT_ACTION.FIND_EMAIL,
    CONTACT_ACTION.FORWARD_CONTACT,
    CONTACT_ACTION.MOVE_CONTACT,
    CONTACT_ACTION.ADD_CONTACT

]
export const CONTACT_CONTENT_NAME = {
    PREFIX: "namePrefix",
    FIRST: "firstName",
    MIDDLE: "middleName",
    MAIDEN: "maidenName",
    LAST: "lastName",
    SUFFIX: "nameSuffix",
    NICK_NAME: "nickname",
    JOB_TITLE: "jobTitle",
    DEPARTMENT: "department",
    COMPANY: "company",
    FILEAS: "fileAs",
}

// Calendar start
export const CALENDAR_VIEW_MODE = {
    DAY: 1,
    WEEK_WORK: 2,
    WEEK: 3,
    MONTH: 4,
    LIST: 5,
}
export const CALENDAR_VIEW_MODE_ZIMBRA = {
    DAY: "day",
    WEEK_WORK: "workWeek",
    WEEK: "week",
    MONTH: "month",
    LIST: "list",
}
export const CALENDAR_VIEW_MODE_STR = {
    DAY: "timeGridDay",
    WEEK_WORK: "timeGridWeek",
    WEEK: "timeGridWeek",
    MONTH: "dayGridMonth",
    LIST: "listWeek",
}
export const CALENDAR_FREE_BUSY_TYPE = {
    FREE: "F", // Rảnh
    BUSY_TENTATIVE: "T", // Chưa dứt khoát
    BUSY: "B", // Bận
    OUT_OF_OFFICE: "O", // Ra khỏi văn phòng,
    NON_WORKING: "NK", // Không làm việc
    UNKNOWN: "U" // Không xác định
}
export const CALENDAR_RESOURE_TYPE = {
    REQUIRED_ATTENDEE: 1, // Người tham dự bắt buộc
    OPTIONAL_ATTENDEE: 2, // Người tham dự tùy chọn
    LOCATION_LABEL: 3, // Địa điểm
    EQUIPMENT_LABEL: 4, // Thiết bị
}
export const DATASOURCE_CALENDAR_RESOURE_TYPE = [
    {
        value: CALENDAR_RESOURE_TYPE.REQUIRED_ATTENDEE,
        label: i18n.t("zimbra.zmMsg.requiredAttendee"), // Người tham dự bắt buộc
    },
    {
        value: CALENDAR_RESOURE_TYPE.OPTIONAL_ATTENDEE,
        label: i18n.t("zimbra.zmMsg.optionalAttendee"), // Người tham dự tùy chọn
    },
    {
        value: CALENDAR_RESOURE_TYPE.LOCATION_LABEL,
        label: i18n.t("zimbra.zhMsg.location"), // Địa điểm
    },
    {
        value: CALENDAR_RESOURE_TYPE.EQUIPMENT_LABEL,
        label: i18n.t("zimbra.zmMsg.equipmentAttendee"), // Thiết bị
    },
]
export const DATASOURCE_FREE_BUSY = [
    {
        value: CALENDAR_FREE_BUSY_TYPE.FREE,
        label: i18n.t("zimbra.zhMsg.free"),
        color: "#56ccf2"
    },
    {
        value: CALENDAR_FREE_BUSY_TYPE.BUSY_TENTATIVE,
        label: i18n.t("zimbra.zhMsg.apptPtstTE"),
        color: "#5d5fef"
    },
    {
        value: CALENDAR_FREE_BUSY_TYPE.BUSY,
        label: i18n.t("zimbra.zhMsg.busy"),
        color: "#eb5757"
    },
    {
        value: CALENDAR_FREE_BUSY_TYPE.OUT_OF_OFFICE,
        label: i18n.t("zimbra.zhMsg.outOfOffice"),
        color: "#6fcf97"
    },
];
export const DATASOURCE_FREE_BUSY_FULL = [
    {
        value: CALENDAR_FREE_BUSY_TYPE.FREE,
        label: i18n.t("zimbra.zhMsg.free"),
        color: "#56ccf2"
    },
    {
        value: CALENDAR_FREE_BUSY_TYPE.NON_WORKING,
        label: i18n.t("zimbra.zmMsg.nonWorking"),
        color: "#e3e3dc"
    },
    {
        value: CALENDAR_FREE_BUSY_TYPE.BUSY,
        label: i18n.t("zimbra.zhMsg.busy"),
        color: "#eb5757"
    },
    {
        value: CALENDAR_FREE_BUSY_TYPE.BUSY_TENTATIVE,
        label: i18n.t("zimbra.zhMsg.apptPtstTE"),
        color: "#5d5fef"
    },
    {
        value: CALENDAR_FREE_BUSY_TYPE.UNKNOWN,
        label: i18n.t("zimbra.zmMsg.mailSizeUnknown"),
        color: "#bb6bd9"
    },
    {
        value: CALENDAR_FREE_BUSY_TYPE.OUT_OF_OFFICE,
        label: i18n.t("zimbra.zhMsg.outOfOffice"),
        color: "#6fcf97"
    },
];
export const FOLDER_VIEW = {
    APPOINTMENT: "appointment",
    MESSAGE: "message",
    DOCUMENT: "document",
    CONTACT: "contact",
    TASK: "task",
    SEARCH: "search",
    BRIEFCASE: "briefcase",
}

export const ICON_FOLDER_DEFAULT = [
    [FOLDER_DEFAULT.INBOX, "ic-inbox"],
    [FOLDER_VIEW.APPOINTMENT, "fi-share fi-rr-calendar"],
    [FOLDER_VIEW.CONTACT, "ic-user-square-sz16"],
    [FOLDER_DEFAULT.EMAIL_CONTACT, "ic-user-square-sz16"],
    [FOLDER_DEFAULT.DRAFTS, "ic-draft"],
    [FOLDER_DEFAULT.TASK, "fi-share fi-rr-apps-add"],
    [FOLDER_DEFAULT.JUNK, "ic-spam"],
    [FOLDER_DEFAULT.TRASH, "ic-trash"],
    [FOLDER_DEFAULT.SENT, "ic-sent"],
];

export const CALENDAR_FREQ_TYPE = { // Lặp lại
    NONE: "NON", // Không lặp
    DAILY: "DAI", // Hàng ngày
    WEEKLY: "WEE", // Hàng tuần
    MONTHLY: "MON", // Hàng tháng
    YEARLY: "YEA", // Hàng năm
    CUSTOM: "CUS", // Tùy chỉnh
}
export const DATASOURCE_FREQ_TYPE = [
    {
        value: CALENDAR_FREQ_TYPE.NONE,
        label: i18n.t("zimbra.zhMsg.no"),
    },
    {
        value: CALENDAR_FREQ_TYPE.DAILY,
        label: i18n.t("zimbra.zhMsg.recurBasicSelectDaily"),
    },
    {
        value: CALENDAR_FREQ_TYPE.WEEKLY,
        label: i18n.t("zimbra.zhMsg.recurBasicSelectWeekly"),
    },
    {
        value: CALENDAR_FREQ_TYPE.MONTHLY,
        label: i18n.t("zimbra.zhMsg.recurBasicSelectMonthly"),
    },
    {
        value: CALENDAR_FREQ_TYPE.YEARLY,
        label: i18n.t("zimbra.zhMsg.recurBasicSelectYearly"),
    },
];
export const REMINDER_TIME = [
    { value: -1, label: i18n.t("zimbra.zmMsg.apptRemindNever") },
    { value: 0, label: i18n.t("zimbra.zmMsg.apptRemindAtEventTime") },
    { value: 1, label: `1 ${i18n.t("zimbra.zmMsg.minute")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 5, label: `5 ${i18n.t("zimbra.zmMsg.minute")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 10, label: `10 ${i18n.t("zimbra.zmMsg.minute")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 15, label: `15 ${i18n.t("zimbra.zmMsg.minute")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 30, label: `30 ${i18n.t("zimbra.zmMsg.minute")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 45, label: `45 ${i18n.t("zimbra.zmMsg.minute")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 60, label: `60 ${i18n.t("zimbra.zmMsg.minute")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 120, label: `2 ${i18n.t("zimbra.ztMsg.hour")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 180, label: `3 ${i18n.t("zimbra.ztMsg.hour")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 240, label: `4 ${i18n.t("zimbra.ztMsg.hour")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 300, label: `5 ${i18n.t("zimbra.ztMsg.hour")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 1080, label: `18 ${i18n.t("zimbra.ztMsg.hour")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 1440, label: `1 ${i18n.t("zimbra.zmMsg.days")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 2880, label: `2 ${i18n.t("zimbra.zmMsg.days")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 4320, label: `3 ${i18n.t("zimbra.zmMsg.days")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 5760, label: `4 ${i18n.t("zimbra.zmMsg.days")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 10080, label: `1 ${i18n.t("zimbra.zmMsg.viewCalForWeek")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: 20160, label: `2 ${i18n.t("zimbra.zmMsg.viewCalForWeek")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
];

export const SNOOZE_TIME = [
    { value: 1, label: `1 ${i18n.t("zimbra.zmMsg.minute")}` },
    { value: 5, label: `5 ${i18n.t("zimbra.zmMsg.minute")}` },
    { value: 10, label: `10 ${i18n.t("zimbra.zmMsg.minute")}` },
    { value: 15, label: `15 ${i18n.t("zimbra.zmMsg.minute")}` },
    { value: 30, label: `30 ${i18n.t("zimbra.zmMsg.minute")}` },
    { value: 45, label: `45 ${i18n.t("zimbra.zmMsg.minute")}` },
    { value: 60, label: `60 ${i18n.t("zimbra.zmMsg.minute")}` },
    { value: 120, label: `2 ${i18n.t("zimbra.ztMsg.hour")}` },
    { value: 240, label: `4 ${i18n.t("zimbra.ztMsg.hour")}` },
    { value: 480, label: `8 ${i18n.t("zimbra.ztMsg.hour")}` },
    { value: 1440, label: `1 ${i18n.t("zimbra.zmMsg.days")}` },
    { value: 2880, label: `2 ${i18n.t("zimbra.zmMsg.days")}` },
    { value: 4320, label: `3 ${i18n.t("zimbra.zmMsg.days")}` },
    { value: 5760, label: `4 ${i18n.t("zimbra.zmMsg.days")}` },
    { value: 10080, label: `1 ${i18n.t("zimbra.zmMsg.viewCalForWeek")} ` },
];

export const REMINDER_SNOOZE_TIME = [
    { value: 0, label: i18n.t("zimbra.zmMsg.apptRemindAtEventTime") },
    { value: -1, label: `1 ${i18n.t("zimbra.zmMsg.minute")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: -5, label: `5 ${i18n.t("zimbra.zmMsg.minute")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: -10, label: `10 ${i18n.t("zimbra.zmMsg.minute")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: -15, label: `15 ${i18n.t("zimbra.zmMsg.minute")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: -30, label: `30 ${i18n.t("zimbra.zmMsg.minute")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: -45, label: `45 ${i18n.t("zimbra.zmMsg.minute")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: -60, label: `60 ${i18n.t("zimbra.zmMsg.minute")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: -120, label: `2 ${i18n.t("zimbra.ztMsg.hour")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: -240, label: `4 ${i18n.t("zimbra.ztMsg.hour")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: -480, label: `8 ${i18n.t("zimbra.ztMsg.hour")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: -1440, label: `1 ${i18n.t("zimbra.zmMsg.days")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: -2880, label: `2 ${i18n.t("zimbra.zmMsg.days")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: -4320, label: `3 ${i18n.t("zimbra.zmMsg.days")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: -5760, label: `4 ${i18n.t("zimbra.zmMsg.days")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
    { value: -10080, label: `1 ${i18n.t("zimbra.zmMsg.viewCalForWeek")} ${i18n.t("zimbra.zmMsg.beforeLc")}` },
];

export const DEFAULT_FORM_SEARCH_CAL = { // Các giá trị default form search
    LIMIT: "500", // Không lặp
    TYPE: "appointment",
    OFFSET: 0,
    SORT_BY: "none",
}

export const DEFAULT_RANGE_SEARCH_CAL = 14;

export const FLAG_FOLDER = { // Giá trị trạng thái của folder
    CHECKED: "#", // check lịch
    EXCLUDE: "b", // Không liệt kê cả bận
    IMAP: "*",
    INHERIT: "i",
    SYNC: "y",
    TURN_ON: "~",
    NOT_ALLOW: "o",
}

export const CALENDAR_RIGHT_MENU_TYPE = {
    DAY: 1,
    EVENT: 2,
    TREE_CALENDAR: 3,
    TREE_CALENDAR_ROOT: 4,
    EVENT_TRASH: 5,
    TREE_CALENDAR_TRASH: 6,
    EVENT_RECURRING: 7,
    SEARCH_FOLDER: 8,
    WEBEX: 9,
    TREE_CALENDAR_CALENDAR: 10,
    MAIL_ARCHIVE: 11,
    YMEMOTIONS: 12
}

export const BRIEFCASE_RIGHT_MENU_TYPE = {
    TREE_BRIEFCASE_ROOT: 1,
    TREE_BRIEFCASE: 2,
    TREE_BRIEFCASE_TRASH: 3,
    TREE_BRIEFCASE_BRIEFCASE: 4,
    WEBEX: 9,
    MAIL_ARCHIVE: 11,
    YMEMOTIONS: 12
}

export const BRIEFCASE_ACTION = {
    OPEN_FILE: "OPEN_FILE",
    DOWNLOAD: "DOWNLOAD",
    EDIT: "EDIT",
    SEND_LINK: "SEND_LINK",
    SEND_EMAIL: "SEND_EMAIL",
    SEND_AS_ATTACHMENTS: "SEND_AS_ATTACHMENTS",
    REGISTER_FILE: "REGISTER_FILE",
    CHECKOUT_FILE: "CHECKOUT_FILE",
    CHECKOUT_FILE_DISCARD: "CHECKOUT_FILE_DISCARD",
    RESTORE_CURRENT_VERSION: "RESTORE_CURRENT_VERSION",
    ASSIGN_TAG: "ASSIGN_TAG",
    DELETE: "DELETE",
    MOVE: "MOVE",
    RENAME: "RENAME",
    OPEN_NEW_WINDOW: "OPEN_NEW_WINDOW"
}

export const DEFAULT_DURATION_SECOND = 1800;

export const ITEM_ACTION = {
    MOVE: "move",
    DELETE: "delete",
    UPDATE: "update",
    TAG: "tag",
    REMOVE_TAG: "!tag",
};

export const FOLDER_ACTION = {
    MOVE: "move",
    CHECK: "check",
    UN_CHECK: "!check",
    TRASH: "trash",
    DELETE: "delete",
    RENAME: "rename",
    COLOR: "color",
    FB: "fb",
};

export const ROOT_CALENDAR_FOLDER_ID = 1;
export const ROOT_BRIEFCASE_FOLDER_ID = 1;
export const CALENDAR_TYPE_MOVE = {
    APPOINTMENT: 1,
    CALENDAR: 2,
}

export const LIST_TYPE_ATTACHMENT_FILE = [
    {
        value: "application/pdf",
        label: "zimbra.zmMsg.adobePdfDocument",
        icon: "far fa-file-pdf",
    },
    {
        value: "text/html",
        label: "zimbra.zmMsg.htmlDocument",
        icon: "	far fa-file-code",
    },
    {
        value: "image/*",
        label: "zimbra.zmMsg.image",
        icon: "far fa-file-image",
    },
    {
        value: "excel",
        label: "zimbra.zmMsg.msExcelDocument",
        icon: "far fa-file-excel",
    },
    {
        value: "word",
        label: "zimbra.zmMsg.msWordDocument",
        icon: "far fa-file-word",
    },
    {
        value: "message/rfc822",
        label: "zimbra.zmMsg.mail",
        icon: "fa fa-envelope",
    },
    {
        value: "text",
        label: "zimbra.zmMsg.textDocuments",
        icon: "far fa-file-alt",
    },
    {
        value: "application/zip OR attachment:application/x-zip-compressed",
        label: "zimbra.zmMsg.zipFile",
        icon: "	far fa-file-archive",
    },
    {
        value: "application/*",
        label: "zimbra.zmMsg.application",
        icon: "fa fa-cube",
    },
]

export const LIST_TYPE_SEARCH = {
    DATE: "DATE",
    SIZE: "SIZE",
    TAG: "TAG",
    FOLDER: "FOLDER",
}

export const TYPE_DAY_SEARCH = {
    BEFORE: "BEFORE",
    AFTER: "AFTER",
    ON: "ON"
}

export const LIST_TYPE_DAY_SEARCH = [
    {
        value: TYPE_DAY_SEARCH.BEFORE,
        label: "zimbra.zmMsg.filterBefore",
    },
    {
        value: TYPE_DAY_SEARCH.AFTER,
        label: "zimbra.zmMsg.filterAfter",
    },
    {
        value: TYPE_DAY_SEARCH.ON,
        label: "zimbra.zmMsg.filterOn",
    }
]

export const TYPE_SIZE_SEARCH = {
    SMALLER: "SMALLER",
    LARGER: "LARGER",
}

export const LIST_TYPE_SIZE_SEARCH = [
    {
        value: TYPE_SIZE_SEARCH.LARGER,
        label: "zimbra.zmMsg.filterLarger",
    },
    {
        value: TYPE_SIZE_SEARCH.SMALLER,
        label: "zimbra.zmMsg.filterSmaller",
    }
]

export const LIST_FORMAT_SIZE_SEARCH = [
    {
        value: "KB",
        label: i18n.t("zimbra.zhMsg.kb")
    },
    {
        value: "MB",
        label: i18n.t("zimbra.zhMsg.mb")
    }
]
/**
 * Loại điều kiện tìm kiếm
 */
export const TYPE_CONDITION_SEARCH = {
    DAY: 1,
    FILE: 2,
    TAG: 3,
    CALENDAR: 4,
}

/**
 * loại thông báo khi reply
 */
export const CALENDAR_TYPE_NOTIFICATION_REPLY = {
    DELETE: 1,
    DECLINE: 2,
}


// Các loại tạo lịch ngoài
export const EXTERNAL_CALENDAR_TYPE = {
    YAHOO_CALENDAR: "yahooCalendar",
    EXTERNAL_CALENDAR: "externalCalendar",
}

// Các form cuộc hẹn
export const APPOINTMENT_FORM_TYPE = {
    HTML: 1,
    CLONE: 2,
    FORWARD: 3
}

// Các loại liên kết rảnh bận
export const CALENDAR_LINK_TYPE = {
    HTML: 1,
    ICS: 2,
    EVENT_ICS: 3
}

export const NOTES_SEPARATOR = "*~*~*~*~*~*~*~*~*~*";

// Calendar end
export const CONTACT_UTILS = {
    PHONE: {
        MOBILE: "mobilePhone",
        WORK: "workPhone",
        WORK_FAX: "workFax",
        COMPANY: "companyPhone",
        HOME: "homePhone",
        HOME_FAX: "homeFax",
        PAGER: "pager",
        CALLBACK: "callbackPhone",
        ASSISTANT: "assistantPhone",
        CAR: "carPhone",
        OTHER: "otherPhone",
        OTHER_FAX: "otherFax",
    },
    IM: {
        XMPP: "xmpp",
        YAHOO: "yahoo",
        AOL: "aol",
        MSN: "msn",
        OTHER: "im",
    },
    ADDRESS: {
        HOME: "home",
        WORK: "work",
        OTHER: "other"
    },
    ADDR_PREFIXES: ["work", "home", "other"],
    ADDR_SUFFIXES: ["Street", "City", "State", "PostalCode", "Country"],
    ADDRESS_TYPE: {
        HOME_STREET: "homeStreet",
        HOME_CITY: "homeCity",
        HOME_STATE: "homeState",
        HOME_POSTAL_CODE: "homePostalCode",
        HOME_COUNTRY: "homeCountry",
        WORK_STREET: "workStreet",
        WORK_CITY: "workCity",
        WORK_STATE: "workState",
        WORK_POSTAL_CODE: "workPostalCode",
        WORK_COUNTRY: "workCountry",
        OTHER_STREET: "otherStreet",
        OTHER_CITY: "otherCity",
        OTHER_STATE: "otherState",
        OTHER_POSTAL_CODE: "otherPostalCode",
        OTHER_COUNTRY: "otherCountry",
    },
    URL_TYPE: [
        "homeURL",
        "workURL",
        "otherURL",
    ],
    OTHER: {
        BIRTHDAY: "birthday",
        ANNIVERSARY: "anniversary",
        CUSTOM: "custom",
    },
    SHOW_TYPE: {
        LAST_FIRST: 1,
        FIRST_LAST: 2,
        COMPANY: 3,
        LAST_FIRST_COMPANY: 4,
        FIRST_LAST_COMPANY: 5,
        COMPANY_LAST_FIRST: 6,
        COMPANY_FIRST_LAST: 7
    }
}
export const ZIMBRA_CAL_RES_TYPE = {
    LOCATION: "Location",
    EQUIPMENT: "Equipment",
}
export const DATASOURCE_TIME_DAYS = [
    {
        value: "SU",
        labelShort: i18n.t("zimbra.zhMsg.CAL_MINICAL_WDAY1"),
        label: i18n.t("zimbra.ztMsg.weekdaySunday"),
    },
    {
        value: "MO",
        labelShort: i18n.t("zimbra.zhMsg.CAL_MINICAL_WDAY2"),
        label: i18n.t("zimbra.ztMsg.weekdayMonday"),
    },
    {
        value: "TU",
        labelShort: i18n.t("zimbra.zhMsg.CAL_MINICAL_WDAY3"),
        label: i18n.t("zimbra.ztMsg.weekdayTuesday"),
    },
    {
        value: "WE",
        labelShort: i18n.t("zimbra.zhMsg.CAL_MINICAL_WDAY4"),
        label: i18n.t("zimbra.ztMsg.weekdayWednesday"),
    },
    {
        value: "TH",
        labelShort: i18n.t("zimbra.ztMsg.weekdayThursdayShort"),
        label: i18n.t("zimbra.ztMsg.weekdayThursday"),
    },
    {
        value: "FR",
        labelShort: i18n.t("zimbra.zhMsg.CAL_MINICAL_WDAY6"),
        label: i18n.t("zimbra.ztMsg.weekdayFriday"),
    },
    {
        value: "SA",
        labelShort: i18n.t("zimbra.zhMsg.CAL_MINICAL_WDAY7"),
        label: i18n.t("zimbra.ztMsg.weekdaySaturday"),
    },
];
export const DATASOURCE_MONTHS = [
    {
        value: 1,
        labelShort: i18n.t("zimbra.ztMsg.monthJanuaryShort"),
        label: i18n.t("zimbra.ztMsg.monthJanuary"),
    },
    {
        value: 2,
        labelShort: i18n.t("zimbra.ztMsg.monthDecemberShort"),
        label: i18n.t("zimbra.ztMsg.monthFebruary"),
    },
    {
        value: 3,
        labelShort: i18n.t("zimbra.ztMsg.monthMarchShort"),
        label: i18n.t("zimbra.ztMsg.monthMarch"),
    },
    {
        value: 4,
        labelShort: i18n.t("zimbra.ztMsg.monthAprilShort"),
        label: i18n.t("zimbra.ztMsg.monthApril"),
    },
    {
        value: 5,
        labelShort: i18n.t("zimbra.ztMsg.monthMayShort"),
        label: i18n.t("zimbra.ztMsg.monthMay"),
    },
    {
        value: 6,
        labelShort: i18n.t("zimbra.ztMsg.monthJuneShort"),
        label: i18n.t("zimbra.ztMsg.monthJune"),
    },
    {
        value: 7,
        labelShort: i18n.t("zimbra.ztMsg.monthJulyShort"),
        label: i18n.t("zimbra.ztMsg.monthJuly"),
    },
    {
        value: 8,
        labelShort: i18n.t("zimbra.ztMsg.monthAugustShort"),
        label: i18n.t("zimbra.ztMsg.monthAugust"),
    },
    {
        value: 9,
        labelShort: i18n.t("zimbra.ztMsg.monthSeptemberShort"),
        label: i18n.t("zimbra.ztMsg.monthSeptember"),
    },
    {
        value: 10,
        labelShort: i18n.t("zimbra.ztMsg.monthOctoberShort"),
        label: i18n.t("zimbra.ztMsg.monthOctober"),
    },
    {
        value: 11,
        labelShort: i18n.t("zimbra.ztMsg.monthNovemberShort"),
        label: i18n.t("zimbra.ztMsg.monthNovember"),
    },
    {
        value: 12,
        labelShort: i18n.t("zimbra.ztMsg.monthDecemberShort"),
        label: i18n.t("zimbra.ztMsg.monthDecember"),
    },
];
export const DATASOURCE_MONTHLY_DAY = [
    {
        id: 1,
        name: "đầu tiên"
    },
    {
        id: 2,
        name: "thứ hai"
    },
    {
        id: 3,
        name: "thứ ba"
    },
    {
        id: 4,
        name: "thứ tư"
    },
    {
        id: 5,
        name: "cuối cùng"
    },
];
export const RECUR_END_INTERVAL_TYPE = {
    NONE: "1",
    NUMBER: "2",
    DATE: "3",
}
export const CAL_PARTICIPATION_STATUS = {
    NEEDS_ACTION: "NE", // Cần hành động
    TENTATIVE: "TE", // Chưa dứt khoát
    ACCEPT: "AC", // Chấp nhận
    DECLINED: "DE", // Từ chối
    DELEGATED: "DG",
    COMPLETED: "CO",
    IN_PROCESS: "IN",
    WAITING: "WA",
    DEFERRED: "DF"
}
export const CAL_ROLE_AT = {
    REQUIRED: "REQ",
    OPTIONAL: "OPT",
}
export const CAL_OPEN_RECURRING_ITEM = {
    OPEN_INSTANCE: "openInstance",
    OPEN_APPT_SERIES: "openApptSeries",
}
export const APPOINTMENT_STATUS = {
    TENTATIVE: "TENT",
    CONFIRMED: "CONF",
    CANCELLED: "CANC",
    COMPLETED: "COMP",
    INPROGRESS: "INPR",
    WAITING: "WAITING",
    DEFERRED: "DEFERRED",
}
// Các loại xóa series của cuộc hẹn định kỳ
export const CAL_RECURRING_DELETE_SERIES_TYPE = {
    ALL_APPT: "allAppt",
    FUTURE_APPT: "futureAppt",
}
export const CAL_ALARM_TYPE = {
    DISPLAY: "DISPLAY",
    EMAIL: "EMAIL",
}
// Loại lặp hàng ngày
export const CAL_RECUR_DAILY_TYPE = {
    EVERY_DAY: 1,
    EVERY_WEEKDAY: 2,
    EVERY_NUM_DAYS: 3,
}
// Loại lặp hàng tuần
export const CAL_RECUR_WEEKLY_TYPE = {
    EVERY_WEEKDAY: 1,
    EVERY_NUM_WEEKS_DATE: 2,
}
// Loại lặp hàng tháng
export const CAL_RECUR_MONTHLY_TYPE = {
    EVERY_NUM_MONTHS_DATE: 1,
    EVERY_NUM_MONTHS_NUMDAY: 2,
}
// Loại lặp hàng năm
export const CAL_RECUR_YEARLY_TYPE = {
    EVERY_DATE: 1,
    EVERY_MONTH_WEEK_DAYS: 2,
}
// Trả lời cuộc hẹn
export const CAL_REPLY_INV = {
    NOTIFY_ORGANIZER: "notifyOrganizer",
    EDIT_REPLY: "editReply",
    DONT_NOTIFY_ORGANIZER: "dontNotifyOrganizer",
}
export const CAL_OPERATOR_REPLY_INV = {
    ACCEPT: "ACCEPT", // Chấp nhận
    TENTATIVE: "TENTATIVE", // Chưa dứt khoát
    DECLINE: "DECLINE", // Từ chối
    NEW_TIME: "NEW_TIME", // Đề xuất thời gian mới
    NEEDS_ACTION: "NEEDS_ACTION",
}

export const CAL_INV_COMP_METHOD = {
    REQUEST: "REQUEST",
    REPLY: "REPLY",
    COUNTER: "COUNTER",
}
export const CONTACT_ALPHABET_TEST = [
    "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
]

export const CONTACT_ALPHABET = [
    "AZ", "123", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
]

export const COLOR_AVATAR = [
    {
        backgroundColor: "#979EC9",
        color: "#FFFFFF"
    },
    {
        backgroundColor: "#79E9C7",
        color: "#FFFFFF"
    },
    {
        backgroundColor: "#4A528A",
        color: "#FFFFFF"
    },
    {
        backgroundColor: "#EE8FA3",
        color: "#FFFFFF"
    },
    {
        backgroundColor: "#EE0033",
        color: "#FFFFFF"
    },
    {
        backgroundColor: "#FFE2AB",
        color: "#FFFFFF"
    },
    {
        backgroundColor: "#FFA800",
        color: "#FFFFFF"
    },
    {
        backgroundColor: "#6D75AB",
        color: "#FFFFFF"
    },
];

export const INCLUDE_ORIGIN_TYPE = {
    INCLUDE_NONE: "includeNone", // Không bao gồm thư gốc
    INCLUDE_BODY_ONLY: "includeBodyOnly", // Bao gồm thư gốc
    INCLUDE_SMART: "includeSmart", // Chỉ bao gồm thư cuối
    INCLUDE_AS_ATTACHMENT: "includeAsAttachment", // Bao gồm thư dưới dạng tập tin đính kèm
}

export const ATTACT_TYPE = {
    MY_COMPUTER: "my_computer", // Máy tính của tôi
    ATTACHINLINE: "attach_inline", // Đính kèm trực tiếp
    BRIEFCASE: "briefcase",
    CONTACTS: "contacts", // Danh bạ
    MAIL: "mail", // Thư
}

export const INCLUDE_BODY = {
    INCLUDE_NONE: "includeNone", // Không bao gồm thư gốc
    INCLUDE_BODY_ONLY: "includeBodyOnly", // bao gồm thư gốc,không tiền tố,không đề mục
    INCLUDE_BODY_WITH_PREFIX: "includeBodyWithPrefix", //  bao gồm thư gốc,có tiền tố,không đề mục
    INCLUDE_BODY_AND_HEADERS: "includeBodyAndHeaders", // bao gồm thư gốc,không tiền tố,có đề mục
    INCLUDE_BODY_AND_HEADERS_WITH_PREFIX: "includeBodyAndHeadersWithPrefix", // Bao gồm đề mục + Sử dụng tiền tố
    INCLUDE_SMART: "includeSmart", // Chỉ bao gồm thư cuối,không tiền tố,không đề mục
    INCLUDE_SMART_WITH_PREFIX: "includeSmartWithPrefix", //  Chỉ bao gồm thư cuối,có tiền tố,không đề mục
    INCLUDE_SMART_AND_HEADERS: "includeSmartAndHeaders", // Chỉ bao gồm thư cuối,không tiền tố,có đề mục
    INCLUDE_SMART_AND_HEADERS_WITH_PREFIX: "includeSmartAndHeadersWithPrefix", // Bao gồm đề mục + Sử dụng tiền tố
    INCLUDE_AS_ATTACHMENT: "includeAsAttachment", // Bao gồm thư dưới dạng tập tin đính kèm
}

export const OPTITON_COMPOSE = {
    INCLUDE_MENU_NONE: "includeMenuNone", // Không bao gồm thư gốc
    INCLUDE_MENU_BODY: "includeMenuBody", // bao gồm thư gốc
    INCLUDE_MENU_SMART: "includeMenuSmart", //  chỉ bao gồm thư cuối cùng
    INCLUDE_ORIGINAL_AS_ATTACH: "includeOriginalAsAttach", // Bao gồm thư dưới dạng tập tin đính kèm
    USER_PREFIX: "usePrefix", //  Sử dụng tiền tố
    INCLUDE_HEADERS: "includeHeaders", // Bao gồm đề mục
}

export const FROM_ADDRESS_TYPE = {
    MAP: "MAP", // tài khoản ngoài imap
    POP: "POP", // tài khoản ngoài pop3
    MAIN_ACCOUNT: "MAIN_ACCOUNT", // tài khoản chính
    IDENTITY: "IDENTITY", // danh tính
    DISCOVER_RIGHTS: "DISCOVER_RIGHTS", // ủy quyền
}

export const CALENDAR_FORM_FIELD = {
    REPLY_TO: "replyTo", // Tới
    FROM: "from", // Từ
    FORWARD_TO: "forwardTo",
    NAME: "name", // Chủ đề
    TO: "to", // Người tham dự
    OPTIONAL: "optional", // Tùy chọn
    LOC: "loc", // Địa điểm
    EQUIPMENT_ATTENDEE: "equipmentAttendee", // Thiết bị
    FB: "fb", // Trạng thái rảnh/bận
    L: "l", // Thư mục
    CLASS: "class", // riêng tư/công khai
    ALLDAY: "allDay", // Cả ngày
    TIME: "time", // Thời gian
    FREQ: "freq", // Lặp lại
    CALENDAR_REMINDER_EMAIL: "calendarReminderEmail", // Trình nhắc
    ALARM: "alarm",  // Trình nhắc
    SCHEDULE: "schedule", // Lịch trình
    ATTACH: "attach", // Tập tin đính kèm
}
export const SEND_UPDATES = {
    NEW: "NEW", // Chỉ gửi bản cập nhật tới người tham dự được thêm hoặc bị xóa.
    ALL: "ALL", // Gửi các cập nhật tới tất cả người tham dự.
}

export const SERVER_WEEK_DAYS = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];

export const ZIMBRA_ZIMLET = {
    COM_ZIMBRA_DATE: "com_zimbra_date",
    COM_ZIMBRA_EMAIL: "com_zimbra_email",
    COM_ZIMBRA_URL: "com_zimbra_url",
}

export const ZIMBRA_ZIMLET_DATE = {
    DAY: "day",
    NEW_APPT: "newAppt",
    SEARCH_FOR_MESSAGES: "searchForMessages",
}
export const ZIMBRA_ZIMLET_EMAIL = {
    COPY: "copy",
    SEARCH: "search",
    FIND_EMAIL_FROM_SENDER: "findEmailFromSender",
    FIND_EMAIL_TO_SENDER: "findEmailToSender",
    NEW_EMAIL: "newEmail",
    EDIT_CONTACT: "editContact",
    ADD_CONTACT: "addContact",
    GO_TO_URL_ALT: "goToUrlAlt",
    ADD_TO_FILTER: "addToFilter",
    NEW_FILTER: "newFilter",
}
export const ADDRESS_ACTION_TYPE = {
    COPY: "copy",
    DELETE: "delete",
    EDIT_CONTACT: "editContact",
    EDIT: "edit",
    EXTEND: "extend",
    MOVE_FROM: "moveFrom",
    MOVE_CC: "moveCc",
    MOVE_BCC: "moveBcc"
}
export const CALENDAR_PRINT_MODE = {
    SEL_DATE: 1,
    RANGE_DATE: 2
}

export const CONFIRM_DIALOGUE_RES = {
    CLOSE: "close",
    CANCEL: "cancel"
}

export const Zm_Display = {
    RIGHT: "right",
    BOTTOM: "bottom",
    OFF: "off",
}

export const Zm_Props_Briefcase_Table = {
    SELECTION: i18n.t("zimbra.zmMsg.selection"),
    EXPAND: i18n.t("zimbra.zmMsg.expand"),
    ICON: i18n.t("zimbra.zmMsg.icon"),
    TYPE: i18n.t("zimbra.zhMsg.optionsAccountType"),
    NAME: i18n.t("zimbra.zhMsg.AB_FIELD_firstName"),
    SIZE: i18n.t("zimbra.zhMsg.MO_size"),
    MODIFIED: i18n.t("zimbra.zhMsg.briefcaseModifiedOn"),
    AUTHOR: i18n.t("zimbra.zhMsg.briefcaseModifiedBy"),
    FOLDER: i18n.t("zimbra.zhMsg.ALT_FOLDER"),
    VERSION: i18n.t("zimbra.zhMsg.briefcaseVersion"),
    TAG: i18n.t("zimbra.zhMsg.actionTag"),
    LOCK: i18n.t("zimbra.zhMsg.key"),
}

export const Zm_Field_Briefcase_Table = [
    {
        label: Zm_Props_Briefcase_Table.SELECTION,
        show: true
    },
    {
        label: Zm_Props_Briefcase_Table.EXPAND,
        show: true
    },
    {
        label: Zm_Props_Briefcase_Table.ICON,
        show: true
    },
    {
        label: Zm_Props_Briefcase_Table.NAME,
        show: true
    },
    {
        label: Zm_Props_Briefcase_Table.TYPE,
        show: true
    },
    {
        label: Zm_Props_Briefcase_Table.SIZE,
        show: true
    },
    {
        label: Zm_Props_Briefcase_Table.MODIFIED,
        show: true
    },
    {
        label: Zm_Props_Briefcase_Table.AUTHOR,
        show: true
    },
    {
        label: Zm_Props_Briefcase_Table.FOLDER,
        show: true
    },
    {
        label: Zm_Props_Briefcase_Table.VERSION,
        show: true
    },
    {
        label: Zm_Props_Briefcase_Table.TAG,
        show: true
    },
    {
        label: Zm_Props_Briefcase_Table.LOCK,
        show: true
    },
];

// Ngăn đọc
export const READ_EMAIL_LAYOUT = {
    RIGHT: 'right',
    HIDE: 'off'
}
export const READ_EMAIL_LAYOUT_ROUTER = {
    NAME_RIGHT: 'Email',
    NAME_HIDE: 'EmailWithReadPanel',
    SUB_RIGHT: 'email',
    SUB_HIDE: 'v-email',
    RIGHT: '/mail/email',
    HIDE: '/mail/v-email'
}
export const MAIL_CONFIG = {
    PRIORITIZE: 'PRIORITIZE',
    CUSTOMER: 'CUSTOMER'
}

export const PATH_REQUEST_SETTING = ["getAvailableSkinsRequest", "getAvailableLocalesRequest", "getAvailableCsvFormatsRequest", "getPrefsRequest"]

export const LOCALES = [
    {
        localName: "Hebrew",
        name: "עברית",
        id: "iw"
    },
    {
        localName: "Indonesian",
        name: "Bahasa Indonesia",
        id: "in"
    },
    {
        localName: "Tiếng Anh",
        name: "English",
        id: "en"
    },
    {
        localName: "Tiếng Anh (Australia)",
        name: "English (Australia)",
        id: "en_AU"
    },
    {
        localName: "Tiếng Anh (Hoa Kỳ)",
        name: "English (United States)",
        id: "en_US"
    },
    {
        localName: "Tiếng Anh (Vương quốc Anh)",
        name: "English (United Kingdom)",
        id: "en_GB"
    },
    {
        localName: "Tiếng Ba Lan",
        name: "polski",
        id: "pl"
    },
    {
        localName: "Tiếng Basque",
        name: "Euskara",
        id: "eu"
    },
    // {
    //     localName: "Tiếng Bulgaria",
    //     name: "български",
    //     id: "bg"
    // },
    {
        localName: "Tiếng Bồ Đào Nha",
        name: "português",
        id: "pt"
    },
    {
        localName: "Tiếng Bồ Đào Nha (Brazil)",
        name: "português (Brasil)",
        id: "pt_BR"
    },
    // {
    //     localName: "Tiếng Bồ Đào Nha (Bồ Đào Nha)",
    //     name: "português (Portugal)",
    //     id: "pt_PT"
    // },
    {
        localName: "Tiếng Catalan",
        name: "català",
        id: "ca"
    },
    // {
    //     localName: "Tiếng Croatia",
    //     name: "hrvatski",
    //     id: "hr"
    // },
    {
        localName: "Tiếng Hindi",
        name: "हिंदी",
        id: "hi"
    },
    {
        localName: "Tiếng Hungary",
        name: "magyar",
        id: "hu"
    },
    {
        localName: "Tiếng Hà Lan",
        name: "Nederlands",
        id: "nl"
    },
    {
        localName: "Tiếng Hàn",
        name: "한국어",
        id: "ko"
    },
    {
        localName: "Tiếng Italy",
        name: "italiano",
        id: "it"
    },
    {
        localName: "Tiếng Lào",
        name: "ພາ​ສາ​ລາວ",
        id: "lo"
    },
    {
        localName: "Tiếng Mã Lai",
        name: "Bahasa Melayu",
        id: "ms"
    },
    {
        localName: "Tiếng Na Uy",
        name: "norsk",
        id: "no"
    },
    {
        localName: "Tiếng Nga",
        name: "русский",
        id: "ru"
    },
    {
        localName: "Tiếng Nhật",
        name: "日本語",
        id: "ja"
    },
    {
        localName: "Tiếng Pháp",
        name: "français",
        id: "fr"
    },
    {
        localName: "Tiếng Pháp (Canada)",
        name: "français (Canada)",
        id: "fr_CA"
    },
    // {
    //     localName: "Tiếng Pháp (Pháp)",
    //     name: "français (France)",
    //     id: "fr_FR"
    // },
    {
        localName: "Tiếng Romania",
        name: "română",
        id: "ro"
    },
    {
        localName: "Tiếng Slovenia",
        name: "Slovenščina",
        id: "sl"
    },
    // {
    //     localName: "Tiếng Séc",
    //     name: "čeština",
    //     id: "cs"
    // },
    // {
    //     localName: "Tiếng Tamil",
    //     name: "தமிழ்",
    //     id: "ta"
    // },
    {
        localName: "Tiếng Thái",
        name: "ไทย",
        id: "th"
    },
    // {
    //     localName: "Tiếng Thổ Nhĩ Kỳ",
    //     name: "Türkçe",
    //     id: "tr"
    // },
    {
        localName: "Tiếng Thụy Điển",
        name: "svenska",
        id: "sv"
    },
    {
        localName: "Tiếng Trung",
        name: "中文",
        id: "zh"
    },
    {
        localName: "Tiếng Trung (Hồng Kông, Trung Quốc)",
        name: "中文 (香港)",
        id: "zh_HK"
    },
    {
        localName: "Tiếng Trung (Trung Quốc)",
        name: "中文 (中国)",
        id: "zh_CN"
    },
    {
        localName: "Tiếng Trung (Đài Loan)",
        name: "中文 (台灣)",
        id: "zh_TW"
    },
    {
        localName: "Tiếng Tây Ban Nha",
        name: "español",
        id: "es"
    },
    // {
    //     localName: "Tiếng Ucraina",
    //     name: "українська",
    //     id: "uk"
    // },
    {
        localName: "Tiếng Việt",
        name: "Tiếng Việt",
        id: "vi"
    },
    {
        localName: "Tiếng Đan Mạch",
        name: "Dansk",
        id: "da"
    },
    {
        localName: "Tiếng Đức",
        name: "Deutsch",
        id: "de"
    },
    {
        localName: "Tiếng Ả Rập",
        name: "العربية",
        id: "ar"
    },
    // {
    //     localName: "Tiếng Séc",
    //     name: "čeština",
    //     id: "cs"
    // },

]

export const THEMES = [
    {
        name: "bare"
    },
    {
        name: "beach"
    },
    {
        name: "bones"
    },
    {
        name: "carbon"
    },
    {
        name: "harmony"
    },
    {
        name: "hotrod"
    },
    {
        name: "lake"
    },
    {
        name: "lavender"
    },
    {
        name: "lemongrass"
    },
    {
        name: "oasis"
    },
    {
        name: "pebble"
    },
    {
        name: "sand"
    },
    {
        name: "serenity"
    },
    {
        name: "sky"
    },
    {
        name: "smoke"
    },
    {
        name: "steel"
    },
    {
        name: "tree"
    },
    {
        name: "twilight"
    },
    {
        name: "waves"
    },
    {
        name: "zextras"
    }
]
