<template>
    <el-dialog
        class="zm-didlog-register-file d-center"
        custom-class="zm-register-file mw__450"
        :title="$t('zimbra.zmMsg.checkInFileToBriefcase')"
        :visible.sync="showDialog"
        :destroy-on-close="true"
        width="28%"
    >
        <div>{{ this.$t("zimbra.zmMsg.checkInFileChoose") }}</div>
        <div class="form__body">
            <div class="form-item upload-title upload__wrap">
                <span class="text-label">{{ this.$t('zimbra.zmMsg.fileLabel') }}</span>
                <div class="form-controls">
                    <input
                        type="file"
                        ref="upload_file"
                        class="el-input__inner"
                        style="z-index: 100; opacity: 0"
                        readonly="readonly"
                        :multiple="false"
                        @change="handleChangeFiles"
                    />
                    <el-input
                        placeholder="Không có tệp nào được chọn"
                        class="view__name"
                        :readonly="true"
                        :value="file.name"
                    />
                </div>
                <el-button type="small" class="btn-email default" @click="handleShowChooseFile">
                    {{$t("zimbraNokey.zimbra_setting_import_choose_file")}}
                </el-button>
            </div>
            <div class="form-item">
                <span class="text-label">{{ this.$t('zimbra.zmMsg.versionLabel') }}</span>
                <span>{{ version }}</span>
            </div>
            <div class="form-item">
                <span class="text-label">{{ this.$t('zimbra.zmMsg.notesLabel') }}</span>
                <base-input
                    type="textarea"
                    :value="notesVal"
                    @input="handleInputNotes"
                />
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <base-button class="btn-email default" @click="showDialog = false">{{ $t("zimbra.zhMsg.actionTaskCancelTT") }}</base-button>
            <base-button class="btn-email primary" @click="handleRegisterFile">{{ $t("zimbra.zhMsg.subscribe") }}</base-button>
        </span>
    </el-dialog>
</template>

<script>
import BriefcaseService from "@/services/BriefcaseService.js"
import ContactService from "@/services/contacts/ContactService.js";
import BaseInput from "@/components/modules/base/base-input/index.vue"
import { mapGetters } from "vuex"
import CommonUtils from "@/utils/common-utils";
import BriefcaseUtils from "@/utils/briefcase-utils";
import BaseButton from "@/components/modules/base/base-button/btn-default"
import { STATUS_REQUEST } from "@/utils/constants";
export default {
    mixins: [CommonUtils, BriefcaseUtils],
    components: {
        BaseInput,
        BaseButton
    },
    data() {
        return {
            showDialog: false,
            version: "",
            notesVal: "",
            file: {
                name: ""
            }
        }
    },
    mounted() {
        this.$root.$on("handlShowDialogRegisterFile", () => {
            this.show()
        })
    },
    computed: {
        ...mapGetters([
            "currentActiveBriefcase",
            "currentExtraActiveBriefcase"
        ])
    },
    watch: {
        currentActiveBriefcase: {
            immediate: true,
            handler(newVal) {
                this.version = newVal?.ver || ""
            }
        }
    },
    methods: {
        show() {
            this.version = this.currentExtraActiveBriefcase.ver + 1
            this.notesVal = ""
            this.file = {
                name: ""
            }
            this.showDialog = true
        },
        handleChangeFiles(event) {
            this.file = event.target.files[0]
        },
        resetForm() {
            this.version = ""
            this.notesVal = ""
            this.file = {
                name: ""
            }
        },
        handleShowChooseFile() {
            this.$refs.upload_file.click()
        },
        async handleRegisterFile() {
            const id = await this.handleUploadFiles()
            const formData = {
                doc: {
                    desc: this.notesVal || "",
                    id: this.currentActiveBriefcase?.id.toString(),
                    name: this.currentActiveBriefcase?.name,
                    ver: this.currentActiveBriefcase?.ver,
                },
                id: id
            }
            await BriefcaseService.saveDocumentRequest(formData).then(async resp => {
                const SaveDocumentResponse = this.getResponseBody(resp)["SaveDocumentResponse"]
                if (SaveDocumentResponse) {
                    this.$root.$emit("updateListAfterRegisterFile", this.currentActiveBriefcase)
                    this.showDialog = false
                    setTimeout(() => {
                        this.resetForm()
                    }, 200)
                }
            }).catch(err => {
                const { detail } = this.getResponseBody(err.response)["soap:Fault"]
                if (detail.Error.Code === STATUS_REQUEST.ALREADY_EXISTS) {
                    this.$confirm(
                        this.$t("zimbra.zmMsg.itemWithFileNameExits", { 0: this.file.name }),
                        this.$t("zimbra.zhMsg.ALT_APP_BRIEFCASE"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            showCancelButton: false,
                            dangerouslyUseHTMLString: true,
                            type: "error",
                        }
                    ).then(() => { })
                }
            })
        },
        async handleUploadFiles() {
            if (this.file) {
                const formData = new FormData();
                formData.append("file", this.file);
                return await ContactService.uploadImage(formData).then((resp) => {
                    const response = resp.data
                    let _start = response.indexOf("{")
                    let _last = response.indexOf("}")
                    let respStr = response.substring(_start + 1, _last)
                    _start = respStr.indexOf("(")
                    _last = respStr.indexOf(")")
                    respStr = respStr.substring(_start + 1, _last)
                    const listResp = respStr.split(",")
                    if (listResp[0] == 200) {
                        const id = listResp[2].substring(1, listResp[2].length - 1) || "";
                        return id
                    } else if (listResp[0] == 413) {
                        this.$confirm(
                            this.$t("zimbra.zmMsg.attachmentSizeError", { 0: "37MB" }),
                            this.$t("zimbraNokey.zimbra_common_criticalMsg"),
                            {
                                confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                                showCancelButton: false,
                                type: "error",
                                dangerouslyUseHTMLString: true
                            }
                        )
                            .then(() => {
                                return
                            })
                    }
                })
            }
        },
        handleInputNotes(val) {
            this.notesVal = val
        }
    }
}
</script>