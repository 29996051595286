<template>
    <div>
        <div
            id="menuRightBriefcase"
            :class="`right-menu ${isLeftSide? 'child-left': ''}`"
            active-text-color="#303133"
            :style="{ left: position.x + 'px', top: position.y + 'px' }"
            ref="rightMenu"
        >
            <div v-if="showMenuBriefcase">
                <!-- Menu root tree -->
                <MenuRightBriefcaseRootTree
                    v-if="BRIEFCASE_RIGHT_MENU_TYPE.TREE_BRIEFCASE_ROOT == menuType"
                    :folderData="apptData"
                />

                <!-- Menu tree briefcase -->
                <MenuRightBriefcaseTree
                    v-else-if="BRIEFCASE_RIGHT_MENU_TYPE.TREE_BRIEFCASE == menuType"
                    :folderData="apptData"
                />

                <!-- Menu tree briefcase Trash -->
                <MenuRightBriefcaseTree
                    v-else-if="BRIEFCASE_RIGHT_MENU_TYPE.TREE_BRIEFCASE_TRASH == menuType"
                    :folderData="apptData"
                    :isFromTrashFolder="true"
                />

                <MenuRightWebEx
                    v-else-if="BRIEFCASE_RIGHT_MENU_TYPE.WEBEX == menuType"
                    :folderData="apptData"
                />
                <MenuRightArchive
                    v-else-if="BRIEFCASE_RIGHT_MENU_TYPE.MAIL_ARCHIVE == menuType"
                    :folderData="apptData"
                />
                <MenuRightYahoo
                    v-else-if="BRIEFCASE_RIGHT_MENU_TYPE.YMEMOTIONS == menuType"
                    :folderData="apptData"
                />
            </div>
        </div>
    </div>
</template>

<script>
import MenuRightBriefcaseRootTree from "./menu-right-briefcase-root-tree.vue"
import MenuRightBriefcaseTree from "./menu-right-briefcase-tree.vue"
import { BRIEFCASE_RIGHT_MENU_TYPE } from "@/utils/constants";
import MenuRightWebEx from "@/components/modules/calendar/components/menu-right-click/menu-right-webex.vue";
import MenuRightArchive from "@/components/modules/layouts/components/contacts/menu-right-archive.vue";
import MenuRightYahoo from "@/components/modules/layouts/components/contacts/menu-right-yahoo.vue";

export default {
    components: {
        MenuRightBriefcaseRootTree,
        MenuRightBriefcaseTree,
        MenuRightWebEx,
        MenuRightArchive,
        MenuRightYahoo
    },
    mounted() {
        this.$root.$on("onRightClickBriefCase", (menuType, eventClick, formAddDefault, apptData) => {
            this.show(menuType, eventClick, formAddDefault, apptData);
        })
        this.$root.$on("onRightClickCalendar", (menuType, eventClick, formAddDefault, apptData) => {
            this.show(menuType, eventClick, formAddDefault, apptData);
        })
        this.$root.$on("closeRightMenuSidebar", () => {
            this.showMenuBriefcase = false
        });
    },
    destroyed() {
        // Xóa emit
        this.$root.$off("onRightClickBriefCase");
        this.$root.$off("onRightClickCalendar");
        document.removeEventListener("click", this.closeRightMenu);
    },
    data() {
        return {
            showMenuBriefcase: false,
            // Loại menu
            menuType: null,
            BRIEFCASE_RIGHT_MENU_TYPE: BRIEFCASE_RIGHT_MENU_TYPE,
            // formAdd cho thêm mới nhanh
            formAddDefault: {},
            apptData: {},
            // biến quyết định đặt li sang trái khi ở rìa phải màn hình
            isLeftSide: false,
            // Vị trí của menu
            position: {
                x: 0,
                y: 0
            },
            // Sự kiện click
            eventJs: {}
        }
    },
    watch: {
        showMenuBriefcase(val) {
            this.$root.$emit("showInfoFolder", !val);
        }
    },
    methods: {
        /**
         * hàm xử lý hiển thị menu
         */
        show(menuType, e, formAddDefault, apptData) {
            this.showMenuBriefcase = false;
            // Thiết lập dữ liệu khi click chuột phải
            this.formAddDefault = formAddDefault;
            this.menuType = menuType;
            this.apptData = apptData;
            // Thiết lập vị trí hiển thị của menu
            this.eventJS = e;
            this.position.x = e.clientX;
            this.position.y = e.clientY;
            const screenHeight = document.getElementById("appZimbra").offsetHeight;
            const screenWidth = document.getElementById("appZimbra").offsetWidth;
            const menuHeight = this.getHeightMenu(menuType)
            const menuWidth = 246
            if (screenHeight - e.clientY < menuHeight) {
                this.position.y = screenHeight - menuHeight;
            }
            // Thiết định lại độ rộng
            if (screenWidth - e.clientX < menuWidth) {
                this.position.x = screenWidth - menuWidth;
                this.isLeftSide = true
            } else {
                this.isLeftSide = false;
            }
            // Thiết định lệch trái cho menu con
            if (screenWidth - e.clientX < menuWidth * 2) {
                this.isLeftSide = true;
            }

            setTimeout(() => {
                this.showMenuBriefcase = true;
            }, 100)

            document.addEventListener("click", this.closeRightMenu)
        },

        /**
         * hàm xử lý đóng menu khi click
         */
        closeRightMenu() {
            // this.showMenuBriefcase = false
            this.$set(this, "showMenuBriefcase", false)
        },

        /**
         * Xóa type menu
         */
        clearType() {
            this.menuType = null;
        },

        /**
         * Hàm lấy chiều cao menu theo từng loại menu để xử lý trương hợp click menu ở cuối màn hình
         */
        getHeightMenu(type) {
            let height = 0;
            switch (type) {
                case BRIEFCASE_RIGHT_MENU_TYPE.TREE_BRIEFCASE:
                case BRIEFCASE_RIGHT_MENU_TYPE.TREE_BRIEFCASE_TRASH:
                    height = 280;
                    break;
                case BRIEFCASE_RIGHT_MENU_TYPE.TREE_CALENDAR_ROOT:
                    height = 250;
                    break;
                case BRIEFCASE_RIGHT_MENU_TYPE.WEBEX:
                    height = 190;
                    break;
                case BRIEFCASE_RIGHT_MENU_TYPE.MAIL_ARCHIVE:
                case BRIEFCASE_RIGHT_MENU_TYPE.YMEMOTIONS:
                    height = 160;
                    break;
                default:
                    height = 420;
                    break;
            }
            return height;
        }
    }

}
</script>

<style>
#menuRightBriefcase.right-menu {
    border-radius: 0.8rem;
    box-shadow: 0px 0px 8px 2px rgb(194 197 206 / 30%);
    border: 1px solid rgba(0,0,0,.15);
}
</style>