<template>
    <el-dialog
        class="dialog-body-wrap zimbra-dialog"
        :title="$t('zimbra.zhMsg.ALT_APP_OPTIONS')"
        :visible.sync="isOpenDialog"
        width="28%"
        custom-class="mw__480"
        height="auto"
        :append-to-body="true"
        :close-on-click-modal="false"
    >
        <el-form class="form-wrap">
            <div class="row pt-2">
                <div class="col-8 zm-label">
                    <p class="webEx-font-bold">
                        {{ $t("zimbra.zmWebExZimlet.appendMeetingInfoToSubOrLoc") }}
                    </p>
                </div>
            </div>
            <hr style="margin: 0px" />
            <div class="row pt-2">
                <div class="col-6 zm-label">
                    <p class="webEx-label">
                        {{ $t("zimbra.zmWebExZimlet.appendMeetingPwd") }}
                    </p>
                </div>
                <div class="col-5">
                    <el-select
                        class="w-100"
                        v-model="formAdd.webexZimlet_appendWebexMeetingPassword"
                        :default-first-option="true"
                    >
                        <el-option
                            v-for="(
                                item, idx
                            ) in lstPreferences"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"
                        >
                            <span>
                                {{ item.label }}
                            </span>
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-6 zm-label">
                    <p class="webEx-label">
                        {{ $t("zimbra.zmWebExZimlet.appendToolFreeNumber") }}
                    </p>
                </div>
                <div class="col-5">
                    <el-select
                        class="w-100"
                        v-model="formAdd.webexZimlet_appendTollFreeConfNumber"
                        :default-first-option="true"
                    >
                        <el-option
                            v-for="(
                                item, idx
                            ) in lstPreferences"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"
                        >
                            <span>
                                {{ item.label }}
                            </span>
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-6 zm-label">
                    <p class="webEx-label">
                        {{ $t("zimbra.zmWebExZimlet.appendTollConfNumber") }}
                    </p>
                </div>
                <div class="col-5">
                    <el-select
                        class="w-100"
                        v-model="formAdd.webexZimlet_appendTollConfNumber"
                        :default-first-option="true"
                    >
                        <el-option
                            v-for="(
                                item, idx
                            ) in lstPreferences"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"
                        >
                            <span>
                                {{ item.label }}
                            </span>
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-6 zm-label">
                    <p class="webEx-label">
                        {{ $t("zimbra.zmWebExZimlet.appendPhonePasscode") }}
                    </p>
                </div>
                <div class="col-5">
                    <el-select
                        class="w-100"
                        v-model="formAdd.webexZimlet_appendPhonePasscode"
                        :default-first-option="true"
                    >
                        <el-option
                            v-for="(
                                item, idx
                            ) in lstPreferences"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"
                        >
                            <span>
                                {{ item.label }}
                            </span>
                        </el-option>
                    </el-select>
                </div>
            </div>
            <hr style="margin: 2px" />
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="onSavePreferences">{{
                $t("zimbra.zmMsg.mobileStatusNeedProvision")
            }}</el-button>
            <el-button @click="isOpenDialog = false">{{
                $t("zimbra.zhMsg.actionTaskCancelTT")
            }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import CalendarUtils from "@/utils/calendar-utils";
import ZimbraMailService from "@/services/ZimbraMailService.js";
export default {
    mixins: [CalendarUtils],
    mounted() {
        this.$root.$on("onOpenWebexPreferences", () => {
            this.isOpenDialog = true;
            if (this.formDataPreferences){
                const formData = {...this.formDataPreferences};
                if(Object.keys(formData).length > 0){
                    this.formAdd = {...formData}
                }
            }
        });
    },
    destroyed() {
        this.$root.$off("onOpenWebexPreferences");
    },
    computed: {
        lstPreferences() {
            const lstPreferences = [];
            lstPreferences.push({value: "NONE", label: this.$t("zimbra.zhMsg.no")})
            lstPreferences.push({value: "SUBJECT", label: this.$t("zimbra.zhMsg.FILT_COND_HEADER_subject")})
            lstPreferences.push({value: "LOCATION", label: this.$t("zimbra.zhMsg.location")})
            lstPreferences.push({value: "BOTH", label: this.$t("zimbra.zmWebExZimlet.subjectAndLocation")})
          return lstPreferences;
        },
        formDataPreferences() {
            return this.$store.getters.formPreferencesWebExZimlet;
        }
    },
    components: {
    },
    data() {
        return {
            formAdd: {
                webexZimlet_appendPhonePasscode: "NONE",
                webexZimlet_appendTollConfNumber: "NONE",
                webexZimlet_appendTollFreeConfNumber: "NONE",
                webexZimlet_appendWebexMeetingPassword: "NONE",
            },
            isOpenDialog: false,
           };
    },
    methods: {
        async onSavePreferences() {
            let arr = [];
            arr.push({n : "webexZimlet_appendPhonePasscode", content: this.formAdd["webexZimlet_appendPhonePasscode"]});
            arr.push({n : "webexZimlet_appendTollConfNumber", content: this.formAdd["webexZimlet_appendTollConfNumber"]});
            arr.push({n : "webexZimlet_appendTollFreeConfNumber", content: this.formAdd["webexZimlet_appendTollFreeConfNumber"]});
            arr.push({n : "webexZimlet_appendWebexMeetingPassword", content: this.formAdd["webexZimlet_appendWebexMeetingPassword"]});
            const formSetFolderAccount = {
                section: "zwc:webexZimletGeneralPreferences",
                a: arr.slice(),
            };
            await ZimbraMailService.setMailboxMetadataRequest(formSetFolderAccount).then(() => {
                this.$notify({
                    title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: this.$t("zimbra.zmWebExZimlet.PrefSaved"),
                    type: "success",
                });
                this.initDataPreferencesWebexZimlet();
            });
            this.isOpenDialog = false;
        },
    },
};
</script>
<style scoped>
.webEx-label {
    line-height: 37px;
    float: right !important;
}
.webEx-font-bold {
    font-weight: bold;
}

</style>