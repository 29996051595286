<template>
    <div>
        <el-dialog
            class="dialog-body-wrap zimbra-dialog zimbra-limited-width-dialog"
            :title="$t('zimbra.zmMsg.mailRedirect')"
            :visible.sync="dialogRedirect"
            width="30%"
            :append-to-body="true"
        >
            <el-form class="form-wrap form-dialog-redirect">
                <AddressSelector
                    ref="toAddressSelector"
                    v-model="listMailRedirect"
                    :type="$t('zimbra.zhMsg.FILT_COND_HEADER_to')"
                    :showCc="false"
                    :showBcc="false"
                    @openDialogAddressSeletor="openDialogAddressSeletor"
                />
                <span>{{ $t("zimbra.zmMsg.redirectExplanation") }}</span>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogRedirect = false">{{
                    $t("zimbra.zhMsg.actionTaskCancelTT")
                }}</el-button>
                <el-button type="primary" @click="redirectMail">{{
                    $t("zimbra.zmMsg.mobileStatusNeedProvision")
                }}</el-button>
            </span>
        </el-dialog>
        <DialogAddressSelector
            ref="refAdressSelector"
            @onselected="handleSeletedAddress"
            :showCc="false"
            :showBcc="false"
        />
    </div>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import AddressSelector from "../../email/mail-compose/components/AddressSelector";
import DialogAddressSelector from "../../email/mail-compose/components/DialogAddressSelector.vue";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import { STATUS_REQUEST, READ_EMAIL_LAYOUT_ROUTER } from "@/utils/constants";
import { mapGetters } from "vuex";
import { isNil } from "lodash";
export default {
    mixins: [CommonUtils],
    components: {
        AddressSelector,
        DialogAddressSelector,
    },
    props: {
        isNewWindow: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters([
            "currentExtraActiveEmail",
            "currentActiveEmail",
            "preference",
        ]),
    },
    mounted() {
        this.$root.$on("showDialogRedirect", () => {
            this.show();
        });
    },
    data() {
        return {
            listMailRedirect: [],
            dialogFormVisible: false,
            dialogRedirect: false,
            idMail: "",
        };
    },
    methods: {
        show() {
            let mailData =
                this.currentExtraActiveEmail || this.currentActiveEmail.data;
            if (this.isNewWindow) {
                mailData = { m: this.currentActiveEmail }
            }
            this.idMail = isNil(mailData.m) ? mailData.id : Array.isArray(mailData.m) ? mailData.m[0].id : mailData.m.id;
            this.dialogRedirect = true;
            this.listMailRedirect = [];

            const { name } = this.$route;
            if (name == READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE) {
                this.isMessage = true
            } else {
                this.isMessage = false
            }
        },
        openDialogAddressSeletor() {
            this.$refs.refAdressSelector.show(this.listMailRedirect);
        },
        handleSeletedAddress(addresses) {
            const newTags = addresses
                ? addresses.map((e) => {
                    return {
                        ...e,
                        text: `"${e.p} <${e.a}>"`,
                    };
                })
                : [];
            this.dialogFormVisible = false;
            this.$refs.toAddressSelector.addTags(newTags);
            // this.listMailRedirect = [...newTags,...this.listMailRedirect];
        },
        redirectMail() {
            this.dialogRedirect = false;
            const e = this.listMailRedirect.map((x) => ({ a: x.a, t: "t" }));
            const form = {
                id: `${this.idMail}`,
                e: e,
            };
            ZimbraMailService.bounceMsgRequest(form).then((res) => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    if (this.isMessage) {
                        this.$message({
                            type: "success",
                            customClass: 'zm-notify',
                            duration: 3500,
                            dangerouslyUseHTMLString: true,
                            message: this.$t("zimbra.zmMsg.redirectSent"),
                        });
                    } else {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t("zimbra.zmMsg.redirectSent"),
                            type: "success",
                        });
                    }
                }
            });
        },
    },
};
</script>
