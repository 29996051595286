<template>
    <div class="form-controls">
        <label v-show="label" :for="id" class="label-control">
            {{this.label}}</label>
        <el-radio-group
            :id="id"
            class="radio-control"
            v-model="keyword"
            @change="handleChange"
        >
            <el-radio
                class="radio-item"
                v-for="option in options"
                :key="option[valueField]"
                :label="option[valueField]"
            >{{option[displayField]}}
            </el-radio
            >
        </el-radio-group>
    </div>
</template>

<script>
    export default {
        name: "base-radio",
        props: {
            id: {
                type: String,
                default: "",
            },
            label: {
                type: String,
                default: "",
            },
            value: {
                type: [String, Number, Object, Boolean],
            },
            options: {
                type: Array,
                required: true,
            },
            valueField: {
                type: String,
                default: "id",
            },
            displayField: {
                type: String,
                default: "name",
            },
        },
        created() {
            this.keyword = this.value;
        },
        data() {
            return {
                keyword: "",
            };
        },
        watch: {
            value(val) {
                this.keyword = val;
            },
        },
        methods: {
            handleChange(val) {
                this.$emit("change", val);
            },
        },
    };
</script>
