<template>
    <div class="d-inline-block">
        <table>
            <tbody>
                <tr>
                    <td v-for="(item, index) in elementsFirstRow" :key="index">
                        <span v-if="index != 0">&nbsp;</span>
                        <span v-if="item.type == 'label'">
                            {{ item.localStringValue }}
                        </span>
                        <!-- <el-input
                            v-else-if="item.type == 'number'"
                            type="number"
                            v-model="values[item.valueOrder]"
                            class="inline-block"
                            :min="1"
                            @focus="onfocus"
                        ></el-input> -->
                        <el-input-number
                            v-else-if="item.type == 'number'"
                            v-model="values[item.valueOrder]"
                            controls-position="right"
                            :min="item.min"
                            :max="item.max"
                            @focus="onfocus"
                        ></el-input-number>
                        <el-select
                            v-else-if="
                                item.type == 'choice' ||
                                (item.type == 'date' &&
                                    item.extraValue == 'EEEE') ||
                                (item.type == 'date' &&
                                    item.extraValue == 'MMMM')
                            "
                            v-model="values[item.valueOrder]"
                            @focus="onfocus"
                        >
                            <el-option
                                v-for="option in item.datasource"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            >
                                <span>
                                    {{ option.label }}
                                </span>
                            </el-option>
                        </el-select>
                        <el-date-picker
                             v-else-if="item.type == 'date' &&
                                    item.extraValue == 'medium'"
                            v-model="values[item.valueOrder]"
                            type="date"
                            format="dd/MM/yyyy"
                            popper-class="zm-calendar"
                            value-format="yyyyMMdd"
                            :clearable="false"
                            @focus="onfocus"
                        >
                        </el-date-picker>
                    </td>
                </tr>
            </tbody>
        </table>
        <table v-if="elementsSecondRow && elementsSecondRow.length > 0" class="mt-2">
            <tbody>
                <tr>
                    <td v-for="(item, index) in elementsSecondRow" :key="index" :colspan="elementsFirstRow.length">
                        <div v-if="item.type == 'list'">
                            <base-checkbox-group
                                displayField="labelShort"
                                valueField="value"
                                :options="item.datasource"
                                :value="values[item.valueOrder] || []"
                                :sort-value="true"
                                @change="val => values[item.valueOrder] = val"
                                @onfocus="onfocus"
                            />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {
    DATASOURCE_MONTHS,
    DATASOURCE_TIME_DAYS
} from "@/utils/constants.js";
import { mapGetters } from "vuex";
import CommonUtils from "@/utils/common-utils";
import BaseCheckboxGroup from "@/components/modules/base/base-checkbox-group";
export default {
    mixins: [CommonUtils],
    components: {
        BaseCheckboxGroup
    },
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        localeKey: {
            type: [String], // "Mỗi năm vào {0,date,MMMM} {1,number,integer}."
            // "Ngày {0,choice,-1#cuối cùng|1#đầu tiên|2#thứ 2|3#thứ 3|4#thứ 4} {1,date,EEEE} của mỗi {2,date,MMMM}.",
            default: "",
        },
        value: {
            type: [Array],
            default: () => {
                return []
            },
        }
    },
    data() {
        // những trường dữ liệu dùng trong component này
        const regExp = /(\{[^}]+\})/gi;
        const localeStringValue = this.$t(this.localeKey);
        const elements = localeStringValue.split(regExp);
        return {
            regExp: regExp,
            localeValue: localeStringValue,
            elements: elements,
            components: [],
            values: this.value || [],
        };
    },
    computed: {
        ...mapGetters(["localeCurrent"]),
        elementsFirstRow() {
            return this.components.filter(e => !["list"].includes(e.type));
        },
        elementsSecondRow() {
            return this.components.filter(e => ["list"].includes(e.type));
        },
    },
    created() {
        // sau khi khởi tạo
    },
    mounted() {
        // sau khi vẽ ra màn hình
        const components = this.createComponents(this.elements, this.regExp);
        this.components = components;
    },
    watch: {
        // khi thay đổi các giá trị của prop hoặc data
        value: {
            deep: true,
            handler(val) {
                this.values = val || [];
            },
        },
        values() {
            this.$emit("change", this.values);
        },
    },
    methods: {
        // nơi định nghĩa các hàm sử dụng
        handleChange() {
            this.$emit("change", this.values);
        },
        createComponents(elements, regExp) {
            const components = [];
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                if (!regExp.test(element)) {
                    components.push({
                        type: "label",
                        localStringValue: element,
                    });
                } else {
                    components.push(this.createOtherComponent(element));
                }
            }
            return components;
        },
        createOtherComponent(localStringValue) {
            // {0,choice,-1#cuối cùng|1#đầu tiên|2#thứ hai|3#thứ ba|4#thứ tư}
            const trimLocalString = localStringValue.substring(
                1,
                localStringValue.length - 1
            );
            const a = trimLocalString.split(",");
            const idx = parseInt(a.shift());
            const type = a.shift();
            const extraValue = a.shift();
            const component = {
                valueOrder: idx,
                type: type,
                extraValue: extraValue,
                localStringValue: localStringValue,
                datasource: this.getDatasource(idx, type, extraValue),
                min: this.getMinValue(type),
                max: this.getMaxValue(type, idx),
            };
            return component;
        },
        getMinValue(type) {
            let min = 1;
            if (type == 'number') {
                switch(this.localeKey) {
                    case "zimbra.zmMsg.recurDailyEveryNumDays":
                        min = 1;
                        break;
                }
            }
            return min;
        },
        getMaxValue(type, idx) {
            let max = 999;
            if (type == 'number') {
                switch(this.localeKey) {
                    case "zimbra.zmMsg.recurDailyEveryNumDays":
                    case "zimbra.zmMsg.recurWeeklyEveryNumWeeksDate":
                    case "zimbra.zmMsg.recurMonthlyEveryNumMonthsWeekDays":
                        max = 99;
                        break;
                    case "zimbra.zmMsg.recurMonthlyEveryNumMonthsDate":
                        max = idx === 0 ? 31 : 99;
                        break;
                    case "zimbra.zmMsg.recurYearlyEveryDate":
                        max = 31;
                        break;
                }
            }
            return max;
        },
        getDatasource(valueOrder, type, extraValue) {
            let datasource = [];
            if (type === "choice") {
                const options = extraValue.split("|");
                options.forEach((item) => {
                    const option = item.split("#");
                    datasource.push({
                        value: parseInt(option[0], 10),
                        label: option[1] || this.$t("zimbra.zmMsg.days"),
                    });
                    const _first = datasource.filter(e => e.value > 0);
                    const _last = datasource.filter(e => e.value <= 0);
                    datasource = _first.concat(_last);
                });
                if (this.localeKey === "zimbra.zmMsg.recurMonthlyEveryNumMonthsNumDay" ||
                    (valueOrder === 1 && this.localeKey === "zimbra.zmMsg.recurMonthlyEveryNumMonthsWeekDays") ||
                    (valueOrder === 1 && this.localeKey === "zimbra.zmMsg.recurYearlyEveryMonthWeekDays")
                ) {
                    datasource = datasource.concat(DATASOURCE_TIME_DAYS);
                }
            } else if (
                (type === "date" && extraValue === "EEEE") ||
                (type === "list" && extraValue === "date")
            ) {
                datasource = DATASOURCE_TIME_DAYS;
                if (this.localeKey === "zimbra.zmMsg.recurWeeklyEveryWeekday") {
                    datasource = datasource.concat([
                        {
                            value: ["MO", "WE", "FR"].join("|"),
                            label: this.titleMonWedFri
                        },
                        {
                            value: ["TU", "TH"].join("|"),
                            label: this.titleTueThu
                        },
                        {
                            value: ["SA", "SU"].join("|"),
                            label: this.titleSatSun
                        },
                    ])
                }
            } else if (type === "date" && extraValue === "MMMM") {
                datasource = DATASOURCE_MONTHS;
            }
            return datasource;
        },
        onfocus() {
            this.$emit("onfocus");
        }
    },
};
</script>
<style lang="scss">
</style>