<template>
    <el-popover v-model="showMenu" id="menu" class="right-menu popover-wrap">
        <el-menu
            ref="rightMenu"
            class="box-control-inbox"
        >
            <el-menu-item
                :hidden="isFolderSearch || isRootSearchFolder"
                index="1"
                class="menu-item"
                @click="$root.$emit('handleSidebarActionAdd')"
                :disabled="
                    disabledRightMenu[0] ||
                    !hasPermissionSpecifyFolder(
                        treeNodeData,
                        RIGHTS_FOLDER.CREATE_SUBFOLDER
                    )
                "
            >
                <i class="el-icon-folder-add" style="color: orange"></i>
                <span>{{ $t("zimbraNokey.zimbra_sidebar_rightMenu_menuItem1") }}</span>
            </el-menu-item>

            <!-- Nạp nguồn tin -->
            <el-menu-item
                :hidden="
                    ((!isFolderRSS || isRootSearchFolder) &&
                        !isParentFolderRSS(treeNodeData)) ||
                    isFolderDefaut(treeNodeData)
                "
                index="1"
                class="menu-item"
                @click="checkFeed(treeNodeData)"
                :disabled="!isFolderRSS"
            >
                <i class="el-icon-refresh-right" style="color: blue"></i>
                <span>{{ $t("zimbra.zhMsg.checkFeed") }}</span>
            </el-menu-item>
            <!-- Tải lại tất cả nguồn cấp tin -->
            <el-menu-item
                :hidden="
                    ((!isFolderRSS || isRootSearchFolder) &&
                        !isParentFolderRSS(treeNodeData)) ||
                    isFolderDefaut(treeNodeData)
                "
                index="1"
                class="menu-item"
                @click="reloadAllFeed()"
                :disabled="!isParentFolderRSS(treeNodeData)"
            >
                <i class="el-icon-refresh-right" style="color: blue"></i>
                <span>{{ $t("zimbra.zmMsg.checkAllFeed") }}</span>
            </el-menu-item>
            <!-- nhận thư ngoài -->
            <el-menu-item
                :hidden="!treeNodeData.isFolderPop"
                index="1"
                class="menu-item"
                @click="loadExternalMail()"
            >
                <i class="el-icon-refresh-right" style="color: blue"></i>
                <span>{{ $t("zimbra.zmMsg.checkExternalMail") }}</span>
            </el-menu-item>
            <!-- đánh dấu đã đọc -->
            <el-menu-item
                index="2"
                class="menu-item"
                :hidden="isFolderSearch || isRootSearchFolder"
                :disabled="
                    disabledRightMenu[1] ||
                    !hasPermissionSpecifyFolder(
                        treeNodeData,
                        RIGHTS_FOLDER.WRITE
                    )
                "
                @click="readAll"
            >
                <i class="el-icon-check" style="color: teal"></i>
                <span>{{ $t("zimbra.zhMsg.actionMarkAllRead") }}</span>
            </el-menu-item>
            <!-- xóa sạch thư mục -->
            <el-menu-item
                index="3"
                class="menu-item"
                :hidden="isTrash || isFolderSearch || isRootSearchFolder"
                @click="clickEmptyFolder"
                :disabled="
                    disabledRightMenu[2] || isFolderDefautShare(treeNodeData)
                "
            >
                <div v-if="!isJunk">
                    <i class="el-icon-delete" style="color: red"></i>
                    <span>{{ $t("zimbra.zhMsg.folderEmptyFolder") }}</span>
                </div>
                <div v-if="isJunk">
                    <i class="el-icon-delete" style="color: red"></i>
                    <span>{{ $t("zimbra.zmMsg.emptyJunk") }}</span>
                </div>
            </el-menu-item>
            <el-menu-item
                index="4"
                class="menu-item"
                :hidden="!isTrash || isRootSearchFolder"
                @click="clickEmptyTrash"
                :disabled="disabledRightMenu[3]"
            >
                <i class="el-icon-delete" style="color: red"></i>
                <span>{{ $t("zimbraNokey.zimbra_sidebar_rightMenu_menuItem4") }}</span>
            </el-menu-item>
            <!-- chia sẻ thư mục -->
            <el-menu-item
                :hidden="isFolderSearch || isRootSearchFolder"
                index="5"
                class="menu-item"
                @click="$root.$emit('handleSidebarActionShare', {}, false)"
                :disabled="
                    disabledRightMenu[4] ||
                    !hasPermissionSpecifyFolder(
                        treeNodeData,
                        RIGHTS_FOLDER.ADMINISTER
                    )
                "
            >
                <i class="el-icon-share" style="color: cornflowerblue"></i>
                <span>{{ $t("zimbra.zmMsg.shareFolder") }}</span>
            </el-menu-item>
            <!-- di chuyển thư mục -->
            <el-menu-item
                index="6"
                class="menu-item"
                @click="
                    activeNodeSidebarRightClick.isSearch
                        ? $root.$emit(
                              'handleSidebarActionMoveArchiveMail',
                              FOLDER_EXTRA_ACTION.MOVE_FOLDER_SEARCH
                          )
                        : $root.$emit(
                              'handleSidebarActionMoveArchiveMail',
                              FOLDER_EXTRA_ACTION.MOVE_FOLDER
                          )
                "
                :disabled="
                    isFolderExternalAccount(treeNodeData) ||
                    disabledRightMenu[5] ||
                    (!hasPermissionSpecifyFolder(
                        treeNodeData,
                        RIGHTS_FOLDER.WRITE
                    ) &&
                        treeNodeData.l != FOLDER_DEFAULT.ROOT)
                "
                :hidden="isRootSearchFolder"
            >
                <i class="el-icon-truck" style="color: black"></i>
                <span>{{ $t("zimbra.zhMsg.actionGo") }}</span>
            </el-menu-item>
            <!-- xóa thư mục -->
            <el-menu-item
                index="7"
                class="menu-item"
                @click="clickDeleteFolder"
                :disabled="
                    disabledRightMenu[6] ||
                    (!hasPermissionSpecifyFolder(
                        treeNodeData,
                        RIGHTS_FOLDER.WRITE
                    ) &&
                        this.treeNodeData.l != FOLDER_DEFAULT.ROOT)
                "
                :hidden="isRootSearchFolder"
            >
                <i class="el-icon-circle-close" style="color: red"></i>
                <span>{{ $t("zimbra.zhMsg.actionDelete") }} </span>
            </el-menu-item>
            <el-menu-item
                index="8"
                class="menu-item"
                @click="$root.$emit('handleSidebarActionEditName')"
                :disabled="
                    isFolderExternalAccount(treeNodeData) ||
                    disabledRightMenu[7] ||
                    (!hasPermissionSpecifyFolder(
                        treeNodeData,
                        RIGHTS_FOLDER.WRITE
                    ) &&
                        treeNodeData.l != FOLDER_DEFAULT.ROOT)
                "
                :hidden="isRootSearchFolder"
            >
                <i class="el-icon-edit-outline" style="color: blue"></i>
                <span>{{
                        treeNodeData.isSearch
                            ? $t("zimbra.zmMsg.renameSearch")
                            : $t("zimbra.zhMsg.renameFolder")
                    }}</span>
            </el-menu-item>
            <el-menu-item
                index="9"
                class="menu-item"
                @click="
                    isFolderSearch
                        ? $root.$emit('handleActionEditFolderSearch')
                        : $root.$emit('handleSidebarActionEdit')
                "
                :disabled="
                    disabledRightMenu[8] ||
                    (!hasPermissionSpecifyFolder(
                        treeNodeData,
                        RIGHTS_FOLDER.WRITE
                    ) &&
                        treeNodeData.l != FOLDER_DEFAULT.ROOT)
                "
                :hidden="isRootSearchFolder"
            >
                <i class="el-icon-edit"></i>
                <span>{{ $t("zimbra.zmMsg.editProperties") }}</span>
            </el-menu-item>
            <el-menu-item
                index="10"
                class="menu-item"
                @click="syncFolder()"
                :hidden="!showActionSync"
            >
                <i class="el-icon-refresh-right" style="color: blue"></i>
                <span v-if="!isSync">{{ $t("zimbra.zmMsg.syncOfflineFolderOn") }}</span>
                <span v-else>{{ $t("zimbra.zmMsg.syncOfflineFolderOff") }}</span>
            </el-menu-item>
            <el-menu-item
                index="10"
                class="menu-item"
                @click="openInTab(note)"
                :disabled="disabledRightMenu[9]"
                :hidden="isRootSearchFolder"
            >
                <i class="el-icon-folder"></i>
                <span>{{ $t("zimbra.zmMsg.openInTab") }}</span>
            </el-menu-item>
            <el-menu-item
                index="11"
                class="menu-item"
                @click="extendAll()"
                :disabled="disabledRightMenu[10]"
            >
                <i class="el-icon-rank"></i>
                <span>{{ $t("zimbra.zmMsg.expandAll") }}</span>
            </el-menu-item>
        </el-menu>
    </el-popover>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import {
    FOLDER_DEFAULT,
    FOLDER_EXTRA_ACTION,
    FOLDER_VIEW,
    READ_EMAIL_LAYOUT_ROUTER,
    RIGHTS_FOLDER,
    SIDEBAR_ACTION,
    STATUS_REQUEST
} from "@/utils/constants";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
    mixins: [CommonUtils],
    computed: {
        ...mapGetters([
            "activeNodeSidebar",
            "activeNodeSidebarRightClick",
            "dataSidebarInBox",
            "mailQuota",
            "dataSidebarExtend"
        ])
    },
    mounted() {
        this.$root.$on("showRightMenu", (e, data, note) => {
            this.show(e, data, note);
        });
        this.$root.$on("deleteFolder", (data) => {
            this.treeNodeData = data;
            this.deleteFolder();
        });
        this.$root.$on("closeRightMenuSidebar", () => {
            this.showMenu = false;
        });
    },
    watch: {
        showMenu(val) {
            this.$root.$emit("showInfoFolder", !val);
        }
    },
    data() {
        return {
            showMenu: false,
            note: "",
            isTrash: false,
            isJunk: false,
            isFolderSearch: false,
            isFolderRSS: false,
            disabledRightMenu: [
                false, // 0 Thêm thư mục
                false, // 1 Đánh dấu tất cả là đã đọc
                false, // 2 Xóa sạch thư mục
                false, // 3 Xóa sạch thùng rác
                false, // 4 Chia sẻ thư mục
                false, // 5 Chuyển
                false, // 6 Xóa
                false, // 7 Đổi tên thư mục
                false, // 8 Sửa các thuộc tính
                false, // 9 Mở trong tab
                false // 10 Mở rộng tất cả
            ],
            SIDEBAR_ACTION: SIDEBAR_ACTION,
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            FOLDER_EXTRA_ACTION: FOLDER_EXTRA_ACTION,
            RIGHTS_FOLDER: RIGHTS_FOLDER,
            treeNodeData: {},
            folderUndo: {},
            notify: {},
            isRootSearchFolder: false,
            showActionSync: false,
            isSync: false,
            menuHeight: 410
        };
    },

    methods: {
        findFolderById(id, listFolder) {
            for (let index in listFolder) {
                if (listFolder[index].id == id) {
                    return listFolder[index];
                } else if (listFolder[index].folder) {
                    const result = this.findFolderById(id, listFolder[index].folder);
                    if (result != null) {
                        return result;
                    }
                }
            }
            return null;
        },
        syncFolder() {
            const form = {
                folder: `<urn1:action id="${this.treeNodeData.id}" op="${this.isSync ? "!syncon" : "syncon"}"></urn1:action>`
            };
            ZimbraMailService.folderActionRequest(form).then(() => {
                const folderUpdate = this.findFolderById(this.treeNodeData.id, this.dataSidebarExtend[0].folder);
                if (this.isSync && folderUpdate) {
                    folderUpdate.f = folderUpdate.f.replace("~", "");
                } else {
                    folderUpdate.f = folderUpdate.f + "~";
                }
                this.$root.$emit("updateItemToDataExtend", folderUpdate);
            });
        },
        async loadExternalMail() {

            const externalAcc = this.externalAccount.find(x => x.l == this.treeNodeData.id);

            const formData = {
                pop3: {
                    id: externalAcc.id
                }
            };
            ZimbraMailService.importDataRequest(formData).then(() => {
                this.$notify({
                    title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: this.$t("zimbra.zmMsg.dataSourceLoadSuccess", [externalAcc.name]),
                    type: "success"
                });
                this.getExternalMail();
            });
        },
        getExternalMail() {
            ZimbraMailService.getImportStatusRequest()
                .then((res) => {
                    const pop = this.getResponseBody(res)["GetImportStatusResponse"];
                    if (pop) {
                        const pop3 = Array.isArray(pop.pop3) ? pop.pop3 : [pop.pop3];
                        if (pop3.findIndex(x => x.isRunning) > -1) {
                            setTimeout(() => {
                                this.getExternalMail();
                            }, 5000);
                        }
                    }
                    const { context } = this.getResponseHeader(res);
                    const { created, modified } = context?.notify || {};
                    if (created || modified) {
                        this.handleUpdateDataExternal(created, modified);
                    }
                })
                .catch(() => {
                });
        },
        handleUpdateDataExternal(created, modified) {
            if (modified) {
                const listFolderUpdate = Array.isArray(modified.folder) ? modified.folder : [modified.folder];
                listFolderUpdate.forEach(x => {
                    let folderUpdate = this.dataSidebarInBox[0].folder.find(e => e.id.toString() == x.id);
                    folderUpdate.n = x.n;
                    folderUpdate.u = x.u;
                    folderUpdate.s = x.s;
                    this.$root.$emit("updateItemToDataInbox", folderUpdate);
                });
                if (listFolderUpdate && listFolderUpdate.map(x => x.id).includes(this.activeNodeSidebar.id)) {
                    this.$root.$emit("reloadListMail");
                }
            }
        },
        show(e, data, note) {
            this.menuHeight = 410;
            if (
                (data.id != FOLDER_DEFAULT.EXTEND &&
                    data.id != FOLDER_DEFAULT.ROOT) ||
                data.isRootSearchFolder
            ) {
                this.showActionSync = false;
                this.showMenu = false;
                this.isRootSearchFolder = data.isRootSearchFolder;
                this.isFolderRSS = !_.isNil(data.url);
                if (data.isSearch) {
                    this.isFolderSearch = true;
                } else {
                    this.isFolderSearch = false;
                }
                this.isJunk = data.id == FOLDER_DEFAULT.JUNK;
                this.isTrash = data.id == FOLDER_DEFAULT.TRASH;
                this.note = note;
                this.treeNodeData = data;
                this.$store.commit("SET_ACTIVE_NODE_SIDEBAR_RIGHTCLICK", data);
                if (data.isLink && data.perm == undefined) {
                    // this.nodeClick(data);
                    return;
                }
                if (data.id != FOLDER_DEFAULT.STARS) {
                    // check nút stars
                    this.checkDisableRootFolder(data);
                    let menu = document.querySelector("#menu");
                    if (this.isFolderRSS || this.isParentFolderRSS(data)) {
                        this.menuHeight = this.menuHeight + 75;
                    }
                    const screenHeight =
                        document.getElementById("appZimbra").offsetHeight;
                    if (data.isFolderPop) {
                        this.menuHeight = this.menuHeight + 40;
                    }
                    if (
                        screenHeight - e.clientY < this.menuHeight &&
                        !this.isRootSearchFolder
                    ) {
                        menu.style.top = screenHeight - this.menuHeight + "px";
                    } else {
                        menu.style.top = e.clientY + "px";
                    }
                    menu.style.left = e.clientX + "px";

                    this.showMenuTag = false;
                    this.showMenu = true;
                    this.$root.$emit("closeRightMenuMail");
                    document.addEventListener("click", this.closeRightMenu);
                    document.addEventListener("contextmenu", this.closeRightMenu);
                }
            }
            const { name } = this.$route;
            if (name == READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE) {
                this.isMessage = true;
            } else {
                this.isMessage = false;
            }
        },
        isFolderDefaut(data) {
            for (var item in FOLDER_DEFAULT) {
                if (FOLDER_DEFAULT[item] == data.id) {
                    return true;
                }
            }
            return false;
        },
        isFolderDefautShare(data) {
            for (var item in FOLDER_DEFAULT) {
                if (data.rid && FOLDER_DEFAULT[item] == data.rid) {
                    return true;
                }
            }
            return false;
        },
        isParentFolderRSS(data) {
            if (this.checkUrlExist(data)) {
                return true;
            }
            if (data.folder) {
                if (Array.isArray(data.folder)) {
                    for (let item in data.folder) {
                        if (this.isParentFolderRSS(data.folder[item])) {
                            return true;
                        }
                    }
                } else {
                    if (this.isParentFolderRSS(data.folder)) {
                        return true;
                    }
                }
            }
            return false;
        },
        checkUrlExist(data) {
            return !_.isNil(data.url);
        },
        checkDisableRootFolder(data) {
            for (var item in this.disabledRightMenu) {
                this.disabledRightMenu[item] = true;
            }
            const mapIdNameRootFolder = new Map([
                [6, "Drafts"],
                [2, "Inbox"],
                [5, "Sent"],
                [-1, "Extend"],
                [4, "Junk"],
                [3, "Trash"],
                [-2, "Tag"]
            ]);
            switch (mapIdNameRootFolder.get(data.id)) {
                case "Drafts":
                    this.disabledRightMenu[4] = false;
                    this.disabledRightMenu[8] = false;
                    this.disabledRightMenu[9] = false;
                    break;
                case "Inbox":
                    this.disabledRightMenu[0] = false;
                    this.disabledRightMenu[4] = false;
                    this.disabledRightMenu[8] = false;
                    this.disabledRightMenu[9] = false;
                    this.disabledRightMenu[2] = true;
                    break;
                case "Sent":
                    this.disabledRightMenu[0] = false;
                    this.disabledRightMenu[4] = false;
                    this.disabledRightMenu[8] = false;
                    this.disabledRightMenu[9] = false;
                    this.disabledRightMenu[2] = true;
                    break;
                case "Extend":
                    break;
                case "Junk":
                    this.disabledRightMenu[9] = false;
                    this.disabledRightMenu[2] = false;
                    break;
                case "Trash":
                    if (data.n) {
                        this.disabledRightMenu[3] = false;
                    }
                    this.disabledRightMenu[0] = false;
                    this.disabledRightMenu[9] = false;
                    break;
                case "Tag":
                    break;
                default:
                    for (var i in this.disabledRightMenu) {
                        this.disabledRightMenu[i] = false;
                    }
            }
            if (data.search?.length > 0 || data.folder?.length > 0) {
                this.disabledRightMenu[3] = false;
                this.disabledRightMenu[10] = false;
            } else {
                this.disabledRightMenu[10] = true;
            }
            this.disabledRightMenu[1] = !data.u;
            if ((data.n && data.n > 0 && data.id != FOLDER_DEFAULT.DRAFTS) || data.search?.length > 0 || data.folder?.length > 0) {
                this.disabledRightMenu[2] = this.disabledRightMenu[2] || false;
            } else {
                this.disabledRightMenu[2] = true;
            }
            if (data.isSubFolderMap) {
                this.isSync = data.f?.includes("~");
                if (data.isInbox) {
                    this.showActionSync = true;
                    this.disabledRightMenu[2] = true;
                    this.disabledRightMenu[5] = true;
                    this.disabledRightMenu[6] = true;
                    this.disabledRightMenu[7] = true;
                }
                if (data.isSent) {
                    this.showActionSync = true;
                    this.disabledRightMenu[1] = true;
                    this.disabledRightMenu[2] = true;
                    this.disabledRightMenu[5] = true;
                    this.disabledRightMenu[6] = true;
                    this.disabledRightMenu[7] = true;
                }
                if (data.isJunk) {
                    this.showActionSync = true;
                    this.isJunk = true;
                    for (var index in this.disabledRightMenu) {
                        this.disabledRightMenu[index] = true;
                    }
                    this.disabledRightMenu[9] = false;
                    this.disabledRightMenu[2] = !(data.n && data.n > 0);
                }
                if (data.isTrash) {
                    this.showActionSync = true;
                    this.isTrash = true;
                    this.disabledRightMenu[4] = true;
                    this.disabledRightMenu[5] = true;
                    this.disabledRightMenu[6] = true;
                    this.disabledRightMenu[7] = true;
                    this.disabledRightMenu[8] = true;
                }
                if (this.showActionSync) {
                    this.menuHeight = this.menuHeight + 40;
                }
            }
        },
        closeRightMenu() {
            this.showMenu = false;
            document.removeEventListener("click", this.closeRightMenu);
            document.removeEventListener("contextmenu", this.closeRightMenu);
        },
        async readAll() {
            const form = this.treeNodeData.isLink ? { folder: `<urn1:action l="${this.treeNodeData.id}" id="${this.treeNodeData.zid}:${this.treeNodeData.rid.toString()}" op="read"></urn1:action>` } :
                { folder: `<urn1:action id="${this.treeNodeData.id}" op="read"></urn1:action>` };
            this.treeNodeData.u = 0;
            this.$store.commit("SET_ACTIVE_NODE_SIDEBAR", this.treeNodeData);
            if (this.treeNodeData.id == FOLDER_DEFAULT.INBOX) {
                const title = `VMail: ${this.treeNodeData.name}}`;
                this.setFavicon(0, title);
            }
            await ZimbraMailService.folderActionRequest(form);
            setTimeout(() => {
                this.$root.$emit("handleUpdateReadAll");
            }, 200);
        },
        clickEmptyFolder() {
            this.$confirm(
                this.$t("zimbraNokey.zimbra_sidebar_confirmEmptyFolder", {
                    name: this.treeNodeData.name
                }),
                this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    cancelButtonText: this.$t("zimbra.zhMsg.actionTaskCancelTT"),
                    type: "warning"
                }
            )
                .then(() => {
                    this.emptyFolder();
                })
                .catch(() => {
                });
        },
        emptyFolder() {
            const form = {
                folder: `<urn1:action id="${this.treeNodeData.id}" op="empty" recursive="0"></urn1:action>`
            };
            ZimbraMailService.folderActionRequest(form).then((res) => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    this.$notify({
                        title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                        message: this.$t(
                            "zimbraNokey.zimbra_sidebar_messageSuccessEmptyFolder",
                            { name: this.treeNodeData.name }
                        ),
                        type: "success"
                    });
                    // Load lại danh sách mail
                    if (this.treeNodeData.id === this.activeNodeSidebar.id) {
                        this.$root.$emit("emptyListEmail");
                        this.activeNodeSidebar.u = 0;
                        this.activeNodeSidebar.n = 0;
                        this.$store.commit(
                            "SET_ACTIVE_NODE_SIDEBAR",
                            this.activeNodeSidebar
                        );

                    }
                    // Cập nhật dung lượng hộp thư
                    const { context } = this.getResponseHeader(res);
                    const { modified } = context?.notify || {};
                    if (modified?.mbx?.s) {
                        this.$store.commit("SET_MAIL_QUOTA", { ...this.mailQuota, used: modified.mbx.s });
                    }
                }
            });
        },
        clickEmptyTrash() {
            this.$confirm(
                this.$t("zimbraNokey.zimbra_sidebar_confirmEmptyTrash"),
                this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    cancelButtonText: this.$t("zimbra.zhMsg.actionTaskCancelTT"),
                    type: "warning"
                }
            )
                .then(() => {
                    this.emptyTrash();
                })
                .catch(() => {
                });
        },
        emptyTrash() {
            const xml = {
                folder: `<urn1:action id="${this.treeNodeData.id}" recursive="true" op="empty"></urn1:action>`
            };
            ZimbraMailService.folderActionRequest(xml).then((res) => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    if (this.isMessage) {
                        this.$message({
                            type: "success",
                            customClass: "zm-notify",
                            duration: 3500,
                            dangerouslyUseHTMLString: true,
                            message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessEmptyTrash")
                        });
                    } else {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t(
                                "zimbraNokey.zimbra_sidebar_messageSuccessEmptyTrash"
                            ),
                            type: "success"
                        });
                    }
                    // Cập nhật dung lượng hộp thư
                    const { context } = this.getResponseHeader(res);
                    const { modified } = context?.notify || {};
                    if (modified?.mbx?.s) {
                        this.$store.commit("SET_MAIL_QUOTA", { ...this.mailQuota, used: modified.mbx.s });
                    }
                }
            });
            this.treeNodeData.folder = [];
            this.$root.$emit("emptyListEmail");
            this.$store.commit("SET_ACTIVE_NODE_SIDEBAR_RIGHTCLICK", this.treeNodeData);
        },
        deleteFolder() {
            const form = {
                folder: `<urn1:action id="${this.treeNodeData.id}" op="trash"></urn1:action>`
            };
            if (this.treeNodeData.absFolderPath.startsWith("/Trash/")) {
                form.folder = `<urn1:action id="${this.treeNodeData.id}" op="delete"></urn1:action>`;
            }
            const uuid = this.generateUniqueId();
            ZimbraMailService.folderActionRequest(form).then((res) => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    if (this.treeNodeData.l != FOLDER_DEFAULT.TRASH) {
                        if (this.isMessage) {
                            this.notify = this.$message({
                                type: "success",
                                customClass: "zm-notify",
                                duration: 3500,
                                dangerouslyUseHTMLString: true,
                                message: `<div> ${this.$t("zimbraNokey.zimbra_sidebar_messageSuccessDeleteFolder")} <span class="zm-content__title">
                                        <a class="zm-btn-undo" id="undo_${uuid}" href="javascript:;"}>
                                            ${this.$t("zimbra.zmMsg.undo")}
                                        </a>
                                    </span>
                                </div>`
                            });
                        } else {
                            this.notify = this.$notify({
                                dangerouslyUseHTMLString: true,
                                message: `<div> ${this.$t(
                                    "zimbraNokey.zimbra_sidebar_messageSuccessDeleteFolder"
                                )} <span style="margin-left: 5px;padding-left: 7px;padding-right: 7px;padding-top: 1px;padding-button: 1px;border: 1px solid white;"> <a id="undo_${uuid}" style="" href="javascript:;"}> ${this.$t(
                                    "zimbra.zmMsg.undo"
                                )}</a></span></div>`,
                                position: "bottom-left",
                                customClass: "notify-wrap",
                                duration: 4000
                            });
                        }
                        this.folderUndo = _.cloneDeep(this.treeNodeData);
                        var x = document.getElementById(`undo_${uuid}`);
                        x.addEventListener("click", this.undo);
                        this.$root.$emit(
                            "deleteItemToDataInBox",
                            this.treeNodeData
                        );
                        this.$root.$emit(
                            "deleteItemToDataExtend",
                            this.treeNodeData
                        );
                        this.treeNodeData.l = FOLDER_DEFAULT.TRASH;

                        //DWP-9
                        this.treeNodeData.absFolderPath = "/Trash/" + this.treeNodeData.name;
                        //this.buildAbsFolderPath(this.treeNodeData, { absFolderPath: "/Trash" });

                        this.$root.$emit(
                            "addItemToDataExtend",
                            this.treeNodeData
                        );
                        setTimeout(() => {
                            this.$root.$emit("setCurrentKeyMainTree", this.treeNodeData.id);
                            this.$root.$emit("extendFolderById", this.treeNodeData.id);
                        }, 200);
                        // Nếu folder xóa là loại tìm kiếm cuộc hẹn
                        if (this.treeNodeData.types == FOLDER_VIEW.APPOINTMENT) {
                            this.$root.$emit("onDeleteCalSearchFolder", this.treeNodeData);
                        }
                        //
                    } else {
                        this.$root.$emit(
                            "deleteItemToDataExtend",
                            this.treeNodeData
                        );
                    }
                }
            });
        },
        buildAbsFolderPath(parent, sub) {
            sub.absFolderPath = parent.absFolderPath + "/" + sub.name;
            if (sub.folder) {
                sub.folder.forEach(x => this.buildAbsFolderPath(sub, x));
            }
        },
        undo() {
            this.notify.close();
            const form = {
                folder: `<urn1:action id="${this.treeNodeData.id}" op="move" l="${this.folderUndo.l}"></urn1:action>`
            };

            ZimbraMailService.folderActionRequest(form).then((res) => {

                if (res.status == STATUS_REQUEST.SUCCESS) {
                    this.dialogMoveFolder = false;
                    this.treeNodeData.l = this.folderUndo.l;
                    this.treeNodeData.absFolderPath = this.folderUndo.absFolderPath;
                    this.$root.$emit(
                        "deleteItemToDataExtend",
                        this.treeNodeData
                    );

                    //TO DO logic lấy hết forder ra tìm bản khi != hoàn tác sau nó replace lại forder
                    for (let item in this.dataSidebarExtend[0].folder) {
                        //Forder id = 3 là thùng rác
                        if (this.dataSidebarExtend[0].folder[item].id === 3) {
                            const arrFolder = this.dataSidebarExtend[0].folder[item].folder.filter(
                                (x) => x.id != this.treeNodeData.id
                            );
                            this.dataSidebarExtend[0].folder[item].folder = arrFolder;
                        }
                    }

                    this.$root.$emit("addItemToDataInBox", this.treeNodeData);
                    if (
                        this.folderUndo.id != FOLDER_DEFAULT.ROOT ||
                        this.treeNodeData.isSearch
                    ) {
                        this.$root.$emit(
                            "addItemToDataExtend",
                            this.treeNodeData
                        );
                    }
                    if (this.treeNodeData.types == FOLDER_VIEW.APPOINTMENT) {
                        this.$root.$emit("onUpdateMoveCalSearchFolder", this.treeNodeData);
                    }
                    if (this.isMessage) {
                        this.$message({
                            type: "success",
                            customClass: "zm-notify",
                            duration: 3500,
                            dangerouslyUseHTMLString: true,
                            message: this.$t("zimbraNokey.zimbra_sidebar_messageSuccessUndoFolder")
                        });
                    } else {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t(
                                "zimbraNokey.zimbra_sidebar_messageSuccessUndoFolder"
                            ),
                            type: "success"
                        });
                    }
                }
            });
        },
        extendAll() {
            this.$root.$emit("extendAllFolder");
        },
        clickDeleteFolder() {
            if (this.isFolderExternalAccount(this.treeNodeData)) {
                this.$confirm(
                    this.$t("zimbra.zmMsg.errorDeletePopFolder", ["", this.treeNodeData.name]),
                    this.$t("zimbraNokey.zimbra_common_titleInfo"),
                    {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        showCancelButton: false,
                        type: "warning"
                    }
                ).then(() => {

                }).catch(() => {
                });
                return;
            }
            let msgConfirm = this.$t("zimbraNokey.zimbra_sidebar_confirmDeleteFolder", {
                name: this.treeNodeData.name
            });
            if (this.treeNodeData.absFolderPath.startsWith("/Trash/")) {
                msgConfirm = this.$t(
                    "zimbraNokey.zimbra_sidebar_confirmPermanentlyDeleteFolder",
                    { name: this.treeNodeData.name }
                );
            }
            this.$confirm(
                msgConfirm,
                this.$t("zimbra.zmMsg.confirmDeleteApptTitle"),
                {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    cancelButtonText: this.$t("zimbra.zhMsg.actionTaskCancelTT"),
                    type: "warning"
                }
            )
                .then(() => {
                    this.deleteFolder();
                    this.$root.$emit("handleDeleteFolder");
                })
                .catch(() => {
                });
        },
        async checkFeed(data) {
            const form = {
                folder: `<urn1:action id="${data.id}" op="sync"></urn1:action>`
            };
            await ZimbraMailService.folderActionRequest(form);
            this.$root.$emit("reloadDataFolder", data.id);
        },
        reloadAllFeed() {
            this.dataSidebarInBox.forEach((x) => {
                this.recursiveReload(x);
            });
        },
        recursiveReload(data) {
            if (!_.isNil(data.url)) {
                this.checkFeed(data);
            }
            if (data.folder) {
                if (Array.isArray(data.folder)) {
                    data.folder.forEach((x) => this.recursiveReload(x));
                } else {
                    this.recursiveReload(data.folder);
                }
            }
        },
        openInTab(note) {
            const query = `in:"${this.getAbsFolderPathDisplay(
                note.data.absFolderPath
            )}"`;
            if (this.$route.name === "Setting") {
                this.$store.commit("SET_LIST_QUERY_TEMP", [query]);
            } else {
                this.$store.commit("SET_LIST_QUERY", [query]);
                this.$store.commit("SET_LIST_QUERY_TEMP", [query]);
            }
            this.$store.commit("SET_SHOW_SEARCH_BOX", true);
            this.$root.$emit("handleSearchBox", query);
        }
    }
};
</script>
