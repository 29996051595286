import Dwt from "./Dwt";
import MD5 from "crypto-js/md5";
import AjxUtil from "./AjxUtil";
import AjxStringUtil from "./AjxStringUtil";

const ZmComposeView = function () {
}

// body components
ZmComposeView.BC_NOTHING = "NOTHING"; // marks beginning and ending
ZmComposeView.BC_TEXT_PRE = "TEXT_PRE"; // canned text (might be user-entered or some form of extraBodyText) 
ZmComposeView.BC_SIG_PRE = "SIG_PRE"; // a sig that goes above quoted text
ZmComposeView.BC_DIVIDER = "DIVIDER"; // tells reader that quoted text is coming
ZmComposeView.BC_HEADERS = "HEADERS"; // from original msg
ZmComposeView.BC_QUOTED_TEXT = "QUOTED_TEXT"; // quoted text
ZmComposeView.BC_SIG_POST = "SIG_POST"; // a sig that goes below quoted text

ZmComposeView.styleQuote = "border-left: 2px solid #1010FF; margin-left: 5px; padding-left: 5px; color: #000; font-weight: normal; font-style: normal; text-decoration: none; font-family: Helvetica,Arial,sans-serif; font-size: 12pt;";

ZmComposeView.BC_MARKER_CHAR = '\u200B';
ZmComposeView.BC_MARKER_REGEXP = new RegExp(ZmComposeView.BC_MARKER_CHAR, 'g');

ZmComposeView.BQ_BEGIN = "BQ_BEGIN";
ZmComposeView.BQ_END = "BQ_END";

ZmComposeView.BC_ALL_COMPONENTS = [
    ZmComposeView.BC_NOTHING, // 0
    ZmComposeView.BC_TEXT_PRE, // 1
    ZmComposeView.BC_SIG_PRE, // 2
    ZmComposeView.BC_DIVIDER, // 3
    ZmComposeView.BC_HEADERS, // 4
    ZmComposeView.BC_QUOTED_TEXT, // 5
    ZmComposeView.BC_SIG_POST, // 6
    ZmComposeView.BC_NOTHING // 7
];

// Create a unique marker sequence (vary by length) for each component, and regexes to find them
ZmComposeView.BC_TEXT_MARKER = {};
ZmComposeView.BC_TEXT_MARKER_REGEX1 = {};
ZmComposeView.BC_TEXT_MARKER_REGEX2 = {};

AjxUtil.foreach(ZmComposeView.BC_ALL_COMPONENTS, function (comp, index) {
    if (comp !== ZmComposeView.BC_NOTHING) {
        // Note: relies on BC_NOTHING coming first
        var markerChar = ZmComposeView.BC_MARKER_CHAR;
        var marker = ZmComposeView.BC_TEXT_MARKER[comp] = AjxStringUtil.repeat(markerChar, index);

        ZmComposeView.BC_TEXT_MARKER_REGEX1[comp] = new RegExp("^" + marker + "[^" + markerChar + "]");
        ZmComposeView.BC_TEXT_MARKER_REGEX2[comp] = new RegExp("[^" + markerChar + "]" + marker + "[^" + markerChar + "]");
    }
});

const generateCid = () => {
    const timeStr = "" + new Date().getTime();
    // var hash = AjxSHA1.hex_sha1(timeStr + Dwt.getNextId());
    const hash = MD5(timeStr + Dwt.getNextId()).toString();
    return hash + "@zimbra";
};


export default {
    ...ZmComposeView,
    generateCid
}
