<template>
    <div>
        <div
            v-show="showMenu"
            id="menuMailFolder"
            class="right-menu"
            active-text-color="#303133"
        >
            <el-menu class="box-control-inbox">
                <el-menu-item
                    index="1"
                    class="menu-item"
                    @click="addFolder"
                >
                    <i class="el-icon-folder-add" style="color: orange"></i>
                    <span>{{
                        $t("zimbra.zhMsg.folderNew")
                    }}</span>
                </el-menu-item>
                <hr style="margin:0">
                <el-menu-item
                    index="2"
                    class="menu-item"
                    @click="handleShowActivityStream"
                >
                    <i class="ic-tag-right icon-custom-wrap" style="color: teal"></i>
                    <span>{{$t("zimbra.zmMsg.activityStream")}}</span>
                </el-menu-item>
                <el-menu-item
                    index="3"
                    class="menu-item"
                    @click="extendAll()"
                >
                    <i class="el-icon-rank"></i>
                    <span>{{ $t("zimbra.zmMsg.expandAll") }}</span>
                </el-menu-item>
                 <!-- nhận thư ngoài -->
                <el-menu-item
                    :hidden="!treeNodeData.isFolderMap"
                    index="4"
                    class="menu-item"
                    @click="loadExternalMail()"
                >
                    <i class="el-icon-refresh-right" style="color: blue"></i>
                    <span>{{ $t("zimbra.zmMsg.checkExternalMail") }}</span>
                </el-menu-item>
                <el-menu-item
                    index="5"
                    class="menu-item"
                    @click="$root.$emit('showAddShareFolder', FOLDER_DEFAULT.INBOX)"
                >
                    <i class="ic-people icon-custom-wrap"></i>
                    <span>{{$t("zimbra.zmMsg.findShares")}}</span>
                </el-menu-item>
            </el-menu>
        </div>
    </div>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import { FOLDER_DEFAULT } from "@/utils/constants";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
export default {
    mixins: [CommonUtils],
    computed: {
        ...mapGetters([
            "dataSidebarExtend",
            "activeNodeSidebar"
        ]),
    },
    mounted() {
        this.$root.$on("showRightMenuMailFolder", (e, data) => {
            this.show(e, data);
        });
        this.$root.$on("closeRightMenuMailFolder", () => {
            this.showMenu = false;
        });
    },
    data() {
        return {
            showMenu: false,
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            treeNodeData: {}
        };
    },
    methods: {
        addFolder() {
            if (this.treeNodeData.isFolderMap) {
                this.$root.$emit('handleSidebarActionAdd');
            } else {
                this.$root.$emit('handleSidebarActionMoveAdd', false);
            }
        },
        ...mapActions([
            "getFilterRulesIncoming"
        ]),
        async loadExternalMail() {
            const externalAcc = this.externalAccount.find(x => x.l == this.treeNodeData.id);
            const formData = {
                pop3: {
                    id: externalAcc.id
                },
                imap: {
                    id: externalAcc.id
                }
            }
            ZimbraMailService.importDataRequest(formData).then(() => {
                this.$notify({
                    title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: this.$t("zimbra.zmMsg.dataSourceLoadSuccess", [externalAcc.name]),
                    type: "success",
                });
                this.getExternalMail();
            })
        },
        getExternalMail() {
            ZimbraMailService.getImportStatusRequest()
                .then((res) => {
                    const pop = this.getResponseBody(res)["GetImportStatusResponse"];
                    if (pop) {
                        const pop3 = Array.isArray(pop.pop3) ? pop.pop3 : [pop.pop3];
                        if (pop3.findIndex(x => x.isRunning) > -1) {
                            setTimeout(() => {
                                this.getExternalMail();
                            }, 5000)
                        }
                    }
                    const { context } = this.getResponseHeader(res);
                    const { created, modified } = context?.notify || {};
                    if (created || modified) {
                        this.handleUpdateDataExternal(created, modified);
                    }
                })
                .catch(() => {});
        },
        handleUpdateDataExternal (created, modified) {
            if (modified) {
                const listFolderUpdate = Array.isArray(modified.folder) ? modified.folder : [modified.folder] ;
                listFolderUpdate.forEach (x => {
                    let folderUpdate = this.dataSidebarExtend[0].folder.find( e => e.id.toString() == x.id);
                    folderUpdate.n = x.n;
                    folderUpdate.u = x.u;
                    folderUpdate.s = x.s;
                    this.$root.$emit("updateItemToDataExtend", folderUpdate);
                });
                if (listFolderUpdate && listFolderUpdate.map(x => x.id).includes(this.activeNodeSidebar.id)) {
                    this.$root.$emit("reloadListMail");
                }
            }
        },
        show(e, data) {
            this.treeNodeData = data;
            let menu = document.querySelector("#menuMailFolder");
            menu.style.left = e.clientX + "px";
            menu.style.top = e.clientY + "px";
            this.showMenu= true;
            document.addEventListener("click", this.closeRightMenu);
        },
        closeRightMenu() {
            this.showMenu = false;
            document.removeEventListener("click", this.closeRightMenu);
        },
        extendAll() {
            this.$root.$emit("extendAllFolder");
        },
        handleShowActivityStream(){
            this.getFilterRulesIncoming().then(() =>{
                this.$root.$emit('handleBuildFormActivityStream')
                this.$root.$emit('handleShowActivityStream')
            })
        }
    },
};
</script>