<template>
    <!-- <perfect-scrollbar> -->
    <div class="setting-tree-wrap">
        <div v-show="showInputSearch">
            <el-input
                class="el-text-control"
                v-model="filterText"
                style="padding-bottom: 10px"
                size="medium"
                ref="refInputSearch"
                @blur="blurInput"
            ></el-input>
        </div>
        <perfect-scrollbar style="height: 300px" id="treeBriefcaseExtra">
            <el-tree
                :class="`tree-wrap ${ this.isMoveBriefcase ? 'disabled-root-node': ''}`"
                ref="treeBriefcaseExtra"
                :data="dataBriefcase"
                node-key="id"
                :props="defaultProps"
                @node-click="nodeClick"
                :filter-node-method="filterNode"
                :expand-on-click-node="false"
                draggable
                highlight-current
                :default-expanded-keys="listIdInBoxFolderExpanded"
            >
                <div class="zm-folder-info" slot-scope="{ node, data }">
                    <el-popover popper-class="zm-tooltip" placement="bottom-start" :open-delay="1000" trigger="hover">
                        <div>
                            <b>{{ node.label }}</b> <br />
                            <div class="d-flex">
                                <span class="folder-info-left">{{ $t("zimbra.zmMsg.mailLabel")}}</span>
                                <span class="folder-info-right">{{ data.n || 0 }}</span>
                            </div>
                            <div v-if="data.s" class="d-flex">
                                <span class="folder-info-left">{{ $t("zimbra.zmMsg.fSizeLabel") }}</span>
                                <span class="folder-info-right">{{ formatSize(data.s) }}</span>
                            </div>
                        </div>
                        <span slot="reference" class="custom-tree-node" id="`${data.id}`">
                            <span v-if="!data.isLink">
                                <i v-if="data.id !== FOLDER_DEFAULT.TRASH" class="briefcase-icon"
                                    :class="data.id === ROOT_BRIEFCASE_FOLDER_ID ? 'active' : ''"
                                    :style="`filter: ${convertHexToFilterCss(
                                        data
                                    )}; padding-right: 18px; margin-right: 5px`"
                                ></i>
                                <i v-else  class="ic-delete-red"
                                    style="padding-right: 18px; margin-right: 5px"
                                ></i>
                            </span>
                            <span v-else>
                                <i :class="['ic-folder-copy ic-with-color', isActive(data) ? 'active' : '',]"
                                    :style="`padding-right: 30px; margin-left: -6px;filter: ${convertHexToFilterCss(data)}; --icColor: ${convertHexToFilterCss(data)}`"
                                ></i>
                            </span>
                            <span v-if=" data.isLink == undefined || (data.isLink && data.perm) " >
                                <span v-if="node.label.length > 30">
                                    {{ node.label.substring(0, 30) + "..." }}
                                </span>
                                <span v-else>
                                    {{ node.label }}
                                </span>
                            </span>
                            <span v-if="data.isLink && data.perm == undefined" style="text-decoration: line-through"
                                >{{ node.label }}
                            </span>
                        </span>
                    </el-popover>
                </div>
            </el-tree>
        </perfect-scrollbar>
    </div>
    <!-- </perfect-scrollbar> -->
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import { FOLDER_DEFAULT, ROOT_BRIEFCASE_FOLDER_ID, FOLDER_VIEW, BRIEFCASE_ACTION } from "@/utils/constants";
import { mapGetters } from "vuex";
import _ from "lodash";
import $ from 'jquery';
export default {
    mixins: [CommonUtils],
    props: {
        showInputSearch: {
            type: Boolean,
            default: true,
        },
        isAddFolder: {
            type: Boolean,
            default: false,
        },
        isMoveBriefcase: {
            type: Boolean,
            default: false,
        },
        isMoveFast: {
            type: Boolean,
            default: false,
        },
        showInfoFolder: {
            type: Boolean,
            default: false,
        },
        listIdFolderHidden: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            defaultProps: {
                children: "folder",
                label: "name",
            },
            ROOT_BRIEFCASE_FOLDER_ID: ROOT_BRIEFCASE_FOLDER_ID,
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            filterText: "",
            dataBriefcase: [],
            allowfilter: true,
            node: {},
            listIdInBoxFolderExpanded: [FOLDER_DEFAULT.ROOT],
            dataBefore: {},
        };
    },
    created() {
        let listTreeBriefcase = _.cloneDeep(this.briefcaseTreeFolders);
        listTreeBriefcase = listTreeBriefcase.filter(folder => !this.listIdFolderHidden.includes(folder.id))
        const rootTree = {
            id: ROOT_BRIEFCASE_FOLDER_ID,
            name: this.$t('zimbra.zmMsg.briefcaseFolders'),
            view: FOLDER_VIEW.DOCUMENT,
            folder: listTreeBriefcase
        }
        this.dataBriefcase = [rootTree]
    },
    mounted() {
        this.$root.$on("setFocusNodeActive", () => {
            this.setFocusNodeActive();
        });
        this.$root.$on("setCurrentKey", (id) => {
            this.setCurrentKey(id);
        });
        this.$root.$on("extendFolderExtra", (id) => {
            this.listIdInBoxFolderExpanded.push(id);
        });
    },
    watch: {
        listIdFolderHidden: {
            deep: true,
            handler(val) {
                this.dataBriefcase[0].folder = this.dataBriefcase[0].folder.filter(x => !val.includes(x.id));
            }
        },
        filterText(val) {
            if (this.allowfilter) {
                this.$refs.treeBriefcaseExtra.filter(val);
            }
        },
        briefcaseTreeFolders: {
            deep: true,
            handler(val) {
                val = val.filter(folder => !this.listIdFolderHidden.includes(folder.id))
                const rootTree = {
                    id: ROOT_BRIEFCASE_FOLDER_ID,
                    name: this.$t('zimbra.zmMsg.briefcaseFolders'),
                    view: FOLDER_VIEW.BRIEFCASE,
                    folder: val
                }
                this.dataBriefcase = [rootTree];
            }
        },
    },
    computed: {
        ...mapGetters([
            "briefcaseTreeFolders",
            "nodeBriefcaseExtraCurrent"
        ]),
    },
    methods: {
        blurInput() {
            this.filterText = this.filterText.trim()
        },
        setCurrentKey(id) {
            setTimeout(() => {
                this.$refs.treeBriefcaseExtra && this.$refs.treeBriefcaseExtra.setCurrentKey(id);
            }, 200);
        },
        filterNode(value, data) {
            if (this.allowfilter) {
                if (!value) return true;
                return data.name.toString().indexOf(value.toString().trim()) !== -1;
            }
            return true;
        },
        nodeClick(data, node) {
            if (node.level != 1 && this.isMoveBriefcase && !this.isAddFolder && !this.isMoveFast) {
                this.dataBefore = data
                this.setCurrentKey(null)
                this.setCurrentKey(data.id)
                this.node = node
                this.allowfilter = false
                this.filterText = data.name
                this.$store.commit("SET_NODE_BRIEFCASE_EXTRA_CURRENT", data)
                if (this.$listeners.onSelectFolder) {
                    this.$emit("onSelectFolder", data)
                }
                setTimeout(() => {
                    this.allowfilter = true
                }, 200)
            } else {
                this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", data)
                this.$store.commit("SET_NODE_BRIEFCASE_EXTRA_CURRENT", data)
                this.$refs.treeBriefcaseExtra.setCurrentKey(this.dataBefore.id)
                if (this.isMoveFast) {
                    setTimeout(() => {
                        this.$root.$emit("handleNavbarAction", BRIEFCASE_ACTION.MOVE);
                        if (data.id !== FOLDER_DEFAULT.ROOT) this.$root.$emit("closePopoverNavbar");
                    }, 200)
                }
            }
        },
        setFocusNodeActive() {
            this.$nextTick(() => {
                setTimeout(() => {
                    let node = document.querySelector('#treeBriefcaseExtra .is-current');
                    if (node) {
                        setTimeout(() => {
                            // node.scrollIntoView();
                            let top = $(node).position().top;
                            $("#treeBriefcaseExtra").scrollTop(top - 120);
                        }, 500);
                    }
                }, 0);
            });
        },
        setFocusInPut() {
            this.$refs.refInputSearch.focus();
        },
        isActive(node) {
            return node.id === this.nodeBriefcaseExtraCurrent?.id;
        },
        onCurrentKeyRoot() {
            this.setCurrentKey(FOLDER_DEFAULT.ROOT)
            const _dataCurrent = this.dataBriefcase[0]
            this.$store.commit("SET_NODE_BRIEFCASE_EXTRA_CURRENT", _dataCurrent);
            this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", _dataCurrent)
        },
    },
};
</script>
