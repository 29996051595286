<template>
    <div class="zm-avatar avt">
        <template v-if="addresses">
            <span v-for="(item, idx) in addresses" :key="idx" class="item">
                <template v-if="genUsernameFromEmail(item)">
                    <div class="user-avt-character" :style="{
                        backgroundImage: 'url(' + urlVopsAvatar(item) + ')',
                        backgroundSize: 'contain'
                    }">
                    </div>
                </template>
                <template v-else>
                    <div class="user-avt-character" :style="{
                        '--avatarBgColor': item.backgroundColor,
                        '--avatarColor': item.color,
                    }">
                        <span class="avatar-short">{{ item.firstCharacter }}</span>
                    </div>
                </template>
            </span>
        </template>
    </div>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import { COLOR_AVATAR } from "@/utils/constants";

export default {
    name: "BaseAvatar",
    mixins: [CommonUtils],
    props: {
        listEmailAddress: {
            type: [Array]
        },
        maxWidth: {
            type: Number,
            default: 32
        },
        maxHeight: {
            type: Number,
            default: 32
        },
        imageClass: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            CONFIG_AVATAR: "CONFIG_AVATAR",
            addresses: []
        };
    },
    computed: {},
    created() {
        this.configAvatar();
    },
    watch: {
        listEmailAddress: {
            deep: true,
            handler: function () {
                this.configAvatar();
            }
        }
    },
    methods: {
        configAvatar() {
            this.addresses = [];
            const numberColor = COLOR_AVATAR.length;
            let { configColor } = this.getLocalStorageObject(this.CONFIG_AVATAR) || {};
            configColor = configColor ? configColor : [];
            if (this.listEmailAddress) {
                this.listEmailAddress.forEach(item => {
                    item.a = item.a ? item.a : this.getDisplayName(item);
                    if (item.a) {
                        const firstCharacter = item.a.substring(0, 1).toUpperCase();
                        const _contact = this.contactsPlain.find(e => e.email === item.a);
                        let url = "";
                        if (_contact && _contact.image) {
                            url = `${this.$configZimbra.ENDPOINT.homeService}/~/?auth=co&id=${_contact.id}&part=${_contact.part}&max_width=${this.maxWidth}&max_height=${this.maxHeight}`;
                        }
                        const idx = configColor.findIndex(e => e.email === item.a);
                        if (idx > -1) {
                            this.addresses.push({
                                ...item,
                                ...configColor[idx],
                                avatar: url
                            });
                            configColor[idx].avatar = url;
                        } else {
                            const randomIdx = Math.floor(Math.random() * (numberColor));
                            const randomColor = COLOR_AVATAR[randomIdx];
                            this.addresses.push({
                                ...item,
                                ...randomColor,
                                firstCharacter: firstCharacter,
                                avatar: url
                            });
                            configColor.push({
                                ...randomColor,
                                firstCharacter: firstCharacter,
                                email: item.a,
                                avatar: url
                            });
                        }
                    }
                });
                const numberAvatar = this.addresses.length;
                if (numberAvatar > 3) {
                    this.addresses = this.addresses.slice(0, 3);
                    this.addresses.push({
                        avatar: "",
                        firstCharacter: numberAvatar - 3,
                        color: "#3F4254",
                        backgroundColor: "#f1f1f1"
                    });
                }
                this.addLocalStorageObject(this.CONFIG_AVATAR, { configColor: configColor });
            }
        },
        onerrorImage(item) {
            item.avatar = "";
        },

        urlVopsAvatar(item) {
            const BASE_AVT = 'https://vops.viettel.vn/avatar/';
            return `${BASE_AVT}${this.genUsernameFromEmail(item)}`;

        },

        genUsernameFromEmail(item) {
            let email = item?.email || item?.a;
            if(!email) return null;

            email = email?.toLowerCase();
            let [preAt, afterAt] = email?.split('@');
            let [dot1, dot2, dot3] = afterAt?.split('.');

            preAt = preAt?.toLowerCase() || preAt;
            if (afterAt === 'viettel.com.vn') { //Neu la email viettel
                return preAt;
            } else { //Neu la email viettelpost,...
                if (dot2 === 'com' && dot3 === 'vn') {
                    return `${preAt}_${dot1}`;
                } else {
                    return null;
                }
            }
        }
    }
};
</script>
