import BaseService from "@/shared/services/BaseService";
import { ZmSetting } from "@/utils/constants";
import CONFIG from '@/config/config';
import _ from "lodash";
class AccountService extends BaseService {
    url = CONFIG.ENDPOINT['compileService'];
    urlService = CONFIG.ENDPOINT['zimbraService'];
    constructor() {
        super("zimbraService");
    }

    encodedStr(unsafe) {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }
    escape(value) {
        return _.escape(value.toString());
    }
    handleJsonToString(obj) {
        return JSON.stringify(obj);
    }
    async getInfoRequest() {
        const url = `${this.baseURL}/getInfoRequest`;
        const request = {
            sections: "attrs, idents, dsrcs"
        };
        return await this.postRequest(url, request);
    }
    async getAllIdentities(data = "") {
        const url = `${this.baseURL}/getIdentitiesRequest`;
        return await this.postRequest(url, {
            sections: data
        });
    }
    async getRightRequest() {
        const url = `${this.baseURL}/getRightsRequest`;
        return await this.postRequest(url, {
            ace: "<urn1:ace right='sendAs'/><urn1:ace right='sendOnBehalfOf'/>"
        });
    }
    async getFolderRequest(form) {
        const url = `${this.baseURL}/getFolderRequest`;
        return await this.postRequest(url, form);
    }
    async autoCompleteRequest(data = "") {
        const url = `${this.baseURL}/autoCompleteRequest`;
        return await this.postRequest(url, {
            name: data
        });
    }
    async sendMsgRequest(data) {
        const url = `${this.baseURL}/sendMsgRequest`;
        return await this.postRequest(url, data);
    }
    async grantRightsRequest(data) {
        // const request = {
        //     listRequest: [
        //         {
        //             path: "grantRightsRequest",
        //             content: this.handleJsonToString({
        //                 xml: data.right
        //                     .map(
        //                         item =>
        //                             `<urn1:ace gt='usr' d='${data.d[0]}' right='${item}'/>`
        //                     )
        //                     .join("")
        //             })
        //         }
        //     ]
        // };
        // return await this.postRequest(this.url, request);
        const request = {
            xml: data.right
                .map(
                    item =>
                        `<urn1:ace gt='usr' d='${data.d[0]}' right='${item}'/>`
                )
                .join("")
        };
        return this.postRequest(this.urlService + "/grantRightsRequest", request);
    }
    async revokeRightsRequest(data) {
        // const listRequest = [
        //     {
        //         path: "revokeRightsRequest",
        //         content: this.handleJsonToString({
        //             xml: data.right
        //                 .map(
        //                     item =>
        //                         `<ace gt='${data.gt}' d='${data.d}' right="${item}"/>`
        //                 )
        //                 .join("")
        //         })
        //     }
        // ];
        // return await this.postRequest(this.url, { listRequest });
        const request = {
            xml: data.right
                .map(
                    item =>
                        `<ace gt='${data.gt}' d='${data.d}' right="${item}"/>`
                )
                .join("")
        };
        return this.postRequest(this.urlService + "/revokeRightsRequest", request);
    }
    async grantOrRemokeRightsRequest(data) {
        const listRequest = [];
        let respGrant, respRemoke;
        if (data.grant.length) {
            // listRequest.push({
            //     path: "grantRightsRequest",
            //     content: this.handleJsonToString({
            //         xml: data.grant
            //             .map(item => `<urn1:ace gt='${data.gt}' d='${data.d}' right='${item}'/>`).join("")
            //     })
            // })
            const request = {
                xml: data.grant
                    .map(item => `<urn1:ace gt='${data.gt}' d='${data.d}' right='${item}'/>`).join("")
            };
            respGrant = await this.postRequest(this.urlService + "/grantRightsRequest", request);
            if (respGrant) {
                listRequest.push(respGrant);
            }

        }
        if (data.remoke.length) {
            // listRequest.push({
            //     path: "revokeRightsRequest",
            //     content: this.handleJsonToString({
            //         xml: data.remoke
            //             .map(item => `<ace gt='${data.gt}' d='${data.d}' right="${item}"/>`).join("")
            //     })
            // })
            const request = {
                xml: data.remoke
                    .map(item => `<ace gt='${data.gt}' d='${data.d}' right="${item}"/>`).join("")
            };
            respRemoke = await this.postRequest(this.urlService + "/revokeRightsRequest", request);
            if (respRemoke) {
                listRequest.push(respRemoke);
            }

        }
        return listRequest;
        // return await this.postRequest(this.url, { listRequest });
    }
    async saveSetting(data) {
        let respIdentities, respListPrefs;
        const listRequest = [];
        if (data.listIdentities.length > 0) {
            // const xmlStr = `<urn1:identity id='${data.id
            //     }'>${data.listIdentities.map(item =>
            //         `<urn1:a name='${this.escape(item.name)}'>${typeof item.content === "boolean"
            //             ? item.content.toString().toUpperCase()
            //             : this.escape(item.content)
            //         }</urn1:a>`
            //     )
            //         .join("")}</urn1:identity>`;
            // listRequest.push({
            //     path: "modifyIdentityRequest",
            //     content: this.handleJsonToString({
            //         identity: xmlStr
            //     })
            // });

            const request = {
                identity: `<urn1:identity id='${data.id}'>${data.listIdentities.map(item =>
                    `<urn1:a name='${this.escape(item.name)}'>${typeof item.content === "boolean"
                        ? item.content.toString().toUpperCase()
                        : this.escape(item.content)
                    }</urn1:a>`
                )
                    .join("")}</urn1:identity>`
            };
            respIdentities = await this.postRequest(this.urlService + "/modifyIdentityRequest", request);
            if (respIdentities) {
                listRequest.push(respIdentities);
            }
        }
        if (data.listPrefs.length > 0) {
            // const xmlStr = data.listPrefs
            //     .map(
            //         item =>
            //             `<urn1:pref name='${item.name}'>${this.escape(item.content)}</urn1:pref>`
            //     )
            //     .join("");
            // listRequest.push({
            //     path: "modifyPrefsRequest",
            //     content: this.handleJsonToString({
            //         xml: xmlStr
            //     })
            // });
            const request = {
                xml: data.listPrefs
                    .map(
                        item =>
                            `<urn1:pref name='${item.name}'>${this.escape(item.content)}</urn1:pref>`
                    )
                    .join("")
            };
            respListPrefs = await this.postRequest(this.urlService + "/modifyPrefsRequest", request);
            if (respListPrefs) {
                listRequest.push(respListPrefs);
            }
        }
        // return await this.postRequest(this.url, {
        //     listRequest
        // });
        return listRequest;

    }
    async createPersonalRequest(data) {
        let xmlStr = "";
        for (let key in data) {
            if (
                key !== "zimbraDisplayEmail" &&
                data[key] !== undefined &&
                data[key] !== null &&
                data[key] !== ""
            ) {
                if (key === "zimbraPrefWhenInFolderIds") {
                    data[key].toString().split(",").forEach(attr => {
                        xmlStr += `<a name="${key}">${attr}</a>`
                    })
                }
                xmlStr += `<a name="${key}">${typeof data[key] === "boolean"
                    ? data[key].toString().toUpperCase()
                    : _.escape(data[key])
                    }</a>`;
            }
        }

        const listRequest = {
                    identity: `<identity name="${_.escape(data.zimbraPrefIdentityName)}">${xmlStr}</identity>`
                };
        return await this.postRequest(this.urlService + "/createIdentityRequest", listRequest , true, false);

        // const listRequest = [
        //     {
        //         path: "createIdentityRequest",
        //         content: this.handleJsonToString({
        //             identity: `<identity name="${_.escape(data.zimbraPrefIdentityName)}">${xmlStr}</identity>`
        //         })
        //     }
        // ];
        // return await this.postRequest(this.url, {
        //     listRequest: listRequest
        // }, true, false);
    }
    async modifyPersonalRequest(data) {
        let xmlStr = "";
        for (let key in data) {
            if (
                data[key] !== undefined &&
                data[key] !== null &&
                data[key] !== "" &&
                key !== "zimbraDisplayEmail"
            ) {
                if (key === "zimbraPrefWhenInFolderIds") {
                    data[key].toString().split(",").forEach(attr => {
                        xmlStr += `<a name="${key}">${attr}</a>`
                    })
                    continue
                }
                xmlStr += `<a name="${this.escape(key)}">${typeof data[key] === "boolean"
                    ? data[key].toString().toUpperCase()
                    : this.escape(data[key])
                    }</a>`;
            }
        }
        const listRequest = {
                        identity: `<identity id="${_.escape(data.zimbraPrefIdentityId)}">${xmlStr}</identity>`
                    };
            return await this.postRequest(this.urlService + "/modifyIdentityRequest", listRequest, true, false);


        // const listRequest = [
        //     {
        //         path: "modifyIdentityRequest",
        //         content: this.handleJsonToString({
        //             identity: `<identity id="${_.escape(data.zimbraPrefIdentityId)}">${xmlStr}</identity>`
        //         })
        //     }
        // ];
        // return await this.postRequest(this.url, {
        //     listRequest: listRequest
        // }, true, false);
    }
    async deletePersonalRequest(data) {
        const listRequest = 
            {
                    id: data.id
            }
        ;
        return this.postRequest(this.urlService + '/deleteIdentityRequest', listRequest);
    }
    async testDataSourceRequest(formData) {
        const url = `${this.baseURL}/testDataSourceRequest`
        const _type = formData.accountType ? formData.accountType : ZmSetting.POP3
        const _body = ` host="${formData.host}" port="${formData.port}" username="${formData.username}" password="${formData.password}" connectionType="${formData.connectionType}" leaveOnServer="${formData.leaveOnServer}"`
        const xml = "<" + _type + _body + " />"
        return await this.postRequest(url, { xml: xml })
    }
    handleBuildDataSourceXml(formData) {
        const _type = formData.accountType ? formData.accountType : ZmSetting.POP3
        let _body = ""
        for (const key in formData) {
            if (key === "accountType") continue;
            if (formData[key] !== undefined && formData[key] !== null && formData[key] !== "") {
                _body += ` ${key}="${formData[key]}"`
            }
        }
        const xml = "<" + _type + _body + " />"
        return xml
    }
    async createDataSourceRequest(formData) {
        const url = `${this.baseURL}/createDataSourceRequest`
        return await this.postRequest(url, { xml: this.handleBuildDataSourceXml(formData) }, true, false)
    }
    async modifyDataSourceRequest(formData) {
        const url = `${this.baseURL}/modifyDataSourceRequest`
        return await this.postRequest(url, { xml: this.handleBuildDataSourceXml(formData) }, true, false)
    }
    async deleteDataSourceRequest(formData) {
        const url = `${this.baseURL}/deleteDataSourceRequest`
        return await this.postRequest(url, { xml: this.handleBuildDataSourceXml(formData) })
    }
    async importDataRequest(formData) {
        const url = `${this.baseURL}/importDataRequest`
        return await this.postRequest(url, formData)
    }
}

export default new AccountService();
