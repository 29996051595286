<template>
    <FullCalendar :options="calendarOptions" ref="miniCalendar" id="small-calendar-side-bar" class="calendar-side-bar"/>
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import allLocales from "@fullcalendar/core/locales-all";
import moment from "moment";
import { mapGetters } from "vuex";
import CommonUtils from "@/utils/common-utils.js";
import CalendarUltils from "@/utils/calendar-utils.js";
import { CALENDAR_VIEW_MODE} from "@/utils/constants";
export default {
    name: "CalendarMini",
    components: {
        FullCalendar
    },
    props: {
        freeBusyCal: { // Lịch rảnh/bận
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    mixins: [CommonUtils, CalendarUltils],
    data() {
        return {
            lstEvent: [],
            selectedDate: moment(new Date).startOf('day').toDate(),
            currentDateInfo: null
        };
    },
    computed: {
        ...mapGetters(["timeZoneCurrent", "localeCurrent", "allTreeFolder"]),
        calendarOptions() {
            let _lang = this.localeCurrent?.content?.toString() || "vi";
            const indexOf = _lang.indexOf(",");
            _lang = _lang.substring(indexOf + 1).replaceAll("_", "-");
            return {
                plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
                initialView: "dayGridMonthCus",
                locales: allLocales,
                locale: _lang,
                timeZone: this.timeZoneCurrent.content,
                dayCellDidMount: (info) => {
                    // Loại bỏ class event
                    const eventDiv = info.el.querySelector('.fc-daygrid-day-events');
                    eventDiv.className = "";
                },
                headerToolbar: {
                    start: "title prevButton,nextButton",
                    center: "",
                    end: "",
                },
                customButtons: {
                    nextButton: {
                        text: '>',
                        click: this.handleNextWeek,
                        icon: 'fa-chevron-right',
                    },
                    prevButton: {
                        text: '<',
                        icon: 'fa-chevron-left',
                        click: this.handleBackWeek
                    },
                },
                views: {
                    dayGridMonthCus: {
                        type: 'dayGridMonth',
                    }
            },
                dateClick: this.onDateClick,
                unselectAuto: false,
            };
        },
    },
    methods: {
        handleNextWeek() {
            this.$refs.miniCalendar.getApi().next();
        },

        handleBackWeek() {
            this.$refs.miniCalendar.getApi().prev();
        },

        /**
         * Hàm chọn click
         */
        async onDateClick(info) {
            this.activeDate(info)
            const date = new Date(info.date.toDateString());
            this.focusDateSelect(info);
            this.selectedDate = date;
        },

        /**
         * Hàm focus vào trường date trên mini calendar
         */
        focusDateSelect(info) {
            const date = new Date(info.date.toDateString());
            // Nếu là week thì chọn range week
            if(this.viewMode === CALENDAR_VIEW_MODE.WEEK || this.viewMode === CALENDAR_VIEW_MODE.WEEK_WORK) {
                const firstday = moment(date).startOf('week').toDate();
                const lastday = moment(date).endOf('week').add(1, 'days').toDate();
                this.$refs.miniCalendar.getApi().select(this.getDateForCalendar(firstday), this.getDateForCalendar(lastday));
            } else {
                // nếu không thì chọn ngày như bình thường
                this.$refs.miniCalendar.getApi().select(this.getDateForCalendar(date));
            }
        },

        /**
         * hàm thêm class active ngày khi chọn
         */
        activeDate(dayInfo){
            // Xóa class active của event cũ
            if(this.currentDateInfo){
                this.currentDateInfo.classList.remove("selected-date")
            }

            // Thiết lập current active hiện tại
            this.currentDateInfo = dayInfo.dayEl;
            dayInfo.dayEl.classList.add("selected-date")
        },
    },
};
</script>
<style>
.main-content .fc .fc-toolbar-title {
    font-size: 1em !important;
    margin: 0;
    text-transform: capitalize !important;
    font-weight: 500;
}
.fc-header-toolbar.fc-toolbar .fc-toolbar-chunk {
   display: flex;
}
.calendar-side-bar .fc-toolbar-chunk {
    align-items: center;
}
.calendar-side-bar .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0.8em;
}
</style>
