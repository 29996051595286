<template>
    <div class="popover-wrap" style="max-width: 306px; width: 300px">
         <ul>
            <li>
            <perfect-scrollbar>
                <tree-briefcase-extra
                    :highlight-default="true"
                    :show-info-folder="false"
                    :showInputSearch="false"
                    :isAddFolder="isAddFolder"
                    :isMoveBriefcase="isMoveBriefcase"
                    :isMoveFast="true"
                    :isShowRightMenu="false"
                    :isClickRoot="true"
                />
            </perfect-scrollbar>
            </li>
            <hr />
            <li index="1" class="menu-item" style="margin-left: 15px; height: 40px;">
                <a href="javascript:;" @click="onOpenAddBriefcase">
                    <i class="ic-folder-add" style="padding-right: 30px;"></i>
                    <span style="font-family: 'Roboto'; font-weight: 500;">{{ $t("zimbra.zhMsg.folderNew") }}</span>
                </a>
            </li>
            <li></li>
         </ul>
    </div>
</template>

<script>
import TreeBriefcaseExtra from "@/components/modules/briefcase/components/TreeBriefcaseExtra.vue";
export default {
    props: {
        isBriefCaseSelector: { // bỏ drop
            type: Boolean,
            default: true,
        },
        isAddFolder: { // bỏ call api khi click vào tree
            type: Boolean,
            default: true,
        },
        isMoveBriefcase: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        TreeBriefcaseExtra
    },
    methods: {
        /**
         * Hàm Thêm thư mục cặp tài liệu mới
         */
        onOpenAddBriefcase() {
            this.$root.$emit('handleAddFolderContact', true, true)
        },
    }
}
</script>

<style>

</style>