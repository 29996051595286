import {
    FOLDER_DEFAULT,
    FOLDER_ACTION,
    FOLDER_RENAME,
    FOLDER_VIEW
} from "@/utils/constants.js";
import i18n from "@/plugins/i18n";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import ContactUtils from "@/utils/contact-utils";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import ZmMimeTable from "@/utils/zimbra-lib/ZmMimeTable";
import moment from "moment";
export default {
    name: "CalendarUtils",
    mixins: [CommonUtils, ContactUtils],
    data() {
        return {
            FOLDER_ACTION: FOLDER_ACTION,
            FOLDER_DEFAULT: FOLDER_DEFAULT
        }
    },
    computed: {
        ...mapGetters([
            "preference",
            "nodeBriefcaseCurrent",
            "briefcaseTreeFolders",
        ])
    },
    methods: {
        ...mapActions(["saveGeneralSetting"]),
        async handleChangeDisplayView(view) {
            setTimeout(() => {
                this.visibleDisplayView = false
            }, 300)
            const formData = {
                name: "zimbraPrefBriefcaseReadingPaneLocation",
                content: view
            }
            await this.saveGeneralSetting([formData]).then(() => {
                this.displayView = view
            }).catch(() => { })
        },
        async getBriefcaseFolder() {
            const formData = {
                folder: `<view>briefcase</view><depth>-1</depth><tr>1</tr><visible>1</visible><needGranteeName>1</needGranteeName>`,
            };
            const searchRequest = await ZimbraMailService.getFolderRequest(formData);
            const { folder } = this.getResponseBody(searchRequest)["GetFolderResponse"];
            let _folder = this.convertBriefcaseFolder(folder);
            _folder = this.reorderFolder(_folder[0].folder)
            return _folder
        },

        async getFolderSearchApi() {
            const formData = {
                folder: `<view>1</view><depth>-1</depth><tr>1</tr><visible>1</visible><needGranteeName>1</needGranteeName>`,
            };
            const searchRequest = await ZimbraMailService.getFolderRequest(formData);
            const searchActionResponse = this.getResponseBody(searchRequest)["GetFolderResponse"];
            const newFolders = this.reorderTree(
                [
                    {
                        id: 1,
                        name: i18n.t("zimbra.zhMsg.optionsSearches"),
                        search: this.wrapFolderSearch(
                            searchActionResponse.folder.search
                        ),
                        isRootSearchFolder: true,
                    },
                ],
                "search",
                ["name"],
                ["asc"]
            );
            this.$store.commit("SET_FOLDER_SEARCH", newFolders);
            return newFolders;
        },

        wrapFolderSearch(search) {
            if (!search) {
                return [];
            }
            if (Array.isArray(search)) {
                search.forEach((item) => {
                    item.search = this.wrapFolderSearch(item.search);
                });
            } else if (search) {
                search = this.wrapFolderSearch([search]);
            }
            return search;
        },

        /**
         * convert cấu trúc tree folder
         * @param {*} folder
         * @returns
         */
        convertBriefcaseFolder(folder, parent) {
            if (!folder) {
                return [];
            }
            folder = Array.isArray(folder) ? folder : [folder]
            for (let index = 0; index < folder.length; index++) {
                const item = folder[index]
                let linkAfter = []
                if (item.link) {
                    const link = Array.isArray(item.link) ? item.link : [item.link]
                    this.tickLinkFolder(item.link)
                    this.reloadOwnerFolder(item.link)
                    linkAfter = link.map(e => ({
                        ...e,
                        isLink: true,
                        isFolder: true,
                    }))
                }
                if (item.folder) {
                    item.folder = Array.isArray(item.folder) ? item.folder : [item.folder]
                } else {
                    item.folder = []
                }
                // convert tên folder
                if (item && !item.absFolderPath) {
                    if (item.l != FOLDER_DEFAULT.ROOT && !_.isNil(parent)) {
                        item.absFolderPath =
                            parent.absFolderPath + "/" + item.name
                        item.view = parent.view
                    }
                }
                item.name = FOLDER_RENAME.includes(item.name)
                    ? i18n.t(`zimbra.systemFolder.folder${item.name}`)
                    : item.name;
                item.isFolder = true
                item.folder = item.folder.concat(linkAfter)
                item.folder = this.convertBriefcaseFolder(item.folder, item)
            }
            return folder
        },

        reloadOwnerFolder(data, parent) {
            if (data.length == undefined) {
                const arrLink = []
                arrLink.push(data)
                data = arrLink
            }
            for (let item in data) {
                if (data[item].l != FOLDER_DEFAULT.ROOT && parent) {
                    data[item].owner = parent.owner
                    data[item].rest = parent.rest + "/" + data[item].name
                }
                if (data[item].folder) {
                    if (data[item].folder.length) {
                        this.reloadOwnerFolder(data[item].folder, data[item])
                    } else {
                        data[item].owner = parent.owner
                        data[item].rest = parent.rest + "/" + data[item].name
                        this.reloadOwnerFolder(data[item].folder, data[item])
                    }
                }
            }
        },

        renderTime(timestamp, locale = "vi", format = "DD/MM/yyyy") {
            if (!timestamp) return "";
            const date = new Date(parseInt(timestamp))
            const resAnotherToday = moment(date)
                .locale(locale)
                .format(format)
            return resAnotherToday
        },

        /**
         * Hàm xóa hẳn thư mục cặp tài liệu
         */
        async deleteBriefCase(idSource, nameSource) {
            let isReload = true;
            await this.$confirm(
                this.$t("zimbra.zmMsg.confirmDeleteFolder", [nameSource]),
                this.$t("zimbra.zhMsg.confirm"), {
                confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                type: "error",
            }
            ).then(async () => {
                await ZimbraMailService.folderActionRequest({
                    folder: `<urn1:action id="${idSource.toString()}" op="${FOLDER_ACTION.DELETE}"></urn1:action>`
                })
            }).catch(() => isReload = false);
            return isReload
        },
        handleDownload(path, filename) {
            const a = document.createElement('a')
            a.setAttribute('href', path)
            a.setAttribute('download', filename)
            document.body.appendChild(a)
            // a.setAttribute('target', '_blank')
            a.click()
            document.body.removeChild(a);
        },
        async handleAssignOrRemoveTags(item, isAssginTag) {
            // const _listSelected = this.listSelectedBriefcase.filter(e => {
            //     const _index = e.listTag.findIndex(tag => tag.name.toString().toLowerCase() == item.name.toString().toLowerCase())
            //     if (!e.idParent && _index === -1) return e
            // })
            const idBriefcases = this.listSelectedBriefcase.map(x => x.id).join(',');
            const op = isAssginTag ? "tag" : "!tag";

            const msg = isAssginTag
                ? i18n.t("zimbra.zmMsg.actionTag", { 0: this.listSelectedBriefcase.length, 1: this.$t("zimbra.zhMsg.files").toLowerCase(), 2: item.name })
                : i18n.t("zimbra.zmMsg.actionUntag", { 0: this.listSelectedBriefcase.length, 1: this.$t("zimbra.zhMsg.files").toLowerCase(), 2: item.name })
            let itemAction = null;
            if (idBriefcases?.length > 0) {
                const formData = {
                    id: `${idBriefcases}`,
                    op: `${op}`,
                    tn: _.escape(item.name.toString()),
                };
                itemAction = await ZimbraMailService.itemActionRequest(formData);
            }
            const responseItemAction = itemAction && this.getResponseBody(itemAction)["ItemActionResponse"];
            if (responseItemAction?.action) {
                this.$notify({
                    title: i18n.t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: `${msg}`,
                    type: "success",
                });
                // cập nhật tag của thư trên danh sách
                this.$root.$emit("updateTagsToList", {
                    listSelectedBriefcase: this.listSelectedBriefcase,
                    tag: item,
                    isAssginTag: isAssginTag
                });
            }
        },
        async handledRemoveAllTags() {
            const idBriefcases = this.listSelectedBriefcase.filter(e => !e.idParent && !e.isFolder).map(x => x.id);
            let itemAction = null;
            if (idBriefcases?.length > 0) {
                const formData = {
                    id: `${idBriefcases.join(',')}`,
                    op: "update",
                    t: ""
                };
                itemAction = await ZimbraMailService.itemActionRequest(formData);
            }
            if (itemAction) {
                this.$notify({
                    title: i18n.t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: i18n.t("zimbra.zmMsg.actionRemoveTags", [idBriefcases.length, this.$t("zimbra.zhMsg.files")]),
                    type: "success",
                });
            }
            // cập nhật tag của thư trên danh sách
            this.$root.$emit("updateTagsToList", {
                listSelectedBriefcase: this.listSelectedBriefcase,
                tag: null,
                isAssginTag: false
            });
        },
        async registerOrCheckoutFile(isRegister, isReload = true) {
            const op = isRegister ? "lock" : "unlock"
            if (this.listSelectedBriefcase.length) {
                const listRequest = []
                const listResponse = []
                this.listSelectedBriefcase.forEach(item => {
                    const formData = {
                        id: item.id.toString(),
                        op: op
                    }
                    listRequest.push(ZimbraMailService.itemActionRequest(formData))
                    if (isRegister && this.listSelectedBriefcase.length === 1) {
                        const { username } = this.getLocalStorageObject("zimbra_token")
                        const fileName = item.name
                        const version = item.ver
                        let restUrl = `${this.$configZimbra.ENDPOINT.importExportService}/${username}/${this.nodeBriefcaseCurrent.absFolderPath.replace("/", "")}/${encodeURIComponent(fileName)}`
                        let urlParams = []
                        urlParams.push("disp=a")
                        if (version) {
                            urlParams.push("ver=" + version)
                        }
                        urlParams = urlParams.join('&');
                        restUrl = restUrl + (urlParams.length > 0 ? "?" : "") + urlParams;
                        const ct = this.listSelectedBriefcase[0].ct;
                        if (!ct.includes(ZmMimeTable.APP_ZIMBRA_DOC)) {
                            this.handleDownload(restUrl, fileName)
                        }
                    }
                })
                await Promise.all(listRequest).then(resp => {
                    resp.forEach(item => {
                        const itemActionResponse = this.getResponseBody(item)["ItemActionResponse"]
                        if (itemActionResponse) listResponse.push(itemActionResponse.action)
                    })
                })
                if (listResponse.length && isReload) {
                    this.$root.$emit("updateItemToListBriefcase", listResponse)
                }
            }
        },

        checkExistsName(name, folderParent) {
            if (Array.isArray(folderParent.folder)) {
                if (
                    folderParent.folder
                        .map((x) => x.name.toString().toLowerCase())
                        .indexOf(name.toString().toLowerCase()) > -1
                ) {
                    let message = "";
                    if (this.typeFolder === FOLDER_VIEW.CONTACT) {
                        message = this.$t("zimbra.zmMsg.errorAlreadyExists", {
                            0: name,
                            1: this.$t("zimbra.zhMsg.addressBook")
                        });
                    } else if (this.typeFolder === FOLDER_VIEW.BRIEFCASE) {
                        message = this.$t("zimbra.zmMsg.errorAlreadyExists", { 0: name, 1: this.$t("zimbra.zhMsg.ALT_APP_BRIEFCASE") });
                    } else {
                        message = this.$t("zimbra.zmMsg.errorAlreadyExists", { 0: name, 1: this.$t("zimbra.zhMsg.ALT_FOLDER") });
                    }
                    this.$alert(
                        message,
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return true;
                }
            }
        },

        findFolderById(listFolder, id) {
            for (let index in listFolder) {
                if (listFolder[index].id == id) {
                    return listFolder[index];
                } else if (listFolder[index].folder) {
                    const result = this.findFolderById(listFolder[index].folder, id);
                    if (result != null) {
                        return result;
                    }
                }
            }
            return null;
        },

        findFolderByAbsFolderPath(listFolder, value) {
            for (let index in listFolder) {
                if (listFolder[index].absFolderPath && listFolder[index].absFolderPath.replace("/", "").toString().toLowerCase() == value.toString().toLowerCase()) {
                    return listFolder[index]
                } else if (listFolder[index].folder) {
                    const result = this.findFolderByAbsFolderPath(listFolder[index].folder, value)
                    if (result != null) {
                        return result
                    }
                }
            }
            return null
        },

        getListFolderShared(folders) {
            folders = Array.isArray(folders) ? folders : [folders];
            let res = [];
            folders.forEach(x => {
                if (x.folder) {
                    res = _.concat(res, this.getListFolderShared(x.folder));
                }
                if (x.isLink) {
                    res.push(x);
                }
            })
            return res;
        },

        getQueryBriefcaseExtra() {
            if (!this.briefcaseTreeFolders || this.briefcaseTreeFolders.length == 0) {
                return "";
            }
            let queryExtra = "";
            const listFolderSharedBriefcase = this.getListFolderShared(this.briefcaseTreeFolders);
            if (this.preference.zimbraPrefIncludeSharedItemsInSearch && listFolderSharedBriefcase.length > 0) {
                const listFolderId = listFolderSharedBriefcase.map(e => `inid:"${e.id}"`);
                listFolderId.push("is:local");
                queryExtra = listFolderId.join(" OR ");
            }
            return queryExtra;
        },

        _alwaysOpenInNewWindow(ct) {
            return (ct == ZmMimeTable.APP_ADOBE_PDF) || (ct == ZmMimeTable.TEXT_XML) || (ct == ZmMimeTable.APP_XML) || (ct.includes(ZmMimeTable.VIDEO));
        },

        isDownloadable(ct) {
            return (!this.isWebDoc(ct) && !ZmMimeTable.isRenderable(ct) && !ZmMimeTable.isRenderableImage(ct) && !ZmMimeTable.isTextType(ct)) && (!this.isJsonView(ct))
        },

        isWebDoc(ct) {
            return ct === ZmMimeTable.APP_ZIMBRA_DOC
        },

        isJsonView(ct) {
            return ct === ZmMimeTable.APP_JSON
        },
    }
}