<template>
    <div class="filter-action__wrap">
        <el-card shadow="never">
            <div
                :style="{
                    position: 'relative',
                }"
            ></div>
            <div
                v-for="(item, index) in listFilterAction"
                :key="index"
                class="card-filter-select"
            >
                <div class="filter-action__item">
                    <base-select
                        id="main-select1"
                        displayField="name"
                        class="select-action__filter"
                        popperClass="dropdown-action-filer"
                        valueField="types"
                        :value="item.types"
                        :options="data.mainSelect"
                        @change="
                            handleChangeMainFilterAction($event, item.index)
                        "
                    />
                    <!-- START-FORM-FILE-INTO -->
                    <div
                        class="formCheck"
                        v-if="item.types === FILTER_ACTION.ACTION_FILE_INTO"
                    >
                        <base-button-default
                            class="btn-email default"
                            size="small"
                            @click="handleShowDialogChooseFolder(item.index)"
                        >
                            {{
                                item.folderPath
                                    ? item.folderPath
                                    : $t("zimbra.zmMsg.browse")
                            }}
                        </base-button-default>
                    </div>
                    <!-- START-FORM-TAG -->
                    <div
                        class="formCheck"
                        v-else-if="item.types === FILTER_ACTION.ACTION_TAG"
                    >
                        <base-button-default
                            class="btn-email default"
                            size="small"
                            @click="handleShowDialogChooseTag(item.index)"
                        >
                            {{
                                item.tagName
                                    ? item.tagName
                                    : $t("zimbra.zmMsg.browse")
                            }}
                        </base-button-default>
                    </div>
                    <!-- START-FORM-ACTION-FLAG -->
                    <div
                        class="formCheck"
                        v-else-if="item.types === FILTER_ACTION.ACTION_FLAG"
                    >
                        <base-select
                            id="mask-as-select"
                            displayField="name"
                            valueField="types"
                            :value="item.flagName"
                            :options="data.maskAs"
                            @change="handleChangeMaskAs($event, item.index)"
                        />
                    </div>
                    <!-- START-FORM-ACTION-REDIRECT -->
                    <div
                        class="formCheck"
                        v-else-if="item.types === FILTER_ACTION.ACTION_REDIRECT"
                    >
                        <base-input
                            id="input-redirect-address"
                            type="text"
                            :value="item.a"
                            @input="
                                handleChangeRedirectAddress($event, item.index)
                            "
                        />
                    </div>
                </div>
                <!-- END-FORM-CHECK-3 -->
                <div class="icon-close-check">
                    <el-button
                        icon="el-icon-close"
                        :disabled="handleCheckingFilterActionButtonRemove"
                        @click="handleDeleleFilterActionItem(item.index)"
                    ></el-button>
                </div>
            </div>
            <div class="add-filter" @click="handleAddFilterAction">
                <i class="el-icon-plus"></i>
                <label>{{
                    this.$t("zimbra.zmMsg.addAction")
                }}</label>
            </div>
        </el-card>
        <base-checkbox
            :value="actionStop"
            :option="$t('zimbra.zhMsg.EFILT_ACTION_STOP')"
            @change="handleChangeActionStop"
        />
        <DialogChooseFolder @handleApcepterAction="handleApcepterAction" />
        <DialogChooseTag @handleAcpectTag="handleAcpectTag" />
    </div>
</template>

<script>
import BaseSelect from "@/components/modules/base/base-select";
import BaseInput from "@/components/modules/base/base-input";
import BaseCheckbox from "@/components/modules/base/base-checkbox";
import BaseButtonDefault from "@/components/modules/base/base-button/btn-default";
import CommonUtils from "@/utils/common-utils";
import DialogChooseFolder from "@/components/modules/layouts/components/DialogChooseFolder.vue";
import DialogChooseTag from "@/components/modules/layouts/components/DialogChooseTag.vue";
import { FILTER_ACTION, FILTER_ACTION_TYPE } from "@/utils/constants";
import { mapGetters } from "vuex";
import _ from "lodash";
function compare(a, b) {
    if (a.index < b.index) {
        return -1;
    }
    if (a.index > b.index) {
        return 1;
    }
    return 0;
}
export default {
    props: {
        filterActions: {
            type: [Object, Array],
        },
    },
    components: {
        BaseSelect,
        BaseCheckbox,
        BaseButtonDefault,
        BaseInput,
        DialogChooseFolder,
        DialogChooseTag,
    },
    mixins: [CommonUtils],
    data() {
        return {
            data: {
                mainSelect: [
                    {
                        id: 1,
                        name: this.$t("zimbra.zhMsg.EFILT_ACTION_KEEP"),
                        types: FILTER_ACTION.ACTION_KEEP,
                    },
                    {
                        id: 2,
                        name: this.$t("zimbra.zhMsg.actionTaskCancelTT"),
                        types: FILTER_ACTION.ACTION_DISCARD,
                    },
                    {
                        id: 3,
                        name: this.$t("zimbra.zmMsg.moveIntoFolder"),
                        types: FILTER_ACTION.ACTION_FILE_INTO,
                    },
                    {
                        id: 4,
                        name: this.$t("zimbra.zhMsg.EFILT_ACTION_TAG"),
                        types: FILTER_ACTION.ACTION_TAG,
                    },
                    {
                        id: 5,
                        name: this.$t("zimbra.zhMsg.markAs"),
                        types: FILTER_ACTION.ACTION_FLAG,
                    },
                    {
                        id: 6,
                        name: this.$t("zimbra.zmMsg.redirectToAddress"),
                        types: FILTER_ACTION.ACTION_REDIRECT,
                    },
                ],
                maskAs: [
                    {
                        id: 1,
                        name: this.$t("zimbra.zmMsg.QUERY_IS_read"),
                        types: FILTER_ACTION.ACTION_FLAG_READ,
                    },
                    {
                        id: 2,
                        name: this.$t("zimbraNokey.zimbra_zmMsg_QUERY_IS_flagged"),
                        types: FILTER_ACTION.ACTION_FLAG_FLAGGED,
                    },
                ],
            },
            FILTER_ACTION: FILTER_ACTION,
            FILTER_ACTION_TYPE: FILTER_ACTION_TYPE,
            treeNodeMove: "",
            dialogMoveFolder: false,
            dialogTagFolder: false,
            defaultProps: {
                children: "folder",
                label: "name",
            },
            ignoreProps: ["actionStop"],
            listFilterAction: [],
            actionStop: false,
            indexCurrent: null,
        };
    },
    created() {
        this.data.mainSelect[0].name =
            this.tabActive === FILTER_ACTION_TYPE.INCOMING_PREFIX
                ? this.$t("zimbra.zhMsg.EFILT_ACTION_KEEP")
                : this.$t("zimbra.zmMsg.keepInSent");
        this.listFilterAction = this.buildConditions(this.filterActions);
    },
    mounted() {
        //    this._addShadow()
    },
    watch: {
        filterActions(newVal) {
            if (newVal.length) {
                const _list = _.cloneDeep(newVal);
                this.listFilterAction = _list.filter(
                    (item) => item.types !== FILTER_ACTION.ACTION_STOP
                );
            } else {
                this.listFilterAction = this.buildConditions(newVal);
            }
        },
        tabActive(val){
            this.data.mainSelect[0].name =
            val === FILTER_ACTION_TYPE.INCOMING_PREFIX
                ? this.$t("zimbra.zhMsg.EFILT_ACTION_KEEP")
                : this.$t("zimbra.zmMsg.keepInSent");
        }
    },
    computed: {
        ...mapGetters(["tabActive"]),
        handleCheckingFilterActionButtonRemove() {
            if (this.listFilterAction.length <= 1) return true;
            return false;
        },
    },
    methods: {
        buildConditions(filterRules) {
            // debugger; // eslint-disable-line no-debugger
            let list = [];
            for (let k in filterRules) {
                if (this.ignoreProps.indexOf(k) > -1) {
                    this.actionStop = true;
                    continue;
                } else {
                    this.actionStop = false;
                }
                const item = filterRules[k];
                if (Array.isArray(item)) {
                    for (let i in item) {
                        item[i].types = k;
                        list.push(item[i]);
                    }
                } else {
                    item.types = k;
                    list.push(item);
                }
            }
            list.sort(compare);
            return list;
        },
        handleShowDialogChooseFolder(index) {
            this.indexCurrent = index;
            this.$root.$emit("handleShowChooseFolder", true);
        },
        handleShowDialogChooseTag(index) {
            this.indexCurrent = index;
            this.$root.$emit("handleShowChooseTag", true);
        },
        handleAddFilterAction() {
            const listIndex = this.listFilterAction.map(item => item.index)
            const indexItem = Math.max(...listIndex) + 1
            const newActionItem = {
                types: FILTER_ACTION.ACTION_KEEP,
                index: indexItem,
            };
            this.listFilterAction.push(newActionItem);
            this.$root.$emit(
                "handleChangeFilterActionItem",
                this.listFilterAction
            );
        },
        handleChangeMainFilterAction(types, index) {
            this.listFilterAction[index].types = types;
            this.beforeEmitData(types, index);
            this.$root.$emit(
                "handleChangeFilterActionItem",
                this.listFilterAction
            );
            // this.$nextTick(() => {
            //     const { input, span } = this._getElements();
            //     span.textContent = input.value;
            //     input.style.width = `${span.getBoundingClientRect().width*1 + 30}px`;
            // });
        },
        handleChangeMaskAs(val, index) {
            this.listFilterAction[index].flagName = val;
            this.$root.$emit(
                "handleChangeFilterActionItem",
                this.listFilterAction
            );
        },
        handleChangeRedirectAddress(val, index) {
            this.listFilterAction[index].a = val;
            this.$root.$emit(
                "handleChangeFilterActionItem",
                this.listFilterAction
            );
        },
        handleChangeActionStop(val) {
            if (!this.actionStop && val) {
                const actionStop = {
                    types: FILTER_ACTION.ACTION_STOP,
                    index: this.listFilterAction.length + 1,
                };
                this.listFilterAction.push(actionStop);
                this.$root.$emit(
                    "handleChangeFilterActionItem",
                    this.listFilterAction
                );
            } else {
                const newList = this.listFilterAction.filter(
                    (item) => item.types !== FILTER_ACTION.ACTION_STOP
                );
                this.$root.$emit("handleChangeFilterActionItem", newList);
            }
            this.actionStop = val;
        },
        beforeEmitData(types, index) {
            if (types === FILTER_ACTION.ACTION_KEEP) {
                this.listFilterAction[index] = {
                    index: index,
                    types: types,
                };
            } else if (types === FILTER_ACTION.ACTION_DISCARD) {
                this.listFilterAction[index] = {
                    index: index,
                    types: types,
                };
            } else if (types === FILTER_ACTION.ACTION_FILE_INTO) {
                this.listFilterAction[index] = {
                    index: index,
                    types: types,
                    folderPath: "",
                };
            } else if (types === FILTER_ACTION.ACTION_TAG) {
                this.listFilterAction[index] = {
                    index: index,
                    types: types,
                    tagName: "",
                };
            } else if (types === FILTER_ACTION.ACTION_FLAG) {
                this.listFilterAction[index] = {
                    index: index,
                    types: types,
                    flagName: FILTER_ACTION.ACTION_FLAG_READ,
                };
            } else if (types === FILTER_ACTION.ACTION_REDIRECT) {
                this.listFilterAction[index] = {
                    index: index,
                    types: types,
                    a: "",
                };
            } else if (types === FILTER_ACTION.ACTION_STOP) {
                this.listFilterAction[index] = {
                    index: index,
                    types: types,
                };
            } else if (types === FILTER_ACTION.ACTION_STOP) {
                this.listFilterAction[index] = {
                    index: index,
                    types: types,
                };
            }
        },
        handleApcepterAction(data) {
            this.listFilterAction[this.indexCurrent].folderPath = data.name;
            this.$root.$emit(
                "handleChangeFilterActionItem",
                this.listFilterAction
            );
        },
        handleDeleleFilterActionItem(index) {
            const newFilterAction = this.listFilterAction.filter(
                (item) => item.index !== index
            );
            this.listFilterAction = newFilterAction;
            this.$root.$emit(
                "handleChangeFilterActionItem",
                this.listFilterAction
            );
        },
        handleAcpectTag(data) {
            this.listFilterAction[this.indexCurrent].tagName = data.name;
            this.$root.$emit(
                "handleChangeFilterActionItem",
                this.listFilterAction
            );
        },
    },
};
</script>

<style></style>
