<template>
    <el-dialog
        class="dialog-body-wrap zimbra-dialog"
        :title="titleDialog"
        :visible.sync="showDialog"
        width="30%"
        height="550px"
        :append-to-body="true"
        :close-on-click-modal="false"
        :destroy-on-close="true"
    >
        <el-form class="form-wrap">
            <div
                style="padding-bottom: 10px"
                >{{ $t("zimbra.zmMsg.chooserDescription") }}
            </div>
            <TagTreeSelector
                :isRootEmitEvt="false"
                @nodeClick="nodeClick"
            />
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button
                v-if="showButtonNew"
                class="btn-outline"
                @click="$root.$emit('handleTagActionAdd')"
                style="float: left"
                >{{ $t("zimbra.zhMsg.apptPtstNEW") }}</el-button
            >
            <el-button
                class="btn-email default"
                @click="showDialog = false"
                >{{ $t("zimbra.zhMsg.actionTaskCancelTT") }}</el-button
            >
            <el-button
                class="btn-email primary"
                type="primary"
                @click="handleSelect()"
                >{{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import TagTreeSelector from "@/components/modules/briefcase/components/TagTreeSelector.vue"
import { BRIEFCASE_ACTION } from "@/utils/constants"
import CommonUtils from "@/utils/common-utils";
import BriefcaseUtils from "@/utils/briefcase-utils";
import { mapGetters } from "vuex";

export default {
    mixins: [CommonUtils, BriefcaseUtils],
    mounted() {
        this.$root.$on("handleShowPopoverAssignTag", this.show)
    },
    computed: {
        ...mapGetters([
            "activeFolderExtra",
            "listSelectedMail",
            "listEmailSearch"
        ]),
    },
    components: {
        TagTreeSelector
    },
    data() {
        return {
            showDialog: false,
            titleDialog: "",
            folderSelect: {},
            showButtonNew: false,
        };
    },
    methods: {
        nodeClick(folder, isDubbleClick) {
            if (isDubbleClick) {
                this.handleSelect(folder);
            } else {
                this.folderSelect = folder;
            }
        },
        show() {
            this.titleDialog = this.$t("zimbra.zmMsg.pickATag")
            this.showButtonNew = true
            this.showDialog = true
        },
        handleCheckExitsTag() {
            const listSelected = this.$store.getters.listSelectedBriefcase
            const _temps = []
            listSelected.forEach(item => {
                const _item = item.listTag.find(tag => tag.name.toString().toLowerCase() == this.folderSelect.name.toString().toLowerCase())
                if (_item) _temps.push(item)
            })
            if (_temps.length) {
                return false
            }
            return true
        },
        handleSelect(data) {
            const folderSelect = data || this.folderSelect
            this.$root.$emit("handleNavbarAction", BRIEFCASE_ACTION.ASSIGN_TAG, { item: folderSelect, isAssginTag: true})
            // this.$root.$emit("handleSelectNode", folderSelect)
            this.showDialog = false
        }
    },
};
</script>
