<template>
    <div>
        <div class="sidebar-container toolbar-left">
            <ul class="toolbar-menu">
                <li class="toolbar-item" :key="uuid">
                    <el-popover placement="left-start" width="200" trigger="click">
                        <ul class="zm-user-info-popover">
                            <li>
                                <strong><i>{{ displayName }}</i></strong>
                            </li>
                            <li class="dc-line"></li>
                            <li>
                                <a href="javascript:;" @click="handleLogout">
                                    <i class="fi fi-rr-sign-out pr-2 pt-1"></i>
                                    <span>{{ $t("zimbra.zhMsg.ALT_APP_LINK_LOGOFF") }}</span>
                                </a>
                            </li>
                        </ul>
                        <span slot="reference" class="zimbra-cursor-pointer">
                            <BaseAvatar :listEmailAddress="avatarUserLogin" />
                            <!-- <span v-if="avatar != null" class="avatar-short">{{avatar}}</span>
                            <span v-if="avatar == null" class="no-avatar-short"></span> -->
                        </span>
                    </el-popover>
                </li>
                <!-- <li :class="['toolbar-item']">
                    <list-app @show="handleVisibleSetting" />
                </li> -->
                <li :class="['toolbar-item']" @click="activeMenu = 'setting'">
                    <quick-setting :activeMenu="activeMenu" @click="handleVisibleSetting"
                        @hide="handleHideQuickSetting" />
                </li>
                <li class="toolbar-item">
                    <el-tooltip class="item" effect="dark"
                                :content='this.$t("zimbra.zhMsg.mail")'
                                placement="left-end"
                    >
                    <div class="element">
                         <i :class="`mail-icon zimbra-cursor-pointer`" @click="handleRedirect"></i>
                    </div>
                       
                    </el-tooltip>
                </li>
                <li class="toolbar-item">
                    <calendar-setting @click="handleVisibleSetting" />
                </li>
                <li :class="['toolbar-item']" @click="handleOpenListContact">
                    <quick-contact :activeContact="activeContact" />
                </li>
                <li class="toolbar-item" @click="handleRedirectToBriefCase">
                    <quick-briefcase />
                </li>
                <li class="toolbar-item">
                    <user-guide />
                </li>
            </ul>
        </div>
        <!-- Một số dialog dùng chung cho các chức năng: mail, calendar, contact -->
        <DialogAddTag />
        <DialogReNameTag />
        <DialogWarningCalendar ref="refDialogWarningCalendar" @fActionMoreDetail="actionMoreDetail" />
        <template v-if="showCalendarForm">
            <CalendarForm ref="refCalendarForm" />
        </template>
        <DialogAddContactGroup />
        <DialogAddMoveFolder ref="DialogAddMoveFolder"></DialogAddMoveFolder>
    </div>
</template>
<script>
import QuickSetting from "@/components/modules/settings/quick-setting";
import CalendarSetting from "@/components/modules/calendar/calendar-setting";
import QuickContact from "@/components/modules/contacts/quick-contact";
import QuickBriefcase from "@/components/modules/briefcase/quick-briefcase";
import UserGuide from "@/components/modules/user-guide"
import { mapActions, mapGetters } from "vuex";
import DialogAddTag from "@/components/modules/layouts/components/DialogAddTag";
import DialogReNameTag from "@/components/modules/layouts/components/DialogReNameTag";
import DialogWarningCalendar from "@/components/modules/calendar/components/warning-calendar/dialog-warning-calendar";
import DialogAddContactGroup from "@/components/modules/contacts/components/views/DialogAddContactGroup.vue"
import DialogAddMoveFolder from "@/components/modules/layouts/components/DialogAddMoveFolder.vue";
import CalendarForm from "@/components/modules/calendar/calendar-form/calendar-form";
import BaseAvatar from "@/components/modules/base/base-avatar/base-avatar.vue";
import ListApp from "@/components/modules/listApp";
import $ from 'jquery'
import CommonUtils from "@/utils/common-utils";
export default {
    mixins: [CommonUtils],
    components: {
        QuickSetting,
        CalendarSetting,
        QuickContact,
        UserGuide,
        QuickBriefcase,
        DialogAddContactGroup,
        DialogAddTag,
        DialogReNameTag,
        DialogWarningCalendar,
        DialogAddMoveFolder,
        CalendarForm,
        BaseAvatar,
        ListApp
    },
    data() {
        return {
            dialogFormVisible: false,
            visibleSetting: false,
            visibleContact: false,
            activeMenu: "",
            activeContact: "",
            uuid: this.generateUniqueId(),
            showCalendarForm: false
        };
    },
    computed: {
        ...mapGetters([
            "attributes",
            "emailAddress",
            "listFileContacts"
        ]),
        displayName() {
            return this.attributes ? (this.attributes.displayName ? this.attributes.displayName : this.emailAddress) : '';
        },
        avatar() {
            return this.attributes ? this.attributes.displayName.charAt(0).toUpperCase() : null;
        },
        avatarUserLogin() {
            return [{
                a: this.emailAddress
            }];
        },
    },
    watch: {
        listFileContacts() {
            // update view
            this.uuid = this.generateUniqueId();
        }
    },
    async created() {
        await this.getPrefInbox({
            xml: "",
        });
    },
    mounted() {
        this.setBackGroundColorAvatar();
        this.$root.$on("openDialogDetailSchedule", this.openDialogDetailSchedule);
    },
    methods: {
        ...mapActions([
            "getPrefInbox"
        ]),
        setBackGroundColorAvatar() {
            let rgb = [];
            // Randomly update colours
            for (let i = 0; i < 3; i++) {
                rgb.push(Math.floor(Math.random() * 255));
            }
            const brightness = Math.round(((parseInt(rgb[0]) * 299) +
                (parseInt(rgb[1]) * 587) +
                (parseInt(rgb[2]) * 114)) / 1000);
            const textColour = (brightness > 125) ? '#3f4254' : 'white';
            const backgroundColour = 'rgb(' + rgb.join(',') + ')';
            $('#avatar-short-id').css('color', textColour);
            $('#avatar-short-id').css('background-color', backgroundColour);
        },
        handleVisibleSetting(val) {
            this.visibleSetting = val;
            this.activeMenu = "";
        },
        handleHideQuickSetting(val) {
            this.activeMenu = val;
        },
        handleChangTheme(theme) {
            this.activeTheme = theme.id;
        },
        handleLogout() {
            this._logout();
            this.removeLocalStorage("menuCollapse")
        },
        handleOpenListContact() {
            // Focus input search
            this.$nextTick(() => {
                $("#input-search-contact").focus();
            });
            this.activeContact = "contact";
            this.$root.$emit("handleOpenListContact", false);
        },
        handleRedirectToBriefCase() {
            const path = "/mail/briefcase";
            if (this.$route.path !== path) {
                window.location.href = path;
                // this.$router.push("/mail/briefcase")
            }
        },
        // handleVisibleContact(val){
        //     this.visibleContact = val,
        //     this.activeContact = "";
        // },
        // handleHideQuickContact(val) {
        //     this.activeContact = val;
        // },
        /**
         *
         */
        openDialogDetailSchedule(event) {
            this.$refs.refCalendarDialogDetail.open(event.invId);
        },

        actionMoreDetail(modelData) {
            this.showCalendarForm = true;
            this.$nextTick(() => {
                this.$refs.refCalendarForm && this.$refs.refCalendarForm.show(null, null, modelData);
            });
        },

        openDialogWarningCalendar() {
            this.$refs.refDialogWarningCalendar.open();
        },

        handleRedirect() {
            window.location.href = "/mail";
        },
    },
    destroyed() {
        this.$root.$off("openDialogDetailSchedule", this.openDialogDetailSchedule);
    }
};
</script>
<style lang="scss">
@import "@/assets/scss/sidebar-right.scss";
</style>
