<template>
    <el-dialog class="dialog-body-wrap zimbra-dialog dialog-share-wrap d-center"
        :title="$t('zimbra.zmMsg.shareProperties')" :visible.sync="dialogShare" width="450px" :append-to-body="true"
        :destroy-on-close="true" :close-on-click-modal="false">
        <!-- <perfect-scrollbar> -->
        <el-form :model="formShare" class="form-wrap">
            <el-form-item :label="$t('zimbra.zhMsg.AB_FIELD_firstName')" label-width="90px">
                <span>{{ formShare.name }}</span>
            </el-form-item>
            <el-form-item :label="$t('zimbra.zhMsg.optionsAccountType')" label-width="90px">
                <span>{{ getNameType() }}</span>
            </el-form-item>
            <el-form-item :label="$t('zimbraNokey.zimbra_sidebar_dialogShare_shareWith')" label-width="90px">
                <div>
                    <el-radio-group class="radio-wrap" v-model="formShare.optionShare" size="medium"
                        style="margin-top: 7px; display: block" @change="changeOptionShare">
                        <el-radio :label="!formShare.group ? 'usr' : 'grp'" :disabled="isEditShare">{{
                            $t("zimbra.zmMsg.shareWithUserOrGroup")
                            }}
                        </el-radio>
                        <el-radio label="guest" :disabled="isEditShare">{{
                            treeNodeData.view !== FOLDER_VIEW.DOCUMENT ? $t("zimbra.zmMsg.shareWithGuest") :
                                $t("zimbra.zmMsg.shareWithExternalGuest")
                        }}
                        </el-radio>
                        <el-radio label="pub" :disabled="!isEnablePublic() || disabledSharePub || isEditShare">{{
                            $t("zimbra.zmMsg.shareWithPublicLong")
                            }}
                        </el-radio>
                    </el-radio-group>
                </div>
                <hr style="margin-top: 0px" v-if="!isSharePublicMode()" />
                <el-form-item class="flex-center" v-if="!isSharePublicMode()" :label="$t('zimbra.zmMsg.emailLabel')"
                    label-width="55px">
                    <el-input ref="email" @blur="blurInput" @input="changeInput" :disabled="isEditShare"
                        class="el-text-control" v-if="formShare.optionShare == 'guest'" style="width: 250px"
                        size="medium" v-model="externalMail" :placeholder="$t('zimbraNokey.zimbra_sidebar_dialogShare_emailPlaceholder')
                            ">
                    </el-input>
                    <BaseAddressSelector v-if="formShare.optionShare !== 'pub' && formShare.optionShare !== 'guest'"
                        v-model="formShare.email" :multiple="true" :disabled="isEditShare" class="select-input-wrap"
                        style="width: 250px" size="medium" />
                </el-form-item>
            </el-form-item>
            <fieldset v-if="formShare.optionShare !== 'pub' || formShare.optionShare !== 'guest'" v-show="showRole"
                class="border rounded-10" style="
                        padding: 5px;
                        margin: 5px;
                        margin-top: 20px;
                    ">
                <legend class="w-auto">
                    <strong>{{ $t("zimbraNokey.zimbra_sidebar_dialogShare_role") }}</strong>
                </legend>
                <!-- <div
                        style="
                            width: 45px;
                            height: 35px;
                            background: white;
                            margin-top: -17px;
                            font-weight: 600;
                        "
                    >
                        <span>{{ $t("zimbraNokey.zimbra_sidebar_dialogShare_role") }}</span>
                    </div> -->
                <div class="radio-wrap">
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-radio @change="disabledBtnOk = false" v-model="formShare.optionRole" label=""
                                size="medium">
                                <b>{{ $t("zimbra.zhMsg.no") }}</b>
                            </el-radio>
                        </el-col>
                        <el-col :span="12">
                            <span>{{
                                $t("zimbra.zhMsg.no")
                            }}</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-radio @change="disabledBtnOk = false" v-model="formShare.optionRole" label="r"
                                size="medium">
                                <b>{{ $t("zimbra.zmMsg.shareRoleViewer") }}</b>
                            </el-radio>
                        </el-col>
                        <el-col :span="12">
                            <span>
                                {{ $t("zimbra.zhMsg.MO_view") }}
                            </span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-radio @change="disabledBtnOk = false" v-model="formShare.optionRole" label="rwidx"
                                size="medium">
                                <b>{{ $t("zimbra.zmMsg.shareRoleManager") }}</b>
                            </el-radio>
                        </el-col>
                        <el-col :span="12"><span>{{
                            $t("zimbraNokey.zimbra_sidebar_dialogShare_operationRole3")
                                }}</span>
                        </el-col>
                    </el-row>
                    <el-row v-show="formShare.optionShare !== 'guest'" :gutter="20">
                        <el-col :span="8">
                            <el-radio @change="disabledBtnOk = false" v-model="formShare.optionRole" label="rwidxa"
                                size="medium"><b>{{
                                    $t("zimbra.zmMsg.shareActionAdmin")
                                    }}</b></el-radio>
                        </el-col>
                        <el-col :span="13"><span>{{
                            $t("zimbraNokey.zimbra_sidebar_dialogShare_operationRole4")
                                }}</span>
                        </el-col>
                    </el-row>
                </div>
            </fieldset>
            <div v-if="isCalendarFolder && !isSharePublicMode()" class="ml-3 mt-4">
                <el-checkbox v-model="formShare.isPrivatePermission">{{
                    $t('zimbra.zmMsg.privatePermission')
                    }}</el-checkbox>
            </div>
            <fieldset v-if="!isSharePublicMode()" :class="`border rounded-10 ${isCalendarFolder ? 'mt-10' : 'mt-20'}`"
                style="
                    padding: 5px;
                    margin: 5px;
                ">
                <legend class="w-auto">
                    <strong>{{ $t("zimbra.zhMsg.ALT_APP_MAIL") }}</strong>
                </legend>
                <!-- <div
                        style="
                            width: 30px;
                            height: 35px;
                            background: white;
                            margin-top: -17px;
                            font-weight: 600;
                        "
                    >
                        <span>{{ $t("zimbra.zhMsg.ALT_APP_MAIL") }}</span>
                    </div> -->
                <div style="margin-left: 20px">
                    <el-select size="medium" v-model="formShare.optionMessage" :placeholder="$t('zimbra.zhMsg.select')
                        " @change="changeOptionMessage">
                        <el-option v-show="showRole" value="1" :label="$t('zimbra.zmMsg.sendNoMailAboutShare')
                            ">
                        </el-option>
                        <el-option value="2" :label="$t('zimbra.zmMsg.sendStandardMailAboutShare')
                            ">
                        </el-option>
                        <el-option value="3" :label="$t('zimbra.zmMsg.sendStandardMailAboutSharePlusNote')
                            ">
                        </el-option>
                    </el-select>
                    <div v-show="showNote" style="width: 26em; display: block; padding-top: 10px;word-break: normal">
                        <b>{{ $t("zimbra.zmMsg.noteLabel") }}</b>
                        {{ $t("zimbraNokey.zimbra_sidebar_dialogShare_noteContent") }}
                    </div>
                    <div v-show="showAddNote">
                        <el-input type="textarea" :autosize="{ minRows: 4 }" v-model="formShare.note"
                            style="width: 26em; display: inline-block; resize: vertical" maxlength=128 show-word-limit
                           >
                        </el-input>

                    </div>
                </div>
            </fieldset>
            <!-- Nếu là chia sẻ danh bạ hoặc cặp tài liệu thì thêm URL -->
            <div v-if="isBriefOrContact">
                <div class="rounded-10" style="
                        border: 1px solid rgb(242, 242, 242);
                        padding: 5px;
                        margin: 5px;
                        margin-top: 20px;">
                    <div style="
                            width: 30px;
                            height: 35px;
                            background: white;
                            margin-top: -17px;
                            font-weight: 600;">
                        <p>URL</p>
                    </div>
                    <div class="share-url-box" style="margin-left: 20px">
                        <div style="
                                min-width: 26em;
                                display: block;
                                padding-top: 10px;">
                            {{ $t("zimbra.zmMsg.shareUrlInfo") }}
                        </div>
                        <div>
                            <a style="color: #005a95" :href="`${getUrlCalendarShare()}`" target="_new">{{
                                getUrlCalendarShare() }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Nếu là chia sẻ lịch thì thêm URL -->
            <div v-if="isCalendarFolder">
                <fieldset class="border rounded-10" style="
                        padding: 5px;
                        margin: 5px;
                        margin-top: 20px;
                        ">
                    <legend class="w-auto">
                        <strong>URL</strong>
                    </legend>
                    <div style="margin-left: 20px">
                        <div style="
                                width: 26em;
                                display: block;
                                padding-top: 10px;
                                word-break: normal;">
                            {{ $t("zimbra.zmMsg.shareUrlInfo") }}
                        </div>
                        <div>
                            ICS:&nbsp;&nbsp;&nbsp;&nbsp; <a style="color: #005a95"
                                :href="`${getUrlCalendarShare()}.ics`" target="_new">{{ getUrlCalendarShare()
                                }}.ics</a><br />
                            {{ $t("zimbra.zhMsg.MO_view") }}:&nbsp;&nbsp;&nbsp;&nbsp; <a style="color: #005a95"
                                :href="`${getUrlCalendarShare()}.html`" target="_new">{{ getUrlCalendarShare()
                                }}.html</a><br />
                            Outlook:&nbsp;&nbsp;&nbsp;&nbsp; <a style="color: #005a95"
                                :href="`webcals://${getUrlCalendarShare().substring(7)}`" target="_new">webcals://{{
                                    getUrlCalendarShare().substring(7) }}</a><br />
                        </div>
                    </div>
                </fieldset>
            </div>
        </el-form>
        <!-- </perfect-scrollbar> -->
        <span slot="footer" class="dialog-footer">
            <el-button class="btn-email default" @click="dialogShare = false">{{
                $t("zimbra.zhMsg.actionTaskCancelTT")
            }}</el-button>
            <el-button class="btn-email primary ml-10" type="primary" @click="shareFolder"
                :disabled="formShare.email.length == 0 && !isSharePublicMode() || disabledBtnOk">
                {{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
import BaseAddressSelector from "@/components/modules/base/base-address-selector/BaseAddressSelector";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CompileService from "@/services/CompileService.js";
import CommonUtils from "@/utils/common-utils";
import color from "./../listColor";
import _ from "lodash";
import { ERROR_CODE, FOLDER_VIEW, READ_EMAIL_LAYOUT_ROUTER } from "@/utils/constants";
import { mapGetters } from "vuex";
import { STATUS_REQUEST } from "@/utils/constants";
export default {
    props: {
        isBriefcase: {
            type: Boolean,
            default: false
        }
    },
    mixins: [CommonUtils],
    mounted() {
        this.$root.$on("handleSidebarActionShare", (data, isEdit) => {
            this.show(data, isEdit);
        });

    },
    destroyed() {
        this.$root.$off("handleSidebarActionShare")
    },
    computed: {
        ...mapGetters([
            "preference",
            "fromAddresses",
            "activeNodeSidebarRightClick",
            "currentActiveEmail"
        ]),

        /**
         * Hàm Lấy địa chỉ email
         */
        emailAddress() {
            return this.$store.getters.emailAddress;
        },

        /**
         * Hàm kiểm tra xem có phải thư mục lịch hay không
         */
        isCalendarFolder() {
            return this.treeNodeData.view == FOLDER_VIEW.APPOINTMENT;
        },

        /**
         * Hàm kiểm tra xem có phải thư mục danh bạ hoặc cặp tài liệu hay không
         */
        isBriefOrContact() {
            return this.treeNodeData.view == FOLDER_VIEW.CONTACT || this.treeNodeData.view == FOLDER_VIEW.DOCUMENT;
        }
    },
    components: {
        BaseAddressSelector,
    },
    watch: {
        preference() {
            this.myAddresses = this.fromAddresses.find(x => x.uuid == this.preference.defaultFromAddress);
        },
    },
    data() {
        return {
            formShare: {
                id: "",
                name: "",
                optionShare: "usr",
                optionRole: "r",
                optionMessage: "1",
                email: [],
                note: "",
            },
            email: [],
            listColor: color,
            dialogShare: false,
            isEditShare: false,
            listEmail: [],
            loaddingText: "Đang tự động hoàn thành...",
            showRole: true,
            showNote: false,
            showAddNote: false,
            disabledBtnOk: false,
            listMembers: [],
            showListMembers: false,
            path: '',
            disabledSharePub: false,
            treeNodeData: {},
            myAddresses: {},
            externalMail: "",
            FOLDER_VIEW: FOLDER_VIEW,


        };
    },
    methods: {
        changeInput(value) {
            this.formShare.email[0] = value.trim();
            if (this.formShare.email[0] == "") {
                this.formShare.email = [];
            }
        },
        blurInput() {
            this.externalMail = this.externalMail.trim();
            this.formShare.email[0] = this.externalMail;
        },
        show(data, isEdit) {
            this.externalMail = "";
            this.treeNodeData = this.activeNodeSidebarRightClick;
            this.path = this.treeNodeData.absFolderPath.split('/');
            this.formShare = {
                id: "",
                optionShare: "usr",
                optionRole: "r",
                optionMessage: "2",
                email: [],
                note: "",
            };
            this.disabledBtnOk = false;
            this.showRole = true;
            if (isEdit) {
                this.formShare = _.cloneDeep(data);
                this.externalMail = this.formShare.email
                this.disabledSharePub = data.disableSharePub
                if (this.isBriefcase) {
                    if (this.formShare.optionShare == "pub") {
                        this.showRole = false
                    } else {
                        this.showRole = true
                    }
                } else {
                    if (this.formShare.optionShare == "guest") {
                        this.showRole = false
                    } else {
                        this.showRole = true
                    }
                }
                this.formShare.optionMessage = "2";
                this.disabledBtnOk = true;
                this.externalMail = this.formShare.email;
            } else {
                this.formShare.email = [];
                this.disabledSharePub = false
            }
            this.showAddNote = false;
            this.showNote = true;
            this.formShare.id = this.treeNodeData.id;
            this.formShare.name = this.treeNodeData.name;
            this.isEditShare = isEdit;
            this.dialogShare = true;
            if (this.formShare.optionShare != "usr") {
                setTimeout(() => {
                    this.$refs.email.focus();
                }, 100);
            }
            const { name } = this.$route;
            if (name == READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE) {
                this.isMessage = true
            } else {
                this.isMessage = false
            }
        },
        shareFolder() {
            let stringXml = {};
            const lstemail = this.formShare.email;
            if (this.myAddresses && lstemail.indexOf(this.myAddresses.a) > -1) {
                this.$confirm(
                    this.$t("zimbra.zmMsg.sharingErrorWithSelf"),
                    this.$t("zimbra.zmMsg.share"),
                    {
                        showConfirmButton: false,
                        cancelButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "info",
                    }
                );
                return;
            }
            if (this.formShare.optionShare == 'guest' && !this.validateEmail(this.formShare.email[0])) {
                this.$confirm(
                    this.$t("zimbraNokey.zimbra_sidebar_messageCriticalFormatMail", { mail: this.formShare.email[0] }),
                    this.$t("zimbraNokey.zimbra_common_criticalMsg"),
                    {
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        showCancelButton: false,
                        type: "error",
                    }
                );
                return;
            }
            // Thêm điều kiện chia sẻ lịch cho phép xem cuộc hẹn riêng tư
            if (this.isCalendarFolder && this.formShare.isPrivatePermission) {
                this.formShare.optionRole += "p";
            }
            //
            if (this.formShare.email.length) {
                lstemail.forEach((x) => {
                    // const email= x.substring(x.indexOf("<") + 1,x.length - 1);
                    const xml = `<urn1:action id="${this.formShare.id}" op="grant"><grant gt="${this.formShare.optionShare}" inh="1" d="${x}" perm="${this.formShare.optionRole}" pw=""/></urn1:action>`;
                    // stringXml.push({
                    //     path: "folderActionRequest",
                    //     content: { folder: xml }
                    // });
                    stringXml = { folder: xml };
                });

            } else {
                const xml = `<urn1:action id="${this.treeNodeData.id}" op="grant"><grant gt="${this.formShare.optionShare}" inh="1" perm="r" pw=""/></urn1:action>`;
                // stringXml.push({
                //     path: "folderActionRequest",
                //     content: { folder: xml }
                // });
                stringXml = { folder: xml };
            }
            const formData = stringXml;

            let checkSuccess = true;
            if (stringXml != "") {
                ZimbraMailService.folderActionRequest(formData).then(async (res) => {
                    if (res.status == STATUS_REQUEST.SUCCESS) {
                        //const dataRequest = res.data.data;
                        const dataRequest = res.data["soap:Envelope"];
                        //const listShareResults = [];
                        // dataRequest.forEach((item) => {
                        //     const data = JSON.parse(item);
                        //     listShareResults.push(data);
                        // });
                        const listShareResults = Object.keys(dataRequest);

                        const _temp = listShareResults.filter(e => e && e["soap:Fault"] && e["soap:Fault"]["detail"] && e["soap:Fault"]["detail"].Error.Code == "mail.GRANT_EXISTS");
                        if (_temp && _temp.length > 0) {
                            if (this.isMessage) {
                                this.$message({
                                    type: "warning",
                                    customClass: 'zm-notify',
                                    duration: 3500,
                                    dangerouslyUseHTMLString: true,
                                    message: this.$t("zimbra.zmMsg.shareNotCreated")
                                });
                            } else {
                                this.$notify({
                                    message: this.$t("zimbra.zmMsg.shareNotCreated"),
                                    type: "warning",
                                });
                            }
                        }
                        if (this.formShare.optionShare == "pub" && _temp.length) {
                            await this.$confirm(
                                this.$t("zimbra.zmMsg.shareExistsPublic"),
                                this.$t("zimbraNokey.zimbra_common_criticalMsg"),
                                {
                                    dangerouslyUseHTMLString: true,
                                    confirmButtonText: this.$t(
                                        "zimbra.zhMsg.resend"
                                    ),
                                    cancelButtonText: this.$t(
                                        "zimbra.zhMsg.actionTaskCancelTT"
                                    ),
                                    type: "error",
                                }
                            );
                            return false;
                        }
                        for (let index = 0; index < lstemail.length; index++) {
                            if (listShareResults[index] && listShareResults[index]["soap:Fault"] && listShareResults[index]["soap:Fault"]["detail"] && listShareResults[index]["soap:Fault"]["detail"].Error.Code == "mail.GRANT_EXISTS") {
                                checkSuccess = false;
                                await this.$confirm(
                                    this.$t(
                                        "zimbraNokey.zimbra_error_mail_GRANT_EXISTS",
                                        { mail: lstemail[index] }
                                    ),
                                    this.$t("zimbraNokey.zimbra_common_criticalMsg"),
                                    {
                                        confirmButtonText: this.$t(
                                            "zimbra.zhMsg.resend"
                                        ),
                                        cancelButtonText: this.$t(
                                            "zimbra.zhMsg.actionTaskCancelTT"
                                        ),
                                        type: "error",
                                    }
                                ).then(() => {
                                    const dataResend = {
                                        perm: this.formShare.optionShare,
                                        name: lstemail[index],
                                        role: ""
                                    };
                                    this.$root.$emit("reSend", dataResend);
                                }).catch(() => {
                                });
                            } else {
                                const notification = {
                                    id: `${this.formShare.id}`,
                                    a: lstemail[index],
                                    note: _.escape(this.formShare.note?.trim()),
                                };
                                if (this.isEditShare) {
                                    notification.action = "edit";
                                }
                                await ZimbraMailService.sendShareNotificationRequest(
                                    notification).catch((err) => {
                                        const { detail } = this.getResponseBody(
                                            err.response
                                        )["soap:Fault"];
                                        if (
                                            detail.Error.Code ===
                                            ERROR_CODE.INVALID_REQUEST
                                        ) {
                                            this.$confirm(
                                                this.$t("zimbraNokey.zimbra_sidebar_messageCriticalFormatMail", { mail: lstemail[index] }),
                                                this.$t("zimbraNokey.zimbra_common_criticalMsg"),
                                                {
                                                    showConfirmButton: false,
                                                    cancelButtonText:
                                                        this.$t(
                                                            "zimbra.zmMsg.mobileStatusNeedProvision"
                                                        ),
                                                    type: "error",
                                                }
                                            )
                                                .then(() => {
                                                })
                                                .catch(() => {
                                                });
                                        }
                                    });
                            }
                        }
                        if (checkSuccess) {
                            const msg = this.isEditShare ? this.$t("zimbra.zmMsg.shareModifiedSubject") : this.$t("zimbra.zmMsg.shareCreatedSubject");
                            if (this.isMessage) {
                                this.$message({
                                    type: "success",
                                    customClass: 'zm-notify',
                                    duration: 3500,
                                    dangerouslyUseHTMLString: true,
                                    message: msg
                                });
                            } else {
                                this.$notify({
                                    title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                                    message: msg,
                                    type: "success",
                                });
                            }
                        }
                    }
                    setTimeout(() => {
                        this.$root.$emit("reloadDataFolder", this.formShare.id)
                    }, 100);

                });
            }
            this.dialogShare = false;
        },
        changeOptionShare() {
            if (this.isBriefcase) {
                if (this.formShare.optionShare == 'pub') this.showRole = false
                else this.showRole = true
            } else {
                if (this.formShare.optionShare == "usr") {
                    this.showRole = true;
                    if (!this.externalMail) {
                        this.formShare.email = [];
                    }
                } else {
                    this.externalMail = this.formShare.email[0];
                    this.showRole = false;
                    this.formShare.optionRole = "r"
                }
            }
        },
        changeOptionMessage() {
            if (this.formShare.optionMessage == "1") {
                this.showAddNote = false;
                this.showNote = false;
            } else if (this.formShare.optionMessage == "3") {
                this.showAddNote = true;
                this.showNote = true;
            } else {
                this.showAddNote = false;
                this.showNote = true;
            }
        },
        remoteMethodEmail(query) {
            if (query !== "") {
                const form = { name: `${query}` };
                ZimbraMailService.autoCompleteRequest(form)
                    .then((res) => {
                        const data = this.getResponseBodyEmail(res);
                        this.listEmail = data;
                        if (this.listEmail) {
                            this.listEmail = Array.isArray(this.listEmail)
                                ? this.listEmail
                                : [this.listEmail];
                            this.listEmail.forEach((x) => {
                                x.label = x.email.substring(
                                    x.email.indexOf("<") + 1,
                                    x.email.length - 1
                                );
                            });
                        }
                    })
                    .catch();
            } else {
                this.listEmail = [];
            }
        },
        openListMembers(email) {
            this.showListMembers = true;
            const content = email.substring(
                email.indexOf("<") + 1,
                email.length - 1
            );
            const form = { dl: { content: `${content}` }, limit: 100, offset: 0 };
            this.listMembers;
            ZimbraMailService.getDistributionListMembersRequest(form)
                .then((res) => {
                    const data =
                        this.getResponseBody(res)[
                        "GetDistributionListMembersResponse"
                        ];
                    if (data && data.dlm) {
                        this.listMembers = Array.isArray(data.dlm)
                            ? data.dlm
                            : [data.dlm];
                    }
                })
                .catch();
            document.addEventListener("click", this.closeMenuMembers);
        },
        closeMenuMembers() {
            this.showListMembers = false;
            document.removeEventListener("click", this.closeMenuMembers);
        },
        selectMembers(email) {
            this.formShare.email.push(email);
        },
        selectAllMembers(listMembers) {
            this.formShare.email = [
                ...listMembers.filter((x) => !this.formShare.email.includes(x)),
                ...this.formShare.email,
            ];
        },
        getResponseBodyEmail(response) {
            return response["data"]["soap:Envelope"]["soap:Body"][
                "AutoCompleteResponse"
            ]["match"];
        },
        // KienPT start thêm logic share folder lịch

        /**
         * Lấy tên theo loại
         */
        getNameType() {
            if (this.treeNodeData.view == FOLDER_VIEW.APPOINTMENT) {
                return this.$t("zimbra.zhMsg.ALT_APP_CALENDAR")
            } else if (this.treeNodeData.view == FOLDER_VIEW.CONTACT) {
                return this.$t("zimbra.zmMsg.contactsFolder")
            } else if (this.treeNodeData.view == FOLDER_VIEW.DOCUMENT) {
                return this.$t("zimbra.zhMsg.ALT_APP_BRIEFCASE")
            }
            return this.$t("zimbra.zmMsg.mailFolder");
        },

        /**
         * Kiểm tra có được phép enable mode public hay không
         */
        isEnablePublic() {
            const isEnablePublic = this.activeNodeSidebarRightClick?.view !== FOLDER_VIEW.MESSAGE
            return isEnablePublic;
        },


        /**
         * Kiểm tra xem có ở mode pulic không
         */
        isSharePublicMode() {
            return this.formShare.optionShare == "pub";
        },

        /**
         * Hàm lấy thông tin url share
         */
        getUrlCalendarShare() {
            // Nếu là calendar thường thì bỏ ký tự đầu tiên đi vì là dầu /
            let pathSearch = this.treeNodeData.absFolderPath.substring(1)
            pathSearch = encodeURIComponent(pathSearch)
            const prefix = `${this.$configZimbra.ENDPOINT.importExportService}/${this.emailAddress}/${pathSearch}`
            return prefix;
        },

        // KienPT start end




    },
};
</script>
<style scoped>
.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}
</style>
