import Vue from "vue";
import VueRouter from "vue-router";
import CalendarLayout from "@/components/modules/layouts/CalendarLayout.vue"
import ContactLayout from "@/components/modules/layouts/ContactLayout"
import BaseLayout from "@/components/modules/layouts/BaseLayout";
import EmailWithReadPanelLayout from "@/components/modules/layouts/EmailWithReadPanelLayout";
import BriefcaseLayout from "@/components/modules/layouts/BriefcaseLayout";
import { READ_EMAIL_LAYOUT, READ_EMAIL_LAYOUT_ROUTER } from "../utils/constants";
import zimbraMailService from "@/services/ZimbraMailService.js";
import generalService from "@/services/GeneralService";
import CommonUtils from "@/utils/common-utils";
import VueBodyClass from 'vue-body-class';
import { loadLanguageAsync } from "@/plugins/i18n";
import CONFIG from "@/config/config";
import AuthSSO from "../views/AuthSSO.vue";
import {LOCALES} from "@/utils/constants";
Vue.use(VueRouter);


async function redirectReadingPaneLocation(to, from, next, isRedirect = true) {
    const zimbraToken = localStorage.getItem('zimbra_token');
 
    if (!zimbraToken) {
        if(to.path === '/auth-sso'){
            next({ name: AuthSSO});
        return;
        }
        next({ name: CONFIG.LOGIN_PAGE });
        return;
    }
    const mapPrefs = ["zimbraPrefReadingPaneLocation", "zimbraPrefFont", "zimbraPrefSkin", "zimbraPrefLocale"];
    const formData = {
        xml: mapPrefs.map(pref => `<pref name=\"${pref}\"></pref>`).join("")
    };
    localStorage.removeItem("defaultLocale");
    const response = await zimbraMailService.getPrefsRequest(formData);
    const result = CommonUtils.methods.getResponseBody(response);
    const { pref } = result['GetPrefsResponse'];
    const prefs = Array.isArray(pref) ? pref : [pref];

    const zimbraPrefReadingPaneLocation = prefs.find(el => el.name == "zimbraPrefReadingPaneLocation")?.content;
    const zimbraPrefFont = prefs.find(el => el.name == "zimbraPrefFont")?.content;
    const zimbraPrefSkin = prefs.find(el => el.name == "zimbraPrefSkin")?.content;
    const zimbraPrefLocale = prefs.find(el => el.name == "zimbraPrefLocale")?.content;
    const { name } = to;
    const splitPrefLocale = zimbraPrefLocale && zimbraPrefLocale.split(",");
    const lastPrefLocale = splitPrefLocale[splitPrefLocale.length - 1];
     const defaultLocale = lastPrefLocale || "vi";

    localStorage.setItem("defaultLocale", defaultLocale);
    await loadLanguageAsync(defaultLocale);
    const timerId = setTimeout(() => {
        if (isRedirect) {
            const mainPage = zimbraPrefReadingPaneLocation == READ_EMAIL_LAYOUT.RIGHT ? READ_EMAIL_LAYOUT_ROUTER.NAME_RIGHT : READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE;
            if (name != mainPage) {
                next({ name: mainPage });
            }
        }
        // Set theme
        generalService.afterSetSkin(null, zimbraPrefSkin, isRedirect ? zimbraPrefReadingPaneLocation : READ_EMAIL_LAYOUT.RIGHT);
        if (zimbraPrefReadingPaneLocation == READ_EMAIL_LAYOUT.RIGHT && name == READ_EMAIL_LAYOUT_ROUTER.NAME_RIGHT) {
            // Set font family
            generalService.afterSetFontFamily(null, zimbraPrefFont);
        }
        clearTimeout(timerId);
        return true;
    }, 300);

    if(!CommonUtils.methods.getCookie('login_sc')) {
        window.location = `/${CONFIG.LOGIN_PAGE}`;
    }
}

const routes = [
    {
        path: "/mail",
        name: "App",
        component: () => import("@/App"),
        children: [
            {
                path: READ_EMAIL_LAYOUT_ROUTER.SUB_RIGHT,
                name: "Email",
                component: () => import(/* webpackChunkName: "lazy-email" */ "../views/Email.vue"),
                meta: { layout: BaseLayout }
            },
            {
                path: READ_EMAIL_LAYOUT_ROUTER.SUB_HIDE,
                name: "EmailWithReadPanel",
                component: () => import(/* webpackChunkName: "lazy-vemail" */ "../views/V-Email.vue"),
                meta: { layout: EmailWithReadPanelLayout, bodyClass: 'vmail__container' }
            },
            {
                path: "/mail/setting",
                name: "Setting",
                component: () => import(/* webpackChunkName: "lazy-setting" */ "../views/Setting.vue"),
                meta: { layout: BaseLayout }
            },
            {
                path: "/mail/contacts",
                name: "Contact",
                component: () => import(/* webpackChunkName: "lazy-contact" */ "../views/Contact.vue"),
                meta: { layout: ContactLayout }
            },
            {
                path: "/mail/calendar",
                name: "Calendar",
                component: () => import(/* webpackChunkName: "lazy-canlendar" */ "../views/Calendar.vue"),
                meta: { layout: CalendarLayout }
            },
            {
                path: "/mail/briefcase",
                name: "Briefcase",
                component: () => import(/* webpackChunkName: "lazy-briefcase" */ "../views/Briefcase.vue"),
                meta: { layout: BriefcaseLayout }
            }
        ]
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "lazy-login" */ "@/views/ViettelLogin.vue")
    },

    {
        path: "/login-sso",
        name: "LoginSSO",
        component: () => import(/* webpackChunkName: "lazy-loginsso" */ "@/views/LoginSSO")
    },
    {
        path: "/auth-sso",
        name: "AuthSSO",
        component: () => import(/* webpackChunkName: "lazy-authsso" */ "../views/AuthSSO.vue")
    },
    {
        path: "/launchNewWindow",
        name: "LaunchNewWindow",
        component: () => import(/* webpackChunkName: "lazy-launchnewwindow" */ "@/views/LaunchNewWindow"),
        meta: { isNewWindow: true }
    },
    {
        path: "/launchNewWindow2",
        name: "LaunchNewWindow2",
        component: () => import(/* webpackChunkName: "lazy-launchnewwindow2" */ "@/views/LaunchNewWindow2"),
    },
    {
        path: "/printmessage",
        name: "PrintMessage",
        component: () => import(/* webpackChunkName: "lazy-printmessage" */ "@/views/PrintMessage")
    },
    {
        path: "/api/zimbra-h/viewimages",
        name: "ViewImages",
        component: () => import(/* webpackChunkName: "lazy-viewimages" */ "@/views/ViewImages")
    },
    {
        path: "/printcontacts",
        name: "PrintContacts",
        component: () => import(/* webpackChunkName: "lazy-printcontacts" */ "@/views/PrintContacts")
    },
    {
        path: "/printAppointments",
        name: "PrintAppointment",
        component: () => import(/* webpackChunkName: "lazy-printappointment" */ "@/views/PrintAppointment")
    },
    {
        path: "/calendar-share",
        name: "CalendarShare",
        component: () => import(/* webpackChunkName: "lazy-calendarshare" */ "@/components/modules/settings/share/CalendarShare")
    },
{
        path: '*',
        beforeEnter: redirectReadingPaneLocation
    },

];

const router = new VueRouter({
    mode: "history",
    routes
});

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach(async (to, from, next) => {
    vueBodyClass.guard(to, next);
    if (to.path == "/mail" || to.path == "/mail/email" || to.path == "/mail/v-email") {
        await redirectReadingPaneLocation(to, from, next);
    } else if (to.path != "/login") {
        await redirectReadingPaneLocation(to, from, next, false);
    }
});


export default router;
