<template>
    <el-dialog
        class="dialog-body-wrap d-center zimbra-dialog zimbra-limited-width-dialog"
        custom-class="dialog-move-folder"
        :title="`${formMove.title}`"
        :visible.sync="dialogMoveFolder"
        width="30%"
        height="550px"
        :append-to-body="true"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @open="onOpenDialog"
    >
        <el-form class="form-wrap" @submit.prevent.native="" @keypress.enter.native="moveFolder">
            <span
                style="margin: 0; margin-bottom: 2px"
                >{{ $t("zimbra.zmMsg.targetFolder") }}
            </span>
            <div style="padding-bottom: 10px">
                {{ $t("zimbra.zmMsg.chooserDescription") }}
            </div>
            <TreeChooseFolderSearch
                ref="treeFolderSearch"
                v-if="moveFolderSearch"
                :typeFolderSearch="activeNodeSidebarRightClick.types"
                :isMoveFolder="true"
            ></TreeChooseFolderSearch>
            <TreeFolderExtra
                :listIdFolderHidden="listIdFolderHidden"
                ref="treeFolderExtra"
                v-if="!moveFolderSearch"
                :isChangeFolderArchive="isChangeFolderArchive"
                :isMoveMail="!formMove.isMoveFolder"
            ></TreeFolderExtra>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button
                v-if="!moveFolderSearch"
                class="btn-outline"
                @click="addNewFolder"
                style="float: left"
                >{{ $t("zimbra.zhMsg.apptPtstNEW") }}</el-button
            >
            <el-button
                class="btn-email default"
                @click="dialogMoveFolder = false"
                >{{ $t("zimbra.zhMsg.actionTaskCancelTT") }}</el-button
            >
            <el-button
                v-if="!formMove.isMoveFolder"
                class="btn-email primary"
                type="primary"
                @click="moveOrArchiveMail"
                >{{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}</el-button
            >
            <el-button
                v-if="formMove.isMoveFolder"
                class="btn-email primary"
                type="primary"
                @click="moveFolder"
                >{{ $t("zimbra.zmMsg.mobileStatusNeedProvision") }}</el-button
            >
        </span>
    </el-dialog>
</template>
<script>
import TreeFolderExtra from "./TreeFolderExtra";
import TreeChooseFolderSearch from "./TreeChooseFolderSearch";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import {
    EMAIL_ACTION,
    FOLDER_EXTRA_ACTION,
    FOLDER_DEFAULT,
    STATUS_REQUEST,
    FOLDER_VIEW,
    READ_EMAIL_LAYOUT_ROUTER

} from "@/utils/constants";
import { mapGetters } from "vuex";
import _ from 'lodash';

export default {
    mixins: [CommonUtils],
    mounted() {
        this.$root.$on("handleSidebarActionMoveArchiveMail", (type) => {
            this.show(type);
        });
        this.$root.$on("closeDialogMove", () => {
            this.closeDialog();
        });
        this.$root.$on("onDropFinish", this.onMoveFolder);
        this.$root.$on("onMousemoveFolderDropSelector", this.onMousemoveDropSelector);
    },
    computed: {
        ...mapGetters([
            "activeFolderExtra",
            "activeNodeSidebarRightClick",
            "dataSidebarInBox",
            "dataSidebarExtend",
            "folderArchive",
            "activeNodeSidebar",
            "folderSearches"
        ]),
        listIdFolderHidden() {
            return this.isChangeFolderArchive || this.type == FOLDER_EXTRA_ACTION.MOVE_MAIL ? [FOLDER_DEFAULT.ROOT] : [FOLDER_DEFAULT.ROOT, FOLDER_DEFAULT.JUNK];
        }
    },
    components: {
        TreeFolderExtra,
        TreeChooseFolderSearch
    },
    data() {
        return {
            formMove: { parent: {}, title: "", isMoveFolder: true },
            newName: "",
            dialogMoveFolder: false,
            filterText: "",
            treeNodeMove: {},
            listIdChild: [],
            EMAIL_ACTION: EMAIL_ACTION,
            FOLDER_EXTRA_ACTION: FOLDER_EXTRA_ACTION,
            STATUS_REQUEST: STATUS_REQUEST,
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            moveFolderSearch: false,
            type: "",
            isChangeFolderArchive: false,
            idUndo: "",
        };
    },
    methods: {
        addNewFolder() {
            this.$root.$emit('handleSidebarActionMoveAdd', false)
        },
        onMousemoveDropSelector(STATE, srcElement, desElement) {
            const idFolderDrag = srcElement.getAttribute("id");
            const typeFolderDrag = srcElement.getAttribute("type");
            const folderSearch = this.getFolderSearch();
            const folderDrag = this.findFolderById(idFolderDrag, this.dataSidebarInBox[0].folder) || this.findFolderById(idFolderDrag, this.dataSidebarExtend[0].folder) || this.findFolderSearchById(idFolderDrag, folderSearch[0].search);
            this.$store.commit("SET_ACTIVE_NODE_SIDEBAR_RIGHTCLICK", folderDrag)
            // dữ liệu thư mục đích
            const idFolderDrop = desElement.getAttribute("id");
            const typeFolderDrop = desElement.getAttribute("type");
            const folderDrop = this.findFolderById(idFolderDrop, this.dataSidebarInBox[0].folder) || this.findFolderById(idFolderDrop, this.dataSidebarExtend[0].folder) || this.findFolderSearchById(idFolderDrop, folderSearch[0].search);
            this.listIdChild = [];
            this.getListIdChild(folderDrag);
            const listIdFolderNotDrop = (this.listIdChild.concat([FOLDER_DEFAULT.STARS, FOLDER_DEFAULT.DRAFTS, FOLDER_DEFAULT.TAG, FOLDER_DEFAULT.EXTEND, FOLDER_DEFAULT.JUNK, folderDrag.l])).map(x => x.toString());
            if (listIdFolderNotDrop.includes(idFolderDrop) || (typeFolderDrag == FOLDER_VIEW.SEARCH && typeFolderDrop !== FOLDER_VIEW.SEARCH)) {
                STATE.isReady = false;
                this.allow = false;
            } else {
                STATE.isReady = true;
                this.allow = true;
                this.formMove.parent = folderDrag;
                this.$store.commit("SET_ACTIVE_FOLDER_EXTRA", folderDrop);
            }
        },
        onMoveFolder() {
            if (this.allow) {
                this.moveFolder()
            }
        },
        show(type) {
            this.type = type;
            this.isChangeFolderArchive = false;
            if (type == FOLDER_EXTRA_ACTION.MOVE_FOLDER || type == FOLDER_EXTRA_ACTION.MOVE_FOLDER_SEARCH) {
                this.formMove.isMoveFolder = true;
                this.formMove.title = type == FOLDER_EXTRA_ACTION.MOVE_FOLDER_SEARCH
                    ? this.$t("zimbraNokey.zimbra_sidebar_dialogMoveFolder_titleSearch", { name: this.activeNodeSidebarRightClick.name })
                    : this.$t("zimbraNokey.zimbra_sidebar_dialogMoveFolder_title1");
            } else if (type == FOLDER_EXTRA_ACTION.MOVE_MAIL) {
                this.formMove.isMoveFolder = false;
                this.formMove.title = this.$t("zimbra.ztMsg.convMove");
            } else {
                this.formMove.isMoveFolder = false;
                this.formMove.title = this.$t("zimbra.zmMsg.chooseFolder");
            }
            this.moveFolderSearch = type == FOLDER_EXTRA_ACTION.MOVE_FOLDER_SEARCH;
            this.dialogMoveFolder = true;
            this.formMove.parent =
                this.$store.getters.activeNodeSidebarRightClick;
            if (this.$refs.treeFolderExtra) {
                this.$refs.treeFolderExtra.filterText = "";
            }
            if (type == FOLDER_EXTRA_ACTION.CHANGE_FOLDER_ARCHIVE) {
                this.isChangeFolderArchive = true;
                setTimeout(() => {
                    this.$root.$emit("setCurrentKey", this.folderArchive.id);
                }, 200)
            } else {
                setTimeout(() => {
                    if (!_.isNil(this.activeFolderExtra)) {
                        this.$root.$emit("setCurrentKey", this.activeFolderExtra.id);
                    } else {
                        this.$root.$emit("setCurrentKey", this.activeNodeSidebar?.id);
                    }
                }, 200)
            }
            const { name } = this.$route;
            if (name == READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE) {
                this.isMessage = true
            } else {
                this.isMessage = false
            }
        },
        checkExistsName(name, folderParent) {
            if (Array.isArray(folderParent.folder)) {
                if (
                    folderParent.folder
                        .map((x) => x.name.toString().toLowerCase())
                        .indexOf(name.toString().toLowerCase()) > -1
                ) {
                    this.$alert(
                        this.$t("zimbra.zmMsg.folderAlreadyExistsInDestination", {
                            name: name,
                        }),
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                    return true;
                }
            }
        },
        moveFolder() {
            if (this.$store.getters.activeFolderExtra) {
                this.treeNodeMove = this.$store.getters.activeFolderExtra;
                if (this.checkExistsName(this.formMove.parent.name, this.treeNodeMove)) {
                    return;
                }
                const form = {
                    folder: `<urn1:action id="${this.formMove.parent.id}" op="move" l="${this.treeNodeMove.id}"></urn1:action>`,
                };
                this.listIdChild = [];
                this.getListIdChild(this.formMove.parent);
                if (this.listIdChild.indexOf(this.treeNodeMove.id) > -1) {
                    this.$alert(
                        this.$t("zimbraNokey.zimbra_sidebar_messageCriticalMoveFolder"),
                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                        {
                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                            type: "error",
                        }
                    );
                } else {
                    ZimbraMailService.folderActionRequest(form).then((res) => {
                        if (res.status == STATUS_REQUEST.SUCCESS) {
                            this.dialogMoveFolder = false;
                            this.$root.$emit(
                                "deleteItemToDataInBox",
                                this.formMove.parent
                            );
                            this.$root.$emit(
                                "deleteItemToDataExtend",
                                this.formMove.parent
                            );
                            this.idUndo = this.formMove.parent.l;
                            this.formMove.parent.l = this.treeNodeMove.id;
                            this.buildAbsFolderPath(this.treeNodeMove, this.formMove.parent);
                            this.$root.$emit(
                                "addItemToDataInBox",
                                this.formMove.parent
                            );
                            this.$root.$emit(
                                "addItemToDataExtend",
                                this.formMove.parent
                            );
                            const msg = this.type === FOLDER_EXTRA_ACTION.MOVE_FOLDER_SEARCH
                                ? this.$t("zimbraNokey.zimbra_sidebar_messageSuccessMoveFolderSearch", { name: this.formMove.parent.name, name1: this.treeNodeMove.name })
                                : this.$t("zimbraNokey.zimbra_sidebar_messageSuccessMoveFolder", { name: this.formMove.parent.name, name1: this.treeNodeMove.name })
                            // this.$notify({
                            //     title: this.$t( "zimbra.zmMsg.dataSourceTestSuccess"),
                            //     message: msg,
                            //     type: "success",
                            // });
                            // Nếu là chuyển của lượt tìm kiếm thì thực hiện tìm kiếm lại folder và dừng luồng
                            if (this.activeNodeSidebarRightClick && this.activeNodeSidebarRightClick.types == FOLDER_VIEW.APPOINTMENT) {
                                this.$root.$emit("onUpdateMoveCalSearchFolder", this.activeNodeSidebarRightClick, this.formMove)
                            } else if (this.activeNodeSidebarRightClick && this.activeNodeSidebarRightClick.types == FOLDER_VIEW.CONTACT) {
                                this.$root.$emit("onUpdateMoveContactSearchFolder", this.activeNodeSidebarRightClick, this.formMove)
                            }
                            //
                            const uuid = this.generateUniqueId();
                            if (this.isMessage) {
                                this.notify = this.$message({
                                    type: "success",
                                    customClass: 'zm-notify',
                                    duration: 3500,
                                    dangerouslyUseHTMLString: true,
                                    message: `<div> ${msg} <span class="zm-content__title">
                                            <a class="zm-btn-undo" id="undo_${uuid}" href="javascript:;"}>
                                                ${this.$t("zimbra.zmMsg.undo")}
                                            </a>
                                        </span>
                                    </div>`
                                });
                            } else {
                                this.notify = this.$notify({
                                    dangerouslyUseHTMLString: true,
                                    message: `<div> ${msg} <span style="margin-left: 5px;padding-left: 7px;padding-right: 7px;padding-top: 1px;padding-button: 1px;border: 1px solid white;"> <a id="undo_${uuid}" style="" href="javascript:;"}> ${this.$t(
                                        "zimbra.zmMsg.undo"
                                    )}</a></span></div>`,
                                    position: "bottom-left",
                                    customClass: "notify-wrap",
                                    duration: 4000,
                                });
                            }

                            var x = document.getElementById(`undo_${uuid}`);
                            x.addEventListener("click", this.undo);
                        }
                    })
                        .catch((error) => {
                            const { detail } = this.getResponseBody(error.response)[
                                "soap:Fault"
                            ];
                            if (detail.Error.Code) {
                                if (detail.Error.Code == "mail.ALREADY_EXISTS") {
                                    this.$alert(
                                        this.$t("zimbra.zmMsg.folderAlreadyExistsInDestination"),
                                        this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                                        {
                                            confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                                            type: "error",
                                        }
                                    );
                                }
                            }
                        });
                }
            } else {
                this.$confirm(
                    this.$t("zimbra.zmMsg.noTargetFolder"),
                    this.$t("zimbraNokey.zimbra_common_criticalMsg"),
                    {
                        showCancelButton: false,
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "error",
                    }
                ).then(() => { })
            }
        },
        buildAbsFolderPath(parent, sub) {
            sub.absFolderPath = parent.absFolderPath + "/" + sub.name;
            if (sub.folder) {
                sub.folder.forEach(x => this.buildAbsFolderPath(sub, x));
            }
        },
        undo() {
            this.notify.close();
            const form = {
                folder: `<urn1:action id="${this.formMove.parent.id}" op="move" l="${this.idUndo}"></urn1:action>`,
            };
            ZimbraMailService.folderActionRequest(form).then((res) => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    this.dialogMoveFolder = false;
                    const parent = this.findFolderById(this.idUndo, this.dataSidebarInBox[0].folder) ||
                        this.findFolderById(this.idUndo, this.dataSidebarExtend[0].folder) || [];
                    this.buildAbsFolderPath(parent, this.formMove.parent);
                    this.$root.$emit("deleteItemToDataExtend", this.formMove.parent);
                    this.$root.$emit("deleteItemToDataInBox", this.formMove.parent);
                    this.formMove.parent.l = this.idUndo;
                    this.$root.$emit("addItemToDataInBox", this.formMove.parent);
                    this.$root.$emit("addItemToDataExtend", this.formMove.parent);
                    if (this.activeNodeSidebarRightClick.types == FOLDER_VIEW.APPOINTMENT) {
                        this.$root.$emit("onUpdateMoveCalSearchFolder", this.activeNodeSidebarRightClick, this.formMove)
                    } else if (this.activeNodeSidebarRightClick.types == FOLDER_VIEW.CONTACT) {
                        this.$root.$emit("onUpdateMoveContactSearchFolder", this.activeNodeSidebarRightClick, this.formMove)
                    }
                    const msg = this.type === FOLDER_EXTRA_ACTION.MOVE_FOLDER_SEARCH
                        ? this.$t("zimbraNokey.zimbra_sidebar_messageSuccessMoveFolderSearch", { name: this.formMove.parent.name, name1: parent.name })
                        : this.$t("zimbraNokey.zimbra_sidebar_messageSuccessMoveFolder", { name: this.formMove.parent.name, name1: parent.name })
                    if (this.isMessage) {
                        this.$message({
                            type: "success",
                            customClass: 'zm-notify',
                            duration: 3500,
                            dangerouslyUseHTMLString: true,
                            message: msg
                        });
                    } else {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: msg,
                            type: "success",
                        });
                    }
                }
            });
        },
        findFolderById(id, listFolder) {
            if (this.type != FOLDER_EXTRA_ACTION.MOVE_FOLDER_SEARCH && id == FOLDER_DEFAULT.ROOT) {
                return this.dataSidebarInBox[0];
            }
            for (let index in listFolder) {
                if (listFolder[index].id == id) {
                    return listFolder[index];
                } else if (listFolder[index].folder) {
                    const result = this.findFolderById(id, listFolder[index].folder);
                    if (result != null) {
                        return result;
                    }
                }
            }
            return null;
        },
        getListIdChild(data) {
            this.listIdChild.push(data.id);
            if (data.folder) {
                for (var item in data.folder) {
                    this.getListIdChild(data.folder[item]);
                }
            } else {
                for (var idx in data.search) {
                    this.getListIdChild(data.search[idx]);
                }
            }
        },
        validateArchive() {
            const idIgnore = [FOLDER_DEFAULT.SENT, FOLDER_DEFAULT.JUNK, FOLDER_DEFAULT.TRASH, FOLDER_DEFAULT.ROOT];
            if (idIgnore.includes(this.activeFolderExtra.id) ||
                this.activeFolderExtra.absFolderPath?.startsWith("/Trash/") ||
                this.activeFolderExtra.absFolderPath?.startsWith("/Sent/")) {
                this.$confirm(
                    this.$t("zimbraNokey.zimbra_sidebar_invalidArchiveFolder"),
                    this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                    {
                        showCancelButton: false,
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "warning",
                    }
                )
                    .then(() => { })
                    .catch(() => { });
                return false;
            }
            return true;
        },
        async moveOrArchiveMail() {
            if (this.activeFolderExtra) {
                if (this.type == FOLDER_EXTRA_ACTION.ARCHIVE_MAIL || this.isChangeFolderArchive) {
                    if (!this.validateArchive()) {
                        return;
                    }
                    const formSetFolderArchive = {
                        section: "zwc:archiveZimlet",
                        a: [
                            {
                                n: "hideDeleteButton",
                                content: "false"
                            },
                            {
                                n: "showSendAndArchive",
                                content: "false"
                            },
                            {
                                n: "archivedFolder",
                                content: `${this.activeFolderExtra.id}`
                            }
                        ],
                    };
                    await ZimbraMailService.setMailboxMetadataRequest(formSetFolderArchive);
                    const folderArchive = _.cloneDeep(this.activeFolderExtra);
                    folderArchive.hideDeleteButton = false;
                    folderArchive.showSendAndArchive = false;
                    this.$store.commit("SET_FOLDER_ARCHIVE", folderArchive);
                    this.dialogMoveFolder = false;
                }
                if (!this.isChangeFolderArchive) {
                    this.$root.$emit('handleNavbarAction', EMAIL_ACTION.MOVE);
                }
            } else {
                this.$confirm(
                    this.$t("zimbra.zmMsg.noTargetFolder"),
                    this.$t("zimbraNokey.zimbra_common_criticalMsg"),
                    {
                        showCancelButton: false,
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "error",
                    }
                ).then(() => { })
            }


        },
        closeDialog() {
            this.dialogMoveFolder = false;
        },
        onOpenDialog() {
            setTimeout(() => {
                this.$refs.treeFolderExtra.setFocusInPut();
            }, 200);
            this.$nextTick(() => {
                this.$refs.treeFolderSearch && this.$refs.treeFolderSearch.setFocus();
            })
            // setTimeout(() => {
            //     this.$refs.treeFolderSearch.setFocus();
            // }, 200);
        },

        /*
        * Hàm lấy danh sách lượt tìm kiếm
        */

        getFolderSearch() {
            const path = "/mail/contacts";
            let calChildrenSearch = []
            if (this.$route.path == path) {
                calChildrenSearch = this.folderSearches[0]?.search ? this.folderSearches[0].search.filter(x => x.types == FOLDER_VIEW.CONTACT) : []
            } else {
                calChildrenSearch = this.folderSearches[0]?.search ? this.folderSearches[0].search.filter(x => x.types == FOLDER_VIEW.APPOINTMENT) : []
            }
            return this.treeFolderSearchData = [
                {
                    ...this.folderSearches[0],
                    search: calChildrenSearch
                }
            ];
        },

        /*
        * Hàm tìm folder search theo id
        */
        findFolderSearchById(id, listFolder) {
            for (let index in listFolder) {
                if (listFolder[index].id == id) {
                    return listFolder[index];
                } else if (listFolder[index].search) {
                    const result = this.findFolderSearchById(id, listFolder[index].search);
                    if (result != null) {
                        return result;
                    }
                }
            }
            return null;
        }
    },
};
</script>
