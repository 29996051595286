<template>
  <perfect-scrollbar class="zm-info-schedules-scroll">
      <div v-for="(item, i) in schedules" :key="i">
          <div>
              <div class="head d-flex justify-content-between">
                  <span>
                      <i class="calendar-icon" style="padding-right: 18px; margin-right: 5px;"></i>
                      <b> {{item.name}} </b>
                      <i :class="['is-recur ic', item.recurClass]"></i>
                  </span>
                  <span>
                      <i v-if="item.otherAtt" class="fas fa-users"></i>
                  </span>
              </div>
              <div>
                  <table>
                      <!-- Người tổ chức -->
                      <tr v-if="!item.isOrg && item.or && item.or.a">
                          <td class="zm-td-label text-right">
                              <b>{{ $t("zimbra.zhMsg.organizerLabel") }}</b>
                          </td>
                          <td class="zm-td-content">
                              {{ item.or.d ||item.or.a }}
                          </td>
                      </tr>
                      <!-- Trạng thái của tôi -->
                      <tr v-if="!item.draft && !item.neverSent && item.method != 'PUBLISH'">
                          <td class="zm-td-label text-right">
                              <b>{{ $t("zimbra.zmMsg.myStatusLabel") }}</b>
                          </td>
                          <td class="zm-td-content">
                              {{ item.ptstText }}
                          </td>
                      </tr>
                      <!-- Thời gian -->
                      <tr v-if="item.inst">
                          <td class="zm-td-label text-right">
                              <b>{{ $t("zimbra.zmMsg.timeLabel") }}</b>
                          </td>
                          <td class="zm-td-content d-flex">
                              <ConvertDateToText
                                    :time="item.inst.s"
                                    key="1"
                                />
                                {{ $t("zimbra.zmMsg.calendarWorkHoursDelimiter") }}
                                <ConvertDateToText
                                    :time="
                                        item.inst.s +
                                        item.dur
                                    "
                                    :showOnlyTime="true"
                                    key="2"
                                />
                          </td>
                      </tr>
                      <!-- Địa điểm -->
                      <tr v-if="item.loc">
                          <td class="zm-td-label text-right">
                              <b>{{ $t("zimbra.zhMsg.locationLabel") }}</b>
                          </td>
                          <td class="zm-td-content">
                              {{ item.loc }}
                          </td>
                      </tr>
                      <!-- Hiển thị -->
                      <tr v-if="item.fb">
                          <td class="zm-td-label text-right">
                              <b>{{ $t("zimbra.zmMsg.displayLabel") }}</b>
                          </td>
                          <td class="zm-td-content">
                              {{ item.fbText }}
                          </td>
                      </tr>
                      <!-- Ghi chú -->
                      <tr v-if="item.fr">
                          <td class="zm-td-label text-right">
                              <b>{{ $t("zimbra.zmMsg.notesLabel") }}</b>
                          </td>
                          <td class="zm-td-content">
                              {{ item.fr }}
                          </td>
                      </tr>
                  </table>
              </div>
              <!-- Người tham dự -->
            <template v-if="item.isOrg && item.atGroup && item.atGroup.length > 0 && !item.draft && !item.neverSent">
                <div class="head pl-3">
                    <b>{{ $t("zimbra.zhMsg.attendee") }}</b>
                </div>
                <div>
                    <table>
                      <tr v-for="(group, idx) in item.atGroup" :key="idx">
                          <td class="zm-td-label text-right">
                              <b>{{ group.ptstText }} ( {{ group.count}} ):</b>
                          </td>
                          <td class="zm-td-content">
                              {{ group.atStr }}
                          </td>
                      </tr>
                    </table>
                </div>
            </template>
          </div>
      </div>
  </perfect-scrollbar>
</template>
<script>
/**
 * Popover hiển thị thông tin lịch trình
 * khi hover vào một khung thời gian trên "Bảng lịch trình"
 */
import CommonUltils from "@/utils/common-utils";
import CalendarUtils from "@/utils/calendar-utils";
import ConvertDateToText from "@/components/modules/calendar/components/warning-calendar/convert-date-to-text.vue";
export default {
    name: "PopoverInfoSchedule",
    mixins: [CommonUltils, CalendarUtils],
    props: {
        schedules: {
            type: Array,
            default: function() {
                return [];
            }
        },
    },
    components: {
        ConvertDateToText
    },
    data() {
        return {

        };
    },
    computed: {
    },
    methods: {
    }
}
</script>