<template>
    <div class="card-filter-row">
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="id"
                :value="valueMainSelect"
                :options="mainSelect"
                @change="handleChangeMainSelect"
            />
        </div>
        <div class="card-filter-item"></div>
        <div class="card-filter-item">
            <base-select
                displayField="name"
                valueField="mineHeaderCompartison"
                :value="valueMineHeaderCompartison"
                :options="mineHeaderCompartison"
                @change="hanldeChangeMineHeaderCompartison"
            />
        </div>
        <div class="card-filter-item"></div>
        <div class="card-filter-item"></div>
    </div>
</template>

<script>
import { FILTER_TEST_CONFIG } from "@/utils/constants";
import CoreTest from "@/components/modules/base/base-filter-form/core-test";
import BaseSelect from "@/components/modules/base/base-select";
export default {
    extends: CoreTest,
    components: {
        BaseSelect,
    },
    created() {
        this.beforeBuildDataForm();
        this.conditionsMimeHeaderTest = this.conditionsTest;
    },
    watch: {
        conditionsTest(newData) {
            this.beforeBuildDataForm();
            this.conditionsMimeHeaderTest = newData;
        },
    },
    data() {
        return {
            FILTER_TEST_CONFIG: FILTER_TEST_CONFIG,
            mineHeaderCompartison: [
                {
                    mineHeaderCompartison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.EXIST,
                    name: this.$t(
                        "zimbra.zmMsg.exists"
                    ),
                },
                {
                    mineHeaderCompartison:
                        FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_EXIST,
                    name: this.$t(
                        "zimbra.zmMsg.notExist"
                    ),
                },
            ],
            valueMainSelect: "",
            valueMineHeaderCompartison: "",
            conditionsMimeHeaderTest: {},
        };
    },
    methods: {
        beforeBuildDataForm() {
            // SET-VALUE-MAIN-SELECT
            this.valueMainSelect = this.mainSelect[10].id;
            //SET-VALUE-MINE-HEADER-COMPARISON
            this.valueMineHeaderCompartison = this.conditionsTest.negative
                ? FILTER_TEST_CONFIG.STRING_COMPARTION.NOT_EXIST
                : FILTER_TEST_CONFIG.STRING_COMPARTION.EXIST;
        },
        hanldeChangeMineHeaderCompartison(mineHeaderCompartison) {
            for (let i in this.conditionsMimeHeaderTest) {
                if (
                    i !== "negative" &&
                    this.validateNegative(mineHeaderCompartison)
                ) {
                    this.conditionsMimeHeaderTest = {
                        ...this.conditionsMimeHeaderTest,
                        negative: FILTER_TEST_CONFIG.NEGATIVE,
                    };
                } else {
                    if (!this.validateNegative(mineHeaderCompartison)) {
                        delete this.conditionsMimeHeaderTest.negative;
                    }
                }
            }
            this.$emit("onChangeConditionsTest", this.conditionsMimeHeaderTest);
        },
    },
};
</script>