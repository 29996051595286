<template>
    <el-header class="navbar-theme-2" height="50px">
        <router-link to="/mail/v-email">
            <img src="@/assets/images/login/logo_vmail.svg" class="logo-vmail" style="height: 30px;width: 145px"/>
        </router-link>
        <div class="d-flex justify-content-between form-search">
            <base-select displayField="name" class="vmail-select-search" popperClass="vmail-search"
                         :class="isSearch ? 'show ' : ''" valueField="query" @change="changeValueSearch"
                         :value="valueMainSelect"
                         :options="mainSelect" :renderSideI18N="false">
            </base-select>
            <el-input class="vmail-input-search" ref="inputSearch" id="vmail-input-search"
                      @keyup.enter.native="handleSearchKeyword()" @keyup.space.native="changeKeyWordSearch()"
                      @focus="handleToggleInputSearch(true)" @blur="e =>handleToggleInputSearch(false, e)"
                      v-model="inputSearch">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                <label v-if="!isSearch" slot="prefix">{{ $t("zimbra.zhMsg.search") }}</label>
                <el-popover v-if="isSearch" slot="suffix" v-model="visibleDialogSearchBox" placement="bottom"
                            trigger="click">
                    <DialogSearchBox v-if="visibleDialogSearchBox" v-model="modelSearch"
                                     @onclose="visibleDialogSearchBox = false" @onsearch="handleSearch" />
                    <a slot="reference" href="javascript:;" class="btn-search-advanced focusable">
                        <el-tooltip class="item" id="icon-search-advance" effect="dark"
                                    :content="$t('zimbra.zmMsg.filter')"
                                    placement="top-start">
                            <img src="@/assets/zimbra/images/ic-filter.svg" alt="" />
                        </el-tooltip>
                    </a>
                </el-popover>
                <div v-if="isSearch" @click="handleSearchKeyword()" slot="suffix">
                    <el-tooltip class="item" effect="dark" :content="$t('zimbra.zhMsg.search')" placement="top-start">
                        <i class="el-input__icon el-icon-right"></i>
                    </el-tooltip>
                </div>
            </el-input>

        </div>
        <div>
            <el-popover placement="left-start" width="200" trigger="click">
                <ul class="zm-user-info-popover">
                    <li>
                        <strong><i>{{ displayName }}</i></strong>
                    </li>
                    <li class="dc-line"></li>
                    <li>
                        <a href="javascript:;" @click="handleLogout">
                            <i class="fi fi-rr-sign-out pr-2 pt-1"></i>
                            <span>{{ $t("zimbra.zhMsg.ALT_APP_LINK_LOGOFF") }}</span>
                        </a>
                    </li>
                </ul>
                <span slot="reference" class="zimbra-cursor-pointer">
                    <BaseAvatar :listEmailAddress="avatarUserLogin" />
                    <!-- <span v-if="avatar != null" class="avatar-short">{{avatar}}</span>
                <span v-if="avatar == null" class="no-avatar-short"></span> -->
                </span>
            </el-popover>
        </div>
    </el-header>
</template>

<script>
import BaseSelect from "@/components/modules/base/base-select";
import DialogSearchBox from "@/components/modules/v-mail/components/DialogSearchBox.vue";
import BaseAvatar from "@/components/modules/base/base-avatar/base-avatar.vue";
import { mapGetters, mapActions } from "vuex";
import CommonUtils from "@/utils/common-utils";
import _ from "lodash";
import hotkeys from "hotkeys-js";

export default {
    mixins: [CommonUtils],
    data() {
        return {
            mainSelect: [
                {
                    id: 1,
                    name: "zimbra.zhMsg.all",
                    query: ""
                },
                {
                    id: 2,
                    name: "zimbra.systemFolder.folderInbox",
                    query: "in:inbox"
                },
                {
                    id: 3,
                    name: "zimbra.systemFolder.folderSent",
                    query: "in:sent"
                },
                {
                    id: 4,
                    name: "zimbra.systemFolder.folderJunk",
                    query: "in:junk"
                },
                {
                    id: 5,
                    name: "zimbra.systemFolder.folderStars",
                    query: "is:flagged"
                }
            ],
            valueMainSelect: "",
            inputSearch: "",
            isSearch: false,
            visibleDialogSearchBox: false
        };
    },
    components: {
        BaseSelect,
        BaseAvatar,
        DialogSearchBox
    },
    computed: {
        ...mapGetters([
            "attributes",
            "emailAddress",
            "listFileContacts",
            "modelSearch",
            "showSearchStringCurrent",
            "mailInitialSearch"
        ]),
        displayName() {
            return this.attributes ? (this.attributes.displayName ? this.attributes.displayName : this.emailAddress) : "";
        },
        avatarUserLogin() {
            return [{
                a: this.emailAddress
            }];
        },
        
    },
    watch: {
        "showSearchStringCurrent.content": {
            immediate: true,
            handler(val) {
                if (val) {
                    const defaultKeySearch = this.mailInitialSearch?.content;
                    this.hasTheWords = defaultKeySearch;
                    this.inputSearch = defaultKeySearch;
                    this.isSearch = true;
                }
            }
        },
       
    },
    async mounted() {
        const a = document.getElementById("app");

        // a.addEventListener("click", this.clickInput);

        hotkeys("/", this.handleFocus);
        this.$root.$on("handleCloseSearchBox", this.handleCloseSearchBox);
        this.$root.$on("setHasTheWords", (content) => {
            if (!this.isSearch) {
                this.isSearch = true;
            }
            // this.inputSearch = content;
        });
    },
    methods: {
        ...mapActions(["getAllGeneral"]),
        clickInput(e) {
            const listPath = e.path;
            this.isSearch = listPath?.some(item => item._prevClass == "d-flex justify-content-between form-search");
            this.isSearch = this.inputSearch.length == 0 ? this.isSearch : true;
            if (this.isSearch && this.inputSearch.length == 0) {
                this.$refs.inputSearch.focus();
            }
        },
        handleLogout() {
            this._logout();
        },
        handleSearch(data) {
            this.visibleDialogSearchBox = false;
            const listQuery = [];
            const paramAgrs = {};
            if (data.tocc) {
                listQuery.push(`tocc:${data.tocc}`);
            }
            if (data.from) {
                listQuery.push(`from:${data.from}`);
            }
            if (data.fromOrToContact && data.fromOrToContact.length) {
                const _type = data.type;
                data.fromOrToContact.forEach((email, index) => {
                    if (index > 0) listQuery.push(`OR ${_type}:${email}`);
                    else listQuery.push(`${_type}:${email}`);
                });
                if (data.header) paramAgrs["header"] = data.header;
                if (data.sortBy) paramAgrs["sortBy"] = data.sortBy;
                if (data.tz) paramAgrs["tz"] = data.tz;
                if (data.locale) paramAgrs["locale"] = data.locale;
                if (data.recip) paramAgrs["recip"] = data.recip;
            }
            if (data.to.length) {
                // listQuery.push(`to:${data.to}`);
                data.to.forEach(t => {
                    listQuery.push(`to:${t}`)
                })
            }
            if (data.subject) {
                listQuery.push(`subject:${data.subject}`);
            }
            if (data.hasTheWords) { /// tách các từ khi tìm kiếm
                data.hasTheWords?.trim().split(" ").forEach((str) => {
                    if (str.trim().length > 0) {
                        listQuery.push(`${str.trim()}`);
                    }
                });
            }
            if (data.doesNotHave) {
                data.doesNotHave?.trim().split(" ").forEach((str) => {
                    if (str.trim().length > 0) {
                        listQuery.push(`(not ${str.trim()})`);
                    }
                });
            }
            if (data.hasAttachment) {
                listQuery.push(`has:attachment`);
            }
            if (data.size && data.sizeAttach && data.sizeUnit) {
                listQuery.push(
                    `${data.size}:${data.sizeAttach}${data.sizeUnit}`
                );
            }
            if (data.time && data.date) {
                listQuery.push(`${data.time}:${data.date}`);
            }
            if (data.searchIn) {
                if (data.searchIn.absFolderPath === "/Flagged") {
                    listQuery.push(`is:flagged`);
                } else {
                    listQuery.push(`in:"${data.searchIn.absFolderPath}"`);
                }
            }
            if (this.inputSearch) {
                listQuery.push(this.inputSearch);
            }
            const query = listQuery.map((e) => _.escape(e)).join(" ");
            this.$store.commit("SET_LIST_QUERY", listQuery);
            this.$store.commit("SET_LIST_QUERY_TEMP", listQuery);
            this.$store.commit("SET_SHOW_SEARCH_BOX", true);
            
            this.$store.commit("SET_MODEL_SEARCH", data);
            this.$root.$emit("handleSearchBox", query, paramAgrs);
        },
        handleSearchKeyword() {
            const _listQuery = [];
            if (this.valueMainSelect != "") {
                _listQuery.push(this.valueMainSelect);
            }
            const _listStrQuery = this.inputSearch?.trim().split(" ");
            _listStrQuery.forEach((str) => {
                if (str.trim().length > 0) {
                    // _listQuery.push(`"${str.trim()}"`);
                    _listQuery.push(`${str.trim()}`); // sửa bỏ dấu nháy kép thừa
                }
            });

            this.$root.$emit("handleSearchKeyWord", _listQuery.join(" "));
            this.$store.commit("SET_LIST_QUERY_TEMP", _listQuery);
            this.inputSearch = "";
        },
        changeKeyWordSearch() {
            // setTimeout(() => {
            //     let transformText = "";
            //     const regex = /^[a-zA-Z]+$/;
            //     const arrKeyWord = this.inputSearch.split(" ");
            //     arrKeyWord.forEach(x => {
            //             if (!regex.test(x) && !this.isContainsBlock(x) && x) {
            //                 transformText = transformText + `"${x}"` + " ";
            //             } else {
            //                 transformText = transformText + x + " ";
            //             }
            //         }
            //     );
            //     this.inputSearch = transformText.replaceAll("  ", " ");
            // }, 200);
        },
        isContainsBlock(keyStr) {
            if (!keyStr || keyStr.length < 2) {
                return false;
            }
            if (keyStr[0] == "\"" && keyStr[keyStr.length - 1] == "\"") {
                return true;
            }
            return false;
        },
        changeValueSearch(value) {
            this.valueMainSelect = value;
            this.$nextTick(() => {
                this.$refs.inputSearch && this.$refs.inputSearch.focus();
            });
        },
        handleFocus(e) {
            e.preventDefault();
            setTimeout(() => {
                this.isSearch = true;
                this.$refs.inputSearch.focus();
            }, 200);
        },
        handleCloseSearchBox() {
            // this.inputSearch = this.mailInitialSearch.content;
        },
        handleToggleInputSearch(isFocus = true, event) {
            if (event?.relatedTarget?.id === "icon-search-advance") return;
            if (this.inputSearch) {
                return;
            }

            setTimeout(() => {
                this.isSearch = isFocus;
                this.$forceUpdate();
            }, 150);
        }

    },
    destroyed() {
        this.$root.$off("handleCloseSearchBox", this.handleCloseSearchBox);
    }
};
</script>

<style></style>
