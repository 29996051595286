<template>
    <!-- TREE ZIMLET -->
    <el-tree
            ref="vuetreeZimlet"
            class="tree-zimlet tree-wrap"
            style="margin-top: 10px"
            node-key="id"
            id="treeZimlet"
            :data="data"
            :props="defaultProps"
            @node-click="nodeClickZimlet"
            @node-contextmenu="rightClickZimlet"
            :allow-drop="allowDrop"
            :allow-drag="allowDrag"
            :expand-on-click-node="false"
            :default-expanded-keys="defaultExpandZimlet"
        >
            <span class="custom-tree-node" slot-scope="{ node, data }">
                <el-popover
                    popper-class="zm-tooltip zm-tooltip-keep-word"
                    placement="top-start"
                    trigger="hover"
                    :open-delay="1000"
                    :content="setTooltipByTypeZimlet(data)"
                    :disabled="data.id == -1 || !showInfoFolder"
                >
                    <div slot="reference"><span class="wrap-zimlet">
                        <span>
                            <i v-if="data.id == -1"
                                class="ic-3d-cube ic-viewable"
                            ></i>
                        </span>
                        <span>
                            <i v-if="data.data == SETTING_ZIMLET.YMEMOTIONS"
                                class="ic-yahoo-2 ic-viewable"
                            ></i>
                        </span>
                        <span>
                            <i v-if="data.data == SETTING_ZIMLET.MAIL_ARCHIVE"
                                class="ic-archire-2 ic-viewable"
                            ></i>
                        </span>
                        <span>
                            <i v-if="data.data == SETTING_ZIMLET.WEBEX"
                                class="ic-webex-2 ic-viewable"
                            ></i>
                        </span>
                        <span>{{ node.label }}</span>
                    </span></div>
                  </el-popover>
            </span>
    </el-tree>
</template>
<script>
import ZimbraService from "@/services/ZimbraService";
import CommonUtils from "@/utils/common-utils";
import EmailUtils from "@/utils/email-utils";
import CalendarUtils from "@/utils/calendar-utils";
import { mapGetters, mapActions } from "vuex";
import {
    FOLDER_DEFAULT,
    SETTING_ZIMLET,
    CALENDAR_RIGHT_MENU_TYPE
} from "@/utils/constants";
export default {
    mixins: [CommonUtils, EmailUtils, CalendarUtils],
    props: {
        highlightDefault: {
            type: Boolean,
            default: false,
        },
        showInfoFolder: {
            type: Boolean,
            default: false
        },
        disableTooltip: {
            type: Boolean,
            default: false
        },
    },
    components: {
    },
    data() {
        return {
            defaultProps: {
                children: "folder",
                label: "name",
                label2: "shortName",
            },
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            SETTING_ZIMLET: SETTING_ZIMLET,
            tableData: [
                {
                    data: "1",
                    content: "com_zimbra_ymemoticons",
                    value: "enabled",
                    name: this.$t("zimbraNokey.zimbra_setting_zimlet_yahoo_emoticons"),
                    description: this.$t(
                        "zimbraNokey.zimbra_setting_zimlet_yahoo_emoticons_desc"
                    ),
                },
                {
                    data: "2",
                    content: "com_zimbra_mailarchive",
                    value: "enabled",
                    name: this.$t("zimbraNokey.zimbra_setting_zimlet_archive"),
                    description: this.$t("zimbraNokey.zimbra_setting_zimlet_archive_desc"),
                },
                {
                    data: "3",
                    content: "com_zextras_chat_open",
                    value: "enabled",
                    name: this.$t("zimbraNokey.zimbra_setting_zimlet_open_chat"),
                    description: this.$t(
                        "zimbraNokey.zimbra_setting_zimlet_open_chat_desc"
                    ),
                },
                {
                    data: "4",
                    content: "com_zimbra_srchhighlighter",
                    value: "enabled",
                    name: this.$t("zimbraNokey.zimbra_setting_zimlet_search_highlighter"),
                    description: this.$t(
                        "zimbraNokey.zimbra_setting_zimlet_search_highlighter_desc"
                    ),
                },
                {
                    data: "5",
                    content: "com_zimbra_webex",
                    value: "enabled",
                    name: this.$t("zimbra.zmWebExZimlet.label"),
                    description: this.$t("zimbra.zmWebExZimlet.description"),
                },
                {
                    data: "6",
                    content: "com_zextras_drive_open",
                    value: "enabled",
                    name: this.$t("zimbraNokey.zimbra_setting_zimlet_zimbra_drive"),
                    description: this.$t(
                        "zimbraNokey.zimbra_setting_zimlet_zimbra_drive_desc"
                    ),
                },
                {
                    data: "7",
                    content: "com_zimbra_phone",
                    value: "enabled",
                    name: this.$t("zimbra.zhMsg.AB_FIELD_homePhone"),
                    description: this.$t("zimbraNokey.zimbra_setting_zimlet_phone_desc"),
                },
            ],
            data: [],
            listCheckZimlet: [
                SETTING_ZIMLET.YMEMOTIONS,
                SETTING_ZIMLET.MAIL_ARCHIVE,
                SETTING_ZIMLET.WEBEX,
            ],
            node: {},
            defaultExpand: [FOLDER_DEFAULT.ROOT],
            defaultExpandTreeExtend: [-1],
            defaultExpandZimlet: [-1],
        };
    },
    mounted() {
        this.$root.$on("handleChangeZimlet", (data) => {
            this.data[0].folder = data.filter(
                (item) =>
                    this.listCheckZimlet.indexOf(item.data * 1) !==
                        SETTING_ZIMLET.UNDEFINED && item.value === "enabled"
            );
        });
        this.$root.$on("extendFolder", () => {
            this.node.expanded = true;
        });
        this.$root.$on("setCurrentKeyMainTree", (id) => {
            setTimeout(() => {
                this.$refs.vuetree && this.$refs.vuetree.setCurrentKey(id);
            }, 200);
        });
        this.$root.$on("actionSetActiveNode", ({nodeExtend, nodeId}) => {
            // Xự kiện set node active trên cây
            this.actionSetActiveNode(nodeExtend, nodeId);
        });
    },
    computed: {
        ...mapGetters([
            "dataTableCurrent",
        ]),
    },
    created() {
        this.getDataZimlet();
        if (this.highlightDefault) {
            setTimeout(() => {
                this.$refs.vuetree && this.$refs.vuetree.setCurrentKey(FOLDER_DEFAULT.INBOX);
            }, 1000);
        }
    },
    methods: {
        ...mapActions(["setTableDataCurrent"]),
        setTooltipByTypeZimlet(data) {
            let tooltip = "zimbra.setting.zimlet.";
            if (data.data == SETTING_ZIMLET.YMEMOTIONS) {
                tooltip += "yahoo_emoticons"
            } else if (data.data == SETTING_ZIMLET.MAIL_ARCHIVE) {
                tooltip += "archive_desc"
            } else if (data.data == SETTING_ZIMLET.WEBEX) {
                tooltip += "webEx_desc"
            } else {
                return null;
            }
            return this.$t(tooltip);
        },
        async getDataZimlet() {
            await ZimbraService.getInfoRequest("zimlets").then(async (resp) => {
                const response =
                    this.getResponseBody(resp)["GetInfoResponse"]["zimlets"][
                        "zimlet"
                    ];
                this.tableData = this.tableData.map((item) => {
                    if (item.content === "com_zimbra_ymemoticons") {
                        item.value = response.find(
                            (item) =>
                                item.zimlet.name === "com_zimbra_ymemoticons"
                        ).zimletContext.presence;
                    } else if (item.content === "com_zimbra_mailarchive") {
                        item.value = response.find(
                            (item) =>
                                item.zimlet.name === "com_zimbra_mailarchive"
                        ).zimletContext.presence;
                    } else if (item.content === "com_zimbra_srchhighlighter") {
                        item.value = response.find(
                            (item) =>
                                item.zimlet.name ===
                                "com_zimbra_srchhighlighter"
                        ).zimletContext.presence;
                    } else if (item.content === "com_zimbra_webex") {
                        item.value = response.find(
                            (item) => item.zimlet.name === "com_zimbra_webex"
                        ).zimletContext.presence;
                    } else if (item.content === "com_zimbra_phone") {
                        item.value = response.find(
                            (item) => item.zimlet.name === "com_zimbra_phone"
                        ).zimletContext.presence;
                    }
                    return item;
                });
            });
            this.setTableDataCurrent(this.tableData.slice(0));
            this.data = [
                {
                    id: -1,
                    folder: [],
                    name: this.$t("zimbraNokey.zimbra_setting_zimlet_title"),
                },
            ];
            this.data[0].folder = this.dataTableCurrent.filter(
                (item) =>
                    this.listCheckZimlet.indexOf(item.data * 1) !==
                        SETTING_ZIMLET.UNDEFINED && item.value === "enabled"
            );
        },
        nodeClick(data) {
            this.$refs.vuetree && this.$refs.vuetree.setCurrentKey(null);
            this.$refs.vuetreeExtend &&
                this.$refs.vuetreeExtend.setCurrentKey(null);
            this.$refs.vuetree &&
                this.$refs.vuetree.setCurrentKey(data.id || 0);
            this.$refs.vuetreeExtend &&
                this.$refs.vuetreeExtend.setCurrentKey(data.id || 0);
            if (data.id == FOLDER_DEFAULT.TAG || data.l == FOLDER_DEFAULT.TAG) {
                this.$store.commit("SET_ACTIVE_NODE_SIDEBAR", data);
            } else {
                this.$store.commit("SET_ACTIVE_NODE_SIDEBAR", data);
            }
            if (data.isLink && data.perm == undefined) {
                this.$confirm(
                    this.$t("zimbraNokey.zimbra_sidebar_warningRegain", {
                        name: data.name,
                    }),
                    this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                    {
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        cancelButtonText: this.$t("zimbra.zhMsg.actionTaskCancelTT"),
                        type: "warning",
                    }
                )
                    .then(() => {
                        this.$root.$emit("deleteFolder", data);
                    })
                    .catch(() => {});
            } else {
                this.$root.$emit("handleSelectNode", data);
            }
        },
        actionSetActiveNode(nodeExtend, nodeId) {
            if (nodeExtend) {
                this.$refs.vuetree && this.$refs.vuetree.setCurrentKey(null);
                this.$refs.vuetreeExtend &&
                    this.$refs.vuetreeExtend.setCurrentKey(nodeId);
                this.defaultExpandTreeExtend = [nodeId];
                this.defaultExpand = null;
            } else {
                this.$refs.vuetree && this.$refs.vuetree.setCurrentKey(nodeId);
                this.$refs.vuetreeExtend &&
                    this.$refs.vuetreeExtend.setCurrentKey(null);
                this.defaultExpand = [nodeId];
                this.defaultExpandTreeExtend = null;
            }
        },
        nodeClickZimlet(data) {
            this.$root.$emit("onRightClickCalendar", "", {}, {}, {});
            if (data.data == SETTING_ZIMLET.YMEMOTIONS) {
                this.$root.$emit("handleShowZimletYahoo", true);
            } else if (data.data == SETTING_ZIMLET.MAIL_ARCHIVE) {
                this.$root.$emit("handleShowZimletArchire", true);
            } else if (data.data == SETTING_ZIMLET.WEBEX) {
                this.$root.$emit("onOpenWebexPreferences")
            }
        },
        rightClickZimlet(e, data, node) {
            // console.log(e);
            // console.log(data);
            const type = CALENDAR_RIGHT_MENU_TYPE.WEBEX;
            const typeMail_Archive = CALENDAR_RIGHT_MENU_TYPE.MAIL_ARCHIVE;
            const typeMail_Ymemotions = CALENDAR_RIGHT_MENU_TYPE.YMEMOTIONS;
            if (data.data == SETTING_ZIMLET.WEBEX) {
                this.$root.$emit("onRightClickCalendar", type, e, {}, data)
            } else if(data.data == SETTING_ZIMLET.MAIL_ARCHIVE) {
                this.$root.$emit("onRightClickCalendar", typeMail_Archive, e, {}, data);
            } else if(data.data == SETTING_ZIMLET.YMEMOTIONS) {
                this.$root.$emit("onRightClickCalendar", typeMail_Ymemotions, e, {}, data);
            }
        },
        allowDrop(draggingNode, dropNode, type) {
            if (draggingNode.level === dropNode.level) {
                if (draggingNode.parent.id === dropNode.parent.id) {
                    return type === "prev" || type === "next";
                }
            } else {
                return false;
            }
        },
        allowDrag() {
            return true;
        },
    },
};
</script>
