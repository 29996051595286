<template>
    <div>
        <el-dialog class="dialog-body-wrap zimbra-dialog" :title="$t('zimbra.zmMsg.folderProperties')"
            :visible.sync="dialogEditFolder" width="600px" :close-on-click-modal="false" :append-to-body="true">
            <el-tabs v-model="activeName" type="card" @tab-click="clickTabFormEdit"
                :class='`${treeNodeData.isLink ? "hidden-tabs" : ""}`'>
                <el-tab-pane name="tabProperties" :label="$t('zimbra.zmMsg.folderTabProperties')">
                    <el-form :model="formEdit" class="form-wrap">
                        <el-row :gutter="20" style="margin-top: 2px">
                            <el-col :span="5" style="
                                    padding-right: 0px;
                                    padding-left: 10px;
                                    padding-top: 10px;
                                "><span>{{
                                    $t("zimbra.zhMsg.nameLabel")
                                    }}</span>
                            </el-col>
                            <el-col :span="15"><el-input class="el-text-control" :disabled="disableEditName"
                                    v-model="formEdit.name" autocomplete="off" size="medium"></el-input></el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="5" style="
                                    padding-right: 0px;
                                    padding-left: 10px;
                                    padding-top: 10px;
                                "><span>{{
                                    $t("zimbra.zmMsg.sharedCalTypeLabel")
                                    }}</span>
                            </el-col>
                            <el-col :span="19" style="padding-top: 10px">
                                <span>
                                    {{
                                        $t(
                                            "zimbra.zmMsg.mailFolder"
                                        )
                                    }}
                                </span>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" v-if="formEdit.owner">
                            <el-col :span="5" style="
                                    padding-right: 0px;
                                    padding-left: 10px;
                                    padding-top: 10px;
                                ">
                                <span>
                                    {{
                                        $t(
                                            "zimbra.zmMsg.ownerLabel"
                                        )
                                    }}
                                </span>
                            </el-col>
                            <el-col :span="19" style="padding-top: 10px"><span>{{ formEdit.owner }}</span></el-col>
                        </el-row>
                        <el-row :gutter="20" v-if="formEdit.operationRole">
                            <el-col :span="5" style="
                                    padding-right: 0px;
                                    padding-left: 10px;
                                    padding-top: 10px;
                                ">
                                <span>
                                    {{
                                        $t(
                                            "zimbra.zmMsg.permissions"
                                        )
                                    }}
                                </span>
                            </el-col>
                            <el-col :span="19" style="padding-top: 10px"><span>{{
                                formEdit.operationRole
                                    }}</span></el-col>
                        </el-row>
                        <el-row :gutter="20" v-if="formEdit.url">
                            <el-col :span="5" style="
                                    padding-right: 0px;
                                    padding-left: 10px;
                                    padding-top: 10px;
                                "><span>{{
                                    $t("zimbra.zhMsg.url")
                                    }}</span>
                            </el-col>
                            <el-col :span="19" style="padding-top: 10px">
                                <a :href="formEdit.url" style="color: #007bff">
                                    {{ formEdit.url }}
                                </a>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="5" style="
                                    padding-right: 0px;
                                    padding-left: 10px;
                                    padding-top: 17px;
                                ">
                                <span>{{
                                    $t("zimbra.zmMsg.colorLabel")
                                    }}</span>
                            </el-col>
                            <el-col :span="19" style="padding-top: 11px">
                                <template>
                                    <div>
                                        <zm-color-picker :class="`${disableEditColor ? 'noclick' : ''}`"
                                            v-model="formEdit.rgb" :visible="false" :showFooter="true"
                                            :disabled="disableEditColor"
                                            :default-colors="['#3F4254', '#0000FF', '#00FFFF', '#008000', '#FF00FF', '#FF0000', '#FFFF00', '#FF66FF', '#808080', '#FF9900']">
                                        </zm-color-picker>
                                    </div>
                                </template>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="5" style="
                                    padding-right: 0px;
                                    padding-left: 10px;
                                    padding-top: 10px;
                                ">
                                <span>
                                    {{
                                        $t(
                                            "zimbra.zmMsg.mailLabel"
                                        )
                                    }}
                                </span>
                            </el-col>
                            <el-col :span="19" style="padding-top: 10px">
                                <span>{{ formEdit.mailNumber }}</span>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" v-if="formEdit.mailUnread">
                            <el-col :span="5" style="
                                    padding-right: 0px;
                                    padding-left: 10px;
                                    padding-top: 10px;
                                ">
                                <span>
                                    {{
                                        $t(
                                            "zimbraNokey.zimbra_sidebar_dialogEditFolder_unRead"
                                        )
                                    }}
                                </span>
                            </el-col>
                            <el-col :span="19" style="padding-top: 10px"><span>{{ formEdit.mailUnread }}</span></el-col>
                        </el-row>
                        <el-row :gutter="20" v-if="formEdit.size != '0B'">
                            <el-col :span="5" style="
                                    padding-right: 0px;
                                    padding-left: 10px;
                                    padding-top: 10px;
                                ">
                                <span>
                                    {{
                                        $t(
                                            "zimbra.zmMsg.fSizeLabel"
                                        )
                                    }}
                                </span>
                            </el-col>
                            <el-col :span="19" style="padding-top: 10px">
                                <span>{{ formEdit.size }}</span>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane name="tabMaintain" label="Duy trì">
                    <el-form class="form-wrap">
                        <el-checkbox size="medium" v-model="formEdit.statusMaintain">
                            <span style="font-weight: bolder">{{
                                $t(
                                    "zimbra.zmMsg.messageRetentionEnable"
                                )
                            }}
                            </span>
                        </el-checkbox>
                        <br />
                        <span style="padding-left: 24px; display: block">
                            {{
                                $t(
                                    "zimbra.zmMsg.messageRetentionExplanation"
                                )
                            }}
                        </span>
                        <div style="
                                display: flex;
                                padding-top: 10px;
                                padding-bottom: 10px;
                            ">
                            <span style="
                                    padding-left: 35px;
                                    font-weight: 300;
                                    padding-right: 7px;
                                    padding-top: 4px;
                                    width: 144px;
                                ">
                                {{
                                    $t(
                                        "zimbra.zmMsg.messageRetentionRange"
                                    )
                                }}
                            </span>
                            <div style="width: 100px">
                                <el-select style="width: 100% !important" v-model="formEdit.optionMaintain" size="mini"
                                    :disabled="!formEdit.statusMaintain">
                                    <el-option :label="$t(
                                        'zimbra.zmMsg.AB_FIELD_custom'
                                    )
                                        " value="1">
                                    </el-option>
                                </el-select>
                            </div>
                            <div style="width: 100px" class="pl-2 pr-2">
                                <el-input class="el-text-control" :disabled="!formEdit.statusMaintain"
                                    v-model="formEdit.timeMaintain" size="mini"></el-input>
                            </div>
                            <div style="width: 100px">
                                <el-select v-model="formEdit.optionTimeMaintain" size="mini"
                                    :disabled="!formEdit.statusMaintain">
                                    <el-option v-for="item in optionsTime" :key="item.id" :label="item.label"
                                        :value="item.days"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <el-checkbox size="medium" v-model="formEdit.statusRemove">
                            <span style="font-weight: bolder">
                                {{
                                    $t(
                                        "zimbra.zmMsg.messageDisposalEnable"
                                    )
                                }}
                            </span>
                        </el-checkbox>
                        <br />
                        <span style="padding-left: 24px; display: block">
                            {{
                                $t(
                                    "zimbra.zmMsg.messageDisposalExplanation"
                                )
                            }}
                        </span>
                        <div style="display: flex; padding-top: 10px">
                            <span style="
                                    padding-left: 35px;
                                    font-weight: 300;
                                    padding-right: 7px;
                                    padding-top: 4px;
                                    width: 144px;
                                ">{{
                                    $t(
                                        "zimbra.zmMsg.messageDisposalThreshold"
                                    )
                                }}</span>
                            <div style="width: 100px">
                                <el-select style="width: 100% !important" v-model="formEdit.optionRemove" size="mini"
                                    :disabled="!formEdit.statusRemove">
                                    <el-option :label="$t(
                                        'zimbra.zmMsg.AB_FIELD_custom'
                                    )
                                        " value="1">
                                    </el-option>
                                </el-select>
                            </div>
                            <div style="width: 100px" class="pl-2 pr-2">
                                <el-input class="el-text-control" :disabled="!formEdit.statusRemove"
                                    v-model="formEdit.timeRemove" size="mini"></el-input>
                            </div>
                            <div style="width: 100px">
                                <el-select v-model="formEdit.optionTimeRemove" size="mini"
                                    :disabled="!formEdit.statusRemove">
                                    <el-option v-for="item in optionsTime" :key="item.id" :label="item.label"
                                        :value="item.days"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </el-form>
                </el-tab-pane>
                <!-- danh sách email được chia sẻ -->
                <fieldset v-show="showEmailShare" style="padding: 5px; margin: 5px; margin-top: 20px;" class="border">
                    <legend class="w-auto">
                        <strong>{{ $t("zimbra.zmMsg.folderSharing") }}</strong>
                    </legend>
                    <perfect-scrollbar style="max-height: 230px">
                        <el-table :data="listShare" :show-header="false" :fit="true" class="table-wrap">
                            <el-table-column prop="name" label="" width="auto" height="auto">
                            </el-table-column>
                            <el-table-column prop="role" label="" width="auto">
                            </el-table-column>
                            <el-table-column prop="actione" label="" width="170">
                                <template slot-scope="scope">
                                    <el-button type="text" size="medium" @click="editShare(scope.row)">
                                        {{
                                            $t(
                                                "zimbra.zhMsg.edit"
                                            )
                                        }}
                                    </el-button>
                                    <el-button type="text" size="medium" @click="clickRegain(scope.row)">{{
                                        $t(
                                            "zimbraNokey.zimbra_sidebar_dialogEditFolder_buttonReGain"
                                        )
                                    }}
                                    </el-button>
                                    <el-button type="text" size="medium" @click="reSend(scope.row)">
                                        {{
                                            $t(
                                                "zimbra.zhMsg.resend"
                                            )
                                        }}
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </perfect-scrollbar>
                </fieldset>
            </el-tabs>

            <span slot="footer" class="dialog-footer">
                <el-button :hidden="!hasPermissionSpecifyFolder(
                    treeNodeData,
                    RIGHTS_FOLDER.ADMINISTER
                )
                    " class="btn-outline float-left" v-if="showButtonShare"
                    @click="$root.$emit('handleSidebarActionShare', {}, false)">
                    {{ $t("zimbraNokey.zimbra_sidebar_dialogEditFolder_buttonAddShare") }}
                </el-button>
                <el-button class="btn-email default" @click="dialogEditFolder = false">{{
                    $t("zimbra.zhMsg.actionTaskCancelTT") }}</el-button>
                <el-button class="btn-email primary" type="primary" @click="editFolder">{{
                    $t("zimbra.zmMsg.mobileStatusNeedProvision") }}</el-button>
            </span>
        </el-dialog>
        <!-- dialog thu hồi chia sẻ -->
        <el-dialog class="dialog-body-wrap zimbra-dialog" :title="$t('zimbraNokey.zimbra_sidebar_dialogReGain_title')"
            :visible.sync="dialogReGain" width="463px" :append-to-body="true">
            <el-form class="form-wrap">
                <b>{{
                    $t("zimbraNokey.zimbra_sidebar_dialogReGain_confirmReGain", {
                        name: rowShare.name,
                    })
                }}</b>
                <div style="margin-left: 20px; margin-top: 10px">
                    <el-select size="medium" v-model="formShare.optionMessage" :placeholder="$t(
                        'zimbra.zhMsg.select'
                    )
                        " @change="changeOptionMessage">
                        <el-option value="1" :label="$t('zimbra.zmMsg.sendNoMailAboutShare')
                            ">
                        </el-option>
                        <el-option value="2" :label="$t('zimbra.zmMsg.sendStandardMailAboutShare')
                            ">
                        </el-option>
                        <el-option value="3" :label="$t('zimbra.zmMsg.sendStandardMailAboutSharePlusNote')
                            ">
                        </el-option>
                    </el-select>
                    <div v-show="showNote" style="width: 26em; display: block; padding-top: 10px">
                        <b>{{ $t("zimbra.zmMsg.noteLabel") }}</b>
                        {{ $t("zimbraNokey.zimbra_sidebar_dialogReGain_noteContent") }}
                    </div>
                    <div v-show="showAddNote">
                        <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" v-model="formShare.note"
                            style="width: 26em; display: inline-block">
                        </el-input>
                    </div>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogReGain = false">{{
                    $t("zimbra.zhMsg.actionTaskCancelTT")
                    }}</el-button>
                <el-button type="primary" @click="regain">{{
                    $t("zimbra.zmMsg.mobileStatusNeedProvision")
                    }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import ZmFolderRetentionView from "@/utils/zimbra-lib/ZmFolderRetentionView.js";
import AjxUtil from "@/utils/zimbra-lib/AjxUtil.js";
import CompileService from "@/services/CompileService.js";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import color from "./../listColor";
import _ from "lodash";
import { TIME_IN_DAYS } from "@/utils/constants";
import AccountService from "@/services/account/AccountService";
import { FOLDER_DEFAULT, RIGHTS_FOLDER, STATUS_REQUEST, READ_EMAIL_LAYOUT_ROUTER } from "@/utils/constants";
import ZmColorPicker from "@/components/modules/base/base-color-picker-advance/zm-color-picker";
import { mapGetters } from "vuex";
export default {
    components: {
        "zm-color-picker": ZmColorPicker
    },
    mixins: [CommonUtils],
    mounted() {
        this.$root.$on("handleSidebarActionEdit", () => {
            this.show();
        });
        this.$root.$on("reloadDataFolder", (idFolder) => {
            this.getDetailFolder(idFolder);
        });
        this.$root.$on("reSend", (data) => {
            this.myAddresses = this.fromAddresses.find(x => x.uuid == this.preference.defaultFromAddress) || this.myAddresses;
            this.myAddresses.d = this.attributes ? this.attributes.displayName : "";
            this.reSend(data);
        });
    },
    computed: {
        ...mapGetters([
            "activeNodeSidebarRightClick",
            "activeNodeSidebar",
            "preference",
            "fromAddresses",
            "activeFolderExtra",
            "dataSidebarInBox",
            "dataSidebarExtend",
            "attributes"
        ]),
    },
    watch: {
    },
    data() {
        return {
            formEdit: {
                color: null,
                statusMaintain: false,
                statusRemove: false,
                optionMaintain: "1",
                optionRemove: "1",
                optionTimeMaintain: 4,
                optionTimeRemove: 4,
                timeMaintain: "",
                timeRemove: "",
                name: "",
                rgb: null,
                size: "",
                mailNumber: "",
                id: "",
                owner: null,
                operationRole: "",
            },
            listColor: color,
            dialogEditFolder: false,
            TIME_IN_DAYS: TIME_IN_DAYS,
            RIGHTS_FOLDER: RIGHTS_FOLDER,
            showEmailShare: false,
            listShare: [],
            showButtonShare: true,
            treeNodeData: {},
            formShare: {
                note: "",
                optionMessage: "2",
            },
            dialogReGain: false,
            rowShare: {},
            showNote: true,
            showAddNote: false,
            emailAddress: "",
            grantorId: "",
            emailOwnerName: "",
            optionsTime: ZmFolderRetentionView._CustomUnits,
            idFolder: "",
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            disableEditName: false,
            disableEditColor: false,
            mapOperationRole: new Map([
                ["", this.$t("zimbra.zhMsg.no")],
                ["r", this.$t("zimbra.zhMsg.MO_view")],
                ["rwidx", this.$t("zimbraNokey.zimbra_setting_share_formShare_roleManager")],
                ["rwidxa", this.$t("zimbraNokey.zimbra_setting_share_formShare_roleAdmin")],
                ["rwidxc", this.$t("zimbraNokey.zimbra_setting_share_formShare_roleManager")],
                ["rwidxac", this.$t("zimbraNokey.zimbra_setting_share_formShare_roleAdmin")],
            ]),
            mapRole: new Map([
                ["", this.$t("zimbra.zhMsg.no")],
                ["r", this.$t("zimbra.zmMsg.shareRoleViewer")],
                ["rwidx", this.$t("zimbra.zmMsg.shareRoleManager")],
                ["rwidxa", this.$t("zimbra.zmMsg.shareActionAdmin")],
                ["rwidxc", this.$t("zimbra.zmMsg.shareRoleManager")],
                ["rwidxac", this.$t("zimbra.zmMsg.shareActionAdmin")],
            ]),
            myAddresses: {},
            activeName: "tabProperties"
        };
    },
    created() {
        // this.myAddresses =  this.fromAddresses.find(x => x.uuid == this.preference.defaultFromAddress) || this.myAddresses;
        this.getInfo();
    },
    methods: {
        show() {
            this.myAddresses = this.fromAddresses.find(x => x.uuid == this.preference.defaultFromAddress) || this.myAddresses;
            this.myAddresses.d = this.attributes ? this.attributes.displayName : "";
            this.showButtonShare = true;
            this.showAddNote = false;
            (this.formShare = {
                note: "",
                optionMessage: "2",
            }),
                (this.treeNodeData = this.activeNodeSidebarRightClick);
            this.disableEditColor =
                this.treeNodeData.id == FOLDER_DEFAULT.DRAFTS;
            this.disableName = false;
            this.idFolder = this.treeNodeData.id;
            this.getDetailFolder(this.idFolder);
            if (_.isNil(this.formEdit.rgb)) {
                this.formEdit.rgb = "#3F4254";
            }
            this.activeName = "tabProperties";
            this.dialogEditFolder = true;
            this.disableEditName = false;
            this.treeNodeData.id;
            for (var item in FOLDER_DEFAULT) {
                if (this.treeNodeData.id == FOLDER_DEFAULT[item]) {
                    this.disableEditName = true;
                    return;
                }
            }
            if (this.isFolderExternalAccount(this.treeNodeData)) {
                this.disableEditName = true;
            }
            const { name } = this.$route;
            if (name == READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE) {
                this.isMessage = true
            } else {
                this.isMessage = false
            }
        },
        findFolderById(id, listFolder) {
            for (let index in listFolder) {
                if (listFolder[index].id == id) {
                    return listFolder[index];
                } else if (listFolder[index].folder) {
                    const result = this.findFolderById(id, listFolder[index].folder);
                    if (result != null) {
                        return result;
                    }
                }
            }
            return null;
        },
        async getDetailFolder(idFolder) {
            this.showEmailShare = false;
            const form = {
                folder: `<folder><l>${idFolder}</l></folder>`,
            };
            this.formEdit = {
                color: null,
                statusMaintain: false,
                statusRemove: false,
                optionMaintain: "1",
                optionRemove: "1",
                optionTimeMaintain: 366,
                optionTimeRemove: 366,
                timeMaintain: "",
                timeRemove: "",
                name: "",
                rgb: null,
                owner: null,
                operationRole: null,
            };
            const request = await ZimbraMailService.getFolderRequest(form);
            const folderResponse =
                this.getResponseBody(request)["GetFolderResponse"];
            let folder = folderResponse.folder || folderResponse.link;
            if (this.treeNodeData.isLink) {
                folder = folderResponse.link;
                this.formEdit.owner = folder.owner;
                this.formEdit.operationRole = this.mapOperationRole.get(
                    folder.perm
                );
            }
            //// reload Folder
            if (!_.isNil(folder) && !_.isNil(folder.id)) {
                const folderReload = this.findFolderById(idFolder, this.dataSidebarInBox[0].folder) ||
                    this.findFolderById(idFolder, this.dataSidebarExtend[0].folder);
                folderReload.u = folder.u;
                folderReload.n = folder.n;
                folderReload.acl = folder.acl;
                this.$root.$emit("updateItemToDataExtend", folderReload);
                this.$root.$emit("updateItemToDataInbox", folderReload);
                if (folder.id == FOLDER_DEFAULT.INBOX) {
                    const title = `VMail: ${folderReload.name} ${folderReload.u > 0 ? `(${folderReload.u})` : ""}`
                    this.setFavicon(folderReload.u, title);
                }
                ///////////////
                this.formEdit.name = this.treeNodeData
                    ? this.treeNodeData.name
                    : folder.name;
                this.formEdit.url = folder.url;
                const sizeFormat = AjxUtil.formatSize(folder.s);
                this.formEdit.size = sizeFormat.size + sizeFormat.units;
                this.formEdit.mailNumber = folder.n;
                this.formEdit.mailUnread = folder.u;
                if (folder.rgb) {
                    this.formEdit.rgb = folder.rgb;
                }
                if (folder.color) {
                    this.formEdit.rgb = this.listColor.find(
                        (x) => x.colorNumeric == folder.color
                    ).value;
                }
                if (_.isNil(this.formEdit.rgb)) {
                    this.formEdit.rgb = "#3F4254";
                }
                if (folder.retentionPolicy) {
                    const retentionPolicy = folder.retentionPolicy;
                    if (retentionPolicy.keep.policy) {
                        const timeValue =
                            retentionPolicy.keep.policy.lifetime.replace("d", "");
                        const time = ZmFolderRetentionView.getValue(timeValue);
                        this.formEdit.optionTimeMaintain = time.option;
                        this.formEdit.timeMaintain = time.value;
                        this.formEdit.statusMaintain = true;
                    }
                    if (retentionPolicy.purge.policy) {
                        const timeValue =
                            retentionPolicy.purge.policy.lifetime.replace("d", "");
                        const time = ZmFolderRetentionView.getValue(timeValue);
                        this.formEdit.optionTimeRemove = time.option;
                        this.formEdit.timeRemove = time.value;
                        this.formEdit.statusRemove = true;
                    }
                }
                this.listShare = [];
                if (folder.acl) {
                    this.showEmailShare = true;
                    let listMaiShare = [];
                    if (folder.acl.grant.length) {
                        listMaiShare = folder.acl.grant;
                    } else {
                        listMaiShare.push(folder.acl.grant);
                    }
                    listMaiShare.forEach((x) => {
                        this.listShare.push({
                            name: x.d || x.zid,
                            role: this.mapRole.get(x.perm),
                            zid: x.zid,
                            perm: x.perm,
                            gt: x.gt,
                        });
                    });
                    ///////// ẩn email của tài khoản đang đăng nhập khi được chia sẻ với quyền quản trị
                    if (this.treeNodeData.isLink) {
                        this.listShare = this.listShare.filter(x => x.name != this.myAddresses.a);
                        if (this.listShare.length < 1) {
                            this.showEmailShare = false;
                        }
                    }
                }
            }

        },
        clickTabFormEdit(tab) {
            this.showButtonShare = true;
            if (tab.name != "tabProperties") {
                this.showButtonShare = false;
            }
        },
        laSoNguyenDuong(value) {
            return (
                !isNaN(value) &&
                Number.isInteger(parseFloat(value)) &&
                parseFloat(value) > 0
            );
        },

        transformData(formData) {

            return formData.map(item => {
                // Transform each item here
                const folder = JSON.parse(item.content);
                return { folder: folder.folder };
            });
        },
        editFolder() {
            this.formEdit.name = this.formEdit.name.toString().trim();
            if (
                this.formEdit.name == "" ||
                this.formEdit.name.length > 128 ||
                this.formEdit.name.indexOf("/") > -1 ||
                (/[!@#$%^&*(),.?":{}|<>]/.test(this.formEdit.name))
            ) {
                const nameError = _.escape(this.formEdit.name);
                const Msgerror =
                    this.formEdit.name == ""
                        ? this.$t("zimbra.zmMsg.nameEmpty")
                        : this.formEdit.name.length > 128
                            ? this.$t("zimbra.zhMsg.nameTooLong", { 0: 128 })
                            : this.$t(
                                "zimbra.zmMsg.errorInvalidName",
                                { 0: nameError }
                            );
                this.$alert(Msgerror, this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"), {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    type: "error",
                });
                return;
            }

            this.formEdit.name = _.escape(this.formEdit.name);
            let stringXml = [];
            if (this.formEdit.name != this.treeNodeData.name) {
                const xml = `<urn1:action id="${this.treeNodeData.id}" op="rename" name="${this.formEdit.name}"></urn1:action>`;
                stringXml.push({
                    path: "folderActionRequest",
                    content: JSON.stringify({ folder: xml }),
                });
            }
            if (this.formEdit.rgb != this.treeNodeData.rgb) {
                this.formEdit.rgb =
                    this.formEdit.rgb == null ? "" : this.formEdit.rgb;
                const xml = `<urn1:action id="${this.treeNodeData.id}" op="color" rgb="${this.formEdit.rgb}"></urn1:action>`;
                stringXml.push({
                    path: "folderActionRequest",
                    content: JSON.stringify({ folder: xml }),
                });
            }

            const arrMsgError = [];
            if (this.formEdit.statusMaintain && !this.laSoNguyenDuong(this.formEdit.timeMaintain)) {
                arrMsgError.push(this.$t("zimbra.zmMsg.retentionKeepLifetimeAmount"))
            }
            if (this.formEdit.statusRemove && !this.laSoNguyenDuong(this.formEdit.timeRemove)) {
                arrMsgError.push(this.$t("zimbra.zmMsg.retentionPurgeLifetimeAmount"))
            }
            if (arrMsgError.length > 0) {
                this.$alert(

                    arrMsgError.join("<br>"),
                    this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                    {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        type: "warning",
                    }
                );
                return;
            }
            let stringXmlMaintain = "";
            let stringXmlRemove = "";
            if (
                this.formEdit.statusMaintain &&
                this.formEdit.timeMaintain != ""
            ) {
                const strTime = `${this.formEdit.timeMaintain *
                    this.formEdit.optionTimeMaintain
                    }d`;
                stringXmlMaintain = `<urn1:keep><urn1:policy lifetime="${strTime}" type="user"/></urn1:keep>`;
            } else {
                stringXmlMaintain = "<urn1:keep></urn1:keep>";
            }
            if (this.formEdit.statusRemove && this.formEdit.timeRemove != "") {
                const strTime = `${this.formEdit.timeRemove * this.formEdit.optionTimeRemove
                    }d`;
                stringXmlRemove = `<urn1:purge><urn1:policy lifetime="${strTime}" type="user"/></urn1:purge>`;
            } else {
                stringXmlRemove = "<urn1:purge></urn1:purge>";
            }
            const xml = `<urn1:action id="${this.treeNodeData.id}" op="retentionpolicy"><urn1:retentionPolicy>${stringXmlMaintain}${stringXmlRemove}</urn1:retentionPolicy></urn1:action>`;
            stringXml.push({
                path: "folderActionRequest",
                content: JSON.stringify({ folder: xml }),
            });
            const formData = {
                listRequest: stringXml,
            };
            const folder = this.treeNodeData;
            this.treeNodeData.name = this.formEdit.name;
            this.treeNodeData.rgb = this.formEdit.rgb;
            if (this.formEdit.statusMaintain) {
                this.treeNodeData.optionTimeMaintain = this.formEdit.optionTimeMaintain;
                this.treeNodeData.timeMaintain = this.formEdit.timeMaintain;
            }
            if (this.formEdit.statusRemove) {
                this.treeNodeData.optionTimeRemove = this.formEdit.optionTimeRemove;
                this.treeNodeData.timeRemove = this.formEdit.timeRemove;
            }


            const check = false;
            if (stringXml != "") {
                const transformedData = this.transformData(formData.listRequest);

                transformedData.map(data => {
                    ZimbraMailService.folderActionRequest(data).then((res) => {
                        if (res.status == 200) {
                            if (this.isMessage) {
                                check = true;

                            }
                            this.$store.commit("SET_ACTIVE_NODE_SIDEBAR", folder);
                        } else {
                            check = false;
                            this.$alert({
                                title: this.$t("zimbra.zmMsg.dataSourceTestFailure"),
                                message: this.$t("zimbra.voice.UNABLE_TO_RETRIEVE_PROFILE_SUMMARY"),
                                type: "error",
                                duration: 3500,
                            })
                        }
                    }).catch(() => {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestFailure"),
                            message: this.$t("zimbra.voice.UNABLE_TO_RETRIEVE_PROFILE_SUMMARY"),
                            type: "error",
                            duration: 2000,
                        })
                    })

                })
            }
            if (check === true) {
                this.$message({
                    type: "success",
                    customClass: 'zm-notify',
                    duration: 3500,
                    dangerouslyUseHTMLString: true,
                    message: this.$t("zimbraNokey.zimbra_sidebar_dialogEditFolder_updateFolderSuccess"),
                });

            } else {
                this.$notify({
                    title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: this.$t("zimbraNokey.zimbra_sidebar_dialogEditFolder_updateFolderSuccess"),
                    type: "success",
                });
            }
            this.dialogEditFolder = false;
        },
        editShare(row) {
            this.isEditShare = true;
            const formShare = {};
            formShare.optionShare = row.gt;
            formShare.optionRole = row.perm;
            formShare.email = [row.name];
            this.$root.$emit("handleSidebarActionShare", formShare, true);
        },
        changeOptionMessage() {
            if (this.formShare.optionMessage == "1") {
                this.showAddNote = false;
                this.showNote = false;
            } else if (this.formShare.optionMessage == "3") {
                this.showAddNote = true;
                this.showNote = true;
            } else {
                this.showAddNote = false;
                this.showNote = true;
            }
        },
        clickRegain(row) {
            this.formShare = {
                note: "",
                optionMessage: "2",
            };
            this.rowShare = row;
            this.showAddNote = false;
            this.dialogReGain = true;
        },
        async getInfo() {
            const reponse = await ZimbraMailService.getInfoRequest({
                sections: "idents,sigs,mbox",
            });
            const infoResponse =
                this.getResponseBody(reponse)["GetInfoResponse"];
            const { name, id } = infoResponse;
            this.emailAddress = name;
            this.grantorId = id;
            const reponse1 = await AccountService.getAccountInfoRequest({
                username: name,
            });
            const infoResponse1 =
                this.getResponseBody(reponse1)["GetAccountInfoResponse"];
            this.emailOwnerName = infoResponse1.attr[2].content;
        },
        regain() {
            const row = this.rowShare;
            const xml = {
                folder: `<urn1:action id="${this.treeNodeData.id}" zid="${row.zid}" op="!grant"></urn1:action>`,
            };

            ZimbraMailService.folderActionRequest(xml).then((res) => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    if (this.isMessage) {
                        this.$message({
                            type: "success",
                            customClass: 'zm-notify',
                            duration: 3500,
                            dangerouslyUseHTMLString: true,
                            message: this.$t("zimbraNokey.zimbra_sidebar_dialogEditFolder_regainSuccess"),
                        });
                    } else {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t("zimbraNokey.zimbra_sidebar_dialogEditFolder_regainSuccess"),
                            type: "success",
                        });
                    }
                }

                this.listShare = this.listShare.filter((x) => x.zid != row.zid);
                if (this.listShare.length < 1) {
                    this.showEmailShare = false;
                }
            });
            if (this.formShare.optionMessage != "1") {
                this.sentMsgRegain(row);
            }
            // setTimeout(() => {
            //     this.getDetailFolder(this.treeNodeData.id);
            // }, 500);
            this.dialogReGain = false;
        },
        // title,nameFolder,emailOwnerName,emailTo,role,operationRole,isRegain,showNote,idFolder,granteeId,grantorId,perm
        buildMpContent(objectInput) {
            const titleGrantee = objectInput.isRegain
                ? "Người bị thu hồi"
                : "Người được cấp";
            let htmlNote = "";
            let plainNote = "";
            if (objectInput.showNote) {
                plainNote = `\n *~*~*~*~*~*~*~*~*~* \n ${objectInput.note}`;
                htmlNote = `<hr>
                <table border="0" cellpadding="3" cellspacing="0" style="color:#333;font:1em sans-serif;">
                    <tr valign="top"><th align="right">Ghi chú:</th><td>${objectInput.note}</td></tr>
                </table>`;
            }
            let htmlRole = "";
            let plainRole = "";
            if (objectInput.role != undefined) {
                htmlRole = `<tr><th align="right">Vai trò:</th><td>${objectInput.role}</td></tr>
                <tr><th align="right">Thao tác được cho phép</th><td>${objectInput.operationRole}</td>`;
                plainRole = `\n Vai trò: ${objectInput.role} \n Thao tác được cho phép: ${objectInput.operationRole}`;
            }
            let stringPlainContent = `${objectInput.title}\n ----------------------------------------------\nMục chia sẻ: ${objectInput.nameFolder}(Thư mục thư) \n Chủ sở hữu: ${objectInput.emailOwnerName}\n ${titleGrantee}: ${objectInput.emailTo} ${plainRole} ${plainNote}`;
            let htmlContent = `<div style="font-family:sans-serif;">
                <h1 style="font-size:1.3em;">${objectInput.title}</h1>
                <hr>
                <table border="0" cellpadding="3" cellspacing="0" style="color:#333;font-size:1em;">
                    <tr><th align="right">Mục chia sẻ:</th><td>${objectInput.nameFolder} (Thư mục thư)</td></tr>
                    <tr><th align="right">Chủ sở hữu:</th><td>${objectInput.emailOwnerName}</td></tr>
                    <tr><th align="right">${titleGrantee}:</th><td>${objectInput.emailTo}</td></tr>
                    ${htmlRole}
                </table>
            </div>
            ${htmlNote}`;
            const action = objectInput.isRegain ? "delete" : "new";
            let xmlContent = `<share xmlns="urn:zimbraShare" version="0.2" action=${action} >
                <grantee id="${objectInput.granteeId}" email="${objectInput.emailTo}" name="${objectInput.emailTo}" />
                <grantor id="${objectInput.grantorId}" email="${objectInput.emailFrom}" name="${objectInput.emailOwnerName}" />
                <link id="${objectInput.idFolder}" name="${objectInput.nameFolder}" view="message" perm="${objectInput.perm}" />
                <notes>${objectInput.note}</notes>
            </share>`;
            const mpValue = [
                { ct: "text/plain", content: _.escape(stringPlainContent.replace(/\n/g, '<br>')) },
                { ct: "text/html", content: _.escape(htmlContent) },
                { ct: "xml/x-zimbra-share", content: _.escape(xmlContent) },
            ];
            const mp = [];
            mp[0] = { ct: "multipart/alternative", mp: mpValue };
            return mp;
        },
        async sentMsgRegain(row) {
            const emailFrom = this.myAddresses.a;
            const emailTo = row.name;
            const pEmailTo = emailTo.substring(0, emailTo.indexOf("@"));
            const pEmailFrom = emailFrom.substring(0, emailFrom.indexOf("@"));
            const objectInput = {
                title: `Lượt chia sẻ sau đã bị hủy bỏ: ${this.treeNodeData.name}`,
                nameFolder: this.treeNodeData.name,
                emailOwnerName: this.myAddresses.d,
                emailTo: row.name,
                emailFrom: this.myAddresses.a,
                isRegain: true,
                showNote: false,
                idFolder: this.treeNodeData.id,
                granteeId: row.zid,
                grantorId: this.grantorId,
                perm: row.perm,
                note: this.formShare.note,
            };
            if (this.formShare.optionMessage == "3") {
                objectInput.showNote = true;
            }
         
            const mail = {
                su: _.escape(
                    `Chia sẻ bị thu hồi: ${objectInput.nameFolder} được chia sẻ bởi ${objectInput.emailOwnerName || objectInput.emailFrom}`
                ),
            };
            const e = [
                { t: "t", a: `${emailTo}`, p: `${pEmailTo}` },
                { t: "f", a: `${emailFrom}`, p: `${pEmailFrom}` },
            ];
            mail["e"] = e;
            mail["mp"] = this.buildMpContent(objectInput);
            const sendMsgRequest = await ZimbraMailService.sendMsgRequest({
                m: mail,
            });
            const sendMsgResponse =
                this.getResponseBody(sendMsgRequest)["SendMsgResponse"];
            if (sendMsgResponse?.m?.id) {
                // this.$notify({
                //     title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                //     message: this.$t("zimbraNokey.zimbra_compose_sendSuccess"),
                //     type: "success",
                // });
                this.$emit("sendMsgSuccess");
            } else {
                // gửi thất bại
            }
        },
        async reSend(row) {
            const MapOperationRole = new Map([
                ["", this.$t("zimbra.zhMsg.no")],
                [this.$t("zimbra.zmMsg.shareRoleViewer"), this.$t("zimbra.zhMsg.MO_view")],
                [this.$t("zimbra.zmMsg.shareRoleManager"), this.$t("zimbraNokey.zimbra_setting_share_formShare_roleManager")],
                [this.$t("zimbra.zmMsg.shareActionAdmin"), this.$t("zimbraNokey.zimbra_setting_share_formShare_roleAdmin")],
            ]);
            row.role = this.mapRole.get(row.perm);
            const objectInput = {
                title: `${this.myAddresses.d} đã chia sẻ ${this.activeNodeSidebarRightClick.name} với ${row.name}`,
                nameFolder: this.activeNodeSidebarRightClick.name,
                emailOwnerName: this.myAddresses.d,
                emailTo: row.name || row.d,
                emailFrom: this.myAddresses.a,
                isRegain: false,
                showNote: false,
                idFolder: this.activeNodeSidebarRightClick.id,
                granteeId: row.zid,
                grantorId: this.grantorId,
                perm: row.perm,
                note: this.formShare.note,
                role: row.role,
                operationRole: MapOperationRole.get(row.role),
            };
            if (this.formShare.optionMessage == "3") {
                objectInput.showNote = true;
            }
            const pEmailTo = objectInput.emailTo.substring(
                0,
                objectInput.emailTo.indexOf("@")
            );
            const pEmailFrom = objectInput.emailFrom.substring(
                0,
                objectInput.emailFrom.indexOf("@")
            );
            const mail = {
                su: _.escape(
                    `Chia sẻ được tạo: ${objectInput.nameFolder} được chia sẻ bởi ${objectInput.emailOwnerName}`
                ),
            };
            const e = [
                { t: "t", a: `${objectInput.emailTo}`, p: `${pEmailTo}` },
                { t: "f", a: `${objectInput.emailFrom}`, p: `${pEmailFrom}` },
            ];
            mail["e"] = e;
            mail["mp"] = this.buildMpContent(objectInput);
            ZimbraMailService.sendMsgRequest({
                m: mail,
            }).then((res) => {
                const sendMsgResponse =
                    this.getResponseBody(res)["SendMsgResponse"];
                if (sendMsgResponse?.m?.id) {
                    if (this.isMessage) {
                        this.$message({
                            type: "success",
                            customClass: 'zm-notify',
                            duration: 3500,
                            dangerouslyUseHTMLString: true,
                            message: this.$t("zimbraNokey.zimbra_compose_sendSuccess"),
                        });
                    } else {
                        this.$notify({
                            title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                            message: this.$t("zimbraNokey.zimbra_compose_sendSuccess"),
                            type: "success",
                        });
                    }
                    this.$emit("sendMsgSuccess");
                } else {
                    // gửi thất bại
                }
            }).catch(() => { });

        },
    },
};
</script>
